import axios from 'axios'
import { getToken, hasToken } from './storage.helper'
export const addAuthHeader = () => {
  if (hasToken() && axios.defaults.headers.common.Authorization === undefined) {
    axios.defaults.headers.common.Authorization = getToken()
  }
}
export const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = undefined
}
