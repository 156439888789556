import { IMe } from 'api/types.users'
import React from 'react'
import ClassesTab from '../../../../features/ClassesTab/ClassesTab'
import { SchoolTabCards } from '../SchoolCardTabs/SchoolCardTabs'

type SchoolViewContentProps = {
  activeTab: SchoolTabCards
  me: IMe
}

const SchoolViewContent = ({ activeTab, me }: SchoolViewContentProps) => {
  if (activeTab === SchoolTabCards.CLASSES) {
    return <ClassesTab me={me} />
  }

  return null
}

export default SchoolViewContent
