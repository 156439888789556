import React, { ReactNode, memo } from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import styles from './HeatmapMenu.module.scss'

import { BUTTON_VARIANT, Button } from '@magmamath/ui'
import { Link } from 'react-router-dom'
import { PATHS } from '../../../config/pathnames.config'
import CrossIcon from '../../../ui/icons/CrossIcon/CrossIcon'

type HeatmapMenuProps = {
  actionButtons?: ReactNode
  navigation?: ReactNode
}

const HeatmapMenuSkeleton = ({ actionButtons, navigation }: HeatmapMenuProps) => {
  return (
    <header className={styles.Menu}>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        As={Link}
        to={PATHS.MAIN}
        icon={<CrossIcon size={16} color='var(--icon-color)' />}
        classes={{ button: styles.Button }}
      />
      <div className={styles.MenuBar}>
        <Skeleton variant='rounded' width={428} height={27} />
        {actionButtons && (
          <div data-id='skeleton' className={styles.Actions}>
            {actionButtons}
          </div>
        )}
      </div>
      {navigation && navigation}
    </header>
  )
}

export default memo(HeatmapMenuSkeleton)
