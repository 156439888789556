import { clsx } from 'clsx'
import React, { PropsWithChildren } from 'react'
import styles from './Skeleton.module.scss'
import { getVariantClassName } from './helpers'

export type SkeletonProps = {
  width?: number | string
  height?: number | string
  variant?: 'rectangular' | 'rounded' | 'circular'
} & PropsWithChildren<JSX.IntrinsicElements['div']>

const Skeleton = ({ className, variant, width, height, children, ...restProps }: SkeletonProps) => {
  return (
    <div
      style={{ width, height }}
      className={clsx(styles.Skeleton, getVariantClassName(variant), className)}
      {...restProps}
    >
      {children}
    </div>
  )
}

export default Skeleton
