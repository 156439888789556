import { combine, createEvent, createStore } from 'effector'
import { $sortOption } from './heatmapSettings'
import { handleStudentsSort } from '../helpers/sorting'
import {
  exerciseInitialState,
  IExerciseWithoutStats,
} from '../../../../redux/modules/exerciseWithoutStats.module'

export const setExercise = createEvent<IExerciseWithoutStats>()
export const $exercise = createStore<IExerciseWithoutStats>(exerciseInitialState.data as any).on(
  setExercise,
  (_, payload) => payload
)

export const $sortedStudents = combine(
  { exercise: $exercise, sortOption: $sortOption },
  ({ exercise, sortOption }) => {
    if (!exercise) return []
    const sorted = handleStudentsSort(exercise.students, sortOption, exercise)
    if (sorted.length !== exercise.students.length) {
      return exercise.students
    }
    return sorted
  }
)
