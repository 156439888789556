import AnswerList from 'features/AnswerList/AnswerList'
import { AnswerType, MyScriptCharacterTypes, Separator } from 'helpers/enums'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import useText from '../../../../i18n/hook'
import styles from './SolutionCardBody.module.scss'

type NoSolutionBodyProps = {
  noSolutionAnswer?: string[]
  characterType: MyScriptCharacterTypes
  answerType?: AnswerType
}

const NoSolutionBody = ({ noSolutionAnswer, characterType, answerType }: NoSolutionBodyProps) => {
  const t = useText()

  if (!noSolutionAnswer || isEmpty(noSolutionAnswer))
    return (
      <div className={styles.Body}>
        <p className={styles.NoAnswer}>{t.noSolutionSubmitted}</p>
      </div>
    )
  return (
    <div className={styles.Body}>
      <AnswerList
        answers={noSolutionAnswer}
        characterType={characterType}
        answerType={answerType}
        separator={Separator.COMMA}
      />
    </div>
  )
}

export default NoSolutionBody
