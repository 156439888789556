import * as React from 'react'
import { IMe, IStudent } from '../../../../api/types.users'
import StudentsOptionsMenuUpdated from '../StudentTableRow/StudentsOptionsMenuUpdated/StudentsOptionsMenuUpdated'
import StudentsTableUIRow from '../../../../ui/tables/StudentsTableUI/StudentsTableUIRow/StudentsTableUIRow'
import { IModalActions } from '../../../../redux/modules/modal.module'
import { ILocalization } from '../../../../config/languages.config'
import { IClass } from '../../../../api/types.classes'
import { IGroup } from '../../../../api/api.groups'

interface IStudentsTableRowProps {
  student: IStudent
  history: any
  localization: ILocalization
  me: IMe
  modalActions: IModalActions
  classId?: string
  groupId?: string
  classOrGroup: IClass | IGroup
}

const StudentsTableRow: React.FC<IStudentsTableRowProps> = ({
  student,
  me,
  modalActions,
  localization,
  history,
  classId,
  groupId,
  classOrGroup,
}) => {
  return (
    <StudentsTableUIRow student={student}>
      <StudentsOptionsMenuUpdated
        me={me}
        username={student.username!}
        modalActions={modalActions}
        history={history}
        localization={localization}
        classId={classId}
        groupId={groupId}
        student={student}
        classOrGroup={classOrGroup}
      />
    </StudentsTableUIRow>
  )
}

export default StudentsTableRow
