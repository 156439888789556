import React from 'react'
import editStyles from '../EditMode/EditMode.module.scss'
import BinIcon from '../../../../ui/icons/BinIcon/BinIcon'
import Button from '../../../../ui/Buttons/UIButton/Button'
import PlusIcon from '../../../../ui/icons/PlusIcon/PlusIcon'
import { IconButton } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { COLORS } from '@magmamath/ui'

import { ITopic } from '../../../../api/api.topics'

type EditModeProps = {
  topics: ITopic[]
  addNewTopicText: string
  onClose: () => void
  addTopic: (topic: ITopic) => void
  removeTopic: (topicId: string) => void
  updateTopic: (topic: ITopic) => void
}

const EditMode = ({
  topics,
  addNewTopicText,
  addTopic,
  removeTopic,
  updateTopic,
}: EditModeProps) => {
  return (
    <div className={editStyles.Content}>
      <Button
        size='large'
        className={editStyles.AddTopic}
        onClick={() => {
          addTopic({ name: '', exercises: [], teachers: [], _id: uuidv4() })
        }}
      >
        {addNewTopicText}
        <PlusIcon size={12} color={COLORS.NEUTRAL_8} strokeWidth='3' />
      </Button>
      <form id='editTopics' className={editStyles.Form}>
        <ul className={editStyles.List}>
          {topics.map((topic, index) => (
            <li className={editStyles.ListItem} key={topic._id}>
              <input
                name={topic._id}
                className={editStyles.Input}
                defaultValue={topic.name}
                autoFocus={index === 0}
                onBlur={(e) =>
                  updateTopic({
                    name: e.target.value,
                    _id: topic._id,
                    exercises: topic.exercises,
                    teachers: topic.teachers,
                  })
                }
              />
              <IconButton
                type='button'
                onClick={() => {
                  removeTopic(topic._id)
                }}
              >
                <BinIcon size={15} color={COLORS.NEUTRAL_6} />
              </IconButton>
            </li>
          ))}
        </ul>
      </form>
    </div>
  )
}

export default EditMode
