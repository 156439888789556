import { createEffect } from 'effector'
import { ChangeStudentsGradesParams } from './types'
import { AVAILABLE_GRADES } from './studentsGradesModel'
import { api } from '../../../api'

export const fetchStudentsByClassFx = createEffect(async (classId: string) =>
  api.classes.getSingle({ id: classId, data: { fetchAll: 1 } }).then((res) => res.students)
)

export const changeStudentsGradesFx = createEffect(
  async ({ classId, studentsGrades }: ChangeStudentsGradesParams) => {
    const data = Array.from(Object.entries(studentsGrades), ([key, value]) => {
      return {
        studentId: key,
        settings: {
          practiceModeLockedGrades: AVAILABLE_GRADES.filter((grade) => !value.includes(grade)),
        },
      }
    })
    await api.classes.updateStudentsSettingsInClass({ classId, data })
  }
)

export const $isFetchingStudents = fetchStudentsByClassFx.pending
