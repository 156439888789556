import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Listbox } from '@magmamath/ui'
import { useDispatch, useSelector } from 'react-redux'
import { NotifyType, TypesOfParams } from '../../../helpers/enums'
import { PATHS } from '../../../config/pathnames.config'
import { useHistory, useLocation } from 'react-router'
import { RootState } from '../../../store/store'
import { selectClass } from './classesFilter.module'
import useText from '../../../i18n/hook'
import { saveClass } from '../../../redux/modules/saveClass.module'
import { getGroups } from '../../../redux/modules/groups.module'
import { getIdFromURL } from '../../../lib/filters'
import { getClasses } from '../../../redux/modules/classes.module'
import Divider from '../../../ui/dividers/Divider/Divider'
import styles from './MultiplicityFilter.module.scss'
import { sortByName } from './helper'
import { $filterSelectorStore, setFilterSelector } from '../StudentsFilter/model'
import { useUnit } from 'effector-react'
import { IFilterExercisesActions } from 'redux/modules/filterExercises.module'
import { getDefaultFilterStateByPage } from '../StudentsFilter/helper'
import { IClass } from '../../../api/types.classes'
import { getDefaultClassOption } from './defaultClassOption.helpers'
import { GradesModal } from 'features/setGrades/GradesModal'
import { callNotification } from 'redux/modules/notify.module'
import { SelectClassGradeModel } from 'features/setGrades/SelectClassGradeModel'

const MIN_CLASS_LENGTH = 1

type OptionsType = {
  name: string
  value: string | null
}

type ClassesFilterProps = {
  filterExercisesActions: IFilterExercisesActions
}
const RESISTENT_IN_CLASS_FILTERS = [TypesOfParams.TOPICS, TypesOfParams.EXAM]

const ClassesFilter = ({ filterExercisesActions }: ClassesFilterProps) => {
  const t = useText()
  const classes = useSelector((state: RootState) =>
    state.classes.data?._embedded?.classes.filter((simpleClass: IClass) => !simpleClass.archived)
  )
  const classFilterRef = useRef<HTMLButtonElement | null>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const allClassesOption = getDefaultClassOption(t)
  const isStudentsPage = history.location.pathname.includes(PATHS.PARTS.STUDENTS)
  const isPracticePage = history.location.pathname.includes(PATHS.PRACTICE.PRACTICE_MAIN)
  const isExercisesPage = history.location.pathname.includes(PATHS.EXERCISES.EXERCISES_MAIN)
  const isSchoolPage = location.pathname === PATHS.CLASSES
  const arrayOfContentPaths = Object.values(PATHS.CONTENT)
  const isLibraryPage = arrayOfContentPaths.includes(location.pathname)
  const isAccountPage = location.pathname === PATHS.ACCOUNT
  const chosenClass = useSelector((state: RootState) => state.auth.data.me?.setting?.chosenClass)
  const me = useSelector((state: RootState) => state.auth.data.me)
  const classesFilterData = useSelector((state: RootState) => state.classesFilter)
  const classesFilterSelector = useUnit($filterSelectorStore)
  const filterExercises = useSelector((state: RootState) => state.filterExercises)
  const [isLoading, setLoading] = useState(true)
  const isActive = !(isSchoolPage || isLibraryPage || isAccountPage)
  const selectedClass = useMemo(() => {
    const currentClass = classes?.find((singleClass: IClass) => singleClass._id === chosenClass)
    return chosenClass && currentClass
      ? {
          value: chosenClass,
          name: currentClass.displayName || currentClass.name,
        }
      : null
  }, [chosenClass, classes, me?.school?._id])
  const { data } = useUnit(SelectClassGradeModel.$state)
  const isClassesGradeSaving = useUnit(SelectClassGradeModel.updateClassGradeFx.pending)

  const onChange = (data: OptionsType) => {
    if (classesFilterData.value === data.value) return

    if (
      isExercisesPage &&
      classesFilterSelector?.type &&
      !RESISTENT_IN_CLASS_FILTERS.includes(classesFilterSelector.type)
    ) {
      const defaultFilterState = getDefaultFilterStateByPage(t).exercises
      setFilterSelector(defaultFilterState)
      filterExercisesActions.resetFilters(filterExercises.selectedOption, filterExercises.sortBy)
    }

    const isPageWithoutClassesFilter = isAccountPage || isLibraryPage || isSchoolPage
    const classesOption = classes.find((item: IClass) => item._id === data.value)

    if (!classesOption) {
      dispatch(saveClass(null))
      dispatch(selectClass(allClassesOption))
      if (isStudentsPage) {
        redirectToStudents(TypesOfParams.CLASSES, null)
      }
      return
    }

    dispatch(
      selectClass({
        value: classesOption._id,
        name: classesOption.displayName || classesOption.name,
      })
    )
    dispatch(saveClass(classesOption._id))
    if (isPageWithoutClassesFilter) history.push(PATHS.EXERCISES.EXERCISES_MAIN)
    if (isStudentsPage) {
      redirectToStudents(TypesOfParams.CLASSES, classesOption._id)
    }
  }
  const redirectToStudents = (params: TypesOfParams, id: string | null) => {
    history.push({
      pathname: `/students/${params}/${id}`,
      search: history.location.search,
    })
  }

  const currentClass = useMemo(() => {
    if (!classesFilterData || classesFilterData.value === Listbox.ALL_OPTION) return null

    return (
      classes?.find((singleClass: IClass) => singleClass._id === classesFilterData.value) ?? null
    )
  }, [classesFilterData?.value, classes])

  useEffect(() => {
    if (!currentClass || currentClass.grade !== -1 || currentClass.source === 0) return

    SelectClassGradeModel.setState({
      data: {
        className: currentClass.name,
        classId: currentClass._id as string,
      },
    })
  }, [currentClass])

  useEffect(() => {
    const failWatcherUnsibscribe = SelectClassGradeModel.updateClassGradeFx.fail.watch(() => {
      SelectClassGradeModel.close()
      callNotification(t.setGradeErrorNotification, NotifyType.Danger)(dispatch)
      dispatch(saveClass(null))
      dispatch(selectClass(allClassesOption))
    })

    const doneWatcherUnsibscribe = SelectClassGradeModel.updateClassGradeFx.done.watch(() => {
      SelectClassGradeModel.close()
      callNotification(t.setGradeSuccessNotification, NotifyType.Success)(dispatch)
      dispatch(getClasses())
    })

    return () => {
      failWatcherUnsibscribe()
      doneWatcherUnsibscribe()
    }
  }, [])

  const onSaveResult = (grade: string) => {
    SelectClassGradeModel.updateClassGradeFx({ grade: +grade })
  }

  useEffect(() => {
    setLoading(false)
    const _id = getIdFromURL(location.pathname)
    if (_id) {
      const currentClass = classes?.find((singleClass: IClass) => singleClass._id === _id)
      if (!currentClass && !isExercisesPage) {
        dispatch(selectClass(allClassesOption))
        return
      }
    }
    if (classesFilterData?.value && classesFilterData.value !== Listbox.ALL_OPTION) {
      dispatch(selectClass(classesFilterData))
      return
    }
    if (selectedClass) {
      dispatch(selectClass(selectedClass))
      return
    }
    dispatch(selectClass(allClassesOption))
  }, [])

  useEffect(() => {
    if (!me?.school?._id) return
    if (isExercisesPage || isPracticePage || isStudentsPage) return
    dispatch(getClasses())
    dispatch(getGroups())
    dispatch(selectClass(allClassesOption))
  }, [me?.school?._id])

  useEffect(() => {
    if ((isStudentsPage || isPracticePage) && classes.length === MIN_CLASS_LENGTH) {
      dispatch(
        selectClass({ value: classes[0]._id, name: classes[0].displayName || classes[0].name })
      )
      return
    }
  }, [isStudentsPage, isPracticePage])

  useEffect(() => {
    const isSelectForceOpened =
      (isStudentsPage || isPracticePage) &&
      (!classesFilterData?.value || classesFilterData.value === Listbox.ALL_OPTION) &&
      (classes?.length > MIN_CLASS_LENGTH || classes?.length === MIN_CLASS_LENGTH)
    if (!isSelectForceOpened) return
    classFilterRef.current?.click()
  }, [isStudentsPage, isPracticePage, classesFilterData, classes?.length])

  const options = useMemo(() => {
    return sortByName(classes).map((simpleClass) => ({
      name: simpleClass.displayName || simpleClass.name,
      value: simpleClass._id,
    }))
  }, [classes])

  const onOpenClassesTab = () => {
    history.push(PATHS.CLASSES)
    classFilterRef.current?.click()
  }

  const value = useMemo(() => {
    const selectedClass = classes.find((item: IClass) => item._id === classesFilterData?.value)
    return selectedClass
      ? { name: selectedClass.displayName || selectedClass.name, value: selectedClass._id }
      : allClassesOption
  }, [classes, classesFilterData])

  return (
    <>
      <div className={styles.ListBoxWrapper}>
        {classesFilterData && !isLoading && (
          <Listbox
            classes={{ ListboxOptions: styles.ListBoxOptions }}
            value={value}
            onChange={onChange}
            ref={classFilterRef}
            multiple={false}
            isActive={isActive}
          >
            <Listbox.Option value={allClassesOption}>{allClassesOption.name}</Listbox.Option>
            <Divider />
            {options.map((option) => (
              <Listbox.Option value={option} key={option.value}>
                {option.name}
              </Listbox.Option>
            ))}
            <Divider />
            <div className={styles.Link} onClick={onOpenClassesTab}>
              {t.moreClassesTxt}
            </div>
          </Listbox>
        )}
      </div>
      {data && (
        <GradesModal
          isOpen={!!data}
          onClose={() => SelectClassGradeModel.close()}
          classNameValue={data.className}
          onSaveClassesGrade={onSaveResult}
          isSavingPending={isClassesGradeSaving}
        />
      )}
    </>
  )
}

export default ClassesFilter
