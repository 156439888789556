import { restore } from 'effector'
import { fetchPracticeStatisticsFx } from './requests'
import { GetSkillsStatisticsResponse } from 'api/types.skillsStatistics'

export class practiceStatisticsModel {
  public static $statistics = restore<GetSkillsStatisticsResponse>(
    fetchPracticeStatisticsFx.doneData,
    {}
  )

  public static isFetching = fetchPracticeStatisticsFx.pending
}
