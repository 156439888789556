import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import useText from '../../../../i18n/hook'
import { Listbox, SelectValue } from '@magmamath/ui'
import { TypesOfParams } from '../../../../helpers/enums'

const ClassOption = () => {
  const t = useText()
  const classesFilter = useSelector((state: RootState) => state.classesFilter)
  const classOption = {
    ...classesFilter,
    name: t.wholeClassTxt,
    type: TypesOfParams.ALL_CLASSROOM_STUDENTS,
  }
  return (
    <>
      {classesFilter?.value && classesFilter.value !== Listbox.ALL_OPTION && (
        <Listbox.Option value={classOption}>{classOption.name}</Listbox.Option>
      )}
    </>
  )
}

export default ClassOption
