import { IMe, User } from 'api/types.users'
import classnames from 'classnames'
import { config } from 'config'
import { localStorageKeys } from 'config/localStorageKeys'
import { addLocale, addToken, currentLocale } from 'config/modules.config'
import { PATHS } from 'config/pathnames.config'
import ToastModel from 'features/ToastModel/ToastModel'
import { Events, LocaleData, SearchParam, SelectedValueTypes } from 'helpers/enums'
import { deleteItemsFromLocalStorage } from 'helpers/exercise.helpers'
import { getLanguageShort, setLanguage } from 'helpers/language.helpers'
import {
  detectPageWithHiddenSidebar,
  detectPageWithoutMainHeader,
  detectPageWithoutSidebar,
  isTeacherPathActive,
} from 'helpers/pathnameChecking.helper'
import {
  getItemFromLocalStorage,
  getItemFromLocalStorageWithParsing,
  isAuth,
  removeChosenProblemsFromStorage,
} from 'helpers/storage.helper'
import { IActions } from 'interfaces/actions.interface'
import IAppState from 'interfaces/app.interface'
import IProps from 'interfaces/props.interface'
import $ from 'jquery'
import isArray from 'lodash/isArray'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Redux from 'redux'
import { LOCALE_LANGUAGE, LOCALIZE } from 'redux/modules/localization.module'
import { ISchoolClasses } from 'redux/modules/schoolClasses.module'
import { IGroup } from '../../api/api.groups'
import { IClass, SourceType } from '../../api/types.classes'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import ReduxEffectorBind from '../../features/_ReduxEffectorBind/ReduxEffectorBind'
import UserIntercom from '../../features/userIntercom/UserIntercom'
import { addAuthHeader } from '../../helpers/interceptor.helper'
import { setLoggly } from '../../helpers/loggly.helper'
import './App.container.scss'
import { AppRoutes, SidebarRoutes } from './App.routes'
import ModalsComponent from './Modals.container'
import { mapDispatch2Props, mapState2Props } from './reduxProps'

export interface IAppProps extends IActions, IProps, Redux.AnyAction {}

export class App extends React.Component<IAppProps, IAppState> {
  public panelRef: HTMLElement

  constructor(props: IAppProps) {
    super(props)
    this.state = {
      userIntercom: null,
      loading: false,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IAppProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({
        prevLocation: this.props.location,
      })
    }
  }

  public UNSAFE_componentWillMount() {
    this.synchronizeTabs()
    this.authorization(this.props)
  }

  public synchronizeTabs() {
    window.addEventListener(Events.STORAGE, async (event) => {
      if (event.key === 'token') {
        addToken()
        this.props.history.push(PATHS.MAIN)
      } else if (event.key === null) {
        window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
      }
    })
  }

  public componentDidMount() {
    setLoggly()
    if (isAuth() && this.checkNotAuthPages(this.props.history.location.pathname)) {
      this.props.history.push(PATHS.MAIN)
    }
  }

  public UNSAFE_componentWillUpdate(nextProps: IAppProps) {
    $('body, html').animate({ scrollTop: 0 }).scrollTop(0)
    if (this.props.location!.pathname !== nextProps.location!.pathname) {
      this.authorization(nextProps)
      this.props.prevLocationActions.setPrevLocation(this.props.history.location.pathname)
    }
  }

  public componentDidUpdate(prevProps: IAppProps) {
    const isItCreatingProblemsFlow: boolean = this.props.location!.pathname.includes(
      PATHS.CONTENT.PROBLEMS
    )
    const changingLanguage: string = getLanguageShort(
      (this.props.localization.data as any).languageTxt
    )
    document.documentElement.setAttribute(localStorageKeys.language, changingLanguage)
    const defaultLang = config.APP_HOST_SWEDEN === config.APP_HOST ? LocaleData.SE : LocaleData.US
    if (this.props.auth?.data?.me !== undefined) {
      currentLocale.currentLang = this.props.auth?.data?.me?.setting?.appLanguage || defaultLang
    }
    if (
      currentLocale.currentLang &&
      (!Object.keys(this.props.localization.data).length ||
        isLanguageValidAndDifferent(
          (this.props.localization.data as any).languageTxt as LOCALIZE,
          currentLocale.currentLang as LocaleData
        ))
    ) {
      setLanguage(this.props.localizationActions)
    }
    addLocale()
    if (
      prevProps.classes.data?._embedded?.classes !== this.props.classes.data?._embedded?.classes ||
      prevProps.schoolClasses?.data?.schoolClasses !== this.props.schoolClasses?.data?.schoolClasses
    ) {
      this.setState({
        loading:
          this.props.classes.data?._embedded?.classes && isArray(this.props.schoolClasses?.data),
      })
    }

    if (!isItCreatingProblemsFlow) {
      localStorage.removeItem(localStorageKeys.section)
    }

    if (
      !(
        this.props.history.location.pathname.includes('solutions') ||
        this.props.history.location.pathname.includes('heatmap')
      )
    ) {
      removeChosenProblemsFromStorage()
    }

    if (this.props.history.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN) {
      this.panelRef.classList.add('without-scroll')
    } else {
      this.panelRef.classList.remove('without-scroll')
    }

    if (
      this.props.classes.data !== prevProps.classes.data ||
      this.props.groups.data !== prevProps.groups.data ||
      this.props.exercises !== prevProps.exercises
    ) {
      const unknownText: string = 'Unknown'
      const appUser: IMe = getItemFromLocalStorageWithParsing(localStorageKeys.me)
      if (!this.props.classes.loading && !this.props.exercises.loading) {
        const classesData: IClass[] = this.props.classes.data?._embedded?.classes || []
        const groupsData: IGroup[] = this.props.groups.data?._embedded?.groups || []
        const multiplicityData: (IClass | IGroup)[] = [...classesData, ...groupsData]
        const students = multiplicityData.flatMap((item: IClass | IGroup) => item.students)

        const filteredStudents = uniqBy(students, '_id')

        const studentsCount = filteredStudents?.length
        this.setState({
          userIntercom: {
            app_id: 'tjidhu4j',
            country: appUser.country ? appUser.country.name : unknownText,
            custom_launcher_selector: '#matteappen',
            email: appUser.email,
            municipality: appUser.municipality ? appUser.municipality.name : unknownText,
            name: appUser.fullName,
            school_id: appUser.school._id,
            school_name: appUser.school.name,
            user_id: appUser._id,
            classes_count: this.props.classes.data?.total || 0,
            exercises_count: this.props.exercises.data?.total || 0,
            students_count: studentsCount || 0,
            source_municipality: appUser.municipality?.source,
            source_user: appUser?.source,
          },
        })
      }
    }
  }

  public componentWillUnmount() {
    if ((window as any).Intercom) {
      ;(window as any).Intercom('shutdown')
    }
    caches.keys().then((names) => {
      for (const name of names) caches.delete(name)
    })
  }

  public getRedirectPath() {
    const appUser: IMe = getItemFromLocalStorageWithParsing(localStorageKeys.me)
    const municipalitySource = appUser?.municipality?.source
    const schoolClasses = this.props.schoolClasses
      ?.data as unknown as ISchoolClasses['schoolClasses'] // TODO: fix this.props.schoolClasses type here
    const classesData = this.props.classes.data?._embedded?.classes
    const isLoadingData = this.props.classes?.loading || this.props.schoolClasses?.loading
    if (isLoadingData) return '/'

    const linkClassPathName = getItemFromLocalStorage(localStorageKeys.redirectUrl)
    if (linkClassPathName) {
      localStorage.removeItem(localStorageKeys.redirectUrl)
      return linkClassPathName + SearchParam.SETTINGS_TAB
    }
    if (classesData) {
      const classes = classesData || []
      const classWithStudents = classes.find((classObj) => classObj.students.length)
      const classWithStudentsToSelect = classWithStudents && {
        _id: classWithStudents._id,
        name: classWithStudents.name,
        type: SelectedValueTypes.CLASS,
      }
      const isNoSchoolClassesNoClasses =
        (municipalitySource === undefined ||
          (municipalitySource === SourceType.matteappen && !schoolClasses?.length)) &&
        !classes.length

      if (isNoSchoolClassesNoClasses) {
        return '/students/classes/null?tab=students'
      }
      if (classWithStudentsToSelect) {
        return PATHS.EXERCISES.EXERCISES_MAIN
      }
      if (classes.length && !classWithStudents) {
        const emptyClassId = classes[0]?._id
        return emptyClassId
          ? `/students/classes/${emptyClassId}?tab=students`
          : '/students/classes/null?tab=students'
      }
      if (!classes.length) {
        return PATHS.CLASSES
      }
    }
    return '/'
  }

  public render() {
    const isNotEmptyPathname: boolean = this.props.history.location.pathname !== '/'
    const isExercisesPathname: boolean =
      this.props.history.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN
    const pageWithoutSidebar: boolean =
      detectPageWithoutSidebar(this.props.history) && !isExercisesPathname
    const isMainHeaderHidden: boolean = detectPageWithoutMainHeader(this.props.history)
    const isSidebarHidden = detectPageWithHiddenSidebar(this.props.history)
    return (
      <div className='App wrapper'>
        <ToastModel />
        <ReduxEffectorBind />
        <UserIntercom appID={config.INTERCOM_KEY} config={this.state.userIntercom} />
        <ModalsComponent {...this.props} />
        <div
          className={classnames('main-panel without-transform', {
            'full-width': !isAuth() || pageWithoutSidebar || isSidebarHidden,
          })}
          ref={(node: any) => (this.panelRef = node)}
        >
          {!isMainHeaderHidden && (
            <Header {...this.props} onTouchMove={(e: any) => e.preventDefault()} />
          )}
          {isAuth() && !pageWithoutSidebar && (
            <Sidebar {...this.props} routes={SidebarRoutes(this.props)} />
          )}
          <>
            <Switch>
              {AppRoutes(this.props).map((route: any, i: number) => {
                route.type = route.type || [User.teacher]
                return (
                  <Route
                    {...route}
                    key={i}
                    render={(props: any) =>
                      route.render({
                        ...this.props,
                        ...props,
                        prevLocation: this.state.prevLocation,
                      })
                    }
                    props={{
                      ...this.props,
                      prevLocation: this.state.prevLocation,
                    }}
                    path={route.path}
                  />
                )
              })}
              {((!isAuth() && !this.checkNotAuthPages(this.props.history.location.pathname)) ||
                !isNotEmptyPathname) &&
                this.state.loading &&
                this.props.classes.data?._embedded?.classes && (
                  <Redirect to={this.getRedirectPath()} />
                )}
              <Redirect to='/' />
            </Switch>
          </>
        </div>
      </div>
    )
  }

  private checkNotAuthPages(pathname: string) {
    const arrayFromNotAuthPathnames: string[] = [
      PATHS.LOGIN.LOGIN_MAIN,
      PATHS.LOGIN.SIGN_IN,
      PATHS.LOGIN.SIGN_UP,
      PATHS.LOGIN.LOGIN_PASSWORD_EMAIL,
      PATHS.LOGIN.LOGIN_PASSWORD_CONFIRMATION,
      PATHS.LOGIN.LOGIN_PASSWORD_CODE,
    ]

    return arrayFromNotAuthPathnames.includes(pathname)
  }

  private authorization(props: IAppProps) {
    const { history } = props
    const { pathname } = history.location
    const isCreateProblemsAddingPathname =
      this.props.history.location.pathname === PATHS.CONTENT.ADD_NEW_PROBLEM
    const appUser: IMe = getItemFromLocalStorageWithParsing(localStorageKeys.me)

    addAuthHeader()
    if (history.location.pathname !== PATHS.LOGIN.LOGIN_MAIN) {
      if (isAuth()) {
        if (pathname === PATHS.MAIN) {
          this.props.classesActions.getClasses()
          this.props.schoolClassesActions.getSchoolClasses(appUser.school._id)
        }
        if (
          pathname === PATHS.ACCOUNT ||
          pathname === PATHS.CONTENT.BOOKSERIES ||
          pathname === PATHS.PRACTICE.PRACTICE_MAIN
        ) {
          this.props.classesActions.getClasses()
          this.props.groupsActions.getGroups()
        }

        if (pathname === PATHS.CLASSES) {
          this.props.classesActions.getClasses()
          this.props.groupsActions.getGroups()
        }

        if (pathname.includes(PATHS.PARTS.STUDENTS)) {
          this.props.schoolClassesActions.getSchoolClasses(appUser.school._id)
        }

        if (!pathname.includes(PATHS.PARTS.SOLUTIONS) && !pathname.includes(PATHS.PARTS.HEATMAP)) {
          localStorage.removeItem(localStorageKeys.studentsOfExercise)
        }

        if (isCreateProblemsAddingPathname) {
          this.props.booksActions.getBooksForCreatingProblems(SelectedValueTypes.INITIALBOOKS)
        }

        if (pathname === PATHS.EXERCISES.EXERCISES_MAIN) {
          this.props.schoolClassesActions.getSchoolClasses(appUser.school._id)
        }
        if (
          pathname === PATHS.EXERCISES.EXERCISES_MAIN ||
          pathname === PATHS.EXERCISES.CREATE ||
          pathname.includes(PATHS.PARTS.COPY) ||
          pathname.includes('/students') ||
          (pathname.includes('/settings') && pathname.includes('/edit'))
        ) {
          this.props.classesActions.getClasses()
          this.props.groupsActions.getGroups()
          this.props.classesStudentsActions.getStudents()
          this.props.topicsActions.getTopics(currentLocale.currentLang)
        }
        if (!pathname.includes('exercises/add')) {
          deleteItemsFromLocalStorage()
        }

        if (isTeacherPathActive(pathname)) {
          this.props.teacherSchoolsActions.getSchoolsByTeacher({ teacherId: appUser?._id })
        }

        return props.authActions.getMe(props.history)
      }
      if (!isAuth() && !history.location.pathname.includes('reset-password')) {
        const classesRedirectRegex = /^\/students\/classes\/[0-9a-f]{24}\?tab=settings$/
        const UrlToTest = `${history.location.pathname}${history.location.search}`
        if (classesRedirectRegex.test(UrlToTest)) {
          localStorage.setItem(localStorageKeys.redirectUrl, UrlToTest)
        }

        window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
      }
    }
  }
}

function isLanguageValidAndDifferent(currentLanguage: LOCALIZE, locale: LocaleData) {
  return LOCALE_LANGUAGE[locale] && currentLanguage && currentLanguage !== LOCALE_LANGUAGE[locale]
}

export default connect(mapState2Props, mapDispatch2Props)(App)
