import { apiManager } from './apiManager'
import { AddNewPinResponse, AddPinParams, FetchPinsParams, Pin } from './types.pins'

export class pinsApi {
  public static readonly createNew = apiManager.post<AddNewPinResponse, AddPinParams>('pins')
  public static readonly getAll = apiManager.get<Pin[], FetchPinsParams>((params) => {
    if (params.userId) {
      return `pins/exercises/${params.exerciseId}/users/${params.userId}`
    }
    if (params.subProblemId) {
      return `pins/exercises/${params.exerciseId}/subproblems/${params.subProblemId}`
    }
    if (params.problemId) {
      return `pins/exercises/${params.exerciseId}/problems/${params.problemId}`
    }
    throw new Error('Invalid parameters for fetching pins')
  })

  public static readonly deleteSingle = apiManager.delete<void, string>('pins')
}
