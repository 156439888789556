import { $commonErrorFilterTask } from 'features/Solutions/CommonError/model/filterTask'
import { $commonErrorData } from 'features/Solutions/CommonError/model/commonErrorData'
import { combine } from 'effector'
import { $pinsData } from 'features/pinnedSolutions/model/pins'
import { $isPinsFilterActive } from 'features/pinnedSolutions/model/isPinsFilterActive'

export const $statsFilter = combine({
  pinsData: $pinsData,
  isPinsFilterActive: $isPinsFilterActive,
  commonErrorData: $commonErrorData,
  commonErrorFilterTask: $commonErrorFilterTask,
})

export type StatsFilter = typeof $statsFilter.defaultState
