import React from 'react'
import { SvgIconProps } from '../types/types'

const PenIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2361 0.504766C11.8414 -0.100546 12.8229 -0.100547 13.4282 0.504765L15.4968 2.57339C16.1021 3.17871 16.1021 4.16011 15.4968 4.76543L4.88258 15.3796C4.51689 15.7453 4.0209 15.9508 3.50373 15.9508H0.800781C0.386568 15.9508 0.0507812 15.615 0.0507812 15.2008L0.0507812 12.4978C0.0507812 11.9807 0.256227 11.4847 0.621923 11.119L11.2361 0.504766ZM12.3675 1.56543C12.348 1.5459 12.3163 1.5459 12.2968 1.56543L10.9928 2.86941L13.1322 5.00875L14.4361 3.70477C14.4557 3.68524 14.4557 3.65358 14.4361 3.63405L12.3675 1.56543ZM12.0715 6.06941L9.93215 3.93007L1.68258 12.1796C1.59819 12.264 1.55078 12.3785 1.55078 12.4978L1.55078 14.4508H3.50373C3.62307 14.4508 3.73753 14.4034 3.82192 14.319L12.0715 6.06941Z'
        fill={color}
      />
    </svg>
  )
}

export default PenIcon
