import React from 'react'
import { SvgIconProps } from '../types/types'

const PrinterIcon = ({ size = 20, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.0999 8.77166C1.85137 8.77166 1.6499 8.97313 1.6499 9.22166V14.7347C1.6499 14.9832 1.85137 15.1847 2.0999 15.1847H5.68904V13.5195H4.85643C4.44221 13.5195 4.10643 13.1837 4.10643 12.7695C4.10643 12.3553 4.44221 12.0195 4.85643 12.0195H15.9347C16.3489 12.0195 16.6847 12.3553 16.6847 12.7695C16.6847 13.1837 16.3489 13.5195 15.9347 13.5195H15.1021V15.1847H17.8999C18.1484 15.1847 18.3499 14.9832 18.3499 14.7347V9.22166C18.3499 8.97313 18.1484 8.77166 17.8999 8.77166H2.0999ZM15.1021 16.6847H17.8999C18.9769 16.6847 19.8499 15.8117 19.8499 14.7347V9.22166C19.8499 8.1447 18.9769 7.27166 17.8999 7.27166H2.0999C1.02295 7.27166 0.149902 8.1447 0.149902 9.22166V14.7347C0.149902 15.8117 1.02295 16.6847 2.0999 16.6847H5.68904V18.2999C5.68904 19.156 6.38299 19.8499 7.23904 19.8499H13.5521C14.4081 19.8499 15.1021 19.156 15.1021 18.2999V16.6847ZM13.6021 13.5195H7.18904V18.2999C7.18904 18.3275 7.21142 18.3499 7.23904 18.3499H13.5521C13.5797 18.3499 13.6021 18.3275 13.6021 18.2999V13.5195Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.05643 1.6499C5.8079 1.6499 5.60643 1.85137 5.60643 2.0999V5.64773C5.60643 6.06195 5.27064 6.39773 4.85643 6.39773C4.44221 6.39773 4.10643 6.06195 4.10643 5.64773V2.0999C4.10643 1.02295 4.97947 0.149902 6.05643 0.149902H12.285C12.749 0.149902 13.2026 0.287232 13.5886 0.544584L14.8469 1.38349C15.5007 1.81933 15.8934 2.55308 15.8934 3.33881V5.64773C15.8934 6.06195 15.5576 6.39773 15.1434 6.39773C14.7292 6.39773 14.3934 6.06195 14.3934 5.64773V3.33881C14.3934 3.05461 14.2514 2.78921 14.0149 2.63156L12.7565 1.79266C12.6169 1.69957 12.4528 1.6499 12.285 1.6499H6.05643Z'
        fill={color}
      />
      <path
        d='M17.5173 10.3956C17.5173 10.8326 17.163 11.1869 16.726 11.1869C16.289 11.1869 15.9347 10.8326 15.9347 10.3956C15.9347 9.95855 16.289 9.60427 16.726 9.60427C17.163 9.60427 17.5173 9.95855 17.5173 10.3956Z'
        fill={color}
      />
    </svg>
  )
}

export default PrinterIcon
