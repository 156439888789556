import { ButtonLoader } from 'components/ButtonLoader/ButtonLoader.component'
import { PATHS } from 'config/pathnames.config'
import { KeyCode } from 'helpers/enums'
import { validateCodeForm } from 'helpers/synchValidation.helpers'
import * as React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { IAuth } from 'redux/modules/auth.module'
import { IState } from '../../../../interfaces/state.interface'
import { renderInputField } from '../../../../helpers/form.helpers'
import { asyncValidateUsernameReverse } from '../../../../helpers/asynchValidation.helpers'
import '../RegisterForms.scss'
import { ILocalization } from "../../../../config/languages.config";

// tslint:disable-next-line:interface-name
interface InputEmailProps extends InjectedFormProps<FormData, any> {
  history: any
  localization: ILocalization
  onSubmit: (values: any, history: any) => {}
  serverErrors?: { message: string }
  auth: IState<IAuth>
  accountActions: IState<any>
}

class ChangePasswordFormComponent extends Component<InputEmailProps, any> {
  public returnNextPage = () => {
    const isValidValuesInForm: boolean = this.props.pristine && this.props.valid
    return {
      pathname: isValidValuesInForm ? '' : PATHS.LOGIN.LOGIN_PASSWORD_CONFIRMATION,
    }
  }

  public handleKeyDown = (event: any, submit: any) => {
    if (event.keyCode === KeyCode.ENTER) {
      this.props.history.push(this.returnNextPage())
    }
  }

  public render() {
    const { handleSubmit, invalid, localization, pristine, valid } = this.props
    const { inputCodeIntrodution, submitTxt, userNameTxt, codeTxt, backTxt } = localization.data

    const submit = (values: any) => {
      if (valid) {
        this.props.onSubmit(values, this.props.history)
      }
      return
    }

    return (
      <div>
        <form
          onSubmit={handleSubmit(submit)}
          onKeyDown={(event: any) => this.handleKeyDown(event, submit)}
          className='register-form'
        >
          <p className='register-form-description'>{inputCodeIntrodution}</p>
          <div className='form-group register-form-group'>
            <Field
              name='username'
              component={renderInputField as any}
              type='text'
              required={true}
              placeholder={userNameTxt}
              className='form-control'
              id='username'
            />
          </div>
          <div className='form-group register-form-group'>
            <Field
              name='code'
              component={renderInputField as any}
              type='text'
              required={true}
              placeholder={codeTxt}
              className='form-control'
              id='code'
            />
          </div>
          <div className='buttons-wrapper'>
            <button
              className='back-button'
              type='button'
              onClick={() => {
                this.props.history.push('/login/reset-password/email')
              }}
            >
              {backTxt}
            </button>
            <ButtonLoader
              autoFocus={true}
              className='confirm-button button-with-link-inside'
              loading={this.props.accountActions.loading}
              disabled={pristine || invalid}
            >
              <Link
                className='link-inside-button'
                to={{
                  pathname: pristine ? '' : PATHS.LOGIN.LOGIN_PASSWORD_CONFIRMATION,
                }}
              >
                {' '}
                {submitTxt}
              </Link>
            </ButtonLoader>
          </div>
        </form>
      </div>
    )
  }
}

const InputCodeForm: any = reduxForm({
  asyncValidate: asyncValidateUsernameReverse,
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: 'resetPassword',
  validate: validateCodeForm,
})(ChangePasswordFormComponent as any)

export default InputCodeForm
