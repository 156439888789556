import React from 'react'
import { COLORS } from '@magmamath/ui'
import { SvgIconProps } from '../types/types'

const CheckBoxCheckedBackgroundIcon = ({ color, size = 25 }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8 0C14.5673 0 16 1.43269 16 3.2V12.8C16 14.5673 14.5673 16 12.8 16H3.19998C1.43267 16 -1.52588e-05 14.5673 -1.52588e-05 12.8V3.2C-1.52588e-05 1.43269 1.43267 0 3.19998 0H12.8Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3027 5.45985C11.588 5.70123 11.6235 6.12816 11.3822 6.41343L8.0189 10.3882C7.69297 10.7734 7.1108 10.8068 6.74292 10.4615L4.67121 8.51659C4.39876 8.26082 4.38524 7.83263 4.641 7.56018C4.89677 7.28774 5.32496 7.27422 5.59741 7.52998L7.30684 9.13475L10.3491 5.53932C10.5905 5.25405 11.0174 5.21848 11.3027 5.45985Z'
        fill={COLORS.NEUTRAL_1}
      />
      <rect
        x='0.68'
        y='0.68'
        width='14.64'
        height='14.64'
        rx='2.52'
        stroke={color}
        strokeWidth='1.36'
      />
    </svg>
  )
}

export default CheckBoxCheckedBackgroundIcon
