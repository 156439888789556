import React from 'react'
import styles from './styles.module.scss'
import { useHistory } from 'react-router'
import ExitButton from '../../components/basic/Buttons/ExitButton/ExitButton'

export default function CloseDrawBoardButton() {
  const history = useHistory()

  function onPress() {
    history.goBack()
  }

  return <ExitButton onPress={onPress} className={styles.CloseButton} />
}
