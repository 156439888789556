import { HeatmapTabsParams, LayoutIds } from 'helpers/enums'
import { getTeacherData } from 'helpers/user.helpers'
import useText from 'i18n/hook'
import { IState } from 'interfaces/state.interface'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { ISkills } from 'redux/modules/skills.module'
import { getSkillsStats } from 'redux/modules/skillsStat.module'
import { RootState } from 'store/store'
import UITab from 'ui/tabs/UITab/UITab'
import UITabs from 'ui/tabs/UITabs/UITabs'
import styles from './HeatmapMenuTabs.module.scss'
import { getTabs } from './helpers'

type HeatmapMenuTabsProps = {
  activeTab: HeatmapTabsParams | null
}

type ExerciseRouteParams = {
  exerciseId?: string
}

const HeatmapMenuTabs = ({ activeTab }: HeatmapMenuTabsProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const t = useText()
  const { exerciseId } = useParams<ExerciseRouteParams>()
  const teacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))

  const skillsStats = useSelector((state: RootState) => state.skillsStats)
  const exerciseWithoutStats: IState<IExerciseWithoutStats> = useSelector(
    (state: RootState) => state.exerciseWithoutStats
  )
  const hasSkillsData = !!(
    skillsStats?.data?.studentsStats?.skills?.length > 1 &&
    Object.values(skillsStats?.data).some((skill: ISkills) => 'exerciseId' in skill)
  )
  const hasExerciseWithoutStatsData = !!(
    exerciseWithoutStats.data?._id && exerciseWithoutStats.data?.students?.length
  )

  useEffect(() => {
    if (exerciseId) {
      dispatch(getSkillsStats(exerciseId))
    }
  }, [exerciseId])

  const hasOldSkills = hasSkillsData && hasExerciseWithoutStatsData
  const isSkillsVisible = !!(teacher?.treeId ? exerciseWithoutStats.data.hasSkills : hasOldSkills)

  return (
    <UITabs layoutId={LayoutIds.HEATMAP_TABS} withDividerBottom={true} className={styles.TabsList}>
      {getTabs(hasOldSkills, isSkillsVisible).map((tab) => {
        if (!tab.show) return null
        return (
          <UITab
            className={styles.Tab}
            key={tab.id}
            isActive={activeTab === tab.id}
            title={tab.getTitle(t)}
            onClick={() => {
              history.push(`/exercises/${exerciseId}/${tab.tabParam}`)
            }}
            layoutId={LayoutIds.HEATMAP_TABS}
          />
        )
      })}
    </UITabs>
  )
}

export default HeatmapMenuTabs
