import { ClassesStoreItem, Group, Student } from './types'
import { ICollectionId } from 'redux/modules/choosing.stud.module'
import { IStudent } from 'api/types.users'
import { IExercise } from '../../../api/types.assignments'
import { ClassroomGroup, IClass } from '../../../api/types.classes'

export const getClassroomInitStoresData = (classroom: IClass, selectionState: boolean) => {
  const initialClass = {
    id: classroom._id,
    name: classroom.name,
    groupIds: classroom.groups.map((group) => group._id),
    studentIds: classroom.students.map((student) => student._id),
    selected: selectionState,
  }
  const initialGroups = classroom.groups.map((group) => ({
    id: group._id,
    name: group.name,
    classId: classroom._id,
    studentIds: group.students.map((student) => student._id),
    selected: selectionState,
  }))
  const initialStudents = classroom.students.map((student) => ({
    id: student._id,
    firstName: student.firstName,
    lastName: student.lastName,
    classId: classroom._id,
    selected: selectionState,
  }))

  return {
    initialClass,
    initialGroups,
    initialStudents,
  }
}

export const getNumbersOfSelectedStudents = ({
  maxStudents,
  isWholeClassSelected,
  selectedNotEmptyGroups,
  selectedSingleStudents,
}: {
  maxStudents: number
  isWholeClassSelected: boolean
  selectedNotEmptyGroups: Group[]
  selectedSingleStudents: Student[]
}) => {
  if (isWholeClassSelected) return maxStudents

  const groupsStudentsIds = selectedNotEmptyGroups.reduce<string[]>(
    (acc, group) => [...acc, ...group.studentIds],
    []
  )
  const selectedSingleStudentsIds = selectedSingleStudents.map((student) => student.id)
  const uniqueStudentsIds = [...new Set([...groupsStudentsIds, ...selectedSingleStudentsIds])]

  return uniqueStudentsIds.length
}

export const formatClassesStoreToCollectionId = (classesStore: ClassesStoreItem[]) => {
  let selectedClassesCount = 0
  const initialState: ICollectionId['data'] = {
    classesIds: [],
    groupsIds: [],
    studentsIds: [],
  }

  const collectionId = classesStore.reduce((result, cls) => {
    if (cls.classStore?.selected) {
      result.classesIds.push(cls.classStore.id)
      selectedClassesCount++
    } else {
      let selected = false
      const selectedGroups = cls.groupsStore.filter((group) => group.selected)
      const selectedStudents = cls.studentsStore.filter((student) => student.selected)

      if (selectedGroups.length > 0) {
        result.groupsIds.push(...selectedGroups.map((group) => group.id))
        selected = true
      }

      if (selectedStudents.length > 0) {
        result.studentsIds.push(...selectedStudents.map((student) => student.id))
        selected = true
      }

      const classId = cls.classStore?.id
      if (selected && classId) {
        result.classesIds.push(classId)
        selectedClassesCount++
      }
    }

    return result
  }, initialState)

  return { selectedClassesCount, collectionId }
}

export const mapClassesDataToClassesStore = ({
  availableClassesWithGroups,
  exerciseData,
}: {
  availableClassesWithGroups: IClass[]
  exerciseData: IExercise
}) =>
  availableClassesWithGroups.map((classObject) => _mapClassToClassStore(classObject, exerciseData))

const _mapClassToClassStore = (classObject: IClass, exerciseData: IExercise) => {
  const isClassSelected =
    exerciseData.allClassroomStudents &&
    exerciseData.classes.some((selectedClass) => selectedClass._id === classObject._id)

  const classStore: ClassesStoreItem = {
    id: classObject._id,
    classStore: {
      id: classObject._id,
      name: classObject.name,
      groupIds: classObject.groups.map((group) => group._id),
      studentIds: classObject.students.map((student) => student._id),
      selected: isClassSelected,
    },
    groupsStore: classObject.groups.map((group) =>
      _mapGroupToGroupStore(group, classObject._id, exerciseData, isClassSelected)
    ),
    studentsStore: classObject.students.map((student) =>
      _mapStudentToStudentStore(student, classObject._id, exerciseData, isClassSelected)
    ),
  }

  return classStore
}

const _mapGroupToGroupStore = (
  group: ClassroomGroup,
  classId: string,
  exerciseData: IExercise,
  isClassSelected: boolean
) => {
  const isGroupSelectedByGroups = exerciseData.groups.some(
    (selectedGroup) => selectedGroup.classroomId === classId && selectedGroup._id === group._id
  )

  return {
    id: group._id,
    name: group.name,
    classId,
    studentIds: group.students.map((student) => student._id),
    selected: isClassSelected || isGroupSelectedByGroups,
  }
}

const _mapStudentToStudentStore = (
  student: IStudent,
  classId: string,
  exerciseData: IExercise,
  isClassSelected: boolean
) => {
  const isStudentSelectedByGroups = exerciseData.groups
    .filter((selectedGroup) => selectedGroup.classroomId === classId)
    .some((selectedGroup) =>
      selectedGroup.students.some((selectedStudent) => selectedStudent._id === student._id)
    )

  const isStudentSelectedByStudents = exerciseData.students.some(
    (selectedStudent) => selectedStudent._id === student._id
  )

  return {
    id: student._id,
    firstName: student.firstName,
    lastName: student.lastName,
    classId,
    selected: isClassSelected || isStudentSelectedByGroups || isStudentSelectedByStudents,
  }
}

export const getClassroomSelectionInfo = ({
  classSelection,
  classObject,
}: {
  classSelection?: ClassesStoreItem
  classObject: IClass
}) => {
  const selectedSingleStudents =
    classSelection?.studentsStore?.filter((studentStore) => studentStore.selected) ?? []
  const selectedNotEmptyGroups =
    classSelection?.groupsStore?.filter(
      (groupsStore) => groupsStore.selected && groupsStore.studentIds.length
    ) ?? []
  const classroomStudentsCount = classObject.students.length
  const isWholeClassSelected = classSelection?.classStore?.selected ?? false
  const hasSelectedStyles = !!(
    isWholeClassSelected ||
    selectedNotEmptyGroups.length ||
    selectedSingleStudents.length
  )

  const selectedStudentsCount = getNumbersOfSelectedStudents({
    maxStudents: classroomStudentsCount,
    isWholeClassSelected,
    selectedNotEmptyGroups,
    selectedSingleStudents,
  })

  return {
    hasSelectedStyles,
    selectedStudentsCount,
    classroomStudentsCount,
  }
}
