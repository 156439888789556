import { apiManager } from './apiManager'
import {
  IProblem,
  ProblemBreadcrumb,
  ProblemElasticSearchPayload,
  ProblemElasticSearchResponse,
} from './types.problem'

type CreateNewProblemPayload = {
  contentId: string
  order: number
  level: number
}

type CreateNewProblemResponse = {
  problem: IProblem
}

type SingleProblemPayload = {
  id: string
  params?: {
    locale?: string
  }
}

type ManyProblemsPayload = {
  ids: string
  fetchAll?: 0 | 1
}

type ManyProblemsResponse = {
  _embedded: {
    problems: IProblem[]
  }
  total: number
  pages: number
}

type UpdateSingleProblemPayload = {
  id: string
  data: {
    contentId?: string
    level?: number
  }
}

type UpdateSingleProblemResponse = {}
type DeleteSingleProblemResponse = {}

export class problemsApi {
  public static readonly getBreadcrumbs = apiManager.get<ProblemBreadcrumb[], string>(
    (problemId) => `problems/${problemId}/breadcrumbs`
  )
  public static readonly createNew = apiManager.post<
    CreateNewProblemResponse,
    CreateNewProblemPayload
  >('problems')

  public static readonly getSingle = apiManager.get<IProblem, SingleProblemPayload>(
    ({ id, params }) => ({
      url: `problems/${id}`,
      params,
    })
  )

  public static readonly getMany = apiManager.get<ManyProblemsResponse, ManyProblemsPayload>(
    'problems'
  )

  public static readonly updateSingle = apiManager.put<
    UpdateSingleProblemResponse,
    UpdateSingleProblemPayload
  >(({ id, data }) => ({
    url: `problems/${id}`,
    body: data,
  }))

  public static readonly deleteSingle = apiManager.delete<DeleteSingleProblemResponse, string>(
    'problems'
  )

  public static readonly elasticSearch = apiManager.get<
    ProblemElasticSearchResponse[],
    ProblemElasticSearchPayload
  >('problems-elastic/search')
}
