import React from 'react'
import styles from '../../../screens/Students/Students.module.scss'
import { getSyncLevel, SyncLevel } from '../../../helpers/classesView.helpers'
import CreatingClassCard from '../../../ui/cards/CreatingClassCard/CreatingClassCard'
import NoDataText from '../../../views/Classes/components/NoDataText/NoDataText'
import { IMe } from '../../../api/types.users'

import { ILocalization } from "../../../config/languages.config";

interface INoContentStudentsScreen {
  me: IMe
  localization: ILocalization
}

const NoContentStudentsScreen: React.FC<INoContentStudentsScreen> = ({ me, localization }) => {
  return (
    <div className={styles.CreateClassWrapper}>
      {SyncLevel.NO_SYNC === getSyncLevel(me) ? (
        <CreatingClassCard
          localization={localization}
          me={me}
          schoolId={me.school._id}
          headerTitle={localization.data.newClassTxt}
          skipRedirectOnClassCreation={true}
        />
      ) : (
        <NoDataText text={localization.data.youCantCreateClass} />
      )}
    </div>
  )
}

export default NoContentStudentsScreen
