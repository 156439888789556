import { useStoreMap, useUnit } from 'effector-react'
import { resetReorderProblemStores } from 'features/bookshelf/ReorderProblems/model/helpers'
import { resetErrorMessages } from 'features/caf/errorMessages/model/errorMessages'
import { initExercise } from 'features/caf/exerciseSettings/model/exerciseSettings'
import { cleanCafStoresData } from 'features/caf/helpers'
import ExerciseDetailsSkeleton from 'features/exerciseDetails/ExerciseDetailsSkeleton'
import { getSortedClassrooms } from 'features/exerciseDetails/helpers'
import { $exercises, $isExerciseDataPending } from 'features/exerciseDetails/model/exercises'
import { LoadingStatus } from 'helpers/enums'
import { filterDeletedProblems } from 'helpers/problems.helpers'
import usePageReloadDetector from 'lib/hooks/usePageReloadDetector'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { RootState } from 'store/store'
import { resetChapters } from '../../features/bookshelf/Book/model/chapters'
import { fetchProblemsBreadcrumbsFx } from '../../features/bookshelf/Book/model/requests'
import { resetSectionPaths } from '../../features/bookshelf/Book/model/sectionPaths'
import { resetSections } from '../../features/bookshelf/Book/model/sections'
import { resetSelectedProblems } from '../../features/bookshelf/Book/model/selectedProblems'
import { resetSearchIsActive } from '../../features/bookshelf/model/foundBooks'
import { resetMyBooks } from '../../features/bookshelf/model/myBooks'
import ExerciseDetails from '../../features/exerciseDetails/ExerciseDetails'
import { ExerciseRouteParams } from '../../features/exerciseDetails/types'
import useText from '../../i18n/hook'

const CopyExerciseView = () => {
  const [breadcrumbsLoadingState, setBreadcrumbsLoadingState] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  )
  const t = useText()
  const isReloaded = usePageReloadDetector()
  const { exerciseId } = useParams<ExerciseRouteParams>()
  const exercise = useStoreMap({
    store: $exercises,
    keys: [exerciseId],
    fn: (exercises, [exerciseId]) => exercises.get(exerciseId),
  })
  const isExerciseDataPending = useUnit($isExerciseDataPending)

  const allClasses = useSelector((state: RootState) => state.classes)
  const groups = useSelector((state: RootState) => state.groups)

  const classrooms = useMemo(
    () => getSortedClassrooms(allClasses.data?._embedded?.classes, groups?.data?._embedded?.groups),
    [allClasses.data?._embedded?.classes, groups?.data?._embedded?.groups]
  )

  useEffect(() => {
    if (!isReloaded) {
      cleanCafStoresData()
    }
    initExercise(exerciseId).then((exercise) => {
      setBreadcrumbsLoadingState(LoadingStatus.LOADING)
      const nonDeletedProblemIds = filterDeletedProblems(exercise.problems).map(
        (problem) => problem._id
      )
      fetchProblemsBreadcrumbsFx(nonDeletedProblemIds).finally(() => {
        setBreadcrumbsLoadingState(LoadingStatus.LOADED)
      })
    })

    return () => {
      resetChapters()
      resetSectionPaths()
      resetSelectedProblems()
      resetSections()
      resetMyBooks()
      resetErrorMessages()
      resetSearchIsActive()
      resetReorderProblemStores()
    }
  }, [isReloaded])

  if (breadcrumbsLoadingState !== LoadingStatus.LOADED || isExerciseDataPending) {
    return <ExerciseDetailsSkeleton submitButtonText={t.createTxt} />
  }

  return <ExerciseDetails classrooms={classrooms} exercise={exercise} isDuplicateExercise />
}

export default CopyExerciseView
