import { IDispatch } from 'interfaces/dispatch.interface'
import { IAction } from '../../interfaces/actions.interface'
import { getClass } from './class.module'
import { checkNetworkError, handleNetworkError } from '../../helpers/modules.helpers'
import { NOTIFY } from './notify.module'
import { NotifyType } from '../../helpers/enums'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'

export enum LANGUAGE_SETTINGS {
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_TEXT_SIZE = 'SET_TEXT_SIZE',
  SET_SOUND_STATE = 'SET_SOUND_STATE',
  SET_ALL_SETTINGS = 'SET_ALL_SETTINGS',
  CLEAR_ALL_SETTINGS = 'CLEAR_ALL_SETTINGS',
  LANGUAGE_SETTINGS_REQUEST = 'LANGUAGE_SETTINGS_REQUEST',
  LANGUAGE_SETTINGS_SUCCESS = 'LANGUAGE_SETTINGS_SUCCESS',
  LANGUAGE_SETTINGS_ERROR = 'LANGUAGE_SETTINGS_ERROR',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function languageSettingsReducer(
  state: any = initialState,
  action: IAction<LANGUAGE_SETTINGS>
) {
  switch (action.type) {
    case LANGUAGE_SETTINGS.SET_LANGUAGE:
      return {
        ...state,
        data: {
          ...state.data,
          language: action.payload.language,
        },
      }
    case LANGUAGE_SETTINGS.SET_TEXT_SIZE:
      return {
        ...state,
        data: {
          ...state.data,
          textSize: action.payload.textSize,
        },
      }
    case LANGUAGE_SETTINGS.SET_SOUND_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          soundState: action.payload.soundState,
        },
      }
    case LANGUAGE_SETTINGS.SET_ALL_SETTINGS:
      return {
        ...state,
        data: {
          ...action.payload,
        },
      }
    case LANGUAGE_SETTINGS.CLEAR_ALL_SETTINGS:
      return initialState
    case LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_SUCCESS: {
      return initialState
    }
    default:
      return state
  }
}

export function changeTranslationLanguage(language: string | null) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: LANGUAGE_SETTINGS.SET_LANGUAGE,
      payload: { language },
    })
  }
}

export function changeTextSize(textSize: number) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: LANGUAGE_SETTINGS.SET_TEXT_SIZE,
      payload: { textSize },
    })
  }
}

export function changeSoundState(soundState: boolean) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: LANGUAGE_SETTINGS.SET_SOUND_STATE,
      payload: { soundState },
    })
  }
}

export function setStudentSettings(language: string | null, textSize: number, soundState: boolean) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: LANGUAGE_SETTINGS.SET_ALL_SETTINGS,
      payload: { language, textSize, soundState },
    })
  }
}

export function clearAllSettings() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: LANGUAGE_SETTINGS.CLEAR_ALL_SETTINGS })
  }
}

export function pushStudentSettings(
  language: string | null,
  textSize: number,
  soundState: boolean,
  id: string,
  localization: ILocalization,
  classId: string
) {
  const { changesSavedTxt } = localization.data
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_REQUEST })
      await api.users.updateStudentSettings({
        studentId: id,
        data: {
          translationLanguage: language,
          textSize,
          textToSpeech: soundState,
        },
      })
      dispatch({ type: LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_SUCCESS })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: changesSavedTxt, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })

      getClass(classId)(dispatch)
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response
              ? { message: error.response.data.message }
              : { message: error.message },
            type: LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_ERROR,
          })
        },
        () => handleNetworkError(LANGUAGE_SETTINGS.LANGUAGE_SETTINGS_ERROR, error, dispatch)
      )
    }
  }
}

export interface ILanguageSettingsActions {
  changeTranslationLanguage: (language: string | null) => void
  changeTextSize: (textSize: number) => void
  changeSoundState: (soundState: boolean) => void
  setStudentSettings: (language: string | null, textSize: number, soundState: boolean) => void
  clearAllSettings: () => void
  pushStudentSettings: (
    language: string | null,
    textSize: number,
    soundState: boolean,
    id: string,
    localization: ILocalization,
    classId: string
  ) => void
}

export interface ILanguageSettings {
  data: {
    language: string | null
    textSize: number
    soundState: boolean
  }
}
