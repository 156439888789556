import { DrawBoardPreviewPreset } from './types'
import { useEffect } from 'react'
import { previewPersistModule } from './model.persist'
import { useObservable } from '@magmamath/react-native-draw-board'

export const usePlayableHistorySettings = (preset: DrawBoardPreviewPreset) => {
  const historySettings = useObservable(preset.context.$historySettings)

  useEffect(() => {
    const model = preset.model
    const unwatchLoad = previewPersistModule.load.watch(() => {
      preset.context.$historySettings.setValue(null)
    })

    const unwatchLFail = previewPersistModule.load.fail.watch((e) => {
      if (previewPersistModule.getCurrentInstance() !== e.params.instance) return
      preset.context.$historySettings.setValue({
        isExists: false,
        anchorsCount: 0,
        isEmpty: true,
      })
    })

    const unwatchImport = model.import.done.watch(() => {
      preset.context.$historySettings.setValue({
        isExists: true,
        anchorsCount: model.cache.anchors.list.length,
        isEmpty: !model.cache.history.length,
      })
      preset.model.historyActions.toAnchor({ offset: preset.context.attemptOffsetRef })
    })

    return () => {
      unwatchLFail()
      unwatchImport()
      unwatchLoad()
      previewPersistModule.setCredits(null)
    }
  }, [preset])

  return historySettings
}
