import { createEvent, createStore } from 'effector'
import { ISection } from '../../../../api/types.books'
import { fetchSectionsFx } from './requests'

export const resetSections = createEvent()

export const $sections = createStore<Map<string, ISection[]>>(new Map())
  .on(fetchSectionsFx.doneData, (state, { chapterId, sections }) => {
    const newState = new Map(state)
    newState.set(chapterId, sections)
    return newState
  })
  .reset(resetSections)
