import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { api } from '../../api'
import { GetPracticeResultsPayload } from '../../api/types.statistics'

export enum PRACTICE_RESULTS {
  RESULTS_SUCCESS = 'RESULTS_SUCCESS',
  PRACTICE_RESULTS_REQUEST = 'PRACTICE_RESULTS_REQUEST',
  PRACTICE_RESULTS_ERROR = 'PRACTICE_RESULTS_ERROR',
  LAST_USER_SOLUTION_SUCCESS = 'LAST_USER_SOLUTION_SUCCESS',
  LAST_USER_SOLUTION_ERROR = 'LAST_USER_SOLUTION_ERROR',
}

const initialState = {
  results: {},
  lastUserSolution: null,
  error: null,
  loading: false,
}

export function practiceResultsReducer(
  state: any = initialState,
  action: IAction<PRACTICE_RESULTS>
) {
  switch (action.type) {
    case PRACTICE_RESULTS.PRACTICE_RESULTS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PRACTICE_RESULTS.RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      }
    case PRACTICE_RESULTS.PRACTICE_RESULTS_ERROR:
      return {
        ...state,
        results: {},
        loading: false,
        error: action.payload,
      }
    case PRACTICE_RESULTS.LAST_USER_SOLUTION_SUCCESS:
      return {
        ...state,
        lastUserSolution: action.payload,
        loading: false,
      }
    case PRACTICE_RESULTS.LAST_USER_SOLUTION_ERROR:
      return {
        ...state,
        lastUserSolution: null,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getPracticeResults = (props: GetPracticeResultsPayload) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PRACTICE_RESULTS.PRACTICE_RESULTS_REQUEST })
      const response = await api.statistics.getPracticeResults(props)
      dispatch({ type: PRACTICE_RESULTS.RESULTS_SUCCESS, payload: response })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: PRACTICE_RESULTS.PRACTICE_RESULTS_ERROR,
      })
    }
  }
}

export const getLastUserSolution = (abilityId: string, userId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PRACTICE_RESULTS.PRACTICE_RESULTS_REQUEST })
      const response = await api.oldPractice.getLastUserSolutions({ abilityId, userId })
      dispatch({ type: PRACTICE_RESULTS.LAST_USER_SOLUTION_SUCCESS, payload: response })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: PRACTICE_RESULTS.LAST_USER_SOLUTION_ERROR,
      })
    }
  }
}

export interface IPracticeResultsActions {
  getPracticeResults: ({ abilityId, urlType, filterOptionId }: GetPracticeResultsPayload) => void
  getLastUserSolution: (abilityId: string, userId: string) => void
}
