import { PATHS } from 'config/pathnames.config'
import { ContentContainer } from 'containers/Content/Content.container'
import { ClassesContainer } from 'containers/Classes/Classes.container'
import { anyTypes, checkRole } from 'helpers/route.helpers'
import * as React from 'react'
import clsx from 'clsx'
import Forbidden from 'views/Error/Forbidden/Forbidden.view'
import Login from '../../views/Login/login.view'
import { AccountContainer } from '../Account/Account.container'
import { ExercisesContainer } from '../Exercises/Exercises.container'
import { ProgressContainer } from '../Progress/Progress.container'
import { StudentsContainer } from '../Students/Students.container'
import { PracticeContainer } from '../Practice/Practice.container'
import { config } from '../../config'

export const SidebarRoutes = (mainProps: any): any[] => {
  return [
    {
      className: 'exercise-link',
      exact: true,
      name: mainProps.localization.data.exercisesTxt,
      path: PATHS.EXERCISES.EXERCISES_MAIN,
      render: (props: any) => checkRole(ExercisesContainer, props),
    },
    {
      className: clsx('practice-link', {
        hidden:
          config.APP_HOST === config.APP_HOST_INTERNATIONAL &&
          !mainProps.auth.data?.me?.setting?.practiceMode,
      }),
      exact: true,
      name: mainProps.localization.data.practiceTxt,
      path: PATHS.PRACTICE.PRACTICE_MAIN,
      render: (props: any) => checkRole(PracticeContainer, props),
    },
    {
      className: 'students-link',
      exact: true,
      name: mainProps.localization.data.studentsTxt,
      path: '/students',
      render: (props: any) => checkRole(StudentsContainer, props),
    },
    {
      className: 'content-link',
      exact: true,
      name: mainProps.localization.data.contentMaterialTxt,
      path: PATHS.CONTENT.BOOKSERIES,
      render: (props: any) => checkRole(ContentContainer, props),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.exercisesTxt,
      path: PATHS.EXERCISES.EXERCISES_MAIN,
      render: (props: any) => <ExercisesContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.accountTxt,
      path: '/profile',
      render: (props: any) => <AccountContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.schoolTxt,
      path: PATHS.CLASSES,
      render: (props: any) => <ClassesContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.startTxt,
      path: '/start',
      render: (props: any) => <AccountContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      path: '/student-progress/:id',
      render: (props: any) => <ProgressContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.studentsTxt,
      path: '/students',
      render: (props: any) => <StudentsContainer {...props} />,
      type: anyTypes(),
    },
    {
      hideInSidebar: true,
      name: mainProps.localization.data.createProblemsTxt,
      path: PATHS.CONTENT.PROBLEMS,
      render: (props: any) => <ContentContainer {...props} />,
      type: anyTypes(),
    },
  ]
}

export function AppRoutes(mainProps: any): any[] {
  return SidebarRoutes(mainProps).concat([
    {
      hideInSidebar: true,
      path: '/login',
      render: (props: any) => <Login {...props} />,
      type: anyTypes(),
    },
    {
      path: '/error/forbidden',
      render: (props: any) => <Forbidden {...props} />,
      type: anyTypes(),
    },
  ])
}
