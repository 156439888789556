import React from 'react'
import StudentsTableHeader from '../StudentsTable/StudentsTableHeader/StudentsTableHeader'
import StudentsTable from '../StudentsTable/StudentsTable'
import styles from '../../../screens/Students/Students.module.scss'
import ChangeMultiplicityAccess from '../ChangeMultiplicityAccess/ChangeMultiplicityAccess'
import ChangeMultiplicityInfo from '../ChangeClassInfo/ChangeClassInfo'
import { StudentsTab } from '../../../screens/Students/Students'
import { IModalActions } from '../../../redux/modules/modal.module'
import { IMe, IStudent, ITeacher } from '../../../api/types.users'
import { RouteComponentProps } from 'react-router'
import ChangeMultiplicitySettings from '../ChangeMultiplicitySetttings/ChangeMultiplicitySettings'
import GroupsTabContent from 'views/Classes/components/GroupsTabContent/GroupsTabContent'
import { ILocalization } from '../../../config/languages.config'
import { IClass } from '../../../api/types.classes'
import { ISelectedOption } from 'redux/modules/filterExercises.module'
import { IGroup } from '../../../api/api.groups'

interface IMultiplicityTableContainerProps {
  classOrGroup: IClass | IGroup | null
  activeTab: StudentsTab | null
  modalActions: IModalActions
  me: IMe
  teachers: ITeacher[]
  history: RouteComponentProps['history']
  localization: ILocalization
  selectedOption: ISelectedOption
}

const MultiplicityTableContainer: React.FC<IMultiplicityTableContainerProps> = ({
  classOrGroup,
  activeTab,
  modalActions,
  me,
  history,
  localization,
  teachers,
  selectedOption,
}) => {
  if (!(classOrGroup && selectedOption)) {
    return null
  }

  const getSortedStudents = (arr: IStudent[]) => {
    return [...arr].sort((a, b) => a.lastName.localeCompare(b.lastName))
  }

  return (
    <>
      {activeTab === StudentsTab.MULTIPLICITY && (
        <>
          <StudentsTableHeader
            classOrGroup={classOrGroup}
            modalActions={modalActions}
            me={me}
            history={history}
            localization={localization}
          />
          <StudentsTable
            students={getSortedStudents(classOrGroup?.students)}
            me={me}
            modalActions={modalActions}
            history={history}
            localization={localization}
            classId={'invitationalCode' in classOrGroup ? classOrGroup._id : undefined}
            groupId={!('invitationalCode' in classOrGroup) ? classOrGroup._id : undefined}
            classOrGroup={classOrGroup}
          />
        </>
      )}
      {activeTab === StudentsTab.SETTINGS && (
        <div className={styles.SettingsWrapper}>
          <ChangeMultiplicityAccess classOrGroup={classOrGroup} teachers={teachers} me={me} />
          <ChangeMultiplicitySettings classOrGroup={classOrGroup} />
          <ChangeMultiplicityInfo classItem={classOrGroup as IClass} me={me} />
        </div>
      )}

      {activeTab === StudentsTab.GROUPS && (
        <GroupsTabContent classroomId={classOrGroup._id} groups={(classOrGroup as IClass).groups} />
      )}
    </>
  )
}

export default MultiplicityTableContainer
