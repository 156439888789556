import React from 'react'
import styles from './LinkHighlighter.module.scss'
import clsx from 'clsx'

type LinkHighlighterProps = {
  className?: string
}

const LinkHighlighter: React.FC<LinkHighlighterProps> = ({ className }) => (
  <div className={clsx(styles.Container, className)} />
)
export default LinkHighlighter
