import { hasDisplayName } from 'helpers/classesView.helpers'

import { IClass } from '../../../api/types.classes'

export const sortByName = (array: IClass[]) => {
  return [...array].sort((class1, class2) => {
    const nameA = hasDisplayName(class1)
      ? class1.displayName.toLowerCase()
      : class1.name.toLowerCase()
    const nameB = hasDisplayName(class2)
      ? class2.displayName.toLowerCase()
      : class2.name.toLowerCase()
    return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
  })
}
