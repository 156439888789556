import { apiManager } from './apiManager'
import {
  GetTeachersBySchoolResponse,
  ShareWithTeachersPayload,
  ShareWithTeachersResponse,
  TeacherClassesResponse,
  TeacherSchool,
  UpdateSingleTeacherPayload,
  UpdateSingleTeacherResponse,
} from './types.teachers'

export class teachersApi {
  public static readonly classes = apiManager.get<TeacherClassesResponse[], void>(
    'teachers/classes'
  )
  public static readonly getAllSchools = apiManager.get<TeacherSchool[], string>(
    (teacherId) => `teachers/${teacherId}/schools`
  )
  public static readonly updateSingle = apiManager.put<
    UpdateSingleTeacherResponse,
    UpdateSingleTeacherPayload
  >(({ teacherId, body }) => ({
    url: `teachers/${teacherId}`,
    body,
  }))
  public static readonly getBySchool = apiManager.get<GetTeachersBySchoolResponse, string>(
    (schoolId) => `schools/${schoolId}/teachers`
  )
  public static readonly share = apiManager.post<
    ShareWithTeachersResponse,
    ShareWithTeachersPayload
  >('share')
}
