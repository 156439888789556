import { createEvent, restore, sample } from 'effector'
import { Class, SelectionUpdateEvent } from '../types'
import { selectAll, unselectAll } from './events'
import { $students } from './students'

export const setClass = createEvent<Class | null>()
export const unselectClass = createEvent()
export const selectAllClass = createEvent()
export const setClassSelected = createEvent<SelectionUpdateEvent | null>()

export const $class = restore(setClass, null)
  .on(selectAll, (state) => (state ? { ...state, selected: true } : state))
  .on(unselectAll, (state) => (state ? { ...state, selected: false } : state))
  .on(unselectClass, (state) => (state ? { ...state, selected: false } : state))
  .on(selectAllClass, (state) => (state ? { ...state, selected: true } : state))

sample({
  source: { class: $class, students: $students },
  clock: setClassSelected,
  fn: (source, payload) => ({ ...source, payload }),
}).watch(({ class: classItem, students, payload }) => {
  if (!payload || !classItem) return
  const allStudentsSelected = students.every((student) => student.selected)
  setClass({ ...classItem, selected: payload.selected && allStudentsSelected })
})
