import { IAction } from 'interfaces/actions.interface'
import { ImageListType } from 'react-images-uploading'
import { AvatarValue } from '../../ui/avatars/ProblemLevelAvatar/ProblemLevelAvatar'

const ARRAY_LENGTH = 4
export const MULTIPLE_CHOICE_INITIAL_VALUE = Array.from({ length: ARRAY_LENGTH }, () => ({
  value: '',
  checked: false,
}))

export enum PROBLEM_CREATOR {
  SET_QUESTION_FIELD_VALUE = 'SET_QUESTION_FIELD_VALUE',
  SET_QUESTION_KATEX_FIELD_VALUE = 'SET_QUESTION_KATEX_FIELD_VALUE',
  SET_ANSWER_NUMERIC = 'SET_ANSWER_NUMERIC',
  SET_ANSWER_ALGEBRA = 'SET_ANSWER_ALGEBRA',
  SET_ANSWER_MULTIPLE = 'SET_ANSWER_MULTIPLE',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  SET_SELECTED_VALUE = 'SET_SELECTED_VALUE',
  SET_MULTIPLE_CHOICE = 'SET_MULTIPLE_CHOICE',
  SET_LEVEL = 'SET_LEVEL',
  SET_IMAGES = 'SET_IMAGES',
  SET_IMAGE_URL = 'SET_IMAGE_URL',
  SET_ERROR_QUESTION_FIELD = 'SET_ERROR_QUESTION_FIELD',
  SET_ERROR_ANSWER_FIELD = 'SET_ERROR_ANSWER_FIELD',
  CLEAR_STORE = 'CLEAR_STORE,',
  SET_ALL_FIELDS = 'SET_ALL_FIELDS',
}

const initialState: ProblemCreatorData = {
  question: '',
  questionKatex: '',
  answerNumeric: '',
  answerAlgebra: '',
  answerMultiple: '',
  activeTab: 0,
  selectedValue: null,
  multipleChoice: MULTIPLE_CHOICE_INITIAL_VALUE,
  level: AvatarValue.GREEN,
  images: [],
  imageURL: '',
  cdnImage: '',
  errorQuestionField: null,
  errorAnswerField: null,
}

export function problemCreatorReducer(
  state: ProblemCreatorData = initialState,
  action: IAction<PROBLEM_CREATOR>
) {
  switch (action.type) {
    case PROBLEM_CREATOR.SET_QUESTION_FIELD_VALUE:
      return {
        ...state,
        question: action.payload.question,
      }
    case PROBLEM_CREATOR.SET_QUESTION_KATEX_FIELD_VALUE:
      return {
        ...state,
        questionKatex: action.payload.questionKatex,
      }
    case PROBLEM_CREATOR.SET_ANSWER_NUMERIC:
      return {
        ...state,
        answerNumeric: action.payload.answerNumeric,
      }
    case PROBLEM_CREATOR.SET_ANSWER_ALGEBRA:
      return {
        ...state,
        answerAlgebra: action.payload.answerAlgebra,
      }
    case PROBLEM_CREATOR.SET_ANSWER_MULTIPLE:
      return {
        ...state,
        answerMultiple: action.payload.answerMultiple,
      }
    case PROBLEM_CREATOR.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      }
    case PROBLEM_CREATOR.SET_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: action.payload.selectedValue,
      }
    case PROBLEM_CREATOR.SET_MULTIPLE_CHOICE:
      return {
        ...state,
        multipleChoice: action.payload.multipleChoice,
      }
    case PROBLEM_CREATOR.SET_LEVEL:
      return {
        ...state,
        level: action.payload.level,
      }
    case PROBLEM_CREATOR.SET_IMAGES:
      return {
        ...state,
        images: action.payload.images,
      }
    case PROBLEM_CREATOR.SET_IMAGE_URL:
      return {
        ...state,
        imageURL: action.payload.imageURL,
      }
    case PROBLEM_CREATOR.SET_ERROR_QUESTION_FIELD:
      return {
        ...state,
        errorQuestionField: action.payload.errorQuestionField,
      }
    case PROBLEM_CREATOR.SET_ERROR_ANSWER_FIELD:
      return {
        ...state,
        errorAnswerField: action.payload.errorAnswerField,
      }
    case PROBLEM_CREATOR.CLEAR_STORE:
      return {
        ...initialState,
      }
    case PROBLEM_CREATOR.SET_ALL_FIELDS:
      return {
        ...state,
        question: action.payload.question,
        questionKatex: action.payload.question,
        answerNumeric: action.payload.answerNumeric,
        answerAlgebra: action.payload.answerAlgebra,
        answerMultiple: action.payload.answerMultiple,
        activeTab: action.payload.activeTab,
        selectedValue: action.payload.selectedValue,
        multipleChoice: action.payload.multipleChoice,
        level: action.payload.level,
        images: action.payload.images,
        imageURL: action.payload.imageURL,
        cdnImage: action.payload.cdnImage,
        errorQuestionField: action.payload.errorQuestionField,
        errorAnswerField: action.payload.errorAnswerField,
      }
    default:
      return state
  }
}

export const setQuestion = (question: string) => ({
  type: PROBLEM_CREATOR.SET_QUESTION_FIELD_VALUE,
  payload: { question },
})

export const setQuestionKatex = (questionKatex: string) => ({
  type: PROBLEM_CREATOR.SET_QUESTION_KATEX_FIELD_VALUE,
  payload: { questionKatex },
})

export const setAnswerNumeric = (answerNumeric: string) => ({
  type: PROBLEM_CREATOR.SET_ANSWER_NUMERIC,
  payload: { answerNumeric },
})

export const setAnswerAlgebra = (answerAlgebra: string) => ({
  type: PROBLEM_CREATOR.SET_ANSWER_ALGEBRA,
  payload: { answerAlgebra },
})

export const setAnswerMultiple = (answerMultiple: string) => ({
  type: PROBLEM_CREATOR.SET_ANSWER_MULTIPLE,
  payload: { answerMultiple },
})

export const setActiveTab = (activeTab: number) => ({
  type: PROBLEM_CREATOR.SET_ACTIVE_TAB,
  payload: { activeTab },
})

export const setSelectedValue = (selectedValue: string | null) => ({
  type: PROBLEM_CREATOR.SET_SELECTED_VALUE,
  payload: { selectedValue },
})

export const setMultipleChoice = (multipleChoice: MultipleChoice[]) => ({
  type: PROBLEM_CREATOR.SET_MULTIPLE_CHOICE,
  payload: { multipleChoice },
})

export const setLevel = (level: AvatarValue) => ({
  type: PROBLEM_CREATOR.SET_LEVEL,
  payload: { level },
})

export const setImages = (images: ImageListType) => ({
  type: PROBLEM_CREATOR.SET_IMAGES,
  payload: { images },
})

export const setImageURL = (imageURL: string) => ({
  type: PROBLEM_CREATOR.SET_IMAGE_URL,
  payload: { imageURL },
})

export const setErrorQuestionField = (errorQuestionField: string | null) => ({
  type: PROBLEM_CREATOR.SET_ERROR_QUESTION_FIELD,
  payload: { errorQuestionField },
})

export const setErrorAnswerField = (errorAnswerField: string | null) => ({
  type: PROBLEM_CREATOR.SET_ERROR_ANSWER_FIELD,
  payload: { errorAnswerField },
})

export const clearStore = () => ({
  type: PROBLEM_CREATOR.CLEAR_STORE,
})

export const setAllFields = (data: any) => ({
  type: PROBLEM_CREATOR.SET_ALL_FIELDS,
  payload: {
    question: data.question,
    questionKatex: data.questionKatex,
    answer: data.answer,
    answerNumeric: data.answerNumeric,
    answerAlgebra: data.answerAlgebra,
    activeTab: data.activeTab,
    selectedValue: data.selectedValue,
    multipleChoice: data.multipleChoice,
    level: data.level,
    images: data.images,
    imageURL: data.imageURL,
    cdnImage: data.cdnImage,
    errorQuestionField: data.errorQuestionField,
    errorAnswerField: data.errorAnswerField,
  },
})

export interface MultipleChoice {
  value: string
  checked: boolean
}

export interface ProblemCreatorData {
  question: string
  questionKatex: string
  answerNumeric: string
  answerAlgebra: string
  answerMultiple: string
  activeTab: number
  selectedValue: string | null
  multipleChoice: MultipleChoice[]
  level: AvatarValue
  images: ImageListType
  imageURL: string
  cdnImage: string
  errorQuestionField: string | null
  errorAnswerField: string | null
}
