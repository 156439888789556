import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { COLORS } from '@magmamath/ui'

import SmallArrowRightIcon from '../icons/SmallArrowRightIcon/SmallArrowRightIcon'
import styles from './Breadcrumbs.module.scss'

type BreadcrumbLink = {
  text: string
  link?: string
}

type BreadcrumbsProps = {
  links: BreadcrumbLink[]
}

const Breadcrumbs = ({ links }: BreadcrumbsProps) => {
  return (
    <nav aria-label='breadcrumbs'>
      <ol className={styles.Breadcrumbs}>
        {links.map((item, index) => {
          const isActive = index === links.length - 1
          return (
            <li key={index} className={clsx(styles.Item, { [styles.Active]: isActive })}>
              {isActive ? (
                item.text
              ) : (
                <>
                  <Link to={`${item.link}`}>{item.text}</Link>
                  <SmallArrowRightIcon color={COLORS.NEUTRAL_8} />
                </>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
