import React from 'react'
import styles from './PrintProblemsCardBody.module.scss'
import Divider from '../../../../ui/dividers/Divider/Divider'
import PrintProblemsDescription from './PrintProblemsDescription/PrintProblemsDescription'
import clsx from 'clsx'
import { IProblem, ISubProblem } from '../../../../api/types.problem'

type PrintProblemsCardBodyProps = {
  problemOrSubProblem: IProblem | ISubProblem
}

const PrintProblemsCardBody = ({ problemOrSubProblem }: PrintProblemsCardBodyProps) => {
  const isShowImageContainer = !!(problemOrSubProblem.image || problemOrSubProblem.drawingAreaImage)

  return (
    <div className={styles.Container}>
      <Divider className={styles.HeaderDivider} />
      <div className={styles.DescriptionWrapper}>
        <span className={styles.ProblemName}>{`${problemOrSubProblem.name}.`}</span>
        <div
          className={clsx(styles.Description, {
            [styles.DescriptionWithImage]:
              problemOrSubProblem.image || problemOrSubProblem.drawingAreaImage,
          })}
        >
          <PrintProblemsDescription problem={problemOrSubProblem} />
        </div>
      </div>
      {isShowImageContainer && (
        <div className={styles.ImageContainer}>
          {problemOrSubProblem.image && (
            <img
              className={styles.Image}
              src={problemOrSubProblem.image}
              alt='Problem'
              loading='eager'
            />
          )}
          {problemOrSubProblem.drawingAreaImage && (
            <img
              className={styles.Image}
              src={problemOrSubProblem.drawingAreaImage}
              alt='Problem background'
              loading='eager'
            />
          )}
        </div>
      )}
    </div>
  )
}

export default PrintProblemsCardBody
