import { KeyCode } from 'helpers/enums'
import { renderInputField } from 'helpers/form.helpers'
import { validateForm } from 'helpers/synchValidation.helpers'
import { IState } from 'interfaces/state.interface'
import * as React from 'react'
import { Component } from 'react'
import { Button, Form } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import '../RegisterForms.scss'
import { ILocalization } from "../../../../config/languages.config";

interface IChangePasswordFormProps extends InjectedFormProps<FormData, any> {
  history: any
  onSubmit: (values: any, history: any) => {}
  serverErrors?: { message: string }
  account: IState<any>
  localization: ILocalization
}

class ChangePasswordFormComponent extends Component<IChangePasswordFormProps, any> {
  public handleKeyDown = (event: any, submit: any) => {
    if (event.keyCode === KeyCode.ENTER) {
      this.props.handleSubmit(submit)
    }
  }

  public render() {
    const { dirty, handleSubmit, invalid, valid, reset } = this.props
    const { resetPassTxt, resetPassIntrodution, newPassTxt, newPassConfirmTxt, backTxt } =
      this.props.localization!.data

    const submit = (values: any) => {
      if (valid) {
        return (this.props.onSubmit(values, this.props.history) as Promise<any>).then(() => reset())
      }
      return
    }

    return (
      <div>
        <Form
          className='register-form'
          onSubmit={handleSubmit(submit)}
          onKeyDown={(event: any) => this.handleKeyDown(event, submit)}
        >
          <p className='register-form-description'>{resetPassIntrodution}</p>
          <div className='form-group register-form-group'>
            <Field
              name='password'
              component={renderInputField as any}
              className='form-control'
              id='password'
              placeholder={newPassTxt}
              type='password'
            />
          </div>
          <div className='form-group register-form-group'>
            <Field
              name='passwordConfirmation'
              component={renderInputField as any}
              className='form-control'
              id='passwordConfirmation'
              placeholder={newPassConfirmTxt}
              type='password'
            />
          </div>
          <div className='buttons-wrapper confirm-password-buttons'>
            <button
              className='back-button'
              type='button'
              onClick={() => {
                this.props.history.goBack()
              }}
            >
              {backTxt}
            </button>
            <Button
              className='confirm-button confirm-password-btn'
              type='submit'
              disabled={!dirty || invalid}
            >
              {resetPassTxt}
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const ChangePasswordForm: any = reduxForm({
  enableReinitialize: true,
  // tslint:disable-next-line:object-literal-sort-keys
  destroyOnUnmount: false,
  form: 'resetPassword',
  validate: validateForm,
})(ChangePasswordFormComponent) as any

export default ChangePasswordForm
