import React from 'react'
import { SvgIconProps } from '../types/types'

const ShareIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7109 3.66949C12.7109 1.67046 14.3315 0.0499268 16.3305 0.0499268C18.3295 0.0499268 19.9501 1.67046 19.9501 3.66949C19.9501 5.66853 18.3295 7.28906 16.3305 7.28906C15.0909 7.28906 13.9968 6.66592 13.3445 5.7158L7.08581 8.80148C7.21754 9.17693 7.28918 9.58045 7.28918 9.99995C7.28918 10.4194 7.21753 10.823 7.08581 11.1984L13.3445 14.2841C13.9968 13.334 15.0909 12.7108 16.3305 12.7108C18.3295 12.7108 19.9501 14.3314 19.9501 16.3304C19.9501 18.3294 18.3295 19.9499 16.3305 19.9499C14.3315 19.9499 12.7109 18.3294 12.7109 16.3304C12.7109 16.1781 12.7203 16.0281 12.7386 15.8808L6.15245 12.6336C5.50498 13.2441 4.63118 13.6195 3.66962 13.6195C1.67058 13.6195 0.0500488 11.999 0.0500488 9.99995C0.0500488 8.00091 1.67058 6.38038 3.66962 6.38038C4.63118 6.38038 5.50498 6.75581 6.15245 7.36626L12.7386 4.11914C12.7203 3.97182 12.7109 3.82175 12.7109 3.66949ZM16.3305 1.74993C15.2704 1.74993 14.4109 2.60935 14.4109 3.66949C14.4109 4.72964 15.2704 5.58906 16.3305 5.58906C17.3907 5.58906 18.2501 4.72964 18.2501 3.66949C18.2501 2.60935 17.3907 1.74993 16.3305 1.74993ZM16.3305 14.4108C15.2704 14.4108 14.4109 15.2702 14.4109 16.3304C14.4109 17.3905 15.2704 18.25 16.3305 18.25C17.3907 18.25 18.2501 17.3905 18.2501 16.3304C18.2501 15.2702 17.3907 14.4108 16.3305 14.4108ZM1.75005 9.99995C1.75005 8.9398 2.60947 8.08038 3.66962 8.08038C4.3212 8.08038 4.89728 8.40424 5.2456 8.90362C5.46224 9.2142 5.58918 9.59098 5.58918 9.99995C5.58918 10.4089 5.46224 10.7857 5.2456 11.0963C4.89728 11.5956 4.3212 11.9195 3.66962 11.9195C2.60947 11.9195 1.75005 11.0601 1.75005 9.99995Z'
        fill={color}
      />
    </svg>
  )
}

export default ShareIcon
