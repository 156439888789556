import { FieldType, FormTypes, ModalType, RemovableTypes } from 'helpers/enums'
import { IMe } from 'api/types.users'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { IAccountActions } from 'redux/modules/account.module'
import { IAuth, IAuthActions } from 'redux/modules/auth.module'
import { IState } from '../../../interfaces/state.interface'
import { IModal, IModalActions } from '../../../redux/modules/modal.module'
import CommonInputModal from '../../../ui/inputs/CommonInputModal/CommonInputModal'
import CommonModalWrapper from '../../../ui/modals/CommonModalWrapper/CommonModalWrapper'
import Button from '../../../ui/Buttons/UIButton/Button'
import { validate } from '../../../lib/modals'
import { ILocalization } from "../../../config/languages.config";

interface ISimpleAsyncModalProps {
  history: History
  localization: ILocalization
  onSubmit: (
    values: { username?: string; email?: string; firstName?: string; lastName?: string },
    id: string,
    history: History,
    localization: ILocalization
  ) => {}
  serverErrors?: { message: string }
  auth: IState<IAuth>
  title?: string
  authActions?: IAuthActions
  accountActions?: IAccountActions
  modalActions?: IModalActions
  modal: IState<IModal>
  me: IMe
}

interface IFormModel {
  initialValue: string
  fieldType: FieldType
  validateHandler: (inputValue?: string, fieldType?: FieldType) => boolean
  name: string
  title: string
}

const SimpleAsyncModal: React.FC<ISimpleAsyncModalProps> = ({
  modalActions,
  localization,
  modal,
  history,
  me,
  onSubmit,
  auth,
}) => {
  const {
    saveTxt,
    firstNameTxt,
    lastNameTxt,
    emailTxt,
    userNameTxt,
    editFirstName,
    editLastName,
    editEmail,
    editUsername,
  } = localization.data

  const setIsDisabledBtnHandler = () => inputValue.trim().length <= 0

  const formDescriptor: Partial<Record<FormTypes, IFormModel>> = {
    [FormTypes.USERNAME]: {
      initialValue: me.username,
      fieldType: FieldType.USER_NAME,
      validateHandler: validate,
      name: userNameTxt,
      title: editUsername,
    },
    [FormTypes.EMAIL]: {
      initialValue: me.email,
      fieldType: FieldType.EMAIL,
      validateHandler: validate,
      name: emailTxt,
      title: editEmail,
    },
    [FormTypes.FIRST_NAME]: {
      initialValue: me.firstName,
      fieldType: FieldType.FIRST_NAME,
      validateHandler: setIsDisabledBtnHandler,
      name: firstNameTxt,
      title: editFirstName,
    },
    [FormTypes.LAST_NAME]: {
      initialValue: me.lastName,
      fieldType: FieldType.LAST_NAME,
      validateHandler: setIsDisabledBtnHandler,
      name: lastNameTxt,
      title: editLastName,
    },
  }

  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [inputValue, setInputValue] = useState(formDescriptor[modal.data.typeOfItem].initialValue)

  const onKeyPressHandler = (e: KeyboardEvent) => {
    if (e.code === 'Enter' && !isDisabledBtn) {
      onSubmit(
        { [formDescriptor[modal.data.typeOfItem].fieldType]: inputValue },
        me._id,
        history,
        localization
      )
    }
    return
  }

  useEffect(() => {
    validateField(modal.data.typeOfItem)
  }, [inputValue])

  useEffect(() => {
    document.addEventListener('keypress', onKeyPressHandler)
    return () => document.removeEventListener('keypress', onKeyPressHandler)
  }, [onKeyPressHandler])

  const validateField = (type: FormTypes | RemovableTypes) => {
    const handler = formDescriptor[type].validateHandler
    setIsDisabledBtn(handler(inputValue, formDescriptor[type].fieldType))
    return
  }

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
  }

  const close = () => {
    modalActions!.closeSimpleAsyncModal()
  }

  const title: string = formDescriptor[modal.data.typeOfItem].title
  const name: string = formDescriptor[modal.data.typeOfItem].name
  const opened = modal.data.modalType === ModalType.SimpleAsyncModal

  const onClickHandler = () => {
    onSubmit(
      { [formDescriptor[modal.data.typeOfItem].fieldType]: inputValue },
      me._id,
      history,
      localization
    )
  }

  return (
    <CommonModalWrapper title={title} open={opened} onClose={close}>
      <CommonInputModal placeholder={name} value={inputValue} onChange={onChangeHandler} />
      <Button
        variant='primary'
        size='medium'
        color='green'
        disabled={isDisabledBtn}
        onClick={onClickHandler}
      >
        {saveTxt}
      </Button>
    </CommonModalWrapper>
  )
}

export default SimpleAsyncModal
