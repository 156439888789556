import { BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import clsx from 'clsx'
import { useStore } from 'effector-react'
import { $pinnedSolutions } from 'features/pinnedSolutions/model/pinnedSolutions'
import React, { SyntheticEvent } from 'react'
import FilledPinIcon from 'ui/icons/FilledPinIcon/FilledPinIcon'
import PinIcon from 'ui/icons/PinIcon/PinIcon'
import { updatePin } from '../../model/pins'
import styles from './SolutionsPin.module.scss'
import { AddPinParams } from '../../../../api/types.pins'

type SolutionsPinProps = { size?: SIZE; isPinVisible: boolean } & AddPinParams

const SolutionsPin = ({ size = SIZE.SMALL, isPinVisible, ...addPinParams }: SolutionsPinProps) => {
  const iconSize = size === SIZE.SMALL ? 15 : 20
  const { isFetchingPins, isMutatingPins, pinsData } = useStore($pinnedSolutions)
  const currentPinsData = pinsData.currentPinsData?.pins
  const shouldShowLoader = isFetchingPins && !currentPinsData

  const pinId = (currentPinsData ?? []).find(
    (pinObj) => pinObj.solutionId === addPinParams.solutionId
  )?._id

  const Icon = pinId ? FilledPinIcon : PinIcon

  return (
    <Button
      variant={BUTTON_VARIANT.TERTIARY}
      size={size}
      isTransparent={true}
      disabled={!isPinVisible || !addPinParams.solutionId}
      icon={<Icon color='var(--icon-color)' size={iconSize} />}
      isLoading={shouldShowLoader}
      classes={{ loader: styles.Loader, button: clsx({ [styles.NonClickable]: isMutatingPins }) }}
      onClick={(e: SyntheticEvent) => {
        e.preventDefault()
        updatePin({ pinId: pinId, ...addPinParams })
      }}
    />
  )
}

export default SolutionsPin
