import { dynamicPaths } from '../../config/pathnames.config'
import { getFullName } from '../../helpers/user.helpers'
import { IStudent } from '../../api/types.users'
import { IExercise } from '../../api/types.assignments'
import { IProblem, ISubProblem } from '../../api/types.problem'

export const getSubProblem = (currentProblem: IProblem, taskId: string) => {
  const subProblemFromExercise = currentProblem.subProblems.find(
    (subProblem) => subProblem._id === taskId
  )
  return subProblemFromExercise || null
}

export const getProblem = (exercise: IExercise, taskId: string) => {
  const problemsFromExercise = exercise.problems.find((problemItem: IProblem) =>
    problemItem._id === taskId
      ? problemItem
      : problemItem.subProblems.find((subProblemItem: ISubProblem) => {
          return subProblemItem._id === taskId
        })
  )
  return problemsFromExercise || null
}

type GetStudentCrumbProps = {
  isAnonymousNameMode: boolean
  studentId?: string
  exerciseId: string
  studentTxt: string
  sortedStudents: IStudent[]
  currentStudent?: IStudent
}
const getStudentCrumb = ({
  isAnonymousNameMode,
  studentId,
  studentTxt,
  sortedStudents,
  currentStudent,
  exerciseId,
}: GetStudentCrumbProps) => ({
  text:
    isAnonymousNameMode && studentId
      ? `${studentTxt} ${
          sortedStudents.findIndex((student) => student._id === currentStudent?._id) + 1
        }`
      : getFullName(currentStudent?.firstName, currentStudent?.lastName),
  link: dynamicPaths.studentMultipleSolutions({ exerciseId, studentId: currentStudent?._id || '' }),
})

type GetBreadcrumbLinksProps = {
  heatmapTxt: string
  exerciseId: string
  studentId?: string
  isAnonymousNameMode: boolean
  studentTxt: string
  sortedStudents: IStudent[]
  currentStudent?: IStudent
  problemTxt: string
  currentProblem: IProblem | null
  currentSubProblem: ISubProblem | null
  wasRedirectedFromMultipleSolutions: boolean
}

type BreadcrumbLink = {
  text: string
  link?: string
}

export const getBreadcrumbLinks = ({
  heatmapTxt,
  exerciseId,
  studentId,
  isAnonymousNameMode,
  studentTxt,
  sortedStudents,
  currentStudent,
  problemTxt,
  currentProblem,
  currentSubProblem,
  wasRedirectedFromMultipleSolutions,
}: GetBreadcrumbLinksProps) => {
  const breadcrumbsLinks: BreadcrumbLink[] = [
    { text: heatmapTxt, link: dynamicPaths.heatmap({ exerciseId }) },
  ]

  const problemLink = currentStudent
    ? dynamicPaths.studentsSolutions({
        exerciseId,
        problemId: currentProblem?._id || '',
        subProblemId: currentSubProblem?._id,
      })
    : undefined

  const studentCrumb = getStudentCrumb({
    isAnonymousNameMode,
    studentId,
    studentTxt,
    sortedStudents,
    currentStudent,
    exerciseId,
  })

  const problemCrumb = {
    text: `${problemTxt} ${currentProblem?.name}${currentSubProblem ? currentSubProblem.name : ''}`,
    link: problemLink,
  }

  if (wasRedirectedFromMultipleSolutions) {
    breadcrumbsLinks.push(studentCrumb)
    breadcrumbsLinks.push(problemCrumb)

    return breadcrumbsLinks
  }

  if (!currentStudent) {
    breadcrumbsLinks.push(problemCrumb)
    return breadcrumbsLinks
  }

  breadcrumbsLinks.push(problemCrumb)
  breadcrumbsLinks.push(studentCrumb)

  return breadcrumbsLinks
}
