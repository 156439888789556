import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { api } from '../../api'

export enum SOLUTION_STATUSES {
  SOLUTION_STATUSES_REQUEST = 'SOLUTION_STATUSES_REQUEST',
  SOLUTION_STATUSES_ERROR = 'SOLUTION_STATUSES_ERROR',
  SOLUTION_STATUSES_SUCCESS = 'SOLUTION_STATUSES_SUCCESS',
}

// Status reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function solutionStatusReducer(
  state: any = initialState,
  action: IAction<SOLUTION_STATUSES>
) {
  switch (action.type) {
    case SOLUTION_STATUSES.SOLUTION_STATUSES_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SOLUTION_STATUSES.SOLUTION_STATUSES_ERROR:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SOLUTION_STATUSES.SOLUTION_STATUSES_SUCCESS:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function getSolutionStatuses(exerciseId: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SOLUTION_STATUSES.SOLUTION_STATUSES_REQUEST })
      const res = await api.solutions.getAllStatuses(exerciseId)
      dispatch({ type: SOLUTION_STATUSES.SOLUTION_STATUSES_ERROR, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SOLUTION_STATUSES.SOLUTION_STATUSES_SUCCESS,
      })
    }
  }
}

export interface ISolutionStatuses {
  _id: string
  attempt: 0
  solved: 0
}
