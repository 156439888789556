import { COLORS } from '@magmamath/ui'

export const INPUT_STYLES = {
  sx: {
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      height: '47px',
      border: `1px solid var(--neutral-5)`,
      borderRadius: '12px',
      backgroundColor: COLORS.NEUTRAL_1,
      color: COLORS.NEUTRAL_10,
      margin: 0,
      marginBottom: '18px',
      padding: '8.5px 14px',
      paddingRight: '43px',

      '&::placeholder': {
        color: COLORS.NEUTRAL_6,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
}
