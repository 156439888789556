import React from 'react'
import clx from 'classnames'
import styles from './CommonInputModalStyles.module.scss'

interface ICommonInputModalProps {
  value?: string
  placeholder: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  errorMessage?: string
  maxLength?: number
}

const CommonInputModal: React.FC<ICommonInputModalProps> = ({
  value = '',
  placeholder,
  onChange,
  errorMessage,
  maxLength,
  autoFocus = true,
  onBlur,
}) => {
  return (
    <div className={styles.InputWrapper}>
      <input
        className={clx(styles.Input, { [styles.InputError]: errorMessage })}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={autoFocus}
        maxLength={maxLength}
      />
      {errorMessage && <span className={styles.ErrorMessage}>{errorMessage}</span>}
    </div>
  )
}

export default CommonInputModal
