const MATH_OPERATOR_REG_EXP = /[+\-–x·÷=]/
const MATH_OPERATOR_IN_END = new RegExp(`${MATH_OPERATOR_REG_EXP.source}$`)

export const isMathOperator = (text: string) => {
  return MATH_OPERATOR_REG_EXP.test(text)
}
export const isEndsWithSpace = (text: string) => {
  return text.endsWith('\\ ')
}

export const isEndsWithInterruptingSymbol = (text: string) => {
  return text.endsWith('\\ ') || MATH_OPERATOR_IN_END.test(text)
}

export const trimNonKatexEnding = (text: string) => {
  let removedPart = ''
  if (!text.includes('}')) return { value: '', removedPart: text }
  const value = text.replace(/}(?!.*})(.*)$/, (match, group1) => {
    removedPart = group1
    return '}'
  })
  return { value, removedPart }
}
export const trimEndSpaces = (text: string) => {
  return text.replace(/\\\s$/g, '')
}

export const getInitialFormulaForKatex = (formula: string) => {
  if (formula.includes('\\frac')) {
    return '\\frac{x}{y}'
  }
  if (formula === '^') {
    return 'x^y'
  }
  return null
}
