import { Tooltip } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { ILocalization } from '../../../config/languages.config'
import { IMergedSkillsStatsAndDataItem, IUser } from '../SkillsTable'
import SkillsInternalTable from './SkillsInternalTable/SkillsInternalTable'
import { IExercise } from '../../../api/types.assignments'
import { ISkillsUsers } from '../../../api/types.statistics'
import { ISkill } from '../../../api/types.oldSkills'

interface ISkillsProgressTableProps {
  localization: ILocalization
  skillsData: ISkill[]
  exercise: IExercise
  teacherId: string
  history: any
  totalAmountProblemsAndSubProblems: number
  groupByBook: IMergedSkillsStatsAndDataItem[][]
  usersWithNameForTable: IUser[]
  isPrinting: boolean
}

export interface IDataForCreateOrEditExerciseModal {
  allClassroomStudents: boolean
  name: string
  classes: string[]
  groups: string[]
  problems: string[]
  students: string[]
  startDate: string
  endDate: null
  testModeEnabled: boolean
  allowedAnswerLooking: boolean
  numberOfProblems: number
  exerciseId: string
  updateMarker: string
}

const SkillsProgressTable: React.FC<ISkillsProgressTableProps> = ({
  exercise,
  localization,
  history,
  groupByBook,
  usersWithNameForTable,
  teacherId,
  skillsData,
  isPrinting,
}) => {
  const isAnonymousNameMode = useStoreMap(
    $heatmapSettings,
    (settings) => settings.isAnonymousNameMode
  )
  const [sortedArray, setSortedArray] = useState<IMergedSkillsStatsAndDataItem[][]>([])
  useEffect(() => {
    const copyGroupByBook = [...groupByBook]

    if (isEmpty(copyGroupByBook)) {
      return
    }
    const moveOtherToEndOfArr = (array: IMergedSkillsStatsAndDataItem[][]) => {
      const putToEnd = (arr: IMergedSkillsStatsAndDataItem[][], index: number) =>
        arr.push(arr.splice(index, 1)[0])
      const indexOfOther = array.findIndex((element: IMergedSkillsStatsAndDataItem[]) =>
        element.some(
          (skill: IMergedSkillsStatsAndDataItem) => skill.skillId === localization.data.other
        )
      )
      putToEnd(array, indexOfOther)
    }
    moveOtherToEndOfArr(copyGroupByBook)
    setSortedArray(copyGroupByBook)
  }, [groupByBook])

  const colorPalette: Array<{ class: string; minPercentage: number }> = [
    { class: 'skill-level-5', minPercentage: 80 },
    { class: 'skill-level-4', minPercentage: 60 },
    { class: 'skill-level-3', minPercentage: 40 },
    { class: 'skill-level-2', minPercentage: 20 },
    { class: 'skill-level-1', minPercentage: 0.001 },
    { class: 'skill-level-0', minPercentage: 0 },
  ]

  const choseCellColor = (solvedPercentage: number, wrongStudentsAnswers: number) => {
    const indexOfLastPaletteItem = colorPalette.length - 1
    const indexOfPenultimatePaletteItem = indexOfLastPaletteItem - 1

    const lastPaletteItem = colorPalette[indexOfLastPaletteItem]
    const penultimatePaletteItem = colorPalette[indexOfPenultimatePaletteItem]

    const paletteItem = colorPalette.find((color) => solvedPercentage >= color.minPercentage)

    const isLastPaletteItem = paletteItem?.class === lastPaletteItem.class

    return isLastPaletteItem && wrongStudentsAnswers
      ? penultimatePaletteItem.class
      : paletteItem?.class
  }

  return (
    <div className='skills-table-container'>
      {!isEmpty(sortedArray) && (
        <table>
          <thead>
            <tr>
              <th className='first-cell'></th>
              {usersWithNameForTable.map((user: IUser, index: number) => (
                <th key={user.userId}>
                  <div className='table-header-cell'>
                    {!isPrinting ? (
                      isAnonymousNameMode ? (
                        <div className='table-header-cell-name'>{index + 1}</div>
                      ) : (
                        <Tooltip title={user.fullName} placement='top'>
                          <div className='table-header-cell-name'>{user.initials}</div>
                        </Tooltip>
                      )
                    ) : (
                      <div className='table-header-cell-name table-header-cell-name_rotation'>
                        {user.fullName}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedArray.map((book: IMergedSkillsStatsAndDataItem[]) => {
              if (isEmpty(book)) {
                return
              }
              return book.map((bookItem: IMergedSkillsStatsAndDataItem, index: number) => (
                <tr key={index}>
                  {index === 0 && (
                    <td className='sticky-column' rowSpan={book?.length}>
                      <SkillsInternalTable
                        localization={localization}
                        book={book}
                        teacherId={teacherId}
                        skillsData={skillsData}
                        exercise={exercise}
                      />
                    </td>
                  )}
                  {!isEmpty(bookItem?.users) &&
                    bookItem?.users?.map((user: ISkillsUsers) => (
                      <td key={user?.userId}>
                        <div
                          className={`color-block ${choseCellColor(
                            user?.solvedPercentage || 0,
                            user?.wrong
                          )}`}
                        />
                      </td>
                    ))}
                </tr>
              ))
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default SkillsProgressTable
