import {
  createDynamicPath,
  DefaultProps,
  commonProps,
  ExerciseProps,
  FullProps,
  ProblemProps,
} from './pathCreator'

export const dynamicPaths = {
  studentMultipleSolutions: createDynamicPath<DefaultProps>('multiple-solutions'),
  studentMultipleSolution: createDynamicPath<FullProps>('multiple-solutions'),
  studentsSolutions: createDynamicPath<ExerciseProps & ProblemProps>('solutions'),
  studentSolutions: createDynamicPath<FullProps>('solutions'),
  pinnedSolutions: createDynamicPath<ExerciseProps & ProblemProps>('pinned-solutions'),
  extendedView: createDynamicPath<FullProps>('extended-view'),
  heatmap: createDynamicPath<ExerciseProps>('heatmap'),
}

export const PATHS = {
  MAIN: '/',
  ACCOUNT: '/profile',
  CLASSES: '/classes',
  CONTENT: {
    BOOKS: '/content/books',
    BOOKSERIES: '/content/book-series',
    PROBLEMS: '/content/problems',
    ADD_NEW_PROBLEM: '/content/problems/add',
    PROBLEMS_EDIT: '/content/problems/edit/:id',
  },
  EXERCISES: {
    CREATE: '/exercises/create',
    ADD_PROBLEMS: '/exercises/add/problems',
    ADD_SETTINGS: '/exercises/add/settings',
    EXERCISES_MAIN: '/exercises',
    HEATMAP: '/exercises/:exerciseId/heatmap',
    STUDENT_MULTIPLE_SOLUTIONS:
      '/exercises/:exerciseId/students/:studentId/multiple-solutions:studentsHiddenParam?',
    STUDENT_MULTIPLE_SOLUTION: [
      '/exercises/:exerciseId/students/:studentId/problems/:problemId/multiple-solutions:studentsHiddenParam?',
      '/exercises/:exerciseId/students/:studentId/sub-problems/:subProblemId/multiple-solutions:studentsHiddenParam?',
    ],
    STUDENTS_SOLUTIONS: [
      '/exercises/:exerciseId/problems/:taskId/solutions:studentsHiddenParam?',
      '/exercises/:exerciseId/sub-problems/:taskId/solutions:studentsHiddenParam?',
    ],
    STUDENT_SOLUTIONS: [
      '/exercises/:exerciseId/problems/:taskId/students/:studentId/solutions:studentsHiddenParam?',
      '/exercises/:exerciseId/sub-problems/:taskId/students/:studentId/solutions:studentsHiddenParam?',
    ],
    EXTENDED_VIEW: [
      dynamicPaths.extendedView({ ...commonProps, subProblemId: ':subProblemId' }),
      dynamicPaths.extendedView({ ...commonProps, problemId: ':problemId' }),
    ],
    SKILLS: '/exercises/:exerciseId/skills',
    PINNED_SOLUTIONS: [
      '/exercises/:exerciseId/problems/:taskId/pinned-solutions:studentsHiddenParam?',
      '/exercises/:exerciseId/sub-problems/:taskId/pinned-solutions:studentsHiddenParam?',
    ],
    PINNED_SOLUTION: [
      '/exercises/:exerciseId/problems/:taskId/students/:studentId/pinned-solutions:studentsHiddenParam?',
      '/exercises/:exerciseId/sub-problems/:taskId/students/:studentId/pinned-solutions:studentsHiddenParam?',
    ],
    PIE_CHART: [
      '/exercises/:exerciseId/pie-chart:studentsHiddenParam?',
      '/exercises/:exerciseId/students/:studentId/pie-chart:studentsHiddenParam?',
      '/exercises/:exerciseId/classes/:classId/pie-chart:studentsHiddenParam?',
      '/exercises/:exerciseId/groups/:groupId/pie-chart:studentsHiddenParam?',
    ],
    EDIT: '/exercises/:exerciseId/edit',
    COPY: '/exercises/:exerciseId/copy',
  },
  LOGIN: {
    SIGN_IN: '/signin?logout=1',
    SIGN_UP: '/signup',
    LOGIN_MAIN: '/login',
    LOGIN_PASSWORD_EMAIL: '/login/reset-password/email',
    LOGIN_PASSWORD_CODE: '/login/reset-password/code',
    LOGIN_PASSWORD_CONFIRMATION: '/login/reset-password/confirm',
  },
  MAP: '/map',
  START: '/start',
  STUDENTS: {
    STUDENTS_MAIN: ['/students/:category(groups|classes)/:id/:tab?'],
  },
  PARTS: {
    EXERCISES_ADD: '/exercises/add',
    HEATMAP: 'heatmap',
    SKILLS: 'skills',
    STUDENT_PROGRESS: '/student-progress',
    PINNED: '/pinned-solutions',
    SOLUTIONS: '/solutions',
    MULTIPLE_SOLUTIONS: '/multiple-solutions',
    PIE_CHART: '/pie-chart',
    EXTENDED_VIEW: 'extended-view',
    CLASS_STUDENTS: '/students/classes/',
    GROUP_STUDENTS: '/students/groups/',
    STUDENTS: '/students',
    PROBLEMS_EDIT: '/content/problems/edit/',
    COPY: '/copy',
    EDIT: '/edit',
  },
  PARAMS: {
    HIDDEN_STUDENTS: '?hidden-students=',
  },
  PRACTICE: {
    PRACTICE_MAIN: '/practice',
  },
}
