import { localStorageKeys } from 'config/localStorageKeys'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import * as React from 'react'
import StudentProgress from 'views/StudentProgress/StudentProgress.view'

export const ProgressRoutes = [
  {
    exact: true,
    path: '/student-progress/:id',
    render: (props: any) => {
      const studentProgressProps: any = {
        classesStudents: props.classesStudents,
        classesStudentsActions: props.classesStudentsActions,
        history: props.history,
        localization: props.localization,
        location: props.location,
        match: props.match,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
        modal: props.modal,
        studentProgress: props.studentProgress,
        studentProgressActions: props.studentProgressActions,
      }
      return <StudentProgress {...studentProgressProps} />
    },
  },
]
