import { TransformedRecord } from '@magmamath/skills-utils'

export const getSkills = (
  nodeIdByGrade: Record<string, string>,
  grade: number,
  skills: TransformedRecord | null
) => {
  if (skills && nodeIdByGrade && grade) {
    const gradeNodeId = nodeIdByGrade[grade]
    const node = skills.children.find((nodeItem) => nodeItem._id === gradeNodeId)
    if (node) {
      skills = {
        ...node,
        children: node.children,
      }
    }
  }
  return skills
}
