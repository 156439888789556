import { IState } from 'interfaces/state.interface'
import React from 'react'
import './Error.component.scss'
import { writeLogg } from '../../helpers/loggly.helper'
import { ILocalization } from '../../config/languages.config'

interface IErrorProps {
  localization?: IState<ILocalization>
}

export default class ErrorBoundary extends React.Component<IErrorProps, any, any> {
  constructor(props: IErrorProps) {
    super(props)
    this.state = {
      error: '',
      hasError: false,
    }
  }

  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // tslint:disable-next-line: no-console
    writeLogg(error, info)
    window.console.log(error, info, this.props.localization)
    this.setState({ error })
  }

  public reloadPage = () => {
    window.location.reload()
  }

  public render() {
    const isLoadingChunkError: string =
      this.state.error &&
      this.state.error?.includes('Loading') &&
      this.state.error.includes('chunk')
    if (this.state.hasError && isLoadingChunkError) {
      return (
        <div className='error-container'>
          <h1 className='error-message'>Network error. Please refresh.</h1>
          <button className='refresh-btn' onClick={() => this.reloadPage()}>
            <span>Retry</span>
          </button>
        </div>
      )
    } else if (this.state.hasError) {
      return (
        <div className='error-container'>
          <h1 className='error-message'>Something went wrong.</h1>
        </div>
      )
    }
    // @ts-ignore
    return this.props.children
  }
}
