import { RequestCreatorProps, RequestDescriptor } from './types'

export function minutes(value: number) {
  return value * 60 * 1000
}

export function hours(value: number) {
  return value * 60 * 60 * 1000
}

export function days(value: number) {
  return value * 24 * 60 * 60 * 1000
}

export function removeStartSlash(url: string) {
  return url.startsWith('/') ? url.slice(1) : url
}

function removeEndSlash(url: string) {
  return url.endsWith('/') ? url.slice(0, -1) : url
}

function removeEdgeSlashes(url: string) {
  return removeStartSlash(removeEndSlash(url))
}

export function isPrimitive(value: any): value is string | number | boolean | null {
  return value !== Object(value)
}

export function bodyToParams(body: object) {
  return Object.entries(body)
    .reduce<string[]>((acc, [key, value]) => {
      if (value === undefined && value === '' && value === null) return acc
      if (Array.isArray(value)) {
        value.forEach((items) => acc.push(`${key}[]=${items}`))
        return acc
      }
      acc.push(`${key}=${value}`)
      return acc
    }, [])
    .join('&')
}

export function requestCreatorPropsToDescriptor<Params = void>(
  settings?: RequestCreatorProps<Params> | undefined
): RequestDescriptor<Params> {
  if (!settings) return { url: '' }
  if (typeof settings === 'string' || typeof settings === 'number') {
    return { url: removeEdgeSlashes(settings.toString()) }
  }
  if (typeof settings === 'function') {
    return { fn: settings }
  }
  const { data, url = '', ...rest } = settings
  if (data) return { fn: data, ...rest }
  return { url, ...rest }
}
