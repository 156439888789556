import { apiManager } from './apiManager'
import { IMe, User } from './types.users'
import { ApiMethod } from '../lib/apiManager/types'

type UsernameCheckPayload = {
  username: string
}
type UsernameCheckResponse = {
  available: boolean
  valid: boolean
}

export type ResetPasswordRequestPayload = {
  email: string
}

export type ResetPasswordPayload = {
  username: string
  code: number
  password: string
  passwordConfirmation: string
}

export type ChangePasswordPayload = {
  username: string
  password: string
  passwordConfirmation: string
}

export type LoginPayload = {
  username: string
  password: string
}

export type LoginResponse = {
  type: User
  token: string
}

export type GetMePayload = {
  fetchCountry?: 1 | 0
}

export type LoginWithSkolonPayload = {
  code: string
}

type AutoLoginTokenResponse = {
  autologinToken: string
}

type AutoLoginPayload = {
  autologinToken: string
  userId: string
}

export class authApi {
  public static readonly login = apiManager.unprotectedRequest<LoginResponse, LoginPayload>(
    ApiMethod.POST,
    'auth'
  )
  public static readonly loginWithSkolon = apiManager.unprotectedRequest<
    LoginResponse,
    LoginWithSkolonPayload
  >(ApiMethod.GET, 'auth/skolon/callback')

  public static readonly getAutoLoginToken =
    apiManager.get<AutoLoginTokenResponse>('autologin-token')

  public static readonly autoLogin = apiManager.unprotectedRequest<LoginResponse, AutoLoginPayload>(
    ApiMethod.POST,
    'autologin'
  )

  public static readonly getMe = apiManager.get<IMe, GetMePayload>('auth/me')
  public static readonly usernameCheck = apiManager.unprotected.post<
    UsernameCheckResponse,
    UsernameCheckPayload
  >('auth/username-check')
  public static readonly resetPasswordRequest = apiManager.unprotected.post<
    void,
    ResetPasswordRequestPayload
  >('auth/reset-password-request')
  public static readonly resetPassword = apiManager.unprotected.post<void, ResetPasswordPayload>(
    'auth/reset-password'
  )
  public static readonly passwordChange = apiManager.post<void, ChangePasswordPayload>(
    'auth/password-change'
  )
}
