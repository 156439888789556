import { SortingTypes, TypesOfStudentCell } from 'helpers/enums'
import { returnDateOfCreation } from 'helpers/general'
import { IState } from 'interfaces/state.interface'
import React from 'react'
import { Progress } from 'reactstrap'
import './StudentTableBody.component.scss'
import { ILocalization } from '../../../../config/languages.config'
import { GetStudentProgressResponse, IStudentProgressStat } from '../../../../api/types.statistics'

export interface IStudentTableBodyProps {
  localization: ILocalization
  studentProgress: IState<GetStudentProgressResponse>
  sortedType: SortingTypes
  sortedStats: IStudentProgressStat[]
}

export default class StudentTableBody extends React.Component<IStudentTableBodyProps, any, any> {
  public months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  public getClassByType = (type: TypesOfStudentCell, isProv: boolean, isColoredCell: boolean) => {
    const isTypeCell: boolean = type === TypesOfStudentCell.TypeCell
    const isNotAnswered: boolean = type === TypesOfStudentCell.NotAnsweredCell

    if (!isColoredCell || isNotAnswered) {
      if (isProv && isTypeCell) {
        return '#c8b3da'
      }
      if (!isTypeCell && isProv) {
        return '#eee8f4'
      } else {
        return 'transparent'
      }
    }
    return 'transparent'
  }

  public returnColorDependsCellType = (type: TypesOfStudentCell) => {
    const isFirstAttempt: boolean = type === TypesOfStudentCell.FirstAttemptCell
    const isAfterFirstAttempt: boolean = type === TypesOfStudentCell.AfterFirstAttemptCell
    const isWrong: boolean = type === TypesOfStudentCell.WrongCell

    if (isFirstAttempt) {
      return '#a7cf7d'
    } else if (isAfterFirstAttempt) {
      return '#fbdf89'
    } else if (isWrong) {
      return '#ff5e5e'
    } else {
      return ''
    }
  }

  public getPercentageCells = (type: TypesOfStudentCell) => {
    const isFirstAttempt: boolean = type === TypesOfStudentCell.FirstAttemptCell
    const isAfterFirstAttempt: boolean = type === TypesOfStudentCell.AfterFirstAttemptCell
    const isWrong: boolean = type === TypesOfStudentCell.WrongCell
    const isNotAnswered: boolean = type === TypesOfStudentCell.NotAnsweredCell
    return isAfterFirstAttempt || isFirstAttempt || isWrong || isNotAnswered
  }

  public renderCell = (
    value: string,
    isProv: boolean,
    hasProgressbar: boolean,
    type: TypesOfStudentCell,
    percent?: number
  ) => {
    const isPercentageCell: boolean = this.getPercentageCells(type)
    const percentageClass: string = isPercentageCell ? 'percentage-cell' : ''
    const isDateCell: boolean = type === TypesOfStudentCell.DateCell
    const dateCellClass: string = isDateCell ? 'date-cell' : ''
    const colorByPercent: string = this.returnColorDependsCellType(type)
    const colorByType: string = this.getClassByType(type, isProv, isPercentageCell)
    const maskStyles: any =
      colorByPercent || colorByType
        ? {
            backgroundColor: colorByPercent || colorByType,
            opacity: colorByPercent ? `${(percent || 0) / 100}` : 1,
          }
        : {}

    return (
      <td className={`student-progress-cell ${percentageClass} ${dateCellClass}`}>
        <div className='color-mask' style={maskStyles} />
        <div className={`student-progressbar-wrapper`}>
          <div className='student-progressbar-container'>
            <span className='student-progress-value'>{value}</span>
            {hasProgressbar && (
              <Progress className='student-progressbar' color='custom' value={percent} />
            )}
          </div>
        </div>
      </td>
    )
  }

  public getRoundPercentValue = (value: number) => {
    return Math.round(value)
  }

  public render() {
    const { localization, sortedStats } = this.props
    const { common, exam } = localization.data
    return (
      <tbody className='student-progress-table-body'>
        {sortedStats.map((stat: IStudentProgressStat) => {
          const typeOfExercise: string = stat.testModeEnabled ? exam : common
          const withHours: boolean = true
          const hasSec: boolean = false
          const lang: string = this.props.localization.data.languageTxt
          const timeOfExerciseCreating: string = returnDateOfCreation(
            stat.createdAt,
            lang,
            withHours,
            hasSec
          )
          const valueFirstAttempt: number = this.getRoundPercentValue(stat.firstAttempt)
          const valueAfterFirstAttempt: number = this.getRoundPercentValue(stat.afterFirstAttempt)
          const valueWrong: number = this.getRoundPercentValue(stat.wrong)
          const valueAnswered: number = this.getRoundPercentValue(
            100 - (stat.notAnswered + stat.wrong)
          )

          const valueNotAnswered: number = this.getRoundPercentValue(
            100 - (valueAnswered + stat.wrong)
          )

          return (
            <tr key={stat._id} id={stat._id} className='student-progress-row'>
              <th className='student-progress-firstcol-cell'>
                {stat.testModeEnabled && <div className='color-mask' />}
                <div className='student-progress-exercise-name'>{stat.exerciseName}</div>
              </th>

              {this.renderCell(
                typeOfExercise,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.TypeCell
              )}
              {this.renderCell(
                `${valueAnswered}%`,
                stat.testModeEnabled,
                true,
                TypesOfStudentCell.AnsweredCell,
                valueAnswered
              )}
              {this.renderCell(
                `${valueFirstAttempt}%`,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.FirstAttemptCell,
                valueFirstAttempt
              )}
              {this.renderCell(
                `${valueAfterFirstAttempt}%`,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.AfterFirstAttemptCell,
                valueAfterFirstAttempt
              )}
              {this.renderCell(
                `${valueWrong}%`,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.WrongCell,
                valueWrong
              )}
              {this.renderCell(
                `${valueNotAnswered}%`,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.NotAnsweredCell,
                valueNotAnswered
              )}
              {this.renderCell(
                timeOfExerciseCreating,
                stat.testModeEnabled,
                false,
                TypesOfStudentCell.DateCell
              )}
            </tr>
          )
        })}
      </tbody>
    )
  }
}
