import * as React from 'react'
import './Header.scss'

class Header extends React.Component<any, any> {
  public render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <div className='app-header-wrapper'>
        <div className='app-header'>{this.props.content}</div>
      </div>
    )
  }
}

export default Header
