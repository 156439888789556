import React from 'react'
import { SvgIconProps } from '../types/types'

const AttentionIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.71205 0C4.19894 0 0.536133 3.584 0.536133 8C0.536133 12.416 4.19894 16 8.71205 16C13.2252 16 16.888 12.416 16.888 8C16.888 3.584 13.2252 0 8.71205 0ZM8.71205 12.8C8.11248 12.8 7.62193 12.32 7.62193 11.7333C7.62193 11.1467 8.11248 10.6667 8.71205 10.6667C9.31161 10.6667 9.80217 11.1467 9.80217 11.7333C9.80217 12.32 9.31161 12.8 8.71205 12.8ZM9.96024 4.528L9.59505 8.80533C9.55689 9.25333 9.17535 9.6 8.71205 9.6C8.24875 9.6 7.8672 9.25333 7.82905 8.80533L7.46386 4.528C7.4039 3.81333 7.97622 3.2 8.71205 3.2C9.40973 3.2 9.96569 3.75467 9.96569 4.42667C9.96569 4.45867 9.96569 4.496 9.96024 4.528Z'
        fill={color}
      />
    </svg>
  )
}

export default AttentionIcon
