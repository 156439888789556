import React from 'react'
import { SvgIconProps } from '../types/types'

const SpeakerIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height='14'
      viewBox='0 0 20 14'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.90726 0.589272C9.23797 -0.474107 11.2438 0.444403 11.2438 2.11716V11.8828C11.2438 13.5556 9.23797 14.4741 7.90726 13.4107L4.295 10.5241H2.0398C0.91325 10.5241 0 9.63797 0 8.54482V5.45517C0 4.36202 0.913248 3.47586 2.0398 3.47586H4.295L7.90726 0.589272ZM9.55223 2.11716C9.55223 1.83157 9.20978 1.67475 8.98258 1.8563L5.35655 4.7539C5.0626 4.98879 4.69365 5.11724 4.31285 5.11724H2.0398C1.84746 5.11724 1.69154 5.26853 1.69154 5.45517V8.54482C1.69154 8.73146 1.84746 8.88275 2.0398 8.88275H4.31285C4.69365 8.88275 5.0626 9.0112 5.35655 9.24609L8.98258 12.1437C9.20978 12.3252 9.55223 12.1684 9.55223 11.8828V2.11716Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3821 2.17141C13.7123 1.85091 14.2479 1.85091 14.5782 2.17141C15.8909 3.44521 16.8159 5.18826 16.8159 7C16.8159 8.81174 15.8909 10.5548 14.5782 11.8286C14.2479 12.1491 13.7123 12.1491 13.3821 11.8286C13.0518 11.5081 13.0518 10.9885 13.3821 10.668C14.4574 9.62452 15.1244 8.27791 15.1244 7C15.1244 5.72209 14.4574 4.37548 13.3821 3.33204C13.0518 3.01154 13.0518 2.49191 13.3821 2.17141Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7701 0.240374C16.1004 -0.0801248 16.6359 -0.0801248 16.9662 0.240374C19.1042 2.31496 20 4.45087 20 7C20 9.54913 19.1042 11.685 16.9662 13.7596C16.6359 14.0801 16.1004 14.0801 15.7701 13.7596C15.4398 13.4391 15.4398 12.9195 15.7701 12.599C17.6122 10.8115 18.3085 9.08535 18.3085 7C18.3085 4.91465 17.6122 3.18849 15.7701 1.401C15.4398 1.08051 15.4398 0.560874 15.7701 0.240374Z'
        fill={color}
      />
    </svg>
  )
}

export default SpeakerIcon
