import React from 'react'
import styles from './AddYourFirstStudent.module.scss'
import { IModalActions } from '../../../redux/modules/modal.module'
import { IMe } from '../../../api/types.users'
import PlusIconButton from 'ui/Buttons/PlusIconButton/PlusIconButton'
import { ILocalization } from '../../../config/languages.config'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../ui/icons/PlusIcon/PlusIcon'
import useText from '../../../i18n/hook'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IAddYourFirstStudentProps {
  localization: ILocalization
  classOrGroup: IClass | IGroup
  modalActions: IModalActions
  me: IMe
}

const AddYourFirstStudent: React.FC<IAddYourFirstStudentProps> = ({
  classOrGroup,
  me,
  modalActions,
  localization,
}) => {
  const t = useText()
  const isClassView = 'invitationalCode' in classOrGroup
  const isRestrictedMunicipality = me?.municipality?.isPrivate

  const signUpStudent = () => {
    modalActions!.openModalRegister(me, localization!)
  }

  const openGroupStudentsList = () => {
    const students: any = classOrGroup?.students
    modalActions!.openGroupStudentAutosuggest(students, classOrGroup._id, localization, history)
  }

  const openClassStudentsList = () => {
    if (classOrGroup._id) modalActions!.openChooseStudentsModalUpdated(classOrGroup._id)
  }

  const openAddStudentModal = () => {
    if (isClassView) {
      if (isRestrictedMunicipality) {
        return openClassStudentsList()
      }
      return signUpStudent()
    }

    return openGroupStudentsList()
  }

  return (
    <div className={styles.Container}>
      <Button
        size={SIZE.LARGE}
        variant={BUTTON_VARIANT.PRIMARY}
        color={BUTTON_COLOR.ORANGE}
        onClick={openAddStudentModal}
        icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
        iconAlign={ICON_ALIGNMENT.RIGHT}
      >
        {t.addStudentTxt}
      </Button>
    </div>
  )
}

export default AddYourFirstStudent
