import React from 'react'
import useText from '../../../i18n/hook'
import Button from '../../../ui/Buttons/UIButton/Button'
import ClassOption from './Option/ClassOption'
import ExamOption from './Option/ExamOption'
import GroupsOption from './Option/GroupsOption'
import StudentsOption from './Option/StudentsOption'
import TopicsOption from './Option/TopicsOption'
import styles from './Students.module.scss'
import { OptionsType } from './StudentsFilter'
import Divider from '../../../ui/dividers/Divider/Divider'
import { BUTTON_VARIANT } from '@magmamath/ui'

type NonPracticePageProps = {
  students: OptionsType[]
  onResetBtnClick: () => void
}

const ExercisesPageFilterOptions = ({ students, onResetBtnClick }: NonPracticePageProps) => {
  const t = useText()
  return (
    <>
      <ClassOption />
      <ExamOption />
      <TopicsOption />
      <GroupsOption />
      <StudentsOption students={students} iShowTitle={true} />
      <div className={styles.ResetButtonWrapper}>
        <Divider />
        <Button
          className={styles.ResetButton}
          variant={BUTTON_VARIANT.TERTIARY}
          size='small'
          onClick={onResetBtnClick}
        >
          {t.reset}
        </Button>
      </div>
    </>
  )
}

export default ExercisesPageFilterOptions
