import { useUnit } from 'effector-react'
import { $exerciseSettings } from 'features/caf/exerciseSettings/model/exerciseSettings'
import { getClassroomSelectionInfo } from 'features/modals/ClassroomModal/helpers'
import { ClassesStoreItem } from 'features/modals/ClassroomModal/types'
import { hasDisplayName } from 'helpers/classesView.helpers'
import useText from 'i18n/hook'
import React, { useMemo } from 'react'
import SelectionSet from '../SelectionSet/SelectionSet'
import { IClass } from '../../../../../api/types.classes'

type SelectExerciseClassProps = {
  onParticipantsButtonClick: (changedClass: IClass) => void
  onClassButtonClick: (changedClass: IClass) => void
  classObject: IClass
  classSelection?: ClassesStoreItem
}

const SelectExerciseClass = ({
  onClassButtonClick,
  onParticipantsButtonClick,
  classObject,
  classSelection,
}: SelectExerciseClassProps) => {
  const isExamMode = useUnit($exerciseSettings).isExamMode
  const t = useText()

  const { hasSelectedStyles, selectedStudentsCount, classroomStudentsCount } = useMemo(
    () =>
      getClassroomSelectionInfo({
        classSelection,
        classObject,
      }),
    [classSelection, classObject]
  )

  const studentsQuantityText = !classroomStudentsCount
    ? t.allTxt
    : `${selectedStudentsCount} ${t.ofTxt} ${classroomStudentsCount}`

  return (
    <SelectionSet
      isSelected={hasSelectedStyles}
      onSelectionChange={() => onClassButtonClick(classObject)}
      label={hasDisplayName(classObject) ? classObject.displayName : classObject.name}
      studentsQuantityText={studentsQuantityText}
      onDropdownClick={() => onParticipantsButtonClick(classObject)}
      isExamMode={isExamMode}
    />
  )
}

export default SelectExerciseClass
