import { sortUserStatsByPinsOrder } from 'features/tables/HeatmapTable/helpers/sorting'
import { HeatmapSortOptions } from 'features/tables/HeatmapTable/model/heatmapSettings'
import { IAnswerInfo, IStudentStat } from 'api/types.solutions'
import isEqual from 'lodash/isEqual'
import { StatsFilter } from './model/model'
import { Pin } from '../../../api/types.pins'
import { MostCommonErrorProblemDetails } from '../../../api/types.statistics'

type GetPinnedAnswerArrayProps = {
  answerArray?: IAnswerInfo[]
  currentPins: Pin[]
}

export const getPinnedAnswerArray = ({ answerArray, currentPins }: GetPinnedAnswerArrayProps) => {
  return answerArray?.reduce<IAnswerInfo[]>((acc, answer, index) => {
    const pin = currentPins?.find((pin) => pin.solutionId === answer._id)
    if (pin) {
      acc.push({
        ...answer,
        selectedAttempt: {
          pin,
          attemptNumber: index + 1,
          solutionAnswerArray: answerArray,
        },
      })
    }
    return acc
  }, [])
}

type GetCommonErrorAnswerArrayProps = {
  answerArray?: IAnswerInfo[]
  solutionCommonErrorData: MostCommonErrorProblemDetails | null
}

export const getCommonErrorAnswerArray = ({
  answerArray,
  solutionCommonErrorData,
}: GetCommonErrorAnswerArrayProps) => {
  return answerArray?.reduce<IAnswerInfo[]>((acc, answer, index) => {
    if (isEqual(solutionCommonErrorData?.answer, answer.answer)) {
      const updatedAnswer = {
        ...answer,
        selectedAttempt: {
          attemptNumber: index + 1,
          solutionAnswerArray: answerArray,
        },
      }
      acc.push(updatedAnswer)
    }
    return acc
  }, [])
}

type GetFilteredStatsProps = {
  usersStats: IStudentStat[] | null
  statsFilterStore: StatsFilter
  sortOption?: HeatmapSortOptions
}

export const getFilteredStats = ({
  usersStats,
  statsFilterStore: { pinsData, isPinsFilterActive, commonErrorData, commonErrorFilterTask },
  sortOption,
}: GetFilteredStatsProps): IStudentStat[] => {
  if (!usersStats) return []
  if (!isPinsFilterActive && !commonErrorFilterTask) return usersStats

  const currentPins = pinsData.currentPinsData?.pins ?? []
  const solutionCommonErrorData =
    (commonErrorFilterTask && commonErrorData?.[commonErrorFilterTask]) || null

  let filteredStats = usersStats

  if (isPinsFilterActive) {
    filteredStats = filteredStats.reduce<IStudentStat[]>((acc, card) => {
      const updatedAnswerArray = getPinnedAnswerArray({
        answerArray: card.stats.answerArray,
        currentPins: pinsData.currentPinsData?.pins ?? [],
      })

      if (updatedAnswerArray?.length) {
        acc.push({
          ...card,
          stats: {
            ...card.stats,
            answerArray: updatedAnswerArray,
          },
        })
      }

      return acc
    }, [])
  }

  if (solutionCommonErrorData) {
    filteredStats = filteredStats.reduce<IStudentStat[]>((acc, { stats, ...cardRest }) => {
      if (!solutionCommonErrorData.studentIds.includes(cardRest.studentId)) return acc

      const updatedAnswerArray = getCommonErrorAnswerArray({
        answerArray: stats.answerArray,
        solutionCommonErrorData,
      })

      if (updatedAnswerArray?.length) {
        acc.push({
          ...cardRest,
          stats: {
            ...stats,
            answerArray: updatedAnswerArray,
          },
        })
      }

      return acc
    }, [])
  }

  if (sortOption === HeatmapSortOptions.PINNED_SOLUTIONS) {
    filteredStats = sortUserStatsByPinsOrder(filteredStats, currentPins)
  }

  return filteredStats
}
