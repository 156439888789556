import React, { FC, useState } from 'react'
import styles from './NumpadUnits.module.scss'
import Units from '../Units/Unit'
import { currentLocale } from '../../../../config/modules.config'
import { LOCALIZED_UNITS_SYMBOLS, UNITS_BUTTONS, UnitsTypes } from '../Symbols/Symbols'
import clsx from 'clsx'

interface NumpadVariantProps {
  onPress: (value: string) => void
  isButtonDisabled: boolean
}

const NumpadUnits: FC<NumpadVariantProps> = ({ onPress, isButtonDisabled }) => {
  const [currentUnit, setCurrentUnit] = useState(UnitsTypes.LENGTH)
  return (
    <div className={styles.Container}>
      {currentLocale.currentLang && (
        <>
          <div className={styles.Row}>
            {UNITS_BUTTONS.map(({ title, icon, fullWidth }, index) => (
              <button
                key={index}
                onClick={() => setCurrentUnit(title)}
                className={clsx(styles.Btn, { [styles.ActiveBtn]: currentUnit === title })}
              >
                {icon}
              </button>
            ))}
          </div>

          <Units
            units={LOCALIZED_UNITS_SYMBOLS[currentLocale.currentLang][currentUnit]}
            isButtonDisabled={isButtonDisabled}
            onPress={onPress}
          />
        </>
      )}
    </div>
  )
}

export default NumpadUnits
