import TabMultipleChoiceAnswer from './multichoice/Tab.MultipleChoiceAnswer'
import { TabDescriptor } from './types'
import TabNumericAnswer from './TabNumericAnswer'
import TabAlgebraAnswer from './TabAlgebraAnswer'

export const ANSWER_CREATING_TABS: TabDescriptor[] = [
  {
    Component: TabNumericAnswer,
    label: (t) => t.numeric,
  },
  {
    Component: TabAlgebraAnswer,
    label: (t) => t.algebra,
  },
  {
    Component: TabMultipleChoiceAnswer,
    label: (t) => t.multipleChoice,
  },
]
