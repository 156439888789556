import { AnswerType } from '../../../helpers/enums'
import { ILocalization } from '../../../config/languages.config'

const DOLLAR_SIGN = '$'
export const removeDollarSign = (title: string) => title.replaceAll(DOLLAR_SIGN, '')

export const ANSWER_TYPE_LOCALIZATION_MAP: Partial<
  Record<AnswerType, (localization: ILocalization['data']) => string>
> = {
  [AnswerType.handwriting]: (t) => t.handWritingAnswer,
  [AnswerType.singleChoice]: (t) => t.singleSelectAnswer,
  [AnswerType.multipleChoice]: (t) => t.multiSelectAnswer,
  [AnswerType.ordered]: (t) => t.selectInOrderAnswer,
}
