import { IDispatch } from '../interfaces/dispatch.interface'
import { api } from '../api'

export const asyncValidateUsername = async (values: any, dispatch: IDispatch<any>, props: any) => {
  const { alreadyExistUsername } = props.localization.data
  const response = await api.auth.usernameCheck(values)
  if (!response.available || !response.valid) {
    throw { username: alreadyExistUsername }
  }
}

export const asyncValidateUsernameReverse = async (
  values: any,
  dispatch: IDispatch<any>,
  props: any
) => {
  const { invalidUsername } = props.localization.data
  const response = await api.auth.usernameCheck(values)

  if (response.available || !response.valid) {
    throw { username: invalidUsername }
  }
}
