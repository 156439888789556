import React from 'react'
import { AddStudentStage } from '../ModalRegisterWindow.component'
import { ModalHeader, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import './StageHeader.scss'
import { ILocalization } from "../../../../config/languages.config";

type StageHeaderProps = {
  localization: ILocalization
  activeStage: AddStudentStage
  setActiveStage: (tabId: AddStudentStage) => void
}

const StageHeader = ({ localization, activeStage, setActiveStage }: StageHeaderProps) => {
  const { addManuallyTxt, classcode, sendClasslist } = localization.data

  return (
    <ModalHeader className='custom-header register-header'>
      <Nav>
        <NavItem>
          <NavLink
            className={classnames({ active: activeStage === AddStudentStage.DIRECT })}
            onClick={() => setActiveStage(AddStudentStage.DIRECT)}
          >
            {addManuallyTxt}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeStage === AddStudentStage.CODE })}
            onClick={() => setActiveStage(AddStudentStage.CODE)}
          >
            {classcode}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeStage === AddStudentStage.LIST })}
            onClick={() => setActiveStage(AddStudentStage.LIST)}
          >
            {sendClasslist}
          </NavLink>
        </NavItem>
      </Nav>
    </ModalHeader>
  )
}

export default StageHeader
