import React from 'react'
import styles from './SidebarProblemsSettings.module.scss'
import SidebarProblemsSettingsHeader from './SidebarProblemsSettingsHeader/SidebarProblemsSettingsHeader'
import SidebarProblemsSettingsFooter from './SidebarProblemsSettingsFooter/SidebarProblemsSettingsFooter'

type SidebarProblemsSettingsProps = {
  isLoading: boolean
  onClose: () => void
  onPrint: () => void
}
const SidebarProblemsSettings = ({ isLoading, onClose, onPrint }: SidebarProblemsSettingsProps) => {
  return (
    <div className={styles.Container}>
      <SidebarProblemsSettingsHeader />
      <SidebarProblemsSettingsFooter onClose={onClose} onPrint={onPrint} isPrinting={isLoading} />
    </div>
  )
}
export default SidebarProblemsSettings
