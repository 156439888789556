import { BUTTON_VARIANT, Button, COLORS, SIZE } from '@magmamath/ui'
import useText from 'i18n/hook'
import React from 'react'
import ArrowDownIcon from 'ui/icons/ArrowSelectIcon/ArrowSelectIcon'
import styles from './AccordionTogglePanel.module.scss'

type AccordionTogglePanelProps = {
  isOpen: boolean
  toggleOpen: () => void
}

const AccordionTogglePanel = ({ isOpen, toggleOpen }: AccordionTogglePanelProps) => {
  const t = useText()
  return (
    <div className={styles.TogglePanel} onClick={toggleOpen}>
      {isOpen ? (
        <ArrowDownIcon className={styles.PanelSwitch} color={COLORS.NEUTRAL_7} size={20} />
      ) : (
        <Button
          classes={{ button: styles.PanelSwitch }}
          size={SIZE.SMALL}
          variant={BUTTON_VARIANT.TERTIARY}
        >
          {t.moreTxt}
        </Button>
      )}
    </div>
  )
}

export default AccordionTogglePanel
