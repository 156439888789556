import '@magmamath/ui/dist/style.css'
import '@magmamath/ui/dist/variables.scss'

import ErrorBoundary from 'components/Error/Error.component'
import { PATHS } from 'config/pathnames.config'
import App from 'containers/App/App.container'
import { Events } from 'helpers/enums'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { StaticContext } from 'react-router'
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom'
import './index.scss'
import store from './store/store'
import { ThemeProvider } from '@mui/material'
import { theme } from './assets/themes/theme'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { initLocale } from 'config/modules.config'

initLocale()

document.addEventListener(Events.DOM_CONTENT_LOADED, () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Route
          path={PATHS.MAIN}
          render={(
            props: RouteComponentProps<{ [x: string]: string | undefined }, StaticContext, unknown>
          ) => (
            // @ts-ignore
            <ErrorBoundary {...props}>
              <ThemeProvider theme={theme}>
                <App {...props} />
              </ThemeProvider>
            </ErrorBoundary>
          )}
        />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
  )
})

serviceWorkerRegistration.register()
