import { sortABC } from 'helpers/sort.helpers'
import { INamedDocument } from 'interfaces/basic.interfaces'
import { IState } from 'interfaces/state.interface'
import { IBasicUser, IStudent } from 'api/types.users'
import _ from 'lodash'
import React from 'react'
import './StudentsListDropdown.scss'
import { IClass } from '../../../../api/types.classes'
import { IClassesStudents } from '../../../../api/types.classesStudents'

export interface IStudentsListDropdownMenuProps {
  classesStudents: IState<IClassesStudents>
  className: string
  history: any
}

export class StudentsListDropdownMenu extends React.Component<
  IStudentsListDropdownMenuProps,
  any,
  any
> {
  constructor(props: IStudentsListDropdownMenuProps) {
    super(props)
    this.state = {
      allStudents: [],
    }
  }

  public componentDidMount() {
    if (!_.isEmpty(this.props.classesStudents.data)) {
      const allClassesStudents: IStudent[] = (this.props.classesStudents.data as any).map(
        (classEl: IClass) => {
          return classEl.students
        }
      )
      const flattenStudents: (INamedDocument | IBasicUser)[] = _.flattenDeep(allClassesStudents)
      const sortedStudents: (INamedDocument | IBasicUser)[] = sortABC(flattenStudents) || []
      this.setState({ allStudents: sortedStudents })
    }
  }

  public render() {
    return (
      <div className={this.props.className}>
        <ul className='students-progress-list'>
          {!_.isEmpty(this.state.allStudents) &&
            this.state.allStudents.map((student: IStudent) => {
              return (
                <li key={student._id}>
                  <a href={`/student-progress/${student._id}`}>
                    <span>{student.firstName}</span> <span>{student.lastName}</span>
                  </a>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
}
