import React from 'react'
import Markdown from '../../../../../components/RichDescription/RichDescription.component'
import { RenderedKaTeX } from '../../../../../components/KaTeXDescription/KaTeXDescription.component'

import { IProblem, ISubProblem } from '../../../../../api/types.problem'

type PrintProblemsDescription = {
  problem: IProblem | ISubProblem
}
const PrintProblemsDescription = ({
  problem: { richDescription, laTeXDescription, description },
}: PrintProblemsDescription) => {
  if (richDescription) {
    return <Markdown>{richDescription}</Markdown>
  }
  if (laTeXDescription) {
    return <RenderedKaTeX laTeXDescription={laTeXDescription} />
  }
  return <div>{description}</div>
}

export default PrintProblemsDescription
