import { IState } from '../../../interfaces/state.interface'
import { IModal, IModalActions } from '../../../redux/modules/modal.module'
import { IUser } from '../../../api/types.users'
import CommonInputModal from '../../../ui/inputs/CommonInputModal/CommonInputModal'
import Button from '../../../ui/Buttons/UIButton/Button'
import CommonModalWrapper from '../../../ui/modals/CommonModalWrapper/CommonModalWrapper'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { ModalType } from '../../../helpers/enums'
import { changeStudentsPassword } from '../../../redux/modules/student.module'
import { useDispatch } from 'react-redux'
import { INotify } from '../../../redux/modules/notify.module'
import { ILocalization } from "../../../config/languages.config";

interface IChangePasswordPopupProps {
  serverErrors?: { message: string }
  account: IState<any>
  localization: ILocalization
  modal: IState<IModal>
  modalActions: IModalActions
  user?: IState<IUser>
  notification: INotify
  history: History
}

const ChangePasswordPopup: React.FC<IChangePasswordPopupProps> = ({
  localization,
  modal,
  modalActions,
}) => {
  const {
    saveTxt,
    resetPassTxt,
    newPassTxt,
    newPassConfirmTxt,
    mustBeGreatPassword,
    passDontMatch,
    requiredFieldText,
  } = localization!.data
  const opened: boolean = modal.data.modalType === ModalType.ChangePasswordModal
  const onClose = () => modalActions!.closeModalPassword()

  const dispatch = useDispatch()

  const [passwordValue, setPasswordValue] = useState('')
  const [confirmedPasswordValue, setConfirmedPasswordValue] = useState('')
  const [errorMessagePasswordField, setErrorMessagePasswordField] = useState('')
  const [errorMessageConfirmField, setErrorMessageConfirmField] = useState('')

  const onKeyPressHandler = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit()
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', onKeyPressHandler)
    return () => document.removeEventListener('keypress', onKeyPressHandler)
  }, [onKeyPressHandler])

  const inputItemsLimit = 6

  const showErrorMessageForPasswordField = () => {
    if (passwordValue.trim().length === 0) {
      setErrorMessagePasswordField(requiredFieldText)
      return requiredFieldText
    }
    if (passwordValue.length < inputItemsLimit) {
      setErrorMessagePasswordField(mustBeGreatPassword)
      return requiredFieldText
    }
    setErrorMessagePasswordField('')
    return ''
  }

  const showErrorMessageConfirmField = () => {
    if (confirmedPasswordValue.trim().length === 0) {
      setErrorMessageConfirmField(requiredFieldText)
      return requiredFieldText
    }
    if (confirmedPasswordValue.length < inputItemsLimit) {
      setErrorMessageConfirmField(mustBeGreatPassword)
      return mustBeGreatPassword
    }
    if (passwordValue !== confirmedPasswordValue) {
      setErrorMessageConfirmField(passDontMatch)
      return passDontMatch
    }
    setErrorMessageConfirmField('')
    return ''
  }

  const onChangePasswordHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setPasswordValue(e.currentTarget.value)
    setErrorMessagePasswordField('')
  }
  const onChangeConfirmHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setConfirmedPasswordValue(e.currentTarget.value)
    setErrorMessageConfirmField('')
  }

  const submit = () => {
    const hasError = showErrorMessageForPasswordField() || showErrorMessageConfirmField()
    if (hasError) {
      return
    }

    dispatch(
      changeStudentsPassword(
        {
          username: modal!.data.username,
          password: passwordValue,
          passwordConfirmation: confirmedPasswordValue,
        },
        localization
      )
    )
  }

  return (
    <CommonModalWrapper title={resetPassTxt} open={opened} onClose={onClose}>
      <CommonInputModal
        placeholder={newPassTxt}
        value={passwordValue}
        onChange={onChangePasswordHandler}
        errorMessage={errorMessagePasswordField}
        onBlur={showErrorMessageForPasswordField}
      />
      <CommonInputModal
        placeholder={newPassConfirmTxt}
        value={confirmedPasswordValue}
        onChange={onChangeConfirmHandler}
        autoFocus={false}
        errorMessage={errorMessageConfirmField}
        onBlur={showErrorMessageConfirmField}
      />
      <Button variant='primary' size='medium' color='green' onClick={submit}>
        {saveTxt}
      </Button>
    </CommonModalWrapper>
  )
}

export default ChangePasswordPopup
