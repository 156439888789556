import React from 'react'
import { SvgIconProps } from '../types/types'

const CorrectIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 1.69154C5.41137 1.69154 1.69154 5.41137 1.69154 10C1.69154 14.5886 5.41137 18.3085 10 18.3085C14.5886 18.3085 18.3085 14.5886 18.3085 10C18.3085 5.41137 14.5886 1.69154 10 1.69154ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.1283 6.56828C14.4849 6.87 14.5294 7.40367 14.2277 7.76025L10.0236 12.7287C9.61617 13.2102 8.88845 13.252 8.42861 12.8203L5.83896 10.3892C5.4984 10.0695 5.4815 9.53425 5.8012 9.19369C6.12091 8.85314 6.65616 8.83623 6.99671 9.15594L9.1335 11.1619L12.9364 6.66761C13.2381 6.31103 13.7717 6.26656 14.1283 6.56828Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default CorrectIcon
