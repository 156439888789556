import littleSpinner from 'assets/img/spinner-grey.svg'
import classnames from 'classnames'
import * as React from 'react'
import { ReactNode } from 'react'
import { Button, ButtonProps } from 'reactstrap'
import './ButtonLoader.component.scss'

export interface IButtonLoaderProps extends ButtonProps {
  loading: boolean
  ref?: React.RefObject<Button<{ children: Element | ReactNode }>>
}

export function ButtonLoader(props: IButtonLoaderProps) {
  const { loading, ref, className, disabled, ...rest } = props

  return (
    <Button
      {...rest}
      className={classnames(className, 'button-loader', { loading })}
      ref={ref}
      disabled={disabled || loading}
    >
      {props.children}
      {loading && <img src={littleSpinner} className='button-loader-spinner' />}
    </Button>
  )
}
