import io from 'socket.io-client'
import { config } from '../config'
import { getItemFromLocalStorage } from './storage.helper'
import { localStorageKeys } from '../config/localStorageKeys'

const socket = io(config.SOCKET_HOST, {
  reconnection: true,
  reconnectionDelay: 500,
  transports: ['websocket'],
  query: {
    token: getItemFromLocalStorage(localStorageKeys.token)?.replace('JWT ', ''),
  },
})

export default socket

export const setTokenForSocket = (token: string) => {
  // @ts-ignore
  socket.io.opts.query.token = token?.replace('JWT ', '')
  socket.disconnect()
  socket.connect()
}
