import ClassroomSelector from 'features/caf/classroomSelector/ui/ClassroomSelector/ClassroomSelector'
import React, { useEffect, useRef, useState } from 'react'
import Sidebar from 'ui/UISidebar/Sidebar'
import NameInput from '../NameInput/NameInput'
import SettingsAccordion from '../SettingsAccordion/SettingsAccordion'
import { IExercise } from '../../../../../api/types.assignments'
import { IClass } from '../../../../../api/types.classes'

type SettingsPanelProps = {
  exercise?: IExercise
  classrooms: IClass[]
  isDuplicateExercise?: boolean
}

const SettingsPanel = ({ classrooms, exercise, isDuplicateExercise }: SettingsPanelProps) => {
  const selectorRef = useRef<HTMLDivElement>(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(true)

  useEffect(() => {
    const selectorScrollHeight = selectorRef.current?.scrollHeight || 0
    const selectorClientHeight = selectorRef.current?.clientHeight || 0
    const hasSelectorScroll = selectorScrollHeight > selectorClientHeight
    setIsAccordionOpen(!hasSelectorScroll)
  }, [])

  return (
    <Sidebar>
      <NameInput />
      <ClassroomSelector
        classrooms={classrooms}
        isSearchAvailable={!exercise || !!isDuplicateExercise}
        ref={selectorRef}
      />
      <SettingsAccordion
        isOpen={isAccordionOpen}
        onToggle={() => setIsAccordionOpen((prevState) => !prevState)}
        exercise={exercise}
      />
    </Sidebar>
  )
}

export default SettingsPanel
