import React from 'react'
import { COLORS } from '@magmamath/ui'

import { SvgIconProps } from '../types/types'

const ArrowLeftDefaultIcon: React.FC<SvgIconProps> = ({ size = 12, color = COLORS.NEUTRAL_9 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 8 12'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.11872 0.381282C7.46043 0.72299 7.46043 1.27701 7.11872 1.61872L2.73744 6L7.11872 10.3813C7.46043 10.723 7.46043 11.277 7.11872 11.6187C6.77701 11.9604 6.22299 11.9604 5.88128 11.6187L0.881282 6.61872C0.539573 6.27701 0.539573 5.72299 0.881282 5.38128L5.88128 0.381282C6.22299 0.0395728 6.77701 0.0395728 7.11872 0.381282Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowLeftDefaultIcon
