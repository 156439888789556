import React, { useEffect, useState } from 'react'
import styles from './ClassroomShareModal.module.scss'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import TextField from '@mui/material/TextField'
import useText from 'i18n/hook'
import { useDispatch } from 'react-redux'
import { clearExercise, shareExercise } from 'redux/modules/exercise.module'
import UIModal from 'ui/modals/UIModal/UIModal'
import { INPUT_STYLES } from './InputStyles'
import SearchIcon from '../../../ui/icons/SearchIcon/AccountIcon'
import { $teachersClasses, fetchTeachersClassesFx } from './model'
import { useStoreMap } from 'effector-react'
import Divider from '../../../ui/dividers/Divider/Divider'
import { filterAndSortTeachersClasses } from './filterAndSortTeachersClasses'
import TeacherClassroom from './TeacherClassroom/TeacherClassroom'
import { IExercise } from '../../../api/types.assignments'
import { TeacherClass } from '../../../api/types.teachers'

type ClassroomShareModalProps = {
  isOpen: boolean
  onClose: () => void
  exercise: IExercise
}

const ClassroomShareModal = ({ isOpen, onClose, exercise }: ClassroomShareModalProps) => {
  const t = useText()
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [selectedClassroomsIds, setSelectedClassroomsIds] = useState(() => new Set<string>())
  const teachersClasses = useStoreMap({
    store: $teachersClasses,
    keys: [searchInputValue],
    fn: (classes) => filterAndSortTeachersClasses(classes, searchInputValue),
  })
  const onClassroomItemClick = (classroomId: string) => {
    const isSelected = selectedClassroomsIds.has(classroomId)
    const newSelectedClassroomsIds = new Set(selectedClassroomsIds)
    isSelected
      ? newSelectedClassroomsIds.delete(classroomId)
      : newSelectedClassroomsIds.add(classroomId)
    setSelectedClassroomsIds(newSelectedClassroomsIds)
  }

  const onSave = async () => {
    setIsSubmitting(true)
    const exerciseId = exercise._id
    const isSuccess = await dispatch(
      shareExercise(selectedClassroomsIds, exerciseId, {
        success: t.shareClassroomSuccess,
        error: t.shareClassroomSuccess,
      })
    )
    if (!!isSuccess) {
      onClose()
      dispatch(clearExercise())
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    fetchTeachersClassesFx()
  }, [])

  return (
    <UIModal
      open={isOpen}
      onClose={onClose}
      wrapperClassName={styles.ModalWrapper}
      closeIconWrapperClassName={styles.CloseModalIcon}
    >
      <>
        <UIModal.Header className={styles.ModalHeader}>
          <UIModal.Title className={styles.ModalHeading}>{t.shareWithTxt}</UIModal.Title>
        </UIModal.Header>
        <UIModal.Content className={styles.ModalBody}>
          <TextField
            fullWidth
            placeholder={t.searchTxt}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
            InputProps={INPUT_STYLES}
          />
          <div className={styles.IconWrapper}>
            <SearchIcon color={'var(--neutral-9)'} />
          </div>
          <ul className={styles.List}>
            {teachersClasses.map((teacher, index) => (
              <React.Fragment key={teacher._id}>
                <li
                  className={styles.ListItemHeading}
                >{`${teacher.firstName} ${teacher.lastName}`}</li>
                <ul>
                  {teacher.classes.map((classroom: TeacherClass) => {
                    const isSelected = selectedClassroomsIds.has(classroom._id)
                    return (
                      <TeacherClassroom
                        key={classroom._id}
                        id={classroom._id}
                        isSelected={isSelected}
                        name={classroom?.displayName || classroom.name}
                        onClassroomItemClick={onClassroomItemClick}
                      />
                    )
                  })}
                </ul>
                {index !== teachersClasses.length - 1 && (
                  <Divider key={teacher._id} className={styles.Divider} />
                )}
              </React.Fragment>
            ))}
          </ul>
        </UIModal.Content>

        <UIModal.Footer className={styles.ModalFooter}>
          <Button size={SIZE.MEDIUM} variant={BUTTON_VARIANT.TERTIARY} onClick={onClose}>
            {t.cancelTxt}
          </Button>
          <Button
            disabled={selectedClassroomsIds.size === 0}
            size={SIZE.LARGE}
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onSave}
            isLoading={isSubmitting}
          >
            {t.saveTxt}
          </Button>
        </UIModal.Footer>
      </>
    </UIModal>
  )
}

export default ClassroomShareModal
