import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from 'store/store'
import { RemovableTypes } from '../../../helpers/enums'
import { IState } from '../../../interfaces/state.interface'
import { IMe, ITeacher } from '../../../api/types.users'
import { shouldShowBtn } from '../../../lib/permissions'
import { unfollowTeacherFromClass } from '../../../redux/modules/class.module'
import {
  openArchiveClassModal,
  openSimpleConfirmModal,
  openTeachAutosuggest,
} from '../../../redux/modules/modal.module'
import { getTeachersBySchool } from '../../../redux/modules/schoolTeachers.module'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import ShareIcon from '../../../ui/icons/ShareIcon/ShareIcon'
import ButtonContentUI from '../../../ui/tables/ChangeMultiplicityTableUI/ButtonContentUI/ButtonContentUI'
import ChangeMultiplicityTableUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTable'
import ChangeMultiplicityTableRowUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTableUIRow/ChangeMultiplicityTableRowUI'
import styles from './ChangeMultiplicityAccess.module.scss'
import ClassroomShareModal from 'features/modals/ClassroomShareModal/ClassroomShareModal'
import { getSyncLevel, SyncLevel } from '../../../helpers/classesView.helpers'
import { Button, BUTTON_VARIANT, SIZE, BUTTON_COLOR, ICON_ALIGNMENT } from '@magmamath/ui'
import { IExercise } from '../../../api/types.assignments'
import { IClass, ISchoolClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IChangeMultiplicityAccessProps {
  classOrGroup: IClass | IGroup
  teachers: ITeacher[]
  me: IMe
}

const ChangeMultiplicityAccess: React.FC<IChangeMultiplicityAccessProps> = ({
  classOrGroup,
  teachers,
  me,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const schoolClasses: ISchoolClass[] = useSelector((state: RootState) => state.schoolClasses.data)
  const exercise: IExercise = useSelector((state: RootState) => state.exercise.data)
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data

  const [isShareClassroomModalOpen, setIsShareClassroomModalOpen] = useState(false)

  const iShowForClassesTab = 'invitationalCode' in classOrGroup
  const updatedMultiplicity = {
    data: {
      ...classOrGroup,
      teachers: classOrGroup.teachers.map((item) => item._id ?? item),
    },
  }
  const syncLevel = iShowForClassesTab ? getSyncLevel(me, classOrGroup) : null

  const showFirstRow = () => {
    if (!iShowForClassesTab) {
      return true
    }
    return syncLevel !== SyncLevel.FULL_SYNC
  }

  const openTeachersModal = async () => {
    const schoolTeachers = teachers?.length
      ? teachers
      : await getTeachersBySchool(me.school._id)(dispatch)
    //TODO: we dont get value of teacher from prop, we get teachers from groups, so we dont need this request
    //and we dont need to pass data
    //moreover, we take schoolTeachers.data, but there is no data in schoolTeachers
    //@ts-ignore
    openTeachAutosuggest(schoolTeachers.data, RemovableTypes.Class, classOrGroup._id)(dispatch)
  }

  return (
    <div>
      <ChangeMultiplicityTableUI>
        {showFirstRow() && (
          <ChangeMultiplicityTableRowUI title={t.shareWithTeachers}>
            <Button
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZE.MEDIUM}
              color={BUTTON_COLOR.ORANGE}
              onClick={openTeachersModal}
              icon={<ShareIcon color='var(--icon-color)' size={20} />}
            >
              {iShowForClassesTab ? t.shareClassTxt : t.shareGroupTxt}
            </Button>
            {isShareClassroomModalOpen && (
              <ClassroomShareModal
                isOpen={isShareClassroomModalOpen}
                onClose={() => setIsShareClassroomModalOpen(false)}
                exercise={exercise}
              />
            )}
          </ChangeMultiplicityTableRowUI>
        )}
        {iShowForClassesTab && (
          <>
            {syncLevel !== SyncLevel.FULL_SYNC && (
              <ChangeMultiplicityTableRowUI title={t.unfollowTxt}>
                <Button
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZE.MEDIUM}
                  onClick={() =>
                    dispatch(
                      unfollowTeacherFromClass(
                        updatedMultiplicity as IState<IClass>,
                        me,
                        localization,
                        history
                      )
                    )
                  }
                >
                  {t.unfollowClass}
                </Button>
              </ChangeMultiplicityTableRowUI>
            )}
            {shouldShowBtn(me.municipality.isPrivate, classOrGroup.source) && (
              <ChangeMultiplicityTableRowUI title={t.archiveForAll}>
                <Button
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZE.MEDIUM}
                  color={BUTTON_COLOR.RED}
                  onClick={() => {
                    dispatch(openArchiveClassModal(classOrGroup))
                  }}
                >
                  {t.archiveClass}
                </Button>
              </ChangeMultiplicityTableRowUI>
            )}
          </>
        )}
        {!iShowForClassesTab && (
          <ChangeMultiplicityTableRowUI
            title={iShowForClassesTab ? t.archiveForAll : t.removeGroupTxt}
          >
            <UIButton
              className={styles.BtnWithoutBackgroundRed}
              onClick={() => {
                dispatch(openSimpleConfirmModal(classOrGroup, RemovableTypes.Group))
              }}
            >
              <ButtonContentUI>
                <div>{t.removeGroupTxt}</div>
              </ButtonContentUI>
            </UIButton>
          </ChangeMultiplicityTableRowUI>
        )}
      </ChangeMultiplicityTableUI>
    </div>
  )
}

export default ChangeMultiplicityAccess
