import React from 'react'
import { forwardRef } from 'react'
import { BUTTON_VARIANT, ButtonProps, SIZE, Button, COLORS, BUTTON_COLOR } from '@magmamath/ui'
import InfoIcon from 'ui/icons/InfoIcon/InfoIcon'

const ColorIndexButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const isActive = !!props['aria-expanded']
  return (
    <Button
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      color={BUTTON_COLOR.BLUE}
      isActive={isActive}
      icon={<InfoIcon color='var(--icon-color)' size={20} />}
      {...props}
      ref={ref}
    />
  )
})

export default ColorIndexButton
