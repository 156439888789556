import { useEffect } from 'react'

type UseHiddenIntercomProps = {
  isHidden: boolean
}

const INTERCOM_CLASS = 'intercom-lightweight-app-launcher'
const INTERCOM_ID = 'intercom-container'

const useHiddenIntercom = ({ isHidden }: UseHiddenIntercomProps) => {
  useEffect(() => {
    const intercomElements = document.querySelectorAll(`.${INTERCOM_CLASS}`)
    intercomElements.forEach((element) => {
      ;(element as HTMLElement).style.visibility = isHidden ? 'hidden' : ''
    })

    const openIntercomElement = document.getElementById(INTERCOM_ID)
    if (!openIntercomElement) return
    openIntercomElement.style.visibility = isHidden ? 'hidden' : ''
  }, [isHidden])

  return null
}

export default useHiddenIntercom
