import axios from 'axios'
import { UKDictionary, englishDictionary, swedishDictionary } from 'config/languages.config'
import { localStorageKeys } from 'config/localStorageKeys'
import { ipConfigUrl } from 'config/urls'
import { LocaleData } from 'helpers/enums'
import { saveLanguageInStorage } from 'helpers/locale.helpers'
import { setItemToLocalStorage } from 'helpers/storage.helper'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

export enum LOCALIZE {
  US = 'US',
  SE = 'SE',
  GB = 'GB',
}

export const LOCALE_LANGUAGE = {
  [LocaleData.US]: LOCALIZE.US,
  [LocaleData.SE]: LOCALIZE.SE,
  [LocaleData.GB]: LOCALIZE.GB,
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function localizationReducer(state: any = initialState, action: IAction<LOCALIZE>) {
  switch (action.type) {
    case LOCALIZE.US:
      return {
        data: englishDictionary,
        error: action.payload,
        loading: false,
      }
    case LOCALIZE.SE:
      return {
        data: swedishDictionary,
        error: action.payload,
        loading: false,
      }
    case LOCALIZE.GB:
      return {
        data: UKDictionary,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function setDefaultLanguage() {
  return async (dispatch: IDispatch<any>) => {
    try {
      const res = await axios.get(ipConfigUrl)
      const dictionary: { [key in LOCALIZE]: LOCALIZE } = {
        US: LOCALIZE.US,
        SE: LOCALIZE.SE,
        GB: LOCALIZE.GB,
      }
      const localization: LOCALIZE = dictionary[res.data.country] || LOCALIZE.US
      setItemToLocalStorage(localStorageKeys.language, LOCALIZE[localization])
      saveLanguageInStorage(localization)
      dispatch({ type: localization })
    } catch (error) {
      setDefaultLanguage()
      dispatch({
        type: LOCALIZE.SE,
      })
    }
  }
}

export function setUS() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: LOCALIZE.US })
    saveLanguageInStorage(LOCALIZE.US)
  }
}

export function setSwedish() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: LOCALIZE.SE })
    saveLanguageInStorage(LOCALIZE.SE)
  }
}

export function setBritish() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: LOCALIZE.GB })
    saveLanguageInStorage(LOCALIZE.GB)
  }
}

export interface ILocalizationActions {
  setDefaultLanguage: () => void
  setUS: () => void
  setSwedish: () => void
  setBritish: () => void
}
