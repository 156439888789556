import { FRACTION_DETECT_REGEXP, POWER_DETECT_REGEXP, QUILL_ERRORS } from './helpers'
import Quill from 'quill'
import { MathInputModel } from '../mathInput/MathInputModel'
import { ExtendedQuill } from './types.quill'
import { QUILL_INPUT_COMMANDS } from '../mathInput/commands'
type Delta = ReturnType<Quill['getContents']>

type MathCommandsListenerMethods = {
  onFormulaDetect?: (latex: string) => void
}

type SymbolReplacerSettings = {
  replaceWith?: string
  addSpaces?: boolean
}

type TwoSideFormulaSettings = {
  command: string
  regExp: RegExp
}

const createSymbolReplacer = (
  originalSymbol: string,
  { replaceWith = originalSymbol, addSpaces }: SymbolReplacerSettings = {}
) => {
  return (quill: ExtendedQuill) => {
    const index = quill.getSelection()?.index || 0
    const leafText: string = quill.getLeaf(index)[0]?.text || ''
    const [_, left = '', right = ''] =
      leafText.match(new RegExp(`/(\w)?\\${originalSymbol}(\w)?/`)) || []
    quill.deleteText(index - 1, 1)
    const toReplace = addSpaces
      ? `${left === ' ' ? '' : ' '}${replaceWith}${right === ' ' ? '' : ' '}`
      : replaceWith
    quill.insertText(index - 1, toReplace)
    setTimeout(() => {
      quill.setSelection(index + toReplace.length - 1, 0)
    })
  }
}
export class MathCommandsListener {
  private readonly input: MathInputModel
  private quill: ExtendedQuill | null = null

  private readonly onFormulaDetect: (initialCommand: string) => void

  public constructor(
    input: MathInputModel,
    { onFormulaDetect = () => {} }: MathCommandsListenerMethods = {}
  ) {
    this.input = input
    this.onFormulaDetect = onFormulaDetect
  }

  public setUp(quill: ExtendedQuill) {
    this.quill = quill
  }

  public detach() {
    this.quill = null
  }

  private readonly startTwoSideFormula = (
    quill: ExtendedQuill,
    { command, regExp }: TwoSideFormulaSettings
  ) => {
    const index = quill.getSelection()?.index || 0
    const leafText: string = quill.getLeaf(index)[0]?.text || ''
    const [_, left = '', right = ''] = leafText.match(regExp) || []
    quill.deleteText(index - left.length - 1, left.length + right.length + 1)
    this.onFormulaDetect(command)
    return [left, right]
  }

  private readonly handleSlash = (quill: ExtendedQuill) => {
    const [numerator, denominator] = this.startTwoSideFormula(quill, {
      command: QUILL_INPUT_COMMANDS.FRAC,
      regExp: FRACTION_DETECT_REGEXP,
    })
    if (numerator) {
      this.input.insertValue(numerator)
      this.input.key.down()
      if (denominator) {
        this.input.insertValue(denominator)
      }
    }
  }

  private readonly handlePower = (quill: ExtendedQuill) => {
    const [base, power] = this.startTwoSideFormula(quill, {
      command: '',
      regExp: POWER_DETECT_REGEXP,
    })
    if (base) this.input.insertValue(base)
    this.input.insertValue(QUILL_INPUT_COMMANDS.POWER)
    this.input.insertValue(power)
  }

  private readonly symbols = {
    '-': createSymbolReplacer('-', {
      replaceWith: '–',
      addSpaces: true,
    }),
    '+': createSymbolReplacer('+', {
      addSpaces: true,
    }),
    '/': this.handleSlash,
    '^': this.handlePower,
  }
  public capture(delta: Delta) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    const quill = this.quill
    return delta.ops.reduce((acc, item) => {
      if (!item.insert || typeof item.insert !== 'string') return acc
      const symbolReplacer = this.symbols[item.insert]
      if (symbolReplacer) {
        symbolReplacer(quill)
        return true
      }
      return acc
    }, false)
  }
}
