import { AnswerType, MyScriptCharacterTypes } from 'helpers/enums'
import React from 'react'
import { HistorySaveCredits } from '../../../DrawBoard/types'
import { PlayablePreview } from '../DrawBoardPreview/PlayablePreview'
import { DrawBoardPreviewPreset } from '../DrawBoardPreview/types'
import NoSolutionBody from './NoSolutionBody'
import styles from './SolutionCardBody.module.scss'
import SolutionCardImageBody from './SolutionCardImageBody'

type SolutionCardBodyProps = {
  noAnswer?: boolean
  drawBoardPreset: DrawBoardPreviewPreset | null
  attemptImage?: string
  attemptsCount: number
  backgroundImage?: string
  solutionImage?: string
  credits: HistorySaveCredits
  noSolutionAnswer?: string[]
  answerType: AnswerType
  characterType: MyScriptCharacterTypes
}

const SolutionCardBody = ({
  credits,
  noAnswer,
  drawBoardPreset,
  attemptImage,
  attemptsCount,
  backgroundImage,
  noSolutionAnswer,
  answerType,
  characterType,
}: SolutionCardBodyProps) => {
  if (noAnswer) {
    return (
      <NoSolutionBody
        noSolutionAnswer={noSolutionAnswer}
        answerType={answerType}
        characterType={characterType}
      />
    )
  }

  if (drawBoardPreset) {
    return (
      <PlayablePreview
        credits={credits}
        preset={drawBoardPreset}
        backgroundImage={backgroundImage}
        attemptsCount={attemptsCount}
        attemptImage={attemptImage}
        noSolutionAnswer={noSolutionAnswer}
        answerType={answerType}
        characterType={characterType}
      />
    )
  }

  if (attemptImage) {
    return <SolutionCardImageBody image={attemptImage} />
  }

  return <div className={styles.Body} />
}

export default SolutionCardBody
