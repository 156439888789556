import { PATHS } from 'config/pathnames.config'
import { useStore } from 'effector-react'
import HeatmapMenu from 'features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuActionsSkeleton from 'features/heatmap/HeatmapMenu/HeatmapMenuActions/HeatmapMenuActionsSkeleton'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import SortMenu from 'features/heatmap/SortMenu/SortMenu'
import { getPinsProblemOrSubProblemParams } from 'features/pinnedSolutions/helpers'
import { $pinnedSolutions } from 'features/pinnedSolutions/model/pinnedSolutions'
import { clearCurrentPinsData } from 'features/pinnedSolutions/model/pins'
import { fetchSinglePinsData } from 'features/pinnedSolutions/model/requests'
import FilterPinnedButton from 'features/pinnedSolutions/ui/FilterPinnedButton/FilterPinnedButton'
import AnonymousCorrectnessButton from 'features/tables/HeatmapTable/ui/AnonymousCorrectnessButton/AnonymousCorrectnessButton'
import AnonymousNameButton from 'features/tables/HeatmapTable/ui/AnonymousNameButton/AnonymousNameButton'
import { socketEvents } from 'helpers/enums'
import { getAllStudentsFromExercise } from 'helpers/getAllStudentFromExercise.helpers'
import socket from 'helpers/socket.helper'
import { getTeacherData } from 'helpers/user.helpers'
import useText from 'i18n/hook'
import { IStudentStat } from 'api/types.solutions'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, useParams } from 'react-router'
import { Route } from 'react-router-dom'
import { RootState } from 'store/store'
import Breadcrumbs from 'ui/Breadcrumbs/Breadcrumbs'
import { sessionStorageKeys } from '../../config/localStorageKeys'
import Spinner from '../../features/Spinner/Spinner'
import HeatmapMenuSkeleton from '../../features/heatmap/HeatmapMenu/HeatmapMenuSkeleton'
import HeatmapMenuTabsSkeleton from '../../features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabsSkeleton'
import { $exercise, $sortedStudents } from '../../features/tables/HeatmapTable/model/exercise'
import { $heatmapSettings } from '../../features/tables/HeatmapTable/model/heatmapSettings'
import { useSocketEvents } from '../../lib/hooks/useSocketEvents'
import {
  getExerciseWithoutStats,
  getStatsForSingleStudent,
  updateStats,
} from '../../redux/modules/exerciseWithoutStats.module'
import AllSolutions from '../../screens/AllSolutions/AllSolutions'
import SingleStudentsSolution from '../../screens/SingleStudentsSolution/SingleStudentsSolution'
import styles from './Solutions.module.scss'
import { getBreadcrumbLinks, getProblem, getSubProblem } from './helpers'
import HelpQueue from 'features/helpQueue/HelpQueue'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'

type RouteParams = {
  exerciseId: string
  taskId: string
  studentId?: string
}

const Solutions = () => {
  const t = useText()
  const dispatch = useDispatch()
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))
  const exerciseWithoutStats = useStore($exercise)
  const { pinsData, isPinsFilterActive } = useStore($pinnedSolutions)

  const heatmapSettings = useStore($heatmapSettings)
  const { exerciseId, taskId, studentId } = useParams<RouteParams>()
  const currentProblem = getProblem(exerciseWithoutStats, taskId)
  const currentSubProblem = currentProblem ? getSubProblem(currentProblem, taskId) : null
  const studentsWithStats = (currentSubProblem || currentProblem)?.studentStats || null
  const users = [...getAllStudentsFromExercise(exerciseWithoutStats), currentTeacher]

  useEffect(() => {
    if (exerciseWithoutStats?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }
  }, [exerciseId])

  useSocketEvents(socket, [
    {
      event: socketEvents.statisticsUpdate,
      handler: async (data) => {
        if (data.exerciseId !== exerciseId) return
        if (!data.userId) return updateStats(data.exerciseId)
        return dispatch(getStatsForSingleStudent(data.exerciseId, data.userId))
      },
    },
  ])

  const currentStudent = studentId
    ? users?.find((student) => student?._id === studentId)
    : undefined
  const sortedStudents = useStore($sortedStudents)
  const wasRedirectedFromMultipleSolutions = !!sessionStorage.getItem(
    sessionStorageKeys.isMultipleSolutionsViewVisited
  )

  const breadcrumbsLinks = getBreadcrumbLinks({
    heatmapTxt: t.heatmapTxt,
    exerciseId,
    currentProblem,
    currentSubProblem,
    studentId,
    wasRedirectedFromMultipleSolutions,
    currentStudent,
    problemTxt: t.problemTxt,
    isAnonymousNameMode: heatmapSettings.isAnonymousNameMode,
    sortedStudents: [...sortedStudents, currentTeacher],
    studentTxt: t.studentTxt,
  })

  const buttonColor = getButtonColor(exerciseWithoutStats.testModeEnabled)
  const teacherStat = studentsWithStats?.find((stat) => stat.isTeacher)
  const sortedStudentsStats = sortedStudents?.reduce((accumulator: IStudentStat[], student) => {
    const studentStat = studentsWithStats?.find((stat) => stat.studentId === student._id)
    if (studentStat) {
      accumulator.push(studentStat)
    }
    return accumulator
  }, [])
  const sortedStats = teacherStat ? [...sortedStudentsStats, teacherStat] : []

  const pinsProblemOrSubProblemParams = useMemo(
    () =>
      getPinsProblemOrSubProblemParams({
        problems: exerciseWithoutStats?.problems ?? [],
        taskId,
      }),
    [exerciseWithoutStats?.problems, taskId]
  )

  useEffect(() => {
    if (!exerciseWithoutStats?._id) return
    pinsProblemOrSubProblemParams?.forEach((pinsProblemOrSubProblemParams) => {
      if (!pinsProblemOrSubProblemParams) return
      fetchSinglePinsData({
        memoizedPins: pinsData.memoizedPins,
        exerciseId: exerciseWithoutStats._id,
        problemOrSubProblemObject: pinsProblemOrSubProblemParams,
      })
    })
  }, [exerciseWithoutStats._id, pinsProblemOrSubProblemParams])

  useEffect(() => {
    return () => {
      clearCurrentPinsData()
    }
  }, [])

  if (!exerciseWithoutStats?._id) {
    return (
      <HeatmapMenuSkeleton
        actionButtons={<HeatmapMenuActionsSkeleton numberOfButtons={4} />}
        navigation={<HeatmapMenuTabsSkeleton />}
      />
    )
  }

  return (
    <div className={styles.Solutions}>
      <>
        <HeatmapMenu
          actions={
            <>
              <HelpQueue />
              <VerticalDivider />
              <FilterPinnedButton buttonColor={buttonColor} />
              <AnonymousNameButton buttonColor={buttonColor} />
              <AnonymousCorrectnessButton buttonColor={buttonColor} />
              <SortMenu
                buttonColor={buttonColor}
                withPinsOrderOption={!studentId && isPinsFilterActive}
              />
            </>
          }
          navigation={<Breadcrumbs links={breadcrumbsLinks} />}
          title={exerciseWithoutStats.name}
        />
        {sortedStats ? (
          <Switch>
            <Route
              path={PATHS.EXERCISES.STUDENTS_SOLUTIONS}
              exact={true}
              render={() => (
                <AllSolutions
                  exercise={exerciseWithoutStats}
                  usersStats={sortedStats}
                  problem={currentProblem}
                  subProblem={currentSubProblem}
                />
              )}
            />
            <Route
              path={PATHS.EXERCISES.STUDENT_SOLUTIONS}
              exact={true}
              render={() => (
                <SingleStudentsSolution
                  exercise={exerciseWithoutStats}
                  usersStats={studentsWithStats}
                  problem={currentProblem}
                  subProblem={currentSubProblem}
                  sortedStudents={sortedStudents}
                />
              )}
            />
          </Switch>
        ) : (
          <Spinner className={styles.Loader} />
        )}
      </>
    </div>
  )
}

export default Solutions
