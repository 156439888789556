import clsx from 'clsx'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import sharedStyles from '../../styles/Styles.module.scss'
import styles from './ExerciseParticipants.module.scss'
import ExerciseParticipantsContent from './ExerciseParticipantsContent'
import ExerciseParticipantsTooltip from './ExerciseParticipantsTooltip'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { useStoreMap } from 'effector-react'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import { getExerciseParticipantsInfo, ExerciseParticipantsProps } from './helpers'

const ExerciseParticipants = ({
  addedClasses,
  addedGroups,
  students,
}: ExerciseParticipantsProps) => {
  const { isBulkModeActive } = useBulkSelectContext()
  const { overflowRef, isOverflowing } = useOverflowDetection()
  const t = useSelector((state: RootState) => state.localization.data)
  const isAnonymousNameMode = useStoreMap($heatmapSettings, (settings) => settings.isAnonymousNameMode)

  const participantsInfo = useMemo(
    () => getExerciseParticipantsInfo({ addedClasses, addedGroups, students }),
    [addedClasses, addedGroups, students]
  )
  const { singleStudentNames } = participantsInfo.participantsData

  const isTooltipVisible = participantsInfo.isTooltipVisible
    || isAnonymousNameMode && !!singleStudentNames.length
    || !isAnonymousNameMode && isOverflowing

  return (
    <UITooltip
      placement='bottom-start'
      tooltipClasses={{
        popper: styles.Popper,
        tooltip: styles.Tooltip,
      }}
      title={
        isTooltipVisible ? (
          <ExerciseParticipantsTooltip participantsData={participantsInfo.participantsData} />
        ) : null
      }
    >
      <div
        ref={overflowRef}
        className={clsx(sharedStyles.CardText, styles.Participants, {
          [sharedStyles.DisableSelect]: isBulkModeActive,
          [styles.MultiRow]: participantsInfo.participantsData.isSingleType,
        })}
        data-skip-redirect
      >
        <ExerciseParticipantsContent
          participants={participantsInfo.participantsData.classNames}
          textForSingle={t.classTxt}
          textForPlural={t.classesTxt}
          participantsInfo={participantsInfo}
          isParentTooltipVisible={isTooltipVisible}
        />
        <ExerciseParticipantsContent
          participants={participantsInfo.participantsData.groupNames}
          textForSingle={t.groupTxt}
          textForPlural={t.groupsTxt}
          participantsInfo={participantsInfo}
          isParentTooltipVisible={isTooltipVisible}
        />
        <ExerciseParticipantsContent
          participants={participantsInfo.participantsData.singleStudentNames}
          textForSingle={t.studentTxt}
          textForPlural={t.studentsTxt}
          participantsInfo={participantsInfo}
          isParentTooltipVisible={isTooltipVisible}
        />
      </div>
    </UITooltip>
  )
}

export default ExerciseParticipants
