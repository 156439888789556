import React from 'react'
import { useStoreMap } from 'effector-react'
import styles from './StudentsGradesList.module.scss'
import StudentGradesItem from './StudentGradesItem/StudentGradesItem'
import { $studentsGradesData, setSingleStudentGrades } from '../../models/studentsGradesModel'

type StudentGradesListProps = {
  containerDimensions?: DOMRect
}

const StudentsGradesList = ({ containerDimensions }: StudentGradesListProps) => {
  const studentsGradesData = useStoreMap($studentsGradesData, (data) => data.studentsGrades)
  return (
    <div className={styles.StudentGradesListContainer}>
      {studentsGradesData.students.map((student) => {
        const studentGrades = studentsGradesData.gradesInfo[student._id] || []
        return (
          <StudentGradesItem
            student={student}
            studentGrades={studentGrades}
            onSelectionChange={setSingleStudentGrades}
            containerDimensions={containerDimensions}
            key={student._id}
          />
        )
      })}
    </div>
  )
}

export default StudentsGradesList
