import React from 'react'

import './LanguageList.scss'
import ListItem from './ListItem/ListItem'
import { ILocalization } from "../../../../config/languages.config";

interface ILanguageList {
  localization: ILocalization
  currentLanguage: string | null
  selectLanguage: (language: string | null) => void
  filteredLanguages: string[]
  isFilterEmpty: boolean
}
const LanguageList: React.FC<ILanguageList> = ({
  localization,
  currentLanguage,
  selectLanguage,
  filteredLanguages,
  isFilterEmpty,
}) => {
  const { noTranslationTxt, languageListNoResults } = localization.data

  return (
    <div className='native-language-list'>
      {isFilterEmpty && (
        <ListItem
          currentLanguage={currentLanguage}
          language={null}
          selectLanguage={selectLanguage}
          noneLanguage={noTranslationTxt}
        />
      )}
      {filteredLanguages?.length ? (
        filteredLanguages.map((language: string) => {
          return (
            <ListItem
              currentLanguage={currentLanguage}
              language={language}
              selectLanguage={selectLanguage}
              key={language}
            />
          )
        })
      ) : (
        <div className='native-language-list-empty-container'>
          <p className='native-language-list-no-results'>{languageListNoResults}</p>
        </div>
      )}
    </div>
  )
}

export default LanguageList
