import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import UIModal from 'ui/modals/UIModal/UIModal'
import Button from 'ui/Buttons/UIButton/Button'
import styles from './TopicsModal.module.scss'
import { RootState } from 'store/store'
import ViewMode from './ViewMode/ViewMode'
import EditMode from './EditMode/EditMode'
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import {
  addTopic as addTopicToStore,
  editTopic as editTopicInStore,
  deleteTopic as deleteTopicFromStore,
} from 'redux/modules/topic.module'
import { NOTIFY } from 'redux/modules/notify.module'
import { NotifyType } from 'helpers/enums'
import { IDispatch } from 'interfaces/dispatch.interface'

import { PROMISE_FULFILLED } from 'features/ExercisesList/constants'
import useText from 'i18n/hook'
import { COLORS } from '@magmamath/ui'

import { ITopic } from '../../../api/api.topics'

type TopicsModalProps = {
  isOpened: boolean
  onClose: () => void
  exerciseId: string
}

const TopicsModal = ({ isOpened, onClose, exerciseId }: TopicsModalProps) => {
  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const t = useText()
  const initialTopics: ITopic[] = useSelector((state: RootState) => state.topics.data.topics)
  const initiallySelectedTopics = initialTopics
    .filter((topic: ITopic) => topic.exercises.includes(exerciseId))
    .map((topic: ITopic) => topic._id)
  const [topics, setTopics] = useState<ITopic[]>(initialTopics)
  const [selectedTopics, setSelectedTopics] = useState<string[]>(initiallySelectedTopics)
  const dispatch = useDispatch()

  const getTopicExercises = (topicId: string) => {
    const topic = initialTopics.find((topicElement: ITopic) => topicElement._id === topicId)
    if (!topic) {
      return []
    }
    if (!initiallySelectedTopics.includes(topicId)) {
      return [...topic.exercises, exerciseId]
    }
    if (!selectedTopics.includes(topicId)) {
      return topic.exercises.filter((exercise: string) => exercise !== exerciseId)
    }
    return topic.exercises
  }

  const onSave = async () => {
    const reqArray: Array<(dispatch: IDispatch<any>) => Promise<void>> = []
    const changedTopics = [...new Set(selectedTopics.concat(initiallySelectedTopics))]
    topics.forEach((topic) => {
      const initialTopic = initialTopics.find((topicEl: ITopic) => topicEl._id === topic._id)
      if (initialTopic) {
        if (topic.name !== initialTopic.name || changedTopics.includes(topic._id)) {
          reqArray.push(
            editTopicInStore(
              {
                name: topic.name,
                exercises: getTopicExercises(topic._id),
              },
              topic._id
            )
          )
        }
      } else {
        reqArray.push(
          addTopicToStore({
            name: topic.name,
            exercises: changedTopics.includes(topic._id) ? [exerciseId] : [],
          })
        )
      }
    })

    initialTopics.forEach((topic) => {
      if (!topics.find((topicElement) => topicElement._id === topic._id)) {
        reqArray.push(deleteTopicFromStore(topic._id))
      }
    })

    const results = await Promise.allSettled(reqArray.map((r) => r(dispatch)))

    dispatch({
      type: NOTIFY.NOTIFY_BEGIN,
      payload: results.find((result) => result.status !== PROMISE_FULFILLED)
        ? { message: t.somethingWentWrong, type: NotifyType.Danger }
        : { message: t.changesSavedTxt, type: NotifyType.Success },
    })

    setTimeout(() => {
      dispatch({ type: NOTIFY.NOTIFY_END })
    }, 500)

    onClose()
  }

  const onTopicClick = (topicId: string) => {
    const isSelected = selectedTopics.includes(topicId)
    const updatedTopics = isSelected
      ? selectedTopics.filter((id) => id !== topicId)
      : [...selectedTopics, topicId]
    setSelectedTopics(updatedTopics)
  }

  const addTopic = (newTopic: ITopic) => {
    setTopics([newTopic, ...topics])
  }

  const deleteTopic = (topicId: string) => {
    setTopics(topics.filter((topic) => topic._id !== topicId))
  }

  const editTopic = (updatedTopic: ITopic) => {
    setTopics(
      topics.map((topic) => {
        if (topic._id === updatedTopic._id) {
          return updatedTopic
        }
        return topic
      })
    )
  }

  return (
    <UIModal open={isOpened} onClose={onClose} wrapperClassName={styles.Wrapper}>
      <>
        <div className={styles.Header}>
          <Button
            variant='tertiary'
            size='small'
            className={styles.EditButton}
            onClick={() => {
              setIsEditModeOn(!isEditModeOn)
            }}
          >
            {isEditModeOn && <ArrowLeftIcon size={12} color={COLORS.NEUTRAL_10} />}
            <span className={clsx(styles.ButtonLabel, { [styles.BackButtonLabel]: isEditModeOn })}>
              {isEditModeOn ? t.backTxt : t.edit}
            </span>
          </Button>
          <p className={styles.Title}>{isEditModeOn ? t.edit : t.topicsTxt}</p>
        </div>
        <div className={styles.Main}>
          {isEditModeOn ? (
            <EditMode
              topics={topics}
              addNewTopicText={t.addNewTopic}
              onClose={onClose}
              removeTopic={deleteTopic}
              addTopic={addTopic}
              updateTopic={editTopic}
            />
          ) : (
            <ViewMode topics={topics} selectedTopics={selectedTopics} onTopicClick={onTopicClick} />
          )}
        </div>
        <div className={styles.Footer}>
          <Button color='green' onClick={onSave}>
            {t.saveTxt}
          </Button>
        </div>
      </>
    </UIModal>
  )
}

export default TopicsModal
