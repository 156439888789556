import { PATHS } from 'config/pathnames.config'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { IAppProps } from '../App/App.container'
import { ContentRoutes } from './Content.routes'

export const ContentContainer = (props: IAppProps) => {
  return (
    <div>
      <Switch>
        {ContentRoutes.map((route: any, i: number) => (
          <Route
            key={i}
            {...route}
            render={(routeProps: any) => route.render({ ...props, ...routeProps })}
          />
        ))}
        <Redirect to={PATHS.CONTENT.PROBLEMS} />
      </Switch>
    </div>
  )
}
