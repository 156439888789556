import { MyScriptCharacterTypes } from './enums'

const UNITS = [
  MyScriptCharacterTypes.UNITS,
  MyScriptCharacterTypes.NUMBERS_AND_UNITS,
  MyScriptCharacterTypes.TIME,
]

const UNITS_WITHOUT_SPACE = [
  MyScriptCharacterTypes.CURRENCY,
  MyScriptCharacterTypes.GEOM_ANGELS,
  MyScriptCharacterTypes.PERCENT,
]

const COORDINATES = [MyScriptCharacterTypes.GEOM_COORDINATE]

// Numbers, minus sign, latin letters, greek letters
const CHAR_GROUP = '[0-9a-zA-Z-αβγδεζηθικλμνξοπρστυφχψωΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ]'

// Greek character to LaTeX replacements (condensed for readability)
const GREEK_TO_LATEX_MAP = {
  α: '\\alpha',
  β: '\\beta',
  γ: '\\gamma',
  δ: '\\delta',
  ε: '\\epsilon',
  ζ: '\\zeta',
  η: '\\eta',
  θ: '\\theta',
  ι: '\\iota',
  κ: '\\kappa',
  λ: '\\lambda',
  μ: '\\mu',
  ν: '\\nu',
  ξ: '\\xi',
  ο: 'o',
  π: '\\pi',
  ρ: '\\rho',
  σ: '\\sigma',
  τ: '\\tau',
  υ: '\\upsilon',
  φ: '\\phi',
  χ: '\\chi',
  ψ: '\\psi',
  ω: '\\omega',
  Α: 'A',
  Β: 'B',
  Γ: '\\Gamma',
  Δ: '\\Delta',
  Ε: 'E',
  Ζ: 'Z',
  Η: 'H',
  Θ: '\\Theta',
  Ι: 'I',
  Κ: 'K',
  Λ: '\\Lambda',
  Μ: 'M',
  Ν: 'N',
  Ξ: '\\Xi',
  Ο: 'O',
  Π: '\\Pi',
  Ρ: 'P',
  Σ: '\\Sigma',
  Τ: 'T',
  Υ: '\\Upsilon',
  Φ: '\\Phi',
  Χ: 'X',
  Ψ: '\\Psi',
  Ω: '\\Omega',
}

export const hardBracketToKatex = (
  answer: string,
  characterType: MyScriptCharacterTypes,
  keepLargeKatex?: boolean
) => {
  if (UNITS.includes(characterType)) {
    return unitsToKatex(answer, keepLargeKatex)
  }
  if (UNITS_WITHOUT_SPACE.includes(characterType)) {
    return unitsWithoutSpaceToKatex(answer, keepLargeKatex)
  }
  if (COORDINATES.includes(characterType)) {
    return coordinatesToKatex(answer, keepLargeKatex)
  }
  // 'NumbersWhole', 'NumbersRational', 'AlgebraBasic', 'AlgebraAdvanced', 'Operators', 'GeneralMath' etc
  return convertExpression(answer, keepLargeKatex)
}

const convertExpression = (expression: string, keepLargeKatex?: boolean) => {
  // Patterns
  const percentagePattern = /(\d+([.,])?\d*|\([\w\s+-/*]+\))%/
  const fractionPattern = new RegExp(
    `\\[(\\[)?(${CHAR_GROUP}+)(])?\\/(\\[)?(${CHAR_GROUP}+)(])?\\]`,
    'g'
  )
  const exponentPattern = new RegExp(`\\^\\[?(${CHAR_GROUP}+)]?`, 'g')
  const sqrtPattern = new RegExp(`√\\[(${CHAR_GROUP}+)\\]`, 'g')

  // Percentage
  expression = expression.replace(percentagePattern, '$1\\%')

  // Fractions
  expression = keepLargeKatex
    ? expression.replace(fractionPattern, '{\\Large\\frac{$2}{$5}}')
    : expression.replace(fractionPattern, '\\frac{$2}{$5}')

  // Exponents
  expression = expression.replace(exponentPattern, '^{$1}')

  // Square roots
  expression = expression.replace(sqrtPattern, '\\sqrt{$1}')

  // Replace , with {,}
  expression = expression.replace(/,/g, '{,}')

  // Replace * with ×
  expression = expression.replace(/\*/g, '×')

  for (const [char, latex] of Object.entries(GREEK_TO_LATEX_MAP)) {
    const regex = new RegExp(char, 'g')
    expression = expression.replace(regex, latex)
  }

  // Return the converted expression wrapped in dollar signs
  return `$${expression}$`
}

const coordinatesToKatex = (answer: string, keepLargeKatex?: boolean) => {
  const match = answer.match(/\(([^,]+),([^)]+)\)/)
  if (match) {
    const x = convertExpression(match[1], keepLargeKatex).replace(/\$/g, '')
    const y = convertExpression(match[2], keepLargeKatex).replace(/\$/g, '')
    return `$(${x},${y})$`
  } else {
    return answer
  }
}

const unitsWithoutSpaceToKatex = (answer: string, keepLargeKatex?: boolean) => {
  const greekCharsRegex = /[αβγδεζηθικλμνξοπρστυφχψωΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ]/
  if (greekCharsRegex.test(answer)) {
    // to handle GeomAngles with greek letters
    return convertExpression(answer, keepLargeKatex)
  } else {
    answer = answer.replace(/kr/g, ' kr') // adding space before kr, an exception to the no space rule hehe
    answer = answer.replace(/\$/g, '\\$') // adding breaking character since dollar is what we use to wrap katex
    return answer
  }
}

const unitsToKatex = (answer: string, keepLargeKatex?: boolean) => {
  // Strip whitespace
  answer = answer.replace(/\s+/g, '')

  // Extract the numeric part (all characters until a letter is reached)
  const numericPartMatch = answer.match(/^[^a-zA-Z]+/)
  const numericPart = numericPartMatch ? numericPartMatch[0] : ''
  const stringWithoutNumeric = answer.slice(numericPart.length)

  // Extract the unit part (all characters until "^" is reached)
  const unitPartMatch = stringWithoutNumeric.match(/^[^^]+/)
  const unitPart = unitPartMatch ? unitPartMatch[0] : ''
  const stringWithoutUnit = stringWithoutNumeric.slice(unitPart.length)

  // The remaining part is the optional exponent part
  const exponentPart = stringWithoutUnit.trim()
  if (exponentPart.length > 0) {
    return `${convertExpression(numericPart, keepLargeKatex)} ${unitPart}${convertExpression(
      exponentPart,
      keepLargeKatex
    )}`
  } else {
    return convertExpression(numericPart, keepLargeKatex) + ' ' + unitPart
  }
}
