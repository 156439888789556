import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

import Loader from '../LoaderWrapper/LoaderWrapper'
import { COLORS } from '@magmamath/ui'

type ButtonLoaderProps = {
  color?: string
  height?: string
  width?: string
} & JSX.IntrinsicElements['div']

const ButtonLoader: React.FC<ButtonLoaderProps> = ({
  color = COLORS.NEUTRAL_1,
  height = '40%',
  width = '40%',
  ...containerProps
}) => {
  return (
    <Loader {...containerProps}>
      <ThreeDots color={color} height={height} width={width} />
    </Loader>
  )
}

export default ButtonLoader
