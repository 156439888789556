import { sortABC } from '../../../helpers/sort.helpers'
import { filterTeacherClassesBySearchWord } from './filterTeacherClassesBySearchWord'
import { TeacherClass, TeacherClassesResponse } from '../../../api/types.teachers'

const sortFunction = (a: TeacherClassesResponse, b: TeacherClassesResponse) =>
  a._id === '0' || b._id !== '0' ? 1 : -1
export const filterAndSortTeachersClasses = (
  teachersClasses: TeacherClassesResponse[],
  searchInputValue: string
): TeacherClassesResponse[] => {
  const sortedTeachersClasses = sortABC(teachersClasses)
    .reduce((acc: TeacherClassesResponse[], teacherClasses: TeacherClassesResponse) => {
      const sortedClasses = sortABC(teacherClasses.classes) as TeacherClass[]
      if (sortedClasses.length !== 0) {
        acc.push({
          ...teacherClasses,
          classes: sortedClasses,
        })
      }
      return acc
    }, [])
    .sort(sortFunction)

  return filterTeacherClassesBySearchWord(sortedTeachersClasses, searchInputValue)
}
