import { SelectedValueTypes } from 'helpers/enums'
import { getClassOrGroupParam } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IBook } from '../../api/types.books'
import { GetMyBookPayload } from '../../api/api.books'
import { api } from '../../api'

// Books constants
export enum BOOKS {
  BOOKS_REQUEST = 'BOOKS_REQUEST',
  BOOKS_SUCCESS = 'BOOKS_SUCCESS',
  BOOKS_ERROR = 'BOOKS_ERROR',
  BOOKS_UPDATE_VISIBILITY_REQUEST = 'BOOKS_UPDATE_VISIBILITY_REQUEST',
  BOOKS_UPDATE_VISIBILITY_SUCCESS = 'BOOKS_UPDATE_VISIBILITY_SUCCESS',
  BOOKS_UPDATE_VISIBILITY_ERROR = 'BOOKS_UPDATE_VISIBILITY_ERROR',
}

// Books reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function booksReducer(state: any = initialState, action: IAction<BOOKS>) {
  switch (action.type) {
    case BOOKS.BOOKS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case BOOKS.BOOKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case BOOKS.BOOKS_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case BOOKS.BOOKS_UPDATE_VISIBILITY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case BOOKS.BOOKS_UPDATE_VISIBILITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case BOOKS.BOOKS_UPDATE_VISIBILITY_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

function getBooksParams(type?: SelectedValueTypes, itemId?: string) {
  let param: GetMyBookPayload = {}
  if (itemId && type) {
    param = getClassOrGroupParam(type, itemId)
  } else if (type === SelectedValueTypes.INITIALBOOKS) {
    param.fetchAll = 1
    param.isInitial = 1
  }
  return param
}

export function getBooksForCreatingProblems(type?: SelectedValueTypes, itemId?: string) {
  const param = getBooksParams(type, itemId)
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: BOOKS.BOOKS_REQUEST })
      const res = await api.books.getMyBook(param)
      dispatch({ type: BOOKS.BOOKS_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: BOOKS.BOOKS_ERROR,
      })
    }
  }
}

export interface IBooksActions {
  getBooksForCreatingProblems: (type?: SelectedValueTypes, itemId?: string) => void
}

export interface IBooks {
  _embedded: {
    books: IBook[]
  }
}
