import { createEvent, restore } from 'effector'
import { TypesOfParams } from '../../../helpers/enums'

export type FilterSelector = {
  value: string
  name: string
  type: TypesOfParams | null
}

export const setFilterSelector = createEvent<FilterSelector | null>()

export const $filterSelectorStore = restore<FilterSelector | null>(setFilterSelector, null)
