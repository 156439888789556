import { TooltipProps } from '@mui/material'
import React from 'react'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'

type StateWithTooltipProps = {
  showTooltip: boolean
  children: JSX.Element
  title: string
  zIndex?: number
  offsetX?: number
  offsetY?: number
} & Pick<TooltipProps, 'placement'>

const TooltipWrapper = ({
  showTooltip,
  title,
  children,
  zIndex = 1500,
  offsetX = 0,
  offsetY = 4,
  placement = 'top',
}: StateWithTooltipProps) => {
  return showTooltip ? (
    <UITooltip
      title={title}
      placement={placement}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [offsetX, offsetY],
            },
          },
        ],
        style: {
          zIndex,
        },
      }}
    >
      {children}
    </UITooltip>
  ) : (
    children
  )
}

export default TooltipWrapper
