import React from 'react'

import { SvgIconProps } from '../types/types'
const CheckBoxCheckedIcon: React.FC<SvgIconProps> = ({ size = 25, color, extraColor }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 7L5.70998 10.21C6.13142 10.6314 6.82562 10.593 7.19795 10.1276L12.5 3.5'
        stroke={extraColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <mask id='path-2-inside-1_3_42' fill='white'>
        <rect width='15' height='15' rx='1' />
      </mask>
      <rect
        width='15'
        height='15'
        rx='1'
        stroke={color}
        strokeWidth='4'
        mask='url(#path-2-inside-1_3_42)'
      />
      <path d='M2 13V2H13V13H2Z' stroke='white' />
    </svg>
  )
}

export default CheckBoxCheckedIcon
