import React from 'react'
import { Breadcrumbs } from '@magmamath/ui'
import useText from 'i18n/hook'
import { Bredcrumb } from '../types'
import { closeSection, openSection } from '../../model/openedBooks'
import SectionCheckbox from './SectionCheckbox/SectionCheckbox'
import styles from '../Book.module.scss'
import { useUnit } from 'effector-react'
import { $isSearchActive } from '../../model/foundBooks'

type BookNavigationProps = {
  title: string
  bookId: string
  onClickBookshelf: () => void
  openedChapter?: Bredcrumb
  openedSection?: Bredcrumb
  isOpenedSectionChecked: boolean
  isOpenedSectionDisabled: boolean
}

const BookNavigation = ({
  title,
  bookId,
  openedChapter,
  openedSection,
  isOpenedSectionDisabled,
  isOpenedSectionChecked,
  onClickBookshelf,
}: BookNavigationProps) => {
  const isSearchActive = useUnit($isSearchActive)
  const t = useText()

  const handleClickBookTitle = () => {
    closeSection(bookId)
  }

  const handleClickChapterTitle = (chapter: Bredcrumb) => {
    openSection({ bookId, chapter })
  }

  const breadcrumbs = [
    {
      name: t.bookshelf,
      onClick: onClickBookshelf,
    },
    {
      name: title,
      onClick: handleClickBookTitle,
    },
    ...(openedChapter
      ? [
        {
          name: openedChapter.name,
          onClick: () => {
            handleClickChapterTitle(openedChapter)
          },
        },
      ]
      : []),
    ...(openedSection
      ? [
        {
          name: (
            <SectionCheckbox
              openedSection={openedSection}
              isOpenedSectionChecked={isOpenedSectionChecked}
              isOpenedSectionDisabled={isOpenedSectionDisabled}
            />
          ),
        },
      ]
      : []),
  ]

  const searchModeBreadcrumbs = [
    ...(openedChapter
      ? [
        {
          name: title,
          onClick: openedSection
            ? () => {
              handleClickChapterTitle(openedChapter)
            }
            : undefined,
        },
        {
          name: openedChapter.name,
          onClick: openedSection
            ? () => {
              handleClickChapterTitle(openedChapter)
            }
            : undefined,
        },
      ]
      : []),
  ]

  const bookBreadcrumbs = isSearchActive ? searchModeBreadcrumbs : breadcrumbs

  return (
    <Breadcrumbs className={styles.Breadcrumbs}>
      {bookBreadcrumbs.map((breadcrumb, index) => (
        <Breadcrumbs.Item key={index} onClick={breadcrumb.onClick}>
          {breadcrumb.name}
        </Breadcrumbs.Item>
      ))}
    </Breadcrumbs>
  )
}

export default BookNavigation
