import fileSaver from 'file-saver'
import { api } from '../../../../api'

type DownloadHeatmapParams = {
  exerciseId: string
  exerciseName: string
}

export const downloadHeatmapResults = async ({
  exerciseId,
  exerciseName,
}: DownloadHeatmapParams) => {
  const response = await api.assignments.getHeatmapResults(exerciseId)
  const blob = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  fileSaver.saveAs(blob, `${exerciseName}.xlsx`)
}
