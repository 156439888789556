import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import { $heatmapSettings, setSortOption } from 'features/tables/HeatmapTable/model/heatmapSettings'
import React, { useState } from 'react'
import useText from '../../../i18n/hook'
import SortIcon from '../../../ui/icons/SortIcon/SortIcon'
import Menu from '../../../ui/menues/Menu/Menu'
import MenuItem from '../../../ui/menues/Menu/MenuItem/MenuItem'
import { getSortMenuItems } from './helpers'
import styles from './SortMenu.module.scss'

type SortMenuProps = {
  buttonColor: BUTTON_COLOR
  withPinsOrderOption?: boolean
}

const SortMenu = ({ buttonColor, withPinsOrderOption }: SortMenuProps) => {
  const sortOption = useStoreMap($heatmapSettings, ({ sortOption }) => sortOption)
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false)
  const t = useText()

  const isExamMode = buttonColor === BUTTON_COLOR.PURPLE

  const sortHeatmapResults = () => {
    setIsSortMenuOpen(!isSortMenuOpen)
  }

  const sortMenuItems = getSortMenuItems(t, withPinsOrderOption)

  return (
    <div className={styles.Sort}>
      <Button
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.MEDIUM}
        color={buttonColor}
        icon={<SortIcon color='var(--icon-color)' size={20} />}
        isActive={isSortMenuOpen}
        onClick={sortHeatmapResults}
        tooltipText={t.sortStudents}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
        classes={{ tooltip: styles.Tooltip }}
      />
      {isSortMenuOpen && (
        <Menu onClose={() => setIsSortMenuOpen(false)} className={styles.SortMenu}>
          {sortMenuItems.map((menuItem) => {
            if (!menuItem) return null
            return (
              <MenuItem
                key={menuItem.sortOptionKey}
                isActive={sortOption === menuItem.sortOptionKey}
                isExamModeOn={isExamMode}
                onClick={() => {
                  setSortOption(menuItem.sortOptionKey)
                  setIsSortMenuOpen(false)
                }}
              >
                {menuItem.getText(t)}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </div>
  )
}

export default SortMenu
