import React from 'react'
import { SvgIconProps } from '../types/types'

const CloseModalIcon: React.FC<SvgIconProps & { strokeWidth?: string }> = ({
  size = 25,
  color,
  strokeWidth = '1.0624',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_495_1052)'>
        <path
          d='M0.509766 0.509766L15.4775 15.4775'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
        />
        <path
          d='M15.4775 0.509766L0.509766 15.4775'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_495_1052'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CloseModalIcon
