import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { FilterMultiplicityType } from '../../helpers/enums'

export enum FILTER_MULTIPLICITY {
  SET_FILTER_VALUE = 'SET_FILTER_VALUE',
}

const initialState = {
  data: {
    filterValue: FilterMultiplicityType.ALL,
  },
  error: null,
  loading: false,
}

export const filterMultiplicityReducer = (
  state: any = initialState,
  action: IAction<FILTER_MULTIPLICITY>
) => {
  switch (action.type) {
    case FILTER_MULTIPLICITY.SET_FILTER_VALUE:
      return {
        ...state,
        data: {
          filterValue: action.payload.filterValue,
        },
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const changeMultiplicityFilter = (filterValue: FilterMultiplicityType | string) => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      type: FILTER_MULTIPLICITY.SET_FILTER_VALUE,
      payload: {
        filterValue,
      },
    })
  }
}

export interface IFilterMultiplicityActions {
  changeMultiplicityFilter: (filterValue: string) => void
}

export interface IFilterMultiplicity {
  filterValue: string
}
