import React from 'react'
import Answer from '../../ui/Answer/Answer'
import { AnswerType, MyScriptCharacterTypes, Separator } from '../../helpers/enums'
import useText from '../../i18n/hook'

type AnswerListProps = {
  answers: string[]
  characterType: MyScriptCharacterTypes
  showNoAnswerText?: boolean
  answerType?: AnswerType
  separator?: Separator
}
const AnswerList = ({
  answers,
  answerType,
  characterType,
  showNoAnswerText,
  separator = Separator.COMMA,
}: AnswerListProps) => {
  const t = useText()

  return (
    <>
      {answers.map((answer, index) =>
        answer || answer === '' ? (
          <Answer
            key={index}
            answerValue={answer}
            answerType={answerType}
            characterType={characterType}
            index={index}
            length={answers.length}
            separator={separator}
            keepLargeKatex={true}
          />
        ) : showNoAnswerText ? (
          <p>{t.notAnsweredYet}</p>
        ) : null
      )}
    </>
  )
}

export default AnswerList
