import { COLORS } from '@magmamath/ui'
import clsx from 'clsx'
import React, { memo } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import EyeIcon from 'ui/icons/EyeIcon/EyeIcon'
import { CellColorClassNames } from 'features/tables/HeatmapTable/types'
import styles from './Table.module.scss'

type BodyCellProps = {
  attemptsNumber?: number
  cellColorClassName: string
  isExamMode: boolean
  linkToPathname: LinkProps<unknown>['to']
  shouldShowWatchedIcon: boolean
} & JSX.IntrinsicElements['div']

const BodyCell = ({
  attemptsNumber,
  cellColorClassName,
  isExamMode,
  linkToPathname,
  shouldShowWatchedIcon,
  ...restProps
}: BodyCellProps) => {
  const isAnswered = !(cellColorClassName === CellColorClassNames.DEFAULT)
  return (
    <div className={styles.TableBodyCell} {...restProps}>
      <Link className={styles.Link} to={linkToPathname}>
        <div
          className={clsx(styles.TableBodyCellContent, styles[cellColorClassName] ?? styles.Gray, {
            [styles.ExamMode]: isExamMode && isAnswered,
            [styles.DefaultMode]: !isExamMode && isAnswered,
          })}
        >
          {shouldShowWatchedIcon && <EyeIcon size={20} color={COLORS.NEUTRAL_1} />}
          {attemptsNumber && <span>{attemptsNumber}</span>}
        </div>
      </Link>
    </div>
  )
}

export default memo(BodyCell)
