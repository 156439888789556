import { ILocalization } from 'config/languages.config'
import { HeatmapSortOptions } from 'features/tables/HeatmapTable/model/heatmapSettings'
import useText from 'i18n/hook'

export const getSortMenuItems = (
  t: ReturnType<typeof useText>,
  withPinsOrderOption?: boolean
): ({
  sortOptionKey: HeatmapSortOptions
  getText: (t: ILocalization['data']) => string
} | null)[] => [
  { sortOptionKey: HeatmapSortOptions.FIRST_NAME, getText: (t) => t.firstNameTxt },
  { sortOptionKey: HeatmapSortOptions.LAST_NAME, getText: (t) => t.lastNameTxt },
  { sortOptionKey: HeatmapSortOptions.MOST_ANSWERS, getText: (t) => t.mostAnswersTxt },
  {
    sortOptionKey: HeatmapSortOptions.MOST_CORRECT_ANSWERS,
    getText: (t) => t.mostCorrectAnswersTxt,
  },
  { sortOptionKey: HeatmapSortOptions.RANDOM, getText: (t) => t.randomTxt },
  withPinsOrderOption
    ? {
        sortOptionKey: HeatmapSortOptions.PINNED_SOLUTIONS,
        getText: (t) => t.pinnedOrderTxt,
      }
    : null,
]
