import { BoardController, ServerPersistModule } from '@magmamath/react-native-draw-board'
import { useEffect } from 'react'
import { useStore } from 'effector-react'
import { CoreSaveCredits, HistorySaveCredits } from './types'
import { api } from '../../api'

export enum EntityType {
  PROBLEM = 1,
  SUB_PROBLEM,
}

function getEntity({ exerciseId, subProblemId, problemId }: CoreSaveCredits) {
  if (subProblemId) {
    return {
      id: `${exerciseId}-${subProblemId}`,
      type: EntityType.SUB_PROBLEM,
    }
  }
  return { id: `${exerciseId}-${problemId}`, type: EntityType.PROBLEM }
}

export const createServerPersistModule = () => {
  return new ServerPersistModule<HistorySaveCredits>({
    api: {
      get: ({ userId, ...credits }) => {
        const entity = getEntity(credits)
        return api.drawBoardHistory
          .get({ userId, entity })
          .then(({ history, _id }) => ({ id: _id, history }))
      },
      set() {
        return Promise.resolve()
      },
    },
  })
    .setUpdateFilter((current, next) => {
      return (
        current &&
        next &&
        current.exerciseId === next.exerciseId &&
        current.problemId === next.problemId &&
        current.subProblemId === next.subProblemId &&
        current.userId === next.userId
      )
    })
    .readonly()
}
export const serverPersistModule = createServerPersistModule()
export const useDrawBoardPersist = (
  boardProvider: { current: BoardController | null },
  credits: HistorySaveCredits
) => {
  const isLoading = useStore(serverPersistModule.load.pending)

  useEffect(() => {
    if (!boardProvider.current?.model) return
    serverPersistModule.setUp(boardProvider.current.model)
    return () => serverPersistModule.saveCurrent({ cleanAfter: true })
  }, [boardProvider])

  useEffect(() => {
    serverPersistModule.setCredits(credits)
  }, [credits])

  return isLoading
}
