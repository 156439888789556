import { useEffect, useCallback, useState, RefObject } from 'react'

export type ScrollPosition = {
  x: number
  y: number
}

export type ScrollStatus = {
  isScrolledToTop: boolean
  isScrolledToBottom: boolean
  isScrolledToLeft: boolean
  isScrolledToRight: boolean
}

export type ScrollAvailability = {
  hasScrollX: boolean
  hasScrollY: boolean
}

const initialScrollPosition: ScrollPosition = {
  x: 0,
  y: 0,
}

const initialScrollStatus: ScrollStatus = {
  isScrolledToTop: true,
  isScrolledToBottom: false,
  isScrolledToLeft: true,
  isScrolledToRight: false,
}

const initialScrollAvailability: ScrollAvailability = {
  hasScrollX: false,
  hasScrollY: false,
}

const useScrollPosition = (ref: RefObject<HTMLElement>, sessionStorageKey?: string) => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>(initialScrollPosition)
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>(initialScrollStatus)
  const [scrollAvailability, setScrollAvailability] =
    useState<ScrollAvailability>(initialScrollAvailability)

  const getScrollPosition = useCallback((): ScrollPosition => {
    if (!ref.current) return initialScrollPosition

    return { x: ref.current.scrollLeft, y: ref.current.scrollTop }
  }, [ref, initialScrollPosition])

  const saveScrollPosition = useCallback(() => {
    if (!(ref.current && sessionStorageKey)) return

    const position = getScrollPosition()
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(position))
  }, [ref, sessionStorageKey, getScrollPosition])

  const scrollTo = useCallback(
    (x: ScrollPosition['x'], y: ScrollPosition['y']) => {
      if (!ref.current) return

      ref.current.scrollTo({
        left: x,
        top: y,
        behavior: 'smooth',
      })
    },
    [ref]
  )

  const updateScrollStatus = useCallback(() => {
    if (!ref.current) return

    const position = getScrollPosition()
    setScrollPosition(position)

    const { scrollHeight, scrollTop, clientHeight, scrollWidth, scrollLeft, clientWidth } =
      ref.current

    setScrollStatus({
      isScrolledToTop: scrollTop === 0,
      isScrolledToBottom: scrollTop + clientHeight === scrollHeight,
      isScrolledToLeft: scrollLeft === 0,
      isScrolledToRight: scrollLeft + clientWidth === scrollWidth,
    })

    setScrollAvailability({
      hasScrollX: scrollWidth > clientWidth,
      hasScrollY: scrollHeight > clientHeight,
    })
  }, [ref, getScrollPosition])

  useEffect(() => {
    const handleScroll = () => {
      // TODO: improve performance (throttle/debounce)
      updateScrollStatus()
      saveScrollPosition()
    }

    const currentRef = ref.current
    currentRef?.addEventListener('scroll', handleScroll)

    return () => currentRef?.removeEventListener('scroll', handleScroll)
  }, [ref, sessionStorageKey, updateScrollStatus, saveScrollPosition])

  useEffect(() => {
    if (!sessionStorageKey) return

    const savedPosition = sessionStorage.getItem(sessionStorageKey)
    if (!savedPosition) return

    const { x, y } = JSON.parse(savedPosition)
    scrollTo(x, y)
  }, [ref, sessionStorageKey, scrollTo])

  return {
    scrollTo,
    updateScrollStatus,
    getScrollPosition,
    saveScrollPosition,
    scrollPosition,
    scrollStatus,
    scrollAvailability,
  }
}

export default useScrollPosition
