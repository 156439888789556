import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

class CustomString extends String {
  charAt(_: number): string {
    return this.valueOf()
  }
}
export class CustomDateAdapter extends AdapterDayjs {
  getWeekdays = () => {
    const start = this.dayjs().startOf('week')
    const customWeekdays = [0, 1, 2, 3, 4, 5, 6].map((diff) => {
      return this.formatByString(start.add(diff, 'day'), 'ddd')
    })
    return customWeekdays.map((day) => new CustomString(day) as string)
  }
}
