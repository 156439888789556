import { apiManager } from './apiManager'
import {
  ContentCreateResponse,
  ContentDetails,
  UpdateSingleContentPayload,
  UpdateSingleContentResponse,
} from './types.contents'

export class contentsApi {
  public static readonly createNew = apiManager.post<ContentCreateResponse, ContentDetails>(
    'contents'
  )
  public static readonly updateSingle = apiManager.put<
    UpdateSingleContentResponse,
    UpdateSingleContentPayload
  >(({ id, data }) => ({
    url: `contents/${id}`,
    body: data,
  }))
}
