import { getExercisesParam } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import _ from 'lodash'
import { EXERCISES_STATUS } from './exercisesStatuses.module'
import { NotifyType } from 'helpers/enums'
import { NOTIFY } from './notify.module'
import { IQueryParams } from 'interfaces/params.interface'
import {
  GetAllAssignmentsPayload,
  GetAllAssignmentsResponse,
  IExercise,
  UpdateExerciseStatusPayload,
} from '../../api/types.assignments'
import { api } from '../../api'
import { mergeAssignments } from './../../features/ExercisesList/helpers/mergeAssignments'

const FIRST_PAGE = 1

// Exercises constants
export enum EXERCISES {
  EXERCISES_REQUEST = 'EXERCISES_REQUEST',
  EXERCISES_SUCCESS = 'EXERCISES_SUCCESS',
  EXERCISES_ERROR = 'EXERCISES_ERROR',
  EXERCISE_REMOVE_FROM_LIST = 'EXERCISE_REMOVE_FROM_LIST',
  BULK_REMOVE_EXERCISES_FROM_LIST = 'BULK_REMOVE_EXERCISES_FROM_LIST',
  EXERCISES_CLEAR = 'EXERCISES_CLEAR',
  EXERCISES_UPDATE_EXERCISE_STATS_IN_LIST = 'EXERCISES_UPDATE_EXERCISE_STATS_IN_LIST',
  EXERCISES_UPDATE_EXERCISE_IN_LIST = 'EXERCISES_UPDATE_EXERCISE_IN_LIST',
  EXERCISE_EDIT_NAME = 'EXERCISE_EDIT_NAME',
  EXERCISES_SET_PAGE = 'EXERCISES_SET_PAGE',
}

type State = {
  page: number
  data: GetAllAssignmentsResponse | null
  error: any
  loading: boolean
}
// Exercises reducer
const initialState: State = {
  page: FIRST_PAGE,
  data: null,
  error: null,
  loading: false,
}

export function exercisesReducer(state = initialState, action: IAction<EXERCISES>) {
  switch (action.type) {
    case EXERCISES.EXERCISES_SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case EXERCISES.EXERCISES_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISES.EXERCISES_SUCCESS:
      const oldAssignments = state.data?._embedded?.exercises || []
      const newAssignments = action.payload?._embedded.exercises || []
      const mergedAssignments = mergeAssignments(oldAssignments, newAssignments)

      return {
        ...state,
        data: {
          ...action.payload,
          _embedded: {
            exercises: mergedAssignments,
          },
        },
        error: null,
        loading: false,
      }
    case EXERCISES.EXERCISES_UPDATE_EXERCISE_STATS_IN_LIST:
      const updatedExercises = state.data?._embedded?.exercises?.map((exercise) => {
        if (exercise._id === action.payload.exerciseId) {
          return { ...exercise, stats: action.payload.stats }
        }
        return exercise
      })
      if (!updatedExercises) return state

      return {
        ...state,
        data: {
          ...state.data,
          _embedded: {
            exercises: updatedExercises,
          },
        },
        error: null,
        loading: false,
      }
    case EXERCISES.EXERCISE_REMOVE_FROM_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          _embedded: {
            exercises: state.data?._embedded?.exercises.filter(
              (exercise) => exercise._id !== action.payload.exerciseId
            ),
          },
        },
        error: null,
        loading: false,
      }
    case EXERCISES.BULK_REMOVE_EXERCISES_FROM_LIST:
      const exercisesIdsToRemove = action.payload.exercisesIds
      const newExercisesList = state.data?._embedded?.exercises?.filter(
        (exercise) => !exercisesIdsToRemove.includes(exercise._id)
      )
      return {
        ...state,
        data: {
          ...state.data,
          _embedded: {
            exercises: newExercisesList,
          },
        },
      }
    case EXERCISES.EXERCISE_EDIT_NAME:
      const res = state.data?._embedded?.exercises.map((exercise) => {
        if (exercise._id === action.payload.exerciseId) {
          return { ...exercise, name: action.payload.name }
        }
        return exercise
      })
      return {
        ...state,
        data: {
          ...state.data,
          _embedded: {
            exercises: res,
          },
        },
        error: null,
        loading: false,
      }
    case EXERCISES.EXERCISES_UPDATE_EXERCISE_IN_LIST:
      const exercisesWithUpdatedExercise = state.data?._embedded?.exercises?.map((exercise) => {
        if (exercise._id === action.payload.exercise._id) {
          return action.payload.exercise
        }
        return exercise
      })
      return {
        ...state,
        data: {
          ...state.data,
          _embedded: {
            exercises: exercisesWithUpdatedExercise,
          },
        },
        error: null,
        loading: false,
      }
    case EXERCISES.EXERCISES_CLEAR:
      return {
        ...state,
        data: {},
        error: null,
        loading: false,
      }
    case EXERCISES.EXERCISES_ERROR:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Exercises actions
export function setExercisesListPage(page: number) {
  return {
    type: EXERCISES.EXERCISES_SET_PAGE,
    payload: page,
  }
}

export function getExercises(params: Omit<GetAllAssignmentsPayload, 'page'>) {
  const param = getExercisesParam(params)
  return async (dispatch: IDispatch<any>, getState: any) => {
    try {
      const { exercises } = getState()
      if (exercises.loading) return
      dispatch({ type: EXERCISES.EXERCISES_REQUEST })
      const res = await api.assignments.getAll({ ...param, page: exercises.page })
      dispatch({ type: EXERCISES.EXERCISES_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISES.EXERCISES_ERROR,
      })
    }
  }
}

export const updateExerciseStatsInExercises =
  (exerciseId: string) => async (dispatch: IDispatch<any>) => {

    try {
      const stats = await api.assignments.getStats(exerciseId)
      dispatch({
        type: EXERCISES.EXERCISES_UPDATE_EXERCISE_STATS_IN_LIST,
        payload: {
          exerciseId,
          stats,
        },
      })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISES.EXERCISES_ERROR,
      })
    }
  }

export const updateExerciseInExercises =
  (exerciseId: string) => async (dispatch: IDispatch<any>) => {
    try {
      const exercise = await api.assignments.getSingle({
        id: exerciseId,
        fetchAll: 0,
      })
      dispatch({
        type: EXERCISES.EXERCISES_UPDATE_EXERCISE_IN_LIST,
        payload: {
          exercise,
        },
      })
    } catch (error) {
      if (error.response?.status === 404) {
        // exercise was deleted (case: from district)
        dispatch({
          type: EXERCISES.EXERCISE_REMOVE_FROM_LIST,
          payload: {
            exerciseId,
          },
        })
        return
      }
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISES.EXERCISES_ERROR,
      })
    }
  }

export const removeExerciseFromList = (exerciseId: string) => async (dispatch: IDispatch<any>) => {
  dispatch({ type: EXERCISES.EXERCISE_REMOVE_FROM_LIST, payload: { exerciseId } })
}

export const bulkUpdateExercisesStatuses =
  (selectedExercises: IExercise[], filterStatus: EXERCISES_STATUS, errorMessage: string) =>
    async (dispatch: IDispatch<any>) => {
      try {
        const exerciseIds = selectedExercises.map((exercise) => exercise._id)
        const requestBodyMap: Record<EXERCISES_STATUS, UpdateExerciseStatusPayload> = {
          [EXERCISES_STATUS.UNPUBLISHED]: { exerciseIds, published: false, archived: false },
          [EXERCISES_STATUS.AVAILABLE]: {
            exerciseIds,
            published: true,
            archived: false,
          },
          [EXERCISES_STATUS.ARCHIVED]: {
            exerciseIds,
            archived: true,
            published: false,
          },
        }
        await api.assignments.updateStatus(requestBodyMap[filterStatus])
        bulkRemoveExercisesFromList(exerciseIds)(dispatch)
      } catch (error) {
        dispatch({
          type: NOTIFY.NOTIFY_BEGIN,
          payload: { message: errorMessage, type: NotifyType.Danger },
        })
        dispatch({ type: NOTIFY.NOTIFY_END })
      }
    }

export const bulkRemoveExercisesFromList =
  (exercisesIds: string[]) => async (dispatch: IDispatch<any>) => {
    dispatch({ type: EXERCISES.BULK_REMOVE_EXERCISES_FROM_LIST, payload: { exercisesIds } })
  }

export const clearExercises = () => async (dispatch: IDispatch<any>) => {
  dispatch({ type: EXERCISES.EXERCISES_CLEAR })
}

export interface IExercisesActions {
  setExercisesListPage: (page: number) => void
  getExercises: (params: IQueryParams) => void
  updateExerciseStatsInExercises: (exerciseId: string) => void
  updateExerciseInExercises: (exerciseId: string) => void
  clearExercises: () => void
  bulkRemoveExercisesFromList: (exercisesIds: string[]) => void
  bulkUpdateExercisesStatuses: (
    selectedExercises: IExercise[],
    filterStatus: EXERCISES_STATUS,
    errorMessage: string
  ) => void
}
