export const localStorageKeys = {
  bookId: 'bookId',
  books: 'books',
  chapterId: 'chapterId',
  classesArray: 'classesArray',
  collectionIdData: 'collectionIdData',
  currentExercise: 'currentExercise',
  currentList: 'currentList',
  currentProblem: 'currentProblem',
  currentSubProblem: 'currentSubProblem',
  exercise: 'exercise',
  exerciseName: 'exerciseName',
  groupsArray: 'groupsArray',
  headerName: 'headerName',
  language: 'lang',
  me: 'me',
  problems: 'problems',
  requestedBooksNames: 'requestedBooksNames',
  redirectUrl: 'redirectUrl',
  section: 'section',
  sectionId: 'sectionId',
  selectedClass: 'selectedClass',
  sortValue: 'sortValue',
  student: 'student',
  studentsArray: 'studentsArray',
  studentsOfExercise: 'studentsOfExercise',
  token: 'token',
  user: 'user',
}

export const sessionStorageKeys = {
  allowedAnswerLooking: 'allowedAnswerLooking',
  isHide: 'isHide',
  isReload: 'isReload',
  levelOfProblem: 'levelOfProblem',
  problems: 'problems',
  sectionId: 'sectionId',
  selectedOption: 'selectedOption',
  studentsIds: 'studentsIds',
  testModeEnabled: 'testModeEnabled',
  solutionsListScrollTop: 'solutionsListScrollTop',
  heatmapTableScrollPosition: 'heatmapTableScrollPosition',
  isMultipleSolutionsViewVisited: 'isMultipleSolutionsViewVisited',

  cafHeatmapSettings: 'cafHeatmapSettings',
  cafClassesStore: 'cafClassesStore',
  cafExerciseName: 'cafExerciseName',
  cafExerciseStatus: 'cafExerciseStatus',
  cafExerciseSettings: 'cafExerciseSettings',
  cafSelectedProblems: 'cafSelectedProblems',
  cafSelectedProblemsData: 'cafSelectedProblemsData',
  cafSavedSortOption: 'cafSavedSortOption',
  cafSavedSortedProblemsId: 'cafSavedSortedProblemsId',
}
