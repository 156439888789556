import { ConfirmModalMode, Levels, LocaleData } from 'helpers/enums'
import { IBook, ISection } from '../../api/types.books'
import { IProblem } from '../../api/types.problem'
import { UpdateSingleSectionPayload } from '../../api/api.sections'

export type MyBooks = IBook[]

export type CreateSectionFxProps = {
  name: string
}

export type CreateSectionRequestFxProps = {
  name: string
  appLanguage: LocaleData
}

export type UpdateChapterFxProps = {
  id: string
  sectionIds: string[]
}

export type EditSectionFxData = {
  data: UpdateSingleSectionPayload['data']
}

export type EditSectionFxProps = EditSectionFxData & {
  section: ISection | null
}
export type ConfirmModalState = {
  isOpen: boolean
  mode: ConfirmModalMode
}

export type DropDownPosition = {
  top: string
  right: string
}

export enum DropDownMode {
  SECTION = 'SECTION',
  PROBLEM = 'PROBLEM',
}
export type ProblemClickProps = {
  clickedProblem: HTMLDivElement
  clickedMenu: HTMLDivElement
  problem: IProblem
}

export type SectionClickProps = {
  section: ISection
  clickedSection: HTMLLIElement
  clickedMenu: HTMLDivElement
  isFirstSection: boolean
}

export type ILevel = {
  name: string
  color: string
  className: string
  complexity: Levels
}
