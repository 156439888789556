import CancelButton from 'components/basic/Buttons/CancelButton/CancelButton'
import { FormTypes, Keys, ModalType, RemovableTypes } from 'helpers/enums'
import { changeAssessModeInExercise } from 'helpers/exercise.helpers'
import { checkSafari } from 'helpers/general'
import { IState } from 'interfaces/state.interface'
import { IStudent } from 'api/types.users'
import useKeyPress from 'lib/hooks/useKeyPress'
import React, { FC, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { IClassActions } from 'redux/modules/class.module'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { IGroupActions } from 'redux/modules/group.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { IUserActions } from 'redux/modules/student.module'
import './../modals.scss'
import './confirmModal.component.scss'
import { ILocalization } from '../../../config/languages.config'
import { IProblem } from '../../../api/types.problem'
import { IExercise } from '../../../api/types.assignments'
import { ISection } from '../../../api/types.books'
import { ClassroomGroup, IClass } from '../../../api/types.classes'
import { getWarningText } from './helpers'
import useText from 'i18n/hook'
import { IGroup } from '../../../api/api.groups'

interface ISimpleConfirmModalProps {
  modalActions: IModalActions
  localization: ILocalization
  modal: IState<IModal>
  history: any
  classActions: IClassActions
  groupActions: IGroupActions
  exerciseActions: IExerciseActions
  userActions: IUserActions
  removedItem: ISection | IProblem | IClass | IGroup | IStudent | IExercise | ClassroomGroup
  typeOfItem: FormTypes | RemovableTypes
  classId?: string
}

export const SimpleConfirmModal: FC<ISimpleConfirmModalProps> = ({
  modalActions,
  localization,
  modal,
  history,
  classActions,
  groupActions,
  exerciseActions,
  userActions,
  removedItem,
  typeOfItem,
  classId,
}) => {
  const isEnterPressed = useKeyPress(Keys.ENTER)
  const t = useText()
  const isSafari = checkSafari()
  const isOpen = modal.data.modalType === ModalType.SimpleConfirmModal
  const onModalClose = (): void => modalActions.closeSimpleConfirmModal()

  useEffect(() => {
    if (isEnterPressed) {
      onActionWithItem()
    }
  }, [isEnterPressed])

  const onActionWithItem = (): void => {
    switch (typeOfItem) {
      case RemovableTypes.Class:
        classActions.deleteClass(removedItem!._id, history, localization)
        break
      case RemovableTypes.Group:
        groupActions.deleteGroup(removedItem!._id, history, localization)
        const classroomId = (removedItem as ClassroomGroup)?.classroomId
        if (classroomId) classActions!.getClass(classroomId)
        break
      case RemovableTypes.StudentFromClass:
        userActions.unfollowStudentFromClass(classId!, removedItem!._id, localization, history)
        break
      case RemovableTypes.StudentFromGroup:
        userActions.deleteStudentFromGroup(
          removedItem!._id,
          history,
          modal.data.updatedItem._id,
          localization,
          classId
        )
        break
      case RemovableTypes.Exercise:
        exerciseActions!.deleteExercise(removedItem!._id, localization as any)
        break
      case RemovableTypes.AssessedExercise:
        const changedAssessMode = changeAssessModeInExercise((removedItem as IExercise)?.settings)
        exerciseActions!.editExercise({ settings: changedAssessMode }, removedItem!._id, history)
        break

      default:
        break
    }
    onModalClose()
  }

  const getQuestionText = (type: RemovableTypes | FormTypes): string => {
    const questionTextMap: Partial<Record<RemovableTypes, string>> = {
      [RemovableTypes.Problem]: t.deleteProblemQuestion,
      [RemovableTypes.Section]: t.deleteSectionQuestion,
    }

    const questionDeleteExercise: Partial<Record<RemovableTypes, string>> = {
      [RemovableTypes.Exercise]: t.alertMessageWhenDeleteAClass,
    }
    return (
      questionTextMap[type] || (
        <>
          {RemovableTypes.StudentFromClass === type ? null : <div>{t.areYouSure}</div>}
          <div>{questionDeleteExercise[type]}</div>
        </>
      )
    )
  }

  const questionText = getQuestionText(typeOfItem)
  const warningText = getWarningText(typeOfItem, t)

  return (
    <div>
      <Modal
        fade={!isSafari}
        autoFocus={false}
        isOpen={isOpen}
        className='custom-modal confirm-modal colored-modal'
        toggle={onModalClose}
      >
        <form className='confirm-form' onSubmit={onActionWithItem}>
          <ModalHeader className='custom-header confirm-modal-header' toggle={onModalClose} />
          <ModalBody className='custom-body confirm-modal-body'>
            <div>
              <p className='question-text'>{questionText}</p>
              <p>{warningText}</p>
            </div>
          </ModalBody>
          <ModalFooter className='custom-footer confirm-modal-footer'>
            <CancelButton
              className='confirm-cancel-button'
              cancelFunc={onModalClose}
              title={t.noTxt}
            />
            <Button className='custom-create-button' type='submit' autoFocus={true}>
              <span>{t.yesTxt}</span>
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}
