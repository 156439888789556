import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { ILocalization } from '../../config/languages.config'
import { BookSeriesData } from '../../api/types.bookSeries'
import { api } from '../../api'

// BookSeries constants
export enum BOOK_SERIES {
  BOOK_SERIES_REQUEST = 'BOOK_SERIES_REQUEST',
  BOOK_SERIES_SUCCESS = 'BOOK_SERIES_SUCCESS',
  BOOK_SERIES_ERROR = 'BOOK_SERIES_ERROR',
  BOOK_SERIES_CLEAR = 'BOOK_SERIES_CLEAR',
}

// BookSeries reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}
export function bookSeriesReducer(state: any = initialState, action: IAction<BOOK_SERIES>) {
  switch (action.type) {
    case BOOK_SERIES.BOOK_SERIES_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case BOOK_SERIES.BOOK_SERIES_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          bookSeries: action.payload,
        },
        loading: false,
      }
    case BOOK_SERIES.BOOK_SERIES_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case BOOK_SERIES.BOOK_SERIES_CLEAR:
      return {
        data: action.payload,
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export const getBookSeries = (localization: ILocalization, offset = 0, seriesIds: string[]) => {
  const ids = seriesIds?.join(',')

  const getAllBookSeries = async (offset: number, ids: string) => {
    const res = await api.bookSeries.getAll({ offset, ids })
    const pages = res.pages
    const promises = []
    const bookSeries = res._embedded.bookSeries

    for (let i = 1; i < pages; i++) {
      promises.push(api.bookSeries.getAll({ offset: i * 20, ids }))
    }

    const responses = await Promise.all(promises).then((responses) =>
      responses.reduce(
        (bookSeries, accumulator) => [...bookSeries, ...accumulator._embedded.bookSeries],
        bookSeries
      )
    )

    return responses.filter((bookSeries: BookSeriesData) => bookSeries.books.length > 0)
  }

  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: BOOK_SERIES.BOOK_SERIES_REQUEST })
      const res = await getAllBookSeries(offset, ids)
      dispatch({ type: BOOK_SERIES.BOOK_SERIES_SUCCESS, payload: res })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: BOOK_SERIES.BOOK_SERIES_ERROR,
      })
    }
  }
}

export const clearBookSeries = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      payload: {},
      type: BOOK_SERIES.BOOK_SERIES_CLEAR,
    })
  }
}

export interface IBookSeriesActions {
  getBookSeries: (localization: ILocalization, offset: number, seriesIds: string[]) => void
  clearBookSeries: () => void
}

export interface IBookSeries {
  bookSeries: BookSeriesData[]
}
