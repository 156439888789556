import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './Menu.module.scss'

type MenuProps = {
  onClose?: () => void
  className?: string
} & PropsWithChildren

const Menu = ({ onClose, className, children }: MenuProps) => {
  return (
    <div>
      <div className={styles.Backdrop} onClick={onClose}></div>
      <ul className={clsx(styles.List, className)}>{children}</ul>
    </div>
  )
}

export default Menu
