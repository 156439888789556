import clsx from 'clsx'
import { dynamicPaths } from 'config/pathnames.config'
import { TABLE_HEADER_HEIGHT } from 'features/tables/HeatmapTable/constants'
import { getUserStudentsStats } from 'features/tables/HeatmapTable/helpers/data'
import { getSolvedStatistics } from 'features/tables/HeatmapTable/helpers/ui'
import {
  MergedProblemsAndSubProblems,
  OnHoverStateChangeParams,
  Orientation,
} from 'features/tables/HeatmapTable/types'
import { checkIsUserRemovedFromAssignment, getFullName } from 'helpers/user.helpers'
import useText from 'i18n/hook'
import { IStudent, ITeacher } from 'api/types.users'
import React from 'react'
import RowHeaderCell from './RowHeaderCell'
import styles from './Table.module.scss'
import { IStudentsExamStats } from '../../../../../api/types.statistics'

type StudentsColumnProps = {
  exerciseId: string
  isAnonymousNameMode: boolean
  mergedProblemsAndSubProblems: MergedProblemsAndSubProblems
  rowsUsers: (IStudent | ITeacher)[]
  onHeaderElementHoverChange: (params: OnHoverStateChangeParams) => void
  isExamMode: boolean
  studentsExamStatsMap?: Map<string, IStudentsExamStats>
}

const StudentsColumn = ({
  exerciseId,
  isAnonymousNameMode,
  onHeaderElementHoverChange,
  mergedProblemsAndSubProblems,
  rowsUsers,
  isExamMode,
  studentsExamStatsMap,
}: StudentsColumnProps) => {
  const t = useText()

  return (
    <>
      <div
        className={clsx(styles.DefaultText, styles.StickyTop, styles.TableTitle)}
        style={{ height: TABLE_HEADER_HEIGHT }}
      >
        <span>{t.studentsTxt}</span>
      </div>
      {rowsUsers.map((user, index) => {
        const userStudentsStats = getUserStudentsStats({
          mergedProblemsAndSubProblems,
          userId: user._id,
        })
        const headerCellStatistics = `${getSolvedStatistics(userStudentsStats)}/${
          userStudentsStats.length
        }`
        const linkToPathname = dynamicPaths.studentMultipleSolutions({
          exerciseId,
          studentId: user._id,
        })
        const isTeacher = index + 1 === rowsUsers.length
        const anonymousName = `${t.studentTxt} ${index + 1}`

        const isUserDeleted = checkIsUserRemovedFromAssignment({
          student: user,
          isCurrentTeacher: isTeacher,
        })

        return (
          <RowHeaderCell
            isUserDeleted={isUserDeleted}
            cheats={isExamMode ? studentsExamStatsMap?.get(user._id)?.examStats?.cheats : undefined}
            linkToPathname={linkToPathname}
            userStatistics={isTeacher ? null : headerCellStatistics}
            userName={
              isAnonymousNameMode ? anonymousName : getFullName(user.firstName, user.lastName)
            }
            key={user._id}
            data-row={index}
            onMouseEnter={() =>
              onHeaderElementHoverChange({
                elementIndex: index,
                newIsHovered: true,
                orientation: Orientation.ROW,
              })
            }
            onMouseLeave={() =>
              onHeaderElementHoverChange({
                elementIndex: index,
                newIsHovered: false,
                orientation: Orientation.ROW,
              })
            }
          />
        )
      })}
    </>
  )
}

export default StudentsColumn
