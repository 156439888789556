import { SortingTypes, StatusesExercises, TypesOfParams } from 'helpers/enums'
import { IAction } from 'interfaces/actions.interface'
import { INamedDocument } from 'interfaces/basic.interfaces'
import { IDispatch } from 'interfaces/dispatch.interface'

// Filter constants
export enum FILTER {
  FILTER_CLASSES = 'FILTER_CLASSES',
  FILTER_WHOLE_CLASS = 'FILTER_WHOLE_CLASS',
  FILTER_GROUPS = 'FILTER_GROUPS',
  FILTER_TOPICS = 'FILTER_TOPICS',
  FILTER_EXAM = 'FILTER_EXAM',
  FILTER_STUDENTS = 'FILTER_STUDENTS',
  FILTER_ORDER = 'FILTER_ORDER',
  FILTER_START_DATE = 'FILTER_START_DATE',
  FILTER_END_DATE = 'FILTER_END_DATE',
  SET_SELECTED_OPTION = 'SET_SELECTED_OPTION',
  RESET_STUDENTS_FILTER = 'RESET_STUDENTS_FILTER',
  RESET_FILTERS = 'RESET_FILTERS',
  CHANGE_STATUS = 'CHANGE_STATUS',
}

// Filter reducer
const initialState = {
  data: {
    status: null,
    classes: [],
    endDate: null,
    exam: false,
    groups: [],
    order: SortingTypes.Desc,
    selectedOption: null,
    sortBy: null,
    startDate: null,
    students: [],
    topics: [],
    allClassroomStudents: null,
  },
  error: false,
  loading: false,
}

export function filterExercisesReducer(state: any = initialState, action: IAction<FILTER>) {
  switch (action.type) {
    case FILTER.CHANGE_STATUS:
      return {
        data: {
          ...state.data,
          status: action.payload.status,
          sortBy: action.payload.sortBy,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_WHOLE_CLASS:
      return {
        data: {
          ...initialState.data,
          allClassroomStudents: 1,
          classes: action.payload.classes,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_CLASSES:
      return {
        data: {
          ...state.data,
          classes: action.payload.classes,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
          allClassroomStudents: initialState.data.allClassroomStudents,
          groups: initialState.data.groups,
          exam: initialState.data.exam,
          topics: initialState.data.topics,
          students: initialState.data.students,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_GROUPS:
      return {
        data: {
          ...state.data,
          groups: action.payload.groups,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
          allClassroomStudents: initialState.data.allClassroomStudents,
          classes: initialState.data.classes,
          exam: initialState.data.exam,
          topics: initialState.data.topics,
          students: initialState.data.students,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_TOPICS:
      return {
        data: {
          ...initialState.data,
          topics: action.payload.topics,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_EXAM:
      return {
        data: {
          ...initialState.data,
          exam: true,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_STUDENTS:
      return {
        data: {
          ...initialState.data,
          students: action.payload.students,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
          classes:
            action.payload.classOrGroup?.type === TypesOfParams.CLASS
              ? action.payload.classOrGroup?._id
              : state.data.classes,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_ORDER:
      return {
        data: {
          ...initialState.data,
          order: action.payload.order,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
          exam: initialState.data.exam,
          topics: initialState.data.topics,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_START_DATE:
      return {
        data: {
          ...initialState.data,
          startDate: action.payload.startDate,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.FILTER_END_DATE:
      return {
        data: {
          ...state.data,
          endDate: action.payload.endDate,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.RESET_STUDENTS_FILTER:
      return {
        data: {
          ...state.data,
          exam: initialState.data.exam,
          topics: initialState.data.topics,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    case FILTER.RESET_FILTERS:
      return {
        data: {
          ...initialState.data,
          sortBy: action.payload.sortBy,
          selectedOption: action.payload.selectedOption,
        },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export function changeStatus(status: StatusesExercises, sortBy: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.CHANGE_STATUS, payload: { status, sortBy } })
  }
}

export function filterByClasses(
  classes: string[],
  selectedOption: ISelectedOption,
  sortBy: SortingTypes
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_CLASSES, payload: { classes, selectedOption, sortBy } })
  }
}

export function filterByWholeClass(
  classes: string[],
  selectedOption: ISelectedOption,
  sortBy: SortingTypes
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_WHOLE_CLASS, payload: { classes, selectedOption, sortBy } })
  }
}

export function filterByGroups(
  groups: string[],
  selectedOption: ISelectedOption,
  sortBy: SortingTypes
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_GROUPS, payload: { groups, selectedOption, sortBy } })
  }
}

export function filterByTopics(
  topics: string[],
  selectedOption: ISelectedOption,
  sortBy: SortingTypes
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_TOPICS, payload: { topics, selectedOption, sortBy } })
  }
}

export function filterByStudents(
  students: string[],
  selectedOption: ISelectedOption,
  sortBy?: SortingTypes,
  classOrGroup?: IMultiplicity
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: FILTER.FILTER_STUDENTS,
      payload: { students, selectedOption, sortBy, classOrGroup },
    })
  }
}

export function sortByDesc(selectedOption: ISelectedOption) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: FILTER.FILTER_ORDER,
      payload: { order: SortingTypes.Desc, sortBy: SortingTypes.Name, selectedOption },
    })
    dispatch({ type: FILTER.SET_SELECTED_OPTION, payload: selectedOption })
  }
}

export function sortByAsc(selectedOption: ISelectedOption) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: FILTER.FILTER_ORDER,
      payload: { order: SortingTypes.Asc, sortBy: SortingTypes.Name, selectedOption },
    })
  }
}

export function filterByExam(selectedOption: ISelectedOption, sortBy: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_EXAM, payload: { selectedOption, sortBy } })
  }
}

export function filterByDate(
  startDate: string,
  endDate: string,
  selectedOption: ISelectedOption,
  sortBy: SortingTypes
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: FILTER.FILTER_START_DATE,
      payload: { startDate, endDate, selectedOption, sortBy },
    })
  }
}

export function resetStudentsFilters(selectedOption: ISelectedOption, sortBy: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.RESET_STUDENTS_FILTER, payload: { selectedOption, sortBy } })
  }
}

export function resetFilters(selectedOption: ISelectedOption | null, sortBy: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.RESET_FILTERS, payload: { selectedOption, sortBy } })
  }
}

export function defaultSortAndOrder(sortType: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: FILTER.FILTER_ORDER, payload: { order: SortingTypes.Desc, sortBy: sortType } })
  }
}

interface IMultiplicity {
  _id: string[]
  type: TypesOfParams
}

export interface IFilterExercisesActions {
  changeStatus: (status: StatusesExercises, sortBy: SortingTypes) => void
  filterByClasses: (
    classes: string[],
    selectedOption: ISelectedOption,
    sortBy: SortingTypes
  ) => void
  filterByWholeClass: (
    classes: string[],
    selectedOption: ISelectedOption,
    sortBy: SortingTypes
  ) => void
  filterByGroups: (groups: string[], selectedOption: ISelectedOption, sortBy: SortingTypes) => void
  filterByTopics: (topics: string[], selectedOption: ISelectedOption, sortBy: SortingTypes) => void
  filterByStudents: (
    students: string[],
    selectedOption: ISelectedOption,
    sortBy?: SortingTypes,
    classOrGroup?: IMultiplicity
  ) => void
  sortByDesc: (selectedOption: ISelectedOption) => void
  sortByAsc: (selectedOption: ISelectedOption) => void
  filterByExam: (selectedOption: ISelectedOption, sortBy: SortingTypes) => void
  filterByDate: (
    startDate: string,
    endDate: string,
    selectedOption: ISelectedOption,
    sortBy?: SortingTypes
  ) => void
  defaultSortAndOrder: (sortType: SortingTypes) => void
  resetStudentsFilters: (selectedOption: ISelectedOption, sortBy: SortingTypes) => void
  resetFilters: (selectedOption: ISelectedOption, sortBy: SortingTypes) => void
}

export interface IFilterExercises {
  classes: string[]
  groups: string[]
  endDate: string
  topics: string[]
  exam: boolean
  order: string
  sortBy: string
  startDate: string
  students: string[]
  selectedOption: ISelectedOption
  status: StatusesExercises
  allClassroomStudents: 1 | null
}

export interface ISelectedOption extends INamedDocument {
  _id: string
  name: string
  type: TypesOfParams | null
}
