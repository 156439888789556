import React, { useState } from 'react'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

import styles from './PracticeHeader.module.scss'

import StudentsFilter from '../../filters/StudentsFilter/StudentsFilter'
import GradeSelector from './GradeSelector/GradeSelector'
import GradesSelectorModal from '../../GradesSelectorModal/GradesSelectorModal'
import { reset } from '../../GradesSelectorModal/models/studentsGradesModel'
import useText from '../../../i18n/hook'
import { TypesOfParams } from 'helpers/enums'
import { useUnit } from 'effector-react'
import { practiceFilterModel } from '../models/practiceFilter'
import { Navbar } from 'reactstrap'
import NavbarMenu from 'components/NavbarContainer/Navbar.container'
import { useHistory } from 'react-router'

const PracticeHeader = () => {
  const [isGradeSelectorMenuOpen, setIsGradeSelectorMenuOpen] = useState(false)
  const t = useText()
  const history = useHistory()

  const localization = useSelector((state: RootState) => state.localization)

  const selectedOption = useUnit(practiceFilterModel.$selectedOption)

  const selectedClassId = selectedOption?.type === TypesOfParams.CLASS ? selectedOption?._id : ''

  const handleCloseModal = () => {
    reset()
    setIsGradeSelectorMenuOpen(false)
  }

  return (
    <Navbar expand='lg' className={styles.PracticeHeaderWrapper}>
      <NavbarMenu history={history} localization={localization} location={location}>
        <div className={styles.PracticeHeaderContainer}>
          <div className={styles.GradeSelectorWrapper}>
            <div className={styles.GradeSelectorContainer}>
              <GradeSelector />
            </div>
          </div>
          <div className={styles.AdditionalSelectorsContainer}>
            {selectedClassId && (
              <>
                <Button
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZE.MEDIUM}
                  classes={{ button: styles.LockGradesButton, content: styles.LockGradesContent }}
                  onClick={() => setIsGradeSelectorMenuOpen(true)}
                >
                  {t.lockUnlockGradesTxt}
                </Button>
                {isGradeSelectorMenuOpen && (
                  <GradesSelectorModal
                    isModalOpened={isGradeSelectorMenuOpen}
                    onModalClose={handleCloseModal}
                    selectedClassId={selectedClassId}
                  />
                )}
              </>
            )}
            <div className={styles.FiltersContainer}>
              <StudentsFilter />
            </div>
          </div>
        </div>
      </NavbarMenu>
    </Navbar>
  )
}

export default PracticeHeader
