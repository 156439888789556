import { createEvent, restore } from 'effector'
import { PracticeSelectedOption, PracticeSelectedStudents } from './types'

export const AVAILABLE_GRADES = Array.from({ length: 13 }, (_, i) => i)

export class practiceFilterModel {
  public static setGrade = createEvent<number>()
  public static setSelectedStudents = createEvent<PracticeSelectedStudents>()
  public static setSelectedOption = createEvent<PracticeSelectedOption>()

  public static $grade = restore<number>(this.setGrade, -1)

  public static $selectedStudents = restore<PracticeSelectedStudents>(
    this.setSelectedStudents,
    []
  )

  public static $selectedOption = restore<PracticeSelectedOption>(this.setSelectedOption, null)
}
