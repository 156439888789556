import React, { SyntheticEvent } from 'react'
import { ISkillStatistic } from '../../../api/types.skillsStatistics'
import { GalleryCard } from '@magmamath/ui'
import CardStats from './CardStats/CardStats'

export type SkillCardProps = {
  skillName: string
  statistic: ISkillStatistic
  isStudentSelected: boolean
  onClick: (e: SyntheticEvent) => void
}

const SkillCard = ({ skillName, statistic, onClick, isStudentSelected }: SkillCardProps) => {
  const isCardDisabled = isStudentSelected && !statistic

  return (
    <GalleryCard
      title={skillName}
      onClick={onClick}
      disabled={isCardDisabled}
      rightContent={<CardStats isStudentSelected={isStudentSelected} statistic={statistic} />}
    />
  )
}

export default SkillCard
