import React from 'react'
import styles from './PracticeBody.module.scss'
import { useUnit } from 'effector-react'
import { practiceTreeModel } from '../models/practiceTree'
import PracticeCategory from '../PracticeCategory/PracticeCategory'
import EmptyExerciseContainer from '../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import { AnimatePresence, motion } from 'framer-motion'
import { practiceFilterModel } from '../models/practiceFilter'
import { practiceStatisticsModel } from '../models/practiceStatistics'

const ANIMATION_PRESET = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
}

const PracticeBody = () => {
  const currentTree = useUnit(practiceTreeModel.$currentGradeTree)
  const isTreeLoading = useUnit(practiceTreeModel.isFetching)
  const isStatisticsLoading = useUnit(practiceStatisticsModel.isFetching)

  const grade = useUnit(practiceFilterModel.$grade)
  const selectedOption = useUnit(practiceFilterModel.$selectedOption)

  if (isTreeLoading || isStatisticsLoading) {
    return <EmptyExerciseContainer heightClass={styles.Loader} />
  }

  return (
    <AnimatePresence>
      <div className={styles.PracticePageBody}>
        {!!currentTree?.children?.length &&
          currentTree.children.map((category) => {
            return (
              <motion.div
                key={category._id + grade + selectedOption?._id}
                initial={ANIMATION_PRESET.closed}
                animate={ANIMATION_PRESET.open}
                exit={ANIMATION_PRESET.closed}
              >
                <PracticeCategory category={category} />
              </motion.div>
            )
          })}
      </div>
    </AnimatePresence>
  )
}

export default PracticeBody
