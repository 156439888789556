import { MutableRefObject, useEffect } from 'react'
import { BoardController } from '@magmamath/react-native-draw-board'

export const useDrawBoardAutoReplay = (
  boardRef: MutableRefObject<BoardController | null>,
  delay = 500
) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    const unwatchImport = boardRef.current?.model.import.done.watch(() => {
      if (timeout !== null) clearTimeout(timeout)
      timeout = setTimeout(() => {
        boardRef.current?.model.historyActions.startPlaying()
        timeout = null
      }, delay)
    })

    return () => {
      unwatchImport?.()
      if (timeout !== null) clearTimeout(timeout)
    }
  }, [])
}
