import * as React from 'react'
import styles from './StudentsButtonBlock.module.scss'
import PrintClass from '../../print/PrintClass/PrintClass'
import { IModalActions } from '../../../redux/modules/modal.module'
import { IMe } from '../../../api/types.users'
import { RouteComponentProps } from 'react-router'
import { shouldShowBtn } from '../../../lib/permissions'
import { ILocalization } from '../../../config/languages.config'
import { hasDisplayName } from 'helpers/classesView.helpers'
import useText from '../../../i18n/hook'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../ui/icons/PlusIcon/PlusIcon'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IStudentsButtonBlockProps {
  localization: ILocalization
  classOrGroup: IClass | IGroup
  modalActions: IModalActions
  me: IMe
  history: RouteComponentProps['history']
}

const StudentsButtonBlock: React.FC<IStudentsButtonBlockProps> = ({
  localization,
  classOrGroup,
  modalActions,
  me,
  history,
}) => {
  const t = useText()
  const signUpStudent = () => {
    modalActions!.openModalRegister(me, localization!)
  }

  const openGroupStudentsList = () => {
    const students: any = classOrGroup?.students
    modalActions!.openGroupStudentAutosuggest(students, classOrGroup._id, localization, history)
  }

  const openClassStudentsList = () => {
    if (classOrGroup._id) modalActions!.openChooseStudentsModalUpdated(classOrGroup._id)
  }

  const isClassView = 'invitationalCode' in classOrGroup
  const isRestrictedMunicipality = me?.municipality?.isPrivate

  const openAddStudentModal = () => {
    if (isClassView) {
      return isRestrictedMunicipality ? openClassStudentsList() : signUpStudent()
    }

    return openGroupStudentsList()
  }

  const showFirstRow = () => {
    if (!isClassView) {
      return true
    }
    return shouldShowBtn(me.municipality.isPrivate, classOrGroup.source)
  }

  return (
    <>
      {isClassView && (
        <div className={styles.TitleWithIcon}>
          <PrintClass
            addedClass={classOrGroup as IClass}
            title={hasDisplayName(classOrGroup) ? classOrGroup.displayName : classOrGroup.name}
            localization={localization as any}
            isArchivedClass={classOrGroup.archived}
            isQRLoginEnabled={classOrGroup.qrCodeLogin}
          />
        </div>
      )}
      {showFirstRow() && (
        <Button
          size={SIZE.MEDIUM}
          variant={BUTTON_VARIANT.PRIMARY}
          color={BUTTON_COLOR.ORANGE}
          onClick={openAddStudentModal}
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.addStudentTxt}
        </Button>
      )}
    </>
  )
}

export default StudentsButtonBlock
