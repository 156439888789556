import { SxProps } from '@mui/material'
import { COLORS } from '@magmamath/ui'

const popperSx: SxProps = {
  zIndex: '10000',
}

const paperSx: SxProps = {
  boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.15)',
  borderRadius: '20px',
  '& .MuiCalendarOrClockPicker-root > div, & .MuiCalendarPicker-root': {
    width: '260px',
    maxHeight: '270px',
  },
  '& .MuiPickersCalendarHeader-root': {
    marginTop: '10px',
    marginBottom: '0',
    padding: '0',
  },
  '& .MuiPickersCalendarHeader-label': {
    color: COLORS.NEUTRAL_10,
    transition: 'none',
  },
  '& .MuiPickersCalendarHeader-labelContainer': {
    width: '100%',
    justifyContent: 'center',
    cursor: 'auto',
  },
  '& .MuiPickersArrowSwitcher-root': {
    width: '100%',
    justifyContent: 'space-between',
    position: 'absolute',
  },
  '& .MuiPickersArrowSwitcher-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:first-child': {
      marginLeft: '12px',
    },
    '&:last-child': {
      marginRight: '12px',
    },
    '&.Mui-disabled': {
      color: COLORS.NEUTRAL_6,
    },
  },
  '& .PrivatePickersSlideTransition-root': {
    minHeight: '188px',
  },
  '& .MuiDayPicker-weekDayLabel': {
    width: '28px',
    height: '28px',
    color: COLORS.NEUTRAL_6,
    fontSize: '11px',
  },
  '& .MuiPickersDay-root': {
    width: '28px',
    height: '28px',
    fontSize: '16px',
    color: COLORS.NEUTRAL_9,
    '&:hover': {
      backgroundColor: COLORS.NEUTRAL_3,
    },
    '&.Mui-selected': {
      color: COLORS.NEUTRAL_1,
      backgroundColor: COLORS.PRIMARY_BLUE,
      '&:hover, &:active, &:focus': {
        backgroundColor: COLORS.PRIMARY_BLUE,
      },
    },
    '&.Mui-disabled': {
      color: COLORS.NEUTRAL_6,
      '&::before': {
        content: '""',
        position: 'absolute',
        height: '1.5rem',
        width: '0.01rem',
        backgroundColor: COLORS.NEUTRAL_6,
        transform: 'rotate(45deg)',
      },
    },
    '&.MuiPickersDay-today': {
      border: 'none',
    },
  },
}

export default {
  popperSx,
  paperSx,
}
