import React, { SyntheticEvent } from 'react'
import UIModal from '../../../ui/modals/UIModal/UIModal'
import styles from './ConfirmationModal.module.scss'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import useText from '../../../i18n/hook'

type ConfirmationModal = {
  title: string
  description: string
  btnText?: string
  isOpen: boolean
  onClick: () => void
  onClose: () => void
}

const ConfirmationModal = ({
  title,
  description,
  btnText = title,
  isOpen,
  onClick,
  onClose,
}: ConfirmationModal) => {
  const t = useText()

  return (
    <UIModal
      open={isOpen}
      onClose={onClose}
      wrapperClassName={styles.WrapperClassName}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={styles.Content}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Description}>{description}</div>
        <div className={styles.ButtonsBlock}>
          <UIButton
            variant='tertiary'
            className={styles.BtnExtraClass}
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            {t.cancelTxt}
          </UIButton>
          <UIButton
            variant='secondary'
            color='red'
            className={styles.BtnExtraClass}
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            {btnText}
          </UIButton>
        </div>
      </div>
    </UIModal>
  )
}

export default ConfirmationModal
