import { IAction } from '../../interfaces/actions.interface'

export enum STATS_FOR_SINGLE_STUDENT {
  GET_STATS_FOR_SINGLE_STUDENT_REQUEST = 'GET_STATS_FOR_SINGLE_STUDENT_REQUEST',
  GET_STATS_FOR_SINGLE_STUDENT_SUCCESS = 'GET_STATS_FOR_SINGLE_STUDENT_SUCCESS',
  GET_STATS_FOR_SINGLE_STUDENT_ERROR = 'GET_STATS_FOR_SINGLE_STUDENT_ERROR',
  CLEAR_STATS_FOR_SINGLE_STUDENT = 'CLEAR_STATS_FOR_SINGLE_STUDENT',
}

const initialState = {
  data: {
    problems: {},
    subProblems: {},
  },
  error: null,
  loading: false,
}

export const statsForSingleStudentReducer = (
  state: any = initialState,
  action: IAction<STATS_FOR_SINGLE_STUDENT>
) => {
  switch (action.type) {
    case STATS_FOR_SINGLE_STUDENT.GET_STATS_FOR_SINGLE_STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STATS_FOR_SINGLE_STUDENT.GET_STATS_FOR_SINGLE_STUDENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case STATS_FOR_SINGLE_STUDENT.GET_STATS_FOR_SINGLE_STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case STATS_FOR_SINGLE_STUDENT.CLEAR_STATS_FOR_SINGLE_STUDENT:
      return {
        ...initialState.data,
        error: null,
        loading: false,
      }
    default:
      return state
  }
}
