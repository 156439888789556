import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

// prevLocation constants

export enum PREV_LOCATION {
  CHANGE = 'CHANGE',
}

// prevLocation reducer
const initialState = {
  data: {
    location: {},
  },
  error: null,
  loading: false,
}

export function prevLocationReducer(state: any = initialState, action: IAction<PREV_LOCATION>) {
  switch (action.type) {
    case PREV_LOCATION.CHANGE:
      return {
        data: { ...state.data, location: action.payload.location },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

// Hide actions
export function setPrevLocation(location: any) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { location },
      type: PREV_LOCATION.CHANGE,
    })
  }
}

export interface IPrevLocationActions {
  setPrevLocation: (location: string) => void
}

export interface IPrevLocation {
  prevLocation: {
    hash: string
    pathname: string
    search: string
    state: any
  }
}
