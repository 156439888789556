import { IAction } from '../../../interfaces/actions.interface'
import { IDispatch } from '../../../interfaces/dispatch.interface'

export enum CLASSES_FILTER {
  SELECT_CLASS = 'SELECT_CLASS',
}

export type ClassesFilter = {
  name: string
  value: string
}

const initialState = null

export const classesFilterReducer = (
  state: ClassesFilter | null = initialState,
  action: IAction<CLASSES_FILTER>
) => {
  switch (action.type) {
    case CLASSES_FILTER.SELECT_CLASS:
      return action.payload
    default:
      return state
  }
}

export const selectClass = (data: ClassesFilter) => {
  return async (dispatch: IDispatch<CLASSES_FILTER>) => {
    dispatch({ type: CLASSES_FILTER.SELECT_CLASS, payload: data })
  }
}
