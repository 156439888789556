import { IStudent, ITeacher } from './types.users'

export type ClassroomGroup = {
  _id: string
  classroomId: string
  createdAt: string
  updatedAt: string
  name: string
  owner: string
  school: string
  source: number
  students: IStudent[]
  teachers: ITeacher[]
}

export enum SourceType {
  matteappen = 0,
  skolon = 1,
  skolFederation = 2,
  google = 3,
  microsoft = 4,
  clever = 5,
  apple = 6,
  mathSync = 7,
  edlink = 8,
  ss12000 = 9,
}

export interface IClass {
  archived: boolean
  grade: number
  name: string
  owner: {
    firstName: string
    fullName: string
    lastName: string
    _id: string
  } | null
  _id: string
  invitationalCode: number
  students: IStudent[]
  teachers: any[]
  year: string
  school: string
  qrCodeLogin: boolean
  source: SourceType
  groups: ClassroomGroup[]
  displayName?: string
}

export interface GetClassesResponse {
  total: number
  pages: number
  _links: {
    self: {
      href: string
    }
    next: {
      href: string
    }
  }
  _embedded: {
    classes: IClass[]
  }
}

export type GetAllClassesPayload = {
  schoolId?: string
  fetchAll?: 1 | 0
  order?: 'asc' | 'desc'
  sortBy?: 'name'
}
export type GetSingleClassPayload = {
  id: string
  data?: {
    fetchAll?: 1 | 0
  }
}
export type UpdateClassGradePayload = {
  data: {
    grade: number
  }
  id: string
}
export type UpdateSingleClassPayload = {
  id: string
  data: {
    archived?: boolean
    qrCodeLogin?: boolean
    name?: string
    grade?: number
    displayName?: string
    teacherIds?: string[]
    ownerId?: string | null
  }
}
export type UpdateSingleClassResponse = {}
export type MoveStudentsPayload = {
  schoolClass: {
    from: string
    to: string
  }
  students: string[]
}
export type MoveStudentsResponse = {}
export type UpdateStudentsSettingsInClassPayload = {
  classId: string
  data: {
    studentId: string
    settings: {
      practiceModeLockedGrades: number[]
    }
  }[]
}
export type UpdateClassSettingsPayload = {
  classId: string
  data: {
    textToSpeech?: boolean
  }
}
export type AddNewClassPayload = {
  schoolId: string
  name: string
  grade: number
}
export type AddNewClassResponse = {
  newClass: {
    data: IClass
  }
  messageKey: string
  message?: string
}
export type DeleteClassResponse = {}
export type ResetClassPasswordsPayload = {
  classId: string
  data: {
    password: string
    passwordConfirmation: string
  }
}
export type ResetClassPasswordsResponse = {}

export type DeleteStudentFromClassPayload = {
  classId: string
  studentId: string
}
export type DeleteStudentFromClassResponse = {}
export type AddStudentToClassPayload = {
  classId: string
  data: {
    studentIds: string[]
  }
}
export type QRCodeData = { userId: string; key: string }

export interface ISchoolClass {
  _id: string
  name: string
  owner: {
    _id: string
    firstName: string
    lastName: string
  }
  students: string[]
}
