import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { COLORS } from '@magmamath/ui'

const CustomTextSlider = styled(Slider)({
  height: '8px',

  '& .MuiSlider-thumb': {
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      boxShadow: 'none',
    },

    '&:before': {
      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.15)',
    },
  },

  '& .MuiSlider-valueLabel': {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50% 50% 50% 0',
    justifyContent: 'center',
    transform: 'scale(0)',
    top: '-44px',
    left: '13px',
    zIndex: '1',
    position: 'absolute',
    fontSize: '0.75rem',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.2',
    letterSpacing: '0.01071em',
    transformOrigin: 'bottom center',

    '&:before': {
      display: 'none',
    },

    '&.MuiSlider-valueLabelOpen': {
      transform: 'rotate(-45deg) translateY(-10px) scale(1)',
    },

    '& .MuiSlider-valueLabelCircle': {
      transform: 'rotate(45deg)',
    },
  },

  '& .MuiSlider-rail': {
    background: COLORS.NEUTRAL_3,
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.1), inset 4px 2px 2px rgba(0, 0, 0, 0.15)',
  },

  '& .MuiSlider-thumbColorPrimary': {
    background: COLORS.NEUTRAL_1,
  },

  '& .MuiSlider-mark': {
    height: '6px',
    width: '6px',
    background: '#B5B5B5',
    borderRadius: '50%',
    transform: 'translate(-1px, -50%)',

    '&:nth-of-type(3),:nth-of-type(7)': {
      display: 'none',
    },
  },
})

export default CustomTextSlider
