import clsx from 'clsx'
import React, { PropsWithChildren, ReactNode } from 'react'
import styles from './SettingsOption.module.scss'

type SettingsOptionProps = JSX.IntrinsicElements['div'] & {
  icon: ReactNode
  isHidden?: boolean
  title: string
}

const SettingsOption = ({
  icon,
  isHidden,
  title,
  children,
  ...rest
}: PropsWithChildren<SettingsOptionProps>) => {
  return (
    <div
      className={clsx(styles.SettingsOption, {
        [styles.Clickable]: !!rest.onClick,
        [styles.Hidden]: isHidden,
      })}
      {...rest}
    >
      <div className={styles.Icon}>{icon}</div>
      <div className={styles.Title}>{title}</div>
      {children}
    </div>
  )
}

export default SettingsOption
