import { PATHS } from 'config/pathnames.config'
import React from 'react'
import Intercom, { IReactIntercomProps } from 'react-intercom'
import { useHistory } from 'react-router'

const SIDEBAR_HORIZONTAL_PADDING = 367
const HORIZONTAL_PADDING_PAGES = [PATHS.EXERCISES.CREATE, PATHS.PARTS.COPY, PATHS.PARTS.EDIT]

const UserIntercom = ({ appID, config }: IReactIntercomProps) => {
  const history = useHistory()
  const isSidebarPadding = HORIZONTAL_PADDING_PAGES.some((element) =>
    history.location.pathname.includes(element)
  )

  const horizontalPadding = isSidebarPadding ? SIDEBAR_HORIZONTAL_PADDING : 0

  const intercomProps = {
    ...config,
    horizontal_padding: horizontalPadding,
  }

  return <Intercom appID={appID} {...intercomProps} />
}

export default UserIntercom
