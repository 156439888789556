import { IAction } from 'interfaces/actions.interface'
export enum CHAPTER {
  CHAPTER_EDIT_REQUEST = 'CHAPTER_EDIT_REQUEST',
  CHAPTER_EDIT_SUCCESS = 'CHAPTER_EDIT_SUCCESS',
  CHAPTER_EDIT_ERROR = 'CHAPTER_EDIT_ERROR',
}

// Chapter reducer
const initialState = {
  data: {
    _id: null,
    isInitial: true,
    locale: null,
    name: null,
    order: 13,
    sections: [],
  },
  error: null,
  loading: false,
}

export function chapterReducer(state: any = initialState, action: IAction<CHAPTER>) {
  switch (action.type) {
    case CHAPTER.CHAPTER_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CHAPTER.CHAPTER_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case CHAPTER.CHAPTER_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
