import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowRightIcon = ({ color, size = 18 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 11 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 1.5L8.79007 8.48632C9.08234 8.76641 9.08234 9.23359 8.79007 9.51368L1.5 16.5'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ArrowRightIcon
