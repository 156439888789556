export enum ActionEndSource {
  BLUR = 'blur',
}

export type SubmitInputEvent = {
  getLatex: () => string
  croppedSymbols?: string
  source?: ActionEndSource
}

export type EditInputEvent = {
  latex: string
}
