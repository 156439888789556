import { NotifyType, ResponseType, StudentsCategoryTypes } from 'helpers/enums'
import { checkNetworkError, handleNetworkError, timeout } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { SubmissionError } from 'redux-form'
import { getClass } from './class.module'
import { getClasses } from './classes.module'
import { getGroup, GROUP } from './group.module'
import { getGroups } from './groups.module'
import { MODAL } from './modal.module'
import { NOTIFY } from './notify.module'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'
import { ChangePasswordPayload } from '../../api/api.auth'
import { SignUpStudentPayload, UpdateSingleStudentPayload } from '../../api/api.users'

// Auth constants
export enum USER {
  ADD_STUDENT_REQUEST = 'ADD_STUDENT_REQUEST',
  ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS',
  ADD_STUDENT_ERROR = 'ADD_STUDENT_ERROR',
  STUDENT_REQUEST = 'STUDENT_REQUEST',
  STUDENT_SUCCESS = 'STUDENT_SUCCESS',
  STUDENT_ERROR = 'STUDENT_ERROR',
  DELETE_STUDENT = 'DELETE_STUDENT',
  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
  ADD_STUDENT_ERROR_GROUP = 'ADD_STUDENT_ERROR_GROUP',
  ADD_STUDENT_SUCCESS_GROUP = 'ADD_STUDENT_SUCCESS_GROUP',
  ADD_STUDENT_REQUEST_GROUP = 'ADD_STUDENT_REQUEST_GROUP',
  ADD_STUDENT_ERROR_CLASS = 'ADD_STUDENT_ERROR_CLASS',
  ADD_STUDENT_SUCCESS_CLASS = 'ADD_STUDENT_SUCCESS_CLASS',
  ADD_STUDENT_REQUEST_CLASS = 'ADD_STUDENT_REQUEST_CLASS',
  STUDENT_REQUEST_GROUP = 'STUDENT_REQUEST_GROUP',
  DELETE_STUDENT_GROUP = 'DELETE_STUDENT_GROUP',
  STUDENT_ERROR_GROUP = 'STUDENT_ERROR_GROUP',
  EDIT_STUDENT_REQUEST = 'EDIT_STUDENT_REQUEST',
  EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS',
  EDIT_STUDENT_ERROR = 'EDIT_STUDENT_ERROR',
  UNFOLLOW_STUDENT_CLASS_REQUEST = 'UNFOLLOW_STUDENT_CLASS_REQUEST',
  UNFOLLOW_STUDENT_CLASS_SUCCESS = 'UNFOLLOW_STUDENT_CLASS_SUCCESS',
  UNFOLLOW_STUDENT_CLASS_ERROR = 'UNFOLLOW_STUDENT_CLASS_ERROR',
}

// Auth reducer
const initialState = {
  data: {
    _id: null,
    classes: [
      {
        name: null,
      },
    ],
    firstName: null,
    invitationalCode: 0,
    lastName: null,
    password: null,
    phone: 0,
    studentIds: [],
    type: 0,
    username: null,
  },
  error: null,
  loading: false,
}

export function userReducer(state: any = initialState, action: IAction<USER>) {
  switch (action.type) {
    case USER.ADD_STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.ADD_STUDENT_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case USER.EDIT_STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.EDIT_STUDENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.EDIT_STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case USER.STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.STUDENT_REQUEST_GROUP:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.STUDENT_ERROR_GROUP:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case USER.STUDENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.STUDENT_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case USER.ADD_STUDENT_REQUEST_GROUP:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.ADD_STUDENT_SUCCESS_GROUP:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.ADD_STUDENT_ERROR_GROUP:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case USER.ADD_STUDENT_REQUEST_CLASS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.ADD_STUDENT_SUCCESS_CLASS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.ADD_STUDENT_ERROR_CLASS:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case USER.UNFOLLOW_STUDENT_CLASS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case USER.UNFOLLOW_STUDENT_CLASS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case USER.UNFOLLOW_STUDENT_CLASS_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function signUpStudent(
  data: SignUpStudentPayload,
  classId: string,
  localization: ILocalization
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.ADD_STUDENT_REQUEST })
      const res = await api.users.signUpStudent(data)
      dispatch({ type: USER.ADD_STUDENT_SUCCESS, payload: res })
      getClass(classId, localization.data.studentAddedTxt)(dispatch)
      return
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (!!error.response === true) {
            const details = error.response.data.error_details
            if (details.includes('email') && localization) {
              dispatch({ type: USER.ADD_STUDENT_ERROR, payload: true })
              dispatch({ type: MODAL.CLOSE_MODAL_REGISTER, payload: { openedRegister: false } })
              dispatch({
                type: NOTIFY.NOTIFY_BEGIN,
                payload: {
                  message: localization.data.userExistingWithEmail,
                  type: NotifyType.Danger,
                },
              })
              dispatch({ type: NOTIFY.NOTIFY_END })
            } else if (details.includes('username') && localization) {
              dispatch({ type: USER.ADD_STUDENT_ERROR, payload: true })
              dispatch({ type: MODAL.CLOSE_MODAL_REGISTER, payload: { openedRegister: false } })
              dispatch({
                type: NOTIFY.NOTIFY_BEGIN,
                payload: { message: localization.data.incorrectUsername, type: NotifyType.Danger },
              })
              dispatch({ type: NOTIFY.NOTIFY_END })
            } else {
              dispatch({
                payload: !!error.response
                  ? { message: error.response.data.message }
                  : { message: error.message },
                type: USER.ADD_STUDENT_ERROR,
              })
              dispatch({
                type: NOTIFY.NOTIFY_BEGIN,
                payload: { message: localization.data.somethingWentWrong, type: NotifyType.Danger },
              })
              dispatch({ type: NOTIFY.NOTIFY_END })
            }
          }
        },
        () => handleNetworkError(USER.ADD_STUDENT_ERROR, error, dispatch)
      )
    }
  }
}

export const addStudentToClass = (
  studentIds: string[],
  classId: string,
  localization: ILocalization
) => {
  const notification: string = localization.data.studentAddedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.ADD_STUDENT_REQUEST_CLASS })
      const res = await api.classes.addStudentToClass({ classId, data: { studentIds } })
      dispatch({ type: USER.ADD_STUDENT_SUCCESS_CLASS, payload: res })
      getClass(classId)(dispatch)
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
      return
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response
              ? { message: error.response.data.message }
              : { message: error.message },
            type: USER.ADD_STUDENT_ERROR_CLASS,
          })
        },
        () => handleNetworkError(USER.ADD_STUDENT_ERROR_GROUP, error, dispatch)
      )
    }
  }
}

export function editStudent(
  data: UpdateSingleStudentPayload['data'],
  id: string,
  associationId: string,
  type: StudentsCategoryTypes,
  localization: ILocalization
) {
  const notification: string = localization.data.studentUsernameEditedTxt
  const errorMessage: string = localization.data.alreadyExistUsername
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.EDIT_STUDENT_REQUEST })
      const res = await api.users.updateSingleStudent({ studentId: id, data })
      dispatch({ type: USER.EDIT_STUDENT_SUCCESS, payload: res })
      dispatch({ type: MODAL.CLOSE_MODAL, payload: { opened: false } })
      if (type === StudentsCategoryTypes.Class) {
        getClass(associationId)(dispatch)
        getClasses()(dispatch)
      } else if (type === StudentsCategoryTypes.Group) {
        getGroup(associationId)(dispatch)
        getGroups()(dispatch)
      }
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Info },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      if (!!error.response) {
        const details = error.response.data.message
        if (details.includes('username') && localization) {
          dispatch({ type: USER.ADD_STUDENT_ERROR, payload: true })
          dispatch({ type: MODAL.CLOSE_MODAL_REGISTER, payload: { openedRegister: false } })
          dispatch({
            type: NOTIFY.NOTIFY_BEGIN,
            payload: { message: errorMessage, type: NotifyType.Danger },
          })
          dispatch({ type: NOTIFY.NOTIFY_END })
        }
      } else {
        dispatch({
          payload: !!error.response ? error.response.data.message : error.message,
          type: USER.EDIT_STUDENT_ERROR,
        })
      }
    }
  }
}

export const unfollowStudentFromClass = (
  classId: string,
  studentId: string,
  localization: ILocalization
) => {
  const notification: string = localization.data.studentDeletedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.UNFOLLOW_STUDENT_CLASS_REQUEST })
      const res = await api.classes.deleteStudentFromClass({ classId, studentId })
      dispatch({ type: USER.UNFOLLOW_STUDENT_CLASS_SUCCESS, payload: res })
      getClass(classId)(dispatch)
      await timeout(1000)
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: USER.UNFOLLOW_STUDENT_CLASS_ERROR,
          })
        },
        () => handleNetworkError(USER.ADD_STUDENT_ERROR_GROUP, error, dispatch)
      )
    }
  }
}

export function deleteStudentFromGroup(
  studentId: string,
  history: any,
  id: string,
  localization: ILocalization,
  classroomId?: string
) {
  const notification: string = localization.data.studentDeletedTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.STUDENT_REQUEST })
      await api.groups.deleteStudentFromGroup({ groupId: id, studentId })
      if (!classroomId) dispatch({ type: GROUP.DELETE_STUDENT, payload: { id: studentId } })
      if (!!classroomId) {
        await getClass(classroomId)(dispatch)
      }
      await getGroups()(dispatch)
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: USER.STUDENT_ERROR,
          })
        },
        () => handleNetworkError(USER.STUDENT_ERROR, error, dispatch)
      )
    }
  }
}

export function changeStudentsPassword(data: ChangePasswordPayload, localization: ILocalization) {
  const notification: string = localization.data.passUpdateSuccess
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: USER.CHANGE_PASSWORD_REQUEST })
      await api.auth.passwordChange(data)
      dispatch({ type: USER.CHANGE_PASSWORD_REQUEST, payload: data })
      dispatch({ type: MODAL.CLOSE_MODAL_PASSWORD })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
      return
    } catch (error) {
      if (!!error.response) {
        const details = error.response.data.error_details
        if (details.includes('fields')) {
          const arr: any[] = JSON.parse(details.substr(10))

          const result = arr.reduce((obj, item) => {
            obj[item.field] = ResponseType[item.code]
            return obj
          }, {})
          dispatch({
            type: USER.CHANGE_PASSWORD_ERROR,
          })
          throw new SubmissionError(result)
        } else {
          dispatch({
            payload: !!error.response ? { ...error.response.data } : { ...error },
            type: USER.CHANGE_PASSWORD_ERROR,
          })
        }
      }
    }
  }
}

export interface IUserActions {
  addStudentToClass: (studentIds: string[], groupId: string, localization: ILocalization) => void
  editStudent: (
    data: { username: string },
    id: string,
    associationId: string,
    history: any,
    localization: ILocalization
  ) => void
  deleteStudentFromGroup: (
    studentId: string,
    history: any,
    id: string,
    localization: ILocalization,
    classroomId?: string
  ) => void
  unfollowStudentFromClass: (
    classId: string,
    studentId: string,
    localization: ILocalization,
    history: any
  ) => void
  changeStudentsPassword: (
    data: { username: string; password: string; passwordConfirmation: string },
    localization: ILocalization
  ) => void
  signUpStudent: (
    data: {
      firstName: string
      lastName: string
      username: string
      password: string
      phone: number
      type: number
      invitationalCode: number
      schoolId: string
    },
    classId: string,
    localization: ILocalization
  ) => void
}
