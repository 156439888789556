import React from 'react'
import { useStore, useStoreMap } from 'effector-react'
import styles from './StudentsInfoList.module.scss'
import StudentCard from '../../StudentCard/StudentCard'
import { $studentsGradesData } from '../../models/studentsGradesModel'
import { $openedStudentId } from '../../models/openedStudentId'

const StudentsInfoList = () => {
  const students = useStoreMap($studentsGradesData, (data) => data.studentsGrades.students)
  const openedStudentId = useStore($openedStudentId)

  return (
    <div className={styles.GradeSelectorModalStudentsList}>
      {students.map((student) => {
        return (
          <div className={styles.StudentItem} key={student._id}>
            <StudentCard
              studentName={`${student.firstName} ${student.lastName}`}
              isActive={openedStudentId === student._id}
            />
          </div>
        )
      })}
    </div>
  )
}

export default StudentsInfoList
