import { useStoreMap } from 'effector-react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { PATHS } from '../../../config/pathnames.config'
import { MyScriptCharacterTypes } from '../../../helpers/enums'
import { RootState } from '../../../store/store'
import Divider from '../../../ui/dividers/Divider/Divider'
import UICard from '../../../ui/cards/Card/Card'
import AttentionOutlinedIcon from '../../../ui/icons/AttentionOutlinedIcon/AttentionOutlinedIcon'
import AnswerList from '../../AnswerList/AnswerList'
import styles from './CommonError.module.scss'
import ErrorMessageTitle from './ErrorMessage/ErrorMessageTitle/ErrorMessageTitle'
import FilterButton from './FilterButton/FilterButton'
import { $commonErrorData } from './model/commonErrorData'
import { fetchCommonErrorDataFx } from './model/request'
import { ErrorTypes } from './model/types'
import { IProblem, ISubProblem } from '../../../api/types.problem'

type CommonErrorProps = { problem: IProblem | ISubProblem; characterType: MyScriptCharacterTypes }

type SolutionPageParams = { taskId: string; studentId?: string }

const MIN_STUDENT_COUNT = 3

const CommonError = ({ problem, characterType }: CommonErrorProps) => {
  const { taskId, studentId } = useParams<SolutionPageParams>()
  const exerciseId = useSelector((state: RootState) => state.exerciseWithoutStats.data._id)
  const { pathname } = useLocation()
  const isMultipleSolutionsPage = pathname.includes(PATHS.PARTS.MULTIPLE_SOLUTIONS)
  const isSingleStudentPage = !!(taskId && studentId)

  const commonErrorData = useStoreMap({
    store: $commonErrorData,
    keys: [taskId],
    fn: (store) => (store && store[taskId]) ?? null,
  })

  const isShowingComponent =
    commonErrorData?.answer?.length &&
    commonErrorData?.studentIds?.length &&
    !isMultipleSolutionsPage

  useEffect(() => {
    if (isMultipleSolutionsPage) return
    fetchCommonErrorDataFx({
      exerciseId: exerciseId,
      minStudentCount: MIN_STUDENT_COUNT,
    })
  }, [exerciseId, isMultipleSolutionsPage])

  if (!isShowingComponent) return null

  return (
    <UICard className={styles.Card}>
      <div className={styles.Container}>
        <ErrorMessageTitle students={commonErrorData.studentIds} />
        <Divider className={styles.Divider} />
        <div className={styles.Body}>
          <div className={styles.Answer}>
            {commonErrorData?.errorType !== ErrorTypes.MAJOR && (
              <div className={styles.IconWrapper}>
                <AttentionOutlinedIcon size={20} color={'var(--primary-orange)'} />
              </div>
            )}
            <AnswerList
              answers={commonErrorData.answer}
              characterType={characterType}
              answerType={problem.answerType}
            />
          </div>
          {!isSingleStudentPage && <FilterButton taskId={taskId} />}
        </div>
      </div>
    </UICard>
  )
}

export default CommonError
