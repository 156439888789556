import { COLORS } from '@magmamath/ui'
import clsx from 'clsx'
import { Directions } from 'helpers/enums'
import useScrollPosition from 'lib/hooks/useScrollPosition'
import React, { forwardRef, RefObject, useLayoutEffect } from 'react'
import UIButton from 'ui/Buttons/UIButton/Button'
import ArrowIcon from 'ui/icons/ArrowIcon/ArrowIcon'
import styles from './ScrollButtons.module.scss'

type ScrollButtonsProps = {
  scrollAmount?: number
}

const ScrollButtons = forwardRef<HTMLDivElement, ScrollButtonsProps>(
  ({ scrollAmount = 250 }, ref) => {
    const { scrollPosition, scrollTo, scrollStatus, scrollAvailability, updateScrollStatus } =
      useScrollPosition(ref as RefObject<HTMLElement>)

    const onScrollButtonClick = (direction: Directions.DOWN | Directions.UP) => {
      let deltaY = 0

      const directionDeltaMap = {
        [Directions.UP]: { deltaY: -scrollAmount },
        [Directions.DOWN]: { deltaY: scrollAmount },
      }

      deltaY = directionDeltaMap[direction].deltaY

      scrollTo(scrollPosition.x, scrollPosition.y + deltaY)
    }

    const buttonsParams = [
      {
        direction: Directions.UP,
        directionClassName: styles.Top,
        isVisible: !scrollStatus.isScrolledToTop && !!scrollPosition.y,
      },
      {
        direction: Directions.DOWN,
        directionClassName: styles.Bottom,
        isVisible: !scrollStatus.isScrolledToBottom && scrollAvailability.hasScrollY,
      },
    ] as const

    useLayoutEffect(() => {
      updateScrollStatus()
    }, [])

    return (
      <>
        {buttonsParams.map(({ direction, directionClassName, isVisible }) =>
          isVisible ? (
            <UIButton
              key={direction}
              className={clsx(styles.ScrollButton, directionClassName)}
              onClick={() => onScrollButtonClick(direction)}
            >
              <ArrowIcon className={styles.ScrollArrowIcon} size={14} color={COLORS.NEUTRAL_7} />
            </UIButton>
          ) : null
        )}
      </>
    )
  }
)

export default ScrollButtons
