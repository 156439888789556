import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { timeout } from '../../helpers/modules.helpers'
import { api } from '../../api'

export enum STUD_REPORT {
  GET_STUD_REPORT_REQUEST = 'GET_STUD_REPORT_REQUEST',
  GET_STUD_REPORT_SUCCESS = 'GET_STUD_REPORT_SUCCESS',
  GET_STUD_REPORT_UPDATE = 'GET_STUD_REPORT_UPDATE',
  GET_STUD_REPORT_ERROR = 'GET_STUD_REPORT_ERROR',
  CLEAR = 'CLEAR',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

interface IParam {
  studentId: string
  page?: number
}

export function studentProgressReducer(state: any = initialState, action: IAction<STUD_REPORT>) {
  switch (action.type) {
    case STUD_REPORT.GET_STUD_REPORT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STUD_REPORT.GET_STUD_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case STUD_REPORT.GET_STUD_REPORT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case STUD_REPORT.GET_STUD_REPORT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          progress: {
            ...state.data.progress,
            studentStats: [
              ...state.data.progress.studentStats,
              ...action.payload.progress.studentStats,
            ],
          },
        },
        error: null,
        loading: false,
      }
    case STUD_REPORT.CLEAR:
      return {
        data: {},
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const getStudentProgress = (id: string, page: number) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: STUD_REPORT.GET_STUD_REPORT_REQUEST })
      await timeout(1000)
      const res = await api.statistics.getStudentsProgress({ studentId: id, page })
      page === 1
        ? dispatch({ type: STUD_REPORT.GET_STUD_REPORT_SUCCESS, payload: res })
        : dispatch({ type: STUD_REPORT.GET_STUD_REPORT_UPDATE, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: STUD_REPORT.GET_STUD_REPORT_ERROR,
      })
    }
  }
}

export const getStudentAllProgress = (id: string, totalPageCount: number, page: number) => {
  return async (dispatch: IDispatch<any>) => {
    await getStudentProgress(id, page)(dispatch)
    if (totalPageCount < page) {
      await getStudentAllProgress(id, totalPageCount, page + 1)(dispatch)
    }
  }
}

export const clearStudProgress = () => async (dispatch: IDispatch<any>) => {
  return dispatch({ type: STUD_REPORT.CLEAR })
}

export interface IStudentProgressActions {
  getStudentProgress: (id: string, page: number) => void
  getStudentAllProgress: (id: string, totalPageCount: number, page: number) => void
  clearStudProgress: () => void
}
