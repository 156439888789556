import { SourceType } from '../api/types.classes'

export enum ModalType {
  Empty = 0,
  SimpleModal = 1,
  RegisterStudentModal = 3,
  SimpleAsyncModal = 4,
  SimpleConfirmModal = 6,
  AllPasswordsModal = 9,
  TeachersAutosuggestModal = 11,
  GroupStudentsAutosuggestModal = 13,
  ChangePasswordModal = 14,
  ClassesModal = 15,
  ExerciseOverlay = 17,
  ShareLinkModal = 18,
  ChooseStudentsModalUpdated = 19,
  CreateOrEditExercise = 22,
  CreateClassModal = 23,
  LanguageSettingsModal = 24,
  TranslationLanguageSelectorModal = 25,
  OldPracticeResultsModal = 27,
  ArchiveClassModal = 28,
  PracticeResultsModal = 29,
}

export enum CalculatorType {
  Off = 0,
  Regular = 1,
  Scientific = 2,
  Graphic = 3,
}

export enum ResultsView {
  AllStudentsResults = 0,
  SingleStudentResult = 1,
  ExampleProblems = 2,
}

export enum TextSizeSettings {
  Min = 16,
  Max = 32,
  Step = 4,
}

export enum Levels {
  EasyLevel = 1,
  MediumLevel = 2,
  HardLevel = 3,
}

export enum Directions {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

export enum KeyCode {
  BACKSPACE = 8,
  TAB = 9,
  ENTER = 13,
  SHIFT = 16,
  CTRL = 17,
  ESCAPE = 27,
  SPACE = 32,
  DELETE = 46,
}

export enum Levels {
  easyLevel = 1,
  mediumLevel = 2,
  hardLevel = 3,
}

export enum ImagePosition {
  TOP = 1,
  BOTTOM = 2,
}

export enum Events {
  CLICK = 'click',
  DOM_CONTENT_LOADED = 'DOMContentLoaded',
  LOAD = 'load',
  RESIZE = 'resize',
  SCROLL = 'scroll',
  STORAGE = 'storage',
  KEYPRESS = 'keypress',
}

export enum Keys {
  ENTER = 'Enter',
}

export enum GeographyItems {
  Country = 0,
  Municipality = 1,
  School = 2,
}

export enum StatusesExercises {
  Available = 'available',
  Unpublished = 'unpublished',
  Archived = 'archived',
}

export enum AnswerType {
  handwriting = 0,
  singleChoice = 1,
  multipleChoice = 2,
  ordered = 3,
  boxes = 5,
  itemsDragging = 6,
  amountDetection = 7,
  prefilledBoxes = 8,
  prefilledChoices = 9,
  geogebra = 10,
}

export enum ResponseCode {
  notFound = 1321,
  invalidUsernameOrPassword = 1311,
}

export enum FormTypes {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  USERNAME = 'username',
  SCHOOL_ID = 'schoolId',
}

export enum ResponseType {
  forbidden = 10,
  unauthorized = 11,
  notFound = 21,
  duplicate = 22,
  validationError = 30,
  missingField = 31,
  notEqual = 32,
  empty = 33,
  undefined = 34,
  invalid = 35,
  invalidFileType = 36,
  created = 40,
  updated = 41,
  deleted = 42,
  notSend = 50,
  timeout = 98,
  uncaughtError = 99,
}

export enum SchoolType {
  Enskild = 1,
  Kommunal = 2,
  Landsting = 3,
  Statlig = 4,
}

export enum SelectedValueTypes {
  NO_VALUE = 0,
  CLASS = 1,
  DATE = 2,
  GROUP = 3,
  EXAM = 4,
  INITIALBOOKS = 5,
  NAME = 6,
  NAME_ASC = 7,
  NAME_DESC = 8,
  STUDENT = 9,
  TOPIC = 10,
}

export enum TypesOfParams {
  ALL_CLASSROOM_STUDENTS = 'allClassroomStudents',
  AUTOLOGIN_STUDENTS_TOKEN = 'autologinToken',
  AUTOLOGIN_DISTRICT_TOKEN = 'token',
  CLASSES = 'classes',
  EXAM = 'exam',
  EXERCISE_ID = 'exerciseId',
  GROUPS = 'groups',
  STATUS = 'status',
  PAGE = 'page',
  CLASS = 'class',
  GROUP = 'group',
  LOCALE = 'locale',
  ORDER = 'order',
  PROBLEM_ID = 'problemId',
  TOPICS = 'topics',
  SHOW_INDIVIDUAL = 'showIndividual',
  SUBPROBLEM_ID = 'subproblemId',
  REDIRECT_URL = 'redirectURL',
  USER_ID = 'userId',
  SORT_BY = 'sortBy',
  DATE = 'date',
  STUDENT = 'students',
  START_DATE = 'createdAtFrom',
  END_DATE = 'createdAtTo',
  SCHOOL_ID = 'schoolId',
}

export const SourceTypeName = {
  matteappen: { value: SourceType.matteappen, name: 'Matteappen' },
  skolon: { value: SourceType.skolon, name: 'Skolon' },
  google: { value: SourceType.google, name: 'Google' },
  microsoft: { value: SourceType.microsoft, name: 'Microsoft' },
  clever: { value: SourceType.clever, name: 'Clever' },
  apple: { value: SourceType.apple, name: 'Apple' },
  mathSync: { value: SourceType.mathSync, name: 'MagmaSync' },
  edlink: { value: SourceType.edlink, name: 'ClassLink' },
  ss12000: { value: SourceType.ss12000, name: 'EduCloud' },
}

export enum socketEvents {
  answerWatched = 'answerWatched',
  cancelledClass = 'cancelledClass',
  cancelledGroup = 'cancelledGroup',
  chapterSkillStatsUpdated = 'chapterSkillStatsUpdated',
  classAdded = 'classAdded',
  groupAdded = 'groupAdded',
  classDeleted = 'classDeleted',
  groupDeleted = 'groupDeleted',
  classStudentAdded = 'classStudentAdded',
  groupStudentAdded = 'groupStudentAdded',
  groupStudentRemoved = 'studentRemovedFromGroup',
  classUpdated = 'classUpdated',
  groupUpdated = 'groupUpdated',
  exerciseDeleted = 'exerciseDeleted',
  exerciseAdded = 'exerciseAdded',
  exercisePublished = 'exercisePublished',
  exerciseArchived = 'exerciseArchived',
  exerciseUnpublished = 'exerciseUnpublished',
  exerciseUpdated = 'exerciseUpdated',
  requestAdded = 'requestAdded',
  requestRemoved = 'requestRemoved',
  sharedClass = 'sharedClass',
  sharedGroup = 'sharedGroup',
  sectionStatsUpdated = 'sectionStatsUpdated',
  statisticsUpdate = 'statisticsUpdate',
  userDeleted = 'userDeleted',
  studentStateUpdated = 'studentStateUpdated',
}

export enum ProblemType {
  standard = 0,
  generatedSingle = 1,
  generatedMulti = 2,
}

export enum ContentBooks {
  myBook = 0,
  addBook = 1,
}

export enum MyScriptCharacterTypes {
  NUMBERS_WHOLE = 10,
  NUMBERS_RATIONAL = 11,
  OPERATORS = 12,
  PERCENT = 13,
  UNITS = 14,
  TIME = 15,
  CURRENCY = 16,
  GEOM_ANGELS = 17,
  GEOM_COORDINATE = 18,
  ALGEBRA_BASIC = 19,
  ALGEBRA_ADVANCED = 20,
  ALGEBRA_GREEK = 21,
  GENERAL_MATH = 22,
  DEFAULT = 1,
  NUMBERS = 2,
  NUMBER_AND_X = 3,
  NUMBER_AND_MULTIPLICATION = 4,
  NUMBERS_AND_UNITS = 5,
  DEFAULT_MATH = 6,
}

export enum StudentsCategoryTypes {
  Class = 0,
  Group = 1,
}

export enum RemovableTypes {
  Class = 0,
  Group = 1,
  Exercise = 2,
  Problem = 3,
  Section = 4,
  StudentFromClass = 5,
  StudentFromGroup = 6,
  AssessedExercise = 7,
  Teachers = 8,
}

export enum CategoryTypes {
  Book = 0,
  Chapter = 1,
  Subchapter = 2,
  Problem = 3,
  Nothing = 4,
}
export enum MenuActions {
  InputChange = 'input-change',
  MenuClose = 'menu-close',
}

export enum SortingTypes {
  Abc = 'abc',
  AbcLastname = 'AbcLastname',
  Asc = 'asc',
  Desc = 'desc',
  MostAnswers = 'mostAnswers',
  MostCorrect = 'mostCorrect',
  Name = 'name',
  Random = 'random',
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
}

export enum TypesOfStudentCell {
  ExerciseNameCell = 0,
  TypeCell = 1,
  AnsweredCell = 2,
  FirstAttemptCell = 3,
  AfterFirstAttemptCell = 4,
  WrongCell = 5,
  NotAnsweredCell = 6,
  DateCell = 7,
}

export enum TimePickerType {
  Hour = 0,
  Minute = 1,
  HourType,
}

export enum USTimeType {
  AM = 0,
  PM = 1,
}

export enum NotifyType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

export enum HeadersType {
  AcceptLanguage = 'Accept-Language',
  ContentType = 'Content-Type',
}

export enum MultiplicityType {
  class,
  group,
}

export enum SkolFedRequestType {
  SignUp = 'signup',
  SignIn = 'signin',
}

export enum SkolFedErrorType {
  EMPTY_USERNAME = 102,
  EXISTED_ACCOUNT = 103,
  NOT_FOUND_ACCOUNT = 104,
  INVALID_SCHOOL = 106,
  DUBLICATED_EMAIL = 107,
}

export enum CardColors {
  SolvedOnFirstAttempt = 'green',
  Solved = 'yellow',
  Wrong = 'red',
  NotAnswered = 'grey',
  Hidden = 'blue',
}

export enum CurrentLanguage {
  US = 'US',
  SE = 'SE',
  GB = 'GB',
}

export enum StudentListMail {
  SE = 'klasslista@magma.se',
  US = 'hello@magmamath.com',
  GB = 'hello@magmamath.com',
}

export enum LocaleData {
  US = 'en-SE',
  SE = 'sv-SE',
  GB = 'en-GB',
}

export enum FilterMultiplicityType {
  ALL,
  SINGLE_STUDENTS,
}

export enum ProfileMenuTypes {
  AccountMenu = 'account-menu',
  LanguageMenu = 'language-menu',
  LibraryMenu = 'library-menu',
  ClassesMenu = 'classes-menu',
}

export enum TaskType {
  Problem = 'problem',
  SubProblem = 'subProblem',
}

export enum StudentsHiddenParam {
  NOT_HIDDEN,
  HIDDEN,
}

export enum ActionElement {
  createClass = 'createClass',
  changeGrade = 'changeGrade',
  editClassName = 'Class',
}

export enum PieChartLink {
  CLASSES = 'classes',
  GROUPS = 'groups',
  STUDENTS = 'students',
}

export enum CategoryStatsParams {
  CLASSES = 'classes',
  GROUPS = 'groups',
}

export enum Separator {
  NONE,
  COMMA,
}

export enum FieldType {
  EMAIL = 'email',
  USER_NAME = 'username',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export enum ShareMultiplicityType {
  CLASS = 1,
  GROUP = 2,
}

export enum LayoutIds {
  EXERCISES_FILTER_TABS = 'exercises-filter-tabs',
  CLASS_TABS = 'school-card-tabs',
  STUDENTS_TABS = 'students-tabs',
  HEATMAP_TABS = 'heatmap-tabs',
}

export enum LayoutGroupIds {
  EXERCISE_ITEM = 'exercise-item',
}

export enum SearchParam {
  STUDENTS_TAB = '?tab=students',
  SETTINGS_TAB = '?tab=settings',
  GROUPS_TAB = '?tab=groups',
}

export enum HeatmapTabsParams {
  HEATMAP = 'heatmap',
  SKILLS = 'skills',
  CHART = 'pie-chart',
}

export enum ButtonsType {
  CREATE_NEW_CLASS,
  CREATE_NEW_STUDENT,
  ADD_EXISTING_STUDENT,
  REMOVE_STUDENT_FROM_CLASS,
  CREATE_TEACHER_ACCOUNT,
  CHANGE_CLASS_NAME,
  UNFOLLOW_CLASS,
  ARCHIVE_CLASS,
  CHANGE_CLASS_GRADE,
  SWITCH_SCHOOL,
  SHARE_CLASS,
  CREATE_STUDENT_ACCOUNT,
  JOINING_CLASS_BY_CLASS_CODE,
}

export enum DropdownPopupIds {
  EXERCISE_CARD = 'exercise-card',
  SEND_REPORT = 'send-report',
  CLASS_CARD = 'class-card',
  GROUP_CARD = 'group-card',
  STUDENT_LIST_ITEM = 'student-list-item',
  PROBLEM_CARD = 'problem-card',
}

export enum DateFormats {
  FULL = 'YYYY-MM-DD HH:mm',
  FULL_US = 'YYYY-MM-DD hh:mm A',
  HOURS_MINUTES = 'HH:mm',
  HOURS_MINUTES_US = 'hh:mm A',
  DAY_MONTH = 'DD MMM',
  DAY_MONTH_US = 'MMM DD',
  FULL_WITH_SECONDS = 'DD MMM YYYY HH:mm:ss',
  FULL_WITH_SECONDS_US = 'DD MMM YYYY hh:mm:ss A',
}

export enum DateLocales {
  US = 'en',
  SV = 'sv',
  'en-SE' = 'en',
  'sv-SE' = 'sv',
  GB = 'en',
  'en-GB' = 'en',
}

export enum ImageType {
  IMAGE,
  CDN_IMAGE,
}

export enum LoadingStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  IDLE = 'idle',
}

export enum SectionModalMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ConfirmModalMode {
  PROBLEM = 'problem',
  SECTION = 'section',
}
