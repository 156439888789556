import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { accountReducer } from './modules/account.module'
import { authReducer } from './modules/auth.module'
import { autologinReducer } from './modules/autologin.module'
import { bookSeriesReducer } from './modules/book-series'
import { bookSeriesNamesReducer } from './modules/books-names.book-series.module'
import { booksReducer } from './modules/books.module'
import { myBooksReducer } from './modules/my-books'
import { chapterReducer } from './modules/chapter.module'
import { CollectionIdReducer } from './modules/choosing.stud.module'
import { classReducer } from './modules/class.module'
import { classesReducer } from './modules/classes.module'
import { solutionReducer } from './modules/correctness.module'
import { countriesReducer } from './modules/countries.module'
import { exerciseReducer } from './modules/exercise.module'
import { exercisesReducer } from './modules/exercises.module'
import { exercisesStatusesReducer } from './modules/exercisesStatuses.module'
import { filterExercisesReducer } from './modules/filterExercises.module'
import { groupReducer } from './modules/group.module'
import { groupsReducer } from './modules/groups.module'
import { imageReducer } from './modules/images.module'
import { localizationReducer } from './modules/localization.module'
import { languageSettingsReducer } from './modules/languageSettings.module'
import { modalReducer } from './modules/modal.module'
import { notifyReducer } from './modules/notify.module'
import { prevLocationReducer } from './modules/prevPathname.module'
import { problemReducer } from './modules/problem.module'
import { problemsReducer } from './modules/problems.module'
import { subchaptersReducer } from './modules/problemsSidebarLinks.module'
import { schoolsClassesReducer } from './modules/schoolClasses.module'
import { schoolTeachersReducer } from './modules/schoolTeachers.module'
import { sectionReducer } from './modules/section.module'
import { solutionsSortingReducer } from './modules/solutionsSorting.module'
import { studentProgressReducer } from './modules/student-report.module'
import { userReducer } from './modules/student.module'
import { studentsReducer } from './modules/students.module'
import { topicReducer } from './modules/topic.module'
import { topicsReducer } from './modules/topics.module'
import { multipleSolutionReducer } from './modules/multipleSolutions.module'
import { pinnedSolutionsReducer } from './modules/pinnedSolutions.module'
import { skillsReducer } from './modules/skills.module'
import { skillsStatsReducer } from './modules/skillsStat.module'
import { filterMultiplicityReducer } from './modules/filterMultiplicity.module'
import { exerciseWithoutStatsReducer } from './modules/exerciseWithoutStats.module'
import { statsForSingleStudentReducer } from './modules/statsForSingleStudent.module'
import { languageReducer } from './modules/language.module'
import { solutionStatusReducer } from './modules/solutionStatuses.module'
import { skillsChaptersStatsReducer } from './modules/skillsChaptersStat.module'
import { sideBarReducer } from './modules/sidebar.module'
import { practiceReducer } from './modules/practice.module'
import { practiceResultsReducer } from './modules/practiceResults.module'
import { practiceExampleReducer } from './modules/practiceExampleSolution'
import { practiceAbilityReducer } from './modules/practiceAbility.module'
import { studentsExamStatsReducer } from './modules/studentsExamStats'
import { problemCreatorReducer } from './modules/problemCreator.module'
import { teacherSchoolsReducer } from './modules/teachersSchools.module'
import { classesFilterReducer } from '../features/filters/MultiplicityFilter/classesFilter.module'
import { practiceTheoryReducer } from './modules/practiceTheory.module'

export const reducers = combineReducers({
  account: accountReducer,
  addedClass: classReducer,
  auth: authReducer,
  autologin: autologinReducer,
  bookSeries: bookSeriesReducer,
  bookSeriesNames: bookSeriesNamesReducer,
  books: booksReducer,
  myBooks: myBooksReducer,
  chapter: chapterReducer,
  classes: classesReducer,
  classesFilter: classesFilterReducer,
  classesStudents: studentsReducer,
  collectionId: CollectionIdReducer,
  countries: countriesReducer,
  exercise: exerciseReducer,
  exerciseWithoutStats: exerciseWithoutStatsReducer,
  exercises: exercisesReducer,
  exercisesStatuses: exercisesStatusesReducer,
  filterExercises: filterExercisesReducer,
  filterMultiplicity: filterMultiplicityReducer,
  form: formReducer,
  group: groupReducer,
  groups: groupsReducer,
  image: imageReducer,
  language: languageReducer,
  localization: localizationReducer,
  languageSettings: languageSettingsReducer,
  modal: modalReducer,
  multipleSolution: multipleSolutionReducer,
  notify: notifyReducer,
  pinnedSolutions: pinnedSolutionsReducer,
  practice: practiceReducer,
  practiceResults: practiceResultsReducer,
  practiceExample: practiceExampleReducer,
  practiceTheory: practiceTheoryReducer,
  practiceAbility: practiceAbilityReducer,
  prevLocation: prevLocationReducer,
  problem: problemReducer,
  problemCreatorData: problemCreatorReducer,
  problems: problemsReducer,
  routing: routerReducer,
  schoolClasses: schoolsClassesReducer,
  section: sectionReducer,
  sideBarStatus: sideBarReducer,
  skills: skillsReducer,
  skillsStats: skillsStatsReducer,
  skillsChapterStats: skillsChaptersStatsReducer,
  solution: solutionReducer,
  solutionsSorting: solutionsSortingReducer,
  solutionStatuses: solutionStatusReducer,
  statsForSingleStudent: statsForSingleStudentReducer,
  studentProgress: studentProgressReducer,
  studentsExamStats: studentsExamStatsReducer,
  subchaptersLinks: subchaptersReducer,
  teachers: schoolTeachersReducer,
  teacherSchools: teacherSchoolsReducer,
  topic: topicReducer,
  topics: topicsReducer,
  user: userReducer,
})
