export enum Entity {
  FRAC = 'frac',
  EXPONENT = 'exponent',
  TEXT = 'text',
  SQRT = 'sqrt',
}

export type ValueNode<T extends Entity> = {
  type: T
  value: KatexNode[]
}
export type TextNode = {
  type: Entity.TEXT
  text: string
}
export type FractionNode = {
  type: Entity.FRAC
  numerator: KatexNode[]
  denominator: KatexNode[]
}
export type ExponentNode = ValueNode<Entity.EXPONENT>
export type KatexNode = TextNode | FractionNode | ValueNode<Entity.SQRT | Entity.EXPONENT>
export type KatexValueParserSettings = {
  acceptedSymbols?: RegExp
}
export type WrapSettings = {
  allowedLevels?: number
  currentLevel?: number
  preventSameEntityNesting?: boolean
  parentEntity?: Entity
}
export type ToObjectSettings = {
  filter?: ((value: string) => string) | null
}
