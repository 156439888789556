import React, { PropsWithChildren } from 'react'

import styles from './MenuItem.module.scss'
import clsx from 'clsx'

type MenuItemProps = {
  onClick?: () => void
  isActive?: boolean
  className?: string
  isExamModeOn?: boolean
} & PropsWithChildren
const MenuItem = ({ onClick, isActive, isExamModeOn, className, children }: MenuItemProps) => {
  return (
    <li
      className={clsx(
        styles.Item,
        { [styles.Active]: isActive, [styles.ExamItem]: isExamModeOn },
        className
      )}
    >
      <button onClick={onClick} type='button'>
        {children}
      </button>
    </li>
  )
}

export default MenuItem
