import { BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import useText from 'i18n/hook'
import React from 'react'
import CompressHeatmapIcon from 'ui/icons/CompressHeatmapIcon/CompressHeatmapIcon'
import ExpandHeatmapIcon from 'ui/icons/ExpandHeatmapIcon/ExpandHeatmapIcon'
import { $heatmapSettings, setCompressionMode } from '../../model/heatmapSettings'

const CompressHeatmapButton = () => {
  const t = useText()
  const isCompressed = useStoreMap($heatmapSettings, ({ isCompressed }) => isCompressed)

  const Icon = isCompressed ? ExpandHeatmapIcon : CompressHeatmapIcon
  const tooltipText = isCompressed ? t.expandHeatmap : t.compressHeatmap

  const toggleCompressionMode = () => setCompressionMode((state) => !state)

  return (
    <Button
      data-color='transparent'
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      icon={<Icon color='var(--icon-color)' size={20} />}
      onClick={toggleCompressionMode}
      isActive={false}
      tooltipText={tooltipText}
      tooltipPosition={TOOLTIP_POSITION.BOTTOM}
    />
  )
}

export default CompressHeatmapButton
