import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import { PATHS } from 'config/pathnames.config'
import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIcon from 'ui/icons/ArrowBackIcon/ArrowBackIcon'
import styles from './PageHeader.module.scss'

type PageHeaderSkeletonProps = {
  submitButtonText?: string
}

const PageHeaderSkeleton = ({ submitButtonText }: PageHeaderSkeletonProps) => (
  <div className={styles.Header}>
    <Button
      variant={BUTTON_VARIANT.TERTIARY}
      As={Link}
      to={PATHS.EXERCISES.EXERCISES_MAIN}
      icon={<ArrowBackIcon size={19} color='var(--icon-color)' />}
      classes={{ button: styles.Link }}
    />

    <Button
      classes={{
        button: styles.DisabledCreateButton,
        content: styles.DisabledCreateButtonContent,
      }}
      size={SIZE.LARGE}
      color={BUTTON_COLOR.ORANGE}
      disabled={true}
      isLoading={!submitButtonText}
    >
      {submitButtonText}
    </Button>
  </div>
)
export default PageHeaderSkeleton
