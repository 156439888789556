import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import styles from './PracticeTheory.module.scss'

import { RootState } from '../../../../store/store'
import { useAutoReplayDrawBoard } from '../../../DrawBoard/useAutoReplayDrawBoard'
import TheoryDrawBoard from './TheoryDrawBoard/TheoryDrawBoard'
import Markdown from '../../../../components/RichDescription/RichDescription.component'

const PracticeTheory = () => {
  const preview = useAutoReplayDrawBoard({ isVisible: true })
  const drawingHistory = useSelector((state: RootState) => state.practiceTheory.drawingHistory)
  const theory = useSelector((state: RootState) => state.practiceTheory.theory)

  useEffect(() => {
    if (!drawingHistory?.history) return

    preview.setDrawing(drawingHistory?.history)
  }, [drawingHistory])

  return (
    <div className={styles.PracticeTheoryContainer}>
      <div className={styles.TheoryDescriptionContent}>
        <Markdown>{theory?.problem?.richDescription}</Markdown>
      </div>
      <div className={styles.PracticeTheoryDrawBoard}>
        <TheoryDrawBoard preset={preview.preset} />
      </div>
    </div>
  )
}

export default PracticeTheory
