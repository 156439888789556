import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React, { PropsWithChildren } from 'react'
import UITooltip from '../../../tooltips/UITooltip/Tooltip'
import styles from './ChangeMultiplicityTableRowUI.module.scss'

interface IChangeMultiplicityTableRowUIProps {
  title: string
  subTitle?: string
}

const ChangeMultiplicityTableRowUI = ({
  title,
  subTitle,
  children,
}: PropsWithChildren<IChangeMultiplicityTableRowUIProps>) => {
  const { overflowRef: subTitleRef, isOverflowing } = useOverflowDetection()

  return (
    <div className={styles.Row}>
      <div className={`${styles.Item} ${styles.Title}`}>{title}</div>
      {subTitle && (
        <UITooltip title={isOverflowing ? subTitle : null}>
          <div ref={subTitleRef} className={`${styles.Item} ${styles.SubTitle}`}>
            {subTitle}
          </div>
        </UITooltip>
      )}
      <div className={`${styles.Item} ${styles.Children}`}>{children}</div>
    </div>
  )
}

export default ChangeMultiplicityTableRowUI
