import React, { useEffect, useRef, useState } from 'react'
import { closeOverlay } from 'redux/modules/modal.module'
import { getExercise } from '../../../redux/modules/exercise.module'
import { useReactToPrint } from 'react-to-print'
import styles from './ProblemsOfExerciseOverlay.module.scss'
import UIModal from '../../../ui/modals/UIModal/UIModal'
import SidebarProblemsSettings from './SidebarProblemsSettings/SidebarProblemsSettings'
import PrintProblems from '../../../screens/PrintProblems/PrintProblems'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { getMergedProblemsAndSubProblems } from '../../../screens/PrintProblems/getMergedProblemsAndSubProblems'
import { fetchImages } from '../../PrintProblems/PrintProblemsCard/fetchImages'
import { IProblem, ISubProblem } from '../../../api/types.problem'

export type ExerciseOverlayProps = {
  id: string
}

export const ExerciseOverlay = ({ id }: ExerciseOverlayProps) => {
  const dispatch = useDispatch()
  const onClose = () => dispatch(closeOverlay())
  const exercise = useSelector((state: RootState) => state.exercise.data)

  const componentRef = useRef<HTMLDivElement | null>(null)
  const [updatedProblemAndSubProblem, setUpdatedProblemAndSubProblem] = useState<
    (IProblem | ISubProblem)[]
  >([])

  const problemsAndSubProblems: (IProblem | ISubProblem)[] =
    getMergedProblemsAndSubProblems(exercise)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    dispatch(getExercise(id))
  }, [id])

  useEffect(() => {
    fetchImages(problemsAndSubProblems).then((res) => {
      setUpdatedProblemAndSubProblem(res)
    })
  }, [])

  return (
    <UIModal
      open={true}
      onClose={onClose}
      className={styles.Modal}
      wrapperClassName={styles.Wrapper}
      closeIconWrapperClassName={styles.CloseIcon}
    >
      <UIModal.Content className={styles.ModalBody}>
        <div className={styles.PreviewProblems}>
          <PrintProblems
            exerciseName={exercise.name}
            problemsAndSubProblems={updatedProblemAndSubProblem}
            ref={componentRef}
          />
        </div>
        <SidebarProblemsSettings
          onPrint={handlePrint}
          onClose={onClose}
          isLoading={updatedProblemAndSubProblem.length === 0}
        />
      </UIModal.Content>
    </UIModal>
  )
}
