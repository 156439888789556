import React from 'react'
import { DrawAreaConfig, DrawBoard, HTMLDrawAreaPreset } from '@magmamath/react-native-draw-board'
import { COLORS } from '@magmamath/ui'

type PreviewDrawBoardProps = {
  preset: HTMLDrawAreaPreset
  backgroundImage?: string
}

const DRAW_AREA_CONFIG: DrawAreaConfig = {
  paddings: {
    left: 32,
    right: 32,
    top: 32,
    bottom: 32,
  },
  gridColor: COLORS.NEUTRAL_3,
  backgroundImagePadding: 0,
}

const PreviewDrawBoard = React.memo(({ preset, backgroundImage }: PreviewDrawBoardProps) => {
  return (
    <DrawBoard
      preset={preset}
      drawAreaSettings={DRAW_AREA_CONFIG}
      fitToDrawing
      imgSrc={backgroundImage}
      hideToolbar
      disabled
      transformDisabled
    />
  )
})

export default PreviewDrawBoard
