import React from 'react'
import styles from './../SectionsList/SectionsList.module.scss'
import useText from 'i18n/hook'
import { ISection } from 'api/types.books'

type ProblemsCountProps = {
  section: ISection
}

const ProblemsCount = ({ section }: ProblemsCountProps) => {
  const t = useText()

  if (typeof section.problemsCount !== 'number') return null

  return (
    <span className={styles.ProblemsCount}>
      {section.problemsCount} {section.problemsCount === 1 ? t.problemTxt : t.problemsTxt}
    </span>
  )
}

export default ProblemsCount
