import { getImageLocalUrl } from '../../../lib/images/helper'
import { IProblem, ISubProblem } from '../../../api/types.problem'

export const fetchImages = async (problemsAndSubProblems: (IProblem | ISubProblem)[]) => {
  try {
    const imagePromises = problemsAndSubProblems.map(async (problemOrSubProblem) => {
      const image = problemOrSubProblem.image
        ? await getImageLocalUrl(problemOrSubProblem.image)
        : ''
      const drawingAreaImage = problemOrSubProblem.drawingAreaImage
        ? await getImageLocalUrl(problemOrSubProblem.drawingAreaImage)
        : ''
      return {
        ...problemOrSubProblem,
        image,
        drawingAreaImage,
      } as IProblem | ISubProblem
    })

    const resolvedImages = await Promise.all(imagePromises)
    return resolvedImages.filter((result) => result !== null)
  } catch (error) {
    console.error('Error fetching data:', error)
    return []
  }
}
