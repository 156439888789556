import _ from 'lodash'
import moment from 'moment-timezone'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import { LOCALIZE } from 'redux/modules/localization.module'
import { DateFormats } from './enums'

export function isEmptyOrUndefined(el: any) {
  return !(el == null || _.isEmpty(el))
}

export function isNotEven(i: number) {
  // tslint:disable-next-line:no-bitwise
  return i & 1
}

export function isEven(i: number) {
  // tslint:disable-next-line:no-bitwise
  return !isNotEven(i)
}
export function checkSafari() {
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)
}

export function checkMicrosoftEdge() {
  return /Edge/.test(navigator.userAgent)
}

export function checkChromeBook() {
  return /(CrOS)/.test(navigator.userAgent)
}

export function checkFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
}

export function isAllEmptyStringValuesInArray(array: string[]) {
  const isFalsy = (currentValue: string) => currentValue === ''
  return array.every(isFalsy)
}

export function returnDateOfCreation(
  date: string,
  lang: string,
  withHours: boolean,
  hasSeconds?: boolean
) {
  const sec: string = hasSeconds ? ':ss' : ''
  const dateFormat: string = withHours ? `DD MMM YYYY HH:mm${sec}` : 'DD MMMM YYYY'
  const languagesMap: { [key in LOCALIZE]: string } = {
    US: 'en-gb',
    SE: 'sv',
    GB: 'en-gb',
  }
  moment.locale(languagesMap[lang])
  const dateWithLang: any = moment(date)
  const dateTxt: string = dateWithLang.tz(moment.tz.guess()).format(dateFormat)
  return dateTxt
}

export function getExerciseDate(date: string, lang: string, withHours: boolean) {
  const hours = lang === LOCALIZE.SE ? 'HH:mm' : 'hh:mm A'
  const dateFormat: string = withHours ? `DD MMM ${hours}` : 'YYYY-MM-DD'
  const languagesMap: { [key in LOCALIZE]: string } = {
    US: 'en-gb',
    SE: 'sv',
    GB: 'en-gb',
  }
  moment.locale(languagesMap[lang])
  const dateWithLang: any = moment(date)
  const dateTxt: string = dateWithLang.tz(moment.tz.guess()).format(dateFormat)
  return dateTxt
}

export function getCheatDate(date: string, lang: string, dateFormat: DateFormats) {
  const languagesMap: { [key in LOCALIZE]: string } = {
    US: 'en-gb',
    SE: 'sv',
    GB: 'en-gb',
  }
  moment.locale(languagesMap[lang])
  const dateWithLang = moment(date)
  return dateWithLang.tz(moment.tz.guess()).format(dateFormat)
}

export function checkArraysNotEmpty(arrays: any[]) {
  return arrays.some((array) => array.length)
}
