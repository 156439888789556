import React from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from 'react-router-dom'
import { TABLE_HEADER_HEIGHT } from 'features/tables/HeatmapTable/constants'
import styles from './Table.module.scss'
import ErrorMarker from '../../../../../ui/markers/ErrorMarker/ErrorMarker'

type ColumnHeaderCellProps = {
  cellName: string
  percentNumber: number
  linkToPathname: LinkProps<unknown>['to']
  isCommonError: boolean
} & JSX.IntrinsicElements['div']

const ColumnHeaderCell = ({
  cellName,
  percentNumber,
  linkToPathname,
  isCommonError,
  ...restProps
}: ColumnHeaderCellProps) => {
  return (
    <div
      style={{ height: TABLE_HEADER_HEIGHT }}
      className={clsx(styles.HeaderCellContainer, styles.StickyTop)}
      {...restProps}
    >
      <Link className={styles.Link} to={linkToPathname}>
        <div className={styles.TableHeadHeaderCell}>
          <ErrorMarker isShowMarker={isCommonError} />
          <span className={styles.DefaultText}>{cellName}</span>
          <span className={styles.PercentNumberText}>{percentNumber}%</span>
        </div>
      </Link>
    </div>
  )
}

export default ColumnHeaderCell
