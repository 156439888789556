import { localStorageKeys, sessionStorageKeys } from 'config/localStorageKeys'

import { SourceType } from '../api/types.classes'

export function localStorageClear() {
  Object.keys(localStorage)
    .filter((key: string) => key !== localStorageKeys.language)
    .forEach((key: string) => localStorage.removeItem(key))
}

export function getItemFromSessionStorage(key: string) {
  return sessionStorage.getItem(key)
}

export function getItemFromSessionStorageWithParsing(key: string) {
  return JSON.parse(sessionStorage.getItem(key)!)
}

export function setItemToSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value)
}

export function getItemFromLocalStorage(key: string) {
  return localStorage.getItem(key)
}

export function getItemFromLocalStorageWithParsing(key: string) {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key)!)
  }
  return null
}

export function setItemToLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value)
}

export function getToken() {
  return getItemFromLocalStorage(localStorageKeys.token)
}

export function hasToken() {
  return getToken() !== undefined
}

export function isAuth() {
  return (
    !!getItemFromLocalStorage(localStorageKeys.token) &&
    !!getItemFromLocalStorage(localStorageKeys.me)
  )
}

export function isSkolon() {
  const me = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  return me && me.source === SourceType.skolon
}

export function removeChosenProblemsFromStorage() {
  localStorage.removeItem(localStorageKeys.currentProblem)
  localStorage.removeItem(localStorageKeys.currentSubProblem)
  localStorage.removeItem(localStorageKeys.student)
  localStorage.removeItem(localStorageKeys.currentExercise)
}

export function removeChosenItemsFromSessionStorage() {
  sessionStorage.removeItem(sessionStorageKeys.studentsIds)
  sessionStorage.removeItem(sessionStorageKeys.problems)
  sessionStorage.removeItem(sessionStorageKeys.allowedAnswerLooking)
  sessionStorage.removeItem(sessionStorageKeys.testModeEnabled)
}
