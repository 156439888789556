import { Levels } from 'helpers/enums'
import styles from './ProblemItem.module.scss'

export const mapProblemLevelToClass = (level: Levels) => {
  const stylesMap: { [key: number]: string } = {
    [Levels.EasyLevel]: styles.Mild,
    [Levels.MediumLevel]: styles.Medium,
    [Levels.HardLevel]: styles.Hard,
  }

  return stylesMap[level] ?? ''
}
