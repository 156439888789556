import { useEffect, useRef, useState } from 'react'
import useFunctionRef from './useFunctionRef'
import useWindowSize from './useWindowSize'

type UseOverflowDetectionProps = {
  parentWidthToTextLengthRatio?: number
  callback?: (hasOverflow: boolean) => void
  title?: string
}

const detectOverflow = (element: HTMLElement, parentWidthToTextLengthRatio?: number): boolean => {
  const isElementOverflowing =
    element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight
  const isWidthToTextLengthOverflowing =
    parentWidthToTextLengthRatio && element.firstElementChild
      ? element.clientHeight < element.firstElementChild.clientHeight ||
        element.clientWidth / (element.firstElementChild.textContent?.length ?? 1) <
          parentWidthToTextLengthRatio
      : false

  return parentWidthToTextLengthRatio ? isWidthToTextLengthOverflowing : isElementOverflowing
}

export function useOverflowDetection({
  parentWidthToTextLengthRatio,
  callback = () => {},
  title,
}: UseOverflowDetectionProps = {}) {
  const [isOverflowing, setOverflowing] = useState(false)
  const overflowRef = useRef<HTMLDivElement>(null)
  const callbackRef = useFunctionRef(callback)

  const size = useWindowSize()

  useEffect(() => {
    if (!overflowRef?.current) return

    const newIsOverflowing = detectOverflow(overflowRef.current, parentWidthToTextLengthRatio)

    if (isOverflowing !== newIsOverflowing) {
      setOverflowing(newIsOverflowing)
      callbackRef?.current?.(newIsOverflowing)
    }
  }, [overflowRef, size.width, title])

  return {
    overflowRef,
    isOverflowing,
  }
}
