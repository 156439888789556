import { Button } from '@mui/material'
import React from 'react'
import './AddExistingStudentLink.scss'
import { ILocalization } from "../../../../config/languages.config";

type AddExistingStudentLinkProps = {
  localization: ILocalization
  onAddExistingStudentClick: () => void
}

const AddExistingStudentLink = ({
  localization,
  onAddExistingStudentClick,
}: AddExistingStudentLinkProps) => {
  const { addExistingStudentTxt } = localization.data

  return (
    <Button component='a' className='add-existing link' onClick={onAddExistingStudentClick}>
      {addExistingStudentTxt}
    </Button>
  )
}

export default AddExistingStudentLink
