import { BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import React from 'react'
import { $pendingMutationStatus } from '../../model/pendingMutationStatus'
import styles from './ExamStatusButtons.module.scss'
import { getExamStatusButtonData } from './helpers'

import { ExerciseState } from '../../../../../api/types.assignments'

const SHARED_BUTTON_PROPS = {
  size: SIZE.SMALL,
  variant: BUTTON_VARIANT.PRIMARY,
  tooltipPosition: TOOLTIP_POSITION.TOP,
}

type ExamSettingsButtonProps = {
  buttonText: string
  tooltipText: string
  exerciseSettingsState: ExerciseState
  exerciseState: ExerciseState
  onClick: (() => Promise<void>) | (() => void)
}

const ExamSettingsButton = ({
  buttonText,
  tooltipText,
  exerciseSettingsState,
  exerciseState,
  onClick,
}: ExamSettingsButtonProps) => {
  const pendingMutationStatus = useUnit($pendingMutationStatus)

  const { color, Icon, isEndButton, isDisabled } = getExamStatusButtonData({
    exerciseState,
    exerciseSettingsState,
  })

  return (
    <Button
      {...SHARED_BUTTON_PROPS}
      classes={{
        button: clsx({ [styles.NoAction]: pendingMutationStatus?.mutationType }),
        content: clsx(styles.SettingsButton, { [styles.EndButton]: isEndButton }),
      }}
      color={color}
      icon={<Icon color='var(--icon-color)' size={10} />}
      tooltipText={tooltipText}
      onClick={onClick}
      disabled={isDisabled}
      isLoading={
        pendingMutationStatus?.mutationType === exerciseState && !pendingMutationStatus?.userId
      }
    >
      {buttonText}
    </Button>
  )
}

export default ExamSettingsButton
