import React from 'react'
import useText from '../../../../i18n/hook'
import isEmpty from 'lodash/isEmpty'
import { Listbox } from '@magmamath/ui'
import { OptionsType } from '../StudentsFilter'
import Divider from '../../../../ui/dividers/Divider/Divider'
import styles from '../../../../ui/filters/SubHeader/SubHeader.module.scss'

type GroupsMenuProps = {
  students: OptionsType[]
  iShowTitle: boolean
}

const StudentsOption = ({ students, iShowTitle }: GroupsMenuProps) => {
  const t = useText()
  return (
    <>
      {!!students.length && <Divider className={styles.Divider} />}
      {iShowTitle && !isEmpty(students) && <Listbox.Subheader>{t.studentsTxt}</Listbox.Subheader>}
      {students.map((student) => (
        <Listbox.Option key={student.value} value={student}>
          {student.name}
        </Listbox.Option>
      ))}
    </>
  )
}

export default StudentsOption
