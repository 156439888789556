import React from 'react'
import { BUTTON_COLOR } from '@magmamath/ui'
import AttemptsNavigation from '../AttepmptsNavigation/AttemptsNavigation'
import { AnswerType, MyScriptCharacterTypes } from '../../../../helpers/enums'
import styles from '../SolutionCard.module.scss'
import useText from '../../../../i18n/hook'
import {
  IAnswerInfo,
  IMultipleSolution,
  IStudentSolutionStats,
} from '../../../../api/types.solutions'
import AnswerList from '../../../AnswerList/AnswerList'
import PlayableControllers from '../PlayableControllers/PlayableControllers'
import { HistorySaveCredits } from '../../../DrawBoard/types'
import { DrawBoardPreviewPreset } from '../DrawBoardPreview/types'

type SolutionCardFooterProps = {
  solution: IMultipleSolution | IStudentSolutionStats
  attemptOffset: number
  attemptsCount: number
  onAttemptOffsetChange: (nextAttempt: number) => void
  buttonColor: BUTTON_COLOR
  answerType: AnswerType
  characterType: MyScriptCharacterTypes
  currentAnswerInfo: IAnswerInfo | null
  drawBoardPreset: DrawBoardPreviewPreset | null
  drawBoardBackground?: string
  credits: HistorySaveCredits
  lockAtAttemptNumber?: number
}
const SolutionCardFooter = ({
  solution,
  attemptOffset,
  attemptsCount,
  buttonColor,
  onAttemptOffsetChange,
  currentAnswerInfo,
  answerType,
  characterType,
  drawBoardPreset,
  drawBoardBackground,
  credits,
  lockAtAttemptNumber,
}: SolutionCardFooterProps) => {
  const t = useText()
  const solutionAnswers = currentAnswerInfo?.answer || []
  const selectedAttempt = currentAnswerInfo?.selectedAttempt
  const selectedAttemptNumber = lockAtAttemptNumber || selectedAttempt?.attemptNumber

  return (
    <footer className={styles.Footer}>
      <div className={styles.AttemptsNavigation}>
        {attemptsCount > 1 && !selectedAttemptNumber && (
          <AttemptsNavigation
            attemptOffset={attemptOffset}
            attemptsCount={attemptsCount}
            onNavigate={(nextAttempt) => {
              onAttemptOffsetChange(nextAttempt)
              if (drawBoardPreset) {
                drawBoardPreset.model.historyActions.toAnchor({ offset: nextAttempt })
              }
            }}
            buttonColor={buttonColor}
          />
        )}
        {(attemptsCount === 1 || selectedAttemptNumber) && (
          <p className={styles.AttemptsText}>
            {`${t.attemptTxt} ${selectedAttemptNumber || attemptsCount} ${t.ofTxt} ${
              selectedAttempt?.solutionAnswerArray?.length || attemptsCount
            }`}
          </p>
        )}
      </div>
      <div className={styles.AnswerWrapper}>
        {solution.answer && solution.answer.length > 0 ? (
          <>
            {`${t.answerTxt}: `}
            <div className={styles.Answer}>
              <AnswerList
                answers={solutionAnswers}
                characterType={characterType}
                answerType={answerType}
              />
            </div>
          </>
        ) : (
          <p className={styles.Answer}>{t.notAnsweredYet}</p>
        )}
      </div>
      {!!drawBoardPreset && (
        <PlayableControllers
          credits={credits}
          attemptsCount={attemptsCount}
          drawBoardPreset={drawBoardPreset}
          onAnchorChange={onAttemptOffsetChange}
          drawBoardBackground={drawBoardBackground}
          latestAttemptNumber={lockAtAttemptNumber}
        />
      )}
    </footer>
  )
}

export default SolutionCardFooter
