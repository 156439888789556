import React, { useState } from 'react'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'

import styles from './PracticeHeader.module.scss'

import StudentsFilter from '../../../filters/StudentsFilter/StudentsFilter'
import GradeSelector from './GradeSelector/GradeSelector'
import GradesSelectorModal from '../../../GradesSelectorModal/GradesSelectorModal'
import { reset } from '../../../GradesSelectorModal/models/studentsGradesModel'
import useText from '../../../../i18n/hook'

type PracticeHeaderProps = {
  selectedClassId: string
}

const PracticeHeader = ({ selectedClassId }: PracticeHeaderProps) => {
  const [isGradeSelectorMenuOpen, setIsGradeSelectorMenuOpen] = useState(false)
  const t = useText()

  const handleCloseModal = () => {
    reset()
    setIsGradeSelectorMenuOpen(false)
  }

  return (
    <div className={styles.PracticeHeaderWrapper}>
      <div className={styles.PracticeHeaderContainer}>
        <div className={styles.GradeSelectorWrapper}>
          <div className={styles.GradeSelectorContainer}>
            <GradeSelector />
          </div>
        </div>
        <div className={styles.AdditionalSelectorsContainer}>
          {selectedClassId && (
            <>
              <Button
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZE.MEDIUM}
                classes={{ button: styles.LockGradesButton, content: styles.LockGradesContent }}
                onClick={() => setIsGradeSelectorMenuOpen(true)}
              >
                {t.lockUnlockGradesTxt}
              </Button>
              {isGradeSelectorMenuOpen && (
                <GradesSelectorModal
                  isModalOpened={isGradeSelectorMenuOpen}
                  onModalClose={handleCloseModal}
                  selectedClassId={selectedClassId}
                />
              )}
            </>
          )}
          <div className={styles.FiltersContainer}>
            <StudentsFilter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PracticeHeader
