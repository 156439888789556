import useText from 'i18n/hook'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import GroupCard from '../GroupCard/GroupCard'
import styles from './GroupsTab.module.scss'
import { sortABC } from 'helpers/sort.helpers'
import { Button, SIZE, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT } from '@magmamath/ui'
import PlusIcon from '../../../../ui/icons/PlusIcon/PlusIcon'
import { ClassroomGroup } from '../../../../api/types.classes'
import { IGroup } from '../../../../api/api.groups'

type GroupsTabProps = {
  onEmptyGroupsButtonClick: () => void
  groups: ClassroomGroup[]
}

const GroupsTab = ({ groups, onEmptyGroupsButtonClick }: GroupsTabProps) => {
  const t = useText()
  const allAvailableGroups: IGroup[] = useSelector(
    (state: RootState) => state.groups.data?._embedded?.groups
  )

  const availableClassGroups = useMemo(() => {
    const filteredAvailableGroups = (groups ?? []).filter((group) =>
      allAvailableGroups.some((availableGroup) => availableGroup._id === group._id)
    )
    return sortABC(filteredAvailableGroups) as ClassroomGroup[]
  }, [allAvailableGroups, groups])

  if (isEmpty(groups))
    return (
      <div className={styles.Container}>
        <Button
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          color={BUTTON_COLOR.ORANGE}
          onClick={onEmptyGroupsButtonClick}
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.newGroupWithoutPlusTxt}
        </Button>
      </div>
    )

  return (
    <div className={styles.GroupsContainer}>
      <span className={styles.FakeFullRow} />
      {availableClassGroups.map((group) => (
        <GroupCard key={group._id} groupDetails={group} />
      ))}
    </div>
  )
}

export default GroupsTab
