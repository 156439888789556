import React, { useEffect, useState } from 'react'

import styles from './PracticeStudentResultsModal.module.scss'

import { ResultsView, TypesOfParams } from '../../../helpers/enums'
import { IState } from '../../../interfaces/state.interface'
import { IModal } from '../../../redux/modules/modal.module'
import ResultsModalHeader from './ResultsModalHeader/ResultsModalHeader'
import AllStudentsResults from './AllStudentsResults/AllStudentsResults'
import SingleStudentResults from './SingleStudentResults/SingleStudentResults'
import {
  fetchExampleProblemsFx,
  fetchLastStudentSolutionFx,
  fetchStudentsResultsFx,
} from '../models/requests'
import { useUnit } from 'effector-react'
import { practiceFilterModel } from '../models/practiceFilter'
import { practiceResultsModel } from '../models/practiceResults'
import { currentLocale } from 'config/modules.config'

type PracticeStudentResultsModalProps = {
  modal: IState<IModal>
  onModalClose: () => void
}
const PracticeStudentResultsModal = ({ modal, onModalClose }: PracticeStudentResultsModalProps) => {
  const { skill, classId } = modal.data

  const { currentLang } = currentLocale

  const selectedOption = useUnit(practiceFilterModel.$selectedOption)
  const selectedStudents = useUnit(practiceFilterModel.$selectedStudents)

  const [practiceResultView, setPracticeResultView] = useState<ResultsView>(
    selectedOption?.type === TypesOfParams.STUDENT
      ? ResultsView.SingleStudentResult
      : ResultsView.AllStudentsResults
  )

  const [studentResultName, setStudentResultName] = useState<string>(
    selectedOption?.type === TypesOfParams.STUDENT ? selectedOption.name : ''
  )

  useEffect(() => {
    if (!classId || !skill._id) {
      return
    }

    fetchStudentsResultsFx({
      entityType: TypesOfParams.CLASS,
      entityId: classId,
      skillId: skill._id,
    })

    fetchExampleProblemsFx(skill._id)

    const theoryId = skill.theory.find((theory) => theory.languageCode === currentLang)?.theory

    if (theoryId) {
      practiceResultsModel.loadTheory(theoryId)
    }
  }, [classId, skill])

  useEffect(() => {
    if (selectedOption?.type !== TypesOfParams.STUDENT) {
      return
    }

    fetchLastStudentSolutionFx({ userId: selectedOption._id, skillId: skill._id })
  }, [selectedOption])

  const openSingleStudentResults = (studentId: string, name: string) => {
    fetchLastStudentSolutionFx({ userId: studentId, skillId: skill._id })
    setPracticeResultView(ResultsView.SingleStudentResult)
    setStudentResultName(name)
  }

  const onBackButtonClick = () => {
    setPracticeResultView(ResultsView.AllStudentsResults)
  }

  return (
    <div className={styles.StudentResultsModalWrapper}>
      <div className={styles.StudentResultsModalContainer}>
        <ResultsModalHeader
          resultsView={practiceResultView}
          cardName={skill.nameTranslations}
          onClose={onModalClose}
          studentName={studentResultName}
          onBackPress={onBackButtonClick}
        />
        <div className={styles.HeaderBodySeparator} />
        <div className={styles.StudentsResultsContainer}>
          {practiceResultView === ResultsView.AllStudentsResults && (
            <AllStudentsResults
              selectedStudents={selectedStudents}
              onOpenSingleStudentView={openSingleStudentResults}
            />
          )}
          {practiceResultView === ResultsView.SingleStudentResult && (
            <SingleStudentResults studentName={studentResultName} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PracticeStudentResultsModal
