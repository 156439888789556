import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { setExercise } from '../tables/HeatmapTable/model/exercise'

const ReduxEffectorBind = () => {
  const exercise = useSelector((state: RootState) => state.exerciseWithoutStats.data)

  setExercise(exercise)

  return null
}

export default React.memo(ReduxEffectorBind)
