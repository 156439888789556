import React from 'react'
import { Bredcrumb } from '../types'
import styles from './SectionsList.module.scss'
import useText from 'i18n/hook'
import { useUnit } from 'effector-react'
import { $isSearchActive } from '../../model/foundBooks'
import SearchSectionsList from '../SearchSectionsList/SearchSectionsList'
import BookSectionsList from '../BookSectionsList/BookSectionsList'
import { ISection } from '../../../../api/types.books'

type SectionsListProps = {
  sections: ISection[]
  onClickSection: (section: Bredcrumb) => void
  openedSection?: Bredcrumb
}

const SectionsList = ({ sections, onClickSection, openedSection }: SectionsListProps) => {
  const t = useText()
  const isSearchActive = useUnit($isSearchActive)

  if (!sections || sections.length === 0) return <p className={styles.Text}>{t.noSectionsFound}</p>

  if (isSearchActive) {
    return (
      <SearchSectionsList
        sections={sections}
        onClickSection={onClickSection}
        openedSection={openedSection}
      />)
  }

  return <BookSectionsList sections={sections} onClickSection={onClickSection} />
}

export default SectionsList
