import { apiManager } from './apiManager'
import { IPracticeAnswerResult, IPracticeCategory, IPracticeProblem } from './types.oldPractice'

type GetLastUserSolutionPayload = {
  abilityId: string
  userId: string
}

type GetPracticeTheoryResponse = {
  historyId: string
  problem: {
    reachDescription: string
  }
}

type GetDrawingHistoryResponse = {
  history: string
}

export class oldPracticeApi {
  public static readonly getLastUserSolutions = apiManager.get<
    IPracticeAnswerResult[],
    GetLastUserSolutionPayload
  >(({ abilityId, userId }) => `led/abilities/${abilityId}/users/${userId}/solutions/last`)

  public static readonly createProblems = apiManager.post<IPracticeProblem[], string>(
    (abilityId) => `led/abilities/${abilityId}/problems`
  )

  public static readonly getAllCategories = apiManager.get<IPracticeCategory[]>('led/categories')

  public static readonly getTheory = apiManager.get<GetPracticeTheoryResponse, string>(
    (abilityId) => `led/abilities/${abilityId}/theory`
  )

  public static readonly getDrawingHistory = apiManager.get<GetDrawingHistoryResponse, string>(
    'led/drawing-history'
  )
}
