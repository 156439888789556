import { IExercise } from 'api/types.assignments'
import { IClass } from 'api/types.classes'
import { useUnit } from 'effector-react'
import { $selectedProblems } from 'features/bookshelf/Book/model/selectedProblems'
import { $errorMessages, updateErrorMessages } from 'features/caf/errorMessages/model/errorMessages'
import { ErrorMessagesFields } from 'features/caf/errorMessages/types'
import { $exerciseName, setExerciseName } from 'features/caf/exerciseSettings/model/exerciseName'
import SettingsPanel from 'features/caf/exerciseSettings/ui/SettingsPanel/SettingsPanel'
import PageHeader from 'features/caf/pageHeader/PageHeader'
import { formatClassesStoreToCollectionId } from 'features/modals/ClassroomModal/helpers'
import { $classes } from 'features/modals/ClassroomModal/model/classes'
import useText from 'i18n/hook'
import React, { useEffect, useMemo } from 'react'
import { $sectionPaths } from '../bookshelf/Book/model/sectionPaths'
import Bookshelf from '../bookshelf/Bookshelf'
import SelectedSectionsList from '../bookshelf/SelectedSectionsList/SelectedSectionsList'
import { SPECIAL_CHAPTERS_IDS } from '../bookshelf/constants'
import { $exerciseSettings } from '../caf/exerciseSettings/model/exerciseSettings'
import styles from './ExerciseDetails.module.scss'
import ExerciseMessages from './ExerciseMessages/ExerciseMessages'

type ExerciseDetailsProps = {
  classrooms: IClass[]
  isDuplicateExercise?: boolean
  exercise?: IExercise
}

const ExerciseDetails = ({ isDuplicateExercise, classrooms, exercise }: ExerciseDetailsProps) => {
  const t = useText()
  const { exerciseName } = useUnit($exerciseName)
  const sectionPaths = useUnit($sectionPaths)
  const errorMessages = useUnit($errorMessages)
  const classes = useUnit($classes)
  const settings = useUnit($exerciseSettings)
  const selectedProblems = useUnit($selectedProblems)

  const areProblemsSelected = useMemo(
    () => [...selectedProblems.values()].filter((set) => set.size > 0).length > 0,
    [selectedProblems]
  )

  const { collectionId, selectedClassesCount } = formatClassesStoreToCollectionId(classes)
  const areParticipantsSelected = Object.values(collectionId).some(
    (array) => Array.isArray(array) && array.length > 0
  )
  const hasAllRequiredValues = !!exerciseName && areProblemsSelected && areParticipantsSelected

  useEffect(() => {
    if (!errorMessages.isSubmitted) return
    updateErrorMessages({
      [ErrorMessagesFields.NAME]: exerciseName ? null : t.pickAName,
      [ErrorMessagesFields.SELECT_PROBLEMS]: areProblemsSelected ? null : t.selectYourProblemsTxt,
      [ErrorMessagesFields.PARTICIPANTS]: areParticipantsSelected ? null : t.whoIsTheAssignmentFor,
    })
  }, [errorMessages.isSubmitted, exerciseName, areProblemsSelected, areParticipantsSelected, t])

  useEffect(() => {
    if (isDuplicateExercise && exercise?.name) {
      setExerciseName({
        exerciseName: `${t.duplicateAssignment} ${exercise.name}`,
        isAutofillEnabled: false,
      })
    }
  }, [isDuplicateExercise, exercise?.name])

  const amountOfSelectedProblems = [...selectedProblems.values()].reduce((acc, problems) => {
    return acc + problems.size
  }, 0)

  const problemFromSpecialChapter = useMemo(
    () =>
      [...sectionPaths.values()].filter((sectionPath) =>
        SPECIAL_CHAPTERS_IDS.includes(sectionPath.chapterId)
      ),
    [sectionPaths]
  )

  const selectedSpecialProblems = useMemo(
    () =>
      problemFromSpecialChapter.find(
        (sectionPath) =>
          selectedProblems.has(sectionPath.id) && selectedProblems.get(sectionPath.id)?.size
      ),
    [selectedProblems, problemFromSpecialChapter]
  )

  return (
    <div className={styles.Container}>
      <PageHeader
        selectedClassesCount={selectedClassesCount}
        hasAllRequiredValues={hasAllRequiredValues}
        exercise={exercise}
        isDuplicateExercise={isDuplicateExercise}
      />
      <div className={styles.Details}>
        <div className={styles.Problems}>
          <ExerciseMessages
            isSelectProblemsError={!!errorMessages.messages[ErrorMessagesFields.SELECT_PROBLEMS]}
            amountOfSelectedProblems={amountOfSelectedProblems}
            includesProblemFromSpecialChapter={!!selectedSpecialProblems}
            isExamMode={settings.isExamMode}
          />
          <Bookshelf />
          <SelectedSectionsList exercise={exercise} />
        </div>
        <SettingsPanel
          classrooms={classrooms}
          exercise={exercise}
          isDuplicateExercise={isDuplicateExercise}
        />
      </div>
    </div>
  )
}

export default ExerciseDetails
