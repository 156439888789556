import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import * as React from 'react'
import Classes from 'views/Classes/Classes.view'

export const ClassesRoutes = [
  {
    exact: true,
    path: PATHS.CLASSES,
    render: (props: any) => {
      const classesProps: any = {
        history: props.history,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
      }
      return <Classes {...classesProps} />
    },
  },
]
