import React from 'react'
import styles from './ProblemDescriptionBlock.module.scss'
import { useDispatch } from 'react-redux'
import MathEditor from '../../mathEditor/MathEditor'
import { MathEditorModel } from '../../mathEditor/model/MathEditorModel'
import PopUpMathInput from '../../mathEditor/mathInput/popUp/PopUpMathInput'
import { PopUpMathInputModel } from '../../mathEditor/mathInput/popUp/PopUpMathInputModel'
import useText from '../../../i18n/hook'
import { setQuestion, setQuestionKatex } from '../../../redux/modules/problemCreator.module'

type ProblemDescriptionEditorProps = {
  mathEditorModel: MathEditorModel
}
const ProblemDescriptionEditor = ({ mathEditorModel }: ProblemDescriptionEditorProps) => {
  const dispatch = useDispatch()
  const t = useText()

  return (
    <div className={styles.Container}>
      <MathEditor
        model={mathEditorModel}
        placeholder={t.writeYourQuestionTxt}
        onChange={({ toHtml, toKatex }) => {
          dispatch(setQuestion(toHtml()))
          dispatch(setQuestionKatex(toKatex()))
        }}
      />
      <PopUpMathInput
        model={mathEditorModel.input as PopUpMathInputModel}
        classes={{
          container: styles.PopUpMathContainerExtraClass,
          input: styles.PopUpMathInputExtraClass,
        }}
      />
    </div>
  )
}

export default ProblemDescriptionEditor
