export const QUILL_INPUT_COMMANDS = {
  FRAC: '\\frac',
  POWER: '^',
}

const COMMANDS_LIST = Object.values(QUILL_INPUT_COMMANDS)

export function isCommand(operator: string) {
  return COMMANDS_LIST.includes(operator)
}
