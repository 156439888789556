import { apiManager } from './apiManager'

export type CreateNewTopicPayload = { name: string; exercises: string[] }
export type CreateNewTopicResponse = {
  message: string
}
export type UpdateTopicPayload = {
  topicId: string
  data: {
    name?: string
    exercises?: string[]
  }
}
export type UpdateTopicResponse = {}

export interface ITopic {
  exercises: string[]
  teachers: string[]
  _id: string
  name: string
}

export type GetAllTopicsPayload = {
  page?: number
}
export type GetAllTopicsResponse = {
  res: {
    pages: number
    _embedded: {
      topics: ITopic[]
    }
  }
}

export class topicsApi {
  public static readonly createNew = apiManager.post<CreateNewTopicResponse, CreateNewTopicPayload>(
    'exercise-topic'
  )
  public static readonly updateSingle = apiManager.put<UpdateTopicResponse, UpdateTopicPayload>(
    ({ topicId, data }) => ({
      url: `exercise-topic/${topicId}`,
      body: data,
    })
  )
  public static readonly deleteSingle = apiManager.delete<void, string>('exercise-topic')
  public static readonly getAll = apiManager.get<GetAllTopicsResponse, GetAllTopicsPayload>(
    'exercise-topic'
  )
}
