import React from 'react'
import { SvgIconProps } from '../types/types'

const BinIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4011_52696)'>
        <path
          d='M5.90625 3.90185L7.8988 1.9094C8.14468 1.66351 8.4782 1.52539 8.82597 1.52539H12.0831C12.4308 1.52539 12.7643 1.66351 13.0102 1.9094L15.0028 3.90185'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M17.3867 18.2579L18.3409 5.30988C18.397 4.54983 17.7954 3.90234 17.0333 3.90234H4.06963C3.29661 3.90234 2.69137 4.56761 2.76426 5.3372L3.99095 18.2853C4.05471 18.9584 4.62006 19.4728 5.29633 19.4728H16.0792C16.7659 19.4728 17.3363 18.9429 17.3867 18.2579Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M0.661621 3.90234H20.3295'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4011_52696'>
          <rect width='21' height='21' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BinIcon
