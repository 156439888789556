import Quill from 'quill'

export type ExtendedQuill = Quill & {
  theme: {
    tooltip: {
      boundsContainer: Element
      root: HTMLDivElement
      textbox: HTMLInputElement
    }
  }
  container: HTMLDivElement
}

type FormulaInsert = {
  formula: string
}

export type DeltaItem = {
  insert: string | FormulaInsert
}
export enum EmitterSources {
  API = 'api',
  SILENT = 'silent',
  USER = 'user',
}
