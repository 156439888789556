import React from 'react'
import clsx from 'clsx'
import styles from '../TopicsModal.module.scss'
import TickIcon from '../../../../ui/icons/TickIcon/TickIcon'
import { COLORS } from '@magmamath/ui'

import { ITopic } from '../../../../api/api.topics'

type ViewModeProps = {
  topics: ITopic[]
  selectedTopics: string[]
  onTopicClick: (topicId: string) => void
}

const ViewMode = ({ topics, selectedTopics, onTopicClick }: ViewModeProps) => {
  return (
    <ul className={styles.List}>
      {topics.map((topic) => {
        const isSelected = selectedTopics.includes(topic._id)
        return (
          <li
            className={clsx(styles.ListItem, { [styles.ListItem__active]: isSelected })}
            key={topic._id}
            onClick={() => {
              onTopicClick(topic._id)
            }}
          >
            <button className={styles.Label}>{topic.name}</button>
            {isSelected && <TickIcon size={15} color={COLORS.NEUTRAL_10} />}
          </li>
        )
      })}
    </ul>
  )
}

export default ViewMode
