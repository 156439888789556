import EmailClasslistLink from 'components/EmailClasslist/EmailClasslist'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ModalBody, ModalFooter } from 'reactstrap'
import { NOTIFY } from 'redux/modules/notify.module'
import AddExistingStudentLink from '../AddExistingStudentLink/AddExistingStudentLink'
import { AddStudentStage } from '../ModalRegisterWindow.component'
import StageHeader from '../StageHeader/StageHeader'
import './AddStudentWithClassList.scss'
import { ILocalization } from '../../../../config/languages.config'
import useText from '../../../../i18n/hook'

type AddStudentWithClassListProps = {
  localization: ILocalization
  activeStage: AddStudentStage
  setActiveStage: (tabId: AddStudentStage) => void
  invitationalCode: number
  onAddExistingStudentClick: () => void
}

const AddStudentWithClassList = ({
  localization,
  activeStage,
  setActiveStage,
  invitationalCode,
  onAddExistingStudentClick,
}: AddStudentWithClassListProps) => {
  const dispatch = useDispatch()
  const t = useText()

  useEffect(() => {
    return () => {
      dispatch({ type: NOTIFY.NOTIFY_END })
    }
  }, [])

  return (
    <>
      <StageHeader
        localization={localization}
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
      <ModalBody>
        <div className='send-classlist-wrapper'>
          <p className='send-classlist-paragraph'>{t.sendClasslistTxtTop}</p>
          <p className='send-classlist-paragraph'>{t.sendClasslistTxtBottom}</p>
          <ul className='send-classlist-list'>
            <li className='send-classlist-list-item'>{t.firstNameTxt}</li>
            <li className='send-classlist-list-item'>{t.lastNameTxt}</li>
            <li className='send-classlist-list-item'>{t.userNameTxt}</li>
            <li className='send-classlist-list-item'>
              {t.theClassCodeTxt} {invitationalCode}
            </li>
            <li className='send-classlist-list-item'>{t.grade}</li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter className='send-classlist-footer'>
        <EmailClasslistLink localization={localization} externalClassName={'send-classlist-link'}>
          <span>{t.sendEmailTxt}</span>
        </EmailClasslistLink>
        <AddExistingStudentLink
          onAddExistingStudentClick={onAddExistingStudentClick}
          localization={localization}
        />
      </ModalFooter>
    </>
  )
}

export default AddStudentWithClassList
