import { IMe } from '../../../api/types.users'
import { IClass } from '../../../api/types.classes'

type GetOwnerDetailsProps = {
  ownerId: string
  teacher: IMe
  schoolClasses: IClass[]
  currentClass: IClass
}
export const getOwnerDetails = ({
  ownerId,
  teacher,
  schoolClasses,
  currentClass,
}: GetOwnerDetailsProps) => {
  return ownerId === teacher._id
    ? teacher
    : schoolClasses.find((schoolClass: IClass) => schoolClass._id === currentClass._id)?.owner ||
        currentClass.owner
}
