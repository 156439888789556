import * as React from 'react'
import { RenderedKaTeX } from '../components/KaTeXDescription/KaTeXDescription.component'
import Markdown from '../components/RichDescription/RichDescription.component'
import { IProblem, ISubProblem } from '../api/types.problem'

const renderDescription = (problem: IProblem | ISubProblem) => {
  const { richDescription, laTeXDescription, description } = problem
  if (richDescription) {
    return <Markdown>{richDescription}</Markdown>
  }
  if (laTeXDescription) {
    return <RenderedKaTeX laTeXDescription={laTeXDescription} />
  }
  return description
}

export default renderDescription
