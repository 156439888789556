import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import styles from './BulkSelectToggle.module.scss'
import { useBulkSelectContext } from '../../contexts/BulkSelectContext/BulkSelectContext'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import clsx from 'clsx'
import { currentLocale } from '../../../../config/modules.config'
import { LocaleData } from '../../../../helpers/enums'

const BulkSelectToggle = () => {
  const t = useSelector((state: RootState) => state.localization.data)
  const { isBulkModeActive, changeBulkModeState } = useBulkSelectContext()
  const isSELocale = currentLocale.currentLang === LocaleData.SE

  const getClassName = () => {
    if (isBulkModeActive) {
      return isSELocale ? styles.TitleCancelSE : styles.TitleCancelEN
    }
    return isSELocale ? styles.TitleSelectSE : styles.TitleSelectEN
  }

  return (
    <div className={clsx(styles.Container, getClassName())}>
      <Button
        classes={{
          button: styles.ToggleButton,
        }}
        size={SIZE.SMALL}
        variant={BUTTON_VARIANT.TERTIARY}
        onClick={() => changeBulkModeState(!isBulkModeActive)}
      >
        {isBulkModeActive ? t.cancelTxt : t.selectTxt}
      </Button>
    </div>
  )
}

export default BulkSelectToggle
