import { getCheatDate } from 'helpers/general'
import React, { useMemo } from 'react'
import { CurrentLanguage, DateFormats } from '../../../../../helpers/enums'
import useText from '../../../../../i18n/hook'
import styles from './DateList.module.scss'
import { CheatsType, ICheat } from '../../../../../api/types.statistics'

interface IDateListProps {
  cheat?: ICheat[]
}

const DateList: React.FC<IDateListProps> = ({ cheat }) => {
  const t = useText()
  const format = t.languageTxt === CurrentLanguage.SE ? DateFormats.FULL : DateFormats.FULL_US

  const sortedCheat = useMemo(() => {
    const cheatsCopy: ICheat[] = [...(cheat || [])]
    return cheatsCopy.sort(
      (cheatA, cheatB) => new Date(cheatA.time).getTime() - new Date(cheatB.time).getTime()
    )
  }, [cheat])

  const TITLE: Record<CheatsType, string> = {
    [CheatsType.LEAVING_EXAM]: t.leftTheAssignment,
    [CheatsType.TEXT_REPLACEMENT]: t.textReplacement,
    [CheatsType.LEAVING_APP]: t.leftMagma,
  }

  if (!sortedCheat) return null

  return (
    <div className={styles.DateList}>
      {sortedCheat.map((item, i) => (
        <div className={styles.Container} key={i}>
          <div className={styles.Item}>{TITLE[item.type]}</div>
          <div className={styles.Data}>{getCheatDate(item.time, t.languageTxt, format)}</div>
        </div>
      ))}
    </div>
  )
}

export default DateList
