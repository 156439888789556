import { SortingTypes, TypesOfStudentCell } from 'helpers/enums'
import { IState } from 'interfaces/state.interface'
import React from 'react'
import './StudentTableHeader.component.scss'
import StudentTableHeaderCell from './StudentTableHeaderCell.component'
import { ILocalization } from '../../../../config/languages.config'
import { GetStudentProgressResponse } from '../../../../api/types.statistics'

export interface IStudentTableHeaderProps {
  localization: ILocalization
  tableHeaderProps: Array<{ order: TypesOfStudentCell; name: string }>
  studentProgress: IState<GetStudentProgressResponse>
  sortedType: SortingTypes
  sortedCell: TypesOfStudentCell
  sortExercisesFunc: (typeOfCell: TypesOfStudentCell, value: SortingTypes) => void
}

export default class StudentProgressTableHeader extends React.PureComponent<
  IStudentTableHeaderProps,
  any,
  any
> {
  constructor(props: IStudentTableHeaderProps) {
    super(props)
    this.state = {
      prevSortedCell: TypesOfStudentCell.DateCell,
    }
  }

  public changePrevSortedCell = (value: TypesOfStudentCell) => {
    this.setState({ prevSortedCell: value })
  }

  public render() {
    const { localization, tableHeaderProps } = this.props

    return (
      <thead className='student-progress-table-header'>
        <tr>
          <th className='header-cell header-of-table-cell'>
            <StudentTableHeaderCell
              localization={this.props.localization}
              order={TypesOfStudentCell.ExerciseNameCell}
              name={localization.data.exerciseNameTxt}
              sortExercisesFunc={this.props.sortExercisesFunc}
              prevSortedCell={this.state.prevSortedCell}
              changePrevSortedCell={this.changePrevSortedCell}
            />
          </th>
          {tableHeaderProps.map((prop: { order: TypesOfStudentCell; name: string }) => {
            return (
              <th className='header-cell' key={prop.order}>
                <StudentTableHeaderCell
                  localization={this.props.localization}
                  order={prop.order}
                  name={prop.name}
                  changePrevSortedCell={this.changePrevSortedCell}
                  prevSortedCell={this.state.prevSortedCell}
                  sortExercisesFunc={this.props.sortExercisesFunc}
                />
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }
}
