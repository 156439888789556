import { config } from 'config'
import { IState } from 'interfaces/state.interface'
//TODO: we use it only here, so probably we can find alternative and remove this package?
import queryString from 'query-string'
import React from 'react'
import { IAccountActions } from 'redux/modules/account.module'
import { IAuth, IAuthActions } from 'redux/modules/auth.module'
import { ICountriesActions } from 'redux/modules/countries.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { INotify } from 'redux/modules/notify.module'
import { clearAutoLogin, IAutologin, IAutologinActions } from '../../redux/modules/autologin.module'
import { ILocalizationActions } from '../../redux/modules/localization.module'
import './login.view.scss'
import { CircularProgress } from '@mui/material'
import { PATHS } from '../../config/pathnames.config'
import { Route, RouteComponentProps } from 'react-router'
import InputEmailForm from '../../components/Forms/Register/InputEmailForm/InputEmailForm'
import InputCodeForm from '../../components/Forms/Register/InputCodeForm/InputCodeForm'
import ChangePasswordForm from 'components/Forms/Register/ChangePasswordForm/ChangePasswordForm'
import { Card, CardBody } from 'reactstrap'
import { ILocalization } from '../../config/languages.config'
import { GetCountriesResponse } from '../../api/types.countries'

export interface ILoginProps {
  history: RouteComponentProps['history']
  localization?: ILocalization
  location: Location
  auth: IState<IAuth>
  autologin: IState<IAutologin>
  authActions: IAuthActions
  autologinActions: IAutologinActions
  accountActions: IAccountActions
  account: IState<IAccountActions>
  countries: IState<GetCountriesResponse>
  modal: IState<IModal>
  modalActions: IModalActions
  localizationActions: ILocalizationActions
  notification: INotify
  countriesActions: ICountriesActions
}

class Login extends React.Component<ILoginProps, any> {
  public UNSAFE_componentWillMount() {
    this.props.countriesActions.getCountries(this.props.localization as any)
  }

  public componentDidMount() {
    const parsed = queryString.parse(location.search.replace('?', ''))
    const { code, userId, token, 'auth-error-code': authErrorCode } = parsed
    if (code) {
      this.props.authActions.getTokenFromCallback(code, this.props.history, this.props.localization)
      return
    }
    if (userId && token) {
      this.props.autologinActions.autoLogin(userId as string, token as string, this.props.history)
      return
    }
    if (authErrorCode) {
      this.props.autologinActions.autoLoginError(
        authErrorCode as string,
        this.props.localization as ILocalization
      )
      return
    }

    if (localStorage.getItem('token')) {
      return
    }

    if (!this.props.auth.data.authenticated && !this.isResetPasswordUrl()) {
      this.goToAuthApp()
      return
    }
    return
  }

  public isResetPasswordUrl() {
    return [
      PATHS.LOGIN.LOGIN_PASSWORD_CODE,
      PATHS.LOGIN.LOGIN_PASSWORD_EMAIL,
      PATHS.LOGIN.LOGIN_PASSWORD_CONFIRMATION,
    ].some((path: string) => location.pathname === path)
  }

  public goToAuthApp() {
    localStorage.clear()
    window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
  }

  public componentWillUnmount() {
    clearAutoLogin()
  }

  public render() {
    return (
      <div>
        <div className='login-container-wrapper'>
          <div className='login-part-wrapper'>
            <Card className='login-part'>
              <CardBody className='login-part-body-card'>
                <Route
                  exact={true}
                  path={PATHS.LOGIN.LOGIN_MAIN}
                  render={() => (
                    <div className='auto-login-container'>
                      <div className='auto-login-title'>
                        {this.props.localization?.data.waitingForAutologin}
                      </div>
                      <CircularProgress />
                    </div>
                  )}
                />
                <Route
                  exact={true}
                  path={PATHS.LOGIN.LOGIN_PASSWORD_EMAIL}
                  render={() => (
                    <InputEmailForm
                      localization={this.props.localization}
                      location={this.props.location}
                      history={this.props.history}
                      accountActions={this.props.accountActions}
                      onSubmit={this.props.accountActions.resetPasswordRequest}
                      goToAuthApp={this.goToAuthApp}
                      localizationActions={this.props.localizationActions}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path={PATHS.LOGIN.LOGIN_PASSWORD_CODE}
                  render={() => (
                    <InputCodeForm
                      localization={this.props.localization}
                      location={this.props.location}
                      history={this.props.history}
                      accountActions={this.props.accountActions}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path={PATHS.LOGIN.LOGIN_PASSWORD_CONFIRMATION}
                  render={() => (
                    <ChangePasswordForm
                      localization={this.props.localization}
                      history={this.props.history}
                      location={this.props.location}
                      accountActions={this.props.accountActions}
                      onSubmit={this.props.accountActions.changePassword}
                    />
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
