import { DragControls, PanInfo, Point, useDragControls } from 'framer-motion'
import { useCallback, useRef } from 'react'

type UseReorderScrollProps = {
  scrollThreshold?: number // How close to the edge of the scroll container to start scrolling
  scrollAmount?: number // How many pixels to move per scroll event
}

export type RecorderScrollDragEvent = (
  ev: PointerEvent | TouchEvent,
  info: PanInfo,
  context?: { reorderManually: (directionVelocity: 1 | -1) => void }
) => void

export const useReorderScroll = ({
  scrollThreshold = 200,
  scrollAmount = 10,
}: UseReorderScrollProps = {}) => {
  const dragControls = useDragControls()
  const scrollerRef = useRef<HTMLDivElement>(null)
  const scrollStartRef = useRef<number>() // scrollTop when the drag starts
  const dragStartRef = useRef<number>() // y position of the cursor when the drag starts
  const draggingEltControls = useRef<VisualElementDragControls>()

  const onScroll = useCallback((ev: React.UIEvent<HTMLElement, UIEvent>) => {
    if (!draggingEltControls.current) return
    const startPoint = getDragStartPoint(draggingEltControls.current)
    const target = ev.target as HTMLElement | null
    if (
      !startPoint ||
      !target ||
      scrollStartRef.current === undefined ||
      dragStartRef.current === undefined
    ) {
      return
    }
    const scrollDistance = target.scrollTop - scrollStartRef.current // Distance from where the drag started
    startPoint.y = dragStartRef.current - scrollDistance // Move the startPoint to account for the scroll
  }, [])

  const onDrag: RecorderScrollDragEvent = useCallback((ev, info, context) => {
    const scrollContainer = scrollerRef.current
    if (!scrollContainer) return

    const { top: containerTop, bottom: containerBottom } = scrollContainer.getBoundingClientRect()
    const dragPoint = info.point.y
    const scrollContainerHeight = scrollContainer.scrollHeight
    const scrollContainerTop = scrollContainer.scrollTop

    const eventTarget = ev.target as Element | null
    if (!eventTarget) return

    const closestDraggable = eventTarget.closest('li[draggable]')
    if (!closestDraggable) return

    const parent = closestDraggable.parentElement
    if (!parent) return

    const isAtTopBoundary = dragPoint < containerTop + scrollThreshold
    const isAtBottomBoundary = dragPoint > containerBottom - scrollThreshold

    const shouldScrollUp =
      isAtTopBoundary && (closestDraggable !== parent.firstElementChild || scrollContainerTop > 0)

    const shouldScrollDown =
      isAtBottomBoundary &&
      (closestDraggable !== parent.lastElementChild || scrollContainerTop < scrollContainerHeight)

    if (shouldScrollUp) {
      scrollContainer.scrollTop -= scrollAmount
      context?.reorderManually(-1)
    }

    if (shouldScrollDown) {
      const lastListItem = parent.lastElementChild

      if (lastListItem && lastListItem.id !== closestDraggable.id) {
        const { top: lastItemTop, height: lastItemHeight } = lastListItem.getBoundingClientRect()
        const { top: dragItemTop, height: dragItemHeight } =
          closestDraggable.getBoundingClientRect()

        const isBottomOfList = dragItemTop + dragItemHeight >= lastItemTop + lastItemHeight

        if (!isBottomOfList) {
          scrollContainer.scrollTop += scrollAmount
          context?.reorderManually(1)
        }
      }
    }
  }, [])

  // Tracks the scroll distance by capturing scrollTop when the drag starts
  const onDragStart = useCallback(() => {
    const scroller = scrollerRef.current
    const controls = findDraggingElementControls(dragControls)
    if (!scroller || !controls) return
    draggingEltControls.current = controls
    scrollStartRef.current = scroller.scrollTop
    const startPoint = getDragStartPoint(controls)
    if (!startPoint) return
    dragStartRef.current = startPoint.y
  }, [dragControls])

  const onDragEnd = useCallback(() => {
    scrollStartRef.current = undefined
    dragStartRef.current = undefined
    draggingEltControls.current = undefined
  }, [])

  return {
    // On the scrolling container
    scrollerRef,
    onScroll,
    // On the item
    dragControls,
    onDrag,
    onDragStart,
    onDragEnd,
  }
}

// A private Framer class that we're just using one little piece of
// https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/drag/VisualElementDragControls.ts#L53
type VisualElementDragControls = {
  panSession: {
    history: Point[]
  }
}

const findDraggingElementControls = (dragControls: DragControls) => {
  try {
    return Array.from<VisualElementDragControls>(
      // @ts-ignore - we're reaching into a private prop
      // https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/drag/use-drag-controls.ts#L29
      dragControls.componentControls
    ).find((c: DragControls['componentControls']) => c.isDragging)
  } catch (err) {
    // If this private prop moves in the future, we'll start logging errors here
    console.error('[caught] findDraggingElementControls', err)
    return
  }
}

const getDragStartPoint = (controls: VisualElementDragControls): Point | undefined => {
  try {
    // https://github.com/framer/motion/blob/fb227f8b700c6e2d9c3b33d0a2af1a2d2b7849e9/packages/framer-motion/src/gestures/pan/PanSession.ts#L257
    return controls.panSession.history[0]
  } catch (err) {
    // If this private prop moves in the future, we'll start logging errors here
    console.error('[caught] getDraggingElementStartPoint', err)
    return
  }
}
