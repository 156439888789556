import { PATHS } from 'config/pathnames.config'
import { LayoutIds } from 'helpers/enums'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store/store'
import styles from './SchoolCardTabs.module.scss'
import UITabs from '../../../../ui/tabs/UITabs/UITabs'
import UITab from '../../../../ui/tabs/UITab/UITab'

export enum SchoolTabCards {
  CLASSES = 'classes',
  GROUPS = 'groups',
}

type SchoolCardTabsProps = {
  activeTab: SchoolTabCards
}

const SchoolCardTabs = ({ activeTab }: SchoolCardTabsProps) => {
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data

  return (
    <UITabs layoutId={LayoutIds.CLASS_TABS} withDividerBottom className={styles.SchoolCardTabs}>
      <Link to={`${PATHS.CLASSES}?tab=${SchoolTabCards.CLASSES}`}>
        <UITab
          isActive={activeTab === SchoolTabCards.CLASSES}
          title={t.classesTxt}
          layoutId={LayoutIds.CLASS_TABS}
          className={styles.ClassesSchoolCardTab}
        />
      </Link>
    </UITabs>
  )
}

export default SchoolCardTabs
