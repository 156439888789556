import { apiManager } from './apiManager'
import { SortingTypes } from '../helpers/enums'
import { GetCountriesResponse, GetMunicipalitiesResponse } from './types.countries'
import { IQueryParams } from '../interfaces/params.interface'

type GetMunicipalitiesPayload = {
  countryId: string
  params?: {
    sort?: SortingTypes
    offset?: number
    search?: string
  }
}

export class countriesApi {
  public static readonly getAll = apiManager.get<GetCountriesResponse, IQueryParams | void>(
    'countries'
  )
  public static readonly getMunicipalities = apiManager.get<
    GetMunicipalitiesResponse,
    GetMunicipalitiesPayload
  >(({ countryId, params }) => {
    const requestParams: GetMunicipalitiesPayload['params'] = {}
    if (params?.sort) requestParams.sort = params.sort
    if (params?.offset) requestParams.offset = params.offset
    if (params?.search && params.search.length >= 3) requestParams.search = params.search
    return {
      url: `countries/${countryId}/municipalities`,
      params: requestParams,
    }
  })
}
