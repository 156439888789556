import { IStatistics } from '../api/types.oldPractice'

export const mergeStatistic = (stateStatistics: IStatistics, statistics: IStatistics[]) => {
  const newStatistics = {}
  statistics.forEach((statistic) => {
    Object.keys(statistic).forEach((key) => {
      const lastStat = statistic[key]
      Object.assign(newStatistics, { [key]: lastStat })
    })
  })

  return newStatistics
}
