import { useSelector } from 'react-redux'
import { RootState } from '../store/store'

import { ILocalization } from '../config/languages.config'

const useText = () => {
  return useSelector((state: RootState) => (state.localization as ILocalization).data)
}

export default useText
