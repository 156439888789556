import React from 'react'

import { SvgIconProps } from '../types/types'
const CheckBoxIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_3_43' fill='white'>
        <rect width='15' height='15' rx='1' />
      </mask>
      <rect
        width='15'
        height='15'
        rx='1'
        stroke={color}
        strokeWidth='4'
        mask='url(#path-1-inside-1_3_43)'
      />
      <path d='M2 13V2H13V13H2Z' stroke='white' />
    </svg>
  )
}

export default CheckBoxIcon
