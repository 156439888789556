import React from 'react'
import { SvgIconProps } from '../types/types'

const CurrencyIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.2222 6.30789C16.0546 5.09528 13.9998 4.25322 12.1111 4.19956M12.1111 4.19956C9.86439 4.13694 7.85267 5.19367 7.85267 8.07633C7.85267 13.3842 17.2222 10.7303 17.2222 16.0382C17.2222 19.0665 14.7282 20.3673 12.1111 20.2689M12.1111 4.19956V1M7 17.8079C8.09875 19.3284 10.14 20.1948 12.1111 20.2689M12.1111 20.2689V24'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.1133 1V24' stroke='black' stroke-width='1.66' stroke-linecap='round' />
    </svg>
  )
}

export default CurrencyIcon
