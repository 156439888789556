import { Listbox, SIZE, SelectValue } from '@magmamath/ui'
import { currentLocale } from 'config/modules.config'
import dayjs from 'dayjs'
import { useUnit } from 'effector-react'
import {
  $exerciseSettings,
  setExerciseSetting,
} from 'features/caf/exerciseSettings/model/exerciseSettings'
import ScheduleModal from 'features/caf/exerciseSettings/ui/ScheduleModal/ScheduleModal'
import { LocaleData } from 'helpers/enums'
import { convertHourTo12HFormat } from 'helpers/timeConverterHelper'
import useText from 'i18n/hook'
import React, { useEffect, useState } from 'react'
import UIModal from 'ui/modals/UIModal/UIModal'
import { $exerciseStatus, setExerciseStatus } from '../../model/exerciseStatus'
import styles from './ExerciseStatusOption.module.scss'
import { EXERCISE_SETTINGS } from 'features/caf/exerciseSettings/types'
import { ExerciseState, ExerciseStatuses, IExercise } from '../../../../../api/types.assignments'

const getStatusDescriptors = (t: ReturnType<typeof useText>) =>
  ({
    [ExerciseStatuses.PublishNow]: {
      text: t.nowTxt,
      dateGetter: () => null,
    },
    [ExerciseStatuses.Unpublished]: {
      text: t.unpublishedSingleTxt,
      dateGetter: () => new Date(),
    },
    [ExerciseStatuses.Schedule]: {
      text: t.scheduleTxt,
      dateGetter: () => new Date(),
    },
  } as const)

const setScheduledData = (startDate: string | undefined, isArchived: boolean) => {
  const newDate = dayjs(startDate)
  const updateDateProperty = isArchived
    ? EXERCISE_SETTINGS.SCHEDULE_ARCHIVE_DATE
    : EXERCISE_SETTINGS.SCHEDULE_DATE
  setExerciseSetting({ key: updateDateProperty, newValue: newDate })

  const startHour = newDate.get('hour')
  const startMinute = newDate.get('minute')
  const { hour, type } =
    currentLocale.currentLang === LocaleData.SE
      ? { hour: startHour, type: null }
      : convertHourTo12HFormat(startHour)
  const updateTimeProperty = isArchived
    ? EXERCISE_SETTINGS.SCHEDULED_ARCHIVE_TIME
    : EXERCISE_SETTINGS.SCHEDULED_TIME
  setExerciseSetting({ key: updateTimeProperty, newValue: { hour, minute: startMinute, type } })
}

type ExerciseStatusOptionProps = {
  exercise?: IExercise
}

const ExerciseStatusOption = ({ exercise }: ExerciseStatusOptionProps) => {
  const settings = useUnit($exerciseSettings)
  const exerciseStatus = useUnit($exerciseStatus)

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
  const t = useText()

  const STATUS_DESCRIPTORS = getStatusDescriptors(t)

  const onExerciseStatusSelect = (status: ExerciseStatuses) => {
    if (exerciseStatus === status) return

    setExerciseStatus(status)
    const newStatusDescriptor = STATUS_DESCRIPTORS[status]
    setExerciseSetting({
      key: EXERCISE_SETTINGS.START_DATE,
      newValue: newStatusDescriptor.dateGetter(),
    })
  }

  const onExerciseChange = () => {
    if (!exercise?._id) return

    const isPublishNowState = Boolean(exercise?.published && exercise?.startDate)
    const isUnpublishedState = !exercise?.published && !exercise?.archived && !exercise?.startDate
    const isScheduleState = Boolean(
      !exercise?.published && !exercise?.archived && exercise?.startDate
    )

    if (isPublishNowState && exercise.startDate) {
      setExerciseStatus(ExerciseStatuses.PublishNow)
      setExerciseSetting({
        key: EXERCISE_SETTINGS.START_DATE,
        newValue: new Date(exercise.startDate),
      })

      if (exercise?.testModeEnabled && exercise?.settings?.state === ExerciseState.Started) {
        setIsScheduleModalOpen(false)
      }

      return
    }

    if (isUnpublishedState) {
      setExerciseStatus(ExerciseStatuses.Unpublished)
      setExerciseSetting({ key: EXERCISE_SETTINGS.START_DATE, newValue: null })

      return
    }

    if (isScheduleState) {
      setExerciseStatus(ExerciseStatuses.Schedule)
      setScheduledData(exercise.startDate, false)
      setScheduledData(exercise.endDate, true)
    }
  }

  useEffect(() => {
    onExerciseChange()
  }, [exercise])

  return (
    <>
      <Listbox
        placeholder={t.statusTxt}
        size={SIZE.SMALL}
        classes={{ ListboxOptions: styles.ListboxOptions, Button: styles.ListboxButton }}
        value={{
          name: STATUS_DESCRIPTORS[exerciseStatus].text,
          value: `${exerciseStatus}`,
        }}
        onChange={(data: SelectValue) => {
          if (!data?.value) return
          const newValue = Number(data.value)
          if (newValue === ExerciseStatuses.Schedule) {
            setIsScheduleModalOpen(true)
            return
          }
          onExerciseStatusSelect(newValue)
        }}
        multiple={false}
        isActive={false}
      >
        {Object.entries(STATUS_DESCRIPTORS).map(([key, statusDescriptor]) => (
          <Listbox.Option value={{ name: statusDescriptor.text, value: key }} key={key}>
            {statusDescriptor.text}
          </Listbox.Option>
        ))}
      </Listbox>

      {isScheduleModalOpen && (
        <UIModal
          open={isScheduleModalOpen}
          onClose={() => setIsScheduleModalOpen(false)}
          wrapperClassName={styles.ScheduleWrapper}
        >
          <ScheduleModal
            handleScheduleClose={() => setIsScheduleModalOpen(false)}
            publishDate={settings.scheduleDate}
            onPublishDateChange={(date) =>
              setExerciseSetting({ key: EXERCISE_SETTINGS.SCHEDULE_DATE, newValue: date })
            }
            publishTime={settings.scheduledTime}
            onPublishTimeChange={(time) =>
              setExerciseSetting({ key: EXERCISE_SETTINGS.SCHEDULED_TIME, newValue: time })
            }
            archiveDate={settings.scheduleArchiveDate}
            onArchiveDateChange={(date) =>
              setExerciseSetting({ key: EXERCISE_SETTINGS.SCHEDULE_ARCHIVE_DATE, newValue: date })
            }
            archiveTime={settings.scheduledArchiveTime}
            onArchiveTimeChange={(time) =>
              setExerciseSetting({ key: EXERCISE_SETTINGS.SCHEDULED_ARCHIVE_TIME, newValue: time })
            }
            handleExerciseStatus={onExerciseStatusSelect}
          />
        </UIModal>
      )}
    </>
  )
}

export default ExerciseStatusOption
