import React from 'react'
import { isEmpty } from 'lodash'
import styles from '../../../../screens/Students/Students.module.scss'
import StudentsButtonBlock from '../../../buttonsBlocks/StudentsButtonBlock/StudentsButtonBlock'
import StudentsTableUIHeader from '../StudentsTableRow/StudentsTableUIHeader/StudentsTableUIHeader'
import { IModalActions } from '../../../../redux/modules/modal.module'
import { IMe } from '../../../../api/types.users'
import { RouteComponentProps } from 'react-router'

import { ILocalization } from '../../../../config/languages.config'
import { IClass } from '../../../../api/types.classes'
import { IGroup } from '../../../../api/api.groups'

interface IStudentsTableHeaderProps {
  classOrGroup: IClass | IGroup
  modalActions: IModalActions
  me: IMe
  history: RouteComponentProps['history']
  localization: ILocalization
}

const StudentsTableHeader: React.FC<IStudentsTableHeaderProps> = ({
  classOrGroup,
  modalActions,
  me,
  history,
  localization,
}) => {
  if (isEmpty(classOrGroup.students)) return null
  return (
    <>
      <div className={styles.ButtonsWrapper}>
        <StudentsButtonBlock
          classOrGroup={classOrGroup as IClass}
          modalActions={modalActions}
          me={me}
          localization={localization}
          history={history}
        />
      </div>
      <StudentsTableUIHeader
        classOrGroup={classOrGroup}
        localization={localization}
        municipality={me.municipality}
      />
    </>
  )
}

export default StudentsTableHeader
