import React from 'react'
import { SvgIconProps } from '../types/types'

const SearchIcon = ({ size = 21, color, ...props }: SvgIconProps) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.03872 0.850952C4.151 0.850952 0.188721 4.81323 0.188721 9.70095C0.188721 14.5887 4.151 18.551 9.03872 18.551C11.0961 18.551 12.9895 17.8489 14.4924 16.6714C14.523 16.7172 14.5585 16.7606 14.5989 16.801L18.5695 20.7717C18.9015 21.1036 19.4397 21.1036 19.7716 20.7717C20.1036 20.4398 20.1036 19.9016 19.7716 19.5696L15.801 15.599C15.7715 15.5695 15.7405 15.5427 15.7081 15.5185C17.0661 13.963 17.8887 11.928 17.8887 9.70095C17.8887 4.81323 13.9264 0.850952 9.03872 0.850952ZM1.88872 9.70095C1.88872 5.75212 5.08988 2.55095 9.03872 2.55095C12.9876 2.55095 16.1887 5.75212 16.1887 9.70095C16.1887 13.6498 12.9876 16.851 9.03872 16.851C5.08988 16.851 1.88872 13.6498 1.88872 9.70095Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.92222 4.95505C8.85469 4.56318 9.11761 4.19076 9.50948 4.12323C9.98029 4.04209 11.2129 3.84255 12.6682 4.87003C13.3772 5.37053 13.8077 5.91607 14.0745 6.4769C14.3285 7.01059 14.4178 7.52911 14.4852 7.92082C14.4866 7.92904 14.488 7.9372 14.4894 7.9453C14.5569 8.33717 14.294 8.70959 13.9021 8.77712C13.5103 8.84466 13.1379 8.58173 13.0703 8.18986C12.9998 7.78077 12.9372 7.43808 12.7742 7.09562C12.6189 6.76912 12.3552 6.41174 11.8377 6.04641C10.8543 5.35209 10.0932 5.48367 9.76765 5.53996C9.76303 5.54076 9.75849 5.54154 9.75404 5.54231C9.36217 5.60984 8.98975 5.34692 8.92222 4.95505Z'
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
