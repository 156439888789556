import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import StudentProgressTable from 'components/StudentTableComponent/StudentTable.component'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { TypesOfStudentCell } from 'helpers/enums'
import { IMatch } from 'interfaces/match.inteface'
import { IState } from 'interfaces/state.interface'
import { IMe } from 'api/types.users'
import _ from 'lodash'
import React from 'react'
import { IModalActions } from 'redux/modules/modal.module'
import { IStudentProgressActions } from 'redux/modules/student-report.module'
import { IClassesStudentsActions } from 'redux/modules/students.module'
import './StudentProgress.view.scss'
import { ILocalization } from '../../config/languages.config'
import Spinner from '../../features/Spinner/Spinner'
import { IClassesStudents } from '../../api/types.classesStudents'
import { GetStudentProgressResponse } from '../../api/types.statistics'

export interface IStudentProgressProps {
  localization: ILocalization
  history: any
  location: Location
  match: IMatch
  me: IMe
  studentProgress: IState<GetStudentProgressResponse>
  studentProgressActions: IStudentProgressActions
  modalActions: IModalActions
  classesStudents: IState<IClassesStudents>
  classesStudentsActions: IClassesStudentsActions
}

export interface IStudentProgressState {
  headerProperties: Array<{ order: TypesOfStudentCell; name: string }>
}

export default class StudentProgress extends React.Component<IStudentProgressProps, any, any> {
  constructor(props: IStudentProgressProps) {
    super(props)
    this.state = {
      headerProperties: [],
    }
  }

  public UNSAFE_componentWillMount() {
    this.props.classesStudentsActions.getStudents()
  }

  public componentDidMount() {
    const { id } = this.props.match.params
    this.setArrayWithTableHeaderTitles()
    this.getStudentProgress(id)
  }

  public componentWillUnmount() {
    this.props.studentProgressActions.clearStudProgress()
  }

  public setArrayWithTableHeaderTitles = (): void => {
    const { localization } = this.props
    this.setState({
      headerProperties: [
        { order: TypesOfStudentCell.TypeCell, name: localization.data.typeTxt },
        { order: TypesOfStudentCell.AnsweredCell, name: localization.data.answeredTxt },
        {
          order: TypesOfStudentCell.FirstAttemptCell,
          name: localization.data.rightAnswerFirstAttempt,
        },
        {
          order: TypesOfStudentCell.AfterFirstAttemptCell,
          name: localization.data.rightAnswerAnyAttempts,
        },
        { order: TypesOfStudentCell.WrongCell, name: localization.data.incorrectTxt },
        {
          order: TypesOfStudentCell.NotAnsweredCell,
          name: localization.data.notAnsweredInProgressPage,
        },
        { order: TypesOfStudentCell.DateCell, name: localization.data.createdAt },
      ],
    })
  }

  public componentDidUpdate(prevProps: IStudentProgressProps, prevState: IStudentProgressState) {
    if (this.props.localization !== prevProps.localization) {
      this.setArrayWithTableHeaderTitles()
    }
  }

  public getStudentProgress = async (id: string): Promise<void> => {
    const page = 1
    await this.props.studentProgressActions.clearStudProgress()
    await this.props.studentProgressActions.getStudentProgress(id, page)
  }

  public render() {
    const { studentProgress, localization, studentProgressActions } = this.props
    const { studentsTxt, youHaveNoStudentProgressStats } = localization.data
    const studentDataIsNotEmpty: boolean =
      studentProgress &&
      !_.isEmpty(studentProgress.data) &&
      !_.isEmpty(studentProgress.data.progress.studentStats)
    const studentDataIsEmpty: boolean =
      studentProgress &&
      !studentProgress.loading &&
      !_.isEmpty(studentProgress.data) &&
      _.isEmpty(studentProgress.data.progress.studentStats)
    const studentName: string =
      studentProgress && !_.isEmpty(studentProgress.data)
        ? studentProgress.data.progress.studentData.firstName +
          ' ' +
          studentProgress.data.progress.studentData.lastName
        : ''
    return (
      <>
        <PanelHeader
          backButton={true}
          backButtonTitle={studentsTxt}
          title=' '
          className='exercises-header title-with-menu-wrapper'
          localization={localization}
          history={this.props.history}
          location={this.props.location}
          classesStudents={this.props.classesStudents}
        />
        <div className='heatmap-common-wrapper'>
          <ViewContainer history={this.props.history} className='student-progress-view'>
            <div className='student-progress-container-wrapper'>
              {studentDataIsNotEmpty && (
                <StudentProgressTable
                  tableHeaderProps={this.state.headerProperties}
                  localization={localization}
                  studentProgress={studentProgress}
                  studentProgressActions={studentProgressActions}
                  studentId={this.props.match.params.id}
                />
              )}
              {studentProgress.loading ? (
                <div className='loader-wrapper'>
                  <Spinner className='loader' />
                </div>
              ) : (
                studentDataIsEmpty && (
                  <div className='message-no-stats-wrapper'>
                    <div className='message-no-stats'>{youHaveNoStudentProgressStats}</div>
                  </div>
                )
              )}
            </div>
          </ViewContainer>
        </div>
      </>
    )
  }
}
