import * as React from 'react'

import { PATHS } from '../../config/pathnames.config'

import Practice from '../../screens/Practice/Practice'

export const PracticeRoutes = [
  {
    exact: true,
    path: PATHS.PRACTICE.PRACTICE_MAIN,
    render: (props: any) => {
      const practiceProps: any = {
        localization: props.localization,
        localizationActions: props.localizationActions,
        practice: props.practice,
        practiceActions: props.practiceActions,
        classes: props.classes,
        groups: props.groups,
        classesActions: props.classesActions,
        groupsActions: props.groupsActions,
      }

      return <Practice {...practiceProps} />
    },
  },
]
