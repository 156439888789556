const HOURS_COUNT_US = 12
const START_HOUR = 7
const HOURS_COUNT_SW = 24
const MINUTES_COUNT = 12
const MINUTES_MULTIPLIER = 5

const getTimeArray = (arrayLength: number, getTimeGenerator: (i: number) => number) =>
  Array.from({ length: arrayLength }, (_, i) => {
    const timeValue = getTimeGenerator(i)
    return {
      name: String(timeValue).padStart(2, '0'),
      value: timeValue.toString(),
    }
  })

export const SW_HOURS = getTimeArray(HOURS_COUNT_SW, (i) => (i + START_HOUR) % HOURS_COUNT_SW)
export const US_HOURS = getTimeArray(HOURS_COUNT_US, (i) => ++i)
export const MINUTES = getTimeArray(MINUTES_COUNT, (i) => i * MINUTES_MULTIPLIER)
export const MERIDIANS = [
  { name: 'AM', value: '0' },
  { name: 'PM', value: '1' },
]
