import { createEffect, createStore, restore } from 'effector'

import {
  fetchDrawningHistoryFx,
  fetchExampleProblemsFx,
  fetchLastStudentSolutionFx,
  fetchPracticeTheoryFx,
  fetchStudentsResultsFx,
} from './requests'
import { GetStudentsResultsResponse } from 'api/types.skillsStatistics'
import { IPracticeAnswerResult, IPracticeProblem } from 'api/types.practice'
import { PracticeTheory } from './types'

export class practiceResultsModel {
  public static loadTheory = createEffect(async (theoryId: string) => {
    const practiceTheory = await fetchPracticeTheoryFx(theoryId)

    if (practiceTheory.historyId) {
      await fetchDrawningHistoryFx(practiceTheory.historyId)
    }
  })

  public static $studentsResults = restore<GetStudentsResultsResponse>(
    fetchStudentsResultsFx.doneData,
    {}
  )

  public static $lastStudentSolution = restore<IPracticeAnswerResult[]>(
    fetchLastStudentSolutionFx.doneData,
    []
  )

  public static $exampleProblems = restore<IPracticeProblem[]>(fetchExampleProblemsFx.doneData, [])

  public static $theory = createStore<PracticeTheory>({
    theory: null,
    drawningHistory: '',
  })
    .on(fetchPracticeTheoryFx.doneData, (state, theory) => ({
      ...state,
      theory,
    }))
    .on(fetchDrawningHistoryFx.doneData, (state, history) => ({
      ...state,
      drawningHistory: history.history,
    }))

  public static isFetchingResults = fetchStudentsResultsFx.pending
  public static isFetchingLastSolution = fetchLastStudentSolutionFx.pending
  public static isFetchingTheory = this.loadTheory.pending
  public static isFetchingExampleProblems = fetchExampleProblemsFx.pending
}
