import { setCompressionMode } from 'features/tables/HeatmapTable/model/heatmapSettings'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import { PATHS } from '../../config/pathnames.config'
import { IAppProps } from '../App/App.container'
import { ExercisesRoutes } from './Exercises.routes'

export const ExercisesContainer = (props: IAppProps) => {
  const location = useLocation()

  useEffect(() => {
    const shouldDecompressHeatmap = !location.pathname.includes(
      `${PATHS.EXERCISES.EXERCISES_MAIN}/`
    )
    if (shouldDecompressHeatmap) {
      setCompressionMode(false)
    }
  }, [location.pathname])

  return (
    <Switch>
      {ExercisesRoutes.map((route: any, i: number) => (
        <Route
          key={i}
          {...route}
          render={(routeProps: any) => route.render({ ...props, ...routeProps })}
        />
      ))}
      <Redirect to={PATHS.EXERCISES.EXERCISES_MAIN} />
    </Switch>
  )
}
