import { createEvent, createStore } from 'effector'
import { fetchAllBooksFx } from './requests'
import { IBook } from '../../../api/types.books'

export const setBooks = createEvent<IBook[]>()

export const $allBooks = createStore<IBook[] | null>(null)
  .on(setBooks, (_, books) => books)
  .on(fetchAllBooksFx.doneData, (_, books) => books)

export const $allBooksLoading = fetchAllBooksFx.pending
