import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { api } from '../../api'

export enum PRACTICE_THEORY {
  THEORY_REQUEST = 'THEORY_REQUEST',
  THEORY_SUCCESS = 'THEORY_SUCCESS',
  THEORY_ERROR = 'THEORY_ERROR',
}

const initialState = {
  theory: null,
  drawingHistory: null,
  loading: false,
}

export function practiceTheoryReducer(state = initialState, action: IAction<PRACTICE_THEORY>) {
  switch (action.type) {
    case PRACTICE_THEORY.THEORY_REQUEST:
      return {
        theory: null,
        drawingHistory: null,
        loading: true,
      }
    case PRACTICE_THEORY.THEORY_SUCCESS:
      const { theory, drawingHistory } = action.payload
      return {
        theory,
        drawingHistory,
        loading: false,
      }
    case PRACTICE_THEORY.THEORY_ERROR:
      return {
        theory: null,
        drawingHistory: null,
        loading: false,
      }
    default:
      return state
  }
}

export const getPracticeTheoryData = (abilityId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PRACTICE_THEORY.THEORY_REQUEST })
      const theoryResponse = await api.oldPractice.getTheory(abilityId)
      dispatch({ type: PRACTICE_THEORY.THEORY_REQUEST })
      const drawingHistoryResponse = await api.oldPractice.getDrawingHistory(theoryResponse?.historyId)
      dispatch({
        type: PRACTICE_THEORY.THEORY_SUCCESS,
        payload: { drawingHistory: drawingHistoryResponse, theory: theoryResponse },
      })
    } catch (error) {
      dispatch({ type: PRACTICE_THEORY.THEORY_ERROR })
    }
  }
}

export interface IPracticeTheoryActions {
  getPracticeTheoryData: (abilityId: string) => void
}
