import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/session'
import { SyntheticEvent } from 'react'

export enum HeatmapSortOptions {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MOST_ANSWERS = 'MOST_ANSWERS',
  MOST_CORRECT_ANSWERS = 'MOST_CORRECT_ANSWERS',
  PINNED_SOLUTIONS = 'PINNED_SOLUTIONS',
  RANDOM = 'RANDOM',
}

export const DEFAULT_SORT_OPTION = HeatmapSortOptions.MOST_ANSWERS

export type HeatmapSettings = {
  isAnonymousNameMode: boolean
  isAnonymousCorrectnessMode: boolean
  isCompressed: boolean
  sortOption: HeatmapSortOptions
}

const defaultSettings: HeatmapSettings = {
  isAnonymousNameMode: false,
  isAnonymousCorrectnessMode: false,
  isCompressed: false,
  sortOption: DEFAULT_SORT_OPTION,
}

const $heatmapSettings = createStore<HeatmapSettings>(defaultSettings)
const $sortOption = $heatmapSettings.map((settings) => settings.sortOption)

const toggleAnonymousNameMode = createEvent<SyntheticEvent | void>()
const setCompressionMode = createEvent<boolean | ((state: boolean) => boolean)>()
const toggleAnonymousCorrectnessMode = createEvent<SyntheticEvent | void>()
const setSortOption = createEvent<HeatmapSortOptions>()

$heatmapSettings
  .on(toggleAnonymousNameMode, (state) => ({
    ...state,
    isAnonymousNameMode: !state.isAnonymousNameMode,
  }))
  .on(toggleAnonymousCorrectnessMode, (state) => ({
    ...state,
    isAnonymousCorrectnessMode: !state.isAnonymousCorrectnessMode,
  }))
  .on(setCompressionMode, (state, payload) => {
    if (typeof payload === 'function') {
      const newState = payload(state.isCompressed)
      if (newState === state.isCompressed) return
      return {
        ...state,
        isCompressed: newState,
      }
    }

    return {
      ...state,
      isCompressed: payload,
    }
  })
  .on(setSortOption, (state, payload) => ({
    ...state,
    sortOption: payload,
  }))

persist({ store: $heatmapSettings, key: sessionStorageKeys.cafHeatmapSettings })

export {
  $heatmapSettings,
  $sortOption,
  setSortOption,
  setCompressionMode,
  toggleAnonymousCorrectnessMode,
  toggleAnonymousNameMode,
}
