import { COLORS } from '@magmamath/ui'
import { IProblem } from 'api/types.problem'
import { DragControls, Reorder, useMotionValue } from 'framer-motion'
import { RecorderScrollDragEvent } from 'lib/hooks/useReorderScroll'
import React, { useEffect, useRef } from 'react'
import DragAnchor from 'ui/icons/DragAnchor/DragAnchor'
import ProblemItem from './ProblemItem'
import styles from './ProblemItem.module.scss'

type DraggableProblemItemProps = {
  problem: IProblem
  order: number
  problemsCount: number
  dragControls: DragControls
  onDragStart: RecorderScrollDragEvent
  onDrag: RecorderScrollDragEvent
  onDragEnd: RecorderScrollDragEvent
}

const DraggableProblemItem = ({
  problem,
  order,
  problemsCount,
  dragControls,
  onDragStart,
  onDrag,
  onDragEnd,
}: DraggableProblemItemProps) => {
  const y = useMotionValue(0)
  const cardRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!cardRef.current) return
    // Disable dragging images separately from the card
    const imgElements = cardRef.current.querySelectorAll('img')
    imgElements.forEach((img: HTMLImageElement) => img.setAttribute('draggable', 'false'))
  }, [cardRef.current])

  return (
    <Reorder.Item
      value={problem}
      id={problem._id}
      style={{ y }}
      className={styles.DraggableProblemItem}
      dragControls={dragControls}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    >
      <div className={styles.DragAnchor} onPointerDown={(event) => dragControls.start(event)}>
        <DragAnchor size={20} color={COLORS.NEUTRAL_6} />
      </div>
      <ProblemItem problem={problem} ref={cardRef} order={order} problemsCount={problemsCount} />
    </Reorder.Item>
  )
}

export default DraggableProblemItem
