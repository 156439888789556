import { PATHS } from 'config/pathnames.config'
import { CurrentLanguage, LocaleData, ProblemType } from 'helpers/enums'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router'
import { ILocalization } from '../../config/languages.config'
import { localStorageKeys } from '../../config/localStorageKeys'
import { getItemFromLocalStorage } from '../../helpers/storage.helper'
import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { closeSimpleConfirmModal } from './modal.module'
import { openSubchapter } from './problemsSidebarLinks.module'
import { editSection } from './section.module'
import { IProblem } from '../../api/types.problem'
import { api } from '../../api'
import { ContentDetails, UpdateSingleContentPayload } from '../../api/types.contents'

// Problem constants
export enum PROBLEM {
  GET_PROBLEM_REQUEST = 'GET_PROBLEM_REQUEST',
  GET_PROBLEM_SUCCESS = 'GET_PROBLEM_SUCCESS',
  GET_PROBLEM_ERROR = 'GET_PROBLEM_ERROR',
  PROBLEM_ADD_REQUEST = 'PROBLEM_ADD_REQUEST',
  PROBLEM_ADD_SUCCESS = 'PROBLEM_ADD_SUCCESS',
  PROBLEM_ADD_ERROR = 'PROBLEM_ADD_ERROR',
  PROBLEM_DELETE_REQUEST = 'PROBLEM_DELETE_REQUEST',
  PROBLEM_DELETE_SUCCESS = 'PROBLEM_DELETE_SUCCESS',
  PROBLEM_DELETE_ERROR = 'PROBLEM_DELETE_ERROR',
  PROBLEM_EDIT_REQUEST = 'PROBLEM_EDIT_REQUEST',
  PROBLEM_EDIT_SUCCESS = 'PROBLEM_EDIT_SUCCESS',
  PROBLEM_EDIT_ERROR = 'PROBLEM_EDIT_ERROR',
  CLEAR_STORE = 'CLEAR_STORE',
}

// Problem reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

type ProblemDetails = {
  order: number
  level: number
} & ContentDetails

type EditProblemData = UpdateSingleContentPayload['data'] & {
  level?: number
}

export function problemReducer(state: any = initialState, action: IAction<PROBLEM>) {
  switch (action.type) {
    case PROBLEM.PROBLEM_ADD_REQUEST:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case PROBLEM.PROBLEM_ADD_SUCCESS:
      return {
        data: { ...state.data },
        error: action.payload,
        loading: true,
      }
    case PROBLEM.PROBLEM_ADD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case PROBLEM.GET_PROBLEM_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PROBLEM.GET_PROBLEM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case PROBLEM.GET_PROBLEM_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PROBLEM.PROBLEM_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PROBLEM.PROBLEM_DELETE_SUCCESS:
      return {
        data: { ...state.data },
        error: null,
        loading: false,
      }
    case PROBLEM.PROBLEM_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PROBLEM.PROBLEM_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PROBLEM.PROBLEM_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case PROBLEM.PROBLEM_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PROBLEM.CLEAR_STORE:
      return {
        initialState,
      }
    default:
      return state
  }
}

export function addProblem(
  data: ProblemDetails,
  section: { _id: string; problems: IProblem[] },
  language: string,
  history: RouteComponentProps['history']
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PROBLEM.PROBLEM_ADD_REQUEST })
      const { order, level, ...contentDetails } = data
      const contentRes = await api.contents.createNew(contentDetails)
      const res = await api.problems.createNew({
        contentId: contentRes.id,
        order,
        level,
      })
      dispatch({ type: PROBLEM.PROBLEM_ADD_SUCCESS, payload: res })

      const stringFromProblems: string = section.problems
        .map((problem: IProblem | string) => {
          if (_.isString(problem)) {
            return problem
          }
          return problem._id
        })
        .concat(res.problem._id)
        .join(',')

      await editSection({ problems: stringFromProblems }, section._id, history)(dispatch)
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (!!error.response) {
            dispatch({
              payload: !!error.response ? error.response.data.message : error.message,
              type: PROBLEM.PROBLEM_ADD_ERROR,
            })
          }
        },
        () => handleNetworkError(PROBLEM.PROBLEM_ADD_ERROR, error, dispatch)
      )
    }
  }
}

export function getProblem(id: string) {
  const linkMap: Record<CurrentLanguage, LocaleData> = {
    [CurrentLanguage.SE]: LocaleData.SE,
    [CurrentLanguage.US]: LocaleData.US,
    [CurrentLanguage.GB]: LocaleData.US,
  }
  const savedLang = getItemFromLocalStorage(localStorageKeys.language)

  const params: Record<string, string> = {
    locale: linkMap[savedLang as string],
  }

  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PROBLEM.GET_PROBLEM_REQUEST })
      const res = await api.problems.getSingle({ id, params })
      dispatch({ type: PROBLEM.GET_PROBLEM_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: PROBLEM.GET_PROBLEM_ERROR,
      })
    }
  }
}

type EditProblemParams = {
  data: EditProblemData
  id: string
  contentId: string
  section: { _id: string; problems: IProblem[]; name: string }
  history: any
}

export function editProblem({ data, id, contentId, section, history }: EditProblemParams) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PROBLEM.PROBLEM_EDIT_REQUEST })
      const { level, ...dataContent } = data
      const content = await api.contents.updateSingle({
        id: contentId,
        data: {
          ...dataContent,
          answerVariants: dataContent.answerVariants || [],
        },
      })
      const res = await api.problems.updateSingle({
        id,
        data: { contentId: content.id, level },
      })
      dispatch({ type: PROBLEM.PROBLEM_EDIT_SUCCESS, payload: res })
      openSubchapter(section._id, section.name, history)(dispatch)
      history.push(PATHS.CONTENT.PROBLEMS)
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (!!error.response === true) {
            dispatch({
              payload: !!error.response ? error.response.data.message : error.message,
              type: PROBLEM.PROBLEM_EDIT_ERROR,
            })
          }
        },
        () => handleNetworkError(PROBLEM.PROBLEM_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export function deleteProblem(
  id: string,
  section: { _id: string; problems: IProblem[] },
  history: any
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PROBLEM.PROBLEM_DELETE_REQUEST })
      const res = await api.problems.deleteSingle(id)
      dispatch({ type: PROBLEM.PROBLEM_DELETE_SUCCESS, payload: res })
      closeSimpleConfirmModal()(dispatch)
      const stringFromProblems: string = section.problems
        .map((problem: IProblem | string) => {
          if (_.isString(problem)) {
            return problem
          }
          return problem._id
        })
        .concat(id)
        .join(',')
      await editSection({ problems: stringFromProblems }, section._id, history)(dispatch)
    } catch (error) {
      closeSimpleConfirmModal()(dispatch)
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: PROBLEM.PROBLEM_DELETE_ERROR,
      })
    }
  }
}

export const clearProblemStore = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: PROBLEM.CLEAR_STORE })
  }
}

export interface IProblemActions {
  addProblem: (
    data: {
      name: string
      answerType: number
      answerVariants: string[] | number[]
      answer: string[]
      order: number
      level: number
      description: string
      richDescription: string
      isInitial: boolean
      problemType: ProblemType.standard
      subProblems: any
      image?: string
      characterType: number
    },
    section: { _id: string; problems: IProblem[] },
    language: string,
    history: any,
    localization: ILocalization
  ) => void
  editProblem: (data: EditProblemParams) => void
  getProblem: (id: string) => void
  deleteProblem: (
    id: string,
    section: { _id: string; problems: IProblem[] },
    history: any,
    localization: ILocalization
  ) => void
}
