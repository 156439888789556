import React from 'react'
import { SvgIconProps } from '../types/types'

const WhiteboardIcon: React.FC<SvgIconProps> = ({ size = 16, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.2327 0.824642C13.0762 -0.0188585 14.4438 -0.0188587 15.2873 0.824642C16.1308 1.66814 16.1308 3.03573 15.2873 3.87923L12.1738 6.99267C11.9354 7.23116 11.6338 7.39669 11.3046 7.46986L8.86312 8.0124C8.40584 8.11402 7.9979 7.70609 8.09952 7.24881L8.64207 4.80736C8.71523 4.47812 8.88076 4.17657 9.11925 3.93809L12.2327 0.824642ZM14.4727 1.63923C14.0791 1.24561 13.4409 1.24561 13.0473 1.63923L9.93384 4.75267C9.85027 4.83624 9.79227 4.9419 9.76663 5.05727L9.39863 6.7133L11.0547 6.34529C11.17 6.31965 11.2757 6.26165 11.3593 6.17809L14.4727 3.06464C14.8663 2.67103 14.8663 2.03285 14.4727 1.63923Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.816 1.13593L9.664 2.28793H2.55997C1.8177 2.28793 1.21597 2.88966 1.21597 3.63193V5.29443C1.65995 4.95963 2.16365 4.61826 2.66623 4.34604C3.12674 4.09662 3.62366 3.88447 4.09433 3.80679C4.56377 3.7293 5.11489 3.77226 5.52727 4.18464C6.03323 4.6906 6.07171 5.30308 5.93297 5.84483C5.80417 6.34774 5.50832 6.85773 5.25587 7.28496L5.19681 7.38478C4.94879 7.80373 4.7408 8.15508 4.62801 8.47398C4.51039 8.80656 4.54989 8.95182 4.62077 9.04633C4.70928 9.16434 4.79521 9.2116 4.87504 9.23312C4.96481 9.25732 5.08832 9.25913 5.25269 9.21827C5.5933 9.13361 5.98498 8.89704 6.32047 8.62472C6.56746 8.42423 6.93021 8.46193 7.1307 8.70892C7.33118 8.95591 7.29348 9.31866 7.04649 9.51914C6.65417 9.83759 6.10789 10.1927 5.53059 10.3362C5.236 10.4095 4.90558 10.4345 4.5752 10.3454C4.23489 10.2537 3.93445 10.0512 3.69917 9.73753C3.29005 9.19204 3.36955 8.5773 3.54193 8.08988C3.69465 7.65806 3.96194 7.20792 4.19399 6.81713L4.26408 6.6989C4.53163 6.24613 4.73578 5.87612 4.81698 5.55903C4.88824 5.28079 4.84672 5.13326 4.71268 4.99923C4.67251 4.95906 4.57069 4.89574 4.28194 4.94341C3.99442 4.99086 3.62791 5.13529 3.21489 5.359C2.48977 5.75175 1.73602 6.32513 1.21597 6.77243V11.3119C1.21597 12.0542 1.8177 12.6559 2.55997 12.6559H13.44C14.1823 12.6559 14.784 12.0542 14.784 11.3119V5.48796L15.936 4.33596V11.3119C15.936 12.6904 14.8185 13.8079 13.44 13.8079H2.55997C1.18146 13.8079 0.0639648 12.6904 0.0639648 11.3119V3.63193C0.0639648 2.25343 1.18147 1.13593 2.55997 1.13593H10.816Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default WhiteboardIcon
