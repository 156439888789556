import { createEvent, createStore } from 'effector'
import { ErrorMessagesFields, ErrorMessagesValues } from '../types'

const DEFAULT_STATE = {
  isSubmitted: false,
  messages: {
    [ErrorMessagesFields.NAME]: null,
    [ErrorMessagesFields.SELECT_PROBLEMS]: null,
    [ErrorMessagesFields.PARTICIPANTS]: null,
  },
} as const

const updateErrorsIsSubmitted = createEvent<boolean>()
const updateErrorMessages = createEvent<Partial<ErrorMessagesValues['messages']>>()
const resetErrorMessages = createEvent()

const $errorMessages = createStore<ErrorMessagesValues>(DEFAULT_STATE)
  .on(updateErrorsIsSubmitted, (state, isSubmitted) => ({
    ...state,
    isSubmitted,
  }))
  .on(updateErrorMessages, (state, newErrorMessagesValues) => ({
    ...state,
    messages: {
      ...state.messages,
      ...newErrorMessagesValues,
    },
  }))
  .reset(resetErrorMessages)

export { $errorMessages, resetErrorMessages, updateErrorMessages, updateErrorsIsSubmitted }
