import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'

const initialState = {
  data: [],
  error: null,
  loading: false,
}

export enum SOLUTIONS_ACTION_TYPE {
  PIN_SOLUTION = 'PIN_SOLUTION',
  UNFIX_SOLUTION = 'UNFIX_SOLUTION',
  CLEAR_ALL = 'CLEAR_ALL',
}

export const pinnedSolutionsReducer = (
  state: any = initialState,
  action: IAction<SOLUTIONS_ACTION_TYPE>
) => {
  switch (action.type) {
    case SOLUTIONS_ACTION_TYPE.PIN_SOLUTION:
      return {
        data: [...state.data, { ...action.payload }],
        error: null,
        loading: true,
      }
    case SOLUTIONS_ACTION_TYPE.UNFIX_SOLUTION:
      return {
        data: [
          ...state.data.filter(
            (solution: IPinnedSolution) =>
              solution.exerciseId !== action.payload.exerciseId &&
              solution.studentId !== action.payload.studentId &&
              solution.problemId !== action.payload.problemId
          ),
        ],
        error: null,
        loading: false,
      }
    case SOLUTIONS_ACTION_TYPE.CLEAR_ALL:
      return {
        data: [],
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const pinSolution = (exerciseId: string, studentId: string, problemId: string) => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      type: SOLUTIONS_ACTION_TYPE.PIN_SOLUTION,
      payload: {
        exerciseId,
        studentId,
        problemId,
      },
    })
  }
}

export const unfixSolution = (exerciseId: string, studentId: string, problemId: string) => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      type: SOLUTIONS_ACTION_TYPE.UNFIX_SOLUTION,
      payload: {
        studentId,
      },
    })
  }
}

export const clearSolutions = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      type: SOLUTIONS_ACTION_TYPE.CLEAR_ALL,
    })
  }
}

export interface IPinnedSolutionsActions {
  pinSolution: (studentId: string, exerciseId: string, problemID: string) => void
  unfixSolution: (studentId: string, exerciseId: string, problemID: string) => void
  clearSolutions: () => void
}

export interface IPinnedSolution {
  exerciseId: string
  studentId: string
  problemId: string
}

export interface IPinnedSolutions {
  data: Array<IPinnedSolution>
}
