import React, { useLayoutEffect, useRef } from 'react'
import styles from './AnswerOption.module.scss'
import katex from 'katex'
import { removeDollarSign } from '../../printProblemsCard.helper'

type AnswerOptionProps = {
  answer: string
}

const AnswerOption = ({ answer }: AnswerOptionProps) => {
  const titleRef = useRef(null)
  useLayoutEffect(() => {
    if (!titleRef.current) return
    katex.render(removeDollarSign(answer), titleRef.current, {
      throwOnError: false,
      displayMode: true,
    })
  }, [answer])
  return (
    <div className={styles.Container}>
      <div className={styles.CheckBox} />
      <div ref={titleRef} className={styles.Title} />
    </div>
  )
}
export default AnswerOption
