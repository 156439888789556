import NumericAnswer from '../NumericAnswer/NumericAnswer'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

const NumericAnswerPreview = () => {
  const answerNumeric = useSelector((state: RootState) => state.problemCreatorData.answerNumeric)
  const selectedValue = useSelector((state: RootState) => state.problemCreatorData.selectedValue)
  return <NumericAnswer value={answerNumeric} selectedValue={selectedValue} />
}

export default NumericAnswerPreview
