import { apiManager } from './apiManager'
import { GetHelpQueueResponse, StudentInQueue } from './types.helpQueue'

export type GetHelpQueuePayload = {
  offset: number
  classroomId?: string
}

export class helpQueueApi {
  public static readonly getAll = apiManager.get<GetHelpQueueResponse, GetHelpQueuePayload>(
    'classroom-helpqueue'
  )
  public static readonly getSingle = apiManager.get<StudentInQueue, string>('classroom-helpqueue')
  public static readonly deleteSingle = apiManager.delete<void, string>('classroom-helpqueue')
}
