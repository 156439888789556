import React from 'react'
import styles from './ExerciseParticipants.module.scss'
import clsx from 'clsx'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import ExerciseParticipantsTooltip from './ExerciseParticipantsTooltip'
import { ExerciseParticipantsInfo } from './helpers'

type ExerciseParticipantsSingleNameProps = {
  name: string
  participantsData: ExerciseParticipantsInfo['participantsData']
  isParentTooltipVisible: boolean
}

const ExerciseParticipantsSingleName = ({
  name,
  participantsData,
  isParentTooltipVisible
}: ExerciseParticipantsSingleNameProps) => {
  const { overflowRef, isOverflowing } = useOverflowDetection()
  const isSingleRow = participantsData.isSingleType && !isOverflowing

  if (!name) return null

  return (
    <UITooltip
      placement='bottom-start'
      tooltipClasses={{
        popper: styles.Popper,
        tooltip: styles.Tooltip,
      }}
      title={
        isOverflowing && !isParentTooltipVisible ? <ExerciseParticipantsTooltip participantsData={participantsData} /> : null
      }
    >
      <div
        ref={overflowRef}
        key={name}
        className={clsx(styles.SingleName, {
          [styles.Ellipsis]: !participantsData.isSingleType,
          [styles.SingleRow]: isSingleRow,
        })}
      >
        {name}
      </div>
    </UITooltip>
  )
}

export default ExerciseParticipantsSingleName
