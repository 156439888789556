import React, { memo } from 'react'
import { Listbox } from '@magmamath/ui'
import { SelectValue } from '@magmamath/ui/dist/components/listbox/types/selectValue'
import styles from './EducationEntitySelect.module.scss'
import useText from 'i18n/hook'

export enum EducationEntityType {
  STUDENTS = 'students',
  GROUPS = 'groups',
}

type EducationEntitySelectProps = {
  groupItems: SelectValue[]
  studentItems: SelectValue[]
  onChangeSelect: (value: SelectValue) => void
  selectedValue: SelectValue
}

const EducationEntitySelect = ({
  groupItems,
  studentItems,
  onChangeSelect,
  selectedValue,
}: EducationEntitySelectProps) => {
  const t = useText()
  return (
    <div className={styles.StudentSelect}>
      <Listbox
        multiple={false}
        value={selectedValue}
        onChange={onChangeSelect}
        texts={{
          noOptions: t.studentsTxt,
        }}
      >
        {studentItems[0] && (
          <Listbox.Option key={studentItems[0].value} value={studentItems[0]}>
            {studentItems[0].name}
          </Listbox.Option>
        )}
        {groupItems.length > 0 && (
          <>
            <Listbox.Subheader>{t.groupsTxt}</Listbox.Subheader>
            {groupItems.map((group) => {
              if (!group) return null
              return (
                <Listbox.Option key={group.value} value={group}>
                  {group.name}
                </Listbox.Option>
              )
            })}
          </>
        )}
        <Listbox.Subheader>{t.studentsTxt}</Listbox.Subheader>
        {studentItems.map((student, index) => {
          if (index === 0 || !student) {
            return null
          }
          return (
            <Listbox.Option key={student.value} value={student}>
              {student.name}
            </Listbox.Option>
          )
        })}
      </Listbox>
    </div>
  )
}

export default memo(EducationEntitySelect)
