import { COMPONENT_COLOR, SectionButton } from '@magmamath/ui'
import { IExercise } from 'api/types.assignments'
import { useStoreMap, useUnit } from 'effector-react'
import React, { useEffect, useRef } from 'react'
import useScrollPosition from '../../../lib/hooks/useScrollPosition'
import { $exerciseSettings } from '../../caf/exerciseSettings/model/exerciseSettings'
import { scrollSectionToTop, scrollToOpenedBook } from '../Book/helpers'
import { $sectionPaths } from '../Book/model/sectionPaths'
import { $selectedProblems, removeSelectedProblems } from '../Book/model/selectedProblems'
import { SectionPath } from '../Book/model/types'
import { resetFoundBooks, resetSearchIsActive } from '../model/foundBooks'
import { openSection, resetOpenedBooks } from '../model/openedBooks'
import ReorderProblems from '../ReorderProblems/ReorderProblems'
import styles from './SelectedSectionsList.module.scss'

type SelectedSectionsListProps = { exercise?: IExercise }

const SelectedSectionsList = ({ exercise }: SelectedSectionsListProps) => {
  const selectedProblemsBySection = useUnit($selectedProblems)
  const sections = useUnit($sectionPaths)
  const isExamMode = useStoreMap($exerciseSettings, (state) => state.isExamMode)
  const selectedSectionsRef = useRef<HTMLUListElement | null>(null)
  const scrollPositionData = useScrollPosition(selectedSectionsRef)

  useEffect(() => {
    // Scroll to the right side of the list when a new section is added
    if (selectedSectionsRef.current) {
      scrollPositionData.scrollTo(selectedSectionsRef.current.scrollWidth, 0)
    }
  }, [selectedProblemsBySection])

  const handleOpenSection = (path: SectionPath) => {
    resetSearchIsActive()
    resetFoundBooks()
    resetOpenedBooks()
    openSection({
      bookId: path.bookId,
      chapter: { id: path.chapterId, name: path.chapterName },
      section: path,
    })

    scrollToOpenedBook(path.bookId)
    scrollSectionToTop(path.id)
  }

  return (
    <ul className={styles.List} ref={selectedSectionsRef}>
      {[...selectedProblemsBySection.keys()].map((selectedSectionId) => {
        const section = sections.get(selectedSectionId)
        const sectionProblems = selectedProblemsBySection.get(selectedSectionId)

        if (!section || !sectionProblems?.size) return null
        return (
          <li key={section.id}>
            <SectionButton
              text={`${section.name} (${sectionProblems.size || 0})`}
              onClick={() => {
                handleOpenSection(section)
              }}
              onClose={() => {
                removeSelectedProblems(section.id)
              }}
              color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
            />
          </li>
        )
      })}
      <ReorderProblems exercise={exercise} />
    </ul>
  )
}

export default SelectedSectionsList
