import { combine, createEffect } from 'effector'
import { getPinsObjectMemoryKey } from '../helpers'
import { setCurrentPinsFromMemory } from './pins'
import { DeletePinParams, PinsDataStore, ProblemOrSubProblemObject } from './types'
import { AddPinParams, FetchPinsParams } from '../../../api/types.pins'
import { api } from '../../../api'

export const fetchPinsDataFx = createEffect(async (params: FetchPinsParams) => {
  const response = await api.pins.getAll(params)
  return { params, pins: response }
})

export const addPinFx = createEffect(async (addPinParams: AddPinParams) => {
  const response = await api.pins.createNew({
    ...addPinParams,
    problemId: addPinParams.subProblemId ? undefined : addPinParams.problemId,
  })
  const newPinId = response?.pinId
  if (!newPinId) throw new Error('Pin was not created')

  return {
    params: addPinParams,
    newPinData: { _id: newPinId, solutionId: addPinParams.solutionId },
    deletedPinIds: response.deletedPinIds,
  }
})

export const deletePinFx = createEffect(async (params: DeletePinParams) => {
  await api.pins.deleteSingle(params.pinId)
  return { params, id: params.pinId }
})

export const $isFetchingPins = fetchPinsDataFx.pending

export const $isMutatingPins = combine(
  addPinFx.pending,
  deletePinFx.pending,
  (addPinPending, deletePinPending) => addPinPending || deletePinPending
)

export const fetchSinglePinsData = ({
  memoizedPins,
  exerciseId,
  problemOrSubProblemObject,
}: {
  memoizedPins: PinsDataStore['memoizedPins']
  exerciseId: string
  problemOrSubProblemObject: ProblemOrSubProblemObject
}) => {
  if (!problemOrSubProblemObject) return
  const { problemOrSubProblemId, isProblem, isCurrent } = problemOrSubProblemObject
  const params: FetchPinsParams = {
    exerciseId: exerciseId,
    problemId: isProblem ? problemOrSubProblemId : undefined,
    subProblemId: isProblem ? undefined : problemOrSubProblemId,
    isCurrent,
  }

  const memoryKey = getPinsObjectMemoryKey(exerciseId, problemOrSubProblemId)
  const memoizedPinsData = memoizedPins?.[memoryKey]
  if (!memoizedPinsData) {
    fetchPinsDataFx(params)
    return
  }
  if (isCurrent && memoizedPinsData) {
    setCurrentPinsFromMemory(params)
    return
  }
}
