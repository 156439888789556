export enum PROBLEM_LEVELS {
  EASY = 1,
  MEDIUM = 2,
  HARD = 3,
}

export enum NUMBER_OF_COLUMNS {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
