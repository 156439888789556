import { FormTypes, ModalType, RemovableTypes, ResultsView } from 'helpers/enums'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IBasicUser, IMe, IStudent } from 'api/types.users'
import { IExerciseActions } from './exercise.module'
import { ILocalization } from '../../config/languages.config'
import { IExercise } from '../../api/types.assignments'
import { IChapter, ISection } from '../../api/types.books'
import { IProblem } from '../../api/types.problem'
import { ClassroomGroup, IClass } from '../../api/types.classes'
import { IClassesStudents } from '../../api/types.classesStudents'
import { IGroup } from '../../api/api.groups'
import { ITeachers } from '../../api/types.teachers'
import { ISkill } from 'api/types.skills'

// Modal constants

export enum MODAL {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_ALL_PASSWORDS_MODAL = 'OPEN_ALL_PASSWORDS_MODAL',
  CLOSE_ALL_PASSWORDS_MODAL = 'CLOSE_ALL_PASSWORDS_MODAL',
  OPEN_MODAL_REGISTER = 'OPEN_MODAL_REGISTER',
  CLOSE_MODAL_REGISTER = 'CLOSE_MODAL_REGISTER',
  OPEN_MODAL_PASSWORD = 'OPEN_MODAL_PASSWORD',
  CLOSE_MODAL_PASSWORD = 'CLOSE_MODAL_PASSWORD',
  OPEN_GROUP_STUDENTS_AUTOSUGGEST = 'OPEN_GROUP_STUDENTS_AUTOSUGGEST',
  CLOSE_GROUP_STUDENTS_AUTOSUGGEST = 'CLOSE_GROUP_STUDENTS_AUTOSUGGEST',
  OPEN_TEACHERS_AUTOSUGGEST = 'OPEN_TEACHERS_AUTOSUGGEST',
  CLOSE_TEACHERS_AUTOSUGGEST = 'CLOSE_TEACHERS_AUTOSUGGEST',
  CLOSE_OVERLAY = 'CLOSE_OVERLAY',
  OPEN_OVERLAY = 'OPEN_OVERLAY',
  CLOSE_MAP_OVERLAY = 'CLOSE_MAP_OVERLAY',
  OPEN_MAP_OVERLAY = 'OPEN_MAP_OVERLAY',
  CLOSE_STATISTICS_OVERLAY = 'CLOSE_STATISTICS_OVERLAY',
  OPEN_STATISTICS_OVERLAY = 'OPEN_STATISTICS_OVERLAY',
  CLOSE_SIMPLE_CONFIRM_MODAL = 'CLOSE_SIMPLE_CONFIRM_MODAL',
  OPEN_SIMPLE_CONFIRM_MODAL = 'OPEN_SIMPLE_CONFIRM_MODAL',
  OPEN_CLASSES_MODAL = 'OPEN_CLASSES_MODAL',
  CLOSE_CLASSES_MODAL = 'CLOSE_CLASSES_MODAL',
  CLOSE_SIMPLE_ASYNC_MODAL = 'CLOSE_SIMPLE_ASYNC_MODAL',
  OPEN_SIMPLE_ASYNC_MODAL = 'OPEN_SIMPLE_ASYNC_MODAL',
  CLOSE_SELECT_SCHOOLS_MODAL = 'CLOSE_SELECT_SCHOOLS_MODAL',
  OPEN_CREATE_OR_EDIT_EXERCISE_MODAL = 'OPEN_CREATE_OR_EDIT_EXERCISE_MODAL',
  CLOSE_CREATE_OR_EDIT_EXERCISE_MODAL = 'CREATE_OR_EDIT_EXERCISE_MODAL',
  OPEN_SHARE_LINK_MODAL = 'OPEN_SHARE_LINK_MODAL',
  CLOSE_SHARE_LINK_MODAL = 'CLOSE_SHARE_LINK_MODAL',
  OPEN_CREATE_CLASS_MODAL = 'OPEN_CREATE_CLASS_MODAL',
  CLOSE_CREATE_CLASS_MODAL = 'CLOSE_CREATE_CLASS_MODAL',
  OPEN_CHOOSE_STUDENTS_MODAL_UPDATED = 'OPEN_CHOOSE_STUDENTS_MODAL_UPDATED',
  CLOSE_CHOOSE_STUDENTS_MODAL_UPDATED = 'CLOSE_CHOOSE_STUDENTS_MODAL_UPDATED',
  OPEN_LANGUAGE_SETTINGS_MODAL = 'OPEN_LANGUAGE_SETTINGS_MODAL',
  CLOSE_LANGUAGE_SETTINGS_MODAL = 'CLOSE_LANGUAGE_SETTINGS_MODAL',
  OPEN_TRANSLATION_LANGUAGE_SELECTOR_MODAL = 'OPEN_TRANSLATION_LANGUAGE_SELECTOR_MODAL',
  CLOSE_TRANSLATION_LANGUAGE_SELECTOR_MODAL = 'CLOSE_TRANSLATION_LANGUAGE_SELECTOR_MODAL',
  OPEN_OLD_PRACTICE_RESULTS_MODAL = 'OPEN_OLD_PRACTICE_RESULTS_MODAL',
  CLOSE_OLD_PRACTICE_RESULTS_MODAL = 'CLOSE_OLD_PRACTICE_RESULTS_MODAL',
  OPEN_ARCHIVE_CLASS_MODAL = 'OPEN_ARCHIVE_CLASS_MODAL',
  CLOSE_ARCHIVE_CLASS_MODAL = 'CLOSE_ARCHIVE_CLASS_MODAL',
  OPEN_PRACTICE_RESULTS_MODAL = 'OPEN_PRACTICE_RESULTS_MODAL',
  CLOSE_PRACTICE_RESULTS_MODAL = 'CLOSE_PRACTICE_RESULTS_MODAL',
}

// Modal reducer
const initialState = {
  data: {
    actionElement: null,
    chapterId: null,
    exercise: null,
    settings: null,
    exerciseId: null,
    id: null,
    imageUrl: null,
    me: null,
    modalType: ModalType.Empty,
    redirecting: false,
    removedItem: null,
    section: null,
    selectedItem: null,
    sentClass: null,
    statuses: null,
    title: null,
    typeOfItem: null,
    updatedItem: null,
    dataForCreateOrEditExerciseModal: null,
    classId: null,
    abilityId: '',
    abilityName: '',
    selectedStudents: [],
    resultView: null,
    studentName: '',
  },
  error: null,
  loading: false,
}

export function modalReducer(state: any = initialState, action: IAction<MODAL>) {
  switch (action.type) {
    case MODAL.OPEN_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.SimpleModal,
          title: action.payload.title,
          actionElement: action.payload.actionElement,
          id: action.payload.id,
          me: action.payload.me,
          settings: action.payload.settings,
        },
      }
    case MODAL.CLOSE_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty, redirecting: true },
      }
    case MODAL.OPEN_SIMPLE_CONFIRM_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.SimpleConfirmModal,
          removedItem: action.payload.removedItem,
          updatedItem: action.payload.updatedItem,
          // tslint:disable-next-line: object-literal-sort-keys
          typeOfItem: action.payload.typeOfItem,
        },
      }
    case MODAL.CLOSE_SIMPLE_CONFIRM_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_CLASSES_MODAL:
      return {
        ...state,
        data: { ...state.data, id: action.payload.id, modalType: ModalType.ClassesModal },
      }
    case MODAL.CLOSE_CLASSES_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_ALL_PASSWORDS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.AllPasswordsModal,
          sentClass: action.payload.sentClass,
        },
      }
    case MODAL.CLOSE_ALL_PASSWORDS_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_OVERLAY:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.ExerciseOverlay,
          title: action.payload.title,
          id: action.payload.id,
        },
      }
    case MODAL.CLOSE_OVERLAY:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_MODAL_REGISTER:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.RegisterStudentModal, me: action.payload.me },
      }
    case MODAL.CLOSE_MODAL_REGISTER:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty, redirecting: true },
      }
    case MODAL.OPEN_MODAL_PASSWORD:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.ChangePasswordModal,
          me: action.payload.me,
          username: action.payload.username,
        },
      }
    case MODAL.CLOSE_MODAL_PASSWORD:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty, redirecting: true },
      }
    case MODAL.OPEN_GROUP_STUDENTS_AUTOSUGGEST:
      return {
        ...state,
        data: {
          ...state.data,
          groupId: action.payload.groupId,
          modalType: ModalType.GroupStudentsAutosuggestModal,
          students: action.payload.students,
        },
      }
    case MODAL.CLOSE_GROUP_STUDENTS_AUTOSUGGEST:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_TEACHERS_AUTOSUGGEST:
      return {
        ...state,
        data: {
          ...state.data,
          typeOfItem: action.payload.typeOfItem,

          id: action.payload.id,
          modalType: ModalType.TeachersAutosuggestModal,
          teachers: action.payload.teachers,
        },
      }
    case MODAL.CLOSE_TEACHERS_AUTOSUGGEST:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_SIMPLE_ASYNC_MODAL:
      return {
        ...state,

        data: {
          ...state.data,
          typeOfItem: action.payload.typeOfItem,

          modalType: ModalType.SimpleAsyncModal,
        },
      }
    case MODAL.CLOSE_SIMPLE_ASYNC_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.CLOSE_SELECT_SCHOOLS_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_CREATE_OR_EDIT_EXERCISE_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.CreateOrEditExercise,
          dataForCreateOrEditExerciseModal: action.payload.dataForCreateOrEditExerciseModal,
        },
      }
    case MODAL.CLOSE_CREATE_OR_EDIT_EXERCISE_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty },
      }
    case MODAL.OPEN_SHARE_LINK_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.ShareLinkModal,
        },
      }
    case MODAL.CLOSE_SHARE_LINK_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_LANGUAGE_SETTINGS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          settings: action.payload.settings,
          classId: action.payload.classId,
          modalType: ModalType.LanguageSettingsModal,
        },
      }
    case MODAL.CLOSE_LANGUAGE_SETTINGS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_TRANSLATION_LANGUAGE_SELECTOR_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.TranslationLanguageSelectorModal,
        },
      }
    case MODAL.CLOSE_TRANSLATION_LANGUAGE_SELECTOR_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_CREATE_CLASS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.CreateClassModal,
          title: action.payload.title,

          actionElement: action.payload.actionElement,
          id: action.payload.id,
          me: action.payload.me,
          settings: action.payload.settings,
        },
      }
    case MODAL.CLOSE_CREATE_CLASS_MODAL:
      return {
        ...state,
        data: { ...state.data, modalType: ModalType.Empty, redirecting: true },
      }
    case MODAL.OPEN_CHOOSE_STUDENTS_MODAL_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          classId: action.payload.classId,
          modalType: ModalType.ChooseStudentsModalUpdated,
        },
      }
    case MODAL.CLOSE_CHOOSE_STUDENTS_MODAL_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_ARCHIVE_CLASS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.ArchiveClassModal,
          selectedItem: action.payload.selectedItem,
        },
      }
    case MODAL.CLOSE_ARCHIVE_CLASS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_OLD_PRACTICE_RESULTS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          abilityId: action.payload.abilityId,
          abilityName: action.payload.abilityName,
          selectedStudents: action.payload.selectedStudents,
          resultView: action.payload.resultView,
          studentName: action.payload.studentName,
          modalType: ModalType.OldPracticeResultsModal,
        },
      }
    case MODAL.CLOSE_OLD_PRACTICE_RESULTS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    case MODAL.OPEN_PRACTICE_RESULTS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          skill: action.payload.skill,
          classId: action.payload.classId,
          modalType: ModalType.PracticeResultsModal,
        },
      }
    case MODAL.CLOSE_PRACTICE_RESULTS_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          modalType: ModalType.Empty,
        },
      }
    default:
      return state
  }
}

// Modal actions
export function openModal(
  title: string,
  actionElement: string,
  me: any,
  id?: string | null,
  settings?: any
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { actionElement, title, id, me, settings },
      type: MODAL.OPEN_MODAL,
    })
  }
}

export function closeModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_MODAL })
  }
}

export function openSimpleConfirmModal(
  removedItem: ISection | IProblem | IClass | IGroup | IStudent | IExercise | ClassroomGroup,
  typeOfItem: RemovableTypes,
  updatedItem?: IChapter | ISection | ClassroomGroup
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { removedItem, updatedItem, typeOfItem },
      type: MODAL.OPEN_SIMPLE_CONFIRM_MODAL,
    })
  }
}

export function closeSimpleConfirmModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_SIMPLE_CONFIRM_MODAL })
  }
}

// Modal of classes
export function openClassesModal(id: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ payload: { id }, type: MODAL.OPEN_CLASSES_MODAL })
  }
}

export function closeClassesModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_CLASSES_MODAL })
  }
}

export function openOverlay(title: string, id: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { title, id },
      type: MODAL.OPEN_OVERLAY,
    })
  }
}

export function closeOverlay() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_OVERLAY })
  }
}

export function openMapOverlay(
  section: any,
  statuses: any,
  exerciseId: string,
  chapterId: string,
  selectedItem: any
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { section, statuses, exerciseId, chapterId, selectedItem },
      type: MODAL.OPEN_MAP_OVERLAY,
    })
  }
}

export function closeMapOverlay() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_MAP_OVERLAY })
  }
}

export function openStatisticsOverlay(exercise: string, selectedItem: any) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { exercise, selectedItem },
      type: MODAL.OPEN_STATISTICS_OVERLAY,
    })
  }
}

export function closeStatisticsOverlay() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_STATISTICS_OVERLAY })
  }
}

export function openModalRegister(me: any) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { me },
      type: MODAL.OPEN_MODAL_REGISTER,
    })
  }
}

export function closeModalRegister() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_MODAL_REGISTER })
  }
}

export function openAllPasswordsModal(sentClass: any) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { sentClass },
      type: MODAL.OPEN_ALL_PASSWORDS_MODAL,
    })
  }
}

export function closeAllPasswordsModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_ALL_PASSWORDS_MODAL })
  }
}

export function openModalPassword(me: any, username: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { me, username },
      type: MODAL.OPEN_MODAL_PASSWORD,
    })
  }
}

export function closeModalPassword() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_MODAL_PASSWORD })
  }
}

export function openGroupStudentAutosuggest(
  students: IClassesStudents,
  groupId: string | undefined
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { students, groupId },
      type: MODAL.OPEN_GROUP_STUDENTS_AUTOSUGGEST,
    })
  }
}

export function closeGroupStudentAutosuggest() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_GROUP_STUDENTS_AUTOSUGGEST })
  }
}

export function openTeachAutosuggest(teachers: ITeachers, typeOfItem: RemovableTypes, id: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { id, teachers, typeOfItem },
      type: MODAL.OPEN_TEACHERS_AUTOSUGGEST,
    })
  }
}

export function closeTeachAutosuggest() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_TEACHERS_AUTOSUGGEST })
  }
}

export function openSimpleAsyncModal(typeOfItem: FormTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { typeOfItem },
      type: MODAL.OPEN_SIMPLE_ASYNC_MODAL,
    })
  }
}

export function closeSimpleAsyncModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_SIMPLE_ASYNC_MODAL })
  }
}

export function closeSelectSchoolsModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_SELECT_SCHOOLS_MODAL })
  }
}

export function openCreateOrEditExerciseModal(
  dataForCreateOrEditExerciseModal: DataForCreateOrEditExerciseModal
) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: MODAL.OPEN_CREATE_OR_EDIT_EXERCISE_MODAL,
      payload: { dataForCreateOrEditExerciseModal },
    })
  }
}

export function closeCreateOrEditExerciseModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_CREATE_OR_EDIT_EXERCISE_MODAL })
  }
}

export function openShareLinkModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.OPEN_SHARE_LINK_MODAL })
  }
}

export function closeShareLinkModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_SHARE_LINK_MODAL })
  }
}

export function openLanguageSettingsModal(settings?: any, classId?: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: MODAL.OPEN_LANGUAGE_SETTINGS_MODAL,
      payload: { settings, classId },
    })
  }
}

export function closeLanguageSettingsModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_LANGUAGE_SETTINGS_MODAL })
  }
}

export function openTranslationLanguageSelectorModal(classId?: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: MODAL.OPEN_TRANSLATION_LANGUAGE_SELECTOR_MODAL,
      payload: { classId },
    })
  }
}

export function closeTranslationLanguageSelectorModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_TRANSLATION_LANGUAGE_SELECTOR_MODAL })
  }
}

export const openCreateClassModal = (
  title: string,
  actionElement: string,
  me: any,
  id?: string | null,
  settings?: any
) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { actionElement, title, id, me, settings },
      type: MODAL.OPEN_CREATE_CLASS_MODAL,
    })
  }
}

export const closeCreateClassModal = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_CREATE_CLASS_MODAL })
  }
}

export const openChooseStudentsModalUpdated = (classId: string) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.OPEN_CHOOSE_STUDENTS_MODAL_UPDATED, payload: { classId } })
  }
}

export const closeChooseStudentsModalUpdated = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_CHOOSE_STUDENTS_MODAL_UPDATED })
  }
}


export function openOldPracticeResultsModal({
  abilityId,
  abilityName,
  selectedStudents,
  resultView,
  studentName,
}: {
  abilityId: string
  abilityName: string
  selectedStudents?: IStudent[] | []
  resultView?: ResultsView
  studentName?: string
}) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: MODAL.OPEN_OLD_PRACTICE_RESULTS_MODAL,
      payload: { abilityId, abilityName, selectedStudents, resultView, studentName },
    })
  }
}

export function closeOldPracticeResultsModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_OLD_PRACTICE_RESULTS_MODAL })
  }
}

export function openPracticeResultsModal({ skill, classId }: { skill: ISkill; classId: string }) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: MODAL.OPEN_PRACTICE_RESULTS_MODAL,
      payload: { skill, classId },
    })
  }
}

export function closePracticeResultsModal() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_PRACTICE_RESULTS_MODAL })
  }
}

export const openArchiveClassModal = (classItem: IClass) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.OPEN_ARCHIVE_CLASS_MODAL, payload: { selectedItem: classItem } })
  }
}

export const closeArchiveClassModal = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: MODAL.CLOSE_ARCHIVE_CLASS_MODAL })
  }
}

export interface IModalActions {
  openModal: (
    title: string,
    actionElement: string,
    me: any,
    id?: string | null,
    settings?: any
  ) => void
  closeModal: () => void
  openClassesModal: (id: string) => void
  closeClassesModal: () => void
  openModalRegister: (me: any, localization: ILocalization) => void
  closeModalRegister: () => void
  openModalPassword: (me: any, username: string, localization: ILocalization) => void
  closeModalPassword: () => void
  openOverlay: (
    title: string,
    id: string,
    localization: ILocalization,
    history: any,
    exerciseActions: IExerciseActions
  ) => void
  closeOverlay: () => void
  openMapOverlay: (
    section: any,
    statuses: any,
    exerciseId: string,
    chapterId: string,
    selectedItem: any,
    localization: ILocalization,
    history: any
  ) => void
  closeMapOverlay: () => void
  openStatisticsOverlay: (
    exercise: any,
    selectedItem: any,
    localization: ILocalization,
    history: any
  ) => void
  closeStatisticsOverlay: () => void
  openGroupStudentAutosuggest: (
    students: IClassesStudents,
    groupId: any,
    localization: ILocalization,
    history: any
  ) => void
  closeGroupStudentAutosuggest: () => void
  openTeachAutosuggest: (teachers: ITeachers, typeOfItem: RemovableTypes, id: string) => void
  closeTeachAutosuggest: () => void
  openAllPasswordsModal: (sentClass: any) => void
  closeAllPasswordsModal: () => void
  openSimpleConfirmModal: (
    removedItem: ISection | IProblem | IClass | IGroup | IStudent | IExercise | ClassroomGroup,
    typeOfItem: RemovableTypes,
    updatedItem?: IChapter | ISection | ClassroomGroup
  ) => void
  closeSimpleConfirmModal: () => void
  openSimpleAsyncModal: (typeOfItem: FormTypes, localization: ILocalization, history: any) => void
  closeSimpleAsyncModal: () => void
  closeSelectSchoolsModal: () => void
  openCreateOrEditExerciseModal: (
    dataForCreateOrEditExerciseModal: DataForCreateOrEditExerciseModal
  ) => void
  closeCreateOrEditExerciseModal: () => void
  openShareLinkModal: () => void
  closeShareLinkModal: () => void
  openLanguageSettingsModal: (settings?: any, classId?: string) => void
  closeLanguageSettingsModal: () => void
  openTranslationLanguageSelectorModal: (classId?: string) => void
  closeTranslationLanguageSelectorModal: () => void
  openCreateClassModal: (
    title: string,
    actionElement: string,
    me: any,
    id?: string | null,
    settings?: any
  ) => void
  closeCreateClassModal: () => void
  openChooseStudentsModalUpdated: (classId: string) => void
  closeChooseStudentsModalUpdated: () => void
  openOldPracticeResultsModal: ({
    abilityId,
    abilityName,
    selectedStudents,
    resultView,
    studentName,
  }: {
    abilityId: string
    abilityName: string
    selectedStudents?: IStudent[] | []
    resultView?: ResultsView
    studentName?: string
  }) => void
  closeOldPracticeResultsModal: () => void
  openPracticeResultsModal: ({ skill, classId }: { skill: ISkill; classId: string }) => void
  closePracticeResultsModal: () => void
}

export type DataForCreateOrEditExerciseModal = {
  allClassroomStudents: boolean
  name: string
  classes: string[]
  groups: string[]
  problems: string[]
  students: string[]
  startDate: string
  endDate: null
  testModeEnabled: boolean
  allowedAnswerLooking: boolean
  numberOfProblems: number
  exerciseId: string
  updateMarker: string
}

export interface IModal {
  actionElement: any
  groupId: string
  chapterId: string
  settings: any
  imageUrl: string
  id: string
  me: IMe
  modalType: ModalType
  exercise: IExercise
  redirecting: boolean
  removedItem: ISection | IProblem
  title: string
  typeOfItem: RemovableTypes | FormTypes
  section: any
  selectedItem: any
  statuses: any
  exerciseId: string
  sentClass: IClass
  updatedItem: IChapter | ISection
  username: string
  classId?: string
  dataForCreateOrEditExerciseModal: DataForCreateOrEditExerciseModal
  abilityId: string
  abilityName: string
  selectedStudents: IStudent[] | []
  resultView: ResultsView | null
  studentName: string
  students?: IBasicUser[]
  skill: ISkill
}
