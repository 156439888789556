import React from 'react'
import { SELECT_OPTION_COLOR, SELECT_OPTION_STATE, SingleSelectGroup } from '@magmamath/ui'
import styles from './GradeSelect.module.scss'
import { GradeItem } from './types'

export type GradeSelectProps = {
  grade?: string | null
  onGradeChanged: (grade: string | null) => void
  gradeItems: GradeItem[]
}

export const GradeSelect = ({ grade, onGradeChanged, gradeItems }: GradeSelectProps) => {
  return (
    <SingleSelectGroup
      value={grade}
      onOptionChange={onGradeChanged}
      color={SELECT_OPTION_COLOR.ORANGE}
      state={SELECT_OPTION_STATE.DEFAULT}
      classes={{
        select: styles.GradeSelectContainer,
      }}
    >
      {gradeItems.map((grade) => (
        <SingleSelectGroup.Option
          key={grade.value}
          value={grade.value.toString()}
          classes={{
            selectOption: styles.GradeSelecetOptionContainer,
            content: styles.GradeSelectOption,
          }}
        >
          {grade.label}
        </SingleSelectGroup.Option>
      ))}
    </SingleSelectGroup>
  )
}
