import React, { FC } from 'react'
import styles from './Unit.module.scss'

export const POWER_SQUARE = 'POWER_SQUARE'
export const POWER_CUBE = 'POWER_CUBE'

export type Unit = {
  symbol: string
  mathPower?: number
}

interface UnitsProps {
  units: Unit[]
  isButtonDisabled?: boolean
  onPress?: (unit: string) => void
}

const MATH_POWER_MAP = {
  2: POWER_SQUARE,
  3: POWER_CUBE,
}

const getMathPowerString = (value: number | undefined) => {
  if (!value) return ''
  return MATH_POWER_MAP[value as keyof typeof MATH_POWER_MAP] || ''
}

const Units: FC<UnitsProps> = ({ units, isButtonDisabled, onPress }) => {
  return (
    <div className={styles.Container}>
      {units
        ? units.map(({ symbol, mathPower }) => (
            <button
              key={symbol}
              className={styles.Btn}
              onClick={() => onPress?.(`${symbol}${getMathPowerString(mathPower)}`)}
              disabled={isButtonDisabled}
            >
              {symbol}
              {mathPower && <sup>{mathPower}</sup>}
            </button>
          ))
        : null}
    </div>
  )
}

export default Units
