import { apiManager } from './apiManager'
import { ISection } from './types.books'

export type UpdateSingleSectionPayload = {
  id: string
  data: {
    name?: string
    problems?: string
  }
}

export type CreateNewSectionPayload = {
  isInitial: boolean
  name: string
  order: number
  locale: string
}

export type CreateNewSectionResponse = {
  section: ISection
}

export class sectionsApi {
  public static readonly getSingle = apiManager.get<ISection, string>('sections')
  public static readonly createNew = apiManager.post<
    CreateNewSectionResponse,
    CreateNewSectionPayload
  >('sections')
  public static readonly updateSingle = apiManager.put<ISection, UpdateSingleSectionPayload>(
    ({ id, data }) => ({
      url: `sections/${id}`,
      body: data,
    })
  )
  public static readonly deleteSingle = apiManager.delete<void, string>('sections')
}
