import React from 'react'
import { ShortcutsButtonsModel } from './ShortcutsButtonsModel'
import { COMMON_OPERATORS, OperatorDescriptor } from './operators'
import styles from './styles.module.scss'
import { useConst } from '../../../lib/hooks/const'
import clsx from 'clsx'

type MathShortcutButtonsProps = {
  onPress: (operator: OperatorDescriptor) => void
  operators?: OperatorDescriptor[]
  classes?: {
    container?: string
    button?: string
  }
}

type OperatorRendererProps = {
  operator: OperatorDescriptor
}
const OperatorRenderer = ({ operator }: OperatorRendererProps) => {
  if (typeof operator.label !== 'string') return <operator.label />
  if (operator.renderStraight) return <>{operator.label}</>
  return null
}

const MathShortcutButtons = React.memo(
  ({ onPress, operators = COMMON_OPERATORS, classes }: MathShortcutButtonsProps) => {
    const model = useConst(() => new ShortcutsButtonsModel(operators))
    model.setOperators(operators)
    return (
      <div className={clsx(styles.Container, classes?.container)}>
        {model.map(({ renderer, ...operator }, index) => {
          return (
            <button
              key={index}
              ref={(buttonRef) => {
                if (buttonRef) renderer(buttonRef)
              }}
              onClick={(e) => {
                e.preventDefault()
                onPress(operator)
              }}
              className={clsx(styles.Button, classes?.button)}
            >
              <OperatorRenderer operator={operator} />
            </button>
          )
        })}
      </div>
    )
  }
)

export default MathShortcutButtons
