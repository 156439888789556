import { socketEvents } from 'helpers/enums'
import { useEffect, useRef } from 'react'
import { Socket } from 'socket.io-client'
import { SocketPayload } from '../../api/socket'

export type EventListener<T extends socketEvents> = {
  event: T
  handler: (payload: SocketPayload<T>) => void
}

type SocketListenersList = { [K in socketEvents]: EventListener<K> }
export type SocketEventListener = SocketListenersList[socketEvents]
/*
  Amount of **events** should always be the same
 */
export const useSocketEvents = (
  socket: typeof Socket,
  events: SocketEventListener[] | SocketEventListener
) => {
  const eventsList = Array.isArray(events) ? events : [events]
  const eventsRef = useRef(eventsList)
  eventsRef.current = eventsList

  useEffect(() => {
    const listeners = eventsList.map(({ event }, index) => {
      const listener = (payload: any) => {
        eventsRef.current[index].handler(payload)
      }
      socket.on(event, listener)
      return { event, listener }
    })

    return () => {
      listeners.forEach(({ event, listener }) => {
        socket.removeListener(event, listener)
      })
    }
  }, [socket])
}
