import { createDebounceEvent } from '../../../../lib/effector/debounceEvent'
import { MathInputModel } from '../MathInputModel'
import { MQField } from '../types.mathquill'
import { ICoords } from '../../common/types'

const MathQuill = window.MathQuill

export type ChangedProps = {
  value: string
}

export type ValueMiddlewareSettings = {
  mq: MQField
  prevCursorPosition: ICoords | null
}

export type StaticInputValueMiddleware = (
  value: string,
  prevValue: string,
  settings?: ValueMiddlewareSettings
) => string

type MathInputModelProps = {
  initialValue?: string
  valueMiddleware?: StaticInputValueMiddleware
}

const noopMiddleware = (value: string) => value

export class StaticMathInputModel extends MathInputModel {
  public readonly changed = createDebounceEvent<ChangedProps>(50)

  protected readonly valueMiddleware
  protected currentValue
  protected cursorPosition: ICoords | null = null

  public constructor({
    initialValue = '',
    valueMiddleware = noopMiddleware,
  }: MathInputModelProps = {}) {
    super()
    this.valueMiddleware = valueMiddleware
    this.currentValue = valueMiddleware(initialValue, initialValue)
    this.blurred.watch(() => {
      this.cursorPosition = null
    })
  }

  private readonly saveCursor = (mq: MQField) => {
    const cursorOffset = mq.__controller.cursor.offset()
    this.cursorPosition = {
      x: cursorOffset.left,
      y: cursorOffset.top,
    }
  }
  public readonly setUp = (element: HTMLElement | null) => {
    if (!element || this.mq) return
    const mq = MathQuill.getInterface(2).MathField(element, {
      handlers: {
        edit: () => {
          const rawValue = mq.latex()
          const value = this.valueMiddleware(rawValue, this.currentValue, {
            mq,
            prevCursorPosition: this.cursorPosition,
          })
          this.saveCursor(mq)
          if (rawValue !== value) {
            mq.select()
            mq.write(value)
            return
          }
          this.currentValue = rawValue
          this.changed({ value: this.currentValue })
        },
      },
    })
    this.retrieveEditor(element)
    mq.write(this.currentValue)
    this.mq = mq
  }

  public readonly setValue = (value: string) => {
    if (this.currentValue === value || !this.mq) return
    this.currentValue = value
    this.mq.select()
    this.mq.write(value)
  }
}
