import { IMe } from 'api/types.users'
import { getFullGrades } from 'lib/grades'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import ClassCard from './ClassCard/ClassCard'
import { ClassTypes } from '../ClassesTab'
import styles from './ClassesList.module.scss'
import { hasSearchedValue } from 'lib/filters'
import { IClass } from '../../../api/types.classes'

type ClassesListProps = {
  classesType: ClassTypes
  searchInputValue: string
  classList: IClass[]
  title: string
  me: IMe
}

const ClassesList = ({ classesType, searchInputValue, classList, title, me }: ClassesListProps) => {
  const localization = useSelector((state: RootState) => state.localization)

  const sortedSchoolClasses = useMemo(() => {
    if (!classList?.length) return []
    const schoolClassesListCopy = [...classList]

    return schoolClassesListCopy.sort((schoolClassA, schoolClassB) => {
      if (schoolClassA.grade === schoolClassB.grade) {
        return schoolClassA.name.localeCompare(schoolClassB.name)
      }
      if (typeof schoolClassA.grade === 'number' && typeof schoolClassB.grade === 'number') {
        return schoolClassA.grade > schoolClassB.grade ? 1 : -1
      }
      return `${schoolClassA.grade}`.localeCompare(`${schoolClassB.grade}`)
    })
  }, [classList])

  const schoolClassesToRender = useMemo(
    () =>
      sortedSchoolClasses.filter((schoolClass) =>
        hasSearchedValue(
          [schoolClass.name, schoolClass.owner?.firstName ?? '', schoolClass.owner?.lastName ?? ''],
          searchInputValue
        )
      ),
    [sortedSchoolClasses, searchInputValue]
  )

  const grades = getFullGrades(me?.setting?.appLanguage, localization)
  const gradesObject = Object.fromEntries(grades.map((obj) => [obj.value, obj.label]))

  if (!schoolClassesToRender.length) return null

  return (
    <>
      {!!title && <h5 className={styles.ClassesGroupTitle}>{title}</h5>}
      {schoolClassesToRender.map((schoolClass) => {
        const gradeLabel = gradesObject[schoolClass.grade] ?? ''
        return (
          <ClassCard
            key={schoolClass._id}
            classesType={classesType}
            classDetails={schoolClass}
            gradeLabel={`${gradeLabel}`}
            me={me}
          />
        )
      })}
    </>
  )
}

export default ClassesList
