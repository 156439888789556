import React from 'react'
import { useSelector } from 'react-redux'
import StaticMathInputWrapper from './StaticMathInputWrapper'
import { setAnswerAlgebra } from '../../../redux/modules/problemCreator.module'
import { RootState } from '../../../store/store'
import { algebraFilter } from '../../mathEditor/middlewares/middlewares'

const TabAlgebraAnswer = () => {
  const answerAlgebra = useSelector((state: RootState) => state.problemCreatorData.answerAlgebra)

  return (
    <StaticMathInputWrapper
      handleMathInputChange={setAnswerAlgebra}
      initialValue={answerAlgebra}
      placeholder='xyz'
      mathInputValueMiddleware={algebraFilter}
    />
  )
}

export default TabAlgebraAnswer
