import { COLORS } from '@magmamath/ui'
import Divider from '@mui/material/Divider'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import UISwitch from 'ui/Switch/Switch'
import CalculatorIcon from 'ui/icons/CalculatorIcon/CalculatorIcon'
import ClockIcon from 'ui/icons/ClockIcon/ClockIcon'
import ExclamationMarkIcon from 'ui/icons/ExclamationMarkIcon/ExclamationMarkIcon'
import EyeIcon from 'ui/icons/EyeIcon/EyeIcon'
import PenIcon from 'ui/icons/PenIcon/PenIcon'
import RulerIcon from 'ui/icons/RulerIcon/RulerIcon'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import ExerciseStatusOption from '../../../exerciseStatus/ui/ExerciseStatusOption/ExerciseStatusOption'
import { ICON_PROPS } from '../../constants'
import {
  $exerciseSettings,
  setExamModeManually,
  setExerciseSetting,
} from '../../model/exerciseSettings'
import { EXERCISE_SETTINGS } from '../../types'
import AccordionTogglePanel from '../AccordionTogglePanel/AccordionTogglePanel'
import CalculatorListbox from '../CalculatorListbox/CalculatorListbox'
import ExerciseModeButtonsSet from '../ExerciseModeButtonsSet/ExerciseModeButtonsSet'
import SettingsOption from '../SettingsOption/SettingsOption'
import styles from './SettingsAccordion.module.scss'
import { IExercise } from '../../../../../api/types.assignments'

type SettingsAccordionProps = {
  exercise?: IExercise,
  onToggle: () => void,
  isOpen: boolean
}

const SettingsAccordion = ({
  exercise,
  onToggle,
  isOpen
}: SettingsAccordionProps) => {
  const t = useText()
  const settings = useUnit($exerciseSettings)

  return (
    <div className={clsx(styles.Accordion, { [styles.Open]: isOpen })}>
      <ExerciseModeButtonsSet
        isExamMode={settings.isExamMode}
        onExerciseModeChange={setExamModeManually}
      />
      <div className={styles.SettingsOptions}>
        <SettingsOption
          icon={<ClockIcon {...ICON_PROPS} />}
          title={t.publishTxt}
          isHidden={!isOpen}
        >
          <ExerciseStatusOption exercise={exercise} />
        </SettingsOption>
        {isOpen && <Divider className={styles.Divider} />}

        {!settings.isExamMode && (
          <SettingsOption
            onClick={() => setExerciseSetting({ key: EXERCISE_SETTINGS.ALLOW_ANSWER_CHECKING })}
            icon={<EyeIcon {...ICON_PROPS} />}
            title={t.allowAnswerChecking}
          >
            <UISwitch checked={settings.allowAnswerChecking} className={styles.Switch} />
          </SettingsOption>
        )}

        {settings.isExamMode && (
          <SettingsOption
            onClick={() => setExerciseSetting({ key: EXERCISE_SETTINGS.CHEAT_DETECTION })}
            icon={
              <UITooltip placement='top' title={t.cheatDetectionDescription}>
                {/* div as an icon wrapper is required by MUI tooltip */}
                <div>
                  <ExclamationMarkIcon size={ICON_PROPS.size} color={COLORS.PRIMARY_RED} />
                </div>
              </UITooltip>
            }
            title={t.cheatDetection}
          >
            <UISwitch checked={settings.cheatDetection} className={styles.Switch} />
          </SettingsOption>
        )}

        <SettingsOption
          onClick={() => setExerciseSetting({ key: EXERCISE_SETTINGS.FORCED_DRAWING })}
          icon={<PenIcon {...ICON_PROPS} />}
          title={t.forcedDrawing}
        >
          <UISwitch checked={settings.forcedDrawing} className={styles.Switch} />
        </SettingsOption>
        <SettingsOption
          isHidden={!isOpen}
          onClick={() => setExerciseSetting({ key: EXERCISE_SETTINGS.REQUIRE_UNIT })}
          icon={<RulerIcon {...ICON_PROPS} />}
          title={t.requireUnit}
        >
          <UISwitch checked={settings.requireUnit} className={styles.Switch} />
        </SettingsOption>
        <SettingsOption
          icon={<CalculatorIcon {...ICON_PROPS} />}
          title={t.calculator}
          isHidden={!isOpen}
        >
          <CalculatorListbox />
        </SettingsOption>
        <AccordionTogglePanel isOpen={isOpen} toggleOpen={onToggle} />
      </div>
    </div>
  )
}

export default SettingsAccordion
