import React from 'react'
import { SvgIconProps } from '../types/types'

const ExpandHeatmapIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.85258 1.00137C1.39845 1.01439 1.01976 1.39308 1.00675 1.8472L0.849953 7.31951C0.836942 7.77363 1.19454 8.13123 1.64866 8.11822C2.10279 8.1052 2.48148 7.72651 2.49449 7.27239L2.59441 3.78522L7.71443 8.90524C8.02635 9.21716 8.54698 9.20224 8.87729 8.87192C9.20761 8.54161 9.22253 8.02097 8.91061 7.70906L3.79059 2.58904L7.27776 2.48912C7.73189 2.47611 8.11058 2.09742 8.12359 1.64329C8.1366 1.18917 7.779 0.831571 7.32488 0.844582L1.85258 1.00137Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.1473 18.9987C18.6015 18.9856 18.9802 18.607 18.9932 18.1528L19.15 12.6805C19.163 12.2264 18.8054 11.8688 18.3512 11.8818C17.8971 11.8948 17.5184 12.2735 17.5054 12.7276L17.4055 16.2148L12.2855 11.0948C11.9736 10.7829 11.4529 10.7978 11.1226 11.1281C10.7923 11.4584 10.7774 11.9791 11.0893 12.291L16.2093 17.411L12.7221 17.5109C12.268 17.5239 11.8893 17.9026 11.8763 18.3567C11.8633 18.8109 12.2209 19.1685 12.675 19.1554L18.1473 18.9987Z'
        fill={color}
      />
    </svg>
  )
}

export default ExpandHeatmapIcon
