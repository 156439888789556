import { localStorageKeys } from 'config/localStorageKeys'
import { IAppProps } from 'containers/App/App.container'
import * as React from 'react'
import { Redirect } from 'react-router'
import { getItemFromLocalStorage, getItemFromLocalStorageWithParsing } from './storage.helper'
import { User } from '../api/types.users'

export function redirectToMainPathWithParams(
  location: Location,
  history: any[],
  params: { [key: string]: any }
) {
  const keys = Object.keys(params)
  let path = `${location.pathname}`
  path = keys.reduce((str: string, item: string) => {
    str = !str.includes(item)
      ? `${str}${str.includes('?') ? '&' : '?'}${item}=${params[item]}`
      : str
    return str
  }, path)
  history.push(path)
}

export const checkRole = (Component: any, props: IAppProps, roles: number[] = adminType()): any => {
  return (props.auth &&
    props.auth.data &&
    props.auth.data.me &&
    roles.includes(props.auth.data.me.type)) ||
    (getItemFromLocalStorage(localStorageKeys.me) &&
      roles.includes(+getItemFromLocalStorageWithParsing(localStorageKeys.me).type)) ? (
    <Component {...props} />
  ) : (
    <Redirect to={`/login`} />
  )
}

export const anyTypes = (): [User.teacher, User.problemsEditor] => [
  User.teacher,
  User.problemsEditor,
]
export const adminType = (): [User.teacher] => [User.teacher]
