import { createEvent, createStore } from 'effector'
import { fetchChaptersFx } from './requests'
import { removeSectionByChapterId } from './sectionPaths'
import { fetchFoundBooksFx, fetchMyBooksFx } from '../../model/requests'
import { ChapterListItem } from './types'
import { getChapterListItems } from '../helpers'
import { BreadcrumbIndex } from '../../model/types'

export const removeChapters = createEvent<string>()
export const resetChapters = createEvent()

export const $chapters = createStore<Map<string, ChapterListItem[]>>(new Map())
  .on(fetchMyBooksFx.doneData, (state, books) => {
    const newState = new Map(state)

    ;[...newState.keys()].forEach((key) => {
      if (!books.find((book) => book._id === key)) {
        newState.delete(key)
      }
    })

    return newState
  })
  .on(removeChapters, (state, bookId) => {
    const newState = new Map(state)
    const chapters = newState.get(bookId) || []

    chapters.forEach((chapter) => {
      removeSectionByChapterId(chapter.id)
    })

    newState.delete(bookId)
    return newState
  })
  .on(fetchChaptersFx.doneData, (state, { bookId, chapters }) => {
    const newState = new Map(state)

    newState.set(bookId, getChapterListItems(bookId, chapters))
    return newState
  })
  .on(fetchFoundBooksFx.done, (state, { result }) => {
    const newState = new Map(state)
    result.forEach((bookBreadcrumb) => {
      newState.set(
        `${bookBreadcrumb.breadcrumbs[BreadcrumbIndex.BOOK].id}-${
          bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].id
        }`,
        [
          {
            id: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.CHAPTER].id,
            name: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.CHAPTER].name,
            sectionId: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].id,
          },
        ]
      )
    })
    return newState
  })

  .reset(resetChapters)
