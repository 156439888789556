import { BUTTON_COLOR } from '@magmamath/ui'
import clsx from 'clsx'
import React from 'react'
import styles from './RadioInputGroup.module.scss'

type RadioInputGroupProps = {
  options: { value: string; label: React.ReactNode }[]
  name: string
  selectedValue: string
  onChange: (value: string) => void
  color?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
}

const RadioInputGroup = ({
  options,
  name,
  selectedValue,
  onChange,
  color = BUTTON_COLOR.BLUE,
}: RadioInputGroupProps) => (
  <div className={styles.InputGroup}>
    {options.map((option) => (
      <label key={option.value}>
        <input
          type='radio'
          name={name}
          value={option.value}
          checked={selectedValue === option.value}
          onChange={() => onChange(option.value)}
        />
        <span
          className={clsx(styles.RadioCustom, {
            [styles.Blue]: color === BUTTON_COLOR.BLUE,
            [styles.Purple]: color === BUTTON_COLOR.PURPLE,
          })}
        />
        {option.label}
      </label>
    ))}
  </div>
)

export default RadioInputGroup
