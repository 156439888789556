import { IBasicUser } from 'api/types.users'
import uniqBy from 'lodash/uniqBy'
import { IExerciseWithoutStats } from '../redux/modules/exerciseWithoutStats.module'
import { IExercise } from '../api/types.assignments'

export const getAllStudentsFromExercise = (
  exercise: IExercise | IExerciseWithoutStats,
  statsStudentsData: { student: IBasicUser }[] = []
) =>
  uniqBy(
    [
      ...exercise.students,
      ...statsStudentsData.reduce(
        (acc, student) => (!student.student ? acc : [...acc, student.student]),
        []
      ),
    ],
    '_id'
  )
