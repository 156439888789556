import { IProblem } from 'api/types.problem'
import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/session'
import { $problems } from 'features/bookshelf/Book/model/problems'
import { $selectedProblems } from 'features/bookshelf/Book/model/selectedProblems'
import { deserializeSelectedProblemsData, serializeSelectedProblemsData } from './helpers'
import { fetchProblemsByIdsFx } from './requests'

export class selectedProblemsDataModel {
  static readonly set = createEvent<Map<string, IProblem> | null>()

  static readonly $state = restore(this.set, null).on(
    fetchProblemsByIdsFx.doneData,
    (_, problems) => {
      const newState = new Map<string, IProblem>()
      problems.forEach((problem) => newState.set(problem._id, problem))
      return newState
    }
  )
}

sample({
  source: {
    selectedProblems: $selectedProblems,
    sectionProblemsMap: $problems,
    selectedProblemsData: selectedProblemsDataModel.$state,
  },
  clock: [$selectedProblems, $problems],
  fn: ({ selectedProblems, sectionProblemsMap, selectedProblemsData }) => {
    const newState = new Map<string, IProblem>()
    selectedProblems.forEach((sectionProblems, sectionId) => {
      sectionProblems.forEach((problemId) => {
        const sourceData = selectedProblemsData?.get(problemId)

        if (sourceData) {
          newState.set(problemId, sourceData)
          return
        }

        const sectionProblems = sectionProblemsMap.get(sectionId)
        const problem = sectionProblems?.find((problem) => problemId === problem._id)
        if (problem) {
          newState.set(problemId, problem)
          return
        }
      })
    })
    return newState
  },
  target: selectedProblemsDataModel.$state, // ! this is sorted according to the section order of selectedProblems - this order should be always correct
})

persist({
  store: selectedProblemsDataModel.$state,
  key: sessionStorageKeys.cafSelectedProblemsData,
  serialize: (state) => serializeSelectedProblemsData(state),
  deserialize: (state) => deserializeSelectedProblemsData(state),
})
