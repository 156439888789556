import { uniq } from 'lodash'
import { IMe } from '../../../api/types.users'

export const getBookSeriesIds = (me: IMe, bookSeriesId?: string[]) => {
  let seriesIds: string[]
  if (bookSeriesId) {
    seriesIds = bookSeriesId
    return seriesIds
  }
  const seriesIdsByCountry: string[] = me?.country?.bookSeries || []
  const seriesIdsByRegion: string[] = me?.region?.bookSeries || []
  const seriesIdsByMunicipality: string[] = me?.municipality?.bookSeries || []
  const seriesIdsByTeacher: string[] = me?.bookSeries || []
  const seriesIdsBySchool: string[] = me?.school?.bookSeries || []
  seriesIds = uniq([
    ...seriesIdsByCountry,
    ...seriesIdsByRegion,
    ...seriesIdsByMunicipality,
    ...seriesIdsByTeacher,
    ...seriesIdsBySchool,
  ])
  return seriesIds
}
