import React from 'react'
import Loader from 'react-spinkit'
import { COLORS } from '@magmamath/ui'

type SpinnerProps = {
  className?: string
}

const Spinner = ({ className }: SpinnerProps) => {
  return (
    <Loader
      name='ball-beat'
      fadeIn='half'
      className={className}
      color={COLORS.PRIMARY_ORANGE}
      data-testid='spinner'
    />
  )
}

export default Spinner
