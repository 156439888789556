import React from 'react'
import styles from './ErrorMessageTitle.module.scss'
import StudentIcon from '../../../../../ui/icons/StudentIcon/StudentIcon'
import UITooltip from '../../../../../ui/tooltips/UITooltip/Tooltip'
import ErrorMessageStudentsList from '../ErrorMessageStudentsList/ErrorMessageStudentsList'
import useText from '../../../../../i18n/hook'
import { COLORS } from '@magmamath/ui'

type ErrorMessageTitleProps = { students: string[] }
const ErrorMessageTitle = ({ students }: ErrorMessageTitleProps) => {
  const t = useText()
  return (
    <div className={styles.Container}>
      <div className={styles.TitleWrapper}>
        <div className={styles.Title}>{t.commonError}</div>
      </div>
      <UITooltip
        title={<ErrorMessageStudentsList students={students} />}
        placement='top-start'
        classes={{ popper: styles.Popper }}
      >
        <div className={styles.StudentsWrapper}>
          <StudentIcon size={16} color={COLORS.NEUTRAL_6} />
          <div className={styles.StudentCount}>{students.length}</div>
        </div>
      </UITooltip>
    </div>
  )
}

export default ErrorMessageTitle
