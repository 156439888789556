import React from 'react'
import { SvgIconProps } from '../types/types'

const AreaIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_214)'>
        <path d='M4.9362 1.39648L1.39453 4.93815' stroke={color} strokeWidth='0.413737' />
        <path d='M8.47786 1.39648L1.39453 8.47982' stroke={color} strokeWidth='0.413737' />
        <path d='M12.2279 1.18945L1.39453 12.0228' stroke={color} strokeWidth='0.413737' />
        <path d='M15.5612 1.39648L1.39453 15.5632' stroke={color} strokeWidth='0.413737' />
        <path d='M19.3086 1.18945L1.18359 19.3145' stroke={color} strokeWidth='0.413737' />
        <path d='M23.0586 0.980469L1.18359 22.8555' stroke={color} strokeWidth='0.413737' />
        <path d='M24.1029 3.48047L3.89453 23.6889' stroke={color} strokeWidth='0.413737' />
        <path d='M24.1003 7.02148L7.43359 23.6882' stroke={color} strokeWidth='0.413737' />
        <path d='M23.4792 11.1875L11.1875 23.4792' stroke={color} strokeWidth='0.413737' />
        <path d='M23.8958 14.3125L14.3125 23.8958' stroke={color} strokeWidth='0.413737' />
        <path d='M24.5156 17.2305L18.2656 23.4805' stroke={color} strokeWidth='0.413737' />
        <path
          d='M22.5143 0.828125H2.47916C1.56516 0.828125 0.824219 1.56907 0.824219 2.48307V22.5182C0.824219 23.4322 1.56516 24.1732 2.47916 24.1732H22.5143C23.4283 24.1732 24.1693 23.4322 24.1693 22.5182V2.48307C24.1693 1.56907 23.4283 0.828125 22.5143 0.828125Z'
          stroke='black'
          strokeWidth='1.66'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_214'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AreaIcon
