import clsx from 'clsx'
import React, { forwardRef } from 'react'

import './Card.scss'

type UICardProps = JSX.IntrinsicElements['div']

const UICard = forwardRef<HTMLDivElement, UICardProps>(({ className, ...rest }, ref) => {
  const mergedClassNames = clsx('ui-card', className)

  return <div ref={ref} className={mergedClassNames} {...rest} />
})

export default UICard
