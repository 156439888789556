import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowUpIcon = ({ size = 15, color, ...restProps }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 12 16'
      fill='none'
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6189 6.81891C11.2772 7.16062 10.7231 7.16062 10.3814 6.81891L6.87515 3.31263L6.87515 14.7999C6.87515 15.2832 6.4834 15.6749 6.00015 15.6749C5.5169 15.6749 5.12515 15.2832 5.12515 14.7999L5.12515 3.31263L1.61887 6.81891C1.27716 7.16062 0.723143 7.16062 0.381434 6.81891C0.0397253 6.4772 0.0397253 5.92319 0.381434 5.58148L5.38143 0.581477C5.72314 0.239768 6.27716 0.239768 6.61887 0.581477L11.6189 5.58148C11.9606 5.92319 11.9606 6.4772 11.6189 6.81891Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowUpIcon
