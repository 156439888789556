import React, { useEffect, useState, useRef } from 'react'
import styles from './GradeSelectorModalBody.module.scss'
import StudentsInfoList from './StudentsInfoList/StudentsInfoList'
import StudentsGradesList from './StudentsGradesList/StudentsGradesList'

const GradeSelectorModalBody = () => {
  const [containerRect, setContainerRect] = useState<DOMRect | undefined>(undefined)
  const modalBodyContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!modalBodyContainerRef.current) {
      return
    }

    setContainerRect(modalBodyContainerRef.current?.getBoundingClientRect())
  }, [])

  return (
    <div className={styles.GradeSelectorModalBodyContainer} ref={modalBodyContainerRef}>
      <div className={styles.Content}>
        <StudentsInfoList />
        <StudentsGradesList containerDimensions={containerRect} />
      </div>
    </div>
  )
}

export default GradeSelectorModalBody
