import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE, COLORS } from '@magmamath/ui'
import clsx from 'clsx'
import { PATHS } from 'config/pathnames.config'
import { useStoreMap, useUnit } from 'effector-react'
import { $selectedProblems } from 'features/bookshelf/Book/model/selectedProblems'
import { getSelectedProblemsIds } from 'features/bookshelf/ReorderProblems/helpers'
import { savedSortedProblemsIdModel } from 'features/bookshelf/ReorderProblems/model/savedSortedSelectedProblemIds'
import { selectedProblemsDataModel } from 'features/bookshelf/ReorderProblems/model/selectedProblemsData'
import { savedSortOptionModel } from 'features/bookshelf/ReorderProblems/model/sortOption/savedSortOption'
import { selectClass } from 'features/filters/MultiplicityFilter/classesFilter.module'
import { getDefaultClassOption } from 'features/filters/MultiplicityFilter/defaultClassOption.helpers'
import { $classes } from 'features/modals/ClassroomModal/model/classes'
import { prepareExerciseRequestData } from 'helpers/exercise.helpers'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { addBulkExercises, addExercise, editExercise } from 'redux/modules/exercise.module'
import { clearExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { getAvailable, getUnpublished } from 'redux/modules/exercisesStatuses.module'
import { saveClass } from 'redux/modules/saveClass.module'
import { RootState } from 'store/store'
import ArrowBackIcon from 'ui/icons/ArrowBackIcon/ArrowBackIcon'
import { ExerciseStatuses, IExercise } from '../../../api/types.assignments'
import BookSearch from '../../bookshelf/BookSearch/BookSearch'
import { updateErrorsIsSubmitted } from '../errorMessages/model/errorMessages'
import { $exerciseName } from '../exerciseSettings/model/exerciseName'
import { $exerciseSettings } from '../exerciseSettings/model/exerciseSettings'
import { $exerciseStatus } from '../exerciseStatus/model/exerciseStatus'
import styles from './PageHeader.module.scss'

const exerciseStatusCallbackMap = {
  [ExerciseStatuses.Unpublished]: getUnpublished,
  [ExerciseStatuses.Schedule]: getUnpublished,
  [ExerciseStatuses.PublishNow]: getAvailable,
}

type PageHeaderProps = {
  selectedClassesCount: number
  hasAllRequiredValues: boolean
  isDuplicateExercise?: boolean
  exercise?: IExercise
}

const PageHeader = ({
  exercise,
  selectedClassesCount,
  hasAllRequiredValues,
  isDuplicateExercise,
}: PageHeaderProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const settings = useUnit($exerciseSettings)
  const exerciseStatus = useUnit($exerciseStatus)
  const { exerciseName } = useUnit($exerciseName)
  const classesStore = useUnit($classes)
  const selectedProblems = useStoreMap($selectedProblems, (selectedProblems) =>
    [...selectedProblems.values()].filter((set) => set.size > 0)
  )
  const savedSortedProblemsId = useUnit(savedSortedProblemsIdModel.$state)
  const savedSortOption = useUnit(savedSortOptionModel.$state)
  const selectedProblemsData = useUnit(selectedProblemsDataModel.$state)

  const dispatch = useDispatch()
  const history = useHistory()
  const t = useText()

  const localization = useSelector((state: RootState) => state.localization)
  const selectedFilterOption = useSelector(
    (state: RootState) => state.filterExercises.data.selectedOption
  )

  const manageMultiplicityFilterSort = () => {
    const isAllClassesOption = !selectedFilterOption?.type
    if (isAllClassesOption) return
    const classDetails = classesStore[0]?.classStore

    if (selectedClassesCount !== 1 || !classDetails) {
      dispatch(selectClass(getDefaultClassOption(t)))
      dispatch(saveClass(null))
      return
    }

    const selectedOption = {
      value: classDetails.id,
      name: classDetails.name,
    }

    dispatch(selectClass(selectedOption))
    dispatch(saveClass(classDetails.id))
  }

  const onSubmitButtonClick = async () => {
    if (!hasAllRequiredValues) {
      updateErrorsIsSubmitted(true)
      return
    }
    try {
      setIsSubmitted(true)

      const exerciseRequestData = prepareExerciseRequestData({
        classesStore,
        settings,
        exerciseStatus,
        selectedProblems: getSelectedProblemsIds({
          savedSortedProblemsId,
          exercise,
          selectedProblems,
          savedSortOption,
          selectedProblemsData,
        }),
        exerciseName,
        isDuplicateExercise,
        savedSortOption: savedSortOption,
      })

      if (!exerciseRequestData) {
        return
      }

      const exerciseActionCallback =
        typeof exerciseStatus === 'number' ? exerciseStatusCallbackMap[exerciseStatus] : null
      exerciseActionCallback?.()(dispatch)
      manageMultiplicityFilterSort()

      if (Array.isArray(exerciseRequestData)) {
        await addBulkExercises(exerciseRequestData, history, localization)(dispatch)
        return
      }

      if (!exercise?._id || isDuplicateExercise) {
        await addExercise(exerciseRequestData, history, localization)(dispatch)
        return
      }

      dispatch(clearExerciseWithoutStats())
      await editExercise(exerciseRequestData, exercise._id, history, false)(dispatch)
    } finally {
      setIsSubmitted(false)
    }
  }

  return (
    <div className={styles.Header}>
      <div className={styles.Search}>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          As={Link}
          to={PATHS.EXERCISES.EXERCISES_MAIN}
          icon={<ArrowBackIcon size={19} color={COLORS.NEUTRAL_7} />}
          classes={{ button: styles.Link }}
        />
        <BookSearch />
      </div>
      <div onClick={onSubmitButtonClick}>
        <Button
          classes={{
            button: clsx({ [styles.DisabledCreateButton]: !hasAllRequiredValues })
          }}
          size={SIZE.LARGE}
          color={BUTTON_COLOR.ORANGE}
          disabled={!hasAllRequiredValues}
          isLoading={isSubmitted}
        >
          {!!exercise?._id && !isDuplicateExercise ? t.updateTxt : t.createTxt}
        </Button>
      </div>
    </div>
  )
}

export default PageHeader
