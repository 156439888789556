import { BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import useText from 'i18n/hook'
import React from 'react'
import IncognitoIcon from 'ui/icons/IncognitoIcon/IncognitoIcon'
import { $heatmapSettings, toggleAnonymousNameMode } from '../../model/heatmapSettings'

type AnonymousNameButtonProps = {
  buttonColor: ReturnType<typeof getButtonColor>
}

const AnonymousNameButton = ({ buttonColor }: AnonymousNameButtonProps) => {
  const t = useText()
  const isAnonymousNameMode = useStoreMap(
    $heatmapSettings,
    ({ isAnonymousNameMode }) => isAnonymousNameMode
  )
  return (
    <Button
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      color={buttonColor}
      icon={<IncognitoIcon color='var(--icon-color)' size={20} />}
      onClick={toggleAnonymousNameMode}
      isActive={isAnonymousNameMode}
      tooltipText={t.anonymousName}
      tooltipPosition={TOOLTIP_POSITION.BOTTOM}
    />
  )
}

export default AnonymousNameButton
