import { config } from 'config'
import { PATHS } from 'config/pathnames.config'
import { SearchParam } from 'helpers/enums'
import { IMe } from 'api/types.users'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import UIButton from 'ui/Buttons/UIButton/Button'
import UIModal from 'ui/modals/UIModal/UIModal'
import styles from './RequestAccessContent.module.scss'
import { ILocalization } from '../../../../config/languages.config'
import { Button, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../../ui/icons/PlusIcon/PlusIcon'
import { IClass } from '../../../../api/types.classes'

type RequestAccessContentProps = {
  classDetails: IClass
  me: IMe
}

const generateEmailData = ({
  t,
  classDetails,
  me,
}: {
  t: ILocalization['data']
  classDetails: IClass
  me: IMe
}) => {
  const classId = classDetails._id ?? ''
  const className = classDetails.name ?? ''
  const userText = classDetails.owner?.firstName ?? t.classOwnerTxt
  const emailSubject = t.shareClassMessageTitle
  const emailLink =
    classId && config.APP_HOST
      ? `${config.APP_HOST}${PATHS.PARTS.CLASS_STUDENTS}${classId}${SearchParam.SETTINGS_TAB}`
      : ''
  const emailBody = `${t.dearTxt}%20${userText},%0A${t.shareClassMessageBody}%20"${className}"%20${t.withMeTxt}?%0A%0A${t.shareClassClickLinkTxt}%0A${emailLink}%0A%0A${t.kindRegardsTxt},%0A${me?.firstName}%20${me?.lastName}`
  const emailHref = `mailto:%20?subject=${emailSubject}&body=${emailBody}`
  return {
    userText,
    emailHref,
  }
}

const RequestAccessContent = ({ classDetails, me }: RequestAccessContentProps) => {
  const localization = useSelector((state: RootState) => state.localization)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const t = localization.data

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const emailData = generateEmailData({ t, classDetails, me })

  return (
    <>
      <UIModal
        open={isModalOpen}
        onClose={onModalClose}
        onBackdropClick={onModalClose}
        wrapperClassName={styles.ModalWrapper}
      >
        <>
          <h2 className={styles.Title}>{t.requestAccess}</h2>
          <p
            className={styles.Description}
          >{`${t.askTxt} ${emailData.userText} ${t.askRequestRestTxt}`}</p>
          <a className={styles.ButtonLink} href={emailData.emailHref}>
            <UIButton variant={'basic'} color={'green'} size={'medium'}>
              {t.sendEmailTxt}
            </UIButton>
          </a>
        </>
      </UIModal>
      <Button
        size={SIZE.SMALL}
        variant={BUTTON_VARIANT.TERTIARY}
        onClick={() => setIsModalOpen(true)}
        icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
        iconAlign={ICON_ALIGNMENT.RIGHT}
      >
        {t.follow}
      </Button>
    </>
  )
}

export default RequestAccessContent
