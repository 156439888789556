import { ILocalization } from "config/languages.config"
import { ConfirmModalMode } from "helpers/enums"

export const getModalTitle = (mode: ConfirmModalMode, t: ILocalization['data']): string => {
  const textsMap: Partial<Record<ConfirmModalMode, string>> = {
    [ConfirmModalMode.PROBLEM]: t.deleteProblemModalTitle,
    [ConfirmModalMode.SECTION]: t.deleteSubchapterModalTitle,
  }
  return textsMap[mode] || ''
}

export const getQuestion = (mode: ConfirmModalMode, t: ILocalization['data']): string => {
  const textsMap: Partial<Record<ConfirmModalMode, string>> = {
    [ConfirmModalMode.PROBLEM]: t.deleteProblemQuestion,
    [ConfirmModalMode.SECTION]: t.deleteSectionQuestion,
  }
  return textsMap[mode] || ''
}

export const getWarning = (mode: ConfirmModalMode, t: ILocalization['data']): string => {

  const textsMap: Partial<Record<ConfirmModalMode, string>> = {
    [ConfirmModalMode.SECTION]: t.deleteSectionNote,
  }
  return textsMap[mode] || ''
}
