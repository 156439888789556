import React from 'react'
import { TypesOfParams } from '../../../../helpers/enums'
import useText from '../../../../i18n/hook'
import { Listbox } from '@magmamath/ui'

const ExamOption = () => {
  const t = useText()
  const examOption = { value: TypesOfParams.EXAM, name: t.examMode, type: TypesOfParams.EXAM }
  return <Listbox.Option value={examOption}>{examOption.name}</Listbox.Option>
}

export default ExamOption
