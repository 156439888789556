import { BUTTON_COLOR, COLORS, SIZE } from '@magmamath/ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import { TaskType } from '../../../../helpers/enums'
import useText from '../../../../i18n/hook'
import {
  IAnswerInfo,
  IMultipleSolution,
  IStudentSolutionStats,
} from '../../../../api/types.solutions'
import { useOverflowDetection } from '../../../../lib/hooks/useOverflowDetection'
import { changeSolutionCorrectness } from '../../../../redux/modules/correctness.module'
import { createDefaultAnswer } from '../../../../redux/modules/defaultAnswer.module'
import EyeIcon from '../../../../ui/icons/EyeIcon/EyeIcon'
import UITooltip from '../../../../ui/tooltips/UITooltip/Tooltip'
import { getHeaderColor } from '../../SmallSolutionCard/helpers'
import SolutionCardActions from '../../SolutionCardActions/SolutionCardActions'
import styles from '../SolutionCard.module.scss'
import StudentsNavigation from '../StudentsNavigation/StudentsNavigation'

type SolutionCardHeaderProps = {
  solution: IMultipleSolution | IStudentSolutionStats
  studentId: string
  exerciseId: string
  problemId: string
  subProblemId?: string
  navigationLinks?: Map<string, { next?: string; prev?: string }>
  currentAnswerInfo: IAnswerInfo | null
  correctAnswer: string[]
  headerColor?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
  title: string
  isUserDeleted: boolean
}

const SolutionCardHeader = ({
  solution,
  studentId,
  exerciseId,
  problemId,
  subProblemId,
  navigationLinks,
  currentAnswerInfo,
  correctAnswer,
  headerColor,
  title,
  isUserDeleted,
}: SolutionCardHeaderProps) => {
  const dispatch = useDispatch()
  const t = useText()
  const nameOverflow = useOverflowDetection()

  const { prev, next } = (studentId && navigationLinks?.get(studentId)) || {
    prev: undefined,
    next: undefined,
  }

  const isCurrentAnswerCorrect = !!currentAnswerInfo?.correct
  const currentAttemptSolutionId = currentAnswerInfo?._id

  const changeCorrectnessOfSolution = () => {
    if (!currentAttemptSolutionId) {
      createDefaultAnswer(
        exerciseId,
        studentId,
        subProblemId ? TaskType.SubProblem : TaskType.Problem,
        subProblemId || problemId,
        correctAnswer
      )(dispatch)
      return
    }
    changeSolutionCorrectness(
      {
        problemId: subProblemId || problemId,
        correct: !isCurrentAnswerCorrect,
        exerciseId,
      },
      currentAttemptSolutionId
    )(dispatch)
  }

  return (
    <header
      className={styles.Header}
      style={{ backgroundColor: getHeaderColor({ headerColor, currentAnswerInfo, solution }) }}
    >
      <div className={styles.Attempts}>
        {solution.attempt !== 0 && (
          <UITooltip
            title={`${solution.attempt} ${solution.attempt > 1 ? t.attemptsTxt : t.attemptTxt}`}
            placement='top'
            className={styles.Tooltip}
          >
            <p>{solution.attempt}</p>
          </UITooltip>
        )}
        {solution.watched && (
          <UITooltip title={t.watched} placement='top' className={styles.Tooltip}>
            <div>
              <EyeIcon color={COLORS.NEUTRAL_1} size={20} />
            </div>
          </UITooltip>
        )}
      </div>
      <UITooltip title={nameOverflow.isOverflowing ? title : null} placement='top'>
        <div className={styles.StudentName}>
          {navigationLinks ? (
            <StudentsNavigation
              title={title}
              nextLink={next}
              previousLink={prev}
              ref={nameOverflow.overflowRef}
            />
          ) : (
            <p className={styles.Title} ref={nameOverflow.overflowRef}>
              {title}
            </p>
          )}
        </div>
      </UITooltip>
      <div className={styles.Actions}>
        <SolutionCardActions
          isCorrect={isCurrentAnswerCorrect}
          onCorrectnessChange={changeCorrectnessOfSolution}
          size={SIZE.MEDIUM}
          exerciseId={exerciseId}
          problemId={problemId}
          subProblemId={subProblemId}
          pinSolutionId={currentAttemptSolutionId}
          isPinVisible={!!currentAttemptSolutionId}
          isCorrectnessVisible={!headerColor}
          isUserDeleted={isUserDeleted}
        />
      </div>
    </header>
  )
}

export default SolutionCardHeader
