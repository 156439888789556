import React, { useEffect } from 'react'
import { Modal } from '@mui/material'
import { useDispatch } from 'react-redux'

import './LanguageSettingsModal.scss'

import { ReactComponent as SpeakerIcon } from '../../../assets/img/speaker.svg'
import { ReactComponent as TranslationIcon } from '../../../assets/img/translation.svg'
import { ReactComponent as ExitIcon } from '../../../assets/img/exit.svg'

import {
  ILanguageSettings,
  ILanguageSettingsActions,
} from '../../../redux/modules/languageSettings.module'
import { LanguageSettingsModalBox } from '../../basic/Boxes/LanguageSettingsModalBox/LanguageSettingsModalBox'
import IOSSwitch from '../../basic/Switches/IOSSwitch/IOSSwitch'
import TextSizeSlider from '../../basic/Sliders/TextSizeSlider/TextSizeSlider'
import { ModalType } from '../../../helpers/enums'
import { closeLanguageSettingsModal, IModalActions } from '../../../redux/modules/modal.module'
import { findRoundedNumberFromArray } from '../../../helpers/math.helpers'
import { ILocalization } from "../../../config/languages.config";

const NONE_LANGUAGE = 'None'

export interface ILanguageSettingsModal {
  modal: any
  localization: ILocalization
  modalActions: IModalActions
  languageSettings: ILanguageSettings
  languageSettingsActions: ILanguageSettingsActions
}
const LanguageSettingsModal: React.FC<ILanguageSettingsModal> = ({
  modal,
  localization,
  modalActions,
  languageSettings,
  languageSettingsActions,
}) => {
  const dispatch = useDispatch()
  const opened: boolean = modal.data.modalType === ModalType.LanguageSettingsModal
  const { readingTxt, textSizeTxt, translationLanguageTxt, selectLanguageTxt, saveTxt } =
    localization.data
  const studentData = modal!.data?.settings?.student
  const studentSettings = studentData?.setting

  useEffect(() => {
    if (
      !studentSettings ||
      !Object.keys(studentSettings).length ||
      Object.keys(languageSettings.data).length
    ) {
      return
    }

    const { translationLanguage, textSize, textToSpeech } = studentSettings
    const roundedNumber = findRoundedNumberFromArray(textSize || null)
    languageSettingsActions.setStudentSettings(
      translationLanguage || null,
      roundedNumber,
      !!textToSpeech
    )
  }, [studentSettings])

  const submitChanges = () => {
    const { language, textSize, soundState } = languageSettings.data
    languageSettingsActions.pushStudentSettings(
      language,
      textSize,
      soundState,
      studentData?._id,
      localization,
      modal.data?.classId
    )
    modalActions.closeLanguageSettingsModal()
  }

  const handleClose = () => {
    dispatch(closeLanguageSettingsModal())
    languageSettingsActions.clearAllSettings()
  }

  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <LanguageSettingsModalBox>
        <div className='language-settings-modal-container'>
          <div className='header-container'>
            <span className='student-name'>
              {studentData?.firstName} {studentData?.lastName}
            </span>
            <div className='exit-settings-modal' onClick={handleClose}>
              <ExitIcon />
            </div>
          </div>
          <div className='settings-container'>
            <div className='settings-part'>
              <span className='settings-part-title'>{readingTxt}</span>
              <div className='settings-part-container'>
                <div className='setting-icon'>
                  <SpeakerIcon />
                </div>
                <IOSSwitch
                  checked={languageSettings.data?.soundState}
                  handleChange={languageSettingsActions.changeSoundState}
                />
              </div>
            </div>
            <div className='settings-part'>
              <span className='settings-part-title'>{textSizeTxt}</span>
              <div className='settings-part-container text-size'>
                <TextSizeSlider
                  value={languageSettings.data?.textSize}
                  handleChange={languageSettingsActions.changeTextSize}
                />
              </div>
              <div className='text-size-icons'>
                <span className='text-icon min'>A</span>
                <span className='text-icon max'>A</span>
              </div>
            </div>
            <div className='settings-part'>
              <span className='settings-part-title'>{translationLanguageTxt}</span>
              <div className='settings-part-container'>
                <div className='setting-icon translation'>
                  <TranslationIcon />
                </div>
                <span
                  className='translation-language-text'
                  onClick={() =>
                    modalActions!.openTranslationLanguageSelectorModal(modal.data?.classId)
                  }
                >
                  {languageSettings.data?.language &&
                  languageSettings.data?.language !== NONE_LANGUAGE
                    ? languageSettings.data.language
                    : selectLanguageTxt}
                </span>
              </div>
            </div>
          </div>
          <div className='submit-button-container'>
            <button className='submit-button' onClick={submitChanges}>
              {saveTxt}
            </button>
          </div>
        </div>
      </LanguageSettingsModalBox>
    </Modal>
  )
}

export default LanguageSettingsModal
