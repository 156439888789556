import React from 'react'
import { PATHS } from '../../../config/pathnames.config'
import SideBarBottomLinkContainer from './SideBarBottomLink/SideBarBottomLinkContainer/SideBarBottomLinkContainer'
import { Link, matchPath } from 'react-router-dom'
import useText from '../../../i18n/hook'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import styles from './SideBarBottomLinks.module.scss'
import clsx from 'clsx'
import LinkHighlighter from '../../../ui/higlihters/LinkHighlighter/LinkHighlighter'
import AccountAvatar from '../../../ui/avatars/AccountAvatar/AccountAvatar'
import LibraryIcon from '../../../ui/icons/LibraryIcon/LibraryIcon'
import SchoolIcon from '../../../ui/icons/SchoolIcon/SchoolIcon'

const SideBarBottomLinks = () => {
  const t = useText()
  const history = useHistory()
  const isClassesPage = !!matchPath(history.location.pathname, PATHS.CLASSES)
  const isLibraryPage = history.location.pathname.includes('content')
  const isProfilePage = history.location.pathname.includes('profile')
  const me = useSelector((state: RootState) => state.auth.data.me)
  const linkData = [
    {
      path: PATHS.ACCOUNT,
      title: me?.firstName?.toUpperCase(),
      isActive: isProfilePage,
      classes: {
        container: styles.SideBarBottomLinkContainer,
        title: styles.SideBarBottomLinkTitle,
        hoveredTitle: styles.HoveredSideBarBottomLinkTitle,
      },
      icon: AccountAvatar,
    },
    {
      path: PATHS.CLASSES,
      title: t.classesTxt,
      isActive: isClassesPage,
      classes: {
        title: styles.SideBarClassesLinkTitle,
      },
      icon: SchoolIcon,
    },
    {
      path: PATHS.CONTENT.BOOKSERIES,
      title: t.contentMaterialTxt,
      isActive: isLibraryPage,
      icon: LibraryIcon,
    },
  ]
  return (
    <>
      {linkData.map((link, index) => (
        <Link key={index} className={styles.Link} to={link.path}>
          <SideBarBottomLinkContainer {...link} />
        </Link>
      ))}
      <LinkHighlighter
        className={clsx({
          [styles.ClassesLinkWrapper]: isClassesPage,
          [styles.LibraryLinkWrapper]: isLibraryPage,
          [styles.ProfileLinkWrapper]: isProfilePage,
        })}
      />
    </>
  )
}
export default SideBarBottomLinks
