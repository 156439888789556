import clsx from 'clsx'
import styles from '../ClassroomShareModal.module.scss'
import React from 'react'

type TeacherClassroomProps = {
  id: string
  isSelected: boolean
  name: string
  onClassroomItemClick: (id: string) => void
}
const TeacherClassroom = ({
  id,
  isSelected,
  name,
  onClassroomItemClick,
}: TeacherClassroomProps) => {
  return (
    <li
      className={clsx(styles.ListItem, {
        [styles.Selected]: isSelected,
      })}
      onClick={() => onClassroomItemClick(id)}
    >
      {name}
    </li>
  )
}
export default TeacherClassroom
