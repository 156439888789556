import Book from '../Book/Book'
import React from 'react'
import { useUnit } from 'effector-react'
import { $foundBooks } from '../model/foundBooks'
import useText from '../../../i18n/hook'
import styles from './SearchResults.module.scss'

const SearchResults = () => {
  const t = useText()
  const books = useUnit($foundBooks)

  return (
    <>
      {books?.size ? (
        Array.from(books).map(([key, book]) => (
          <Book title={book.name} id={key} key={key} animated={false} />
        ))
      ) : (
        <div className={styles.NoResults}>
          <p>{t.noResultsFound}</p>
          <p>{t.messageUs}</p>
        </div>
      )}
    </>
  )
}

export default SearchResults
