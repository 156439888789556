import { Operator, OperatorType } from '../model/types'
import { QUILL_INPUT_COMMANDS } from '../mathInput/commands'
import { CurrentLanguage } from '../../../helpers/enums'
import FractionIcon from '../../../ui/icons/FractionIcon/FractionIcon'
import { FC } from 'react'
import SqrtIcon from '../../../ui/icons/SqrtIcon/SqrtIcon'
import PowIcon from '../../../ui/icons/PowIcon/PowIcon'

export type OperatorDescriptor = {
  label: string | FC
  renderStraight?: boolean
} & Operator

type SymbolSettings = {
  renderStraight?: boolean
}

const createSymbol = (
  value: string,
  { renderStraight }: SymbolSettings = {}
): OperatorDescriptor => {
  return { type: OperatorType.SYMBOL, label: value, value, renderStraight }
}

const createStraightSymbol = (value: string) => createSymbol(value, { renderStraight: true })

export const COMMON_OPERATORS: OperatorDescriptor[] = [
  createSymbol('+'),
  createSymbol('-'),
  createSymbol('×'),
  createSymbol('.'),
  createSymbol('÷'),
  createStraightSymbol('%'),
  { type: OperatorType.KATEX, label: FractionIcon, value: QUILL_INPUT_COMMANDS.FRAC },
  { type: OperatorType.KATEX, label: PowIcon, value: QUILL_INPUT_COMMANDS.POWER },
  { type: OperatorType.KATEX, label: SqrtIcon, value: '\\sqrt' },
]

export const getNumericOperators = (lang: string) => {
  return [
    createSymbol('-'),
    createStraightSymbol(lang === CurrentLanguage.SE ? ',' : '.'),
    createStraightSymbol('%'),
    { type: OperatorType.KATEX, label: FractionIcon, value: QUILL_INPUT_COMMANDS.FRAC },
    { type: OperatorType.KATEX, label: PowIcon, value: QUILL_INPUT_COMMANDS.POWER },
  ]
}

export const getAlgebraOperators = (lang: string) => {
  return [
    createSymbol('-'),
    createSymbol(lang === CurrentLanguage.SE ? ',' : '.'),
    createSymbol('='),
    { type: OperatorType.KATEX, label: FractionIcon, value: QUILL_INPUT_COMMANDS.FRAC },
    { type: OperatorType.KATEX, label: PowIcon, value: QUILL_INPUT_COMMANDS.POWER },
    { type: OperatorType.KATEX, label: SqrtIcon, value: '\\sqrt' },
    createStraightSymbol('x'),
    createStraightSymbol('y'),
    createStraightSymbol('z'),
  ]
}
