import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import * as React from 'react'
import Profile, { IProfileProps } from 'views/Account/Profile.view'
import GetStarted, { IGetStartedProps } from 'views/GetStartedPage/GetStarted.view'

export const AccountRoutes = [
  {
    exact: true,
    path: PATHS.START,
    render: (props: IGetStartedProps) => {
      const startProps: IGetStartedProps = {
        history: props.history,
        localization: props.localization,
        localizationActions: props.localizationActions,
        location: props.location,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
      }
      return <GetStarted {...startProps} />
    },
  },
  {
    exact: true,
    path: PATHS.ACCOUNT,
    render: (props: IProfileProps) => {
      const profileProps: IProfileProps = {
        auth: props.auth,
        history: props.history,
        localization: props.localization,
        location: props.location,
        match: props.match,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
        modalActions: props.modalActions,
        authActions: props.authActions,
        autologinActions: props.autologinActions,
        teacherSchools: props.teacherSchools,
      }
      return <Profile {...profileProps} />
    },
  },
]
