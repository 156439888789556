import { ButtonOptions } from './Button'

export const getColorClassName = (color: ButtonOptions['color']) => {
  const map = {
    blue: 'ui-btn-blue',
    green: 'ui-btn-green',
    red: 'ui-btn-red',
    yellow: 'ui-btn-yellow',
    purple: 'ui-btn-purple',
    gradient: 'ui-btn-gradient',
    grey: 'ui-btn-grey',
  }

  if (!color || !map[color]) return 'ui-btn-green'

  return map[color]
}

export const getVariantClassName = (variant: ButtonOptions['variant']) => {
  const map = {
    primary: 'ui-btn-primary',
    secondary: 'ui-btn-secondary',
    tertiary: 'ui-btn-tertiary',
    cancel: 'ui-btn-cancel',
    choice: 'ui-btn-choice',
    basic: 'ui-btn-basic',
  }

  if (!variant || !map[variant]) return 'ui-btn-primary'

  return map[variant]
}

export const getSizeClassName = (size: ButtonOptions['size']) => {
  const map = {
    large: 'ui-btn-large',
    medium: 'ui-btn-medium',
    small: 'ui-btn-small',
    answer: 'ui-btn-answer',
    pill: 'ui-btn-pill',
  }

  if (!size || !map[size]) return 'ui-btn-medium'

  return map[size]
}
