import React from 'react'
import clsx from 'clsx'

import styles from './SolutionResult.module.scss'

import { getSolutionsUrl } from '../../../../../../helpers/exercise.helpers'
import { getDisplayedAnswer } from '../../../../helpers'
import useText from '../../../../../../i18n/hook'

type SolutionResultProps = {
  studentName: string
  drawingImageName?: string
  answer: [string]
  isCorrect: boolean
}

const SolutionResult = ({
  studentName,
  drawingImageName,
  answer,
  isCorrect,
}: SolutionResultProps) => {
  const t = useText()

  return (
    <div className={styles.SolutionResultContainer}>
      <span className={clsx(styles.StudentName, { [styles.Success]: isCorrect })}>
        {studentName}
      </span>
      <div className={styles.SolutionImageContainer}>
        {drawingImageName ? (
          <img src={getSolutionsUrl(drawingImageName)} alt='solution-img' />
        ) : (
          <span className={styles.NoImageText}>{t.noSolutionSubmitted}</span>
        )}
      </div>
      <div className={styles.StudentAnswer}>
        <span>{t.answerTxt}:</span>
        <span className={styles.Answer}>{getDisplayedAnswer(answer)}</span>
      </div>
    </div>
  )
}

export default SolutionResult
