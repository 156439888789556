import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowBackIcon: React.FC<SvgIconProps> = ({ size = 18, color }) => {
  return (
    <svg
      width={size * 1.22}
      height={size}
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='arrow_back 1' clipPath='url(#clip0_5182_52328)'>
        <path
          d='M20.4824 10.1248C21.1108 10.1248 21.6203 9.62109 21.6203 8.99979C21.6203 8.37849 21.1108 7.87479 20.4824 7.87479V10.1248ZM0.712199 8.20426C0.267809 8.64361 0.267809 9.35596 0.712199 9.79531L7.95396 16.9547C8.39836 17.3941 9.11882 17.3941 9.56322 16.9547C10.0076 16.5154 10.0076 15.8031 9.56322 15.3638L3.12611 8.99979L9.56322 2.63583C10.0076 2.19649 10.0076 1.48418 9.56322 1.04484C9.11882 0.605497 8.39836 0.605497 7.95396 1.04484L0.712199 8.20426ZM20.4824 7.87479H1.51684V10.1248H20.4824V7.87479Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default ArrowBackIcon
