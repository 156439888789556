import { resetSelectedProblems } from 'features/bookshelf/Book/model/selectedProblems'
import { resetClasses } from 'features/modals/ClassroomModal/model/classes'
import { resetErrorMessages } from './errorMessages/model/errorMessages'
import { resetExerciseStatus } from './exerciseStatus/model/exerciseStatus'
import { resetExerciseName } from './exerciseSettings/model/exerciseName'
import { resetExerciseSettings } from './exerciseSettings/model/exerciseSettings'
import { resetSearchIsActive } from '../bookshelf/model/foundBooks'

export const cleanCafStoresData = () => {
  resetExerciseName()
  resetExerciseStatus()
  resetExerciseSettings()
  resetErrorMessages()
  resetClasses()
  resetSelectedProblems()
  resetSearchIsActive()
}
