import clsx from 'clsx'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React from 'react'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import sharedStyles from '../../styles/Styles.module.scss'
import styles from './ExerciseTitle.module.scss'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'

type ExerciseTitleProps = {
  title: string
}

const ExerciseTitle = ({ title }: ExerciseTitleProps) => {
  const { isBulkModeActive } = useBulkSelectContext()
  const { overflowRef, isOverflowing } = useOverflowDetection({ parentWidthToTextLengthRatio: 5 })

  return (
    <UITooltip title={isOverflowing ? <span>{title}</span> : null} placement='top-start'>
      <div ref={overflowRef} className={styles.CardTitleContainer}>
        <h5 className={clsx(styles.CardTitle, { [sharedStyles.DisableSelect]: isBulkModeActive })}>
          {title}
        </h5>
      </div>
    </UITooltip>
  )
}

export default ExerciseTitle
