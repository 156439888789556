import React, { LegacyRef } from 'react'

import { omit, truncate } from 'lodash'

interface IChapterTitleProps {
  chapterId: string
  getChapterName: (chapterId: string) => string
  limitLettersForChapters: number
}

const ChapterTitle: React.FC<IChapterTitleProps> = React.forwardRef((props, ref) => {
  const tooltipProps = omit(props, ['chapterId', 'getChapterName', 'limitLettersForChapters'])
  return (
    <div
      {...tooltipProps}
      ref={ref as LegacyRef<HTMLDivElement> | undefined}
      className='chapter-title'
    >
      {truncate(props.getChapterName(props.chapterId), { length: props.limitLettersForChapters })}
    </div>
  )
})

export default ChapterTitle
