import React, { useRef } from 'react'
import { useStoreMap, useUnit } from 'effector-react'
import { AccordionList } from '@magmamath/ui'
import SectionsList from '../SectionsList/SectionsList'
import ChaptersList from '../ChaptersList/ChaptersList'
import styles from './TableOfContents.module.scss'
import { Bredcrumb } from '../types'
import { openSection, resetSection } from '../../model/openedBooks'
import { $chapters } from '../model/chapters'
import { OpenedSectionPath } from '../../model/types'
import { $isSearchActive } from '../../model/foundBooks'
import { $sections } from '../model/sections'
import clsx from 'clsx'
import { $exerciseSettings } from 'features/caf/exerciseSettings/model/exerciseSettings'

type TableOfContentsProps = {
  bookId: string
  currentBookPath?: OpenedSectionPath
}

const TableOfContents = ({ bookId, currentBookPath }: TableOfContentsProps) => {
  const contentRef = useRef<HTMLOListElement | null>(null)
  const sectionsList = useUnit($sections)
  const chaptersList = useUnit($chapters)
  const isSearchActive = useUnit($isSearchActive)
  const isExamMode = useStoreMap($exerciseSettings, (state) => state.isExamMode)

  const openedChapterPath = currentBookPath?.chapter
  const bookChapters = chaptersList
    .get(bookId)
    ?.find((chapter) => chapter.id === openedChapterPath?.id)
  const isSectionOpen = !!openedChapterPath
  const isChaptersListOpen = !openedChapterPath
  const chapterSections =
    isSearchActive && bookChapters
      ? sectionsList
        .get(bookChapters.id)
        ?.filter((section) => section._id === bookChapters.sectionId)
      : sectionsList.get(openedChapterPath?.id || '')

  const toggleSectionOpen = (section: Bredcrumb) => {
    section.id === currentBookPath?.section?.id
      ? resetSection({ bookId })
      : openSection({ bookId, chapter: currentBookPath?.chapter, section })
  }

  const handleClickChapter = (chapter: Bredcrumb) => {
    openSection({ bookId: bookId, chapter })
  }

  return (
    <AccordionList
      className={clsx(styles.AccordionList, {
        [styles.ExamMode]: isExamMode,
      })}
      ref={contentRef}
    >
      {isSectionOpen && (
        <SectionsList
          sections={chapterSections || []}
          onClickSection={toggleSectionOpen}
          openedSection={currentBookPath.section}
        />
      )}
      {isChaptersListOpen && <ChaptersList bookId={bookId} onClickChapter={handleClickChapter} />}
    </AccordionList>
  )
}

export default TableOfContents
