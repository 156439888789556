import { SkeletonProps } from './Skeleton'
import styles from './Skeleton.module.scss'

export const getVariantClassName = (variant: SkeletonProps['variant']) => {
  const map = {
    rectangular: styles.Rectangular,
    rounded: styles.Rounded,
    circular: styles.Circular,
  }

  if (!variant || !map[variant]) return styles.Rectangular

  return map[variant]
}
