import { Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import arrowBackGrey from 'assets/img/arrow-back-grey.svg'
import infoIcon from 'assets/img/color-stats.svg'
import downloadFile from 'assets/img/download-file.svg'
import pinWithBackground from 'assets/img/pin-filled.svg'
import pin from 'assets/img/pin-outlined.svg'
import printIcon from 'assets/img/print-gray.svg'
import NavbarMenu from 'components/NavbarContainer/Navbar.container'
import { StudentsListDropdownMenu } from 'components/basic/Dropdowns/StudentListDropdown/StudentListDropdown'
import { extremeScreenWidth } from 'config'
import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import StudentsFilter from 'features/filters/StudentsFilter/StudentsFilter'
import { Events, LocaleData, StudentsHiddenParam } from 'helpers/enums'
import { isEmptyOrUndefined } from 'helpers/general'
import { detectPageWithoutSidebar } from 'helpers/pathnameChecking.helper'
import { getItemFromLocalStorageWithParsing, isAuth } from 'helpers/storage.helper'
import useText from 'i18n/hook'
import { IMatch } from 'interfaces/match.inteface'
import { IState } from 'interfaces/state.interface'
import { IMe, IStudent } from 'api/types.users'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, Route, RouteComponentProps } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { ILocalizationActions } from 'redux/modules/localization.module'
import { ISolutionsSorting, ISolutionsSortingActions } from 'redux/modules/solutionsSorting.module'
import ClassesHeader from 'views/Classes/components/ClassesHeaderButton/ClassesHeader'
import { ILocalization } from '../../config/languages.config'
import HelpQueue from '../../features/helpQueue/HelpQueue'
import { RootState } from '../../store/store'
import PlusIconButton from '../../ui/Buttons/PlusIconButton/PlusIconButton'
import PieChartLegend from '../PieChart/PieChartWrapper/PieChartLegend/PieChartLegend'
import HideStudentsNames from '../basic/Dropdowns/HideStudentsNames/HideNames'
import './PanelHeader.component.scss'
import styles from './PanelHeader.module.scss'
import { PanelHeaderTooltip } from './PanelHeaderTooltip/PanelHeaderTooltip'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import Divider from '../../ui/dividers/Divider/Divider'
import clsx from 'clsx'
import { currentLocale } from '../../config/modules.config'
import { IExercise } from '../../api/types.assignments'
import { IClass, GetClassesResponse } from '../../api/types.classes'
import { IClassesStudents } from '../../api/types.classesStudents'
import { GetAllGroupsResponse, IGroup } from '../../api/api.groups'

export interface IPanelHeaderProps {
  location: Location
  backButton: boolean
  match?: IMatch
  localization: ILocalization
  localizationActions?: ILocalizationActions
  history: RouteComponentProps['history']
  title?: string
  backButtonTitle?: string
  className?: string
  me?: IMe
  exercise?: IState<IExercise>
  classes?: IState<GetClassesResponse>
  groups?: IState<GetAllGroupsResponse>
  isAllStudentsEmpty?: boolean
  chosenProblems?: string[]
  exerciseActions?: IExerciseActions
  solutionsSorting?: IState<ISolutionsSorting>
  solutionsSortingActions?: ISolutionsSortingActions
  classesForHeatmap?: IClass[]
  groupsForHeatmap?: IGroup[]
  studentsForHeatmap?: IStudent[]
  classesStudents?: IState<IClassesStudents>
  initialBookName?: string
  studentWasChangedHandling?: () => void
  isShowExampleCard?: boolean
  setIsShowPrintData?: (param: boolean) => void
  isShowPrintData?: boolean
  downLoadExcelFile?: () => void
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({
  location,
  backButton,
  match,
  localization,
  localizationActions,
  history,
  title,
  backButtonTitle,
  className,
  me,
  exercise,
  classes,
  groups,
  isAllStudentsEmpty,
  chosenProblems,
  exerciseActions,
  solutionsSorting,
  solutionsSortingActions,
  classesForHeatmap,
  groupsForHeatmap,
  studentsForHeatmap,
  classesStudents,
  isShowExampleCard,
  setIsShowPrintData,
  isShowPrintData,
  downLoadExcelFile,
}) => {
  const t = useText()
  const [isOpenedDropdown, setIsOpenedDropdown] = useState<boolean>(false)
  const [isTitleHidden, setIsTitleHidden] = useState<boolean>(false)
  const isCreateProblemsPath: boolean = history.location.pathname === PATHS.CONTENT.PROBLEMS
  const isExercisesPage: boolean = history.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN
  const isStudentsPage = !!matchPath(history.location.pathname, PATHS.STUDENTS.STUDENTS_MAIN)
  const isSolutionsPage: boolean = history.location.pathname.includes(PATHS.PARTS.SOLUTIONS)
  const isMultipleSolutionsPage: boolean = history.location.pathname.includes(
    PATHS.PARTS.MULTIPLE_SOLUTIONS
  )
  const isPinnedSolutionsPage: boolean =
    history.location.pathname.includes(PATHS.PARTS.PINNED) &&
    !history.location.pathname.includes(PATHS.PARTS.STUDENTS)
  const isPinnedSolutionPage: boolean =
    history.location.pathname.includes(PATHS.PARTS.PINNED) &&
    history.location.pathname.includes(PATHS.PARTS.STUDENTS)
  const isSkillsPage: boolean = history.location.pathname.includes(PATHS.PARTS.SKILLS)
  const isHeatmap: boolean = history.location.pathname.includes(PATHS.PARTS.HEATMAP)
  const isPieChartPage: boolean = history.location.pathname.includes(PATHS.PARTS.PIE_CHART)
  const isStudentProgress: boolean = history.location.pathname.includes(
    PATHS.PARTS.STUDENT_PROGRESS
  )
  const pageWithoutSidebar: boolean = detectPageWithoutSidebar(history)
  const littleScreenWidth: boolean = window.innerWidth < extremeScreenWidth
  const navBarAdditionalClass: string = className || ''
  const ref = useRef<any>(null)
  const pinnedSolutions = useSelector((state: RootState) => state.pinnedSolutions.data)
  const isStudentsNamesHidden: boolean =
    history.location.search === `${PATHS.PARAMS.HIDDEN_STUDENTS}${StudentsHiddenParam.HIDDEN}`
  const studentsHiddenParam = `${PATHS.PARAMS.HIDDEN_STUDENTS}${
    isStudentsNamesHidden ? StudentsHiddenParam.HIDDEN : StudentsHiddenParam.NOT_HIDDEN
  }`
  const isContentPage = history.location.pathname === PATHS.CONTENT.BOOKSERIES
  const isProfilePage = history.location.pathname === PATHS.ACCOUNT
  const isClassesPage = history.location.pathname === PATHS.CLASSES

  const isEditClassPage = history.location.pathname.includes(
    `${PATHS.STUDENTS.STUDENTS_MAIN}/class`
  )
  const isSHowTitleLeft =
    isExercisesPage ||
    isStudentsPage ||
    isContentPage ||
    isProfilePage ||
    isEditClassPage ||
    isClassesPage

  useEffect(() => {
    document.addEventListener(Events.CLICK, handleClickOutside, true)
    window.addEventListener(Events.RESIZE, hideTitle, true)
    return () => {
      document.removeEventListener(Events.CLICK, handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    getLocalStorageElementsAndCheckingTheirAvailability()
  }, [chosenProblems, isAllStudentsEmpty])

  const handleClickOutside = (event: MouseEvent | any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpenedDropdown(false)
    }
  }

  const hideTitle = (): void => {
    const isLoginPage: boolean = history.location.pathname === PATHS.LOGIN.LOGIN_MAIN
    const isRegisterPages: boolean = history.location.pathname.includes('register')
    setIsTitleHidden(littleScreenWidth && !(isLoginPage || isRegisterPages))
  }

  const getLocalStorageElementsAndCheckingTheirAvailability = (): boolean => {
    const isAddStudentForm: boolean = history.location.pathname === PATHS.EXERCISES.ADD_SETTINGS
    const problemsFromStorage: string = getItemFromLocalStorageWithParsing(
      localStorageKeys.problems
    )

    return isAddStudentForm
      ? !isEmptyOrUndefined(problemsFromStorage) || !!isAllStudentsEmpty
      : !isEmptyOrUndefined(chosenProblems)
  }

  const renderIcon = (): JSX.Element | null => {
    if (isStudentProgress) {
      return renderOpenMenuIcon()
    }
    return null
  }

  const renderOpenMenuIcon = (): JSX.Element => {
    const classForCaret: string = isOpenedDropdown ? 'fa-angle-up' : 'fa-angle-down'
    return (
      <i
        className={`fa ${classForCaret} dropdown-opening-icon`}
        onClick={() => {
          handleDropdownForTitle()
        }}
      />
    )
  }

  const handleDropdownForTitle = (): void => {
    setIsOpenedDropdown(!isOpenedDropdown)
  }
  const getFormTitleClasses = (): string => {
    const isStartedPage: boolean = history.location.pathname === '/start'
    const isLoginPage: boolean = history.location.pathname === PATHS.LOGIN.LOGIN_MAIN
    const isRegisterPages: boolean = history.location.pathname.includes('register')
    const isTeacherAccountPage: boolean = history.location.pathname === PATHS.ACCOUNT
    const isSolutionsClass: string = isSolutionsPage ? 'solutions-title-wrapper ' : ''
    const isTeacherAccountClass: string = isTeacherAccountPage ? 'account-title ' : ''
    const authClassSettings: string =
      !isAuth() || isStartedPage ? 'justify-content-end ' : 'justify-content-center '
    const specificWidthClass: string = !title ? '' : 'specific-width '
    const classForLoginPage: string = isRegisterPages || isLoginPage ? 'login-wrapper ' : ''
    return `title-wrapper ${classForLoginPage}${specificWidthClass}${isTeacherAccountClass}${authClassSettings}${isSolutionsClass}`
  }

  const goBackToHeatMap = () => {
    history.push({
      pathname: `/exercises/${exercise?.data?._id}/heatmap`,
      search: studentsHiddenParam,
    })
  }

  const goBackFromSolution = () => {
    const URL = match?.url

    if (!URL?.includes('solutions')) {
      return
    }

    if (URL?.includes('students')) {
      const newUrl = match?.url.replace(`students/${match?.params?.studentId}/`, '')

      history.push({
        pathname: newUrl,
        search: studentsHiddenParam,
      })
      return
    }

    goBackToHeatMap()
    return
  }

  const goBackFromMultipleSolution = () => {
    const URL = match?.url

    if (!URL?.includes('multiple-solutions')) {
      return
    }

    if (URL?.includes('problems') || URL?.includes('sub-problems')) {
      history.push({
        pathname: `/exercises/${match?.params?.exerciseId}/students/${match?.params?.studentId}/multiple-solutions${history.location.search}`,
        search: studentsHiddenParam,
      })
      return
    }

    if (URL?.includes('students')) {
      goBackToHeatMap()
      return
    }

    return
  }

  const goBackFromPinnedSolution = () => {
    const pinnedUrl = match?.url
    const result = pinnedUrl?.replace(`/students/${match?.params.studentId}`, '')
    history.push({
      pathname: result,
      search: studentsHiddenParam,
    })
  }

  const goBackFromPinnedSolutions = () => {
    const pinnedUrl = match?.url
    const result = pinnedUrl
      ?.replace('pinned-solutions', 'solutions')
      .replace(`/students/${match?.params.studentId}`, '')
    history.push({
      pathname: result,
      search: studentsHiddenParam,
    })
  }

  const goBack = async (): Promise<any> => {
    if (isSolutionsPage) {
      goBackFromSolution()
    } else if (isMultipleSolutionsPage) {
      goBackFromMultipleSolution()
    } else if (isPieChartPage) {
      goBackToHeatMap()
    } else if (isPinnedSolutionPage) {
      goBackFromPinnedSolution()
    } else if (isPinnedSolutionsPage) {
      goBackFromPinnedSolutions()
    } else {
      await history.goBack()
    }
  }

  const goToPinnedSolutions = () => {
    if (isEmpty(pinnedSolutions) && !isShowExampleCard) {
      return
    }
    const pinnedUrl = match?.url
    const result = pinnedUrl?.replace('solutions', 'pinned-solutions')
    history.push({
      pathname: result,
      search: studentsHiddenParam,
    })
  }

  const titleClasses: string = getFormTitleClasses()

  const chooseTooltipTitle = () => {
    return isSkillsPage ? localization.data.skillsInfoTxt : localization.data.statsInfoTxt
  }

  const isShowFilter = classes?.data?._embedded?.classes && groups?.data?._embedded?.groups
  const isSELocale = currentLocale.currentLang === LocaleData.SE

  return (
    <div ref={ref}>
      {!isEmpty(localization.data) && (
        <Navbar
          expand='lg'
          className={`navbar-header d-flex flex-nowrap ${navBarAdditionalClass}` as string}
        >
          {backButton && !(littleScreenWidth && !pageWithoutSidebar) && (
            <div
              className={`prev-action-button ${pageWithoutSidebar ? 'little-width' : ''}`}
              onClick={(e: MouseEvent | any) => {
                e.preventDefault()
                if (isStudentProgress) {
                  history.goBack()
                } else if (isHeatmap || isSkillsPage) {
                  history.push({
                    pathname: PATHS.EXERCISES.EXERCISES_MAIN,
                    search: history.location.search,
                  })
                } else if (isCreateProblemsPath) {
                  history.push(PATHS.CONTENT.BOOKSERIES)
                } else {
                  goBack()
                }
              }}
            >
              <img src={arrowBackGrey} alt='icon' />
              {!littleScreenWidth && <span>{backButtonTitle}</span>}
            </div>
          )}
          <NavbarMenu
            history={history}
            location={location}
            localization={localization}
            localizationActions={localizationActions}
            exercise={exercise}
            solutionsSorting={solutionsSorting}
            solutionsSortingActions={solutionsSortingActions}
            me={me}
          >
            {!isSHowTitleLeft && <div className='left-side-block' />}
            {!isTitleHidden && !isCreateProblemsPath && !isStudentProgress && !isSkillsPage && (
              <div className={`${titleClasses} ${isSHowTitleLeft && 'show-title-left'}`}>
                <div className='relative-wrapper'>
                  <h1 className='container-title'>{title && title}</h1>
                </div>
              </div>
            )}

            <div
              className={`title-wrapper-with-dropdown ${
                isCreateProblemsPath || isStudentProgress || isSkillsPage ? 'open' : ''
              }`}
            >
              {(isCreateProblemsPath || isStudentProgress || isSkillsPage) && (
                <PanelHeaderTooltip
                  title={chooseTooltipTitle()}
                  disableHoverListener={true}
                  open={!isStudentProgress && isOpenedDropdown}
                >
                  <div className='relative-title-wrapper'>
                    <div className='flex-title-wrapper'>
                      <div className='title-with-dropdown-block'>
                        <h1 className='title-with-dropdown'>{title && title}</h1>
                      </div>
                      {renderIcon()}
                      {isStudentProgress && isOpenedDropdown && (
                        <StudentsListDropdownMenu
                          className='students-progress-menu'
                          history={history}
                          classesStudents={classesStudents!}
                        />
                      )}
                    </div>
                  </div>
                </PanelHeaderTooltip>
              )}
            </div>

            <div className='right-side-block'>
              <Route
                exact={true}
                path={[
                  ...PATHS.EXERCISES.PINNED_SOLUTION,
                  ...PATHS.EXERCISES.PINNED_SOLUTIONS,
                  ...PATHS.EXERCISES.STUDENTS_SOLUTIONS,
                ]}
                render={() => (
                  <div
                    className='pinned-icon right-side-icon'
                    onClick={() => {
                      isSolutionsPage ? goToPinnedSolutions() : goBackFromPinnedSolutions()
                    }}
                  >
                    <img
                      src={isEmpty(pinnedSolutions) && !isShowExampleCard ? pin : pinWithBackground}
                      alt='pin-icon'
                    />
                  </div>
                )}
              />
              <Route
                exact={true}
                path={[...PATHS.EXERCISES.PIE_CHART]}
                render={() => (
                  <Tooltip
                    title={<PieChartLegend localization={localization} />}
                    placement='bottom'
                  >
                    <img className='info-icon right-side-icon' src={infoIcon} alt='info-icon' />
                  </Tooltip>
                )}
              />
              <Route
                exact={true}
                path={PATHS.EXERCISES.HEATMAP}
                render={() => (
                  <div className='download-icon right-side-icon'>
                    <img
                      src={downloadFile}
                      className='watch-button-img button-img'
                      onClick={() => {
                        if (downLoadExcelFile) {
                          downLoadExcelFile()
                        }
                      }}
                    />
                  </div>
                )}
              />
              {setIsShowPrintData && (
                <Route
                  exact={true}
                  path={PATHS.EXERCISES.PIE_CHART}
                  render={() => (
                    <div onClick={() => setIsShowPrintData(true)}>
                      {isShowPrintData ? (
                        <CircularProgress size={25} />
                      ) : (
                        <img className='print-icon right-side-icon' src={printIcon} />
                      )}
                    </div>
                  )}
                />
              )}
              {isExercisesPage && (
                <>
                  <HelpQueue
                    classes={{
                      root: styles.HelpQueue,
                      modal: clsx(styles.HelpQueueModalEN, {
                        [styles.HelpQueueModalSE]: isSELocale,
                      }),
                    }}
                  />
                  <Divider className={styles.VerticalDivider} />
                </>
              )}
              <Route
                exact={true}
                path={[
                  PATHS.EXERCISES.EXERCISES_MAIN,
                  PATHS.EXERCISES.HEATMAP,
                  ...PATHS.EXERCISES.STUDENTS_SOLUTIONS,
                  ...PATHS.EXERCISES.STUDENT_SOLUTIONS,
                  ...PATHS.EXERCISES.PINNED_SOLUTIONS,
                  ...PATHS.EXERCISES.PINNED_SOLUTION,
                  ...PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTION,
                  PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTIONS,
                  ...PATHS.EXERCISES.PIE_CHART,
                ]}
                render={() => <HideStudentsNames />}
              />
              <Route
                exact={true}
                path={PATHS.EXERCISES.EXERCISES_MAIN}
                render={() => isShowFilter && <StudentsFilter />}
              />
              <Route
                exact={true}
                path={PATHS.CONTENT.BOOKSERIES}
                render={() => (
                  <Button
                    classes={{
                      button: styles.MyBookBtn,
                    }}
                    As={Link}
                    to={PATHS.CONTENT.PROBLEMS}
                    size={SIZE.LARGE}
                    variant={BUTTON_VARIANT.SECONDARY}
                  >
                    {t.myBookTxt}
                  </Button>
                )}
              />
              <Route
                exact={true}
                path={[PATHS.EXERCISES.EXERCISES_MAIN, PATHS.CONTENT.BOOKSERIES]}
                render={() => (
                  <Button
                    classes={{
                      content: styles.SubmitBtnContent,
                    }}
                    size={SIZE.LARGE}
                    variant={BUTTON_VARIANT.PRIMARY}
                    color={BUTTON_COLOR.ORANGE}
                    onClick={() => {
                      setTimeout(() => {
                        history.push('/exercises/create')
                        //show the button click animation and only then redirect
                      }, 500)
                    }}
                  >
                    {localization!.data.newExerciseTxt}
                  </Button>
                )}
              />
              <Route
                exact={true}
                path={PATHS.CONTENT.PROBLEMS}
                render={() => (
                  <Link to={PATHS.CONTENT.ADD_NEW_PROBLEM}>
                    <PlusIconButton className='create-problem-btn'>
                      {t.addNewProblemTxt}
                    </PlusIconButton>
                  </Link>
                )}
              />
              {isClassesPage && <ClassesHeader />}
            </div>
          </NavbarMenu>
        </Navbar>
      )}
    </div>
  )
}

export default PanelHeader
