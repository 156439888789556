import { apiManager } from './apiManager'

export enum EntityType {
  PROBLEM = 1,
  SUB_PROBLEM,
}

export type DrawingHistoryEntity = {
  type: EntityType
  id: string
}

type CreateDrawingHistoryProps = {
  entity: DrawingHistoryEntity
  history: string
}

type CreateDrawingHistoryResponse = { _id: string }

type UpdateDrawingHistoryProps = {
  id: string
  history: string
}

type GetDrawingHistoryProps = {
  entity: DrawingHistoryEntity
  userId?: string
}

type GetDrawingHistoryResponse = {
  createdAt: string
  updatedAt: string
  entityType: EntityType
  userId: string
  history: string
  _id: string
}

type GetPayload = {
  entity_id: string
  entity_type: EntityType
  user_id?: string
}

export class drawBoardHistoryApi {
  public static readonly get = apiManager.get<GetDrawingHistoryResponse, GetDrawingHistoryProps>(
    ({ userId, entity }) => {
      const params: GetPayload = {
        entity_id: entity.id,
        entity_type: entity.type,
      }
      if (userId) params.user_id = userId
      return { url: 'drawing-history', params }
    }
  )

  public static readonly create = apiManager.post<CreateDrawingHistoryResponse, CreateDrawingHistoryProps>(
    ({ entity, history }) => ({
      url: 'drawing-history',
      body: { entityId: entity.id, entityType: entity.type, history },
    })
  )

  public static readonly update = apiManager.put<CreateDrawingHistoryResponse, UpdateDrawingHistoryProps>(
    ({ id, history }) => ({ url: `drawing-history/${id}`, body: { history } })
  )
}
