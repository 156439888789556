import React from 'react'
import { COMPONENT_COLOR } from '@magmamath/ui'
import { Bredcrumb } from '../types'
import { useStoreMap, useUnit } from 'effector-react'
import { ISection } from 'api/types.books'
import { $exerciseSettings } from 'features/caf/exerciseSettings/model/exerciseSettings'
import { $selectedProblems } from '../model/selectedProblems'
import { $problems } from '../model/problems'
import SearchSectionsListItem from '../SearchSectionsListItem/SearchSectionsListItem'

type SearchSectionsListProps = {
  sections: ISection[]
  onClickSection: (section: Bredcrumb) => void
  openedSection?: Bredcrumb
}

const SearchSectionsList = ({ sections, onClickSection, openedSection }: SearchSectionsListProps) => {
  const problems = useUnit($problems)
  const selectedProblems = useUnit($selectedProblems)
  const { isExamMode } = useStoreMap($exerciseSettings, (data) => ({
    isExamMode: data.isExamMode,
  }))

  return (
    <>
      {sections.map((section) => {
        return (
          <SearchSectionsListItem
            openedSection={openedSection}
            problems={problems}
            key={section._id}
            section={section}
            onClickSection={onClickSection}
            selectedProblems={selectedProblems}
            checkboxColor={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
          />
        )
      })}
    </>
  )
}

export default SearchSectionsList
