import React from 'react'
import { SvgIconProps } from '../types/types'

const VIEW_BOX_RATIO = 0.7857

const ArrowIcon = ({ size = 25, color, strokeWidth = '2.08337', ...restProps }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={Math.round(size * VIEW_BOX_RATIO)}
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M12.2085 8.21777L7.35681 3.15515C7.1623 2.95218 6.83786 2.95218 6.64335 3.15515L1.79167 8.21777'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ArrowIcon
