import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Modal, ModalProps } from '@mui/material'

import CloseModalIcon from '../../icons/CloseModalIcon/CloseModalIcon'
import styles from './UIModal.module.scss'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'

type UIModalProps = {
  open: boolean
  onClose: () => void
  wrapperClassName?: string
  closeIconWrapperClassName?: string
  children: React.ReactNode
} & ModalProps

const UIModal = ({
  open,
  onClose,
  wrapperClassName,
  closeIconWrapperClassName,
  children,
  ...restProps
}: UIModalProps) => (
  <Modal open={open} onClose={onClose} {...restProps}>
    <div className={clsx(styles.Wrapper, wrapperClassName)}>
      <div className={clsx(styles.CloseIconWrapper, closeIconWrapperClassName)}>
        <Button
          onClick={onClose}
          size={SIZE.MEDIUM}
          variant={BUTTON_VARIANT.TERTIARY}
          icon={<CloseModalIcon size={16} color='var(--icon-color)' />}
        />
      </div>
      {children}
    </div>
  </Modal>
)

UIModal.Header = ({
  className,
  children,
  ...restProps
}: JSX.IntrinsicElements['div'] & PropsWithChildren) => {
  return (
    <div className={clsx(styles.Header, className)} {...restProps}>
      {children}
    </div>
  )
}

UIModal.Title = ({
  className,
  children,
  ...restProps
}: JSX.IntrinsicElements['p'] & PropsWithChildren) => {
  return (
    <p className={clsx(styles.Title, className)} {...restProps}>
      {children}
    </p>
  )
}

UIModal.Content = ({
  className,
  children,
  ...restProps
}: JSX.IntrinsicElements['div'] & PropsWithChildren) => {
  return (
    <div className={clsx(styles.Content, className)} {...restProps}>
      {children}
    </div>
  )
}

UIModal.Footer = ({
  className,
  children,
  ...restProps
}: JSX.IntrinsicElements['div'] & PropsWithChildren) => {
  return (
    <div className={clsx(styles.Footer, className)} {...restProps}>
      {children}
    </div>
  )
}

export default UIModal
