import {
  $books,
  $selectedSection,
  $selectedProblem,
  deleteSectionFx,
  deleteProblemFx,
  createSectionFx,
  editSectionFx,
  resetBooks,
  setSelectedSection,
  setSelectedProblem,
} from './model'

import {
  fetchBooksFx,
} from './requests'

export class MyBookModel {
  public static readonly $books = $books
  public static readonly $selectedProblem = $selectedProblem
  public static readonly $selectedSection = $selectedSection
  public static readonly resetBooks = resetBooks
  public static readonly setSelectedSection = setSelectedSection
  public static readonly setSelectedProblem = setSelectedProblem
  public static readonly deleteSectionFx = deleteSectionFx
  public static readonly deleteProblemFx = deleteProblemFx
  public static readonly createSectionFx = createSectionFx
  public static readonly fetchBooksFx = fetchBooksFx
  public static readonly editSectionFx = editSectionFx
}