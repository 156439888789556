import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { getColorClassName, getSizeClassName, getVariantClassName } from './helpers'

import './Button.scss'

export interface ButtonOptions {
  /**
   * The style variant to use.
   * @default 'primary'
   */
  variant?: 'primary' | 'secondary' | 'tertiary' | 'cancel' | 'choice' | 'basic'
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: 'large' | 'medium' | 'small' | 'answer' | 'pill'
  /**
   * The color of the component.
   * Does not make impact on 'tertiary' & 'cancel' & 'choice' variants.
   * @default 'green'
   */
  color?: 'blue' | 'green' | 'red' | 'yellow' | 'purple' | 'gradient' | 'grey'
  /**
   * Content to display inside a circle displayed in top-right button corner.
   * Has limited size depending on button size.
   */
  badge?: React.ReactNode
}

const DEFAULT_OPTIONS: ButtonOptions = {
  variant: 'primary',
  size: 'medium',
  color: 'green',
}

type Ref = HTMLButtonElement

export type ButtonProps = JSX.IntrinsicElements['button'] & ButtonOptions

const UIButton = forwardRef<Ref, ButtonProps>(
  (
    {
      variant = DEFAULT_OPTIONS.variant,
      size = DEFAULT_OPTIONS.size,
      color = DEFAULT_OPTIONS.color,
      badge,
      className,
      children,
      ...rest
    },
    ref
  ) => {
    const isSkipColorClassVariant = ['tertiary', 'cancel', 'choice'].includes(`${variant}`)
    const colorClassName = isSkipColorClassVariant ? '' : getColorClassName(color)
    const mergedClassNames = clsx(
      'ui-btn',
      getVariantClassName(variant),
      getSizeClassName(size),
      colorClassName,
      className
    )

    return (
      <button ref={ref} className={mergedClassNames} {...rest}>
        {children}

        {!!badge && <div className='ui-btn-badge'>{badge}</div>}
      </button>
    )
  }
)

export default UIButton
