import React from 'react'
import { SvgIconProps } from '../types/types'

const AddIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.32031 11.9993C6.32031 12.3749 6.62476 12.6793 7.00031 12.6793C7.37587 12.6793 7.68031 12.3749 7.68031 11.9993V7.67934H12.0003C12.3759 7.67934 12.6803 7.37489 12.6803 6.99934C12.6803 6.62378 12.3759 6.31934 12.0003 6.31934H7.68031V1.99934C7.68031 1.62378 7.37587 1.31934 7.00031 1.31934C6.62476 1.31934 6.32031 1.62378 6.32031 1.99934V6.31934H2.00031C1.62476 6.31934 1.32031 6.62378 1.32031 6.99934C1.32031 7.37489 1.62476 7.67934 2.00031 7.67934H6.32031V11.9993Z'
        fill={color}
      />
    </svg>
  )
}

export default AddIcon
