import React from 'react'

import './ModalAutosuggestClearButton.scss'

import { ReactComponent as CloseIcon } from '../../../../assets/img/exit.svg'
interface IModalAutosuggestClearButtonProps {
  onClick: () => void
}

const ModalAutosuggestClearButton: React.FC<IModalAutosuggestClearButtonProps> = ({ onClick }) => {
  return (
    <div className='modal-autosuggest-clear-search-button' onClick={onClick}>
      <CloseIcon />
    </div>
  )
}

export default ModalAutosuggestClearButton
