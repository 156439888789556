import { apiManager } from './apiManager'
import { GetTreeChildrenResponse, GetTreeResponse, GetTreeSkillsResponse } from './types.skills'

export class skillsApi {
  public static readonly getTree = apiManager.get<GetTreeResponse, string>(
    (treeId) => `tree-nodes/${treeId}/tree`
  )

  public static readonly getTreeChildren = apiManager.get<GetTreeChildrenResponse, string>(
    (treeId) => `tree-nodes/${treeId}/children`
  )

  public static readonly getTreeSkills = apiManager.get<GetTreeSkillsResponse, string>(
    (treeId) => `tree-nodes/${treeId}/tree/skills`
  )
}
