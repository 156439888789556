import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

// CollectionIds constants
export enum COLLECTION {
  COLLECTION_CLASSES = 'COLLECTION_CLASSES',
  COLLECTION_GROUPS = 'COLLECTION_GROUPS',
  COLLECTION_STUDENTS = 'COLLECTION_STUDENTS',
  CLEAR_ALL = 'CLEAR_ALL',
}

// CollectionIds reducer
const initialState = {
  data: {
    classesIds: [],
    groupsIds: [],
    studentsIds: [],
  },
  error: null,
  loading: false,
}

export function CollectionIdReducer(state: any = initialState, action: IAction<COLLECTION>) {
  switch (action.type) {
    case COLLECTION.COLLECTION_CLASSES:
      return {
        data: { ...state.data, classesIds: action.payload },
        error: false,
        loading: false,
      }
    case COLLECTION.COLLECTION_GROUPS:
      return {
        data: { ...state.data, groupsIds: action.payload },
        error: false,
        loading: false,
      }
    case COLLECTION.COLLECTION_STUDENTS:
      return {
        data: { ...state.data, studentsIds: action.payload },
        error: false,
        loading: false,
      }
    case COLLECTION.CLEAR_ALL:
      return {
        data: { classesIds: [], groupsIds: [], studentsIds: [] },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export function changeStudentsForExercises(idsArray: string[]) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: idsArray,
      type: COLLECTION.COLLECTION_STUDENTS,
    })
  }
}

export function changeClassesForExercises(idsArray: string[]) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: idsArray,
      type: COLLECTION.COLLECTION_CLASSES,
    })
  }
}

export function changeGroupsForExercises(idsArray: string[]) {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      payload: idsArray,
      type: COLLECTION.COLLECTION_GROUPS,
    })
  }
}

export function clearAllEntityForExercises() {
  return (dispatch: IDispatch<any>) => {
    dispatch({
      type: COLLECTION.CLEAR_ALL,
    })
  }
}

export interface ICollectionIdActions {
  changeStudentsForExercises: (idsArray: string[]) => void
  changeClassesForExercises: (idsArray: string[]) => void
  changeGroupsForExercises: (idsArray: string[]) => void
  clearAllEntityForExercises: () => void
}

export interface ICollectionId {
  studentsIds: string[]
  data: {
    classesIds: string[]
    groupsIds: string[]
    studentsIds: string[]
  }
}
