import { COLORS, Input } from '@magmamath/ui'
import clsx from 'clsx'
import { ErrorMessagesFields } from 'features/caf/errorMessages/types'
import ErrorMessageText from 'features/caf/errorMessages/ui/ErrorMessageText'
import useText from 'i18n/hook'
import React, { useEffect, useRef } from 'react'
import SearchIcon from 'ui/icons/SearchIcon/AccountIcon'
import styles from './ClassroomSelectorHeader.module.scss'
import { IClass } from '../../../../../api/types.classes'

type ClassroomSelectorHeaderProps = {
  isSearchVisible: boolean
  isSearchAvailable: boolean
  availableClasses: IClass[]
  searchValue: string
  onSearchVisibilityChange: (newOpenState?: boolean) => void
  onSearchValueChange: (newValue: string) => void
}

const ClassroomSelectorHeader = ({
  availableClasses,
  isSearchAvailable,
  isSearchVisible,
  searchValue,
  onSearchValueChange,
  onSearchVisibilityChange,
}: ClassroomSelectorHeaderProps) => {
  const t = useText()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current && isSearchVisible) {
      inputRef.current.focus()
    }
  }, [isSearchVisible])

  return (
    <div className={clsx(styles.Header, { [styles.WithSearch]: isSearchVisible })}>
      <h5 className={styles.Heading}>
        {t.classesTxt}
        {!!availableClasses.length && isSearchAvailable && (
          <SearchIcon
            className={styles.SearchIcon}
            size={16}
            color={COLORS.NEUTRAL_9}
            onClick={() => onSearchVisibilityChange()}
          />
        )}
        <ErrorMessageText field={ErrorMessagesFields.PARTICIPANTS} />
      </h5>
      {isSearchAvailable && (
        <Input
          type='search'
          onChange={(e) => onSearchValueChange(e.target.value)}
          placeholder={t.searchTxt}
          value={searchValue}
          classes={{ InputControl: styles.Search }}
          ref={inputRef}
        />
      )}
    </div>
  )
}

export default ClassroomSelectorHeader
