import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import 'dayjs/locale/sv'
import 'dayjs/locale/en'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker as DatePickerMUI } from '@mui/x-date-pickers/DesktopDatePicker'
import useText from 'i18n/hook'
import { currentLocale } from 'config/modules.config'
import DatePickerInput from 'components/basic/Inputs/DatePickerInput/DatePickerInput'
import ArrowRightIcon from 'ui/icons/ArrowRightIcon/ArrowRightIcon'
import ArrowLeftDatePickerIcon from 'ui/icons/ArrowLeftDatePickerIcon/ArrowLeftDatePickerIcon'
import { DateFormats, DateLocales, LocaleData } from 'helpers/enums'

import { CustomDateAdapter } from './helpers/CustomDayjsAdapter'
import datePickerSxStyles from './DatePickerSxStyles'
import { COLORS } from '@magmamath/ui'
import ArrowUpSelectIcon from '../../../ui/icons/ArrowUpSelectIcon/ArrowUpSelectIcon'
import ArrowDownSelectIcon from '../../../ui/icons/ArrowDownSelectIcon/ArrowDownSelectIcon'
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

type DateFormatDescriptor = {
  dateFormat: DateFormats
  locale: DateLocales
}
const LANG_MAP: Record<LocaleData, DateFormatDescriptor> = {
  [LocaleData.SE]: {
    dateFormat: DateFormats.DAY_MONTH,
    locale: DateLocales.SV,
  },
  [LocaleData.US]: {
    dateFormat: DateFormats.DAY_MONTH_US,
    locale: DateLocales.US,
  },
  [LocaleData.GB]: {
    dateFormat: DateFormats.DAY_MONTH_US,
    locale: DateLocales.US,
  },
}

const getDateAttributes = (currentLang: string) => {
  return LANG_MAP[currentLang] || LANG_MAP[LocaleData.SE]
}

interface DatePickerProps {
  onChangeDate: (newValue: Dayjs) => void
  date: Dayjs
  minDate?: Dayjs
}

const DatePicker: React.FC<DatePickerProps> = ({ onChangeDate, date, minDate }) => {
  const t = useText()
  const [isOpen, setOpen] = useState(false)
  const { dateFormat, locale } = getDateAttributes(currentLocale.currentLang)
  const ArrowIcon = isOpen ? ArrowUpSelectIcon : ArrowDownSelectIcon
  const getDateValue = (date: Dayjs): string | null => {
    if (date.isToday()) return t.today
    if (date.isTomorrow()) return t.tomorrow
    return null
  }

  return (
    <>
      <LocalizationProvider dateAdapter={CustomDateAdapter} adapterLocale={locale}>
        <DatePickerMUI
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={onChangeDate}
          value={date}
          disablePast={true}
          minDate={minDate}
          inputFormat={dateFormat}
          PaperProps={{
            sx: datePickerSxStyles.paperSx,
          }}
          PopperProps={{
            sx: datePickerSxStyles.popperSx,
          }}
          components={{
            OpenPickerIcon: () => <ArrowIcon color={COLORS.NEUTRAL_8} />,
            RightArrowIcon: () => <ArrowRightIcon color={COLORS.NEUTRAL_10} />,
            LeftArrowIcon: () => <ArrowLeftDatePickerIcon color={COLORS.NEUTRAL_10} />,
          }}
          renderInput={(params) => {
            const value = getDateValue(date)
            const textFieldParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                placeholder: t.nowTxt,
                disabled: true,
                ...(value && { value }),
              },
            }
            return <DatePickerInput {...textFieldParams} />
          }}
          views={['day']}
        />
      </LocalizationProvider>
    </>
  )
}

export default DatePicker
