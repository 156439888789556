import React, { PropsWithChildren } from 'react'
import styles from './ProblemDescriptionBlock.module.scss'
import UploadIcon from '../../../ui/icons/UploadIcon/UploadIcon'
import TrashIcon from '../../../ui/icons/TrashIcon/TrashIcon'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import useText from '../../../i18n/hook'
import { COLORS } from '@magmamath/ui'

type UploadImageButtonProps = {
  variant: 'upload' | 'reset'
  onUploadPress?: () => void
  onResetPress?: () => void
}

type UploadImageProtoProps = {
  onClick?: () => void
  label: string
}

const UploadImageButtonProto = ({
  onClick,
  label,
  children,
}: PropsWithChildren<UploadImageProtoProps>) => {
  return (
    <UIButton className={styles.ImageButton} onClick={onClick}>
      <div className={styles.ImageButtonContainer}>
        <div className={styles.ImageButtonTitle}>{label}</div>
        {children}
      </div>
    </UIButton>
  )
}

const UploadImageButton = ({ variant, onUploadPress, onResetPress }: UploadImageButtonProps) => {
  const t = useText()
  if (variant === 'upload') {
    return (
      <UploadImageButtonProto label={t.addImage} onClick={onUploadPress}>
        <UploadIcon size={21} color={COLORS.NEUTRAL_8} />
      </UploadImageButtonProto>
    )
  }

  return (
    <UploadImageButtonProto label={t.removeImage} onClick={onResetPress}>
      <TrashIcon size={15} color={COLORS.NEUTRAL_8} />
    </UploadImageButtonProto>
  )
}

export default UploadImageButton
