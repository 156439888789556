import React from 'react'
import { SvgIconProps } from '../types/types'

const OptionIcon: React.FC<SvgIconProps> = ({ size = 25, color, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.93413 3.29647C8.8444 3.29647 9.58237 2.55853 9.58237 1.64823C9.58237 0.73794 8.8444 0 7.93413 0C7.02384 0 6.2859 0.73794 6.2859 1.64823C6.2859 2.55853 7.02384 3.29647 7.93413 3.29647Z'
        fill={color}
      />
      <path
        d='M7.93412 9.58251C8.84439 9.58251 9.58236 8.84454 9.58236 7.93428C9.58236 7.02398 8.84439 6.28604 7.93412 6.28604C7.02382 6.28604 6.28589 7.02398 6.28589 7.93428C6.28589 8.84454 7.02382 9.58251 7.93412 9.58251Z'
        fill={color}
      />
      <path
        d='M8.00006 16C8.94678 16 9.71423 15.2326 9.71423 14.2859C9.71423 13.3391 8.94678 12.5717 8.00006 12.5717C7.05335 12.5717 6.28589 13.3391 6.28589 14.2859C6.28589 15.2326 7.05335 16 8.00006 16Z'
        fill={color}
      />
    </svg>
  )
}

export default OptionIcon
