import { apiManager } from './apiManager'
import {
  GetAllChapterSectionsResponse,
  GetAllChapterSectionsPayload,
  GetChapterSingleSectionPayload,
  GetChapterSingleSectionResponse,
  UpdateSingleChapterPayload,
} from './types.chapters'

export class chaptersApi {
  public static readonly updateSingle = apiManager.put<void, UpdateSingleChapterPayload>(
    ({ id, data }) => ({
      url: `chapters/${id}`,
      body: data,
    })
  )
  public static readonly getAllSections = apiManager.get<
    GetAllChapterSectionsResponse,
    string | GetAllChapterSectionsPayload
  >((props) => {
    if (typeof props === 'string') {
      return `chapters/${props}/sections`
    }
    return {
      url: `chapters/${props.chapterId}/sections`,
      params: { page: props.page },
    }
  })

  public static getSingleSection = apiManager.get<
    GetChapterSingleSectionResponse,
    GetChapterSingleSectionPayload
  >(({ chapterId, sectionId, ...params }) => ({
    url: `chapters/${chapterId}/sections/${sectionId}`,
    params,
  }))
}
