import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import { localStorageKeys } from 'config/localStorageKeys'
import { api } from '../../api'

// Groups constants
export enum GROUPS {
  REQUEST = 'GROUPS_REQUEST',
  SUCCESS = 'GROUPS_SUCCESS',
  ERROR = 'GROUPS_ERROR',
}

// Groups reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function groupsReducer(state: any = initialState, action: IAction<GROUPS>) {
  switch (action.type) {
    case GROUPS.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case GROUPS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GROUPS.ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Groups actions
export function getGroups() {
  const me = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  const schoolId = me?.school?._id
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: GROUPS.REQUEST })
      const res = await api.groups.getAll({
        schoolId,
        fetchAll: 1,
        order: 'asc',
        sortBy: 'name',
      })
      dispatch({ type: GROUPS.SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: GROUPS.ERROR,
      })
    }
  }
}

export interface IGroupsActions {
  getGroups: () => void
}
