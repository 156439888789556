import React from 'react'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'

import DownloadIcon from '../../../ui/icons/DownloadIcon/DownloadIcon'
import useText from '../../../i18n/hook'
import { downloadHeatmapResults } from './helpers/downloadHeatmapResults'

type DownloadResultsProps = {
  exerciseId: string
  exerciseName: string
  buttonColor: BUTTON_COLOR
}

const DownloadResults = ({ exerciseId, exerciseName, buttonColor }: DownloadResultsProps) => {
  const t = useText()

  return (
    <Button
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      color={buttonColor}
      icon={<DownloadIcon color='var(--icon-color)' size={20} />}
      onClick={() => downloadHeatmapResults({ exerciseId, exerciseName })}
      tooltipText={t.download}
      tooltipPosition={TOOLTIP_POSITION.BOTTOM}
    />
  )
}

export default DownloadResults
