import { BUTTON_COLOR } from '@magmamath/ui'
import { dynamicPaths } from 'config/pathnames.config'
import { AnswerType, MyScriptCharacterTypes } from 'helpers/enums'
import { IStudentSolutionStats } from 'api/types.solutions'
import { buildUrl } from 'lib/url/buildUrl'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './SmallSolutionCard.module.scss'
import SmallSolutionCardBody from './SmallSolutionCardBody/SmallSolutionCardBody'
import SmallSolutionCardFooter from './SmallSolutionCardFooter/SmallSolutionCardFooter'
import SmallSolutionCardHeader from './SmallSolutionCardHeader/SmallSolutionCardHeader'

type SmallSolutionCardProps = {
  solution: IStudentSolutionStats
  title: string
  exerciseId: string
  isUserDeleted: boolean
  problemId: string
  subProblemId?: string
  studentId: string
  correctAnswer: string[]
  characterType: MyScriptCharacterTypes
  answerType: AnswerType
  headerColor?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
}

const SmallSolutionCard = ({
  solution,
  title,
  exerciseId,
  isUserDeleted,
  problemId,
  subProblemId,
  studentId,
  headerColor,
  correctAnswer,
  characterType,
  answerType,
}: SmallSolutionCardProps) => {
  const selectedAttemptAnswerInfo =
    solution.answerArray?.find((answer) => answer.selectedAttempt?.attemptNumber) || null

  const solutionLink = dynamicPaths.studentSolutions({
    exerciseId,
    problemId,
    subProblemId,
    studentId,
  })

  const cardLinkUrl = buildUrl({
    baseUrl: solutionLink,
    searchParams: {
      attempt: selectedAttemptAnswerInfo?.selectedAttempt?.attemptNumber,
    },
  })

  const isAnswerSubmitted = !!(solution.answer && solution.answer.length > 0)

  return (
    <Link to={cardLinkUrl} className={styles.SolutionLink}>
      <div className={styles.SolutionCard}>
        <SmallSolutionCardHeader
          solution={solution}
          title={title}
          exerciseId={exerciseId}
          problemId={problemId}
          subProblemId={subProblemId}
          studentId={studentId}
          correctAnswer={correctAnswer}
          headerColor={headerColor}
          selectedAttemptAnswerInfo={selectedAttemptAnswerInfo}
          isUserDeleted={isUserDeleted}
        />
        <SmallSolutionCardBody
          drawingUrl={selectedAttemptAnswerInfo?.drawing || solution.drawing?.drawingUrl}
          isAnswerSubmitted={isAnswerSubmitted}
          noSolutionAnswer={selectedAttemptAnswerInfo?.answer ?? solution.lastAnswer}
          answerType={answerType}
          characterType={characterType}
        />
        <SmallSolutionCardFooter
          solution={solution}
          answerType={answerType}
          characterType={characterType}
          selectedAttemptAnswerInfo={selectedAttemptAnswerInfo}
        />
      </div>
    </Link>
  )
}

export default SmallSolutionCard
