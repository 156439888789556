import React from 'react'
import styles from './CardStats.module.scss'
import { ISkillStatistic } from '../../../../api/types.skillsStatistics'
import { CircleProgressBar, StarProgressBar } from '@magmamath/ui'
import { getProgressStarCount } from '../../helpers'

type CardStatsProps = {
  isStudentSelected: boolean
  statistic: ISkillStatistic
}

const CardStats = ({ isStudentSelected, statistic }: CardStatsProps) => {
  if (isStudentSelected) {
    const studentStatistic = (() => {
      if (!statistic) return 0

      const total = statistic.correct + statistic.wrong + statistic.notAnswered

      return (statistic.correct / total) * 100
    })()

    return <StarProgressBar count={3} progress={getProgressStarCount(studentStatistic)} size={25} />
  }

  return (
    <CircleProgressBar
      classes={{ wrapper: styles.SkillCardProgressCircle }}
      progressData={[statistic?.correct ?? 0, statistic?.wrong ?? 0]}
      size={44}
      backgroundColor={'var(--card-background-color)'}
    />
  )
}

export default CardStats
