import React from 'react'
import { SvgIconProps } from '../types/types'

const ClockIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00015 1.66016C4.49867 1.66016 1.66016 4.49867 1.66016 8.00015C1.66016 11.5016 4.49867 14.3401 8.00015 14.3401C11.5016 14.3401 14.3401 11.5016 14.3401 8.00015C14.3401 4.49867 11.5016 1.66016 8.00015 1.66016ZM0.160156 8.00015C0.160156 3.67024 3.67024 0.160156 8.00015 0.160156C12.3301 0.160156 15.8401 3.67024 15.8401 8.00015C15.8401 12.3301 12.3301 15.8401 8.00015 15.8401C3.67024 15.8401 0.160156 12.3301 0.160156 8.00015Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00014 3.24276C8.41436 3.24276 8.75014 3.57855 8.75014 3.99276V7.96346L10.6461 9.58853C10.9606 9.8581 10.997 10.3316 10.7274 10.6461C10.4578 10.9606 9.98437 10.997 9.66988 10.7274L7.73554 9.06942C7.42746 8.80534 7.25014 8.41983 7.25014 8.01405V3.99276C7.25014 3.57855 7.58593 3.24276 8.00014 3.24276Z'
        fill={color}
      />
    </svg>
  )
}

export default ClockIcon
