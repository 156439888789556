import * as React from 'react'

import CustomTimePickerDropdown from 'components/basic/Dropdowns/CustomTimePickerDropdown/CustomTimePickerDropdown'
import {
  MERIDIANS,
  MINUTES,
  SW_HOURS,
  US_HOURS,
} from 'features/DateTimePicker/TimePicker/helpers/dateTimeValues'
import { LocaleData, TimePickerType } from 'helpers/enums'
import { currentLocale } from 'config/modules.config'
import { Time } from 'interfaces/scheduleTime.interface'
import styles from './TimePicker.module.scss'

interface ITimePicker {
  time: Time
  onTimeChange: (newValue: Time) => void
}

const TimePicker: React.FC<ITimePicker> = ({ time, onTimeChange }) => {
  return (
    <div className={styles.TimePickerContainer}>
      <CustomTimePickerDropdown
        options={currentLocale.currentLang === LocaleData.SE ? SW_HOURS : US_HOURS}
        time={time}
        onTimeChange={onTimeChange}
        timePickerType={TimePickerType.Hour}
      />
      <CustomTimePickerDropdown
        options={MINUTES}
        time={time}
        onTimeChange={onTimeChange}
        timePickerType={TimePickerType.Minute}
      />
      {(currentLocale.currentLang === LocaleData.US ||
        currentLocale.currentLang === LocaleData.GB) && (
          <CustomTimePickerDropdown
            options={MERIDIANS}
            time={time}
            onTimeChange={onTimeChange}
            timePickerType={TimePickerType.HourType}
          />
        )}
    </div>
  )
}

export default TimePicker
