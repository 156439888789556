import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { MODAL } from './modal.module'
import { getGroup } from './group.module'
import { ShareMultiplicityType } from '../../helpers/enums'
import { getClasses } from './classes.module'
import { api } from '../../api'
import { ShareWithTeachersPayload } from '../../api/types.teachers'

export enum TEACHERS {
  REQUEST = 'TEACHERS_REQUEST',
  SUCCESS = 'TEACHERS_SUCCESS',
  ERROR = 'TEACHERS_ERROR',
  ALL_TEACHERS_REQUEST = 'ALL_TEACHERS_REQUEST',
  ALL_TEACHERS_SUCCESS = 'ALL_TEACHERS_SUCCESS',
  ALL_TEACHERS_ERROR = 'ALL_TEACHERS_ERROR',
  SHARE_ERROR = 'SHARE_ERROR',
  SHARE_SUCCESS = 'SHARE_SUCCESS',
  SHARE_REQUEST = 'SHARE_REQUEST',
}

// Teachers reducer
const initialState = {
  data: {
    all: {},
  },
  error: null,
  loading: false,
}

export function schoolTeachersReducer(state: any = initialState, action: IAction<TEACHERS>) {
  switch (action.type) {
    case TEACHERS.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case TEACHERS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case TEACHERS.ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case TEACHERS.ALL_TEACHERS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case TEACHERS.ALL_TEACHERS_SUCCESS:
      return {
        ...state,
        data: {
          all: action.payload,
        },
        loading: false,
      }
    case TEACHERS.ALL_TEACHERS_ERROR:
      return {
        data: {
          all: {},
        },
        error: action.payload,
        loading: false,
      }
    case TEACHERS.SHARE_REQUEST:
      return {
        ...state,
        data: { ...state, data: action.payload },
        loading: true,
      }
    case TEACHERS.SHARE_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case TEACHERS.SHARE_SUCCESS:
      return {
        data: { ...state },
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Teachers actions
export function getTeachersBySchool(schoolId: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: TEACHERS.REQUEST })
      const teachers = await api.teachers.getBySchool(schoolId)
      dispatch({ type: TEACHERS.SUCCESS, payload: teachers.data })
      return teachers.data
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: TEACHERS.ERROR,
      })
      return []
    }
  }
}

export function resetCurrentSchoolTeachers() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: TEACHERS.SUCCESS, payload: [] })
  }
}

// Teachers actions
export function share(data: ShareWithTeachersPayload) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: TEACHERS.SHARE_REQUEST })
      const res = await api.teachers.share(data)
      dispatch({ type: TEACHERS.SHARE_SUCCESS, payload: res })
      dispatch({ type: MODAL.CLOSE_TEACHERS_AUTOSUGGEST })
      data.type === ShareMultiplicityType.CLASS
        ? getClasses()(dispatch)
        : getGroup(data.subject)(dispatch)
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: TEACHERS.SHARE_ERROR,
      })
    }
  }
}

export interface ITeachersActions {
  getTeachersBySchool: (schoolId: string) => void
  share: (data: { subject: string; type: number; to: any }) => void
}
