import React from 'react'
import styles from './ReportErrorModal.module.scss'
import { IProblem, ISubProblem } from '../../../api/types.problem'
import ReportErrorForm from '../ReportErrorForm/ReportErrorForm'
import { BUTTON_COLOR, Modal } from '@magmamath/ui'
import useText from 'i18n/hook'

type ReportErrorModalProps = {
  isOpen: boolean
  onClose: () => void
  subProblem: ISubProblem | null
  problem: IProblem | null
  buttonColor: BUTTON_COLOR
}
const ReportErrorModal = ({ isOpen, onClose, subProblem, problem, buttonColor }: ReportErrorModalProps) => {
  const t = useText()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classes={{ Dialog: styles.Dialog }}
    >
      <Modal.Header title={t.reportQuestion} />
      <Modal.Content>
        <p className={styles.Text}> {t.reportQuestionModalText}</p>
        <ReportErrorForm
          onFormSubmit={onClose}
          subProblem={subProblem}
          problem={problem}
          buttonColor={buttonColor}
        />
      </Modal.Content>
    </Modal>
  )
}
export default ReportErrorModal
