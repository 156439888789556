import { IPracticeAbility, IPracticeCategory, IPracticeSubcategory } from '../../api/types.oldPractice'
import { displayAnswer } from '../../helpers/solutions.helpers'
import { Separator, TypesOfParams } from '../../helpers/enums'
import { IStudent } from '../../api/types.users'
import { IPracticeResult, GetPracticeResultsResponse } from '../../api/types.statistics'
import { CorrectAnswerRatePercentage } from './constants'

type VisiblePart = { element: Element; height: number; isFullPlacedOnScrollArea: boolean }

const isSubcategoryHasAbilitiesWithGrade = (subcategory: IPracticeSubcategory, grade: number) => {
  return subcategory.abilities.some((ability: IPracticeAbility) => ability.grades.includes(grade))
}

export const filterCategoriesByGrade = (categories: IPracticeCategory[], grade: number) => {
  return categories.filter((category: IPracticeCategory) => {
    return category.subcategories.some((subcategory: IPracticeSubcategory) => {
      return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
    })
  })
}

export const filterSubcategoriesByGrade = (
  subcategories: IPracticeSubcategory[],
  grade: number
) => {
  return subcategories.filter((subcategory: IPracticeSubcategory) => {
    return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
  })
}

export const filterAbilitiesByGrade = (abilities: IPracticeAbility[], grade: number) => {
  return abilities.filter((ability: IPracticeAbility) => {
    return ability.grades.includes(grade)
  })
}

export const filteredGradesAccordingToExistedAbility = (
  grades: number[],
  categories: IPracticeCategory[]
) => {
  return grades.filter((grade) => {
    return categories.some((category: IPracticeCategory) => {
      return category.subcategories.some((subcategory: IPracticeSubcategory) => {
        return isSubcategoryHasAbilitiesWithGrade(subcategory, grade)
      })
    })
  })
}

const getVisiblePart: (element: Element, scrollAreaElement: HTMLElement) => VisiblePart = (
  element,
  scrollAreaElement
) => {
  const rect = element.getBoundingClientRect()
  const scrollAreaHeight: number = scrollAreaElement.offsetHeight
  const topDistance = rect.top - scrollAreaElement.offsetTop
  const bottomDistance = rect.bottom - scrollAreaElement.offsetTop
  if (topDistance < 0 && bottomDistance > scrollAreaHeight) {
    return { element, height: rect.height, isFullPlacedOnScrollArea: false }
  }

  if (bottomDistance > scrollAreaHeight) {
    return { element, height: scrollAreaHeight - topDistance, isFullPlacedOnScrollArea: false }
  }

  if (topDistance >= 0) {
    return { element, height: rect.height, isFullPlacedOnScrollArea: true }
  }

  return { element, height: rect.height - Math.abs(topDistance), isFullPlacedOnScrollArea: false }
}

const sortElementsByVisiblePart: (parts: VisiblePart[]) => VisiblePart[] = (parts) => {
  return parts.sort((a, b) => {
    return Number(a.height < b.height) - Number(a.height > b.height)
  })
}

export const findMostVisibleElementInView = (
  elements: Element[],
  scrollAreaElement: HTMLElement
) => {
  const elementsWithSize = elements.map((element: Element) =>
    getVisiblePart(element, scrollAreaElement)
  )
  const fullyVisibleElements = elementsWithSize.filter(
    (element) => element.isFullPlacedOnScrollArea
  )
  if (fullyVisibleElements.length === 1) return fullyVisibleElements[0].element

  return sortElementsByVisiblePart(
    fullyVisibleElements.length ? fullyVisibleElements : elementsWithSize
  )[0]?.element
}

export const getDisplayedAnswer = (answers: [string] | undefined) => {
  return answers?.map((answer: string, index: number) =>
    displayAnswer(answer, index, answers?.length, Separator.COMMA)
  )
}

const compareUndefinedCounts = (count1: number | undefined, count2: number | undefined) => {
  if (count1 === undefined && count2 === undefined) {
    return 0
  }

  if (count2 === undefined) {
    return -1
  }

  return 1
}

export const sortStudentsByCorrectAnswers: (
  students: IStudent[],
  result: GetPracticeResultsResponse
) => IStudent[] = (students: IStudent[], result: GetPracticeResultsResponse) => {
  const compare = (student1: IStudent, student2: IStudent) => {
    const correctCountStudent1 = result?.[student1._id]?.correctCount
    const correctCountStudent2 = result?.[student2._id]?.correctCount
    if (correctCountStudent1 === undefined || correctCountStudent2 === undefined) {
      return compareUndefinedCounts(correctCountStudent1, correctCountStudent2)
    }

    return correctCountStudent2 - correctCountStudent1
  }

  students.sort(compare)
  return students
}

export const getGoBackParams: (
  selectedClassId: string,
  selectedGroupId: string
) => { type: TypesOfParams | null; _id: string } = (selectedClassId, selectedGroupId) => {
  if (!selectedClassId && !selectedGroupId) {
    return { type: TypesOfParams.CLASSES, _id: 'null' }
  }

  return {
    type: selectedClassId ? TypesOfParams.CLASSES : TypesOfParams.GROUPS,
    _id: selectedClassId || selectedGroupId,
  }
}

export const getStudentStats = (studentResult: IPracticeResult) => {
  return !studentResult?.correctCount || !studentResult?.totalCount
    ? 0
    : Math.round((studentResult.correctCount / studentResult.totalCount) * 100)
}

export const getProgressStarCount = (stats: number) => {
  if (stats >= CorrectAnswerRatePercentage.THREE_STARS) {
    return 3
  }

  if (stats >= CorrectAnswerRatePercentage.TWO_STARS) {
    return 2
  }

  if (stats >= CorrectAnswerRatePercentage.ONE_STAR) {
    return 1
  }

  return 0
}
