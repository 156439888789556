import React, { ReactNode, useState } from 'react'
import clsx from 'clsx'
import { Button, BUTTON_VARIANT, COLORS, COMPONENT_COLOR } from '@magmamath/ui'
import CrossIcon from '../../icons/CrossIcon/CrossIcon'
import styles from './InfoMessage.module.scss'

type InfoMessageProps = {
  children: ReactNode
  color?: COMPONENT_COLOR.BLUE | COMPONENT_COLOR.PURPLE
  className?: string
}

const InfoMessage = ({ children, color = COMPONENT_COLOR.PURPLE, className }: InfoMessageProps) => {
  const mergedClasses = clsx(styles.InfoMessage, className)
  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible) {
    return null
  }

  return (
    <div className={mergedClasses}>
      <span
        className={clsx(styles.Border, {
          [styles.BlueBorder]: color === COMPONENT_COLOR.BLUE,
        })}
      />
      <div className={styles.Text}>{children}</div>
      <Button
        onClick={() => setIsVisible(false)}
        icon={<CrossIcon color={COLORS.NEUTRAL_9} size={12} strokeWidth={1.5} />}
        variant={BUTTON_VARIANT.TERTIARY}
      />
    </div>
  )
}

export default InfoMessage
