import { BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStore } from 'effector-react'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import {
  $heatmapSettings,
  HeatmapSortOptions,
} from 'features/tables/HeatmapTable/model/heatmapSettings'
import AnonymousCorrectnessButton from 'features/tables/HeatmapTable/ui/AnonymousCorrectnessButton/AnonymousCorrectnessButton'
import AnonymousNameButton from 'features/tables/HeatmapTable/ui/AnonymousNameButton/AnonymousNameButton'
import socket from 'helpers/socket.helper'
import { getFullName } from 'helpers/user.helpers'
import useText from 'i18n/hook'
import { IProblemStat, IStudentStat } from 'api/types.solutions'
import { useSocketEvents } from 'lib/hooks/useSocketEvents'
import _, { flattenDeep, isEmpty, orderBy } from 'lodash'
import React, { ReactInstance, RefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import PrinterIcon from 'ui/icons/PrinterIcon/PrinterIcon'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { ILocalization } from 'config/languages.config'
import Spinner from 'features/Spinner/Spinner'
import HeatmapMenu from 'features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuTabs from 'features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabs'
import SortMenu from 'features/heatmap/SortMenu/SortMenu'
import { HeatmapTabsParams, socketEvents } from 'helpers/enums'
import { IMatch } from 'interfaces/match.inteface'
import { IStudent } from 'api/types.users'
import { getExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { reloadAllSkills } from 'redux/modules/skills.module'
import { getSkillsChaptersStats } from 'redux/modules/skillsChaptersStat.module'
import { getSkillsStats } from 'redux/modules/skillsStat.module'
import { ISolutionStatuses, getSolutionStatuses } from 'redux/modules/solutionStatuses.module'
import { RootState } from 'store/store'
import './OldPieChart.view.scss'
import ColorIndexMenu from 'features/heatmap/ColorIndexMenu/ColorIndexMenu'
import NamesBlock from './NamesTable'
import { MemoizedPrintPieChart } from './PrintPieChart.tsx'
import PieChartWrapper from './PieChartWrapper'
import HelpQueue from 'features/helpQueue/HelpQueue'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'
import { IProblem, ISubProblem } from '../../../api/types.problem'
import { IClass } from '../../../api/types.classes'
import { ISkills, ISkillsUsers } from '../../../api/types.statistics'
import { IGroup } from '../../../api/api.groups'
import { ISkill } from '../../../api/types.oldSkills'

export interface IPieChartViewProps {
  history: any
  localization: ILocalization
  match: IMatch
}

export interface IUser {
  firstName: string
  fullName: string
  lastName: string
  username: string
  _id: string
  isTeacher?: boolean
  attempts: number
  solved: number
}

export interface ISkillsStatsForOneEntity {
  _id: string
  length: number
  name: string
  progress: number
  attempts: number
}

export interface ISkillsAndChaptersEntity {
  skills: ISkillsStatsForOneEntity[]
  chapterId: string
  name: string
  length: number
  progress: number
  attempts: number
}

export enum EntityType {
  Student,
  Group,
  Class,
}

export enum DataType {
  Percentage,
  Attempts,
}

const OldPieChart: React.FC<IPieChartViewProps> = ({ history, localization, match }) => {
  const t = useText()
  const heatmapSettings = useStore($heatmapSettings)

  const dispatch = useDispatch()
  const componentRef = useRef()
  const [isShowPrintData, setIsShowPrintData] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef!.current as unknown as ReactInstance | null,
    onAfterPrint: () => setIsShowPrintData(false),
    documentTitle: 'Magma',
  })

  useSocketEvents(socket, {
    event: socketEvents.chapterSkillStatsUpdated,
    handler: async (data: { exerciseId: string; userId: string | null }) => {
      dispatch(getSkillsStats(data.exerciseId))
    },
  })

  useEffect(() => {
    if (!isShowPrintData) {
      return
    }
    setTimeout(handlePrint, 200)
  }, [isShowPrintData])

  const exerciseWithoutStats = useSelector((state: RootState) => state.exerciseWithoutStats)
  const skills = useSelector((state: RootState) => state.skills)
  const [uniqueSkillsIds, setUniqueSkillsIds] = useState<string[]>([])
  const { isAnonymousNameMode } = useStore($heatmapSettings)

  const [students, setStudents] = useState<IUser[]>([])
  const [classes, setClasses] = useState<IClass[]>([])
  const [groups, setGroups] = useState<IGroup[]>([])
  const [classesIds, setClassesIds] = useState<string[]>([])
  const [groupsIds, setGroupsIds] = useState<string[]>([])

  const [sortedUsers, setSortedUsers] = useState<IUser[]>([])
  const selectedValue = useSelector((state: RootState) => state.filterMultiplicity.data.filterValue)
  const solutionStatuses = useSelector((state: RootState) => state.solutionStatuses.data)
  const getPercentage = (currentStats: any) => Math.round(currentStats?.solvedPercentage ?? 0)
  const getAttempts = (currentStats: any) => currentStats?.solved + currentStats?.wrong

  const [pieChartData, setPieChartData] = useState<any>([])
  const [pieChartSingleData, setDataSingleChart] = useState<any>([])
  const getChapterId = (id: string) => (id === 'null' ? 'other' : id)

  const skillsStats = useSelector((state: RootState) => state.skillsStats)
  const skillsClassesStats = useSelector((state: RootState) => state.skillsStats.data.classesStats)
  const skillsGroupsStats = useSelector((state: RootState) => state.skillsStats.data.groupsStats)
  const skillsChaptersStats = useSelector(
    (state: RootState) => state.skillsChapterStats.data.studentsStats
  )
  const skillsChaptersClassesStats = useSelector(
    (state: RootState) => state.skillsChapterStats.data.classesStats
  )
  const skillsChaptersGroupsStats = useSelector(
    (state: RootState) => state.skillsChapterStats.data.groupsStats
  )

  const hasParams = match?.params?.studentId || match?.params?.classId || match?.params?.groupId

  const buttonColor = getButtonColor(exerciseWithoutStats.testModeEnabled)

  const calculateSkillsData = (
    dataType: DataType,
    type: EntityType,
    studentsStats: any,
    classesStats: any,
    groupsStats: any,
    entityId: string,
    uniqueSkillId?: string
  ) => {
    if (type === EntityType.Student) {
      const targetSkill = studentsStats?.find((skillStats: ISkills) =>
        uniqueSkillId ? skillStats.skillId === uniqueSkillId : !skillStats?.skillId
      )
      const targetUser = targetSkill?.users?.find((user: ISkillsUsers) => user.userId === entityId)
      return dataType === DataType.Percentage ? getPercentage(targetUser) : getAttempts(targetUser)
    }
    if (type === EntityType.Class) {
      const targetSkill = classesStats[entityId]?.skills?.find((skillStats: ISkills) =>
        uniqueSkillId ? skillStats.skillId === uniqueSkillId : !skillStats?.skillId
      )
      const targetUser = targetSkill?.classes?.find(
        (simpleClass: {
          classId: string
          solved: number
          solvedPercentage: number
          wrong: number
        }) => simpleClass.classId === entityId
      )
      return dataType === DataType.Percentage ? getPercentage(targetUser) : getAttempts(targetUser)
    }
    if (type === EntityType.Group) {
      const targetSkill = groupsStats[entityId]?.skills?.find((skillStats: ISkills) =>
        uniqueSkillId ? skillStats.skillId === uniqueSkillId : !skillStats?.skillId
      )
      const targetUser = targetSkill?.groups?.find(
        (simpleGroup: {
          groupId: string
          solved: number
          solvedPercentage: number
          wrong: number
        }) => simpleGroup.groupId === entityId
      )
      return dataType === DataType.Percentage ? getPercentage(targetUser) : getAttempts(targetUser)
    }
    return 0
  }

  const shouldShowClasses = () => {
    if (!isEmpty(exerciseWithoutStats.data?.classes)) {
      return !(!isEmpty(skillsChaptersClassesStats) && !isEmpty(skillsClassesStats))
    }
    return false
  }

  const shouldShowGroups = () => {
    if (!isEmpty(exerciseWithoutStats.data?.groups)) {
      return !(!isEmpty(skillsGroupsStats) && !isEmpty(skillsChaptersGroupsStats))
    }
    return false
  }

  useEffect(() => {
    if (
      isEmpty(students) ||
      isEmpty(skillsChaptersStats) ||
      !skills.data?.items ||
      !skillsStats.data.studentsStats?.skills ||
      shouldShowClasses() ||
      shouldShowGroups() ||
      !exerciseWithoutStats.data?._id
    ) {
      return
    }

    const modifiedUsers = students.map((user: IUser) => ({
      _id: user._id,
      type: EntityType.Student,
    }))
    const modifiedClasses = classes.map((singleClass: IClass) => ({
      _id: singleClass._id,
      type: EntityType.Class,
    }))
    const modifiedGroups = groups.map((singleGroup: IGroup) => ({
      _id: singleGroup._id,
      type: EntityType.Group,
    }))

    const getChapterProgress = (
      entityType: EntityType,
      skillChapterId: string,
      entityId: string
    ) => {
      if (entityType === EntityType.Class) {
        return Math.round(
          skillsChaptersClassesStats[entityId][skillChapterId]?.solvedPercentage * 100
        )
      }
      if (entityType === EntityType.Group) {
        return Math.round(
          skillsChaptersGroupsStats[entityId][skillChapterId]?.solvedPercentage * 100
        )
      }
      return Math.round(skillsChaptersStats[skillChapterId].users[entityId]?.solvedPercentage * 100)
    }

    const getChapterAttempts = (
      entityType: EntityType,
      skillChapterId: string,
      entityId: string
    ) => {
      if (skillChapterId === 'null') {
        return calculateSkillsData(
          DataType.Attempts,
          entityType,
          skillsStats.data.studentsStats?.skills,
          skillsClassesStats,
          skillsGroupsStats,
          entityId
        )
      }
      const targetSkills = skills.data?.items
        .filter((simpleSkill: ISkill) => simpleSkill.chapter === skillChapterId)
        .map((skill: ISkill) => skill._id)
      return targetSkills.reduce((acc: number, el: any) => {
        return (
          acc +
          calculateSkillsData(
            DataType.Attempts,
            entityType,
            skillsStats.data.studentsStats?.skills,
            skillsClassesStats,
            skillsGroupsStats,
            entityId,
            el
          )
        )
      }, 0)
    }

    const skillsChaptersStatsIds = Object.keys(skillsChaptersStats)
    const entities = [...modifiedUsers, ...modifiedClasses, ...modifiedGroups].map((entity) => ({
      ...entity,
      [entity._id]: skillsChaptersStatsIds.map((skillChapterId) => ({
        chapterId: getChapterId(skillChapterId),
        name:
          skills?.data?.items?.find((skill: ISkill) => skill?.chapter === skillChapterId)
            ?.chapterName || localization.data.other,
        length:
          skillsChaptersStats[skillChapterId].problemsLength +
          skillsChaptersStats[skillChapterId].subProblemsLength,
        progress: getChapterProgress(entity.type, skillChapterId, entity._id),
        attempts: getChapterAttempts(entity.type, skillChapterId, entity._id),
        skills:
          skillChapterId !== 'null'
            ? skills.data?.items
                .filter((simpleSkill: ISkill) => simpleSkill.chapter === skillChapterId)
                .map((skill: ISkill) => ({
                  _id: skill._id,
                  name: skill.name || localization.data.other,
                  length: exerciseWithoutStats?.data?.problems
                    .filter((problem: IProblem) => problem?.skillId === skill._id)
                    .reduce(
                      (prev: number, current: IProblem) =>
                        prev + (current?.subProblems.length || 1),
                      0
                    ),
                  progress: calculateSkillsData(
                    DataType.Percentage,
                    entity.type,
                    skillsStats.data.studentsStats?.skills,
                    skillsClassesStats,
                    skillsGroupsStats,
                    entity._id,
                    skill._id
                  ),
                  attempts: calculateSkillsData(
                    DataType.Attempts,
                    entity.type,
                    skillsStats.data.studentsStats?.skills,
                    skillsClassesStats,
                    skillsGroupsStats,
                    entity._id,
                    skill._id
                  ),
                }))
            : [
                {
                  _id: 'other',
                  name: localization.data.other,
                  length:
                    skillsChaptersStats.null.problemsLength +
                    skillsChaptersStats.null.subProblemsLength,
                  progress: calculateSkillsData(
                    DataType.Percentage,
                    entity.type,
                    skillsStats.data.studentsStats?.skills,
                    skillsClassesStats,
                    skillsGroupsStats,
                    entity._id
                  ),
                  attempts: calculateSkillsData(
                    DataType.Attempts,
                    entity.type,
                    skillsStats.data.studentsStats?.skills,
                    skillsClassesStats,
                    skillsGroupsStats,
                    entity._id
                  ),
                },
              ],
      })),
    }))
    setPieChartData(entities)
  }, [
    students,
    classes,
    groups,
    skills.data?.items,
    skillsChaptersClassesStats,
    skillsChaptersGroupsStats,
    skillsGroupsStats,
    skillsStats.data.studentsStats?.skills,
    exerciseWithoutStats.data?._id,
    skillsChaptersStats,
    skillsClassesStats,
  ])

  useEffect(() => {
    if (!exerciseWithoutStats.data._id || isEmpty(students)) {
      return
    }
    setSortedUsers(getSortedDataOfUsers())
  }, [exerciseWithoutStats.data, students, heatmapSettings.sortOption, selectedValue])

  const getSortedByGroupOrClass = (users: IUser[]) => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    const { classes: classesFromExercise, groups: groupsFromExercise } = exerciseWithoutStats.data
    const arrayOfClassesAndGroups = [...classesFromExercise, ...groupsFromExercise].find(
      (classOrGroup: IGroup | IClass) => classOrGroup._id === selectedValue
    )
    const studentsIds = arrayOfClassesAndGroups?.students.map((student: IStudent) => student._id)

    return users?.filter((element: IUser) => studentsIds?.find((id: string) => id === element._id))
  }

  const getSortedByMultiplicity = (users: IUser[]) => users || getSortedByGroupOrClass(users)

  const getProblemsAndSubProblemsStats = () => {
    const dataOfExercise = exerciseWithoutStats.data
    const problemsWithoutSubProblems: IProblem[] = dataOfExercise.problems.filter(
      (problemOfExercise: IProblem) => _.isEmpty(problemOfExercise.subProblems)
    )
    const problemsWithSubProblems: IProblem[] = dataOfExercise.problems.filter(
      (problemOfExercise: IProblem) => !_.isEmpty(problemOfExercise.subProblems)
    )

    const subproblemsArray: ISubProblem[] = _.flattenDeep(
      problemsWithSubProblems.map((problemOfExercise: IProblem) =>
        problemOfExercise.subProblems.map((sub: ISubProblem) => sub)
      )
    )

    const statsFromSubProblems: IProblemStat[][] = problemsWithSubProblems.map(
      (problemOfExercise: IProblem) => {
        return problemOfExercise.subProblems.map((sub: ISubProblem) => {
          const subproblemStat: IProblemStat = {
            problemName: problemOfExercise.name + sub.name,
            problemStats: sub.studentStats!,
          }
          return subproblemStat
        })
      }
    )

    const statsFromProblems: IProblemStat[] = problemsWithoutSubProblems.map(
      (problem: IProblem) => {
        const problemStat: IProblemStat = {
          problemName: problem.name,
          problemStats: problem.studentStats,
        }
        return problemStat
      }
    )

    return {
      allProblemsQuantity: problemsWithoutSubProblems.length + subproblemsArray.length,
      problemsStats: statsFromProblems,
      subProblemsStats: _.flattenDeep(statsFromSubProblems),
    }
  }

  const getAllStudentStats = (): IStudentStat[] => {
    const { problemsStats, subProblemsStats } = getProblemsAndSubProblemsStats()
    const statsFromProblem: IStudentStat[] =
      _.flattenDeep(problemsStats?.map((problemStat: IProblemStat) => problemStat.problemStats)) ||
      []
    const statsFromSub: IStudentStat[] =
      _.flattenDeep(
        subProblemsStats?.map((problemStat: IProblemStat) => problemStat.problemStats)
      ) || []
    return _.flattenDeep([...statsFromProblem, ...statsFromSub])
  }

  const returnProblemsQuantity = (
    userId: string,
    calculate: (acc: number, statOfStudent: IStudentStat) => number
  ): number => {
    const allStudentsStats: IStudentStat[] = getAllStudentStats()
    const statsForOneStudent: IStudentStat[] = flattenDeep(allStudentsStats).filter(
      (stat: IStudentStat) => stat?.studentId === userId
    )
    return statsForOneStudent.reduce(
      (acc: number, statOfStudent: IStudentStat) => calculate(acc, statOfStudent),
      0
    )
  }

  const calculateAnsweredProblems = (acc: number, statOfStudent: IStudentStat): number => {
    const answeredWithError: boolean =
      !_.isEmpty(statOfStudent.stats.answer) && !statOfStudent.stats.solved
    const defaultValue: number = 1
    const answered: number = answeredWithError ? defaultValue : statOfStudent.stats.solved
    return acc + answered
  }

  const compareWithId = (a: IUser, b: IUser): number => {
    const comparableIdA = a._id
    const comparableIdB = b._id
    return comparableIdA.localeCompare(comparableIdB)
  }

  const compareWithCorrectAnswered = (a: number, b: number): number => {
    return a < b ? 1 : -1
  }

  const sortByMostAnswers = () => {
    return students?.sort((a: IUser, b: IUser) => {
      const percentOfCompletedForFirst: number = returnProblemsQuantity(
        a._id,
        calculateAnsweredProblems
      )
      const percentOfCompletedForSecond: number = returnProblemsQuantity(
        b._id,
        calculateAnsweredProblems
      )
      const percentIsEqual: boolean = percentOfCompletedForFirst === percentOfCompletedForSecond
      return percentIsEqual
        ? compareWithId(a, b)
        : compareWithCorrectAnswered(percentOfCompletedForFirst, percentOfCompletedForSecond)
    })
  }

  const sortByRandom = () => {
    const randomSort = () => Math.random() - 0.5
    return students?.sort(randomSort)
  }

  const sortByName = (nameField: IUser['firstName' | 'lastName' | 'fullName'] = 'fullName') => {
    return orderBy(students, [nameField], ['asc'])
  }

  const sortByMostCorrectAnswers = () => {
    return orderBy(students, ['solved'], ['desc'])
  }

  const getSortedDataOfUsers = () => {
    const sortHandlerMap: Record<string, () => IUser[]> = {
      [HeatmapSortOptions.MOST_ANSWERS]: sortByMostAnswers,
      [HeatmapSortOptions.MOST_CORRECT_ANSWERS]: sortByMostCorrectAnswers,
      [HeatmapSortOptions.RANDOM]: sortByRandom,
      [HeatmapSortOptions.FIRST_NAME]: () => sortByName('firstName'),
      [HeatmapSortOptions.LAST_NAME]: () => sortByName('lastName'),
    }
    const handler = sortHandlerMap[heatmapSettings.sortOption] || sortByName
    return getSortedByMultiplicity(handler())
  }

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id || !solutionStatuses.ok) {
      return
    }
    const usersWithoutTeacherField = exerciseWithoutStats.data.students as IStudent[]
    const usersWithSolutionStatuses = usersWithoutTeacherField.map((user) => ({
      ...user,
      fullName: getFullName(user.firstName, user.lastName),
      solved:
        solutionStatuses.data.find(
          (solutionStatus: ISolutionStatuses) => solutionStatus._id === user._id
        )?.solved || 0,
      attempts:
        solutionStatuses.data.find(
          (solutionStatus: ISolutionStatuses) => solutionStatus._id === user._id
        )?.attempts || 0,
    }))
    setStudents(usersWithSolutionStatuses as IUser[])
  }, [exerciseWithoutStats.data?._id, solutionStatuses?.ok])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    setClasses([...exerciseWithoutStats.data.classes])
    setGroups([...exerciseWithoutStats.data.groups])
    setClassesIds(
      exerciseWithoutStats.data.classes.map((singleCLass: IClass) => singleCLass._id) || []
    )
    setGroupsIds(exerciseWithoutStats.data.groups.map((group: IGroup) => group._id))
  }, [exerciseWithoutStats.data?._id])

  useEffect(() => {
    const { exerciseId } = match.params
    if (!exerciseWithoutStats.data?._id || exerciseWithoutStats.data?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }
  }, [localization.data.languageTxt, exerciseWithoutStats.data?._id, match.params?.exerciseId])

  useEffect(() => {
    if (exerciseWithoutStats.data?._id) {
      dispatch(getSolutionStatuses(exerciseWithoutStats.data?._id))
    }
  }, [exerciseWithoutStats.data?._id])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    if (classesIds && !isEmpty(classesIds)) {
      dispatch(getSkillsChaptersStats(exerciseWithoutStats.data._id, classesIds))
      dispatch(getSkillsStats(exerciseWithoutStats.data?._id, classesIds))
    }
  }, [exerciseWithoutStats.data?._id, classesIds])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    if (groupsIds && !isEmpty(groupsIds)) {
      dispatch(getSkillsChaptersStats(exerciseWithoutStats.data._id, [], groupsIds))
      dispatch(getSkillsStats(exerciseWithoutStats.data?._id, [], groupsIds))
    }
  }, [exerciseWithoutStats.data?._id, groupsIds])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    dispatch(getSkillsChaptersStats(exerciseWithoutStats.data._id))
    dispatch(getSkillsStats(exerciseWithoutStats.data?._id))
  }, [exerciseWithoutStats.data?._id])

  useEffect(() => {
    if (isEmpty(skillsStats.data.studentsStats)) {
      return
    }
    setUniqueSkillsIds(
      skillsStats?.data?.studentsStats?.skills
        ?.map((skillStats: ISkills) => skillStats?.skillId || 'other')
        .filter((uniqueId: string) => uniqueId)
    )
  }, [skillsStats.data.studentsStats])

  useEffect(() => {
    if (!pieChartData || isEmpty(pieChartData) || isEmpty(skillsStats.data)) {
      return
    }
    if (!hasParams) {
      const url = `/exercises/${exerciseWithoutStats.data._id}/classes/${exerciseWithoutStats.data.classes[0]._id}/pie-chart`
      history.push(url)
    }
    const resFromParams = pieChartData.find(
      (p: any) =>
        p._id === match?.params?.studentId ||
        p._id === match?.params?.classId ||
        p._id === match?.params?.groupId
    )?.[match?.params?.studentId || match?.params?.classId || match?.params?.groupId]

    if (resFromParams) setDataSingleChart(resFromParams)
  }, [match.params, pieChartData, skillsStats.data])

  useEffect(() => {
    if (isEmpty(uniqueSkillsIds)) {
      return
    }
    const uniqueSkillsIdsWithoutOther = uniqueSkillsIds.filter(
      (skillId: string) => skillId !== 'other'
    )
    dispatch(reloadAllSkills(uniqueSkillsIdsWithoutOther))
  }, [uniqueSkillsIds])

  return (
    <div className='pie-chart-new'>
      <HeatmapMenu
        actions={
          <>
            <HelpQueue />
            <VerticalDivider />
            <Button
              color={buttonColor}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZE.MEDIUM}
              icon={<PrinterIcon color='var(--icon-color)' size={20} />}
              onClick={() => setIsShowPrintData(true)}
              tooltipText={t.printTxt}
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
            />
            <ColorIndexMenu />
            <AnonymousNameButton buttonColor={buttonColor} />
            <AnonymousCorrectnessButton buttonColor={buttonColor} />
            <SortMenu buttonColor={buttonColor} />
          </>
        }
        navigation={<HeatmapMenuTabs activeTab={HeatmapTabsParams.CHART} />}
        title={exerciseWithoutStats.data.name}
      />
      <ViewContainer history={history} className='pie-chart-container-view'>
        <div className='pie-chart-container'>
          <div className='pie-chart-names-table'>
            <NamesBlock
              history={history}
              exerciseWithoutStats={exerciseWithoutStats}
              localization={localization}
              users={sortedUsers}
              classes={classes}
              groups={groups}
              isStudentsNamesHidden={isAnonymousNameMode}
              match={match}
            />
          </div>
          {!isEmpty(pieChartSingleData) && hasParams ? (
            <div className='pie-chart-wrapper'>
              <PieChartWrapper skillsAndChaptersEntity={pieChartSingleData} isPrinting={false} />
            </div>
          ) : (
            <div className='loader-container'>
              <Spinner className='loader' />
            </div>
          )}
        </div>
        {!skills.loading &&
          exerciseWithoutStats.data._id &&
          skills.data?.items &&
          skillsStats.data.studentsStats?.skills &&
          isShowPrintData && (
            <div className='pie-chart-wrapper'>
              <MemoizedPrintPieChart
                skillsAndChaptersEntity={pieChartData}
                componentRef={componentRef as unknown as RefObject<HTMLDivElement>}
                users={sortedUsers}
                classes={classes}
                groups={groups}
                localization={localization}
                exerciseName={exerciseWithoutStats.data?.name}
                exercise={exerciseWithoutStats}
              />
            </div>
          )}
      </ViewContainer>
    </div>
  )
}

export default OldPieChart
