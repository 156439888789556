import { EXERCISES_STATUS, IExercisesStatuses } from '../redux/modules/exercisesStatuses.module'

import { IExercise } from '../api/types.assignments'

export const getKeyByValue = (object: IExercisesStatuses) => {
  return Object.keys(object).find((key) => object[key] === true)
}

export const getStatusFromExercise = (exercise: IExercise) => {
  const { archived, published } = exercise
  if (archived) {
    return EXERCISES_STATUS.ARCHIVED
  }
  if (published) {
    return EXERCISES_STATUS.AVAILABLE
  }
  return EXERCISES_STATUS.UNPUBLISHED
}
