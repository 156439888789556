import { BUTTON_COLOR, COLORS } from '@magmamath/ui'
import PauseIcon from 'ui/icons/PauseIcon/PauseIcon'
import PlayIcon from 'ui/icons/PlayIcon/PlayIcon'
import StopIcon from 'ui/icons/StopIcon/StopIcon'
import { SvgIconProps } from 'ui/icons/types/types'
import { END_BUTTON_COLOR, ICON_COLOR } from './constants'

import { ExerciseState } from '../../../../../api/types.assignments'

type GetIconColorProps = {
  color: BUTTON_COLOR
  isActive: boolean
  isHovered: boolean
}

export const getIconColor = ({ color, isActive, isHovered }: GetIconColorProps) => {
  const colorsMap = {
    [BUTTON_COLOR.GREEN]: COLORS.PRIMARY_GREEN,
    [BUTTON_COLOR.YELLOW]: COLORS.PRIMARY_YELLOW,
    [END_BUTTON_COLOR]: COLORS.NEUTRAL_9,
  }

  if (isActive) return ICON_COLOR
  if (isHovered) return colorsMap[color] || color
  return COLORS.NEUTRAL_6
}

export const getExamStatusButtonData = ({
  exerciseState,
  exerciseSettingsState,
}: {
  exerciseState: ExerciseState
  exerciseSettingsState?: ExerciseState
}) => {
  const isUnpublishedState = exerciseSettingsState === ExerciseState.NotStarted
  const isDistrictLocked = exerciseSettingsState === ExerciseState.DistrictLocked
  const isEndButton = exerciseState === ExerciseState.Finished

  const colorMap = {
    [ExerciseState.Started]: BUTTON_COLOR.GREEN,
    [ExerciseState.Stopped]: BUTTON_COLOR.YELLOW,
    [ExerciseState.Finished]: END_BUTTON_COLOR,
  }

  const isActiveMap = {
    [ExerciseState.Started]: !isDistrictLocked && exerciseSettingsState === ExerciseState.Started,
    [ExerciseState.Stopped]:
      !(isUnpublishedState || isDistrictLocked) &&
      (exerciseSettingsState === ExerciseState.Stopped || !exerciseSettingsState),
    [ExerciseState.Finished]:
      !(isUnpublishedState || isDistrictLocked) && exerciseSettingsState === ExerciseState.Finished,
  }

  const isDisabledMap = {
    [ExerciseState.Started]: isDistrictLocked,
    [ExerciseState.Stopped]: isDistrictLocked || isUnpublishedState,
    [ExerciseState.Finished]: isDistrictLocked || isUnpublishedState,
  }

  const IconMap = {
    [ExerciseState.Started]: PlayIcon,
    [ExerciseState.Stopped]: PauseIcon,
    [ExerciseState.Finished]: StopIcon,
  }

  const color: BUTTON_COLOR = colorMap[exerciseState] || BUTTON_COLOR.GREEN
  const isActive: boolean = isActiveMap[exerciseState] || false
  const isDisabled: boolean = isDisabledMap[exerciseState] || false
  const Icon: React.FC<SvgIconProps> = IconMap[exerciseState] || PlayIcon

  return {
    color,
    Icon,
    isActive,
    isEndButton,
    isDisabled,
  }
}
