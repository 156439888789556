import { PATHS } from 'config/pathnames.config'
import { NotifyType } from 'helpers/enums'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
// tslint:disable-next-line:ordered-imports
import { changeStudentsForExercises, clearAllEntityForExercises } from './choosing.stud.module'
import { EXERCISES } from './exercises.module'
import { MODAL } from './modal.module'
import { NOTIFY } from './notify.module'
import { mapExerciseStatusData } from 'helpers/exercise.helpers'
import { ILocalization } from '../../config/languages.config'
import { selectClass } from 'features/filters/MultiplicityFilter/classesFilter.module'
import { saveClass } from './saveClass.module'
import { AddExerciseData, EditExerciseData, IExercise } from '../../api/types.assignments'
import { api } from '../../api'
import { getDefaultClassOption } from 'features/filters/MultiplicityFilter/defaultClassOption.helpers'

// Exercise constants
export enum EXERCISE {
  ADD_EXERCISE = 'ADD_EXERCISE',
  ADD_EXERCISE_ERROR = 'ADD_EXERCISE_ERROR',
  ADD_EXERCISE_SUCCESS = 'ADD_EXERCISE_SUCCESS',
  GET_EXERCISE_MANUAL = 'GET_EXERCISE_MANUAL',
  GET_EXERCISE_REQUEST = 'GET_EXERCISE_REQUEST',
  GET_EXERCISE_SUCCESS = 'GET_EXERCISE_SUCCESS',
  GET_EXERCISE_ERROR = 'GET_EXERCISE_ERROR',
  EXERCISE_EDIT_REQUEST = 'EXERCISE_EDIT_REQUEST',
  EXERCISE_EDIT_SUCCESS = 'EXERCISE_EDIT_SUCCESS',
  EXERCISE_EDIT_ERROR = 'EXERCISE_EDIT_ERROR',
  DELETE_EXERCISE_ERROR = 'DELETE_EXERCISE_ERROR',
  DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS',
  DELETE_EXERCISE_REQUEST = 'DELETE_EXERCISE_REQUEST',
  DUPLICATE_EXERCISE_REQUEST = 'DUPLICATE_EXERCISE_REQUEST',
  DUPLICATE_EXERCISE_SUCCESS = 'DUPLICATE_EXERCISE_SUCCESS',
  DUPLICATE_EXERCISE_ERROR = 'DUPLICATE_EXERCISE_ERROR',
  CLEAR_EXERCISE = 'CLEAR_EXERCISE',
}

// Exercise reducer
const initialState = {
  data: {
    _id: null,
    allowedAnswerLooking: false,
    isRequiredDrawing: false,
    isRequiredUnit: false,
    drawBoardAvailableFeatures: {
      calculator: false,
    },
    archived: false,
    classes: [],
    groups: [],
    invitationalCode: null,
    name: null,
    problems: [],
    students: [],
    teacher: null,
    teachers: null,
    testModeEnabled: false,
  },
  error: null,
  loading: false,
}

export function exerciseReducer(state: any = initialState, action: IAction<EXERCISE>) {
  switch (action.type) {
    case EXERCISE.GET_EXERCISE_MANUAL:
      return {
        ...state,
        data: { ...action.payload },
        error: null,
        loading: false,
      }
    case EXERCISE.ADD_EXERCISE:
      return {
        ...state,
        data: { ...state.data },
        loading: true,
      }
    case EXERCISE.ADD_EXERCISE_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case EXERCISE.ADD_EXERCISE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE.DUPLICATE_EXERCISE_REQUEST:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case EXERCISE.DUPLICATE_EXERCISE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE.DUPLICATE_EXERCISE_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case EXERCISE.EXERCISE_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE.EXERCISE_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE.EXERCISE_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE.GET_EXERCISE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE.GET_EXERCISE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE.GET_EXERCISE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE.DELETE_EXERCISE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE.DELETE_EXERCISE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE.DELETE_EXERCISE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE.CLEAR_EXERCISE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// Exercise actions
export function addExercise(data: AddExerciseData, history: any, localization: ILocalization) {
  const notification: string = localization.data.addedExerciseTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      const mappedData = mapExerciseStatusData(data)
      await api.assignments.createNew(mappedData)
      dispatch({ type: EXERCISE.ADD_EXERCISE_SUCCESS, payload: initialState.data })
      clearAllEntityForExercises()(dispatch)
      if (history.location.pathname !== PATHS.MAP) {
        history.push('/exercises')
        setTimeout(() => {
          dispatch({
            type: NOTIFY.NOTIFY_BEGIN,
            payload: { message: notification, type: NotifyType.Success },
          })
          dispatch({ type: NOTIFY.NOTIFY_END })
        }, 500)
      } else {
        dispatch({ type: MODAL.CLOSE_MAP_OVERLAY, payload: { openedMapOverlay: false } })
      }

      dispatch({ type: MODAL.CLOSE_CREATE_OR_EDIT_EXERCISE_MODAL })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: EXERCISE.ADD_EXERCISE_ERROR,
          })
        },
        () => handleNetworkError(EXERCISE.ADD_EXERCISE_ERROR, error, dispatch)
      )
    }
  }
}

export function addBulkExercises(
  data: AddExerciseData[],
  history: any,
  localization: ILocalization
) {
  const numberOfUpdatedClasses = data.reduce((acc, curr) => acc + curr.classes.length, 0)
  const notification = localization.data.addedBulkExercisesMessage(numberOfUpdatedClasses)
  return async (dispatch: IDispatch<any>) => {
    try {
      const mappedData = data.map((dataEl) => mapExerciseStatusData(dataEl))
      const res = await api.assignments.bulk(mappedData)
      dispatch({ type: EXERCISE.ADD_EXERCISE_SUCCESS, payload: res })
      clearAllEntityForExercises()(dispatch)
      saveClass(null)(dispatch)
      selectClass(getDefaultClassOption(localization.data))(dispatch)

      if (history.location.pathname !== PATHS.MAP) {
        history.push('/exercises')
        setTimeout(() => {
          dispatch({
            type: NOTIFY.NOTIFY_BEGIN,
            payload: { message: notification, type: NotifyType.Success },
          })
          dispatch({ type: NOTIFY.NOTIFY_END })
        }, 500)
      } else {
        dispatch({ type: MODAL.CLOSE_MAP_OVERLAY, payload: { openedMapOverlay: false } })
      }

      dispatch({ type: MODAL.CLOSE_CREATE_OR_EDIT_EXERCISE_MODAL })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: EXERCISE.ADD_EXERCISE_ERROR,
          })
        },
        () => handleNetworkError(EXERCISE.ADD_EXERCISE_ERROR, error, dispatch)
      )
    }
  }
}

export function getExercise(id: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: EXERCISE.GET_EXERCISE_REQUEST })
      const res = await api.assignments.getSingle(id)
      dispatch({ type: EXERCISE.GET_EXERCISE_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISE.GET_EXERCISE_ERROR,
      })
    }
  }
}

export function getExerciseManual(data: IExercise) {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: EXERCISE.GET_EXERCISE_MANUAL, payload: data })
  }
}

export function clearExercise() {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: EXERCISE.CLEAR_EXERCISE })
  }
}

export function editExercise(
  data: EditExerciseData,
  id: string,
  history: any,
  preventRedirect?: boolean
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      const mappedData = mapExerciseStatusData(data)
      dispatch({ type: EXERCISE.EXERCISE_EDIT_REQUEST })

      const res = await api.assignments.updateSingle({ id, data: mappedData })
      dispatch({ type: EXERCISE.EXERCISE_EDIT_SUCCESS, payload: res })
      if (preventRedirect) return
      changeStudentsForExercises([])(dispatch)
      if (history.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN) {
        if (data.name) {
          dispatch({
            type: EXERCISES.EXERCISE_EDIT_NAME,
            payload: {
              exerciseId: id,
              name: data.name,
            },
          })
        }

        const shouldRemoveFromList = !!(
          data.endDate ||
          data.startDate ||
          (data.startDate === null && data.endDate === null) ||
          typeof data.archived === 'boolean' ||
          typeof data.published === 'boolean'
        )

        if (shouldRemoveFromList) {
          dispatch({
            type: EXERCISES.EXERCISE_REMOVE_FROM_LIST,
            payload: {
              exerciseId: id,
            },
          })
        }
      }
      if (
        history.location.pathname !== PATHS.EXERCISES.EXERCISES_MAIN &&
        history.location.pathname !== PATHS.MAP
      ) {
        history.push(`/exercises`)
      }
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: EXERCISE.EXERCISE_EDIT_ERROR,
          })
        },
        () => handleNetworkError(EXERCISE.EXERCISE_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export function deleteExercise(id: string, localization: ILocalization) {
  const notification: string = localization.data.deletedExerciseTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: EXERCISE.DELETE_EXERCISE_REQUEST })
      const res = await api.assignments.deleteSingle(id)
      dispatch({ type: EXERCISE.DELETE_EXERCISE_SUCCESS, payload: res })
      dispatch({ type: EXERCISES.EXERCISE_REMOVE_FROM_LIST, payload: { exerciseId: id } })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          const errorMessage = error?.response?.data?.message || error?.message
          dispatch({
            payload: errorMessage,
            type: EXERCISE.DELETE_EXERCISE_ERROR,
          })
          dispatch({
            type: NOTIFY.NOTIFY_BEGIN,
            payload: { message: errorMessage, type: NotifyType.Danger },
          })
          dispatch({ type: NOTIFY.NOTIFY_END })
        },
        () => handleNetworkError(EXERCISE.DELETE_EXERCISE_ERROR, error, dispatch)
      )
    }
  }
}

export function shareExercise(
  classroomIds: Set<string>,
  exerciseId: string,
  messages: { success: string; error: string }
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      await api.assignments.share({ assignmentId: exerciseId, classroomIds: [...classroomIds] })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: messages.success, type: NotifyType.Success },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
      return true
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            type: NOTIFY.NOTIFY_BEGIN,
            payload: { message: messages.error, type: NotifyType.Danger },
          })
          dispatch({ type: NOTIFY.NOTIFY_END })
        },
        () => {
          handleNetworkError(NOTIFY.NOTIFY_BEGIN, error, dispatch)
          dispatch({ type: NOTIFY.NOTIFY_END })
        }
      )
      return false
    }
  }
}

export interface IExerciseActions {
  addExercise: (
    data: AddExerciseData,
    history: any,
    localization: ILocalization,
    userExercisesCount?: number
  ) => void
  addBulkExercises: (
    data: AddExerciseData[],
    history: any,
    localization: ILocalization,
    userExercisesCount?: number
  ) => void
  getExercise: (id: string) => void
  getExerciseManual: (data: IExercise) => void
  editExercise: (
    data: EditExerciseData,
    id: string,
    history: any,
    preventRedirect?: boolean
  ) => void
  deleteExercise: (id: string, localization: ILocalization) => void
  clearExercise: () => void
  shareExercise: (
    classroomIds: Set<string>,
    exerciseId: string,
    messages: { success: string; error: string }
  ) => void
}
