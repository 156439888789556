import { SortingTypes } from 'helpers/enums'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

export enum SOLUTIONS_SORTING {
  SORT = 'SORT',
}

const initialState = {
  data: {
    sortingType: SortingTypes.MostAnswers,
  },
  error: null,
  loading: false,
}

export function solutionsSortingReducer(
  state: any = initialState,
  action: IAction<SOLUTIONS_SORTING>
) {
  switch (action.type) {
    case SOLUTIONS_SORTING.SORT:
      return {
        ...state,
        data: { ...state.data, sortingType: action.payload },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export function sortSolutions(type: SortingTypes) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: SOLUTIONS_SORTING.SORT, payload: type })
  }
}

export interface ISolutionsSortingActions {
  sortSolutions: (type: SortingTypes) => void
}

export interface ISolutionsSorting {
  sortingType: SortingTypes
}
