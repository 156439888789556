import React from 'react'

import styles from './PracticeSubcategory.module.scss'

import { IStudent } from '../../../../api/types.users'
import AbilityCard from '../AbilityCard/AbilityCard'
import { filterAbilitiesByGrade } from '../../helpers'
import { IPracticeAbility, IStatistics } from 'api/types.oldPractice'

type SubcategoriesBlockProps = {
  blockName: string
  blockId: string
  abilities: IPracticeAbility[]
  statistics: IStatistics
  grade: number
  selectedStudents: IStudent[] | []
}
const PracticeSubcategory = ({
  blockName,
  blockId,
  abilities,
  statistics,
  grade,
  selectedStudents,
}: SubcategoriesBlockProps) => {
  const filteredAbilities: IPracticeAbility[] = filterAbilitiesByGrade(abilities, grade)

  return (
    <div className={styles.SubcategoryBlock} id={blockId}>
      <span className={styles.SubcategoryTitle}>{blockName}</span>
      <div className={styles.AbilitiesContainer}>
        {filteredAbilities.map((ability) => {
          const stats = statistics?.[ability._id]
          return (
            <AbilityCard
              key={ability._id}
              ability={ability}
              stats={stats}
              selectedStudents={selectedStudents}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PracticeSubcategory
