import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import SchoolCardTabs, { SchoolTabCards } from './components/SchoolCardTabs/SchoolCardTabs'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { RootState } from 'store/store'
import './Classes.view.scss'
import PanelHeader from '../../components/PanelHeader/PanelHeader.component'
import { ViewContainer } from '../../components/ViewContainer/ViewContainer.component'
import SchoolViewContent from './components/SchoolViewContent/SchoolViewContent'
import { clearSchoolClasses, getSchoolClasses } from '../../redux/modules/schoolClasses.module'
import { COLORS } from '@magmamath/ui'
import Spinner from '../../features/Spinner/Spinner'

const Notification = loadable(() => import('components/Notification/Notification.component'))

type ClassesProps = {
  history: any
}

const Classes = ({ history }: ClassesProps) => {
  const { search } = useLocation()
  const activeTab = search.includes(SchoolTabCards.CLASSES)
    ? SchoolTabCards.CLASSES
    : SchoolTabCards.CLASSES
  const localization = useSelector((state: RootState) => state.localization)
  const notification = useSelector((state: RootState) => state.notify)
  const classes = useSelector((state: RootState) => state.classes)
  const teacherSchools = useSelector((state: RootState) => state.teacherSchools)
  const me = useSelector((state: RootState) => state.auth)
  const schoolClasses = useSelector((state: RootState) => state.schoolClasses.data)
  const dispatch = useDispatch()
  const isContentReadyForLoad =
    me.data.me?.school?._id === classes.data?._embedded?.classes?.at(0)?.school?._id ||
    classes.data?._embedded?.classes.length === 0
  const isDataLoaded = !teacherSchools.loading && !me.loading && isContentReadyForLoad

  useEffect(() => {
    if (isDataLoaded) {
      dispatch(getSchoolClasses(me.data.me?.school?._id))
    }
  }, [isDataLoaded])

  useEffect(() => {
    return () => {
      dispatch(clearSchoolClasses())
    }
  }, [])

  if (teacherSchools.loading) {
    return <Spinner className='loader' />
  }

  return (
    <div className='school-wrapper'>
      <PanelHeader
        backButton={false}
        className='school-header'
        history={history}
        location={history.location}
        localization={localization}
      />
      <ViewContainer history={history} className='school-view-container'>
        <SchoolCardTabs activeTab={activeTab} />
        {Array.isArray(schoolClasses) && me?.data.me ? (
          <SchoolViewContent activeTab={activeTab} me={me.data.me} />
        ) : (
          <Spinner className='loader' />
        )}
      </ViewContainer>
      <Notification notification={notification} history={history} localization={localization} />
    </div>
  )
}

export default Classes
