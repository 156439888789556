import { createEffect } from 'effector'
import { api } from '../../../api'

export const fetchSkillTreeFx = createEffect(api.skills.getTreeChildren)

export const fetchGradeSkillTreeFx = createEffect(api.skills.getTree)

export const fetchPracticeStatisticsFx = createEffect(api.skillsStatistics.getStatistics)

export const fetchStudentsResultsFx = createEffect(api.skillsStatistics.getStudentsResults)

export const fetchLastStudentSolutionFx = createEffect(api.practice.getLastUserSolution)

export const fetchPracticeTheoryFx = createEffect(api.practice.getTheory)

export const fetchDrawningHistoryFx = createEffect(api.practice.getDrawningHistory)

export const fetchExampleProblemsFx = createEffect(api.practice.getExampleProblems)
