import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'

export enum SIDEBAR_STATUS {
  SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS',
}

const initialState = {
  data: { isOpen: false },
  error: null,
  loading: false,
}

export const sideBarReducer = (state: any = initialState, action: IAction<SIDEBAR_STATUS>) => {
  switch (action.type) {
    case SIDEBAR_STATUS.SET_SIDEBAR_STATUS:
      return {
        data: { isOpen: action.payload.isOpen },
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const setSidebarStatus = (isOpen: boolean) => (dispatch: IDispatch<any>) =>
  dispatch({ type: SIDEBAR_STATUS.SET_SIDEBAR_STATUS, payload: { isOpen } })

export interface ISidebarActions {
  setSidebarStatus: (isOpen: boolean) => void
}

export interface ISidebar {
  isOpen: boolean
}
