import { TokenManager } from './TokenManager'
import { ApiError } from './ApiError'
import { ApiMethod, ContentType, MapperFn, MinimumRequestConfig } from './types'
import { bodyToParams, isPrimitive, removeStartSlash } from './helpers'

type DriverProps = {
  baseURL: string
  tokenManager: TokenManager
  requestMiddleware?: <T extends MinimumRequestConfig>(config: T) => T
}

type CommonRequestProps = {
  method: ApiMethod
  protect?: boolean
  headers?: Record<string, string>
  keepalive?: boolean
}

type FetchRequestProps<T> = {
  url: string
  body?: T
  contentType?: ContentType
  keepalive?: boolean
} & CommonRequestProps

type FetchRequestDescriptor<P> = {
  url?: string
  fn?: MapperFn<P>
} & CommonRequestProps

export class FetchDriver {
  private readonly baseURL
  private readonly tokenManager: TokenManager
  private requestMiddleware

  public constructor({ baseURL, tokenManager, requestMiddleware }: DriverProps) {
    this.baseURL = baseURL
    this.tokenManager = tokenManager
    this.requestMiddleware = requestMiddleware
  }

  public setRequestMiddleware(middleware: <T extends MinimumRequestConfig>(config: T) => T) {
    this.requestMiddleware = middleware
  }

  private async prepareData({
    protect = true,
    method,
    headers: extHeaders,
    body,
    contentType = ContentType.JSON,
    keepalive = true,
  }: FetchRequestProps<any>): Promise<RequestInit> {
    const headers: Record<string, string> = { ...extHeaders, 'Content-Type': contentType }
    if (protect && !headers.Authorization) {
      let token = await this.tokenManager.get()
      if (!token) throw new Error('User is not authorized')
      headers.Authorization = token
    }
    const data: RequestInit = { method, headers, keepalive }
    if (!body) return data
    if (contentType === ContentType.JSON) {
      data.body = JSON.stringify(body)
      return data
    }
    data.body = body as any as FormData
    return data
  }
  private async doRequest<R, P>(props: FetchRequestProps<P>): Promise<R> {
    let requestData = await this.prepareData(props)
    if (this.requestMiddleware) {
      requestData = this.requestMiddleware(requestData as MinimumRequestConfig)
    }
    const response = await fetch(props.url, requestData)
    const contentType = response.headers.get('content-type')
    const isJsonAvailable = contentType?.includes(ContentType.JSON)
    if (response.ok) {
      if (!isJsonAvailable) return null as R
      return (await response.json()) as R
    }
    if (!isJsonAvailable) throw ApiError.unknown(response)
    throw await ApiError.fromResponse(response)
  }

  public createParamsRequest<R = void, P = void>({
    fn,
    url = '',
    ...rest
  }: FetchRequestDescriptor<P>) {
    if (!fn) {
      const fullUrl = `${this.baseURL}/${removeStartSlash(url)}`
      return (props: P) => {
        if (props === undefined || props === null) {
          return this.doRequest<R, P>({ url: fullUrl, ...rest })
        }
        if (isPrimitive(props)) {
          return this.doRequest<R, P>({
            url: `${fullUrl}/${removeStartSlash(props.toString())}`,
            ...rest,
          })
        }
        const params = bodyToParams(props)
        let finalUrl = fullUrl
        if (params) finalUrl += `?${params}`
        return this.doRequest<R, P>({ url: finalUrl, ...rest })
      }
    }

    return (props: P) => {
      const result = fn(props)
      if (isPrimitive(result)) {
        return this.doRequest<R, P>({
          url: `${this.baseURL}/${removeStartSlash(result.toString())}`,
          ...rest,
        })
      }
      const params = bodyToParams(result)
      let finalUrl = this.baseURL
      if (result.url) finalUrl += `/${removeStartSlash(result.url)}`
      if (params) finalUrl += `?${params}`
      return this.doRequest<R, P>({
        ...rest,
        url: finalUrl,
        headers: { ...result.headers, ...rest.headers },
      })
    }
  }

  public createBodyRequest<R = void, P = void>({
    fn,
    url = '',
    ...rest
  }: FetchRequestDescriptor<P>) {
    if (!fn) {
      return (props: P) => {
        return this.doRequest<R, P>({
          url: `${this.baseURL}/${removeStartSlash(url)}`,
          body: props,
          ...rest,
        })
      }
    }
    return (props: P) => {
      let finalUrl = this.baseURL
      const result = fn(props)
      if (isPrimitive(result)) {
        return this.doRequest<R, P>({
          url: `${this.baseURL}/${removeStartSlash(result.toString())}`,
          ...rest,
        })
      }
      if (result.url) finalUrl += `/${removeStartSlash(result.url)}`
      return this.doRequest<R, P>({
        ...rest,
        url: finalUrl,
        headers: { ...result.headers, ...rest.headers },
        body: result.body,
      })
    }
  }
}
