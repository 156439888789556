import { useCallback, useState } from 'react'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'

export const useSelected = <P>(initialState: P[]) => {
  const [selected, setSelected] = useState(initialState)

  const add = useCallback((items: P[]) => {
    setSelected((oldList) => uniq([...oldList, ...items]))
  }, [])

  const remove = useCallback((items: P[]) => {
    setSelected((oldList) => difference(oldList, items))
  }, [])

  const change = useCallback(
    (addOrRemove: boolean, items: P[]) => {
      const handler = addOrRemove ? add : remove
      handler(items)
    },
    [add, remove]
  )

  const clear = useCallback(() => setSelected([]), [])

  return { selected, add, remove, clear, change }
}
