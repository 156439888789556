import { Events, SortingTypes } from 'helpers/enums'
import { IState } from 'interfaces/state.interface'
import * as React from 'react'
import { ISolutionsSorting, ISolutionsSortingActions } from 'redux/modules/solutionsSorting.module'
import sortDownImg from '../../../../assets/img/sort-descending.svg'
import './SortDropdown.component.scss'
import { ILocalization } from "../../../../config/languages.config";

interface ISortDropdownProps {
  history: any
  localization: ILocalization
  solutionsSorting: IState<ISolutionsSorting>
  solutionsSortingActions: ISolutionsSortingActions
}

class SortDropdown extends React.Component<ISortDropdownProps, any> {
  public menuRef: HTMLElement | null
  public iconRef: HTMLElement | null
  constructor(props: ISortDropdownProps) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  public componentDidMount() {
    document.addEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public dropdownToggle = (): void => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  public dropdownClose = (): void => {
    this.setState({
      dropdownOpen: false,
    })
  }

  public handleClickOutside = (event: MouseEvent): void => {
    if (
      event.target instanceof HTMLElement &&
      !this.iconRef?.contains(event.target) &&
      this.state.dropdownOpen &&
      !this.menuRef?.contains(event.target)
    ) {
      this.dropdownClose()
    }
  }

  public componentWillUnmount() {
    document.removeEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public renderOptions(): JSX.Element {
    const { localization, solutionsSorting } = this.props
    const { firstNameTxt, lastNameTxt, mostAnswersTxt, mostCorrectAnswersTxt, randomTxt } =
      localization.data

    return (
      <div
        className='sort-options'
        onClick={() => {
          this.dropdownToggle()
        }}
        ref={(node: HTMLElement | null) => (this.menuRef = node)}
      >
        <>
          <div
            className={`sort-option ${
              solutionsSorting.data.sortingType === SortingTypes.Abc ? 'active' : ''
            }`}
            onClick={() => this.props.solutionsSortingActions.sortSolutions(SortingTypes.Abc)}
          >
            <span>{firstNameTxt}</span>
          </div>
          <div
            className={`sort-option ${
              solutionsSorting.data.sortingType === SortingTypes.AbcLastname ? 'active' : ''
            }`}
            onClick={() =>
              this.props.solutionsSortingActions.sortSolutions(SortingTypes.AbcLastname)
            }
          >
            <span>{lastNameTxt}</span>
          </div>
          <div
            className={`sort-option ${
              solutionsSorting.data.sortingType === SortingTypes.MostAnswers ? 'active' : ''
            }`}
            onClick={() =>
              this.props.solutionsSortingActions.sortSolutions(SortingTypes.MostAnswers)
            }
          >
            <span>{mostAnswersTxt}</span>
          </div>
          <div
            className={`sort-option ${
              solutionsSorting.data.sortingType === SortingTypes.MostCorrect ? 'active' : ''
            }`}
            onClick={() =>
              this.props.solutionsSortingActions.sortSolutions(SortingTypes.MostCorrect)
            }
          >
            <span>{mostCorrectAnswersTxt}</span>
          </div>
          <div
            className={`sort-option ${
              solutionsSorting.data.sortingType === SortingTypes.Random ? 'active' : ''
            }`}
            onClick={() => this.props.solutionsSortingActions.sortSolutions(SortingTypes.Random)}
          >
            <span>{randomTxt}</span>
          </div>
        </>
      </div>
    )
  }

  public render() {
    return (
      <div className='sort-menu'>
        <div className='sort-icon-wrapper' onClick={() => this.dropdownToggle()}>
          <img
            className='sort-img'
            src={sortDownImg}
            ref={(node: HTMLElement | null) => (this.iconRef = node)}
          />
        </div>
        {this.state.dropdownOpen && this.renderOptions()}
      </div>
    )
  }
}

export default SortDropdown
