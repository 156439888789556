import React, { useState, useEffect, useRef } from 'react'
import { useStoreMap } from 'effector-react'
import { useDispatch } from 'react-redux'
import styles from './GradesSelectorModal.module.scss'
import UIModal from '../../ui/modals/UIModal/UIModal'
import GradeSelectorModalHeader from './GradeSelectorModalHeader/GradeSelectorModalHeader'
import GradeSelectorModalBody from './GradeSelectorModalBody/GradeSelectorModalBody'
import GradeSelectorModalFooter from './GradeSelectorModalFooter/GradeSelectorModalFooter'
import { $studentsGradesData } from './models/studentsGradesModel'
import { fetchStudentsByClassFx, changeStudentsGradesFx } from './models/requests'
import EmptyExerciseContainer from '../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import { callNotification } from '../../redux/modules/notify.module'
import { NotifyType } from '../../helpers/enums'
import useText from '../../i18n/hook'

type GradesSelectorModalProps = {
  isModalOpened: boolean
  onModalClose: () => void
  selectedClassId: string
}

const GradesSelectorModal = ({
  isModalOpened,
  onModalClose,
  selectedClassId,
}: GradesSelectorModalProps) => {
  const dispatch = useDispatch()
  const t = useText()
  const studentsGrades = useStoreMap($studentsGradesData, (data) => data.studentsGrades.gradesInfo)
  const isLoadingStudents = useStoreMap($studentsGradesData, (data) => data.isFetchingStudents)
  const isGradesChanged = useStoreMap(
    $studentsGradesData,
    (data) => data.studentsGrades.isDataChanged
  )
  const isGradesDirty = useStoreMap($studentsGradesData, (data) => data.studentsGrades.isDataDirty)
  const [containerRect, setContainerRect] = useState<DOMRect | undefined>(undefined)
  const [isErrorOnCloseModal, setIsErrorOnCloseModal] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    setContainerRect(containerRef.current?.getBoundingClientRect())
  }, [containerRef.current])

  useEffect(() => {
    fetchStudentsByClassFx(selectedClassId)
  }, [selectedClassId])

  const onSavePress = async () => {
    changeStudentsGradesFx({ classId: selectedClassId, studentsGrades })
      .then(() => {
        onModalClose()
        callNotification(t.yourChangesSavedTxt, NotifyType.Success)(dispatch)
      })
      .catch(() => {
        callNotification(t.yourChangesSavingErrorTxt, NotifyType.Danger)(dispatch)
      })
  }

  const onClickOutsideModal = () => {
    if (isGradesChanged || isGradesDirty) {
      setIsErrorOnCloseModal(true)
      return
    }

    onModalClose()
  }

  return (
    <UIModal
      open={isModalOpened}
      onClose={onClickOutsideModal}
      wrapperClassName={styles.GradesSelectorModalWrapper}
      closeIconWrapperClassName={styles.CloseIconWrapper}
    >
      <div className={styles.GradesSelectorModalContainer} ref={containerRef}>
        {isLoadingStudents ? (
          <EmptyExerciseContainer heightClass={styles.Loader} />
        ) : (
          <>
            <GradeSelectorModalHeader containerDimensions={containerRect} />
            <GradeSelectorModalBody />
            <GradeSelectorModalFooter
              onSaveClick={onSavePress}
              onCancelClick={onModalClose}
              isErrorOnClose={isErrorOnCloseModal}
            />
          </>
        )}
      </div>
    </UIModal>
  )
}

export default GradesSelectorModal
