import React from 'react'
import styles from './styles.module.scss'
import MultipleChoiceAnswerItem from './MultipleChoiceAnswerItem'
import useText from '../../../../i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { MultipleChoice, setMultipleChoice } from '../../../../redux/modules/problemCreator.module'
import { getPlaceholder } from '../../PoblemCreator.helper'

const TabMultipleChoiceAnswer = () => {
  const t = useText()
  const dispatch = useDispatch()
  const multipleChoice: MultipleChoice[] = useSelector(
    (state: RootState) => state.problemCreatorData.multipleChoice
  )

  const onMultipleChoiceChange = (index: number, item: MultipleChoice) => {
    const copy = [...multipleChoice]
    copy[index] = item
    dispatch(setMultipleChoice(copy))
  }

  const onSelectionChange = (index: number, nextValue: boolean) => {
    const updatedChoices = multipleChoice.map((obj, i) => ({
      ...obj,
      checked: i === index ? nextValue : obj.checked,
    }))
    dispatch(setMultipleChoice(updatedChoices))
  }

  return (
    <div className={styles.Container}>
      {multipleChoice.map((el, index) => (
        <MultipleChoiceAnswerItem
          key={index}
          autofocus={index === 0}
          placeholder={getPlaceholder(index, t.answerTxt, t.optional)}
          item={el}
          onChange={(item) => onMultipleChoiceChange(index, item)}
          onSelectionChanged={(nextValue) => onSelectionChange(index, nextValue)}
        />
      ))}
    </div>
  )
}

export default TabMultipleChoiceAnswer
