import { createEvent, EventCallable } from 'effector'

export const createDebounceEvent = <T>(delay: number) => {
  let lastValue: T | undefined
  let timer: ReturnType<typeof setTimeout> | null = null
  const event = createEvent<T>()

  const call = (value: T) => {
    lastValue = value
    if (timer) return
    timer = setTimeout(() => {
      timer = null
      if (lastValue === undefined) return
      event(lastValue)
      lastValue = undefined
    }, delay)
  }

  return Object.assign(call, event) as EventCallable<T>
}
