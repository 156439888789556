import { apiManager } from './apiManager'
import { config } from '../config'
import { UploadImagePayload, UploadImageResponse } from './types.images'

export enum ImageFormat {
  WEBP = 'webp',
  JPEG = 'jpeg',
  PNG = 'png',
}

export enum ImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type CdnImageSettings = {
  format: ImageFormat
  size: ImageSize
}

const DEFAULT_CDN_IMAGE_OPTIONS = {
  format: ImageFormat.WEBP,
  size: ImageSize.SMALL,
}
export class imagesApi {
  public static readonly uploadSingle = apiManager.post<UploadImageResponse, UploadImagePayload>(
    ({ image, type }) => {
      const formData = new FormData()
      formData.append('images', image, image.name)
      formData.append('type', String(type))
      return {
        url: 'images',
        body: formData,
      }
    }
  )

  public static readonly url = apiManager.createUrl.bind(apiManager)
  public static readonly cdnUrl = (
    imageId: string,
    {
      format = DEFAULT_CDN_IMAGE_OPTIONS.format,
      size = DEFAULT_CDN_IMAGE_OPTIONS.size,
    }: Partial<CdnImageSettings> = {}
  ) => `${config.CDN_HOST}/images/${imageId}?format=${format}&size=${size}`
}
