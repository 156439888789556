import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { UploadImageType } from '../../api/types.images'
import { api } from '../../api'

// Image constants
export enum IMAGE {
  GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST',
  GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS',
  GET_IMAGE_ERROR = 'GET_IMAGE_ERROR',
  IMAGE_ADD_REQUEST = 'IMAGE_ADD_REQUEST',
  IMAGE_ADD_SUCCESS = 'IMAGE_ADD_SUCCESS',
  IMAGE_ADD_ERROR = 'IMAGE_ADD_ERROR',
  CLEAR = 'CLEAR_IMAGE',
}

// Image reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function imageReducer(state: any = initialState, action: IAction<IMAGE>) {
  switch (action.type) {
    case IMAGE.IMAGE_ADD_REQUEST:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case IMAGE.IMAGE_ADD_SUCCESS:
      return {
        data: { ...action.payload },
        error: action.payload,
        loading: false,
      }
    case IMAGE.IMAGE_ADD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case IMAGE.GET_IMAGE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case IMAGE.GET_IMAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case IMAGE.GET_IMAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case IMAGE.CLEAR:
      return {
        data: {},
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export function addImage(file: File, errorMessage: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: IMAGE.IMAGE_ADD_REQUEST })
      const res = await api.images.uploadSingle({
        image: file,
        type: UploadImageType.PROBLEM,
      })
      dispatch({ type: IMAGE.IMAGE_ADD_SUCCESS, payload: res })
      return res
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          if (error.response.code === 3036) {
            dispatch({
              payload: errorMessage ? errorMessage : error.message,
              type: IMAGE.IMAGE_ADD_ERROR,
            })
          } else {
            dispatch({
              payload: !!error.response ? error.response.data.message : error.message,
              type: IMAGE.IMAGE_ADD_ERROR,
            })
          }
        },
        () => handleNetworkError(IMAGE.IMAGE_ADD_ERROR, error, dispatch)
      )
      return
    }
  }
}

export function clearImage() {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: IMAGE.CLEAR })
  }
}

export type IMAGE_DATA = {
  image: IMAGE_TYPE
}

export type IMAGE_TYPE = {
  cdnId: string
  createdAt: string
  isPublic: true
  name: string
  type: number
  updatedAt: '2024-05-28T09:41:00.953Z'
  uuid: string
  __v: number
  _id: string
}
