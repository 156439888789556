import { IProblem } from 'api/types.problem'
import { createEvent, restore, sample } from 'effector'
import { reorderModalStateModel } from './isReorderModalOpen'
import { fetchProblemsByIdsFx } from './requests'
import { currentSortOptionModel } from './sortOption/currentSortOption'
import { selectedProblemsDataModel } from './selectedProblemsData'

export class dndSelectedProblemsModel {
  static readonly set = createEvent<null | IProblem[]>()
  static readonly shuffle = createEvent()
  static readonly moveUp = createEvent<string>()
  static readonly moveDown = createEvent<string>()
  static readonly reset = createEvent()

  static readonly $state = restore(this.set, null)
    .reset(this.reset)
    .on(this.shuffle, (dndSelectedProblems) => {
      if (!dndSelectedProblems) return null
      return [...dndSelectedProblems].sort(() => Math.random() - 0.5)
    })
    .on(this.moveUp, (dndSelectedProblems, problemId) => {
      if (!dndSelectedProblems) return null
      const index = dndSelectedProblems.findIndex((p) => p._id === problemId)
      if (index === 0) return dndSelectedProblems
      const newOrder = [...dndSelectedProblems]
      newOrder.splice(index - 1, 0, newOrder.splice(index, 1)[0])

      return newOrder
    })
    .on(this.moveDown, (dndSelectedProblems, problemId) => {
      if (!dndSelectedProblems) return null
      const index = dndSelectedProblems.findIndex((p) => p._id === problemId)
      if (index === dndSelectedProblems.length - 1) return dndSelectedProblems
      const newOrder = [...dndSelectedProblems]
      newOrder.splice(index + 1, 0, newOrder.splice(index, 1)[0])

      return newOrder
    })
    .on(fetchProblemsByIdsFx.doneData, (_, problems) => problems)
}

sample({
  source: {
    selectedProblemsData: selectedProblemsDataModel.$state,
    dndSelectedProblems: dndSelectedProblemsModel.$state,
  },
  clock: [
    selectedProblemsDataModel.$state,
    currentSortOptionModel.$state,
    reorderModalStateModel.$state,
  ],
  fn: ({ dndSelectedProblems, selectedProblemsData }) => {
    if (!selectedProblemsData) return dndSelectedProblems
    if (!dndSelectedProblems) return Array.from(selectedProblemsData.values())

    const selectedIds = new Set(selectedProblemsData.keys())
    const updatedDndSelectedProblems = dndSelectedProblems.filter((problem) =>
      selectedIds.has(problem._id)
    )

    selectedProblemsData.forEach((problem) => {
      if (!dndSelectedProblems.some((item) => item._id === problem._id)) {
        updatedDndSelectedProblems.push(problem)
      }
    })
    return updatedDndSelectedProblems
  },
  target: dndSelectedProblemsModel.$state,
})
