import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { isEmpty, truncate } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Doughnut, getElementAtEvent } from 'react-chartjs-2'

import './PieChartWrapper.scss'

import { COLORS } from '@magmamath/ui'
import useText from 'i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  DataForCreateOrEditExerciseModal,
  openCreateOrEditExerciseModal,
} from 'redux/modules/modal.module'
import { clearSection, getSection } from 'redux/modules/section.module'
import { RootState } from 'store/store'
import fontStyle from '../../../assets/scss/fonts.scss'
import {
  ISkillsAndChaptersEntity,
  ISkillsStatsForOneEntity,
} from 'views/PieChart/OldPieChart/OldPieChart.view'
import { ISkillsUsers } from '../../../api/types.statistics'
import { ISkill } from '../../../api/types.oldSkills'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels
)

interface IPieChartWrapperProps {
  skillsAndChaptersEntity: ISkillsAndChaptersEntity[]
  isPrinting: boolean
}
const BLACK_COLOR = '#000000'
const maxLabelLength = 25
// const minAngleForLabel = 10;

const chooseChartSectionColor = (percentage: number, attempts: number, hoverColor: boolean) => {
  if (!attempts) {
    return hoverColor ? '#E9E9E9' : COLORS.NEUTRAL_3
  }
  if (percentage >= 0 && percentage <= 19) {
    return hoverColor ? '#F33C3C' : '#EB5757'
  }
  if (percentage > 19 && percentage <= 39) {
    return hoverColor ? '#FF9A8E' : 'rgba(255, 129, 112, 0.6)'
  }
  if (percentage > 39 && percentage <= 59) {
    return hoverColor ? '#FFEFB2' : 'rgba(255, 241, 182, 0.6)'
  }
  if (percentage > 59 && percentage <= 79) {
    return hoverColor ? '#A4E9C6' : 'rgb(200,241,221)'
  }
  return hoverColor ? '#4AC97F' : COLORS.PRIMARY_GREEN
}

const PieChartWrapper: React.FC<IPieChartWrapperProps> = ({
  skillsAndChaptersEntity,
  isPrinting,
}) => {
  const params = useParams<{ classId?: string }>()
  const [chosenSkill, setChosenSkill] = useState<{ id: string; name: string } | null>(null)
  const dispatch = useDispatch()
  const skillsItems = useSelector((state: RootState) => state.skills?.data?.items)
  const studentsStatsSkills = useSelector(
    (state: RootState) => state.skillsStats?.data?.studentsStats?.skills
  )
  const problemsFromSection = useSelector((state: RootState) => state.section.data.problems)
  const exerciseWithoutStatsData = useSelector(
    (state: RootState) => state.exerciseWithoutStats?.data
  )
  const chartRef = useRef()
  const t = useText()

  const titleToolTip = (tooltipItems: any) => {
    const value = isNaN(tooltipItems[0].dataset.value[tooltipItems[0].dataIndex])
      ? ''
      : ', ' + tooltipItems[0].dataset.value[tooltipItems[0].dataIndex] + '%'
    return `${tooltipItems[0].dataset.labels[tooltipItems[0].dataIndex]}${
      tooltipItems[0].dataset.showPercentageInTooltip ? value : ''
    }`
  }

  const skills = useMemo(
    () => skillsAndChaptersEntity.flatMap((item) => item.skills),
    [skillsAndChaptersEntity]
  )

  const getProblemsFromSection = (skillId: string) => {
    const chosenSkill = skillsItems.find((skillItem: ISkill) => skillItem._id === skillId)
    if (!chosenSkill) return
    const chosenSectionId = chosenSkill.mainSection
    setChosenSkill({ name: chosenSkill.name, id: chosenSkill._id })
    dispatch(getSection(chosenSectionId, history))
  }

  const onDoughnutClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!chartRef.current) return
    const activePoint = getElementAtEvent(chartRef.current, event)?.[0]

    if (!activePoint || activePoint.datasetIndex !== 0) return
    const clickedElementIndex = activePoint.index
    const skillId = data.datasets[0].skillIds?.[clickedElementIndex]

    if (!skillId || skillId === t.other?.toLowerCase() || !skillsItems.length) return

    getProblemsFromSection(skillId)
  }

  const getCoordinates = (entity: ISkillsAndChaptersEntity[] | ISkillsStatsForOneEntity[]) => {
    // @ts-ignore
    const totalCount = entity.reduce(
      (acc: number, el: ISkillsAndChaptersEntity | ISkillsStatsForOneEntity) => acc + el.length,
      0
    )
    // @ts-ignore
    const coordinates = entity.reduce(
      (
        result: { start: number; end: number }[],
        element: ISkillsAndChaptersEntity | ISkillsStatsForOneEntity,
        index: number
      ) => {
        const numberOfProblems = element.length
        const degrees = (numberOfProblems / totalCount) * 360

        if (index === 0) {
          return result.concat({ start: 0, end: degrees })
        }

        const previousEnd = result[index - 1].end
        return result.concat({ start: previousEnd, end: previousEnd + degrees })
      },
      []
    )

    return coordinates.map((element: { start: number; end: number }) => {
      const bisector = (element.start + element.end) / 2
      return bisector > 90 && bisector < 270 ? bisector + 180 : bisector
    })
  }

  const formatter = (
    value: number,
    context: {
      dataset: {
        labels: { [x: string]: string }
        data: number[]
        value: number[]
        showPercentage: boolean
      }
      dataIndex: string | number
    }
  ) => {
    const totalCount = context.dataset.data.reduce((acc: number, el: number) => acc + el, 0)
    const degrees = (value / totalCount) * 360
    return degrees || isPrinting
      ? `${truncate(context.dataset.labels[context.dataIndex], { length: maxLabelLength })}${
          context.dataset.showPercentage
            ? '\n' + context.dataset.value[context.dataIndex] + '%'
            : ''
        }`
      : ''
  }

  const getStudentIds = (skillId: string) => {
    const minPercentageSolved = 60

    const chosenSkill = studentsStatsSkills?.find(
      (skillStats: { skillId: string }) => skillStats?.skillId === skillId
    )

    if (!chosenSkill) return []

    const usersWithoutTeacher = chosenSkill.users.filter(
      (user: ISkillsUsers) => user.userId !== exerciseWithoutStatsData?.teacher?._id
    )
    const studentsWithBadResult = usersWithoutTeacher.filter(
      (user: ISkillsUsers) =>
        (user.solvedPercentage < minPercentageSolved && user.solvedPercentage) || user.wrong
    )

    return studentsWithBadResult.map((user: ISkillsUsers) => user.userId)
  }

  const data: any = {
    datasets: [
      {
        animation: {
          duration: 0,
        },
        skillIds: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) => simpleSkill?._id)
        ),
        labels: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) => simpleSkill?.name)
        ),
        data: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) => simpleSkill?.length)
        ),
        backgroundColor: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) =>
            chooseChartSectionColor(simpleSkill?.progress, simpleSkill?.attempts, false)
          )
        ),
        hoverBackgroundColor: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) =>
            chooseChartSectionColor(simpleSkill?.progress, simpleSkill?.attempts, true)
          )
        ),
        value: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) => simpleSkill?.progress)
        ),
        isCursorPointer: skillsAndChaptersEntity.flatMap((item) =>
          item.skills.map((simpleSkill) => {
            if (!simpleSkill?._id) return false
            const studentsAssigned = getStudentIds(simpleSkill?._id)
            const isEmptyCondition = isEmpty(studentsAssigned)
            return !isEmptyCondition
          })
        ),
        showPercentage: true,
        showPercentageInTooltip: true,
        datalabels: {
          borderWidth: 7,
          color: BLACK_COLOR,
          font: {
            size: isPrinting ? 12 : 10,
            family: fontStyle.defaultFont,
          },
          rotation: getCoordinates(skills),
          labels: {
            title: {
              textAlign: 'center',
              anchor: 'center',
              align: 'center',
              height: 20,
              formatter,
            },
          },
        },
      },
      {
        animation: {
          duration: 0,
        },
        labels: skillsAndChaptersEntity.map((item) => item?.name),
        data: skillsAndChaptersEntity.map((item) => item?.length),
        backgroundColor: skillsAndChaptersEntity.map((item) =>
          chooseChartSectionColor(item?.progress, item?.attempts, false)
        ),
        value: skillsAndChaptersEntity.map((item) => item?.progress),
        showPercentage: false,
        showPercentageInTooltip: true,
        hoverBackgroundColor: skillsAndChaptersEntity.map((item) =>
          chooseChartSectionColor(item?.progress, item?.attempts, true)
        ),
        datalabels: {
          borderWidth: 7,
          color: BLACK_COLOR,
          font: {
            size: isPrinting ? 12 : 10,
            family: fontStyle.defaultFont,
          },
          rotation: getCoordinates(skillsAndChaptersEntity),
          labels: {
            title: {
              textAlign: 'center',
              anchor: 'center',
              align: 'center',
              height: 20,
              textOverflow: 'ellipsis',
              width: 10,
              formatter,
            },
          },
        },
      },
    ],
  }

  const openModal = (skillId: string) => {
    const studentsAssigned = getStudentIds(skillId)
    const isEmptyCondition = isEmpty(studentsAssigned)
    if (isEmptyCondition) return
    const allClassroomStudents =
      exerciseWithoutStatsData?.students.length === studentsAssigned.length
    const dataForModal: DataForCreateOrEditExerciseModal = {
      allClassroomStudents,
      name: `${exerciseWithoutStatsData?.name} ${chosenSkill?.name}`,
      classes: exerciseWithoutStatsData?.classes.map((cls: { _id: string }) => cls._id),
      groups: [],
      problems: problemsFromSection,
      students: studentsAssigned,
      startDate: new Date().toISOString(),
      endDate: null,
      testModeEnabled: false,
      allowedAnswerLooking: true,
      numberOfProblems: problemsFromSection?.length,
      exerciseId: exerciseWithoutStatsData?._id,
      updateMarker: 'update',
    }
    dispatch(openCreateOrEditExerciseModal(dataForModal))
  }

  useEffect(() => {
    if (problemsFromSection && chosenSkill && params.classId) {
      openModal(chosenSkill.id)
    }
    return () => {
      setChosenSkill(null)
      dispatch(clearSection())
    }
  }, [problemsFromSection])

  return (
    <div
      className={
        isPrinting ? 'pie-chart-component-wrapper-to-print' : 'pie-chart-component-wrapper'
      }
    >
      <Doughnut
        ref={chartRef}
        data={data}
        options={{
          cutout: 40,
          layout: {},
          rotation: 90,
          elements: {
            arc: {
              borderWidth: 2,
            },
          },
          plugins: {
            tooltip: {
              displayColors: false,
              backgroundColor: '#FFF',
              yAlign: 'bottom',
              titleColor: BLACK_COLOR,
              animation: {
                duration: 50,
              },
              callbacks: {
                title: titleToolTip,
                label: () => {
                  return ''
                },
              },
            },
          },
        }}
      />
    </div>
  )
}

export default React.memo(PieChartWrapper)
