import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import * as React from 'react'
import { PropsWithChildren } from 'react'
import { IStudent } from '../../../../api/types.users'
import UITooltip from '../../../tooltips/UITooltip/Tooltip'
import styles from './StudentsTableUIRow.module.scss'
import clsx from 'clsx'

interface IStudentsTableUIRowProps {
  student: IStudent
}

const StudentsTableUIRow = ({ student, children }: PropsWithChildren<IStudentsTableUIRowProps>) => {
  const { Row, Content, Item, ItemBtn, Flex } = styles
  const { overflowRef: firstNameRef, isOverflowing: isFirstNameOverflowing } =
    useOverflowDetection()
  const { overflowRef: lastNameRef, isOverflowing: isLastNameOverflowing } = useOverflowDetection()
  const { overflowRef: usernameRef, isOverflowing: isUsernameOverflowing } = useOverflowDetection()

  return (
    <div className={Row}>
      <div className={Content}>
        <UITooltip title={isFirstNameOverflowing ? student.firstName : null}>
          <div ref={firstNameRef} className={Item}>
            {student.firstName}
          </div>
        </UITooltip>
        <UITooltip ref={lastNameRef} title={isLastNameOverflowing ? student.lastName : null}>
          <div className={Item}>{student.lastName}</div>
        </UITooltip>
        <UITooltip ref={usernameRef} title={isUsernameOverflowing ? student.username : null}>
          <div className={clsx(Item, Flex)}>{student?.username}</div>
        </UITooltip>
        <div className={`${Item} ${ItemBtn}`}>{children}</div>
      </div>
    </div>
  )
}

export default StudentsTableUIRow
