import React from 'react'
import { SvgIconProps } from '../types/types'

const InfoIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 18.3C14.584 18.3 18.3 14.584 18.3 10C18.3 5.41604 14.584 1.7 10 1.7C5.41604 1.7 1.7 5.41604 1.7 10C1.7 14.584 5.41604 18.3 10 18.3ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
          fill={color}
        />
        <path
          d='M11.2 6C11.2 6.66274 10.6627 7.2 10 7.2C9.33726 7.2 8.8 6.66274 8.8 6C8.8 5.33726 9.33726 4.8 10 4.8C10.6627 4.8 11.2 5.33726 11.2 6Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 8.31665C10.4694 8.31665 10.85 8.69721 10.85 9.16665V14.5833C10.85 15.0528 10.4694 15.4333 10 15.4333C9.53056 15.4333 9.15 15.0528 9.15 14.5833V9.16665C9.15 8.69721 9.53056 8.31665 10 8.31665Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default InfoIcon
