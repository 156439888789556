import {
  Button,
  BUTTON_COLOR,
  BUTTON_VARIANT,
  SIZE,
  TOOLTIP_POSITION,
  Typography,
} from '@magmamath/ui'
import useText from 'i18n/hook'
import React from 'react'
import ShuffleIcon from 'ui/icons/ShuffleIcon/ShuffleIcon'
import RadioInputGroup from 'ui/inputs/RadioInputGroup/RadioInputGroup'
import { dndSelectedProblemsModel } from '../model/dndSelectedProblems'
import { currentSortOptionModel } from '../model/sortOption/currentSortOption'
import { SortOptions } from '../model/sortOption/sortOptionModel'
import styles from './OrderSelection.module.scss'

type OrderSelectionProps = {
  sortOption: SortOptions
  isExamMode: boolean
}

const OrderSelection = ({ sortOption, isExamMode }: OrderSelectionProps) => {
  const t = useText()
  return (
    <div className={styles.SortOptions}>
      <Typography className={styles.ListTitle} variant='h8'>
        {t.orderProblemsOnTxt}
      </Typography>
      <RadioInputGroup
        color={isExamMode ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.BLUE}
        name='order'
        selectedValue={sortOption}
        onChange={(value: SortOptions) => currentSortOptionModel.set(value)}
        options={[
          { value: SortOptions.SUBCHAPTER, label: t.subchapterTxt },
          { value: SortOptions.DIFFICULTY, label: t.difficultyTxt },
          {
            value: SortOptions.MANUAL,
            label: (
              <div className={styles.ManualOrder}>
                <span>{t.manualTxt}</span>
                <Button
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZE.SMALL}
                  icon={<ShuffleIcon color='var(--icon-color)' size={16} />}
                  onClick={() => dndSelectedProblemsModel.shuffle()}
                  tooltipText={t.randomizeTxt}
                  tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                  data-color='transparent'
                  disabled={sortOption !== SortOptions.MANUAL}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}

export default OrderSelection
