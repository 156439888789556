import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import {
  GetSingleStudentExamStatsPayload,
  IStudentsExamStats,
  UpdateExamStatusPayload,
} from '../../api/types.statistics'
import { api } from '../../api'
import { ExerciseState } from '../../api/types.assignments'

export enum STUDENTS_EXAM_STATS {
  GET_STATS_FOR_ALL_STUDENTS_REQUEST = 'GET_STATS_FOR_ALL_STUDENTS_REQUEST',
  GET_STATS_FOR_ALL_STUDENTS_SUCCESS = 'GET_STATS_FOR_ALL_STUDENTS_SUCCESS',
  GET_STATS_FOR_ALL_STUDENTS_ERROR = 'GET_STATS_FOR_ALL_STUDENTS_ERROR',
  GET_STATS_FOR_SINGLE_STUDENT_REQUEST = 'GET_STATS_FOR_SINGLE_STUDENT_REQUEST',
  GET_STATS_FOR_SINGLE_STUDENT_SUCCESS = 'GET_STATS_FOR_SINGLE_STUDENT_SUCCESS',
  GET_STATS_FOR_SINGLE_STUDENT_ERROR = 'GET_STATS_FOR_SINGLE_STUDENT_ERROR',
  CLEAR_STATS_FOR_SINGLE_STUDENT = 'CLEAR_STATS_FOR_SINGLE_STUDENT',
  UPDATE_STUDENT_EXAM_STATUS_REQUEST = 'UPDATE_STUDENT_EXAM_STATUS_REQUEST',
  UPDATE_STUDENT_EXAM_STATUS_SUCCESS = 'UPDATE_STUDENT_EXAM_STATUS_SUCCESS',
  UPDATE_STUDENT_EXAM_STATUS_ERROR = 'UPDATE_STUDENT_EXAM_STATUS_ERROR',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export const studentsExamStatsReducer = (
  state: any = initialState,
  action: IAction<STUDENTS_EXAM_STATS>
) => {
  switch (action.type) {
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_SUCCESS:
      const updatedStudentStats = action.payload.updatedStudentStats
      const id = updatedStudentStats.studentId
      const updatedExamStats = state.data.map((el: IStudentsExamStats) => {
        return el.studentId === id ? updatedStudentStats : el
      })
      return {
        ...state,
        data: updatedExamStats,
        error: null,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_SUCCESS:
      const { studentIds, status } = action.payload
      const updatedStats = state.data.map((el: IStudentsExamStats) => {
        return studentIds.includes(el.studentId)
          ? { ...el, examStats: { ...el.examStats, state: status } }
          : el
      })
      return {
        ...state,
        data: updatedStats,
        error: null,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case STUDENTS_EXAM_STATS.CLEAR_STATS_FOR_SINGLE_STUDENT:
      return {
        ...initialState.data,
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const getStudentsExamStats = (exerciseId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_REQUEST })
      const res = await api.statistics.getAllStudentsExam(exerciseId)
      dispatch(getStudentsExamStatsAC(res))
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_ERROR,
      })
    }
  }
}

export const getStudentExamStats = (props: GetSingleStudentExamStatsPayload) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_REQUEST })
      const res = await api.statistics.getSingleStudentExam(props)
      dispatch(getStudentExamStatsAC({ updatedStudentStats: res }))
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_ERROR,
      })
    }
  }
}

export const updateStudentExamStatus = ({
  exerciseId,
  status,
  studentIds,
}: UpdateExamStatusPayload) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_REQUEST })
      await api.statistics.updateExamStatus({ exerciseId, status, studentIds })
      dispatch(updateStudentExamStatusAC({ studentIds, status }))
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_ERROR,
      })
    }
  }
}

export const clearStudentsExamStats = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: STUDENTS_EXAM_STATS.CLEAR_STATS_FOR_SINGLE_STUDENT })
  }
}

const getStudentsExamStatsAC = (props: IStudentsExamStats[]) => ({
  type: STUDENTS_EXAM_STATS.GET_STATS_FOR_ALL_STUDENTS_SUCCESS,
  payload: props,
})

const getStudentExamStatsAC = (props: { updatedStudentStats: IStudentsExamStats }) => ({
  type: STUDENTS_EXAM_STATS.GET_STATS_FOR_SINGLE_STUDENT_SUCCESS,
  payload: props,
})

const updateStudentExamStatusAC = (props: { studentIds: string[]; status: ExerciseState }) => ({
  type: STUDENTS_EXAM_STATS.UPDATE_STUDENT_EXAM_STATUS_SUCCESS,
  payload: props,
})
