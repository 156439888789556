import React, { useEffect, useState } from 'react'

import ProblemCreatorHeader from '../../features/ProblemCreator/ProblemCreatorHeader'
import ProblemCreatorBody from '../../features/ProblemCreator/ProblemCreatorBody'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearStore,
  MULTIPLE_CHOICE_INITIAL_VALUE,
  MultipleChoice,
  setAllFields,
} from '../../redux/modules/problemCreator.module'
import { getProblem } from '../../redux/modules/problem.module'
import { useParams } from 'react-router'
import { RootState } from '../../store/store'
import {
  bracketSystemToKatex,
  getChoices,
  getCorrectAnswers,
  getMultipleOrSingleChoiceAnswer,
  getSelectedValueFromAnswer,
  removeDollarSigns,
  removeSelectedValueFromAnswer,
} from '../../features/ProblemCreator/PoblemCreator.helper'
import { AnswerType, MyScriptCharacterTypes } from '../../helpers/enums'
import { clearImage } from '../../redux/modules/images.module'
import Spinner from '../../features/Spinner/Spinner'

export enum ProblemCreationTab {
  NUMERIC,
  ALGEBRA,
  MULTIPLE_CHOICE,
}

interface RouteParams {
  id?: string
}

const ProblemCreator = () => {
  const dispatch = useDispatch()
  const currentProblem = useSelector((state: RootState) => state.problem.data)
  const multipleChoice = useSelector(
    (state: RootState) => state.problemCreatorData.multipleChoice as MultipleChoice[]
  )
  const [isContentLoaded, setIsContentLoaded] = useState(false)
  const { id } = useParams<RouteParams>()
  const isShowComponent = !id || (currentProblem?._id && isContentLoaded)

  useEffect(() => {
    return () => {
      dispatch(clearStore())
      dispatch(clearImage())
    }
  }, [])
  useEffect(() => {
    if (!id) {
      return
    }
    dispatch(getProblem(id))
  }, [id])

  useEffect(() => {
    if (!currentProblem?._id) {
      return
    }
    const {
      richDescription,
      answer: problemAnswer,
      answerType,
      level: problemLevel,
      answerVariants,
      characterType,
    } = currentProblem
    const [firstProblemAnswer] = problemAnswer

    const getActiveTab = () => {
      if (answerType === AnswerType.singleChoice || answerType === AnswerType.multipleChoice) {
        return ProblemCreationTab.MULTIPLE_CHOICE
      }
      if (characterType === MyScriptCharacterTypes.ALGEBRA_BASIC) {
        return ProblemCreationTab.ALGEBRA
      }
      return ProblemCreationTab.NUMERIC
    }
    const selectedValueFromServer =
      answerType === AnswerType.handwriting
        ? getSelectedValueFromAnswer(bracketSystemToKatex(firstProblemAnswer), characterType)
        : null
    const answer =
      characterType === MyScriptCharacterTypes.ALGEBRA_BASIC
        ? bracketSystemToKatex(firstProblemAnswer)
        : removeSelectedValueFromAnswer(bracketSystemToKatex(firstProblemAnswer))

    dispatch(
      setAllFields({
        question: richDescription,
        questionKatex: richDescription,
        answerNumeric:
          (characterType === MyScriptCharacterTypes.UNITS ||
            characterType === MyScriptCharacterTypes.NUMBERS_RATIONAL ||
            characterType === MyScriptCharacterTypes.CURRENCY) &&
          answerType === AnswerType.handwriting
            ? answer
            : '',
        answerAlgebra: characterType === MyScriptCharacterTypes.ALGEBRA_BASIC ? answer : '',
        answerMultiple:
          characterType === MyScriptCharacterTypes.NUMBERS_RATIONAL &&
          answerType === AnswerType.singleChoice
            ? answer
            : '',
        activeTab: getActiveTab(),
        selectedValue: selectedValueFromServer,
        multipleChoice: getMultipleOrSingleChoiceAnswer(
          problemAnswer,
          answerType,
          answerVariants,
          firstProblemAnswer,
          multipleChoice
        ),
        level: problemLevel,
        images: [],
        imageURL: currentProblem.image,
        cdnImage: currentProblem.cdnImage,
        errorQuestionField: null,
        errorAnswerField: null,
      })
    )
    setIsContentLoaded(true)
  }, [currentProblem])

  return (
    <div>
      {isShowComponent ? (
        <>
          <ProblemCreatorHeader />
          <ProblemCreatorBody />
        </>
      ) : (
        <Spinner className='loader' />
      )}
    </div>
  )
}

export default ProblemCreator
