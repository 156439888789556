import DropdownLocalization from 'components/basic/Dropdowns/LocalizationDropdownMenu/LocalizationDropdownMenu'
import SortDropdown from 'components/basic/Dropdowns/SortDropdown/SortDropdown.component'
import { PATHS } from 'config/pathnames.config'
import { detectPageWithoutSidebar } from 'helpers/pathnameChecking.helper'
import { isAuth } from 'helpers/storage.helper'
import { IState } from 'interfaces/state.interface'
import { IMe } from 'api/types.users'
import * as React from 'react'
import { PropsWithChildren, useEffect } from 'react'
import { matchPath, Route } from 'react-router-dom'
import { Collapse, Container, Nav, NavItem } from 'reactstrap'
import { ILocalizationActions } from 'redux/modules/localization.module'
import { ISolutionsSorting, ISolutionsSortingActions } from 'redux/modules/solutionsSorting.module'
import Logo from '../Logo/Logo'
import './Navbar.container.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { setSidebarStatus } from '../../redux/modules/sidebar.module'
import clsx from 'clsx'
import { ILocalization } from '../../config/languages.config'
import { IExercise } from '../../api/types.assignments'

interface INavbarMenuProps {
  history: any
  location: Location
  localization: ILocalization
  localizationActions?: ILocalizationActions
  exercise?: IState<IExercise>
  solutionsSorting?: IState<ISolutionsSorting>
  solutionsSortingActions?: ISolutionsSortingActions
  me?: IMe
}

const NavbarMenu = ({
  history,
  location,
  localization,
  localizationActions,
  exercise,
  solutionsSorting,
  solutionsSortingActions,
  me,
  children,
}: PropsWithChildren<INavbarMenuProps>) => {
  useEffect(() => {
    if (document.documentElement!.className.indexOf('nav-open') !== -1) {
      document.documentElement!.classList.toggle('nav-open')
      dispatch(setSidebarStatus(false))
    }
  }, [history.location.pathname])

  const pageWithoutSidebar: boolean = detectPageWithoutSidebar(history)
  const isStudentProgress: boolean = history.location.pathname.includes(
    PATHS.PARTS.STUDENT_PROGRESS
  )
  const isForbiddenPage: boolean = history.location.pathname.includes('forbidden')
  const isHeatmap: boolean = history.location.pathname.includes(PATHS.PARTS.HEATMAP)
  const isSolutionsPage: boolean = history.location.pathname.includes(PATHS.PARTS.SOLUTIONS)
  const isPinnedSolutionsPage: boolean = history.location.pathname.includes(PATHS.PARTS.PINNED)
  const isSkillsPage: boolean = history.location.pathname.includes(PATHS.PARTS.SKILLS)
  const isPieChartPage: boolean = history.location.pathname.includes(PATHS.PARTS.PIE_CHART)
  const isStartedPage: boolean = history.location.pathname === '/start'
  const isLoginPage: boolean = history.location.pathname === '/login'
  const isRegisterPage: boolean = history.location.pathname.includes('/register')
  const isStudentsPage = !!matchPath(history.location.pathname, PATHS.STUDENTS.STUDENTS_MAIN)
  const isSidebarOpen = useSelector((state: RootState) => state.sideBarStatus.data.isOpen)
  const dispatch = useDispatch()

  const openSidebar = (): void => {
    document.documentElement!.classList.toggle('nav-open')
    dispatch(setSidebarStatus(true))
  }

  return (
    <Container
      fluid={true}
      className={`container-navbar ${isStudentProgress ? 'little-container' : ''}`}
    >
      <div className='navbar-wrapper header-wrapper-navbar d-flex justify-content-between'>
        {(!isAuth() || isStartedPage) && <Logo className='logo-magma-header' />}
        <div className='navbar-toggle-wrapper'>
          {isAuth() && !pageWithoutSidebar && (
            <div className='navbar-toggle'>
              <button type='button' className='navbar-toggler' onClick={() => openSidebar()}>
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </button>
            </div>
          )}
        </div>
        {children}
        {!isStudentsPage && (
          <Collapse
            isOpen={isSidebarOpen}
            className={clsx({
              'header-dropdowns': true,
              'header-dropdowns-auth-page': isLoginPage || isRegisterPage,
            })}
          >
            <Nav navbar={true} className='dropdowns-navbar'>
              {(!isAuth() || (isAuth() && isStartedPage)) && !isForbiddenPage && (
                <NavItem>
                  <DropdownLocalization
                    localization={localization}
                    localizationActions={localizationActions!}
                  />
                </NavItem>
              )}
              <Route
                path={[
                  PATHS.EXERCISES.HEATMAP,
                  ...PATHS.EXERCISES.STUDENTS_SOLUTIONS,
                  ...PATHS.EXERCISES.STUDENT_SOLUTIONS,
                  ...PATHS.EXERCISES.PINNED_SOLUTIONS,
                  ...PATHS.EXERCISES.PINNED_SOLUTION,
                  ...PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTION,
                  PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTIONS,
                  ...PATHS.EXERCISES.PIE_CHART,
                ]}
                render={() => {
                  return (
                    <>
                      <NavItem>
                        {(isHeatmap || isSolutionsPage || isSkillsPage || isPieChartPage) &&
                          !isPinnedSolutionsPage &&
                          exercise && (
                            <SortDropdown
                              solutionsSorting={solutionsSorting!}
                              solutionsSortingActions={solutionsSortingActions!}
                              history={history}
                              localization={localization}
                            />
                          )}
                      </NavItem>
                    </>
                  )
                }}
              />
            </Nav>
          </Collapse>
        )}
      </div>
    </Container>
  )
}

export default NavbarMenu
