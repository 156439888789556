import axios from 'axios'

export const getImageLocalUrl = async (url: string) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' })
    return URL.createObjectURL(response.data)
  } catch (error) {
    return ''
  }
}
