import React, { useEffect, useState } from 'react'
import { Button, BUTTON_VARIANT, Listbox, SelectValue, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useSelector } from 'react-redux'
import useText from '../../i18n/hook'
import { RootState } from '../../store/store'
import HelpQueueIcon from '../../ui/icons/HelpQueueIcon/HelpQueueIcon'
import HelpQueueModal from './HelpQueueModal/HelpQueueModal'
import { useParams } from 'react-router'
import {
  $helpQueueData,
  clearHelpQueueData,
  deleteHelpQueueFx,
  fetchHelpQueueFx,
  fetchSingleHelpQueueFx,
} from './model'
import { useUnit } from 'effector-react'
import { getClassRoomId } from './helper'
import { useSocketEvents } from '../../lib/hooks/useSocketEvents'
import socket from '../../helpers/socket.helper'
import { socketEvents } from '../../helpers/enums'
import { IExercise } from '../../api/types.assignments'

type HelpQueueProps = {
  classes?: {
    root?: string
    modal?: string
  }
}

type ExerciseRouteParams = {
  exerciseId?: string
}

type SocketData = {
  classroomId: string
  exerciseId: string
  helpRequestId: string
  studentId: string
}

const HelpQueue = ({ classes }: HelpQueueProps) => {
  const helpQueue = useUnit($helpQueueData)
  const [isHelpQueueModalOpen, setIsHelpQueueModalOpen] = useState(false)
  const t = useText()
  const selectedClass = useSelector((state: RootState) => state.classesFilter)
  const allExercises: IExercise[] = useSelector(
    (state: RootState) => state.exercises?.data?._embedded?.exercises ?? []
  )
  const currentExercises = useSelector((state: RootState) => state.exerciseWithoutStats.data)
  const { exerciseId } = useParams<ExerciseRouteParams>()

  const [classRoomId, setClassRoomId] = useState<string | null>(null)

  const openHelpQueueModal = () => {
    setIsHelpQueueModalOpen(true)
  }

  const closeHelpQueueModal = () => {
    setIsHelpQueueModalOpen(false)
  }

  const updateHelpQueue = (data: SocketData) => {
    if (selectedClass?.value === Listbox.ALL_OPTION || classRoomId === data.classroomId) {
      fetchSingleHelpQueueFx({ id: data.helpRequestId })
    }
  }

  const removeHelpQueue = (data: SocketData) => {
    deleteHelpQueueFx({ id: data.helpRequestId, isSocketEvent: true })
  }

  useSocketEvents(socket, [
    {
      event: socketEvents.requestAdded,
      handler: updateHelpQueue,
    },
    {
      event: socketEvents.requestRemoved,
      handler: removeHelpQueue,
    },
  ])

  useEffect(() => {
    if (exerciseId || selectedClass) {
      setClassRoomId(
        getClassRoomId({
          exercises: allExercises,
          exercise: currentExercises,
          exerciseId,
          selectedClass,
        })
      )
    }
  }, [allExercises, currentExercises, exerciseId, selectedClass])

  useEffect(() => {
    if (classRoomId === null) return
    clearHelpQueueData()
    fetchHelpQueueFx({ classroomId: classRoomId, offset: 0 })
  }, [exerciseId, classRoomId])

  return (
    <div className={classes?.root}>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.LARGE}
        icon={<HelpQueueIcon color='var(--icon-color)' />}
        onClick={openHelpQueueModal}
        badge={helpQueue.total}
        isActive={isHelpQueueModalOpen}
        tooltipText={t.helpQueue}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
      />
      <HelpQueueModal
        onClose={closeHelpQueueModal}
        isOpen={isHelpQueueModalOpen}
        className={classes?.modal}
      />
    </div>
  )
}

export default HelpQueue
