import React, { Fragment } from 'react'
import { Tab } from '@headlessui/react'
import classnames from 'classnames'
import styles from './AnswerVarianTabs.module.scss'
import useText from '../../../i18n/hook'
import { TabDescriptor } from './types'
import clsx from 'clsx'

type TabProps = {
  tabs: TabDescriptor[]
  defaultIndex: number
  onChange: (key: number) => void
  classes?: { TabsList?: string; Tab?: string }
}

const AnswerVariantTabs = ({ tabs, defaultIndex = 0, onChange, classes }: TabProps) => {
  const t = useText()
  const mergedClasses = {
    tabsList: clsx(styles.TabList, classes?.TabsList),
    tab: clsx(styles.Tab, classes?.Tab),
  }
  return (
    <Tab.Group defaultIndex={defaultIndex} onChange={onChange}>
      <Tab.List className={mergedClasses.tabsList}>
        {tabs.map(({ label }, index) => (
          <Tab key={index} as={Fragment}>
            {({ selected }) => (
              <button className={classnames(selected && styles.Tab__selected, mergedClasses.tab)}>
                {label(t)}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        <div className={styles.Container}>
          {tabs.map(({ Component }, index) => (
            <Tab.Panel key={`panel${index}`}>
              <Component />
            </Tab.Panel>
          ))}
        </div>
      </Tab.Panels>
    </Tab.Group>
  )
}

export default AnswerVariantTabs
