import React, { PropsWithChildren } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FormControl } from '@mui/material'
import { iconStyles, menuProps, selectStyles } from '../../../styles/MUISelectStyles'
import ArrowDropDownIcon from '../../icons/ArrowDropDownIcon/ArrowDropDownIcon'
import FilterLabel from '../../Labels/FilterLabel/FilterLabel'
import { COLORS } from '@magmamath/ui'

interface UISelectProps {
  value: string
  onChange: (event: SelectChangeEvent) => void
  style: any
  filterIconColor?: string
  sx: any
  open: boolean
  onOpen: () => void
  onClose: () => void
  firstRowLimit: number
  label: string
  isIconHidden?: boolean
  sxSelect?: any
}

const UISelect = ({
  children,
  value,
  onChange,
  style,
  filterIconColor,
  sx,
  open,
  onOpen,
  onClose,
  firstRowLimit,
  label,
  isIconHidden,
  sxSelect = selectStyles,
}: PropsWithChildren<UISelectProps>) => {
  return (
    <FormControl sx={sx}>
      <Select
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        sx={sxSelect}
        labelId='book-series-select'
        displayEmpty={true}
        style={style}
        size='small'
        MenuProps={menuProps}
        value={value}
        onChange={onChange}
        renderValue={() => (label?.length > firstRowLimit ? <FilterLabel label={label} /> : label)}
        IconComponent={(props) => {
          if (isIconHidden) return null
          return (
            <div {...props} style={iconStyles}>
              <ArrowDropDownIcon color={filterIconColor || COLORS.NEUTRAL_7} />
            </div>
          )
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default UISelect
