import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const FilledPinIcon = ({ size = 16, color = COLORS.NEUTRAL_1 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20271 0.0148227C9.70726 -0.0588469 10.2244 0.147294 10.5945 0.517407L15.4826 5.40545C15.8527 5.77556 16.0588 6.29267 15.9852 6.79721C15.9044 7.35073 15.4947 7.79229 14.8677 7.88913C14.4377 7.95555 13.9608 7.9634 13.4762 7.90057L9.98718 11.3896C10.1481 11.8728 10.2426 12.47 10.2163 13.0945C10.1809 13.9357 9.92366 14.877 9.25476 15.6754C8.88393 16.118 8.25428 16.0814 7.89905 15.7262L4.54022 12.3674L1.55368 15.3539C1.303 15.6046 0.896566 15.6046 0.645887 15.3539C0.395208 15.1033 0.395207 14.6968 0.645887 14.4461L3.63243 11.4596L0.273606 8.10086C-0.0791277 7.74813 -0.123438 7.11099 0.334341 6.74026C1.16551 6.06715 2.17673 5.87932 3.01391 5.87104C3.61961 5.86505 4.17306 5.95225 4.57489 6.04813L8.09934 2.52372C8.03651 2.03915 8.04436 1.56226 8.11078 1.13224C8.20761 0.50529 8.64918 0.0956437 9.20271 0.0148227Z'
        fill={color}
      />
    </svg>
  )
}

export default FilledPinIcon
