import { apiManager } from './apiManager'
import { IStudent } from './types.users'

type GetAllGroupsPayload = {
  schoolId?: string
  fetchAll?: 1 | 0
  order?: 'asc' | 'desc'
  sortBy?: string
}

export interface IGroup {
  classroomId: string
  success: boolean
  name: string
  _id: string
  students: IStudent[]
  teachers: []
}

export type GetAllGroupsResponse = {
  total: number
  pages: number
  _links: {
    self: {
      href: string
    }
    next: {
      href: string
    }
  }
  _embedded: {
    groups: IGroup[]
  }
}

export type DeleteStudentFromGroupPayload = {
  groupId: string
  studentId: string
}

export type CreateNewGroupPayload = { classroomId: string; name: string }
export type UpdateGroupPayload = {
  id: string
  data: {
    name?: string
    studentIds?: string[]
    classroomId?: string
    ownerId?: string
  }
}

type DeleteGroupResponse = {}

export class groupsApi {
  public static readonly getAll = apiManager.get<GetAllGroupsResponse, GetAllGroupsPayload>(
    'groups'
  )
  public static readonly getSingle = apiManager.get<IGroup, string>('groups')
  public static readonly createNew = apiManager.post<IGroup, CreateNewGroupPayload>('groups')
  public static readonly updateSingle = apiManager.put<IGroup, UpdateGroupPayload>(
    ({ id, data }) => ({
      url: `groups/${id}`,
      body: data,
    })
  )
  public static readonly deleteSingle = apiManager.delete<DeleteGroupResponse, string>('groups')
  public static readonly deleteStudentFromGroup = apiManager.delete<
    void,
    DeleteStudentFromGroupPayload
  >(({ groupId, studentId }) => `groups/${groupId}/students/${studentId}`)
}
