import { SortOptions } from 'features/bookshelf/ReorderProblems/model/sortOption/sortOptionModel'
import { IDrawBoardAvailableFeatures } from '../redux/modules/exerciseWithoutStats.module'
import { IGroup } from './api.groups'
import { IClass } from './types.classes'
import { IProblem } from './types.problem'
import { IBasicUser, IStudent, ITeacher } from './types.users'

export enum ExerciseState {
  NotStarted = 0,
  Started = 1,
  Stopped = 2,
  Finished = 3,
  DistrictLocked = 4,
}

export interface IExercise {
  allClassroomStudents: boolean
  isRequiredUnit: boolean
  drawBoardAvailableFeatures: IDrawBoardAvailableFeatures
  isRequiredDrawing: boolean
  createdAt: string
  name: string
  _id: string
  students: IStudent[]
  teachers: ITeacher[]
  archived: boolean
  published: boolean
  problems: IProblem[]
  classes: IClass[]
  stats: any
  groups: IGroup[]
  allowedAnswerLooking: boolean
  testModeEnabled: boolean
  cheatDetection: boolean
  settings?: {
    assessed: boolean
    state: ExerciseState
  }
  startDate?: string
  endDate?: string
  districtExercise?: string
  problemsSortOption?: SortOptions
}

export enum ExerciseStatuses {
  PublishNow = 0,
  Unpublished = 1,
  Schedule = 2,
}

export type AddExerciseData = {
  allClassroomStudents: boolean
  name: string
  classes: string[]
  groups: string[]
  problems: string[]
  students: string[]
  startDate?: string | null
  endDate?: string | null
  testModeEnabled: boolean
  cheatDetection: boolean
  allowedAnswerLooking: boolean
  isRequiredDrawing: boolean
  isRequiredUnit: boolean
  drawBoardAvailableFeatures: IDrawBoardAvailableFeatures
  exerciseStatus?: ExerciseStatuses
  isDuplicateExercise?: boolean
  problemsSortOption?: SortOptions
}

export type EditExerciseData = Partial<AddExerciseData> & {
  settings?: {
    assessed?: boolean
    state?: ExerciseState
  }
  archived?: boolean
  published?: boolean
}

export type GetAllAssignmentsPayload = {
  fetchAll?: 1 | 0
  ids?: string
  createdAtFrom?: Date
  updatedSince?: Date
  search?: string
  order?: string
  sortBy?: string
  statusExercise?: string
  pageNumber?: number
  showIndividualParam?: number
  classes?: string[]
  allClassroomStudents?: 1
  groups?: string[]
  topics?: string[]
  exam?: boolean
  students?: string[]
  startDate?: string
  endDate?: string
  schoolId?: string
  page?: number
}

export type GetAllAssignmentsResponse = {
  _embedded: {
    exercises: IExercise[]
  }
  total: number
  pages: number
  _links: {
    self: {
      href: string
    }
    next: {
      href: string
    }
  }
}

export type GetStatsResponse = {
  _id: string
  exerciseId: string
  createdAt: string
  activeUsers: number
  afterFirstAttempt: number
  completedAmount: number
  firstAttempt: number
  notAnswered: number
  totalStudents: number
  updatedAt: string
  wrong: number
}

export type GetSingleStatsResponse = {
  _id: string
  exerciseId: string
  teacherId: string
  __v: number
  activeUsers: number
  afterFirstAttempt: number
  completedAmount: number
  createdAt: string
  firstAttempt: number
  notAnswered: number
  totalStudents: number
  updatedAt: string
  wrong: number
  answeredByTeacher: boolean
  students: {
    _id: string
    exerciseId: string
    studentId: string
    __v: number
    createdAt: string
    updatedAt: string
    student: IBasicUser
  }[]
  exercise: IExercise
}

export type UpdateExerciseStatusPayload = {
  exerciseIds: string[]
  published: boolean
  archived: boolean
}

export type GetSingleAssignmentPayload =
  | {
      id: string
      fetchAll?: 0 | 1
      useCache?: boolean
    }
  | string

export type ShareAssignmentPayload = {
  assignmentId: string
  classroomIds: string[]
}

export type UpdateAssignmentPayload = {
  data: EditExerciseData
  id: string
}
