import { sortByName } from 'features/filters/MultiplicityFilter/helper'
import { ClassroomGroup, IClass } from '../../api/types.classes'

export const getFormattedSelectedProblems = (selectedProblems: Map<string, Set<string>>) => {
  return [...selectedProblems].reduce<[string, string[]][]>((acc, problemSet) => {
    const [sectionId, problems] = problemSet
    const problemIds = [...problems]
    return [...acc, [sectionId, [...problemIds]]]
  }, [])
}

export const getSortedClassrooms = (classes: IClass[] = [], groups: ClassroomGroup[] = []) => {
  return sortByName(
    classes.reduce<IClass[]>((acc, singleClass) => {
      if (singleClass.archived) return acc

      acc.push({
        ...singleClass,
        groups: groups.filter((group) => group.classroomId === singleClass._id),
      })

      return acc
    }, [])
  )
}
