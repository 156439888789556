import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import styles from './ChaptersSkeleton.module.scss'

type ChaptersSkeletonProps = {
  count?: number
}

const ChaptersSkeleton = ({ count = 1 }: ChaptersSkeletonProps) => {
  return (
    <div className={styles.ChaptersWrapper}>
      {[...Array(count)].map((_, index) => (
        <Skeleton key={index} height={40} className={styles.Chapter} />
      ))}
    </div>
  )
}

export default ChaptersSkeleton
