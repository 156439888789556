import React from 'react'
import { SvgIconProps } from '../types/types'

const HelpQueueIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9259 2C21.3736 2 20.9259 2.44772 20.9259 3V10.9669C20.9259 11.8144 20.7099 12.6479 20.2983 13.3887L18.3 16.9856C18.1645 17.2297 17.9331 17.4059 17.6618 17.4718C17.3905 17.5376 17.1041 17.4871 16.8717 17.3324C15.5244 16.4355 13.9011 16 11.4259 16C5.87675 16 2.79746 19.7589 2.08412 22.7479C2.07522 22.7852 2.07881 22.806 2.08222 22.8182C2.08631 22.8328 2.09643 22.8554 2.12129 22.8824C2.17439 22.9401 2.28109 23 2.42588 23H20.4259C20.5696 23 20.68 22.941 20.7403 22.8766C20.792 22.8213 20.8072 22.7677 20.794 22.699C20.6405 21.9022 20.3489 21.0362 19.8973 20.1908C19.7571 19.9285 19.741 19.6175 19.8533 19.3421L22.704 12.3485C22.8505 11.9889 22.9259 11.6044 22.9259 11.2161V3C22.9259 2.44772 22.4782 2 21.9259 2ZM21.9259 0C23.5827 0 24.9259 1.34315 24.9259 3V11.2161C24.9259 11.8632 24.8003 12.5041 24.556 13.1034L21.8775 19.6747C22.3053 20.5654 22.5937 21.4683 22.7579 22.3206C23.0574 23.8754 21.749 25 20.4259 25H2.42588C1.12017 25 -0.240461 23.8726 0.138752 22.2836C1.03912 18.5109 4.83613 14 11.4259 14C13.6859 14 15.4835 14.3327 17.0468 15.1231L18.55 12.4174C18.7965 11.9737 18.9259 11.4745 18.9259 10.9669V3C18.9259 1.34315 20.269 0 21.9259 0Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4259 2.5C9.21978 2.5 7.42589 4.29132 7.42589 6.5C7.42589 8.74185 9.01034 10.5 11.4196 10.5C13.8367 10.5 15.4371 8.7348 15.4259 6.50504C15.4147 4.28786 13.6274 2.5 11.4259 2.5ZM5.42589 6.5C5.42589 3.18585 8.11611 0.5 11.4259 0.5C14.7402 0.5 17.4092 3.18938 17.4259 6.49496C17.4425 9.78796 14.9899 12.5 11.4196 12.5C7.84145 12.5 5.42589 9.78098 5.42589 6.5Z'
        fill={color}
      />
    </svg>
  )
}

export default HelpQueueIcon
