import { motion } from 'framer-motion'
import { LayoutIds } from 'helpers/enums'
import React from 'react'
import UIButton from 'ui/Buttons/UIButton/Button'
import styles from './FilterLink.module.scss'
import clsx from 'clsx'

interface IFilterLinkProps {
  activeLink: boolean
  title: string
  onClick: () => void
  badgeNumber: number
  isActionButtonActive?: boolean
  actionButtonTitle: string
}

const FilterLink: React.FC<IFilterLinkProps> = ({
  activeLink,
  title,
  onClick,
  badgeNumber,
  isActionButtonActive,
  actionButtonTitle,
}) => {
  if (isActionButtonActive && !activeLink) {
    return (
      <li className={clsx(styles.FilterLink, styles.ActionLink)}>
        <UIButton
          className={styles.ActionButton}
          variant='basic'
          color='blue'
          size='small'
          onClick={onClick}
        >
          {actionButtonTitle}
        </UIButton>
      </li>
    )
  }

  return (
    <li
      className={clsx(styles.FilterLink, {
        [styles.FilterLinkActive]: activeLink,
        [styles.NonClickable]: isActionButtonActive,
      })}
      onClick={onClick}
      aria-selected={activeLink}
    >
      <span className={styles.LinkTitle}>
        {title}

        {activeLink && (
          <motion.div
            transition={{ type: 'spring', stiffness: 65, damping: 12 }}
            className={styles.UnderLine}
            layoutId={LayoutIds.EXERCISES_FILTER_TABS}
          />
        )}

        {!!badgeNumber && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.7,
              type: 'spring',
              stiffness: 50,
              damping: 10,
            }}
            className={styles.Badge}
          >
            {badgeNumber}
          </motion.div>
        )}
      </span>
    </li>
  )
}

export default FilterLink
