import styles from '../NumericAnswer.module.scss'
import ContentEditableArea from '../../../../../ui/inputs/ContentEditableArea/ContentEditableArea'
import katex from 'katex'
import React from 'react'
import UnitAnswer from '../UnitAnswer/UnitAnswer'

type CurrencyNumericAnswerProps = {
  value: string
  selectedValue: string | null
}

const CurrencyNumericAnswer = ({ selectedValue, value }: CurrencyNumericAnswerProps) => {
  return (
    <>
      <UnitAnswer selectedValue={selectedValue} />
      <ContentEditableArea value={katex.renderToString(value)} disabled className={styles.Title} />
    </>
  )
}
export default CurrencyNumericAnswer
