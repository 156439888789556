import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const SqrtIcon: React.FC<SvgIconProps> = ({ color = COLORS.NEUTRAL_10 }) => {
  return (
    <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.7044 1.31159C6.77261 1.01928 7.03319 0.8125 7.33334 0.8125H24.125C24.4816 0.8125 24.7708 1.10165 24.7708 1.45833C24.7708 1.81501 24.4816 2.10417 24.125 2.10417H8.35831C8.05817 2.10417 7.79757 2.31095 7.72937 2.60324L3.44144 20.9801C3.37495 21.2651 3.12511 21.4697 2.8326 21.4789C2.54008 21.4879 2.27801 21.2992 2.19391 21.0189L0.256412 14.5606C0.153918 14.219 0.347784 13.8588 0.68943 13.7564C1.03108 13.6539 1.39112 13.8477 1.49361 14.1894L2.03781 16.0035C2.22797 16.6374 3.13498 16.6091 3.28536 15.9647L6.7044 1.31159Z'
        fill={color}
      />
    </svg>
  )
}

export default SqrtIcon
