import { apiManager } from './apiManager'
import {
  AddNewClassPayload,
  AddNewClassResponse,
  AddStudentToClassPayload,
  DeleteClassResponse,
  DeleteStudentFromClassPayload,
  DeleteStudentFromClassResponse,
  GetAllClassesPayload,
  GetClassesResponse,
  GetSingleClassPayload,
  IClass,
  ISchoolClass,
  MoveStudentsPayload,
  MoveStudentsResponse,
  QRCodeData,
  ResetClassPasswordsPayload,
  ResetClassPasswordsResponse,
  UpdateClassGradePayload,
  UpdateClassSettingsPayload,
  UpdateSingleClassPayload,
  UpdateSingleClassResponse,
  UpdateStudentsSettingsInClassPayload,
} from './types.classes'
import { IUser } from './types.users'

export class classesApi {
  public static readonly getAll = apiManager.get<GetClassesResponse, GetAllClassesPayload>(
    'classes'
  )
  public static readonly createNew = apiManager.post<AddNewClassResponse, AddNewClassPayload>(
    'classes'
  )

  public static readonly getSingle = apiManager.get<IClass, GetSingleClassPayload>(
    ({ id, data }) => ({ url: `classes/${id}`, params: data })
  )

  public static readonly updateClassGrade = apiManager.patch<void, UpdateClassGradePayload>(
    ({ id, data }) => ({
      url: `classes/${id}`,
      body: data,
    })
  )

  public static readonly updateSingle = apiManager.put<
    UpdateSingleClassResponse,
    UpdateSingleClassPayload
  >(({ id, data }) => ({ url: `classes/${id}`, body: data }))

  public static readonly deleteSingle = apiManager.delete<DeleteClassResponse, string>('classes')

  public static readonly moveStudents = apiManager.put<MoveStudentsResponse, MoveStudentsPayload>(
    'classes/move-students'
  )

  public static readonly resetPasswords = apiManager.put<
    ResetClassPasswordsResponse,
    ResetClassPasswordsPayload
  >(({ classId, data }) => ({
    url: `classes/${classId}/reset-passwords`,
    body: data,
  }))

  public static readonly updateStudentsSettingsInClass = apiManager.patch<
    void,
    UpdateStudentsSettingsInClassPayload
  >(({ classId, data }) => ({
    url: `classes/${classId}/settings`,
    body: data,
  }))

  public static readonly updateSettings = apiManager.put<void, UpdateClassSettingsPayload>(
    ({ classId, data }) => ({
      url: `classes/${classId}/settings`,
      body: data,
    })
  )

  public static readonly deleteStudentFromClass = apiManager.delete<
    DeleteStudentFromClassResponse,
    DeleteStudentFromClassPayload
  >(({ classId, studentId }) => `classes/${classId}/students/${studentId}`)

  public static readonly addStudentToClass = apiManager.post<IUser, AddStudentToClassPayload>(
    ({ classId, data }) => ({
      url: `classes/${classId}/students`,
      body: data,
    })
  )

  public static readonly getQRCodes = apiManager.get<QRCodeData[], string>(
    (classId) => `classes/${classId}/qr-codes`
  )

  public static readonly getBySchool = apiManager.get<ISchoolClass[], string>((schoolId) => ({
    url: `schools/${schoolId}/classes`,
    params: {
      fetchAll: 1,
      published: 1,
    },
  }))
}
