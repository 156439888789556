import React from 'react'
import { Link } from 'react-router-dom'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import ArrowLeftIcon from '../../../../ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import SmallArrowRightIcon from '../../../../ui/icons/SmallArrowRightIcon/SmallArrowRightIcon'
import styles from './ProblemNavigation.module.scss'

type ProblemNavigationProps = {
  problemName: string
  buttonColor: BUTTON_COLOR
  nextLink?: string
  prevLink?: string
}

const ProblemNavigation = ({
  problemName,
  buttonColor,
  nextLink,
  prevLink,
}: ProblemNavigationProps) => {
  const PrevButton = prevLink ? Link : 'button'
  const NextButton = nextLink ? Link : 'button'

  return (
    <nav className={styles.Navigation}>
      <Button
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.SMALL}
        color={buttonColor}
        disabled={!prevLink}
        icon={<ArrowLeftIcon color='var(--icon-color)' size={18} className={styles.Icon} />}
        As={PrevButton}
        to={prevLink}
      />
      <p className={styles.Title}>{problemName}</p>
      <Button
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.SMALL}
        color={buttonColor}
        disabled={!nextLink}
        icon={<SmallArrowRightIcon color='var(--icon-color)' size={18} className={styles.Icon} />}
        As={NextButton}
        to={nextLink}
      />
    </nav>
  )
}

export default ProblemNavigation
