import React from 'react'
import styles from './OptionsMenu.module.scss'
import Option from './Option/Option'
import useText from '../../../../i18n/hook'
import { getCorrectGradeName } from '../../../../features/GradesSelectorModal/helpers'

type CustomOptionsMenuProps = {
  enableAllOptionsSelect: boolean
  onListSelect: () => void
  allOptionsName: string
  options: number[]
  selectedOptions: number[]
  onItemSelect: (item: number) => void
  isAllOptionsSelected: boolean
}

const OptionsMenu = ({
  enableAllOptionsSelect,
  onListSelect,
  allOptionsName,
  options,
  selectedOptions,
  onItemSelect,
  isAllOptionsSelected,
}: CustomOptionsMenuProps) => {
  const t = useText()

  return (
    <div className={styles.MenuContainer}>
      {enableAllOptionsSelect && (
        <>
          <Option
            isOptionActive={isAllOptionsSelected}
            onClick={onListSelect}
            optionName={allOptionsName}
          />
          <div className={styles.MenuTitleSeparator}></div>
        </>
      )}
      {options.map((item) => {
        return (
          <Option
            isOptionActive={selectedOptions.includes(item)}
            onClick={() => onItemSelect(item)}
            optionName={`${t.grade} ${getCorrectGradeName(item, t)}`}
            key={item}
          />
        )
      })}
    </div>
  )
}

export default OptionsMenu
