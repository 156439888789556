import { createEvent, createStore } from 'effector'
import { ProblemsBySection } from './types'
import { fetchSectionsFx } from './requests'
import { IProblem } from '../../../../api/types.problem'

export const setProblems = createEvent<ProblemsBySection>()

export const $problems = createStore<Map<string, IProblem[]>>(new Map())
  .on(setProblems, (state, { sectionId, problems }) => {
    const newState = new Map(state)
    newState.set(sectionId, problems)
    return newState
  })
  .on(fetchSectionsFx.doneData, (state, { sections }) => {
    const newState = new Map(state)
    sections.forEach((section) => {
      newState.set(section._id, section.problems)
    })
    return newState
  })
