import { mergeProblemsAndSubProblems } from './helpers/data'

export enum HeatmapTestIds {
  TABLE = 'heatmap-table',
  STUDENTS_COLUMN = 'heatmap-students-column',
  PROBLEMS_COLUMNS = 'heatmap-problems-columns',
}

export enum CellColorClassNames {
  DEFAULT = 'Default',
  CORRECT_FIRST_TRY = 'CorrectFirstTry',
  CORRECT_NOT_FIRST_TRY = 'CorrectNotFirstTry',
  INCORRECT = 'Incorrect',
}

export enum Orientation {
  ROW = 'row',
  COLUMN = 'column',
}

export type HeatmapTableRef = HTMLDivElement | null

export type OnHoverStateChangeParams = {
  elementIndex: number
  newIsHovered: boolean
  orientation: Orientation
}

export type MergedProblemsAndSubProblems = ReturnType<typeof mergeProblemsAndSubProblems>
