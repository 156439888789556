import clsx from 'clsx'
import styles from '../NumericAnswer.module.scss'
import { replaceSpecialSymbolsToHTMLElements } from '../../../../../ui/inputs/ContentEditableArea/ContentEditable.helpers'
import React from 'react'

type UnitAnswerProps = {
  selectedValue: string | null
}
const UnitAnswer = ({ selectedValue }: UnitAnswerProps) => (
  <div
    className={clsx(styles.Title, styles.SelectedValue)}
    dangerouslySetInnerHTML={{
      __html: replaceSpecialSymbolsToHTMLElements(selectedValue || ''),
    }}
  />
)
export default UnitAnswer
