import { createEvent, createStore } from 'effector'
import { Bredcrumb } from '../Book/types'
import { fetchFoundBooksFx } from './requests'
import { BreadcrumbIndex, OpenedSectionPath } from './types'

type ToggleSectionProps = {
  bookId: string
  chapter?: Bredcrumb
  section?: Bredcrumb
}

export const toggleBook = createEvent<string>()

export const closeBook = createEvent<string>()

export const closeAllBooks = createEvent()

export const openBook = createEvent<string>()

export const openSection = createEvent<ToggleSectionProps>()

export const resetSection = createEvent<ToggleSectionProps>()

export const resetOpenedBooks = createEvent()

export const closeSection = createEvent<string>()
export const $openedBooks = createStore<Map<string, OpenedSectionPath>>(new Map())
  .on(closeBook, (state, bookId) => {
    const newState = new Map(state)
    newState.delete(bookId)
    return newState
  })
  .on(closeAllBooks, () => {
    return new Map()
  })
  .on(openBook, (state, bookId) => {
    const newState = new Map(state)
    newState.set(bookId, {})
    return newState
  })
  .on(toggleBook, (state, bookId) => {
    const newState = new Map(state)
    if (newState.has(bookId)) {
      newState.delete(bookId)
    } else {
      newState.set(bookId, {})
    }
    return newState
  })
  .on(openSection, (state, { bookId, chapter, section }) => {
    const newState = new Map(state)
    const openedSectionPath = newState.get(bookId)

    newState.set(bookId, { ...openedSectionPath, chapter, section })
    return newState
  })
  .on(resetSection, (state, { bookId }) => {
    const newState = new Map(state)
    const openedSectionPath = newState.get(bookId)
    
    newState.set(bookId, { ...openedSectionPath, section: undefined })
    return newState
  })
  .on(closeSection, (state, bookId) => {
    const newState = new Map(state)
    newState.set(bookId, {})
    return newState
  })
  .on(fetchFoundBooksFx.done, (_, { result }) => {
    const newState = new Map<string, OpenedSectionPath>()
    result.forEach((book) => {
      newState.set(
        `${book.breadcrumbs[BreadcrumbIndex.BOOK].id}-${book.breadcrumbs[BreadcrumbIndex.SECTION].id
        }`,
        {
          chapter: book.breadcrumbs[BreadcrumbIndex.CHAPTER],
        }
      )
    })
    return newState
  })
  .reset(resetOpenedBooks)