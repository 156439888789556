import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { api } from '../../api'

export enum PRACTICE_EXAMPLE {
  EXAMPLE_SUCCESS = 'EXAMPLE_SUCCESS',
  EXAMPLE_REQUEST = 'EXAMPLE_REQUEST',
  EXAMPLE_ERROR = 'EXAMPLE_ERROR',
}

const initialState = {
  exampleProblems: [],
  error: null,
  loading: false,
}

export function practiceExampleReducer(
  state: any = initialState,
  action: IAction<PRACTICE_EXAMPLE>
) {
  switch (action.type) {
    case PRACTICE_EXAMPLE.EXAMPLE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PRACTICE_EXAMPLE.EXAMPLE_SUCCESS:
      return {
        ...state,
        exampleProblems: action.payload,
        loading: false,
      }
    case PRACTICE_EXAMPLE.EXAMPLE_ERROR:
      return {
        exampleProblems: [],
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getPracticeExample = (abilityId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PRACTICE_EXAMPLE.EXAMPLE_REQUEST })
      const response = await api.oldPractice.createProblems(abilityId)
      dispatch({ type: PRACTICE_EXAMPLE.EXAMPLE_SUCCESS, payload: response })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: PRACTICE_EXAMPLE.EXAMPLE_ERROR,
      })
    }
  }
}

export interface IPracticeExampleActions {
  getPracticeExample: (abilityId: string) => void
}
