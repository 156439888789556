import React, { useEffect } from 'react'

import styles from './PracticeTheory.module.scss'

import { useAutoReplayDrawBoard } from '../../DrawBoard/useAutoReplayDrawBoard'
import TheoryDrawBoard from './TheoryDrawBoard/TheoryDrawBoard'
import Markdown from '../../../components/RichDescription/RichDescription.component'
import { useUnit } from 'effector-react'
import { practiceResultsModel } from '../models/practiceResults'

const PracticeTheory = () => {
  const preview = useAutoReplayDrawBoard({ isVisible: true })
  const { theory, drawningHistory } = useUnit(practiceResultsModel.$theory)

  useEffect(() => {
    if (!drawningHistory) return

    preview.setDrawing(drawningHistory)
  }, [drawningHistory])

  return (
    <div className={styles.PracticeTheoryContainer}>
      <div className={styles.TheoryDescriptionContent}>
        <Markdown>{theory?.problem?.richDescription}</Markdown>
      </div>
      <div className={styles.PracticeTheoryDrawBoard}>
        <TheoryDrawBoard preset={preview.preset} />
      </div>
    </div>
  )
}

export default PracticeTheory
