import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import styles from './BookshelfSkeleton.module.scss'
import clsx from 'clsx'

type BookshelfSkeletonProps = {
  className?: string
}

const BookshelfSkeleton = ({ className }: BookshelfSkeletonProps) => {
  return (
    <div className={clsx(styles.Bookshelf, className)}>
      <Skeleton className={styles.Book} />
      <Skeleton className={styles.Book} />
      <Skeleton className={styles.Book} />
      <Skeleton className={styles.Button} />
    </div>
  )
}

export default BookshelfSkeleton
