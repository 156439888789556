import { BUTTON_COLOR, COLORS } from '@magmamath/ui'
import { IAnswerInfo, IMultipleSolution, IStudentSolutionStats } from 'api/types.solutions'

type GetHeaderColorProps = {
  headerColor?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
  currentAnswerInfo: IAnswerInfo | null
  solution: IMultipleSolution | IStudentSolutionStats
}
export const getHeaderColor = ({
  headerColor,
  currentAnswerInfo,
  solution,
}: GetHeaderColorProps) => {
  const attemptsCount = solution.attempt

  const isCorrect = currentAnswerInfo
    ? !!currentAnswerInfo?.correct
    : solution.answerArray?.length && solution.answerArray.every((attempt) => attempt.correct)

  if (headerColor === BUTTON_COLOR.BLUE) {
    return COLORS.PRIMARY_BLUE
  }
  if (headerColor === BUTTON_COLOR.PURPLE) {
    return COLORS.PRIMARY_PURPLE
  }

  if (isCorrect) {
    return COLORS.PRIMARY_GREEN
  }

  const isLastAttemptCorrect = !!solution.answerArray?.at(-1)?.correct
  const isAnyAttemptCorrectBeforeLast = !!solution.answerArray
    ?.slice(0, -1)
    .some((attempt) => attempt.correct)

  if (
    !currentAnswerInfo &&
    attemptsCount > 1 &&
    (isLastAttemptCorrect || isAnyAttemptCorrectBeforeLast)
  ) {
    return COLORS.PRIMARY_YELLOW
  }

  if (attemptsCount > 0) {
    return COLORS.PRIMARY_RED
  }

  return headerColor
}

export const getSolutionCardInfo = ({
  solution,
  selectedAttemptAnswerInfo,
}: {
  solution: IStudentSolutionStats
  selectedAttemptAnswerInfo: IAnswerInfo | null
}) => {
  const lastAttemptOfSolution = solution.answerArray?.at(-1)

  const isCorrect = selectedAttemptAnswerInfo
    ? selectedAttemptAnswerInfo.correct
    : !!lastAttemptOfSolution?.correct

  const currentAttemptSolutionId = selectedAttemptAnswerInfo
    ? selectedAttemptAnswerInfo._id
    : lastAttemptOfSolution?._id

  const pinSolutionId =
    selectedAttemptAnswerInfo?.selectedAttempt?.pin?.solutionId ?? lastAttemptOfSolution?._id

  return { isCorrect, currentAttemptSolutionId, pinSolutionId }
}
