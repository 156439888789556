import { createEvent, restore } from 'effector'

export class BooleanModel {
  readonly setState = createEvent<boolean>()
  readonly reset = createEvent()
  readonly $state

  constructor(defaultState = false) {
    this.$state = restore(this.setState, defaultState).reset(this.reset)
  }
}
