import React, { PropsWithChildren } from 'react'
import styles from './CreatingProblemCard.module.scss'
import clx from 'classnames'
import ErrorMessage from '../../Solutions/CommonError/ErrorMessage/ErrorMessage'

interface CreatingProblemCardProps {
  errorMessage?: string | null
  className?: string
}

const CreatingProblemCard = ({
  children,
  className,
  errorMessage,
}: PropsWithChildren<CreatingProblemCardProps>) => {
  return (
    <div className={styles.ContainerWrapper}>
      {errorMessage && <ErrorMessage title={errorMessage} />}
      <div className={clx(styles.Container, className)}>{children}</div>
    </div>
  )
}

export default CreatingProblemCard
