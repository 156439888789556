import React, { useCallback, useState, useEffect, useRef, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { COLORS } from '@magmamath/ui'
import styles from './ButtonsOptionSelect.module.scss'
import ArrowDropDownIcon from '../../icons/ArrowDropDownIcon/ArrowDropDownIcon'
import SelectedOption from './SelectedOption/SelectedOption'
import useOutsideClick from '../../../features/PracticeMode/hooks/useOutsideClick'
import { Dimensions } from './types'

const MENU_MARGIN = 8
const DROPDOWN_ARROW_WIDTH = 15

type CustomMenuSelectProps = {
  allOptionsName: string
  specialOptionName?: string
  selectedOptions: number[]
  isAllOptionsSelected: boolean
  containerDimensions?: DOMRect
  onOpenMenuCallback?: () => void
  onCloseMenuCallback?: () => void
  isSelectOverflowHidden?: boolean
  isSelectorResized?: boolean
}

const ButtonsOptionSelect = ({
  children,
  allOptionsName,
  specialOptionName,
  selectedOptions,
  isAllOptionsSelected,
  containerDimensions,
  onOpenMenuCallback,
  onCloseMenuCallback,
  isSelectOverflowHidden,
  isSelectorResized,
}: PropsWithChildren<CustomMenuSelectProps>) => {
  const selectedItemsRef = useRef<HTMLDivElement>(null)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [isContentOverflows, setIsContentOverflows] = useState(false)
  const [selectorFreeSpace, setSelectorFreeSpace] = useState<Dimensions>({
    top: 0,
    bottom: 0,
  })
  const [overflowSelectorWidth, setOverflowSelectorWidth] = useState(0)
  const [isSelectorHovered, setIsSelectorHovered] = useState(false)

  useEffect(() => {
    if (!isSelectOverflowHidden) {
      return
    }

    setOverflowSelectorWidth(ref.current?.getBoundingClientRect()?.width)
  }, [selectedOptions.length, isSelectOverflowHidden, isSelectorResized])

  useEffect(() => {
    // no need callbacks in dependencies because they will not change
    if (!isMenuOpened) {
      return
    }

    onOpenMenuCallback?.()
  }, [isMenuOpened])

  const handleClickOutside = useCallback(() => {
    if (!isMenuOpened) {
      return
    }

    onCloseMenuCallback?.()
    setIsMenuOpened(false)
  }, [isMenuOpened])

  const ref = useOutsideClick(handleClickOutside)

  useEffect(() => {
    const selectorRect = ref.current?.getBoundingClientRect()
    if (!selectorRect || !containerDimensions) {
      return
    }

    const selectorTopSpace = selectorRect.y - containerDimensions.y
    const selectorBottomSpace = containerDimensions.bottom - selectorRect.bottom
    setSelectorFreeSpace({ top: selectorTopSpace, bottom: selectorBottomSpace })
  }, [containerDimensions, isMenuOpened])

  useEffect(() => {
    if (!selectedItemsRef.current || !ref.current || !overflowSelectorWidth) {
      return
    }

    if (!selectedOptions.length) {
      setIsContentOverflows(false)
      return
    }

    const selectedItemsWidth = selectedItemsRef.current.scrollWidth
    const maxSelectedItemsWidth = ref.current.offsetWidth - (2 * MENU_MARGIN + DROPDOWN_ARROW_WIDTH)
    setIsContentOverflows(selectedItemsWidth >= maxSelectedItemsWidth)
  }, [selectedOptions.length, ref.current, selectedItemsRef.current, overflowSelectorWidth])

  const onSelectClick = () => {
    if (isMenuOpened) {
      setIsMenuOpened(false)
      onCloseMenuCallback?.()
      return
    }

    onOpenMenuCallback?.()
    setIsMenuOpened(true)
  }

  const isMenuOnTop = selectorFreeSpace.bottom < selectorFreeSpace.top
  const menuMaxHeight =
    (isMenuOnTop ? selectorFreeSpace.top : selectorFreeSpace.bottom) - MENU_MARGIN

  const menuStyle = {
    maxHeight: `${menuMaxHeight}px`,
    maxWidth: isSelectOverflowHidden ? `${overflowSelectorWidth}px` : undefined,
  }

  const isSelectorActive = isSelectorHovered || isMenuOpened
  return (
    <div
      className={clsx(styles.CustomMenuSelectContainer, {
        [styles.Hovered]: isSelectorHovered,
        [styles.Focused]: isMenuOpened,
      })}
      ref={ref}
    >
      <div
        className={styles.CustomMenuSelect}
        onClick={onSelectClick}
        onMouseOver={() => setIsSelectorHovered(true)}
        onMouseLeave={() => setIsSelectorHovered(false)}
      >
        <div
          className={clsx(styles.SelectedItemsContainer, {
            [styles.Overflows]: isContentOverflows,
          })}
          ref={selectedItemsRef}
        >
          <SelectedOption
            allOptionsSelected={isAllOptionsSelected}
            allOptionsName={allOptionsName}
            specialOptionName={specialOptionName}
            selectedOptions={selectedOptions}
            isActive={isSelectorActive}
          />
        </div>
        <div className={clsx(styles.ArrowDropdown, { [styles.Rotated]: isMenuOpened })}>
          <ArrowDropDownIcon color={isSelectorActive ? COLORS.NEUTRAL_9 : COLORS.NEUTRAL_8} />
        </div>
      </div>
      {isMenuOpened && (
        <div
          className={clsx(styles.MenuWrapper, {
            [styles.MenuOnTop]: isMenuOnTop,
            [styles.FixedPositionMenu]: isSelectOverflowHidden,
          })}
          style={menuStyle}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default ButtonsOptionSelect
