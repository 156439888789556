import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TypesOfParams } from '../../../helpers/enums'
import { IStudent } from '../../../api/types.users'
import { RootState } from '../../../store/store'
import { matchPath } from 'react-router-dom'
import { PATHS } from '../../../config/pathnames.config'
import { useHistory } from 'react-router'
import useText from '../../../i18n/hook'
import { getClass } from '../../../redux/modules/class.module'
import PracticePageFilterOptions from './PracticePageFilterOptions'
import ExercisesPageFilterOptions from './ExercisesPageFilterOptions'
import { useUnit } from 'effector-react'
import { $filterSelectorStore, FilterSelector, setFilterSelector } from './model'
import { Listbox } from '@magmamath/ui'
import styles from './Students.module.scss'
import {
  getDefaultFilterStateByPage,
  getExercisesPageInitialFilterOption,
  sortByName,
} from './helper'
import { ExercisesListModel } from 'features/ExercisesList/model'
import { IClass } from '../../../api/types.classes'

export type OptionsType = {
  name: string
  value: string
  type: TypesOfParams | null
}

export type InitialOptionType = {
  name: string
  value: string
  type: null
}

const StudentsFilter = () => {
  const classes = useSelector((state: RootState) => state.classes.data?._embedded?.classes)
  const t = useText()
  const dispatch = useDispatch()
  const history = useHistory()
  const [students, setStudents] = useState<IStudent[]>([])
  const isPracticePage = !!matchPath(history.location.pathname, PATHS.PRACTICE.PRACTICE_MAIN)
  const defaultFilterStatesByPage = getDefaultFilterStateByPage(t)
  const classesFilterSelector = useUnit($filterSelectorStore)
  const classesFilterValue = useSelector((state: RootState) => state.classesFilter)
  const filterRef = useRef<HTMLButtonElement | null>(null)

  const defaultFilterState: InitialOptionType = isPracticePage
    ? defaultFilterStatesByPage.practice
    : defaultFilterStatesByPage.exercises
  const initialOption = isPracticePage
    ? defaultFilterState
    : getExercisesPageInitialFilterOption({
      defaultState: defaultFilterState,
      currentFilter: classesFilterSelector,
      hasGoneToHeatmap: ExercisesListModel.assignmentOrder !== null
    })
  const isInitialFilterValue =
    !classesFilterSelector || defaultFilterState.value === classesFilterSelector?.value

  const studentOptions = useMemo(() => {
    return sortByName(
      students.map((student) => ({
        name: `${student.firstName} ${student.lastName}`,
        value: student._id,
        type: TypesOfParams.STUDENT,
      }))
    )
  }, [students])

  const handleResetBtn = () => {
    filterRef.current?.click()
    if (defaultFilterState.value !== classesFilterSelector?.value) {
      setFilterSelector(defaultFilterState)
    }
  }

  const onChange = (data: FilterSelector) => {
    setFilterSelector(data)
  }

  useEffect(() => {
    setFilterSelector(initialOption)
  }, [])

  useEffect(() => {
    if (classesFilterValue?.value && classesFilterValue?.value !== Listbox.ALL_OPTION) {
      dispatch(getClass(classesFilterValue.value))
    }

    if (isPracticePage) {
      setFilterSelector(defaultFilterState)
    }

    setStudents(
      (classes.find((simpleClass: IClass) => simpleClass._id === classesFilterValue?.value)
        ?.students as IStudent[]) || []
    )
  }, [classesFilterValue, isPracticePage])

  return (
    <div className={styles.ListBoxWrapper}>
      <Listbox
        classes={{ ListboxOptions: styles.ListBoxOptions }}
        value={classesFilterSelector}
        onChange={onChange}
        multiple={false}
        isActive={!isInitialFilterValue}
        ref={filterRef}
      >
        {isPracticePage ? (
          <PracticePageFilterOptions
            students={studentOptions}
            allStudents={initialOption as InitialOptionType}
          />
        ) : (
          <ExercisesPageFilterOptions students={studentOptions} onResetBtnClick={handleResetBtn} />
        )}
      </Listbox>
    </div>
  )
}

export default StudentsFilter
