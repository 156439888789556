import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/session'
import { $sectionPaths } from 'features/bookshelf/Book/model/sectionPaths'
import { $selectedProblems } from 'features/bookshelf/Book/model/selectedProblems'

type ExerciseNameStore = {
  isAutofillEnabled: boolean
  exerciseName: string
}

const setExerciseName = createEvent<ExerciseNameStore>()
const resetExerciseName = createEvent()

const $exerciseName = createStore<ExerciseNameStore>({
  isAutofillEnabled: true,
  exerciseName: '',
})
  .on(setExerciseName, (_, payload) => payload)
  .reset(resetExerciseName)

sample({
  source: {
    exerciseName: $exerciseName,
    selectedProblems: $selectedProblems,
    sectionPaths: $sectionPaths,
  },
  clock: [$selectedProblems, $sectionPaths],
  fn: ({ exerciseName, selectedProblems, sectionPaths }) => {
    if (!exerciseName.isAutofillEnabled) return exerciseName

    const selectedSection = [...selectedProblems.keys()].reduce<string[]>(
      (acc, selectedSectionId) => {
        const section = sectionPaths.get(selectedSectionId)
        const sectionProblems = selectedProblems.get(selectedSectionId)

        if (!section || !sectionProblems?.size) return acc

        acc.push(section.name)
        return acc
      },
      []
    )

    const shouldAutofill = selectedSection.length === 1

    return {
      exerciseName: shouldAutofill ? selectedSection[0] : '',
      isAutofillEnabled: true,
    }
  },
  target: setExerciseName,
})

persist({
  store: $exerciseName,
  key: sessionStorageKeys.cafExerciseName,
  serialize: JSON.stringify,
  deserialize: JSON.parse,
})

export { $exerciseName, resetExerciseName, setExerciseName }
