import React from 'react'
import { AnswerType, MyScriptCharacterTypes } from '../../../../helpers/enums'
import useText from '../../../../i18n/hook'
import { IAnswerInfo, IStudentSolutionStats } from '../../../../api/types.solutions'
import AnswerList from '../../../AnswerList/AnswerList'
import styles from '../SmallSolutionCard.module.scss'

type SmallSolutionCardFooterProps = {
  solution: IStudentSolutionStats
  characterType: MyScriptCharacterTypes
  answerType: AnswerType
  selectedAttemptAnswerInfo: IAnswerInfo | null
}
const SmallSolutionCardFooter = ({
  solution,
  characterType,
  answerType,
  selectedAttemptAnswerInfo,
}: SmallSolutionCardFooterProps) => {
  const t = useText()

  const answer = selectedAttemptAnswerInfo?.answer || solution.answer
  const lastAnswer = selectedAttemptAnswerInfo?.answer || solution.lastAnswer

  return (
    <footer className={styles.Footer}>
      {answer?.length ? (
        <div className={styles.Answer}>
          <div className={styles.AnswerList}>
            <AnswerList
              answers={lastAnswer}
              answerType={answerType}
              characterType={characterType}
              showNoAnswerText
            />
          </div>
        </div>
      ) : (
        <p className={styles.NoAnswer}>{t.notAnsweredYet}</p>
      )}
    </footer>
  )
}

export default SmallSolutionCardFooter
