import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const PencilIcon = ({ size = 20, color = COLORS.NEUTRAL_9 }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0884 0.674175C14.8206 -0.0580575 16.0078 -0.0580589 16.74 0.674174L19.3258 3.25996C20.0581 3.99219 20.0581 5.17938 19.3258 5.91161L6.05806 19.1794C5.61266 19.6248 5.00857 19.875 4.37868 19.875H1C0.516751 19.875 0.125 19.4833 0.125 19L0.125 15.6213C0.125 14.9914 0.375222 14.3873 0.820621 13.9419L14.0884 0.674175ZM15.5026 1.91161C15.4538 1.8628 15.3746 1.8628 15.3258 1.91161L13.6517 3.58579L16.4142 6.34835L18.0884 4.67417C18.1372 4.62536 18.1372 4.54621 18.0884 4.4974L15.5026 1.91161ZM15.1768 7.58579L12.4142 4.82322L2.05806 15.1794C1.94085 15.2966 1.875 15.4556 1.875 15.6213L1.875 18.125H4.37868C4.54444 18.125 4.70341 18.0592 4.82062 17.9419L15.1768 7.58579Z'
        fill={color}
      />
    </svg>
  )
}

export default PencilIcon
