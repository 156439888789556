import { IStudent } from 'api/types.users'
import { hasDisplayName } from 'helpers/classesView.helpers'
import { IClass } from '../../../../api/types.classes'
import { IGroup } from '../../../../api/api.groups'

export type ExerciseParticipantsProps = {
  addedClasses: IClass[]
  addedGroups: IGroup[]
  students: IStudent[]
}

export type ExerciseParticipantsInfo = ReturnType<typeof getExerciseParticipantsInfo>

export const getExerciseParticipantsInfo = ({
  addedClasses,
  addedGroups,
  students,
}: ExerciseParticipantsProps) => {
  const classNames = addedClasses.map((classItem) =>
    hasDisplayName(classItem) ? classItem.displayName : classItem.name
  )
  const groupNames = addedGroups.map(({ name }) => name)
  const classesAndGroupsStudents = [...addedClasses, ...addedGroups]
    .map(({ students }) => students)
    .flat()
  const singleStudentNames = students
    .filter((student) => !classesAndGroupsStudents.some(({ _id }) => _id === student._id))
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)

  const participantsLengths = [classNames, groupNames, singleStudentNames].map(
    ({ length }) => length
  )
  const sumOfParticipants = participantsLengths.reduce((acc, curr) => acc + curr, 0)
  const isShowAll = sumOfParticipants > 1 && sumOfParticipants < 4
  const isMultiRow = participantsLengths.filter((length) => length > 0).length > 1 || isShowAll
  const isSingleType =
    !isMultiRow && participantsLengths.filter((length) => length > 0).every((length) => length <= 1)
  const isTooltipVisible =
    !isShowAll &&
    (participantsLengths.filter((length) => length > 0).length > 1 ||
      participantsLengths.some((length) => length > 1))

  return {
    participantsData: {
      classNames,
      groupNames,
      singleStudentNames,
      isSingleType,
    },
    isTooltipVisible,
    isShowAll,
  }
}
