import React from 'react'
import { SvgIconProps } from '../types/types'

const PinIcon: React.FC<SvgIconProps> = ({ size = 20, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4959 0.0155689C12.079 -0.0649789 12.6757 0.172511 13.1039 0.600696L19.3993 6.89606C19.8275 7.32424 20.065 7.92095 19.9844 8.50401C19.8967 9.13925 19.4377 9.65861 18.7181 9.78983C18.14 9.89525 17.4857 9.91319 16.8212 9.82384L12.4038 14.2413C12.6131 14.8535 12.7368 15.6215 12.6982 16.4256C12.6475 17.483 12.3127 18.6616 11.4535 19.651C11.0421 20.1247 10.3523 20.0927 9.95619 19.6966L5.65432 15.3948L1.87107 19.178C1.58133 19.4678 1.11157 19.4678 0.821834 19.178C0.532096 18.8883 0.532096 18.4185 0.821834 18.1288L4.60509 14.3456L0.303227 10.0437C-0.0906088 9.64991 -0.129678 8.95345 0.357838 8.54148C1.38456 7.67384 2.65191 7.42574 3.70794 7.41015C4.49208 7.39857 5.20817 7.51388 5.71694 7.63785L10.1761 3.17874C10.0867 2.5143 10.1047 1.85995 10.2101 1.28186C10.3413 0.562289 10.8607 0.103326 11.4959 0.0155689ZM11.6882 1.48746C11.6835 1.49627 11.6759 1.51474 11.6699 1.54805C11.5776 2.05391 11.5741 2.66872 11.698 3.28811C11.7466 3.53135 11.6705 3.78282 11.4951 3.95822L6.45874 8.99451C6.26003 9.19322 5.9661 9.26261 5.69951 9.17374C5.3636 9.06177 4.59589 8.88103 3.72985 8.89382C3.03445 8.90409 2.33201 9.03759 1.73751 9.37956L10.6337 18.2757C11.0134 17.6807 11.1847 17.0082 11.2161 16.3546C11.2552 15.5373 11.0721 14.8089 10.8664 14.3976C10.7236 14.1119 10.7796 13.767 11.0054 13.5412L16.0417 8.50487C16.2172 8.32947 16.4686 8.25333 16.7119 8.30198C17.3313 8.42585 17.9461 8.42232 18.4519 8.33007C18.4852 8.324 18.5037 8.3164 18.5125 8.31174C18.5132 8.30918 18.5139 8.30564 18.5145 8.30095C18.5221 8.246 18.5014 8.09657 18.3501 7.94529L12.0547 1.64992C11.9034 1.49864 11.7539 1.47785 11.699 1.48544C11.6943 1.48609 11.6908 1.48681 11.6882 1.48746ZM11.6925 1.4808L11.691 1.48271L11.6925 1.4808ZM11.6835 1.48892L11.6825 1.48935L11.6835 1.48892ZM18.5192 8.30746L18.5173 8.30896L18.5192 8.30746ZM18.5106 8.31739L18.5111 8.31642L18.5106 8.31739Z'
        fill={color}
      />
    </svg>
  )
}

export default PinIcon
