import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'

export enum PRACTICE_ABILITY {
  SET_ACTIVE_ABILITY_ID = 'SET_ACTIVE_ABILITY_ID',
}

const initialState = {
  activeAbilityId: '',
}

export function practiceAbilityReducer(
  state: any = initialState,
  action: IAction<PRACTICE_ABILITY>
) {
  switch (action.type) {
    case PRACTICE_ABILITY.SET_ACTIVE_ABILITY_ID:
      return { activeAbilityId: action.payload }
    default:
      return state
  }
}

export const setActiveAbilityId = (id: string) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE_ABILITY.SET_ACTIVE_ABILITY_ID, payload: id })
  }
}

export interface IPracticeAbilityActions {
  setActiveAbilityId: (id: string) => void
}
