import { IDispatch } from '../../interfaces/dispatch.interface'
import { TaskType } from '../../helpers/enums'
import { api } from '../../api'

export enum DefaultAnswer {
  DEFAULT_ANSWER_REQUEST = 'DEFAULT_ANSWER_REQUEST',
  DEFAULT_ANSWER_SUCCESS = 'DEFAULT_ANSWER_SUCCESS',
  DEFAULT_ANSWER_ERROR = 'DEFAULT_ANSWER_ERROR',
}

//TODO: these dispatches are unused so whole enum can be removedm and function could be converted to simple function
export const createDefaultAnswer = (
  exerciseId: string,
  userId: string,
  taskType: TaskType,
  taskId: string,
  answer: string[]
) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: DefaultAnswer.DEFAULT_ANSWER_REQUEST })
      const res = await api.solutions.createDefaultAnswer({
        exercise: exerciseId,
        user: userId,
        [taskType]: taskId,
        answer,
      })
      dispatch({ type: DefaultAnswer.DEFAULT_ANSWER_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: DefaultAnswer.DEFAULT_ANSWER_ERROR,
      })
    }
  }
}
