import { TextField } from '@mui/material'
import { IMe } from 'api/types.users'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import ClassesList from './ClassesList/ClassesList'
import NoDataText from '../../views/Classes/components/NoDataText/NoDataText'
import styles from './ClassesTab.module.scss'
import { INPUT_STYLES } from './InputStyles'
import { hasSearchedValue } from 'lib/filters'
import { ActionElement } from '../../helpers/enums'
import { getOwnerDetails } from './helpers/getOwnerDetails'
import Spinner from '../Spinner/Spinner'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import { openCreateClassModal } from '../../redux/modules/modal.module'
import PlusIcon from '../../ui/icons/PlusIcon/PlusIcon'
import { IClass, SourceType } from '../../api/types.classes'

export enum ClassTypes {
  USER_CLASSES = 'USER_CLASSES',
  OTHER_CLASSES = 'OTHER_CLASSES',
}

type ClassesTabProps = {
  me: IMe
}

const ClassesTab = ({ me }: ClassesTabProps) => {
  const dispatch = useDispatch()
  const localization = useSelector((state: RootState) => state.localization)
  const userClassesState = useSelector((state: RootState) => state.classes)
  const schoolClassesState = useSelector((state: RootState) => state.schoolClasses)
  const [searchInputValue, setSearchInputValue] = useState('')
  const t = localization.data

  const userClasses = (userClassesState.data?._embedded?.classes as IClass[]) ?? []
  const schoolClasses = schoolClassesState.data || []

  const isLoadingClassesData =
    (userClassesState.loading || schoolClassesState.loading) && isEmpty(userClasses)

  const activeUserClasses = useMemo(() => {
    return userClasses.filter((userClass) => {
      const classShouldNotBeShown =
        userClass.archived ||
        (userClass.source === SourceType.matteappen &&
          !userClass.owner &&
          !me.municipality.isPrivate)

      if (classShouldNotBeShown) return false
      const ownerId = (userClass.owner?._id ?? userClass.owner) as string // owner sometimes is a string (ID) and sometimes an object IClass['owner']
      userClass.owner = getOwnerDetails({
        ownerId,
        teacher: me,
        schoolClasses,
        currentClass: userClass,
      })
      return compact([ownerId, ...userClass.teachers]).includes(me?._id)
    })
  }, [userClasses])

  const otherClasses = useMemo(() => {
    const userClassesIds = activeUserClasses.map((userClass) => userClass._id)
    if (!Array.isArray(schoolClasses)) return [] //schoolClasses = {schoolClasses:[]} on the beginning and IClass[] after fetch
    return schoolClasses.filter((schoolClass) => {
      const shouldShowClassWithoutOwner = me.municipality.isPrivate
      const isInUserClass = userClassesIds.includes(schoolClass._id)
      const hasOwner = !!schoolClass.owner && typeof schoolClass.owner !== 'string'
      const isArchived = schoolClass.archived === true

      if (shouldShowClassWithoutOwner) return !isArchived && !isInUserClass
      return !isArchived && !isInUserClass && hasOwner
    })
  }, [activeUserClasses, schoolClasses])

  const numberOfRenderedClasses = useMemo(
    () =>
      [...activeUserClasses, ...otherClasses].filter((schoolClass) =>
        hasSearchedValue(
          [schoolClass.name, schoolClass.owner?.firstName ?? '', schoolClass.owner?.lastName ?? ''],
          searchInputValue
        )
      ).length,
    [userClasses, schoolClasses, searchInputValue]
  )

  if (isLoadingClassesData) {
    return <Spinner className={styles.Spinner} />
  }

  if (isEmpty(userClasses) && isEmpty(schoolClasses))
    return <NoDataText text={t.noSchoolClassesTxt} />

  return (
    <>
      <div className={styles.Header}>
        <TextField
          fullWidth
          placeholder={t.searchClassesTxt}
          onChange={(e) => setSearchInputValue(e.target.value)}
          value={searchInputValue}
          InputProps={INPUT_STYLES}
        />
        <Button
          classes={{
            button: styles.NewClassBtn,
          }}
          size={SIZE.MEDIUM}
          variant={BUTTON_VARIANT.PRIMARY}
          color={BUTTON_COLOR.ORANGE}
          onClick={() =>
            dispatch(openCreateClassModal(t.newClassTxt, ActionElement.createClass, me))
          }
          icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
          iconAlign={ICON_ALIGNMENT.RIGHT}
        >
          {t.newClassTxt}
        </Button>
      </div>

      {numberOfRenderedClasses !== 0 ? (
        <div className={styles.ClassesContainer}>
          {!isEmpty(activeUserClasses) && (
            <ClassesList
              classesType={ClassTypes.USER_CLASSES}
              searchInputValue={searchInputValue}
              classList={activeUserClasses}
              title={t.myClassesTxt}
              me={me}
            />
          )}

          {!isEmpty(otherClasses) && (
            <ClassesList
              classesType={ClassTypes.OTHER_CLASSES}
              searchInputValue={searchInputValue}
              classList={otherClasses}
              title={!!activeUserClasses.length ? t.schoolClassesTxt : ''}
              me={me}
            />
          )}
        </div>
      ) : (
        <NoDataText text={t.noClassesFound} containerClassName={styles.NoClassesFoundContainer} />
      )}
    </>
  )
}

export default ClassesTab
