import CloseIcon from '../../Icons/CloseIcon'
import Fab from '@mui/material/Fab'
import React from 'react'

type ExitButtonProps = {
  onPress?: () => void
  className?: string
}

export default function ExitButton({ onPress, className }: ExitButtonProps) {
  return (
    <div className={className}>
      <Fab onClick={onPress} size='small' color='primary' aria-label='add'>
        <CloseIcon />
      </Fab>
    </div>
  )
}
