import { IChapter } from '../../../api/types.books'
import { ISection } from '../../../api/types.books'

export const getNewSelectedSection = (removedSection: ISection, chapter: IChapter): ISection => {
  const removedSectionIndex = chapter.sections.findIndex(
    (section) => section._id === removedSection._id
  )
  return removedSectionIndex === chapter.sections.length - 1
    ? chapter.sections[removedSectionIndex - 1]
    : chapter.sections[removedSectionIndex + 1]
}
