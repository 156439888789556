import { useState, useEffect } from 'react'

const useTouchScreenDetection = (): boolean => {
  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    const checkTouch = () => {
      if (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        // @ts-ignore
        navigator.msMaxTouchPoints > 0
      ) {
        setIsTouch(true)
      } else {
        setIsTouch(false)
      }
    }

    checkTouch()

    window.addEventListener('resize', checkTouch)

    return () => {
      window.removeEventListener('resize', checkTouch)
    }
  }, [])

  return isTouch
}

export default useTouchScreenDetection
