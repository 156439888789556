import clsx from 'clsx'
import StudentsSettingsColumn from 'features/heatmap/ExamMode/ui/StudentsSettingsColumn/StudentsSettingsColumn'
import {
  HeatmapTestIds,
  MergedProblemsAndSubProblems,
  OnHoverStateChangeParams,
} from 'features/tables/HeatmapTable/types'
import { IStudent, ITeacher } from 'api/types.users'
import React, { memo } from 'react'
import { SHADOW_COVER } from '../../constants'
import ProblemsColumns from './ProblemsColumns'
import StudentsColumn from './StudentsColumn'
import styles from './Table.module.scss'
import { IStudentsExamStats } from '../../../../../api/types.statistics'
import { ExerciseState } from '../../../../../api/types.assignments'

type TableContentProps = {
  exerciseId: string
  isAnonymousNameMode: boolean
  isCompressedMode: boolean
  isExamMode: boolean
  onHeaderElementHoverChange: (params: OnHoverStateChangeParams) => void
  mergedProblemsAndSubProblems: MergedProblemsAndSubProblems
  rowsUsers: (IStudent | ITeacher)[]
  tableListHeight: number
  studentsExamStatsMap?: Map<string, IStudentsExamStats>
  exerciseState?: ExerciseState
}

const TableContent = ({
  exerciseId,
  isAnonymousNameMode,
  isCompressedMode,
  isExamMode,
  exerciseState,
  onHeaderElementHoverChange,
  mergedProblemsAndSubProblems,
  rowsUsers,
  tableListHeight,
  studentsExamStatsMap,
}: TableContentProps) => {
  return (
    <>
      <div
        className={clsx(styles.StickyLeft, styles.FlexGapColumn, {
          [styles.ExamModeColumns]: isExamMode && !isCompressedMode,
        })}
        style={{ height: tableListHeight + SHADOW_COVER }}
        data-testid={HeatmapTestIds.STUDENTS_COLUMN}
      >
        <StudentsSettingsColumn
          exerciseId={exerciseId}
          isCompressedMode={isCompressedMode}
          isExamMode={isExamMode}
          rowsUsers={rowsUsers}
          studentsExamStatsMap={studentsExamStatsMap}
          exerciseState={exerciseState}
        >
          <StudentsColumn
            exerciseId={exerciseId}
            isAnonymousNameMode={isAnonymousNameMode}
            isExamMode={isExamMode}
            mergedProblemsAndSubProblems={mergedProblemsAndSubProblems}
            onHeaderElementHoverChange={onHeaderElementHoverChange}
            rowsUsers={rowsUsers}
            studentsExamStatsMap={studentsExamStatsMap}
          />
        </StudentsSettingsColumn>
      </div>

      <ProblemsColumns
        exerciseId={exerciseId}
        isExamMode={isExamMode}
        mergedProblemsAndSubProblems={mergedProblemsAndSubProblems}
        rowsUsers={rowsUsers}
        tableListHeight={tableListHeight}
        onHeaderElementHoverChange={onHeaderElementHoverChange}
      />
    </>
  )
}

export default memo(TableContent)
