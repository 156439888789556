import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IStatistic } from '../../../../api/types.oldPractice'
import { GalleryCard } from '@magmamath/ui'
import {
  getLastUserSolution,
  getPracticeResults,
} from '../../../../redux/modules/practiceResults.module'
import { getPracticeExample } from '../../../../redux/modules/practiceExampleSolution'
import { getPracticeTheoryData } from '../../../../redux/modules/practiceTheory.module'
import { RootState } from '../../../../store/store'
import { IStudent } from '../../../../api/types.users'
import { openOldPracticeResultsModal } from '../../../../redux/modules/modal.module'
import { ResultsView, TypesOfParams } from '../../../../helpers/enums'
import { useUnit } from 'effector-react'
import { $filterSelectorStore } from '../../../filters/StudentsFilter/model'
import CardStats from './CardStats/CardStats'

enum StatisticTypes {
  CLASSES = 'classes',
  GROUPS = 'groups',
}

export type AbilityCardProps = {
  ability: {
    _id: string
    primaryName: string
  }
  stats: IStatistic
  selectedStudents: IStudent[] | []
}

const AbilityCard = ({ ability, stats, selectedStudents }: AbilityCardProps) => {
  const dispatch = useDispatch()
  const classFilterValue = useSelector((state: RootState) => state.classesFilter)
  const studentsFilterValue = useUnit($filterSelectorStore)
  const selectedOption = studentsFilterValue?.type ? studentsFilterValue : classFilterValue

  const loadPracticeResultsData = () => {
    dispatch(
      getPracticeResults({
        abilityId: ability._id,
        urlType: StatisticTypes.CLASSES,
        filterOptionId: classFilterValue.value,
      })
    )
    dispatch(getPracticeTheoryData(ability._id))
    dispatch(getPracticeExample(ability._id))
  }

  const loadSingleStudentResults = async () => {
    await dispatch(getLastUserSolution(ability._id, selectedOption.value))
  }

  const onCardClick = async () => {
    const practiceResultModalParams = {
      abilityId: ability._id,
      abilityName: ability.primaryName,
      selectedStudents,
    }

    loadPracticeResultsData()
    if (selectedOption?.type !== TypesOfParams.STUDENT) {
      dispatch(openOldPracticeResultsModal(practiceResultModalParams))
      return
    }

    await loadSingleStudentResults()
    dispatch(
      openOldPracticeResultsModal({
        ...practiceResultModalParams,
        resultView: ResultsView.SingleStudentResult,
        studentName: selectedOption.name,
      })
    )
  }

  const isStudentSelected = selectedOption.type === TypesOfParams.STUDENT
  const isCardDisabled = isStudentSelected && !stats

  return (
    <GalleryCard
      title={ability.primaryName}
      onClick={onCardClick}
      disabled={isCardDisabled}
      rightContent={<CardStats isStudentSelected={isStudentSelected} stats={stats} />}
    />
  )
}

export default AbilityCard
