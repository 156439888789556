import { IStudent } from 'api/types.users'
import React from 'react'
import QRCode from 'react-qr-code'
import './ComponentToPrint.scss'
import { getFullName } from 'helpers/user.helpers'
import { ILocalization } from '../../config/languages.config'
import { IClass } from '../../api/types.classes'

export interface IComponentToPrintClassProps {
  qrCodesData: { userId: string; key: string }[]
  printedClass: IClass
  title: string
  localization: ILocalization
  isArchivedClass: boolean
}

const QR_CODE_STYLES = {
  size: 120,
  containerStyle: {
    height: 'auto',
    maxWidth: '13.1vh',
    width: '100%',
  },
  svgStyle: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
    paddingTop: '10px',
  },
  viewBox: '0 0 120 120',
}

export default class ComponentToPrintClass extends React.Component<
  IComponentToPrintClassProps,
  any
> {
  public renderSubtitle = (classEl: IClass) => {
    const { classcode } = this.props.localization!.data
    return (
      <p className='overlay-title'>
        <span>{classcode}:</span> <span>{classEl.invitationalCode}</span>
      </p>
    )
  }

  public renderClassList = (classEl: IClass) => {
    if (this.props.isArchivedClass) return null

    return (
      classEl.students &&
      classEl.students.map((student: IStudent, index) => {
        const qrCodeObject = this.props.qrCodesData.find((qrCode) => qrCode.userId === student._id)
        const qrCodeString = qrCodeObject?.key ?? null
        const usernameOrEmail = student.username || student.email
        const marginTopInPx = classEl.qrCodeLogin && index ? (index % 5 ? 0 : 70) : 0
        const fullName = getFullName(student.firstName, student.lastName)

        return (
          <li key={student._id}>
            <div className='list-link-item student-row' style={{ marginTop: marginTopInPx }}>
              <div>
                {!!fullName && <span>{fullName} </span>}
                {!!usernameOrEmail && <span>({usernameOrEmail})</span>}
              </div>
              {qrCodeString && classEl.qrCodeLogin && (
                <div style={QR_CODE_STYLES.containerStyle}>
                  <QRCode
                    size={QR_CODE_STYLES.size}
                    style={QR_CODE_STYLES.svgStyle}
                    value={qrCodeString}
                    viewBox={QR_CODE_STYLES.viewBox}
                  />
                </div>
              )}
            </div>
          </li>
        )
      })
    )
  }

  public render() {
    const { printedClass } = this.props

    return (
      <div className='print-content'>
        <div className='title-wrapper'>
          <h5 className='overlay-title'>{this.props.title}</h5>
          {this.renderSubtitle(printedClass)}
        </div>
        <ol className='overlay-list'>{this.renderClassList(printedClass)}</ol>
      </div>
    )
  }
}
