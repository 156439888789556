import { BUTTON_COLOR } from '@magmamath/ui'
import CommonError from 'features/Solutions/CommonError/CommonError'
import React from 'react'
import styles from './Problem.module.scss'
import ProblemCard from './ProblemCard/ProblemCard'
import ProblemNavigation from './ProblemNavigation/ProblemNavigation'
import { IProblem, ISubProblem } from '../../../api/types.problem'

type ProblemProps = {
  problem: IProblem
  subProblem: ISubProblem | null
  exerciseId: string
  buttonColor: BUTTON_COLOR
  isCorrectnessHidden: boolean
  nextLink?: string
  prevLink?: string
}
const Problem = ({
  problem,
  subProblem,
  exerciseId,
  buttonColor,
  isCorrectnessHidden,
  nextLink,
  prevLink,
}: ProblemProps) => {
  const problemName = subProblem ? `${problem?.name}${subProblem.name}` : problem?.name
  return (
    <div className={styles.Problem}>
      {nextLink || prevLink ? (
        <ProblemNavigation
          problemName={problemName}
          nextLink={nextLink}
          prevLink={prevLink}
          buttonColor={buttonColor}
        />
      ) : (
        <p className={styles.ProblemTitle}>{problemName}</p>
      )}
      <ProblemCard
        problem={problem}
        subProblem={subProblem}
        exerciseId={exerciseId}
        buttonColor={buttonColor}
        isCorrectnessHidden={isCorrectnessHidden}
      />
      {!isCorrectnessHidden && (
        <CommonError problem={subProblem || problem} characterType={problem.characterType} />
      )}
    </div>
  )
}

export default Problem
