import React, { LegacyRef } from 'react'

import { omit, truncate } from 'lodash'
import { IMergedSkillsStatsAndDataItem } from '../../../SkillsTable'

interface ISectionTitleProps {
  item: IMergedSkillsStatsAndDataItem
  onClickHandler: (skillId: string) => void
  limitLettersForSections: number
}

const SectionTitle: React.FC<ISectionTitleProps> = React.forwardRef((props, ref) => {
  const tooltipProps = omit(props, ['item', 'onClickHandler', 'limitLettersForSections'])
  return (
    <div
      onClick={() => props.onClickHandler(props.item.skillId)}
      {...tooltipProps}
      ref={ref as LegacyRef<HTMLDivElement> | undefined}
      className='section-title'
    >
      {truncate(props.item.name, { length: props.limitLettersForSections })}
    </div>
  )
})

export default SectionTitle
