import React from 'react'
import { SvgIconProps } from '../types/types'

const HelpIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_122_1637)'>
        <path
          d='M12.4951 24.1093C18.9084 24.1093 24.1074 18.9103 24.1074 12.497C24.1074 6.08376 18.9084 0.884766 12.4951 0.884766C6.08181 0.884766 0.882812 6.08376 0.882812 12.497C0.882812 18.9103 6.08181 24.1093 12.4951 24.1093Z'
          stroke={color}
          strokeWidth='1.65888'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.69019 9.3724C10.1068 8.65905 10.4728 8.21606 10.8753 7.94066C11.2586 7.67829 11.7463 7.5201 12.5012 7.5201C14.2968 7.5201 15.199 8.71155 15.199 9.70771C15.199 10.2476 15.0617 10.6048 14.6627 11.0144C14.2046 11.4845 13.4235 11.9983 12.09 12.7595C11.8315 12.9071 11.6718 13.182 11.6718 13.4799V15.8426C11.6718 16.3008 12.0431 16.6721 12.5013 16.6721C12.9595 16.6721 13.3308 16.3008 13.3308 15.8426V14.4359C13.3308 14.1408 13.4882 13.8687 13.7412 13.7167C14.6459 13.1736 15.3506 12.6859 15.8512 12.1721C16.5545 11.4502 16.8581 10.6765 16.8581 9.70782C16.8581 7.68698 15.1008 5.86133 12.5014 5.86133C11.4927 5.86133 10.6577 6.07978 9.93869 6.57175C9.23886 7.05056 8.72316 7.73936 8.25786 8.53609C8.02684 8.93164 8.16026 9.43959 8.55581 9.67059C8.95135 9.9016 9.4593 9.76818 9.69031 9.37264L9.69019 9.3724Z'
          fill={color}
        />
        <path
          d='M12.4977 19.2445C13.0703 19.2445 13.5345 18.8431 13.5345 18.3479C13.5345 17.8527 13.0703 17.4512 12.4977 17.4512C11.9252 17.4512 11.4609 17.8527 11.4609 18.3479C11.4609 18.8431 11.9252 19.2445 12.4977 19.2445Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_122_1637'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HelpIcon
