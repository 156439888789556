import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import * as React from 'react'
import MyBook from 'views/Content/MyBook/MyBook.view'
import ProblemCreator from '../../screens/ProblemCreator/ProblemCreator'
import BookSeries from '../../views/Content/BookSeries/BookSeries.view'
import { IState } from 'interfaces/state.interface'
import { IProblems } from 'redux/modules/problems.module'
import { ISection } from '../../api/types.books'

export const ContentRoutes = [
  {
    exact: true,
    path: PATHS.CONTENT.BOOKSERIES,
    render: (props: any) => {
      const contentBooksProps: any = {
        bookSeries: props.bookSeries,
        bookSeriesActions: props.bookSeriesActions,
        bookSeriesNames: props.bookSeriesNames,
        myBooksActions: props.myBooksActions,
        myBooks: props.myBooks,
        history: props.history,
        localization: props.localization,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
        notification: props.notify,
      }
      return <BookSeries {...contentBooksProps} />
    },
  },
  {
    exact: true,
    path: PATHS.CONTENT.PROBLEMS,
    render: (props: { problems: IState<IProblems>; section: IState<ISection> }) => {
      return <MyBook problems={props.problems} section={props.section} />
    },
  },
  {
    exact: true,
    path: PATHS.CONTENT.ADD_NEW_PROBLEM,
    render: (props: any) => {
      return <ProblemCreator />
    },
  },
  {
    exact: true,
    path: '/content/problems/edit/:id',
    render: (props: any) => {
      return <ProblemCreator />
    },
  },
]
