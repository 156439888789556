import React from 'react'
import { SvgIconProps } from '../types/types'

const TimeUnitIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_216)'>
        <path
          d='M12.496 24.1677C18.9399 24.1677 24.1638 18.9438 24.1638 12.4999C24.1638 6.0559 18.9399 0.832031 12.496 0.832031C6.05199 0.832031 0.828125 6.0559 0.828125 12.4999C0.828125 18.9438 6.05199 24.1677 12.496 24.1677Z'
          stroke={color}
          strokeWidth='1.66432'
        />
        <path
          d='M12.4961 6.875V12.3277C12.4961 12.438 12.5399 12.5438 12.618 12.6219L15.7252 15.7291'
          stroke={color}
          strokeWidth='1.66432'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_216'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TimeUnitIcon
