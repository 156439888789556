import { ButtonsType } from '../helpers/enums'
import { SourceType } from '../api/types.classes'

export const shouldShowBtn = (
  isRestricted?: boolean,
  sourceType?: SourceType,
  buttonType?: ButtonsType
) => {
  if (typeof isRestricted === 'boolean' && !isRestricted) return true

  const btnTypes = [ButtonsType.UNFOLLOW_CLASS, ButtonsType.SWITCH_SCHOOL, ButtonsType.SHARE_CLASS]

  if (sourceType === SourceType.mathSync || sourceType === SourceType.ss12000) {
    return buttonType ? btnTypes.includes(buttonType) : false
  }

  return (
    !(
      sourceType === SourceType.skolon ||
      sourceType === SourceType.clever ||
      sourceType === SourceType.edlink
    ) || !sourceType
  )
}
