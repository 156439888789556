import React, { PropsWithChildren, useEffect, useState } from 'react'
import styles from './SelectUnit.module.scss'
import PlusIcon from '../../icons/PlusIcon/PlusIcon'
import { replaceSpecialSymbolsToHTMLElements } from '../../inputs/ContentEditableArea/ContentEditable.helpers'
import clsx from 'clsx'
import useText from '../../../i18n/hook'
import TrashIcon from '../../icons/TrashIcon/TrashIcon'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { COLORS } from '@magmamath/ui'

interface SelectUnitProps {
  value: string | null
  isOpenedExt: boolean
  onModalStateChange: (param: boolean) => void
  onRemoveButtonPress: (param: string | null) => void
  className?: string
}

const SelectUnit = ({
  value,
  isOpenedExt,
  onModalStateChange,
  className,
  children,
  onRemoveButtonPress,
}: PropsWithChildren<SelectUnitProps>) => {
  const t = useText()
  const closeSelect = () => {
    if (!isOpenedExt) {
      return
    }
    onModalStateChange(false)
  }
  const ref = useDetectClickOutside({ onTriggered: closeSelect })
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(isOpened)
  }, [isOpenedExt])

  useEffect(() => {
    onModalStateChange(isOpened)
  }, [isOpened])

  return (
    <div className={clsx(styles.Container, className)} ref={ref}>
      <div
        className={styles.Wrapper}
        onClick={() => {
          onModalStateChange(!isOpenedExt)
        }}
      >
        {value ? (
          <div dangerouslySetInnerHTML={{ __html: replaceSpecialSymbolsToHTMLElements(value) }} />
        ) : (
          t.unitTxt
        )}
        <PlusIcon size={15} color={COLORS.NEUTRAL_8} />
      </div>
      {isOpenedExt && <div className={styles.ChildrenWrapper}>{children}</div>}
      {value && (
        <div
          className={styles.IconWrapper}
          onClick={() => {
            onRemoveButtonPress(null)
          }}
        >
          <TrashIcon size={15} color={COLORS.NEUTRAL_8} />
        </div>
      )}
    </div>
  )
}

export default SelectUnit
