import { renderInputField } from 'helpers/form.helpers'
import { checkFirefox, checkMicrosoftEdge } from 'helpers/general'
import { IState } from 'interfaces/state.interface'
import React from 'react'
import { ModalBody, ModalFooter } from 'reactstrap'
import { Field, SubmitHandler } from 'redux-form'
import { IModal } from 'redux/modules/modal.module'
import AddExistingStudentLink from '../AddExistingStudentLink/AddExistingStudentLink'
import { AddStudentStage } from '../ModalRegisterWindow.component'
import StageHeader from '../StageHeader/StageHeader'
import './AddStudentDirectly.scss'
import { ILocalization } from '../../../../config/languages.config'
import { IClass } from '../../../../api/types.classes'

type AddStudentDirectlyProps = {
  localization: ILocalization
  onSubmit: (values: any, classId: string, localization: ILocalization) => {}
  modal?: IState<IModal>
  addedClass?: IState<IClass>
  pristine: boolean
  invalid: boolean
  handleSubmit: SubmitHandler<FormData, any, string>
  activeStage: AddStudentStage
  setActiveStage: (tabId: AddStudentStage) => void
  reset: () => void
  onAddExistingStudentClick: () => void
}

const AddStudentDirectly = ({
  localization,
  onSubmit,
  modal,
  addedClass,
  pristine,
  invalid,
  handleSubmit,
  activeStage,
  setActiveStage,
  reset,
  onAddExistingStudentClick,
}: AddStudentDirectlyProps) => {
  const { firstNameTxt, lastNameTxt, userNameTxt, saveTxt, passwordTxt } = localization.data

  const isMEBrowser = !!checkMicrosoftEdge()
  const isFirefox = !!checkFirefox()
  const setTypeForPasswordField = isFirefox || isMEBrowser ? 'password' : 'text'

  const submit = (values: any) => {
    values.phone = 0
    values.type = 2
    values.schoolId = modal!.data.me.school._id
    values.invitationalCode = addedClass!.data.invitationalCode
    onSubmit(values, addedClass!.data._id, localization)
    reset()
    setActiveStage(AddStudentStage.SUCCESS)
  }

  return (
    <form className='register-form' onSubmit={handleSubmit(submit)}>
      <StageHeader
        localization={localization}
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
      <ModalBody className='custom-body register-body'>
        <div className='form-group input-wrapper'>
          <Field
            name='firstName'
            component={renderInputField as any}
            type='text'
            placeholder={firstNameTxt}
            autoComplete='off'
            className='form-control modal-default-input'
            id='firstName'
          />
        </div>
        <div className='form-group input-wrapper'>
          <Field
            name='lastName'
            component={renderInputField as any}
            type='text'
            placeholder={lastNameTxt}
            autoComplete='off'
            className='form-control modal-default-input'
            id='lastName'
          />
        </div>
        <div className='form-group input-wrapper'>
          <Field
            name='username'
            component={renderInputField as any}
            type='text'
            placeholder={userNameTxt}
            autoComplete='off'
            className='form-control modal-default-input'
            id='username'
          />
        </div>
        <div className='form-group input-wrapper'>
          <Field
            name='password'
            component={renderInputField as any}
            type={setTypeForPasswordField}
            autoComplete='off'
            placeholder={passwordTxt}
            className='form-control modal-default-input'
            id='password'
          />
        </div>
      </ModalBody>
      <ModalFooter className='custom-footer register-footer'>
        <button type='submit' className='custom-create-button' disabled={pristine || invalid}>
          <span>{saveTxt}</span>
        </button>
        <AddExistingStudentLink
          onAddExistingStudentClick={onAddExistingStudentClick}
          localization={localization}
        />
      </ModalFooter>
    </form>
  )
}

export default AddStudentDirectly
