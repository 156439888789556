import {
  BoardController,
  DrawBoard,
  DrawBoardMode,
  DrawingHistoryMode,
  ToolBarVariants,
} from '@magmamath/react-native-draw-board'
import React, { useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router'
import { IMatch } from '../../interfaces/match.inteface'
import CloseDrawBoardButton from './CloseDrawBoardButton'
import { useDrawBoardPersist } from './hook.persist'
import styles from './styles.module.scss'
import { ExtendedSolutionProps, SolutionLocationProps } from './types'
import { useDrawBoardAutoReplay } from './hook.autoReplay'

export type ExtendedSolutionRouteProps = {
  match: IMatch<SolutionLocationProps>
} & ExtendedSolutionProps

export default function ExtendedSolution({ match: { params } }: ExtendedSolutionRouteProps) {
  const { studentId, exerciseId, problemId, subProblemId } = params
  const {
    location: { state },
  } = useHistory<ExtendedSolutionProps>()
  const ref = useRef<BoardController>(null)
  const credits = useMemo(
    () => ({
      userId: studentId,
      subProblemId: subProblemId ?? null,
      problemId,
      exerciseId,
    }),
    [subProblemId, studentId, problemId, exerciseId]
  )

  useDrawBoardPersist(ref, credits)
  useDrawBoardAutoReplay(ref)

  return (
    <div className={styles.Container}>
      <CloseDrawBoardButton />
      <DrawBoard
        fitToDrawing={true}
        imgSrc={state?.imgSrc}
        disabled={true}
        toolsSettings={{
          variant: ToolBarVariants.REPLAY,
        }}
        historySettings={{
          mode: DrawingHistoryMode.LINEAR_BRANCHES,
        }}
        initialMode={DrawBoardMode.MOVE}
        ref={ref}
      />
    </div>
  )
}
