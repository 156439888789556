import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/session'
import { sortProblemsBySortOption } from '../helpers'
import { selectedProblemsDataModel } from './selectedProblemsData'
import { savedSortOptionModel } from './sortOption/savedSortOption'
import { SortOptions } from './sortOption/sortOptionModel'

export class savedSortedProblemsIdModel {
  static readonly set = createEvent<null | string[]>()
  static readonly reset = createEvent()
  static readonly $state = restore(this.set, null).reset(this.reset)
}

persist({
  store: savedSortedProblemsIdModel.$state,
  key: sessionStorageKeys.cafSavedSortedProblemsId,
})

sample({
  source: {
    selectedProblemsData: selectedProblemsDataModel.$state,
    savedSortOption: savedSortOptionModel.$state,
    savedSortedProblemsId: savedSortedProblemsIdModel.$state,
  },
  clock: [selectedProblemsDataModel.$state, savedSortOptionModel.$state],
  fn: ({ savedSortedProblemsId, savedSortOption, selectedProblemsData }) => {
    if (!selectedProblemsData || !savedSortedProblemsId || !savedSortOption) {
      return savedSortedProblemsId
    }

    if (savedSortOption !== SortOptions.MANUAL) {
      const sortedProblemsBySortOption = sortProblemsBySortOption(
        selectedProblemsData,
        savedSortOption
      )
      return Array.from(sortedProblemsBySortOption.values()).map((problem) => problem._id)
    }

    const selectedIds = new Set(selectedProblemsData.keys())
    const updatedSavedSortedProblemsId = savedSortedProblemsId.filter((problemId) =>
      selectedIds.has(problemId)
    )
    selectedProblemsData.forEach((problem) => {
      if (!savedSortedProblemsId.some((problemId) => problemId === problem._id)) {
        updatedSavedSortedProblemsId.push(problem._id)
      }
    })
    return updatedSavedSortedProblemsId
  },
  target: savedSortedProblemsIdModel.$state,
})
