import { createEvent, createStore } from 'effector'
import { updatePinState } from './isPinsFilterActive'
import {
  handleAddedPin,
  handleCurrentPinsDataCleared,
  handleCurrentPinsSetFromMemory,
  handleDeletedPin,
  handleFetchedPinsData,
} from './pinsStoreHandlers'
import { addPinFx, deletePinFx, fetchPinsDataFx } from './requests'
import { PinsDataStore, SetCurrentPinsFromMemoryParams, UpdatePinParams } from './types'

const defaultState: PinsDataStore = {
  exerciseId: null,
  currentPinsData: null,
  memoizedPins: null,
}

export const updatePin = createEvent<UpdatePinParams>()
export const clearCurrentPinsData = createEvent()
export const setCurrentPinsFromMemory = createEvent<SetCurrentPinsFromMemoryParams>()

export const $pinsData = createStore<PinsDataStore>(defaultState)
  .on(clearCurrentPinsData, handleCurrentPinsDataCleared)
  .on(fetchPinsDataFx.doneData, handleFetchedPinsData)
  .on(addPinFx.doneData, handleAddedPin)
  .on(deletePinFx.doneData, handleDeletedPin)
  .on(setCurrentPinsFromMemory, handleCurrentPinsSetFromMemory)

updatePin.watch((params) => {
  if ('pinId' in params && params.pinId) return deletePinFx(params)
  return addPinFx(params)
})

$pinsData.watch((pins) => {
  if (!pins.currentPinsData?.pins?.length) {
    updatePinState(false)
  }
})
