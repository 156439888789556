import { IStudent } from 'api/types.users'
import { config } from 'config'
import { localStorageKeys } from 'config/localStorageKeys'
import dayjs from 'dayjs'
import { SortOptions } from 'features/bookshelf/ReorderProblems/model/sortOption/sortOptionModel'
import { ExerciseSettings } from 'features/caf/exerciseSettings/types'
import { getStartAndEndDates } from 'features/caf/exerciseStatus/model/helpers'
import { formatClassesStoreToCollectionId } from 'features/modals/ClassroomModal/helpers'
import { ClassesStoreItem } from 'features/modals/ClassroomModal/types'
import _ from 'lodash'
import React from 'react'
import { IExerciseActions } from 'redux/modules/exercise.module'
import {
  AddExerciseData,
  EditExerciseData,
  ExerciseState,
  ExerciseStatuses,
  GetSingleStatsResponse,
  IExercise,
} from '../api/types.assignments'
import { IProblem, ISubProblem } from '../api/types.problem'
import { ICollectionId } from '../redux/modules/choosing.stud.module'
import { IExerciseWithoutStats } from '../redux/modules/exerciseWithoutStats.module'
import { IExercisesStatusActions } from '../redux/modules/exercisesStatuses.module'
import { CalculatorType } from './enums'
import { getItemFromLocalStorage, getItemFromLocalStorageWithParsing } from './storage.helper'
import { getTeacherData } from './user.helpers'

function sortClassesStoreItemsByClassName(classesStoreItems: ClassesStoreItem[]) {
  return [...classesStoreItems].sort((obj1, obj2) => {
    const name1 = obj1?.classStore?.name
    const name2 = obj2?.classStore?.name
    if (!name1 || !name2) return -1
    return name2.localeCompare(name1)
  })
}

type PrepareExerciseRequestData = {
  classesStore: ClassesStoreItem[]
  settings: ExerciseSettings
  exerciseStatus: ExerciseStatuses
  selectedProblems: string[]
  exerciseId?: string
  exerciseName: string
  isDuplicateExercise?: boolean
  savedSortOption: SortOptions | null
}

export const prepareExerciseRequestData = ({
  classesStore,
  settings,
  exerciseStatus,
  selectedProblems,
  exerciseName,
  isDuplicateExercise,
  savedSortOption,
}: PrepareExerciseRequestData) => {
  const { startDate, endDate } = getStartAndEndDates({
    exerciseStatus,
    settings,
    isDuplicateExercise,
  })
  const { collectionId, selectedClassesCount } = formatClassesStoreToCollectionId(classesStore)

  const baseAddExerciseData: Omit<
    AddExerciseData,
    'allClassroomStudents' | 'classes' | 'groups' | 'students'
  > = {
    drawBoardAvailableFeatures: { calculator: settings.calculatorType || undefined },
    name: exerciseName,
    problems: selectedProblems,
    startDate,
    endDate,
    testModeEnabled: settings.isExamMode,
    cheatDetection: settings.cheatDetection,
    allowedAnswerLooking: settings.allowAnswerChecking,
    isRequiredDrawing: settings.forcedDrawing,
    isRequiredUnit: settings.requireUnit,
    exerciseStatus,
    isDuplicateExercise,
    problemsSortOption: savedSortOption || undefined,
  }

  if (selectedClassesCount === 1) {
    const classId = collectionId.classesIds[0]
    const allClassroomStudents = !!classesStore.find((store) => store.classStore?.id === classId)
      ?.classStore?.selected

    const data: AddExerciseData = {
      ...baseAddExerciseData,
      allClassroomStudents,
      classes: [classId],
      groups: collectionId.groupsIds,
      students: collectionId.studentsIds,
    }

    return data
  }

  const getSelectedIds = (store: { id: string; selected?: boolean }[]) =>
    store.reduce((accumulator: string[], item) => {
      if (item.selected) accumulator.push(item.id)
      return accumulator
    }, []) || []

  const sortedClassesStoreItems = sortClassesStoreItemsByClassName(classesStore)
  return sortedClassesStoreItems.reduce(
    (accumulator: AddExerciseData[], { classStore, groupsStore, studentsStore }, index) => {
      const isScheduleState = exerciseStatus === ExerciseStatuses.Schedule
      const isClassSelected = !!classStore?.selected
      const shouldCreateExercise =
        isClassSelected ||
        groupsStore?.some((item) => item.selected) ||
        studentsStore?.some((item) => item.selected)

      if (shouldCreateExercise) {
        const data: AddExerciseData = {
          ...baseAddExerciseData,
          allClassroomStudents: isClassSelected,
          classes: classStore?.id ? [classStore.id] : [],
          groups: isClassSelected ? [] : getSelectedIds(groupsStore),
          students: isClassSelected ? [] : getSelectedIds(studentsStore),
          startDate: isScheduleState
            ? dayjs(baseAddExerciseData.startDate).add(index, 'millisecond').toISOString()
            : baseAddExerciseData.startDate,
        }

        accumulator.push(data)
      }

      return accumulator
    },
    []
  )
}

// common function for finish of creating and editing exercise
export function createOrEditExercise(
  classesStoreItems: ClassesStoreItem[],
  allowedAnswerLooking: any,
  testModeEnabled: any,
  cheatDetection: boolean,
  isRequiredDrawing: boolean,
  isRequiredUnit: boolean,
  calculatorType: CalculatorType,
  chosenStartDate: any,
  chosenEndDate: any,
  history: any,
  localization: any,
  exerciseActions: IExerciseActions,
  collectionId: ICollectionId['data'],
  id?: string,
  exerciseTitle?: string,
  skillsStudentsIds?: string[], // creates new assignment for skills (skills matrix)
  exerciseStatus?: ExerciseStatuses,
  exercisesStatusActions?: IExercisesStatusActions,
  userExercisesCount?: number,
  preventRedirect?: boolean,
  isDuplicateExercise?: boolean
) {
  const { groupsIds, studentsIds } = collectionId
  const name = exerciseTitle
    ? exerciseTitle
    : getItemFromLocalStorage(localStorageKeys.exerciseName)!
  const problems = getItemFromLocalStorageWithParsing(localStorageKeys.problems)
  const startDate = chosenStartDate ? chosenStartDate.toISOString() : chosenStartDate
  const endDate = chosenEndDate ? chosenEndDate.toISOString() : chosenEndDate
  const exerciseSettingCallbackMap = {
    [ExerciseStatuses.Unpublished]: () => exercisesStatusActions?.getUnpublished(),
    [ExerciseStatuses.Schedule]: () => exercisesStatusActions?.getUnpublished(),
    [ExerciseStatuses.PublishNow]: () => exercisesStatusActions?.getAvailable(),
  }

  const exerciseActionCallback =
    typeof exerciseStatus === 'number' ? exerciseSettingCallbackMap[exerciseStatus] : null
  exerciseActionCallback?.()

  const baseAddExerciseData: Omit<
    AddExerciseData,
    'allClassroomStudents' | 'classes' | 'groups' | 'students'
  > = {
    drawBoardAvailableFeatures: { calculator: calculatorType || undefined },
    name,
    problems,
    startDate,
    endDate,
    testModeEnabled,
    cheatDetection,
    allowedAnswerLooking,
    isRequiredDrawing,
    isRequiredUnit,
    exerciseStatus,
    isDuplicateExercise,
  }

  if (classesStoreItems.length === 1) {
    const classId = classesStoreItems[0].classStore?.id ?? ''

    const data: AddExerciseData = {
      ...baseAddExerciseData,
      allClassroomStudents: !!classesStoreItems[0].classStore?.selected,
      classes: [classId],
      groups: groupsIds,
      students: studentsIds,
    }

    if (!id || (id && skillsStudentsIds)) {
      return exerciseActions.addExercise(data, history, localization!, userExercisesCount)
    }

    return exerciseActions.editExercise(data, id!, history, preventRedirect)
  }

  const getSelectedIds = (store: { id: string; selected?: boolean }[]) =>
    store.reduce((accumulator: string[], item) => {
      if (item.selected) accumulator.push(item.id)
      return accumulator
    }, []) || []
  const sortedClassesStoreItems = sortClassesStoreItemsByClassName(classesStoreItems)
  const bulkData = sortedClassesStoreItems.reduce(
    (accumulator: AddExerciseData[], { classStore, groupsStore, studentsStore }, index) => {
      const isScheduleState = exerciseStatus === ExerciseStatuses.Schedule
      const isClassSelected = !!classStore?.selected
      const shouldCreateExercise =
        isClassSelected ||
        groupsStore?.some((item) => item.selected) ||
        studentsStore?.some((item) => item.selected)

      if (shouldCreateExercise) {
        const data: AddExerciseData = {
          ...baseAddExerciseData,
          allClassroomStudents: isClassSelected,
          classes: classStore?.id ? [classStore.id] : [],
          groups: isClassSelected ? [] : getSelectedIds(groupsStore),
          students: isClassSelected ? [] : getSelectedIds(studentsStore),
          startDate: isScheduleState
            ? dayjs(baseAddExerciseData.startDate).add(index, 'millisecond').toISOString()
            : baseAddExerciseData.startDate,
        }

        accumulator.push(data)
      }

      return accumulator
    },
    []
  )

  return exerciseActions.addBulkExercises(bulkData, history, localization!, userExercisesCount)
}

// function for localStorage clearing
export function deleteItemsFromLocalStorage() {
  localStorage.removeItem(localStorageKeys.exerciseName)
  localStorage.removeItem(localStorageKeys.classesArray)
  localStorage.removeItem(localStorageKeys.groupsArray)
  localStorage.removeItem(localStorageKeys.studentsArray)
  localStorage.removeItem(localStorageKeys.problems)
}

// function that filters an array by removing duplicate ids

export function preventRepeatingItems(currentArray: any) {
  const used = {}
  return currentArray.filter((obj: any) => {
    return obj?._id in used ? 0 : (used[obj?._id] = 1)
  })
}

// function that gives us url of picture depending on whether we are on production server or on mars test server

export function getSolutionsUrl(drawingUrl: string) {
  return `${config.IMAGE_SOLUTIONS_HOST}/${drawingUrl}`
}

// function renders prefilled boxes

export function tasksWithPrefilledBoxes(problem: any) {
  if (problem && problem.answerVariants) {
    return (
      <p>
        {problem.answerVariants.map((variant: any, i: any) => {
          if (variant && i !== problem.answerVariants.length - 1) {
            return variant + ' '
          } else if (variant && i === problem.answerVariants.length - 1) {
            return variant
          }
        })}
      </p>
    )
  } else if (problem && problem.boxes) {
    return (
      <p>
        {problem.boxes.map((variant: any, i: any) => {
          if (variant.answerVariants && i !== problem.boxes.length - 1) {
            return '_ '
          } else if (variant.answerVariants && i === problem.boxes.length - 1) {
            return '_'
          } else if (variant.text && i !== problem.boxes.length - 1) {
            return variant.text + ' '
          } else if (variant.text && i === problem.boxes.length - 1) {
            return variant.text
          }
        })}
      </p>
    )
  }
  return null
}

const getUpdatedExerciseState = (exerciseSettings?: IExerciseWithoutStats['settings']) => {
  const isAssessed = !!exerciseSettings?.assessed
  if (isAssessed) return ExerciseState.Started

  const defaultSettings = { state: ExerciseState.NotStarted }
  const stateMap = {
    [ExerciseState.Started]: ExerciseState.Stopped,
    [ExerciseState.Stopped]: ExerciseState.Started,
    [ExerciseState.NotStarted]: ExerciseState.Started,
  }

  const settings = exerciseSettings || defaultSettings
  return stateMap[settings.state]
}

export const prepareEditExerciseData = (
  exercise: IExerciseWithoutStats | IExercise,
  status: ExerciseState
) => {
  const { settings, archived, published } = exercise
  const dateParam = archived ? 'endDate' : 'startDate'
  const currentDate = { startDate: new Date().toISOString(), endDate: null }
  const editStartDate = !published ? currentDate.startDate : undefined
  const changedSettings = { state: status || getUpdatedExerciseState(settings) }

  return {
    [dateParam]: archived ? currentDate.endDate : editStartDate,
    settings: changedSettings,
  }
}

export function changeAssessModeInExercise(exerciseSettings?: {
  state: ExerciseState
  assessed?: boolean
}) {
  if (exerciseSettings && !exerciseSettings.assessed) {
    const stateValue: ExerciseState =
      exerciseSettings.state === ExerciseState.Started
        ? ExerciseState.Stopped
        : exerciseSettings.state
    const settings: { state: ExerciseState; assessed?: boolean } = {
      assessed: true,
      state: stateValue,
    }
    return settings
  }
  return
}

export const addStudentsStats = (
  exercise: IExerciseWithoutStats | IExercise,
  task: IProblem | ISubProblem,
  taskType: string,
  users: IStudent[],
  stats: GetSingleStatsResponse
) => {
  return users.map((user: IStudent) => {
    const currentTeacher = getTeacherData()

    const studentStatsForAllProblems =
      stats.students.find((studentWithStats) => studentWithStats?.studentId === user?._id) || []
    const studentStatsForSimpleMultiplicity =
      (studentStatsForAllProblems[taskType + 's'] || []).find(
        (item: any) => item[`${taskType}Id`] === task._id
      ) || null

    return {
      studentId: user._id,
      isTeacher: user._id === currentTeacher?._id,
      stats: {
        answer: studentStatsForSimpleMultiplicity?.answer || [],
        answerArray: studentStatsForSimpleMultiplicity?.answerArray || [],
        attempt: studentStatsForSimpleMultiplicity?.attempt || 0,
        drawing: studentStatsForSimpleMultiplicity?.drawing || {},
        exerciseId: exercise._id,
        initiallyCorrect: studentStatsForSimpleMultiplicity?.initiallyCorrect || false,
        lastAnswer: studentStatsForSimpleMultiplicity?.lastAnswer || [],
        [`${taskType}Id`]: task?._id,
        solved: studentStatsForSimpleMultiplicity?.solved || 0,
        solvedOnFirstAttempt: studentStatsForSimpleMultiplicity?.solvedOnFirstAttempt || 0,
        solvedOnLast: studentStatsForSimpleMultiplicity?.solvedOnLast || 0,
        studentId: user._id,
        watched: studentStatsForSimpleMultiplicity?.watched || false,
        strokeCount: studentStatsForSimpleMultiplicity?.strokeCount || 0,
        isInitialData: !studentStatsForSimpleMultiplicity,
      },
    }
  })
}

export const updateSingleStudentStat = (
  studentId: string,
  problemsWithStats: IProblem[],
  statsForSingleStudent: any
) => {
  return problemsWithStats.map((problem: IProblem) => {
    const newProblemsStudentsStats = problem.studentStats?.map((studentWithStats: any) => {
      return studentWithStats.studentId === studentId
        ? {
            ...studentWithStats,
            stats: statsForSingleStudent.problems?.[problem._id] || studentWithStats.stats,
          }
        : studentWithStats
    })

    const newSubProblemsStudentsStats = problem.subProblems.map((subProblem: ISubProblem) => {
      const subProblemsStats = subProblem.studentStats?.map((subProblemStudentStats: any) =>
        subProblemStudentStats.studentId === studentId
          ? {
              ...subProblemStudentStats,
              stats: statsForSingleStudent.subProblems?.[subProblem._id],
            }
          : subProblemStudentStats
      )
      return { ...subProblem, studentStats: subProblemsStats }
    })

    return {
      ...problem,
      studentStats: newProblemsStudentsStats,
      subProblems: newSubProblemsStudentsStats,
    }
  })
}

export const removeDuplicateStats = (stats: any): any[] => {
  const studentStatsMap = _.groupBy(stats, 'studentId')
  const getNumberOfSolved = (singleStats: any) =>
    Object.keys(singleStats.problems).length + Object.keys(singleStats.subProblems).length
  return Object.keys(studentStatsMap).reduce((result, studentId) => {
    const studentStats = studentStatsMap[studentId]
    studentStats.sort((a, b) => getNumberOfSolved(b) - getNumberOfSolved(a))
    return result.concat(studentStats[0])
  }, [])
}

export const mapExerciseStatusData = <T extends AddExerciseData | EditExerciseData>(data: T): T => {
  const isDuplicateScheduled =
    data.isDuplicateExercise && data.exerciseStatus === ExerciseStatuses.Schedule

  if (isDuplicateScheduled) {
    return {
      ...data,
      published: undefined,
      archived: undefined,
    }
  }

  const shouldMapData =
    data.exerciseStatus === ExerciseStatuses.PublishNow ||
    data.exerciseStatus === ExerciseStatuses.Unpublished

  const mappedData = {
    ...data,
    published: data.exerciseStatus === ExerciseStatuses.PublishNow,
    archived: false,
    startDate: undefined,
    endDate: undefined,
  }

  return shouldMapData ? mappedData : data
}

export const getExerciseStatusData = (exercise?: IExercise) => {
  if (!exercise)
    return {
      isUnpublishedState: false,
      isPublishNowState: false,
      isScheduleState: false,
      exerciseStatusNumber: null,
    }

  const { published, archived, startDate } = exercise
  let exerciseStatusNumber = null
  const isPublishNowState = Boolean(published && startDate)
  if (isPublishNowState) exerciseStatusNumber = ExerciseStatuses.PublishNow
  const isUnpublishedState = !published && !archived && !startDate
  if (isUnpublishedState) exerciseStatusNumber = ExerciseStatuses.Unpublished
  const isScheduleState = Boolean(!published && !archived && startDate)
  if (isScheduleState) exerciseStatusNumber = ExerciseStatuses.Schedule

  return { isUnpublishedState, isPublishNowState, isScheduleState, exerciseStatusNumber }
}
