import React from 'react'
import { SvgIconProps } from '../types/types'

const UploadIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_79_4291)'>
        <path
          d='M18.3166 0H2.68345C1.20377 0 0 1.20381 0 2.68349V18.3166C0 19.7962 1.20377 21 2.68345 21H18.3166C19.7962 21 21 19.7962 21 18.3165V2.68349C21 1.20381 19.7962 0 18.3166 0ZM19.7631 18.3166C19.7631 19.1142 19.1141 19.7631 18.3166 19.7631H2.68345C1.88582 19.7631 1.23691 19.1141 1.23691 18.3166V16.0604L5.30791 12.5966C5.45647 12.4702 5.6732 12.469 5.82315 12.5935L8.37326 14.7111C8.61915 14.9152 8.98008 14.8985 9.20604 14.6723L15.2652 8.60385C15.3747 8.49413 15.5024 8.48347 15.5691 8.48687C15.6355 8.49028 15.7616 8.51398 15.8594 8.63436L19.7631 13.441L19.7631 18.3166ZM19.7631 11.4788L16.8195 7.85445C16.5276 7.49499 16.0949 7.27523 15.6324 7.25148C15.1703 7.2281 14.7171 7.40209 14.3899 7.7298L8.72944 13.399L6.61344 11.642C5.99989 11.1325 5.11379 11.1378 4.50635 11.6546L1.23691 14.4364V2.68349C1.23691 1.88586 1.88582 1.23695 2.68345 1.23695H18.3166C19.1142 1.23695 19.7631 1.88586 19.7631 2.68349V11.4788Z'
          fill={color}
        />
        <path
          d='M6.60932 2.58386C4.9648 2.58386 3.62695 3.92179 3.62695 5.56623C3.62695 7.21071 4.96484 8.5486 6.60932 8.5486C8.2538 8.5486 9.59169 7.21071 9.59169 5.56623C9.59169 3.92175 8.25384 2.58386 6.60932 2.58386ZM6.60932 7.31169C5.64685 7.31169 4.86386 6.52866 4.86386 5.56623C4.86386 4.60376 5.64685 3.82077 6.60932 3.82077C7.57179 3.82077 8.35478 4.6038 8.35478 5.56623C8.35478 6.52866 7.57179 7.31169 6.60932 7.31169Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_79_4291'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UploadIcon
