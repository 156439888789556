import React, { ElementType } from 'react'
import { PropsWithChildren, ReactNode } from 'react'
import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import { clsx } from 'clsx'

import styles from './Popover.module.scss'

type DropdownMenuProps = {
  Button: ElementType | undefined
  classes?: {
    Panel?: string
  }
}

const Popover = ({ children, classes, Button }: PropsWithChildren<DropdownMenuProps>) => {
  return (
    <HeadlessPopover>
      <div className={styles.Popover}>
        <HeadlessPopover.Button as={Button} />
        <HeadlessPopover.Overlay />
        <Transition enter={styles.DisplayAnimation} leave={styles.HideAnimation}>
          <HeadlessPopover.Panel className={clsx(styles.Panel, classes?.Panel)}>
            {children}
          </HeadlessPopover.Panel>
        </Transition>
      </div>
    </HeadlessPopover>
  )
}

export default Popover
