import { IBasicUser, IStudent } from '../../../api/types.users'

export const sortStudentsByIndexedOrder = (
  students: IBasicUser[],
  order: IStudent[]
): IBasicUser[] => {
  const orderIndex = new Map(order.map((item, index) => [item._id, index]))
  return students.sort((a, b) => {
    const indexA = orderIndex.get(a._id) ?? Number.MAX_SAFE_INTEGER
    const indexB = orderIndex.get(b._id) ?? Number.MAX_SAFE_INTEGER
    return indexA - indexB
  })
}
