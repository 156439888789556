import { BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import useText from 'i18n/hook'
import React from 'react'
import CorrectIcon from 'ui/icons/CorrectIcon/CorrectIcon'
import { $heatmapSettings, toggleAnonymousCorrectnessMode } from '../../model/heatmapSettings'

type AnonymousCorrectnessButtonProps = {
  buttonColor: ReturnType<typeof getButtonColor>
}

const AnonymousCorrectnessButton = ({ buttonColor }: AnonymousCorrectnessButtonProps) => {
  const t = useText()
  const isAnonymousCorrectnessMode = useStoreMap(
    $heatmapSettings,
    ({ isAnonymousCorrectnessMode }) => isAnonymousCorrectnessMode
  )

  return (
    <Button
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      color={buttonColor}
      icon={<CorrectIcon color='var(--icon-color)' size={20} />}
      onClick={toggleAnonymousCorrectnessMode}
      isActive={isAnonymousCorrectnessMode}
      tooltipText={t.anonymousCorrectness}
      tooltipPosition={TOOLTIP_POSITION.BOTTOM}
    />
  )
}

export default AnonymousCorrectnessButton
