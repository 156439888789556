import ComponentToPrintClass from 'components/ComponentToPrint/ComponentToPrintClass'
import React, { ReactInstance, useState } from 'react'
import ReactToPrint from 'react-to-print'
import PrintIcon from '../../../ui/icons/PrintIcon/PrintIcon'
import QRIcon from '../../../ui/icons/QRIcon/QRIcon'
import styles from './PrintClass.module.scss'
import { ILocalization } from '../../../config/languages.config'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import useText from '../../../i18n/hook'
import { IClass, QRCodeData } from '../../../api/types.classes'
import { api } from '../../../api'

export interface IPrintClassProps {
  addedClass: IClass
  title: string
  localization: ILocalization
  isArchivedClass: boolean
  isQRLoginEnabled: boolean
}

const styleOverrides: string = `
        .print-content {
            padding: 0;
            margin: 0;
        }
        .print-content .title-wrapper {
            display: flex;
            align-item: center;
            justify-content: space-around;
        }
        .print-content .title-wrapper .overlay-title {
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: bold;
        }        
        .print-content ol.overlay-list li {
            font-family: 'Open Sans', sans-serif;
            margin-left: 15px;
            list-style: decimal !important;
            font-weight: 400;
            font-size: 14px;
            padding: 10px;
          }`

const PrintClass: React.FC<IPrintClassProps> = ({
  addedClass,
  title,
  localization,
  isArchivedClass,
  isQRLoginEnabled,
}) => {
  const t = useText()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [qrCodesData, setQrCodesData] = useState<QRCodeData[]>([])
  let componentClassRef: ReactInstance | null

  const getQrCodesData = async () => {
    try {
      const res = await api.classes.getQRCodes(addedClass._id)
      setQrCodesData(res)
    } catch (error) {
      setQrCodesData([])
    }
  }

  const Icon = isQRLoginEnabled ? QRIcon : PrintIcon

  return (
    <div className={styles.Container}>
      <ReactToPrint
        content={() => componentClassRef}
        trigger={() => (
          <Button
            size={SIZE.MEDIUM}
            variant={BUTTON_VARIANT.SECONDARY}
            color={BUTTON_COLOR.ORANGE}
            icon={<Icon color={COLORS.NEUTRAL_10} size={14} />}
          >
            {t.printTxt}
          </Button>
        )}
        copyStyles={false}
        pageStyle={styleOverrides}
        onBeforeGetContent={async () => {
          await getQrCodesData()
          setPreviewOpen(!previewOpen)
        }}
      />
      <ComponentToPrintClass
        isArchivedClass={isArchivedClass}
        printedClass={addedClass}
        title={title}
        localization={localization}
        ref={(el) => (componentClassRef = el)}
        qrCodesData={qrCodesData}
      />
    </div>
  )
}

export default PrintClass
