export type ICoords = {
  x: number
  y: number
}

export enum ConvertFormat {
  HTML = 'html',
  KATEX = 'katex',
  RAW = 'raw',
}
