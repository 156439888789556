import { ErrorWithMessage } from 'helpers/error.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { api } from '../../api'
import { TeacherSchool } from '../../api/types.teachers'

type SchoolTeachersReducerState = {
  data: TeacherSchool[]
  error: null | ErrorWithMessage
  loading: boolean
}

export enum TEACHER_SCHOOLS {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const initialState: SchoolTeachersReducerState = {
  data: [],
  error: null,
  loading: false,
}

export function teacherSchoolsReducer(
  state: SchoolTeachersReducerState = initialState,
  action: IAction<TEACHER_SCHOOLS>
) {
  switch (action.type) {
    case TEACHER_SCHOOLS.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case TEACHER_SCHOOLS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case TEACHER_SCHOOLS.ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function getSchoolsByTeacher({ teacherId }: { teacherId: string }) {
  return async (dispatch: IDispatch<TEACHER_SCHOOLS>) => {
    try {
      dispatch({ type: TEACHER_SCHOOLS.REQUEST })
      const res = await api.teachers.getAllSchools(teacherId)
      //TODO no idea why it here, probably can be removed:
      /// @ts-ignore
      if (res.messageKey) return
      dispatch({ type: TEACHER_SCHOOLS.SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: TEACHER_SCHOOLS.ERROR,
      })
    }
  }
}

export type TeacherSchools = { id: string; name: string }
