export type UploadImagePayload = {
  image: File
  type: UploadImageType
}

export enum UploadImageType {
  PROBLEM = 1,
  AVATAR = 2,
  SOLUTION = 3,
}

export type ImageData = {
  cdnId: string
  createdAt: string
  isPublic: true
  name: string
  type: number
  updatedAt: '2024-05-28T09:41:00.953Z'
  uuid: string
  __v: number
  _id: string
}

export type UploadImageResponse = {
  image: ImageData
}
