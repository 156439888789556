import React from 'react'
import styles from './SidebarProblemsSettingsHeader.module.scss'
import useText from '../../../../../i18n/hook'
import Divider from '../../../../../ui/dividers/Divider/Divider'

const SidebarProblemsSettingsHeader = () => {
  const t = useText()
  return (
    <>
      <div className={styles.Title}>{t.printTxt}</div>
      <Divider className={styles.Divider} />
    </>
  )
}
export default SidebarProblemsSettingsHeader
