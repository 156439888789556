import React from 'react'
import { SvgIconProps } from '../types/types'

const LibraryIcon: React.FC<SvgIconProps> = ({ size = 25, color, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_237)'>
        <path
          d='M4.20797 0.796992H7.24466C7.67879 0.796992 8.03076 1.14896 8.03076 1.58309V23.2433C8.03076 23.6774 7.67879 24.0294 7.24466 24.0294H4.20797C3.77384 24.0294 3.42188 23.6774 3.42188 23.2433V1.58297C3.42188 1.14884 3.77384 0.796875 4.20797 0.796875V0.796992Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M9.42282 0.796992H12.4595C12.8936 0.796992 13.2456 1.14896 13.2456 1.58309V23.2433C13.2456 23.6774 12.8936 24.0294 12.4595 24.0294H9.42282C8.98869 24.0294 8.63672 23.6774 8.63672 23.2433V1.58297C8.63672 1.14884 8.98869 0.796992 9.42282 0.796992Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M14.325 10.2856L17.2582 9.49965C17.6775 9.38725 18.1085 9.63619 18.2209 10.0554L21.5358 22.4271C21.6482 22.8465 21.3994 23.2777 20.98 23.3898L18.0468 24.1759C17.6275 24.2881 17.1965 24.0394 17.0841 23.62L13.7692 11.2484C13.6568 10.829 13.9056 10.398 14.325 10.2856Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M3.43359 5.08789H8.02466'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M8.64453 19.0898H13.2356'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M16.5078 21.1291L20.6407 20.0215'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M14.4453 13.5156L18.5782 12.4082'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_237'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LibraryIcon
