import { apiManager } from './apiManager'
import { assignmentsApi } from './api.assignments'
import { booksApi } from './api.books'
import { libraryApi } from './api.library'
import { bookSeriesApi } from './api.bookSeries'
import { sectionsApi } from './api.sections'
import { statisticsApi } from './api.statistics'
import { helpQueueApi } from './api.helpQueue'
import { teachersApi } from './api.teachers'
import { classesApi } from './api.classes'
import { classesStudentsApi } from './api.classesStudents'
import { contentsApi } from './api.contents'
import { chaptersApi } from './api.chapters'
import { drawBoardHistoryApi } from './api.drawBoard'
import { authApi } from './api.auth'
import { pinsApi } from './api.pins'
import { problemsApi } from './api.problems'
import { countriesApi } from './api.countries'
import { groupsApi } from './api.groups'
import { skillsStatisticsApi } from './api.skillsStatistics'
import { oldPracticeApi } from './api.oldPractice'
import { usersApi } from './api.users'
import { topicsApi } from './api.topics'
import { solutionsApi } from './api.solutions'
import { ReportIssuePayload } from './types.issues'
import { oldSkillsApi } from './api.oldSkills'
import { skillsApi } from './api.skills'
import { practiceApi } from './api.practice'
import { imagesApi } from './api.images'

export class api {
  public static readonly manager = apiManager

  public static readonly auth = authApi
  public static readonly assignments = assignmentsApi
  public static readonly countries = countriesApi
  public static readonly chapters = chaptersApi
  public static readonly books = booksApi
  public static readonly library = libraryApi
  public static readonly bookSeries = bookSeriesApi
  public static readonly sections = sectionsApi
  public static readonly statistics = statisticsApi
  public static readonly helpQueue = helpQueueApi
  public static readonly teachers = teachersApi
  public static readonly classes = classesApi
  public static readonly classesStudents = classesStudentsApi
  public static readonly contents = contentsApi
  public static readonly drawBoardHistory = drawBoardHistoryApi
  public static readonly pins = pinsApi
  public static readonly problems = problemsApi
  public static readonly groups = groupsApi
  public static readonly skillsStatistics = skillsStatisticsApi
  public static readonly oldPractice = oldPracticeApi
  public static readonly users = usersApi
  public static readonly topics = topicsApi
  public static readonly solutions = solutionsApi
  public static readonly oldSkills = oldSkillsApi
  public static readonly skills = skillsApi
  public static readonly practice = practiceApi
  public static readonly images = imagesApi

  public static readonly reportNewIssue = apiManager.post<void, ReportIssuePayload>('issues')
}
