import HelpQueue from 'features/helpQueue/HelpQueue'
import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import { VerticalDivider } from '../VerticalDivider'

type HeatmapMenuActionsSkeletonProps = { numberOfButtons: number }

const HeatmapMenuActionsSkeleton = ({ numberOfButtons }: HeatmapMenuActionsSkeletonProps) => {
  return (
    <>
      <HelpQueue />
      <VerticalDivider />
      {Array.from({ length: numberOfButtons }).map((_, index) => (
        <Skeleton key={index} variant='circular' width={34} height={34} />
      ))}
    </>
  )
}

export default HeatmapMenuActionsSkeleton
