import React from 'react'
import styles from './ErrorMarker.module.scss'
import ErrorMarkerIcon from '../../icons/ErrorMarkerIcon/ErrorMarkerIcon'
import UITooltip from '../../tooltips/UITooltip/Tooltip'
import TooltipTitle from './TooltipTitle'
import { COLORS } from '@magmamath/ui'

type ErrorMarkerProps = {
  isShowMarker: boolean
}
const ErrorMarker = ({ isShowMarker }: ErrorMarkerProps) => {
  return (
    <UITooltip
      className={styles.TooltipContent}
      classes={{
        popper: styles.Popper,
        tooltip: styles.Tooltip,
        arrow: styles.Arrow,
      }}
      title={isShowMarker ? <TooltipTitle /> : null}
      placement='top'
      arrow
    >
      <div className={styles.Container}>
        {isShowMarker && <ErrorMarkerIcon size={6} color={COLORS.PRIMARY_RED} />}
      </div>
    </UITooltip>
  )
}
export default ErrorMarker
