import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { motion, Transition } from 'framer-motion'
import styles from './UITab.module.scss'

type Ref = HTMLLIElement

type TabOptions = {
  isActive: boolean
  title: string
  layoutId: string
  titleClassName?: string
  underlineClassName?: string
  underlineTransition?: Transition
}

type TabProps = JSX.IntrinsicElements['li'] & TabOptions

const UITab = forwardRef<Ref, TabProps>(
  (
    {
      isActive,
      title,
      className,
      titleClassName,
      underlineClassName,
      layoutId,
      underlineTransition = { type: 'spring', stiffness: 65, damping: 12 },
      children,
      ...rest
    },
    ref
  ) => {
    const mergedTabClassNames = clsx(styles.Tab, { [styles.TabActive]: isActive }, className)
    const mergedTitleClassNames = clsx(styles.TabTitle, titleClassName)
    const mergedUnderlineClassNames = clsx(styles.TabUnderLine, underlineClassName)

    return (
      <li ref={ref} className={mergedTabClassNames} {...rest}>
        <span className={mergedTitleClassNames}>
          {title}

          {isActive && (
            <motion.div
              transition={underlineTransition}
              className={mergedUnderlineClassNames}
              layoutId={layoutId}
            />
          )}
        </span>

        {children}
      </li>
    )
  }
)

export default UITab
