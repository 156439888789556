import { useSelected } from 'lib/hooks/useSelected'
import { OnSelectChangeEvent, useShiftSelected } from 'lib/hooks/useShiftSelected'
import React, { ReactNode, useContext, useState } from 'react'

import { IExercise } from '../../../../api/types.assignments'

type BulkSelectContextValue = {
  areBulkActionButtonsActive: boolean
  isBulkModeActive: boolean
  changeBulkModeState: (isBulkModeActive: boolean) => void
  selectedExercises: IExercise[]
  toggleSelectedExercise: (exercise: IExercise) => void
  checkIsSelected: (selectedExerciseId: string) => boolean
  clearSelectedExercises: () => void
  onSelectChange: (event: OnSelectChangeEvent, item: IExercise) => void
  isBulkUpdating: boolean
  changeIsBulkUpdating: (newIsBulkUpdating: boolean) => void
}

type BulkSelectProviderProps = {
  exercises: IExercise[]
  children?: ReactNode
}

const initialState: BulkSelectContextValue = {
  areBulkActionButtonsActive: false,
  isBulkModeActive: false,
  changeBulkModeState: () => null,
  selectedExercises: [],
  toggleSelectedExercise: () => null,
  checkIsSelected: () => false,
  clearSelectedExercises: () => null,
  onSelectChange: () => null,
  isBulkUpdating: false,
  changeIsBulkUpdating: () => null,
}

const BulkSelectContext = React.createContext<BulkSelectContextValue>(initialState)

export const BulkSelectProvider = ({ exercises, children }: BulkSelectProviderProps) => {
  const [isBulkUpdating, setIsBulkUpdating] = useState(initialState.isBulkUpdating)
  const [isBulkModeActive, setIsBulkModeActive] = useState(initialState.isBulkModeActive)
  const {
    selected: selectedExercises,
    add,
    remove,
    clear: clearSelectedExercises,
    change,
  } = useSelected(initialState.selectedExercises)
  const onSelectChange = useShiftSelected(exercises, change)

  const changeIsBulkUpdating = (newIsBulkUpdating: boolean) => setIsBulkUpdating(newIsBulkUpdating)

  const changeBulkModeState = (isBulkModeActive: boolean) => {
    setIsBulkModeActive(isBulkModeActive)
    if (!isBulkModeActive) return clearSelectedExercises()
  }

  const checkIsSelected = (selectedExerciseId: string) =>
    selectedExercises.some((exercise) => exercise._id === selectedExerciseId)

  const toggleSelectedExercise = (selectedExercise: IExercise) => {
    const isAlreadySelected = checkIsSelected(selectedExercise._id)
    if (isAlreadySelected) return remove([selectedExercise])
    return add([selectedExercise])
  }

  const areBulkActionButtonsActive = isBulkModeActive && selectedExercises.length > 0

  return (
    <BulkSelectContext.Provider
      value={{
        areBulkActionButtonsActive,
        isBulkModeActive,
        changeBulkModeState,
        selectedExercises,
        toggleSelectedExercise,
        checkIsSelected,
        clearSelectedExercises,
        onSelectChange,
        isBulkUpdating,
        changeIsBulkUpdating,
      }}
    >
      {children}
    </BulkSelectContext.Provider>
  )
}

export const useBulkSelectContext = () => useContext(BulkSelectContext)
