import React, { useEffect, useState } from 'react'
import styles from './PlayableControllers.module.scss'
import PlayBackIcon from '../../../../ui/icons/PlayBackIcon/PlayBackIcon'
import PlayForwardIcon from '../../../../ui/icons/PlayForwardIcon/PlayForwardIcon'
import { useUnit } from 'effector-react'
import PlayableFullScreenButton from './PlayableFullScreenButton'
import PlayableControllerButton from './PlayableControllerButton'
import { HistorySaveCredits } from '../../../DrawBoard/types'
import { previewPersistModule } from '../DrawBoardPreview/model.persist'
import { usePlayableHotKeys } from './model.hotkeys'
import { DrawBoardPreviewPreset } from '../DrawBoardPreview/types'
import PreviewReplayButton from './PreviewReplayButton'
import { useObservable } from '@magmamath/react-native-draw-board'

type PlayableControllersProps = {
  attemptsCount: number
  drawBoardPreset: DrawBoardPreviewPreset
  onAnchorChange: (offset: number) => void
  credits: HistorySaveCredits
  drawBoardBackground?: string
  latestAttemptNumber?: number
}

const PlayableControllers = ({
  latestAttemptNumber,
  drawBoardBackground,
  attemptsCount,
  drawBoardPreset,
  onAnchorChange,
  credits,
}: PlayableControllersProps) => {
  const model = drawBoardPreset.model
  const isHistoryLoading = useUnit(previewPersistModule.load.pending)
  const isBackAvailable = useUnit(model.historyActions.isBackAvailable.$store)
  const isForwardAvailable = useUnit(model.historyActions.isForwardAvailable.$store)
  const [isForwardDisabledByAnchor, setIsForwardDisabledByAnchor] = useState(false)
  const historySettings = useObservable(drawBoardPreset.context.$historySettings)
  const isOldHistory = historySettings && historySettings.anchorsCount !== attemptsCount

  const back = () => {
    const result = model.historyActions.stepBack({ trackAnchors: true })
    if (result.changedAnchor) onAnchorChange(result.changedAnchor.offset)
  }

  const forward = () => {
    const result = model.historyActions.stepForward({ trackAnchors: true })
    if (result.changedAnchor) onAnchorChange(result.changedAnchor.offset)
  }

  usePlayableHotKeys(drawBoardPreset)

  useEffect(() => {
    if (latestAttemptNumber === undefined) return
    return model.cache.history.watch(() => {
      const next = model.cache.getAnchorForNextIndex()
      setIsForwardDisabledByAnchor(!next || next.index > latestAttemptNumber - 1)
    })
  }, [model, latestAttemptNumber])

  if (!historySettings || historySettings.isEmpty) {
    return null
  }

  if (isOldHistory || !attemptsCount || isHistoryLoading) {
    return (
      <div className={styles.PlayableController}>
        <PlayableFullScreenButton
          replayVariant
          credits={credits}
          drawBoardBackground={drawBoardBackground}
        />
      </div>
    )
  }

  return (
    <div className={styles.PlayableController}>
      <PlayableFullScreenButton credits={credits} drawBoardBackground={drawBoardBackground} />
      <PlayableControllerButton Icon={PlayBackIcon} onClick={back} disabled={!isBackAvailable} />
      <PlayableControllerButton
        Icon={PlayForwardIcon}
        onClick={forward}
        disabled={!isForwardAvailable || isForwardDisabledByAnchor}
      />
      <PreviewReplayButton drawBoardPreset={drawBoardPreset} />
    </div>
  )
}

export default PlayableControllers
