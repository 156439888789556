import React from 'react'
import styles from './ProblemCardPrinter.module.scss'
import PrintProblemsCard from '../../../PrintProblems/PrintProblemsCard/PrintProblemsCard'
import Divider from '../../../../ui/dividers/Divider/Divider'
import { IProblem, ISubProblem } from '../../../../api/types.problem'

type ProblemCardPrinterProps = {
  printedItems: (IProblem | ISubProblem)[]
  exerciseName: string
}
const ProblemCardPrinter = ({ printedItems, exerciseName }: ProblemCardPrinterProps) => {
  return (
    <div className={styles.Container}>
      {printedItems.map((printedItem, index) => (
        <div key={index}>
          <PrintProblemsCard problemOrSubProblem={printedItem} exerciseName={exerciseName} />
          {index !== printedItems.length - 1 && <Divider className={styles.DashedDivider} />}
        </div>
      ))}
    </div>
  )
}
export default ProblemCardPrinter
