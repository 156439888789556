import { urls } from 'api/urls'

import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { IState } from 'interfaces/state.interface'
import { Listbox, SelectValue } from '@magmamath/ui'

import { EducationEntityType } from '../types'
import { ClassroomGroup } from '../../../../api/types.classes'
import { AssignmentStatisticsEntityType } from '../../../../api/types.oldSkills'

type FilterDataFn = (
  educationEntityType: EducationEntityType,
  exerciseWithoutStats: IState<IExerciseWithoutStats>,
  selectedValue: SelectValue | null,
  groups: ClassroomGroup[]
) => { entityIds: string[]; entityType: AssignmentStatisticsEntityType }

export const getFilterData: FilterDataFn = (
  educationEntityType,
  exerciseWithoutStats,
  selectedValue,
  groups
) => {
  if (educationEntityType === EducationEntityType.GROUPS && selectedValue?.value) {
    const groupsStudents = groups.reduce<string[]>((acc, group) => {
      if (group._id === selectedValue.value) {
        return acc.concat(group.students.map((student) => student._id))
      }
      return acc
    }, [])

    return {
      entityIds: groupsStudents,
      entityType: AssignmentStatisticsEntityType.STUDENTS,
    }
  }

  if (educationEntityType === EducationEntityType.STUDENTS) {
    const isAll = selectedValue?.value === Listbox.ALL_OPTION
    if (isAll) {
      const classId = exerciseWithoutStats.data.classes[0]._id
      return {
        entityType: AssignmentStatisticsEntityType.CLASSES,
        entityIds: [classId],
      }
    }
  }

  return {
    entityIds: selectedValue?.value ? [selectedValue?.value] : [],
    entityType: AssignmentStatisticsEntityType.STUDENTS,
  }
}
