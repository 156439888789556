import React from 'react'

import CustomTextSlider from './CustomTextSlider/CustomTextSlider'

interface ITextSizeSlider {
  value: number
  handleChange: (value: number) => void
}
const TextSizeSlider: React.FC<ITextSizeSlider> = ({ value, handleChange }) => {
  const onChange = (event: Event, value: number | Array<number>) => {
    handleChange(value as number)
  }

  return (
    <CustomTextSlider
      aria-label='Custom marks'
      defaultValue={16}
      value={value}
      onChange={onChange}
      min={16}
      max={32}
      step={4}
      marks
      track={false}
      valueLabelDisplay='auto'
    />
  )
}

export default TextSizeSlider
