import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import 'quill/dist/quill.snow.css'
import 'katex/dist/katex.min.css'
import { MathEditorModel } from './model/MathEditorModel'
import clsx from 'clsx'
import { ChangedProps } from './model/types'
import { useConst } from '../../lib/hooks/const'
import { MathInputVariant } from './mathInput/variants'
import { MathInputModel } from './mathInput/MathInputModel'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

type MathEditorProps = {
  className?: string
  onChange?: (props: ChangedProps) => void
  placeholder?: string
  formulaInputModel?: MathInputModel
  model?: MathEditorModel
}

const MathEditor = ({
  formulaInputModel,
  model: extModel,
  className,
  onChange,
  placeholder = '',
}: MathEditorProps) => {
  const questionKatex = useSelector((state: RootState) => state.problemCreatorData.questionKatex)

  const model = useConst(() => {
    return (
      extModel ||
      new MathEditorModel({
        inputModel: formulaInputModel,
        placeholder,
        inputVariant: MathInputVariant.POP_UP,
      })
    )
  })

  useEffect(() => {
    model.setValue(questionKatex)
  }, [])

  useEffect(() => {
    return model.changed.watch((e) => {
      onChange?.(e)
    })
  }, [onChange, model])

  useEffect(() => {
    model.setPlaceholder(placeholder)
  }, [model, placeholder])

  return (
    <div className={styles.Container}>
      <div
        onClick={model.editFormulaUnderMousePointer}
        className={clsx(styles.EditorContainer, className)}
        ref={model.setUp}
      />
    </div>
  )
}

export default MathEditor
