import { ILocalization } from '../../../config/languages.config'

import { ITopic } from '../../../api/api.topics'

export const getTopicsText = (
  topics: ITopic[],
  isDistrictExercise: boolean,
  t: ILocalization['data']
) => {
  if (isDistrictExercise) {
    return t.fromDistrict
  }

  if (topics.length > 1) {
    return t.multipleTopics
  }

  return topics[0]?.name || null
}
