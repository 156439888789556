import React, { forwardRef, useMemo } from 'react'
import styles from './PrintProblems.module.scss'
import clsx from 'clsx'
import { ITEMS_PER_COMPONENT } from './constants'
import ProblemCardPrinter from '../../features/modals/ProblemsOfExerciseOverlay/ProblemCardPrinter/ProblemCardPrinter'
import Spinner from '../../features/Spinner/Spinner'
import { IProblem, ISubProblem } from '../../api/types.problem'

type PrintProblemsProps = {
  problemsAndSubProblems: (IProblem | ISubProblem)[]
  exerciseName: string
}

const PrintProblems = forwardRef<HTMLDivElement, PrintProblemsProps>(
  ({ problemsAndSubProblems, exerciseName }, ref) => {
    const iterations = useMemo(
      () => Math.ceil(problemsAndSubProblems.length / ITEMS_PER_COMPONENT),
      [problemsAndSubProblems.length]
    )

    const slicedItems = useMemo(
      () =>
        [...Array(iterations)].map((_, index) =>
          problemsAndSubProblems.slice(
            index * ITEMS_PER_COMPONENT,
            (index + 1) * ITEMS_PER_COMPONENT
          )
        ),
      [iterations, problemsAndSubProblems]
    )

    return (
      <div className={styles.Container} ref={ref}>
        {problemsAndSubProblems.length === 0 ? (
          <Spinner className={styles.Spinner} />
        ) : (
          slicedItems.map((_, index) => (
            <div key={index}>
              <ProblemCardPrinter
                printedItems={problemsAndSubProblems.slice(
                  index * ITEMS_PER_COMPONENT,
                  (index + 1) * ITEMS_PER_COMPONENT
                )}
                exerciseName={exerciseName}
              />
              <div className={styles.PageBreak} />
              <div className={clsx(styles.ItemBreak, styles.RemovedItemBreak)} />
            </div>
          ))
        )}
      </div>
    )
  }
)

export default PrintProblems
