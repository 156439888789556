import { PATHS } from 'config/pathnames.config'
import React from 'react'
import { ModalBody } from 'reactstrap'
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus-sign.svg'
import { AddStudentStage } from '../ModalRegisterWindow.component'

import { capitalize } from 'lodash'
import { useHistory } from 'react-router'
import './AddStudentSuccess.scss'
import { ILocalization } from "../../../../config/languages.config";

type AddStudentSuccessProps = {
  localization: ILocalization
  close: () => void
  setActiveStage: (tabId: AddStudentStage) => void
}

const AddStudentSuccess = ({ localization, setActiveStage, close }: AddStudentSuccessProps) => {
  const { addStudentSuccessTxt, goToAssignmentsTxt, addMoreStudents, orTxt } = localization.data
  const history = useHistory()

  return (
    <>
      <ModalBody className='body-container'>
        <h2 className='body-title'>{addStudentSuccessTxt}</h2>

        <button
          className='body-button-primary'
          onClick={() => setActiveStage(AddStudentStage.DIRECT)}
        >
          <span className='margin-right'>{addMoreStudents}</span>
          <PlusIcon />
        </button>

        <span className='body-cta-text'>{capitalize(orTxt)}</span>

        <button
          className='body-button-outlined'
          onClick={() => {
            close()
            history.push(PATHS.EXERCISES.EXERCISES_MAIN)
          }}
        >
          <span>{goToAssignmentsTxt}</span>
        </button>
      </ModalBody>
    </>
  )
}

export default AddStudentSuccess
