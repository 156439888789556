import React from 'react'
import styles from './ExampleProblem.module.scss'
import ExampleProblemData from './ExampleProblemData/ExampleProblemData'
import useText from '../../../../../../i18n/hook'

const ExampleProblem = () => {
  const t = useText()
  return (
    <div className={styles.ExampleProblemContainer}>
      <span className={styles.ExampleProblemTitle}>{t.exampleProblemTxt}</span>
      <div className={styles.ExampleProblemContent}>
        <ExampleProblemData />
      </div>
    </div>
  )
}

export default ExampleProblem
