export const config: {
  API_HOST: string
  API_HOST_INTERNATIONAL: string
  API_HOST_SWEDEN: string
  APP_HOST_INTERNATIONAL: string
  APP_HOST_SWEDEN: string
  APP_HOST: string
  CDN_HOST: string
  CDN_HOST_SWEDEN: string
  CDN_HOST_INTERNATIONAL: string
  IMAGE_SOLUTIONS_HOST: string
  SKOLON_HOST: string
  SOCKET_HOST: string
  STATISTICS_HOST: string
  STUDENTS_HOST_INTERNATIONAL: string
  STUDENTS_HOST_SWEDEN: string
  STUDENTS_HOST: string
  INTERCOM_KEY: string
  INTERCOM_HELP_URL: string
  REACT_APP_LOGGLY_CUSTOMER_TOKEN: string
  REACT_APP_LOGGLY_TAG: string
  IP_CONFIG_URL: string
  WEB_HOOK_HOST: string
  SKOLFEDERATION_HOST: string
  SKOLON_CALLBACK_URL: string
  MATTEAPPEN_AGREEMENT_SE: string
  MATTEAPPEN_AGREEMENT_EN: string
  MATTEAPPEN_AGREEMENT_UK: string
  MAGMAMATH_AGREEMENT: string
  LOGIN_PAGE_INTERNATIONAL: string
  LOGIN_PAGE_SWEDEN: string
  GOOGLE_SHARE_CLASS: string
  REACT_APP_LOGGLY_TAG_PROD: string
  REACT_APP_LOGGLY_TAG_MARS: string
  WEB_ADMIN: string
  DISTRICT_HOST: string
  DISTRICT_HOST_INTERNATIONAL: string
  DISTRICT_HOST_SWEDEN: string
} = (window as any)._env_

config.APP_HOST = location.hostname.includes('.com')
  ? config.APP_HOST_INTERNATIONAL
  : config.APP_HOST_SWEDEN
config.STUDENTS_HOST = location.hostname.includes('.com')
  ? config.STUDENTS_HOST_INTERNATIONAL
  : config.STUDENTS_HOST_SWEDEN
config.STUDENTS_HOST = location.hostname.includes('.com')
  ? config.STUDENTS_HOST_INTERNATIONAL
  : config.STUDENTS_HOST_SWEDEN
config.API_HOST = location.hostname.includes('.com')
  ? config.API_HOST_INTERNATIONAL
  : config.API_HOST_SWEDEN
config.REACT_APP_LOGGLY_TAG = location.hostname.includes('mars')
  ? config.REACT_APP_LOGGLY_TAG_MARS
  : config.REACT_APP_LOGGLY_TAG_PROD
config.DISTRICT_HOST = location.hostname.includes('.com')
  ? config.DISTRICT_HOST_INTERNATIONAL
  : config.DISTRICT_HOST_SWEDEN
config.CDN_HOST = location.hostname.includes('.com')
  ? config.CDN_HOST_INTERNATIONAL
  : config.CDN_HOST_SWEDEN

export const googleAnalitycsKey: string = 'UA-129531119-2'

export const iOSDevice: boolean = !!navigator.platform.match(/iPhone|iPod|iPad/)

export const extremeScreenWidth: number = 993

export const pageSizes: {
  ten: number
  twenty: number
} = {
  ten: 10,
  twenty: 20,
}
