import React from 'react'
import { SvgIconProps } from '../types/types'

const StudentsIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.932 6.164c1.09 0 1.977.885 1.977 1.977 0 1.118-.779 1.977-1.974 1.977-1.2 0-1.986-.865-1.98-1.973a1.983 1.983 0 011.977-1.981zm3.57 1.977c0-1.972-1.6-3.57-3.57-3.57-1.973 0-3.56 1.6-3.57 3.566-.01 1.955 1.454 3.574 3.573 3.574 2.126 0 3.567-1.625 3.567-3.57zM24.948 19.295c-.678-2.78-3.504-5.942-8.337-5.942-2.603 0-4.563.773-5.947 1.935a7.184 7.184 0 00-2.42 4.034c-.255 1.237.795 2.11 1.813 2.11h13.107c1.008 0 2.091-.877 1.784-2.137zm-1.661.391a.192.192 0 01-.123.039H10.057a.192.192 0 01-.143-.056v-.003a5.478 5.478 0 011.847-3.07c1.043-.876 2.6-1.536 4.85-1.536 3.986 0 6.165 2.561 6.676 4.626zM16.61 5.275a2.561 2.561 0 012.56 2.56c0 1.442-1.01 2.56-2.555 2.56-1.552 0-2.571-1.124-2.564-2.556a2.568 2.568 0 012.56-2.564zm4.267 2.56a4.268 4.268 0 00-4.266-4.267c-2.358 0-4.255 1.913-4.267 4.263-.012 2.338 1.735 4.27 4.27 4.27 2.543 0 4.263-1.937 4.263-4.266zM6.644 19.291H1.607c-.877 0-1.797-.755-1.575-1.838a5.94 5.94 0 012.002-3.335c1.146-.962 2.762-1.597 4.898-1.597 1.39 0 2.58.318 3.564.83a9.52 9.52 0 00-1.311 1.157 6.29 6.29 0 00-2.253-.394c-1.806 0-3.048.53-3.874 1.224a4.352 4.352 0 00-1.449 2.36h5.544c-.216.53-.384 1.066-.51 1.593z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default StudentsIcon
