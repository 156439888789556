import { CheckboxGroup, CheckboxGroupItem, Search } from '@magmamath/ui'
import useText from 'i18n/hook'
import { hasSearchedValue } from 'lib/filters'
import React, { ChangeEvent, memo, useMemo, useState } from 'react'
import styles from './SearchableList.module.scss'
import { IBook } from '../../../../api/types.books'

type ListItem = {
  id: string
  label: string
}

type List = {
  title: string
  items: ListItem[]
}

type SearchableListProps = {
  bookItems: List[]
  selectedItems: Map<string, IBook>
  onClickItem: (id: string) => void
}

const SearchableList = memo(({ bookItems, selectedItems, onClickItem }: SearchableListProps) => {
  const [searchValue, setSearchValue] = useState('')
  const t = useText()

  const filteredItems = useMemo(() => {
    if (!searchValue) return bookItems
    return bookItems.reduce<List[]>((acc, list) => {
      const titleMatches = hasSearchedValue([list.title], searchValue)
      if (titleMatches) {
        acc.push(list)
        return acc
      }
      const filteredItems = list.items.filter((item) => hasSearchedValue([item.label], searchValue))
      if (filteredItems.length) {
        acc.push({
          title: list.title,
          items: filteredItems,
        })
      }
      return acc
    }, [])
  }, [searchValue, bookItems])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  return (
    <>
      <Search
        onChange={handleSearch}
        placeholder={t.searchTxt}
        value={searchValue}
        classes={{ InputWrapper: styles.Search }}
      />
      <div className={styles.Content}>
        {filteredItems.length ? (
          filteredItems.map((list) => (
            <CheckboxGroup
              key={list.title}
              isEmpty={list.items.length <= 0}
              title={list.title}
              classes={{ ListWrapper: styles.List }}
            >
              {list.items.map((item) => (
                <CheckboxGroupItem
                  key={item.id}
                  id={item.id}
                  onClick={onClickItem}
                  isChecked={selectedItems.has(item.id)}
                  label={item.label}
                />
              ))}
            </CheckboxGroup>
          ))
        ) : (
          <p className={styles.NoResults}>{t.noBooksInBookSeries}</p>
        )}
      </div>
    </>
  )
})

SearchableList.displayName = 'SearchableList'

export default SearchableList
