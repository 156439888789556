import { asyncValidateUsername } from 'helpers/asynchValidation.helpers'
import { ModalType } from 'helpers/enums'
import { checkSafari } from 'helpers/general'
import { validateRegisterForm } from 'helpers/synchValidation.helpers'
import React, { useCallback, useState } from 'react'
import { Modal } from 'reactstrap'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { ReactComponent as ExitIcon } from '../../../assets/img/exit.svg'
import { IState } from '../../../interfaces/state.interface'
import { IModal, IModalActions } from '../../../redux/modules/modal.module'

import './../modals.scss'
import AddStudentDirectly from './AddStudentDirectly/AddStudentDirectly'
import AddStudentSuccess from './AddStudentSuccess/AddStudentSuccess'
import AddStudentWithClassCode from './AddStudentWithClassCode/AddStudentWithClassCode'
import AddStudentWithClassList from './AddStudentWithClassList/AddStudentWithClassList'
import './ModalRegisterWindow.component.scss'
import { ILocalization } from '../../../config/languages.config'
import { IClass } from '../../../api/types.classes'

interface IRegisterStudentFormProps extends InjectedFormProps<FormData, any> {
  localization: ILocalization
  onSubmit: (values: any, classId: string, localization: ILocalization) => {}
  modalActions?: IModalActions
  modal?: IState<IModal>
  addedClass?: IState<IClass>
}

export enum AddStudentStage {
  DIRECT = 'DIRECT',
  CODE = 'CODE',
  LIST = 'LIST',
  SUCCESS = 'SUCCESS',
}

const RegisterStudentFormComponent = ({
  localization,
  onSubmit,
  modalActions,
  modal,
  addedClass,
  pristine,
  invalid,
  handleSubmit,
  reset,
}: IRegisterStudentFormProps) => {
  const isSafari = !!checkSafari()
  const [activeStage, setActiveStage] = useState(
    (addedClass?.data.grade ?? 0) < 4 && (addedClass?.data.grade ?? 0) !== -1
      ? AddStudentStage.DIRECT
      : AddStudentStage.CODE
  )

  const opened = !!((modal as any).data.modalType === ModalType.RegisterStudentModal)

  const close = () => {
    modalActions!.closeModalRegister()
  }

  const onAddExistingStudentClick = useCallback(() => {
    const classId = addedClass?.data._id
    if (classId) modalActions!.openChooseStudentsModalUpdated(classId)
  }, [addedClass, modalActions])

  return (
    <Modal
      className='custom-modal modal-register'
      autoFocus={false}
      isOpen={opened}
      fade={!isSafari}
      toggle={close}
    >
      <div className='exit' onClick={close}>
        <ExitIcon />
      </div>
      {activeStage === AddStudentStage.DIRECT && (
        <AddStudentDirectly
          localization={localization}
          onSubmit={onSubmit}
          modal={modal}
          pristine={pristine}
          invalid={invalid}
          handleSubmit={handleSubmit}
          activeStage={activeStage}
          setActiveStage={setActiveStage}
          addedClass={addedClass}
          reset={reset}
          onAddExistingStudentClick={onAddExistingStudentClick}
        />
      )}

      {activeStage === AddStudentStage.CODE && (
        <AddStudentWithClassCode
          localization={localization}
          activeStage={activeStage}
          setActiveStage={setActiveStage}
          invitationalCode={addedClass?.data.invitationalCode ?? 0}
          onAddExistingStudentClick={onAddExistingStudentClick}
        />
      )}

      {activeStage === AddStudentStage.LIST && (
        <AddStudentWithClassList
          localization={localization}
          activeStage={activeStage}
          setActiveStage={setActiveStage}
          invitationalCode={addedClass?.data.invitationalCode ?? 0}
          onAddExistingStudentClick={onAddExistingStudentClick}
        />
      )}

      {activeStage === AddStudentStage.SUCCESS && (
        <AddStudentSuccess
          localization={localization}
          close={close}
          setActiveStage={setActiveStage}
        />
      )}
    </Modal>
  )
}

const RegisterStudentForm: any = reduxForm({
  enableReinitialize: true,
  // tslint:disable-next-line:object-literal-sort-keys
  destroyOnUnmount: true,
  form: 'register',
  validate: validateRegisterForm,
  asyncValidate: asyncValidateUsername,
  asyncChangeFields: ['username'],
  asyncBlurFields: ['username'],
})(RegisterStudentFormComponent) as any

export default RegisterStudentForm
