import React from 'react'
import { useStoreMap, useUnit } from 'effector-react'

import styles from './ExampleProblemData.module.scss'

import PracticeTheory from '../../../../PracticeTheory/PracticeTheory'
import ExampleProblemInfo from './ExampleProblemInfo/ExampleProblemInfo'
import EmptyExerciseContainer from '../../../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import { practiceResultsModel } from '../../../../models/practiceResults'

const ExampleProblemData = () => {
  const isFetchingPracticeTheory = useUnit(practiceResultsModel.isFetchingTheory)

  const isFetchingExampleProblems = useUnit(practiceResultsModel.isFetchingExampleProblems)
  const exampleProblems = useUnit(practiceResultsModel.$exampleProblems)

  const isPracticeTheory = useStoreMap(
    practiceResultsModel.$theory,
    (data) => !!data.theory && !!data.drawningHistory
  )

  if (isPracticeTheory && !isFetchingPracticeTheory) {
    return <PracticeTheory />
  }

  if (!isPracticeTheory && !isFetchingPracticeTheory && !isFetchingExampleProblems) {
    return <ExampleProblemInfo problems={exampleProblems} />
  }

  return <EmptyExerciseContainer heightClass={styles.Loader} />
}

export default ExampleProblemData
