import React from 'react'

import styles from './StudentSolution.module.scss'

import { IPracticeAnswerResult } from '../../../../../api/types.oldPractice'
import SolutionInfo from './SolutionInfo/SolutionInfo'
import SolutionResult from './SolutionResult/SolutionResult'

type StudentExpandedResultsProps = {
  result: IPracticeAnswerResult
  problemNumber: number
  studentName: string
}

const StudentSolution = ({ result, problemNumber, studentName }: StudentExpandedResultsProps) => {
  return (
    <div className={styles.StudentSolutionContainer}>
      <SolutionInfo
        solutionNumber={problemNumber}
        description={result?.problem?.richDescription}
        correctAnswer={result?.correctAnswer}
      />
      <SolutionResult
        studentName={studentName}
        answer={result?.answer}
        isCorrect={result?.correct}
        drawingImageName={result?.drawingImageName}
      />
    </div>
  )
}

export default StudentSolution
