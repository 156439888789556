import BookshelfSkeleton from 'features/bookshelf/BookshelfSkeleton/BookshelfSkeleton'
import SettingsPanelSkeleton from 'features/caf/exerciseSettings/ui/SettingsPanel/SettingsPanelSkeleton'
import PageHeaderSkeleton from 'features/caf/pageHeader/PageHeaderSkeleton'
import React from 'react'
import styles from './ExerciseDetails.module.scss'

type ExerciseDetailsSkeletonProps = {
  submitButtonText?: string
}

const ExerciseDetailsSkeleton = ({ submitButtonText }: ExerciseDetailsSkeletonProps) => (
  <div className={styles.Container}>
    <PageHeaderSkeleton submitButtonText={submitButtonText} />
    <div className={styles.Details}>
      <BookshelfSkeleton />
      <SettingsPanelSkeleton />
    </div>
  </div>
)

export default ExerciseDetailsSkeleton
