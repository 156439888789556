import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import styles from './Practice.module.scss'
import loadable from '@loadable/component'
const Notification = loadable(() => import('components/Notification/Notification.component'))
import { RootState } from '../../store/store'
import { ILocalizationActions } from '../../redux/modules/localization.module'
import { ILocalization } from '../../config/languages.config'
import { IPracticeActions } from '../../redux/modules/practice.module'
import { IPractice, IStatistics } from '../../api/types.oldPractice'
import { IClassesActions } from '../../redux/modules/classes.module'
import { IGroupsActions } from '../../redux/modules/groups.module'
import NoContentUI from '../../ui/NoContentUI/NoContentUI'
import { filterCategoriesByGrade } from '../../features/PracticeMode/helpers'
import { useUnit } from 'effector-react'
import { $filterSelectorStore } from '../../features/filters/StudentsFilter/model'
import { TypesOfParams } from '../../helpers/enums'
import OldPracticeHeader from 'features/PracticeMode/OldPracticeMode/PracticeHeader/PracticeHeader'
import PracticeHeader from '../../features/PracticeMode/PracticeHeader/PracticeHeader'
import OldPracticeBody from '../../features/PracticeMode/OldPracticeMode/PracticeBody/PracticeBody'
import PracticeBody from 'features/PracticeMode/PracticeBody/PracticeBody'
import useText from '../../i18n/hook'
import { IClass } from '../../api/types.classes'
import { Listbox, SelectValue } from '@magmamath/ui'
import { practiceTreeModel } from '../../features/PracticeMode/models/practiceTree'
import {
  fetchSkillTreeFx,
  fetchPracticeStatisticsFx,
} from '../../features/PracticeMode/models/requests'
import { practiceFilterModel } from '../../features/PracticeMode/models/practiceFilter'

// --- OLD PRACTICE ---

const oldFilterTypeMap = {
  class: 'classes',
  students: 'users',
  group: 'groups',
}

// --- OLD PRACTICE ---

const filterTypeMap = {
  class: 'class',
  students: 'student',
  group: 'group',
}

export type PracticePageProps = {
  localization: ILocalization
  localizationActions: ILocalizationActions
  practice: IPractice
  practiceActions: IPracticeActions
  classesActions: IClassesActions
  groupsActions: IGroupsActions
}

const Practice = ({
  localization,
  practice,
  practiceActions,
  classesActions,
}: PracticePageProps) => {
  const history = useHistory()
  const classFilter: SelectValue = useSelector((state: RootState) => state.classesFilter)
  const studentsFilterValue = useUnit($filterSelectorStore)

  const classes = useSelector((state: RootState) => state.classes)
  const meData = useSelector((state: RootState) => state.auth?.data?.me)

  const skillTree = useUnit(practiceTreeModel.$skillTree)

  const grade = useUnit(practiceFilterModel.$grade)
  const selectedOption = useUnit(practiceFilterModel.$selectedOption)
  const selectedStudents = useUnit(practiceFilterModel.$selectedStudents)

  //TODO: turn it back when API is ready
  const practiceModeTreeId = meData?.setting?.practiceModeTreeId

  const notification = useSelector((state: RootState) => state.notify)
  const t = useText()

  useEffect(() => {
    return () => {
      practiceTreeModel.resetGradeTrees()
    }
  }, [])

  useEffect(() => {
    if (!selectedOption || selectedOption?.type !== TypesOfParams.CLASS) return
    const options = classes?.data?._embedded?.classes
    if (!options || !options.length) return

    const option = options.find((option: IClass) => option._id === selectedOption._id)
    practiceFilterModel.setSelectedStudents(option?.students || [])
  }, [selectedOption])

  useEffect(() => {
    const loadClassesAndGroups = async () => {
      const schoolId = meData?.school?._id
      const promises: any[] = []
      !Object.keys(classes.data)?.length &&
        !classes.loading &&
        schoolId &&
        promises.push(classesActions.getClasses())
      await Promise.all(promises)
    }

    loadClassesAndGroups()
  }, [classes.data, classes.loading])

  useEffect(() => {
    const selectedClass = classFilter
      ? {
          _id: classFilter.value,
          name: classFilter.name,
          type: classFilter?.value ? TypesOfParams.CLASS : null,
        }
      : null
    const selectedStudent = studentsFilterValue
      ? {
          _id: studentsFilterValue.value,
          name: studentsFilterValue.name,
          type: studentsFilterValue?.value ? TypesOfParams.STUDENT : null,
        }
      : null

    const newSelectedOption = selectedStudent?._id !== '0' ? selectedStudent : selectedClass

    if (newSelectedOption?._id !== selectedOption?._id) {
      practiceFilterModel.setSelectedOption(newSelectedOption)
    }
  }, [classFilter, studentsFilterValue, selectedOption?._id])

  const filteredCategoriesHash = useMemo(
    () => JSON.stringify(practice.filteredCategories),
    [practice.filteredCategories]
  )

  useEffect(() => {
    if (!practiceModeTreeId) {
      return
    }

    fetchSkillTreeFx(practiceModeTreeId)
  }, [practiceModeTreeId])

  useEffect(() => {
    if (!skillTree || !practiceModeTreeId) return

    const practiceGradeTreeId = skillTree.find((tree) => tree?.attributes?.grade === grade)?._id

    if (practiceGradeTreeId) {
      practiceTreeModel.loadCurrentGradeTreeFx(practiceGradeTreeId)
    }
  }, [skillTree, grade, practiceModeTreeId])

  useEffect(() => {
    if (
      !selectedOption ||
      !selectedOption.type ||
      selectedOption._id === Listbox.ALL_OPTION ||
      !practiceModeTreeId
    ) {
      return
    }

    fetchPracticeStatisticsFx({
      entityType: filterTypeMap[selectedOption.type],
      entityId: selectedOption._id,
    })
  }, [selectedOption, practiceModeTreeId])

  // --- OLD PRACTICE ---

  useEffect(() => {
    if (practiceModeTreeId) return

    practiceActions.setFilteredCategories(
      filterCategoriesByGrade(practice.categories, practice.grade)
    )
  }, [practice.categories, practice.grade, practiceModeTreeId])

  useEffect(() => {
    if (!meData || practiceModeTreeId) return
    practiceActions.getPracticeCategories(localization)
  }, [meData, practiceModeTreeId])

  useEffect(() => {
    if (
      !practice.filteredCategories.length ||
      !selectedOption ||
      selectedOption._id === Listbox.ALL_OPTION ||
      !selectedOption?.type ||
      practiceModeTreeId
    ) {
      return
    }
    practiceActions.subcategoriesStatisticsRequest()

    const loadStatistic = async () => {
      if (!selectedOption?.type) return
      const type = oldFilterTypeMap[selectedOption.type]
      const statistics: any[] = await Promise.all(
        practice.filteredCategories
          .map((category) =>
            category.subcategories?.map((subcategory) =>
              practiceActions.getSubcategoryStatistics(
                localization,
                subcategory?._id,
                type,
                selectedOption._id
              )
            )
          )
          .flat()
      )

      practiceActions.setSubcategoryStatistics(statistics as IStatistics[])
    }

    loadStatistic()
  }, [filteredCategoriesHash, selectedOption, practiceModeTreeId])

  // --- OLD PRACTICE ---

  return (
    <div className={styles.PracticePageWrapper}>
      <div className={styles.PracticeContainer}>
        {classFilter?.value && classFilter.value !== Listbox.ALL_OPTION ? (
          <>
            {practiceModeTreeId ? (
              <>
                <PracticeHeader />
                <PracticeBody />
              </>
            ) : (
              <>
                <OldPracticeHeader
                  selectedClassId={
                    selectedOption?.type === TypesOfParams.CLASS ? selectedOption?._id : ''
                  }
                />
                <OldPracticeBody selectedStudents={selectedStudents} />
              </>
            )}
          </>
        ) : (
          <div className={styles.NoContentWrapper}>
            <NoContentUI title={t.pickAClass} />
          </div>
        )}
      </div>
      <div className='notification'>
        <Notification notification={notification} history={history} localization={localization} />
      </div>
    </div>
  )
}

export default Practice
