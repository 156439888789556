import { IDispatch } from '../../interfaces/dispatch.interface'
import { IAction } from '../../interfaces/actions.interface'
import { timeout } from '../../helpers/modules.helpers'
import { NOTIFY } from './notify.module'
import { NotifyType } from '../../helpers/enums'
import { sortMyBooksByOrder } from '../../helpers/sort.helpers'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'

export enum MY_BOOKS {
  MY_BOOKS_REQUEST = 'MY_BOOKS_REQUEST',
  MY_BOOKS_SUCCESS = 'MY_BOOKS_SUCCESS',
  MY_BOOKS_ERROR = 'MY_BOOKS_ERROR',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function myBooksReducer(state: any = initialState, action: IAction<MY_BOOKS>) {
  switch (action.type) {
    case MY_BOOKS.MY_BOOKS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case MY_BOOKS.MY_BOOKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case MY_BOOKS.MY_BOOKS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const getMyBooks = (localization: ILocalization) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: MY_BOOKS.MY_BOOKS_REQUEST })
      await timeout(500)
      const res = await api.library.getAll()
      dispatch({ type: MY_BOOKS.MY_BOOKS_SUCCESS, payload: res })
    } catch (error) {
      const anotherErrorMessages = !!error.response ? error.response.data.message : error.message
      const networkErrorMessage = localization.data.networkError
      const errorMessage =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: MY_BOOKS.MY_BOOKS_ERROR,
      })
    }
  }
}

export const addBook = (localization: ILocalization, ids: string | string[]) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: MY_BOOKS.MY_BOOKS_REQUEST })
      const res = await api.library.addBooks({ bookIds: Array.isArray(ids) ? ids : [ids] })
      dispatch({
        type: MY_BOOKS.MY_BOOKS_SUCCESS,
        payload: { ...res, books: [...res.books].sort(sortMyBooksByOrder) },
      })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: MY_BOOKS.MY_BOOKS_ERROR,
      })
    }
  }
}

export const removeBook = (localization: ILocalization, id: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: MY_BOOKS.MY_BOOKS_REQUEST })
      const res = await api.library.deleteBook(id)
      dispatch({
        type: MY_BOOKS.MY_BOOKS_SUCCESS,
        payload: { ...res, books: [...res.books].sort(sortMyBooksByOrder) },
      })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: MY_BOOKS.MY_BOOKS_ERROR,
      })
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: localization.data.somethingWentWrong, type: NotifyType.Danger },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    }
  }
}

export interface IMyBooksActions {
  getMyBooks: (localization: ILocalization) => void
  addBook: (localization: ILocalization, id: string) => void
  removeBook: (localization: ILocalization, id: string) => void
}
