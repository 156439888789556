import { IProblem, ISubProblem } from '../../../../api/types.problem'
import { api } from '../../../../api'
import { CdnImageSettings } from '../../../../api/api.images'

export const getImageUrl = ({ imageOrigin, image }: { imageOrigin?: string; image?: string }) =>
  imageOrigin ? api.images.url(imageOrigin) : image

export const getProblemImageUrl = (
  problem: IProblem | ISubProblem,
  cdnImageOptions?: CdnImageSettings
) => {
  if (problem.cdnImage) {
    return api.images.cdnUrl(problem.cdnImage, cdnImageOptions)
  }

  if (problem.cdnDrawingAreaImage) {
    return api.images.cdnUrl(problem.cdnDrawingAreaImage, cdnImageOptions)
  }

  return problem.image
    ? getImageUrl({
        imageOrigin: problem.imageOrigin,
        image: problem.image,
      })
    : problem.drawingAreaImage
}
