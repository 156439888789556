import { IconButton, Menu, MenuItem } from '@mui/material'
import { MuiMenuProps } from 'features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'

import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { DropdownPopupIds, RemovableTypes } from '../../../../../helpers/enums'
import { openSimpleConfirmModal } from 'redux/modules/modal.module'
import { useDispatch } from 'react-redux'
import { IStudent } from 'api/types.users'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { ClassroomGroup } from '../../../../../api/types.classes'

type StudentsGroupOptionsMenuProps = { student: IStudent; group: ClassroomGroup }

const StudentsGroupOptionsMenu = ({ student, group }: StudentsGroupOptionsMenuProps) => {
  const dispatch = useDispatch()
  const t = useText()
  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.STUDENT_LIST_ITEM}>
      {(popupState) => (
        <>
          <Button
            size={SIZE.SMALL}
            variant={BUTTON_VARIANT.TERTIARY}
            icon={<OptionIcon size={18} color='var(--icon-color)' />}
            {...bindTrigger(popupState)}
          />
          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close()
                dispatch(openSimpleConfirmModal(student, RemovableTypes.StudentFromGroup, group))
              }}
            >
              <span style={{ color: COLORS.PRIMARY_RED }}>{t.removeStudentFromGroup}</span>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default StudentsGroupOptionsMenu
