import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import { getProblemsByLevel } from './helpers'
import styles from './SectionProblems.module.scss'
import Problems from './Problems/Problems'
import { useUnit } from 'effector-react'
import { selectAllBySection } from '../model/selectedProblems'
import useText from 'i18n/hook'
import { $problems } from '../model/problems'
import { PROBLEM_LEVELS } from './types'

import { IProblem } from '../../../../api/types.problem'

type SectionProblemsProps = {
  sectionId: string
}

type SectionProblemsHandlers = {
  selectAllProblems: () => void
}

const SectionProblems = forwardRef<SectionProblemsHandlers, SectionProblemsProps>(
  ({ sectionId }, ref) => {
    const t = useText()
    const problems = useUnit($problems)
    const currentSectionProblems = problems.get(sectionId)
    const problemsByLevel = useMemo(
      () =>
        currentSectionProblems
          ? getProblemsByLevel(currentSectionProblems)
          : new Map<PROBLEM_LEVELS, IProblem[]>(),
      [currentSectionProblems]
    )

    const selectAllProblems = () => {
      if (!problems) {
        return
      }
      selectAllBySection(sectionId)
    }

    useImperativeHandle(
      ref,
      () => ({
        selectAllProblems,
      }),
      []
    )

    if (currentSectionProblems && currentSectionProblems.length > 0) {
      return <Problems problemsByLevel={problemsByLevel} sectionId={sectionId} />
    }

    return <p className={styles.Text}>{t.noProblemsFound}</p>
  }
)

export default SectionProblems
