import { IMe } from 'api/types.users'

import { IClass, SourceType } from '../api/types.classes'

export enum SyncLevel {
  NO_SYNC = 'NO_SYNC',
  PARTLY_SYNC = 'PARTLY_SYNC',
  FULL_SYNC = 'FULL_SYNC',
}

const FULL_SYNC_SOURCES = [SourceType.skolon, SourceType.clever, SourceType.edlink]
const PARTLY_SYNC_SOURCES = [SourceType.mathSync, SourceType.ss12000, SourceType.matteappen]

export const getSyncLevel = (me: IMe | null, classDetails?: IClass) => {
  if (!me?.municipality && !me?.municipality.isPrivate) return SyncLevel.NO_SYNC

  if (classDetails && FULL_SYNC_SOURCES.includes(classDetails.source)) {
    return SyncLevel.FULL_SYNC
  }

  if (
    me.municipality.isPrivate &&
    classDetails &&
    PARTLY_SYNC_SOURCES.includes(classDetails.source)
  ) {
    return SyncLevel.PARTLY_SYNC
  }

  return SyncLevel.NO_SYNC
}

const rosteredSources = [
  SourceType.skolon,
  SourceType.clever,
  SourceType.mathSync,
  SourceType.edlink,
  SourceType.ss12000,
]

export const isClassCreatedManually = (source?: SourceType) => {
  return source === SourceType.matteappen || (source && !rosteredSources.includes(source))
}

export const isRosteredClass = (classDetails?: IClass) =>
  !!classDetails && rosteredSources.includes(classDetails.source)

export const hasDisplayName = (classDetails: IClass): classDetails is Required<IClass> =>
  isRosteredClass(classDetails) && !!classDetails.displayName
