import React, { useState } from 'react'
import styles from './AnswerCreatorBoard.module.scss'
import SelectUnit from '../../../ui/selects/SelectUnit/SelectUnit'
import NumpadUnits from '../../../ui/selects/SelectUnit/NumpadUnits/NumpadUnits'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import StaticMathInputWrapper from './StaticMathInputWrapper'
import { mathNumberFilter } from '../../mathEditor/middlewares/middlewares'
import { setAnswerNumeric, setSelectedValue } from '../../../redux/modules/problemCreator.module'

const TabNumericAnswer = () => {
  const dispatch = useDispatch()
  const selectedValue = useSelector((state: RootState) => state.problemCreatorData.selectedValue)
  const answerNumeric = useSelector((state: RootState) => state.problemCreatorData.answerNumeric)
  const [isOpen, setIsOpen] = useState(false)
  const setCurrentSelectedValue = (param: string | null) => {
    dispatch(setSelectedValue(param))
  }

  const onPress = (value: string) => {
    setCurrentSelectedValue(value)
    setIsOpen(false)
  }

  return (
    <StaticMathInputWrapper
      mathInputValueMiddleware={mathNumberFilter}
      initialValue={answerNumeric}
      handleMathInputChange={setAnswerNumeric}
      placeholder='123'
      className={styles.MathInputWidth}
    >
      <SelectUnit
        value={selectedValue}
        isOpenedExt={isOpen}
        onModalStateChange={setIsOpen}
        onRemoveButtonPress={setCurrentSelectedValue}
        className={styles.SelectUnitExtraClass}
      >
        <NumpadUnits isButtonDisabled={false} onPress={onPress} />
      </SelectUnit>
    </StaticMathInputWrapper>
  )
}

export default TabNumericAnswer
