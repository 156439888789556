import React from 'react'
import { SvgIconProps } from '../types/types'

const AccountIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_217)'>
        <path
          d='M18.1814 2.23711V22.7517C18.1814 23.2494 17.778 23.6529 17.2802 23.6529H3.48763C2.98992 23.6529 2.58645 23.2494 2.58645 22.7517V4.72065C2.58645 4.48166 2.49151 4.25244 2.32251 4.08344L1.11341 2.87433C0.545704 2.30664 0.947775 1.33594 1.75064 1.33594H17.2802C17.778 1.33594 18.1814 1.73941 18.1814 2.23711Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M24.1696 7.2768C24.093 3.6385 21.0679 3.27399 18.925 3.50337C18.4912 3.54981 18.1797 3.92783 18.1797 4.36412V5.72057C18.1797 6.21827 18.5832 6.62174 19.0809 6.62174H20.3798C20.8843 6.62174 21.2906 7.0347 21.2829 7.53926C21.2513 9.63539 21.2249 13.1025 21.2988 15.2796C21.3782 17.6255 22.6582 16.3416 23.6866 15.2986C23.8453 15.1376 23.9389 14.925 23.9535 14.6995C24.0274 13.5592 24.2463 10.9193 24.1696 7.2768Z'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M2.55078 10.4494C3.8836 9.82949 7.34892 8.90582 10.5477 10.1704C13.7464 11.4351 16.7778 10.2634 17.8936 9.51953'
          stroke={color}
          strokeWidth='1.66'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_217'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccountIcon
