import { useUnit } from 'effector-react'
import { updatePendingMutation } from 'features/heatmap/ExamMode/model/pendingMutationStatus'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import HelpQueue from 'features/helpQueue/HelpQueue'
import AnonymousCorrectnessButton from 'features/tables/HeatmapTable/ui/AnonymousCorrectnessButton/AnonymousCorrectnessButton'
import AnonymousNameButton from 'features/tables/HeatmapTable/ui/AnonymousNameButton/AnonymousNameButton'
import CompressHeatmapButton from 'features/tables/HeatmapTable/ui/CompressHeatmapButton/CompressHeatmapButton'
import HeatmapTable from 'features/tables/HeatmapTable/ui/HeatmapTable/HeatmapTable'
import socket from 'helpers/socket.helper'
import { getTeacherData } from 'helpers/user.helpers'
import { useSocketEvents } from 'lib/hooks/useSocketEvents'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  IExerciseWithoutStats,
  getExerciseWithoutStats,
  getStatsForSingleStudent,
  setAnswerWatched,
  updateStats,
} from 'redux/modules/exerciseWithoutStats.module'
import {
  clearStudentsExamStats,
  getStudentExamStats,
  getStudentsExamStats,
} from 'redux/modules/studentsExamStats'
import { RootState } from 'store/store'
import { $commonErrorData } from '../../features/Solutions/CommonError/model/commonErrorData'
import { fetchCommonErrorDataFx } from '../../features/Solutions/CommonError/model/request'
import DownloadResults from '../../features/heatmap/DownloadResults/DownloadResults'
import HeatmapMenu from '../../features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuTabs from '../../features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabs'
import SortMenu from '../../features/heatmap/SortMenu/SortMenu'
import { HeatmapTabsParams, socketEvents } from '../../helpers/enums'
import { IState } from '../../interfaces/state.interface'
import styles from './Heatmap.module.scss'
import HeatmapSkeleton from './HeatmapSkeleton'
import { IStudentsExamStats } from '../../api/types.statistics'

const HeatmapView = () => {
  const { exerciseId } = useParams<{ exerciseId: string }>()
  const dispatch = useDispatch()
  const exerciseWithoutStats: IState<IExerciseWithoutStats> = useSelector(
    (state: RootState) => state.exerciseWithoutStats
  )
  const commonErrorData = useUnit($commonErrorData)
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))
  const studentsExamStats: IState<IStudentsExamStats[] | {}> = useSelector(
    (state: RootState) => state.studentsExamStats
  )

  const buttonColor = getButtonColor(exerciseWithoutStats.data.testModeEnabled)

  useEffect(() => {
    if (exerciseWithoutStats.data?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }
  }, [exerciseId])

  useEffect(() => {
    const correctExerciseId = exerciseWithoutStats.data?._id
    const isExamMode = exerciseWithoutStats.data?.testModeEnabled

    updatePendingMutation(null)

    if (correctExerciseId && isExamMode) {
      dispatch(getStudentsExamStats(exerciseId))
      return
    }

    dispatch(clearStudentsExamStats())
  }, [exerciseWithoutStats.data])

  useEffect(() => {
    fetchCommonErrorDataFx({
      exerciseId,
    })
  }, [exerciseId])

  useSocketEvents(socket, [
    {
      event: socketEvents.answerWatched,
      handler: (params: Parameters<typeof setAnswerWatched>[0]) =>
        dispatch(setAnswerWatched(params)),
    },
    {
      event: socketEvents.exerciseUpdated,
      handler: async (data) => {
        if (data.exerciseId !== exerciseId) return
        dispatch(getExerciseWithoutStats(exerciseId))
      },
    },
    {
      event: socketEvents.statisticsUpdate,
      handler: async (data) => {
        if (data.exerciseId !== exerciseId || exerciseWithoutStats.loading) return
        if (!data.userId) return updateStats(data.exerciseId)
        return dispatch(getStatsForSingleStudent(data.exerciseId, data.userId))
      },
    },
    {
      event: socketEvents.studentStateUpdated,
      handler: async (data) => {
        if (
          data.exerciseId !== exerciseId ||
          !exerciseWithoutStats.data.testModeEnabled ||
          studentsExamStats.loading ||
          exerciseWithoutStats.loading
        )
          return
        return dispatch(
          getStudentExamStats({ exerciseId: data.exerciseId, studentId: data.studentId })
        )
      },
    },
  ])

  const isLoadingState =
    !exerciseWithoutStats.data?._id ||
    exerciseWithoutStats.data?._id !== exerciseId ||
    commonErrorData === null ||
    (exerciseWithoutStats.data.testModeEnabled &&
      isEmpty(studentsExamStats.data) &&
      !Array.isArray(studentsExamStats.data))

  if (isLoadingState) return <HeatmapSkeleton />

  return (
    <div className={styles.HeatmapPageContainer}>
      <HeatmapMenu
        actions={
          <>
            <HelpQueue />
            <VerticalDivider />
            <DownloadResults
              exerciseId={exerciseWithoutStats.data._id}
              exerciseName={exerciseWithoutStats.data.name}
              buttonColor={buttonColor}
            />
            <CompressHeatmapButton />
            <AnonymousNameButton buttonColor={buttonColor} />
            <AnonymousCorrectnessButton buttonColor={buttonColor} />
            <SortMenu buttonColor={buttonColor} />
          </>
        }
        navigation={<HeatmapMenuTabs activeTab={HeatmapTabsParams.HEATMAP} />}
        title={exerciseWithoutStats.data.name}
      />
      <HeatmapTable
        exerciseWithoutStatsData={exerciseWithoutStats.data}
        teacher={currentTeacher}
        studentsExamStatsData={studentsExamStats.data}
      />
    </div>
  )
}

export default HeatmapView
