import React from 'react'
import './PieChartLegend.scss'
import { currentLocale } from '../../../../config/modules.config'
import { ILocalization } from "../../../../config/languages.config";

interface IPropsPieChartLegend {
  localization: ILocalization
}

const PieChartLegend: React.FC<IPropsPieChartLegend> = ({ localization }) => {
  const wrapperWidth = currentLocale.currentLang === 'sv-SE' && 'pie-chart-legend-item-wrapper-se'
  return (
    <div className='pie-chart-legend-container'>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle dark-green' />
          <div className='pie-chart-legend-item-wrapper-text'>
            80-100% {localization.data.correctTxt}
          </div>
        </div>
      </div>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle green' />
          <div className='pie-chart-legend-item-wrapper-text'>
            60-79% {localization.data.correctTxt}
          </div>
        </div>
      </div>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle yellow' />
          <div className='pie-chart-legend-item-wrapper-text'>
            40-59% {localization.data.correctTxt}
          </div>
        </div>
      </div>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle red' />
          <div className='pie-chart-legend-item-wrapper-text'>
            20-39% {localization.data.correctTxt}
          </div>
        </div>
      </div>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle dark-red ' />
          <div className='pie-chart-legend-item-wrapper-text'>
            0-19% {localization.data.correctTxt}
          </div>
        </div>
      </div>
      <div className='pie-chart-legend-item'>
        <div className={`pie-chart-legend-item-wrapper ${wrapperWidth}`}>
          <div className='pie-chart-legend-item-wrapper-circle grey' />
          <div className='pie-chart-legend-item-wrapper-text'>{localization.data.noAnswer}</div>
        </div>
      </div>
    </div>
  )
}

export default PieChartLegend
