import { useUnit } from 'effector-react'
import { resetReorderProblemStores } from 'features/bookshelf/ReorderProblems/model/helpers'
import { resetErrorMessages } from 'features/caf/errorMessages/model/errorMessages'
import { cleanCafStoresData } from 'features/caf/helpers'
import ExerciseDetailsSkeleton from 'features/exerciseDetails/ExerciseDetailsSkeleton'
import { getSortedClassrooms } from 'features/exerciseDetails/helpers'
import useText from 'i18n/hook'
import usePageReloadDetector from 'lib/hooks/usePageReloadDetector'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { resetChapters } from '../../features/bookshelf/Book/model/chapters'
import { fetchProblemsBreadcrumbsFx } from '../../features/bookshelf/Book/model/requests'
import { resetSectionPaths } from '../../features/bookshelf/Book/model/sectionPaths'
import { resetSections } from '../../features/bookshelf/Book/model/sections'
import {
  $selectedProblems,
  resetSelectedProblems,
} from '../../features/bookshelf/Book/model/selectedProblems'
import { resetSearchIsActive } from '../../features/bookshelf/model/foundBooks'
import { resetMyBooks } from '../../features/bookshelf/model/myBooks'
import ExerciseDetails from '../../features/exerciseDetails/ExerciseDetails'
import { LoadingStatus } from '../../helpers/enums'

const CreateExerciseView = () => {
  const [breadcrumbsLoadingState, setBreadcrumbsLoadingState] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  )
  const t = useText()
  const isReloaded = usePageReloadDetector()
  const selectedProblems = useUnit($selectedProblems)

  const allClasses = useSelector((state: RootState) => state.classes)
  const groups = useSelector((state: RootState) => state.groups)

  const classrooms = useMemo(
    () => getSortedClassrooms(allClasses.data?._embedded?.classes, groups?.data?._embedded?.groups),
    [allClasses.data?._embedded?.classes, groups?.data?._embedded?.groups]
  )

  useEffect(() => {
    if (selectedProblems.size === 0) {
      setBreadcrumbsLoadingState(LoadingStatus.LOADED)
      return
    }

    const ids = [...selectedProblems.values()].map((problems) => [...problems]).flat()
    setBreadcrumbsLoadingState(LoadingStatus.LOADING)
    fetchProblemsBreadcrumbsFx(ids).finally(() => {
      setBreadcrumbsLoadingState(LoadingStatus.LOADED)
    })
  }, [])

  useEffect(() => {
    if (!isReloaded) {
      cleanCafStoresData()
    }
    return () => {
      resetErrorMessages()
      resetChapters()
      resetSections()
      resetSectionPaths()
      resetSelectedProblems()
      resetMyBooks()
      resetSearchIsActive()
      resetReorderProblemStores()
    }
  }, [isReloaded])

  if (
    breadcrumbsLoadingState === LoadingStatus.LOADED &&
    !isEmpty(allClasses.data) &&
    !isEmpty(groups.data)
  ) {
    return <ExerciseDetails classrooms={classrooms} />
  }

  return <ExerciseDetailsSkeleton submitButtonText={t.createTxt} />
}

export default CreateExerciseView
