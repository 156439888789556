import clsx from 'clsx'
import { LayoutGroup } from 'framer-motion'
import React, { forwardRef } from 'react'
import styles from './UITabs.module.scss'

type Ref = HTMLUListElement

type TabsOptions = {
  layoutId: string
  withDividerBottom: boolean
}

type TabsProps = JSX.IntrinsicElements['ul'] & TabsOptions

const UITabs = forwardRef<Ref, TabsProps>(
  ({ layoutId, className, children, withDividerBottom, ...rest }, ref) => {
    const mergedTabsClassNames = clsx(
      styles.Tabs,
      { [styles.DividerBottom]: withDividerBottom },
      className
    )

    return (
      <LayoutGroup id={layoutId}>
        <ul ref={ref} className={mergedTabsClassNames} {...rest}>
          {children}
        </ul>
      </LayoutGroup>
    )
  }
)

export default UITabs
