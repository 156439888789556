import { NotifyType } from 'helpers/enums'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ModalBody, ModalFooter } from 'reactstrap'
import { NOTIFY } from 'redux/modules/notify.module'
import AddExistingStudentLink from '../AddExistingStudentLink/AddExistingStudentLink'
import { AddStudentStage } from '../ModalRegisterWindow.component'
import StageHeader from '../StageHeader/StageHeader'
import './AddStudentWithClassCode.scss'
import { ILocalization } from "../../../../config/languages.config";

type AddStudentWithClassCodeProps = {
  localization: ILocalization
  activeStage: AddStudentStage
  setActiveStage: (tabId: AddStudentStage) => void
  invitationalCode: number
  onAddExistingStudentClick: () => void
}

const AddStudentWithClassCode = ({
  localization,
  activeStage,
  setActiveStage,
  invitationalCode,
  onAddExistingStudentClick,
}: AddStudentWithClassCodeProps) => {
  const dispatch = useDispatch()

  const { copyCode, addStudentsWithCodeTxt, copied } = localization.data

  const handleCopyButtonClick = useCallback(() => {
    dispatch({
      type: NOTIFY.NOTIFY_BEGIN,
      payload: { message: copied, type: NotifyType.Success },
    })

    setTimeout(() => {
      dispatch({ type: NOTIFY.NOTIFY_END })
    }, 500)

    if (invitationalCode) navigator.clipboard.writeText(invitationalCode.toString())
  }, [])

  return (
    <>
      <StageHeader
        localization={localization}
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
      <ModalBody>
        <div className='class-code-wrapper'>
          <p className='class-code-paragraph'>{addStudentsWithCodeTxt}</p>

          {invitationalCode && (
            <h1 className='class-code-code'>
              {invitationalCode.toString().replace(/^(.{2})(.{2})(.*)$/, '$1 $2 $3')}
            </h1>
          )}
        </div>
      </ModalBody>
      <ModalFooter className='class-code-footer'>
        <button className='class-code-button' onClick={handleCopyButtonClick}>
          <span>{copyCode}</span>
        </button>
        <AddExistingStudentLink
          onAddExistingStudentClick={onAddExistingStudentClick}
          localization={localization}
        />
      </ModalFooter>
    </>
  )
}

export default AddStudentWithClassCode
