import { QuillOptionsStatic } from 'quill'
import katex from 'katex'

export const QUILL_EDITOR_SETTINGS: QuillOptionsStatic = {
  theme: 'snow',
  modules: {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
    syntax: {
      highlight: katex.renderToString,
    },
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'indent',
    'link',
    'image',
    'code-block',
    'formula',
    'script',
  ],
}
