import { useUnit } from 'effector-react'
import React from 'react'
import DraggableProblemsList from '../DraggableProblemsList/DraggableProblemsList'
import ProblemItem from '../DraggableProblemsList/ProblemItem/ProblemItem'
import { sortProblemsBySortOption } from '../helpers'
import { selectedProblemsDataModel } from '../model/selectedProblemsData'
import { currentSortOptionModel } from '../model/sortOption/currentSortOption'
import { SortOptions } from '../model/sortOption/sortOptionModel'
import styles from './ProblemsList.module.scss'

const ProblemsList = () => {
  const sortOption = useUnit(currentSortOptionModel.$state)
  const selectedProblemsData = useUnit(selectedProblemsDataModel.$state)

  if (!selectedProblemsData) return null

  if (sortOption === SortOptions.MANUAL) return <DraggableProblemsList />

  const sortedProblemsBySortOption = sortProblemsBySortOption(selectedProblemsData, sortOption)
  return (
    <ul className={styles.ProblemsList}>
      {Array.from(sortedProblemsBySortOption.values()).map((problem, index) => (
        <ProblemItem
          key={problem._id}
          problem={problem}
          order={index + 1}
          problemsCount={sortedProblemsBySortOption.size}
        />
      ))}
    </ul>
  )
}

export default ProblemsList
