import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import {
  $heatmapSettings,
  toggleAnonymousNameMode,
} from 'features/tables/HeatmapTable/model/heatmapSettings'
import React from 'react'
import useText from '../../../../i18n/hook'
import IncognitoIcon from '../../../../ui/icons/IncognitoIcon/IncognitoIcon'
import './HideNames.scss'

const HideStudentsNames = () => {
  const t = useText()
  const isAnonymousNameMode = useStoreMap(
    $heatmapSettings,
    (settings) => settings.isAnonymousNameMode
  )

  return (
    <div className='hiding-menu'>
      <Button
        color={BUTTON_COLOR.BLUE}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.MEDIUM}
        icon={<IncognitoIcon color='var(--icon-color)' size={20} />}
        onClick={() => toggleAnonymousNameMode()}
        isActive={isAnonymousNameMode}
        tooltipText={t.anonymousName}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
      />
    </div>
  )
}

export default HideStudentsNames
