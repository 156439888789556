import React, { useState } from 'react'
import PencilIcon from 'ui/icons/PencilIcon/PencilIcon'
import BinIcon from 'ui/icons/BinIcon/BinIcon'
import useText from 'i18n/hook'
import { checkProblemCreationAllowed } from './helpers'
import { COLORS } from '@magmamath/ui'
import { DropDownMode, DropDownPosition } from 'features/myBook/types'
import { useUnit } from 'effector-react'
import styles from './DropdownMenu.module.scss'
import clsx from 'clsx'
import { MyBookModel } from '../model'

type DropdownMenuProps = {
  onEdit: () => void,
  onDelete: () => void,
  isOpen: boolean,
  position: DropDownPosition
  mode: DropDownMode | null
  isDeleteHidden?: boolean
}

enum DropdownTypes {
  EDIT_SECTION = 'editSection',
  EDIT_PROBLEM = 'sections',
  DELETE_ITEM = 'deleteItem'
}

const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(({
  onEdit,
  onDelete,
  isOpen,
  position,
  mode,
  isDeleteHidden
}, ref) => {
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const selectedProblem = useUnit(MyBookModel.$selectedProblem)
  const [hoveredTab, setHoveredTab] = useState<DropdownTypes | null>(null)
  const t = useText()

  if (!selectedSection || !isOpen || !mode) return null
  const isSectionMode = mode === DropDownMode.SECTION

  const isEditable = isSectionMode || checkProblemCreationAllowed(selectedProblem?.createdAt)
  return (
    <div
      className={clsx(styles.DropDownMenu, {
        [styles.OpenDropDownMenu]: isOpen
      })}
      ref={ref}
      style={position}
    >
      {isEditable && (
        <div className={clsx(styles.Option, styles.TopOption)}
          onClick={onEdit}
          onMouseEnter={() => setHoveredTab(DropdownTypes.EDIT_SECTION)}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <p>
            <PencilIcon
              color={
                hoveredTab === DropdownTypes.EDIT_SECTION
                  ? COLORS.PRIMARY_ORANGE
                  : COLORS.NEUTRAL_7
              }
              size={16} />
            {isSectionMode ? t.editSectionTxt : t.editProblemTxt}
          </p>
        </div>)}
      {!isDeleteHidden && (
        <div
          className={clsx(styles.Option, styles.DeleteOption)}
          onClick={onDelete}
          onMouseEnter={() => setHoveredTab(DropdownTypes.DELETE_ITEM)}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <p>
            <BinIcon color={
              hoveredTab === DropdownTypes.DELETE_ITEM
                ? COLORS.RED_6
                : COLORS.PRIMARY_RED
            }
              size={16} />
            {isSectionMode ? t.deleteSection : t.deleteProblem}
          </p>
        </div>)}
    </div>)
})

export default DropdownMenu
