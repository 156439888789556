import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { PATHS } from 'config/pathnames.config'
import { isAuth } from 'helpers/storage.helper'
import { IMe } from 'api/types.users'
import React, { useEffect } from 'react'
import { ILocalizationActions } from '../../redux/modules/localization.module'
import './GetStarted.view.scss'
import { localStorageKeys } from '../../config/localStorageKeys'
import { CurrentLanguage } from '../../helpers/enums'
import { config } from '../../config'
import { ILocalization } from '../../config/languages.config'
// tslint:disable-next-line: no-var-requires
const Confetti = require('react-confetti')

export interface IGetStartedProps {
  localization: ILocalization
  localizationActions: ILocalizationActions
  history: any
  me: IMe
  location: Location
}

const GetStarted: React.FC<IGetStartedProps> = ({
  me,
  localization,
  localizationActions,
  history,
}) => {
  const {
    youAreAllSetToGo,
    weHaveSentEmailTo,
    withSomeTipsHowToGetStarted,
    getStarted,
    dontHestitate,
    wohoo,
  } = localization.data

  useEffect(() => {
    if (!isAuth()) {
      window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
    }
  }, [])

  const currentLanguageIsSE = localStorage.getItem(localStorageKeys.language) === CurrentLanguage.SE

  const moveToExercise = () => {
    history.push(PATHS.EXERCISES.EXERCISES_MAIN)
  }

  return (
    <div className='get-started-wrapper'>
      <PanelHeader
        title=' '
        backButton={false}
        className='get-started-header'
        me={me}
        localization={localization as any}
        localizationActions={localizationActions}
        history={history}
        location={location}
      />
      <div>
        <Confetti width={window.outerWidth} height={window.outerHeight} />
      </div>
      <ViewContainer className='get-started-container-view' history={history}>
        <div className='get-started-container'>
          <div>
            <p>{wohoo}!</p>
            <p>{youAreAllSetToGo}</p>
          </div>
          <div>
            <p>
              {currentLanguageIsSE ? (
                <>
                  {weHaveSentEmailTo} <span>{me?.email}</span> {withSomeTipsHowToGetStarted}
                </>
              ) : (
                <>{dontHestitate}</>
              )}
            </p>
          </div>
          <button className='get-started-btn'>
            <a className='started-btn-link' onClick={moveToExercise}>
              <span className='started-btn-txt'>{getStarted}</span>
            </a>
          </button>
        </div>
      </ViewContainer>
    </div>
  )
}

export default GetStarted
