import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const FractionIcon: React.FC<SvgIconProps> = ({ color = COLORS.NEUTRAL_10 }) => {
  return (
    <svg width='21' height='35' viewBox='0 0 21 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.68633 19.7454V23.1643C7.68633 23.5569 7.67461 23.9377 7.65117 24.3069C7.6336 24.676 7.61895 24.9631 7.60723 25.1682H7.68633C7.95 24.7346 8.33086 24.3684 8.82891 24.0696C9.32695 23.7708 9.9627 23.6213 10.7361 23.6213C11.9432 23.6213 12.9041 24.0403 13.6189 24.8782C14.3397 25.7102 14.7 26.9495 14.7 28.5959C14.7 29.6799 14.5359 30.594 14.2078 31.3381C13.8797 32.0823 13.4168 32.6448 12.8191 33.0256C12.2215 33.4065 11.5154 33.5969 10.701 33.5969C9.93926 33.5969 9.31231 33.4563 8.82012 33.175C8.33379 32.8879 7.95879 32.5393 7.69512 32.1292H7.58086L7.28203 33.4211H6.22735V19.7454H7.68633ZM10.4988 24.843C9.80156 24.843 9.24785 24.9807 8.8377 25.2561C8.42754 25.5256 8.13164 25.9358 7.95 26.4866C7.77422 27.0315 7.68633 27.7229 7.68633 28.5608V28.6399C7.68633 29.8528 7.88848 30.7815 8.29278 31.426C8.69707 32.0647 9.43242 32.384 10.4988 32.384C11.3953 32.384 12.0662 32.0559 12.5115 31.3997C12.9627 30.7434 13.1883 29.8059 13.1883 28.5872C13.1883 27.345 12.9656 26.4104 12.5203 25.7834C12.0809 25.1565 11.407 24.843 10.4988 24.843Z'
        fill={COLORS.NEUTRAL_10}
      />
      <path
        d='M10.3406 5.20898C11.4891 5.20898 12.3416 5.4668 12.8982 5.98242C13.4549 6.49805 13.7332 7.32129 13.7332 8.45215V15H12.6697L12.3885 13.5762H12.3182C12.0486 13.9277 11.7674 14.2236 11.4744 14.4639C11.1814 14.6982 10.8416 14.877 10.4549 15C10.074 15.1172 9.60528 15.1758 9.04863 15.1758C8.4627 15.1758 7.94121 15.0732 7.48418 14.8682C7.03301 14.6631 6.67559 14.3525 6.41192 13.9365C6.1541 13.5205 6.0252 12.9932 6.0252 12.3545C6.0252 11.3936 6.40606 10.6553 7.16778 10.1396C7.92949 9.62402 9.08965 9.34277 10.6482 9.2959L12.3094 9.22559V8.63672C12.3094 7.80469 12.1307 7.22168 11.7732 6.8877C11.4158 6.55371 10.9119 6.38672 10.2615 6.38672C9.75762 6.38672 9.27715 6.45996 8.82012 6.60645C8.36309 6.75293 7.92949 6.92578 7.51934 7.125L7.0711 6.01758C7.50469 5.79492 8.00274 5.60449 8.56524 5.44629C9.12774 5.28809 9.71953 5.20898 10.3406 5.20898ZM12.2918 10.2539L10.824 10.3154C9.62285 10.3623 8.77617 10.5586 8.28399 10.9043C7.7918 11.25 7.5457 11.7393 7.5457 12.3721C7.5457 12.9229 7.7127 13.3301 8.04668 13.5938C8.38067 13.8574 8.82305 13.9893 9.37383 13.9893C10.2293 13.9893 10.9295 13.752 11.4744 13.2773C12.0193 12.8027 12.2918 12.0908 12.2918 11.1416V10.2539Z'
        fill={COLORS.NEUTRAL_10}
      />
      <line x1='5.1001' y1='17.9211' x2='15.3001' y2='17.9211' stroke={color} />
    </svg>
  )
}

export default FractionIcon
