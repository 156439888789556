import React from 'react'
import styles from './CustomTimePickerDropdown.module.scss'
import { Events, TimePickerType, USTimeType } from 'helpers/enums'
import { HALF_DAY } from 'helpers/timeConverterHelper'
import { Time } from 'interfaces/scheduleTime.interface'
import { Listbox } from '@magmamath/ui'
import clsx from 'clsx'
import { getValue } from './getValue'

export interface Option {
  name: string
  value: string
}
interface ICustomTimePickerDropdown {
  options: Option[]
  time: Time
  onTimeChange: (newValue: Time) => void
  timePickerType: TimePickerType
}

const CustomTimePickerDropdown: React.FC<ICustomTimePickerDropdown> = ({
  options,
  time,
  onTimeChange,
  timePickerType,
}) => {
  const timeSelectorRef = React.useRef<any>(null)

  const manageTimeSelectorState = React.useCallback((event: MouseEvent) => {
    const selectorElement = timeSelectorRef.current
    if (!selectorElement) {
      return
    }

    const target = event.target
    if (selectorElement.contains(target)) {
      return
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener(Events.CLICK, manageTimeSelectorState, true)

    return () => {
      window.removeEventListener(Events.CLICK, manageTimeSelectorState, true)
    }
  }, [])

  const onChange = (data: Option) => {
    const value = +data.value
    const changeTimeObjectMap = {
      [TimePickerType.Hour]: {
        ...time,
        hour: value,
        type: value < HALF_DAY ? USTimeType.AM : USTimeType.PM,
      },
      [TimePickerType.Minute]: { ...time, minute: value },
      [TimePickerType.HourType]: {
        ...time,
        type: value,
        hour: time.hour <= HALF_DAY ? time.hour : time.hour - HALF_DAY,
      },
    }

    onTimeChange(changeTimeObjectMap[timePickerType])
  }
  return (
    <div className={clsx(styles.TimeSelector, styles.ListBoxWrapper)} ref={timeSelectorRef}>
      <Listbox
        classes={{ ListboxOptions: styles.ListBoxOptions }}
        value={getValue(time, timePickerType)}
        onChange={onChange}
        multiple={false}
        isActive={false}
      >
        {options.map((option) => (
          <Listbox.Option value={option} key={option.value}>
            {option.name}
          </Listbox.Option>
        ))}
      </Listbox>
    </div>
  )
}

export default CustomTimePickerDropdown
