import React from 'react'
import { SvgIconProps } from '../types/types'

const CheckmarkIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.478516 7.99794L5.74061 13.26L14.5107 2.73584'
        stroke={color}
        strokeWidth='0.996'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CheckmarkIcon
