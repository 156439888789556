import { createEvent, createStore } from 'effector'
import { SectionPath } from './types'
import { fetchChaptersFx, fetchProblemsBreadcrumbsFx, fetchSectionsFx } from './requests'
import { CategoryTypes } from '../../../../helpers/enums'
import { removeSelectedProblems } from './selectedProblems'
import { fetchFoundBooksFx } from '../../model/requests'
import { BreadcrumbIndex } from '../../model/types'

export const resetSectionPaths = createEvent()
export const removeSectionByChapterId = createEvent<string>()
export const $sectionPaths = createStore<Map<string, SectionPath>>(new Map())
  .on(removeSectionByChapterId, (state, chapterId) => {
    const newState = new Map(state)

    ;[...newState.entries()].forEach(([sectionId, sectionDetails]) => {
      if (sectionDetails.chapterId === chapterId) {
        removeSelectedProblems(sectionId)
        newState.delete(sectionId)
      }
    })

    return newState
  })
  .on(fetchChaptersFx.doneData, (state, { bookId, chapters }) => {
    const newState = new Map(state)

    chapters.forEach((chapter) => {
      chapter.sections.forEach((section) => {
        newState.set(section._id, {
          id: section._id,
          name: section.name,
          bookId,
          chapterId: chapter._id,
          chapterName: chapter.name,
          problemsCount: section.problemsCount,
        })
      })
    })
    return newState
  })
  .on(fetchProblemsBreadcrumbsFx.doneData, (state, breadcrumbs) => {
    const newState = new Map(state)
    breadcrumbs.forEach((breadcrumb) => {
      newState.set(breadcrumb[CategoryTypes.Subchapter].id, {
        id: breadcrumb[CategoryTypes.Subchapter].id,
        name: breadcrumb[CategoryTypes.Subchapter].name,
        bookId: breadcrumb[CategoryTypes.Book].id,
        chapterId: breadcrumb[CategoryTypes.Chapter].id,
        chapterName: breadcrumb[CategoryTypes.Chapter].name,
      })
    })
    return newState
  })
  .on(fetchFoundBooksFx.done, (state, { result }) => {
    const newState = new Map(state)
    result.forEach((bookBreadcrumb) => {
      newState.set(bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].id, {
        id: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].id,
        name: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].name,
        chapterId: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.CHAPTER].id,
        chapterName: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.CHAPTER].name,
        bookId: bookBreadcrumb.breadcrumbs[BreadcrumbIndex.BOOK].id,
      })
    })
    return newState
  })
  .reset(resetSectionPaths)
