import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { getAllStudents } from '../../../helper'
import { useStoreMap, useUnit } from 'effector-react'
import { $heatmapSettings } from '../../../../tables/HeatmapTable/model/heatmapSettings'
import useText from '../../../../../i18n/hook'
import { $sortedStudents } from '../../../../tables/HeatmapTable/model/exercise'
import { sortStudentsByIndexedOrder } from '../../sortStudentsByCustomOrder'
import { getFullName } from '../../../../../helpers/user.helpers'
import { getStudentDisplayIndex } from '../../getStudentDisplayIndex'

type ErrorMessageStudentsListProps = { students: string[] }

const ErrorMessageStudentsList = ({ students }: ErrorMessageStudentsListProps) => {
  const t = useText()
  const exercise = useSelector((state: RootState) => state.exerciseWithoutStats.data)
  const isAnonymousNameMode = useStoreMap(
    $heatmapSettings,
    (settings) => settings.isAnonymousNameMode
  )
  const sortedStudents = useUnit($sortedStudents)
  const allStudents = getAllStudents(exercise, students)

  return (
    <>
      {sortStudentsByIndexedOrder(allStudents, sortedStudents).map((student) => (
        <div key={student?._id}>
          {isAnonymousNameMode
            ? `${t.studentTxt} ${getStudentDisplayIndex(student, sortedStudents)}`
            : `${getFullName(student.firstName, student.lastName)}`}
        </div>
      ))}
    </>
  )
}
export default ErrorMessageStudentsList
