import { SkillStatisticsElasticRecord } from '@magmamath/skills-utils'

export interface ISkill {
  createdAt: string
  hints: []
  mainSection: string
  name: string
  order: number
  updatedAt: string
  __v: number
  _id: string
  book: string
  bookName: string
  chapter: string
  chapterName: string
}

export type GetAllSkillsPayload = {
  limit?: number
  ids?: string
  offset: number
}
export type GetAllSkillsResponse = {
  items: ISkill[]
}

export type GetAssignmentStatisticsResponse = SkillStatisticsElasticRecord[]

export enum AssignmentStatisticsEntityType {
  CLASSES = 'classes',
  STUDENTS = 'students',
}
