import { createEvent, restore, sample } from 'effector'
import { Group, SelectionUpdateEvent } from '../types'
import { setClassSelected } from './class'
import { selectAll, unselectAll } from './events'
import { $students, setStudentSelected, setStudents } from './students'

export const setGroups = createEvent<Group[]>()
export const setGroupsSelected = createEvent<{
  groupIds: string[]
  selected: boolean
  studentId: string
}>()
export const selectAllGroups = createEvent()
export const setGroupItemSelected = createEvent<SelectionUpdateEvent>()

export const $groups = restore(setGroups, [])
  .on(
    selectAll,
    (state) => state.map((group) => ({ ...group, selected: !!group.studentIds.length })) // skip empty groups
  )
  .on(unselectAll, (state) => state.map((group) => ({ ...group, selected: false })))
  .on(
    selectAllGroups,
    (state) => state.map((group) => ({ ...group, selected: !!group.studentIds.length })) // skip empty groups
  )
  .on(setGroupItemSelected, (state, { id, selected }) => {
    return state.map((group) => {
      if (group.id === id) {
        return { ...group, selected }
      }
      return group
    })
  })

sample({
  source: { groups: $groups, students: $students },
  clock: setGroupItemSelected,
  fn: (source, { id }) => ({ ...source, id }),
}).watch(({ groups, students, id }) => {
  const group = groups.find((group) => group.id === id)
  if (!group) return

  const allGroupsSelected = groups.every(({ selected }) => selected)
  setClassSelected({ id: group.classId, selected: allGroupsSelected })

  setStudents(
    students.map((student) => {
      const studentGroups = groups.filter((group) => group.studentIds.includes(student.id))
      if (!studentGroups?.length) return student
      const isSelected = studentGroups.some((studentGroup) =>
        studentGroup.id === id ? group.selected : studentGroup.selected
      )

      return {
        ...student,
        selected: isSelected,
      }
    })
  )
})

sample({
  source: { groups: $groups, students: $students },
  clock: setGroupsSelected,
  fn: (source, payload) => ({ ...source, ...payload }),
}).watch(({ groups, students, groupIds, studentId, selected }) => {
  setGroups(
    groups.map((group) => {
      if (!groupIds.includes(group.id)) return group

      const groupStudents = students.filter((student) => group.studentIds.includes(student.id))

      const isSelected = groupStudents.every((student) =>
        student.id === studentId ? selected : student.selected
      )

      return {
        ...group,
        selected: isSelected,
      }
    })
  )
})

sample({
  source: $groups,
  clock: setStudentSelected,
  fn: (groups, { id: studentId, selected }) => {
    const studentGroupsIds = groups
      .filter((group) => group.studentIds.includes(studentId))
      .map(({ id }) => id)

    return {
      groupIds: studentGroupsIds,
      selected,
      studentId,
    }
  },
  target: setGroupsSelected,
})
