import React from 'react'
import clsx from 'clsx'

import styles from './GradeSelectorMenu.module.scss'

import useText from '../../../../../i18n/hook'
import { getCorrectGradeName } from '../../../../GradesSelectorModal/helpers'

type GradeSelectorMenuProps = {
  grades: number[]
  onSelectGrade: (grade: number) => void
  activeGrade: number
}

const GradeSelectorMenu = ({ grades, onSelectGrade, activeGrade }: GradeSelectorMenuProps) => {
  const t = useText()

  return (
    <div className={styles.GradeSelectorMenuContainer}>
      <span className={styles.GradeSelectorTitle}>{t.goTo.trim()}...</span>
      {grades.map((grade, index) => (
        <span
          className={clsx(styles.GradeSelectorItem, { [styles.Active]: activeGrade === grade })}
          key={index}
          onClick={() => onSelectGrade(grade)}
        >
          {t.grade} {getCorrectGradeName(grade, t)}
        </span>
      ))}
    </div>
  )
}

export default GradeSelectorMenu
