import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import styles from './NoDataText.module.scss'

type NoDataTextProps = {
  text?: string
  textClassName?: string
  containerClassName?: string
} & PropsWithChildren

const NoDataText = ({ text, textClassName, children, containerClassName }: NoDataTextProps) => {
  return (
    <div className={clsx(styles.NoDataContainer, containerClassName)}>
      {children ? (
        <div className={clsx(styles.NoDataText, textClassName)}>{children}</div>
      ) : (
        <h5 className={clsx(styles.NoDataText, textClassName)}>{text}</h5>
      )}
    </div>
  )
}

export default NoDataText
