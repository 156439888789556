import * as React from 'react'
import { PropsWithChildren } from 'react'
import styles from './ButtonContentUI.module.scss'

interface IButtonContentUIProps {
  isIcon?: boolean
}

const ButtonContentUI = ({ children, isIcon }: PropsWithChildren<IButtonContentUIProps>) => {
  return <div className={styles.ContentWrapper}>{children}</div>
}

export default ButtonContentUI
