import React, { useEffect, useState } from 'react'
import { Search } from '@magmamath/ui'
import styles from './BookSearch.module.scss'
import { fetchAllBookSeriesFx, fetchFoundBooksFx } from '../model/requests'
import { resetFoundBooks, setIsSearchActive } from '../model/foundBooks'
import { $allBooks } from '../model/allBooks'
import { useUnit } from 'effector-react'
import { getBookSeriesIds } from '../BooksModal/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useDebounce } from '../../../lib/hooks/useDebounce'
import useText from '../../../i18n/hook'
import { getFormattedValue } from './helpers'

const BookSearch = () => {
  const [searchValue, setSearchValue] = useState('')
  const search = useDebounce(searchValue, 500)
  const me = useSelector((state: RootState) => state.auth.data.me)
  const t = useText()
  const allBooks = useUnit($allBooks)
  const bookSeriesIds = getBookSeriesIds(me)

  useEffect(() => {
    if (searchValue && !allBooks) {
      fetchAllBookSeriesFx({ bookSeriesIds })
    }
  }, [searchValue])

  useEffect(() => {
    const formattedValue = getFormattedValue(search)
    if (!formattedValue) {
      return
    }
    fetchFoundBooksFx(formattedValue)
  }, [search])

  const handleSearch = (value: string) => {
    const formattedValue = getFormattedValue(value)
    if (!formattedValue) {
      resetFoundBooks()
    }
    setSearchValue(value)
    setIsSearchActive(formattedValue)
  }

  return (
    <Search
      classes={{ InputControl: styles.Input }}
      value={searchValue}
      placeholder={t.searchTxt}
      onChange={(e) => {
        handleSearch(e.target.value)
      }}
    />
  )
}

export default BookSearch
