import clsx from 'clsx'
import StudentsOptionsMenu from 'components/basic/Dropdowns/StudentOptionsMenu/StudentsOptionsMenu'
import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { PATHS } from 'config/pathnames.config'
import { FormTypes, TypesOfParams } from 'helpers/enums'
import { isAuth } from 'helpers/storage.helper'
import { IMatch } from 'interfaces/match.inteface'
import { IState } from 'interfaces/state.interface'
import { IMe } from 'api/types.users'
import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { IAuth, IAuthActions } from 'redux/modules/auth.module'
import { IModalActions } from '../../redux/modules/modal.module'
import './Profile.view.scss'
import { config } from '../../config'
import HelpLink from '../../ui/links/HelpLink/HelpLink'
import { TeacherSchools } from '../../redux/modules/teachersSchools.module'
import { ILocalization } from '../../config/languages.config'
import { BUTTON_VARIANT, Button, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import GoToIcon from 'ui/icons/GoToIcon/GoToIcon'
import { IAutologinActions } from 'redux/modules/autologin.module'

export interface IProfileProps {
  localization: ILocalization
  history: any
  match: IMatch
  modalActions: IModalActions
  autologinActions: IAutologinActions
  location: Location
  auth: IState<IAuth>
  me: IMe
  authActions: IAuthActions
  teacherSchools?: IState<TeacherSchools[]>
}

export default class Profile extends React.PureComponent<IProfileProps, any, any> {
  public componentWillMount() {
    if (!isAuth()) {
      window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
    }
  }

  public changingData = (): JSX.Element => {
    const { userNameTxt, firstNameTxt, lastNameTxt } = this.props.localization.data
    const dataCategories: string[] = [firstNameTxt, lastNameTxt, userNameTxt]

    return (
      <div className='profile-details left-column'>
        <div className='content-left d-flex flex-column'>
          {dataCategories.map((dataCategory: string, i: number) => (
            <div className='content-data-row' key={i}>
              <span>{dataCategory}</span>
            </div>
          ))}
        </div>
        <div className='content-right d-flex flex-column'>
          <div className='content-data-row'>
            <div className='content-data-wrapper'>
              <span>{this.props.auth.data?.me.firstName}</span>
            </div>
            <StudentsOptionsMenu
              me={this.props.me}
              type={FormTypes.FIRST_NAME}
              modalActions={this.props.modalActions}
              history={this.props.history}
              localization={this.props.localization}
            />
          </div>
          <div className='content-data-row'>
            <div className='content-data-wrapper'>
              <span>{this.props.auth.data?.me.lastName}</span>
            </div>
            <StudentsOptionsMenu
              me={this.props.me}
              type={FormTypes.LAST_NAME}
              modalActions={this.props.modalActions}
              history={this.props.history}
              localization={this.props.localization}
            />
          </div>
          <div className='content-data-row'>
            <div className='content-data-wrapper'>
              <span>{this.props.auth.data?.me.username}</span>
            </div>
            <StudentsOptionsMenu
              me={this.props.me}
              type={FormTypes.USERNAME}
              modalActions={this.props.modalActions}
              history={this.props.history}
              localization={this.props.localization}
            />
          </div>
        </div>
      </div>
    )
  }

  public additionalData = (): JSX.Element => {
    const { emailTxt, schoolTxt } = this.props.localization.data
    const dataCategories: string[] = [emailTxt, schoolTxt]
    return (
      <div className='profile-details right-column'>
        <div className='content-left d-flex flex-column'>
          {dataCategories.map((dataCategory: string, i: number) => (
            <div className='content-data-row' key={i}>
              <span>{dataCategory}</span>
            </div>
          ))}
        </div>
        <div className='content-right d-flex flex-column'>
          <div className='content-data-row'>
            <div className='content-data-wrapper'>
              <span>{this.props.auth.data?.me.email || ' '}</span>
            </div>
            <StudentsOptionsMenu
              me={this.props.me}
              type={FormTypes.EMAIL}
              modalActions={this.props.modalActions}
              history={this.props.history}
              localization={this.props.localization}
            />
          </div>
          <div className='content-data-row'>
            <div className='content-data-wrapper'>
              <span>{this.props.me.school.name}</span>
            </div>
            <StudentsOptionsMenu
              me={this.props.me}
              type={FormTypes.SCHOOL_ID}
              modalActions={this.props.modalActions}
              history={this.props.history}
              localization={this.props.localization}
              teacherSchools={this.props.teacherSchools}
            />
          </div>
        </div>
      </div>
    )
  }

  public renderCommonData = (): JSX.Element => {
    return (
      <div className='row content-profile-container'>
        <div className='col-xs-60 content-profile-container-column' />
        <div className='col content-profile'>
          {this.props.auth.data.me && this.changingData()}
          {this.props.auth.data.me && this.additionalData()}
        </div>
      </div>
    )
  }

  public render() {
    const isTeacherWithDistricAccess = this.props.me.managedMunicipality
    return (
      <div className='profile-wrapper'>
        <Route
          path={PATHS.ACCOUNT}
          render={() => (
            <Fragment>
              <PanelHeader
                title=' '
                backButton={false}
                className='profile-header'
                me={this.props.auth.data?.me}
                localization={this.props.localization as any}
                history={this.props.history}
                location={this.props.location}
              />
              <ViewContainer className='profile-container-view' history={this.props.history}>
                {this.renderCommonData()}
                <div
                  className={clsx('profile-button-wrapper', {
                    'multiple-actions-wrapper': isTeacherWithDistricAccess,
                  })}
                >
                  {isTeacherWithDistricAccess && (
                    <Button
                      variant={BUTTON_VARIANT.SECONDARY}
                      size={SIZE.LARGE}
                      icon={<GoToIcon color={COLORS.NEUTRAL_9} />}
                      iconAlign={ICON_ALIGNMENT.LEFT}
                      onClick={() => {
                        this.props.autologinActions.getAutologinTokenAndRedirect({
                          autologinUrl: config.DISTRICT_HOST,
                          userId: this.props.me._id,
                          autologinParamName: TypesOfParams.AUTOLOGIN_DISTRICT_TOKEN,
                        })
                      }}
                    >
                      {this.props.localization.data.goToDistrict}
                    </Button>
                  )}
                  <Button
                    onClick={() => this.props.authActions.logout(config.LOGIN_PAGE_INTERNATIONAL)}
                    size={SIZE.LARGE}
                  >
                    {this.props.localization.data.logoutTxt}
                  </Button>
                </div>
                <div className='profile-help-link-wrapper'>
                  <HelpLink localization={this.props.localization} />
                </div>
              </ViewContainer>
            </Fragment>
          )}
        />
      </div>
    )
  }
}
