import { useEffect, useState } from 'react'

const useObservable = <T>($observable: { watch: (fn: (props: T) => void) => () => void }) => {
  const [state, setState] = useState<T>()
  useEffect(() => {
    return $observable.watch((props) => {
      setState(props)
    })
  }, [$observable])
  return state
}

export default useObservable
