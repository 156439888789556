import React from 'react'

import './PrimaryNormalButton.scss'

interface IPrimaryNormalButtonProps {
  title: string
  onClick: () => void
}

const PrimaryNormalButton: React.FC<IPrimaryNormalButtonProps> = ({ title, onClick }) => {
  return (
    <button className='primary-normal-button' onClick={onClick}>
      {title}
    </button>
  )
}

export default PrimaryNormalButton
