export const extractSubTextPartsBetweenBrackets = (answerValue: string) => {
  let partAfterBrackets = ''
  const partBeforeBrackets = answerValue.substring(0, answerValue.indexOf('['))
  const supText = answerValue.substring(answerValue.indexOf('['), answerValue.indexOf('/'))
  const subText = answerValue.substring(answerValue.indexOf('/') + 1)

  if (subText.includes(']')) {
    partAfterBrackets = subText.includes(']]')
      ? subText.substring(subText.indexOf(']]') + 2)
      : subText.substring(subText.indexOf(']') + 1)
  }

  const clearSubText = partAfterBrackets
    ? subText.substring(0, subText.indexOf(partAfterBrackets[0]))
    : subText

  return { partBeforeBrackets, clearSubText, supText, subText, partAfterBrackets }
}

export const extractAtPart = (answerValue: string, matchAtSymbol: RegExpMatchArray) => {
  const dashSymbol = '\u2013'
  let answerWithAtSymbol = answerValue
  if (!matchAtSymbol.index) {
    return ''
  }
  const atPart = answerValue.slice(
    matchAtSymbol.index,
    matchAtSymbol.index + matchAtSymbol[0].length
  )
  answerWithAtSymbol = [
    answerWithAtSymbol.slice(0, matchAtSymbol.index),
    atPart.replace('@', ` ${dashSymbol} `),
    answerWithAtSymbol.slice(matchAtSymbol.index + matchAtSymbol[0].length),
  ].join('')

  return answerWithAtSymbol
}
