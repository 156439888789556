import { SkolFedErrorType, TypesOfParams } from 'helpers/enums'
import { generateLanguageFromStorage } from 'helpers/locale.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { loginSuccess } from './auth.module'

import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'

// Autologin constants
export enum AUTOLOGIN {
  AUTOLOGIN_REQUEST = 'AUTOLOGIN_REQUEST',
  AUTOLOGIN_SUCCESS = 'AUTOLOGIN_SUCCESS',
  AUTOLOGIN_ERROR = 'AUTOLOGIN_ERROR',
  AUTOLOGIN_CLEAR = 'AUTOLOGIN_CLEAR',
}

// Autologin reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function autologinReducer(state: any = initialState, action: IAction<AUTOLOGIN>) {
  switch (action.type) {
    case AUTOLOGIN.AUTOLOGIN_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTOLOGIN.AUTOLOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case AUTOLOGIN.AUTOLOGIN_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case AUTOLOGIN.AUTOLOGIN_CLEAR:
      return {
        data: {},
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export const autoLoginError = (authErrorCode: string, localization: ILocalization) => {
  return async (dispatch: IDispatch<any>) => {
    const { emptyUserName, existedAccount, notFoundAccount, invalidSchool, dublicatedEmail } =
      localization.data
    const errorMap: Record<SkolFedErrorType, string> = {
      [SkolFedErrorType.EMPTY_USERNAME]: emptyUserName,
      [SkolFedErrorType.EXISTED_ACCOUNT]: existedAccount,
      [SkolFedErrorType.NOT_FOUND_ACCOUNT]: notFoundAccount,
      [SkolFedErrorType.INVALID_SCHOOL]: invalidSchool,
      [SkolFedErrorType.DUBLICATED_EMAIL]: dublicatedEmail,
    }
    // bad solution for display error-notification when something went wrong when user tried to
    // auth through SkolFederation
    setTimeout(() => {
      dispatch({
        payload: { message: errorMap[authErrorCode] },
        type: AUTOLOGIN.AUTOLOGIN_ERROR,
      })
    }, 500)
  }
}

export const autoLogin = (userId: string, autologinToken: string, history: any) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: AUTOLOGIN.AUTOLOGIN_REQUEST })
      const res = await api.auth.autoLogin({ userId, autologinToken })
      dispatch({ type: AUTOLOGIN.AUTOLOGIN_SUCCESS, payload: res })
      loginSuccess(dispatch, res.token, history)
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: AUTOLOGIN.AUTOLOGIN_ERROR,
      })
    }
  }
}

export const clearAutoLogin = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: AUTOLOGIN.AUTOLOGIN_CLEAR })
  }
}

type AutologinTokenAndRedirect = {
  autologinUrl: string
  userId: string
  params?: Record<string, string>
  autologinParamName: string
}

export function getAutologinTokenAndRedirect({
  autologinUrl,
  userId,
  params,
  autologinParamName,
}: AutologinTokenAndRedirect) {
  const lang: string = generateLanguageFromStorage()
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: AUTOLOGIN.AUTOLOGIN_REQUEST })
      const res = await api.auth.getAutoLoginToken()
      dispatch({ type: AUTOLOGIN.AUTOLOGIN_SUCCESS, payload: res })
      const redirectUrlParams = new URLSearchParams(params)
      redirectUrlParams.set(autologinParamName, res.autologinToken)
      redirectUrlParams.set(TypesOfParams.USER_ID, userId)
      redirectUrlParams.set(TypesOfParams.LOCALE, lang)
      const redirectUrlWithParams: string = `${autologinUrl}?${redirectUrlParams.toString()}`
      window.open(redirectUrlWithParams, '_self')
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: AUTOLOGIN.AUTOLOGIN_ERROR,
      })
    }
  }
}

export interface IAutologinActions {
  getAutologinTokenAndRedirect: (params: AutologinTokenAndRedirect) => void
  autoLogin: (userId: string, autologinToken: string, history: any) => void
  autoLoginError: (authErrorCode: string, localization: ILocalization) => void
}

export interface IAutologin {
  data: {}
  error: null
  loading: false
}
