import React from 'react'
import './ButtonPrimary.scss'

interface IButtonPrimaryProps {
  title: string
  disabled: boolean
  onClickHandler: () => void
}

const ButtonPrimary: React.FC<IButtonPrimaryProps> = ({ title, disabled, onClickHandler }) => {
  return (
    <button className='button-primary' disabled={disabled} onClick={onClickHandler}>
      {title}
    </button>
  )
}

export default ButtonPrimary
