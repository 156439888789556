import React from 'react'
import { Button, BUTTON_VARIANT, SIZE, ICON_ALIGNMENT } from '@magmamath/ui'
import EditIcon from '../../../../ui/icons/EditIcon/EditIcon'

interface IFirstRowButton {
  title: string
  onClick: () => void
}

const FirstRowButton: React.FC<IFirstRowButton> = ({ title, onClick }) => {
  return (
    <>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.MEDIUM}
        onClick={onClick}
        icon={<EditIcon color='var(--icon-color)' size={20} />}
      >
        {title}
      </Button>
    </>
  )
}

export default FirstRowButton
