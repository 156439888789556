import clsx from 'clsx'
import React from 'react'
import Sidebar from 'ui/UISidebar/Sidebar'
import Divider from 'ui/dividers/Divider/Divider'
import Skeleton from 'ui/skeleton/Skeleton'
import SettingsOptionSkeleton from '../SettingsOption/SettingsOptionSkeleton'
import styles from './SettingsPanelSkeleton.module.scss'

const SettingsPanelSkeleton = () => (
  <Sidebar>
    <div className={styles.SkeletonContainer}>
      <div className={styles.NameSkeleton}>
        <Skeleton variant='rounded' width={130} height={19} />
        <Skeleton variant='rounded' width={'100%'} height={45} />
      </div>
      <div className={clsx(styles.NameSkeleton, styles.ClassesSkeleton)}>
        <Skeleton variant='rounded' width={70} height={19} />
        <Skeleton variant='rounded' width={'100%'} height={45} />
      </div>

      <div className={styles.NameSkeleton}>
        <Skeleton variant='rounded' width={100} height={19} />
        <div className={styles.ModeButtonsSkeleton}>
          <Skeleton variant='rounded' width={'48%'} height={52} />
          <Skeleton variant='rounded' width={'48%'} height={52} />
        </div>
      </div>
      <div className={styles.SettingsOptionsSkeleton}>
        <SettingsOptionSkeleton nameWidth={75}>
          <Skeleton variant='rounded' width={125} height={30} />
        </SettingsOptionSkeleton>
        <Divider />

        <SettingsOptionSkeleton nameWidth={90}>
          <Skeleton variant='rounded' width={36} height={18} />
        </SettingsOptionSkeleton>
        <SettingsOptionSkeleton nameWidth={60}>
          <Skeleton variant='rounded' width={36} height={18} />
        </SettingsOptionSkeleton>
        <SettingsOptionSkeleton nameWidth={75}>
          <Skeleton variant='rounded' width={36} height={18} />
        </SettingsOptionSkeleton>
        <SettingsOptionSkeleton nameWidth={75}>
          <Skeleton variant='rounded' width={125} height={30} />
        </SettingsOptionSkeleton>
      </div>
    </div>
  </Sidebar>
)

export default SettingsPanelSkeleton
