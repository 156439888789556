import React from 'react'
import styles from './NumericAnswer.module.scss'
import { replaceSpecialSymbolsToHTMLElements } from '../../../../ui/inputs/ContentEditableArea/ContentEditable.helpers'
import ContentEditableArea from '../../../../ui/inputs/ContentEditableArea/ContentEditableArea'
import katex from 'katex'
import clsx from 'clsx'
import {
  LOCALIZED_UNITS_SYMBOLS,
  UnitsTypes,
} from '../../../../ui/selects/SelectUnit/Symbols/Symbols'
import { currentLocale } from '../../../../config/modules.config'
import CurrencyNumericAnswer from './CurrencyNumericAnswer/CurrencyNumericAnswer'
import OtherNumericAnswer from './OtherNumericAnswer/OtherNumericAnswer'

interface NumericAnswerProps {
  value: string
  selectedValue: string | null
}

const DOLLAR_SYMBOL = '$'

const NumericAnswer: React.FC<NumericAnswerProps> = ({ value, selectedValue }) => {
  return (
    <div className={styles.Container}>
      {selectedValue === DOLLAR_SYMBOL ? (
        <CurrencyNumericAnswer value={value} selectedValue={selectedValue} />
      ) : (
        <OtherNumericAnswer value={value} selectedValue={selectedValue} />
      )}
    </div>
  )
}

export default NumericAnswer
