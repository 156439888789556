import { createStore, restore, combine, createEvent, attach, createEffect } from 'effector'

import { fetchSkillTreeFx, fetchGradeSkillTreeFx } from './requests'
import { PracticeGradeTrees, PracticeSkillTree } from './types'
import { ISkillTreeNode } from 'api/types.skills'

type loadCurrentGradeTreeEffectProps = { tree: ISkillTreeNode; treeId: string }
export class practiceTreeModel {
  public static resetGradeTrees = createEvent()

  public static $skillTree = restore<PracticeSkillTree>(fetchSkillTreeFx.doneData, [])

  public static $gradeTrees = createStore<PracticeGradeTrees>({})
    .on(fetchGradeSkillTreeFx.doneData, (state, gradeTree) => ({
      ...state,
      [gradeTree._id]: gradeTree,
    }))
    .reset(this.resetGradeTrees)

  public static loadCurrentGradeTreeFx = attach({
    source: this.$gradeTrees,
    mapParams: (treeId: string, gradeTrees: PracticeGradeTrees) => ({
      tree: gradeTrees[treeId] ?? null,
      treeId,
    }),
    effect: createEffect(({ tree, treeId }: loadCurrentGradeTreeEffectProps) => {
      if (tree) return tree
      return fetchGradeSkillTreeFx(treeId)
    }),
  })

  public static $currentGradeTree = restore(this.loadCurrentGradeTreeFx.doneData, null)

  public static isFetching = combine(
    {
      isFetchingTree: fetchSkillTreeFx.pending,
      isFetchingGradeTree: fetchGradeSkillTreeFx.pending,
    },
    ({ isFetchingTree, isFetchingGradeTree }) => isFetchingTree || isFetchingGradeTree
  )
}
