import React from 'react'
import { SvgIconProps } from '../types/types'

const MinusIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height='2' viewBox='0 0 16 2' fill='none'>
      <path
        d='M0.899902 1.00002C0.899902 0.530582 1.28046 0.150024 1.7499 0.150024L14.2499 0.150024C14.7193 0.150024 15.0999 0.530583 15.0999 1.00002C15.0999 1.46947 14.7193 1.85002 14.2499 1.85002L1.7499 1.85002C1.28046 1.85002 0.899902 1.46947 0.899902 1.00002Z'
        fill={color}
      />
    </svg>
  )
}

export default MinusIcon
