import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowLeftDatePickerIcon: React.FC<SvgIconProps> = ({ color }) => {
  return (
    <svg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5 1.5L2.20993 8.48632C1.91766 8.76641 1.91766 9.23359 2.20993 9.51368L9.5 16.5'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ArrowLeftDatePickerIcon
