import React, { useState } from 'react'
import { colors, Divider } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import { DropdownPopupIds, RemovableTypes } from 'helpers/enums'
import { useDispatch, useSelector } from 'react-redux'
import { openTeachAutosuggest } from 'redux/modules/modal.module'
import { RootState } from 'store/store'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { MuiMenuProps } from '../../../../features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import ConfirmationModal from 'ui/modals/ConfirmationModal/ConfirmationModal'
import useText from 'i18n/hook'
import { getTeachersBySchool } from 'redux/modules/schoolTeachers.module'
import { ILocalization } from '../../../../config/languages.config'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import styles from './ClassCardDropdown.module.scss'
import { IClass, SourceType } from '../../../../api/types.classes'

type ClassCardDropdownProps = {
  changeFollowState: (state: boolean) => Promise<void>
  classDetails: IClass
}
const MODAL_STATE_DESCRIPTOR = {
  UNFOLLOW: {
    title: (t: ILocalization['data']) => t.unfollowClass,
    description: (t: ILocalization['data']) => t.unfollowClassDescription,
    btnTxt: (t: ILocalization['data']) => t.unfollowClass,
  },
  ARCHIVE: {
    title: (t: ILocalization['data']) => t.areYouSure,
    description: (t: ILocalization['data']) => t.archiveClassConfirmation,
    btnTxt: (t: ILocalization['data']) => t.archiveClass,
  },
}

const ClassCardDropdown = ({ changeFollowState, classDetails }: ClassCardDropdownProps) => {
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false)
  const teachers = useSelector((state: RootState) => state.teachers.data)
  const dispatch = useDispatch()
  const me = useSelector((state: RootState) => state.auth.data.me)
  const t = useText()
  const isTeacherOwner = classDetails.owner?._id === me?._id || classDetails.owner === me?._id

  const isUnfollowModal =
    classDetails.source === SourceType.mathSync ||
    classDetails.source === SourceType.ss12000 ||
    (classDetails.teachers.length === 1 && classDetails.teachers[0] !== me?._id) ||
    classDetails.teachers.length > 1 ||
    !isTeacherOwner

  const modalLabels = isUnfollowModal
    ? MODAL_STATE_DESCRIPTOR.UNFOLLOW
    : MODAL_STATE_DESCRIPTOR.ARCHIVE

  const openTeachersModal = async () => {
    const schoolTeachers = teachers?.length
      ? teachers
      : await getTeachersBySchool(me.school._id)(dispatch)
    //TODO: we dont get value of teacher from prop, we get teachers from groups, so we dont need this request
    //and we dont need to pass data
    //moreover, we take schoolTeachers.data, but there is no data in schoolTeachers
    //@ts-ignore
    openTeachAutosuggest(schoolTeachers.data, RemovableTypes.Class, classDetails._id)(dispatch)
  }

  return (
    <>
      <PopupState variant='popover' popupId={DropdownPopupIds.CLASS_CARD}>
        {(popupState) => (
          <>
            <Button
              size={SIZE.SMALL}
              variant={BUTTON_VARIANT.TERTIARY}
              icon={<OptionIcon size={18} color={COLORS.NEUTRAL_7} />}
              {...bindTrigger(popupState)}
            />
            <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close()
                  openTeachersModal()
                }}
              >
                {t.shareWithTeachers}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setIsConfirmationModalOpened(true)
                  popupState.close()
                }}
              >
                {t.unfollowTxt}
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
      <ConfirmationModal
        title={modalLabels.title(t)}
        description={modalLabels.description(t)}
        btnText={modalLabels.btnTxt(t)}
        isOpen={isConfirmationModalOpened}
        onClick={() => {
          changeFollowState(false)
        }}
        onClose={() => {
          setIsConfirmationModalOpened(false)
        }}
      />
    </>
  )
}

export default ClassCardDropdown
