import React from 'react'
import { COLORS } from '@magmamath/ui'
import { SvgIconProps } from '../types/types'

const GoToIcon = ({ size = 20, color = COLORS.NEUTRAL_9 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.23392 15.7722C2.92203 15.4419 2.92203 14.9064 3.23392 14.5761L6.99231 10.596C7.3042 10.2657 7.80989 10.2657 8.12178 10.596C8.43368 10.9263 8.43368 11.4618 8.12178 11.7921L5.72679 14.3283H16.2013C16.6424 14.3283 17 14.707 17 15.1741C17 15.6412 16.6424 16.0199 16.2013 16.0199H5.72679L8.12178 18.5562C8.43368 18.8865 8.43368 19.422 8.12178 19.7523C7.80989 20.0826 7.3042 20.0826 6.99231 19.7523L3.23392 15.7722Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7661 5.42393C17.078 5.09364 17.078 4.55813 16.7661 4.22783L13.0077 0.247721C12.6958 -0.0825737 12.1901 -0.0825737 11.8782 0.247721C11.5663 0.578016 11.5663 1.11353 11.8782 1.44383L14.2732 3.98011H3.79866C3.35758 3.98011 3.00001 4.35878 3.00001 4.82588C3.00001 5.29299 3.35758 5.67166 3.79866 5.67166H14.2732L11.8782 8.20794C11.5663 8.53823 11.5663 9.07375 11.8782 9.40404C12.1901 9.73434 12.6958 9.73434 13.0077 9.40404L16.7661 5.42393Z'
        fill={color}
      />
    </svg>
  )
}

export default GoToIcon
