import 'katex/dist/katex.min.css'
import React from 'react'
// tslint:disable-next-line:no-var-requires
const { BlockMath, InlineMath } = require('react-katex')
import ReactMarkdown from 'react-markdown'
import RemarkMathPlugin from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkMath from 'remark-math'

const mapProps = (props: any) => ({
  ...props,
  escapeHtml: false,
  components: {
    ...props.renderers,
    math: ({ value }: { value: string }) => <BlockMath>{value}</BlockMath>,
    // tslint:disable-next-line:object-literal-sort-keys
    inlineMath: ({ value }: { value: string }) => <InlineMath>{value}</InlineMath>,
  },
  children: props.children,
  remarkPlugins: [remarkMath, RemarkMathPlugin],
  rehypePlugins: [rehypeKatex, rehypeRaw],
})

const Markdown = (props: any) => <ReactMarkdown {...mapProps(props)} />

export default Markdown
