import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const TickInCircleIcon = ({ size = 16, color = COLORS.NEUTRAL_1 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 11105'>
        <g id='Group'>
          <path
            id='Vector'
            d='M9.61328 17C14.0316 17 17.6133 13.4183 17.6133 9C17.6133 4.58172 14.0316 1 9.61328 1C5.19501 1 1.61328 4.58172 1.61328 9C1.61328 13.4183 5.19501 17 9.61328 17Z'
            stroke={color}
            strokeWidth='1.5'
          />
        </g>
        <g id='g443'>
          <path
            id='path445'
            d='M13.1846 6.79527L8.35684 11.623L6.05859 9.32484'
            stroke={color}
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  )
}

export default TickInCircleIcon
