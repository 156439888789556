import React from 'react'
import defaultLogo from '../../assets/img/logo/magma_logo.svg'

import { PATHS } from '../../config/pathnames.config'
import { Link } from 'react-router-dom'
import styles from './Logo.module.scss'
import clsx from 'clsx'

type LogoProps = {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return (
    <div className={clsx(styles.Logo, className)}>
      <Link to={PATHS.EXERCISES.EXERCISES_MAIN} className={styles.Link}>
        <img className={styles.Image} src={defaultLogo} alt='magma logo' />
      </Link>
    </div>
  )
}

export default Logo
