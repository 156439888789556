import { MutationStatus } from './types'

import { ExerciseState } from '../../../../api/types.assignments'

export const checkLoadingState = (
  status: ExerciseState,
  userId: string,
  pendingMutationStatus: MutationStatus
) => {
  if (pendingMutationStatus?.mutationType === status && pendingMutationStatus?.userId === userId) {
    return true
  }
  return false
}
