import { Divider, IconButton } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { DropdownPopupIds, RemovableTypes } from 'helpers/enums'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModal, openSimpleConfirmModal } from 'redux/modules/modal.module'
import { RootState } from 'store/store'

import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { MuiMenuProps } from '../../../../features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import styles from './GroupCardDropdown.module.scss'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { ClassroomGroup } from '../../../../api/types.classes'

type GroupCardDropdownProps = {
  groupDetails: ClassroomGroup
}

const GroupCardDropdown = ({ groupDetails }: GroupCardDropdownProps) => {
  const t = useText()
  const dispatch = useDispatch()
  const me = useSelector((state: RootState) => state.auth.data.me)

  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.GROUP_CARD}>
      {(popupState) => (
        <>
          <Button
            size={SIZE.SMALL}
            variant={BUTTON_VARIANT.TERTIARY}
            icon={<OptionIcon size={18} color='var(--icon-color)' />}
            {...bindTrigger(popupState)}
          />
          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close()
                dispatch(openModal(t.editGroupNameTxt, t.groupTxt, me, groupDetails._id))
              }}
            >
              {t.changeGroupNameTxt}
            </MenuItem>
            <Divider className={styles.Divider} />
            <MenuItem
              onClick={() => {
                popupState.close()
                dispatch(openSimpleConfirmModal(groupDetails, RemovableTypes.Group))
              }}
            >
              <span data-skip-redirect className={styles.ColorRed}>
                {t.removeGroupTxt}
              </span>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default GroupCardDropdown
