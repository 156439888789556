import React, { ReactElement, ReactNode } from 'react'
import styles from './DateTimePicker.module.scss'

type DateTimePickerProps = {
  label: string
  Date: ReactElement
  Time: ReactElement
}
const DateTimePicker = ({ label, Date, Time }: DateTimePickerProps) => {
  return (
    <div className={styles.DateTimeContainer}>
      <span className={styles.ScheduledType}>{label}</span>
      <div className={styles.DatePickerContainer}>{Date}</div>
      {Time}
    </div>
  )
}

export default DateTimePicker
