import React, { useMemo } from 'react'
import useText from '../../../../i18n/hook'
import isEmpty from 'lodash/isEmpty'
import { OptionsType } from '../StudentsFilter'
import { Listbox } from '@magmamath/ui'
import SubHeader from '../../../../ui/filters/SubHeader/SubHeader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { TypesOfParams } from '../../../../helpers/enums'

import { ITopic } from '../../../../api/api.topics'

const TopicsOption = () => {
  const t = useText()
  const topics = useSelector((state: RootState) => state.topics.data?.topics)

  const topicOptions: OptionsType[] = useMemo(() => {
    return topics.map((topic: ITopic) => ({
      name: topic.name,
      value: topic._id,
      type: TypesOfParams.TOPICS,
    }))
  }, [topics])
  return (
    <>
      {!isEmpty(topics) && <SubHeader title={t.topicsTxt} />}
      {topicOptions.map((topic) => (
        <Listbox.Option key={topic.value} value={topic}>
          {topic.name}
        </Listbox.Option>
      ))}
    </>
  )
}

export default TopicsOption
