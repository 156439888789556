import clsx from 'clsx'
import ConfirmationModal from 'features/modals/ConfirmationModal/ConfirmationModal'
import { TABLE_HEADER_HEIGHT } from 'features/tables/HeatmapTable/constants'
import { checkIsUserRemovedFromAssignment } from 'helpers/user.helpers'
import useText from 'i18n/hook'
import { IStudent, ITeacher } from 'api/types.users'
import React, { PropsWithChildren, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateStudentExamStatus } from 'redux/modules/studentsExamStats'
import styles from '../../../../../features/tables/HeatmapTable/ui/HeatmapTable/Table.module.scss'
import { updatePendingMutation } from '../../model/pendingMutationStatus'
import ExamStatusButtonsSet from '../ExamStatusButtonsSet/ExamStatusButtonsSet'
import { IStudentsExamStats } from '../../../../../api/types.statistics'
import { ExerciseState } from '../../../../../api/types.assignments'

type ChangeStudentExamStatusParams = {
  exerciseId: string
  newExerciseState: ExerciseState
  studentId: string
}

type StudentsSettingsColumnProps = PropsWithChildren<{
  exerciseId: string
  isExamMode: boolean
  isCompressedMode: boolean
  exerciseState?: ExerciseState
  rowsUsers: (IStudent | ITeacher)[]
  studentsExamStatsMap?: Map<string, IStudentsExamStats>
}>

const StudentsSettingsColumn = ({
  exerciseId,
  rowsUsers,
  isCompressedMode,
  isExamMode,
  exerciseState,
  studentsExamStatsMap,
  children,
}: StudentsSettingsColumnProps) => {
  const dispatch = useDispatch()
  const t = useText()

  const [endExamModalData, setEndExamModalData] = useState<null | ChangeStudentExamStatusParams>(
    null
  )

  const changeStudentExamStatus = ({
    exerciseId,
    newExerciseState,
    studentId,
  }: ChangeStudentExamStatusParams) => {
    updatePendingMutation({ mutationType: newExerciseState, userId: studentId })
    return updateStudentExamStatus({
      exerciseId,
      status: newExerciseState,
      studentIds: [studentId],
    })(dispatch)
  }

  if (!isExamMode || !studentsExamStatsMap || isCompressedMode) return <>{children}</>

  return (
    <>
      <div className={styles.SingleColumn}>
        <div
          className={clsx(styles.DefaultText, styles.StickyTop, styles.TableTitle, styles.Status)}
          style={{ height: TABLE_HEADER_HEIGHT }}
        >
          <span>{t.statusTxt}</span>
        </div>
        {rowsUsers.map((user, index) => {
          const studentExamStats = studentsExamStatsMap.get(user._id)
          const isTeacher = index === rowsUsers.length - 1
          if (isTeacher) return null

          const isUserDeleted = checkIsUserRemovedFromAssignment({
            student: user,
            isCurrentTeacher: false,
          })

          return (
            <ExamStatusButtonsSet
              key={user._id}
              exerciseId={exerciseId}
              exerciseState={exerciseState}
              onEndButtonClick={setEndExamModalData}
              studentExamStats={studentExamStats}
              userId={user._id}
              isUserDeleted={isUserDeleted}
            />
          )
        })}

        <ConfirmationModal
          contentText={t.endExamWarning?.(true)}
          confirmText={t.endExam}
          isModalOpen={!!endExamModalData}
          onModalClose={() => setEndExamModalData(null)}
          onConfirm={() => {
            if (!endExamModalData) return Promise.resolve()
            return changeStudentExamStatus(endExamModalData)
          }}
        />
      </div>
      <div className={styles.SingleColumn}>{children}</div>
    </>
  )
}

export default StudentsSettingsColumn
