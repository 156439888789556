import { ClassesModal } from 'components/Modals/ClassesModal/ClassesModal.component'
import { AllPasswordsModal } from 'components/Modals/ConfirmModals/allPasswordsModal.component'
import { SimpleConfirmModal } from 'components/Modals/ConfirmModals/areYouSureQuestionModal'
import { PopupGroupStudentsAutosuggest } from 'components/Modals/ModalAutosuggestWindow/studentsForGroups/StudentsAutosuggestWindow.component'
import { PopupTeachersAutosuggest } from 'components/Modals/ModalAutosuggestWindow/teachers/TeachersAutosuggestWindow.component'
import RegisterStudentForm from 'components/Modals/ModalRegisterWindow/ModalRegisterWindow.component'
import { localStorageKeys } from 'config/localStorageKeys'
import { ModalType } from 'helpers/enums'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import _ from 'lodash'
import * as React from 'react'
import CreateOrEditExerciseModal from '../../components/Modals/CreateOrEditExerciseModal/CreateOrEditExerciseModal'
import LanguageSettingsModal from '../../components/Modals/LanguageSettingsModal /LanguageSettingsModal'
import ModalChooseStudentsUpdated from '../../components/Modals/ModalAutosuggestWindow/ModalChooseStudentsUpdated/ModalChooseStudentsUpdated'
import ModalCreateClass from '../../components/Modals/ModalCreateClass/ModalCreateClass'
import PopupContainer from '../../components/Modals/ModalSimpleWindow/ModalSimpleWindow.component'
import ShareLinkModal from '../../components/Modals/ShareLinkModal/ShareLinkModal'
import TranslationLanguageSelectorModal from '../../components/Modals/TranslationLanguageSelectorModal/TranslationLanguageSelectorModal'
import ChangePasswordPopup from '../../features/modals/ChangePasswordPopupComponent/ChangePasswordPopupComponent'
import SimpleAsyncModal from '../../features/modals/SimpleAsyncModal/SimpleAsyncModal'
import OldPracticeResultsModal from '../../components/Modals/OldPracticeResultsModal/OldPracticeResultsModal'
import PracticeResultsModal from '../../components/Modals/PracticeResultsModal/PracticeResultsModal'
import ArchiveClassModal from '../../features/modals/ArchiveClassModal/ArchiveClassModal'
import { ExerciseOverlay } from '../../features/modals/ProblemsOfExerciseOverlay/ProblemsOfExerciseOverlay.component'

//TODO: we need to go through and check if all modals are used, if not then remove and check redux stores they touch
//and remove them as well
class ModalsComponent extends React.Component<any, any> {
  public render() {
    const { data } = this.props.modal
    const schoolClassesAreReady: boolean =
      this.props.schoolClasses &&
      !this.props.schoolClasses.loading &&
      !_.isEmpty(this.props.schoolClasses.data)

    const mapOfModalRenders: { [key in ModalType]: () => JSX.Element } = {
      [ModalType.SimpleModal]: () => <PopupContainer {...this.props} />,
      [ModalType.RegisterStudentModal]: this.renderRegisterStudentFormModal,
      [ModalType.SimpleAsyncModal]: this.renderSimpleAsyncModalFormModal,
      [ModalType.SimpleConfirmModal]: this.renderSimpleConfirmModal,
      [ModalType.AllPasswordsModal]: this.renderAllPasswordsModal,
      [ModalType.ClassesModal]: this.renderClassesModal,
      [ModalType.ChangePasswordModal]: this.renderChangePasswordPopup,
      [ModalType.GroupStudentsAutosuggestModal]: this.renderPopupGroupStudentsAutosuggest,
      [ModalType.TeachersAutosuggestModal]: this.renderPopupTeachersAutosuggest,
      [ModalType.ExerciseOverlay]: this.renderExerciseOverlay,
      [ModalType.CreateOrEditExercise]: this.renderCreateOrEditExerciseModal,
      [ModalType.ShareLinkModal]: this.renderShareLinkModal,
      [ModalType.LanguageSettingsModal]: this.renderLanguageSettingsModal,
      [ModalType.TranslationLanguageSelectorModal]: this.renderTranslationLanguageSelectorModal,
      [ModalType.CreateClassModal]: this.renderCreateClassModal,
      [ModalType.ChooseStudentsModalUpdated]: this.renderChooseStudentsModalUpdated,
      [ModalType.OldPracticeResultsModal]: this.renderOldPracticeResultsModal,
      [ModalType.PracticeResultsModal]: this.renderPracticeResultsModal,
      [ModalType.ArchiveClassModal]: this.renderArchiveClassModal,
      [ModalType.Empty]: () => <React.Fragment>{null}</React.Fragment>,
    }
    return (
      <React.Fragment>
        {data.modalType !== ModalType.ClassesModal || schoolClassesAreReady
          ? mapOfModalRenders[data.modalType]()
          : mapOfModalRenders[ModalType.Empty]()}
      </React.Fragment>
    )
  }

  public renderPopupTeachersAutosuggest = (): JSX.Element => {
    return (
      <PopupTeachersAutosuggest
        teachersActions={this.props.teachersActions}
        id={this.props.modal.data.id}
        history={this.props.history}
        modal={this.props.modal}
        modalActions={this.props.modalActions}
        groupActions={this.props.groupActions}
        classActions={this.props.classActions}
        localization={this.props.localization}
        teachers={this.props.modal.data.teachers || this.props.teachers.data}
        me={getItemFromLocalStorageWithParsing(localStorageKeys.me)}
        addedClass={this.props.addedClass}
        group={this.props.group}
        exercise={this.props.exercise}
        exerciseActions={this.props.exerciseActions}
      />
    )
  }

  public renderExerciseOverlay = (): JSX.Element => {
    return <ExerciseOverlay id={this.props.modal.data.id} />
  }

  public renderPopupGroupStudentsAutosuggest = (): JSX.Element => {
    return (
      <PopupGroupStudentsAutosuggest
        modal={this.props.modal}
        collectionId={this.props.collectionId.data}
        collectionIdActions={this.props.collectionIdActions}
        groupId={this.props.modal.data.groupId}
        history={this.props.history}
        groupActions={this.props.groupActions}
        modalActions={this.props.modalActions}
        localization={this.props.localization}
        classesStudents={this.props.classesStudents}
        classes={this.props.classes}
        groups={this.props.groups}
      />
    )
  }

  public renderChangePasswordPopup = (): JSX.Element => {
    return (
      <ChangePasswordPopup
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        notification={this.props.notify}
        localization={this.props.localization}
        user={this.props.user}
        serverErrors={this.props.account.error}
        history={this.props.history}
        account={this.props.account}
      />
    )
  }

  public renderRegisterStudentFormModal = (): JSX.Element => {
    return (
      <RegisterStudentForm
        localization={this.props.localization}
        onSubmit={this.props.userActions.signUpStudent}
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        addedClass={this.props.addedClass}
      />
    )
  }

  public renderSimpleAsyncModalFormModal = (): JSX.Element => {
    return (
      <SimpleAsyncModal
        auth={this.props.auth}
        me={getItemFromLocalStorageWithParsing(localStorageKeys.me)}
        authActions={this.props.authActions}
        accountActions={this.props.accountActions}
        localization={this.props.localization}
        history={this.props.history}
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        onSubmit={this.props.accountActions.editAccount}
      />
    )
  }

  public renderSimpleConfirmModal = (): JSX.Element => {
    return (
      <SimpleConfirmModal
        modal={this.props.modal}
        modalActions={this.props.modalActions}
        classId={
          !_.isEmpty(this.props.addedClass.data) ? this.props.addedClass.data._id : undefined
        }
        userActions={this.props.userActions}
        classActions={this.props.classActions}
        groupActions={this.props.groupActions}
        exerciseActions={this.props.exerciseActions}
        localization={this.props.localization}
        history={this.props.history}
        removedItem={this.props.modal.data.removedItem}
        typeOfItem={this.props.modal.data.typeOfItem}
      />
    )
  }

  public renderAllPasswordsModal = (): JSX.Element => {
    return (
      <AllPasswordsModal
        onSubmit={this.props.classActions.resetPasswordsForClass}
        initialValues={{
          password: this.props.addedClass.data.invitationalCode,
          passwordConfirmation: this.props.addedClass.data.invitationalCode,
        }}
        modal={this.props.modal}
        modalActions={this.props.modalActions}
        localization={this.props.localization}
        history={this.props.history}
        addedClass={this.props.addedClass}
        classActions={this.props.classActions}
      />
    )
  }

  public renderClassesModal = (): JSX.Element => {
    return (
      <ClassesModal
        me={getItemFromLocalStorageWithParsing(localStorageKeys.me)}
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        localization={this.props.localization}
        addedClass={this.props.addedClass}
        history={this.props.history}
        schoolClasses={this.props.schoolClasses}
        classesActions={this.props.classesActions}
      />
    )
  }

  public renderCreateOrEditExerciseModal = (): JSX.Element => {
    return (
      <CreateOrEditExerciseModal
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        localization={this.props.localization}
        history={this.props.history}
        notification={this.props.notification}
        location={this.props.location}
      />
    )
  }
  public renderShareLinkModal = (): JSX.Element => {
    return (
      <ShareLinkModal
        modal={this.props.modal}
        localization={this.props.localization}
        exercise={this.props.exercise}
      />
    )
  }

  public renderLanguageSettingsModal = (): JSX.Element => {
    return (
      <LanguageSettingsModal
        modal={this.props.modal}
        localization={this.props.localization}
        modalActions={this.props.modalActions}
        languageSettings={this.props.languageSettings}
        languageSettingsActions={this.props.languageSettingsActions}
      />
    )
  }

  public renderTranslationLanguageSelectorModal = (): JSX.Element => {
    return (
      <TranslationLanguageSelectorModal
        modal={this.props.modal}
        localization={this.props.localization}
        modalActions={this.props.modalActions}
        languageSettings={this.props.languageSettings}
        languageSettingsActions={this.props.languageSettingsActions}
      />
    )
  }
  public renderCreateClassModal = (): JSX.Element => {
    return (
      <ModalCreateClass
        classActions={this.props.classActions}
        modalActions={this.props.modalActions}
        modal={this.props.modal}
        localization={this.props.localization}
        addedClass={this.props.addedClass}
        me={this.props.auth.data.me}
      />
    )
  }
  public renderChooseStudentsModalUpdated = (): JSX.Element => {
    return (
      <ModalChooseStudentsUpdated
        modal={this.props.modal}
        collectionId={this.props.collectionId.data}
        collectionIdActions={this.props.collectionIdActions}
        groupId={this.props.modal.data.groupId}
        history={this.props.history}
        groupActions={this.props.groupActions}
        modalActions={this.props.modalActions}
        localization={this.props.localization}
        classesStudents={this.props.classesStudents}
        classes={this.props.classes}
        userActions={this.props.userActions}
      />
    )
  }

  public renderOldPracticeResultsModal = () => {
    return (
      <OldPracticeResultsModal modal={this.props.modal} modalActions={this.props.modalActions} />
    )
  }

  public renderPracticeResultsModal = () => {
    return <PracticeResultsModal modal={this.props.modal} modalActions={this.props.modalActions} />
  }

  public renderArchiveClassModal = () => {
    return <ArchiveClassModal />
  }
}

export default ModalsComponent
