import React from 'react'
import { SvgIconProps } from '../types/types'

const ExclamationMarkIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='exclamation-mark 2' clipPath='url(#clip0_3979_48100)'>
        <g id='Group'>
          <path
            id='Vector'
            d='M9 0C4.02973 0 0 4.02973 0 9C0 13.9708 4.02973 18 9 18C13.9708 18 18 13.9714 18 9C18 4.02973 13.9708 0 9 0ZM9 16.3125C4.96127 16.3125 1.68753 13.0387 1.68753 9C1.68753 4.96127 4.96127 1.68753 9 1.68753C13.0387 1.68753 16.3125 4.96127 16.3125 9C16.3125 13.0387 13.0387 16.3125 9 16.3125ZM9.00057 3.94705C8.35596 3.94705 7.86823 4.28344 7.86823 4.8268V9.81054C7.86823 10.3545 8.3559 10.6897 9.00057 10.6897C9.62944 10.6897 10.1329 10.3404 10.1329 9.81054V4.8268C10.1328 4.29634 9.62944 3.94705 9.00057 3.94705ZM9.00057 11.8125C8.38126 11.8125 7.87723 12.3165 7.87723 12.9363C7.87723 13.5551 8.38126 14.0591 9.00057 14.0591C9.61988 14.0591 10.1233 13.5551 10.1233 12.9363C10.1233 12.3165 9.61988 11.8125 9.00057 11.8125Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3979_48100'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExclamationMarkIcon
