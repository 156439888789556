import React from 'react'
import { SvgIconProps } from '../types/types'

const SmallArrowRightIcon = ({ color = '#666679', size = 12, className }: SvgIconProps) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
    >
      <g clipPath='url(#clip0_1318_4649)'>
        <path
          d='M2.75 1.4165L7.20504 5.68592C7.38365 5.85709 7.38365 6.14259 7.20504 6.31375L2.75 10.5832'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1318_4649'>
          <rect width='11.0278' height='11' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SmallArrowRightIcon
