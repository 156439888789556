import React from 'react'
import styles from './PrintProblemsCardFooter.module.scss'
import { AnswerType } from '../../../../helpers/enums'
import useText from '../../../../i18n/hook'
import AnswerOption from './AnswerOption/AnswerOption'
import Divider from '../../../../ui/dividers/Divider/Divider'
import { ANSWER_TYPE_LOCALIZATION_MAP } from '../printProblemsCard.helper'

type PrintProblemsCardFooterProps = {
  answerType: AnswerType
  answerVariants?: string[]
}
const PrintProblemsCardFooter = ({ answerType, answerVariants }: PrintProblemsCardFooterProps) => {
  const t = useText()
  const title = ANSWER_TYPE_LOCALIZATION_MAP[answerType] || ((t) => t.handWritingAnswer)
  const hasAnswerVariants = answerVariants && answerVariants.length > 0

  return (
    <div className={styles.Wrapper}>
      <Divider className={styles.BottomDivider} />
      <div className={styles.Container}>
        <div className={styles.Title}>{`${title(t)}:`}</div>
        <div className={styles.AnswerWrapper}>
          {hasAnswerVariants ? (
            answerVariants.map((answer, i) => <AnswerOption key={i} answer={answer} />)
          ) : (
            <div className={styles.Answer} />
          )}
        </div>
      </div>
    </div>
  )
}
export default PrintProblemsCardFooter
