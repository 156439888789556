import React, { LegacyRef } from 'react'

import { omit, truncate } from 'lodash'

interface IBooksTitleProps {
  getBookName: (bookId: string) => string
  groupByBook: any
  limitLettersForBooks: number
}

const BooksTitle: React.FC<IBooksTitleProps> = React.forwardRef((props, ref) => {
  const tooltipProps = omit(props, ['getBookName', 'groupByBook', 'limitLettersForBooks'])
  return (
    <div
      {...tooltipProps}
      ref={ref as LegacyRef<HTMLDivElement> | undefined}
      className='book-title'
    >
      {truncate(props.getBookName(Object.keys(props.groupByBook).toString()), {
        length: props.limitLettersForBooks,
      })}
    </div>
  )
})

export default BooksTitle
