import { USTimeType } from './enums'

export const HALF_DAY = 12

export const convertHourTo24HFormat = (hour: number, type: USTimeType) => {
  if (hour === HALF_DAY) {
    return type === USTimeType.AM ? 0 : HALF_DAY
  }

  if (type === USTimeType.AM) {
    return hour
  }

  return hour + HALF_DAY
}

export const convertHourTo12HFormat = (hour: number) => {
  if (hour === 0) {
    return { hour: HALF_DAY, type: USTimeType.AM }
  }

  if (hour === HALF_DAY) {
    return { hour, type: USTimeType.PM }
  }

  if (hour < HALF_DAY) {
    return { hour, type: USTimeType.AM }
  }

  return { hour: hour - HALF_DAY, type: USTimeType.PM }
}
