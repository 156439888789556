import { createEvent, createStore } from 'effector'
import { ICoords } from '../../common/types'
import { MathInputModel, MathInputProps } from '../MathInputModel'
import { isEndsWithInterruptingSymbol } from '../../common/helpers.katex'

export class InlineMathInputModel extends MathInputModel {
  public readonly updatePosition = createEvent<ICoords>()
  public readonly $inputPosition = createStore<ICoords | null>(null)
    .on(this.updatePosition, (state, payload) => {
      if (!state || (state.x === payload.x && state.y === payload.y)) return state
      return this.getElementPosition(payload)
    })
    .on(this.showInput, (state, payload) => {
      if (!payload?.anchorElement) return null
      const { x, y } = payload.elementCoords || payload.anchorElement.getBoundingClientRect()
      return this.getElementPosition({ x, y })
    })
    .on(this.hideInput, () => null)

  protected parentElement: HTMLElement | null = null
  private currentPositionBinder: MutationObserver | null = null
  public constructor(props?: MathInputProps) {
    super({
      ...props,
      editInterruptCondition: isEndsWithInterruptingSymbol,
    })

    this.hideInput.watch(() => {
      this.currentPositionBinder?.disconnect()
      this.currentPositionBinder = null
    })

    this.showInput.watch(({ anchorElement, pressCoords }) => {
      if (!anchorElement) return
      this.bindPositionTo(anchorElement)

      if (pressCoords) {
        setTimeout(() => {
          this.mq?.clickAt(pressCoords.x, pressCoords.y)
        }, 20)
      }
    })
  }

  public readonly setUp = (element: HTMLElement | null) => {
    super.setUp(element)
    this.parentElement = element?.parentElement?.parentElement || null
  }

  private readonly getElementPosition = (coords: ICoords) => {
    if (this.parentElement) {
      const parentPosition = this.parentElement.getBoundingClientRect()
      return {
        x: coords.x - parentPosition.x,
        y: coords.y - parentPosition.y,
      }
    }
    return coords
  }

  public bindPositionTo(element: HTMLElement) {
    this.currentPositionBinder?.disconnect()
    this.currentPositionBinder = new MutationObserver(() => {
      const { x, y } = element.getBoundingClientRect()
      this.updatePosition({ x, y })
    })
    this.currentPositionBinder.observe(element, { childList: true, subtree: true })
  }
}
