import { Bredcrumb } from '../Book/types'

export type FetchAllBooksFxParams = {
  offset?: number
  bookSeriesIds?: string[]
}

export type FetchAllBookSeriesFxParams = {
  offset?: number
  bookSeriesIds: string[]
}

export type OpenedSectionPath = {
  chapter?: Bredcrumb
  section?: Bredcrumb
}

export enum BreadcrumbIndex {
  BOOK = 0,
  CHAPTER = 1,
  SECTION = 2,
}
