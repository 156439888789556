import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const SimpleInput = styled(TextField)({
  width: '100%',
  '& .MuiInput-input': {
    borderBottom: '1px solid #E0E0E0',
  },
  '& .MuiInput-input:focus': {
    borderBottom: '1px solid #268AF9',
    transition: '0.2s',
  },
})
