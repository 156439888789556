import { GetSolutionViewURL } from './HelpQueueModal/HelpQueueModalContent/HelpQueueModalContent'
import { IExercise } from '../../api/types.assignments'
import { Listbox, SelectValue } from '@magmamath/ui'

type GetClassRoomIdParams = {
  exercises: IExercise[]
  exercise: IExercise
  exerciseId: string | undefined
  selectedClass: SelectValue
}

export const getClassRoomId = ({
  exercises,
  exercise,
  exerciseId,
  selectedClass,
}: GetClassRoomIdParams) => {
  const pickedExercises = exercises.find((currentExercises) => currentExercises._id === exerciseId)
  const currentExercise = exercise._id === exerciseId ? exercise : pickedExercises
  if (currentExercise) {
    return currentExercise?.classes[0]?._id
  }
  return selectedClass?.value && selectedClass?.value !== Listbox.ALL_OPTION
    ? selectedClass?.value
    : ''
}

type SingleStudentExerciseProps = {
  exerciseId: string
  problemType: string
  problemId: string
  studentId: string
}

const singleStudentExerciseUrl = ({
  exerciseId,
  problemType,
  problemId,
  studentId,
}: SingleStudentExerciseProps) =>
  `/exercises/${exerciseId}/${problemType}/${problemId}/students/${studentId}/solutions`
export const getSolutionViewURL = ({
  exerciseId,
  problemId,
  subProblemId,
  studentId,
}: GetSolutionViewURL) => {
  const problemType = subProblemId ? 'sub-problems' : 'problems'
  return singleStudentExerciseUrl({ exerciseId, problemType, problemId, studentId })
}
