import React from 'react'
import { SvgIconProps } from '../types/types'

const DisabledIcon = ({ color, size = 18 }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        d='M0 6.99996C0 10.8598 3.14015 13.9999 6.99996 13.9999C10.8598 13.9999 13.9999 10.8598 13.9999 6.99996C13.9999 3.14015 10.8598 0 6.99996 0C3.14007 0 0 3.14015 0 6.99996ZM6.99996 12.7481C3.83043 12.7481 1.25186 10.1696 1.25186 6.99996C1.25186 5.64028 1.72739 4.39009 2.51965 3.4048L10.5951 11.4803C9.60983 12.2725 8.35972 12.7481 6.99996 12.7481ZM12.7481 6.99996C12.7481 8.35964 12.2725 9.60983 11.4803 10.5951L3.4048 2.51965C4.39009 1.72739 5.64028 1.25186 6.99996 1.25186C10.1695 1.25186 12.7481 3.83043 12.7481 6.99996Z'
        fill={color}
      />
    </svg>
  )
}

export default DisabledIcon
