import { createToastsModel, ToastsContainer } from '@magmamath/ui'
import React from 'react'
import styles from './ToastModel.module.scss'

export const toast = createToastsModel({ autoCloseTime: 3000, behaviour: 'queue' })

const ToastModel = () => (
  <ToastsContainer factory={toast} classes={{ container: styles.Container }} />
)

export default ToastModel
