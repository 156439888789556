import React from 'react'
import styles from './AccountAvatar.module.scss'
import clsx from 'clsx'

type AccountAvatarProps = {
  title?: string
  className?: string
}

const AccountAvatar = ({ title = '', className }: AccountAvatarProps) => {
  return <div className={clsx(styles.Container, className)}>{title}</div>
}

export default AccountAvatar
