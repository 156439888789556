import { apiManager } from './apiManager'
import { BookSeriesData } from './types.bookSeries'

type GetAllPayload = {
  offset?: number
  ids: string
}

type GetAllBooksSeriesResponse = {
  pages: number
  _embedded: {
    bookSeries: BookSeriesData[]
  }
}

export class bookSeriesApi {
  public static readonly getAll = apiManager.get<GetAllBooksSeriesResponse, GetAllPayload>(
    'book-series'
  )
}
