import React from 'react'
import { SvgIconProps } from '../types/types'

const DragAnchor = ({ size = 25, color, ...restProps }: SvgIconProps) => {
  const sizeRatio = 16 / 9

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size / sizeRatio}
      viewBox='0 0 16 9'
      fill='none'
      {...restProps}
    >
      <path
        d='M1.55458 5.00049C2.41342 5.00049 3.10965 5.69672 3.10965 6.55556C3.10965 7.4144 2.41342 8.11062 1.55458 8.11062C0.695739 8.11062 -0.000488281 7.4144 -0.000488281 6.55556C-0.000488281 5.69672 0.695739 5.00049 1.55458 5.00049Z'
        fill={color}
      />
      <path
        d='M1.55458 0C2.41342 0 3.10965 0.696228 3.10965 1.55507C3.10965 2.41391 2.41342 3.11014 1.55458 3.11014C0.695739 3.11014 -0.000488281 2.41391 -0.000488281 1.55507C-0.000488281 0.696228 0.695739 0 1.55458 0Z'
        fill={color}
      />
      <path
        d='M8.00009 5.00049C8.85893 5.00049 9.55516 5.69672 9.55516 6.55556C9.55516 7.4144 8.85893 8.11062 8.00009 8.11062C7.14125 8.11062 6.44502 7.4144 6.44502 6.55556C6.44502 5.69672 7.14125 5.00049 8.00009 5.00049Z'
        fill={color}
      />
      <path
        d='M8.00009 0C8.85893 0 9.55516 0.696228 9.55516 1.55507C9.55516 2.41391 8.85893 3.11014 8.00009 3.11014C7.14125 3.11014 6.44502 2.41391 6.44502 1.55507C6.44502 0.696228 7.14125 0 8.00009 0Z'
        fill={color}
      />
      <path
        d='M14.4456 5.00049C15.3044 5.00049 16.0007 5.69672 16.0007 6.55556C16.0007 7.4144 15.3044 8.11062 14.4456 8.11062C13.5868 8.11062 12.8905 7.4144 12.8905 6.55556C12.8905 5.69672 13.5868 5.00049 14.4456 5.00049Z'
        fill={color}
      />
      <path
        d='M14.4456 0C15.3044 0 16.0007 0.696228 16.0007 1.55507C16.0007 2.41391 15.3044 3.11014 14.4456 3.11014C13.5868 3.11014 12.8905 2.41391 12.8905 1.55507C12.8905 0.696228 13.5868 0 14.4456 0Z'
        fill={color}
      />
    </svg>
  )
}

export default DragAnchor
