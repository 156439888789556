import React from 'react'
import styles from '../Book.module.scss'
import LibraryIcon from '../../../../ui/icons/LibraryIcon/LibraryIcon'
import { Button, BUTTON_VARIANT, COLORS, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { Link } from 'react-router-dom'
import { PATHS } from '../../../../config/pathnames.config'
import PencilIcon from '../../../../ui/icons/PencilIcon/PencilIcon'
import { getImageUrl } from '../../../heatmap/Problem/helpers/imageUrl'
import bookCover from '../../../../assets/img/BookCover.png'
import useText from '../../../../i18n/hook'

type BookTitleProps = {
  title: string
  image?: string
}

const BookTitle = ({ title, image }: BookTitleProps) => {
  const t = useText()
  const imageUrl = image ? getImageUrl({ imageOrigin: image }) : bookCover
  const isMyBook = title === t.myBookTxt

  return (
    <div className={styles.Title}>
      {isMyBook ? (
        <LibraryIcon color={COLORS.NEUTRAL_7} size={24} className={styles.Icon} />
      ) : (
        <img src={imageUrl} alt='Book' className={styles.Image} />
      )}
      {title}
      {isMyBook && (
        <Button
          classes={{ button: styles.EditButton }}
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.SMALL}
          tooltipText={t.editMyBook}
          tooltipPosition={TOOLTIP_POSITION.BOTTOM}
          icon={<PencilIcon color={COLORS.NEUTRAL_9} size={20} />}
          As={Link}
          to={{
            pathname: PATHS.CONTENT.PROBLEMS, state: { prevPath: location.pathname }
          }}
        />
      )}
    </div>
  )
}

export default BookTitle
