import { UrlSearchParams, buildUrl } from 'lib/url/buildUrl'
import { dynamicPaths } from '../../../../config/pathnames.config'
import { IProblem, ISubProblem } from '../../../../api/types.problem'

type GetCardDataProps = {
  problemId: string
  studentId: string
  exerciseId: string
  problems: Map<string, IProblem | ISubProblem>
  subProblemId?: string
  searchParams?: UrlSearchParams
}
export const getCardData = ({
  problemId,
  studentId,
  exerciseId,
  problems,
  subProblemId,
  searchParams,
}: GetCardDataProps) => {
  const cardSubProblem = subProblemId ? problems.get(subProblemId) : undefined
  const cardProblem = cardSubProblem?.problemId
    ? problems.get(cardSubProblem.problemId)
    : problems.get(problemId)

  const solutionLink = cardProblem?._id
    ? buildUrl({
        baseUrl: dynamicPaths.studentSolutions({
          exerciseId,
          problemId: cardProblem._id,
          subProblemId: cardSubProblem?._id,
          studentId,
        }),
        searchParams,
      })
    : '/'

  return { cardProblem, cardSubProblem, solutionLink }
}
