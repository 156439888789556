import { pageSizes } from 'config'
import { Directions, SortingTypes, TypesOfStudentCell } from 'helpers/enums'
import {
  compareDate,
  compareWithPercent,
  sortByDate,
  sortByExamMode,
  sortByExerciseName,
} from 'helpers/sort.helpers'
import { IState } from 'interfaces/state.interface'
import _ from 'lodash'
import React from 'react'
import { IStudentProgressActions } from 'redux/modules/student-report.module'
import { handleVerticalScroll } from 'views/Exercises/scrollDirection.helpers'
import './StudentTable.component.scss'
import StudentTableBody from './StudentTableComponents/TableBodyContainer/StudentTableBody.component'
import StudentProgressTableHeader from './StudentTableComponents/TableHeaderContainer/StudentTableHeader.component'
import { ILocalization } from '../../config/languages.config'
import { GetStudentProgressResponse, IStudentProgressStat } from '../../api/types.statistics'

export interface IStudentTableProps {
  localization: ILocalization
  tableHeaderProps: Array<{ order: TypesOfStudentCell; name: string }>
  studentProgress: IState<GetStudentProgressResponse>
  studentProgressActions: IStudentProgressActions
  studentId: string
}

export interface IStudentTableState {
  lastExerciseIsVisible: boolean
  page: number
  scrollDirection: string
  sortedType: SortingTypes
  sortedCell: TypesOfStudentCell.DateCell
  sortedStats: IStudentProgressStat[]
}

class StudentProgressTable extends React.Component<IStudentTableProps, any, any> {
  public lastScroll: number = 0
  public tableWrapper: HTMLElement | null
  public directionDown: boolean = false

  constructor(props: IStudentTableProps) {
    super(props)
    this.state = {
      commonStats: [],
      lastExerciseIsVisible: false,
      page: 1,
      scrollDirection: undefined,
      sortedCell: TypesOfStudentCell.DateCell,
      sortedStats: this.props.studentProgress.data.progress.studentStats,
      sortedType: SortingTypes.Desc,
    }
  }

  public getStudentAllProgress(id: string, totalPageCount: number, page: number) {
    if (totalPageCount >= page) {
      this.getStudentAllProgress(id, totalPageCount, page + 1)
      this.props.studentProgressActions.getStudentAllProgress(id, totalPageCount, page)
    }
  }

  public componentDidMount() {
    const totalPageCount = this.props.studentProgress.data.totalPages
    const secondPage = 2
    this.getStudentAllProgress(this.props.studentId, totalPageCount, secondPage)
    this.addFirstElementToCommonArray().then(() => this.elementIsVisible())
  }

  public componentDidUpdate(prevProps: IStudentTableProps) {
    if (
      prevProps.studentProgress.data.progress.studentStats !==
      this.props.studentProgress.data.progress.studentStats
    ) {
      this.setState({
        sortedStats: this.props.studentProgress.data.progress.studentStats,
      })
    }
  }

  public componentWillUnmount() {
    this.props.studentProgressActions.clearStudProgress()
  }

  public addFirstElementToCommonArray = async (): Promise<void> => {
    const reportObject: GetStudentProgressResponse = {
      page: 1,
      progress: this.props.studentProgress.data.progress,
      totalPages: this.props.studentProgress.data.totalPages,
    }

    await this.setState({
      commonStats: [...this.state.commonStats, reportObject],
      sortedStats: this.props.studentProgress.data.progress.studentStats,
    })
  }

  public getReportsForPages = async (studentId: string, page: number): Promise<void> => {
    if (this.props.studentProgress) {
      this.changeAssociationArray(this.props.studentProgress.data, this.state.commonStats).then(
        () => {
          this.sortExercises(this.state.sortedCell, this.state.sortedType)
        }
      )
    }
  }

  public changeAssociationArray = async (
    report: GetStudentProgressResponse,
    arrayWithOldStats: GetStudentProgressResponse[]
  ): Promise<void> => {
    const oldReport = _.find(arrayWithOldStats, { page: report.page })
    const reportObject: GetStudentProgressResponse = {
      page: report.page || 1,
      progress: report.progress,
      totalPages: report.totalPages,
    }

    if (!(oldReport && oldReport.page === report.page)) {
      await this.setState({
        commonStats: [...this.state.commonStats, reportObject],
        sortedStats: [...this.state.sortedStats, ...reportObject.progress.studentStats],
      })
    }
  }

  public elementIsVisible = (scrollDirection?: string): void => {
    const { sortedStats } = this.state
    if (sortedStats.length >= pageSizes.ten) {
      const lastStat: IStudentProgressStat = sortedStats[sortedStats.length - 1]
      const isScrollDownOfWithoutScrolling: boolean =
        _.isUndefined(scrollDirection) || (!!scrollDirection && scrollDirection === 'down')
      if (lastStat) {
        const lastStatRow: HTMLElement | null = document.getElementById(lastStat._id)

        if (lastStatRow) {
          const classTableRect: ClientRect = (lastStatRow as any).getBoundingClientRect()
          const windowPositionRight: number =
            window.pageYOffset + document.documentElement.clientHeight
          const nextPage: number = this.state.page + 1
          const isPageLessFromTotal: boolean =
            nextPage <= this.props.studentProgress.data.totalPages

          if (
            !this.state.lastExerciseIsVisible &&
            isScrollDownOfWithoutScrolling &&
            isPageLessFromTotal &&
            classTableRect.bottom < windowPositionRight
          ) {
            this.setState({ lastExerciseIsVisible: true, page: nextPage })
            this.getReportsForPages(lastStat.studentId, nextPage)
          } else {
            this.setState({ lastExerciseIsVisible: false })
          }
        } else {
          this.setState({ lastExerciseIsVisible: false })
        }
      }
    }
  }

  public handleElementVisibility = (): void => {
    if (this.state.commonStats.length >= 2) {
      this.elementIsVisible(this.state.scrollDirection)
    }
  }

  public changeDirection = (value: Directions): void => {
    this.setState({ scrollDirection: value })
  }

  public sortExercises = async (
    typeOfCell: TypesOfStudentCell,
    value: SortingTypes
  ): Promise<void> => {
    const { sortedStats } = this.state
    this.setState({ sortedType: value, sortedCell: typeOfCell })

    if (typeOfCell === TypesOfStudentCell.DateCell) {
      await this.setState({ sortedStats: sortByDate(sortedStats, value) })
    } else if (typeOfCell === TypesOfStudentCell.TypeCell) {
      this.setState({ sortedStats: sortByExamMode(sortedStats, value) })
    } else if (
      typeOfCell === TypesOfStudentCell.FirstAttemptCell ||
      typeOfCell === TypesOfStudentCell.AfterFirstAttemptCell ||
      typeOfCell === TypesOfStudentCell.WrongCell ||
      typeOfCell === TypesOfStudentCell.AnsweredCell ||
      typeOfCell === TypesOfStudentCell.NotAnsweredCell
    ) {
      this.setState({ sortedStats: this.sortByPercent(sortedStats, value, typeOfCell) })
    } else if (typeOfCell === TypesOfStudentCell.ExerciseNameCell) {
      this.setState({ sortedStats: sortByExerciseName(sortedStats, value) })
    }
  }

  public calculatePercent = (
    progressStat: IStudentProgressStat,
    typeOfCell: TypesOfStudentCell
  ): number => {
    if (typeOfCell === TypesOfStudentCell.FirstAttemptCell) {
      return progressStat.firstAttempt
    } else if (typeOfCell === TypesOfStudentCell.AfterFirstAttemptCell) {
      return progressStat.afterFirstAttempt
    } else if (typeOfCell === TypesOfStudentCell.WrongCell) {
      return progressStat.wrong
    } else if (typeOfCell === TypesOfStudentCell.AnsweredCell) {
      return 100 - (progressStat.notAnswered + progressStat.wrong)
    } else if (typeOfCell === TypesOfStudentCell.NotAnsweredCell) {
      const valueAnswered: number = 100 - (progressStat.notAnswered + progressStat.wrong)
      return 100 - (valueAnswered + progressStat.wrong)
    } else {
      return 0
    }
  }

  public sortByPercent = (
    array: IStudentProgressStat[],
    order: SortingTypes,
    typeOfCell: TypesOfStudentCell
  ) => {
    return array.sort((a: IStudentProgressStat, b: IStudentProgressStat) => {
      const percentOfCompletedForFirst: number = this.calculatePercent(a, typeOfCell)
      const percentOfCompletedForSecond: number = this.calculatePercent(b, typeOfCell)
      const percentIsEqual: boolean =
        Math.round(percentOfCompletedForFirst) === Math.round(percentOfCompletedForSecond)

      if (percentIsEqual) {
        return compareDate(
          percentOfCompletedForFirst,
          percentOfCompletedForSecond,
          SortingTypes.Desc
        )
      } else {
        return compareWithPercent(percentOfCompletedForFirst, percentOfCompletedForSecond, order)
      }
    })
  }

  public render() {
    const { localization } = this.props
    return (
      <div
        className='student-progress-container'
        ref={(node: HTMLElement | null) => (this.tableWrapper = node)}
        onScroll={(e: any) => {
          e.stopPropagation()
          handleVerticalScroll(
            this.tableWrapper,
            this.lastScroll,
            this.directionDown,
            this.changeDirection,
            this.handleElementVisibility
          )
        }}
      >
        <table className='student-progress-table'>
          <StudentProgressTableHeader
            sortedType={this.state.sortedType}
            sortedCell={this.state.sortedCell}
            sortExercisesFunc={this.sortExercises}
            tableHeaderProps={this.props.tableHeaderProps}
            localization={this.props.localization}
            studentProgress={this.props.studentProgress}
          />
          <StudentTableBody
            sortedType={this.state.sortedType}
            studentProgress={this.props.studentProgress}
            sortedStats={this.state.sortedStats}
            localization={localization}
          />
        </table>
      </div>
    )
  }
}

export default StudentProgressTable
