import clsx from 'clsx'
import useText from 'i18n/hook'
import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import { TABLE_HEADER_HEIGHT } from 'features/tables/HeatmapTable/constants'
import styles from './Table.module.scss'
import { getArrayFromNumber } from '../../../../../helpers/getArrayFromNumber'

const TABLE_LIST_HEIGHT = 300
const STUDENTS_NUMBER = 24
const PROBLEMS_NUMBER = 10

const TableSkeleton = () => {
  const t = useText()
  return (
    <div className={styles.TableContainer} style={{ paddingRight: 10 }}>
      <div className={clsx(styles.Table, styles.TableSkeleton)}>
        <div
          className={clsx(styles.StickyLeft, styles.FlexGapColumn)}
          style={{ height: TABLE_LIST_HEIGHT }}
        >
          <div
            className={clsx(styles.DefaultText, styles.StickyTop, styles.TableTitle)}
            style={{ height: TABLE_HEADER_HEIGHT, paddingTop: 4, marginBottom: 4 }}
          >
            <span>{t.studentsTxt}</span>
          </div>
          {getArrayFromNumber(STUDENTS_NUMBER).map((_, index) => (
            <Skeleton
              variant='rounded'
              style={{ width: '210px', height: 40, marginBottom: 4 }}
              key={index}
              className={styles.TableRowHeaderContainer}
            ></Skeleton>
          ))}
        </div>
        <div className={styles.TableColumns}>
          {getArrayFromNumber(PROBLEMS_NUMBER).map((_, index) => (
            <div key={index} className={styles.TableColumn}>
              <Skeleton
                variant='rounded'
                style={{
                  height: TABLE_HEADER_HEIGHT,
                  minHeight: TABLE_HEADER_HEIGHT,
                  maxHeight: TABLE_HEADER_HEIGHT,
                  minWidth: 40,
                  flexGrow: 1,
                  marginBottom: 2,
                }}
                key={index}
                className={clsx(styles.HeaderCellContainer, styles.StickyTop)}
              />
              {getArrayFromNumber(STUDENTS_NUMBER).map((_, index) => {
                return (
                  <Skeleton
                    variant='rounded'
                    style={{
                      height: 40,
                      minHeight: 40,
                      maxHeight: 40,
                      minWidth: 40,
                      flexGrow: 1,
                      marginBottom: 2,
                    }}
                    key={index}
                    className={styles.TableRowTableBodyCellHeaderContainer}
                  />
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TableSkeleton
