import {
  Entity,
  KatexNode,
  KatexValueParserSettings,
  TextNode,
  ToObjectSettings,
  WrapSettings,
} from './types'
import {
  disassemble,
  exponentNode,
  extractRegExpValue,
  findOuterBraces,
  fracNode,
  sqrtNode,
  textNode,
} from './helpers'

const parseSingle = (input: string, offset: number, { filter }: ToObjectSettings = {}) => {
  const end = findOuterBraces(input, offset)
  const textValue = input.slice(offset + 1, end)
  const value: KatexNode[] =
    textValue === ' ' ? [textNode(' ')] : KatexValueParser.toObject(textValue, { filter })
  return { input: input.slice(end + 1), value, end }
}
export class KatexValueParser {
  public static toObject(input: string, { filter }: ToObjectSettings = {}): KatexNode[] {
    const result: KatexNode[] = []
    disassemble(input, (current) => {
      const frac = /^\\frac\{/.test(current)
      if (frac) {
        const numerator = parseSingle(current, 5, { filter })
        const denominator = parseSingle(current, numerator.end + 1, { filter })
        result.push(fracNode(numerator.value, denominator.value))
        return current.slice(denominator.end + 1)
      }

      const exponent = /^\^./.test(current)
      if (exponent) {
        const isBraced = current[1] === '{'
        if (isBraced) {
          const parsed = parseSingle(current, 1, { filter })
          result.push(exponentNode(parsed.value))
          return parsed.input
        }
        result.push(exponentNode([textNode(current[1])]))
        return current.slice(2)
      }

      const sqrt = /^\\sqrt\{/.test(current)
      if (sqrt) {
        const parsed = parseSingle(current, 5, { filter })
        result.push(sqrtNode(parsed.value))
        return parsed.input
      }

      const text = current.match(/^(([^\\^]+)|(\\ ))+/)?.[0]
      if (text) {
        const value = filter ? filter(text) : text
        if (value) result.push(textNode(value.replace(/ /g, '\\ ')))
        return current.slice(text.length)
      }

      return current.slice(1)
    })
    return result
  }

  public static toString(structure: KatexNode[]): string {
    return structure
      .map((item) => {
        if (item.type === Entity.TEXT) return item.text
        if (item.type === Entity.FRAC) {
          return `\\frac{${this.toString(item.numerator)}}{${this.toString(item.denominator)}}`
        }
        if (item.type === Entity.SQRT) {
          return `\\sqrt{${this.toString(item.value)}}`
        }
        if (item.type === Entity.EXPONENT) {
          const exponentText = this.toString(item.value)
          return exponentText.length > 1 || exponentText === ' '
            ? `^{${this.toString(item.value)}}`
            : `^${exponentText}`
        }
        return ''
      })
      .join('')
  }

  public static toTextNode(structure: KatexNode[]): TextNode {
    return {
      type: Entity.TEXT,
      text: structure.reduce((acc, numItem) => {
        if (numItem.type === Entity.TEXT) return acc + numItem.text
        if (numItem.type === Entity.FRAC) {
          return this.toString(this.unwrap(numItem.numerator, { allowedLevels: 0 }))
        }
        return ''
      }, ''),
    }
  }

  public static unwrap<T extends string | KatexNode[]>(
    input: T,
    {
      allowedLevels = 2,
      currentLevel = 1,
      preventSameEntityNesting = true,
      parentEntity,
    }: WrapSettings = {}
  ): T {
    const structure: KatexNode[] = typeof input === 'string' ? this.toObject(input) : input
    const next: KatexNode[] = structure.map((item) => {
      const nextStepProps: WrapSettings = {
        allowedLevels,
        currentLevel: currentLevel + 1,
        preventSameEntityNesting,
        parentEntity: item.type,
      }
      if (
        currentLevel > allowedLevels ||
        (preventSameEntityNesting && item.type === parentEntity)
      ) {
        if (item.type === Entity.FRAC) return this.toTextNode(item.numerator)
        if (item.type === Entity.EXPONENT || item.type === Entity.SQRT) {
          return { type: Entity.TEXT, text: '' }
        }
        return item
      }
      if (item.type === Entity.FRAC) {
        return {
          type: Entity.FRAC,
          numerator: this.unwrap(item.numerator, nextStepProps),
          denominator: this.unwrap(item.denominator, nextStepProps),
        }
      }
      if (item.type === Entity.EXPONENT || item.type === Entity.SQRT) {
        return {
          type: item.type,
          value: this.unwrap(item.value, nextStepProps),
        }
      }
      return item
    })
    return (typeof input === 'string' ? this.toString(next) : next) as T
  }

  private current
  private structure
  private syncRequired = false
  public value() {
    if (this.syncRequired) {
      this.current = KatexValueParser.toString(this.structure)
      this.syncRequired = false
    }
    return this.current
  }

  private readonly filterFn: ((value: string) => string) | null = null

  public constructor(value: string, { acceptedSymbols }: KatexValueParserSettings = {}) {
    this.current = value
    if (acceptedSymbols) {
      const regExp = new RegExp(`[^${extractRegExpValue(acceptedSymbols)}]`, 'g')
      this.filterFn = (value) => value.replace(regExp, '').replace(/%/g, '\\%')
    }
    this.structure = KatexValueParser.toObject(value, { filter: this.filterFn })
  }

  public unwrap(settings: WrapSettings = {}) {
    this.structure = KatexValueParser.unwrap(this.structure, settings)
    this.syncRequired = true
    return this
  }

  public fixPercent() {
    this.current = this.value().replace(/%/g, '\\%')
    return this
  }
}
