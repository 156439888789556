import React from 'react'
import styles from './ConfirmDeleteModal.module.scss'
import { Modal, Button, BUTTON_VARIANT, SIZE, BUTTON_COLOR } from '@magmamath/ui'
import useText from 'i18n/hook'
import { ConfirmModalMode } from 'helpers/enums'
import { getModalTitle, getQuestion, getWarning } from './helpers'
import { useUnit } from 'effector-react'
import { MyBookModel } from '../model'

type ConfirmDeleteModalProps = {
  mode: ConfirmModalMode,
  isOpen: boolean,
  onModalToggle: () => void,
}

const ConfirmDeleteModal = ({
  isOpen,
  mode,
  onModalToggle,
}: ConfirmDeleteModalProps) => {
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const t = useText()

  if (!selectedSection) return null

  const title = getModalTitle(mode, t)
  const question = getQuestion(mode, t)
  const warning = getWarning(mode, t)

  const handleSubmit = () => {
    mode === ConfirmModalMode.SECTION ? MyBookModel.deleteSectionFx() : MyBookModel.deleteProblemFx()
    onModalToggle()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalToggle}
      hiddenCloseButton
    >
      <Modal.Header title={title} className={styles.ModalHeader} />
      <Modal.Content classes={{ Content: styles.ModalContent }}>

        <div className={styles.ModalText}>
          <p>{question}</p>
          {warning && <p>{warning}</p>}
        </div>
        <div className={styles.ModalFooter}>
          <Button
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZE.LARGE}
            onClick={onModalToggle}
          >
            {t.cancelTxt}
          </Button>
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.LARGE}
            color={BUTTON_COLOR.RED}
            onClick={handleSubmit}
            type='submit'
          >
            <span>{t.deleteTxt}</span>
          </Button>
        </div>
      </Modal.Content>

    </Modal>
  )
}

export default ConfirmDeleteModal
