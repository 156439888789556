import { IAction } from 'interfaces/actions.interface'

// Problems constants
export enum PROBLEMS {
  SECTION_PROBLEMS_REQUEST = 'SECTION_PROBLEMS_REQUEST',
  SECTION_PROBLEMS_SUCCESS = 'SECTION_PROBLEMS_SUCCESS',
  SECTION_PROBLEMS_ERROR = 'SECTION_PROBLEMS_ERROR',
  SEARCH_PROBLEMS_REQUEST = 'SEARCH_PROBLEMS_REQUEST',
  SEARCH_PROBLEMS_SUCCESS = 'SEARCH_PROBLEMS_SUCCESS',
  SEARCH_PROBLEMS_ERROR = 'SEARCH_PROBLEMS_ERROR',
}

// Problems reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function problemsReducer(state: any = initialState, action: IAction<PROBLEMS>) {
  switch (action.type) {
    case PROBLEMS.SECTION_PROBLEMS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PROBLEMS.SECTION_PROBLEMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case PROBLEMS.SECTION_PROBLEMS_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case PROBLEMS.SEARCH_PROBLEMS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case PROBLEMS.SEARCH_PROBLEMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case PROBLEMS.SEARCH_PROBLEMS_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export interface IProblems {
  total: number
  pages: number
  _links: {
    self: {
      href: string
    }
    next: {
      href: string
    }
  }
  _embedded: {
    problems: any[]
  }
}
