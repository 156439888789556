import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { Menu, MenuItem } from '@mui/material'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { selectProblem } from 'features/bookshelf/Book/model/selectedProblems'
import { changeExerciseMode } from 'features/caf/exerciseSettings/model/exerciseSettings'
import { MuiMenuProps } from 'features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import { DropdownPopupIds } from 'helpers/enums'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useCallback } from 'react'
import ArrowUpIcon from 'ui/icons/ArrowUpIcon/ArrowUpIcon'
import BinIcon from 'ui/icons/BinIcon/BinIcon'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { dndSelectedProblemsModel } from '../../model/dndSelectedProblems'
import { currentSortOptionModel } from '../../model/sortOption/currentSortOption'
import { SortOptions } from '../../model/sortOption/sortOptionModel'
import styles from './PopoverMenu.module.scss'

type PopoverMenuProps = {
  order: number
  problemsCount: number
  problemId: string
  sectionId: string
}

const OPTION_ICON_SIZE = 11

const PopoverMenu = ({ order, problemsCount, problemId, sectionId }: PopoverMenuProps) => {
  const t = useText()
  const sortOption = useUnit(currentSortOptionModel.$state)

  const onProblemSelection = useCallback(
    (closePopup: () => void) => {
      changeExerciseMode(sectionId)
      selectProblem({ sectionId, problemId })
      closePopup()
    },
    [sectionId, problemId]
  )

  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.PROBLEM_CARD}>
      {(popupState) => (
        <div className={styles.MenuContainer}>
          <Button
            classes={{ button: styles.OptionsButton }}
            {...bindTrigger(popupState)}
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZE.MEDIUM}
            icon={<OptionIcon color={COLORS.NEUTRAL_7} size={16} />}
            data-color='transparent'
          />
          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
            {sortOption === SortOptions.MANUAL && (
              <MenuItem
                className={styles.MenuItem}
                disabled={order === 1}
                onClick={() => {
                  dndSelectedProblemsModel.moveUp(problemId)
                  popupState.close()
                }}
              >
                <ArrowUpIcon color={COLORS.NEUTRAL_7} size={OPTION_ICON_SIZE} />
                <span>{t.moveUpTxt}</span>
              </MenuItem>
            )}
            {sortOption === SortOptions.MANUAL && (
              <MenuItem
                className={styles.MenuItem}
                disabled={order === problemsCount}
                onClick={() => {
                  dndSelectedProblemsModel.moveDown(problemId)
                  popupState.close()
                }}
              >
                <ArrowUpIcon
                  color={COLORS.NEUTRAL_7}
                  size={OPTION_ICON_SIZE}
                  transform='rotate(180)'
                />
                <span>{t.moveDownTxt}</span>
              </MenuItem>
            )}
            <MenuItem
              className={clsx(styles.MenuItem, styles.Remove)}
              onClick={() => onProblemSelection(popupState.close)}
            >
              <BinIcon color={COLORS.RED_6} size={OPTION_ICON_SIZE} />
              {t.removeTxt}
            </MenuItem>
          </Menu>
        </div>
      )}
    </PopupState>
  )
}

export default PopoverMenu
