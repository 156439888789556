import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import * as React from 'react'
import { useRef, useEffect } from 'react'
import { ISection } from '../../../api/types.books'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import styles from './SectionItem.module.scss'
import { BUTTON_VARIANT, Button, COLORS, SIZE } from '@magmamath/ui'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { useUnit } from 'effector-react'
import { SectionClickProps } from '../types'
import { MyBookModel } from '../model'

type ISectionItemProps = {
  currentSection: ISection,
  onMenuClick: (props: SectionClickProps) => void
  isDropdownOpen: boolean,
  isMenuBtnActive: boolean,
  onSectionSelected: (section: HTMLLIElement) => void,
  isFirstSection: boolean
}

const SectionItem = ({
  currentSection,
  onMenuClick,
  isDropdownOpen,
  isMenuBtnActive,
  onSectionSelected,
  isFirstSection
}: ISectionItemProps) => {
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const { overflowRef, isOverflowing } = useOverflowDetection({ title: currentSection.name })
  const sectionRef = useRef<HTMLLIElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const isSelected = selectedSection?._id === currentSection._id
  const activeClass = isSelected ? styles.SectionItemActive : ''

  useEffect(() => {
    if (sectionRef.current && isSelected) {
      onSectionSelected(sectionRef.current)
    }
  }, [isSelected])

  if (!selectedSection) return null

  return (
    <UITooltip
      placement='bottom-start'
      tooltipClasses={{
        popper: styles.Popper,
        tooltip: styles.Tooltip,
      }}
      title={isOverflowing && !isDropdownOpen ? currentSection.name : ''}
    >
      <li
        key={currentSection._id}
        className={`${activeClass} ${styles.SectionItem}`}
        onClick={() => MyBookModel.setSelectedSection(currentSection)}
        ref={sectionRef}
      >
        <div className={styles.SectionItemTitile}>
          <p ref={overflowRef}>{currentSection.name}</p>
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation()
            if (menuRef.current && sectionRef.current) {
              onMenuClick({
                clickedSection: sectionRef.current,
                clickedMenu: menuRef.current,
                section: currentSection,
                isFirstSection,
              })
            }
          }}
          className={styles.MenuBtnWrap}
          ref={menuRef}
        >
          <Button
            onFocus={(event) => event.target.blur()}
            isActive={isMenuBtnActive}
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZE.SMALL}
            icon={<OptionIcon size={16} color={COLORS.NEUTRAL_7} />}
          />
        </div>
      </li>
    </UITooltip>
  )
}

export default SectionItem
