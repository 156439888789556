import React from 'react'

import { SvgIconProps } from '../types/types'
const CheckboxIconActiveChecked: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.41438 10.4983L7.97486 15.0588L15.5756 5.93781M6.14286 19H13.8571C16.6975 19 19 16.6975 19 13.8571V6.14286C19 3.30254 16.6975 1 13.8571 1H6.14286C3.30254 1 1 3.30254 1 6.14286V13.8571C1 16.6975 3.30254 19 6.14286 19Z'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CheckboxIconActiveChecked
