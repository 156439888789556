import { ILocalization } from '../../config/languages.config'

export const sortNumbersASC = (a: number, b: number) => a - b

export const compareGradesArrays = (gradesArrays: number[][]) => {
  return gradesArrays.every(
    (grades) =>
      JSON.stringify(grades.sort(sortNumbersASC)) ===
      JSON.stringify(gradesArrays[0].sort(sortNumbersASC))
  )
}

export const getCorrectGradeName = (grade: number, t: ILocalization['data']) => {
  if (grade === 0) {
    return t.zeroGradeTxt
  }

  return grade
}
