import { createStore } from 'effector'
import { fetchSectionsFx } from './requests'

export const $examModeSections = createStore<Set<string>>(new Set()).on(
  fetchSectionsFx.doneData,
  (state, { sections }) => {
    const newState = new Set(state)
    sections.forEach((section) => {
      if (section.defaultExam) {
        newState.add(section._id)
      }
    })
    return newState
  }
)
