import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import styles from './SearchResultsSkeleton.module.scss'
import clsx from 'clsx'

type BookshelfSkeletonProps = {
  className?: string
}

const SearchResultsSkeleton = ({ className }: BookshelfSkeletonProps) => {
  return (
    <div className={clsx(styles.Bookshelf, className)}>
      <Skeleton className={styles.Result} />
      <Skeleton className={styles.Result} />
      <Skeleton className={styles.Result} />
    </div>
  )
}

export default SearchResultsSkeleton
