import React, { useState, useMemo } from 'react'
import { Modal } from '@mui/material'
import { useDispatch } from 'react-redux'

import './TranslationLanguageSelectorModal.scss'

import { ReactComponent as ExitIcon } from '../../../assets/img/exit.svg'

import { TranslationLanguageSelectorModalBox } from '../../basic/Boxes/TranslationLanguageSelectorModalBox/TranslationLanguageSelectorModalBox'
import { ModalType } from '../../../helpers/enums'
import {
  ILanguageSettings,
  ILanguageSettingsActions,
} from '../../../redux/modules/languageSettings.module'
import { openLanguageSettingsModal } from '../../../redux/modules/modal.module'
import {
  closeTranslationLanguageSelectorModal,
  IModalActions,
} from '../../../redux/modules/modal.module'

import LanguageList from './LanguageList/LanguageList'
import { getNativeLanguageList } from '../../../helpers/language.helpers'
import { currentLocale } from '../../../config/modules.config'
import { ILocalization } from "../../../config/languages.config";

export interface ITranslationLanguageModal {
  modal: any
  localization: ILocalization
  modalActions: IModalActions
  languageSettings: ILanguageSettings
  languageSettingsActions: ILanguageSettingsActions
}
const TranslationLanguageSelectorModal: React.FC<ITranslationLanguageModal> = ({
  modal,
  localization,
  languageSettings,
  languageSettingsActions,
}) => {
  const dispatch = useDispatch()
  const opened: boolean = modal.data.modalType === ModalType.TranslationLanguageSelectorModal
  const nativeLanguageItems: string[] = getNativeLanguageList(currentLocale.currentLang)
  const { translationLanguageTxt, searchTxt } = localization.data
  const { language: currentLanguage } = languageSettings?.data
  const { changeTranslationLanguage } = languageSettingsActions

  const [languageSearchTerm, setLanguageSearchTerm] = useState('')
  const handleClose = () => {
    dispatch(closeTranslationLanguageSelectorModal())
    dispatch(openLanguageSettingsModal(modal.data?.settings, modal.data?.classId))
  }
  const onLanguageSelect = (language: string | null) => {
    changeTranslationLanguage(language)
    handleClose()
    setLanguageSearchTerm('')
  }

  const handleClearSearchTerm = () => {
    setLanguageSearchTerm('')
  }

  const filteredLanguages = useMemo(() => {
    if (!languageSearchTerm) {
      return nativeLanguageItems
    }

    return nativeLanguageItems.filter((language) => {
      return language.toLowerCase().includes(languageSearchTerm.toLowerCase())
    })
  }, [languageSearchTerm])

  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <TranslationLanguageSelectorModalBox>
        <div className='translation-language-modal'>
          <span className='translation-language-modal-title'>{translationLanguageTxt}</span>
          <div className='exit-translation-modal' onClick={handleClose}>
            <ExitIcon />
          </div>
          <div className='native-language-search-input-wrapper'>
            <input
              type='search'
              className='native-language-search-input'
              placeholder={searchTxt}
              value={languageSearchTerm}
              onChange={(event) => setLanguageSearchTerm(event.target.value)}
            />

            {!!languageSearchTerm && (
              <button
                className='native-language-search-input-clear'
                onClick={handleClearSearchTerm}
              >
                <ExitIcon />
              </button>
            )}
          </div>
          <LanguageList
            localization={localization}
            currentLanguage={currentLanguage}
            selectLanguage={onLanguageSelect}
            filteredLanguages={filteredLanguages}
            isFilterEmpty={!languageSearchTerm.length}
          />
        </div>
      </TranslationLanguageSelectorModalBox>
    </Modal>
  )
}

export default TranslationLanguageSelectorModal
