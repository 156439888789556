import React from 'react'
import { AccordionListItem } from '@magmamath/ui'
import { useStoreMap } from 'effector-react'
import useText from 'i18n/hook'
import { Bredcrumb } from '../types'
import { $chapters } from '../model/chapters'
import styles from '../Book.module.scss'

type ChaptersListProps = {
  bookId: string
  onClickChapter: (chapter: Bredcrumb) => void
}

const ChaptersList = ({ bookId, onClickChapter }: ChaptersListProps) => {
  const bookChapters = useStoreMap($chapters, (data) => data.get(bookId))
  const t = useText()

  return (
    <>
      {!!bookChapters?.length ? (
        bookChapters.map((chapter, index) => (
          <AccordionListItem
            key={chapter.id}
            title={`${index + 1}. ${chapter.name}`}
            onClick={() => onClickChapter({ id: chapter.id, name: chapter.name })}
          />
        ))
      ) : (
        <p className={styles.Text}>{t.noChaptersFound}</p>
      )}
    </>
  )
}

export default ChaptersList
