import { config } from 'config'
import { localStorageKeys } from 'config/localStorageKeys'
import { addToken } from 'config/modules.config'
import { PATHS } from 'config/pathnames.config'
import { generateErrorMessageFromeErrorCode } from 'helpers/modules.helpers'
import { localStorageClear, setItemToLocalStorage } from 'helpers/storage.helper'
import { setLanguage } from '../../helpers/language.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IMe, User } from 'api/types.users'
import { clearAuthHeader } from '../../helpers/interceptor.helper'
import { ILocalizationActions } from './localization.module'
import { setTokenForSocket } from '../../helpers/socket.helper'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'
import { LoginPayload } from '../../api/api.auth'

// Auth constants
export enum AUTH {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  GET_ME_REQUEST = 'GET_ME_REQUEST',
  GET_ME_SUCCESS = 'GET_ME_SUCCESS',
  GET_ME_ERROR = 'GET_ME_ERROR',
  UNATHENTIFICATED = 'UNATHENTIFICATED',
  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_ERROR = 'SIGNUP_ERROR',
  SKOLON_REQUEST = 'SKOLON_REQUEST',
  SKOLON_SUCCESS = 'SKOLON_SUCCESS',
  SKOLON_ERROR = 'SKOLON_ERROR',
  SIGNUP_SKOLON_REQUEST = 'SIGNUP_SKOLON_REQUEST',
  SIGNUP_SKOLON_SUCCESS = 'SIGNUP_SKOLON_SUCCESS',
  SIGNUP_SKOLON_ERROR = 'SIGNUP_SKOLON_ERROR',
  AUTHENTICATED = 'AUTHENTICATED',
}

// Auth reducer
const initialState = {
  data: {
    authenticated: false,
    available: false,
    me: undefined,
    type: 0,
    valid: false,
  },
  error: null,
  loading: false,
}

export function authReducer(state: any = initialState, action: IAction<AUTH>) {
  switch (action.type) {
    case AUTH.LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        data: { ...state.data, authenticated: true },
        loading: false,
      }
    case AUTH.LOGIN_ERROR:
      return {
        data: { authenticated: false, me: false, type: 0 },
        error: action.payload,
        loading: false,
      }
    case AUTH.SIGNUP_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTH.SIGNUP_SUCCESS:
      return {
        ...state,
        data: { ...state.data, authenticated: true },
        loading: false,
      }
    case AUTH.SIGNUP_ERROR:
      return {
        data: { authenticated: false, me: false, type: 0 },
        error: action.payload,
        loading: false,
      }
    case AUTH.AUTHENTICATED:
      return {
        ...state,
        data: { ...state.data, authenticated: true },
        loading: false,
      }
    case AUTH.UNATHENTIFICATED:
      return {
        ...state,
        data: { authenticated: false, me: undefined, type: 0 },
        loading: false,
      }
    case AUTH.GET_ME_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTH.GET_ME_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          authenticated: true,
          me: action.payload,
          type: action.payload.type,
        },
        loading: false,
      }
    case AUTH.GET_ME_ERROR:
      return {
        data: { authenticated: false, me: {}, type: 0 },
        error: action.payload,
        loading: false,
      }
    case AUTH.SKOLON_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTH.SKOLON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case AUTH.SKOLON_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case AUTH.SIGNUP_SKOLON_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case AUTH.SIGNUP_SKOLON_SUCCESS:
      return {
        ...state,
        data: { ...state.data, authenticated: true },
        loading: false,
      }
    case AUTH.SIGNUP_SKOLON_ERROR:
      return {
        data: { authenticated: false, me: false, type: 0 },
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Auth actions
export function logout(loginAppURl: string) {
  api.manager.resetToken()
  localStorageClear()
  sessionStorage.clear()
  clearAuthHeader()
  window.location.href = `${loginAppURl}${PATHS.LOGIN.SIGN_IN}`
}

export const loginSuccess = async (dispatch: IDispatch<any>, token: string, history: any) => {
  dispatch({ type: AUTH.LOGIN_SUCCESS })
  setItemToLocalStorage(localStorageKeys.token, token)
  addToken(token)
  setTokenForSocket(token)
  await getMeData(dispatch, history)
  history.push('/')
}

export function login(
  { username, password }: LoginPayload,
  history: any,
  localization: ILocalization
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: AUTH.LOGIN_REQUEST })
      const res = await api.auth.login({ username, password })
      if (+res?.type !== User.teacher) {
        throw new Error(localization.data.userNotTeacher)
      }
      loginSuccess(dispatch, res.token, history)
    } catch (error) {
      if (navigator.onLine && error.message !== 'Network Error') {
        if (!error.response) {
          dispatch({
            payload: { message: localization.data.userNotTeacher },
            type: AUTH.LOGIN_ERROR,
          })
        } else {
          const errorMessage: string = generateErrorMessageFromeErrorCode(
            error.response.data.code,
            localization
          )
          dispatch({
            payload: { message: errorMessage },
            type: AUTH.LOGIN_ERROR,
          })
        }
      } else {
        dispatch({
          payload: { message: localization.data.networkError },
          type: AUTH.LOGIN_ERROR,
        })
      }
    }
  }
}

export function getMe(history: any, localizationActions?: ILocalizationActions) {
  return async (dispatch: IDispatch<any>) => {
    await getMeData(dispatch, history, localizationActions)
  }
}

export async function getMeData(
  dispatch: IDispatch<any>,
  history: any,
  localizationActions?: ILocalizationActions
) {
  try {
    dispatch({ type: AUTH.GET_ME_REQUEST })
    const res = await api.auth.getMe({
      fetchCountry: 1,
    })
    setItemToLocalStorage(localStorageKeys.me, JSON.stringify(res))
    if (history.location.pathname === PATHS.LOGIN.LOGIN_MAIN) {
      history.push('/')
    }
    dispatch({ type: AUTH.GET_ME_SUCCESS, payload: res })
    if (localizationActions) {
      setLanguage(localizationActions)
    }
  } catch (error) {
    if (navigator.onLine && error.message !== 'Network Error') {
      localStorage.removeItem(localStorageKeys.token)
      localStorage.removeItem(localStorageKeys.me)
      window.location.href = `${config.LOGIN_PAGE_INTERNATIONAL}${PATHS.LOGIN.SIGN_IN}`
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: AUTH.GET_ME_ERROR,
      })
    }
  }
}

export function getTokenFromCallback(code: any, history: any, localization: any) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: AUTH.SIGNUP_SKOLON_REQUEST })
      const res = await api.auth.loginWithSkolon({ code })
      if (res && +res.type !== User.teacher) {
        throw new Error(localization.data.userNotFound)
      }
      dispatch({ type: AUTH.SIGNUP_SKOLON_SUCCESS })
      setItemToLocalStorage(localStorageKeys.token, res.token)
      setTokenForSocket(res.token)
      addToken(res.token)
      await getMeData(dispatch, history)
      history.push('/')
    } catch (error) {
      dispatch({
        payload: !!error.response
          ? { message: error.response.data.message }
          : { message: error.message },
        type: AUTH.SIGNUP_SKOLON_ERROR,
      })
    }
  }
}

export interface IAuthActions {
  logout: (loginAppURl: string) => void
  login: (
    data: { password: string; username: string },
    history: any,
    localization: ILocalization
  ) => void
  getMe: (history: any, localizationActions?: ILocalizationActions) => void
  getTokenFromCallback: (code: any, history: any, localization: any) => void
}

export interface IAuth {
  authenticated: boolean
  available?: boolean
  me: IMe
  type: number
}
