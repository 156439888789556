import React from 'react'
import styles from '../../../screens/Students/Students.module.scss'
import { isEmpty } from 'lodash'
import StudentsScreenTab from '../../tabs/StudentsScreenTab/StudentsScreenTab'
import MultiplicityTableContainer from '../../tables/MultiplicityTableContainer/MultiplicityTableContainer'
import NoContentUI from '../../../ui/NoContentUI/NoContentUI'
import StudentsTableUIHeader from '../../tables/StudentsTable/StudentsTableRow/StudentsTableUIHeader/StudentsTableUIHeader'
import AddYourFirstStudent from '../../NoContent/AddYourFirstStudent/AddYourFirstStudent'
import { StudentsTab } from '../../../screens/Students/Students'
import { ISelectedOption } from '../../../redux/modules/filterExercises.module'
import { RouteComponentProps } from 'react-router'
import { IMe, ITeacher } from '../../../api/types.users'
import { IModalActions } from '../../../redux/modules/modal.module'
import { ILocalization } from '../../../config/languages.config'
import Spinner from '../../Spinner/Spinner'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IStudentsScreenContainerProps {
  showCreateClassComponent: boolean
  classOrGroup: IClass | IGroup | null
  selectedOption: ISelectedOption
  activeTab: StudentsTab | null
  history: RouteComponentProps['history']
  loading: boolean
  me: IMe
  modalActions: IModalActions
  localization: ILocalization
  teachers: ITeacher[]
  showPickAClass: boolean
  showAddStudentBtn: () => boolean
}

const StudentsScreenContainer: React.FC<IStudentsScreenContainerProps> = ({
  showCreateClassComponent,
  classOrGroup,
  selectedOption,
  activeTab,
  history,
  loading,
  me,
  modalActions,
  localization,
  teachers,
  showPickAClass,
  showAddStudentBtn,
}) => {
  const isAddStudentBtnVisible = showAddStudentBtn()
  const isEmptyClassOrGroupStudentsTab =
    !classOrGroup?.students?.length && activeTab === StudentsTab.MULTIPLICITY
  if (showCreateClassComponent) return null

  return (
    <div className={styles.Container}>
      <>
        {classOrGroup && !isEmpty(classOrGroup?.students && selectedOption) && (
          <StudentsScreenTab activeTab={activeTab} classOrGroup={classOrGroup} />
        )}
      </>
      {!!(!loading && me) && (
        <>
          <MultiplicityTableContainer
            classOrGroup={classOrGroup}
            activeTab={activeTab}
            modalActions={modalActions}
            me={me}
            history={history}
            localization={localization}
            teachers={teachers}
            selectedOption={selectedOption}
          />
          {showPickAClass && (
            <div className={styles.NoContentWrapper}>
              <NoContentUI title={localization.data.pickAClass} />
            </div>
          )}

          {classOrGroup && isEmptyClassOrGroupStudentsTab && isAddStudentBtnVisible && (
            <div className={styles.NoStudents}>
              <div className={styles.NoContentHeader}>
                <StudentsTableUIHeader
                  classOrGroup={classOrGroup}
                  localization={localization}
                  municipality={me.municipality}
                />
              </div>
              <AddYourFirstStudent
                localization={localization}
                classOrGroup={classOrGroup}
                modalActions={modalActions}
                me={me}
              />
            </div>
          )}
        </>
      )}
      {loading && <Spinner className={styles.Loader} />}
    </div>
  )
}

export default StudentsScreenContainer
