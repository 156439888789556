import ContentEditableArea from '../../../../ui/inputs/ContentEditableArea/ContentEditableArea'
import katex from 'katex'
import styles from '../ProblemCreatorSideBar.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

const AlgebraAnswerPreview = () => {
  const answerAlgebra = useSelector((state: RootState) => state.problemCreatorData.answerAlgebra)
  return (
    <ContentEditableArea
      value={katex.renderToString(answerAlgebra)}
      disabled
      className={styles.ContentEditableExtraClass}
    />
  )
}

export default AlgebraAnswerPreview
