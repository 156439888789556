import { Checkbox, CheckboxProps } from '@mui/material'
import React from 'react'

import CheckBoxCheckedIcon from 'ui/icons/CheckBoxCheckedIcon/CheckBoxCheckedIcon'
import CheckBoxIcon from 'ui/icons/CheckBoxIcon/CheckBoxIcon'
import { COLORS } from '@magmamath/ui'

const DEFAULT_STYLES = {
  color: COLORS.NEUTRAL_9,
  '&.Mui-checked': {
    color: COLORS.NEUTRAL_10,
  },
}

const IconCheckbox = ({
  icon = <CheckBoxIcon size={16} color={COLORS.NEUTRAL_9} />,
  checkedIcon = (
    <CheckBoxCheckedIcon size={16} color={COLORS.NEUTRAL_9} extraColor={COLORS.NEUTRAL_10} />
  ),
  sx = { DEFAULT_STYLES },
  ...rest
}: CheckboxProps) => {
  return <Checkbox sx={sx} icon={icon} checkedIcon={checkedIcon} {...rest} />
}

export default IconCheckbox
