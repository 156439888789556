import { ContentBooks } from 'helpers/enums'
import { IMe } from 'api/types.users'
import React from 'react'
import { IMyBooksActions } from '../../../../redux/modules/my-books'
import './BookSeriesCard.component.scss'
import DefaultBookIcon from '../../../../assets/img/default_book.png'
import { checkLongWord } from '../../../../helpers/contentTab.helpers'
import { ILocalization } from '../../../../config/languages.config'
import styles from './BookSeriesCard.module.scss'
import { Button, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import MinusIcon from '../../../../ui/icons/MinusIcon/MinusIcon'
import PlusIcon from '../../../../ui/icons/PlusIcon/PlusIcon'
import { IBook, IMyBook } from '../../../../api/types.books'
import { api } from '../../../../api'

export interface IBookSeriesCardProps {
  localization: ILocalization
  books: IBook[] | IMyBook[]
  myBookActions: IMyBooksActions
  me: IMe
  bookType: ContentBooks
  loading: boolean
}

export default class BookSeriesCard extends React.Component<IBookSeriesCardProps, any, any> {
  constructor(props: IBookSeriesCardProps) {
    super(props)
  }

  public onBookButtonClick = (localization: ILocalization, id: string) => {
    if (this.props.bookType === ContentBooks.addBook) {
      this.props.myBookActions.addBook(localization, id)
      return
    }

    this.props.myBookActions.removeBook(localization, id)
  }

  public renderBookCards = () => {
    const { localization } = this.props

    return (
      <>
        {this.props.books.map((book) => {
          return (
            <div
              className={`book-card ${
                this.props.bookType === ContentBooks.myBook ? 'my-book' : ''
              } ${this.props.loading ? 'loading' : ''}`}
              key={book._id}
            >
              <div className='book-icon'>
                <img src={book.image ? api.images.url(book.image) : DefaultBookIcon} alt='book' />
              </div>
              <div className='book-name'>
                <span
                  className={`name ${book.name && checkLongWord(book.name) ? 'long-word' : ''}`}
                >
                  {book.name}
                </span>
              </div>
              {this.props.bookType === ContentBooks.myBook ? (
                <div className={styles.RemoveButtonWrapper}>
                  {!book.isInitial && (
                    <Button
                      classes={{ button: styles.RemoveButton }}
                      size={SIZE.SMALL}
                      variant={BUTTON_VARIANT.TERTIARY}
                      onClick={() => this.onBookButtonClick(localization, book._id)}
                      icon={<MinusIcon color='var(--icon-color)' size={20} />}
                      tooltipText={localization.data.removeFromListTxt}
                      tooltipPosition={TOOLTIP_POSITION.LEFT}
                    />
                  )}
                </div>
              ) : (
                <Button
                  size={SIZE.SMALL}
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={() => this.onBookButtonClick(localization, book._id)}
                  icon={
                    <PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={12} />
                  }
                  iconAlign={ICON_ALIGNMENT.RIGHT}
                >
                  {localization.data.add}
                </Button>
              )}
            </div>
          )
        })}
      </>
    )
  }

  public render() {
    return this.renderBookCards()
  }
}
