import { Directions } from 'helpers/enums'

export const handleVerticalScroll = (
  container: HTMLElement | null,
  lastScroll: number,
  directionDown: boolean,
  changeDirection: (value: Directions) => void,
  checkElementVisibility: () => void
): void => {
  const scrollTop: number = container?.scrollTop || document.body.scrollTop
  if (scrollTop > lastScroll && !directionDown) {
    // scroll down
    changeDirection(Directions.DOWN)
    directionDown = true
  } else {
    // scroll up
    changeDirection(Directions.UP)
    directionDown = false
  }
  lastScroll = scrollTop
  checkElementVisibility()
}
