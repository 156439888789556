import { config } from 'config'
import { LocaleData, StudentListMail } from 'helpers/enums'
import React from 'react'

import { ILocalization } from '../../config/languages.config'
import { currentLocale } from 'config/modules.config'

type EmailClasslistLinkProps = {
  localization: ILocalization
  externalClassName?: string
  children: React.ReactNode
}

const linkMap: Record<string, StudentListMail> = {
  [LocaleData.SE]: StudentListMail.SE,
  [LocaleData.US]: StudentListMail.US,
  [LocaleData.GB]: StudentListMail.GB,
}

const EmailClasslistLink = ({
  localization,
  externalClassName,
  children,
}: EmailClasslistLinkProps) => {
  const { hiTxt, findAttachedStudentLists, thankYouTxt, klasslistaSubject } = localization.data

  const textForEmailBody = `${hiTxt}!%0A${findAttachedStudentLists}.%0A${thankYouTxt}!`

  return (
    <a
      className={externalClassName ?? ''}
      href={`mailto:${
        linkMap[currentLocale.currentLang] || linkMap[LocaleData.US]
      }?subject=${klasslistaSubject}&body=${textForEmailBody}`}
    >
      {children}
    </a>
  )
}

export default EmailClasslistLink
