import React from 'react'
import ProblemDescriptionBlockHeader from './ProblemDescriptionBlockHeader'
import ProblemDescriptionEditor from './ProblemDescriptionEditor'
import CreatingProblemCard from '../common/CreatingProblemCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { MathEditorModel } from '../../mathEditor/model/MathEditorModel'
import { MathInputVariant } from '../../mathEditor/mathInput/variants'
import { useConst } from '../../../lib/hooks/const'
import { MathInputBlurBehaviour } from '../../mathEditor/mathInput/MathInputModel'

type ProblemDescriptionBlockProps = {
  className?: string
}

const ProblemDescriptionBlock = ({ className }: ProblemDescriptionBlockProps) => {
  const errorQuestionField = useSelector(
    (state: RootState) => state.problemCreatorData.errorQuestionField
  )
  const mathEditorModel = useConst(
    () =>
      new MathEditorModel({
        inputVariant: MathInputVariant.POP_UP,
        inputBlurBehaviour: MathInputBlurBehaviour.NONE,
      })
  )

  return (
    <CreatingProblemCard className={className} errorMessage={errorQuestionField}>
      <ProblemDescriptionBlockHeader
        onInsertFormulaPress={() => {
          mathEditorModel.startFormulaEditor('')
        }}
      />
      <ProblemDescriptionEditor mathEditorModel={mathEditorModel} />
    </CreatingProblemCard>
  )
}

export default ProblemDescriptionBlock
