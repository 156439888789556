import { DeleteHelpQueueParams, FetchSingleHelpQueueParams } from './types'
import { createEffect, createEvent, createStore } from 'effector'
import { GetHelpQueueResponse, StudentInQueue } from '../../api/types.helpQueue'
import { api } from '../../api'
import { GetHelpQueuePayload } from '../../api/api.helpQueue'

const defaultState: GetHelpQueueResponse = {
  items: [],
  limit: 20,
  offset: 0,
  total: 0,
}

export const fetchHelpQueueFx = createEffect(({ offset, classroomId }: GetHelpQueuePayload) => {
  const params: GetHelpQueuePayload = { offset }
  if (classroomId) params.classroomId = classroomId
  return api.helpQueue.getAll(params)
})
export const fetchSingleHelpQueueFx = createEffect((params: FetchSingleHelpQueueParams) =>
  api.helpQueue.getSingle(params.id)
)

export const deleteHelpQueueFx = createEffect(
  async ({ id, isSocketEvent = false }: DeleteHelpQueueParams) => {
    if (!isSocketEvent) await api.helpQueue.deleteSingle(id)
    return { id }
  }
)

export const clearHelpQueueData = createEvent()

export const $helpQueueData = createStore<GetHelpQueueResponse>(defaultState)
  .on(
    fetchHelpQueueFx.doneData,
    (state: GetHelpQueueResponse, fetchedData: GetHelpQueueResponse) => {
      const existingIds = new Set(state.items.map((studentInQueue) => studentInQueue._id))
      const uniqueFetchedItems = fetchedData.items.filter(
        (studentInQueue) => !existingIds.has(studentInQueue._id)
      )
      return { ...fetchedData, items: [...state.items, ...uniqueFetchedItems] }
    }
  )
  .on(
    fetchSingleHelpQueueFx.doneData,
    (state: GetHelpQueueResponse, fetchedData: StudentInQueue) => {
      return { ...state, items: [...state.items, fetchedData], total: state.total + 1 }
    }
  )
  .on(
    deleteHelpQueueFx.doneData,
    (state: GetHelpQueueResponse, deleteHelpQueue: { id: string }) => {
      const isItemInQueue = state.items.some(
        (studentInQueue) => studentInQueue._id === deleteHelpQueue.id
      )
      if (!isItemInQueue) return state
      const res = state.items.filter((studentInQueue) => studentInQueue._id !== deleteHelpQueue.id)
      return { ...state, items: res, total: state.total - 1 }
    }
  )
  .reset(clearHelpQueueData)
