import React from 'react'
import { SvgIconProps } from '../types/types'

const PlayIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <path
          id='Vector'
          d='M16.403 7.74941V7.74564H16.406L16.4039 7.74988M16.403 7.74941L2.10514 0.607609C2.10512 0.607596 2.10509 0.607584 2.10506 0.607571C1.91308 0.518711 1.70193 0.479262 1.49079 0.492754C1.27962 0.506248 1.0752 0.572305 0.896068 0.684886C0.716938 0.797466 0.568906 0.953038 0.465323 1.1374L0.465322 1.1374C0.361747 1.32168 0.30599 1.52901 0.303078 1.74035V16.0213V16.0214H0.303078C0.301271 16.2384 0.355535 16.4521 0.460607 16.642C0.56568 16.8318 0.718027 16.9915 0.902889 17.1055C1.08775 17.2193 1.29892 17.2836 1.51597 17.2921L1.51573 17.2983L1.51597 17.2921C1.73303 17.3007 1.94859 17.2531 2.14186 17.1542L2.14191 17.1542L16.4064 10.0106C16.6167 9.90511 16.7934 9.74334 16.9171 9.54329C17.0406 9.34323 17.106 9.11282 17.106 8.87778C17.106 8.64273 17.0406 8.41232 16.9171 8.21226C16.7941 8.01311 16.6183 7.8519 16.4092 7.74642V7.7518H16.4091V7.74639C16.409 7.74635 16.409 7.74631 16.4089 7.74627L16.4064 7.75115L16.4039 7.74988M16.403 7.74941V7.75178L16.4039 7.74988M16.403 7.74941L16.4039 7.74988'
          fill={color}
          stroke={color}
          strokeWidth='0.0123178'
        />
      </g>
    </svg>
  )
}

export default PlayIcon
