import { Listbox } from '@magmamath/ui'
import { ResponseCode, SelectedValueTypes, TypesOfParams } from 'helpers/enums'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IQueryParams } from 'interfaces/params.interface'
import _ from 'lodash'
import { NOTIFY } from 'redux/modules/notify.module'
import { ILocalization } from '../config/languages.config'

export function handleNetworkError(typeOfAction: any, error: any, dispatch: IDispatch<any>): void {
  dispatch({
    payload: error.message,
    type: typeOfAction,
  })
  dispatch({ type: NOTIFY.NOTIFY_BEGIN, payload: { message: error.message, type: 'danger' } })
  dispatch({ type: NOTIFY.NOTIFY_END })
}

export function checkNetworkError(
  error: any,
  handleErrorByDefault: () => void,
  handleErrorWithNetwork: () => void
): void {
  if (navigator.onLine && error.message !== 'Network Error') {
    handleErrorByDefault()
  } else {
    handleErrorWithNetwork()
  }
}

export function generateErrorMessageFromeErrorCode(
  code: ResponseCode,
  localization: ILocalization
): string {
  const mapOfMessages: { [key in ResponseCode]: string } = {
    [ResponseCode.notFound]: localization.data.teacherNotFound,
    [ResponseCode.invalidUsernameOrPassword]: localization.data.invalidUsernameOrPassword,
  }
  return mapOfMessages[code]
}

export const getExercisesParam = (params: IQueryParams): IQueryParams => {
  const paramsMap: {} = {}
  if (params.statusExercise) {
    paramsMap[TypesOfParams.STATUS] = params.statusExercise
  }
  if (params.pageNumber) {
    paramsMap[TypesOfParams.PAGE] = params.pageNumber
  }
  if (params.classes && params.classes[0] !== Listbox.ALL_OPTION) {
    paramsMap[TypesOfParams.CLASSES] = params.classes![0]
    if (params.allClassroomStudents) {
      paramsMap[TypesOfParams.ALL_CLASSROOM_STUDENTS] = params.allClassroomStudents
    }
  }
  if (!_.isEmpty(params.groups)) {
    paramsMap[TypesOfParams.GROUPS] = params.groups![0]
  }
  if (!_.isEmpty(params.topics)) {
    paramsMap[TypesOfParams.TOPICS] = params.topics![0]
  }
  if (params.exam) {
    paramsMap[TypesOfParams.EXAM] = +params.exam
  }
  if (params.sortBy) {
    paramsMap[TypesOfParams.SORT_BY] = params.sortBy
  }
  if (params.order) {
    paramsMap[TypesOfParams.ORDER] = params.order
  }
  if (!_.isEmpty(params.students)) {
    paramsMap[TypesOfParams.STUDENT] = params.students![0]
  }
  if (params.startDate) {
    paramsMap[TypesOfParams.START_DATE] = params.startDate
  }
  if (params.endDate) {
    paramsMap[TypesOfParams.END_DATE] = params.endDate
  }
  return paramsMap
}

export const getClassOrGroupParam = (
  type: SelectedValueTypes,
  paramValue: string
): { classId?: string; groupId?: string } => {
  const paramsMap: { [key in SelectedValueTypes]?: string } = {
    [SelectedValueTypes.CLASS]: 'classId',
    [SelectedValueTypes.GROUP]: 'groupId',
  }
  const paramName: string = paramsMap[type] || ''
  return { [paramName]: paramValue }
}

export function returnStatus(statusExercise: string): { status: string } | undefined {
  return statusExercise ? { [TypesOfParams.STATUS]: statusExercise } : undefined
}

export function returnPage(pageNumber: number): { page: number } | undefined {
  return pageNumber ? { [TypesOfParams.PAGE]: pageNumber } : undefined
}

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
