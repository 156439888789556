export type UrlSearchParams = Record<string, number | string | null | undefined>

export const buildUrl = ({
  baseUrl,
  searchParams,
}: {
  baseUrl: string
  searchParams?: UrlSearchParams
}) => {
  if (!searchParams) return baseUrl

  const notNullishParams = Object.entries(searchParams).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value.toString()
      }
      return acc
    },
    {}
  )
  return `${baseUrl}?${new URLSearchParams(notNullishParams)}`
}
