import React, { useEffect, useState } from 'react'
import { DrawBoard, Preset } from '@magmamath/react-native-draw-board'
import styles from './TheoryDrawBoard.module.scss'
import PlayController from './PlayController/PlayController'

type TheoryDrawBoardProps = {
  preset: Preset
}

const TheoryDrawBoard = ({ preset }: TheoryDrawBoardProps) => {
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    preset.model.historyActions.isPlaying.watch((state) => setIsPlaying(state))
  }, [preset])

  const onPlay = () => {
    preset.model.historyActions.togglePlaying()
  }

  const onUndo = () => {
    preset.model.historyActions.stepBack()
  }
  const onRedo = () => {
    preset.model.historyActions.stepForward()
  }

  return (
    <div className={styles.DrawBoardContainer}>
      <DrawBoard preset={preset} transformDisabled disabled hideToolbar fitToDrawing />
      <PlayController
        isPlaying={isPlaying}
        onUndoClick={onUndo}
        onRedoClick={onRedo}
        onPlayClick={onPlay}
      />
    </div>
  )
}

export default TheoryDrawBoard
