import { ExerciseSettings } from 'features/caf/exerciseSettings/types'
import { unionDateAndTime } from 'lib/date/helpers'
import { ExerciseStatuses } from '../../../../api/types.assignments'

export const getStartAndEndDates = ({
  exerciseStatus,
  settings,
  isDuplicateExercise,
}: {
  exerciseStatus: ExerciseStatuses
  settings: ExerciseSettings
  isDuplicateExercise?: boolean
}) => {
  if (isDuplicateExercise && exerciseStatus !== ExerciseStatuses.Schedule) {
    return { startDate: undefined, endDate: undefined }
  }

  const { startDate, scheduleDate, scheduledTime, scheduledArchiveTime, scheduleArchiveDate } =
    settings

  const sanitizedStartDate =
    startDate && typeof startDate !== 'string' ? startDate.toISOString() : startDate

  const exerciseStatusMap = {
    [ExerciseStatuses.Unpublished]: { startDate: null, endDate: null },
    [ExerciseStatuses.PublishNow]: { startDate: sanitizedStartDate, endDate: null },
    [ExerciseStatuses.Schedule]: {
      startDate: unionDateAndTime(scheduleDate, scheduledTime).toISOString(),
      endDate: unionDateAndTime(scheduleArchiveDate, scheduledArchiveTime).toISOString(),
    },
  }

  return exerciseStatusMap[exerciseStatus]
}
