import axios from 'axios'
import { localStorageKeys } from 'config/localStorageKeys'
import { HeadersType } from 'helpers/enums'
import { getItemFromLocalStorage } from 'helpers/storage.helper'
import { LOCALIZE } from 'redux/modules/localization.module'
import { api } from '../api'

const localeHeaders = {
  'en-SE': 'en-SE;q=1.0, sv-SE;q=0.9',
  'en-GB': 'en-SE;q=1.0, sv-SE;q=0.9',
  'sv-SE': 'sv-SE;q=1.0, en-SE;q=0.9',
}

const LanguageHeaders: Record<LOCALIZE, string> = {
  [LOCALIZE.SE]: 'sv-SE',
  [LOCALIZE.US]: 'en-SE',
  [LOCALIZE.GB]: 'en-SE',
}

export const currentLocale = {
  currentLang: '',
}

export function initLocale() {
  const { searchParams } = new URL(window.location.href)

  const locale = searchParams.get('locale')

  if (locale) {
    currentLocale.currentLang = locale
  }
}

const defaultLocaleHeader: string = 'en-SE'

const getLocaleFromLocalStorage = () => {
  const localStorageLang = localStorage.getItem('lang') as LOCALIZE
  return LanguageHeaders[localStorageLang] || defaultLocaleHeader
}

export const getCurrentLocale = () => {
  const currentLang = currentLocale.currentLang || getLocaleFromLocalStorage()
  return currentLang
}

//TODO should be moved to apiManager file
api.manager.setRequestMiddleware((config) => {
  const currentLocale = getCurrentLocale()
  if (!config.headers) config.headers = {}
  config.headers[HeadersType.AcceptLanguage] =
    localeHeaders[currentLocale] || localeHeaders['en-SE']
  return config
})

export function addLocale(): void {
  //TODO: can remove later
  const locale = getCurrentLocale()
  axios.defaults.headers.common[HeadersType.AcceptLanguage] =
    localeHeaders[locale] || localeHeaders['en-SE']
}

export function addToken(sendedToken?: string) {
  const token: string | null = sendedToken
    ? sendedToken
    : getItemFromLocalStorage(localStorageKeys.token)
  axios.defaults.headers.common.Authorization = token || null
  //TODO: temp solution
  api.manager.setToken(
    token
      ? {
          access: token,
          refresh: '',
          startTime: Date.now(),
        }
      : null
  )
}