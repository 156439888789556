import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'

import SpeakerIcon from 'ui/icons/SpeakerIcon/SpeakerIcon'
import { setTextToSpeechForWholeClass } from '../../../redux/modules/class.module'
import { openAllPasswordsModal } from '../../../redux/modules/modal.module'
import ChangeMultiplicityTableUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTable'
import ChangeMultiplicityTableRowUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTableUIRow/ChangeMultiplicityTableRowUI'
import styles from '../ChangeMultiplicityAccess/ChangeMultiplicityAccess.module.scss'
import QrCodeMultiplicityAccess from '../QrCodeMultiplicityAccess/QrCodeMultiplicityAccess'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IChangeMultiplicitySettingsProps {
  classOrGroup: IClass | IGroup
}

const ChangeMultiplicitySettings: React.FC<IChangeMultiplicitySettingsProps> = ({
  classOrGroup,
}) => {
  const dispatch = useDispatch()
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data
  const iShowForClassesTab = 'invitationalCode' in classOrGroup

  if (!iShowForClassesTab) return null

  return (
    <ChangeMultiplicityTableUI className={styles.SpaceTop}>
      <ChangeMultiplicityTableRowUI title={t.resetStudentsPasswordsTxt}>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.MEDIUM}
          onClick={() => {
            dispatch(openAllPasswordsModal(classOrGroup))
          }}
        >
          {t.resetPasswordsTxt}
        </Button>
      </ChangeMultiplicityTableRowUI>
      <ChangeMultiplicityTableRowUI title={t.readingTxt}>
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.MEDIUM}
          onClick={() => {
            dispatch(
              setTextToSpeechForWholeClass({
                classId: classOrGroup._id,
                textToSpeechStatus: true,
                localization,
              })
            )
          }}
          icon={<SpeakerIcon size={20} color='var(--icon-color)' />}
        >
          {t.enableForAllTxt}
        </Button>
      </ChangeMultiplicityTableRowUI>
      <QrCodeMultiplicityAccess classOrGroup={classOrGroup} />
    </ChangeMultiplicityTableUI>
  )
}

export default ChangeMultiplicitySettings
