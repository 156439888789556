const AFRIKAANS = 'Afrikaans'
const ALBANIAN = 'Albanian'
const AMHARIC = 'Amharic'
const ARABIC = 'Arabic'
const ARMENIAN = 'Armenian'
const ASSAMESE = 'Assamese'
const AYMARA = 'Aymara'
const AZERBAIJANI = 'Azerbaijani'
const BAMBARA = 'Bambara'
const BASQUE = 'Basque'
const BELARUSIAN = 'Belarusian'
const BENGALI = 'Bengali'
const BHOJPURI = 'Bhojpuri'
const BOSNIAN = 'Bosnian'
const BULGARIAN = 'Bulgarian'
const CATALAN = 'Catalan'
const CEBUANO = 'Cebuano'
const CHINESE_SIMPLIFIED = 'Chinese (Simplified)'
const CHINESE_TRADITIONAL = 'Chinese (Traditional)'
const CORSICAN = 'Corsican'
const CROATIAN = 'Croatian'
const CZECH = 'Czech'
const DANISH = 'Danish'
const DHIVEHI = 'Dhivehi'
const DOGRI = 'Dogri'
const DUTCH = 'Dutch'
export const ENGLISH = 'English'
const ESPERANTO = 'Esperanto'
const ESTONIAN = 'Estonian'
const EWE = 'Ewe'
const FINNISH = 'Finnish'
const FRENCH = 'French'
const FRISIAN = 'Frisian'
const GALICIAN = 'Galician'
const GEORGIAN = 'Georgian'
const GERMAN = 'German'
const GREEK = 'Greek'
const GUARANI = 'Guarani'
const GUJARATI = 'Gujarati'
const HAITIAN_CREOLE = 'Haitian Creole'
const HAUSA = 'Hausa'
const HAWAIIAN = 'Hawaiian'
const HEBREW = 'Hebrew'
const HINDI = 'Hindi'
const HMONG = 'Hmong'
const HUNGARIAN = 'Hungarian'
const ICELANDIC = 'Icelandic'
const IGBO = 'Igbo'
const ILOCANO = 'Ilocano'
const INDONESIAN = 'Indonesian'
const IRISH = 'Irish'
const ITALIAN = 'Italian'
const JAPANESE = 'Japanese'
const JAVANESE = 'Javanese'
const KANNADA = 'Kannada'
const KAZAKH = 'Kazakh'
const KHMER = 'Khmer'
const KINYARWANDA = 'Kinyarwanda'
const KONKANI = 'Konkani'
const KOREAN = 'Korean'
const KRIO = 'Krio'
const KURDISH = 'Kurdish'
const KURDISH_SORANI = 'Kurdish (Sorani)'
const KYRGYZ = 'Kyrgyz'
const LAO = 'Lao'
const LATVIAN = 'Latvian'
const LINGALA = 'Lingala'
const LITHUANIAN = 'Lithuanian'
const LUGANDA = 'Luganda'
const LUXEMBOURGISH = 'Luxembourgish'
const MACEDONIAN = 'Macedonian'
const MAITHILI = 'Maithili'
const MALAGASY = 'Malagasy'
const MALAY = 'Malay'
const MALAYALAM = 'Malayalam'
const MALTESE = 'Maltese'
const MAORI = 'Maori'
const MARATHI = 'Marathi'
const MEITEILON_MANIPURI = 'Meiteilon (Manipuri)'
const MIZO = 'Mizo'
const MONGOLIAN = 'Mongolian'
const MYANMAR_BURMESE = 'Myanmar (Burmese)'
const NEPALI = 'Nepali'
const NORWEGIAN = 'Norwegian'
const NYANJA_CHICHEWA = 'Nyanja (Chichewa)'
const ODIA_ORIYA = 'Odia (Oriya)'
const OROMO = 'Oromo'
const PASHTO = 'Pashto'
const PERSIAN = 'Persian'
const POLISH = 'Polish'
const PORTUGUESE_PORTUGAL_BRAZIL = 'Portuguese (Portugal, Brazil)'
const PUNJABI = 'Punjabi'
const QUECHUA = 'Quechua'
const ROMANIAN = 'Romanian'
const RUSSIAN = 'Russian'
const SAMOAN = 'Samoan'
const SANSKRIT = 'Sanskrit'
const SCOTS_GAELIC = 'Scots Gaelic'
const SEPEDI = 'Sepedi'
const SERBIAN = 'Serbian'
const SESOTHO = 'Sesotho'
const SHONA = 'Shona'
const SINDHI = 'Sindhi'
const SINHALA_SINHALESE = 'Sinhala (Sinhalese)'
const SLOVAK = 'Slovak'
const SLOVENIAN = 'Slovenian'
const SOMALI = 'Somali'
const SPANISH = 'Spanish'
const SUNDANESE = 'Sundanese'
const SWAHILI = 'Swahili'
export const SWEDISH = 'Swedish'
const TAGALOG_FILIPINO = 'Tagalog (Filipino)'
const TAJIK = 'Tajik'
const TAMIL = 'Tamil'
const TATAR = 'Tatar'
const TELUGU = 'Telugu'
const THAI = 'Thai'
const TIGRINYA = 'Tigrinya'
const TSONGA = 'Tsonga'
const TURKISH = 'Turkish'
const TURKMEN = 'Turkmen'
const TWI_AKAN = 'Twi (Akan)'
const UKRAINIAN = 'Ukrainian'
const URDU = 'Urdu'
const UYGHUR = 'Uyghur'
const UZBEK = 'Uzbek'
const VIETNAMESE = 'Vietnamese'
const WELSH = 'Welsh'
const XHOSA = 'Xhosa'
const YIDDISH = 'Yiddish'
const YORUBA = 'Yoruba'
const ZULU = 'Zulu'

export const nativeLanguages = [
  AFRIKAANS,
  ALBANIAN,
  AMHARIC,
  ARABIC,
  ARMENIAN,
  ASSAMESE,
  AYMARA,
  AZERBAIJANI,
  BAMBARA,
  BASQUE,
  BELARUSIAN,
  BENGALI,
  BHOJPURI,
  BOSNIAN,
  BULGARIAN,
  CATALAN,
  CEBUANO,
  CHINESE_SIMPLIFIED,
  CHINESE_TRADITIONAL,
  CORSICAN,
  CROATIAN,
  CZECH,
  DANISH,
  DHIVEHI,
  DOGRI,
  DUTCH,
  ENGLISH,
  ESPERANTO,
  ESTONIAN,
  EWE,
  FINNISH,
  FRENCH,
  FRISIAN,
  GALICIAN,
  GEORGIAN,
  GERMAN,
  GREEK,
  GUARANI,
  GUJARATI,
  HAITIAN_CREOLE,
  HAUSA,
  HAWAIIAN,
  HEBREW,
  HINDI,
  HMONG,
  HUNGARIAN,
  ICELANDIC,
  IGBO,
  ILOCANO,
  INDONESIAN,
  IRISH,
  ITALIAN,
  JAPANESE,
  JAVANESE,
  KANNADA,
  KAZAKH,
  KHMER,
  KINYARWANDA,
  KONKANI,
  KOREAN,
  KRIO,
  KURDISH,
  KURDISH_SORANI,
  KYRGYZ,
  LAO,
  LATVIAN,
  LINGALA,
  LITHUANIAN,
  LUGANDA,
  LUXEMBOURGISH,
  MACEDONIAN,
  MAITHILI,
  MALAGASY,
  MALAY,
  MALAYALAM,
  MALTESE,
  MAORI,
  MARATHI,
  MEITEILON_MANIPURI,
  MIZO,
  MONGOLIAN,
  MYANMAR_BURMESE,
  NEPALI,
  NORWEGIAN,
  NYANJA_CHICHEWA,
  ODIA_ORIYA,
  OROMO,
  PASHTO,
  PERSIAN,
  POLISH,
  PORTUGUESE_PORTUGAL_BRAZIL,
  PUNJABI,
  QUECHUA,
  ROMANIAN,
  RUSSIAN,
  SAMOAN,
  SANSKRIT,
  SCOTS_GAELIC,
  SEPEDI,
  SERBIAN,
  SESOTHO,
  SHONA,
  SINDHI,
  SINHALA_SINHALESE,
  SLOVAK,
  SLOVENIAN,
  SOMALI,
  SPANISH,
  SUNDANESE,
  SWAHILI,
  SWEDISH,
  TAGALOG_FILIPINO,
  TAJIK,
  TAMIL,
  TATAR,
  TELUGU,
  THAI,
  TIGRINYA,
  TSONGA,
  TURKISH,
  TURKMEN,
  TWI_AKAN,
  UKRAINIAN,
  URDU,
  UYGHUR,
  UZBEK,
  VIETNAMESE,
  WELSH,
  XHOSA,
  YIDDISH,
  YORUBA,
  ZULU,
]
