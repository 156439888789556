import { BUTTON_COLOR, COLORS } from '@magmamath/ui'
import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'
import { TaskType } from '../../../../helpers/enums'
import useText from '../../../../i18n/hook'
import { IAnswerInfo, IStudentSolutionStats } from '../../../../api/types.solutions'
import { useOverflowDetection } from '../../../../lib/hooks/useOverflowDetection'
import { changeSolutionCorrectness } from '../../../../redux/modules/correctness.module'
import { createDefaultAnswer } from '../../../../redux/modules/defaultAnswer.module'
import EyeIcon from '../../../../ui/icons/EyeIcon/EyeIcon'
import UITooltip from '../../../../ui/tooltips/UITooltip/Tooltip'
import SolutionCardActions from '../../SolutionCardActions/SolutionCardActions'
import styles from '../SmallSolutionCard.module.scss'
import { getHeaderColor, getSolutionCardInfo } from '../helpers'

type SmallSolutionCardHeaderProps = {
  solution: IStudentSolutionStats
  title: string
  exerciseId: string
  problemId: string
  subProblemId?: string
  studentId: string
  correctAnswer: string[]
  headerColor?: BUTTON_COLOR.BLUE | BUTTON_COLOR.PURPLE
  selectedAttemptAnswerInfo: IAnswerInfo | null
  isUserDeleted: boolean
}

const SmallSolutionCardHeader = ({
  solution,
  headerColor,
  correctAnswer,
  studentId,
  isUserDeleted,
  subProblemId,
  problemId,
  title,
  exerciseId,
  selectedAttemptAnswerInfo,
}: SmallSolutionCardHeaderProps) => {
  const t = useText()
  const nameOverflow = useOverflowDetection()
  const dispatch = useDispatch()

  const { isCorrect, currentAttemptSolutionId, pinSolutionId } = getSolutionCardInfo({
    solution,
    selectedAttemptAnswerInfo,
  })

  const changeCorrectnessOfSolution = () => {
    if (!currentAttemptSolutionId) {
      createDefaultAnswer(
        exerciseId,
        studentId,
        subProblemId ? TaskType.SubProblem : TaskType.Problem,
        subProblemId || problemId,
        correctAnswer
      )(dispatch)
      return
    }

    if (problemId) {
      changeSolutionCorrectness(
        {
          problemId: subProblemId || problemId,
          correct: !isCorrect,
          exerciseId,
        },
        currentAttemptSolutionId
      )(dispatch)
    }
  }

  return (
    <header
      className={clsx(styles.Header, { [styles.ActionsVisible]: !headerColor })}
      style={{
        backgroundColor: getHeaderColor({
          headerColor,
          currentAnswerInfo: selectedAttemptAnswerInfo,
          solution,
        }),
      }}
    >
      <div className={styles.Attempts}>
        {solution.attempt !== 0 && (
          <UITooltip
            title={`${solution.attempt} ${solution.attempt > 1 ? t.attemptsTxt : t.attemptTxt}`}
            placement='top'
            className={styles.Tooltip}
          >
            <p className={styles.Attempts}>{solution.attempt}</p>
          </UITooltip>
        )}
        {solution.watched && (
          <UITooltip title={t.watched} placement='top' className={styles.Tooltip}>
            <div className={styles.Icon}>
              <EyeIcon color={COLORS.NEUTRAL_1} size={16} />
            </div>
          </UITooltip>
        )}
      </div>
      <UITooltip
        title={nameOverflow.isOverflowing ? title : null}
        placement='top'
        className={styles.Tooltip}
      >
        <p className={styles.StudentName} ref={nameOverflow.overflowRef}>
          {title}
        </p>
      </UITooltip>

      <SolutionCardActions
        isCorrect={isCorrect}
        onCorrectnessChange={changeCorrectnessOfSolution}
        exerciseId={exerciseId}
        problemId={problemId}
        subProblemId={subProblemId}
        pinSolutionId={pinSolutionId}
        isPinVisible
        isUserDeleted={isUserDeleted}
        isCorrectnessVisible={!headerColor}
      />
    </header>
  )
}

export default SmallSolutionCardHeader
