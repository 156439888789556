import { PATHS } from '../../../../config/pathnames.config'
import AssignmentsIcon from '../../../../ui/icons/AssignmentsIcon/AssignmentsIcon'
import StudentsIcon from '../../../../ui/icons/StudentsIcon/StudentsIcon'
import PracticeIcon from '../../../../ui/icons/PracticeIcon/PracticeIcon'
import React from 'react'

interface ISidebarLinkIconProps {
  param: string
  getIconColor: (param: string) => string
}

const SidebarLinkIcon: React.FC<ISidebarLinkIconProps> = ({ param, getIconColor }) => {
  if (param === PATHS.EXERCISES.EXERCISES_MAIN) {
    return <AssignmentsIcon color={getIconColor(param)} />
  }
  if (param === PATHS.PARTS.STUDENTS) {
    return <StudentsIcon color={getIconColor(param)} />
  }
  if (param === PATHS.PRACTICE.PRACTICE_MAIN) {
    return <PracticeIcon color={getIconColor(param)} />
  }
  return null
}

export default SidebarLinkIcon
