import React from 'react'
import styles from './CardStats.module.scss'
import { IStatistic } from '../../../../../api/types.oldPractice'
import { CircleProgressBar, StarProgressBar } from '@magmamath/ui'
import { getProgressStarCount } from '../../../helpers'

type CardStatsProps = {
  isStudentSelected: boolean
  stats: IStatistic
}

const CardStats = ({ isStudentSelected, stats }: CardStatsProps) => {
  const correctAnswersPercentage = stats?.correct ?? 0
  const wrongAnswerPercentage = stats?.wrong ?? 0

  if (isStudentSelected) {
    return (
      <StarProgressBar
        count={3}
        progress={getProgressStarCount(correctAnswersPercentage)}
        size={25}
      />
    )
  }

  return (
    <CircleProgressBar
      classes={{ wrapper: styles.AbilityCardProgressCircle }}
      progressData={[correctAnswersPercentage, wrongAnswerPercentage]}
      size={44}
      backgroundColor={'var(--card-background-color)'}
    />
  )
}

export default CardStats
