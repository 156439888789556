import { COLORS } from '@magmamath/ui'
import clsx from 'clsx'
import CheatIndicator from 'features/heatmap/ExamMode/ui/CheatIndicator/CheatIndicator'
import { TABLE_ROW_HEADER_WIDTH } from 'features/tables/HeatmapTable/constants'
import useText from 'i18n/hook'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import DisabledIcon from 'ui/icons/DisabledIcon/DisabledIcon'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import styles from './Table.module.scss'
import { ICheat } from '../../../../../api/types.statistics'

type RowHeaderCellProps = {
  cheats?: ICheat[]
  isUserDeleted: boolean
  linkToPathname: LinkProps<unknown>['to']
  userName: string
  userStatistics: string | null
} & React.HTMLAttributes<HTMLDivElement>

const RowHeaderCell = ({
  cheats,
  isUserDeleted,
  linkToPathname,
  userName,
  userStatistics,
  ...restProps
}: RowHeaderCellProps) => {
  const t = useText()
  const { overflowRef, isOverflowing } = useOverflowDetection()
  return (
    <div className={styles.TableRowHeaderContainer} {...restProps}>
      <Link className={styles.Link} to={linkToPathname}>
        <div className={styles.TableRowHeader} style={{ width: TABLE_ROW_HEADER_WIDTH }}>
          {isUserDeleted && (
            <UITooltip placement='top' title={t.noLongerInAssignmentTxt}>
              <div className={styles.IconContainer}>
                <DisabledIcon color={COLORS.NEUTRAL_8} size={16} />
              </div>
            </UITooltip>
          )}
          <CheatIndicator cheats={cheats} />
          <UITooltip ref={overflowRef} placement='top' title={isOverflowing ? userName : null}>
            <span className={clsx(styles.DefaultText, styles.Ellipsis, styles.Grow)}>
              {userName}
            </span>
          </UITooltip>
          {userStatistics !== null && (
            <span className={styles.StatisticsText}>{userStatistics}</span>
          )}
        </div>
      </Link>
    </div>
  )
}

export default RowHeaderCell
