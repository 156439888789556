import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import { theme } from '../../../assets/themes/theme'

export const PanelHeaderTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 630,
    fontSize: '14px',
    color: theme.palette.secondary.dark,
  },
})
