import React, { useRef } from 'react'
import styles from './styles.module.scss'
import clx from 'classnames'
import IconCheckbox from '../../../../ui/checkboxes/iconCheckbox/IconCheckbox'

import CheckboxIconDefault from '../../../../ui/icons/CheckboxIconDefault/CheckboxIconDefault'
import CheckboxIconActiveChecked from '../../../../ui/icons/CheckboxIconActiveChecked/CheckboxIconActiveChecked'
import { MultipleChoice } from '../../../../redux/modules/problemCreator.module'
import StaticMathInput, { MathInputRef } from '../../../mathEditor/mathInput/static/StaticMathInput'
import { COLORS } from '@magmamath/ui'

interface MultipleOptionProps {
  item: MultipleChoice
  placeholder: string
  onChange: (value: MultipleChoice) => void
  onSelectionChanged?: (value: boolean) => void
  autofocus?: boolean
}

const MultipleChoiceAnswerItem: React.FC<MultipleOptionProps> = ({
  placeholder,
  item,
  onChange,
  onSelectionChanged,
  autofocus,
}) => {
  const ref = useRef<MathInputRef | null>(null)
  return (
    <div className={styles.Item}>
      <div>
        <IconCheckbox
          onChange={() => {
            ref.current?.model.focus()
            if (!item.value) return
            onSelectionChanged?.(!item.checked)
          }}
          checked={item.checked}
          icon={
            <CheckboxIconDefault
              size={16}
              color={item.value.length ? COLORS.NEUTRAL_10 : COLORS.NEUTRAL_6}
            />
          }
          checkedIcon={<CheckboxIconActiveChecked size={16} color={COLORS.NEUTRAL_10} />}
          disabled={false}
        />
      </div>
      <div className={clx(styles.InputContainer)}>
        <StaticMathInput
          ref={ref}
          autofocus={autofocus}
          initialValue={item.value}
          onChange={({ value }) => {
            onChange?.({
              value,
              checked: !!value && item.checked,
            })
          }}
          className={styles.MathInput}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

export default MultipleChoiceAnswerItem
