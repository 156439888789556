import _ from 'lodash'
import React, { ReactElement } from 'react'
import { Separator } from './enums'
import Markdown from '../components/RichDescription/RichDescription.component'

export function isMarkedAsTeacher(stats: any) {
  const markedByTeacherAsCorrect: boolean =
    stats.answerArray &&
    !_.isEmpty(stats.answerArray) &&
    stats.answerArray[stats.answerArray.length - 1].reviewedByTeacherAt &&
    stats.answerArray[stats.answerArray.length - 1].correct
  return markedByTeacherAsCorrect
}

export function divideDisplayingPartsOfAnswer(answerString: string) {
  let answer: ReactElement | null
  const isUnderscoreWithBrackets: RegExpMatchArray | null = answerString.match(/.*_\[([^]]*)]/g)
  const isMatchSuper: RegExpMatchArray | null = answerString.match(/.*[\^]+[^]+(?=)/g)
  const partOfStringWithUnderscore: string | undefined = isUnderscoreWithBrackets
    ? isUnderscoreWithBrackets.toString()
    : undefined
  const partOfStringWithSuper: string | undefined = isMatchSuper
    ? isMatchSuper.toString()
    : undefined

  if (!!partOfStringWithUnderscore && answerString.includes(partOfStringWithUnderscore)) {
    const subNumber: string = partOfStringWithUnderscore
      .match(/[^[\]]+(?=])/g)![0]
      .replace(/"\"\]']/g, '')
    const partBeforeUnderscore: string = answerString.substring(
      0,
      answerString.indexOf(partOfStringWithUnderscore[0])
    )
      ? answerString.substring(0, answerString.indexOf(partOfStringWithUnderscore[0]))
      : ''
    const firstPartNumber: string = partOfStringWithUnderscore.substring(
      0,
      partOfStringWithUnderscore.indexOf('_')
    )
    const partAfterBrackets: string = answerString.substring(
      partOfStringWithUnderscore.indexOf(']') + 1
    )
    answer = (
      <span className='answer'>
        <span>{partBeforeUnderscore.replace(/[[\]]/g, '')}</span>
        <span>{firstPartNumber.replace(/[[\]]/g, '')}</span>
        <sub>{subNumber.replace(/[[\]]/g, '')}</sub>{' '}
        <span>{partAfterBrackets.replace(/[[\]]/g, '')}</span>
      </span>
    )
  } else if (!!partOfStringWithSuper && answerString.includes(partOfStringWithSuper)) {
    const supNumber: RegExpMatchArray | null = answerString.match(/[(^)]+[^]+()/g)
    const cleanSupNumber: string = !!supNumber
      ? supNumber[0].replace(/$[[\]]+(?=\/)/g, '').replace(/[(^)]/g, '')
      : ''
    const partBeforeSuper: string = answerString.substring(
      0,
      answerString.indexOf(partOfStringWithSuper[0])
    )
      ? answerString.substring(0, answerString.indexOf(partOfStringWithSuper[0]))
      : ''
    const firstPartNumber: string = partOfStringWithSuper.substring(
      0,
      partOfStringWithSuper.indexOf('^')
    )
    const partAfterBrackets: string = answerString.substring(partOfStringWithSuper.indexOf(']') + 1)
    const finishSupNumber: string = cleanSupNumber
      .substring(cleanSupNumber.indexOf('['), cleanSupNumber.indexOf(']'))
      .replace(/[[\]]/g, '')
    answer = (
      <span className='answer'>
        <span>{partBeforeSuper.replace(/[[\]]/g, '')}</span>
        <span>{firstPartNumber.replace(/[[\]]/g, '')}</span>
        <sup>{finishSupNumber.replace(/[[\]]/g, '')}</sup>{' '}
        <span>{partAfterBrackets.replace(/[[\]]/g, '')}</span>
      </span>
    )
  } else {
    answer = <span className='answer'>{answerString.replace(/[[\]]/g, '')}</span>
  }

  return answer
}

export function displayAnswer(answer: string, index: number, length: number, separator: Separator) {
  const isFractionWithoutBrackets: RegExpMatchArray | null = answer.match(/[\/]/g)
  const isSeparatorComma = separator === Separator.COMMA
  const isShowComma = index + 1 !== length && isSeparatorComma

  if (answer.match(/^\$.+\$$/)) {
    return (
      <>
        <Markdown>{answer}</Markdown>
        {isShowComma && <span className='comma-txt'>,</span>}
      </>
    )
  }

  const regexpAtSymbol: RegExp = /\d+(.\d+)?@\d+(.\d+)?/g
  const matchAtSymbol: RegExpExecArray | null = regexpAtSymbol.exec(answer)
  let answerWithAtSymbol: string = answer
  if (matchAtSymbol) {
    const dashSymbol: string = '\u2013'
    const atPart: string = answer.slice(
      matchAtSymbol.index,
      matchAtSymbol.index + matchAtSymbol[0].length
    )
    answerWithAtSymbol = [
      answerWithAtSymbol.slice(0, matchAtSymbol.index),
      atPart.replace('@', ` ${dashSymbol} `),
      answerWithAtSymbol.slice(matchAtSymbol.index + matchAtSymbol[0].length),
    ].join('')

    return (
      <>
        <span className='answer-txt'>{answerWithAtSymbol}</span>
        {isShowComma && <span className='comma-txt'>,</span>}
      </>
    )
  }

  if (!isFractionWithoutBrackets) {
    return (
      <>
        <span className='answer-txt'>{divideDisplayingPartsOfAnswer(answer)}</span>
        {isShowComma && <span className='comma-txt'>,</span>}
      </>
    )
  }

  let partAfterBrackets: string = ''
  const partBeforeBrackets: string = answer.substring(0, answer.indexOf('['))
  const supText: string = answer.substring(answer.indexOf('['), answer.indexOf('/'))
  const subText: string = answer.substring(answer.indexOf('/') + 1)
  if (subText.includes(']')) {
    partAfterBrackets = subText.includes(']]')
      ? subText.substring(subText.indexOf(']]') + 2)
      : subText.substring(subText.indexOf(']') + 1)
  }
  const clearSubText: string = partAfterBrackets
    ? subText.substring(0, subText.indexOf(partAfterBrackets[0]))
    : subText

  return (
    <span className='answer-txt fraction-txt' key={answer}>
      <span>{partBeforeBrackets.replace(/[[\]]/g, '')}</span>
      <span className='frac'>
        <sup className='frac-sup'>{divideDisplayingPartsOfAnswer(supText)}</sup>
        <span className='frac-divider'>&frasl;</span>
        <sub className='frac-sub'>{divideDisplayingPartsOfAnswer(clearSubText)}</sub>
      </span>
      <span>{partAfterBrackets.replace(/[[\]]/g, '')}</span>
    </span>
  )
}
