import clsx from 'clsx'
import { useStoreMap } from 'effector-react'
import ClassroomModal from 'features/modals/ClassroomModal/ClassroomModal'
import {
  $classes,
  setClassSelectionStatus,
  updateClassesWithClass,
} from 'features/modals/ClassroomModal/model/classes'
import { SyncLevel, getSyncLevel } from 'helpers/classesView.helpers'
import { ActionElement } from 'helpers/enums'
import useText from 'i18n/hook'
import { hasSearchedValue } from 'lib/filters'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openCreateClassModal } from 'redux/modules/modal.module'
import { RootState } from 'store/store'
import SelectExerciseClass from 'features/caf/classroomSelector/ui/SelectExerciseClass/SelectExerciseClass'
import ClassroomSelectorHeader from '../ClassroomSelectorHeader/ClassroomSelectorHeader'
import NewClassButton from '../NewClassButton/NewClassButton'
import styles from './ClassroomSelector.module.scss'
import { IClass } from '../../../../../api/types.classes'

type ClassroomSelectorProps = {
  isSearchAvailable: boolean
  classrooms: IClass[]
}

const ClassroomSelector = React.forwardRef<HTMLDivElement, ClassroomSelectorProps>(
  ({ classrooms, isSearchAvailable }, ref) => {
    const t = useText()
    const dispatch = useDispatch()

    const { classesStore, classesStoreMap } = useStoreMap($classes, (classesStore) => ({
      classesStore,
      classesStoreMap: new Map(classesStore.map((singleClass) => [singleClass.id, singleClass])),
    }))

    const [isSearchVisible, setIsSearchVisible] = useState(false)
    const [classesSearch, setClassesSearch] = useState('')
    const [modalClass, setModalClass] = useState<IClass | null>(null)

    const me = useSelector((state: RootState) => state.auth.data.me)
    const syncLevel = getSyncLevel(me)
    const isNewClassButtonVisible = !classrooms.length && syncLevel === SyncLevel.NO_SYNC

    const filteredClassesWithGroups = useMemo(
      () =>
        classrooms.filter((listClass) =>
          hasSearchedValue([listClass.name, listClass.displayName ?? ''], classesSearch)
        ),
      [classrooms, classesSearch]
    )

    const changeSearchState = (newOpenState?: boolean) => {
      isSearchVisible && setClassesSearch('') // clear search input when closing search
      setIsSearchVisible((prevState) => newOpenState ?? !prevState)
    }

    const toggleClassSelection = (changedClass: IClass) => {
      const classStoreChangedClass = classesStore.find((cls) => cls.id === changedClass._id)
      if (!classStoreChangedClass || !classStoreChangedClass.classStore) {
        updateClassesWithClass({
          id: changedClass._id,
          value: {
            id: changedClass._id,
            classStore: {
              id: changedClass._id,
              name: changedClass.name,
              groupIds: changedClass.groups.map((group) => group._id),
              studentIds: changedClass.students.map((student) => student._id),
              selected: true,
            },
            groupsStore: changedClass.groups.map((group) => ({
              id: group._id,
              name: group.name,
              classId: changedClass._id,
              studentIds: group.students.map((student) => student._id),
              selected: !!group.students.length, // skip empty groups
            })),
            studentsStore: changedClass.students.map((student) => ({
              id: student._id,
              firstName: student.firstName,
              lastName: student.lastName,
              classId: changedClass._id,
              selected: true,
            })),
          },
        })
        return
      }

      const isPrevSelected = classStoreChangedClass.classStore.selected ?? false
      const hasSomeStudentsSelected = classStoreChangedClass.studentsStore.some(
        (studentStore) => studentStore.selected
      )
      const isSelected =
        !isPrevSelected && hasSomeStudentsSelected
          ? true
          : !(classStoreChangedClass.classStore.selected ?? false)

      setClassSelectionStatus({ classId: changedClass._id, isSelected })
    }

    useEffect(() => {
      // automatically assign class if there is only one class and no classes are selected
      const isSingleCreateClass = classrooms.length === 1 && !classesStore.length
      if (isSingleCreateClass) {
        toggleClassSelection(classrooms[0])
      }
    }, [classrooms, classesStore])

    if (!classrooms) return <div className={styles.ClassroomSelector} />

    return (
      <div className={styles.ClassroomSelector}>
        <ClassroomSelectorHeader
          availableClasses={classrooms}
          isSearchAvailable={isSearchAvailable}
          isSearchVisible={isSearchVisible}
          searchValue={classesSearch}
          onSearchVisibilityChange={changeSearchState}
          onSearchValueChange={setClassesSearch}
        />
        {isNewClassButtonVisible && (
          <div className={styles.ClassesListBody}>
            <NewClassButton
              onClick={() =>
                dispatch(
                  openCreateClassModal(t.newClassTxt, ActionElement.createClass, me, null, {
                    skipRedirectOnClassCreation: true,
                  })
                )
              }
            />
          </div>
        )}

        {!filteredClassesWithGroups.length && !isNewClassButtonVisible && (
          <div className={styles.ClassesListBody}>
            <p className={styles.NoClassesText}>
              {isSearchVisible ? t.noClassesFound : t.noClassesFoundTxt}
            </p>
          </div>
        )}

        {!!filteredClassesWithGroups.length && (
          <div className={clsx(styles.ClassesList, {
            [styles.WithSearch]: isSearchVisible
          })}
            ref={ref}>
            {filteredClassesWithGroups.map((classObject) => (
              <SelectExerciseClass
                key={classObject._id}
                onClassButtonClick={toggleClassSelection}
                onParticipantsButtonClick={setModalClass}
                classObject={classObject}
                classSelection={classesStoreMap.get(classObject._id)}
              />
            ))}
          </div>
        )}

        {!!modalClass && (
          <ClassroomModal
            classesStore={classesStore}
            modalClass={modalClass}
            onClose={() => setModalClass(null)}
          />
        )}
      </div>
    )
  }
)

export default ClassroomSelector
