import React from 'react'
import { COLORS } from '@magmamath/ui'

import { SvgIconProps } from '../types/types'

const RedoIcon: React.FC<SvgIconProps> = ({ size = 25, color = COLORS.NEUTRAL_9 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 26'
      fill='none'
    >
      <path
        d='M19.48 24.5C19.48 24.5 19.3815 24.5 8.84888 24.5C-1.68376 24.5 -1.23651 6.70001 8.84888 6.70001C18.9343 6.70001 22.8807 6.70001 22.8807 6.70001'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
      />
      <path
        d='M23.4706 7.28691C23.7948 6.96278 23.7948 6.43725 23.4706 6.11311L18.1885 0.831026C17.8644 0.506891 17.3389 0.506891 17.0147 0.831026C16.6906 1.15516 16.6906 1.68069 17.0147 2.00482L21.7099 6.70001L17.0147 11.3952C16.6906 11.7193 16.6906 12.2449 17.0147 12.569C17.3389 12.8931 17.8644 12.8931 18.1885 12.569L23.4706 7.28691ZM15.2021 7.53001H22.8837V5.87001H15.2021V7.53001Z'
        fill={color}
      />
    </svg>
  )
}

export default RedoIcon
