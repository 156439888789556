import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

export enum EXERCISES_STATUS {
  AVAILABLE = 'AVAILABLE',
  ARCHIVED = 'ARCHIVED',
  UNPUBLISHED = 'UNPUBLISHED',
}

const initialState = {
  data: {
    archived: false,
    available: true,
    unpublished: false,
  },
  error: null,
  loading: false,
}

export function exercisesStatusesReducer(
  state: any = initialState,
  action: IAction<EXERCISES_STATUS>
) {
  switch (action.type) {
    case EXERCISES_STATUS.AVAILABLE:
      return {
        ...state,
        data: {
          ...state.data,
          archived: false,
          available: action.payload,
          unpublished: false,
        },
        error: false,
        loading: false,
      }
    case EXERCISES_STATUS.ARCHIVED:
      return {
        ...state,
        data: {
          ...state.data,
          archived: action.payload,
          available: false,
          unpublished: false,
        },
        error: false,
        loading: false,
      }
    case EXERCISES_STATUS.UNPUBLISHED:
      return {
        ...state,
        data: {
          ...state.data,
          archived: false,
          available: false,
          unpublished: action.payload,
        },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export const exerciseStatusAC = (status: EXERCISES_STATUS) => ({ type: status, payload: true })

const getExerciseStatus = (status: EXERCISES_STATUS) => (dispatch: IDispatch<any>) =>
  dispatch(exerciseStatusAC(status))

export const getAvailable = () => getExerciseStatus(EXERCISES_STATUS.AVAILABLE)

export const getArchived = () => getExerciseStatus(EXERCISES_STATUS.ARCHIVED)

export const getUnpublished = () => getExerciseStatus(EXERCISES_STATUS.UNPUBLISHED)

export interface IExercisesStatusActions {
  getAvailable: () => void
  getArchived: () => void
  getUnpublished: () => void
}

export interface IExercisesStatuses {
  archived: boolean
  available: boolean
  unpublished: boolean
}
