import React from 'react'
import { SvgIconProps } from '../types/types'

const PauseIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.890625 2.10062C0.890625 1.15622 1.65622 0.390625 2.60063 0.390625H5.80062C6.74503 0.390625 7.51062 1.15622 7.51062 2.10063V14.9006C7.51062 15.845 6.74503 16.6106 5.80062 16.6106H2.60063C1.65622 16.6106 0.890625 15.845 0.890625 14.9006V2.10062Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.4906 2.10062C10.4906 1.15622 11.2562 0.390625 12.2006 0.390625H15.4006C16.345 0.390625 17.1106 1.15622 17.1106 2.10063V14.9006C17.1106 15.845 16.345 16.6106 15.4006 16.6106H12.2006C11.2562 16.6106 10.4906 15.845 10.4906 14.9006V2.10062Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default PauseIcon
