import React from 'react'
import ChaptersSkeleton from '../ChaptersSkeleton/ChaptersSkeleton'
import SectionProblems from '../SectionProblems/SectionProblems'
import TableOfContents from '../TableOfContents/TableOfContents'
import { LoadingStatus } from '../../../../helpers/enums'
import { OpenedSectionPath } from '../../model/types'
import { useUnit } from 'effector-react/effector-react.umd'
import { $chapters } from '../model/chapters'
import styles from './BookContent.module.scss'
import { $isSearchActive } from 'features/bookshelf/model/foundBooks'

type BookContentProps = {
  bookId: string
  chaptersLoadingState: LoadingStatus
  sectionsLoadingState: LoadingStatus
  openedBookPath?: OpenedSectionPath
}

const BookContent = ({
  bookId,
  openedBookPath,
  sectionsLoadingState,
  chaptersLoadingState,
}: BookContentProps) => {
  const chapters = useUnit($chapters)
  const chaptersList = chapters.get(bookId)
  const openedChapter = openedBookPath?.chapter
  const openedSection = openedBookPath?.section
  const isProblemView = !!(openedChapter && openedSection)
  const isSearchActive = useUnit($isSearchActive)

  if (chaptersLoadingState === LoadingStatus.LOADING) {
    return (
      <div className={styles.Content}>
        <ChaptersSkeleton />
      </div>
    )
  }

  if (sectionsLoadingState === LoadingStatus.LOADING) {
    return (
      <div className={styles.Content}>
        <ChaptersSkeleton count={chaptersList?.length} />
      </div>
    )
  }

  return (
    <div className={styles.Content}>
      {isProblemView ? (
        <>
          {isSearchActive && <TableOfContents bookId={bookId} currentBookPath={openedBookPath} />}
          <SectionProblems sectionId={openedSection.id} />
        </>
      ) : (
        <TableOfContents bookId={bookId} currentBookPath={openedBookPath} />
      )}
    </div>
  )
}

export default BookContent
