import React from 'react'
import { SvgIconProps } from '../types/types'

const PlayIcon: React.FC<SvgIconProps> = ({ size = 25, color, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 34 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.8 0.5V2.95H23.5V18.2C23.5 21.45 25.7 22.9 28.3 22.9C29.85 22.9 31.55 22.4 32.95 21.4L34 23.15C32.05 24.6 29.85 25.3 27.85 25.3C24.1 25.3 20.95 22.85 20.95 18.2V2.95H9.65V25H7V2.95H2.83122e-07V0.5H31.8Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayIcon
