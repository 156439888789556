import React, { forwardRef } from 'react'
import clsx from 'clsx'

import './Switch.scss'

type SwitchProps = JSX.IntrinsicElements['input']

const UISwitch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ onChange = () => null, className, ...rest }, ref) => {
    const mergedClassNames = clsx('ui-switch-input', className)

    return (
      <input onChange={onChange} ref={ref} className={mergedClassNames} type='checkbox' {...rest} />
    )
  }
)

export default UISwitch
