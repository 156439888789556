import classnames from 'classnames'
import _ from 'lodash'
import * as React from 'react'
import { FormFeedback, FormText, Input, InputProps, Label } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'
import { removeSpacesFromString } from './problems.helpers'

export function focusElement(element: HTMLElement) {
  element.focus()
}

export const renderInputField = ({
  className,
  input,
  label,
  placeholder,
  autoFocus,
  meta: { touched, error },
  type,
  id,
  inline,
  disabled,
  autoComplete,
  description,
}: InputProps & WrappedFieldProps & any) => [
  <Label htmlFor={id} className={classnames({ 'mr-sm-2': inline })} key='0'>
    {label}
  </Label>,
  <Input
    {...input}
    key='1'
    placeholder={placeholder || label}
    type={type}
    className={className}
    invalid={!!(touched && error)}
    valid={touched && !error}
    id={id}
    disabled={disabled}
    autoFocus={autoFocus}
    autoComplete={autoComplete}
  />,
  description ? (
    <FormText key='2' color='muted'>
      {description}
    </FormText>
  ) : null,
  ,
  <FormFeedback key='3'>{error}</FormFeedback>,
]

export const valueWithoutSpaces = (value: string) => {
  if (!value) {
    return value
  } else {
    const fixedValue: string = removeSpacesFromString(value)
    return fixedValue
  }
}
