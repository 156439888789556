import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { LocaleData } from '../../helpers/enums'
import { api } from '../../api'

export enum LANGUAGE {
  CHANGE_LANGUAGE_REQUEST = 'CHANGE_LANGUAGE_REQUEST',
  CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS',
  CHANGE_LANGUAGE_ERROR = 'CHANGE_LANGUAGE_ERROR',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function languageReducer(state: any = initialState, action: IAction<LANGUAGE>) {
  switch (action.type) {
    case LANGUAGE.CHANGE_LANGUAGE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case LANGUAGE.CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case LANGUAGE.CHANGE_LANGUAGE_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const changeLanguage = (language: LocaleData) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: LANGUAGE.CHANGE_LANGUAGE_REQUEST })
      const res = await api.users.updateMySettings({ appLocale: language })
      dispatch({ type: LANGUAGE.CHANGE_LANGUAGE_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: LANGUAGE.CHANGE_LANGUAGE_ERROR,
      })
    }
  }
}

export interface ILanguage {
  messageKey: string
}
