import clsx from 'clsx'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import sharedStyles from '../../styles/Styles.module.scss'
import styles from './ExerciseTopics.module.scss'
import ExerciseTopicsTooltip from './ExerciseTopicsTooltip'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { getTopicsText } from '../../helpers/getTopicsText'

import { ITopic } from '../../../../api/api.topics'

type ExerciseTopicsProps = {
  topics: ITopic[]
  isDistrictExercise: boolean
}

const ExerciseTopics = ({ topics, isDistrictExercise }: ExerciseTopicsProps) => {
  const { isBulkModeActive } = useBulkSelectContext()
  const { overflowRef, isOverflowing } = useOverflowDetection({ parentWidthToTextLengthRatio: 8.2 })
  const t = useSelector((state: RootState) => state.localization.data)
  const isMultipleTopics = topics.length > 1
  const isTooltipVisible = isMultipleTopics || isOverflowing

  return (
    <UITooltip
      title={
        isTooltipVisible ? <ExerciseTopicsTooltip topics={topics} topicsTxt={t.topicsTxt} /> : null
      }
      placement='top'
    >
      <div ref={overflowRef} className={styles.Categories}>
        <span
          className={clsx(sharedStyles.CardText, {
            [sharedStyles.DisableSelect]: isBulkModeActive,
          })}
          data-skip-redirect={isMultipleTopics}
        >
          {getTopicsText(topics, isDistrictExercise, t)}
        </span>
      </div>
    </UITooltip>
  )
}

export default ExerciseTopics
