import React from 'react'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { IState } from 'interfaces/state.interface'
import { PieChartLink } from 'helpers/enums'
import { IMatch } from 'interfaces/match.inteface'
import { SmallCircleIcon } from 'components/basic/Icons/SmallCircleIcon/SmallCircleIcon'
import { ILocalization } from 'config/languages.config'
import { IUser } from './OldPieChart.view'
import './NamesBlock.scss'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface INamesBlockProps {
  history: any
  localization: ILocalization
  exerciseWithoutStats: IState<IExerciseWithoutStats>
  users: IUser[]
  classes: IClass[]
  isStudentsNamesHidden: boolean
  match: IMatch
  groups: IGroup[]
}

const NamesBlock: React.FC<INamesBlockProps> = ({
  history,
  exerciseWithoutStats,
  localization,
  users,
  classes,
  isStudentsNamesHidden,
  match,
  groups,
}) => {
  const onClickHandler = (exerciseId: string, classId: string, linkType: PieChartLink): void => {
    history.push(`/exercises/${exerciseId}/${linkType}/${classId}/pie-chart`)
  }

  const getNameWhenUserHidden = (user: IUser, index: number) => {
    return `${user.isTeacher ? localization.data.teacherTxt : localization.data.studentTxt} ${
      index + 1
    }`
  }
  return (
    <div className='names-block'>
      <div className='names-block-header'></div>
      <div className='names-block-body'>
        {classes.map((singleClass) =>
          exerciseWithoutStats?.data?.allClassroomStudents ? (
            <div
              key={singleClass._id}
              className='entity-name-block'
              onClick={() =>
                onClickHandler(exerciseWithoutStats.data._id, singleClass._id, PieChartLink.CLASSES)
              }
            >
              {match.params?.classId === singleClass._id && <SmallCircleIcon />}
              <div className='entity-name-title'>{`${localization.data.classTxt} ${singleClass.name}`}</div>
            </div>
          ) : null
        )}
        {groups.map((group) => (
          <div
            key={group._id}
            className='entity-name-block'
            onClick={() =>
              onClickHandler(exerciseWithoutStats.data._id, group._id, PieChartLink.GROUPS)
            }
          >
            {match.params?.groupId === group._id && <SmallCircleIcon />}
            <div className='entity-name-title'>{`${localization.data.groupTxt} ${group.name}`}</div>
          </div>
        ))}
        {users.map((user, index) => (
          <div
            key={user._id}
            className='entity-name-block'
            onClick={() =>
              onClickHandler(exerciseWithoutStats.data._id, user._id, PieChartLink.STUDENTS)
            }
          >
            {match.params?.studentId === user._id && <SmallCircleIcon />}
            <div className='entity-name-title entity-name-title_font-normal'>
              {isStudentsNamesHidden
                ? getNameWhenUserHidden(user, index)
                : `${user.firstName} ${user.lastName}`}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NamesBlock
