import React from 'react'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { $errorMessages } from '../model/errorMessages'
import { ErrorMessagesFields } from '../types'
import styles from './ErrorMessageText.module.scss'
type ErrorMessageTextProps = {
  field: ErrorMessagesFields
} & React.HTMLAttributes<HTMLSpanElement>

const ErrorMessageText = ({ field, className, ...restProps }: ErrorMessageTextProps) => {
  const { messages, isSubmitted } = useUnit($errorMessages)
  const errorText = messages[field]

  if (!errorText || !isSubmitted) return null

  return (
    <span className={clsx(styles.ErrorMessageText, className)} {...restProps}>
      {errorText}
    </span>
  )
}

export default ErrorMessageText
