import * as React from 'react'
import './Forbidden.scss'
import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import { ReactComponent as LostConnectionImg } from '../../../assets/img/lost_connection.svg'
import ButtonPrimary from '../../../components/basic/OwnDesign/Buttons/Primary/ButtonPrimary'
import { ILocalization } from "../../../config/languages.config";

interface IForbiddenProps {
  localization: ILocalization
  history: any
  location: Location
}

const Forbidden: React.FC<IForbiddenProps> = ({ localization, history, location }) => {
  const { oupsTxt, lostConnection1, lostConnection2, tryAgainTxt } = localization.data

  const onClickHandler = () => {
    history.goBack()
  }
  return (
    <div className='forbidden-container'>
      <PanelHeader
        backButton={false}
        title=' '
        className='forbidden-header'
        localization={localization as any}
        history={history}
        location={location}
      />
      <div className='forbidden-body'>
        <div className='forbidden-body-img'>
          <LostConnectionImg />
        </div>
        <div className='forbidden-body-title'>{oupsTxt}</div>
        <div className='forbidden-body-description'>
          <p>{lostConnection1}</p>
          <p>{lostConnection2}</p>
        </div>
        <ButtonPrimary title={tryAgainTxt} disabled={false} onClickHandler={onClickHandler} />
      </div>
    </div>
  )
}

export default Forbidden
