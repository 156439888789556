import React from 'react'
import { SvgIconProps } from '../types/types'

const StudentIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 14 16'
      fill='none'
    >
      <circle cx='7.00029' cy='3.87345' r='2.78275' stroke={color} strokeWidth='1.41091' />
      <mask id='path-2-inside-1_878_4' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.166158 14.2053C0.00865529 14.9728 0.658852 15.6145 1.44238 15.6145H12.5577C13.3413 15.6145 13.9914 14.9728 13.8339 14.2053C13.182 11.0281 10.3701 8.63861 7.00005 8.63861C3.63004 8.63861 0.818128 11.0281 0.166158 14.2053Z'
        />
      </mask>
      <path
        d='M1.44238 17.0332H12.5577V14.1959H1.44238V17.0332ZM7.00005 10.0572C9.68312 10.0572 11.925 11.9599 12.4443 14.4904L15.2236 13.9201C14.439 10.0963 11.057 7.21998 7.00005 7.21998V10.0572ZM1.55583 14.4904C2.07511 11.9599 4.31699 10.0572 7.00005 10.0572V7.21998C2.94309 7.21998 -0.438857 10.0963 -1.22352 13.9201L1.55583 14.4904ZM12.5577 17.0332C14.0245 17.0332 15.6025 15.7663 15.2236 13.9201L12.4443 14.4904C12.436 14.4502 12.4363 14.3945 12.4537 14.337C12.4702 14.2829 12.4958 14.2466 12.5147 14.2265C12.5478 14.1912 12.5664 14.1959 12.5577 14.1959V17.0332ZM1.44238 14.1959C1.43371 14.1959 1.45228 14.1912 1.48543 14.2265C1.50434 14.2466 1.5299 14.2829 1.54636 14.337C1.56382 14.3945 1.56408 14.4502 1.55583 14.4904L-1.22352 13.9201C-1.60236 15.7663 -0.0243583 17.0332 1.44238 17.0332V14.1959Z'
        fill={color}
        mask='url(#path-2-inside-1_878_4)'
      />
    </svg>
  )
}

export default StudentIcon
