import { TimePickerType } from '../../../../helpers/enums'
import { Time } from '../../../../interfaces/scheduleTime.interface'

export const getValue = (time: Time, timePickerType: TimePickerType) => {
  const currentValueMap = {
    [TimePickerType.Hour]: time.hour,
    [TimePickerType.Minute]: time.minute,
    [TimePickerType.HourType]: time.type,
  }
  const value = currentValueMap[timePickerType]
  if (value === null) return
  if (TimePickerType.HourType === timePickerType) {
    const meridian = value === 0 ? 'AM' : 'PM'
    return { name: meridian, value: value.toString() }
  }
  const currentValue = String(currentValueMap[timePickerType]).length < 2 ? `0${value}` : value
  return { name: currentValue, value: value.toString() }
}
