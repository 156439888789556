import React from 'react'
import { useUnit } from 'effector-react'

import styles from './PracticeSubcategory.module.scss'

import SkillCard from '../SkillCard/SkillCard'
import { ISkill, ISkillTreeNode } from 'api/types.skills'
import { practiceStatisticsModel } from '../models/practiceStatistics'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { $filterSelectorStore } from 'features/filters/StudentsFilter/model'
import { openPracticeResultsModal } from 'redux/modules/modal.module'
import { TypesOfParams } from 'helpers/enums'

type SubcategoriesBlockProps = {
  subcategory: ISkillTreeNode
}
const PracticeSubcategory = ({ subcategory }: SubcategoriesBlockProps) => {
  const dispatch = useDispatch()
  const classFilterValue = useSelector((state: RootState) => state.classesFilter)

  const statistics = useUnit(practiceStatisticsModel.$statistics)

  const studentsFilterValue = useUnit($filterSelectorStore)
  const selectedOption = studentsFilterValue?.type ? studentsFilterValue : classFilterValue
  const isStudentSelected = selectedOption.type === TypesOfParams.STUDENT

  const cardClickHandler = (skill: ISkill) => {
    dispatch(openPracticeResultsModal({ skill, classId: classFilterValue?.value }))
  }

  return (
    <div className={styles.SubcategoryBlock}>
      <span className={styles.SubcategoryTitle}>{subcategory.nameTranslations}</span>
      <div className={styles.SkillsContainer}>
        {subcategory.skills.map((skill) => {
          return (
            <SkillCard
              key={subcategory._id + skill._id}
              skillName={skill.nameTranslations}
              statistic={statistics[skill._id]}
              isStudentSelected={isStudentSelected}
              onClick={() => cardClickHandler(skill)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PracticeSubcategory
