import React, { useEffect, useState } from 'react'
import styles from './ProblemCreatorHeader.module.scss'

import CloseModalIcon from '../../ui/icons/CloseModalIcon/CloseModalIcon'
import UIButton from '../../ui/Buttons/UIButton/Button'
import useText from '../../i18n/hook'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import {
  MultipleChoice,
  setErrorAnswerField,
  setErrorQuestionField,
} from '../../redux/modules/problemCreator.module'
import { addProblem, clearProblemStore, editProblem } from '../../redux/modules/problem.module'
import {
  ImageType,
  MyScriptCharacterTypes,
  ProblemType,
  SelectedValueTypes,
} from '../../helpers/enums'
import { getItemFromLocalStorageWithParsing } from '../../helpers/storage.helper'
import { localStorageKeys } from '../../config/localStorageKeys'
import { getBooksForCreatingProblems } from '../../redux/modules/books.module'
import {
  getAnswerType,
  getImageURL,
  isValidAnswerValue,
  katexToBracketSystem,
  replaceSpecialSymbolsWithBrackets,
} from './PoblemCreator.helper'
import { PATHS } from '../../config/pathnames.config'
import isEmpty from 'lodash/isEmpty'
import { ProblemCreationTab } from '../../screens/ProblemCreator/ProblemCreator'
import { addImage } from '../../redux/modules/images.module'
import {
  INITIAL_ORDER_VALUE,
  MIN_CORRECT_ANSWER_LENGTH,
  MIN_MULTIPLE_ANSWERS_LENGTH,
  MIN_QUALITY_CHOICES,
} from './constatnts'
import ButtonLoader from '../../ui/loaders/ButtonLoader/ButtonLoader'
import { COLORS } from '@magmamath/ui'
import { LOCALIZED_UNITS_SYMBOLS, UnitsTypes } from '../../ui/selects/SelectUnit/Symbols/Symbols'
import { currentLocale } from '../../config/modules.config'
import { ISection } from '../../api/types.books'

const DOLLAR_SIGN = '$'

const ProblemCreatorHeader = () => {
  const dispatch = useDispatch()
  const t = useText()
  const history = useHistory()
  const activeTab = useSelector((state: RootState) => state.problemCreatorData.activeTab)
  const questionKatexFieldValue = useSelector(
    (state: RootState) => state.problemCreatorData.questionKatex
  )
  const multipleChoice = useSelector(
    (state: RootState) => state.problemCreatorData.multipleChoice as MultipleChoice[]
  )
  const currentProblem = useSelector((state: RootState) => state.problem)
  const problemCreatorData = useSelector((state: RootState) => state.problemCreatorData)
  const imageData = useSelector((state: RootState) => state.image)
  const {
    answerNumeric,
    answerAlgebra,
    selectedValue,
    imageURL: imageURLFromServer,
    cdnImage,
    level,
    images,
  } = problemCreatorData
  const books = useSelector((state: RootState) => state.books)
  const [savedSection, setSavedSection] = useState<ISection | null>(null)
  const isValidMultipleChoiceQuality =
    multipleChoice.filter((el: MultipleChoice) => el.value.length !== 0).length >=
    MIN_QUALITY_CHOICES
  const rightAnswers = multipleChoice.filter((el: MultipleChoice) => el.checked)
  const isMultipleChoiceHasCorrectAnswer = rightAnswers.length >= MIN_CORRECT_ANSWER_LENGTH
  const isMultipleAnswers = rightAnswers.length >= MIN_MULTIPLE_ANSWERS_LENGTH
  const isValidAnswerField =
    isValidMultipleChoiceQuality &&
    isMultipleChoiceHasCorrectAnswer &&
    activeTab === ProblemCreationTab.MULTIPLE_CHOICE
  const goBack = () => {
    history.push(PATHS.CONTENT.PROBLEMS)
  }

  const languageTxt = t.languageTxt
  const firstSectionFromBooks: ISection =
    !books.loading && books.data._embedded && books.data._embedded.books[0].chapters[0].sections[0]
  const sectionData: ISection =
    !!savedSection && savedSection._id ? savedSection : firstSectionFromBooks
  const isDataLoaded = !currentProblem.loading && !imageData.loading

  const imageURL = getImageURL(
    ImageType.IMAGE,
    imageURLFromServer,
    imageData.data,
    cdnImage,
    images
  )
  const imageCDN = getImageURL(
    ImageType.CDN_IMAGE,
    imageURLFromServer,
    imageData.data,
    cdnImage,
    images
  )
  const getAnswer = () => {
    if (activeTab === ProblemCreationTab.NUMERIC) {
      const answer =
        selectedValue === DOLLAR_SIGN
          ? replaceSpecialSymbolsWithBrackets(selectedValue) + answerNumeric
          : answerNumeric +
            replaceSpecialSymbolsWithBrackets(selectedValue === null ? '' : selectedValue)
      return [katexToBracketSystem(answer)]
    }
    if (activeTab === ProblemCreationTab.ALGEBRA) {
      return [katexToBracketSystem(answerAlgebra)]
    }
    return multipleChoice.reduce((accumulator: string[], current) => {
      if (current.checked) {
        accumulator.push(`$${current.value}$`)
      }
      return accumulator
    }, [])
  }
  const getAnswerVariants = () => {
    if (activeTab !== ProblemCreationTab.MULTIPLE_CHOICE) {
      return []
    }
    return multipleChoice.filter((el) => el.value).map((item) => `$${item.value}$`)
  }

  const getCharacterType = (tab: ProblemCreationTab) => {
    const isCurrency = LOCALIZED_UNITS_SYMBOLS[currentLocale.currentLang][UnitsTypes.CURRENCY].some(
      (unitSymbol) => unitSymbol.symbol === selectedValue
    )
    const characterTypeForNumericField = isCurrency
      ? MyScriptCharacterTypes.CURRENCY
      : MyScriptCharacterTypes.UNITS
    const characterTypesMap = {
      [ProblemCreationTab.ALGEBRA]: MyScriptCharacterTypes.ALGEBRA_BASIC,
      [ProblemCreationTab.MULTIPLE_CHOICE]: MyScriptCharacterTypes.NUMBERS_RATIONAL,
      [ProblemCreationTab.NUMERIC]: selectedValue
        ? characterTypeForNumericField
        : MyScriptCharacterTypes.NUMBERS_RATIONAL,
    }

    return characterTypesMap[tab]
  }

  useEffect(() => {
    return () => {
      dispatch(clearProblemStore())
    }
  }, [])

  useEffect(() => {
    setSavedSection(getItemFromLocalStorageWithParsing(localStorageKeys.section))
    if (isEmpty(books.data)) {
      dispatch(getBooksForCreatingProblems(SelectedValueTypes.INITIALBOOKS))
    }
  }, [])

  useEffect(() => {
    if (questionKatexFieldValue.trim()?.length > 0 || images.length > 0 || imageURL) {
      dispatch(setErrorQuestionField(null))
    }
  }, [questionKatexFieldValue, images])

  useEffect(() => {
    if (
      (activeTab !== ProblemCreationTab.MULTIPLE_CHOICE || isValidAnswerField) &&
      (activeTab !== ProblemCreationTab.NUMERIC || isValidAnswerValue(answerNumeric)) &&
      (activeTab !== ProblemCreationTab.ALGEBRA || isValidAnswerValue(answerAlgebra))
    ) {
      dispatch(setErrorAnswerField(null))
    }
  }, [isValidAnswerField, activeTab, answerNumeric, answerAlgebra])

  useEffect(() => {
    const file = images[0]?.file
    if (!file) return
    dispatch(addImage(file, ''))
  }, [images])

  const onClickHandler = () => {
    if (!isDataLoaded) {
      return
    }
    if (!questionKatexFieldValue.trim() && !images?.length) {
      dispatch(setErrorQuestionField(t.errorMessageQuestion))
      return
    }
    if (!isValidMultipleChoiceQuality && activeTab === ProblemCreationTab.MULTIPLE_CHOICE) {
      dispatch(setErrorAnswerField(t.errorMessageAnswer))
      return
    }
    if (!isMultipleChoiceHasCorrectAnswer && activeTab === ProblemCreationTab.MULTIPLE_CHOICE) {
      dispatch(setErrorAnswerField(t.errorMessageCorrectAnswer))
      return
    }
    if (
      (activeTab === ProblemCreationTab.NUMERIC && !isValidAnswerValue(answerNumeric)) ||
      (activeTab === ProblemCreationTab.ALGEBRA && !isValidAnswerValue(answerAlgebra))
    ) {
      dispatch(setErrorAnswerField(t.errorMessageAnswerShort))
      return
    }
    addNewProblem()
  }

  const addNewProblem = () => {
    const problemData = {
      answer: getAnswer(),
      answerType: getAnswerType(activeTab, isMultipleAnswers),
      answerVariants: getAnswerVariants() as unknown as string[] | number[],
      description: questionKatexFieldValue,
      richDescription: questionKatexFieldValue,
      image: imageURL,
      cdnImage: imageCDN,
      level,
      subProblems: [],
      characterType: getCharacterType(activeTab),
    }
    dispatch(
      currentProblem.data?._id
        ? editProblem({
            data: { ...problemData },
            id: currentProblem.data._id,
            section: sectionData,
            contentId: currentProblem.data.content,
            history,
          })
        : addProblem(
            {
              ...problemData,
              isInitial: true,
              name: '',
              order: INITIAL_ORDER_VALUE,
              problemType: ProblemType.standard,
            },
            sectionData,
            languageTxt,
            history
          )
    )
  }
  return (
    <div className={styles.Container}>
      <div className={styles.IconWrapper} onClick={goBack}>
        <CloseModalIcon size={23} color={COLORS.NEUTRAL_10} />
      </div>
      <UIButton className={styles.HeaderSaveButton} color='blue' onClick={onClickHandler}>
        {isDataLoaded ? t.saveTxt : <ButtonLoader />}
      </UIButton>
    </div>
  )
}

export default ProblemCreatorHeader
