import React from 'react'
import { SvgIconProps } from '../types/types'

const PlusIcon: React.FC<SvgIconProps> = ({ size = 25, color, className }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M7.1499 14.25C7.1499 14.7195 7.53046 15.1 7.9999 15.1C8.46934 15.1 8.8499 14.7195 8.8499 14.25V8.85002H14.2499C14.7193 8.85002 15.0999 8.46947 15.0999 8.00002C15.0999 7.53058 14.7193 7.15002 14.2499 7.15002H8.8499V1.75003C8.8499 1.28058 8.46934 0.900024 7.9999 0.900024C7.53046 0.900024 7.1499 1.28058 7.1499 1.75003V7.15002H1.7499C1.28046 7.15002 0.899902 7.53058 0.899902 8.00002C0.899902 8.46947 1.28046 8.85002 1.7499 8.85002H7.1499V14.25Z'
        fill={color}
      />
    </svg>
  )
}

export default PlusIcon
