import Quill from 'quill'
import React from 'react'
import { QUILL_EDITOR_SETTINGS } from './settings'
import { trimEndSpaces } from '../common/helpers.katex'
import { DeltaItem, ExtendedQuill } from './types.quill'
import { ConvertFormat, ICoords } from '../common/types'
import { ActionEndSource } from '../mathInput/types.events'
import { QuillAreaManager } from './QuillAreaManager'
import { createDebounceEvent } from '../../../lib/effector/debounceEvent'
import { ChangedProps, Operator, OperatorType } from './types'
import { MathInputBlurBehaviour, MathInputModel } from '../mathInput/MathInputModel'
import { getMathInputVariant, MathInputVariant } from '../mathInput/variants'
import { renderFormulaToElement, textToDelta } from './helpers'
import { MathCommandsListener } from './MathCommandsListener'

type MathEditorModelProps = {
  placeholder?: string
  inputVariant?: MathInputVariant
  inputModel?: MathInputModel
  enableFormulaDetect?: boolean
  enableRealTimeUpdates?: boolean
  inputBlurBehaviour?: MathInputBlurBehaviour
}

export class MathEditorModel {
  public readonly changed = createDebounceEvent<ChangedProps>(50)

  public quill: ExtendedQuill | null = null
  public input: MathInputModel
  private readonly area = new QuillAreaManager()
  private readonly mathCommands: MathCommandsListener | null = null

  public constructor({
    placeholder = '',
    inputVariant = MathInputVariant.INLINE,
    inputBlurBehaviour,
    inputModel = getMathInputVariant(inputVariant)(),
    enableFormulaDetect,
    enableRealTimeUpdates = false,
  }: MathEditorModelProps = {}) {
    this.area
      .setPlaceholder(placeholder)
      .setRealTimeUpdates(enableRealTimeUpdates || inputVariant === MathInputVariant.INLINE)
    this.input = inputModel
    if (enableFormulaDetect) {
      this.mathCommands = new MathCommandsListener(inputModel, {
        onFormulaDetect: this.startFormulaEditor.bind(this),
      })
    }
    if (inputBlurBehaviour) this.input.setBlurBehaviour(inputBlurBehaviour)
  }

  public readonly convert = this.area.convert.bind(this.area)
  public readonly setPlaceholder = this.area.setPlaceholder.bind(this.area)

  public readonly detach = () => {
    this.quill = null
    this.area.detach()
    this.mathCommands?.detach()
  }

  public readonly setUp = (element: Element | null) => {
    if (!element) return this.detach()
    if (this.quill) return
    const quill = new Quill(element, {
      ...QUILL_EDITOR_SETTINGS,
      placeholder: this.area.placeholder,
    }) as ExtendedQuill
    this.quill = quill
    this.area.setUp(quill)
    this.mathCommands?.setUp(quill)
    const changedDefaultProps = {
      toContent: () => this.area.convert(ConvertFormat.RAW),
      toHtml: () => this.area.convert(ConvertFormat.HTML),
      toKatex: () => this.area.convert(ConvertFormat.KATEX),
      root: quill.root,
    }

    quill.on('text-change', (newDelta, delta) => {
      const hasCaptured = this.mathCommands?.capture(newDelta)
      if (hasCaptured) return
      this.changed({ ...changedDefaultProps, ops: delta.ops as DeltaItem[] })
    })
  }

  public insertSymbol(symbol: string) {
    if (this.input.isFocused) return this.input.insertValue(symbol)
    this.area.insertValue(symbol)
  }

  public insertFormula(formula: string) {
    if (this.input.isFocused) return this.input.insertValue(formula)
    this.startFormulaEditor(formula)
  }

  public readonly insert = ({ type, value }: Operator) => {
    if (type === OperatorType.KATEX) {
      return this.insertFormula(value)
    }
    this.insertSymbol(value)
  }

  public editFormulaOfElement(element: HTMLElement, pressCoords: ICoords) {
    const initialValue = element.getAttribute('data-value') || ''
    const draft = this.area.setEditingFormula({
      element,
      initialValue,
    })
    if (this.input.isFocused) {
      this.input.submitted({ doNotHide: true })
    }

    this.input.showInput({
      anchorElement: element,
      pressCoords,
      value: initialValue,
      onSubmit: (e) => {
        const latex = trimEndSpaces(e.getLatex())
        draft.finish?.()
        if (!latex) element.remove()
        renderFormulaToElement(latex, element)
        if (e.source === ActionEndSource.BLUR) return
        this.area.focus()
      },
      onDiscard: (e) => {
        draft.finish?.()
        if (e.source === ActionEndSource.BLUR) return
        this.area.focus()
      },
      onEdit: draft.edit,
      onDelete: () => {
        if (!this.area.isRealtimeUpdatesEnabled) return
        const latex = trimEndSpaces(this.input.getLatex())
        draft.finish?.()
        if (!latex) element.remove()
        this.input.hideInput()
        this.area.focus()
      },
    })
  }

  public startFormulaEditor(value: string) {
    if (!this.quill) return
    const currentRange = this.quill.getSelection()
    const draft = this.area.createFormulaDraft(value)

    this.input.showInput({
      value,
      anchorElement: draft.element,
      elementCoords: draft.coords,
      onSubmit: (e) => {
        const latex = trimEndSpaces(e.getLatex())
        draft.finish?.()
        if (latex || e.croppedSymbols) {
          return this.area.insertFormula(latex, {
            toRange: currentRange,
            finishSymbol: e.croppedSymbols,
            preventSelection: e.source === ActionEndSource.BLUR,
          })
        }
        if (e.source === ActionEndSource.BLUR) return
        this.area.focus(currentRange)
      },
      onDiscard: (e) => {
        draft.finish?.()
        if (e.source === ActionEndSource.BLUR) return
        this.area.focus(currentRange)
      },
      onEdit: draft.edit,
      onDelete: () => {
        if (!this.area.isRealtimeUpdatesEnabled) return
        draft.finish?.()
        this.input.hideInput()
        this.area.focus(currentRange)
      },
    })
    this.quill.blur()
  }

  private editFormulaUnderPointer(target: HTMLElement, coords: ICoords) {
    const formula = this.area.findRootFormulaOfElement(target)
    if (!formula || this.area.isEditingFormula(formula)) return
    if (formula) this.editFormulaOfElement(formula, coords)
  }

  public readonly editFormulaUnderMousePointer = (e: React.MouseEvent<HTMLDivElement>) => {
    this.editFormulaUnderPointer(e.target as HTMLElement, { x: e.clientX, y: e.clientY })
  }

  public setValue(value: string) {
    if (!this.quill) return
    const delta = this.quill.getContents()
    delta.ops = textToDelta(value)
    this.quill.setContents(delta)
  }
}
