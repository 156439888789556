import React from 'react'
import { SvgIconProps } from '../types/types'

const SyncIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 27 23'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91554 9.38074H9.89303C8.90277 9.38074 8.10001 10.1835 8.10001 11.1738V15.1069C8.10001 16.0972 8.90277 16.8999 9.89303 16.8999H17.107C18.0972 16.8999 18.9 16.0972 18.9 15.1069V11.1738C18.9 10.1835 18.0972 9.38074 17.107 9.38074H17.0845C17.054 8.73495 16.9639 7.96625 16.6978 7.27026C16.4866 6.71773 16.1475 6.1615 15.5983 5.74557C15.0404 5.32307 14.3374 5.09992 13.5 5.09992C12.6626 5.09992 11.9596 5.32307 11.4017 5.74557C10.8525 6.1615 10.5134 6.71773 10.3022 7.27026C10.0361 7.96625 9.946 8.73495 9.91554 9.38074ZM11.7186 9.38074H15.2814C15.2536 8.87028 15.1851 8.35386 15.0165 7.91311C14.8885 7.57831 14.7204 7.33866 14.5116 7.18054C14.3115 7.02898 14.0055 6.89991 13.5 6.89991C12.9945 6.89991 12.6885 7.02898 12.4884 7.18054C12.2796 7.33866 12.1115 7.57831 11.9835 7.91311C11.8149 8.35386 11.7464 8.87028 11.7186 9.38074ZM9.90001 11.1807V15.0999H17.1V11.1807H9.90001Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 2.27777C9.68891 2.27777 6.41551 4.58972 5.01015 7.89162C4.79387 8.39979 4.20658 8.63641 3.6984 8.42012C3.19023 8.20383 2.95362 7.61654 3.1699 7.10837C4.87813 3.09486 8.85894 0.277771 13.5 0.277771C18.8382 0.277771 23.3058 4.00494 24.4425 8.99888L24.7191 8.65308C25.0641 8.22181 25.6934 8.15189 26.1247 8.4969C26.556 8.84191 26.6259 9.4712 26.2809 9.90247L24.5031 12.1247C24.3374 12.3318 24.0963 12.4646 23.8327 12.4939C23.5691 12.5232 23.3046 12.4465 23.0975 12.2809L20.8753 10.5031C20.444 10.1581 20.3741 9.52878 20.7191 9.09752C21.0641 8.66626 21.6934 8.59634 22.1247 8.94135L22.4293 9.18499C21.4022 5.21218 17.7938 2.27777 13.5 2.27777ZM2.49692 10.8753C2.84192 10.444 3.47122 10.3741 3.90248 10.7191L6.1247 12.4969C6.55596 12.8419 6.62588 13.4712 6.28088 13.9025C5.93587 14.3337 5.30657 14.4036 4.87531 14.0586L4.57076 13.815C5.59781 17.7878 9.20623 20.7222 13.5 20.7222C17.2499 20.7222 20.4791 18.4841 21.9208 15.2666C22.1466 14.7626 22.7382 14.5371 23.2422 14.7629C23.7462 14.9887 23.9717 15.5803 23.7459 16.0844C21.9938 19.9948 18.0665 22.7222 13.5 22.7222C8.16185 22.7222 3.69422 18.995 2.55752 14.0011L2.28088 14.3469C1.93587 14.7782 1.30657 14.8481 0.875311 14.5031C0.444049 14.1581 0.374128 13.5288 0.719137 13.0975L2.49692 10.8753Z'
        fill={color}
      />
    </svg>
  )
}

export default SyncIcon
