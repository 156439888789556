import { getTeacherData } from 'helpers/user.helpers'
import { IStudent } from '../../api/types.users'
import { IExerciseWithoutStats } from '../../redux/modules/exerciseWithoutStats.module'

import { IProblem } from '../../api/types.problem'
import { IClass } from '../../api/types.classes'
import { IGroup } from '../../api/api.groups'

export const getExerciseStats = (
  exerciseWithoutStats: IExerciseWithoutStats,
  currentTeacher: ReturnType<typeof getTeacherData>
) => {
  const taskIds = (exerciseWithoutStats.problems || []).reduce(
    (tasks: string[], problem: IProblem) =>
      tasks.concat(
        problem.subProblems.length === 0
          ? problem._id
          : (problem.subProblems || []).map((subProblem) => subProblem._id)
      ),
    []
  )

  const usersFromAllSets = [
    ...getAllStudents(exerciseWithoutStats?.classes),
    ...getAllStudents(exerciseWithoutStats?.groups),
    ...exerciseWithoutStats?.students,
    currentTeacher,
  ]
  return { taskIds, usersFromAllSets }
}

const getAllStudents = (multiplicities: Array<IClass | IGroup>) =>
  multiplicities.reduce(
    (students: IStudent[], classOrGroup: IClass | IGroup) => students.concat(classOrGroup.students),
    []
  )
