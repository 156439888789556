import { IExercise } from '../../api/types.assignments'
import { IClass } from '../../api/types.classes'
import { IGroup } from '../../api/api.groups'

export const getAllStudents = (exercise: IExercise, studentsIds: string[]) => {
  const studentsFromClasses = exercise.classes.flatMap(
    (currentClass: IClass) => currentClass.students
  )
  const studentsFromGroups = exercise.groups.flatMap((group: IGroup) => group.students)
  const studentsFromExercise = [...studentsFromClasses, ...studentsFromGroups, ...exercise.students]
  return studentsIds.map((id) => {
    const matchingObject = studentsFromExercise.find((student) => student._id === id)
    return matchingObject
      ? { _id: id, firstName: matchingObject.firstName, lastName: matchingObject.lastName }
      : { _id: id, firstName: '', lastName: '' }
  })
}
