export class InterruptingTimer {
  private readonly delay: number
  private readonly onTimeout: () => void
  private currentTimer: ReturnType<typeof setTimeout> | null = null
  public constructor(onTimeout: () => void, delay: number) {
    this.onTimeout = onTimeout
    this.delay = delay
  }

  public start() {
    this.interrupt()
    this.currentTimer = setTimeout(() => {
      this.onTimeout()
      this.currentTimer = null
    }, this.delay)
  }

  public interrupt() {
    if (!this.currentTimer) return
    clearTimeout(this.currentTimer)
    this.currentTimer = null
  }
}
