import { dynamicPaths } from 'config/pathnames.config'
import {
  getCellColorClassName,
  getHeaderCellPercent,
  isWatchedIconVisibleForColorClass,
} from 'features/tables/HeatmapTable/helpers/ui'
import {
  HeatmapTestIds,
  MergedProblemsAndSubProblems,
  OnHoverStateChangeParams,
  Orientation,
} from 'features/tables/HeatmapTable/types'
import { isMarkedAsTeacher } from 'helpers/solutions.helpers'
import { IStudent, ITeacher } from 'api/types.users'
import React from 'react'
import BodyCell from './BodyCell'
import ColumnHeaderCell from './ColumnHeaderCell'
import styles from './Table.module.scss'
import isEmpty from 'lodash/isEmpty'
import { useUnit } from 'effector-react'
import { $commonErrorData } from '../../../../Solutions/CommonError/model/commonErrorData'
import { pickTheMostPopularErrors } from '../../helpers/data'

type ProblemsColumnsProps = {
  exerciseId: string
  isExamMode: boolean
  mergedProblemsAndSubProblems: MergedProblemsAndSubProblems
  onHeaderElementHoverChange: (params: OnHoverStateChangeParams) => void
  rowsUsers: (IStudent | ITeacher)[]
  tableListHeight: number
}

const ProblemsColumns = ({
  exerciseId,
  isExamMode,
  mergedProblemsAndSubProblems,
  onHeaderElementHoverChange,
  tableListHeight,
  rowsUsers,
}: ProblemsColumnsProps) => {
  const commonErrorData = useUnit($commonErrorData)
  const theMostPopularErrors = pickTheMostPopularErrors(
    commonErrorData || {},
    mergedProblemsAndSubProblems
  )
  return (
    <div className={styles.TableColumns} data-testid={HeatmapTestIds.PROBLEMS_COLUMNS}>
      {mergedProblemsAndSubProblems.map((problemOrSubproblem, indexH) => {
        const isProblem = 'subProblems' in problemOrSubproblem

        const columnHeaderCellPercent = getHeaderCellPercent({
          studentStats: problemOrSubproblem.studentStats ?? [],
          divider: rowsUsers.length - 1,
        })

        const linkToPathname = dynamicPaths.studentsSolutions({
          exerciseId,
          problemId: problemOrSubproblem._id,
          subProblemId: !isProblem ? problemOrSubproblem._id : undefined,
        })

        return (
          <div
            key={problemOrSubproblem._id}
            data-column={indexH}
            className={styles.TableColumn}
            style={{ height: tableListHeight }}
          >
            <ColumnHeaderCell
              linkToPathname={linkToPathname}
              cellName={problemOrSubproblem.cellName}
              percentNumber={columnHeaderCellPercent}
              isCommonError={!isEmpty(theMostPopularErrors?.[problemOrSubproblem._id])}
              onMouseEnter={() =>
                onHeaderElementHoverChange({
                  elementIndex: indexH,
                  newIsHovered: true,
                  orientation: Orientation.COLUMN,
                })
              }
              onMouseLeave={() =>
                onHeaderElementHoverChange({
                  elementIndex: indexH,
                  newIsHovered: false,
                  orientation: Orientation.COLUMN,
                })
              }
            />
            {rowsUsers.map((rowUser, index) => {
              const studentStats = problemOrSubproblem.studentStats?.find(
                (stat) => stat.studentId === rowUser._id
              )
              if (!studentStats) return null
              const { attempt, solved, watched } = studentStats.stats
              const isMarkedAsCorrect = isMarkedAsTeacher(studentStats.stats)
              const cellColor = getCellColorClassName({ isMarkedAsCorrect, studentStats })
              const shouldShowWatchedIcon = watched && isWatchedIconVisibleForColorClass(cellColor)
              const attemptsNumber =
                !shouldShowWatchedIcon && (attempt > 1 || (attempt === 1 && !solved))
                  ? attempt
                  : undefined
              const linkToPathname = dynamicPaths.studentSolutions({
                exerciseId: exerciseId,
                problemId: problemOrSubproblem._id,
                subProblemId: !isProblem ? problemOrSubproblem._id : undefined,
                studentId: rowUser._id,
              })

              return (
                <BodyCell
                  attemptsNumber={attemptsNumber}
                  cellColorClassName={cellColor}
                  data-row={index}
                  isExamMode={isExamMode}
                  key={`${problemOrSubproblem._id}-${rowUser._id}`}
                  linkToPathname={linkToPathname}
                  shouldShowWatchedIcon={shouldShowWatchedIcon}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default ProblemsColumns
