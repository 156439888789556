import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { isEmpty } from 'lodash'
import { timeout } from '../../helpers/modules.helpers'
import { GetSkillsStatisticsPayload, StatisticsType } from '../../api/types.statistics'
import { api } from '../../api'

export enum SKILLS_STATS {
  GET_SKILLS_STATS_REQUEST = 'GET_SKILLS_STATS_REQUEST',
  GET_SKILLS_STUDENTS_STATS_SUCCESS = 'GET_SKILLS_STUDENTS_STATS_SUCCESS',
  GET_SKILLS_CLASSES_STATS_SUCCESS = 'GET_SKILLS_CLASSES_STATS_SUCCESS',
  GET_SKILLS_GROUPS_STATS_SUCCESS = 'GET_SKILLS_GROUPS_STATS_SUCCESS',
  GET_SKILLS_STATS_ERROR = 'GET_SKILLS_STATS_ERROR',
  CLEAR_SKILLS_STATS = 'CLEAR_SKILLS_STATS',
}

const initialState = {
  data: {
    studentsStats: {},
    classesStats: {},
    groupsStats: {},
  },
  error: null,
  loading: false,
}

export const skillsStatsReducer = (state: any = initialState, action: IAction<SKILLS_STATS>) => {
  switch (action.type) {
    case SKILLS_STATS.GET_SKILLS_STATS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SKILLS_STATS.GET_SKILLS_STUDENTS_STATS_SUCCESS:
      return {
        data: { ...state.data, studentsStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_STATS.GET_SKILLS_CLASSES_STATS_SUCCESS:
      return {
        data: { ...state.data, classesStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_STATS.GET_SKILLS_GROUPS_STATS_SUCCESS:
      return {
        data: { ...state.data, groupsStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_STATS.GET_SKILLS_STATS_ERROR:
      return {
        data: {
          studentsStats: {},
          classesStats: {},
          groupsStats: {},
        },
        error: action.payload,
        loading: false,
      }
    case SKILLS_STATS.CLEAR_SKILLS_STATS:
      return {
        data: {
          studentsStats: {},
          classesStats: {},
          groupsStats: {},
        },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

const getActionType = (classIds?: string[], groupsIds?: string[]) => {
  if (classIds && !isEmpty(classIds)) {
    return SKILLS_STATS.GET_SKILLS_CLASSES_STATS_SUCCESS
  }
  if (groupsIds && !isEmpty(groupsIds)) {
    return SKILLS_STATS.GET_SKILLS_GROUPS_STATS_SUCCESS
  }
  return SKILLS_STATS.GET_SKILLS_STUDENTS_STATS_SUCCESS
}

export const getSkillsStats = (exerciseId: string, classIds?: string[], groupsIds?: string[]) => {
  const params: GetSkillsStatisticsPayload = { exerciseId }
  if (!isEmpty(classIds)) {
    params.classIds = classIds?.toString()
  }
  if (!isEmpty(groupsIds)) {
    params.groupIds = groupsIds?.toString()
  }
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SKILLS_STATS.GET_SKILLS_STATS_REQUEST })
      let res = await api.statistics.getSkills(params)
      if (isEmpty(res)) {
        await timeout(1000)
        res = await api.statistics.getSkills(params)
      }
      dispatch({ type: getActionType(classIds, groupsIds), payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SKILLS_STATS.GET_SKILLS_STATS_ERROR,
      })
    }
  }
}

export const clearSkillsStats = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: SKILLS_STATS.CLEAR_SKILLS_STATS })
  }
}

export interface ISkillsStatsActions {
  getSkillsStats: (exerciseId: string, classIds?: string[], groupsIds?: string[]) => void
  clearSkillsStats: () => void
}
