import React, { useMemo } from 'react'
import useText from '../../../../i18n/hook'
import { Listbox } from '@magmamath/ui'
import { OptionsType } from '../StudentsFilter'
import SubHeader from '../../../../ui/filters/SubHeader/SubHeader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { TypesOfParams } from '../../../../helpers/enums'
import { IGroup } from '../../../../api/api.groups'

const GroupsOption = () => {
  const t = useText()
  const groups = useSelector((state: RootState) => state.addedClass.data?.groups)
  const classesFilterValue = useSelector((state: RootState) => state.classesFilter)
  const groupOptions: OptionsType[] = useMemo(() => {
    if (!classesFilterValue.value || classesFilterValue.value === Listbox.ALL_OPTION) return []
    return (groups || []).map((group: IGroup) => ({
      name: group.name,
      value: group._id,
      type: TypesOfParams.GROUPS,
    }))
  }, [groups, classesFilterValue])
  return (
    <>
      {!!groupOptions.length && <SubHeader title={t.groupsTxt} />}
      {groupOptions.map((group) => (
        <Listbox.Option key={group.value} value={group}>
          {group.name}
        </Listbox.Option>
      ))}
    </>
  )
}

export default GroupsOption
