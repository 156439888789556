import clsx from 'clsx'
import React from 'react'
import styles from './ChangeMultiplicityTableUI.module.scss'

type ChangeMultiplicityTableUIProps = JSX.IntrinsicElements['div']

const ChangeMultiplicityTableUI = ({ children, className }: ChangeMultiplicityTableUIProps) => {
  return <div className={clsx(styles.Body, className)}>{children}</div>
}

export default ChangeMultiplicityTableUI
