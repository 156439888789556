import { localStorageKeys } from 'config/localStorageKeys'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import * as React from 'react'
import Profile from 'views/Account/Profile.view'
import Students, { IStudentsProps } from '../../screens/Students/Students'
import { PATHS } from '../../config/pathnames.config'

export const StudentsRoutes = [
  {
    exact: true,
    path: [...PATHS.STUDENTS.STUDENTS_MAIN],
    render: (props: any) => {
      const studentsProps: IStudentsProps = {
        history: props.history,
        localization: props.localization,
        location: props.location,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
        modalActions: props.modalActions,
        notification: props.notify,
      }
      return <Students {...studentsProps} />
    },
  },
  {
    exact: true,
    path: '/students/class/:classId/user/:id',
    render: (props: any) => {
      const studentClassProps: any = {
        authActions: props.authActions,
        // tslint:disable-next-line:object-literal-sort-keys
        autologinActions: props.autologinActions,
        accountActions: props.accountActions,
        addedClass: props.addedClass,
        classActions: props.classActions,
        form: props.form,
        history: props.history,
        location: props.location,
        // tslint:disable-next-line:object-literal-sort-keys
        localization: props.localization,
        match: props.match,
        student: props.user,
        modal: props.modal,
        modalActions: props.modalActions,
        notification: props.notify,
      }
      return <Profile {...studentClassProps} />
    },
  },
  {
    exact: true,
    path: '/students/group/:groupId/user/:id',
    render: (props: any) => {
      const studentGroupProps: any = {
        authActions: props.authActions,
        // tslint:disable-next-line:object-literal-sort-keys
        accountActions: props.accountActions,
        autologinActions: props.autologinActions,
        form: props.form,
        history: props.history,
        group: props.group,
        groupActions: props.groupActions,
        location: props.location,
        // tslint:disable-next-line:object-literal-sort-keys
        localization: props.localization,
        match: props.match,
        student: props.user,
        modal: props.modal,
        modalActions: props.modalActions,
        notification: props.notify,
      }
      return <Profile {...studentGroupProps} />
    },
  },
]
