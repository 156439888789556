import { MathInputModel, MathInputProps } from '../MathInputModel'
import { createEvent, restore } from 'effector'

export class PopUpMathInputModel extends MathInputModel {
  public readonly setIsVisible = createEvent<boolean>()
  public readonly $isVisible = restore(this.setIsVisible, false)

  public constructor(props?: MathInputProps) {
    super(props)
    this.showInput.watch(() => {
      this.setIsVisible(true)
      setTimeout(() => {
        this.focus()
      }, 10)
    })

    this.submitted.watch(() => {
      this.setIsVisible(false)
    })

    this.discarded.watch(() => {
      this.setIsVisible(false)
    })

    this.$isVisible.updates.watch((isVisible) => {
      if (!isVisible) this.hideInput()
    })
  }
}
