import React from 'react'
import { SvgIconProps } from '../types/types'

const PlayForwardIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.389 0.930607C11.6819 0.637714 12.1567 0.637714 12.4496 0.930607L15.2496 3.73061C15.5425 4.0235 15.5425 4.49837 15.2496 4.79127L12.4496 7.59127C12.1567 7.88416 11.6819 7.88416 11.389 7.59127C11.0961 7.29837 11.0961 6.8235 11.389 6.53061L12.9086 5.01094H6.3993C3.98581 5.01094 2.0293 6.96745 2.0293 9.38094V10.4209C2.0293 12.8344 3.98581 14.7909 6.3993 14.7909H11.9193C12.3335 14.7909 12.6693 15.1267 12.6693 15.5409C12.6693 15.9552 12.3335 16.2909 11.9193 16.2909H6.3993C3.15739 16.2909 0.529297 13.6628 0.529297 10.4209V9.38094C0.529297 6.13903 3.15739 3.51094 6.3993 3.51094H12.9086L11.389 1.99127C11.0961 1.69837 11.0961 1.2235 11.389 0.930607Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayForwardIcon
