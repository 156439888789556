import { combine } from 'effector'
import { $groups } from './groups'
import { $students } from './students'
import { selectAllClass, unselectClass } from './class'

export const $groupsAndStudentsStore = combine($groups, $students)

$groupsAndStudentsStore.watch(([groups, students]) => {
  const areAllStudentsAssigned = students.every((student) => student.selected)
  const areAllGroupsAssigned = groups
    .filter((group) => group.studentIds.length)
    .every((group) => group.selected)

  if (!areAllStudentsAssigned) {
    return unselectClass()
  }

  if (areAllGroupsAssigned && areAllStudentsAssigned) {
    return selectAllClass()
  }
})
