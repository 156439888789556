import { getPinsObjectMemoryKey } from '../helpers'
import {
  DeletePinParams,
  PinStoreObjectData,
  PinsDataStore,
  SetCurrentPinsFromMemoryParams,
} from './types'

import { AddPinParams, FetchPinsParams, Pin } from '../../../api/types.pins'

const MAX_MEMOIZED_PINS_ELEMENTS = 500

const _detectMemoryCleanup = (state: PinsDataStore, exerciseId: string) =>
  state.exerciseId !== exerciseId ||
  (state.memoizedPins && Object.keys(state.memoizedPins).length > MAX_MEMOIZED_PINS_ELEMENTS)

export const handleCurrentPinsDataCleared = (state: PinsDataStore) => ({
  ...state,
  currentPinsData: null,
})

export const handleFetchedPinsData = (
  state: PinsDataStore,
  fetchPinsData: {
    params: FetchPinsParams
    pins: Pin[]
  }
) => {
  const { exerciseId, problemId, subProblemId, userId, isCurrent } = fetchPinsData.params
  const shouldClearMemory = _detectMemoryCleanup(state, exerciseId)

  if (userId) {
    // Don't memoize "byUser" requests
    return {
      ...state,
      exerciseId,
      currentPinsData: {
        memoryKey: null,
        pins: fetchPinsData.pins,
      },
    }
  }
  const problemOrSubProblemId = subProblemId || problemId
  if (!problemOrSubProblemId) return state
  const memoryKey = getPinsObjectMemoryKey(exerciseId, problemOrSubProblemId)

  if (isCurrent) {
    return {
      ...state,
      exerciseId,
      currentPinsData: {
        memoryKey,
        pins: fetchPinsData.pins,
      },
      memoizedPins: shouldClearMemory ? null : state.memoizedPins,
    }
  }

  return {
    ...state,
    exerciseId,
    memoizedPins: {
      ...(!shouldClearMemory && state.memoizedPins),
      [memoryKey]: {
        memoryKey,
        pins: fetchPinsData.pins,
      },
    },
  }
}

export const handleAddedPin = (
  state: PinsDataStore,
  addPinData: {
    params: AddPinParams
    newPinData: {
      _id: string
      solutionId: string
    }
    deletedPinIds: string[]
  }
) => {
  const { exerciseId, problemId, subProblemId } = addPinData.params
  const problemOrSubProblemId = subProblemId || problemId
  if (!problemOrSubProblemId || !state.exerciseId) return state
  const memoryKey = getPinsObjectMemoryKey(exerciseId, problemOrSubProblemId)
  const newPinsData = [...(state.currentPinsData?.pins ?? []), addPinData.newPinData].filter(
    (pin) => !addPinData.deletedPinIds.includes(pin._id)
  )
  const newPinObjectStore: PinStoreObjectData = {
    memoryKey,
    pins: newPinsData,
  }

  return {
    ...state,
    currentPinsData: {
      ...state.currentPinsData,
      memoryKey,
      pins: newPinsData,
    },
    memoizedPins: {
      ...state.memoizedPins,
      [memoryKey]: newPinObjectStore,
    },
  }
}

export const handleDeletedPin = (
  state: PinsDataStore,
  deletePinData: {
    params: DeletePinParams
    id: string
  }
) => {
  if (!state.currentPinsData?.pins) return state
  const newPinsData = state.currentPinsData.pins.filter((pin) => pin._id !== deletePinData.id)
  const memoryKey = state.currentPinsData.memoryKey

  if (!memoryKey)
    return {
      ...state,
      currentPinsData: {
        ...state.currentPinsData,
        pins: newPinsData,
      },
    }

  const memoizedPinsElement = memoryKey && state.memoizedPins?.[memoryKey]
  return {
    ...state,
    currentPinsData: {
      ...state.currentPinsData,
      pins: newPinsData,
    },
    memoizedPins: {
      ...state.memoizedPins,
      ...(memoizedPinsElement && {
        [memoryKey]: { ...memoizedPinsElement, pins: newPinsData },
      }),
    },
  }
}

export const handleCurrentPinsSetFromMemory = (
  state: PinsDataStore,
  { problemId, subProblemId, exerciseId }: SetCurrentPinsFromMemoryParams
) => {
  const problemOrSubProblemId = subProblemId || problemId
  if (!problemOrSubProblemId) return state
  const memoryKey = getPinsObjectMemoryKey(exerciseId, problemOrSubProblemId)
  const memoizedPinsData = state.memoizedPins?.[memoryKey]
  if (!memoizedPinsData) return state

  return {
    ...state,
    exerciseId,
    currentPinsData: memoizedPinsData,
  }
}
