import {
  Listbox,
  PieChartNavigation,
  PieChartNavigationSkeleton,
  SelectValue,
  TREE_NODE_TYPE,
} from '@magmamath/ui'
import { useUnit } from 'effector-react'

import useText from 'i18n/hook'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import HeatmapMenu from 'features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuTabs from 'features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabs'
import ColorIndexMenu from 'features/heatmap/ColorIndexMenu/ColorIndexMenu'
import { HeatmapTabsParams } from 'helpers/enums'
import { RootState } from 'store/store'
import EducationEntitySelect from './EducationEntitySelect'
import {
  $isFetchingSkills,
  $skills,
  fetchSkillsDataFx,
  fetchSkillsFx,
  resetSkills,
  setGrade,
} from './model/model'
import { getFullName, getTeacherData } from 'helpers/user.helpers'
import {
  getExerciseWithoutStats,
  IExerciseWithoutStats,
} from 'redux/modules/exerciseWithoutStats.module'
import { useParams } from 'react-router'
import { IState } from 'interfaces/state.interface'
import { getClass } from 'redux/modules/class.module'
import { IStudent } from 'api/types.users'
import HelpQueue from 'features/helpQueue/HelpQueue'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'
import { getFilterData } from './helpers/educationEntityFilterData'
import { getDefaultPath, getDefaultPathWithoutGrade } from './helpers/defaultPath'
import { EducationEntityType } from './types'
import { ILocalization } from 'config/languages.config'
import styles from './NewPieChart.module.scss'
import { ClassroomGroup } from '../../../api/types.classes'
import { currentLocale } from 'config/modules.config'

export enum EntityType {
  Student,
  Group,
  Class,
}

export type TreeNodeType = {
  _id: string
  name: string
  treeType: TREE_NODE_TYPE
  stats: {
    score: number
    size: number
  }
  children: TreeNodeType[]
  path?: string
}

const LEVELS_TO_SKIP = 3
const US_EXCLUDED_GRADES = [9, 10, 11, 12]

const allStudentsItem = (t: ILocalization['data']) => ({
  name: t.allStudentsTxt,
  value: Listbox.ALL_OPTION,
  group: EducationEntityType.STUDENTS,
})

type EducationEntitySelectValue = Required<SelectValue>

const NewPieChart: React.FC = () => {
  const { exerciseId } = useParams<{ exerciseId: string }>()
  const t = useText()
  const [selectedNodePath, setSelectedNodePath] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<EducationEntitySelectValue>(() =>
    allStudentsItem(t)
  )
  const skillsData = useUnit($skills)

  const isSkillsLoading = useUnit($isFetchingSkills)

  const teacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))
  const exerciseWithoutStats: IState<IExerciseWithoutStats> = useSelector(
    (state: RootState) => state.exerciseWithoutStats
  )
  const classGroups: ClassroomGroup[] = useSelector(
    (state: RootState) => state.addedClass.data.groups || []
  )
  const [students, setStudents] = useState<IStudent[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (exerciseWithoutStats.data?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }
    return () => {
      resetSkills()
    }
  }, [])

  useEffect(() => {
    setSelectedValue(allStudentsItem(t))
  }, [t])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }
    const classItem = exerciseWithoutStats.data.classes[0]
    const skipGradeSet =
      classItem.grade === -1 ||
      (currentLocale.currentLang === 'en-SE' && US_EXCLUDED_GRADES.includes(classItem.grade))
    if (!skipGradeSet) {
      setGrade(classItem.grade)
    }
    dispatch(getClass(classItem._id))
    setStudents(exerciseWithoutStats.data.students)
    const studentEntityData = getFilterData(
      selectedValue?.group as EducationEntityType,
      exerciseWithoutStats,
      selectedValue,
      classGroups
    )

    fetchSkillsDataFx({
      assignmentId: exerciseWithoutStats.data?._id,
      ...studentEntityData,
      treeId: teacher.treeId as string,
    })
  }, [exerciseWithoutStats.data?._id])

  useEffect(() => {
    if (!exerciseWithoutStats.data?._id) {
      return
    }

    const classGrade = exerciseWithoutStats.data.classes[0].grade
    if (skillsData.skills && skillsData.nodeIdByGrade && !selectedNodePath) {
      setSelectedNodePath(
        skillsData.grade
          ? getDefaultPathWithoutGrade(skillsData.skills)
          : getDefaultPath(skillsData.skills, classGrade, skillsData.nodeIdByGrade)
      )
    }
  }, [skillsData, selectedNodePath, skillsData.nodeIdByGrade, exerciseWithoutStats])

  const studentItems: SelectValue[] = useMemo(
    () => [
      allStudentsItem(t),
      ...students.map((student) => ({
        name: getFullName(student.firstName, student.lastName),
        value: student._id,
        group: EducationEntityType.STUDENTS,
      })),
    ],
    [students, t]
  )

  const groupItems: SelectValue[] = useMemo(
    () => [
      ...classGroups.map((group) => ({
        name: group.name,
        value: group._id,
        group: EducationEntityType.GROUPS,
      })),
    ],
    [classGroups]
  )

  const handleChangeSelect = useCallback(
    (selectedValue: EducationEntitySelectValue) => {
      setSelectedValue(selectedValue)
      if (!exerciseWithoutStats.data?._id) {
        return
      }

      const studentEntityData = getFilterData(
        selectedValue.group as EducationEntityType,
        exerciseWithoutStats,
        selectedValue,
        classGroups
      )
      fetchSkillsFx({
        assignmentId: exerciseWithoutStats.data?._id,
        ...studentEntityData,
      })
    },
    [exerciseWithoutStats.data?._id, selectedValue, classGroups]
  )

  const isExerciseLoading =
    !exerciseWithoutStats.data?._id || exerciseWithoutStats.data?._id !== exerciseId || !classGroups

  return (
    <div className={styles.PieChartContainer}>
      <HeatmapMenu
        actions={
          <>
            <HelpQueue />
            <EducationEntitySelect
              onChangeSelect={handleChangeSelect}
              studentItems={studentItems}
              groupItems={groupItems}
              selectedValue={selectedValue}
            />
            <VerticalDivider />
            <ColorIndexMenu />
          </>
        }
        navigation={<HeatmapMenuTabs activeTab={HeatmapTabsParams.CHART} />}
        title={exerciseWithoutStats.data.name}
        className={styles.Menu}
      />
      {isExerciseLoading ||
        (isSkillsLoading && !skillsData.skills && (
          <PieChartNavigationSkeleton
            className={styles.PieChartNavigation}
            navigationTitle={t.standardAndSkill}
          />
        ))}

      {skillsData.skills && (
        <PieChartNavigation
          maxCollapsableLevel={skillsData.treeDepth - LEVELS_TO_SKIP}
          data={skillsData.skills.children}
          navigationTitle={t.standardAndSkill}
          classes={{
            PieChartNavigation: styles.PieChartNavigation,
            TreeViewSectionHeader: styles.TreeViewSectionHeader,
          }}
          selectedNodePath={selectedNodePath}
          onSelectedNodePathChange={setSelectedNodePath}
        />
      )}
    </div>
  )
}

export default NewPieChart
