import React, { ComponentType, useEffect, useRef, useState } from 'react'
import styles from './SideBarBottomLinkContainer.module.scss'
import clsx from 'clsx'
import UITooltip from '../../../../../ui/tooltips/UITooltip/Tooltip'
import { useOverflowDetection } from '../../../../../lib/hooks/useOverflowDetection'
import { useTitleRowDetection } from '../../useTitleRowDetection'
import { COLORS } from '@magmamath/ui'

type IconProps = {
  color: string
  size?: number
  title?: string
}

type SideBarBottomLinkContainerProps = {
  title: string
  isActive: boolean
  classes?: {
    container?: string
    title?: string
    hoveredTitle?: string
    activeTitle?: string
    iconWrapper?: string
  }
  icon: ComponentType<IconProps>
}

const SideBarBottomLinkContainer: React.FC<SideBarBottomLinkContainerProps> = ({
  title,
  isActive,
  classes,
  icon,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const hoverIconColor = isHovered ? COLORS.PRIMARY_ORANGE : COLORS.NEUTRAL_7
  const iconColor = isActive ? COLORS.PRIMARY_ORANGE : hoverIconColor
  const { overflowRef: titleRef, isOverflowing } = useOverflowDetection({
    title,
  })
  const IconComponent = icon
  const isTitleContainTwoRows = useTitleRowDetection(title, titleRef)
  return (
    <div
      className={clsx(styles.Container, classes?.container)}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <div className={clsx(styles.IconWrapper, classes?.iconWrapper)}>
        <IconComponent size={20} color={iconColor} title={title ? title[0] : ''} />
      </div>
      <UITooltip title={isOverflowing ? title : null} enterDelay={500} leaveDelay={200}>
        <div
          ref={titleRef}
          className={clsx([
            styles.Title,
            classes?.title,
            isTitleContainTwoRows && styles.TitleLarge,
            isHovered && (classes?.hoveredTitle || styles.HoveredTitle),
            isActive && (classes?.activeTitle || styles.ActiveTitle),
          ])}
        >
          {title}
        </div>
      </UITooltip>
    </div>
  )
}

export default SideBarBottomLinkContainer
