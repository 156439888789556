import React from 'react'
import clsx from 'clsx'
import { Button, BUTTON_VARIANT, COLORS } from '@magmamath/ui'

import styles from './ResultsModalHeader.module.scss'
import { ResultsView } from '../../../../../helpers/enums'
import CrossIcon from '../../../../../ui/icons/CrossIcon/CrossIcon'
import ArrowLeftDefaultIcon from '../../../../../ui/icons/ArrowLeftDefaultIcon/ArrowLeftDefaultIcon'

type ResultsModalHeaderProps = {
  resultsView: ResultsView
  cardName: string
  onClose: () => void
  onBackPress?: () => void
  studentName?: string
}

const ResultsModalHeader = ({
  resultsView,
  cardName,
  studentName,
  onClose,
  onBackPress,
}: ResultsModalHeaderProps) => {
  const isSingleStudentResultView = resultsView === ResultsView.SingleStudentResult
  return (
    <div className={styles.ResultsModalHeaderContainer}>
      <div className={styles.HeaderButtonContainer}>
        {isSingleStudentResultView && (
          <Button
            icon={<ArrowLeftDefaultIcon color={COLORS.NEUTRAL_9} />}
            variant={BUTTON_VARIANT.TERTIARY}
            onClick={onBackPress}
          />
        )}
      </div>
      <span className={clsx(styles.HeaderTitle, { [styles.FullWidth]: isSingleStudentResultView })}>
        {cardName}
        {isSingleStudentResultView && studentName && ` - ${studentName}`}
      </span>
      <div className={styles.HeaderButton}>
        <Button
          icon={<CrossIcon size={14} color={COLORS.NEUTRAL_9} />}
          variant={BUTTON_VARIANT.TERTIARY}
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default ResultsModalHeader
