import React, { useState } from 'react'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import FlagIcon from 'ui/icons/FlagIcon/FlagIcon'
import useText from 'i18n/hook'
import { IProblem, ISubProblem } from '../../api/types.problem'
import ReportErrorModal from './ReportErrorModal/ReportErrorModal'

type ReportErrorProps = {
  subProblem: ISubProblem | null
  problem: IProblem | null
  buttonColor?: BUTTON_COLOR
}

const ReportError = ({ subProblem, problem, buttonColor }: ReportErrorProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const t = useText()

  return (
    <>
      <Button
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.SMALL}
        color={buttonColor || BUTTON_COLOR.ORANGE}
        icon={<FlagIcon color='var(--icon-color)' />}
        tooltipText={t.reportQuestion}
        isActive={isModalOpened}
        onClick={() => setIsModalOpened(true)}
      />
      <ReportErrorModal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        subProblem={subProblem}
        problem={problem}
        buttonColor={buttonColor || BUTTON_COLOR.BLUE}
      />
    </>
  )
}
export default ReportError
