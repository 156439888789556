import React from 'react'
import styles from './MultipleChoiceAnswer.module.scss'
import clsx from 'clsx'
import { MultipleChoice } from '../../../../redux/modules/problemCreator.module'
import katex from 'katex'
import ContentEditableArea from '../../../../ui/inputs/ContentEditableArea/ContentEditableArea'

interface MultipleChoiceAnswerProps {
  items: MultipleChoice[]
}

const MultipleChoiceAnswer: React.FC<MultipleChoiceAnswerProps> = ({ items }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        {items.map(({ value, checked }, index) => {
          if (!value) return null

          return (
            <div key={index} className={styles.ItemWrapper}>
              <ContentEditableArea
                value={katex.renderToString(value, {
                  throwOnError: false,
                })}
                disabled
                className={clsx(styles.Item, { [styles.Active]: checked })}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MultipleChoiceAnswer
