import { localStorageKeys } from 'config/localStorageKeys'
import { IBasicUser, IMe, ITeacher } from 'api/types.users'
import { getItemFromLocalStorageWithParsing } from './storage.helper'

export const getFullName = (firstName?: string, lastName?: string) =>
  `${firstName || ''} ${lastName || ''}`.trim()

export const getTeacherData = (me?: IMe): ITeacher => {
  const teacherData = me ?? getItemFromLocalStorageWithParsing(localStorageKeys.me)
  if (!teacherData) console.log('no me')

  return {
    _id: teacherData._id,
    firstName: teacherData.firstName,
    lastName: teacherData.lastName,
    fullName: getFullName(teacherData.firstName, teacherData.lastName),
    treeId: teacherData.setting.treeId,
  }
}

type CheckIsUserRemovedFromAssignmentProps = {
  currentTeacher?: ITeacher
  student?: IBasicUser
  isCurrentTeacher?: boolean
}

export const checkIsUserRemovedFromAssignment = ({
  currentTeacher,
  student,
  isCurrentTeacher,
}: CheckIsUserRemovedFromAssignmentProps): boolean => {
  if (isCurrentTeacher) return false

  const hasSettingData = !!student?.setting
  if (!currentTeacher) return !hasSettingData

  return !hasSettingData && currentTeacher._id !== student?._id
}
