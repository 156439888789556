import { apiManager } from './apiManager'
import {
  GetAllSkillsPayload,
  GetAllSkillsResponse,
  AssignmentStatisticsEntityType,
  GetAssignmentStatisticsResponse,
} from './types.oldSkills'

type GetAssignmentStatisticsPayload = {
  entityType: AssignmentStatisticsEntityType
  entityIds: string[]
  assignmentId: string
}

export class oldSkillsApi {
  public static readonly getAll = apiManager.get<GetAllSkillsResponse, GetAllSkillsPayload>(
    'skills'
  )

  public static readonly getAssignmentStatistics = apiManager.get<
    GetAssignmentStatisticsResponse,
    GetAssignmentStatisticsPayload
  >(({ assignmentId, entityType, entityIds }) => ({
    url: `statistics/skills/exercises/${assignmentId}/${entityType}`,
    params: { entityIds },
  }))
}
