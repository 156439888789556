import React from 'react'

import { SvgIconProps } from '../types/types'

const EyeIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.87612 3.85612C5.1956 2.76901 6.93995 1.81055 8.99947 1.81055C11.059 1.81055 12.8033 2.76901 14.1228 3.85612C15.4452 4.94564 16.4031 6.21443 16.9073 6.95919C17.338 7.5953 17.33 8.41604 16.8922 9.04408C16.3757 9.78495 15.3968 11.0522 14.0706 12.1412C12.7499 13.2257 11.014 14.1905 8.99947 14.1905C6.98495 14.1905 5.24905 13.2257 3.92836 12.1412C2.60212 11.0522 1.62328 9.78495 1.10677 9.04408C0.668924 8.41604 0.660933 7.5953 1.09162 6.95919C1.59588 6.21443 2.5537 4.94564 3.87612 3.85612ZM4.82993 5.01381C3.66075 5.97708 2.79567 7.11786 2.3337 7.80016C2.25255 7.92002 2.25299 8.06536 2.33726 8.18623C2.81307 8.86873 3.70156 10.0141 4.88026 10.982C6.06452 11.9544 7.47118 12.6905 8.99947 12.6905C10.5278 12.6905 11.9344 11.9544 13.1187 10.982C14.2974 10.0141 15.1859 8.86873 15.6617 8.18623C15.7459 8.06536 15.7464 7.92002 15.6652 7.80016C15.2033 7.11786 14.3382 5.97708 13.169 5.01381C11.9969 4.04811 10.5809 3.31055 8.99947 3.31055C7.418 3.31055 6.00205 4.04811 4.82993 5.01381Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.93552 6.45024C8.11262 6.45024 7.44552 7.11734 7.44552 7.94024C7.44552 8.76315 8.11262 9.43024 8.93552 9.43024C9.75843 9.43024 10.4255 8.76315 10.4255 7.94024C10.4255 7.11734 9.75843 6.45024 8.93552 6.45024ZM5.94552 7.94024C5.94552 6.28891 7.28419 4.95024 8.93552 4.95024C10.5869 4.95024 11.9255 6.28891 11.9255 7.94024C11.9255 9.59158 10.5869 10.9302 8.93552 10.9302C7.28419 10.9302 5.94552 9.59158 5.94552 7.94024Z'
        fill={color}
      />
    </svg>
  )
}

export default EyeIcon
