import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { IMe } from 'api/types.users'
import React from 'react'
import { useEffect, useState } from 'react'
import { getClass } from 'redux/modules/class.module'
import { IModalActions } from 'redux/modules/modal.module'
import { INotify } from '../../redux/modules/notify.module'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import styles from './Students.module.scss'
import { SearchParam } from '../../helpers/enums'
import { RouteComponentProps, useParams } from 'react-router'
import loadable from '@loadable/component'
import { isEmpty } from 'lodash'
import { shouldShowBtn } from '../../lib/permissions'
import NoContentStudentsScreen from '../../features/NoContent/NoContentStudentsScreen/NoContentStudentsScreen'
import StudentsScreenContainer from '../../features/containers/StudentsScreenContainer/StudentsScreenContainer'
import NoDataText from '../../views/Classes/components/NoDataText/NoDataText'
import { getSchoolsByTeacher } from '../../redux/modules/teachersSchools.module'
import { ILocalization } from '../../config/languages.config'
import Spinner from '../../features/Spinner/Spinner'
import { IClass, SourceType } from '../../api/types.classes'
import { Listbox, SelectValue } from '@magmamath/ui'
import { IGroup } from '../../api/api.groups'

const Notification = loadable(
  () => import('./../../components/Notification/Notification.component')
)

export interface IStudentsProps {
  notification: INotify
  localization: ILocalization
  location: Location
  history: RouteComponentProps['history']
  modalActions: IModalActions
  me: IMe
}

export enum StudentsTab {
  MULTIPLICITY = 'MULTIPLICITY',
  SETTINGS = 'SETTINGS',
  GROUPS = 'GROUPS',
}

type TParams = {
  id: string
}

export const isSingleGroupPage = (search?: string): boolean => {
  return !!search?.includes('&group=')
}

const findActiveTab = (search: string): StudentsTab => {
  if (isSingleGroupPage(search)) return StudentsTab.GROUPS
  const searchParamMap = {
    [SearchParam.STUDENTS_TAB]: StudentsTab.MULTIPLICITY,
    [SearchParam.SETTINGS_TAB]: StudentsTab.SETTINGS,
    [SearchParam.GROUPS_TAB]: StudentsTab.GROUPS,
  }

  return searchParamMap[search] || StudentsTab.MULTIPLICITY
}

const Students: React.FC<IStudentsProps> = ({
  modalActions,
  me,
  localization,
  history,
  location,
  notification,
}) => {
  const selectedClass = useSelector((state: RootState) => state.classesFilter)
  const dispatch = useDispatch()
  const currentClass = useSelector((state: RootState) => state.addedClass.data)
  const classes = useSelector((state: RootState) => state.classes)
  const groups = useSelector((state: RootState) => state.groups)
  const teachers = useSelector((state: RootState) => state.teachers.data.data)
  const [classOrGroup, setClassOrGroup] = useState<IClass | IGroup | null>(null)
  const [loading, setLoading] = useState(true)
  const currentClasses = classes.data?._embedded?.classes
  const currentGroups = groups.data?._embedded?.groups
  const activeTab = findActiveTab(history.location.search)
  const isClass = !!(classOrGroup && 'invitationalCode' in classOrGroup)
  const { id } = useParams<TParams>()
  const clearActiveTab = () => {
    history.replace({
      pathname: history.location.pathname,
      search: '',
    })
  }

  useEffect(() => {
    getSchoolsByTeacher({ teacherId: me._id })(dispatch)

    return () => {
      clearActiveTab()
    }
  }, [])

  useEffect(() => {
    clearActiveTab()
  }, [selectedClass])

  useEffect(() => {
    if (classOrGroup === null) {
      setLoading(false)
      return
    }

    setLoading(!!(classOrGroup?._id && classOrGroup._id !== selectedClass?.value))
  }, [selectedClass, classOrGroup])

  useEffect(() => {
    if (!selectedClass || selectedClass.value === Listbox.ALL_OPTION || !currentClasses) {
      return
    }
    if (selectedClass.value && selectedClass.value !== Listbox.ALL_OPTION) {
      dispatch(getClass(selectedClass.value))
      return
    }
  }, [selectedClass, currentClasses])

  useEffect(() => {
    if (!selectedClass?.value || selectedClass.value === Listbox.ALL_OPTION) {
      setClassOrGroup(null)
      return
    }
    if (currentClass?._id) {
      setClassOrGroup(currentClass)
      return
    }
  }, [selectedClass, currentClass])

  const showAddStudentBtn = () => {
    if (!classOrGroup) return false
    if (!('invitationalCode' in (classOrGroup as IClass))) return true
    return shouldShowBtn(
      me.municipality.isPrivate,
      isClass ? classOrGroup.source : me.municipality.source
    )
  }

  const isShowGroupsOnly =
    me?.municipality?.isPrivate && me?.municipality?.source === SourceType.skolon

  const hasTeacherNotArchivedClasses = currentClasses?.some((cl: IClass) => !cl.archived)
  const showCreateClassComponent =
    !hasTeacherNotArchivedClasses && currentClasses && !classes.loading && !isShowGroupsOnly
  const showPickAClass = id === 'null' && !isEmpty(currentClasses) && !isShowGroupsOnly
  const showComponent = currentClasses && currentGroups && !classes.loading
  const showNoStudentsText =
    isClass &&
    !!classOrGroup?.source &&
    activeTab === StudentsTab.MULTIPLICITY &&
    classOrGroup?.students?.length === 0

  if (showComponent) {
    return (
      <>
        <PanelHeader
          backButton={false}
          className='students-header'
          me={me}
          localization={localization}
          history={history}
          location={location}
          classes={classes}
          groups={groups}
        />
        <ViewContainer history={history}>
          <StudentsScreenContainer
            showCreateClassComponent={showCreateClassComponent}
            classOrGroup={classOrGroup}
            selectedOption={selectedClass}
            activeTab={activeTab}
            history={history}
            loading={loading}
            me={me}
            modalActions={modalActions}
            localization={localization}
            teachers={teachers}
            showPickAClass={showPickAClass}
            showAddStudentBtn={showAddStudentBtn}
          />

          {showCreateClassComponent && !showNoStudentsText && (
            <NoContentStudentsScreen me={me} localization={localization} />
          )}
          {showNoStudentsText && (
            <NoDataText containerClassName={styles.NoStudentText}>
              <p>{localization.data.noStudentsText}</p>
              <p>{localization.data.messageUsTxt}</p>
            </NoDataText>
          )}
        </ViewContainer>
        <div className='notification'>
          <Notification
            notification={notification as INotify}
            history={history}
            localization={localization}
          />
        </div>
      </>
    )
  }
  return (
    <div className={styles.LoaderWrapper}>
      <Spinner className={styles.Loader} />
    </div>
  )
}

export default Students
