import { sessionStorageKeys } from 'config/localStorageKeys'
import { attach, createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/session'
import { DEFAULT_EXERCISE_SETTINGS } from '../constants'
import { EXERCISE_SETTINGS, ExerciseSettings } from '../types'
import { $problems } from '../../../bookshelf/Book/model/problems'
import { $selectedProblems } from '../../../bookshelf/Book/model/selectedProblems'
import { $examModeSections } from '../../../bookshelf/Book/model/examModeSections'
import { fetchExerciseFx } from '../../../exerciseDetails/model/requests'
import { CalculatorType } from '../../../../helpers/enums'

type ExerciseSettingsPairs = {
  [K in keyof ExerciseSettings]: { key: K; newValue?: ExerciseSettings[K] }
}

const setExerciseSetting = createEvent<ExerciseSettingsPairs[keyof ExerciseSettingsPairs]>()
const setExerciseSettings = createEvent<Partial<ExerciseSettings>>()
const setExamModeManually = createEvent<boolean>()

const resetExerciseSettings = createEvent()

const $exerciseSettings = createStore<ExerciseSettings>(DEFAULT_EXERCISE_SETTINGS)
  .on(setExerciseSettings, (state, settings) => ({
    ...state,
    ...settings,
  }))
  .on(setExerciseSetting, (state, { key, newValue }) => ({
    ...state,
    [key]: newValue !== undefined ? newValue : !state[key],
  }))
  .on(setExamModeManually, (state, isExamMode) => ({
    ...state,
    [EXERCISE_SETTINGS.IS_EXAM_MODE]: isExamMode,
    [EXERCISE_SETTINGS.IS_EXAM_MODE_MANUALLY_SELECTED]: true,
  }))
  .reset(resetExerciseSettings)

persist({ store: $exerciseSettings, key: sessionStorageKeys.cafExerciseSettings })

export {
  $exerciseSettings,
  setExerciseSetting,
  setExerciseSettings,
  setExamModeManually,
  resetExerciseSettings,
}

export const changeExerciseMode = attach({
  source: {
    selectedProblems: $selectedProblems,
    problems: $problems,
    isExamModeManuallySelected: $exerciseSettings.map((data) => data.isExamModeManuallySelected),
    examModeSections: $examModeSections,
  },
  effect: (
    { selectedProblems, problems, isExamModeManuallySelected, examModeSections },
    sectionId: string
  ) => {
    if (isExamModeManuallySelected || !examModeSections.has(sectionId)) {
      return
    }
    const isExamSectionSelected = [...selectedProblems.keys()].some(
      (key) => examModeSections.has(key) && selectedProblems.get(key)?.size
    )
    const sectionProblems = problems.get(sectionId)

    if (isExamSectionSelected || !sectionProblems) {
      return
    }
    const areAllSelected = sectionProblems.length === selectedProblems.get(sectionId)?.size
    setExerciseSetting({ key: EXERCISE_SETTINGS.IS_EXAM_MODE, newValue: !areAllSelected })
  },
})

export const initExercise = async (exerciseId: string) => {
  // useCache: false for the case of deletion the problem via web-admin to update it's deletedAt field.
  const exercise = await fetchExerciseFx({ id: exerciseId, useCache: false })
  setExerciseSettings({
    allowAnswerChecking: exercise.allowedAnswerLooking,
    isExamMode: exercise.testModeEnabled,
    forcedDrawing: exercise.isRequiredDrawing,
    requireUnit: exercise.isRequiredUnit,
    calculatorType: exercise.drawBoardAvailableFeatures?.calculator ?? CalculatorType.Off,
    cheatDetection: exercise.cheatDetection,
    publishNow: exercise.published,
  })

  return exercise
}
