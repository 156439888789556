import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import _, { isEmpty } from 'lodash'
import { Modal, ModalBody } from 'reactstrap'
import * as Autosuggest from 'react-autosuggest'

import { PATHS } from 'config/pathnames.config'
import { ModalType, StudentsCategoryTypes } from 'helpers/enums'
import { checkSafari } from 'helpers/general'
import { sortABC } from 'helpers/sort.helpers'
import { IState } from 'interfaces/state.interface'
import { IStudent } from 'api/types.users'
import { ICollectionId, ICollectionIdActions } from 'redux/modules/choosing.stud.module'
import { IGroupActions } from 'redux/modules/group.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { ScrollableContainer } from '../ScrollableContainer.component'
import './../../modals.scss'
import { ReactComponent as CloseIcon } from 'assets/img/exit.svg'
import { ReactComponent as SelectedIcon } from 'assets/img/selected.svg'
import PrimaryNormalButton from '../../../basic/Buttons/PrimaryNormalButton/PrimaryNormalButton'
import ModalAutosuggestClearButton from '../ModalAutosuggestClearSearchButton/ModalAutosuggestClearButton'
import '../ModalAutosuggestWindow.scss'
import {
  checkIsGroupDetailPage,
  getActiveClassId,
  getActiveGroup,
} from 'helpers/pathnameChecking.helper'
import { getFullName } from 'helpers/user.helpers'
import { IClass, GetClassesResponse } from '../../../../api/types.classes'
import { IClassesStudents } from '../../../../api/types.classesStudents'
import { GetAllGroupsResponse, IGroup } from '../../../../api/api.groups'

export interface IPopupGroupStudentsAutosuggestProps {
  modalActions?: IModalActions | undefined
  classesStudents: IState<IClassesStudents[]>
  localization?: any
  modal?: IState<IModal> | undefined
  history: RouteComponentProps['history']
  studentsArray?: string[]
  groupActions: IGroupActions
  groupId: string | undefined
  textInput?: any
  collectionId: ICollectionId
  collectionIdActions: ICollectionIdActions
  classes: IState<GetClassesResponse>
  groups: IState<GetAllGroupsResponse>
}

export class PopupGroupStudentsAutosuggest extends React.Component<
  IPopupGroupStudentsAutosuggestProps,
  any
> {
  public notArchivedClasses = this.props.classesStudents.data.filter(
    (simpleClass: IClassesStudents) => !simpleClass.archived
  )

  public suggestionsFromClass = this.notArchivedClasses
    ? (this.notArchivedClasses as any).map((classElement: any) => ({
        name: classElement.name,
        students: classElement.students,
        classOrGroup: StudentsCategoryTypes.Class,
      }))
    : []

  public suggestionsFromOneClass = this.notArchivedClasses
    ? this.notArchivedClasses
        .filter(
          (classElement) =>
            classElement._id === getActiveClassId(this.props.history!.location.pathname)
        )
        .map((classElement) => ({
          name: classElement.name,
          students: classElement.students,
          classOrGroup: StudentsCategoryTypes.Class,
        }))
    : []

  public suggestionsFromGroup =
    this.props?.groups?.data?._embedded?.groups
      .filter((classOrGroup: IClass | IGroup) => !isEmpty(classOrGroup.students))
      .map((groupElement: IClass | IGroup) => ({
        name: groupElement.name,
        students: groupElement.students,
        classOrGroup: StudentsCategoryTypes.Group,
      })) || []

  public inputSearch: any

  constructor(props: any) {
    super(props)
    this.state = {
      isEnable: false,
      modal: false,
      prevStudentsArray: [],
      studentId: '',
      studentsArray: [],
      suggestions: checkIsGroupDetailPage(this.props.history!.location.search)
        ? this.suggestionsFromOneClass
        : // @ts-ignore
          [...sortABC(this.suggestionsFromClass), ...sortABC(this.suggestionsFromGroup)],
      value: '',
    }
    this.toggle = this.toggle.bind(this)
    this.addStudentsForExercises = this.addStudentsForExercises.bind(this)
    this.changeStudentId = this.changeStudentId.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
  }

  public componentDidMount() {
    if (checkIsGroupDetailPage(this.props.history!.location.search)) {
      const currentGroup = getActiveGroup(
        this.props.history!.location.search,
        this.props.groups.data
      )
      const groupStudentsIds = currentGroup?.students.map((student) => student._id) ?? []
      this.setState({ studentsArray: groupStudentsIds, prevStudentsArray: [] })
      return
    }
    if (this.props.history!.location.pathname === PATHS.EXERCISES.ADD_SETTINGS) {
      const studentsIds = this.props.collectionId.studentsIds
      this.setState({ studentsArray: studentsIds, prevStudentsArray: studentsIds })
      return
    }
    if (
      this.props.history!.location.pathname.includes('/group') &&
      this.props.history!.location.pathname.includes('/students')
    ) {
      this.props.collectionIdActions.clearAllEntityForExercises()
      const studentsIds = (this.props.modal as any).data.students.map((student: any) => student._id)
      this.setState({ studentsArray: studentsIds, prevStudentsArray: studentsIds })
      return
    }
  }

  public toggle() {
    this.props.modalActions!.closeGroupStudentAutosuggest()
  }

  public addStudentsForExercises(id: string) {
    const stArr = !_.isEmpty((this.props.collectionId as any).studentsIds)
      ? (this.props.collectionId as any).studentsIds
      : this.state.studentsArray

    if (this.state.studentsArray && this.state.studentsArray.length === 0) {
      const joined = (this.state.studentsArray as string[]).concat(id)
      this.setState({
        studentsArray: [...joined, ...stArr],
      })
    } else {
      if (!(stArr as string[]).includes(id)) {
        this.setState({
          studentsArray: [id, ...stArr],
        })
      } else {
        const joined = stArr.filter((currId: string) => id !== currId)
        this.setState({ studentsArray: [...joined] })
      }
    }
  }

  public componentDidUpdate(
    prevProps: IPopupGroupStudentsAutosuggestProps,
    prevState: IPopupGroupStudentsAutosuggestProps
  ) {
    if (prevState.studentsArray !== this.state.studentsArray) {
      this.props.collectionIdActions.changeStudentsForExercises(this.state.studentsArray)
    }
  }

  public componentWillUnmount() {
    if (this.props.history!.location.pathname.includes('/group')) {
      this.props.collectionIdActions.clearAllEntityForExercises()
    }
  }

  public changeStudentId(value: string) {
    this.setState({ schoolId: value })
  }

  public render() {
    const opened: boolean =
      (this.props.modal as any).data.modalType === ModalType.GroupStudentsAutosuggestModal
    const { value, suggestions } = this.state
    const isSafari: boolean = checkSafari()
    const { saveTxt, addStudentsTxt, searchTxt, emptyUsersList, tryCreateNewUserTxt } =
      this.props.localization!.data
    const theme = {
      container: 'form-group',
      input: 'form-control',
      sectionTitle: 'section-title',
      suggestionFocused: 'active',
    }
    return (
      <Modal
        className='colored-modal custom-modal autosuggest-user'
        fade={!isSafari}
        toggle={this.toggle}
        isOpen={opened}
      >
        <div className='add-existing-user-modal-header'>
          <span className='header-title'>{addStudentsTxt}</span>
          <button
            className='close-modal-button'
            onClick={async () => {
              await this.setState({ studentsArray: this.state.prevStudentsArray })
              this.props.modalActions!.closeGroupStudentAutosuggest()
              if (this.props.history!.location.pathname.includes('/students/group')) {
                this.props.collectionIdActions.changeStudentsForExercises([])
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <ModalBody className='custom-body body-autosuggest-user'>
          {!suggestions || (!suggestions.length && !value) ? (
            <div className='no-users-container'>
              <span className='no-users-text'>{emptyUsersList}</span>
              <span className='no-users-text'>{tryCreateNewUserTxt}</span>
            </div>
          ) : (
            <>
              {value && <ModalAutosuggestClearButton onClick={this.onSearchClear} />}
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                multiSection={true}
                renderSuggestionsContainer={this.renderSuggestionsContainer as any}
                renderSuggestion={this.renderSuggestion}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions as any}
                getSuggestionValue={this.getEmptySuggestionValue}
                alwaysRenderSuggestions={true}
                focusInputOnSuggestionClick={false}
                ref={(input: any) => {
                  this.inputSearch = input
                }}
                inputProps={{
                  onChange: (e, changeEvent) => {
                    this.onChange(e, changeEvent)
                  },
                  placeholder: searchTxt,
                  value,
                }}
                theme={theme}
              />
            </>
          )}
        </ModalBody>
        <div className='add-existing-user-modal-submit-container'>
          <PrimaryNormalButton
            title={saveTxt}
            onClick={() => {
              if (
                [PATHS.EXERCISES.ADD_SETTINGS, PATHS.EXERCISES.EXERCISES_MAIN].includes(
                  this.props.history!.location.pathname
                )
              ) {
                this.props.modalActions!.closeGroupStudentAutosuggest()
              } else {
                const data = checkIsGroupDetailPage(this.props.history!.location.search)
                  ? {
                      studentIds: this.state.studentsArray,
                      classroomId: getActiveClassId(this.props.history!.location.pathname)
                        ? (getActiveClassId(this.props.history!.location.pathname) as string)
                        : undefined,
                    }
                  : { students: this.state.studentsArray }

                this.props.groupActions.editGroup(
                  data,
                  this.props.groupId as string,
                  this.props.history,
                  this.props.localization
                )
                this.setState({ studentsArray: [] })
                this.props.modalActions!.closeGroupStudentAutosuggest()
              }
            }}
          />
        </div>
      </Modal>
    )
  }

  protected onSearchClear = () => {
    this.setState({ value: '' })
    this.onSuggestionsFetchRequested({ value: '' })
  }

  protected renderSuggestion(suggestion: IStudent): JSX.Element {
    const isSelected = _.includes(this.state.studentsArray, suggestion._id)

    return (
      <div
        className={`d-flex justify-content-between align-items-baseline user-wrapper ${
          isSelected ? 'selected' : ''
        }`}
        onClick={() => {
          this.addStudentsForExercises(suggestion._id)
        }}
      >
        <span>
          {suggestion.firstName} {suggestion.lastName}
        </span>
        {_.includes(this.state.studentsArray, suggestion._id) ? (
          <div className='selected-icon'>
            <SelectedIcon />
          </div>
        ) : null}{' '}
      </div>
    )
  }

  protected getSectionSuggestions(section: any) {
    return sortABC(section.students)
  }

  protected renderSectionTitle(section: any) {
    return (
      <span
        className={`section-title ${
          section.classOrGroup === StudentsCategoryTypes.Group && 'section-title_blue'
        }`}
      >
        {section.name}
      </span>
    )
  }

  protected onChange(event: React.FormEvent<any>, { newValue }: Autosuggest.ChangeEvent) {
    const student =
      (_.find(_.flattenDeep(this.state.suggestions.map((sug: any) => sug.students))),
      { firstName: newValue })
    if (student) {
      this.changeStudentId((student as any)._id)
    }
    this.setState({ value: newValue })
  }

  protected onSuggestionsFetchRequested({ value }: any): void {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  protected getSuggestions(value: string): any {
    const allSections: unknown[] = checkIsGroupDetailPage(this.props.history!.location.search)
      ? this.suggestionsFromOneClass
      : // @ts-ignore
        [...sortABC(this.suggestionsFromClass), ...sortABC(this.suggestionsFromGroup)]
    return allSections.reduce((acc: any, section: any) => {
      const filteredStudents = section.students.filter((student: any) =>
        getFullName(student.firstName, student.lastName).toLowerCase().includes(value.toLowerCase())
      )
      if (!isEmpty(filteredStudents)) {
        return acc.concat({ ...section, students: filteredStudents })
      }
      return acc
    }, [])
  }

  protected getEmptySuggestionValue() {
    return ''
  }

  protected renderSuggestionsContainer = ({ containerProps, children }: any) => {
    if (children === null) {
      const { emptyUsersSearchListTxt, tryAnotherNameTxt } = this.props.localization!.data
      return (
        <div className='empty-results-container'>
          <span className='empty-results-text'>{emptyUsersSearchListTxt}</span>
          <span className='empty-results-text'>{tryAnotherNameTxt}</span>
        </div>
      )
    }

    return <ScrollableContainer {...containerProps}>{children}</ScrollableContainer>
  }
}
