import { sortABC, sortByLastName } from 'helpers/sort.helpers'
import { IStudentSolutionStats, IStudentStat } from 'api/types.solutions'
import { IStudent } from 'api/types.users'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { HeatmapSortOptions } from '../model/heatmapSettings'
import { Pin } from '../../../../api/types.pins'

type SortByCorrectnessData = {
  attemptsA: number
  attemptsB: number
  correctAnswersA: number
  correctAnswersB: number
}

type StudentExerciseResults = {
  attempts: number
  correctAnswers: number
  stats: IStudentSolutionStats[]
}

const updateStudentStats = (
  stats: IStudentSolutionStats,
  currentStudentResults: StudentExerciseResults
) => {
  const isSolutionCorrect = stats.answerArray?.at(-1)?.correct || false
  const studentResults = currentStudentResults
  if (stats.answerArray && stats.answerArray.length > 0) {
    studentResults.stats.push(stats)
    studentResults.attempts += stats.attempt
    studentResults.correctAnswers += isSolutionCorrect ? 1 : 0
  }
  return studentResults
}

const initializeStudentStats = (): StudentExerciseResults => ({
  attempts: 0,
  correctAnswers: 0,
  stats: [],
})

const processStudentStats = (
  acc: Map<string, StudentExerciseResults>,
  stats: IStudentStat[]
): Map<string, StudentExerciseResults> => {
  stats.forEach((stat) => {
    if (!acc.has(stat.studentId)) {
      acc.set(stat.studentId, initializeStudentStats())
    }
    const currentStudentStats = acc.get(stat.studentId)!
    acc.set(stat.studentId, updateStudentStats(stat.stats, currentStudentStats))
  })
  return acc
}

const getAllStudentsStatsMap = (exercise: IExerciseWithoutStats) => {
  return exercise.problems.reduce((acc: Map<string, StudentExerciseResults>, problem) => {
    if (problem.subProblems.length === 0) {
      acc = processStudentStats(acc, problem.studentStats)
    }
    problem.subProblems.forEach((subProblem) => {
      if (subProblem.studentStats) {
        acc = processStudentStats(acc, subProblem.studentStats)
      }
    })
    return acc
  }, new Map<string, StudentExerciseResults>())
}

const sortByMostAnswers = (students: IStudent[], exercise: IExerciseWithoutStats) => {
  if (!students.length) return []
  const studentsStats = getAllStudentsStatsMap(exercise)

  return students.sort((userA, userB) => {
    const userAStats = studentsStats.get(userA._id)
    const userBStats = studentsStats.get(userB._id)
    const attemptsForUserA = userAStats?.stats.length || 0
    const attemptsForUserB = userBStats?.stats.length || 0
    if (!userAStats || !userBStats) return 0
    if (attemptsForUserA === attemptsForUserB)
      return sortByCorrectAnswers({
        correctAnswersA: userAStats.correctAnswers,
        attemptsA: userAStats.attempts,
        correctAnswersB: userBStats.correctAnswers,
        attemptsB: userBStats.attempts,
      })
    return attemptsForUserA < attemptsForUserB ? 1 : -1
  })
}
const sortByNumberOfAttempts = (attemptsA = 0, attemptsB = 0) => {
  if (attemptsA === attemptsB) return 0
  if (attemptsA === 0 || attemptsB === 0) return attemptsA < attemptsB ? 1 : -1
  return attemptsA > attemptsB ? 1 : -1
}

const sortByCorrectAnswers = ({
  correctAnswersA,
  attemptsA,
  correctAnswersB,
  attemptsB,
}: SortByCorrectnessData) => {
  if (correctAnswersA === correctAnswersB) return sortByNumberOfAttempts(attemptsA, attemptsB)
  return correctAnswersA < correctAnswersB ? 1 : -1
}

const sortByMostCorrectAnswers = (students: IStudent[], exercise: IExerciseWithoutStats) => {
  if (!students.length) return []
  const studentsStats = getAllStudentsStatsMap(exercise)
  return students.sort((userA, userB) => {
    const userAStats = studentsStats.get(userA._id)
    const userBStats = studentsStats.get(userB._id)
    if (!userAStats || !userBStats) return 0

    return sortByCorrectAnswers({
      correctAnswersA: userAStats.correctAnswers,
      attemptsA: userAStats.attempts,
      correctAnswersB: userBStats.correctAnswers,
      attemptsB: userBStats.attempts,
    })
  })
}

const getSortedStudentsMap = (dataOfExercise: IExerciseWithoutStats) => ({
  [HeatmapSortOptions.FIRST_NAME]: (students: IStudent[]) => sortABC(students) as IStudent[],
  [HeatmapSortOptions.LAST_NAME]: (students: IStudent[]) => sortByLastName(students),
  [HeatmapSortOptions.MOST_ANSWERS]: (students: IStudent[]) =>
    sortByMostAnswers(students, dataOfExercise),
  [HeatmapSortOptions.MOST_CORRECT_ANSWERS]: (students: IStudent[]) =>
    sortByMostCorrectAnswers(students, dataOfExercise),
  [HeatmapSortOptions.RANDOM]: (students: IStudent[]) => students.sort(() => Math.random() - 0.5),
  // We do not have this sorting option in the heatmap view - so setting the default sorting mechanism as a backup in case we forget to switch the option to any existing one.
  [HeatmapSortOptions.PINNED_SOLUTIONS]: (students: IStudent[]) =>
    sortByMostAnswers(students, dataOfExercise),
})

export const handleStudentsSort = (
  students: IStudent[],
  sortOption: HeatmapSortOptions,
  dataOfExercise: IExerciseWithoutStats
): IStudent[] => {
  const studentsCopy = [...students]
  const sortedStudentsMap = getSortedStudentsMap(dataOfExercise)

  return sortedStudentsMap[sortOption](studentsCopy)
}

export const sortUserStatsByPinsOrder = (userStats: IStudentStat[], pins: Pin[]) => {
  const pinOrder = pins.map((pin) => pin.solutionId)
  return userStats.sort((userStatA, userStatB) => {
    if (!userStatA.stats.answerArray || !userStatB.stats.answerArray) return 0
    const lastAId = userStatA.stats.answerArray?.at(-1)?._id
    const lastBId = userStatB.stats.answerArray?.at(-1)?._id

    const notFoundIndex = pinOrder.length

    const indexOfPinA = lastAId ? pinOrder.indexOf(lastAId) : notFoundIndex
    const indexOfPinB = lastBId ? pinOrder.indexOf(lastBId) : notFoundIndex

    if (indexOfPinA === indexOfPinB) return 0
    return indexOfPinA > indexOfPinB ? 1 : -1
  })
}
