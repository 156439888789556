import React from 'react'
import ProblemLevels from './ProblemLevels/ProblemLevels'
import styles from './Problems.module.scss'
import { PROBLEM_LEVELS } from '../types'
import { IProblem } from '../../../../../api/types.problem'

type ProblemsProps = {
  sectionId: string
  problemsByLevel: Map<PROBLEM_LEVELS, IProblem[]>
}

const Problems = ({ problemsByLevel, sectionId }: ProblemsProps) => {

  return (
    <div className={styles.Section} id={sectionId}>
      <ProblemLevels sectionId={sectionId} problemsByLevel={problemsByLevel} />
    </div>
  )
}

export default Problems
