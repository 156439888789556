import React, { useState } from 'react'
import styles from '../BooksFilter/BooksFilter.module.scss'
import { Listbox } from '@magmamath/ui'
import useText from '../../../i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearBookSeriesNames,
  reloadBookSeriesNames,
} from '../../../redux/modules/books-names.book-series.module'
import { Divider } from '@mui/material'
import { getBookSeriesIds } from '../../../views/Content/BookSeries/fetchBookSeruesNames'
import { RootState } from '../../../store/store'
import { BookSeriesData } from '../../../api/types.bookSeries'

const ALL_BOOKS_ID = 'ALL_BOOKS'

type OptionsType = {
  name: string
  value: string
}

type BooksFilterProps = {
  booksSeriesData: BookSeriesData[]
}

const BooksFilter = ({ booksSeriesData }: BooksFilterProps) => {
  const t = useText()
  const dispatch = useDispatch()
  const allBooks = {
    value: ALL_BOOKS_ID,
    name: t.allBooks,
  }
  const [selectedBookSeries, setSelectedBookSeries] = useState<OptionsType>(allBooks)
  const bookSeriesNames = booksSeriesData.map(({ name, _id }) => ({ name, value: _id }))
  const me = useSelector((state: RootState) => state.auth.data.me)
  const onChange = (data: OptionsType) => {
    if (data.value === ALL_BOOKS_ID) {
      setSelectedBookSeries({ value: data.value, name: t.allBooks })
      dispatch(
        reloadBookSeriesNames(
          getBookSeriesIds(
            me,
            booksSeriesData.map((book) => book._id)
          )
        )
      )
      return
    }

    const foundItem = booksSeriesData.find((item) => item._id === data.value)
    if (!foundItem) {
      setSelectedBookSeries({ value: '', name: '' })
      dispatch(clearBookSeriesNames())
      return
    }

    setSelectedBookSeries({ value: data.value, name: foundItem.name })
    dispatch(reloadBookSeriesNames(getBookSeriesIds(me, [data.value])))
  }

  return (
    <div className={styles.ListBoxWrapper}>
      <Listbox
        classes={{ ListboxOptions: styles.ListBoxOptions }}
        value={selectedBookSeries}
        onChange={onChange}
        multiple={false}
        isActive={selectedBookSeries.value !== ALL_BOOKS_ID}
      >
        <Listbox.Option value={allBooks} key={allBooks.value}>
          {allBooks.name}
        </Listbox.Option>
        <Divider className={styles.Divider} />
        {bookSeriesNames.map((option) => (
          <Listbox.Option value={option} key={option.value}>
            {option.name}
          </Listbox.Option>
        ))}
      </Listbox>
    </div>
  )
}
export default BooksFilter
