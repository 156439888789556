import React from 'react'
import styles from './PrintProblemsCardHeader.module.scss'

type PrintProblemsCardHeaderProps = {
  title: string
}
const PrintProblemsCardHeader = ({ title }: PrintProblemsCardHeaderProps) => {
  return <div className={styles.Title}>{title}</div>
}
export default PrintProblemsCardHeader
