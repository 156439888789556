import { ModalType } from 'helpers/enums'
import { checkSafari } from 'helpers/general'
import { sortABC } from 'helpers/sort.helpers'
import { INamedDocument } from 'interfaces/basic.interfaces'
import { IState } from 'interfaces/state.interface'
import { IBasicUser, IMe } from 'api/types.users'
import _ from 'lodash'
import * as React from 'react'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import NavLink from 'reactstrap/lib/NavLink'
import { IClassesActions } from 'redux/modules/classes.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import { ISchoolClasses } from 'redux/modules/schoolClasses.module'
import './../modals.scss'
import './ClassesModal.component.scss'
import { ILocalization } from '../../../config/languages.config'
import { IClass, ISchoolClass } from '../../../api/types.classes'

interface IClassesModalProps {
  modalActions: IModalActions
  localization: ILocalization
  modal: IState<IModal>
  history: any
  addedClass: IState<IClass>
  me: IMe
  schoolClasses: IState<ISchoolClasses>
  classesActions: IClassesActions
}

export class ClassesModal extends React.Component<IClassesModalProps, any> {
  public close = (): void => {
    this.props.modalActions.closeClassesModal()
  }

  public render() {
    const { localization, classesActions, schoolClasses } = this.props
    const opened: boolean = (this.props.modal as any).data.modalType === ModalType.ClassesModal
    const isSafari: boolean = checkSafari()
    const { saveTxt, changeClass, cancelTxt } = localization.data
    const sortedClasses: (INamedDocument | IBasicUser)[] = !_.isEmpty(schoolClasses.data)
      ? sortABC(schoolClasses.data as any)
      : []

    return (
      <Modal
        className='colored-modal custom-modal school-classes-modal'
        fade={!isSafari}
        toggle={this.close}
        isOpen={opened}
      >
        <ModalHeader className='custom-header school-classes-modal-header'>
          <NavLink
            className='button-school-classes'
            onClick={() => {
              this.props.modalActions!.closeClassesModal()
            }}
          >
            {cancelTxt}
          </NavLink>{' '}
          <p>{changeClass}</p>
          <NavLink className='button-school-classes'>{saveTxt}</NavLink>
        </ModalHeader>
        <ModalBody className='custom-body school-classes-modal-body'>
          <ul>
            {!schoolClasses.loading &&
              !!sortedClasses &&
              !_.isEmpty(sortedClasses) &&
              sortedClasses
                .filter(
                  (schoolClass: ISchoolClass) => schoolClass._id !== this.props.addedClass.data._id
                )
                .map((schoolClass: ISchoolClass) => {
                  const movedStudents: string[] = [this.props.modal.data.id]
                  const classesMovingObject: {
                    from: string
                    to: string
                  } = {
                    from: this.props.addedClass.data._id,
                    to: schoolClass._id,
                  }
                  return (
                    <li
                      key={schoolClass._id}
                      onClick={() => {
                        const data: {
                          schoolClass: {
                            from: string
                            to: string
                          }
                          students: string[]
                        } = {
                          schoolClass: classesMovingObject,
                          students: movedStudents,
                        }
                        classesActions.moveStudents(
                          data,
                          this.props.addedClass.data._id,
                          this.props.history,
                          this.props.localization
                        )
                      }}
                    >
                      <span>{schoolClass.name}</span>{' '}
                      {schoolClass.owner && (
                        <span>
                          ({schoolClass.owner.firstName} {schoolClass.owner.lastName})
                        </span>
                      )}
                    </li>
                  )
                })}
          </ul>
        </ModalBody>
      </Modal>
    )
  }
}
