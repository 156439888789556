import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import InfoMenu from 'features/heatmap/ColorIndexMenu/ColorIndexMenu'
import AnonymousCorrectnessButton from 'features/tables/HeatmapTable/ui/AnonymousCorrectnessButton/AnonymousCorrectnessButton'
import AnonymousNameButton from 'features/tables/HeatmapTable/ui/AnonymousNameButton/AnonymousNameButton'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SkillsTable from '../../components/SkillsTable/SkillsTable'
import { ILocalization } from '../../config/languages.config'
import Spinner from '../../features/Spinner/Spinner'
import HeatmapMenu from '../../features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuTabs from '../../features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabs'
import SortMenu from '../../features/heatmap/SortMenu/SortMenu'
import { HeatmapTabsParams, socketEvents } from '../../helpers/enums'
import socket from '../../helpers/socket.helper'
import { IState } from '../../interfaces/state.interface'
import { IMe } from '../../api/types.users'
import { IAutologinActions } from '../../redux/modules/autologin.module'
import { getExercise, IExerciseActions } from '../../redux/modules/exercise.module'
import { reloadAllSkills } from '../../redux/modules/skills.module'
import { getSkillsStats } from '../../redux/modules/skillsStat.module'
import { RootState } from '../../store/store'
import styles from './Skills.module.scss'
import HelpQueue from 'features/helpQueue/HelpQueue'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'
import {
  getExerciseWithoutStats,
  IExerciseWithoutStats,
} from 'redux/modules/exerciseWithoutStats.module'
import { useParams } from 'react-router'

import { GetAllAssignmentsResponse, IExercise } from '../../api/types.assignments'
import { GetClassesResponse } from '../../api/types.classes'
import { ISkills } from '../../api/types.statistics'
import { GetAllGroupsResponse } from '../../api/api.groups'

export interface ISkillsProps {
  history: any
  localization: ILocalization
  exercise: IState<IExercise>
  exerciseActions: IExerciseActions
  classes: IState<GetClassesResponse>
  groups: IState<GetAllGroupsResponse>
  me: IMe
  exercises: IState<GetAllAssignmentsResponse>
  autologinActions: IAutologinActions
}

const Skills: React.FC<ISkillsProps> = ({ exercise, localization, exerciseActions, history }) => {
  const { exerciseId } = useParams<{ exerciseId: string }>()
  const skillsStats = useSelector((state: RootState) => state.skillsStats.data.studentsStats)
  const skillsData = useSelector((state: RootState) => state.skills.data.items)
  const exerciseWithoutStats: IState<IExerciseWithoutStats> = useSelector(
    (state: RootState) => state.exerciseWithoutStats
  )

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const buttonColor = getButtonColor(exercise.data.testModeEnabled)

  useEffect(() => {
    if (exerciseWithoutStats.data?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }
    if (!exercise?.data?._id || exercise.data?._id !== exerciseId) {
      dispatch(getExercise(exerciseId))
      socket.on(socketEvents.statisticsUpdate, async (data: { exerciseId: string }) => {
        await getExerciseAfterSocketReaction(data, exerciseId)
      })
    }
    return () => {
      socket.removeListener(socketEvents.statisticsUpdate)
    }
  }, [exerciseId])

  useEffect(() => {
    if (exercise?.data?._id) {
      dispatch(getSkillsStats(exerciseId))
      setIsLoading(false)
    }
    return () => {
      setIsLoading(true)
    }
  }, [exerciseId, exercise?.data?._id])

  useEffect(() => {
    if (!isEmpty(skillsStats)) {
      const skillsIds = skillsStats?.skills
        .filter((skillStats: ISkills) => skillStats.skillId)
        .map((skillStatsWithId: ISkills) => skillStatsWithId.skillId)
      !isEmpty(skillsIds)
        ? dispatch(reloadAllSkills(skillsIds))
        : history.push(`/exercises/${exercise?.data?._id}/heatmap`)
    }
  }, [skillsStats])

  const getExerciseAfterSocketReaction = async (
    socketResData: { exerciseId: string },
    exerciseId: string
  ): Promise<any> => {
    if (history.location.pathname.includes('skills') && socketResData.exerciseId === exerciseId) {
      await exerciseActions.getExercise(socketResData.exerciseId)
    }
  }
  return (
    <div className={styles.SkillsContainer}>
      <HeatmapMenu
        actions={
          <>
            <HelpQueue />
            <VerticalDivider />
            <InfoMenu />
            <AnonymousNameButton buttonColor={buttonColor} />
            <AnonymousCorrectnessButton buttonColor={buttonColor} />
            <SortMenu buttonColor={buttonColor} />
          </>
        }
        navigation={<HeatmapMenuTabs activeTab={HeatmapTabsParams.SKILLS} />}
        title={exercise.data.name}
      />
      {exercise?.data?._id &&
      !isEmpty(skillsStats) &&
      !isLoading &&
      exercise.data?._id === exerciseId ? (
        <SkillsTable
          history={history}
          exercise={exercise}
          localization={localization}
          skillsStats={skillsStats}
          skillsData={skillsData}
          isPrinting={false}
        />
      ) : (
        <Spinner className='loader' />
      )}
    </div>
  )
}

export default Skills
