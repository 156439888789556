import { createEffect } from 'effector'
import { SendReportProps } from './types'
import { api } from 'api'
import { RESPONSE_SUCCESS_STATUS, DEFAULT_ERROR_STATUS } from './constants'

const sendReportFx = createEffect(async (params: SendReportProps) => {
  try {
    await api.reportNewIssue({ type: 1, payload: params })
    return RESPONSE_SUCCESS_STATUS
  } catch (error) {
    return error.response?.status || DEFAULT_ERROR_STATUS
  }
})

export {
  sendReportFx
}