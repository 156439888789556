import React from 'react'
import { SvgIconProps } from '../types/types'

const RulerIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.481357 10.3382C-0.160452 10.98 -0.160453 12.0206 0.481357 12.6624L3.3376 15.5186C3.97941 16.1605 5.01999 16.1605 5.6618 15.5186L15.5186 5.6618C16.1605 5.01999 16.1605 3.97941 15.5186 3.3376L12.6624 0.481358C12.0206 -0.160452 10.98 -0.160453 10.3382 0.481357L0.481357 10.3382ZM1.46144 11.6823C1.36092 11.5818 1.36092 11.4188 1.46144 11.3183L2.1965 10.5832L3.80664 12.1934C4.01929 12.406 4.36406 12.406 4.57671 12.1934C4.78936 11.9807 4.78935 11.6359 4.57671 11.4233L2.96657 9.81315L4.10067 8.67906L5.01074 9.58914C5.22339 9.80178 5.56816 9.80178 5.78081 9.58914C5.99346 9.37649 5.99346 9.03172 5.78081 8.81907L4.87073 7.90899L6.00483 6.77489L7.61497 8.38503C7.82761 8.59768 8.17239 8.59768 8.38503 8.38503C8.59768 8.17238 8.59768 7.82761 8.38503 7.61497L6.7749 6.00483L7.90899 4.87073L8.81907 5.78081C9.03172 5.99346 9.37649 5.99346 9.58914 5.78081C9.80178 5.56816 9.80178 5.22339 9.58914 5.01074L8.67906 4.10067L9.81315 2.96657L11.4233 4.57671C11.6359 4.78935 11.9807 4.78935 12.1934 4.57671C12.406 4.36406 12.406 4.01929 12.1934 3.80664L10.5832 2.1965L11.3183 1.46144C11.4188 1.36092 11.5818 1.36092 11.6823 1.46144L14.5386 4.31769C14.6391 4.41821 14.6391 4.58119 14.5386 4.68172L4.68172 14.5386C4.58119 14.6391 4.41821 14.6391 4.31769 14.5386L1.46144 11.6823Z'
        fill={color}
      />
    </svg>
  )
}

export default RulerIcon
