import { LayoutIds } from 'helpers/enums'
import React from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import UITab from 'ui/tabs/UITab/UITab'
import UITabs from 'ui/tabs/UITabs/UITabs'
import styles from './HeatmapMenuTabs.module.scss'

const NUMBER_OF_TABS = 3

const HeatmapMenuTabsSkeleton = () => {
  return (
    <UITabs layoutId={LayoutIds.HEATMAP_TABS} withDividerBottom={true} className={styles.TabsList}>
      {[...Array(NUMBER_OF_TABS).keys()].map((_, index) => (
        <UITab
          className={styles.Tab}
          key={index}
          isActive={false}
          title={''}
          layoutId={LayoutIds.HEATMAP_TABS}
        >
          <Skeleton variant='rounded' width={100} height={25} />
        </UITab>
      ))}
    </UITabs>
  )
}

export default HeatmapMenuTabsSkeleton
