import { sessionStorageKeys } from 'config/localStorageKeys'
import { useEffect, useState } from 'react'

const sessionStorageKey = sessionStorageKeys.isReload

const initializeReloadState = () => {
  const isReload = sessionStorage.getItem(sessionStorageKey)
  sessionStorage.removeItem(sessionStorageKey)
  return !!isReload
}

const usePageReloadDetector = () => {
  const [isPageReload] = useState(initializeReloadState)

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem(sessionStorageKey, 'true')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return isPageReload
}

export default usePageReloadDetector
