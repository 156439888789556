import React from 'react'
import clsx from 'clsx'

import { COLORS } from '@magmamath/ui'
import useText from 'i18n/hook'
import TimeIcon from 'ui/icons/TimeIcon/TimeIcon'
import UIModal from 'ui/modals/UIModal/UIModal'
import styles from './HelpQueueModal.module.scss'
import HelpQueueModalContent from './HelpQueueModalContent/HelpQueueModalContent'

type HelpQueueModalProps = {
  onClose: () => void
  isOpen: boolean
  className?: string
}

const HelpQueueModal = ({ onClose, isOpen, className }: HelpQueueModalProps) => {
  const t = useText()

  return (
    <UIModal
      onClose={onClose}
      open={isOpen}
      className={styles.Modal}
      wrapperClassName={clsx(styles.Wrapper, className)}
      closeIconWrapperClassName={styles.CloseIcon}
    >
      <>
        <header className={styles.ModalHeader}>
          <p className={styles.Title}>{t.helpQueue}</p>
          <TimeIcon color={COLORS.NEUTRAL_9} size={20} />
        </header>
        <HelpQueueModalContent />
      </>
    </UIModal>
  )
}

export default HelpQueueModal
