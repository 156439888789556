import React from 'react'
import styles from './SidebarProblemsSettingsFooter.module.scss'
import Divider from '../../../../../ui/dividers/Divider/Divider'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import useText from '../../../../../i18n/hook'

type SidebarProblemsSettingsFooterProps = {
  isPrinting: boolean
  onClose: () => void
  onPrint: () => void
}
const SidebarProblemsSettingsFooter = ({
  isPrinting,
  onClose,
  onPrint,
}: SidebarProblemsSettingsFooterProps) => {
  const t = useText()
  return (
    <>
      <Divider className={styles.Divider} />
      <div className={styles.BtnWrapper}>
        <Button size={SIZE.MEDIUM} variant={BUTTON_VARIANT.TERTIARY} onClick={onClose}>
          {t.cancelTxt}
        </Button>
        <div className={styles.Print}>
          <Button
            size={SIZE.MEDIUM}
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onPrint}
            isLoading={isPrinting}
          >
            {t.printTxt}
          </Button>
        </div>
      </div>
    </>
  )
}
export default SidebarProblemsSettingsFooter
