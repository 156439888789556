import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { COLORS } from '@magmamath/ui'

export const ModalBox = styled(Box)({
  position: 'absolute',
  top: 200,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '450px',
  border: '1px solid #00000',
  background: COLORS.NEUTRAL_1,
  borderRadius: '3px',
})
