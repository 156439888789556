import { ChangeGradeActions } from 'api/types.oldPractice'


type ChangeYearProps = {
  type: ChangeGradeActions
  currentGrade: number
  filteredGrades: number[]
}

type ChangedYearProps = ChangeYearProps & {
  newGrade: number
}

export const getIsChangeYearDisabled = ({
  type,
  currentGrade,
  filteredGrades,
}: ChangeYearProps) => {
  if (type === ChangeGradeActions.DECREASE) {
    return currentGrade <= filteredGrades[0]
  }

  return currentGrade >= filteredGrades[filteredGrades.length - 1]
}

export const getChangedGrade = ({
  type,
  currentGrade,
  newGrade,
  filteredGrades,
}: ChangedYearProps) => {
  const direction = type === ChangeGradeActions.DECREASE ? -1 : +1

  let result = newGrade
  while (
    !filteredGrades.includes(newGrade) &&
    !getIsChangeYearDisabled({ type, currentGrade, filteredGrades })
  ) {
    result += direction
  }

  return result
}
