import { createTheme } from '@mui/material'

import fontStyle from '../scss/fonts.scss'
import { COLORS } from '@magmamath/ui'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#6abfdf',
      light: '#ffffff',
    },
    secondary: {
      light: '#b3b3b3',
      dark: '#6b747a',
      main: '#949494',
    },
    error: {
      light: '#EC7E5E',
      dark: '#d32f2f',
      main: '#f44336',
    },
    success: {
      light: '#EC7E5E',
      dark: COLORS.PRIMARY_GREEN,
      main: '#52b382',
    },
  },
  typography: {
    fontFamily: fontStyle.defaultFont,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#000000',
          fontFamily: fontStyle.defaultFont,
          fontSize: '10px',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 9999,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontStyle.defaultFont,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontStyle.defaultFont,
          color: '#ffffff',
          fontWeight: 400,
          fontSize: 14,
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: COLORS.PRIMARY_GREEN,
            '&:hover': {
              backgroundColor: '#52b382',
            },
          },
        },
      ],
    },
  },
})
