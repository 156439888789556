import { IDispatch } from 'interfaces/dispatch.interface'
import { SubmissionError } from 'redux-form'
import { ResponseType } from './enums'

export type ErrorWithMessage = {
  message: string
}

export function dispatchError(dispatch: IDispatch<any>, error: any, type: string) {
  dispatch({
    payload: !!error.response ? { ...error.response.data } : { message: error.message, ...error },
    type,
  })
}

export function handleError(error: any, dispatch: IDispatch<any>, type: string) {
  if (!!error.response === true) {
    const details = error.response.data.error_details
    if (details && details.includes('fields')) {
      const arr: any[] = JSON.parse(details.substr(10))
      const result = arr.reduce((obj, item) => {
        obj[item.field] = ResponseType[item.code]
        return obj
      }, {})
      dispatch({
        type,
      })
      throw new SubmissionError(result)
    } else {
      dispatchError(dispatch, error, type)
    }
  }
}
