import React from 'react'
import styles from './PrintProblemsCard.module.scss'
import PrintProblemsCardHeader from './PrintProblemsCardTHeader/PrintProblemsCardHeader'
import PrintProblemsCardBody from './PrintProblemsCardBody/PrintProblemsCardBody'
import PrintProblemsCardFooter from './PrintProblemsCardFooter/PrintProblemsCardFooter'
import { IProblem, ISubProblem } from '../../../api/types.problem'

type PrintProblemsCardProps = {
  problemOrSubProblem: IProblem | ISubProblem
  exerciseName: string
}
const PrintProblemsCard = ({ problemOrSubProblem, exerciseName }: PrintProblemsCardProps) => {
  return (
    <div className={styles.Container}>
      <PrintProblemsCardHeader title={exerciseName} />
      <PrintProblemsCardBody problemOrSubProblem={problemOrSubProblem} />
      <PrintProblemsCardFooter
        answerType={problemOrSubProblem.answerType}
        answerVariants={problemOrSubProblem.answerVariants}
      />
    </div>
  )
}
export default PrintProblemsCard
