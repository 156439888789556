import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { ISchoolClass } from '../../api/types.classes'
import { api } from '../../api'

// SchoolsClasses constants
export enum SCHOOLS_CLASSES {
  SCHOOLS_CLASSES_GET_REQUEST = 'SCHOOLS_CLASSES_GET_REQUEST',
  SCHOOLS_CLASSES_GET_SUCCESS = 'SCHOOLS_CLASSES_GET_SUCCESS',
  SCHOOLS_CLASSES_GET_ERROR = 'SCHOOLS_CLASSES_GET_ERROR',
  SCHOOLS_CLASSES_UPDATE_SUCCESS = 'SCHOOLS_CLASSES_UPDATE_SUCCESS',
  SCHOOLS_CLASSES_CLEAR = 'SCHOOLS_CLASSES_CLEAR',
}

// SchoolsClasses reducer
const initialState = {
  data: {
    schoolClasses: [],
  },
  error: null,
  loading: false,
}

export function schoolsClassesReducer(state: any = initialState, action: IAction<SCHOOLS_CLASSES>) {
  switch (action.type) {
    case SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SCHOOLS_CLASSES.SCHOOLS_CLASSES_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case SCHOOLS_CLASSES.SCHOOLS_CLASSES_CLEAR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

// SchoolClasses actions
export function getSchoolClasses(schoolId: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_REQUEST })
      const res = await api.classes.getBySchool(schoolId)
      dispatch({ type: SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SCHOOLS_CLASSES.SCHOOLS_CLASSES_GET_ERROR,
      })
    }
  }
}

export const clearSchoolClasses = () => (dispatch: IDispatch<any>) =>
  dispatch({ type: SCHOOLS_CLASSES.SCHOOLS_CLASSES_CLEAR })

export interface ISchoolClassesActions {
  getSchoolClasses: (schoolId: string) => void
}

export interface ISchoolClasses {
  schoolClasses: ISchoolClass[]
}
