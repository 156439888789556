import React from 'react'
import { SvgIconProps } from '../types/types'

const SchoolIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.999756 24H1.99976M25.0001 24H23.9998M1.99976 24V13M1.99976 24H6.49976M1.99976 13H0.999756L2.49976 9.5H9.99976M1.99976 13H6.49976M23.9998 13H25.0001L23.4998 9.5H15.9998M23.9998 13V24M23.9998 13H19.4998M23.9998 24H19.4998M6.49976 13L12.9998 6.5M6.49976 13V24M12.9998 6.5L19.4998 13M12.9998 6.5V4.5M19.4998 13V24M12.9998 4.5V2H15.9998V4.5H12.9998ZM10.4998 24V18.5H15.4998V24M19.4998 24H15.9998H9.99976H6.49976'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4998 13.5C15.4998 12.1193 14.3805 11 12.9998 11C11.619 11 10.4998 12.1193 10.4998 13.5C10.4998 14.8807 11.619 16 12.9998 16C14.3805 16 15.4998 14.8807 15.4998 13.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default SchoolIcon
