import { apiManager } from './apiManager'
import { GetAllChaptersResponse, GetMyBooksResponse, IBook, IMyBook } from './types.books'

type GetByBookSeriesPayload = {
  limit?: number
  offset?: number
  bookSeriesIds?: string
}

type GetByBookSeriesResponse = {
  items: IBook[]
}

type GetAllChaptersPayload = {
  bookId: string
  page?: number
}

export type GetMyBookPayload = {
  fetchAll?: 1 | 0
  isInitial?: 1 | 0
  fetchShowTo?: boolean
  classId?: string
  groupId?: string
}

export class booksApi {
  public static readonly getMyBook = apiManager.get<GetMyBooksResponse, GetMyBookPayload>('books')
  public static readonly getSingle = apiManager.get<IMyBook, string>('books')
  public static readonly getAllChapters = apiManager.get<
    GetAllChaptersResponse,
    GetAllChaptersPayload
  >(({ bookId, ...params }) => ({
    url: `books/${bookId}/chapters`,
    params,
  }))
  public static readonly getByBookSeries = apiManager.get<
    GetByBookSeriesResponse,
    GetByBookSeriesPayload
  >('books/names/book-series')
}
