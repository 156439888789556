import React from 'react'
import WrongSwitch from 'react-switch'
import './IOSSwitch.scss'
import { COLORS } from '@magmamath/ui'

const Switch = WrongSwitch as any

interface ISwitchState {
  checked: boolean
  handleChange: (checked: boolean) => void
}
const IOSSwitch: React.FC<ISwitchState> = ({ checked, handleChange }) => {
  const onChange = () => {
    handleChange(!checked)
  }

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onColor={COLORS.PRIMARY_GREEN}
      offColor={COLORS.NEUTRAL_6}
      uncheckedIcon={false}
      checkedIcon={false}
      handleDiameter={28}
      width={52}
      height={25}
      boxShadow='2px 2px 2px rgba(0, 0, 0, 0.1)'
      activeBoxShadow={''}
    />
  )
}

export default IOSSwitch
