import { attach, createEffect, createEvent, restore } from 'effector'
import { GradeModalState, SetClassesGadeFxParams, SetClassesGadeParams } from './types'
import { api } from 'api'

export class SelectClassGradeModel {
  public static readonly setState = createEvent<GradeModalState>()

  public static readonly close = createEvent()

  public static readonly $state = restore<GradeModalState>(this.setState, {
    data: null,
  }).reset(this.close)

  public static readonly updateClassGradeFx = attach({
    source: this.$state,
    mapParams: (
      { grade }: SetClassesGadeParams,
      { data }: GradeModalState
    ): SetClassesGadeFxParams => ({
      id: data!.classId,
      data: { grade },
    }),
    effect: createEffect(api.classes.updateClassGrade),
  })
}
