import { KeyboardShortcutsManager } from '@magmamath/react-native-draw-board'
import { useEffect } from 'react'

import { DrawBoardPreviewPreset } from '../DrawBoardPreview/types'

enum KeyboardKeys {
  SPACE = ' ',
}

export const playableHotKeysListener = new KeyboardShortcutsManager()
export const usePlayableHotKeys = (preset: DrawBoardPreviewPreset) => {
  useEffect(() => {
    const removeKey = playableHotKeysListener.addSingleKeyPress(KeyboardKeys.SPACE, () => {
      preset.model.historyActions.toggleAnchorPlaying({
        offset: preset.context.attemptOffsetRef,
      })
    })

    playableHotKeysListener.start()
    return () => {
      removeKey()
      playableHotKeysListener.stop()
    }
  }, [preset])
}
