import { COLORS } from '@magmamath/ui'
import { CurrentLanguage, DateFormats } from 'helpers/enums'
import { getCheatDate } from 'helpers/general'
import useText from 'i18n/hook'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import AttentionIcon from 'ui/icons/AttentionIcon/AttentionIcon'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import styles from 'features/tables/HeatmapTable/ui/HeatmapTable/Table.module.scss'
import { CheatsType, ICheat } from '../../../../../api/types.statistics'

type CheatIndicatorProps = {
  cheats?: ICheat[]
}

const CheatIndicator = ({ cheats }: CheatIndicatorProps) => {
  const t = useText()
  const isCheatDetection = useSelector(
    (state: RootState) => !!state.exerciseWithoutStats.data.cheatDetection
  )

  const isForceHideIcon = !!cheats?.every((item) => item.type === CheatsType.LEAVING_EXAM)

  const lastLeaveCheat = useMemo(() => {
    if (!cheats) return

    return [...cheats]
      .reverse()
      .find(
        (cheat) => cheat.type === CheatsType.LEAVING_EXAM || cheat.type === CheatsType.LEAVING_APP
      )
  }, [cheats])

  const format = t.languageTxt === CurrentLanguage.SE ? DateFormats.FULL : DateFormats.FULL_US

  if (!isCheatDetection || !cheats?.length || isForceHideIcon) return null

  return (
    <UITooltip
      placement='top'
      title={lastLeaveCheat ? getCheatDate(lastLeaveCheat.time, t.languageTxt, format) : null}
    >
      <div className={styles.IconContainer}>
        <AttentionIcon size={18} color={COLORS.PRIMARY_RED} />
      </div>
    </UITooltip>
  )
}

export default CheatIndicator
