import { apiManager } from './apiManager'
import {
  IDrawningHistory,
  IPracticeAnswerResult,
  IPracticeProblem,
  IPracticeTheory,
} from './types.practice'

type GetLastUserSolutionPayload = {
  skillId: string
  userId: string
}

export class practiceApi {
  public static readonly getTheory = apiManager.get<IPracticeTheory, string>('led/solutions/theory')

  public static readonly getDrawningHistory = apiManager.get<IDrawningHistory, string>(
    'led/drawing-history'
  )

  public static readonly getLastUserSolution = apiManager.get<
    IPracticeAnswerResult[],
    GetLastUserSolutionPayload
  >(({ skillId, userId }) => `/led/skills/${skillId}/users/${userId}/solutions/last`)

  public static readonly getExampleProblems = apiManager.get<IPracticeProblem[], string>(
    (skillId) => `/new-skills/${skillId}/example-problems`
  )
}
