import React, { useState } from 'react'
import styles from './ProblemLevelAvatar.module.scss'
import clx from 'classnames'

export enum AvatarValue {
  GREEN = 1,
  YELLOW,
  RED,
}

interface ProblemLevelAvatarProps {
  title: string
  label: string
  classes?: {
    container?: string
    avatar?: string
    label?: string
  }
  value: AvatarValue | null
  type: AvatarValue
  onClick: () => void
}

const getColor = (color: AvatarValue | null) => {
  const mapColor: Record<AvatarValue, { avatar: string; label: string }> = {
    [AvatarValue.GREEN]: { avatar: styles.GreenAvatar, label: styles.GreenLabel },
    [AvatarValue.YELLOW]: { avatar: styles.YellowAvatar, label: styles.YellowLabel },
    [AvatarValue.RED]: { avatar: styles.RedAvatar, label: styles.RedLabel },
  }
  return !color || !mapColor[color]
    ? { avatar: styles.GreenAvatar, label: styles.GreenLabel }
    : mapColor[color]
}

const ProblemLevelAvatar: React.FC<ProblemLevelAvatarProps> = ({
  title,
  label,
  classes,
  value,
  type,
  onClick,
}) => {
  const [activeClass, setActiveClass] = useState(getColor(value))
  const [isHovered, setHovered] = useState(false)

  return (
    <div
      className={clx(styles.Container, classes?.container)}
      onClick={onClick}
      onMouseOver={() => {
        setActiveClass(getColor(type))
        setHovered(true)
      }}
      onMouseOut={() => {
        setActiveClass(getColor(value))
        setHovered(false)
      }}
    >
      <div
        className={clx(styles.ProblemLevelAvatar, classes?.avatar, {
          [activeClass.avatar]: isHovered || value === type,
        })}
      >
        {title}
      </div>
      <div
        className={clx(styles.Label, classes?.label, {
          [activeClass.label]: isHovered || value === type,
        })}
      >
        {label}
      </div>
    </div>
  )
}

export default ProblemLevelAvatar
