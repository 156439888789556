import React from 'react'
import { SvgIconProps } from '../types/types'

const TickIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 15 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.478516 5.99842L5.74061 11.2605L14.5107 0.736328'
        stroke={color}
        strokeWidth='0.996'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default TickIcon
