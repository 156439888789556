import { ILocalizationActions, LOCALIZE } from 'redux/modules/localization.module'
import { config } from '../config'
import { ENGLISH, nativeLanguages, SWEDISH } from '../config/allLanguages'
import { currentLocale } from '../config/modules.config'
import { LocaleData } from './enums'

export const getObjKey = (obj: any, value: any) => {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export async function setLanguage(localizationActions: ILocalizationActions): Promise<any> {
  const savedLang = currentLocale.currentLang
  const languageHandlerMap: Record<LOCALIZE, () => void> = {
    US: localizationActions.setUS,
    SE: localizationActions.setSwedish,
    GB: localizationActions.setBritish,
  }
  if (savedLang) {
    languageHandlerMap[getObjKey(LocaleData, savedLang) as LOCALIZE]()
  } else if (config.APP_HOST_INTERNATIONAL === config.APP_HOST) {
    localizationActions.setUS()
  } else {
    localizationActions.setSwedish()
  }
}

export function getLanguageShort(lang: string): string {
  const languagesMap: { [key in LOCALIZE]: string } = {
    US: 'us',
    SE: 'sv',
    GB: 'gb',
  }
  return languagesMap[lang]
}

export const getNativeLanguageList = (appLocale: string) => {
  const languageToExclude: string = appLocale === LocaleData.SE ? SWEDISH : ENGLISH
  return nativeLanguages.filter((language) => language !== languageToExclude)
}
