import { IProblem, ISubProblem } from '../../../../api/types.problem'

export const mapProblemsById = (problems: IProblem[]) => {
  return problems.reduce((acc: Map<string, IProblem | ISubProblem>, problem) => {
    acc.set(problem._id, problem)
    if (problem.subProblems) {
      problem.subProblems.forEach((subProblem) => {
        acc.set(subProblem._id, { ...subProblem, problemId: problem._id })
      })
    }
    return acc
  }, new Map())
}
