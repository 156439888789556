import React from 'react'
import styles from './ProblemCreatorSideBar.module.scss'
import useText from '../../../i18n/hook'
import ContentEditableArea from '../../../ui/inputs/ContentEditableArea/ContentEditableArea'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import SideBarImage from './SideBarImage/SideBarImage'
import NumericAnswerPreview from './NumericAnswerPreview/NumericAnswerPreview'
import AlgebraAnswerPreview from './AlgebraAnswerPreview/AlgebraAnswerPreview'
import MultipleChoicePreview from './MultipleChoicePreview/MultipleChoicePreview'
import { ProblemCreationTab } from '../../../screens/ProblemCreator/ProblemCreator'
import { api } from '../../../api'

const ProblemCreatorSideBar = () => {
  const ANSWERS_PREVIEW_MAP = {
    [ProblemCreationTab.NUMERIC]: NumericAnswerPreview,
    [ProblemCreationTab.ALGEBRA]: AlgebraAnswerPreview,
    [ProblemCreationTab.MULTIPLE_CHOICE]: MultipleChoicePreview,
  }

  const getPreviewComponent = (tab: ProblemCreationTab) => {
    return ANSWERS_PREVIEW_MAP[tab]
  }

  const activeTab = useSelector((state: RootState) => state.problemCreatorData.activeTab)
  const t = useText()
  const question = useSelector((state: RootState) => state.problemCreatorData.question)
  const currentProblem = useSelector((state: RootState) => state.problem.data)
  const imageURL = useSelector((state: RootState) => state.problemCreatorData.imageURL)
  const Preview = getPreviewComponent(activeTab)
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>{t.studentPreview}</div>
      <div className={styles.Wrapper}>
        <div className={styles.TaskArea}>
          {currentProblem?._id && imageURL ? (
            <img src={api.images.url(imageURL)} className={styles.ImageItem} alt={t.problemsTxt} />
          ) : (
            <SideBarImage />
          )}

          <ContentEditableArea value={question} disabled />
        </div>
        <div className={styles.AnswerArea}>
          <Preview />
        </div>
      </div>
    </div>
  )
}

export default ProblemCreatorSideBar
