import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router'
import DropdownMenu from 'features/myBook/DropdownMenu/DropdownMenu'
import { DropDownMode, DropDownPosition, ILevel, ProblemClickProps } from 'features/myBook/types'
import { Events } from 'helpers/enums'
import { useUnit } from 'effector-react'
import clsx from 'clsx'
import styles from './ProblemsColumn.module.scss'
import { PATHS } from 'config/pathnames.config'
import ProblemsList from '../ProblemsList/ProblemsList'
import { MyBookModel } from '../model'

type ProblemsColumnProps = {
  level: ILevel,
  onProblemDeleteClick: () => void,
}

const LEFT_MENU_PADDING = 4
const TOP_MENU_PADDING = 36

const ProblemsColumn = ({
  level,
  onProblemDeleteClick
}: ProblemsColumnProps) => {
  const selectedProblem = useUnit(MyBookModel.$selectedProblem)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dropDownPosition, setDropDownPosition] = useState<DropDownPosition>({ top: '0', right: '0' })
  const currentOpenMenu = useRef<HTMLDivElement | null>(null)
  const dropDownRef = useRef<HTMLDivElement | null>(null)
  const history = useHistory()

  useEffect(() => {
    function handleClickOutside(this: Document, event: MouseEvent) {
      if (
        !dropDownRef.current?.contains(event.target as Node)
        && !currentOpenMenu.current?.contains(event.target as Node)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener(Events.CLICK, handleClickOutside, true)
    return () => document.removeEventListener(Events.CLICK, handleClickOutside, true)
  }, [])

  const handleMenuClick = useCallback(({ clickedProblem, clickedMenu, problem }: ProblemClickProps) => {
    MyBookModel.setSelectedProblem(problem)
    currentOpenMenu.current = clickedMenu
    setIsDropdownOpen((prev) => !prev)
    if (clickedProblem) {
      setDropDownPosition({
        top: `${clickedProblem.offsetTop + TOP_MENU_PADDING}px`,
        right: `${LEFT_MENU_PADDING}px`
      })
    }
  }, [setIsDropdownOpen, setDropDownPosition])

  const handleEditClick = () => {
    setIsDropdownOpen((prev) => !prev)
    history.push(`${PATHS.PARTS.PROBLEMS_EDIT}${selectedProblem?._id}`)
  }

  const handleDeleteClick = () => {
    onProblemDeleteClick()
    setIsDropdownOpen((prev) => !prev)
  }

  return (
    <>
      <div className={clsx(styles.LevelHead, styles[level.color])}>
        <span className={clsx(styles.LevelName, level.className)}>{level.name}</span>
      </div>
      <ProblemsList
        level={level}
        isDropdownOpen={isDropdownOpen}
        onMenuClick={handleMenuClick}
      />
      {isDropdownOpen && (
        <DropdownMenu
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          isOpen={isDropdownOpen}
          position={dropDownPosition}
          ref={dropDownRef}
          mode={DropDownMode.PROBLEM}
        />)}
    </>
  )
}

export default ProblemsColumn