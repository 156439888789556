import React from 'react'
import { DrawBoardPreviewPreset } from '../DrawBoardPreview/types'
import styles from './PlayableControllers.module.scss'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import PauseIcon from '../../../../ui/icons/PauseIcon/PauseIcon'
import PlayIcon from '../../../../ui/icons/PlayIcon/PlayIcon'
import useText from '../../../../i18n/hook'
import { useStoreMap } from 'effector-react'

type PreviewReplayButtonProps = {
  drawBoardPreset: DrawBoardPreviewPreset
}

const PreviewReplayButton = ({ drawBoardPreset }: PreviewReplayButtonProps) => {
  const model = drawBoardPreset.model
  const isPlaying = useStoreMap(model.player.$playingState, Boolean)
  const t = useText()

  const Icon = isPlaying ? PauseIcon : PlayIcon

  return (
    <Button
      classes={{
        content: styles.ReplayButton,
      }}
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.SMALL}
      icon={<Icon color='var(--icon-color)' size={16} />}
      onClick={() => {
        const toAnchor = model.cache.anchors.getLast({
          offset: drawBoardPreset.context.attemptOffsetRef,
        })
        model.historyActions.togglePlaying({ toAnchor })
      }}
    >
      {isPlaying ? t.pauseTxt : t.play}
    </Button>
  )
}

export default PreviewReplayButton
