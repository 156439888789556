import React from 'react'
import { SvgIconProps } from '../types/types'

const ShuffleIcon = ({ size = 20, color }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 14'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9697 0.96967C12.2626 0.676777 12.7374 0.676777 13.0303 0.96967L15.0303 2.96967C15.3232 3.26256 15.3232 3.73744 15.0303 4.03033L13.0303 6.03033C12.7374 6.32322 12.2626 6.32322 11.9697 6.03033C11.6768 5.73744 11.6768 5.26256 11.9697 4.96967L12.6893 4.25H11.4692C9.86544 4.25 8.5037 5.41932 8.25787 7C8.5037 8.58068 9.86544 9.75 11.4692 9.75H12.6893L11.9697 9.03033C11.6768 8.73744 11.6768 8.26256 11.9697 7.96967C12.2626 7.67678 12.7374 7.67678 13.0303 7.96967L15.0303 9.96967C15.3232 10.2626 15.3232 10.7374 15.0303 11.0303L13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303C11.6768 12.7374 11.6768 12.2626 11.9697 11.9697L12.6893 11.25H11.4692C9.82526 11.25 8.35532 10.4093 7.5 9.11022C6.64468 10.4093 5.17474 11.25 3.53082 11.25H1.5C1.08579 11.25 0.75 10.9142 0.75 10.5C0.75 10.0858 1.08579 9.75 1.5 9.75H3.53082C5.13456 9.75 6.4963 8.58068 6.74213 7C6.4963 5.41932 5.13456 4.25 3.53082 4.25H1.5C1.08579 4.25 0.75 3.91421 0.75 3.5C0.75 3.08579 1.08579 2.75 1.5 2.75H3.53082C5.17474 2.75 6.64468 3.59065 7.5 4.88978C8.35532 3.59065 9.82526 2.75 11.4692 2.75H12.6893L11.9697 2.03033C11.6768 1.73744 11.6768 1.26256 11.9697 0.96967Z'
        fill={color}
      />
    </svg>
  )
}

export default ShuffleIcon
