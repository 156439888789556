import React from 'react'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import styles from './ProblemDescriptionBlock.module.scss'
import PiIcon from '../../../ui/icons/PiIcon/PiIcon'
import { COLORS } from '@magmamath/ui'

type InsertFormulaButtonProps = {
  onClick?: () => void
  label: string
}

const InsertFormulaButton = ({ onClick, label }: InsertFormulaButtonProps) => {
  return (
    <UIButton className={styles.ImageButton} onClick={onClick}>
      <div className={styles.ImageButtonContainer}>
        <div className={styles.ImageButtonTitle}>{label}</div>
        <PiIcon size={21} color={COLORS.NEUTRAL_8} />
      </div>
    </UIButton>
  )
}

export default InsertFormulaButton
