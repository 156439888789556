import { BUTTON_COLOR, Button, SIZE } from '@magmamath/ui'
import clsx from 'clsx'
import { useAnimationsContext } from 'features/ExercisesList/contexts/AnimationsContext/AnimationsContext'
import { EXERCISE_CARD_ANIMATION_DURATION } from 'features/ExercisesList/contexts/AnimationsContext/animationConfig'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { AssignmentFilterBadges } from 'features/ExercisesList/types/types'
import { prepareEditExerciseData } from 'helpers/exercise.helpers'
import { getStatusFromExercise } from 'helpers/exercises.helpers'
import useText from 'i18n/hook'
import isEmpty from 'lodash/isEmpty'
import React, { SyntheticEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { editExercise } from 'redux/modules/exercise.module'
import { EXERCISES_STATUS } from 'redux/modules/exercisesStatuses.module'
import { RootState } from 'store/store'
import PlayIcon from 'ui/icons/PlayIcon/PlayIcon'
import StopIcon from 'ui/icons/StopIcon/StopIcon'
import TooltipWrapper from '../../../../ui/tooltips/UITooltip/TooltipWrapper'
import styles from './ExerciseStateBadge.module.scss'
import { ExerciseState, IExercise } from '../../../../api/types.assignments'

enum ExerciseStateBadges {
  START = 'START',
  ENDED = 'ENDED',
  STARTED_COUNT = 'STARTED_COUNT',
}

const getBadgeComponentType = (exercise: IExercise) => {
  const settings = exercise.settings
  const exerciseState = settings?.state
  if (!exercise.testModeEnabled || exerciseState === undefined) return
  const isInActiveTestMode =
    exercise.testModeEnabled && (isEmpty(settings) || (settings && !settings.assessed))
  if (!exerciseState && isInActiveTestMode) return ExerciseStateBadges.START

  const badgeVariantsMap = {
    [ExerciseState.NotStarted]: ExerciseStateBadges.START,
    [ExerciseState.Started]: ExerciseStateBadges.STARTED_COUNT,
    [ExerciseState.Stopped]: ExerciseStateBadges.STARTED_COUNT,
    [ExerciseState.Finished]: ExerciseStateBadges.ENDED,
    [ExerciseState.DistrictLocked]: ExerciseStateBadges.ENDED,
  }

  return badgeVariantsMap[exerciseState]
}

type ExerciseStateBadgeProps = {
  exercise: IExercise
  onExamModeStatusChange: React.Dispatch<React.SetStateAction<AssignmentFilterBadges>>
}

const ExerciseStateBadge = ({ exercise, onExamModeStatusChange }: ExerciseStateBadgeProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const t = useText()

  const exercisesStatuses = useSelector((state: RootState) => state.exercisesStatuses.data)
  const { handleAssignmentsAnimation } = useAnimationsContext()
  const { isBulkModeActive } = useBulkSelectContext()
  const [isLoading, setIsLoading] = useState(false)

  const settings = exercise.settings
  const examStats = exercise.stats?.examStats

  const onAssignmentsStatusChange = (newStatus: EXERCISES_STATUS) => {
    handleAssignmentsAnimation(exercisesStatuses, newStatus)
    setTimeout(() => {
      onExamModeStatusChange((prevState) => ({
        ...prevState,
        [newStatus]: prevState[newStatus] + 1,
      }))
    }, EXERCISE_CARD_ANIMATION_DURATION * 1800)
  }

  const onStartButtonClick = async (e: SyntheticEvent) => {
    e.stopPropagation()
    if (settings?.assessed || isLoading) return
    setIsLoading(true)
    const editExerciseData = prepareEditExerciseData(exercise, ExerciseState.Started)
    await editExercise(editExerciseData, exercise._id, history, !!exercise.published)(dispatch)
    const exerciseStatus = getStatusFromExercise(exercise)
    if (exerciseStatus === EXERCISES_STATUS.UNPUBLISHED) {
      onAssignmentsStatusChange(EXERCISES_STATUS.AVAILABLE)
    }
    // Action inside editExercise triggers 'UPDATE_EXERCISE_IN_EXERCISES' action which I cannot track
    // So I'm using setTimeout to wait for the action to finish to prevent clicks on not updated state
    await setTimeout(() => setIsLoading(false), 5000)
  }

  const badgeComponentType = getBadgeComponentType(exercise)

  if (badgeComponentType === ExerciseStateBadges.START)
    return (
      <Button
        data-skip-redirect
        size={SIZE.SMALL}
        classes={{ button: styles.Start }}
        color={BUTTON_COLOR.GREEN}
        icon={<PlayIcon color='var(--icon-color)' size={10} />}
        onClick={onStartButtonClick}
        disabled={isBulkModeActive}
        isLoading={isLoading}
      >
        {t.startTxt}
      </Button>
    )

  if (badgeComponentType === ExerciseStateBadges.STARTED_COUNT) {
    return (
      <span
        className={clsx(styles.CardTag, styles.StartedCount, {
          [styles.NoAction]: isBulkModeActive,
        })}
      >
        {examStats?.startedStudents ? (
          <>
            {`${examStats.startedStudents.length}/${exercise.students.length} ${t.startedTxt}`}
            {!!examStats.cheaters?.length && exercise.cheatDetection && (
              <div className={styles.Badge}>{examStats.cheaters.length}</div>
            )}
          </>
        ) : (
          t.startedTxt
        )}
      </span>
    )
  }

  if (badgeComponentType === ExerciseStateBadges.ENDED)
    return (
      <TooltipWrapper
        title={t.districtLockedTooltip}
        showTooltip={settings?.state === ExerciseState.DistrictLocked}
      >
        <span
          className={clsx(styles.CardTag, styles.Ended, {
            [styles.NoAction]: isBulkModeActive,
          })}
        >
          <StopIcon color='var(--icon-color)' size={10} />
          {t.endedTxt}
        </span>
      </TooltipWrapper>
    )

  return <span className={styles.CardTagEmpty} />
}

export default ExerciseStateBadge
