import { apiManager } from './apiManager'
import {
  AddExerciseData,
  GetAllAssignmentsPayload,
  GetAllAssignmentsResponse,
  GetSingleAssignmentPayload,
  GetSingleStatsResponse,
  GetStatsResponse,
  IExercise,
  ShareAssignmentPayload,
  UpdateAssignmentPayload,
  UpdateExerciseStatusPayload,
} from './types.assignments'

type UpdateAssignmentResponse = {}
type DeleteAssignmentResponse = {}

export class assignmentsApi {
  public static readonly getAll = apiManager.get<
    GetAllAssignmentsResponse,
    GetAllAssignmentsPayload
  >({
    url: 'assignments',
    headers: {
      'Cache-Control': 'no-cache',
    },
  })

  public static readonly createNew = apiManager.post<void, AddExerciseData>('assignments')
  public static readonly getSingle = apiManager.get<IExercise, GetSingleAssignmentPayload>(
    (props) => {
      if (typeof props === 'string') return `assignments/${props}`
      const { id, ...params } = props
      return { url: `assignments/${props.id}`, params }
    }
  )
  public static readonly updateSingle = apiManager.put<
    UpdateAssignmentResponse,
    UpdateAssignmentPayload
  >(({ id, data }) => ({ url: `assignments/${id}`, body: data }))
  public static readonly deleteSingle = apiManager.delete<DeleteAssignmentResponse, string>(
    'assignments'
  )

  public static readonly bulk = apiManager.post<IExercise, AddExerciseData[]>('assignments/bulk')
  public static readonly getStats = apiManager.get<GetStatsResponse, string>(
    (exerciseId) => `statistics/exercises/${exerciseId}/teacher`
  )
  public static readonly getSingleStats = apiManager.get<GetSingleStatsResponse, string>(
    'statistics/exercises'
  )
  public static readonly updateStatus = apiManager.patch<void, UpdateExerciseStatusPayload>(
    'exercises'
  )
  public static readonly share = apiManager.post<void, ShareAssignmentPayload>(
    ({ assignmentId, classroomIds }) => ({
      url: `assignments/${assignmentId}/share`,
      body: { classroomIds },
    })
  )

  public static readonly getHeatmapResults = apiManager.get<ArrayBuffer, string>({
    responseType: 'arraybuffer',
    data: (exerciseId) => ({
      url: `reports/exercises/${exerciseId}/heatmap`,
    }),
  })
}
