import React, { useEffect, useState } from 'react'
import { Button, SIZE } from '@magmamath/ui'

import useText from 'i18n/hook'
import styles from './HelpQueueModalContent.module.scss'
import { IStudent } from 'api/types.users'
import { Link } from 'react-router-dom'
import UICheckbox from 'ui/checkboxes/uiCheckbox/Checkbox'
import { $helpQueueData, deleteHelpQueueFx, fetchHelpQueueFx } from '../../model'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useUnit } from 'effector-react'
import { getClassRoomId, getSolutionViewURL } from '../../helper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useParams } from 'react-router'
import { ExerciseRouteParams } from '../../../heatmap/HeatmapMenu/types/heatmap'
import { IExercise } from '../../../../api/types.assignments'
import { GetHelpQueuePayload } from '../../../../api/api.helpQueue'

type HelpQueueItem = {
  _id: string
  student: IStudent
  exerciseName: string
  exerciseId: string
  problemId: string
  subProblemId?: string
}

export type GetSolutionViewURL = {
  exerciseId: string
  problemId: string
  subProblemId?: string
  studentId: string
}

const HELP_QUEUE_ID = 'HELP_QUEUE_ID'
const OFFSET_VALUE = 20

const HelpQueueModalContent = () => {
  const [page, setPage] = useState(1)
  const helpQueue = useUnit($helpQueueData)
  const selectedClass = useSelector((state: RootState) => state.classesFilter)
  const allExercises: IExercise[] = useSelector(
    (state: RootState) => state.exercises?.data?._embedded?.exercises ?? []
  )
  const t = useText()
  const currentExercise = useSelector((state: RootState) => state.exerciseWithoutStats.data)
  const { exerciseId } = useParams<ExerciseRouteParams>()
  const dataLength = helpQueue.items.length || 20
  const classRoomId = getClassRoomId({
    exercises: allExercises,
    exercise: currentExercise,
    exerciseId,
    selectedClass: selectedClass,
  })

  const nextPage = () => setPage((page) => page + 1)

  useEffect(() => {
    if (page === 1) return
    const offset = (page - 1) * OFFSET_VALUE
    fetchHelpQueueFx({ offset, classroomId: classRoomId })
  }, [page])

  if (helpQueue.items.length === 0) {
    return <p className={styles.NoStudents}>{t.noStudentsForHelp}</p>
  }

  return (
    <form>
      <ul id={HELP_QUEUE_ID} className={styles.List}>
        <InfiniteScroll
          dataLength={dataLength}
          next={nextPage}
          hasMore={helpQueue.items.length < helpQueue.total}
          loader={<div />}
          scrollableTarget={HELP_QUEUE_ID}
        >
          {helpQueue.items.map((item: HelpQueueItem) => (
            <li className={styles.Item} key={item._id}>
              <div className={styles.Content}>
                <UICheckbox
                  className={styles.Checkbox}
                  id={item._id}
                  onChange={() => deleteHelpQueueFx({ id: item._id })}
                />
                <label htmlFor={item._id} />
                <div>
                  <p className={styles.Name}>
                    {item.student.firstName} {item.student.lastName}
                  </p>
                  <p className={styles.Description}>{item.exerciseName}</p>
                </div>
              </div>
              <Button
                size={SIZE.SMALL}
                type='button'
                As={Link}
                to={getSolutionViewURL({ studentId: item.student._id, ...item })}
              >
                {t.showTxt}
              </Button>
            </li>
          ))}
        </InfiniteScroll>
      </ul>
    </form>
  )
}

export default HelpQueueModalContent
