import { BUTTON_COLOR, BUTTON_VARIANT, Button, Checkbox, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import clsx from 'clsx'
import React from 'react'
import ArrowSelectIcon from 'ui/icons/ArrowSelectIcon/ArrowSelectIcon'
import styles from './SelectionSet.module.scss'

type SelectionSetProps = {
  isSelected: boolean
  onSelectionChange: React.ChangeEventHandler<HTMLInputElement>
  label: string
  studentsQuantityText?: string
  onDropdownClick: (event: React.SyntheticEvent) => void
  isExamMode?: boolean
}

const SelectionSet = ({
  isSelected,
  onSelectionChange,
  onDropdownClick,
  label,
  studentsQuantityText,
  isExamMode,
}: SelectionSetProps) => {
  return (
    <div
      className={clsx(styles.SelectionSet, {
        [styles.Active]: isSelected,
        [styles.ExamMode]: isExamMode,
      })}
    >
      <Checkbox
        classes={{
          InputControl: clsx(styles.InputControl, {
            [styles.SmallText]: label.length >= 20,
          }),
        }}
        checked={isSelected}
        onChange={onSelectionChange}
        size={SIZE.MEDIUM}
        color={isExamMode ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.BLUE}
        label={label}
      />
      <Button
        classes={{
          button: styles.ListboxButton,
          content: styles.ListboxButtonContent,
        }}
        onClick={onDropdownClick}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZE.SMALL}
        icon={<ArrowSelectIcon size={17} color='var(--icon-color)' />}
        iconAlign={ICON_ALIGNMENT.RIGHT}
      >
        {studentsQuantityText}
      </Button>
    </div>
  )
}

export default SelectionSet
