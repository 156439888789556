import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { getFilteredStats } from 'features/filters/StatsFilter/helpers'
import { $statsFilter } from 'features/filters/StatsFilter/model/model'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import Problem from 'features/heatmap/Problem/Problem'
import SmallSolutionCard from 'features/heatmap/SmallSolutionCard/SmallSolutionCard'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import useText from 'i18n/hook'
import { IStudentStat } from 'api/types.solutions'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { sessionStorageKeys } from '../../config/localStorageKeys'
import {
  checkIsUserRemovedFromAssignment,
  getFullName,
  getTeacherData,
} from '../../helpers/user.helpers'
import styles from './AllSolutions.module.scss'
import { getStudentsSolutionsNavigationLinks } from './helpers/getNavigationLinks'
import { mapStudentsById } from './helpers/mapStudentsById'
import { $pinnedSolutions } from '../../features/pinnedSolutions/model/pinnedSolutions'
import { $commonErrorFilterTask } from '../../features/Solutions/CommonError/model/filterTask'
import { IExercise } from '../../api/types.assignments'
import { IProblem, ISubProblem } from '../../api/types.problem'

type AllSolutionsProps = {
  exercise: IExercise
  usersStats: IStudentStat[] | null
  problem: IProblem | null
  subProblem: ISubProblem | null
}

const ONE_SOLUTION = 1

const AllSolutions = ({ exercise, usersStats, problem, subProblem }: AllSolutionsProps) => {
  const buttonColor = getButtonColor(exercise.testModeEnabled)
  const navigationLinks = getStudentsSolutionsNavigationLinks(exercise.problems || [], exercise._id)
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))
  const formattedStudents = mapStudentsById([...exercise.students, currentTeacher])
  const heatmapSettings = useUnit($heatmapSettings)
  const { isPinsFilterActive } = useUnit($pinnedSolutions)
  const commonErrorFilterTask = useUnit($commonErrorFilterTask)

  const t = useText()

  const currentUsersStats = useStoreMap({
    store: $statsFilter,
    keys: [usersStats, heatmapSettings.sortOption],
    fn: (statsFilterStore) =>
      getFilteredStats({
        usersStats,
        statsFilterStore,
        sortOption: heatmapSettings.sortOption,
      }),
  })

  const showBigSolutionsLayout = commonErrorFilterTask || isPinsFilterActive

  useEffect(() => {
    sessionStorage.removeItem(sessionStorageKeys.isMultipleSolutionsViewVisited)
  }, [])

  return (
    <div
      className={clsx(styles.Container, {
        [styles.OneSolution]: currentUsersStats.length === ONE_SOLUTION && showBigSolutionsLayout,
      })}
    >
      {problem && (
        <>
          <div className={styles.ProblemWrapper}>
            <Problem
              problem={problem}
              subProblem={subProblem}
              exerciseId={exercise._id}
              buttonColor={buttonColor}
              nextLink={navigationLinks.get(subProblem?._id || problem._id)?.next}
              prevLink={navigationLinks.get(subProblem?._id || problem._id)?.prev}
              isCorrectnessHidden={heatmapSettings.isAnonymousCorrectnessMode}
            />
          </div>

          <div
            className={clsx(styles.SolutionCards, {
              [styles.PinnedSolutions]: showBigSolutionsLayout,
            })}
          >
            {currentUsersStats.map((card, index) => {
              const student = formattedStudents.get(card.studentId)
              if (!student) return null

              const studentNumber = usersStats?.findIndex(
                (stat) => stat.studentId === card.studentId
              )
              const isUserDeleted = checkIsUserRemovedFromAssignment({ currentTeacher, student })

              const headerColor =
                (heatmapSettings.isAnonymousCorrectnessMode &&
                  getButtonColor(exercise.testModeEnabled)) ||
                undefined
              const title = heatmapSettings.isAnonymousNameMode
                ? `${t.studentTxt} ${(studentNumber ?? index) + 1}`
                : getFullName(student.firstName, student.lastName)

              return (
                <SmallSolutionCard
                  key={card.stats.studentId}
                  exerciseId={exercise._id}
                  studentId={card.studentId}
                  problemId={problem._id}
                  subProblemId={subProblem?._id}
                  solution={card.stats}
                  title={title}
                  headerColor={headerColor}
                  correctAnswer={subProblem?.answer || problem.answer}
                  characterType={problem.characterType}
                  answerType={(subProblem || problem).answerType}
                  isUserDeleted={isUserDeleted}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default AllSolutions
