import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { MODAL } from './modal.module'
import { getTopics, TOPICS } from './topics.module'
import { api } from '../../api'
import { CreateNewTopicPayload, UpdateTopicPayload } from '../../api/api.topics'

// topic constants
export enum TOPIC {
  GET_TOPIC_REQUEST = 'GET_TOPIC_REQUEST',
  GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS',
  GET_TOPIC_ERROR = 'GET_TOPIC_ERROR',
  ADD_TOPIC_REQUEST = 'ADD_TOPIC_REQUEST',
  ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS',
  ADD_TOPIC_ERROR = 'ADD_TOPIC_ERROR',
  EDIT_TOPIC_REQUEST = 'EDIT_TOPIC_REQUEST',
  EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS',
  EDIT_TOPIC_ERROR = 'EDIT_TOPIC_ERROR',
}

// topic reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function topicReducer(state: any = initialState, action: IAction<TOPIC>) {
  switch (action.type) {
    case TOPIC.GET_TOPIC_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case TOPIC.GET_TOPIC_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case TOPIC.GET_TOPIC_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case TOPIC.ADD_TOPIC_REQUEST:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case TOPIC.ADD_TOPIC_SUCCESS:
      return {
        data: { ...state.data },
        error: action.payload,
        loading: false,
      }
    case TOPIC.ADD_TOPIC_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Topic actions
export function addTopic(data: CreateNewTopicPayload) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: TOPIC.ADD_TOPIC_REQUEST })
      const res = await api.topics.createNew(data)
      dispatch({ type: TOPIC.ADD_TOPIC_SUCCESS, payload: res.message })
      await getTopics()(dispatch)
      dispatch({ type: MODAL.CLOSE_MODAL })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: TOPIC.ADD_TOPIC_ERROR,
          })
        },
        () => handleNetworkError(TOPIC.ADD_TOPIC_ERROR, error, dispatch)
      )
    }
  }
}

export function editTopic(data: UpdateTopicPayload['data'], id: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: TOPIC.EDIT_TOPIC_REQUEST })
      const res = await api.topics.updateSingle({ topicId: id, data })
      dispatch({ type: TOPIC.EDIT_TOPIC_SUCCESS, payload: res })
      await getTopics()(dispatch)
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: TOPIC.EDIT_TOPIC_ERROR,
          })
        },
        () => handleNetworkError(TOPIC.EDIT_TOPIC_ERROR, error, dispatch)
      )
    }
  }
}

export function deleteTopic(topicId: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      await api.topics.deleteSingle(topicId)
      dispatch({ type: TOPICS.DELETE_TOPIC, payload: { id: topicId } })
    } catch (error) {
      dispatch({
        type: TOPICS.DELETE_TOPIC_ERROR,
        payload: error.response ? error.response.data.message : error.message,
      })
    }
  }
}

export interface ITopicActions {
  addTopic: (data: { name: string; exercises: string[] }) => void
  editTopic: (data: { name?: string; exercises?: string[] }, id: string) => void
}
