import { useStoreMap, useUnit } from 'effector-react'
import { resetReorderProblemStores } from 'features/bookshelf/ReorderProblems/model/helpers'
import { resetErrorMessages } from 'features/caf/errorMessages/model/errorMessages'
import { setExerciseName } from 'features/caf/exerciseSettings/model/exerciseName'
import { initExercise } from 'features/caf/exerciseSettings/model/exerciseSettings'
import ExerciseDetailsSkeleton from 'features/exerciseDetails/ExerciseDetailsSkeleton'
import { $exercises, $isExerciseDataPending } from 'features/exerciseDetails/model/exercises'
import { mapClassesDataToClassesStore } from 'features/modals/ClassroomModal/helpers'
import { setClasses } from 'features/modals/ClassroomModal/model/classes'
import { LoadingStatus } from 'helpers/enums'
import { filterDeletedProblems } from 'helpers/problems.helpers'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { resetChapters } from '../../features/bookshelf/Book/model/chapters'
import { fetchProblemsBreadcrumbsFx } from '../../features/bookshelf/Book/model/requests'
import { resetSectionPaths } from '../../features/bookshelf/Book/model/sectionPaths'
import { resetSections } from '../../features/bookshelf/Book/model/sections'
import { resetSelectedProblems } from '../../features/bookshelf/Book/model/selectedProblems'
import { resetSearchIsActive } from '../../features/bookshelf/model/foundBooks'
import { resetMyBooks } from '../../features/bookshelf/model/myBooks'
import ExerciseDetails from '../../features/exerciseDetails/ExerciseDetails'
import { ExerciseRouteParams } from '../../features/exerciseDetails/types'
import useText from '../../i18n/hook'

const EditExerciseView = () => {
  const [breadcrumbsLoadingState, setBreadcrumbsLoadingState] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  )
  const { exerciseId } = useParams<ExerciseRouteParams>()
  const isExerciseDataPending = useUnit($isExerciseDataPending)
  const exercise = useStoreMap({
    store: $exercises,
    keys: [exerciseId],
    fn: (exercises, [exerciseId]) => exercises.get(exerciseId),
  })
  const t = useText()

  useEffect(() => {
    initExercise(exerciseId).then((exercise) => {
      setExerciseName({ exerciseName: exercise.name, isAutofillEnabled: false })
      const mappedClassesDataToClassesStore = mapClassesDataToClassesStore({
        availableClassesWithGroups: exercise.classes,
        exerciseData: exercise,
      })
      setClasses(mappedClassesDataToClassesStore)
      setBreadcrumbsLoadingState(LoadingStatus.LOADING)
      const nonDeletedProblemIds = filterDeletedProblems(exercise.problems).map(
        (problem) => problem._id
      )
      fetchProblemsBreadcrumbsFx(nonDeletedProblemIds).finally(() => {
        setBreadcrumbsLoadingState(LoadingStatus.LOADED)
      })
    })

    return () => {
      resetChapters()
      resetSectionPaths()
      resetSelectedProblems()
      resetMyBooks()
      resetSections()
      resetErrorMessages()
      resetSearchIsActive()
      resetReorderProblemStores()
    }
  }, [])

  if (breadcrumbsLoadingState !== LoadingStatus.LOADED || isExerciseDataPending) {
    return <ExerciseDetailsSkeleton submitButtonText={t.updateTxt} />
  }

  return <ExerciseDetails classrooms={exercise?.classes ?? []} exercise={exercise} />
}

export default EditExerciseView
