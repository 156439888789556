import React from 'react'
import { Modal } from '@mui/material'

import { ModalType } from '../../../helpers/enums'
import { IModal, IModalActions } from '../../../redux/modules/modal.module'
import PracticeStudentResultsModal from '../../../features/PracticeMode/PracticeStudentResultsModal/PracticeStudentResultsModal'
import { IState } from '../../../interfaces/state.interface'

type PracticeResultsModalProps = {
  modal: IState<IModal>
  modalActions: IModalActions
}

const PracticeResultsModal = ({ modal, modalActions }: PracticeResultsModalProps) => {
  const opened: boolean = modal.data.modalType === ModalType.PracticeResultsModal
  const handleClose = () => {
    modalActions.closeOldPracticeResultsModal()
  }

  return (
    <Modal open={opened} onClose={handleClose}>
      <PracticeStudentResultsModal modal={modal} onModalClose={handleClose} />
    </Modal>
  )
}

export default PracticeResultsModal
