import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ButtonLoader from 'ui/loaders/ButtonLoader/ButtonLoader'
import useText from '../../../i18n/hook'
import { addGroup } from '../../../redux/modules/group.module'
import { RootState } from '../../../store/store'
import UIButton from '../../../ui/Buttons/UIButton/Button'
import CommonInputModal from '../../../ui/inputs/CommonInputModal/CommonInputModal'
import styles from './CreateGroupForm.module.scss'
import clsx from 'clsx'

type CreateGroupFormProps = {
  onClose: () => void
  classroomId: string
}

const CreateGroupForm = ({ onClose, classroomId }: CreateGroupFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const localization = useSelector((state: RootState) => state.localization)
  const me = useSelector((state: RootState) => state.auth.data.me)
  const dispatch = useDispatch()
  const history = useHistory()
  const t = useText()
  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    await dispatch(addGroup({ classroomId, name: groupName }, history, localization))
    setIsLoading(false)
    setGroupName('')
    onClose()
  }

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setGroupName(e.currentTarget.value)
  }

  return (
    <form className={styles.Form} onSubmit={onFormSubmit}>
      <CommonInputModal
        value={groupName}
        placeholder={t.createNewGroup}
        onChange={onChangeHandler}
        maxLength={50}
      />

      <UIButton
        className={clsx({ [styles.NonClickable]: isLoading })}
        disabled={!groupName}
        type='submit'
      >
        {isLoading ? <ButtonLoader className={styles.Center} /> : t.createTxt}
      </UIButton>
    </form>
  )
}

export default CreateGroupForm
