import clsx from 'clsx'
import React, { forwardRef } from 'react'

import styles from './Checkbox.module.scss'

type CheckboxProps = JSX.IntrinsicElements['input']

const UICheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, onChange, className, ...rest }, ref) => {
    const mergedClassNames = clsx(styles.Checkbox, className)

    return (
      <input
        checked={checked}
        onChange={onChange}
        ref={ref}
        className={mergedClassNames}
        type='checkbox'
        {...rest}
      />
    )
  }
)

export default UICheckbox
