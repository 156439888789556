import React from 'react'
import { useStoreMap } from 'effector-react'
import { Button, BUTTON_VARIANT, SIZE, BUTTON_COLOR } from '@magmamath/ui'
import styles from './GradeSelectorModalHeader.module.scss'
import ButtonsOptionSelect from '../../../ui/selects/ButtonsOptionSelect/ButtonsOptionSelect'
import OptionsMenu from '../../../ui/selects/ButtonsOptionSelect/OptionsMenu/OptionsMenu'
import StudentCard from '../StudentCard/StudentCard'
import {
  $studentsGradesData,
  setAllStudentsGrades,
  applyToAllStudents,
  AVAILABLE_GRADES,
} from '../models/studentsGradesModel'
import { setOpenedStudentId } from '../models/openedStudentId'
import useText from '../../../i18n/hook'
import toggleItemReducer from '../../../lib/reducers/toggleItemReducer'

type GradeSelectorModalHeaderProps = {
  containerDimensions?: DOMRect
}

const GradeSelectorModalHeader = ({ containerDimensions }: GradeSelectorModalHeaderProps) => {
  const allStudentsSelectedGrades = useStoreMap(
    $studentsGradesData,
    (data) => data.studentsGrades.allStudentsGrades
  )
  const isMixedGrades = useStoreMap(
    $studentsGradesData,
    (data) => data.studentsGrades.isValuesMixed
  )
  const shouldApplyForAllStudents = useStoreMap(
    $studentsGradesData,
    (data) => data.studentsGrades.shouldApplyForAll
  )
  const t = useText()
  const handleGradeItemSelect = (grade: number) => {
    setAllStudentsGrades(toggleItemReducer(allStudentsSelectedGrades, grade))
  }

  const handleGradeListSelect = () => {
    if (AVAILABLE_GRADES.length === allStudentsSelectedGrades.length) {
      setAllStudentsGrades([])
      return
    }

    setAllStudentsGrades(AVAILABLE_GRADES)
  }

  const isAllOptionsSelected = AVAILABLE_GRADES.length === allStudentsSelectedGrades.length
  const getSpecialOptionName = () => {
    if (isMixedGrades) {
      return t.mixedTxt
    }

    if (allStudentsSelectedGrades.length) {
      return ''
    }

    return t.noneTxt
  }

  return (
    <div className={styles.GradeSelectorModalHeader}>
      <span className={styles.GradeSelectorModalTitle}>{t.availableGradesTxt}</span>
      <div className={styles.AllStudentsSelectorContainer}>
        <div className={styles.AllStudentsCardContainer}>
          <StudentCard textClassName={styles.AllStudentsTitle} studentName={t.allStudentsTxt} />
        </div>
        <div className={styles.GradeSelectorContainer}>
          <ButtonsOptionSelect
            allOptionsName={t.allGradesTxt}
            selectedOptions={!isMixedGrades ? allStudentsSelectedGrades : []}
            isAllOptionsSelected={isAllOptionsSelected}
            specialOptionName={getSpecialOptionName()}
            containerDimensions={containerDimensions}
            onOpenMenuCallback={() => setOpenedStudentId('')}
            isSelectOverflowHidden
            isSelectorResized={shouldApplyForAllStudents}
          >
            <OptionsMenu
              enableAllOptionsSelect
              onListSelect={handleGradeListSelect}
              allOptionsName={t.allGradesTxt}
              options={AVAILABLE_GRADES}
              selectedOptions={allStudentsSelectedGrades}
              onItemSelect={handleGradeItemSelect}
              isAllOptionsSelected={isAllOptionsSelected}
            />
          </ButtonsOptionSelect>
        </div>
        {shouldApplyForAllStudents && (
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.SMALL}
            color={BUTTON_COLOR.ORANGE}
            classes={{ button: styles.ApplyToAllButton, content: styles.ApplyToAllButtonContent }}
            onClick={() => applyToAllStudents()}
          >
            {t.applyToAllTxt}
          </Button>
        )}
      </div>
      <div className={styles.GradeSelectorHeaderSeparator} />
    </div>
  )
}

export default GradeSelectorModalHeader
