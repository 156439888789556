import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { IAnswerInfo } from '../../api/types.solutions'
import { api } from '../../api'

export enum MULTIPLE_SOLUTIONS {
  GET_MULTIPLE_SOLUTIONS_REQUEST = 'GET_MULTIPLE_SOLUTIONS_REQUEST',
  GET_MULTIPLE_SOLUTIONS_SUCCESS = 'GET_MULTIPLE_SOLUTIONS_SUCCESS',
  GET_MULTIPLE_SOLUTIONS_ERROR = 'GET_MULTIPLE_SOLUTIONS_ERROR',
  MULTIPLE_SOLUTIONS_CLEAR_DATA = 'MULTIPLE_SOLUTIONS_CLEAR_DATA',
  UPDATE_CORRECTNESS = 'UPDATE_CORRECTNESS',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export const multipleSolutionReducer = (
  state: any = initialState,
  action: IAction<MULTIPLE_SOLUTIONS>
) => {
  switch (action.type) {
    case MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case MULTIPLE_SOLUTIONS.MULTIPLE_SOLUTIONS_CLEAR_DATA:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case MULTIPLE_SOLUTIONS.UPDATE_CORRECTNESS:
      const studentsStats =
        state.data.problems[action.payload.taskId] || state.data.subProblems[action.payload.taskId]
      const answers = studentsStats.answerArray || []
      const selectedSolution = answers.answerArray.find(
        (answer: IAnswerInfo) => answer._id === action.payload.solutionId
      )
      selectedSolution.correct = !selectedSolution.correct

      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

//TODO: mb we can get rid of it
export const getMultipleSolutions = (exerciseId: string, studentId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_REQUEST })
      const res = await api.solutions.getStudentAllSolutions({ exerciseId, studentId })
      dispatch({ type: MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: MULTIPLE_SOLUTIONS.GET_MULTIPLE_SOLUTIONS_ERROR,
      })
    }
  }
}

export const clearDataMultipleSolutions = () => {
  return (dispatch: IDispatch<any>) =>
    dispatch({ type: MULTIPLE_SOLUTIONS.MULTIPLE_SOLUTIONS_CLEAR_DATA })
}

export const updateCorrectness = (taskId: string, solutionId: string) => {
  return (dispatch: IDispatch<any>) =>
    dispatch({ type: MULTIPLE_SOLUTIONS.UPDATE_CORRECTNESS, payload: { taskId, solutionId } })
}

export interface IMultipleSolutionsActions {
  getMultipleSolutions: (exerciseId: string, studentId: string) => void
  clearDataMultipleSolutions: () => void
  updateCorrectness: (taskId: string, solutionId: string) => void
}
