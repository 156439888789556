import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { ModalType } from '../../../helpers/enums'
import { closeArchiveClassModal } from '../../../redux/modules/modal.module'
import { changeArchivedClassStatus } from '../../../redux/modules/classes.module'
import { useHistory } from 'react-router'
import ConfirmationModal from '../../../ui/modals/ConfirmationModal/ConfirmationModal'

const ArchiveClassModal = () => {
  const dispatch = useDispatch()
  const modalType = useSelector((state: RootState) => state.modal.data.modalType)
  const selectedItem = useSelector((state: RootState) => state.modal.data.selectedItem)
  const t = useSelector((state: RootState) => state.localization.data)
  const history = useHistory()
  const open = modalType === ModalType.ArchiveClassModal
  const onClose = () => {
    dispatch(closeArchiveClassModal())
  }
  const archiveClass = () => {
    dispatch(changeArchivedClassStatus(selectedItem._id, !selectedItem.archived, history, t))
    dispatch(closeArchiveClassModal())
  }
  return (
    <ConfirmationModal
      title={t.archiveClass}
      description={t.archiveClassDescription}
      isOpen={open}
      onClick={archiveClass}
      onClose={onClose}
    />
  )
}

export default ArchiveClassModal
