import React from 'react'
import { SvgIconProps } from '../types/types'

const ExpandIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4906 1.14062C10.4906 0.726411 10.8264 0.390625 11.2406 0.390625H14.4406C15.9152 0.390625 17.1106 1.58602 17.1106 3.06063V6.26062C17.1106 6.67484 16.7749 7.01062 16.3606 7.01062C15.9464 7.01062 15.6106 6.67484 15.6106 6.26062V3.06063C15.6106 2.41445 15.0868 1.89062 14.4406 1.89062H11.2406C10.8264 1.89062 10.4906 1.55484 10.4906 1.14062ZM0.890649 3.06065C0.890649 1.58605 2.08605 0.390649 3.56065 0.390649H6.76065C7.17486 0.390649 7.51065 0.726436 7.51065 1.14065C7.51065 1.55486 7.17486 1.89065 6.76065 1.89065H3.56065C2.91448 1.89065 2.39065 2.41448 2.39065 3.06065V6.26065C2.39065 6.67486 2.05486 7.01065 1.64065 7.01065C1.22644 7.01065 0.890649 6.67486 0.890649 6.26065V3.06065ZM1.64062 9.99065C2.05484 9.99065 2.39062 10.3264 2.39062 10.7406V13.9406C2.39062 14.5868 2.91445 15.1106 3.56063 15.1106H6.76062C7.17484 15.1106 7.51062 15.4464 7.51062 15.8606C7.51062 16.2749 7.17484 16.6106 6.76062 16.6106H3.56063C2.08603 16.6106 0.890625 15.4152 0.890625 13.9406V10.7406C0.890625 10.3264 1.22641 9.99065 1.64062 9.99065ZM16.3606 9.99065C16.7749 9.99065 17.1106 10.3264 17.1106 10.7406V13.9406C17.1106 15.4152 15.9152 16.6106 14.4406 16.6106H11.2406C10.8264 16.6106 10.4906 16.2749 10.4906 15.8606C10.4906 15.4464 10.8264 15.1106 11.2406 15.1106H14.4406C15.0868 15.1106 15.6106 14.5868 15.6106 13.9406V10.7406C15.6106 10.3264 15.9464 9.99065 16.3606 9.99065Z'
        fill={color}
      />
    </svg>
  )
}

export default ExpandIcon
