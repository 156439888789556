import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { IPracticeCategory, IStatistics } from '../../api/types.oldPractice'
import { mergeStatistic } from '../../helpers/practiceMode.hepler'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'

export enum PRACTICE {
  CATEGORIES_REQUEST = 'CATEGORIES_REQUEST',
  CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS',
  CATEGORIES_ERROR = 'CATEGORIES_ERROR',
  OPEN_CATEGORY = 'OPEN_CATEGORY',
  SET_FILTERED_CATEGORIES = 'SET_FILTERED_CATEGORIES',
  SUBCATEGORY_STATISTIC_REQUEST = 'SUBCATEGORY_STATISTIC_REQUEST',
  SUBCATEGORY_STATISTIC_SUCCESS = 'SUBCATEGORY_STATISTIC_SUCCESS',
  SUBCATEGORY_STATISTIC_ERROR = 'SUBCATEGORY_STATISTIC_ERROR',
  SET_ACTIVE_SUBCATEGORY = 'SET_ACTIVE_SUBCATEGORY',
  SET_GRADE = 'SET_GRADE',
}

const initialState = {
  categories: [],
  openedCategory: null,
  filteredCategories: [],
  statistics: {},
  grade: 1,
  activeSubcategoryId: '',
  error: null,
  loading: false,
}

export function practiceReducer(state: any = initialState, action: IAction<PRACTICE>) {
  switch (action.type) {
    case PRACTICE.CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PRACTICE.CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }
    case PRACTICE.CATEGORIES_ERROR:
      return {
        categories: [],
        openedCategory: null,
        error: action.payload,
        loading: false,
      }
    case PRACTICE.OPEN_CATEGORY:
      return {
        ...state,
        openedCategory: action.payload,
      }
    case PRACTICE.SET_FILTERED_CATEGORIES:
      return {
        ...state,
        filteredCategories: action.payload,
      }
    case PRACTICE.SUBCATEGORY_STATISTIC_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PRACTICE.SUBCATEGORY_STATISTIC_SUCCESS:
      return {
        ...state,
        statistics: mergeStatistic(state.statistics, action.payload),
        loading: false,
      }
    case PRACTICE.SUBCATEGORY_STATISTIC_ERROR:
      return {
        ...state,
        loading: false,
        statistics: {},
      }
    case PRACTICE.SET_ACTIVE_SUBCATEGORY:
      return {
        ...state,
        activeSubcategoryId: action.payload,
      }
    case PRACTICE.SET_GRADE:
      return {
        ...state,
        grade: action.payload,
      }
    default:
      return state
  }
}

export const getPracticeCategories = (localization: ILocalization) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: PRACTICE.CATEGORIES_REQUEST })
      const data = await api.oldPractice.getAllCategories()
      dispatch({ type: PRACTICE.CATEGORIES_SUCCESS, payload: data })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: PRACTICE.CATEGORIES_ERROR,
      })
    }
  }
}

export const getSubcategoryStatistics = (
  localization: ILocalization,
  subcategoryId: string,
  filterType: string,
  id: string
) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      return await api.statistics.getSubcategories({
        subcategoryId,
        filterType,
        id,
      })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: PRACTICE.SUBCATEGORY_STATISTIC_ERROR,
      })
      return
    }
  }
}

export const subcategoriesStatisticsRequest = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.SUBCATEGORY_STATISTIC_REQUEST })
  }
}

export const setSubcategoryStatistics = (statistics: IStatistics[]) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.SUBCATEGORY_STATISTIC_SUCCESS, payload: statistics })
  }
}

export const setActiveSubcategoryId = (id: string) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.SET_ACTIVE_SUBCATEGORY, payload: id })
  }
}

export const setGrade = (grade: number) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.SET_GRADE, payload: grade })
  }
}

export const setOpenedCategory = (category: IPracticeCategory) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.OPEN_CATEGORY, payload: category })
  }
}

export const setFilteredCategories = (categories: IPracticeCategory[]) => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: PRACTICE.SET_FILTERED_CATEGORIES, payload: categories })
  }
}

export interface IPracticeActions {
  getPracticeCategories: (localization: ILocalization) => void
  getSubcategoryStatistics: (
    localization: ILocalization,
    subcategoryId: string,
    filterType: string,
    id: string
  ) => void
  setOpenedCategory: (category: IPracticeCategory) => void
  setFilteredCategories: (categories: IPracticeCategory[]) => void
  subcategoriesStatisticsRequest: () => void
  setSubcategoryStatistics: (statistics: IStatistics[]) => void
  setActiveSubcategoryId: (id: string) => void
  setGrade: (grade: number) => void
}
