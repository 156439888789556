import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import { useStore } from 'effector-react'
import { sortABC } from 'helpers/sort.helpers'
import useText from 'i18n/hook'
import { IBasicUser, IStudent } from 'api/types.users'
import { hasSearchedValue } from 'lib/filters'
import React, { useEffect, useMemo, useState } from 'react'

import UIButton from 'ui/Buttons/UIButton/Button'
import CheckmarkIcon from 'ui/icons/CheckmarkIcon/CheckmarkIcon'
import UIModal from 'ui/modals/UIModal/UIModal'
import styles from './ClassroomModal.module.scss'
import { INPUT_STYLES } from './InputStyles'
import { getClassroomInitStoresData } from './helpers'
import { $class, setClass } from './model/class'
import { selectAll, unselectAll } from './model/events'
import { $groups, setGroupItemSelected, setGroups } from './model/groups'
import { $groupsAndStudentsStore } from './model/groupsStudents'
import { $students, setStudentSelected, setStudents } from './model/students'
import { ClassesStoreItem } from './types'
import { updateClassesWithClass } from './model/classes'
import { COLORS } from '@magmamath/ui'
import { hasDisplayName } from 'helpers/classesView.helpers'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

type ClassroomModalProps = {
  modalClass: IClass
  onClose: () => void
  classesStore: ClassesStoreItem[]
}

const ClassroomModal = ({ modalClass, onClose, classesStore }: ClassroomModalProps) => {
  const t = useText()

  const [searchInputValue, setSearchInputValue] = useState('')

  const classStore = useStore($class)
  const groupsStore = useStore($groups)
  const studentsStore = useStore($students)
  useStore($groupsAndStudentsStore)

  const isClassSelected = !!classStore?.selected

  const groupsToDisplay = useMemo(
    () =>
      sortABC(modalClass.groups).filter(
        (group: any) =>
          group.students?.length > 0 && hasSearchedValue([group.name], searchInputValue)
      ) as unknown as IGroup[],
    [modalClass.groups, searchInputValue]
  )

  const studentsToDisplay = useMemo(
    () =>
      sortABC(modalClass.students).filter((student: IBasicUser) =>
        hasSearchedValue([student.firstName, student.lastName], searchInputValue)
      ) as unknown as IStudent[],
    [modalClass.students, searchInputValue]
  )

  const onSave = () => {
    updateClassesWithClass({
      id: modalClass._id,
      value: { id: modalClass._id, classStore, groupsStore, studentsStore },
    })
    onClose()
  }

  useEffect(() => {
    const modalClassStores = classesStore.find((store) => store.classStore?.id === modalClass._id)

    if (modalClassStores) {
      // editing / duplicating assignment
      setClass(modalClassStores.classStore)
      setGroups(modalClassStores.groupsStore)
      setStudents(modalClassStores.studentsStore)
      return
    }

    // creating new assignment when no storeToUpdateModalStores - no exercise.data so we need to create new unselected stores
    const { initialClass, initialGroups, initialStudents } = getClassroomInitStoresData(
      modalClass,
      false
    )

    setClass(initialClass)
    setGroups(initialGroups)
    setStudents(initialStudents)

    return () => {
      setClass(null)
      setGroups([])
      setStudents([])
    }
  }, [])

  return (
    <UIModal
      open={!!modalClass}
      onClose={onClose}
      wrapperClassName={styles.ModalWrapper}
      closeIconWrapperClassName={styles.CloseModalIcon}
    >
      <>
        <UIModal.Header className={styles.ModalHeader}>
          <UIModal.Title className={styles.ModalHeading}>
            {hasDisplayName(modalClass) ? modalClass.displayName : modalClass.name}
          </UIModal.Title>
        </UIModal.Header>
        <UIModal.Content className={styles.ModalBody}>
          <TextField
            fullWidth
            placeholder={t.searchTxt}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
            InputProps={INPUT_STYLES}
          />

          <ul className={styles.List}>
            {groupsToDisplay.length > 0 && (
              <>
                <div className={styles.BlockTitle}>{t.groupsTxt}</div>
                {groupsToDisplay.map((group) => {
                  const isSelected =
                    groupsStore.find((groupStore) => groupStore.id === group._id)?.selected ?? false
                  return (
                    <li
                      className={clsx(styles.ListItem, {
                        [styles.Selected]: isSelected,
                      })}
                      key={group._id}
                      onClick={() => {
                        setGroupItemSelected({
                          id: group._id,
                          selected: !isSelected,
                        })
                      }}
                    >
                      <span className={styles.ListItemText}>{group.name}</span>
                      {isSelected && <CheckmarkIcon color={COLORS.NEUTRAL_10} size={15} />}
                    </li>
                  )
                })}
              </>
            )}

            <div className={styles.BlockTitle}>{t.studentsTxt}</div>
            <li
              className={clsx(styles.ListItem, { [styles.Selected]: isClassSelected })}
              onClick={() => (isClassSelected ? unselectAll() : selectAll())}
            >
              <span className={styles.ListItemText}>{t.allStudentsTxt}</span>
              {isClassSelected && <CheckmarkIcon color={COLORS.NEUTRAL_10} size={15} />}
            </li>
            {studentsToDisplay.map((student) => {
              const isSelected =
                studentsStore.find((studentStore) => studentStore.id === student._id)?.selected ??
                false

              return (
                <li
                  className={clsx(styles.ListItem, { [styles.Selected]: isSelected })}
                  key={student._id}
                  onClick={() => {
                    setStudentSelected({
                      id: student._id,
                      selected: !isSelected,
                    })
                  }}
                >
                  <span className={styles.ListItemText}>
                    {student.firstName} {student.lastName}
                  </span>
                  {isSelected && <CheckmarkIcon color={COLORS.NEUTRAL_10} size={15} />}
                </li>
              )
            })}
          </ul>
        </UIModal.Content>

        <UIModal.Footer className={styles.ModalFooter}>
          <UIButton onClick={onSave}>{t.saveTxt}</UIButton>
        </UIModal.Footer>
      </>
    </UIModal>
  )
}

export default ClassroomModal
