import React from 'react'
import { Listbox } from '@magmamath/ui'
import styles from './SubHeader.module.scss'
import Divider from '../../dividers/Divider/Divider'

type SubHeaderProps = { title: string }
const SubHeader = ({ title }: SubHeaderProps) => {
  return (
    <>
      <Divider className={styles.Divider} />
      <Listbox.Subheader>{title}</Listbox.Subheader>
    </>
  )
}
export default SubHeader
