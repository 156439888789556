import { Entity, ExponentNode, FractionNode, KatexNode, TextNode, ValueNode } from './types'

export function extractRegExpValue(input: RegExp) {
  return input.toString().match(/^\/(.+)(?:\/\w*)$/)?.[1] || input
}

export function disassemble(string: string, destructor: (current: string) => string) {
  let initial = string
  while (initial) {
    initial = destructor(initial)
  }
}

export function findOuterBraces(input: string, offset = 0) {
  let counter = 0
  for (let i = offset; i < input.length; i++) {
    if (input[i] === '{') counter++
    if (input[i] === '}') counter--
    if (counter === 0) return i
  }
  return input.length - 1
}

export const textNode = (text: string): TextNode => {
  return { type: Entity.TEXT, text }
}

export const exponentNode = (value: KatexNode[]): ExponentNode => {
  return { type: Entity.EXPONENT, value }
}

export const sqrtNode = (value: KatexNode[]): ValueNode<Entity.SQRT> => {
  return { type: Entity.SQRT, value }
}

export const fracNode = (numerator: KatexNode[], denominator: KatexNode[]): FractionNode => {
  return { type: Entity.FRAC, numerator, denominator }
}
