import React from 'react'
import { useStoreMap } from 'effector-react'
import { Checkbox, COMPONENT_COLOR, SIZE } from '@magmamath/ui'
import { selectAllBySection } from '../../model/selectedProblems'
import {
  $exerciseSettings,
  changeExerciseMode,
} from 'features/caf/exerciseSettings/model/exerciseSettings'
import { Bredcrumb } from '../../types'
import styles from '../../Book.module.scss'

type SectionCheckboxProps = {
  openedSection: Bredcrumb
  isOpenedSectionDisabled: boolean
  isOpenedSectionChecked: boolean
}

const SectionCheckbox = ({
  openedSection,
  isOpenedSectionDisabled,
  isOpenedSectionChecked,
}: SectionCheckboxProps) => {
  const { isExamMode } = useStoreMap($exerciseSettings, (data) => ({
    isExamMode: data.isExamMode,
  }))

  return (
    <Checkbox
      classes={{ InputControl: styles.Checkbox }}
      size={SIZE.MEDIUM}
      disabled={isOpenedSectionDisabled}
      color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}
      checked={isOpenedSectionChecked && !isOpenedSectionDisabled}
      onChange={() => {
        changeExerciseMode(openedSection.id)
        selectAllBySection(openedSection.id)
      }}
      label={openedSection.name}
    />
  )
}

export default SectionCheckbox
