import React, { useRef } from 'react'
import { IProblem } from '../../../api/types.problem'
import { BUTTON_VARIANT, Button, COLORS, Problem, SIZE } from '@magmamath/ui'
import { getProblemImageUrl } from 'features/heatmap/Problem/helpers/imageUrl'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import styles from './ProblemBlock.module.scss'
import { ProblemClickProps } from '../types'
import { MyBookModel } from '../model'

  type IProblemBlockProps = {
    problem: IProblem,
    problemOrder: number,
    onMenuClick: (props: ProblemClickProps) => void,
    isMenuBtnActive: boolean,
  }


const ProblemBlock = ({ problem, problemOrder, onMenuClick, isMenuBtnActive }: IProblemBlockProps) => {
  const problemRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const answerVariants = problem.answerVariants?.filter(answer => !!answer) ?? []

  const menuButton = () => (
    <div
      className={styles.MenuBtnWrapper}
      onClick={() => {
        if (problemRef.current && menuRef.current) {
          onMenuClick({
            clickedProblem: problemRef.current,
            clickedMenu: menuRef.current,
            problem
          })
        }
      }}
      ref={menuRef}
    >
      <Button
        classes={{ button: styles.Button }}
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.SMALL}
        icon={<OptionIcon size={16} color={COLORS.NEUTRAL_7} />}
        isActive={isMenuBtnActive}
      />
    </div>
  )

  return (
    <div
      key={problem._id}
      className={styles.ProblemBlock}
      onClick={() => MyBookModel.setSelectedProblem(problem)}
      ref={problemRef}
    >
      <Problem
        problem={{ ...problem, answerVariants }}
        order={problemOrder}
        imageLinkGetter={getProblemImageUrl}
        menu={menuButton()}
      />
    </div>
  )
}


export default ProblemBlock
