import React from 'react'
import { SvgIconProps } from '../types/types'

const TrashIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 15 15'
      fill='none'
    >
      <g clipPath='url(#clip0_70_3513)'>
        <path
          d='M4.21875 2.78737L5.642 1.36419C5.81763 1.18856 6.05586 1.0899 6.30426 1.0899H8.63075C8.87914 1.0899 9.11732 1.18856 9.29299 1.36419L10.7163 2.78737'
          stroke={color}
          strokeWidth='1.1288'
          strokeLinecap='round'
        />
        <path
          d='M12.4196 13.0416L13.1011 3.79295C13.1412 3.25006 12.7115 2.78757 12.1671 2.78757H2.90737C2.35521 2.78757 1.9229 3.26276 1.97496 3.81246L2.85117 13.0611C2.89671 13.5419 3.30053 13.9093 3.78358 13.9093H11.4856C11.9761 13.9093 12.3836 13.5308 12.4196 13.0416Z'
          stroke={color}
          strokeWidth='1.1288'
          strokeLinecap='round'
        />
        <path
          d='M0.472656 2.78757H14.5212'
          stroke={color}
          strokeWidth='1.1288'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_70_3513'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrashIcon
