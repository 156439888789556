import React, { PropsWithChildren, useRef } from 'react'
import styles from './AnswerCreatorBoard.module.scss'
import StaticMathInput, { MathInputRef } from '../../mathEditor/mathInput/static/StaticMathInput'
import MathShortcutButtons from '../../mathEditor/shortcuts/MathShortcutButtons'
import { useDispatch, useSelector } from 'react-redux'
import { getAlgebraOperators, getNumericOperators } from '../../mathEditor/shortcuts/operators'
import { RootState } from '../../../store/store'
import useText from '../../../i18n/hook'
import clsx from 'clsx'
import { StaticInputValueMiddleware } from '../../mathEditor/mathInput/static/StaticMathInputModel'
import { ProblemCreationTab } from '../../../screens/ProblemCreator/ProblemCreator'

interface StaticMathInputWrapperProps {
  handleMathInputChange: (value: string) => void
  mathInputValueMiddleware?: StaticInputValueMiddleware
  initialValue: string
  placeholder?: string
  className?: string
}

const StaticMathInputWrapper = ({
  handleMathInputChange,
  mathInputValueMiddleware,
  children,
  initialValue,
  placeholder,
  className,
}: PropsWithChildren<StaticMathInputWrapperProps>) => {
  const dispatch = useDispatch()
  const mathInputRef = useRef<MathInputRef>(null)
  const activeTab = useSelector((state: RootState) => state.problemCreatorData.activeTab)
  const t = useText()
  const operatorsType =
    activeTab === ProblemCreationTab.NUMERIC ? getNumericOperators : getAlgebraOperators
  return (
    <>
      <div className={styles.Wrapper}>
        <StaticMathInput
          valueMiddleware={mathInputValueMiddleware}
          ref={mathInputRef}
          className={clsx(styles.MathInput, className)}
          initialValue={initialValue}
          placeholder={placeholder}
          onChange={({ value }) => dispatch(handleMathInputChange(value))}
        />
        {children}
      </div>
      <MathShortcutButtons
        onPress={(operator) => {
          mathInputRef.current?.model.insertOperator(operator)
        }}
        operators={operatorsType(t.languageTxt)}
      />
    </>
  )
}

export default StaticMathInputWrapper
