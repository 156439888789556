import React, { memo } from 'react'
import styles from './ExerciseTopics.module.scss'

import { ITopic } from '../../../../api/api.topics'

type ExerciseTopicsTooltipProps = {
  topics: ITopic[]
  topicsTxt: string
}

const ExerciseTopicsTooltip = ({ topics, topicsTxt }: ExerciseTopicsTooltipProps) => {
  return (
    <div className={styles.TooltipContent}>
      {!!topics.length && (
        <>
          {topics.length > 1 && <div className={styles.ListTitle}>{topicsTxt}</div>}
          <ul className={styles.List}>
            {topics.map((topic) => (
              <li key={topic._id} className={styles.ListItem}>
                {topic.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default memo(ExerciseTopicsTooltip)
