import { createEvent, createStore } from 'effector'
import { BreadcrumbIndex } from './types'
import { fetchFoundBooksFx } from './requests'
import { $allBooks } from './allBooks'
import { $myBooks } from './myBooks'
import { IBook } from '../../../api/types.books'

type FoundBooks = Map<string, IBook> | null

export const setIsSearchActive = createEvent<string>()

export const resetSearchIsActive = createEvent()

export const resetFoundBooks = createEvent()

export const $isSearchActive = createStore(false)
  .on(setIsSearchActive, (state, searchText) => {
    return searchText !== ''
  })
  .reset(resetSearchIsActive)

const getBook = (bookId: string, allBooks: IBook[] | null, myBooks: IBook[] | null) => {
  return (
    allBooks?.find((book) => book._id === bookId) || myBooks?.find((book) => book._id === bookId)
  )
}

export const $foundBooks = createStore<FoundBooks>(null)
  .on(fetchFoundBooksFx.done, (_, { result }) => {
    const newState = new Map<string, IBook>()
    const allBooks = $allBooks.getState()
    const myBooks = $myBooks.getState()
    result.forEach((bookBreadcrumb) => {
      const searchedBookId = bookBreadcrumb.breadcrumbs[BreadcrumbIndex.BOOK].id
      const book = getBook(searchedBookId, allBooks, myBooks)

      if (book) {
        newState.set(`${book._id}-${bookBreadcrumb.breadcrumbs[BreadcrumbIndex.SECTION].id}`, book)
      }
    })
    return newState
  })
  .reset(resetFoundBooks)

export const $isFoundBooksLoading = fetchFoundBooksFx.pending
