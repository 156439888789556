import React, { useEffect, useState } from 'react'

import './SkillsInternalTable.scss'

import { IMergedSkillsStatsAndDataItem } from '../../SkillsTable'
import { groupBy } from 'lodash'
import { clearSection, getSection } from '../../../../redux/modules/section.module'
import { openCreateOrEditExerciseModal } from '../../../../redux/modules/modal.module'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { IDataForCreateOrEditExerciseModal } from '../SkillsProgressTable'
import { Tooltip } from '@mui/material'
import BooksTitle from './BooksTitle/BooksTitle'
import ChapterTitle from './ChapterTitle/ChapterTitle'
import SectionTitle from './SectionTitle/SectionTitle'
import { ILocalization } from '../../../../config/languages.config'
import { IExercise } from '../../../../api/types.assignments'
import { ISkillsUsers } from '../../../../api/types.statistics'
import { ISkill } from '../../../../api/types.oldSkills'

interface ISkillsInternalTable {
  localization: ILocalization
  book: IMergedSkillsStatsAndDataItem[]
  exercise: IExercise
  teacherId: string
  skillsData: ISkill[]
}

const SkillsInternalTable: React.FC<ISkillsInternalTable> = ({
  localization,
  book,
  exercise,
  teacherId,
  skillsData,
}) => {
  const groupByBook = groupBy(book, 'book')
  const groupByChapter = groupBy(book, 'chapter')
  const [chosenSkillId, setChosenSkillId] = useState<string>('')
  const problemsFromSection = useSelector((state: RootState) => state.section.data.problems)
  const limitLettersForBooks = 25
  const limitLettersForChapters = 30
  const limitLettersForSections = 37

  const dispatch = useDispatch()

  const getBookName = (bookId: string) =>
    book.find((bookItem: IMergedSkillsStatsAndDataItem) => bookId === bookItem.book)!.bookName

  const getChapterName = (chapterId: string) =>
    book.find((bookItem: IMergedSkillsStatsAndDataItem) => chapterId === bookItem.chapter)!
      .chapterName

  const addExtraClassToSectionItem = (skillId: string) =>
    skillId === localization.data.other ? '' : 'section-item-active'

  const chooseSkillName = (skillId: string) => {
    if (skillsData) {
      const res = skillsData.find((skillsName: ISkill) => skillsName?._id === skillId)
      return res?.name || localization.data.other
    }
    return localization.data.other
  }

  const getStudentIds = (skillId: string) => {
    const minPercentageSolved = 60
    const chosenSkill: IMergedSkillsStatsAndDataItem | undefined = book.find(
      (skillStats: IMergedSkillsStatsAndDataItem) =>
        skillId ? skillStats?.skillId === skillId : !skillStats?.skillId
    )

    const usersWithoutTeacher = chosenSkill!.users.filter(
      (user: ISkillsUsers) => user.userId !== teacherId
    )
    const studentsWithBadResult = usersWithoutTeacher.filter(
      (user: ISkillsUsers) =>
        (user.solvedPercentage < minPercentageSolved && user.solvedPercentage) || user.wrong
    )

    return studentsWithBadResult.map((user: ISkillsUsers) => user.userId)
  }

  const getProblemsFromSection = (skillId: string) => {
    const chosenSkill = skillsData.find((skill: ISkill) => skill._id === skillId)
    const chosenSectionId = chosenSkill!.mainSection
    setChosenSkillId(skillId)
    dispatch(getSection(chosenSectionId, history))
  }

  useEffect(() => {
    if (problemsFromSection && chosenSkillId) {
      openModal(chosenSkillId)
    }
    return () => {
      setChosenSkillId('')
      dispatch(clearSection())
    }
  }, [problemsFromSection])

  const openModal = (skillId: string) => {
    const studentsAssigned = getStudentIds(skillId)
    const allClassroomStudents =
      exercise.allClassroomStudents && exercise.students.length === studentsAssigned.length

    const dataForModal: IDataForCreateOrEditExerciseModal = {
      allClassroomStudents,
      name: `${exercise.name} ${chooseSkillName(skillId)}`,
      classes: exercise.classes.map((cls) => cls._id),
      groups: [],
      problems: problemsFromSection,
      students: studentsAssigned,
      startDate: new Date().toISOString(),
      endDate: null,
      testModeEnabled: false,
      allowedAnswerLooking: true,
      numberOfProblems: problemsFromSection?.length,
      exerciseId: exercise._id,
      updateMarker: 'update',
    }

    dispatch(openCreateOrEditExerciseModal(dataForModal))
  }

  const onClickHandler = (skillId: string) => {
    if (!(skillId === localization.data.other)) {
      getProblemsFromSection(skillId)
    }
  }

  return (
    <div className='skills-internal-table-container'>
      <div className='item-wrapper'>
        <div className='book-container'>
          {Object.keys(groupByBook).toString().length > limitLettersForBooks ? (
            <Tooltip
              title={getBookName(Object.keys(groupByBook).toString()) as string}
              placement='top'
              disableInteractive={true}
            >
              <BooksTitle
                getBookName={getBookName}
                groupByBook={groupByBook}
                limitLettersForBooks={limitLettersForBooks}
              />
            </Tooltip>
          ) : (
            <BooksTitle
              getBookName={getBookName}
              groupByBook={groupByBook}
              limitLettersForBooks={limitLettersForBooks}
            />
          )}
        </div>
        <div className='chapter-container'>
          {Object.keys(groupByChapter).map((chapterId: string, index: number) => (
            <div key={index} className='chapter-item'>
              {getChapterName(chapterId)!.length > limitLettersForChapters ? (
                <Tooltip
                  title={getChapterName(chapterId) as string}
                  placement='top'
                  disableInteractive={true}
                >
                  <ChapterTitle
                    chapterId={chapterId}
                    getChapterName={getChapterName}
                    limitLettersForChapters={limitLettersForChapters}
                  />
                </Tooltip>
              ) : (
                <ChapterTitle
                  chapterId={chapterId}
                  getChapterName={getChapterName}
                  limitLettersForChapters={limitLettersForChapters}
                />
              )}
              <div className='section-container'>
                {groupByChapter[chapterId].map((item: IMergedSkillsStatsAndDataItem) => (
                  <div
                    key={item.skillId}
                    className={`section-item ${addExtraClassToSectionItem(item.skillId)}`}
                  >
                    {item.name.length > limitLettersForSections ? (
                      <Tooltip title={item.name} placement='top' disableInteractive={true}>
                        <SectionTitle
                          key={item.skillId}
                          item={item}
                          onClickHandler={onClickHandler}
                          limitLettersForSections={limitLettersForSections}
                        />
                      </Tooltip>
                    ) : (
                      <SectionTitle
                        key={item.skillId}
                        item={item}
                        onClickHandler={onClickHandler}
                        limitLettersForSections={limitLettersForSections}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SkillsInternalTable
