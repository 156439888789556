import React, { useState } from 'react'
import { MenuItem, SelectChangeEvent } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from 'store/store'
import styles from './ChangeSchool.module.scss'
import UISelect from '../../ui/selects/UISelect/UISelect'
import { classesSelectStyles, classesStyles } from '../../styles/MUISelectStyles'
import { getMe } from '../../redux/modules/auth.module'
import { updateTeacherCurrentSchool } from './model'
import { clearSchoolClasses } from '../../redux/modules/schoolClasses.module'
import { resetCurrentSchoolTeachers } from '../../redux/modules/schoolTeachers.module'
import { selectClass } from '../filters/MultiplicityFilter/classesFilter.module'
import useText from '../../i18n/hook'
import { COLORS } from '@magmamath/ui'
import { TeacherSchool } from '../../api/types.teachers'
import { getDefaultClassOption } from 'features/filters/MultiplicityFilter/defaultClassOption.helpers'

const FIRST_ROW_LIMIT = 28

const inlineSelectStyle = {
  color: COLORS.NEUTRAL_8,
  background: COLORS.NEUTRAL_1,
  width: '250px',
  height: '42px',
}

const ChangeSchool = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useSelector((state: RootState) => state.auth.data.me)
  const teacherSchools = useSelector((state: RootState) => state.teacherSchools)
  const [isOpen, setOpen] = useState(false)
  const hasOnlyOneSchool = teacherSchools.data.length === 1 || !Array.isArray(teacherSchools.data)
  const [isError, setError] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState({
    _id: me?.school._id,
    name: me?.school.name,
  })
  const t = useText()
  const onClickHandler = (school: { _id: string; name: string }) => {
    setSelectedSchool({ _id: school._id, name: school.name })
  }

  const handleOpen = () => {
    if (hasOnlyOneSchool) return
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSchoolChange = async (e: SelectChangeEvent) => {
    try {
      const newSchoolId = e.target.value
      dispatch(clearSchoolClasses())
      await updateTeacherCurrentSchool({ teacherId: me._id, schoolId: newSchoolId })
      resetCurrentSchoolTeachers()(dispatch)
      dispatch(getMe(history))
      dispatch(selectClass(getDefaultClassOption(t)))
    } catch (error) {
      setError(true)
    }
  }
  if (isError || hasOnlyOneSchool) return null
  return (
    <UISelect
      value={selectedSchool._id}
      onChange={onSchoolChange}
      firstRowLimit={FIRST_ROW_LIMIT}
      label={selectedSchool.name}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      style={inlineSelectStyle}
      sx={classesStyles}
      isIconHidden={hasOnlyOneSchool}
      sxSelect={classesSelectStyles}
    >
      {Array.isArray(teacherSchools.data) &&
        teacherSchools.data?.map((school: TeacherSchool) => (
          <MenuItem
            key={school._id}
            value={school._id}
            className={styles.MenuItem}
            onClick={onClickHandler.bind(this, school)}
          >
            {school.name}
          </MenuItem>
        ))}
    </UISelect>
  )
}

export default ChangeSchool
