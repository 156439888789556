import { Keys } from 'helpers/enums'
import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import FormGroup from 'reactstrap/lib/FormGroup'
import styles from './SectionModal.module.scss'
import { Button, Input, BUTTON_COLOR, BUTTON_VARIANT, SIZE, Modal } from '@magmamath/ui'
import { SectionModalMode } from 'helpers/enums'
import useText from 'i18n/hook'
import { useUnit } from 'effector-react'
import { MyBookModel } from '../model'

type SectionModalProps = {
  isOpen: boolean
  onModalStateReset: () => void
  mode: SectionModalMode | null
}

const SectionModal = ({ isOpen, onModalStateReset, mode }: SectionModalProps) => {
  const isEditMode = mode === SectionModalMode.EDIT
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const [inputValue, setInputValue] = useState(isEditMode ? selectedSection?.name || '' : '')
  const inputRef = useRef<HTMLInputElement>(null)
  const t = useText()

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  if (!selectedSection) return null

  const onInputValueChange = (e: React.FormEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)

  const onClose = () => {
    setInputValue(isEditMode ? selectedSection?.name : '')
    onModalStateReset()
  }

  const onSubmit = () => {
    if (isEditMode) {
      const data = {
        name: inputValue,
      }
      MyBookModel.editSectionFx({ data })
    } else {
      MyBookModel.createSectionFx({ name: inputValue })
    }
    onClose()
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.ENTER && inputValue && inputValue.length > 0) {
      onSubmit()
      onClose()
    }
  }

  return (
    <Modal
      classes={{ Dialog: styles.Dialog }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header
        title={
          isEditMode
            ? t.editSubchapterModalTitle
            : t.newSubchapterChooseNameTxt
        } />
      <Modal.Content classes={{ Content: styles.Content }}>
        <FormGroup className={styles.FormGroup}>
          <Input
            ref={inputRef}
            label={t.nameSubchapterInputLabel}
            placeholder={t.nameSubchapterInputPlaceholder}
            value={inputValue}
            onChange={onInputValueChange}
            onKeyDown={onKeyPress}
          />
        </FormGroup>
        <div className={styles.Footer}>
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.LARGE}
            color={BUTTON_COLOR.ORANGE}
            disabled={!inputValue?.length}
            onClick={onSubmit}
            type='submit'
          >
            <span>{t.saveTxt}</span>
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default SectionModal
