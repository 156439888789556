import { ButtonLoader } from 'components/ButtonLoader/ButtonLoader.component'
import { KeyCode } from 'helpers/enums'
import { validateEmailAndUsernameForm } from 'helpers/synchValidation.helpers'
import * as React from 'react'
import { Component } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { ILocalizationActions } from '../../../../redux/modules/localization.module'
import { IState } from '../../../../interfaces/state.interface'
import { currentLocale } from '../../../../config/modules.config'
import { config } from '../../../../config'
import { LocaleData } from 'helpers/enums'
import { setLanguage } from '../../../../helpers/language.helpers'
import { renderInputField } from '../../../../helpers/form.helpers'
import '../RegisterForms.scss'
import { ILocalization } from '../../../../config/languages.config'

// tslint:disable-next-line:interface-name
interface InputEmailProps extends InjectedFormProps<FormData, any> {
  history: any
  localization: ILocalization
  onSubmit: (values: any, history: any) => {}
  serverErrors?: { message: string }
  accountActions: IState<any>
  goToAuthApp: () => void
  localizationActions: ILocalizationActions
}

class ResetPasswordForm extends Component<InputEmailProps, any> {
  public componentDidMount() {
    currentLocale.currentLang =
      config.APP_HOST_SWEDEN === config.APP_HOST ? LocaleData.SE : LocaleData.US
    setLanguage(this.props.localizationActions)
  }

  public handleKeyDown = (event: any, submit: any) => {
    if (event.keyCode === KeyCode.ENTER) {
      this.props.handleSubmit(submit)
    }
  }

  public render() {
    const { handleSubmit, localization, valid, invalid, pristine } = this.props
    const { forgotPasswordIntrodution, emailTxt, getResetCode, backTxt } = localization.data

    const submit = (values: any) => {
      if (valid) {
        this.props.onSubmit(values, this.props.history)
      }
      return
    }

    if (!Object.keys(localization.data).length) {
      return
    }

    return (
      <div>
        <form onSubmit={handleSubmit(submit)} className='register-form'>
          <p className='register-form-description'>{forgotPasswordIntrodution}</p>
          <div className='form-group register-form-group'>
            <Field
              name='email'
              component={renderInputField as any}
              type='text'
              required={true}
              placeholder={emailTxt}
              className='form-control'
              autoFocused={true}
              id='email'
              onKeyDown={(event: any) => this.handleKeyDown(event, submit)}
            />
          </div>
          <div className='buttons-wrapper'>
            <button
              type='button'
              className='back-button'
              onClick={() => {
                this.props.goToAuthApp()
              }}
            >
              {backTxt}
            </button>
            <ButtonLoader
              className='confirm-button'
              type='submit'
              autoFocus={true}
              loading={this.props.accountActions.loading}
              disabled={pristine || invalid}
            >
              {getResetCode}
            </ButtonLoader>
          </div>
        </form>
      </div>
    )
  }
}

const InputEmailForm: any = reduxForm({
  enableReinitialize: true,
  // tslint:disable-next-line:object-literal-sort-keys
  destroyOnUnmount: false,
  form: 'inputEmail',
  validate: validateEmailAndUsernameForm,
})(ResetPasswordForm as any)

export default InputEmailForm
