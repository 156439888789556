import { useState } from 'react'
import {
  DrawingHistoryMode,
  HTMLDrawAreaPreset,
  observableValue,
} from '@magmamath/react-native-draw-board'
import { DrawBoardPreviewPreset, DrawBoardHistorySettings } from './types'
/**
 *
 * Do not support dynamic change of isAvailable, so preset will be created when component is mounted and
 * won't be changed after that
 */
export const useDrawBoardPreviewPreset = (isAvailable: boolean): DrawBoardPreviewPreset | null => {
  const [preset] = useState(() => {
    if (!isAvailable) return null
    return new HTMLDrawAreaPreset({
      historySettings: {
        mode: DrawingHistoryMode.LINEAR_BRANCHES,
        refitInPlayable: true,
        alwaysWaitForFullLoading: true,
      },
      renderSettings: {
        minScale: 0.05,
        cellsCountToFit: 5,
      },
    }).setContext({
      $historySettings: observableValue<DrawBoardHistorySettings | null>(null),
      attemptOffsetRef: 0,
    })
  })
  return preset
}
