import { PATHS } from 'config/pathnames.config'
import { useHistory } from 'react-router'

import { GetAllGroupsResponse } from '../api/api.groups'

export function checkClassEditingPage(pathname: string) {
  const regularExpressionClass: RegExp = /(class)/
  const regularExpressionEdit: RegExp = /(edit)/
  const isExistInPathname: boolean =
    regularExpressionClass.test(pathname) && regularExpressionEdit.test(pathname)

  return isExistInPathname
}

const PATHS_WITHOUT_SIDEBAR = {
  PARTIAL: [
    PATHS.PARTS.SOLUTIONS,
    PATHS.PARTS.MULTIPLE_SOLUTIONS,
    PATHS.PARTS.PINNED,
    PATHS.LOGIN.LOGIN_MAIN,
    PATHS.PARTS.HEATMAP,
    PATHS.PARTS.SKILLS,
    PATHS.PARTS.EXTENDED_VIEW,
    'student-progress',
    PATHS.PARTS.PIE_CHART,
    PATHS.PARTS.PROBLEMS_EDIT,
    PATHS.EXERCISES.CREATE,
    PATHS.EXERCISES.COPY,
    PATHS.EXERCISES.EDIT,
  ],
  FULL: [
    PATHS.CONTENT.PROBLEMS,
    PATHS.CONTENT.ADD_NEW_PROBLEM,
    PATHS.CONTENT.PROBLEMS_EDIT,
    '/start',
  ],
}

const PATHS_WITH_HIDDEN_SIDEBAR = {
  PARTIAL: [PATHS.PARTS.EXERCISES_ADD],
  FULL: [],
}

type HistoryReturn = ReturnType<typeof useHistory>

export const isExerciseEditPage = (pathname: string) => {
  const editRegExp = /^\/exercises\/[^/]+\/edit$/
  const copyRegExp = /^\/exercises\/[^/]+\/copy$/

  return editRegExp.test(pathname) || copyRegExp.test(pathname)
}

export function detectPageWithHiddenSidebar(history: HistoryReturn) {
  return (
    PATHS_WITH_HIDDEN_SIDEBAR.FULL.some((path) => history.location.pathname === path) ||
    PATHS_WITH_HIDDEN_SIDEBAR.PARTIAL.some((path) => history.location.pathname.includes(path))
  )
}

export function detectPageWithoutSidebar(history: HistoryReturn) {
  return (
    PATHS_WITHOUT_SIDEBAR.FULL.some((path) => history.location.pathname === path) ||
    isExerciseEditPage(history.location.pathname) ||
    PATHS_WITHOUT_SIDEBAR.PARTIAL.some((path) => history.location.pathname.includes(path))
  )
}

const PATH_WITHOUT_MAIN_HEADER = {
  FULL: [],
}

export function detectPageWithoutMainHeader(history: HistoryReturn) {
  return PATH_WITHOUT_MAIN_HEADER.FULL.some((path) => history.location.pathname === path)
}

export const getActiveClassId = (queryString?: string) => {
  if (!queryString) return null

  const regex = /\/classes\/([^/?]+)/
  const match = queryString.match(regex)

  return match ? match[1] : null
}

export const getActiveGroup = (queryString?: string, groups?: GetAllGroupsResponse) => {
  if (!groups || !queryString) return null

  const regex = /(?:\?|&)group=([^&]+)/
  const match = queryString.match(regex)
  if (match) return groups._embedded.groups?.find((group) => group._id === match[1]) ?? null

  return null
}

export const checkIsGroupDetailPage = (search?: string) =>
  search ? search.includes('=groups&group=') : false

export const isTeacherPathActive = (pathname: string) =>
  [PATHS.ACCOUNT, PATHS.EXERCISES.EXERCISES_MAIN, PATHS.CLASSES].includes(pathname)
