import React from 'react'
import clsx from 'clsx'
import { COLORS } from '@magmamath/ui'

import styles from './YearSelectorControl.module.scss'
import ArrowLeftIcon from '../../../../../../ui/icons/ArrowLeftIcon/ArrowLeftIcon'

type YearSelectorControlProps = {
  isDisabled: boolean
  onClick: () => void
  isReversed?: boolean
}

const YearSelectorControl = ({ isDisabled, onClick, isReversed }: YearSelectorControlProps) => {
  return (
    <div
      className={clsx(styles.YearSelectorArrowControl, { [styles.Reversed]: isReversed })}
      aria-disabled={isDisabled}
      onClick={onClick}
    >
      <ArrowLeftIcon color={COLORS.NEUTRAL_9} size={15} />
    </div>
  )
}

export default YearSelectorControl
