import { LocaleData } from '../helpers/enums'
import { ILocalization } from '../config/languages.config'

const EN_GRADES_COUNT = 12
const SE_GRADES_COUNT = 9
const UK_GRADES_COUNT = 12
export const EN_GRADES_LENGTH = 14

export const EXTRA_GRADES_FOR_SE = [
  { value: 10, label: 'Matematik 1' },
  { value: 11, label: 'Matematik 2' },
]

type ExtraGradesForSe = typeof EXTRA_GRADES_FOR_SE

export const createBasicGrades = (localization: ILocalization) => {
  return [
    {
      value: '-1',
      label: localization.data.other,
    },
    {
      value: '0',
      label: localization.data.gradeZeroValue,
    },
  ]
}

export const getNumbersGrades = (arrayLength: number) => {
  return Array.from(Array(arrayLength).keys()).map((el) => {
    return { value: el + 1, label: el + 1 }
  })
}

type LocalGradeDescriptor = {
  extraGrades?: ExtraGradesForSe
  gradesCount: number
}

const GRADES_DESCRIPTORS: Partial<Record<LocaleData, LocalGradeDescriptor>> = {
  [LocaleData.US]: {
    gradesCount: EN_GRADES_COUNT,
  },
  [LocaleData.SE]: {
    gradesCount: SE_GRADES_COUNT,
    extraGrades: EXTRA_GRADES_FOR_SE,
  },
  [LocaleData.GB]: {
    gradesCount: UK_GRADES_COUNT,
  },
}

const createGrades = (localization: ILocalization, descriptor?: LocalGradeDescriptor) => {
  if (!descriptor) return createBasicGrades(localization)

  return [
    ...createBasicGrades(localization),
    ...getNumbersGrades(descriptor.gradesCount),
    ...(descriptor.extraGrades || []),
  ]
}

export const getFullGrades = (
  currentLocale: LocaleData | undefined,
  localization: ILocalization
) => {
  const descriptor = GRADES_DESCRIPTORS[currentLocale || LocaleData.US]
  return createGrades(localization, descriptor)
}

export const getGradeItem = (gradeNum: number, localization: ILocalization) => {
  const gradeMap: Partial<Record<string, string>> = {
    '-1': ' ',
    '0': localization.data.gradeZeroValue,
  }
  return gradeMap[gradeNum] || gradeNum
}
