import React from 'react'
import styles from './SettingsCreatorBoard.module.scss'
import ProblemLevelAvatar, {
  AvatarValue,
} from '../../../ui/avatars/ProblemLevelAvatar/ProblemLevelAvatar'
import useText from '../../../i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { setLevel } from '../../../redux/modules/problemCreator.module'

const SettingsCreatorBoard = () => {
  const t = useText()
  const dispatch = useDispatch()
  const level = useSelector((state: RootState) => state.problemCreatorData.level)

  const setCurrentLevel = (param: AvatarValue) => {
    dispatch(setLevel(param))
  }

  return (
    <div className={styles.Container}>
      <ProblemLevelAvatar
        value={level}
        type={AvatarValue.GREEN}
        title='1'
        label={t.easyLevel}
        classes={{ container: styles.ExtraAvatarClass }}
        onClick={() => {
          setCurrentLevel(AvatarValue.GREEN)
        }}
      />
      <ProblemLevelAvatar
        value={level}
        type={AvatarValue.YELLOW}
        title='2'
        label={t.mediumLevel}
        classes={{ container: styles.ExtraAvatarClass }}
        onClick={() => {
          setCurrentLevel(AvatarValue.YELLOW)
        }}
      />
      <ProblemLevelAvatar
        value={level}
        type={AvatarValue.RED}
        title='3'
        label={t.hardLevel}
        classes={{ container: styles.ExtraAvatarClass }}
        onClick={() => {
          setCurrentLevel(AvatarValue.RED)
        }}
      />
    </div>
  )
}

export default SettingsCreatorBoard
