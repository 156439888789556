import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import SmallArrowRightIcon from 'ui/icons/SmallArrowRightIcon/SmallArrowRightIcon'
import styles from './StudentsNavigation.module.scss'

type StudentsNavigationProps = {
  title: string
  nextLink?: string
  previousLink?: string
}

const StudentsNavigation = forwardRef<HTMLParagraphElement, StudentsNavigationProps>(
  ({ title, previousLink, nextLink }, ref) => {
    return (
      <div className={styles.AttemptsNavigation}>
        {previousLink ? (
          <Button
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZE.MEDIUM}
            icon={<ArrowLeftIcon color='var(--icon-color)' size={20} className={styles.Icon} />}
            As={Link}
            isTransparent
            to={previousLink}
          />
        ) : (
          <div />
        )}
        <p className={styles.Title} ref={ref}>
          {title}
        </p>
        {nextLink ? (
          <Button
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZE.MEDIUM}
            icon={
              <SmallArrowRightIcon color='var(--icon-color)' size={20} className={styles.Icon} />
            }
            As={Link}
            isTransparent
            to={nextLink}
          />
        ) : (
          <div />
        )}
      </div>
    )
  }
)

export default StudentsNavigation
