import { ALPHABET } from './constants'
import { IExercise } from '../../api/types.assignments'

export const getMergedProblemsAndSubProblems = (exercise: IExercise) => {
  if (!exercise?.problems) {
    return []
  }
  return exercise.problems.reduce((acc, problem, index) => {
    const problemIndex = (index + 1).toString()
    if (problem.subProblems && problem.subProblems.length > 0) {
      const subProblemsWithNames = problem.subProblems.map((subProblem, subProblemIndex) => ({
        ...subProblem,
        name: `${problemIndex} ${ALPHABET[subProblemIndex]}`,
        description: `${problem.description} ${subProblem.description}`,
        image: subProblem.image || problem.image,
        drawingAreaImage: subProblem.drawingAreaImage || problem.drawingAreaImage,
      }))
      return [...acc, ...subProblemsWithNames]
    }

    const namedProblem = { ...problem, name: problemIndex }
    return [...acc, namedProblem]
  }, [])
}
