import React, { useEffect, useState } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { IState } from '../../../interfaces/state.interface'
import {
  IExercisesStatusActions,
  IExercisesStatuses,
} from '../../../redux/modules/exercisesStatuses.module'

import SidebarLinkIcon from './SidebarLinkIcon/SidebarLinkIcon'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { CategoryStatsParams } from '../../../helpers/enums'
import clsx from 'clsx'
import { PATHS } from '../../../config/pathnames.config'
import { useHistory } from 'react-router'
import { ILocalization } from '../../../config/languages.config'
import { COLORS, Listbox } from '@magmamath/ui'
import { config } from '../../../config'
import { IClass } from '../../../api/types.classes'

interface ISideBarLink {
  index: number
  className: string
  activeRoute: (routeName: string) => string
  path: string
  setExerciseSubLinks: (param: boolean) => void
  isExercisesPath: boolean
  icon: string
  name: string
  exerciseSubLinks: boolean
  checkActiveFilterLink: (activeFilter: boolean) => string
  localization: ILocalization
  exercisesStatuses: IState<IExercisesStatuses>
  changeExerciseStatuses: (callback: () => void) => void
  exercisesStatusActions: IExercisesStatusActions
  refs: {}
  isExercisesPage: boolean
}

interface IMultiplicity {
  _id: string | null
  type: CategoryStatsParams
}

const SideBarLink: React.FC<ISideBarLink> = ({
  index,
  className,
  activeRoute,
  path,
  setExerciseSubLinks,
  isExercisesPath,
  name,
  refs,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const getIconColor = (param: string) => {
    const hasActive: boolean = activeRoute(param) === 'active'
    const hoverIconColor = isHovered ? COLORS.PRIMARY_ORANGE : COLORS.NEUTRAL_7
    return hasActive ? COLORS.PRIMARY_ORANGE : hoverIconColor
  }
  const currentClassId = useSelector((state: RootState) => state.classesFilter?.value)
  const groups = useSelector((state: RootState) => state.groups.data?._embedded?.groups)
  const classes = useSelector((state: RootState) => state.classes.data?._embedded?.classes)
  const [classOrGroup, setClassOrGroup] = useState<IMultiplicity | null>(null)
  const history = useHistory()
  const isStudentsPage = !!matchPath(history.location.pathname, PATHS.STUDENTS.STUDENTS_MAIN)

  useEffect(() => {
    if (path !== '/students') {
      return
    }

    if (!groups || !classes) {
      return
    }

    const notArchivedClasses = classes.filter((item: IClass) => !item.archived)

    if ((!currentClassId || currentClassId === Listbox.ALL_OPTION) && isStudentsPage) {
      setClassOrGroup({ _id: null, type: CategoryStatsParams.CLASSES })
      return
    }

    if (notArchivedClasses.length === 1 && !groups.length && !isStudentsPage) {
      setClassOrGroup({ _id: notArchivedClasses[0]?._id, type: CategoryStatsParams.CLASSES })
      return
    }

    const currentMultiplicity = classes.find((m: IClass) => m._id === currentClassId)
    const _id = currentMultiplicity ? currentMultiplicity._id : null
    const type = currentMultiplicity
      ? currentMultiplicity.invitationalCode
        ? CategoryStatsParams.CLASSES
        : CategoryStatsParams.GROUPS
      : CategoryStatsParams.CLASSES
    setClassOrGroup({ _id, type })
  }, [currentClassId, groups, classes, path, isStudentsPage])
  return (
    <li
      className={`sidebar-link-wrapper ${className} ${activeRoute(path)} link_margin-bottom`}
      key={index}
      ref={(ref: any) => {
        refs[index] = ref
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Link
        to={{
          pathname:
            path !== PATHS.PARTS.STUDENTS
              ? path
              : `/students/${classOrGroup?.type}/${classOrGroup?._id}`,
          search: '?tab=students',
          state: { from: history.location.pathname },
        }}
        onClick={() => {
          setExerciseSubLinks(isExercisesPath)
        }}
        className='sidebar-link'
      >
        <SidebarLinkIcon param={path} getIconColor={getIconColor} />
        <p
          className={clsx({
            ['sidebar-link-active']: activeRoute(path),
            ['sidebar-link-active-filter']: activeRoute(path),
            ['sidebar-link-hover']: isHovered,
          })}
        >
          {name}
        </p>
        {path === PATHS.PRACTICE.PRACTICE_MAIN &&
          config.APP_HOST === config.APP_HOST_INTERNATIONAL && (
            <span
              className={clsx('beta-tag', {
                ['sidebar-link-active']: activeRoute(path),
                ['sidebar-link-active-filter']: activeRoute(path),
                ['sidebar-link-hover']: isHovered,
              })}
            >
              beta
            </span>
          )}
      </Link>
    </li>
  )
}

export default SideBarLink
