import clsx from 'clsx'
import { Directions } from 'helpers/enums'
import useScrollPosition from 'lib/hooks/useScrollPosition'
import React, { useLayoutEffect } from 'react'
import UIButton from 'ui/Buttons/UIButton/Button'
import ArrowIcon from 'ui/icons/ArrowIcon/ArrowIcon'
import styles from './ScrollButtons.module.scss'

type TableScrollButtonProps = {
  scrollThresholdX: number
  scrollThresholdY: number
  scrollPositionData: ReturnType<typeof useScrollPosition>
  isCompressedMode: boolean
  isExamMode: boolean
}

const TableScrollButtons = ({
  scrollPositionData,
  scrollThresholdX,
  scrollThresholdY,
  isCompressedMode,
  isExamMode,
}: TableScrollButtonProps) => {
  const { scrollPosition, scrollTo, scrollStatus, scrollAvailability, updateScrollStatus } =
    scrollPositionData

  const onScrollButtonClick = (direction: Directions) => {
    let deltaX = 0
    let deltaY = 0

    const directionDeltaMap = {
      [Directions.UP]: { deltaX: 0, deltaY: -scrollThresholdY },
      [Directions.DOWN]: { deltaX: 0, deltaY: scrollThresholdY },
      [Directions.LEFT]: { deltaX: -scrollThresholdX, deltaY: 0 },
      [Directions.RIGHT]: { deltaX: scrollThresholdX, deltaY: 0 },
    }

    deltaX = directionDeltaMap[direction].deltaX
    deltaY = directionDeltaMap[direction].deltaY

    scrollTo(scrollPosition.x + deltaX, scrollPosition.y + deltaY)
  }

  const buttonsParams = [
    {
      direction: Directions.UP,
      directionClassNames: clsx(styles.Top, {
        [styles.ExamModeTop]: isExamMode,
        [styles.Compressed]: isCompressedMode,
      }),
      isVisible: !scrollStatus.isScrolledToTop && !!scrollPosition.y,
    },
    {
      direction: Directions.DOWN,
      directionClassNames: clsx(styles.Bottom, {
        [styles.ExamModeBottom]: isExamMode,
        [styles.Compressed]: isCompressedMode,
      }),
      isVisible: !scrollStatus.isScrolledToBottom && scrollAvailability.hasScrollY,
    },
    {
      direction: Directions.RIGHT,
      directionClassNames: clsx(styles.Right, {
        [styles.ExamModeRight]: isExamMode,
        [styles.Compressed]: isCompressedMode,
      }),
      isVisible: !scrollStatus.isScrolledToRight && scrollAvailability.hasScrollX,
    },
    {
      direction: Directions.LEFT,
      directionClassNames: clsx(styles.Left, {
        [styles.ExamModeLeft]: isExamMode,
        [styles.Compressed]: isCompressedMode,
      }),
      isVisible: !scrollStatus.isScrolledToLeft && !!scrollPosition.x,
    },
  ]

  useLayoutEffect(() => {
    updateScrollStatus()
  }, [])

  return (
    <>
      {buttonsParams.map(({ direction, directionClassNames, isVisible }) =>
        isVisible ? (
          <UIButton
            key={direction}
            className={clsx(styles.ScrollButton, directionClassNames)}
            onClick={() => onScrollButtonClick(direction)}
          >
            <ArrowIcon className={styles.ScrollArrowIcon} size={14} color='none' />
          </UIButton>
        ) : null
      )}
    </>
  )
}

export default TableScrollButtons
