import ConfirmationModal from 'features/modals/ConfirmationModal/ConfirmationModal'
import { prepareEditExerciseData } from 'helpers/exercise.helpers'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { editExercise } from 'redux/modules/exercise.module'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import { updatePendingMutation } from '../../model/pendingMutationStatus'
import CheatDetectionSwitch from '../CheatDetectionSwitch/CheatDetectionSwitch'
import CheatsTableModal from '../CheatsTableModal/CheatsTableModal'
import ExamSettingsButton from '../ExamStatusButtons/ExamSettingsButton'
import styles from './ExamSettings.module.scss'
import { IStudentsExamStats } from '../../../../../api/types.statistics'
import { ExerciseState } from '../../../../../api/types.assignments'

type ExamSettingsProps = {
  exerciseWithoutStatsData: IExerciseWithoutStats
  studentsExamStatsMap: Map<string, IStudentsExamStats>
}

const ExamSettings = ({ exerciseWithoutStatsData, studentsExamStatsMap }: ExamSettingsProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const t = useText()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const exerciseSettingsState = exerciseWithoutStatsData.settings?.state
  const isUnpublishedState = exerciseSettingsState === ExerciseState.NotStarted
  const isDistrictLocked = exerciseSettingsState === ExerciseState.DistrictLocked

  const onStatusChange = (status: ExerciseState) => {
    updatePendingMutation({ mutationType: status })
    const editExerciseData = prepareEditExerciseData(exerciseWithoutStatsData, status)
    return editExercise(editExerciseData, exerciseWithoutStatsData._id, history, true)(dispatch)
  }

  return (
    <div className={styles.ExamSettings}>
      <UITooltip
        title={isDistrictLocked ? t.districtLockedTooltip : undefined}
        placement='bottom'
        className={styles.DistrictLockedTooltip}
      >
        <div className={styles.ExamSettingsButtons}>
          <ExamSettingsButton
            buttonText={t.startTxt}
            tooltipText={t.doForAll?.(t.startTxt)}
            exerciseSettingsState={exerciseSettingsState}
            exerciseState={ExerciseState.Started}
            onClick={() => onStatusChange(ExerciseState.Started)}
          />
          <ExamSettingsButton
            buttonText={t.pauseTxt}
            tooltipText={t.doForAll?.(t.pauseTxt)}
            exerciseSettingsState={exerciseSettingsState}
            exerciseState={ExerciseState.Stopped}
            onClick={() => onStatusChange(ExerciseState.Stopped)}
          />
          <ExamSettingsButton
            buttonText={t.endTxt}
            tooltipText={t.doForAll?.(t.endTxt)}
            exerciseSettingsState={exerciseSettingsState}
            exerciseState={ExerciseState.Finished}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </UITooltip>
      {!isUnpublishedState ? (
        <>
          <ConfirmationModal
            contentText={t.endExamWarning?.(false)}
            confirmText={t.endExam}
            isModalOpen={isModalOpen}
            onModalClose={() => setIsModalOpen(false)}
            onConfirm={() => onStatusChange(ExerciseState.Finished)}
          />
          <CheatsTableModal
            exerciseWithoutStatsData={exerciseWithoutStatsData}
            studentsExamStatsMap={studentsExamStatsMap}
          />
        </>
      ) : (
        <div className={styles.EmptySpacing} />
      )}

      <CheatDetectionSwitch
        disabled={isDistrictLocked}
        exerciseId={exerciseWithoutStatsData._id}
        cheatDetection={exerciseWithoutStatsData.cheatDetection}
      />
    </div>
  )
}

export default ExamSettings
