import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { ActionElement } from '../../../helpers/enums'
import { IMe } from '../../../api/types.users'
import { getGradeItem } from '../../../lib/grades'
import { shouldShowBtn } from '../../../lib/permissions'
import { openCreateClassModal, openModal } from '../../../redux/modules/modal.module'
import ChangeMultiplicityTableUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTable'
import ChangeMultiplicityTableRowUI from '../../../ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTableUIRow/ChangeMultiplicityTableRowUI'
import FirstRowButton from '../StudentsTable/FirstRowButton/FirstRowButton'
import { ILocalization } from '../../../config/languages.config'
import { isRosteredClass } from 'helpers/classesView.helpers'
import { IClass } from '../../../api/types.classes'

interface IChangeClassInfosProps {
  classItem: IClass
  me: IMe
}

const ChangeClassInfo = ({ classItem, me }: IChangeClassInfosProps) => {
  const dispatch = useDispatch()
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data

  const iShowForClassesTab = 'invitationalCode' in classItem
  const showFirstRow = () => {
    if (!iShowForClassesTab) {
      return true
    }
    return shouldShowBtn(me.municipality.isPrivate, classItem.source)
  }

  const showForSpecificClasses =
    iShowForClassesTab && shouldShowBtn(me.municipality.isPrivate, classItem.source)

  const hasDisplayName = isRosteredClass(classItem)

  return (
    <ChangeMultiplicityTableUI>
      {showFirstRow() && (
        <ChangeMultiplicityTableRowUI title={t.nameTxt} subTitle={classItem.name}>
          {!hasDisplayName && (
            <FirstRowButton
              title={t.edit}
              onClick={() => {
                const params: (string | ILocalization | IMe)[] = !iShowForClassesTab
                  ? [t.editGroupNameTxt, t.groupTxt, me, localization]
                  : [t.editClassNameTxt, t.classTxt, me, localization]
                dispatch(openModal.apply(this, params))
              }}
            />
          )}
        </ChangeMultiplicityTableRowUI>
      )}
      {hasDisplayName && (
        <ChangeMultiplicityTableRowUI title={t.displayName} subTitle={classItem.displayName}>
          <FirstRowButton
            title={classItem.displayName ? t.edit : t.addName}
            onClick={() => {
              const params: (string | ILocalization | IMe)[] = [
                classItem.displayName ? t.editDisplayName : t.addDisplayName,
                t.displayName,
                me,
                localization,
              ]
              dispatch(openModal.apply(this, params))
            }}
          />
        </ChangeMultiplicityTableRowUI>
      )}
      {showForSpecificClasses && (
        <ChangeMultiplicityTableRowUI
          title={t.grade}
          subTitle={getGradeItem(classItem.grade, localization).toString()}
        >
          {!hasDisplayName && (
            <FirstRowButton
              title={t.edit}
              onClick={() => {
                dispatch(openCreateClassModal(t.changeGrade, ActionElement.changeGrade, me, null))
              }}
            />
          )}
        </ChangeMultiplicityTableRowUI>
      )}
    </ChangeMultiplicityTableUI>
  )
}

export default ChangeClassInfo
