import loadable from '@loadable/component'
import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { localStorageKeys } from 'config/localStorageKeys'
import {
  getItemFromLocalStorage,
  removeChosenItemsFromSessionStorage,
} from 'helpers/storage.helper'
import { IState } from 'interfaces/state.interface'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { IAutologinActions } from 'redux/modules/autologin.module'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { IFilterExercisesActions } from 'redux/modules/filterExercises.module'
import { IModalActions } from 'redux/modules/modal.module'
import { INotify } from 'redux/modules/notify.module'
import { ITopics, ITopicsActions } from 'redux/modules/topics.module'
import { IExercisesActions } from '../../redux/modules/exercises.module'
import { IExercisesStatusActions } from '../../redux/modules/exercisesStatuses.module'
import ExercisesList from '../../features/ExercisesList/ExercisesList.view'
import { AnimationsProvider } from '../../features/ExercisesList/contexts/AnimationsContext/AnimationsContext'
import { BulkSelectProvider } from '../../features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import styles from './Exercises.module.scss'
import clsx from 'clsx'
import { ILocalization } from '../../config/languages.config'
import Spinner from '../../features/Spinner/Spinner'

import { GetAllAssignmentsResponse, IExercise } from '../../api/types.assignments'
import { GetClassesResponse } from '../../api/types.classes'
import { GetAllGroupsResponse } from '../../api/api.groups'

const Notification = loadable(() => import('components/Notification/Notification.component'))

export interface IExercisesProps {
  localization: ILocalization
  exercises: IState<GetAllAssignmentsResponse>
  exercise: IState<IExercise>
  exercisesStatusActions: IExercisesStatusActions
  modalActions: IModalActions
  history: RouteComponentProps['history']
  exercisesActions: IExercisesActions
  exerciseActions: IExerciseActions
  notification: INotify
  location: Location
  classes: IState<GetClassesResponse>
  groups: IState<GetAllGroupsResponse>
  autologinActions: IAutologinActions
  topics: IState<ITopics>
  topicsActions: ITopicsActions
  filterExercisesActions: IFilterExercisesActions
}

export default class Exercises extends React.PureComponent<IExercisesProps, any, any> {
  constructor(props: IExercisesProps) {
    super(props)
    this.state = {
      isUpdateOpenedTab: false,
    }
  }

  public UNSAFE_componentWillMount() {
    removeChosenItemsFromSessionStorage()
  }

  public componentDidMount() {
    this.props.topicsActions.getTopics()
  }

  public toTriggerOfUpdatingTab = (value: boolean) => {
    this.setState({ isUpdateOpenedTab: value })
  }

  public render() {
    const langFromStorage: string | null = getItemFromLocalStorage(localStorageKeys.language)

    return (
      <Fragment>
        <PanelHeader
          backButton={false}
          title=' '
          className='exercises-header title-with-menu-wrapper'
          localization={this.props.localization as ILocalization}
          history={this.props.history}
          location={this.props.location}
          groups={this.props.groups}
          classes={this.props.classes}
          exercise={this.props.exercise}
          exerciseActions={this.props.exerciseActions}
        />
        {!isEmpty(this.props.topics.data) && (
          <ViewContainer className='exercises-container-view' history={this.props.history}>
            <AnimationsProvider>
              <BulkSelectProvider exercises={this.props.exercises.data?._embedded?.exercises ?? []}>
                {this.props.localization.data.languageTxt === langFromStorage ? (
                  <ExercisesList
                    autologinActions={this.props.autologinActions}
                    modalActions={this.props.modalActions}
                    exercisesActions={this.props.exercisesActions}
                    exerciseActions={this.props.exerciseActions}
                    filterExercisesActions={this.props.filterExercisesActions}
                  />
                ) : (
                  <div className={styles.SpinnerWrapper}>
                    <Spinner className={clsx('loader', styles.Spinner)} />
                  </div>
                )}
              </BulkSelectProvider>
            </AnimationsProvider>
          </ViewContainer>
        )}
        <div className='notification'>
          <Notification
            notification={this.props.notification as INotify}
            history={this.props.history}
            localization={this.props.localization as any}
            toTriggerOfUpdatingTab={this.toTriggerOfUpdatingTab}
          />
        </div>
      </Fragment>
    )
  }
}
