import Quill from 'quill'
import { DeltaItem } from './types.quill'
import { ICoords } from '../common/types'
import { EditInputEvent } from '../mathInput/types.events'

export type ChangedProps = {
  toHtml: () => string
  toKatex: () => string
  toContent: () => ReturnType<Quill['getContents']>
  ops: DeltaItem[]
  root: HTMLElement
}

export enum OperatorType {
  KATEX = 'katex',
  SYMBOL = 'symbol',
}

export type Operator = {
  type: OperatorType
  value: string
}
export type EditingFormula = {
  element: HTMLElement
  initialValue: string
  isDraft?: boolean
}
export type FormulaDraft = Partial<{
  coords: ICoords
  element: HTMLElement
  finish: () => void
  edit: ((props: EditInputEvent) => void) | null
}>
