import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import SolutionsPin from 'features/pinnedSolutions/ui/SolutionsPin/SolutionsPin'
import React, { SyntheticEvent } from 'react'
import CircleIcon from 'ui/icons/CircleIcon/CircleIcon'
import TickInCircleIcon from 'ui/icons/TickInCircleIcon/TickInCircleIcon'
import styles from './SolutionCardActions.module.scss'
import { AddPinParams } from '../../../api/types.pins'

type SolutionCardActionsProps = {
  isCorrect: boolean
  onCorrectnessChange: () => void
  size?: SIZE
  isPinVisible: boolean
  isUserDeleted: boolean
  isCorrectnessVisible: boolean
  pinSolutionId?: string
} & Omit<AddPinParams, 'solutionId'>

enum ICON_SIZE {
  SMALL = 15,
  BIG = 20,
}

const SolutionCardActions = ({
  isCorrect,
  onCorrectnessChange,
  size = SIZE.SMALL,
  isPinVisible = true,
  isUserDeleted,
  isCorrectnessVisible,
  pinSolutionId,
  ...addPinParams
}: SolutionCardActionsProps) => {
  const iconSize = size === SIZE.SMALL ? ICON_SIZE.SMALL : ICON_SIZE.BIG
  return (
    <div className={styles.CardActions}>
      {isCorrectnessVisible && (
        <Button
          variant={BUTTON_VARIANT.TERTIARY}
          size={size}
          isTransparent
          disabled={isUserDeleted}
          icon={
            isCorrect ? (
              <TickInCircleIcon color='var(--icon-color)' size={iconSize} />
            ) : (
              <CircleIcon color='var(--icon-color)' size={iconSize} />
            )
          }
          onClick={(e: SyntheticEvent) => {
            e.preventDefault()
            onCorrectnessChange()
          }}
        />
      )}
      {pinSolutionId && (
        <SolutionsPin
          isPinVisible={isPinVisible}
          size={size}
          {...addPinParams}
          solutionId={pinSolutionId}
        />
      )}
    </div>
  )
}

export default SolutionCardActions
