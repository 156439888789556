import { COLORS } from '@magmamath/ui'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { IMe } from 'api/types.users'
import { localStorageKeys } from 'config/localStorageKeys'
import { STUDENTS_AUTOLOGIN_URL } from 'config/urls'
import { useAnimationsContext } from 'features/ExercisesList/contexts/AnimationsContext/AnimationsContext'
import { EXERCISE_CARD_ANIMATION_DURATION } from 'features/ExercisesList/contexts/AnimationsContext/animationConfig'
import { AssignmentFilterBadges } from 'features/ExercisesList/types/types'
import { DropdownPopupIds, RemovableTypes, TypesOfParams } from 'helpers/enums'
import { getStatusFromExercise } from 'helpers/exercises.helpers'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IAutologinActions } from 'redux/modules/autologin.module'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { EXERCISES_STATUS } from 'redux/modules/exercisesStatuses.module'
import { IModalActions } from 'redux/modules/modal.module'
import { RootState } from 'store/store'
import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { ExerciseState, IExercise } from '../../../../api/types.assignments'
import Divider from '../../../../ui/dividers/Divider/Divider'
import styles from './ExerciseDropdown.module.scss'
import { MuiMenuProps } from './MuiMenuProps'

type ExerciseDropdown = {
  exercise: IExercise
  exerciseActions: IExerciseActions
  modalActions: IModalActions
  title: string
  autologinActions: IAutologinActions
  setFilterBadges: React.Dispatch<React.SetStateAction<AssignmentFilterBadges>>
  openTopicsModal: () => void
  onShareClassroomOptionClick: () => void
}

const ExerciseDropdown = ({
  exercise,
  exerciseActions,
  modalActions,
  title,
  autologinActions,
  setFilterBadges,
  openTopicsModal,
  onShareClassroomOptionClick,
}: ExerciseDropdown) => {
  const history = useHistory()
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data

  const { handleAssignmentsAnimation } = useAnimationsContext()
  const exercisesStatuses = useSelector((state: RootState) => state.exercisesStatuses.data)
  const exerciseStatus = getStatusFromExercise(exercise)
  const saveExerciseInRedux = () => exerciseActions.getExerciseManual(exercise)

  const onAssignmentsStatusChange = (newStatus: EXERCISES_STATUS) => {
    handleAssignmentsAnimation(exercisesStatuses, newStatus)
    setTimeout(() => {
      setFilterBadges((prevState) => ({
        ...prevState,
        [newStatus]: prevState[newStatus] + 1,
      }))
    }, EXERCISE_CARD_ANIMATION_DURATION * 1800)
  }

  const isLockedDistrict = exercise?.settings?.state === ExerciseState.DistrictLocked
  const isArchivable =
    !exercise.archived && (exercise.published || (!exercise.published && isLockedDistrict))

  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.EXERCISE_CARD}>
      {(popupState) => (
        <>
          <IconButton
            disableTouchRipple
            className={styles.DropdownIcon}
            {...bindTrigger(popupState)}
            data-skip-redirect
            data-testid='assignment-menu-icon'
          >
            <OptionIcon size={25} color={COLORS.NEUTRAL_6} data-skip-redirect />
          </IconButton>
          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)} data-testid='assignment-menu'>
            {!exercise.districtExercise && (
              <MenuItem
                onClick={() => {
                  saveExerciseInRedux()
                  popupState.close()
                  history.push(`/exercises/${exercise._id}/edit`)
                }}
              >
                {t.editExercise}
              </MenuItem>
            )}

            {isArchivable && (
              <MenuItem
                onClick={() => {
                  saveExerciseInRedux()
                  onAssignmentsStatusChange(EXERCISES_STATUS.ARCHIVED)
                  popupState.close()
                  exerciseActions.editExercise(
                    { archived: true, published: false },
                    exercise._id,
                    history
                  )
                }}
              >
                {t.exerciseArchiveBtn}
              </MenuItem>
            )}

            {(exercise.archived || (!exercise.published && !isLockedDistrict)) && (
              <MenuItem
                onClick={() => {
                  onAssignmentsStatusChange(EXERCISES_STATUS.AVAILABLE)
                  saveExerciseInRedux()
                  exerciseActions.editExercise(
                    { archived: false, published: true },
                    exercise._id,
                    history
                  )
                  popupState.close()
                }}
              >
                {t.publishTxt}
              </MenuItem>
            )}

            <Divider />

            {!isLockedDistrict && (
              <MenuItem
                onClick={() => {
                  saveExerciseInRedux()
                  const me: IMe = getItemFromLocalStorageWithParsing(localStorageKeys.me)
                  autologinActions.getAutologinTokenAndRedirect({
                    autologinUrl: STUDENTS_AUTOLOGIN_URL,
                    userId: me._id,
                    params: {
                      [TypesOfParams.EXERCISE_ID]: exercise._id,
                    },
                    autologinParamName: TypesOfParams.AUTOLOGIN_STUDENTS_TOKEN,
                  })
                  popupState.close()
                }}
              >
                {t.solve}
              </MenuItem>
            )}

            {!exercise.districtExercise && !isLockedDistrict && (
              <MenuItem
                onClick={() => {
                  saveExerciseInRedux()
                  popupState.close()
                  history.push(`/exercises/${exercise._id}/copy`)
                }}
              >
                {t.copy}
              </MenuItem>
            )}

            {!exercise.districtExercise && !isLockedDistrict && (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  saveExerciseInRedux()
                  onShareClassroomOptionClick()
                }}
              >
                {t.shareLinkTeachersTxt}
              </MenuItem>
            )}

            {exerciseStatus !== EXERCISES_STATUS.UNPUBLISHED &&
              exercise.published &&
              !isLockedDistrict && (
                <MenuItem
                  onClick={() => {
                    saveExerciseInRedux()
                    popupState.close()
                    modalActions.openShareLinkModal()
                  }}
                >
                  {t.shareLinkTxt}
                </MenuItem>
              )}

            <MenuItem
              onClick={(e) => {
                saveExerciseInRedux()
                popupState.close()
                modalActions.openOverlay(
                  title,
                  exercise._id,
                  localization,
                  history,
                  exerciseActions
                )
                e.stopPropagation()
              }}
            >
              {t.printTxt}
            </MenuItem>

            {!exercise.districtExercise && (
              <MenuItem
                onClick={() => {
                  saveExerciseInRedux()
                  popupState.close()
                  openTopicsModal()
                }}
              >
                {t.changeTopic}
              </MenuItem>
            )}

            {!exercise.districtExercise && (
              <div>
                <Divider />
                <MenuItem
                  className='Delete'
                  onClick={() => {
                    saveExerciseInRedux()
                    popupState.close()
                    modalActions.openSimpleConfirmModal(exercise, RemovableTypes.Exercise)
                  }}
                >
                  {t.exerciseDeleteBtn}
                </MenuItem>
              </div>
            )}
          </Menu>
        </>
      )}
    </PopupState>
  )
}
export default ExerciseDropdown
