import React from 'react'
import styles from './Option.module.scss'
import IconCheckbox from '../../../../checkboxes/iconCheckbox/IconCheckbox'
import CheckboxIconDefault from '../../../../icons/CheckboxIconDefault/CheckboxIconDefault'
import { COLORS } from '@magmamath/ui'
import CheckBoxCheckedBackgroundIcon from '../../../../icons/CheckBoxCheckedBackgroundIcon/CheckBoxCheckedBackgroundIcon'

type CustomOptionProps = {
  isOptionActive: boolean
  onClick: () => void
  optionName: string
}

const Option = ({ isOptionActive, onClick, optionName }: CustomOptionProps) => {
  return (
    <div className={styles.CustomOptionContainer} onClick={onClick}>
      <IconCheckbox
        checked={isOptionActive}
        icon={<CheckboxIconDefault size={16} color={COLORS.NEUTRAL_6} />}
        checkedIcon={<CheckBoxCheckedBackgroundIcon size={16} color={COLORS.PRIMARY_GREEN} />}
        disabled={false}
        classes={{ root: styles.Checkbox }}
      />
      <span className={styles.CustomOptionTitle}>{optionName}</span>
    </div>
  )
}

export default Option
