import React from 'react'

import styles from './SingleStudentResults.module.scss'

import { IPracticeAnswerResult } from '../../../../../api/types.oldPractice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import StudentSolution from './StudentSolution/StudentSolution'
import EmptyExerciseContainer from '../../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import useText from '../../../../../i18n/hook'

type SingleStudentResultsProps = {
  studentName: string
}

const SingleStudentResults = ({ studentName }: SingleStudentResultsProps) => {
  const isLoading = useSelector((state: RootState) => state.practiceResults.loading)
  const lastUserSolution = useSelector((state: RootState) => state.practiceResults.lastUserSolution)
  const t = useText()

  if (isLoading) {
    return (
      <div className={styles.NoSolutionsContainer}>
        <EmptyExerciseContainer heightClass={styles.Loader} />
      </div>
    )
  }

  if (!lastUserSolution || !lastUserSolution?.length) {
    return (
      <div className={styles.NoSolutionsContainer}>
        <span className={styles.NoSolutionsText}>{t.noSolutionSubmitted}</span>
      </div>
    )
  }

  return (
    <div className={styles.StudentResultsContainer}>
      {lastUserSolution.map((result: IPracticeAnswerResult, index: number) => {
        return (
          <StudentSolution
            result={result}
            problemNumber={index + 1}
            studentName={studentName}
            key={result._id}
          />
        )
      })}
    </div>
  )
}

export default SingleStudentResults
