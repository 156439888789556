import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowLeftIcon = ({ size = 25, color, strokeWidth = '1.5', className }: SvgIconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 8 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 11.916L1.48495 6.87034C1.27386 6.66805 1.27386 6.33065 1.48495 6.12836L6.75 1.08268'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ArrowLeftIcon
