import React from 'react'
import clsx from 'clsx'
import styles from './SelectedOption.module.scss'
import useText from '../../../../i18n/hook'
import { getCorrectGradeName } from '../../../../features/GradesSelectorModal/helpers'

type SelectedOptionProps = {
  allOptionsSelected: boolean
  allOptionsName: string
  specialOptionName?: string
  selectedOptions: number[]
  isActive: boolean
}

const SelectedOption = ({
  allOptionsSelected,
  allOptionsName,
  specialOptionName,
  selectedOptions,
  isActive,
}: SelectedOptionProps) => {
  const t = useText()

  if (allOptionsSelected || specialOptionName) {
    return (
      <span className={clsx(styles.SpecialSelectedOption, { [styles.Active]: isActive })}>
        {specialOptionName || allOptionsName}
      </span>
    )
  }

  return (
    <>
      {selectedOptions.map((option) => {
        return (
          <div className={styles.SelectedOption} key={option}>
            {getCorrectGradeName(option, t)}
          </div>
        )
      })}
    </>
  )
}

export default SelectedOption
