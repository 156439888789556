import { apiManager } from './apiManager'
import {
  GetSkillsStatisticsResponse,
  GetStudentsResultsResponse,
  GetStudentStatisticsResponse,
} from './types.skillsStatistics'

type GetSkillsStatisticsPayload = {
  entityType: string
  entityId: string
}

type GetStudentStatisticsPayload = {
  id: string
}

type GetStudentsResultsPayload = {
  entityType: string
  entityId: string
  skillId: string
}

export class skillsStatisticsApi {
  public static getStatistics = apiManager.get<
    GetSkillsStatisticsResponse,
    GetSkillsStatisticsPayload
  >(({ entityType, entityId }) => `statistics/led/${entityType}/${entityId}`)

  public static getStudentsResults = apiManager.get<
    GetStudentsResultsResponse,
    GetStudentsResultsPayload
  >(
    ({ entityType, entityId, skillId }) =>
      `statistics/led/skills/${skillId}/${entityType}/${entityId}`
  )
}
