export const POWER_HTML_ELEMENT = "<sup class='a-pow-b'>&nbsp;</sup>"
export const POWER_SQUARE_HTML_ELEMENT = '<sup>2</sup>'
export const POWER_CUBE_HTML_ELEMENT = '<sup>3</sup>'
export const FRACTION_HTML_ELEMENT =
  "<div class='fraction'><span placeholder='a' tabindex='0' inputmode='none' class='fraction-item numerator'></span><span placeholder='b' tabindex='0' inputmode='none' class='fraction-item denominator'></span></div>"

export const replaceSpecialSymbolsToHTMLElements = (value: string) => {
  return value
    .replace(/POWER_SQUARE/g, POWER_SQUARE_HTML_ELEMENT)
    .replace(/POWER_CUBE/g, POWER_CUBE_HTML_ELEMENT)
    .replace(/POWER/g, POWER_HTML_ELEMENT)
    .replace(/FRACTION/g, FRACTION_HTML_ELEMENT)
}
