import React from 'react'
import styles from './ErrorMarker.module.scss'
import useText from '../../../i18n/hook'
import AttentionOutlinedIcon from '../../icons/AttentionOutlinedIcon/AttentionOutlinedIcon'
import { COLORS } from '@magmamath/ui'

const TooltipTitle = () => {
  const t = useText()
  return (
    <div className={styles.TooltipTitleContainer}>
      <AttentionOutlinedIcon size={14} color={COLORS.NEUTRAL_1} />
      <div className={styles.TooltipContent}>{t.commonError}</div>
    </div>
  )
}
export default TooltipTitle
