import React from 'react'
import { SvgIconProps } from '../types/types'

const IncognitoIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.397998 15.6283C0.397998 13.2138 2.33587 11.2564 4.72636 11.2564C6.85578 11.2564 8.66503 12.6518 8.99943 14.8745H10.8015C11.136 12.6518 12.9452 11.2564 15.0746 11.2564C17.4651 11.2564 19.4029 13.2138 19.403 15.6283C19.403 18.0704 17.3422 19.9994 15.0746 19.9994C13.0458 19.9994 11.1848 18.4471 10.8134 16.3821H8.98612C8.61158 18.4238 6.75614 20 4.72638 20C2.45861 20 0.397998 18.0705 0.397998 15.6283ZM4.72636 12.764C3.16018 12.764 1.89054 14.0464 1.89054 15.6283C1.89054 17.2059 3.25084 18.4925 4.72638 18.4925C6.19619 18.4925 7.56216 17.1693 7.56218 15.6283C7.5622 13.8627 6.32202 12.764 4.72636 12.764ZM15.0746 12.764C13.479 12.764 12.2388 13.8627 12.2388 15.6283C12.2388 17.1956 13.6005 18.4919 15.0746 18.4919C16.5503 18.4919 17.9105 17.2055 17.9104 15.6283C17.9104 14.0464 16.6408 12.764 15.0746 12.764Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.28882 0.0792405C5.4737 -0.0138941 5.68853 -0.0254495 5.88215 0.0473261L9.90347 1.55877L14.0236 0.0454806C14.2182 -0.025989 14.4334 -0.0125921 14.6178 0.0824685C14.8022 0.177529 14.9393 0.345708 14.9962 0.546794L17.2296 8.44233L19.2537 8.44225C19.6659 8.44223 20 8.7797 20 9.196C20 9.61229 19.6659 9.94978 19.2538 9.9498L16.6667 9.94989C16.3335 9.94991 16.0407 9.7268 15.9491 9.4032L15.3077 7.13581H6.71642C6.30427 7.13581 5.97015 6.79834 5.97015 6.38204C5.97015 5.96574 6.30427 5.62827 6.71642 5.62827H14.8813L13.781 1.73868L10.1555 3.07031C9.98899 3.13148 9.80632 3.13079 9.64023 3.06837L6.10952 1.74132L4.13505 8.44235H13.9801C14.3923 8.44235 14.7264 8.77983 14.7264 9.19612C14.7264 9.61242 14.3923 9.94989 13.9801 9.94989H0.746269C0.334116 9.94989 0 9.61242 0 9.19612C0 8.77983 0.334116 8.44235 0.746269 8.44235H2.57781L4.90663 0.538759C4.96556 0.338755 5.10394 0.172375 5.28882 0.0792405Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default IncognitoIcon
