import React, { useEffect, useState } from 'react'

export function useTitleRowDetection(title: string, titleRef: React.RefObject<HTMLDivElement>) {
  const [isTitleContainTwoRows, setTitleContainTwoRows] = useState(false)

  useEffect(() => {
    if (!titleRef.current) return
    const computedStyle = window.getComputedStyle(titleRef.current)
    const lineHeight = parseFloat(computedStyle.getPropertyValue('line-height'))
    const height = parseFloat(computedStyle.getPropertyValue('height'))
    setTitleContainTwoRows(!(Math.floor(lineHeight) === Math.floor(height)))
  }, [titleRef, title])

  return isTitleContainTwoRows
}
