import React, { useEffect, useRef, useState } from 'react'
import { ISection } from '../../../api/types.books'
import SectionItem from 'features/myBook/SectionItem/SectionItem'
import { Button, BUTTON_VARIANT, ICON_ALIGNMENT, SIZE, COLORS } from '@magmamath/ui'
import AddIcon from 'ui/icons/AddIcon/AddIcon'
import useText from 'i18n/hook'
import SectionModal from 'features/myBook/SectionModal/SectionModal'
import { Events, SectionModalMode } from 'helpers/enums'
import { useUnit } from 'effector-react'
import DropdownMenu from 'features/myBook/DropdownMenu/DropdownMenu'
import { DropDownMode, DropDownPosition, SectionClickProps } from '../types'
import styles from './SectionsList.module.scss'
import { MyBookModel } from '../model'

type SectionsListProps = {
  onSectionDeleteClick: () => void,
}

type SectionModalState = {
  isOpen: boolean
  mode: SectionModalMode | null
}

const SECTION_MODAL_INITIAL_STATE: SectionModalState = { isOpen: false, mode: null }
const LEFT_MENU_PADDING = 8
const TOP_MENU_PADDING = 8

const SectionsList = (
  {
    onSectionDeleteClick
  }: SectionsListProps
) => {
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const books = useUnit(MyBookModel.$books)
  const [sectionModalState, setSectionModalState] = useState<SectionModalState>(SECTION_MODAL_INITIAL_STATE)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [openedMenuSection, setOpenedMenuSection] = useState<ISection | null>(null)
  const [dropDownPosition, setDropDownPosition] = useState<DropDownPosition>({ top: '0', right: '0' })
  const currentOpenMenu = useRef<HTMLDivElement | null>(null)
  const dropDownRef = useRef<HTMLInputElement | null>(null)
  const sectionListRef = useRef<HTMLOListElement | null>(null)
  const t = useText()
  const { newSubchapterTxt, subchapterTxt } = t
  const subchapters = books?.length > 0
    ? books[0]?.chapters[0]?.sections
    : []
  const isInactiveSectionMenuOpen = openedMenuSection && openedMenuSection._id !== selectedSection?._id
  const [isFirstSectionMenuOpen, setIsFirstSectionMenuOpen] = useState(false)

  useEffect(() => {
    function handleClickOutside(this: Document, event: MouseEvent): any {
      if (
        !dropDownRef.current?.contains(event.target as Node)
        && !currentOpenMenu.current?.contains(event.target as Node)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener(Events.CLICK, handleClickOutside, true)
    return () => document.removeEventListener(Events.CLICK, handleClickOutside, true)
  }, [])

  if (!selectedSection) return null

  const handleMenuClick = ({ clickedSection, clickedMenu, section, isFirstSection }: SectionClickProps) => {
    setOpenedMenuSection(section)
    setIsFirstSectionMenuOpen(isFirstSection)
    currentOpenMenu.current = clickedMenu
    setIsDropdownOpen((prev) => !prev)
    setDropDownPosition({
      top: `${clickedSection.getBoundingClientRect().top - clickedSection.offsetHeight - TOP_MENU_PADDING}px`,
      right: `${LEFT_MENU_PADDING}px`
    })
  }

  const handleEditClick = () => {
    if (isInactiveSectionMenuOpen) {
      MyBookModel.setSelectedSection(openedMenuSection)
    }
    setIsDropdownOpen((prev) => !prev)
    setSectionModalState({ isOpen: true, mode: SectionModalMode.EDIT })
  }

  const handleDeleteClick = () => {
    if (isInactiveSectionMenuOpen) {
      MyBookModel.setSelectedSection(openedMenuSection)
    }
    onSectionDeleteClick()
    setIsDropdownOpen((prev) => !prev)
  }

  const handleModalReset = () => {
    setSectionModalState(SECTION_MODAL_INITIAL_STATE)
  }

  const handleScrollToSection = (section: HTMLLIElement) => {
    const sectionListHeight = sectionListRef?.current?.offsetHeight || 0
    const scrolledOffset = sectionListRef?.current?.scrollTop || 0

    const isSelectedSectionVisible = scrolledOffset
      ? section.offsetTop > scrolledOffset && section.offsetTop < scrolledOffset + sectionListHeight
      : section.offsetTop < sectionListHeight
    if (
      sectionListRef.current && section
      && !isSelectedSectionVisible
    ) {
      sectionListRef.current.scrollTo({
        top: section.offsetTop - section.offsetHeight,
      })
    }
  }

  return (
    <div className={styles.MenuWrapper}>
      <p className={styles.MenuHeader}>
        {subchapterTxt}
      </p>
      <ol
        ref={sectionListRef}
        className={styles.MenuList}
        onScroll={() => setIsDropdownOpen(false)}>
        {subchapters.length > 0 &&
          subchapters.map((section, index) => (
            <SectionItem
              isFirstSection={index === 0}
              key={section._id}
              currentSection={section}
              onMenuClick={handleMenuClick}
              isDropdownOpen={isDropdownOpen}
              isMenuBtnActive={openedMenuSection?._id === section._id && isDropdownOpen}
              onSectionSelected={handleScrollToSection}
            />
          ))}
      </ol>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.MEDIUM}
        icon={<AddIcon size={14} color={COLORS.NEUTRAL_9} />}
        iconAlign={ICON_ALIGNMENT.RIGHT}
        onClick={() => setSectionModalState({ isOpen: true, mode: SectionModalMode.CREATE })}
      >
        {newSubchapterTxt}
      </Button>
      {sectionModalState.mode && (
        <SectionModal
          onModalStateReset={handleModalReset}
          {...sectionModalState}
        />)}
      {isDropdownOpen && (
        <DropdownMenu
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          isOpen={isDropdownOpen}
          position={dropDownPosition}
          ref={dropDownRef}
          mode={DropDownMode.SECTION}
          isDeleteHidden={isFirstSectionMenuOpen}
        />)}
    </div>
  )
}

export default SectionsList
