import clsx from 'clsx'
import React, { useMemo } from 'react'
import { AnswerType, MyScriptCharacterTypes, socketEvents } from '../../../../helpers/enums'
import socket from '../../../../helpers/socket.helper'
import { SocketEventListener, useSocketEvents } from '../../../../lib/hooks/useSocketEvents'
import Skeleton from '../../../../ui/skeleton/Skeleton'
import { HistorySaveCredits } from '../../../DrawBoard/types'
import NoSolutionBody from '../SolutionCardBody/NoSolutionBody'
import bodyStyles from '../SolutionCardBody/SolutionCardBody.module.scss'
import SolutionCardImageBody from '../SolutionCardBody/SolutionCardImageBody'
import styles from './DrawBoardPreview.module.scss'
import PreviewDrawBoard from './PreviewDrawBoard'
import { usePlayableHistorySettings } from './hook.historySettings'
import { previewPersistModule, usePreviewPersistModule } from './model.persist'
import { DrawBoardPreviewPreset } from './types'

type PlayablePreviewProps = {
  credits: HistorySaveCredits
  preset: DrawBoardPreviewPreset
  attemptsCount: number
  backgroundImage?: string
  attemptImage?: string
  noSolutionAnswer?: string[]
  answerType: AnswerType
  characterType: MyScriptCharacterTypes
}

export const PlayablePreview = ({
  credits,
  attemptsCount,
  attemptImage,
  backgroundImage,
  preset,
  answerType,
  characterType,
  noSolutionAnswer,
}: PlayablePreviewProps) => {
  const historySettings = usePlayableHistorySettings(preset)
  const isLoading = usePreviewPersistModule(preset, credits)

  const listener: SocketEventListener = useMemo(() => {
    return {
      event: socketEvents.statisticsUpdate,
      handler: ({ userId, exerciseId }) => {
        if (userId === credits.userId && exerciseId === credits.exerciseId) {
          previewPersistModule.reloadCurrent()
        }
      },
    }
  }, [credits.userId, credits.exerciseId])

  useSocketEvents(socket, listener)

  const isOldHistory = historySettings && historySettings.anchorsCount !== attemptsCount
  const isNoPlayable = historySettings && !historySettings.isExists

  if ((isNoPlayable || isOldHistory) && attemptImage) {
    return <SolutionCardImageBody className={bodyStyles.Expanded} image={attemptImage} />
  }

  if (!isLoading && historySettings?.isEmpty && (!backgroundImage || !attemptsCount)) {
    return (
      <NoSolutionBody
        noSolutionAnswer={noSolutionAnswer}
        characterType={characterType}
        answerType={answerType}
      />
    )
  }

  return (
    <div className={clsx(bodyStyles.Body, styles.PlayableBody)}>
      <Skeleton
        variant='rectangular'
        className={clsx(styles.Skeleton, { [styles.Hidden]: !isLoading && historySettings })}
      />
      {!isLoading && !isOldHistory && (
        <PreviewDrawBoard preset={preset} backgroundImage={backgroundImage} />
      )}
    </div>
  )
}
