import React, { useState } from 'react'
import clsx from 'clsx'
import { useAnimationsContext } from 'features/ExercisesList/contexts/AnimationsContext/AnimationsContext'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { AssignmentFilterBadges } from 'features/ExercisesList/types/types'
import { LayoutGroup, motion, usePresence } from 'framer-motion'
import { LayoutGroupIds } from 'helpers/enums'
import 'react-circular-progressbar/dist/styles.css'
import { useHistory } from 'react-router'
import { IAutologinActions } from 'redux/modules/autologin.module'
import { IExerciseActions } from 'redux/modules/exercise.module'
import { IModalActions } from 'redux/modules/modal.module'

import UICheckbox from 'ui/checkboxes/uiCheckbox/Checkbox'
import UICard from 'ui/cards/Card/Card'
import CircleProgressBar from '../../../../ui/Bars/CircleProgressBar/CircleProgressBar'
import ExerciseDate from '../ExerciseDate/ExerciseDate'
import ExerciseDropdown from '../ExerciseDropdown/ExerciseDropdown'
import ExerciseStateBadge from '../ExerciseStateBadge/ExerciseStateBadge'
import ExerciseParticipants from '../ExerciseParticipants/ExerciseParticipants'
import ExerciseTitle from '../ExerciseTitle/ExerciseTitle'
import ExerciseTopics from '../ExerciseTopics/ExerciseTopics'
import styles from './ExerciseContainer.module.scss'
import TopicsModal from '../../../../components/Modals/TopicsModal/TopicsModal'
import ClassroomShareModal from 'features/modals/ClassroomShareModal/ClassroomShareModal'
import { COLORS } from '@magmamath/ui'
import { dynamicPaths } from 'config/pathnames.config'
import { ExerciseState, IExercise } from '../../../../api/types.assignments'

import { ITopic } from '../../../../api/api.topics'

const checkForNoRedirectablePart = (target: HTMLElement) => {
  const skipRedirect =
    // items with specific data attribute
    target.getAttribute('data-skip-redirect') ||
    // options button icon
    target.nodeName === 'path' ||
    // options button icon
    target.nodeName === 'svg' ||
    // options dropdown backdrop
    target.classList.contains('MuiBackdrop-root') ||
    // options dropdown settings menu item
    target.classList.contains('MuiMenuItem-root')

  return !!skipRedirect
}

type ExerciseContainerProps = {
  autologinActions: IAutologinActions
  exerciseActions: IExerciseActions
  modalActions: IModalActions
  exercise: IExercise
  topics: ITopic[]
  setFilterBadges: React.Dispatch<React.SetStateAction<AssignmentFilterBadges>>
  onExerciseClick: () => void
}

const ExerciseContainer = ({
  autologinActions,
  exerciseActions,
  modalActions,
  exercise,
  topics,
  setFilterBadges,
  onExerciseClick,
}: ExerciseContainerProps) => {
  const [isTopicsModalOpened, setIsTopicsModalOpened] = useState(false)
  const [isShareClassroomModalOpen, setIsShareClassroomModalOpen] = useState(false)
  const history = useHistory()
  const [isPresent, safeToRemove] = usePresence()
  const { getAnimationConfig } = useAnimationsContext()
  const { isBulkModeActive, checkIsSelected, onSelectChange } = useBulkSelectContext()
  const isBulkSelected = isBulkModeActive && checkIsSelected(exercise._id)
  const animationConfig = getAnimationConfig(isPresent, safeToRemove)
  const exerciseHeatmapPathname = dynamicPaths.heatmap({ exerciseId: exercise._id })

  const openHeatmapInNewTab = () => {
    //using setTimeout here for switching to the new tab on command press + click
    setTimeout(() => {
      window.open(window.location.origin + exerciseHeatmapPathname, '_blank')
    }, 0)
  }

  const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    openHeatmapInNewTab()
  }

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.metaKey) {
      openHeatmapInNewTab()
      return
    }

    const target = e.target as HTMLElement
    if (!target) return

    if (isBulkModeActive) {
      const isSelected = checkIsSelected(exercise._id)
      const updatedTarget = { ...target, checked: !isSelected }
      return onSelectChange({ ...e, target: updatedTarget }, exercise)
    }

    if (checkForNoRedirectablePart(target)) return
    onExerciseClick()

    history.push(
      exercise._id ? { pathname: exerciseHeatmapPathname, search: history.location.search } : '#'
    )
  }

  const isStatusBadgeVisible =
    exercise.published ||
    exercise.settings?.state === ExerciseState.NotStarted ||
    (exercise.districtExercise && exercise.settings?.state === ExerciseState.DistrictLocked)

  return (
    <LayoutGroup id={LayoutGroupIds.EXERCISE_ITEM}>
      <motion.div
        className={styles.ExerciseContainer}
        {...animationConfig}
        onClick={onCardClick}
        onContextMenu={onContextMenu}
      >
        <UICard
          className={clsx(styles.Card, {
            [styles.BulkSelected]: isBulkModeActive && isBulkSelected,
          })}
        >
          <CircleProgressBar
            correct={exercise.stats.firstAttempt}
            wrong={exercise.stats.wrong}
            correctAfterFirstAttempt={exercise.stats.afterFirstAttempt}
            fillColor={isBulkSelected ? COLORS.NEUTRAL_4 : COLORS.NEUTRAL_1}
          />
          <ExerciseTitle title={exercise.name} />
          {isStatusBadgeVisible && (
            <div className={styles.ExamBadge}>
              <ExerciseStateBadge exercise={exercise} onExamModeStatusChange={setFilterBadges} />
            </div>
          )}
          <ExerciseParticipants
            addedClasses={exercise.allClassroomStudents !== false ? exercise.classes : []}
            addedGroups={exercise.groups}
            students={exercise.students}
          />
          <ExerciseDate exercise={exercise} />
          <ExerciseTopics topics={topics} isDistrictExercise={!!exercise.districtExercise} />
          {isBulkModeActive && <UICheckbox checked={isBulkSelected} />}
          {!isBulkModeActive && (
            <ExerciseDropdown
              exercise={exercise}
              exerciseActions={exerciseActions}
              modalActions={modalActions}
              title={exercise.name}
              autologinActions={autologinActions}
              setFilterBadges={setFilterBadges}
              openTopicsModal={() => setIsTopicsModalOpened(true)}
              onShareClassroomOptionClick={() => setIsShareClassroomModalOpen(true)}
            />
          )}
          <span
            className={clsx(styles.ColoredBorderRight, {
              [styles.ExamMode]: !!exercise.testModeEnabled,
              [styles.NonExamMode]: !exercise.testModeEnabled,
            })}
          />
        </UICard>
      </motion.div>
      {isTopicsModalOpened && (
        <TopicsModal
          isOpened={isTopicsModalOpened}
          onClose={() => setIsTopicsModalOpened(false)}
          exerciseId={exercise._id}
        />
      )}

      {isShareClassroomModalOpen && (
        <ClassroomShareModal
          isOpen={isShareClassroomModalOpen}
          onClose={() => setIsShareClassroomModalOpen(false)}
          exercise={exercise}
        />
      )}
    </LayoutGroup>
  )
}

export default ExerciseContainer
