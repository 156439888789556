import { Events, FormTypes, RemovableTypes } from 'helpers/enums'
import { IMe, IStudent } from 'api/types.users'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { IModalActions } from 'redux/modules/modal.module'
import './StudentsOptionsMenu.scss'
import { PATHS } from '../../../../config/pathnames.config'
import { IState } from '../../../../interfaces/state.interface'
import { TeacherSchools } from '../../../../redux/modules/teachersSchools.module'
import { ILocalization } from '../../../../config/languages.config'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import OptionIcon from '../../../../ui/icons/OptionIcon/OptionIcon'

interface IStudentsOptionsMenuProps {
  type?: FormTypes
  history: any
  localization: ILocalization
  me: IMe
  modalActions: IModalActions
  student?: IStudent
  username?: string
  classId?: string
  groupId?: string
  classOfLastRow?: string
  teacherSchools?: IState<TeacherSchools[]>
}

class StudentsOptionsMenu extends React.Component<IStudentsOptionsMenuProps, any> {
  public dropdownRef: HTMLElement | null

  constructor(props: IStudentsOptionsMenuProps) {
    super(props)
    this.state = {
      isOpenedDropdown: false,
    }
  }

  public componentDidMount = () => {
    document.addEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public componentWillUnmount = () => {
    document.removeEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public handleClickOutside = (event: MouseEvent): void => {
    const domNode: Element | Text | null = ReactDOM?.findDOMNode(this)
    if (domNode && !domNode.contains(event.target as Node)) {
      this.setState({
        isOpenedDropdown: false,
      })
    }
  }

  public renderDropdownForProblem = (): void => {
    this.setState({ isOpenedDropdown: !this.state.isOpenedDropdown })
  }

  public moveToStudentStat = (studentId: string) => {
    this.props.history.push(`/student-progress/${studentId}`)
  }

  public renderStudentOptions = (): JSX.Element => {
    const { localization, classId, groupId, student } = this.props
    const {
      changeUsernameTxt,
      moveToAnotherClass,
      changeStudentUsername,
      removeStudentFromClass,
      removeStudentTxt,
      userNameTxt,
      resetPassTxt,
      showStats,
    } = localization!.data
    return (
      <>
        <div
          className='list-item-menu-option top-link'
          onClick={(e: MouseEvent | any) => {
            e.preventDefault()
            this.moveToStudentStat(student!._id)
            this.setState({
              isOpenedDropdown: false,
            })
          }}
        >
          {showStats}
        </div>
        <div
          className='list-item-menu-option middle-link'
          onClick={(e: MouseEvent | any) => {
            e.preventDefault()
            this.props.modalActions!.openModalPassword(
              this.props.me,
              this.props.username!,
              this.props.localization!
            )
            this.setState({
              isOpenedDropdown: false,
            })
          }}
        >
          {resetPassTxt}
        </div>
        <div
          className='list-item-menu-option middle-link'
          onClick={(e: MouseEvent | any) => {
            e.preventDefault()
            const studentSettings: { student?: IStudent } = { student }
            this.props.modalActions!.openModal(
              changeStudentUsername,
              userNameTxt,
              this.props.me,
              student!._id,
              studentSettings
            )
            this.setState({
              isOpenedDropdown: false,
            })
          }}
        >
          {changeUsernameTxt}
        </div>
        {classId && !groupId && (
          <div
            className='list-item-menu-option middle-link'
            onClick={(e: MouseEvent | any) => {
              e.preventDefault()
              this.props.modalActions!.openClassesModal(student!._id)
              this.setState({
                isOpenedDropdown: false,
              })
            }}
          >
            {moveToAnotherClass}
          </div>
        )}

        {(classId || groupId) && (
          <div
            className='list-item-menu-option bottom-link'
            onClick={() => {
              const type: RemovableTypes = classId
                ? RemovableTypes.StudentFromClass
                : RemovableTypes.StudentFromGroup
              this.props.modalActions!.openSimpleConfirmModal(student!, type)
              this.setState({
                isOpenedDropdown: false,
              })
            }}
          >
            {groupId ? removeStudentTxt : removeStudentFromClass}
          </div>
        )}
      </>
    )
  }

  public renderOptionsByType = (type: FormTypes): JSX.Element => {
    const title: string = this.getTitleByType(type)
    return (
      <>
        <div
          className='list-item-menu-option top-link'
          onClick={(e: MouseEvent | any) => {
            e.preventDefault()
            this.openModalByType(type)
            this.setState({
              isOpenedDropdown: false,
            })
          }}
        >
          {title}
        </div>
      </>
    )
  }

  public getTitleByType = (type: FormTypes): string => {
    const { switchSchool, editFirstName, editLastName, editEmail, editUsername } =
      this.props.localization.data
    const mapOfTitles: { [key in FormTypes]: string } = {
      [FormTypes.FIRST_NAME]: editFirstName,
      [FormTypes.LAST_NAME]: editLastName,
      [FormTypes.USERNAME]: editUsername,
      [FormTypes.EMAIL]: editEmail,
      [FormTypes.SCHOOL_ID]: switchSchool,
    }
    return mapOfTitles[type]
  }

  public openModalByType = (type: FormTypes): void => {
    const editedTroughInput: FormTypes[] = [
      FormTypes.EMAIL,
      FormTypes.FIRST_NAME,
      FormTypes.LAST_NAME,
      FormTypes.USERNAME,
    ]
    if (editedTroughInput.includes(type)) {
      this.props.modalActions.openSimpleAsyncModal(
        type,
        this.props.localization,
        this.props.history
      )
    } else {
      this.props.history.push(PATHS.CLASSES)
    }
  }

  public render() {
    const { classOfLastRow, student, type } = this.props
    const { isOpenedDropdown } = this.state
    return (
      <div className={`list-item-menu-options-menu-wrapper ${isOpenedDropdown ? 'active' : ''}`}>
        {this.props.teacherSchools?.data?.length !== 1 && (
          <span
            className='list-item-menu-opening-trigger'
            onClick={() => this.renderDropdownForProblem()}
          >
            <Button
              size={SIZE.SMALL}
              variant={BUTTON_VARIANT.TERTIARY}
              icon={<OptionIcon size={18} color='var(--icon-color)' />}
            />
          </span>
        )}

        {isOpenedDropdown && (
          <div
            className={`list-item-menu-options ${classOfLastRow || ''}`}
            ref={(node: HTMLElement | null) => (this.dropdownRef = node)}
          >
            {student && !type && this.renderStudentOptions()}
            {type && this.renderOptionsByType(type)}
          </div>
        )}
      </div>
    )
  }
}

export default StudentsOptionsMenu
