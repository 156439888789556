import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE, TOOLTIP_POSITION } from '@magmamath/ui'
import { dynamicPaths } from 'config/pathnames.config'
import { useStore, useUnit } from 'effector-react'
import {
  $commonErrorFilterTask,
  updateCommonErrorFilterTask,
} from 'features/Solutions/CommonError/model/filterTask'
import useText from 'i18n/hook'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import PinIcon from 'ui/icons/PinIcon/PinIcon'
import { updatePinState } from '../../model/isPinsFilterActive'
import { $pinnedSolutions } from '../../model/pinnedSolutions'
import styles from './FilterPinnedButton.module.scss'

type FilterPinnedButtonProps = { buttonColor: BUTTON_COLOR }

const FilterPinnedButton = ({ buttonColor }: FilterPinnedButtonProps) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams<{ exerciseId?: string; studentId?: string; taskId?: string }>()
  const t = useText()
  const { pinsData, isPinsFilterActive, isFetchingPins } = useStore($pinnedSolutions)
  const commonErrorFilterTask = useUnit($commonErrorFilterTask)

  const currentPinsData = pinsData.currentPinsData?.pins
  const pinsLength = currentPinsData?.length ?? 0
  const shouldShowLoader = isFetchingPins && !currentPinsData

  const handleButtonClick = () => {
    if (commonErrorFilterTask) updateCommonErrorFilterTask(null)

    updatePinState(!isPinsFilterActive)
    if (!params.taskId || !params.studentId || !params.exerciseId) return
    const isProblemPage = location.pathname.includes('problem')
    const redirectPath = dynamicPaths.studentsSolutions({
      exerciseId: params.exerciseId,
      problemId: params.taskId,
      subProblemId: isProblemPage ? undefined : params.taskId,
    })
    history.push(redirectPath)
  }
  return (
    <Button
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.MEDIUM}
      classes={{ badge: styles.Badge, loader: styles.Loader }}
      color={buttonColor}
      icon={<PinIcon color='var(--icon-color)' size={20} />}
      disabled={!pinsLength}
      isTransparent
      isLoading={shouldShowLoader}
      badge={shouldShowLoader ? 0 : pinsLength}
      onClick={handleButtonClick}
      isActive={isPinsFilterActive}
      tooltipText={t.pinnedSolutionTxt}
      tooltipPosition={TOOLTIP_POSITION.BOTTOM}
    />
  )
}

export default FilterPinnedButton
