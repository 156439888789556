import SkillsTable from 'components/SkillsTable/SkillsTable'
import HeatmapTable from 'features/tables/HeatmapTable/ui/HeatmapTable/HeatmapTable'
import { getTeacherData } from 'helpers/user.helpers'
import { startCase } from 'lodash'
import React, { RefObject } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { v4 as uuidv4 } from 'uuid'
import magmaLogo from 'assets/img/magmaLogo.png'
import { ILocalization } from 'config/languages.config'
import { IStudentStat } from 'api/types.solutions'
import { IState } from 'interfaces/state.interface'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import './PrintPieChart.scss'
import { EntityType, IUser } from './OldPieChart.view'
import PieChartToPrint from './PieChartToPrint'
import { IProblem, ISubProblem } from '../../../api/types.problem'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IPrintPieChart {
  componentRef: RefObject<HTMLDivElement>
  skillsAndChaptersEntity: any
  users: IUser[]
  classes: IClass[]
  groups: IGroup[]
  localization: ILocalization
  exerciseName: string
  exercise: IState<IExerciseWithoutStats>
}

const PrintPieChart: React.FC<IPrintPieChart> = ({
  componentRef,
  skillsAndChaptersEntity,
  users,
  classes,
  localization,
  exerciseName,
  exercise,
  groups,
}) => {
  const skillsStats = useSelector((state: RootState) => state.skillsStats.data.studentsStats)
  const skillsData = useSelector((state: RootState) => state.skills.data.items)
  const teacherData = useSelector((state: RootState) => getTeacherData(state.auth.data.me))

  const problemSubProblemLength = exercise?.data?.problems.reduce(
    (prev: number, current: IProblem) => prev + (current?.subProblems.length || 1),
    0
  )

  const getTitleForPieChart = (pieChartSingleData: any) => {
    if (pieChartSingleData.type === EntityType.Student) {
      const student = users.find((user) => user._id === pieChartSingleData._id)
      const solvedSumForUser = mergedProblemsAndSubProblems.reduce(
        (acc: any, item: IProblem | ISubProblem) => {
          const solved =
            item?.studentStats?.find(
              (statsForSimpleStudent: IStudentStat) =>
                statsForSimpleStudent.studentId === student?._id
            )?.stats?.solved || 0
          return acc + solved
        },
        0
      )
      return `${student?.firstName} ${student?.lastName} ${solvedSumForUser}/${problemSubProblemLength}`
    }
    if (pieChartSingleData.type === EntityType.Class) {
      const chosenClass = classes.find((simpleClass) => simpleClass._id === pieChartSingleData._id)
      return `${localization.data.classTxt} ${(chosenClass as IClass)?.name}`
    }
    if (pieChartSingleData.type === EntityType.Group) {
      const chosenGroup = groups.find((simpleGroup) => simpleGroup._id === pieChartSingleData._id)
      return `${localization.data.groupTxt} ${(chosenGroup as IGroup)?.name}`
    }
    return ''
  }

  const problems = exercise.data?.problems.map((p, index) => ({
    ...p,
    indexName: index + 1,
    problemId: p._id,
    subProblems: p.subProblems.map((sb) => ({
      ...sb,
      indexName: `${index + 1}${sb.name}`,
      problemId: p._id,
      subProblemId: sb._id,
    })),
  }))

  // @ts-ignore
  const mergedProblemsAndSubProblems: (IProblem | ISubProblem)[] = (problems || []).reduce(
    // @ts-ignore
    (acc: (IProblem | ISubProblem)[], problem: IProblem) =>
      acc.concat(
        problem.subProblems.length === 0
          ? problem
          : (problem.subProblems || []).map((subProblem) => subProblem)
      ),
    []
  )

  const sortedSkillsAndChaptersEntity = [...skillsAndChaptersEntity].sort((a, b) => b.type - a.type)
  return (
    <div className='print'>
      <div ref={componentRef} className='print-wrapper'>
        <div className='print-wrapper-main print-wrapper-logo'>
          <img src={magmaLogo} alt='magmalogo' />
          <h1>
            {startCase(localization.data.resultTxt)} for {exerciseName}
          </h1>
        </div>
        <div className='pagebreak' />
        {sortedSkillsAndChaptersEntity.map((p) => (
          <div key={uuidv4()}>
            <div className='print-wrapper-main print-wrapper-pie-chart' key={uuidv4()}>
              <h1 key={uuidv4()}>{getTitleForPieChart(p)}</h1>
              <PieChartToPrint
                skillsAndChaptersEntity={p[p._id]}
                isPrinting={true}
                key={uuidv4()}
              />
            </div>
            <div className='pagebreak' />
          </div>
        ))}
        <SkillsTable
          history={history}
          exercise={exercise}
          localization={localization}
          skillsStats={skillsStats}
          skillsData={skillsData}
          isPrinting={true}
        />
        <div className='pagebreak' />
        <HeatmapTable isPrint exerciseWithoutStatsData={exercise.data} teacher={teacherData} />
      </div>
    </div>
  )
}

export const MemoizedPrintPieChart = React.memo(PrintPieChart)
