import { config } from 'config'
import { LogglyTracker } from 'loggly-jslogger'
import Bowser from 'bowser'
import { getItemFromLocalStorage } from './storage.helper'
import { localStorageKeys } from '../config/localStorageKeys'
import React from 'react'

const logger = new LogglyTracker()

export const setLoggly = () => {
  if (config.APP_HOST !== 'http://localhost:3000') {
    logger.push({
      logglyKey: config.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
      sendConsoleErrors: true,
      tag: config.REACT_APP_LOGGLY_TAG,
    })
  }
}

export default logger

export const getBrowserInfo = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  return browser.getBrowser()
}

export const getCurrentUrl = () => {
  return window.location?.href
}

export const writeLogg = (error: Error, info: React.ErrorInfo) => {
  const loggBody = {
    message: error.message,
    info,
    browserName: getBrowserInfo().name,
    browserVersion: getBrowserInfo().version,
    currentURL: getCurrentUrl(),
    username: JSON.parse(getItemFromLocalStorage(localStorageKeys.me) || '')?.username,
  }
  logger.push(loggBody)
}
