import { useRef } from 'react'

const useFunctionRef = <T extends (...p: any[]) => any>(fn: T) => {
  const ref = useRef(fn)
  ref.current = fn

  return ref
}

export default useFunctionRef
