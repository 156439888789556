import { Events } from 'helpers/enums'
import * as React from 'react'
import { RefObject } from 'react'

interface IScrollableProps {
  history: any
}
export class ScrollableContainer extends React.Component<IScrollableProps, any> {
  public scrollTop: number = 0
  public divRef: RefObject<HTMLElement> | null = React.createRef()

  public onScroll = (): void => {
    if (!!this.divRef?.current) {
      this.scrollTop = this.divRef.current.scrollTop
    }
  }

  public componentDidMount() {
    if (!!this.divRef?.current) {
      this.divRef.current.addEventListener(Events.SCROLL, this.onScroll)
    }
  }

  public componentDidUpdate() {
    if (!!this.divRef?.current) {
      this.divRef.current.scrollTop = this.scrollTop || 0
    }
  }

  public componentWillUnmount() {
    if (!!this.divRef?.current) {
      this.divRef.current.removeEventListener(Events.SCROLL, this.onScroll)
    }
  }

  public render() {
    if (window.location.pathname.includes('/heatmap')) {
      return <tbody className='container-scrollable' {...this.props} ref={() => this.divRef} />
    } else {
      return <div className='container-scrollable' {...this.props} ref={() => this.divRef} />
    }
  }
}
