import React from 'react'
import styles from './Textarea.module.scss'

type TextareaProps = {
  value: string
  onChange: (value: string) => void
  placeholderText: string
  numberOfRows: number
}
const Textarea = ({ value, onChange, placeholderText, numberOfRows }: TextareaProps) => {
  return (
    <label className={styles.Label}>
      <textarea
        className={styles.Textarea}
        placeholder={placeholderText}
        value={value}
        rows={numberOfRows}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </label>
  )
}

export default Textarea
