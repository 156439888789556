import React, { forwardRef } from 'react'
import styles from './Tooltip.module.scss'
import clsx from 'clsx'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

type Ref = HTMLDivElement

// @@ Really hard to match Tooltip title types - any React.ReactNode can be used (now I leave it as any | undefined )
type UITooltipProps = JSX.IntrinsicElements['div']
  & TooltipProps
  & {
    title: any | undefined,
    tooltipClasses?: {
      popper?: string
      tooltip?: string
    }
  }

const UITooltip = forwardRef<Ref, UITooltipProps>(
  ({ title, children, classes, className, tooltipClasses, ...rest }, ref) => {
    const mergedClassNames = clsx(styles.TooltipContent, className)
    return (
      <Tooltip
        // @ts-ignore
        ref={ref}
        title={title ? <div className={mergedClassNames}>{title}</div> : null}
        TransitionProps={{ timeout: 0 }}
        classes={{ popper: styles.Popper, tooltip: styles.Tooltip, ...classes, ...tooltipClasses }}
        {...rest}
      >
        {children}
      </Tooltip>
    )
  }
)

export default UITooltip
