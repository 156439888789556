import React from 'react'
import { Modal } from '@mui/material'

import { ModalType } from '../../../helpers/enums'
import { IModal, IModalActions } from '../../../redux/modules/modal.module'
import OldAbilityStudentResultsModal from '../../../features/PracticeMode/OldPracticeMode/AbilityStudentResultsModal/AbilityStudentResultsModal'
import { IState } from '../../../interfaces/state.interface'

type PracticeResultsModalProps = {
  modal: IState<IModal>
  modalActions: IModalActions
}

const OldPracticeResultsModal = ({ modal, modalActions }: PracticeResultsModalProps) => {
  const opened: boolean = modal.data.modalType === ModalType.OldPracticeResultsModal
  const handleClose = () => {
    modalActions.closeOldPracticeResultsModal()
  }

  return (
    <Modal open={opened} onClose={handleClose}>
      <OldAbilityStudentResultsModal modal={modal} onModalClose={handleClose} />
    </Modal>
  )
}

export default OldPracticeResultsModal
