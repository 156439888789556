import useText from 'i18n/hook'
import React from 'react'
import { useHistory } from 'react-router'
import { LayoutIds, SearchParam, TypesOfParams } from '../../../helpers/enums'
import { StudentsTab } from '../../../screens/Students/Students'
import styles from '../../../screens/Students/Students.module.scss'
import UITab from '../../../ui/tabs/UITab/UITab'
import UITabs from '../../../ui/tabs/UITabs/UITabs'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IStudentsTab {
  activeTab: StudentsTab | null
  classOrGroup: IClass | IGroup
}

const StudentsScreenTab: React.FC<IStudentsTab> = ({ activeTab, classOrGroup }) => {
  const history = useHistory()
  const t = useText()
  const tabs = [
    {
      id: StudentsTab.MULTIPLICITY,
      title: t.studentsTxt,
      searchParam: SearchParam.STUDENTS_TAB,
      hidden: false,
    },
    {
      id: StudentsTab.GROUPS,
      title: t.groupsTxt,
      searchParam: SearchParam.GROUPS_TAB,
      hidden: !(classOrGroup as IClass).invitationalCode,
    },
    {
      id: StudentsTab.SETTINGS,
      title: t.settingsTxt,
      searchParam: SearchParam.SETTINGS_TAB,
      hidden: false,
    },
  ]

  return (
    <UITabs
      layoutId={LayoutIds.STUDENTS_TABS}
      withDividerBottom={true}
      className={styles.TabsExtraClass}
    >
      {tabs.map((tab) => {
        if (tab.hidden) return null
        return (
          <UITab
            key={tab.id}
            isActive={activeTab === tab.id}
            title={tab.title}
            onClick={() =>
              history.push(
                `/students/${TypesOfParams.CLASSES}/${classOrGroup?._id}${tab.searchParam}`
              )
            }
            layoutId={LayoutIds.STUDENTS_TABS}
          />
        )
      })}
    </UITabs>
  )
}

export default StudentsScreenTab
