import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editExercise } from 'redux/modules/exercise.module'
import { getExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { RootState } from 'store/store'
import UISwitch from 'ui/Switch/Switch'
import styles from './CheatDetectionSwitch.module.scss'

type CheatDetectionSwitchProps = {
  disabled: boolean
  exerciseId: string
  cheatDetection: boolean
}

const CheatDetectionSwitch = ({
  disabled,
  exerciseId,
  cheatDetection,
}: CheatDetectionSwitchProps) => {
  const isExerciseWithoutStatsLoading = useSelector(
    (state: RootState) => state.exerciseWithoutStats.loading
  )

  const t = useText()
  const [isMutating, setIsMutating] = useState(isExerciseWithoutStatsLoading)
  const dispatch = useDispatch()

  const onCheatSwitchChange = async () => {
    setIsMutating(true)
    try {
      await editExercise({ cheatDetection: !cheatDetection }, exerciseId, history, true)(dispatch)
      await getExerciseWithoutStats(exerciseId)(dispatch)
    } catch (error) {
    } finally {
      setIsMutating(false)
    }
  }

  const isSpinnerVisible = isMutating && isExerciseWithoutStatsLoading

  return (
    <div className={styles.SwitchWrapper}>
      <UISwitch
        disabled={disabled}
        className={clsx(styles.Switch, { [styles.Loading]: isExerciseWithoutStatsLoading })}
        checked={cheatDetection}
        onChange={onCheatSwitchChange}
      />
      <label className={styles.SwitchLabel}>
        <>
          {t.cheatDetection}
          {isSpinnerVisible && <CircularProgress size={12} color={'secondary'} />}
        </>
      </label>
    </div>
  )
}

export default CheatDetectionSwitch
