import { COLORS } from '@magmamath/ui'
import { currentLocale } from 'config/modules.config'
import dayjs from 'dayjs'
import { CalculatorType, LocaleData, USTimeType } from 'helpers/enums'
import { ExerciseSettings } from './types'

export const ICON_PROPS = {
  size: 16,
  color: COLORS.NEUTRAL_9,
} as const

export const DEFAULT_EXERCISE_SETTINGS: ExerciseSettings = {
  allowAnswerChecking: true,
  isExamMode: false,
  isExamModeManuallySelected: false,
  forcedDrawing: true,
  requireUnit: false,
  calculatorType: CalculatorType.Off,
  cheatDetection: false,
  publishNow: false,
  topic: false,
  scheduleDate: dayjs().add(1, 'day').set('hour', 7).startOf('hour'),
  scheduleArchiveDate: dayjs().add(14, 'day').set('hour', 20).startOf('hour'),
  scheduledTime: {
    hour: 7,
    minute: 0,
    type: USTimeType.AM,
  },
  scheduledArchiveTime: {
    hour: currentLocale.currentLang === LocaleData.SE ? 20 : 8,
    minute: 0,
    type: USTimeType.PM,
  },
  startDate: new Date(),
} as const

export const CALCULATOR_DESCRIPTORS = {
  [CalculatorType.Off]: 'off',
  [CalculatorType.Regular]: 'regularCalculator',
  [CalculatorType.Scientific]: 'scientificCalculator',
  [CalculatorType.Graphic]: 'graphicCalculator',
} as const
