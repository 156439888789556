import React from 'react'

import styles from './StudentsList.module.scss'

import { IStudent } from '../../../../../api/types.users'
import StudentItem from './StudentItem/StudentItem'
import { getFullName } from '../../../../../helpers/user.helpers'
import { getStudentStats } from '../../../helpers'
import { GetPracticeResultsResponse } from '../../../../../api/types.statistics'

type StudentsListProps = {
  students: IStudent[]
  results: GetPracticeResultsResponse
  onClickItem: (studentId: string, studentFullName: string) => void
}

const StudentsList = ({ students, results, onClickItem }: StudentsListProps) => {
  return (
    <div className={styles.StudentsListContainer}>
      {students.map((student) => {
        const studentStats = results[student._id]
        const fullName = getFullName(student.firstName, student.lastName)
        return (
          <StudentItem
            studentName={fullName || ''}
            studentStats={getStudentStats(studentStats)}
            isDisabled={studentStats?.totalCount === undefined}
            onClick={() => onClickItem(student._id, fullName)}
            key={student._id}
          />
        )
      })}
    </div>
  )
}

export default StudentsList
