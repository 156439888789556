import React, { useEffect, useState } from 'react'
import Textarea from '../../../ui/inputs/Textarea/Textarea'
import styles from './ReportErrorForm.module.scss'
import { IProblem, ISubProblem } from '../../../api/types.problem'
import { Button, BUTTON_VARIANT, BUTTON_COLOR, SIZE } from '@magmamath/ui'
import { Events, Keys } from 'helpers/enums'
import useText from 'i18n/hook'
import { reportErrorModel } from '../model'
import { toast } from 'features/ToastModel/ToastModel'
import { RESPONSE_SUCCESS_STATUS } from '.././model/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

type ReportErrorFormProps = {
  onFormSubmit: () => void
  subProblem: ISubProblem | null
  problem: IProblem | null
  buttonColor: BUTTON_COLOR
}

const TOAST_LIVE_PERIOD = 8000

const ReportErrorForm = ({ onFormSubmit, subProblem, problem, buttonColor }: ReportErrorFormProps) => {
  const [message, setMessage] = useState('')
  const me = useSelector((state: RootState) => state.auth.data.me)
  const t = useText()

  useEffect(() => {
    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === Keys.ENTER) {
        sendReport()
        onFormSubmit()
      }
    }

    document.addEventListener(Events.KEYPRESS, handleEnterPress)
    return () => document.removeEventListener(Events.KEYPRESS, handleEnterPress)
  }, [])

  async function sendReport() {
    const params = {
      teacherUserName: me.username,
      teacherEmail: me.email,
      message,
      teacherId: me._id,
      problem: problem?._id,
      ...(subProblem && { subProblem: subProblem?._id }),
      locale: me.setting.appLanguage,
    }
    const status = await reportErrorModel.sendReportFx(params)

    if (status === RESPONSE_SUCCESS_STATUS) {
      toast.confirmation({ props: { title: t.reportSentPopupText }, autoCloseTime: TOAST_LIVE_PERIOD })
    } else {
      toast.error({ props: { title: t.reportErrorPopupText }, autoCloseTime: TOAST_LIVE_PERIOD })
    }
  }

  return (
    <form
      className={styles.Form}
      onSubmit={(e) => {
        e.preventDefault()
        onFormSubmit()
        sendReport()
        setMessage('')
      }}
    >
      <Textarea
        value={message}
        onChange={setMessage}
        placeholderText={t.describeError}
        numberOfRows={5}
      />
      <Button
        variant={BUTTON_VARIANT.PRIMARY}
        color={buttonColor}
        size={SIZE.LARGE}
      >
        {t.sendTxt}
      </Button>
    </form>
  )
}

export default ReportErrorForm
