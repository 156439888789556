import React from 'react'
import styles from './ClassesHeaderButton.module.scss'
import ChangeSchool from '../../../../features/ChangeSchool/ChangeSchool'

const ClassesHeader = () => {
  return (
    <div className={styles.Container}>
      <ChangeSchool />
    </div>
  )
}

export default ClassesHeader
