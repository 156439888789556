import React from 'react'
import { SvgIconProps } from '../types/types'

const DownloadIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.875 15C1.875 15.6213 2.37868 16.125 3 16.125H15C15.6213 16.125 16.125 15.6213 16.125 15V11.5C16.125 11.0168 16.5168 10.625 17 10.625C17.4832 10.625 17.875 11.0168 17.875 11.5V15C17.875 16.5878 16.5878 17.875 15 17.875H3C1.41218 17.875 0.125 16.5878 0.125 15V11.5C0.125 11.0168 0.516751 10.625 1 10.625C1.48325 10.625 1.875 11.0168 1.875 11.5V15Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.88128 7.38128C4.53957 7.72299 4.53957 8.27701 4.88128 8.61872L8.38128 12.1187C8.72299 12.4604 9.27701 12.4604 9.61872 12.1187L13.1187 8.61872C13.4604 8.27701 13.4604 7.72299 13.1187 7.38128C12.777 7.03957 12.223 7.03957 11.8813 7.38128L9.875 9.38756V1C9.875 0.516751 9.48325 0.125 9 0.125C8.51675 0.125 8.125 0.516751 8.125 1V9.38756L6.11872 7.38128C5.77701 7.03957 5.22299 7.03957 4.88128 7.38128Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default DownloadIcon
