import { TeacherClassesResponse } from '../../../api/types.teachers'

export const filterTeacherClassesBySearchWord = (
  teachersClasses: TeacherClassesResponse[],
  searchInputValue: string
): TeacherClassesResponse[] => {
  const normalizedSearchInput = searchInputValue.trim().toLowerCase()

  if (!normalizedSearchInput) return teachersClasses

  return teachersClasses.reduce(
    (filteredResults: TeacherClassesResponse[], { firstName, lastName, classes, ...rest }) => {
      const fullName = `${firstName} ${lastName}`.toLowerCase()

      if (fullName.includes(normalizedSearchInput)) {
        filteredResults.push({ firstName, lastName, classes, ...rest })
        return filteredResults
      }
      const matchingClasses = classes.filter(({ name = '' }) =>
        name.toLowerCase().includes(normalizedSearchInput)
      )

      if (matchingClasses.length > 0) {
        filteredResults.push({ firstName, lastName, classes: matchingClasses, ...rest })
      }

      return filteredResults
    },
    []
  )
}
