export type SkillsUser = {
  attempts: number
  correctAnswers: number
  fullName: string
  firstName?: string
  lastName?: string
  isTeacher: boolean
  initials: string
  userId: string
}
export const sortByName = (firstItem: SkillsUser, secondItem: SkillsUser) =>
  firstItem.fullName.localeCompare(secondItem.fullName)

export const sortByFirstName = (firstItem: SkillsUser, secondItem: SkillsUser) =>
  (firstItem.firstName ?? '').localeCompare(secondItem.firstName ?? '')

export const sortByLastName = (firstItem: SkillsUser, secondItem: SkillsUser) =>
  (firstItem.lastName ?? '').localeCompare(secondItem.lastName ?? '')

export const sortByAttempts = (firstItem: SkillsUser, secondItem: SkillsUser) =>
  secondItem.attempts - firstItem.attempts

export const sortByRightAnswer = (firstItem: SkillsUser, secondItem: SkillsUser) =>
  secondItem.correctAnswers - firstItem.correctAnswers

export const sortByRandom = () => Math.random() - 0.5
