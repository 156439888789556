import { attach, createEvent, createStore, restore, createEffect } from 'effector'
import {
  createSectionRequestFx,
  deleteProblemRequestFx,
  deleteSectionRequestFx,
  editSectionRequestFx,
  fetchBooksFx,
  updateChapterFx,
} from './requests'
import { CreateSectionFxProps, MyBooks, EditSectionFxProps, EditSectionFxData } from '../types'
import { getNewSelectedSection } from './helpers'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import { localStorageKeys } from 'config/localStorageKeys'
import { ISection } from '../../../api/types.books'
import { IProblem } from '../../../api/types.problem'

export const resetBooks = createEvent()
const updateSections = createEvent<ISection[]>()
export const setSelectedSection = createEvent<ISection | null>()
export const setSelectedProblem = createEvent<IProblem | null>()

export const $books = createStore<MyBooks>([])
  .on(fetchBooksFx.doneData, (_, books) => books._embedded.books)
  .on(resetBooks, () => [])
  .on(updateSections, (state, sections) => {
    const newBooks = [...state]
    const chapter = newBooks[0]?.chapters[0]
    if (!chapter) return
    chapter.sections = sections
    return newBooks
  })

export const $selectedSection = restore(setSelectedSection, null)

export const $selectedProblem = restore(setSelectedProblem, null)

export const deleteSectionFx = attach({
  source: { books: $books, section: $selectedSection },
  effect: async ({ books, section }) => {
    const chapter = books[0]?.chapters[0]
    if (!section) return
    setSelectedSection(getNewSelectedSection(section, chapter))
    const newSections = chapter.sections.filter((s) => s._id !== section._id)
    updateSections(newSections)
    const sectionIds = newSections.map((s) => s._id)
    await deleteSectionRequestFx(section._id)
    await updateChapterFx({ id: chapter._id, sectionIds })
    await fetchBooksFx()
  },
})

export const deleteProblemFx = attach({
  source: { section: $selectedSection, problem: $selectedProblem },
  effect: async ({ section, problem }) => {
    if (!section || !problem) return
    const updatedProblems = section.problems
      .map((problem) => problem._id)
      .filter((id) => id !== problem._id)
      .join(',')
    const data = {
      problems: updatedProblems,
    }
    await deleteProblemRequestFx(problem._id)
    await editSectionRequestFx({ id: section._id, data })
    await fetchBooksFx()
  },
})


export const createSectionFx = attach({
  source: { section: $selectedSection, books: $books },
  mapParams: (props: CreateSectionFxProps, { books }: { books: MyBooks }) => ({ ...props, books }),
  effect: createEffect(async ({ name, books }: CreateSectionFxProps & { books: MyBooks }) => {
    const chapter = books[0]?.chapters[0]
    const { appLanguage } = getItemFromLocalStorageWithParsing(localStorageKeys.me).setting
    const res = await createSectionRequestFx({ name, appLanguage })
    const sectionIds = chapter.sections.map((section) => section._id).concat(res.section._id)
    await updateChapterFx({ id: chapter._id, sectionIds })
    await fetchBooksFx()
    setSelectedSection(res.section)
  }),
})

export const editSectionFx = attach({
  source: { section: $selectedSection, books: $books },
  mapParams: (props: EditSectionFxData, { section }): EditSectionFxProps => {
    return { ...props, section }
  },
  effect: createEffect(async ({ data, section }: EditSectionFxProps) => {
    if (!data || !section) return
    await editSectionRequestFx({ id: section._id, data })
    await fetchBooksFx()
  }),
})
