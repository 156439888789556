import React from 'react'
import styles from './ExerciseParticipants.module.scss'
import { useStoreMap } from 'effector-react'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import useText from 'i18n/hook'
import clsx from 'clsx'


type ExerciseParticipantsTooltipContentProps = {
  list: string[]
  textForSingle: string
  textForPlural: string
  isSingleType: boolean
}

const ExerciseParticipantsTooltipContent = ({
  list,
  textForSingle,
  textForPlural,
  isSingleType,
}: ExerciseParticipantsTooltipContentProps) => {
  const t = useText()
  const isAnonymousNameMode = useStoreMap($heatmapSettings, (settings) => settings.isAnonymousNameMode)
  if (isAnonymousNameMode && textForSingle === t.studentTxt && !!list.length) {
    return <div className={styles.TooltipContent}>{t.hiddenStudentsNamesTooltip}</div>
  }
  if (!!list.length) {
    return (
      <>
        {!isSingleType && (
          <div className={styles.ListTitle}>
            {list.length === 1 ? textForSingle : textForPlural}
          </div>
        )}

        <ul className={styles.List}>
          {list.map((listItem) => (
            <li key={listItem} className={clsx(styles.ListItem, {
              [styles.NoTitleListItem]: isSingleType,
            })}>
              {listItem}
            </li>
          ))}
        </ul>
      </>
    )
  }

  return null
}

export default ExerciseParticipantsTooltipContent
