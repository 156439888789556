import { IDispatch } from '../../interfaces/dispatch.interface'
import { api } from '../../api'

export enum SaveClass {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const saveClass = (classId: string | null) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SaveClass.REQUEST })
      const res = await api.users.updateMySettings({ chosenClass: classId })
      dispatch({ type: SaveClass.SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SaveClass.ERROR,
      })
    }
  }
}
