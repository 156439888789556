import { localStorageKeys } from 'config/localStorageKeys'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { api } from '../../api'

export enum STUDENTS {
  REQUEST = 'STUDENTS_REQUEST',
  SUCCESS = 'STUDENTS_SUCCESS',
  ERROR = 'STUDENTS_ERROR',
}

// Students reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function studentsReducer(state: any = initialState, action: IAction<STUDENTS>) {
  switch (action.type) {
    case STUDENTS.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case STUDENTS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case STUDENTS.ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

// Students actions
export function getStudents() {
  const me = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  const schoolId = me?.school?._id
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: STUDENTS.REQUEST })
      const res = await api.classesStudents.getAll({ schoolId })
      dispatch({ type: STUDENTS.SUCCESS, payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: STUDENTS.ERROR,
      })
    }
  }
}

export interface IClassesStudentsActions {
  getStudents: (studentsUrl?: string) => void
}
