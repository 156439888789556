import 'jquery'
import 'mathquill/build/mathquill.css'
import 'mathquill/build/mathquill.js'
import { createEvent, createStore, sample } from 'effector'
import { ActionEndSource, EditInputEvent, SubmitInputEvent } from './types.events'
import { InterruptingTimer } from '../../../lib/models/InterruptingTimer'
import { MQField } from './types.mathquill'
import { ICoords } from '../common/types'
import { Operator, OperatorType } from '../model/types'
import { isCommand } from './commands'

const MathQuill = window.MathQuill

export enum MathInputBlurBehaviour {
  NONE = 1,
  SUBMIT,
  DISCARD,
}

type SubmitProps = {
  withValue?: string
  croppedSymbols?: string
  doNotHide?: boolean
  source?: ActionEndSource
}

type DiscardProps = {
  source?: ActionEndSource
  doNotHide?: boolean
}

type SessionSettings = {
  pressCoords?: ICoords
  anchorElement?: HTMLElement | null
  elementCoords?: ICoords
  value?: string
  onSubmit?: (event: SubmitInputEvent) => void
  onEdit?: ((event: EditInputEvent) => void) | null
  onDelete?: () => void
  onDiscard?: (props: DiscardProps) => void
}

export type MathInputProps = {
  editInterruptCondition?: (latex: string) => boolean
  blurBehaviour?: MathInputBlurBehaviour
}

export class MathInputModel {
  protected blurBehaviour

  public readonly submitted = createEvent<SubmitProps | void>()
  public readonly discarded = createEvent<DiscardProps | void>()
  public readonly deleted = createEvent()
  public readonly edited = createEvent<EditInputEvent>()
  public readonly blurred = createEvent()

  public readonly showInput = createEvent<SessionSettings>()
  public readonly hideInput = createEvent()
  protected readonly $currentSessionProps = createStore<Partial<SessionSettings> | null>(null)
    .on(this.showInput, (_, props) => props)
    .reset(this.hideInput)

  public mq: MQField | null = null
  protected readonly editInterruptCondition
  protected editor: HTMLSpanElement | null = null

  protected readonly blurTimeout = new InterruptingTimer(() => {
    this.blurred()
    if (this.blurBehaviour === MathInputBlurBehaviour.SUBMIT) {
      return this.submitted({ source: ActionEndSource.BLUR })
    }
    if (this.blurBehaviour === MathInputBlurBehaviour.DISCARD) {
      return this.discarded({ source: ActionEndSource.BLUR })
    }
  }, 150)

  public constructor({
    blurBehaviour = MathInputBlurBehaviour.SUBMIT,
    editInterruptCondition = () => false,
  }: MathInputProps = {}) {
    this.editInterruptCondition = editInterruptCondition
    this.blurBehaviour = blurBehaviour

    sample({
      source: this.$currentSessionProps,
      clock: this.submitted,
      fn: (session, submitProps) => ({ ...submitProps, session }),
    }).watch(({ session, croppedSymbols, source, doNotHide, withValue }) => {
      if (!session) return
      session.onSubmit?.({
        getLatex: () => withValue ?? (this.mq?.latex() || ''),
        croppedSymbols,
        source,
      })
      if (!doNotHide) this.hideInput()
    })

    sample({
      source: this.$currentSessionProps,
      clock: this.discarded,
      fn: (session, props) => ({ ...props, session }),
    }).watch(({ session, source, doNotHide }) => {
      if (!session) return
      session.onDiscard?.({ source })
      if (!doNotHide) this.hideInput()
    })

    sample({
      source: this.$currentSessionProps,
      clock: this.deleted,
      fn: (session) => session,
    }).watch((session) => {
      session?.onDelete?.()
    })

    sample({
      source: this.$currentSessionProps,
      clock: this.edited,
      fn: (session, editProps) => ({ editProps, session }),
    }).watch(({ session, editProps }) => {
      if (!session) return
      session.onEdit?.(editProps)
    })

    this.showInput.watch((props) => {
      this.resetMqValue()
      if (props?.value && this.mq) {
        this.insertValue(props.value)
      }
    })
  }

  public readonly key = {
    down: () => this.mq?.keystroke('Down'),
  }

  public get isFocused() {
    return !!this.$currentSessionProps.getState()
  }

  public resetMqValue() {
    if (!this.mq) return
    this.mq.select()
    this.mq.write('')
  }

  protected retrieveEditor = (element: HTMLElement) => {
    this.editor = (element.querySelector('.mq-root-block') as HTMLSpanElement) || null
  }
  public readonly setUp = (element: HTMLElement | null) => {
    if (this.mq || !element) return
    this.mq = MathQuill.getInterface(2).MathField(element, {
      handlers: {
        edit: () => {
          if (!this.mq) return
          const latex = this.mq.latex()
          if (this.editInterruptCondition(latex)) return this.submitted()
          this.edited({ latex })
        },
        enter: () => {
          this.submitted()
        },
        moveOutOf: () => {
          this.submitted()
        },
        deleteOutOf: () => {
          this.deleted()
        },
      },
    })
    this.retrieveEditor(element)
    const observer = new MutationObserver(() => {
      if (!this.isFocused) return
      if (!element.classList.contains('mq-focused')) {
        this.blurTimeout.start()
        return
      }
      this.blurTimeout.interrupt()
    })
    observer.observe(element, {
      attributes: true,
      attributeFilter: ['class'],
    })
  }
  public insertValue(value: string) {
    const mq = this.mq
    if (!mq) return
    if (isCommand(value)) {
      mq.cmd(value)
    } else {
      mq.write(value)
    }

    setTimeout(() => {
      mq.reflow()
      mq.focus()
    })
  }

  public readonly insertOperator = ({ value, type }: Operator) => {
    if (!this.mq) return
    if (type === OperatorType.KATEX) {
      this.mq.cmd(value)
    } else {
      this.mq.write(value)
    }
    this.mq.focus()
  }

  public readonly focus = () => {
    if (!this.mq) return
    this.mq.focus()
  }

  public readonly setPlaceholder = (value: string) => {
    if (!this.editor) return
    this.editor.setAttribute('data-placeholder', value)
  }

  public readonly setBlurBehaviour = (behaviour: MathInputBlurBehaviour) => {
    this.blurBehaviour = behaviour
  }

  public readonly getLatex = () => {
    return this.mq?.latex() || ''
  }
}
