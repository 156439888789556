import { dynamicPaths } from '../../../config/pathnames.config'
import { IStudent } from '../../../api/types.users'
import { IProblem, ISubProblem } from '../../../api/types.problem'

type LinkById = Map<string, { link: string; next?: string; prev?: string }>

const convertToLinkedList = (sortedLinks: LinkById) => {
  const updatedSortedLinks = new Map(sortedLinks)
  const sortedLinksArray = Array.from(sortedLinks.values())

  Array.from(sortedLinks.keys()).forEach((problemId, index) => {
    const link = updatedSortedLinks.get(problemId)
    if (!link) return
    link.next = sortedLinksArray[index + 1]?.link
    link.prev = sortedLinksArray[index - 1]?.link
  })

  return updatedSortedLinks
}

export const getStudentsSolutionsNavigationLinks = (problems: IProblem[], exerciseId: string) => {
  const mapByProblemId: LinkById = problems.reduce(
    (problemLinks: LinkById, currentProblem: IProblem) => {
      if (currentProblem.subProblems.length > 0) {
        currentProblem.subProblems.forEach((subProblem: ISubProblem) => {
          const subProblemPathname = dynamicPaths.studentsSolutions({
            exerciseId: exerciseId,
            problemId: currentProblem._id,
            subProblemId: subProblem._id,
          })

          problemLinks.set(subProblem._id, { link: subProblemPathname })
        })

        return problemLinks
      }

      const problemPathname = dynamicPaths.studentsSolutions({
        exerciseId: exerciseId,
        problemId: currentProblem._id,
      })

      problemLinks.set(currentProblem._id, {
        link: problemPathname,
        next: undefined,
        prev: undefined,
      })
      return problemLinks
    },
    new Map()
  )

  return convertToLinkedList(mapByProblemId)
}

export const getStudentSolutionsNavigationLinks = (
  problems: IProblem[],
  exerciseId: string,
  studentId: string
) => {
  const mapByProblemId: LinkById = problems.reduce(
    (problemLinks: Map<string, { link: string }>, currentProblem: IProblem) => {
      if (currentProblem.subProblems.length > 0) {
        currentProblem.subProblems.forEach((subProblem: ISubProblem) => {
          const subProblemPathname = dynamicPaths.studentSolutions({
            exerciseId,
            problemId: currentProblem._id,
            subProblemId: subProblem._id,
            studentId,
          })

          problemLinks.set(subProblem._id, { link: subProblemPathname })
        })

        return problemLinks
      }

      const problemPathname = dynamicPaths.studentSolutions({
        exerciseId: exerciseId,
        problemId: currentProblem._id,
        studentId,
      })

      problemLinks.set(currentProblem._id, { link: problemPathname })
      return problemLinks
    },
    new Map()
  )
  return convertToLinkedList(mapByProblemId)
}

export const getStudentSolutionsNavigationLinksByStudent = (
  students: IStudent[],
  exerciseId: string,
  problemId: string
) => {
  const mapByStudentId: LinkById = students.reduce(
    (linksByStudentId: Map<string, { link: string }>, currentStudent: IStudent) => {
      const problemPathname = dynamicPaths.studentSolutions({
        exerciseId: exerciseId,
        problemId,
        studentId: currentStudent._id,
      })

      linksByStudentId.set(currentStudent._id, { link: problemPathname })
      return linksByStudentId
    },
    new Map()
  )
  return convertToLinkedList(mapByStudentId)
}
