import React from 'react'
import { Button, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import { SvgIconProps } from '../../../../ui/icons/types/types'

type PlayableControllerButtonProps = {
  Icon: React.FC<SvgIconProps>
  disabled?: boolean
  onClick: () => void
  label?: string
  className?: string
}

const PlayableControllerButton = ({
  Icon,
  onClick,
  disabled,
  label = '',
  className,
}: PlayableControllerButtonProps) => {
  return (
    <Button
      classes={{
        content: className,
      }}
      disabled={disabled}
      variant={BUTTON_VARIANT.SECONDARY}
      size={SIZE.SMALL}
      icon={<Icon color='var(--icon-color)' size={16} />}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export default PlayableControllerButton
