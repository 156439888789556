import React, { useEffect } from 'react'
import styles from './ProblemCard.module.scss'
import useText from 'i18n/hook'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import WhiteboardIcon from 'ui/icons/WhiteboardIcon/WhiteboardIcon'
import ReportError from '../../../ReportError/ReportError'
import { getAutologinTokenAndRedirect } from 'redux/modules/autologin.module'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import ProblemBody from './ProblemBody/ProblemBody'
import AnswerList from '../../../AnswerList/AnswerList'
import { TypesOfParams } from 'helpers/enums'
import { STUDENTS_AUTOLOGIN_URL } from 'config/urls'
import clsx from 'clsx'
import { IProblem, ISubProblem } from '../../../../api/types.problem'

type ProblemCardProps = {
  problem: IProblem
  subProblem: ISubProblem | null
  exerciseId: string
  buttonColor: BUTTON_COLOR
  isCorrectnessHidden: boolean
}

const ProblemCard = ({
  problem,
  subProblem,
  exerciseId,
  isCorrectnessHidden,
  buttonColor,
}: ProblemCardProps) => {
  const t = useText()
  const me = useSelector((state: RootState) => state.auth.data.me)
  const dispatch = useDispatch()
  const problemOrSubProblemAnswer = (subProblem || problem)?.answer ?? []

  return (
    <div className={styles.ProblemCard}>
      <ProblemBody problem={problem} subProblemId={subProblem?._id} />

      <div className={styles.Footer}>
        <div className={clsx(styles.Content, { [styles.Hidden]: isCorrectnessHidden })}>
          <p className={styles.Text}>{t.correctAnswer}</p>
          <div className={styles.Answer}>
            <AnswerList
              answers={problemOrSubProblemAnswer}
              characterType={problem.characterType}
              answerType={(subProblem || problem).answerType}
            />
          </div>
        </div>
        <div className={styles.Actions}>
          <Button
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZE.SMALL}
            color={buttonColor}
            icon={<WhiteboardIcon color='var(--icon-color)' size={15} />}
            tooltipText={t.solve}
            onClick={() => {
              getAutologinTokenAndRedirect({
                autologinUrl: STUDENTS_AUTOLOGIN_URL,
                userId: me._id,
                params: {
                  [TypesOfParams.EXERCISE_ID]: exerciseId,
                  [TypesOfParams.PROBLEM_ID]: problem._id,
                  [TypesOfParams.REDIRECT_URL]: window.location.pathname,
                  ...(subProblem?._id && { [TypesOfParams.SUBPROBLEM_ID]: subProblem?._id }),
                },
                autologinParamName: TypesOfParams.AUTOLOGIN_STUDENTS_TOKEN,
              })(dispatch)
            }}
          />
          <ReportError problem={problem} subProblem={subProblem} buttonColor={buttonColor} />
        </div>
      </div>
    </div>
  )
}

export default ProblemCard
