import { MotionStyle, Variants } from 'framer-motion'
import { IExercisesStatuses, EXERCISES_STATUS } from 'redux/modules/exercisesStatuses.module'

export enum AnimationVariants {
  IN = 'in',
  OUT = 'out',
  EXIT_IN_DIRECTION = 'exitInDirection',
}

export enum AnimationDirections {
  LEFT = -1,
  NONE = 0,
  RIGHT = 1,
}

export const EXERCISE_CARD_ANIMATION_DURATION = 0.7
const mainTransitionDelay =
  EXERCISE_CARD_ANIMATION_DURATION / 2 + EXERCISE_CARD_ANIMATION_DURATION / 20

export const mainTransition = {
  type: 'tween',
  duration: EXERCISE_CARD_ANIMATION_DURATION - mainTransitionDelay,
  delay: mainTransitionDelay,
}

export const animationVariants: Variants = {
  [AnimationVariants.IN]: { opacity: 1, zIndex: 0, transition: { duration: 0.2 } },
  [AnimationVariants.OUT]: { opacity: 0, zIndex: -1 },
  [AnimationVariants.EXIT_IN_DIRECTION]: (custom: { direction: number }) => ({
    zIndex: -1,
    x: custom.direction < 0 ? -3000 : 3000,
    z: -1,
    opacity: 0,
    transition: {
      type: 'tween',
      duration: EXERCISE_CARD_ANIMATION_DURATION + 0.3,
      opacity: {
        duration: EXERCISE_CARD_ANIMATION_DURATION / 2,
      },
    },
    transitionEnd: {
      display: 'none',
      opacity: 0,
      position: 'absolute',
    },
  }),
}

export const getAnimationStyles = (isPresent: boolean): MotionStyle => ({
  position: isPresent ? 'static' : 'absolute',
})

export const getAnimationDirection = (
  exercisesStatuses: IExercisesStatuses,
  chosenExerciseStatus: EXERCISES_STATUS
) => {
  const exerciseStatusesMap = {
    [EXERCISES_STATUS.UNPUBLISHED]: AnimationDirections.LEFT,
    [EXERCISES_STATUS.AVAILABLE]: exercisesStatuses.archived
      ? AnimationDirections.LEFT
      : AnimationDirections.RIGHT,
    [EXERCISES_STATUS.ARCHIVED]: AnimationDirections.RIGHT,
  }

  return exerciseStatusesMap[chosenExerciseStatus]
}
