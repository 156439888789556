import { scroller } from 'react-scroll'
import { BOOKSHELF_ID } from '../constants'
import { PROBLEM_LEVELS } from './SectionProblems/types'

import { IChapter } from '../../../api/types.books'
import { IProblem } from '../../../api/types.problem'

export const scrollToOpenedBook = (bookId: string) => {
  scroller.scrollTo(`book-${bookId}`, {
    smooth: true,
    containerId: BOOKSHELF_ID,
    duration: 500,
    offset: -3,
  })
}

export const scrollSectionToTop = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  section?.scrollTo(0, 0)
}

export const deserializeSelectedProblems = (state: string) => {
  const formattedState: [string, string[]][] = JSON.parse(state)
  return formattedState.reduce<Map<string, Set<string>>>((acc, [sectionId, problems]) => {
    acc.set(sectionId, new Set(problems))
    return acc
  }, new Map())
}

export const filterProblemsByLevel = (problems: IProblem[]) => {
  return problems.filter((problem) => problem.level <= PROBLEM_LEVELS.HARD)
}

export const getChapterListItems = (bookId: string, chapter: IChapter[]) => {
  return chapter.map((chapter) => ({
    id: chapter._id,
    name: chapter.name,
    sections: chapter.sections.map((section) => ({
      chapterId: chapter._id,
      chapterName: chapter.name,
      bookId: bookId,
      id: section._id,
      name: section.name,
    })),
  }))
}

export const sortProblemsByLevelAndOrder = (
  selectedProblems: Set<string>,
  allSectionProblems: IProblem[]
) => {
  const sectionProblemsMap = new Map(allSectionProblems.map((problem) => [problem._id, problem]))

  const sortedProblems = Array.from(selectedProblems).sort((idProblemA, idProblemB) => {
    const problemA = sectionProblemsMap.get(idProblemA)
    const problemB = sectionProblemsMap.get(idProblemB)

    if (!problemA || !problemB) return !problemA ? 1 : -1

    return problemA.level !== problemB.level
      ? problemA.level - problemB.level
      : problemA.order - problemB.order
  })

  return new Set(sortedProblems)
}
