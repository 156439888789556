import { intercomHelpUrl } from 'config/urls'
import { getLanguageShort } from './language.helpers'

const linkLanguageMap = {
  us: 'en',
  sv: 'sv',
  gb: 'en',
}

export function getHelpCenterLink(lang: string): string {
  const locale: string = getLanguageShort(lang)
  const linkLanguage = linkLanguageMap[locale] ?? locale
  return `${intercomHelpUrl}${linkLanguage}`
}
