import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { COLORS } from '@magmamath/ui'

const DatePickerInput = styled(TextField)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',

  '& .MuiOutlinedInput-root': {
    paddingRight: 0,
    height: '100%',
  },

  '& .MuiInputAdornment-root': {
    height: '100%',
    maxHeight: 'unset',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 0,

    '& .MuiButtonBase-root': {
      width: '100%',
      justifyContent: 'flex-end',
      height: '100%',
      paddingRight: '12px',
      borderRadius: 'unset',

      '&:active': {
        background: 'unset',
      },

      '&:hover': {
        background: 'unset',
      },
    },
  },

  '& fieldset': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    fontSize: '15px',
    color: 'var(--neutral-7)',
    height: '100%',
    paddingLeft: '12px',
  },
  '& .MuiButtonBase-root': {
    padding: 0,
    width: '100%',
    display: 'flex',
    marginRight: 'unset',
  },
  '&:hover': {
    '& .MuiOutlinedInput-input': {
      color: 'var(--neutral-10)',
    },
    backgroundColor: 'var(--neutral-2)',
    borderRadius: '12px',
  },
})

export default DatePickerInput
