import React, { PropsWithChildren } from 'react'
import Skeleton from 'ui/skeleton/Skeleton'
import styles from './SettingsOption.module.scss'

type SettingsOptionSkeletonProps = PropsWithChildren<{ nameWidth?: number | string }>

const SettingsOptionSkeleton = ({ children, nameWidth }: SettingsOptionSkeletonProps) => {
  return (
    <div className={styles.SettingsOption}>
      <Skeleton variant='circular' width={18} height={18} />
      <Skeleton className={styles.Name} variant='rounded' width={nameWidth} height={22} />
      {children}
    </div>
  )
}

export default SettingsOptionSkeleton
