import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import styles from './AbilityStudentResultsModal.module.scss'

import { ResultsView } from '../../../../helpers/enums'
import { getLastUserSolution } from '../../../../redux/modules/practiceResults.module'
import { IState } from '../../../../interfaces/state.interface'
import { IModal } from '../../../../redux/modules/modal.module'
import ResultsModalHeader from './ResultsModalHeader/ResultsModalHeader'
import AllStudentsResults from './AllStudentsResults/AllStudentsResults'
import SingleStudentResults from './SingleStudentResults/SingleStudentResults'

type AbilityStudentResultsModalProps = {
  modal: IState<IModal>
  onModalClose: () => void
}
const AbilityStudentResultsModal = ({ modal, onModalClose }: AbilityStudentResultsModalProps) => {
  const dispatch = useDispatch()

  const { abilityId, abilityName, selectedStudents, resultView, studentName } = modal.data
  const [practiceResultView, setPracticeResultView] = useState<ResultsView>(
    resultView || ResultsView.AllStudentsResults
  )
  const [studentResultName, setStudentResultName] = useState(studentName || '')

  const openSingleStudentResults = (studentId: string, name: string) => {
    setStudentResultName(name)
    dispatch(getLastUserSolution(abilityId, studentId))
    setPracticeResultView(ResultsView.SingleStudentResult)
  }

  const onBackButtonClick = () => {
    setPracticeResultView(ResultsView.AllStudentsResults)
  }

  return (
    <div className={styles.StudentResultsModalWrapper}>
      <div className={styles.StudentResultsModalContainer}>
        <ResultsModalHeader
          resultsView={practiceResultView}
          cardName={abilityName}
          onClose={onModalClose}
          studentName={studentResultName}
          onBackPress={onBackButtonClick}
        />
        <div className={styles.HeaderBodySeparator} />
        <div className={styles.StudentsResultsContainer}>
          {practiceResultView === ResultsView.AllStudentsResults && (
            <AllStudentsResults
              selectedStudents={selectedStudents}
              onOpenSingleStudentView={openSingleStudentResults}
            />
          )}
          {practiceResultView === ResultsView.SingleStudentResult && (
            <SingleStudentResults studentName={studentResultName} />
          )}
        </div>
      </div>
    </div>
  )
}

export default AbilityStudentResultsModal
