import CancelButton from 'components/basic/Buttons/CancelButton/CancelButton'
import { ModalType } from 'helpers/enums'
import { renderInputField } from 'helpers/form.helpers'
import { checkSafari } from 'helpers/general'
import { validateForm } from 'helpers/synchValidation.helpers'
import { IState } from 'interfaces/state.interface'
import { IUser } from 'api/types.users'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FormGroup from 'reactstrap/lib/FormGroup'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { IClassActions } from 'redux/modules/class.module'
import { IModal, IModalActions } from 'redux/modules/modal.module'
import './../modals.scss'
import './allPasswordsModal.component.scss'
import { ILocalization } from '../../../config/languages.config'
import { IClass } from '../../../api/types.classes'

export interface IAllPasswordsModalProps extends InjectedFormProps<FormData, any> {
  onSubmit: (values: any, id: string, history: any, localization: ILocalization) => {}
  classActions?: IClassActions
  modalActions?: IModalActions
  localization?: ILocalization
  modal?: IState<IModal>
  history: RouteComponentProps['history']
  addedClass?: IState<IClass>
  user?: IState<IUser>
  account: IState<any>
  initialValues: any
}

export class AllPasswordsReduxForm extends React.PureComponent<IAllPasswordsModalProps, any> {
  public toggle = () => {
    this.props.modalActions!.closeAllPasswordsModal()
  }

  public render() {
    const { handleSubmit, onSubmit, valid, invalid, reset, addedClass, localization, history } =
      this.props
    const { saveTxt, resetAllPasswordsQuestion, newPassTxt, newPassConfirmTxt, cancelTxt } =
      localization!.data
    const isSafari: boolean = !!checkSafari()
    const opened: boolean = !!(
      (this.props.modal as any).data.modalType === ModalType.AllPasswordsModal
    )

    const submit = (values: any) => {
      if (valid) {
        return (
          onSubmit(values, addedClass!.data._id, history, localization!) as Promise<any>
        ).then(() => reset())
      }
      return
    }

    return (
      <div>
        <Modal
          fade={!isSafari}
          isOpen={opened}
          className='custom-modal all-passwords-modal colored-modal'
          toggle={this.toggle}
        >
          <Form onSubmit={handleSubmit(submit)}>
            <ModalHeader className='custom-header all-passwords-modal-header' toggle={this.toggle}>
              {resetAllPasswordsQuestion}
            </ModalHeader>
            <ModalBody className='custom-body all-passwords-modal-body'>
              <FormGroup className='form-group all-passwords-form input-wrapper'>
                <Field
                  name='password'
                  component={renderInputField as any}
                  className='form-control modal-default-input'
                  placeholder={newPassTxt}
                  id='password'
                  type='text'
                />
              </FormGroup>
              <FormGroup className='form-group all-passwords-form input-wrapper'>
                <Field
                  name='passwordConfirmation'
                  component={renderInputField as any}
                  className='form-control modal-default-input'
                  placeholder={newPassConfirmTxt}
                  id='passwordConfirmation'
                  type='text'
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter className='custom-footer all-passwords-footer'>
              <Button className='custom-create-button' type='submit' disabled={invalid}>
                <span>{saveTxt}</span>
              </Button>
              <CancelButton cancelFunc={this.toggle} title={cancelTxt} />
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

export const AllPasswordsModal: any = reduxForm({
  enableReinitialize: true,
  form: 'AllPasswordsReduxForm',
  validate: validateForm,
})(AllPasswordsReduxForm) as any
