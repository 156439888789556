import React from 'react'
import styles from './AnswerCreatorBoard.module.scss'
import ProblemCreatorCardHeader from '../common/ProblemCreatorCardHeader'
import CreatingProblemCard from '../common/CreatingProblemCard'
import useText from '../../../i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { ANSWER_CREATING_TABS } from './tabs'
import { setActiveTab } from '../../../redux/modules/problemCreator.module'
import AnswerVariantTabs from './AnswerVariantTabs'

type AnswerCreationBlockProps = {
  className?: string
}

const AnswerCreationBlock = ({ className }: AnswerCreationBlockProps) => {
  const t = useText()
  const dispatch = useDispatch()
  const errorAnswerField = useSelector(
    (state: RootState) => state.problemCreatorData.errorAnswerField
  )
  const activeTab = useSelector((state: RootState) => state.problemCreatorData.activeTab)

  return (
    <CreatingProblemCard className={className} errorMessage={errorAnswerField}>
      <ProblemCreatorCardHeader title={t.answerTxt} />
      <AnswerVariantTabs
        classes={{ Tab: styles.TabExtraClass, TabsList: styles.TabsExtraClass }}
        defaultIndex={activeTab}
        tabs={ANSWER_CREATING_TABS}
        onChange={(index) => dispatch(setActiveTab(index))}
      />
    </CreatingProblemCard>
  )
}

export default AnswerCreationBlock
