import HeatmapMenuActionsSkeleton from 'features/heatmap/HeatmapMenu/HeatmapMenuActions/HeatmapMenuActionsSkeleton'
import HeatmapMenuSkeleton from 'features/heatmap/HeatmapMenu/HeatmapMenuSkeleton'
import HeatmapMenuTabsSkeleton from 'features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabsSkeleton'
import TableSkeleton from 'features/tables/HeatmapTable/ui/HeatmapTable/TableSkeleton'
import React from 'react'
import styles from './Heatmap.module.scss'

const HeatmapSkeleton = () => (
  <div className={styles.HeatmapPageContainer}>
    <HeatmapMenuSkeleton
      actionButtons={<HeatmapMenuActionsSkeleton numberOfButtons={5} />}
      navigation={<HeatmapMenuTabsSkeleton />}
    />
    <TableSkeleton />
  </div>
)

export default HeatmapSkeleton
