import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { getSection } from './section.module'
import { ILocalization } from "../../config/languages.config";

// Subchapters links constants
export enum SUBCHAPTERS_LINKS {
  OPEN_SUBCHAPTER = 'OPEN_SUBCHAPTER',
  CLOSE_SUBCHAPTER = 'CLOSE_SUBCHAPTER',
}

// Subchapters links reducer
const initialState = {
  data: {
    sectionId: null,
    sectionName: null,
  },
  error: null,
  loading: false,
}

export function subchaptersReducer(state: any = initialState, action: IAction<SUBCHAPTERS_LINKS>) {
  switch (action.type) {
    case SUBCHAPTERS_LINKS.OPEN_SUBCHAPTER:
      return {
        ...state,
        data: {
          ...state.data,
          sectionName: action.payload.sectionName,
          sectionId: action.payload.sectionId,
        },
      }
    case SUBCHAPTERS_LINKS.CLOSE_SUBCHAPTER:
      return {
        ...state,
        data: { ...state.data, opened: false, redirecting: true },
      }

    default:
      return state
  }
}

// Subchapters links actions
export function openSubchapter(sectionId: string, sectionName: string, history: any) {
  return async (dispatch: IDispatch<any>) => {
    dispatch({
      payload: { sectionName, sectionId },
      type: SUBCHAPTERS_LINKS.OPEN_SUBCHAPTER,
    })
    await getSection(sectionId, history)(dispatch)
  }
}

export function closeSubchapter() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: SUBCHAPTERS_LINKS.CLOSE_SUBCHAPTER })
  }
}

export interface ISubchaptersLinksActions {
  openSubchapter: (
    sectionId: string,
    sectionName: string,
    history: any,
    localization: ILocalization
  ) => void
  closeSubchapter: () => void
}

export interface ISubchaptersLinks {
  sectionId: string
  sectionName: string
}
