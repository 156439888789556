import useText from 'i18n/hook'
import React from 'react'
import styles from './SpecialBooksInfo.module.scss'
import { SPECIAL_BOOK_HREF } from '../constants'

const SpecialBooksInfo = () => {
  const t = useText()
  return (
    <p className={styles.Paragraph}>
      <span>{t.specialBook} </span>
      <a href={SPECIAL_BOOK_HREF} target='_blank' className={styles.Link}>
        {t.startguide}
      </a>
    </p>
  )
}

export default SpecialBooksInfo
