import * as React from 'react'
import StudentsTableUI from '../../../ui/tables/StudentsTableUI/StudentsTableUI'
import { IMe, IStudent } from '../../../api/types.users'
import { IModalActions } from '../../../redux/modules/modal.module'
import StudentsTableRow from './StudentsTableRow/StudentsTableRow'
import { ILocalization } from '../../../config/languages.config'
import { IClass } from '../../../api/types.classes'
import { IGroup } from '../../../api/api.groups'

interface IStudentsTableProps {
  students: IStudent[]
  history: any
  localization: ILocalization
  me: IMe
  modalActions: IModalActions
  classId?: string
  groupId?: string
  classOrGroup: IGroup | IClass
}

const StudentsTable: React.FC<IStudentsTableProps> = ({
  students,
  history,
  localization,
  me,
  modalActions,
  classId,
  groupId,
  classOrGroup,
}) => {
  if (!students.length) return null

  return (
    <StudentsTableUI>
      {students.map((st) => (
        <StudentsTableRow
          key={st?._id}
          student={st}
          me={me}
          modalActions={modalActions}
          history={history}
          localization={localization}
          classId={classId}
          groupId={groupId}
          classOrGroup={classOrGroup}
        />
      ))}
    </StudentsTableUI>
  )
}

export default StudentsTable
