import React, { PropsWithChildren } from 'react'
import { Modal } from '@mui/material'
import styles from './CommonModalWrapperStyles.module.scss'
import CloseModalIcon from '../../icons/CloseModalIcon/CloseModalIcon'
import { COLORS } from '@magmamath/ui'

interface CommonModalWrapperProps {
  open: boolean
  onClose: () => void
  title: string
}

const CommonModalWrapper = ({
  title,
  open,
  onClose,
  children,
}: PropsWithChildren<CommonModalWrapperProps>) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.Wrapper}>
        <div className={styles.CLoseIconWrapper} onClick={onClose}>
          <CloseModalIcon size={16} color={COLORS.NEUTRAL_10} />
        </div>
        <h1 className={styles.Title}>{title}</h1>
        <div className={styles.Body}>{children}</div>
      </div>
    </Modal>
  )
}

export default CommonModalWrapper
