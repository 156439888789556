import React from 'react'
import style from './FilterLabel.module.scss'

interface IFilterLabel {
  label: string
}

const FilterLabel: React.FC<IFilterLabel> = ({ label }) => {
  return <p className={style.Paragraph}>{label}</p>
}

export default FilterLabel
