import React from 'react'
import { AccordionListItem } from '@magmamath/ui'
import { Bredcrumb } from '../types'
import styles from './../SectionsList/SectionsList.module.scss'
import { ISection } from 'api/types.books'
import ProblemsCount from '../ProblemsCount/ProblemsCount'

type BookSectionsListProps = {
  sections: ISection[]
  onClickSection: (section: Bredcrumb) => void
}

const BookSectionsList = ({ sections, onClickSection }: BookSectionsListProps) => (
  <>
    {sections.map((section, index) => (
      <AccordionListItem
        key={section._id}
        title={
          <div className={styles.Title}>
            <span>{`${index + 1}. ${section.name}`}</span>
            <ProblemsCount section={section} />
          </div>
        }
        onClick={() =>
          onClickSection({
            id: section._id,
            name: section.name,
          })
        }
      />
    )
    )}
  </>
)


export default BookSectionsList
