import { currentLocale } from 'config/modules.config'
import dayjs, { Dayjs } from 'dayjs'
import { LocaleData } from 'helpers/enums'
import { convertHourTo24HFormat } from 'helpers/timeConverterHelper'
import { Time } from 'interfaces/scheduleTime.interface'

export const unionDateAndTime = (date: Dayjs | null, time: Time) => {
  const hour =
    (currentLocale.currentLang === LocaleData.US || currentLocale.currentLang === LocaleData.GB) &&
    time.type !== null
      ? convertHourTo24HFormat(time.hour, time.type)
      : time.hour
  return dayjs(date).set('hour', hour).set('minute', time.minute).startOf('minute')
}
