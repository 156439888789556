import React, { PropsWithChildren } from 'react'
import styles from './NoContentUI.module.scss'
import clsx from 'clsx'

interface INoContentUIProps {
  title?: string
  className?: string
}

const NoContentUI = ({ title, children, className }: PropsWithChildren<INoContentUIProps>) => {
  return (
    <div className={`${styles.Container} ${className}`}>
      {title && <div className={styles.Title}>{title}</div>}
      {children}
    </div>
  )
}

export default NoContentUI
