import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { MULTIPLE_SOLUTIONS } from './multipleSolutions.module'
import { ChangeSolutionCorrectnessPayload } from '../../api/api.solutions'
import { api } from '../../api'

// Solution constants
export enum SOLUTION {
  CORRECTNESS_EDIT_REQUEST = 'CORRECTNESS_EDIT_REQUEST',
  CORRECTNESS_EDIT_SUCCESS = 'CORRECTNESS_EDIT_SUCCESS',
  CORRECTNESS_EDIT_ERROR = 'CORRECTNESS_EDIT_ERROR',
}

// Solution reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function solutionReducer(state: any = initialState, action: IAction<SOLUTION>) {
  switch (action.type) {
    case SOLUTION.CORRECTNESS_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SOLUTION.CORRECTNESS_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SOLUTION.CORRECTNESS_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function changeSolutionCorrectness(
  data: ChangeSolutionCorrectnessPayload['data'],
  solutionId: string
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SOLUTION.CORRECTNESS_EDIT_REQUEST })
      const res = await api.solutions.changeCorrectness({ solutionId, data })
      dispatch({ type: SOLUTION.CORRECTNESS_EDIT_SUCCESS, payload: res })
      dispatch({
        type: MULTIPLE_SOLUTIONS.UPDATE_CORRECTNESS,
        payload: { taskId: data.problemId, solutionId },
      })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: SOLUTION.CORRECTNESS_EDIT_ERROR,
          })
        },
        () => handleNetworkError(SOLUTION.CORRECTNESS_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export interface ISolutionActions {
  changeSolutionCorrectness: (
    data: {
      problemId: string
      correct: boolean
      exerciseId: string
    },
    solutionId: string
  ) => void
}

export interface ISolution {
  answer: string[]
  correct: boolean
  createdAt: string
  drawingImageName: string
  drawingName: string
  exercise: string
  laTeX: string
  subProblem: string
  updatedAt: string
  user: string
  userType: boolean
  reviewedByTeacherAt?: string
  _id: string
}
