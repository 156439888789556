import swedishRoundFlag from 'assets/img/flags/sweden-round.svg'
import swedishFlag from 'assets/img/flags/sweden.svg'
import englishRoundFlag from 'assets/img/flags/united-states-of-america-round.svg'
import englishFlag from 'assets/img/flags/united-states-of-america.svg'
import britishRoundFlag from 'assets/img/flags/united-kingdom-round.svg'
import britishFlag from 'assets/img/flags/united-kingdom.svg'
import { Events } from 'helpers/enums'
import * as React from 'react'
import { ILocalizationActions, LOCALIZE } from 'redux/modules/localization.module'
import './LocalizationDropdown.scss'
import { config } from '../../../../config'
import { ILocalization } from "../../../../config/languages.config";

interface IDropdownLocalizationProps {
  localization: ILocalization
  localizationActions: ILocalizationActions
}

interface IDropdownLocalizationState {
  dropdownOpen: boolean
}

class DropdownLocalization extends React.Component<IDropdownLocalizationProps, any> {
  public iconRef: HTMLElement | null
  public menuRef: HTMLElement | null
  constructor(props: IDropdownLocalizationProps) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  public componentDidMount() {
    document.addEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public toggle = (): void => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  public close = (): void => {
    this.setState({ dropdownOpen: false })
  }

  public handleClickOutside = (event: MouseEvent): void => {
    if (
      !!this.iconRef &&
      event.target instanceof HTMLElement &&
      !this.iconRef.contains(event.target) &&
      this.menuRef &&
      !this.menuRef.contains(event.target)
    ) {
      this.close()
    }
  }

  public componentDidUpdate(
    prevProps: IDropdownLocalizationProps,
    prevState: IDropdownLocalizationState
  ) {
    if (prevState.dropdownOpen !== this.state.dropdownOpen && this.menuRef) {
      if (this.state.dropdownOpen) {
        this.menuRef.classList.remove('d-none')
      } else {
        this.menuRef.classList.add('d-none')
      }
    }
  }

  public getIcon = (lang: LOCALIZE): string => {
    const dictionary: { [key in LOCALIZE]: string } = {
      US: englishRoundFlag,
      SE: swedishRoundFlag,
      GB: britishRoundFlag,
    }
    return dictionary[lang]
  }

  public componentWillUnmount() {
    document.removeEventListener(Events.CLICK, this.handleClickOutside, true)
  }

  public render() {
    const iconOfChosenLang: string = this.getIcon((this.props.localization.data as any).languageTxt)
    return (
      <div
        className='localization-menu oval'
        ref={(node: HTMLElement | null) => (this.iconRef = node)}
        onClick={() => {
          if (config.APP_HOST_SWEDEN === config.APP_HOST) {
            this.toggle()
          }
        }}
      >
        <div className='localization-menu-icon-wrapper'>
          <img className='main-language-icon' src={iconOfChosenLang} />
        </div>
        <ul
          className='localization-dropdown-menu d-none'
          ref={(node: HTMLElement | null) => (this.menuRef = node)}
        >
          <li
            className='localization-option top-link'
            onClick={() => {
              this.props.localizationActions.setUS()
            }}
          >
            <img className='flag-icon' src={englishFlag} />
            <span>English</span>
          </li>
          <li
            className='localization-option bottom-link'
            onClick={() => {
              this.setState({ dropdownOpen: false })
              this.props.localizationActions.setSwedish()
            }}
          >
            <img className='flag-icon' src={swedishFlag} />
            <span>Svenska</span>
          </li>
          <li
            className='localization-option bottom-link'
            onClick={() => {
              this.setState({ dropdownOpen: false })
              this.props.localizationActions.setBritish()
            }}
          >
            <img className='flag-icon' src={britishFlag} />
            <span>British</span>
          </li>
        </ul>
      </div>
    )
  }
}

export default DropdownLocalization
