import { useStore, useStoreMap } from 'effector-react'
import { getPinnedAnswerArray } from 'features/filters/StatsFilter/helpers'
import { $statsFilter } from 'features/filters/StatsFilter/model/model'
import { getButtonColor } from 'features/heatmap/HeatmapMenu/getButtonColor'
import Problem from 'features/heatmap/Problem/Problem'
import SolutionCard from 'features/heatmap/SolutionCard/SolutionCard'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { sessionStorageKeys } from '../../../config/localStorageKeys'
import './MultipleSolutions.module.scss'
import styles from './MultipleSolutions.module.scss'
import { getCardData } from './helpers/getCardData'
import { mapProblemsById } from './helpers/mapProblemsById'
import { IExercise } from '../../../api/types.assignments'
import { IMultipleSolution } from '../../../api/types.solutions'

interface IMultipleSolutionsProps {
  exercise: IExercise
  stats: IMultipleSolution[]
  cardTitle: string
  studentId: string
  isUserDeleted: boolean
}

const MultipleSolutions = ({
  stats,
  exercise,
  isUserDeleted,
  cardTitle,
  studentId,
}: IMultipleSolutionsProps) => {
  const buttonColor = getButtonColor(exercise.testModeEnabled)
  const problems = mapProblemsById(exercise.problems)
  const heatmapSettings = useStore($heatmapSettings)
  const cardColor = (heatmapSettings.isAnonymousCorrectnessMode && buttonColor) || undefined

  useEffect(() => {
    sessionStorage.setItem(sessionStorageKeys.isMultipleSolutionsViewVisited, 'true')
  }, [])

  const currentUsersStats = useStoreMap({
    store: $statsFilter,
    keys: [stats],
    fn: ({ pinsData, isPinsFilterActive }) => {
      if (!stats) return [] as IMultipleSolution[]
      if (!isPinsFilterActive) return stats

      return stats.reduce<IMultipleSolution[]>((acc, card) => {
        const updatedAnswerArray = getPinnedAnswerArray({
          answerArray: card.answerArray,
          currentPins: pinsData.currentPinsData?.pins ?? [],
        })
        if (updatedAnswerArray?.length) {
          acc.push({
            ...card,
            answerArray: updatedAnswerArray,
          })
        }

        return acc
      }, [])
    },
  })

  return (
    <div className={styles.SolutionsList}>
      {currentUsersStats.map((card) => {
        const selectedAttemptAnswer = card.answerArray?.find(
          (answer) => answer.selectedAttempt?.attemptNumber
        )

        const { cardSubProblem, cardProblem, solutionLink } = getCardData({
          problemId: card.problemId,
          subProblemId: card.subProblemId,
          problems,
          studentId: card.studentId,
          exerciseId: exercise._id,
          searchParams: {
            attempt: selectedAttemptAnswer?.selectedAttempt?.attemptNumber,
          },
        })

        return (
          <div className={styles.Problem} key={card.subProblemId || card.problemId}>
            {cardProblem && 'characterType' in cardProblem && (
              <>
                <Problem
                  buttonColor={buttonColor}
                  exerciseId={exercise._id}
                  problem={cardProblem}
                  subProblem={cardSubProblem || null}
                  isCorrectnessHidden={heatmapSettings.isAnonymousCorrectnessMode}
                />

                <Link to={solutionLink} className={styles.SolutionLink}>
                  <SolutionCard
                    key={card.problemId}
                    solution={card}
                    title={cardTitle}
                    buttonColor={buttonColor}
                    exerciseId={exercise._id}
                    headerColor={cardColor}
                    studentId={studentId}
                    problem={cardSubProblem || cardProblem}
                    problemId={cardProblem._id}
                    subProblemId={cardSubProblem?._id}
                    correctAnswer={cardSubProblem?.answer || cardProblem.answer}
                    withPlayable={false}
                    characterType={cardProblem.characterType}
                    answerType={(cardSubProblem || cardProblem).answerType}
                    isUserDeleted={isUserDeleted}
                  />
                </Link>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default MultipleSolutions
