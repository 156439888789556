import React from 'react'
import styles from './SolutionCardBody.module.scss'
import { getSolutionsUrl } from '../../../../helpers/exercise.helpers'
import useText from '../../../../i18n/hook'
import clsx from 'clsx'

type SolutionCardImageBodyProps = {
  image: string
  className?: string
}

const SolutionCardImageBody = ({ image, className }: SolutionCardImageBodyProps) => {
  const t = useText()

  return (
    <div className={styles.Body}>
      <img
        className={clsx(styles.Drawing, className)}
        src={getSolutionsUrl(image)}
        alt={t.solutionsTxt}
      />
    </div>
  )
}

export default SolutionCardImageBody
