import katex from 'katex'
import { OperatorDescriptor } from './operators'

type MapButtonProps = {
  renderer: (element: HTMLElement) => void
} & OperatorDescriptor

export class ShortcutsButtonsModel {
  private operators: OperatorDescriptor[]

  public constructor(operators: OperatorDescriptor[]) {
    this.operators = operators
  }

  public setOperators(operators: OperatorDescriptor[]) {
    this.operators = operators
  }

  public readonly map = <T>(callback: (props: MapButtonProps, index: number) => T) => {
    return this.operators.map((operator, index) => {
      return callback(
        {
          ...operator,
          renderer: (element: HTMLElement) => {
            if (operator.renderStraight || typeof operator.label !== 'string') return
            katex.render(operator.label, element, {
              throwOnError: false,
            })
          },
        },
        index
      )
    })
  }
}
