import React from 'react'
import { SvgIconProps } from '../types/types'

const ErrorMarkerIcon = ({ size = 6, color }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 6 6'
      fill='none'
    >
      <circle cx='3' cy='3' r='3' fill={color} />
    </svg>
  )
}

export default ErrorMarkerIcon
