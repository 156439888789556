import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClass } from 'redux/modules/class.module'
import { changeLoginWithQrCodeStatus } from 'redux/modules/classes.module'
import { RootState } from 'store/store'
import UISwitch from 'ui/Switch/Switch'
import ChangeMultiplicityTableRowUI from 'ui/tables/ChangeMultiplicityTableUI/ChangeMultiplicityTableUIRow/ChangeMultiplicityTableRowUI'
import styles from './QrCodeMultiplicityAccess.module.scss'
import { IClass } from '../../../api/types.classes'

type QrCodeMultiplicityAccessProps = {
  classOrGroup: IClass
}

const QrCodeMultiplicityAccess = ({ classOrGroup }: QrCodeMultiplicityAccessProps) => {
  const localization = useSelector((state: RootState) => state.localization)
  const dispatch = useDispatch()
  const classId = classOrGroup._id ?? ''
  const t = localization.data

  const onSwitchValueChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked

    const isSuccessful = await dispatch(changeLoginWithQrCodeStatus(classId, value, history))

    if (!!isSuccessful) dispatch(getClass(classId))
  }

  return (
    <ChangeMultiplicityTableRowUI title={t.signInWithQRCodeTxt} subTitle={'   '}>
      <div className={styles.SwitchWrapper}>
        <UISwitch checked={classOrGroup?.qrCodeLogin ?? false} onChange={onSwitchValueChange} />
      </div>
    </ChangeMultiplicityTableRowUI>
  )
}

export default QrCodeMultiplicityAccess
