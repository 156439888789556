import { IProblem } from 'api/types.problem'
import { useUnit } from 'effector-react'
import { Reorder } from 'framer-motion'
import { useReorderScroll } from 'lib/hooks/useReorderScroll'
import useTouchScreenDetection from 'lib/hooks/useTouchScreenDetection'
import React from 'react'
import { dndSelectedProblemsModel } from '../model/dndSelectedProblems'
import styles from './DraggableProblemsList.module.scss'
import DraggableProblemItem from './ProblemItem/DraggableProblemItem'
import ScrollButtons from './ScrollButtons/ScrollButtons'

const DraggableProblemsList = () => {
  const isTouchScreenDevice = useTouchScreenDetection()
  const { scrollerRef, onScroll, dragControls, onDragStart, onDrag, onDragEnd } = useReorderScroll()
  const dndSelectedProblems = useUnit(dndSelectedProblemsModel.$state)

  if (!dndSelectedProblems) return null

  return (
    <>
      <Reorder.Group
        className={styles.List}
        values={dndSelectedProblems}
        axis='y'
        onReorder={(newOrder: IProblem[]) => dndSelectedProblemsModel.set(newOrder)}
        layoutScroll
        style={{ touchAction: 'none' }} // Intentionally added as inline style
        ref={scrollerRef}
        onScroll={onScroll}
      >
        {dndSelectedProblems.map((problem, index) => (
          <DraggableProblemItem
            key={problem._id}
            problem={problem}
            order={index + 1}
            problemsCount={dndSelectedProblems.length}
            dragControls={dragControls}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
        ))}
      </Reorder.Group>
      {isTouchScreenDevice && <ScrollButtons ref={scrollerRef} />}
    </>
  )
}

export default DraggableProblemsList
