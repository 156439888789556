import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './LoaderWrapper.module.scss'

const Loader = ({
  children,
  className,
  ...restProps
}: JSX.IntrinsicElements['div'] & PropsWithChildren) => (
  <div className={clsx(styles.LoaderWrapper, className)} {...restProps}>
    {children}
  </div>
)

export default Loader
