import React from 'react'
import { SvgIconProps } from '../types/types'
import { COLORS } from '@magmamath/ui'

const CalculatorIcon: React.FC<SvgIconProps> = ({ size = 25, color = COLORS.NEUTRAL_9 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.91937 1.9C3.55487 1.9 3.25937 2.19549 3.25937 2.56V13.44C3.25937 13.8045 3.55487 14.1 3.91937 14.1H12.0794C12.4439 14.1 12.7393 13.8045 12.7393 13.44V2.56C12.7393 2.19549 12.4439 1.9 12.0794 1.9H3.91937ZM1.85938 2.56C1.85938 1.42229 2.78167 0.5 3.91937 0.5H12.0794C13.2171 0.5 14.1393 1.42229 14.1393 2.56V13.44C14.1393 14.5777 13.2171 15.5 12.0794 15.5H3.91937C2.78167 15.5 1.85938 14.5777 1.85938 13.44V2.56Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5793 3.92C4.5793 3.5334 4.8927 3.22 5.2793 3.22H10.7193C11.1059 3.22 11.4193 3.5334 11.4193 3.92C11.4193 4.30659 11.1059 4.62 10.7193 4.62H5.2793C4.8927 4.62 4.5793 4.30659 4.5793 3.92ZM4.5793 6.63999C4.5793 6.25339 4.8927 5.93999 5.2793 5.93999H5.2861C5.6727 5.93999 5.9861 6.25339 5.9861 6.63999C5.9861 7.02659 5.6727 7.33999 5.2861 7.33999H5.2793C4.8927 7.33999 4.5793 7.02659 4.5793 6.63999ZM7.29929 6.63999C7.29929 6.25339 7.61269 5.93999 7.99929 5.93999H8.00609C8.39269 5.93999 8.70609 6.25339 8.70609 6.63999C8.70609 7.02659 8.39269 7.33999 8.00609 7.33999H7.99929C7.61269 7.33999 7.29929 7.02659 7.29929 6.63999ZM10.0193 6.63999C10.0193 6.25339 10.3327 5.93999 10.7193 5.93999H10.7261C11.1127 5.93999 11.4261 6.25339 11.4261 6.63999C11.4261 7.02659 11.1127 7.33999 10.7261 7.33999H10.7193C10.3327 7.33999 10.0193 7.02659 10.0193 6.63999ZM4.5793 9.35998C4.5793 8.97338 4.8927 8.65998 5.2793 8.65998H5.2861C5.6727 8.65998 5.9861 8.97338 5.9861 9.35998C5.9861 9.74658 5.6727 10.06 5.2861 10.06H5.2793C4.8927 10.06 4.5793 9.74658 4.5793 9.35998ZM7.29929 9.35998C7.29929 8.97338 7.61269 8.65998 7.99929 8.65998H8.00609C8.39269 8.65998 8.70609 8.97338 8.70609 9.35998C8.70609 9.74658 8.39269 10.06 8.00609 10.06H7.99929C7.61269 10.06 7.29929 9.74658 7.29929 9.35998ZM10.7193 8.65998C11.1059 8.65998 11.4193 8.97338 11.4193 9.35998V12.08C11.4193 12.4666 11.1059 12.78 10.7193 12.78C10.3327 12.78 10.0193 12.4666 10.0193 12.08V9.35998C10.0193 8.97338 10.3327 8.65998 10.7193 8.65998ZM4.5793 12.08C4.5793 11.6934 4.8927 11.38 5.2793 11.38H5.2861C5.6727 11.38 5.9861 11.6934 5.9861 12.08C5.9861 12.4666 5.6727 12.78 5.2861 12.78H5.2793C4.8927 12.78 4.5793 12.4666 4.5793 12.08ZM7.29929 12.08C7.29929 11.6934 7.61269 11.38 7.99929 11.38H8.00609C8.39269 11.38 8.70609 11.6934 8.70609 12.08C8.70609 12.4666 8.39269 12.78 8.00609 12.78H7.99929C7.61269 12.78 7.29929 12.4666 7.29929 12.08Z'
        fill={color}
      />
    </svg>
  )
}

export default CalculatorIcon
