import { TransformedRecord } from '@magmamath/skills-utils'

const TREE_PATHS = {
  ROOT: '0',
  FIRST_GRADE: '0.0',
  FIRST_DOMAIN: '0.0.0',
  FIRST_STANDARD: '0.0.0.0',
  SINGLE_DOMAIN_BY_GRADE: (grade: number) => `0.${grade}.0`,
  SINGLE_STANDARD_BY_GRADE: (grade: number) => `0.${grade}.0.0`,
  MULTIPLE_DOMAINS_BY_GRADE: (grade: number) => `0.${grade}`,
}

export const getDefaultPath = (
  skillsData: TransformedRecord,
  classGrade: number,
  nodeIdByGrade: Record<string, string>
) => {
  if (skillsData) {
    // no grade
    if (classGrade === -1) {
      return TREE_PATHS.ROOT
    }
    // single grade, single domain, single standard
    if (
      skillsData.children.length === 1 &&
      skillsData.children[0].children.length === 1 &&
      skillsData.children[0].children[0].children.length === 1
    ) {
      return TREE_PATHS.FIRST_STANDARD
    }
    // single grade, single domain, multiple standards
    if (
      skillsData.children.length === 1 &&
      skillsData.children[0].children.length === 1 &&
      skillsData.children[0].children[0].children.length > 1
    ) {
      return TREE_PATHS.FIRST_DOMAIN
    }
    // single grade, multiple domains
    if (skillsData.children.length === 1 && skillsData.children[0].children.length > 1) {
      return TREE_PATHS.FIRST_GRADE
    }
    const gradeIndex = skillsData.children.findIndex(
      (grade) => grade._id === nodeIdByGrade[classGrade]
    )
    // multiple grades, assignment grade not found in tree grades
    if (gradeIndex === -1 && skillsData.children.length > 1) {
      return TREE_PATHS.ROOT
    }
    // multiple grades single domain multiple standards 12-13
    if (
      skillsData.children.length > 1 &&
      skillsData.children[gradeIndex].children.length === 1 &&
      skillsData.children[gradeIndex].children[0].children.length > 1
    ) {
      return TREE_PATHS.SINGLE_DOMAIN_BY_GRADE(gradeIndex)
    }
    // multiple grades single domain single standard 14-15
    if (
      skillsData.children.length > 1 &&
      skillsData.children[gradeIndex].children.length === 1 &&
      skillsData.children[gradeIndex].children[0].children.length === 1
    ) {
      return TREE_PATHS.SINGLE_STANDARD_BY_GRADE(gradeIndex)
    }
    // multiple grades multiple domains multiple standards 16-17
    if (skillsData.children.length > 1 && skillsData.children[gradeIndex].children.length > 1) {
      return TREE_PATHS.MULTIPLE_DOMAINS_BY_GRADE(gradeIndex)
    }
  }
  return TREE_PATHS.ROOT
}

const TREE_PATHS_WITHOUT_GRADE = {
  ROOT: '0',
  FIRST_DOMAIN: '0.0',
  FIRST_STANDARD: '0.0.0',
}

export const getDefaultPathWithoutGrade = (skillsData: TransformedRecord) => {
  if (skillsData) {
    // single domain, single standard
    if (skillsData.children.length === 1 && skillsData.children[0].children.length === 1) {
      return TREE_PATHS_WITHOUT_GRADE.FIRST_STANDARD
    }
    // single domain, multiple standards
    if (skillsData.children.length === 1 && skillsData.children[0].children.length > 1) {
      return TREE_PATHS_WITHOUT_GRADE.FIRST_DOMAIN
    }
    // multiple domains
    if (skillsData.children.length > 1) {
      return TREE_PATHS_WITHOUT_GRADE.ROOT
    }
  }
  return TREE_PATHS_WITHOUT_GRADE.ROOT
}
