import 'katex/dist/katex.min.css'
import React from 'react'
// tslint:disable-next-line:no-var-requires
const { InlineMath } = require('react-katex')

export interface IKaTeXProps {
  laTeXDescription: string
}

export class RenderedKaTeX extends React.Component<IKaTeXProps, any> {
  public render() {
    return <InlineMath>{this.props.laTeXDescription}</InlineMath>
  }
}
