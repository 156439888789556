import { BUTTON_VARIANT, Button, Modal, SIZE, Typography } from '@magmamath/ui'
import { ILocalization } from 'config/languages.config'
import useText from 'i18n/hook'
import { getFullGrades } from 'lib/grades'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import styles from './GradesModal.module.scss'
import { GradeSelect } from './GradeSelect'

type GradesModalProps = {
  isOpen: boolean
  onClose: () => void
  classNameValue?: string
  onSaveClassesGrade: (grade: string) => void
  isSavingPending: boolean
}

export const GradesModal = ({
  isOpen,
  classNameValue = '',
  onSaveClassesGrade,
  isSavingPending,
}: GradesModalProps) => {
  const t = useText()
  const localization = useSelector((state: RootState) => state.localization as ILocalization)
  const me = useSelector((state: RootState) => state.auth.data.me)

  const [grade, setGrade] = useState<string | null>(null)

  const gradeItems = useMemo(() => {
    const grades = getFullGrades(me.setting.appLanguage, localization)
    return grades.slice(1)
  }, [me, localization])

  const onSaveClick = () => {
    if (!grade) return
    onSaveClassesGrade(grade)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      hiddenCloseButton={true}
      classes={{
        Dialog: styles.GradesModalDialog,
        Modal: styles.GradesModal,
      }}
    >
      <Modal.Header className={styles.GradesModalHeader}>
        <Typography variant='h2' align='center'>
          {t.selectGrade}
        </Typography>
        <Typography variant='h8' align='center' className={styles.GradeModalSubTitle}>
          {t.assignGrade(classNameValue)}
        </Typography>
      </Modal.Header>
      <Modal.Content>
        <GradeSelect grade={grade} onGradeChanged={setGrade} gradeItems={gradeItems} />
      </Modal.Content>
      <Modal.Footer className={styles.GradesModalFooter}>
        <Button
          size={SIZE.LARGE}
          variant={BUTTON_VARIANT.PRIMARY}
          disabled={!grade}
          isLoading={isSavingPending}
          onClick={onSaveClick}
        >
          {t.saveTxt}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
