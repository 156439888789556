import { combine } from 'effector'
import { $isPinsFilterActive, updatePinState } from './isPinsFilterActive'
import { $pinsData } from './pins'
import { $isFetchingPins, $isMutatingPins } from './requests'
import {
  $heatmapSettings,
  DEFAULT_SORT_OPTION,
  HeatmapSortOptions,
  setSortOption,
} from 'features/tables/HeatmapTable/model/heatmapSettings'

export const $pinnedSolutions = combine({
  pinsData: $pinsData,
  isPinsFilterActive: $isPinsFilterActive,
  isFetchingPins: $isFetchingPins,
  isMutatingPins: $isMutatingPins,
})

const $activeMemoryKey = $pinsData.map((state) => state.currentPinsData?.memoryKey)

$activeMemoryKey.watch(() => {
  updatePinState(false)
})

const $sortOptionFactors = combine({
  currentPins: $pinsData.map((state) => state.currentPinsData?.pins),
  isPinsFilterActive: $isPinsFilterActive,
  sortOption: $heatmapSettings.map((settings) => settings.sortOption),
})

$sortOptionFactors.watch(({ currentPins, isPinsFilterActive, sortOption }) => {
  if (currentPins && isPinsFilterActive) return
  if (sortOption === HeatmapSortOptions.PINNED_SOLUTIONS) {
    setSortOption(DEFAULT_SORT_OPTION)
  }
})
