import { createEffect } from 'effector'
import { api } from '../../../../api'

const FIRST_PAGE = 1

export const fetchProblemsBreadcrumbsFx = createEffect(async (problemIds: string[]) => {
  return Promise.all(problemIds.map((problemId) => api.problems.getBreadcrumbs(problemId)))
})

export const fetchChaptersFx = createEffect(async (bookId: string) => {
  const firstPageResponse = await api.books.getAllChapters({ bookId, page: FIRST_PAGE })

  const {
    pages,
    _embedded: { chapters: initialChapters },
  } = firstPageResponse

  const nextPagesPromises = Array.from({ length: pages - 1 }, (_, index) =>
    api.books.getAllChapters({ bookId, page: FIRST_PAGE + index + 1 })
  )

  const nextPagesResponses = await Promise.all(nextPagesPromises)
  const nextPagesChapters = nextPagesResponses.flatMap((response) => response._embedded.chapters)

  const allChapters = [...initialChapters, ...nextPagesChapters].filter(
    (chapter) => chapter.sections.length > 0
  )

  return {
    bookId,
    chapters: allChapters,
  }
})

export const fetchSectionsFx = createEffect(async (chapterId: string) => {
  const firstResponse = await api.chapters.getAllSections(chapterId)

  const {
    pages,
    _embedded: { sections: initialSections },
  } = firstResponse

  const nextPagesPromises = Array.from({ length: pages - 1 }, (_, index) =>
    api.chapters.getAllSections({ chapterId, page: FIRST_PAGE + index + 1 })
  )

  const nextPagesResponses = await Promise.all(nextPagesPromises)
  const nextPagesSections = nextPagesResponses.flatMap((response) => response._embedded.sections)

  const allSections = [...initialSections, ...nextPagesSections]

  return { chapterId, sections: allSections }
})
