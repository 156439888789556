import React from 'react'
import { useSelector } from 'react-redux'

import styles from './ExampleProblemData.module.scss'

import { RootState } from '../../../../../../../store/store'
import PracticeTheory from '../../../../PracticeTheory/PracticeTheory'
import ExampleProblemInfo from './ExampleProblemInfo/ExampleProblemInfo'
import EmptyExerciseContainer from '../../../../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'

const ExampleProblemData = () => {
  const isPracticeTheoryType = useSelector((state: RootState) => {
    const { theory, drawingHistory, loading } = state.practiceTheory
    return theory && drawingHistory && !loading
  })
  const isEmptyPracticeTheory = useSelector((state: RootState) => {
    const { theory, drawingHistory, loading } = state.practiceTheory
    return !(theory && drawingHistory) && !loading
  })
  const exampleProblems = useSelector((state: RootState) => state.practiceExample.exampleProblems)
  const isExampleLoading = useSelector((state: RootState) => state.practiceExample.loading)

  if (isPracticeTheoryType) {
    return <PracticeTheory />
  }

  if (isEmptyPracticeTheory && !isExampleLoading) {
    return <ExampleProblemInfo problems={exampleProblems} />
  }

  return <EmptyExerciseContainer heightClass={styles.Loader} />
}

export default ExampleProblemData
