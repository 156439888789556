import React from 'react'

import './ListItem.scss'

interface IListItem {
  currentLanguage: string | null
  language: string | null
  selectLanguage: (language: string | null) => void
  noneLanguage?: string
}

const ListItem: React.FC<IListItem> = ({
  currentLanguage,
  language,
  selectLanguage,
  noneLanguage,
}) => {
  return (
    <div className='native-language-list-item'>
      <span
        className={`language ${currentLanguage === language ? 'active' : ''}`}
        onClick={() => selectLanguage(language || null)}
      >
        {language || noneLanguage}
      </span>
    </div>
  )
}

export default ListItem
