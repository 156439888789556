import { COLORS } from '@magmamath/ui'

export const INPUT_STYLES = {
  sx: {
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      height: '37px',
      border: `1px solid var(--neutral-4)`,
      borderRadius: '10px',
      backgroundColor: COLORS.NEUTRAL_1,
      color: COLORS.NEUTRAL_10,
      margin: 0,
      marginBottom: '9px',
      padding: '8.5px 14px',

      '&::placeholder': {
        color: COLORS.NEUTRAL_6,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
}
