import { ApiManager } from '../lib/apiManager/ApiManager'
import { config } from '../config'

const KEY = '@token_v2'
const OLD_KEY = 'token'
export const apiManager = new ApiManager(config.API_HOST, {
  tokenSettings: {
    saveKey: KEY,
    accessLifetime: Infinity,
    transitionToNew: async (currentToken) => {
      if (currentToken) {
        if (currentToken.refresh) return currentToken
        /**
         * Here should be request to exchange old token to a new one
         */
        return currentToken
      }
      const oldToken = localStorage.getItem(OLD_KEY)
      if (!oldToken) return null
      return {
        access: oldToken,
        refresh: '',
        startTime: Date.now(),
      }
    },
    refresher: async (currentToken) => {
      //request to backend to refresh token
      return currentToken
    },
  },
})
