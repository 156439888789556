export interface IPracticeSubcategory {
  _id: string
  primaryName: string
  order: number
  abilities: IPracticeAbility[]
}

export interface IPracticeCategory {
  _id: string
  order: number
  primaryName: string
  subcategories: IPracticeSubcategory[]
}

export interface IPracticeAbility {
  grades: number[]
  order: number
  primaryName: string
  _id: string
}

export interface IPractice {
  categories: IPracticeCategory[]
  openedCategory: IPracticeCategory
  filteredCategories: IPracticeCategory[]
  statistics: IStatistics
  grade: number
  activeSubcategoryId: string
}

export interface IStatistic {
  correct: number
  wrong: number
  notAnswered: number
}

export interface IStatistics {
  [key: string]: IStatistic
}

export interface IPracticeProblem {
  richDescription: string
  answer: [string]
  characterType: number
  allowSimplification: boolean
  smartEvaluation: boolean
  answerType: number
  answerFormat: boolean
}

export interface IPracticeAnswerResult {
  correct: boolean
  answer: [string]
  correctAnswer: [string]
  type: number
  historyId: string
  drawingImageName: string
  handwritingImage: string
  strokeCount: number
  recognitionUsed: boolean
  createdAt: string
  user: string
  userType: number
  _id: string
  problem: IPracticeProblem
}

export enum ChangeGradeActions {
  DECREASE = 0,
  INCREASE = 1,
}