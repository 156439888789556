import { IStudentStat } from 'api/types.solutions'
import {
  COMPRESSED_SINGLE_ROW_HEIGHT,
  SINGLE_ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_LIST_PADDING_BOTTOM,
} from '../constants'
import { CellColorClassNames } from '../types'

type GetCellColorClassNameProps = {
  isMarkedAsCorrect: boolean
  studentStats: IStudentStat | null
}

const getCellColorClassName = ({
  isMarkedAsCorrect,
  studentStats,
}: GetCellColorClassNameProps): CellColorClassNames => {
  if (!studentStats) return CellColorClassNames.DEFAULT
  const { stats } = studentStats

  const isAnyAttemptCorrectBeforeLast = !!stats.answerArray
    ?.slice(0, -1)
    .some((attempt) => attempt.correct)
  const isIncorrect =
    !isMarkedAsCorrect &&
    stats.solved === 0 &&
    stats.attempt !== 0 &&
    !isAnyAttemptCorrectBeforeLast

  if (isIncorrect) return CellColorClassNames.INCORRECT

  const areAllAttemptsCorrect = !!(
    stats.answerArray?.length && stats.answerArray.every((attempt) => attempt.correct)
  )
  const isCorrectFirstAttempt =
    areAllAttemptsCorrect || (stats.solved === 1 && stats.solvedOnFirstAttempt === 1)
  const isCorrectAfterFirstAttempt =
    isMarkedAsCorrect && (stats.attempt === 1 || stats.solvedOnFirstAttempt === 1)

  if (isCorrectFirstAttempt || isCorrectAfterFirstAttempt)
    return CellColorClassNames.CORRECT_FIRST_TRY

  const isCorrectMultipleAttempts = stats.solved === 1 && stats.solvedOnFirstAttempt !== 1
  const isCorrectedAfterSubsequentAttempts =
    isMarkedAsCorrect && (stats.attempt > 1 || stats.solvedOnLast === 1)

  if (
    isCorrectMultipleAttempts ||
    isCorrectedAfterSubsequentAttempts ||
    isAnyAttemptCorrectBeforeLast
  )
    return CellColorClassNames.CORRECT_NOT_FIRST_TRY

  return CellColorClassNames.DEFAULT
}

type GetHeaderCellPercentProps = {
  studentStats: IStudentStat[]
  divider?: number
}

const getHeaderCellPercent = ({ studentStats, divider }: GetHeaderCellPercentProps) => {
  const statsCount = getSolvedStatistics(studentStats)
  return statsCount && divider ? Math.round((statsCount / divider) * 100) : 0
}

export const getSolvedStatistics = (studentStats: IStudentStat[]) =>
  studentStats.reduce(
    (accumulator, studentStat) =>
      studentStat.isTeacher ? accumulator : studentStat.stats.solved + accumulator,
    0
  )

const isWatchedIconVisibleForColorClass = (cellColorClassName: CellColorClassNames) => {
  const classesWhenShow = [CellColorClassNames.INCORRECT, CellColorClassNames.CORRECT_NOT_FIRST_TRY]
  return classesWhenShow.includes(cellColorClassName)
}

type GetTableListHeightProps = {
  numberOfUsers: number
  isCompressed: boolean
}

const getTableListHeight = ({ numberOfUsers, isCompressed }: GetTableListHeightProps) => {
  const singleRowHeight = isCompressed ? COMPRESSED_SINGLE_ROW_HEIGHT : SINGLE_ROW_HEIGHT
  return (
    Math.ceil(numberOfUsers * singleRowHeight) + TABLE_HEADER_HEIGHT + TABLE_LIST_PADDING_BOTTOM
  )
}

export {
  getCellColorClassName,
  getHeaderCellPercent,
  getTableListHeight,
  isWatchedIconVisibleForColorClass,
}
