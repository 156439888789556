import React from 'react'
import { SvgIconProps } from '../types/types'

const AttentionOutlinedIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_590_7266)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 2.2C14.584 2.2 18.3 5.91604 18.3 10.5C18.3 15.084 14.584 18.8 10 18.8C5.41604 18.8 1.7 15.084 1.7 10.5C1.7 5.91604 5.41604 2.2 10 2.2ZM10 0.5C15.5228 0.5 20 4.97715 20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5Z'
          fill={color}
        />
        <path
          d='M11.2 15.3C11.2 14.6373 10.6627 14.1 10 14.1C9.33726 14.1 8.8 14.6373 8.8 15.3C8.8 15.9627 9.33726 16.5 10 16.5C10.6627 16.5 11.2 15.9627 11.2 15.3Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 12.5333C10.6627 12.5333 11.2 11.996 11.2 11.3333V5.91663C11.2 5.25389 10.6627 4.71663 10 4.71663C9.33726 4.71663 8.8 5.25389 8.8 5.91663V11.3333C8.8 11.996 9.33726 12.5333 10 12.5333Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default AttentionOutlinedIcon
