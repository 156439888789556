import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const TextButton = styled(Button)({
  color: '#8F8F8F',
  border: '1px solid var(--neutral-1)',
  minWidth: '100px',
  height: '37px',
  '&:hover': {
    border: '1px solid #5CD397',
    transition: '0.2s',
  },
})
