import { useAnimationsContext } from 'features/ExercisesList/contexts/AnimationsContext/AnimationsContext'
import { EXERCISE_CARD_ANIMATION_DURATION } from 'features/ExercisesList/contexts/AnimationsContext/animationConfig'
import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { AssignmentFilterBadges } from 'features/ExercisesList/types/types'
import { LayoutIds } from 'helpers/enums'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../../../interfaces/state.interface'
import { bulkUpdateExercisesStatuses } from '../../../../redux/modules/exercises.module'
import {
  EXERCISES_STATUS,
  getArchived,
  getAvailable,
  getUnpublished,
} from '../../../../redux/modules/exercisesStatuses.module'
import { RootState } from '../../../../store/store'
import FilterLink from '../../../../ui/links/FilterLink/FilterLink'
import styles from './AssignmentsFilter.module.scss'
import UITabs from '../../../../ui/tabs/UITabs/UITabs'

import { GetAllAssignmentsResponse } from '../../../../api/types.assignments'

interface IAssignmentsFilterProps {
  exercises: IState<GetAllAssignmentsResponse | null>
  onAssignmentStatusChange: React.Dispatch<React.SetStateAction<boolean>>
  filterBadges: AssignmentFilterBadges
  onBulkUpdate: React.Dispatch<React.SetStateAction<AssignmentFilterBadges>>
}

const AssignmentsFilter: React.FC<IAssignmentsFilterProps> = ({
  exercises,
  onAssignmentStatusChange,
  onBulkUpdate,
  filterBadges,
}) => {
  const dispatch = useDispatch()
  const t = useSelector((state: RootState) => state.localization.data)
  const { handleAssignmentsAnimation, isAnimating } = useAnimationsContext()
  const {
    changeBulkModeState,
    selectedExercises,
    areBulkActionButtonsActive,
    changeIsBulkUpdating,
  } = useBulkSelectContext()
  const exercisesStatuses = useSelector((state: RootState) => state.exercisesStatuses.data)
  const { unpublished, archived, available } = exercisesStatuses

  const handleBulkAction = async (filterStatus: EXERCISES_STATUS) => {
    handleAssignmentsAnimation(exercisesStatuses, filterStatus)
    changeIsBulkUpdating(true)
    dispatch(bulkUpdateExercisesStatuses(selectedExercises, filterStatus, t.networkError))
    setTimeout(() => {
      onBulkUpdate((prevState) => ({
        ...prevState,
        [filterStatus]: prevState[filterStatus] + selectedExercises.length,
      }))
    }, EXERCISE_CARD_ANIMATION_DURATION * 1800)
    changeBulkModeState(false)
  }

  const changeExerciseStatuses = (callback: () => void) => {
    if (exercises.loading) {
      return
    }
    onAssignmentStatusChange(true)

    dispatch(callback())
  }

  const onFilterLinkClick = (
    filterStatus: EXERCISES_STATUS,
    changeExerciseStatusCallback: () => void
  ) => {
    if (exercisesStatuses[filterStatus.toLowerCase()]) {
      return
    }

    if (areBulkActionButtonsActive) return handleBulkAction(filterStatus)

    onBulkUpdate((prevState) => ({
      ...prevState,
      [filterStatus]: 0,
    }))
    return changeExerciseStatuses(changeExerciseStatusCallback)
  }

  return (
    <UITabs
      layoutId={LayoutIds.EXERCISES_FILTER_TABS}
      withDividerBottom={false}
      className={styles.AssignmentsFilter}
      data-testid='assignment-status-tabs'
    >
      <FilterLink
        activeLink={unpublished}
        title={t.unpublishedTxt}
        onClick={() => onFilterLinkClick(EXERCISES_STATUS.UNPUBLISHED, getUnpublished)}
        badgeNumber={filterBadges.UNPUBLISHED}
        isActionButtonActive={areBulkActionButtonsActive}
        actionButtonTitle={t.unpublishTxt}
        data-testid='unpublished'
      />
      <FilterLink
        activeLink={available}
        title={t.availableTxt}
        onClick={() => onFilterLinkClick(EXERCISES_STATUS.AVAILABLE, getAvailable)}
        badgeNumber={filterBadges.AVAILABLE}
        isActionButtonActive={areBulkActionButtonsActive}
        actionButtonTitle={t.publishTxt}
        data-testid='available'
      />
      <FilterLink
        activeLink={archived}
        title={t.archivedTxt}
        onClick={() => onFilterLinkClick(EXERCISES_STATUS.ARCHIVED, getArchived)}
        badgeNumber={filterBadges.ARCHIVED}
        isActionButtonActive={areBulkActionButtonsActive}
        actionButtonTitle={t.archiveTxt}
        data-testid='archived'
      />
    </UITabs>
  )
}

export default AssignmentsFilter
