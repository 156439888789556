import React from 'react'
import styles from './PracticeBody.module.scss'
import PracticeCategory from '../PracticeCategory/PracticeCategory'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { IStudent } from '../../../../api/types.users'
import EmptyExerciseContainer from '../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import { IPracticeCategory } from 'api/types.oldPractice'

type PracticeBodyProps = {
  selectedStudents: IStudent[] | []
}

const PracticeBody = ({ selectedStudents }: PracticeBodyProps) => {
  const isLoading = useSelector((state: RootState) => state.practice.loading)
  const practiceCategories = useSelector((state: RootState) => state.practice.filteredCategories)
  const grade = useSelector((state: RootState) => state.practice.grade)
  const statistics = useSelector((state: RootState) => state.practice.statistics)

  if (isLoading) {
    return <EmptyExerciseContainer heightClass={styles.Loader} />
  }

  return (
    <div className={styles.PracticePageBody}>
      {practiceCategories.map((category: IPracticeCategory) => (
        <PracticeCategory
          category={category}
          grade={grade}
          statistics={statistics}
          selectedStudents={selectedStudents}
          key={category._id}
        />
      ))}
    </div>
  )
}

export default PracticeBody
