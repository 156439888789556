import UnitAnswer from '../UnitAnswer/UnitAnswer'
import ContentEditableArea from '../../../../../ui/inputs/ContentEditableArea/ContentEditableArea'
import katex from 'katex'
import styles from '../NumericAnswer.module.scss'
import React from 'react'

type OtherNumericAnswerProps = {
  value: string
  selectedValue: string | null
}
const OtherNumericAnswer = ({ value, selectedValue }: OtherNumericAnswerProps) => {
  return (
    <>
      <ContentEditableArea value={katex.renderToString(value)} disabled className={styles.Title} />
      <UnitAnswer selectedValue={selectedValue} />
    </>
  )
}
export default OtherNumericAnswer
