import React from 'react'
import { SvgIconProps } from '../types/types'

const ArrowUpSelectIcon = ({ color, className, size = 12 }: SvgIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    transform='rotate(180)'
    className={className}
  >
    <path
      d='M1.37493 0.784913L6.71493 6.12491L12.0549 0.784912'
      stroke={color}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowUpSelectIcon
