import React from 'react'

import styles from './ExampleProblemInfo.module.scss'

import { IPracticeProblem } from '../../../../../../../api/types.practice'
import Markdown from '../../../../../../../components/RichDescription/RichDescription.component'

type ExampleProblemInfoProps = {
  problems: IPracticeProblem[]
}

const ExampleProblemInfo = ({ problems }: ExampleProblemInfoProps) => {
  if (!problems.length) return null

  return (
    <div className={styles.ExampleProblemsContainer}>
      {problems.map((problem, index) => {
        return (
          <div className={styles.ExampleProblem} key={index}>
            <span className={styles.ProblemNumber}>{index + 1}. </span>
            <Markdown>{problem.richDescription}</Markdown>
          </div>
        )
      })}
    </div>
  )
}

export default ExampleProblemInfo
