import { sessionStorageKeys } from 'config/localStorageKeys'
import { persist } from 'effector-storage/session'
import { SortOptionModel } from './sortOptionModel'

export const savedSortOptionModel = new SortOptionModel()

persist({
  store: savedSortOptionModel.$state,
  key: sessionStorageKeys.cafSavedSortOption,
})
