import MultipleChoiceAnswer from '../MultipleChoiceAnswer/MultipleChoiceAnswer'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

const MultipleChoicePreview = () => {
  const multipleChoice = useSelector((state: RootState) => state.problemCreatorData.multipleChoice)
  return <MultipleChoiceAnswer items={multipleChoice} />
}
export default MultipleChoicePreview
