import React, { forwardRef, RefObject, useEffect, useImperativeHandle, useRef } from 'react'
import {
  ChangedProps,
  StaticInputValueMiddleware,
  StaticMathInputModel,
} from './StaticMathInputModel'
import styles from './stylesStaticInput.module.scss'
import clsx from 'clsx'
import { useConst } from '../../../../lib/hooks/const'
import { COLORS } from '@magmamath/ui'

type MathInputProps = {
  className?: string
  onChange?: (props: ChangedProps) => void
  value?: string
  initialValue?: string
  placeholder?: string
  autofocus?: boolean
  valueMiddleware?: StaticInputValueMiddleware
}

export type MathInputRef = {
  model: StaticMathInputModel
}

const StaticMathInput = forwardRef(
  (
    {
      autofocus,
      className,
      value,
      onChange,
      initialValue,
      placeholder = '',
      valueMiddleware,
    }: MathInputProps,
    ref: RefObject<MathInputRef>
  ) => {
    const model = useConst(() => new StaticMathInputModel({ initialValue, valueMiddleware }))
    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange

    useEffect(() => {
      return model.changed.watch((props) => {
        onChangeRef.current?.(props)
      })
    }, [model])

    useEffect(() => {
      if (value === undefined) return
      model.setValue(value)
    }, [model, value])

    useEffect(() => {
      model.setPlaceholder(placeholder)
    }, [placeholder, model])

    useEffect(() => {
      if (autofocus) model.focus()
    }, [autofocus])

    useImperativeHandle(ref, () => ({ model }))
    return (
      <span
        className={clsx(styles.MathInput, styles.WithText, className)}
        ref={model.setUp}
        style={{ color: !initialValue ? COLORS.NEUTRAL_6 : COLORS.NEUTRAL_9 }}
      />
    )
  }
)

export default StaticMathInput
