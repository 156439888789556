import React from 'react'
import { SvgIconProps } from '../types/types'

const FlagIcon = ({ size = 15, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 10 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.21163 1.98521V7.36804C1.604 7.21136 2.08303 7.10002 2.6339 7.10002C3.08986 7.10002 3.55708 7.25431 3.98262 7.44001C4.40928 7.6262 4.8519 7.87088 5.26488 8.09916L5.27835 8.10661C5.70657 8.34332 6.10399 8.56244 6.47547 8.72456C6.85272 8.88919 7.14603 8.96845 7.3661 8.96845C8.04749 8.96845 8.54771 8.56936 8.78837 8.27716V2.89622C8.41418 3.04705 7.93976 3.16312 7.3661 3.16312C6.34145 3.16312 5.36146 2.60273 4.58353 2.15009C4.53843 2.12385 4.49394 2.0979 4.45002 2.07228C4.09133 1.86305 3.77049 1.6759 3.46338 1.53244C3.11781 1.37102 2.84895 1.2947 2.6339 1.2947C1.94155 1.2947 1.43985 1.70809 1.21163 1.98521ZM1.21163 8.80445C1.46119 8.63269 1.94901 8.39472 2.6339 8.39472C2.85397 8.39472 3.14728 8.47398 3.52453 8.63861C3.89601 8.80072 4.29343 9.01985 4.72165 9.25656L4.73512 9.264C5.1481 9.49229 5.59072 9.73696 6.01738 9.92315C6.44292 10.1089 6.91014 10.2631 7.3661 10.2631C8.57257 10.2631 9.4093 9.52341 9.77829 9.03023C9.94414 8.80857 10 8.55121 10 8.33218V1.90504C10 1.57589 9.79947 1.35234 9.60685 1.26046C9.41653 1.16968 9.14218 1.16111 8.91725 1.35578C8.76386 1.48853 8.25008 1.86843 7.3661 1.86843C6.70068 1.86843 5.9906 1.4946 5.16296 1.01304C5.11891 0.987406 5.07448 0.961462 5.02972 0.93533C4.67884 0.730474 4.30811 0.514031 3.9487 0.346142C3.5443 0.157236 3.09488 0 2.6339 0C1.2883 0 0.397862 0.918127 0.0965674 1.41776L0 1.5779V13.3527C0 13.7102 0.271233 14 0.605816 14C0.940399 14 1.21163 13.7102 1.21163 13.3527V8.80445Z'
        fill={color}
      />
    </svg>
  )
}

export default FlagIcon
