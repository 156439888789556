import React from 'react'
import { useUnit } from 'effector-react'
import { ProblemLevel } from '@magmamath/ui'
import { getProblemLevelColumns } from '../../helpers'
import { getProblemImageUrl } from 'features/heatmap/Problem/helpers/imageUrl'
import useText from 'i18n/hook'
import { $selectedProblems, selectAllByLevel, selectProblem } from '../../../model/selectedProblems'
import { changeExerciseMode } from 'features/caf/exerciseSettings/model/exerciseSettings'
import styles from './ProblemLevels.module.scss'
import { PROBLEM_LEVELS } from '../../types'
import { IProblem } from '../../../../../../api/types.problem'

type ProblemLevelsProps = {
  sectionId: string
  problemsByLevel: Map<PROBLEM_LEVELS, IProblem[]>
}

const ProblemLevels = ({ problemsByLevel, sectionId }: ProblemLevelsProps) => {
  const t = useText()
  const selectedProblems = useUnit($selectedProblems)

  const easyProblemsCount = problemsByLevel.get(PROBLEM_LEVELS.EASY)?.length || 0
  const mediumProblemsCount = problemsByLevel.get(PROBLEM_LEVELS.MEDIUM)?.length || 0
  const sectionSelectedProblems = selectedProblems.get(sectionId) || new Set()

  const handleSelectAll = (level: PROBLEM_LEVELS) => {
    const problems = problemsByLevel.get(level)
    if (!problems) return

    changeExerciseMode(sectionId)
    selectAllByLevel({ sectionId, problemIds: problems.map((problem) => problem._id) })
  }

  const handleSelectProblem = (problemId: string) => {
    changeExerciseMode(sectionId)
    selectProblem({ sectionId, problemId })
  }
  const levels = [
    {
      level: PROBLEM_LEVELS.EASY,
      label: t.easyLevel,
    },
    {
      level: PROBLEM_LEVELS.MEDIUM,
      label: t.mediumLevel,
      numerationFrom: easyProblemsCount > 0 ? easyProblemsCount + 1 : 1,
    },
    {
      level: PROBLEM_LEVELS.HARD,
      label: t.hardLevel,
      numerationFrom:
        easyProblemsCount > 0 || mediumProblemsCount > 0
          ? easyProblemsCount + mediumProblemsCount + 1
          : 1,
    },
  ]
  return (
    <div className={styles.ProblemLevels}>
      {levels.map(({ level, label, numerationFrom }) => {
        if (!problemsByLevel.get(level)) {
          return null
        }

        return (
          <ProblemLevel
            key={level}
            level={level}
            selectedProblems={sectionSelectedProblems}
            onSelectAll={() => handleSelectAll(level)}
            onSelectProblem={(problemId: string) => handleSelectProblem(problemId)}
            className={styles.ProblemLevel}
            levelLabel={label}
            problems={problemsByLevel.get(level)}
            numerationFrom={numerationFrom}
            numberOfColumns={getProblemLevelColumns(problemsByLevel, level)}
            imageLinkGetter={getProblemImageUrl}
          />
        )
      })}
    </div>
  )
}

export default ProblemLevels
