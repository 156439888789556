import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { IQueryParams } from 'interfaces/params.interface'

import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'

// Countries constants
export enum COUNTRIES {
  COUNTRIES_REQUEST = 'COUNTRIES_REQUEST',
  COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS',
  COUNTRIES_ERROR = 'COUNTRIES_ERROR',
}

// Countries reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}
export function countriesReducer(state: any = initialState, action: IAction<COUNTRIES>) {
  switch (action.type) {
    case COUNTRIES.COUNTRIES_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case COUNTRIES.COUNTRIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case COUNTRIES.COUNTRIES_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function getCountries(localization: ILocalization, paramsFromClient?: IQueryParams) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: COUNTRIES.COUNTRIES_REQUEST })
      const res = await api.countries.getAll(paramsFromClient)
      dispatch({ type: COUNTRIES.COUNTRIES_SUCCESS, payload: res })
    } catch (error) {
      const anotherErrorMessages: string = !!error.response
        ? error.response.data.message
        : error.message
      const networkErrorMessage: string = localization.data.networkError
      const errorMessage: string =
        navigator.onLine && error.message !== 'Network Error'
          ? anotherErrorMessages
          : networkErrorMessage
      dispatch({
        payload: errorMessage,
        type: COUNTRIES.COUNTRIES_ERROR,
      })
    }
  }
}

export interface ICountriesActions {
  getCountries: (localization: ILocalization, paramsFromClient?: IQueryParams) => void
}
