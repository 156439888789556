import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import _ from 'lodash'
import { addLocale } from '../../config/modules.config'
import { IBook } from '../../api/types.books'
import { api } from '../../api'

// Books names from series constants
export enum BOOK_SERIES_NAMES {
  BOOK_SERIES_NAMES_REQUEST = 'BOOK_SERIES_NAMES_REQUEST',
  BOOK_SERIES_NAMES_SUCCESS = 'BOOK_SERIES_NAMES_SUCCESS',
  BOOK_SERIES_NAMES_ERROR = 'BOOK_SERIES_NAMES_ERROR',
  BOOK_SERIES_NAMES_CHANGE_SHOW_TO_STATUS = 'BOOK_SERIES_NAMES_CHANGE_SHOW_TO_STATUS',
  BOOK_SERIES_NAMES_CLEAR = 'BOOK_SERIES_NAMES_CLEAR',
}

// Books names from series reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}

export function bookSeriesNamesReducer(
  state: any = initialState,
  action: IAction<BOOK_SERIES_NAMES>
) {
  switch (action.type) {
    case BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_SUCCESS:
      const oldBookNames = state.data?.items || []
      const newBooksNames = action.payload?.items || []
      const resBooksNames = _.uniqBy([...oldBookNames, ...newBooksNames], '_id')
      return {
        data: { ...action.payload, items: [...resBooksNames] },
        error: null,
        loading: false,
      }
    case BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_CHANGE_SHOW_TO_STATUS:
      const stateCopy = { ...state }
      const changedState = stateCopy.data.items.map((bookSeriesName: IBook) => {
        return bookSeriesName._id === action.payload.id
          ? { ...bookSeriesName, showTo: !bookSeriesName.showTo }
          : bookSeriesName
      })
      return {
        ...stateCopy,
        data: { items: changedState },
        error: null,
        loading: false,
      }
    case BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_CLEAR:
      return {
        data: {},
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

function getBooksNamesParams(limit: number, offset: number, bookSeriesIds?: string[]) {
  const params: {
    bookSeriesIds?: string
    limit: number
    offset: number
  } = {
    limit,
    offset,
  }
  if (bookSeriesIds) {
    params.bookSeriesIds = _.join(bookSeriesIds, ',')
  }
  return params
}

// Books names from series actions

//TODO: it looks like exactly the same function as we already have, pls check where api.books.getByBookSeries is used
const getPartialBooks = async (
  offset = 0,
  dispatch: IDispatch<any>,
  bookSeriesIds?: string[]
): Promise<void> => {
  const limit: number = 20
  const books: IBook[] = []
  dispatch({ type: BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_REQUEST })

  const getBooks = async (
    offset = 0,
    dispatch: IDispatch<any>,
    bookSeriesIds?: string[]
  ): Promise<void> => {
    const params = getBooksNamesParams(limit, offset, bookSeriesIds)
    const res = await api.books.getByBookSeries(params)
    books.push(...res.items)
    if (res.items.length === limit) {
      return getBooks(offset + (limit - 1), dispatch, bookSeriesIds)
    }
  }
  await getBooks(offset, dispatch, bookSeriesIds)
  dispatch({
    type: BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_SUCCESS,
    payload: { limit, offset, items: books },
  })
}

export function reloadBookSeriesNames(bookSeriesIds?: string[]) {
  addLocale()
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_CLEAR })
      getPartialBooks(0, dispatch, bookSeriesIds)
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_ERROR,
      })
    }
  }
}

export function clearBookSeriesNames() {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: BOOK_SERIES_NAMES.BOOK_SERIES_NAMES_CLEAR })
  }
}

export interface IBookSeriesNames {
  items: IBook[]
}
