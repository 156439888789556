import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'

// Notify constants
export enum NOTIFY {
  NOTIFY_BEGIN = 'NOTIFY_BEGIN',
  NOTIFY_END = 'NOTIFY_END',
}

// Notify reducer
const initialState = {
  data: {
    message: null,
    success: false,
    type: undefined,
  },
  error: null,
  loading: false,
}
export function notifyReducer(state: any = initialState, action: IAction<NOTIFY>) {
  switch (action.type) {
    case NOTIFY.NOTIFY_BEGIN:
      return {
        data: {
          ...state.data,
          success: true,
          message: action.payload.message,
          type: action.payload.type,
        },
        error: false,
        loading: false,
      }
    case NOTIFY.NOTIFY_END:
      return {
        data: { ...state.data, success: false, message: null, type: undefined },
        error: false,
        loading: false,
      }
    default:
      return state
  }
}

export function callNotification(text: string, typeOfMessage: string) {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: NOTIFY.NOTIFY_BEGIN, payload: { message: text, type: typeOfMessage } })
    dispatch({ type: NOTIFY.NOTIFY_END })
  }
}

export interface INotify {
  data: {
    message: string
    success: boolean
    type: any
  }
}
