import React from 'react'
import StudentsOption from './Option/StudentsOption'
import { OptionsType } from './StudentsFilter'
import { Listbox } from '@magmamath/ui'
import { InitialOptionType } from './types'

type PracticePageProps = {
  students: OptionsType[]
  allStudents: InitialOptionType
}

const PracticePageFilterOptions = ({ students, allStudents }: PracticePageProps) => {
  return (
    <>
      <Listbox.Option key={allStudents.value} value={allStudents}>
        {allStudents.name}
      </Listbox.Option>
      <StudentsOption students={students} iShowTitle={false} />
    </>
  )
}

export default PracticePageFilterOptions
