import { createEffect } from 'effector'
import { CreateSectionRequestFxProps, UpdateChapterFxProps } from '../types'
import { LocaleData } from 'helpers/enums'
import { api } from '../../../api'

export const fetchBooksFx = createEffect(() =>
  api.books.getMyBook({
    fetchAll: 1,
    isInitial: 1,
  })
)

export const createSectionRequestFx = createEffect(
  async ({ name, appLanguage }: CreateSectionRequestFxProps) => {
    return api.sections.createNew({
      isInitial: true,
      name,
      order: 13,
      locale: appLanguage === LocaleData.SE ? LocaleData.SE : LocaleData.US,
    })
  }
)

export const deleteSectionRequestFx = createEffect(api.sections.deleteSingle)
export const editSectionRequestFx = createEffect(api.sections.updateSingle)

export const updateChapterFx = createEffect(async ({ id, sectionIds }: UpdateChapterFxProps) => {
  return api.chapters.updateSingle({
    id,
    data: { sections: sectionIds.join(',') },
  })
})

export const deleteProblemRequestFx = createEffect(api.problems.deleteSingle)
