import React, { useCallback, useEffect, useState } from 'react'

import './BookSeries.view.scss'

import loadable from '@loadable/component'
import _, { uniq, uniqBy } from 'lodash'

import {
  IBookSeriesNames,
  reloadBookSeriesNames,
} from 'redux/modules/books-names.book-series.module'

import { ContentBooks } from '../../../helpers/enums'
import { IState } from 'interfaces/state.interface'
import { INotify } from 'redux/modules/notify.module'
import { IMe } from 'api/types.users'
import { IBookSeries, IBookSeriesActions } from 'redux/modules/book-series'
import { IMyBooksActions } from '../../../redux/modules/my-books'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import PanelHeader from 'components/PanelHeader/PanelHeader.component'
import BookSeriesCard from './BookSeriesCards/BookSeriesCard.component'
import { currentLocale } from '../../../config/modules.config'
import { sortMyBooksByOrder } from '../../../helpers/sort.helpers'
import { ILocalization } from '../../../config/languages.config'
import Spinner from '../../../features/Spinner/Spinner'
import BooksFilter from '../../../features/filters/BooksFilter/BooksFilter'
import { useDispatch } from 'react-redux'
import { getBookSeriesIds } from './fetchBookSeruesNames'
import { IMyBooks } from '../../../api/types.books'
import { BookSeriesData } from '../../../api/types.bookSeries'

const Notification = loadable(() => import('components/Notification/Notification.component'))

export interface IBookSeriesProps {
  history: any
  me: IMe
  localization: ILocalization
  notification: INotify
  bookSeries: IState<IBookSeries>
  bookSeriesNames: IState<IBookSeriesNames>
  bookSeriesActions: IBookSeriesActions
  myBooksActions: IMyBooksActions
  myBooks: IState<IMyBooks>
}

const BookSeries: React.FC<IBookSeriesProps> = ({
  history,
  me,
  localization,
  notification,
  bookSeries,
  bookSeriesNames,
  bookSeriesActions,
  myBooksActions,
  myBooks,
}) => {
  const currentLang = currentLocale.currentLang

  const [filteredBooksSeries, setFilteredBooksSeries] = useState<BookSeriesData[]>([])
  const dispatch = useDispatch()
  const filterBookSeries = useCallback(
    (allBookSeries: BookSeriesData[], currentBookSeries: string[] | undefined) => {
      return allBookSeries.filter(
        (bookSeriesItem: BookSeriesData) =>
          currentBookSeries && currentBookSeries!.includes(bookSeriesItem._id)
      )
    },
    []
  )

  const bookSeriesFromMyCountry = me?.country.bookSeries
  const bookSeriesFromMyRegion = me?.region.bookSeries
  const bookSeriesFromMyMunicipality = me?.municipality.bookSeries
  const bookSeriesTeacher = me?.bookSeries
  const bookSeriesFromMySchool = me?.school?.bookSeries

  const getBookSeries = async (offset = 0) => {
    const limit: number = 20
    const seriesIdsByCountry: string[] = me?.country?.bookSeries || []
    const seriesIdsByRegion: string[] = me?.region?.bookSeries || []
    const seriesIdsByMunicipality: string[] = me?.municipality?.bookSeries || []
    const seriesIdsByTeacher: string[] = me?.bookSeries || []
    const seriesIdsBySchool: string[] = me?.school?.bookSeries || []
    const seriesIds = uniq([
      ...seriesIdsByCountry,
      ...seriesIdsByRegion,
      ...seriesIdsByMunicipality,
      ...seriesIdsByTeacher,
      ...seriesIdsBySchool,
    ])

    bookSeriesActions.getBookSeries(localization, offset, seriesIds)
  }

  useEffect(() => {
    const bookSeriesIsExist: boolean =
      !_.isEmpty(bookSeries.data) && !_.isEmpty(bookSeries.data.bookSeries)
    if (!bookSeriesIsExist) {
      return
    }

    const filteredBookSeriesByCountry: BookSeriesData[] = filterBookSeries(
      bookSeries.data.bookSeries,
      me.country.bookSeries
    )
    const filteredBookSeriesByRegion: BookSeriesData[] = filterBookSeries(
      bookSeries.data.bookSeries,
      me.region.bookSeries
    )
    const filteredBookSeriesByMunicipality: BookSeriesData[] = filterBookSeries(
      bookSeries.data.bookSeries,
      me?.municipality.bookSeries
    )
    const filteredBookSeriesByTeacher: BookSeriesData[] = filterBookSeries(
      bookSeries.data.bookSeries,
      me?.bookSeries
    )
    const filteredBookSeriesBySchool: BookSeriesData[] = filterBookSeries(
      bookSeries.data.bookSeries,
      me?.school?.bookSeries
    )
    const filteredBooks = uniqBy(
      [
        ...filteredBookSeriesByCountry,
        ...filteredBookSeriesByRegion,
        ...filteredBookSeriesByMunicipality,
        ...filteredBookSeriesByTeacher,
        ...filteredBookSeriesBySchool,
      ],
      '_id'
    ).sort(sortMyBooksByOrder)

    setFilteredBooksSeries(filteredBooks)
  }, [
    bookSeries,
    JSON.stringify(bookSeriesFromMyCountry),
    JSON.stringify(bookSeriesFromMyRegion),
    JSON.stringify(bookSeriesFromMyMunicipality),
    JSON.stringify(bookSeriesTeacher),
    JSON.stringify(bookSeriesFromMySchool),
  ])

  useEffect(() => {
    if (!currentLang) {
      return
    }

    bookSeriesActions.clearBookSeries()
    getBookSeries()
    dispatch(reloadBookSeriesNames(getBookSeriesIds(me)))
    myBooksActions.getMyBooks(localization)
  }, [currentLang])

  const renderBookSeriesCards = (bookType: ContentBooks): JSX.Element | null => {
    if (
      bookType === ContentBooks.addBook &&
      _.isEmpty(bookSeriesNames.data?.items) &&
      !bookSeriesNames.loading
    ) {
      return <p className='no-books-available-txt'>{localization.data.bookSeriesEmptyPageTxt}</p>
    }

    if (bookType === ContentBooks.myBook && _.isEmpty(myBooks.data?.books) && !myBooks.loading) {
      return <p className='no-my-books-txt'>{localization.data.emptyMyBooksTxt}</p>
    }

    const myBooksToShow = (myBooks?.data?.books || []).sort(sortMyBooksByOrder)
    const availableBooksToAdd = bookSeriesNames?.data?.items || []

    const availableBooksToShow = _.orderBy(
      availableBooksToAdd.filter((book) => {
        return !myBooksToShow.find((myBook) => myBook._id === book._id)
      }),
      ['order', 'name'],
      ['asc', 'asc']
    )

    if (bookType === ContentBooks.addBook && !availableBooksToShow.length) {
      return (
        <p className='no-books-available-txt'>{localization.data.allBooksFromSeriesAddedTxt}</p>
      )
    }

    const isLoading =
      (bookType === ContentBooks.myBook && myBooks.loading) ||
      (bookType === ContentBooks.addBook && bookSeriesNames.loading)

    return (
      <>
        {isLoading ? <Spinner className='loader' /> : null}
        <BookSeriesCard
          localization={localization}
          books={bookType === ContentBooks.addBook ? availableBooksToShow : myBooksToShow}
          myBookActions={myBooksActions}
          me={me}
          bookType={bookType}
          loading={isLoading}
        />
      </>
    )
  }

  const teacherHasBooksToAdd: boolean = !_.isEmpty(bookSeriesNames.data?.items)
  const teacherHasBooks: boolean = !_.isEmpty(myBooks.data?.books)

  return (
    <>
      <PanelHeader
        backButton={false}
        title=' '
        className='content-header'
        history={history}
        location={history.location}
        localization={localization}
      />
      <ViewContainer className='content-component-view' history={history}>
        <div className='book-container'>
          <div className='book-series-cards-wrapper'>
            <div className='book-series-header'>
              <span className='book-series-title'>{localization.data.booksTxt}</span>
              <div className='book-series-selector'>
                {!!currentLang && <BooksFilter booksSeriesData={filteredBooksSeries} />}
              </div>
            </div>
            <div className='book-series-card-wrapper'>
              {!teacherHasBooksToAdd && bookSeriesNames.loading ? (
                <Spinner className='loader' />
              ) : (
                renderBookSeriesCards(ContentBooks.addBook)
              )}
            </div>
          </div>
          <div className='book-series-my-books'>
            <div className='my-books-header'>
              <span className='my-books-title'>{localization.data.myListTxt}</span>
            </div>
            <div className='my-books-container'>
              {!teacherHasBooks && myBooks.loading ? (
                <Spinner className='loader' />
              ) : (
                renderBookSeriesCards(ContentBooks.myBook)
              )}
            </div>
          </div>
        </div>
      </ViewContainer>
      <div className='notification'>
        <Notification
          notification={notification as INotify}
          history={history}
          localization={localization as any}
        />
      </div>
    </>
  )
}

export default BookSeries
