import React from 'react'
import { AccordionListItem, COMPONENT_COLOR, Checkbox, SIZE, COLORS } from '@magmamath/ui'
import { Bredcrumb } from '../types'
import styles from './../SectionsList/SectionsList.module.scss'
import { ISection } from 'api/types.books'
import ArrowIcon from 'ui/icons/ArrowIcon/ArrowIcon'
import clsx from 'clsx'
import ProblemsCount from '../ProblemsCount/ProblemsCount'
import { PROBLEM_LEVELS } from '../SectionProblems/types'
import { removeSelectedProblems, selectAllBySection } from '../model/selectedProblems'
import { IProblem } from 'api/types.problem'

type SearchSectionsListItemProps = {
  section: ISection
  onClickSection: (section: Bredcrumb) => void
  checkboxColor?: COMPONENT_COLOR
  selectedProblems: Map<string, Set<string>>
  problems: Map<string, IProblem[]>
  openedSection?: Bredcrumb
}

const SearchSectionsListItem = ({
  section,
  onClickSection,
  selectedProblems,
  problems,
  openedSection,
  checkboxColor
}: SearchSectionsListItemProps) => {
  const selectedSectionProblems = selectedProblems.get(section._id) || new Set()
  const totalSectionProblems = problems
    ?.get(section._id)
    ?.filter((problem) => problem.level <= PROBLEM_LEVELS.HARD)
  const areAllProblemsSelected = !!selectedSectionProblems?.size
    && selectedSectionProblems?.size === totalSectionProblems?.length
  const isDisabled = !totalSectionProblems || totalSectionProblems.length === 0
  const isOpened = section._id === (openedSection?.id || '')

  return (
    <AccordionListItem
      key={section._id}
      title={
        <div className={styles.Title}>
          <div className={clsx(styles.ArrowWrap, {
            [styles.OpenSectionArrowWrap]: isOpened,
          })}>
            <ArrowIcon size={12} color={COLORS.NEUTRAL_9} />
          </div>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            classes={{ InputControl: styles.Checkbox }}
            size={SIZE.MEDIUM}
            disabled={isDisabled}
            color={checkboxColor}
            checked={areAllProblemsSelected && !isDisabled}
            onChange={() =>
              areAllProblemsSelected
                ? removeSelectedProblems(section._id)
                : selectAllBySection(section._id)
            }
            label={section.name}
          />
          <ProblemsCount section={section} />
        </div>
      }
      onClick={() =>
        onClickSection({
          id: section._id,
          name: section.name,
        })
      }
    />
  )
}

export default SearchSectionsListItem
