import React from 'react'
import { COMPONENT_COLOR } from '@magmamath/ui'
import useText from '../../../i18n/hook'
import InfoMessage from '../../../ui/messages/InfoMessage/InfoMessage'
import SpecialBooksInfo from '../../bookshelf/SpecialBookInfo/SpecialBookInfo'
import ErrorMessageText from '../../caf/errorMessages/ui/ErrorMessageText'
import { ErrorMessagesFields } from '../../caf/errorMessages/types'
import styles from './ExerciseMessages.module.scss'
import { MAX_RECOMMENDED_PROBLEMS } from '../../bookshelf/constants'

type ExerciseMessagesProps = {
  isSelectProblemsError: boolean
  amountOfSelectedProblems: number
  includesProblemFromSpecialChapter: boolean
  isExamMode: boolean
}

const ExerciseMessages = ({
  isSelectProblemsError,
  amountOfSelectedProblems,
  includesProblemFromSpecialChapter,
  isExamMode,
}: ExerciseMessagesProps) => {
  const t = useText()

  const isAttentionMessageVisible = amountOfSelectedProblems > MAX_RECOMMENDED_PROBLEMS

  const isAnyMessageVisible =
    isAttentionMessageVisible || includesProblemFromSpecialChapter || isSelectProblemsError

  if (!isAnyMessageVisible) return null

  return (
    <div className={styles.Container}>
      {isAttentionMessageVisible && (
        <InfoMessage color={isExamMode ? COMPONENT_COLOR.PURPLE : COMPONENT_COLOR.BLUE}>
          {t.attentionMaxChosenProblems}
        </InfoMessage>
      )}
      {includesProblemFromSpecialChapter && (
        <InfoMessage>
          <SpecialBooksInfo />
        </InfoMessage>
      )}
      {isSelectProblemsError && (
        <ErrorMessageText field={ErrorMessagesFields.SELECT_PROBLEMS} className={styles.Error} />
      )}
    </div>
  )
}

export default ExerciseMessages
