import { EXERCISES_STATUS } from 'redux/modules/exercisesStatuses.module'
import { AssignmentFilterBadges } from '../types/types'

export const FIRST_PAGE = 1
export const EXERCISES_PER_PAGE = 20
export const THROTTLE_TIME_IN_MS = 2000
export const FILTER_BADGES_DEFAULT: AssignmentFilterBadges = {
  [EXERCISES_STATUS.UNPUBLISHED]: 0,
  [EXERCISES_STATUS.AVAILABLE]: 0,
  [EXERCISES_STATUS.ARCHIVED]: 0,
}
export const INFINITE_SCROLL_STYLES = {
  overflow: 'hidden',
  minHeight: '80vh',
  position: 'relative',
} as const
export const INFINITE_SCROLL_ID = 'scrollable-wrapper'

export const PROMISE_FULFILLED = 'fulfilled'
