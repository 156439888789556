import { LocaleData } from '../helpers/enums'
import { ICountry, IMunicipality } from './types.countries'

export interface IRegion {
  _id: string
  name: string
  bookSeries: string[]
  municipalities: string[]
}

export interface IBasicUser {
  _id: string
  firstName: string
  lastName: string
  setting?: {
    practiceModeLockedGrades?: number[]
  }
}

export interface IUser extends IBasicUser {
  type: number
  invitationalCode: number
  username: string
  password: string
  phone: number
  classes: [
    {
      name: string
    }
  ]
}

export interface ITeacher extends IBasicUser {
  fullName: string
  treeId?: string
}

export interface IStudent extends IBasicUser {
  username?: string
  email?: string
  name?: string
}

export interface IMe {
  _id: string
  email: string
  firstName: string
  lastName: string
  profile: { _id: string; profileImage: string }
  setting: { _id: string; appLanguage: LocaleData; practiceMode?: boolean; treeId?: string }
  type: number
  username: string
  school: { _id: string; name: string; bookSeries: string[] }
  exerciseCount: number
  enabled: boolean
  source: number
  tokens: [string]
  entityStatus: number
  isAdmin: boolean
  phone: { attempt: number; verify: boolean }
  fullName: string
  country: ICountry
  region: IRegion
  municipality: IMunicipality
  bookSeries: string[]
  managedMunicipality?: string
  managedSchool?: string
}

export enum User {
  teacher = 1,
  student = 2,
  admin = 3,
  problemsEditor = 4,
}
