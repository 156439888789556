import clsx from 'clsx'
import renderDescription from 'helpers/description.helpers'
import { AnswerType } from 'helpers/enums'
import { tasksWithPrefilledBoxes } from 'helpers/exercise.helpers'
import React, { useEffect, useRef, useState } from 'react'
import UIModal from 'ui/modals/UIModal/UIModal'
import Skeleton from 'ui/skeleton/Skeleton'
import { getProblemImageUrl } from '../../helpers/imageUrl'
import { ZoomedContent } from '../../types/ZoomedContent'
import styles from './ProblemBody.module.scss'
import { IProblem } from '../../../../../api/types.problem'

type ProblemBodyProps = {
  problem: IProblem
  subProblemId?: string
}
const ProblemBody = ({ problem, subProblemId }: ProblemBodyProps) => {
  const [zoomedContent, setZoomedContent] = useState<null | ZoomedContent>(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const subProblem = problem.subProblems.find((subProblem) => subProblem._id === subProblemId)
  const imageURL = getProblemImageUrl(problem)

  const descriptionContent =
    problem.answerType === AnswerType.prefilledBoxes
      ? tasksWithPrefilledBoxes(problem)
      : renderDescription(problem)
  const subProblemDescriptionContent = subProblem ? renderDescription(subProblem) : null

  //  Ref and useEffect below is used to prevent flickering of the image when it's loaded (image from cache)
  const delayRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!imageURL) return setIsImageLoaded(false)
    delayRef.current = setTimeout(() => {
      setIsImageLoaded(false)
      delayRef.current = null
    }, 50)

    return () => {
      if (delayRef.current) clearTimeout(delayRef.current)
      delayRef.current = null
    }
  }, [imageURL])

  return (
    <>
      <div className={styles.Body}>
        {imageURL && (
          <img
            src={imageURL}
            style={{ display: isImageLoaded ? 'block' : 'none' }}
            className={styles.Image}
            alt={problem.name}
            onClick={() => setZoomedContent(ZoomedContent.IMAGE)}
            onLoad={() => {
              if (delayRef.current) {
                clearTimeout(delayRef.current)
                delayRef.current = null
              }
              setIsImageLoaded(true)
            }}
          />
        )}
        {imageURL && !isImageLoaded && (
          <Skeleton width={200} height={50} variant='rounded' className={styles.Image} />
        )}

        <div onClick={() => setZoomedContent(ZoomedContent.DESCRIPTION)}>
          {descriptionContent}
          {subProblemDescriptionContent && <div>{subProblemDescriptionContent}</div>}
        </div>
      </div>
      <UIModal
        open={!!zoomedContent}
        onClose={() => {
          setZoomedContent(null)
        }}
        wrapperClassName={styles.Modal}
      >
        <div
          className={clsx(styles.ModalContent, {
            [styles.TextContent]: zoomedContent === ZoomedContent.DESCRIPTION,
          })}
        >
          {zoomedContent === ZoomedContent.IMAGE && (
            <img src={imageURL} className={styles.Image} alt={problem.name} />
          )}
          {zoomedContent === ZoomedContent.DESCRIPTION && (
            <>
              <div>{descriptionContent}</div>
              {subProblemDescriptionContent && <div>{subProblemDescriptionContent}</div>}
            </>
          )}
        </div>
      </UIModal>
    </>
  )
}

export default ProblemBody
