import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, BUTTON_VARIANT } from '@magmamath/ui'

import styles from './GradeSelector.module.scss'

import { RootState } from '../../../../store/store'
import { getChangedGrade, getIsChangeYearDisabled } from '../helpers'
import { ChangeGradeActions } from '../../../../api/types.oldPractice'
import YearSelectorControl from './YearSelectorControl /YearSelectorControl'
import GradeSelectorMenu from './GradeSelectorMenu/GradeSelectorMenu'
import useOutsideClick from '../../hooks/useOutsideClick'
import { useUnit } from 'effector-react'
import { $filterSelectorStore } from '../../../filters/StudentsFilter/model'
import useText from '../../../../i18n/hook'
import { TypesOfParams } from '../../../../helpers/enums'
import { getCorrectGradeName } from '../../../GradesSelectorModal/helpers'
import { IClass } from '../../../../api/types.classes'
import { practiceFilterModel } from '../../models/practiceFilter'
import { AVAILABLE_GRADES } from '../../models/practiceFilter'

const SELECTED_OPTION_CLASS = 'class'

const GradeSelector = () => {
  const t = useText()
  const [isGradeSelectorMenuShown, setIsShownGradeSelectorMenu] = useState(false)
  const ref = useOutsideClick(() => setIsShownGradeSelectorMenu(false))

  const classes = useSelector((state: RootState) => state.classes?.data?._embedded?.classes)
  const classFilterValue = useSelector((state: RootState) => state.classesFilter)
  const studentsFilterValue = useUnit($filterSelectorStore)
  const selectedOption = {
    _id: studentsFilterValue?.type ? studentsFilterValue.value : classFilterValue.value,
    name: studentsFilterValue?.type ? studentsFilterValue.name : classFilterValue.name,
    type: studentsFilterValue?.type
      ? TypesOfParams.STUDENT
      : classFilterValue.value
      ? TypesOfParams.CLASS
      : null,
  }

  const grade = useUnit(practiceFilterModel.$grade)

  const setGrade = practiceFilterModel.setGrade

  useEffect(() => {
    if (!selectedOption || selectedOption?.type !== SELECTED_OPTION_CLASS) return
    const selectedClassGrade = classes.find(
      (option: IClass) => option._id === selectedOption._id
    )?.grade

    setGrade(selectedClassGrade)

    /*
     no need dependencies here because there is setting only on first load to set grade according to selected class.
     After changing class we don't need to automatically change grade
     */
  }, [])

  useEffect(() => {
    if (AVAILABLE_GRADES.includes(grade)) {
      return
    }

    const newGrade = getChangedGrade({
      type: ChangeGradeActions.DECREASE,
      currentGrade: grade,
      filteredGrades: AVAILABLE_GRADES,
      newGrade: grade - 1,
    })

    setGrade(Math.max(newGrade, 1))
  }, [])

  const onChangeGrade = (type: ChangeGradeActions) => {
    const newGrade = type === ChangeGradeActions.DECREASE ? grade - 1 : grade + 1
    const changedGrade = getChangedGrade({
      type,
      currentGrade: grade,
      filteredGrades: AVAILABLE_GRADES,
      newGrade,
    })
    setGrade(changedGrade)
  }

  const isChangingYearDisabled = (type: ChangeGradeActions) => {
    return getIsChangeYearDisabled({ type, currentGrade: grade, filteredGrades: AVAILABLE_GRADES })
  }

  const onSelectGrade = (grade: number) => {
    setGrade(grade)
    setIsShownGradeSelectorMenu(false)
  }

  return (
    <div className={styles.GradeSelectorControl}>
      <YearSelectorControl
        isDisabled={isChangingYearDisabled(ChangeGradeActions.DECREASE)}
        onClick={() => onChangeGrade(ChangeGradeActions.DECREASE)}
      />
      <div className={styles.GradeSelectorButton} ref={ref}>
        <Button
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={() => setIsShownGradeSelectorMenu(!isGradeSelectorMenuShown)}
          isActive={isGradeSelectorMenuShown}
          classes={{ button: styles.ButtonContainer, content: styles.ButtonContent }}
        >
          {getCorrectGradeName(grade, t)}
        </Button>
        {isGradeSelectorMenuShown && (
          <GradeSelectorMenu
            grades={AVAILABLE_GRADES}
            onSelectGrade={onSelectGrade}
            activeGrade={grade}
          />
        )}
      </div>
      <YearSelectorControl
        isDisabled={isChangingYearDisabled(ChangeGradeActions.INCREASE)}
        onClick={() => onChangeGrade(ChangeGradeActions.INCREASE)}
        isReversed
      />
    </div>
  )
}

export default GradeSelector
