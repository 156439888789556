import { Dayjs } from 'dayjs'
import { USTimeType, CalculatorType } from 'helpers/enums'

export enum EXERCISE_SETTINGS {
  IS_DEFAULT = 'isDefault',
  ALLOW_ANSWER_CHECKING = 'allowAnswerChecking',
  IS_EXAM_MODE = 'isExamMode',
  IS_EXAM_MODE_MANUALLY_SELECTED = 'isExamModeManuallySelected',
  FORCED_DRAWING = 'forcedDrawing',
  REQUIRE_UNIT = 'requireUnit',
  CALCULATOR_TYPE = 'calculatorType',
  CHEAT_DETECTION = 'cheatDetection',
  PUBLISH_NOW = 'publishNow',
  TOPIC = 'topic',
  SCHEDULE_DATE = 'scheduleDate',
  SCHEDULE_ARCHIVE_DATE = 'scheduleArchiveDate',
  SCHEDULED_TIME = 'scheduledTime',
  SCHEDULED_ARCHIVE_TIME = 'scheduledArchiveTime',
  START_DATE = 'startDate',
}

export type ExerciseSettings = {
  allowAnswerChecking: boolean
  isExamMode: boolean
  isExamModeManuallySelected: boolean
  forcedDrawing: boolean
  requireUnit: boolean
  calculatorType: CalculatorType
  cheatDetection: boolean
  publishNow: boolean
  topic: boolean
  scheduleDate: Dayjs
  scheduleArchiveDate: Dayjs
  scheduledTime: {
    hour: number
    minute: number
    type: USTimeType | null
  }
  scheduledArchiveTime: {
    hour: number
    minute: number
    type: USTimeType | null
  }

  startDate: string | Dayjs | Date | null
}
