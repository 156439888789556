import { BUTTON_VARIANT, Button, COLORS, Modal } from '@magmamath/ui'
import clsx from 'clsx'
import DateList from 'features/heatmap/ExamMode/ui/DateList/DateList'
import { CurrentLanguage, DateFormats } from 'helpers/enums'
import { getCheatDate } from 'helpers/general'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import AttentionOutlinedIcon from 'ui/icons/AttentionOutlinedIcon/AttentionOutlinedIcon'
import styles from './CheatsTableModal.module.scss'
import { ICheat, IStudentsExamStats } from '../../../../../api/types.statistics'

const DATE_LENGTH_LIMIT = 1

type CheatsTableModalProps = {
  exerciseWithoutStatsData: IExerciseWithoutStats
  studentsExamStatsMap: Map<string, IStudentsExamStats>
}

const CheatsTableModal = ({
  exerciseWithoutStatsData,
  studentsExamStatsMap,
}: CheatsTableModalProps) => {
  const t = useText()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getTitle = (cheats: ICheat[]) => {
    if (cheats?.length === DATE_LENGTH_LIMIT) {
      const format =
        t.languageTxt === CurrentLanguage.SE
          ? DateFormats.HOURS_MINUTES
          : DateFormats.HOURS_MINUTES_US
      return getCheatDate(cheats[0].time, t.languageTxt, format)
    }
    return cheats?.length > DATE_LENGTH_LIMIT ? `${cheats.length} ${t.timesTxt}` : ''
  }

  const studentsWithCheats = exerciseWithoutStatsData.students.filter((student) => {
    const studentExamStats = studentsExamStatsMap.get(student._id)
    return studentExamStats?.examStats?.cheats
  })

  if (!studentsWithCheats.length || !exerciseWithoutStatsData.cheatDetection)
    return <div className={styles.EmptySpacing} />

  return (
    <>
      <Button
        classes={{ button: styles.Button, content: styles.ButtonContent }}
        icon={<AttentionOutlinedIcon color={COLORS.NEUTRAL_9} size={16} />}
        variant={BUTTON_VARIANT.SECONDARY}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        classes={{ Modal: styles.Modal }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Modal.Header title={t.cheatDetection} />
        <Modal.Content className={styles.Content}>
          <div className={styles.CheatsTable}>
            <div className={styles.HeadersRow}>
              <div className={styles.ColumnHeader}>{t.lastNameTxt}</div>
              <div className={styles.ColumnHeader}>{t.firstNameTxt}</div>
              <div className={styles.ColumnHeader}>{t.left}</div>
            </div>
            <div className={styles.CheatsTableContent}>
              {studentsWithCheats.map((student, index) => {
                const studentExamStats = studentsExamStatsMap.get(student._id)
                const cheats = studentExamStats?.examStats?.cheats
                if (!cheats) return null
                return (
                  <div key={student._id} className={styles.Row}>
                    <div className={styles.RowColumn}>{student.lastName}</div>
                    <div className={styles.RowColumn}>{student.firstName}</div>
                    <div className={clsx(styles.TooltipContainer, styles.RowColumn)}>
                      <div className={styles.RowElements}>
                        <span className={styles.DateTitle}>{getTitle(cheats)}</span>
                        <div className={clsx(styles.Tooltip, { [styles.ToTop]: index > 5 })}>
                          <DateList cheat={cheats} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CheatsTableModal
