import React from 'react'
import styles from './Divider.module.scss'
import clsx from 'clsx'

type DividerProps = {
  className?: string
}

const Divider = ({ className }: DividerProps) => <hr className={clsx(styles.Divider, className)} />

export default Divider
