import React from 'react'

import styles from './AllStudentsResults.module.scss'

import StudentsList from '../StudentsList/StudentsList'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { sortStudentsByCorrectAnswers } from '../../../helpers'
import { IStudent } from '../../../../../api/types.users'
import EmptyExerciseContainer from '../../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import ExampleProblem from './ExampleProblem/ExampleProblem'

type AllStudentsResultsProps = {
  selectedStudents: IStudent[]
  onOpenSingleStudentView: (studentId: string, studentFullName: string) => void
}

const AllStudentsResults = ({
  selectedStudents,
  onOpenSingleStudentView,
}: AllStudentsResultsProps) => {
  const isLoading = useSelector((state: RootState) => state.practiceResults.loading)
  const usersResults = useSelector((state: RootState) => state.practiceResults.results)

  const orderedSelectedStudents = sortStudentsByCorrectAnswers(selectedStudents, usersResults)

  if (isLoading) {
    return (
      <div className={styles.AllStudentsResultsContainer}>
        <EmptyExerciseContainer heightClass={styles.Loader} />
      </div>
    )
  }

  return (
    <div className={styles.AllStudentsResultsContainer}>
      <StudentsList
        students={orderedSelectedStudents}
        results={usersResults}
        onClickItem={onOpenSingleStudentView}
      />
      <div className={styles.BodyItemsSeparator} />
      <ExampleProblem />
    </div>
  )
}

export default AllStudentsResults
