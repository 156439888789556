import * as React from 'react'
import { COLORS } from '@magmamath/ui'

const STROKE_WIDTH: string = '8'

interface ICircleProgressBar {
  correct: number
  wrong: number
  correctAfterFirstAttempt?: number
  fillColor?: string
}

const countStrokeDashArray = (value: number) => `${value} ${Math.round(100 - value)}`

const CircleProgressBar: React.FC<ICircleProgressBar> = ({
  correct,
  wrong,
  correctAfterFirstAttempt,
  fillColor,
}) => {
  return (
    <div className='progress-exercise'>
      <div style={{ width: '47px' }}>
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 42 42'
          transform='rotate(270)'
          className='donut'
        >
          <circle
            className='donut-hole'
            cx='21'
            cy='21'
            r='15.91549430918954'
            fill={fillColor ?? COLORS.NEUTRAL_1}
          />
          <circle
            className='donut-ring'
            cx='21'
            cy='21'
            r='15.91549430918954'
            fill='transparent'
            stroke={COLORS.NEUTRAL_5}
            strokeWidth={STROKE_WIDTH}
          />
          <circle
            className='donut-segment'
            cx='21'
            cy='21'
            r='15.91549430918954'
            fill='transparent'
            stroke={COLORS.PRIMARY_GREEN}
            strokeLinecap='butt'
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={countStrokeDashArray(correct)}
            strokeDashoffset={0}
          />
          {correctAfterFirstAttempt && (
            <circle
              className='donut-segment'
              cx='21'
              cy='21'
              r='15.91549430918954'
              fill='transparent'
              stroke={COLORS.PRIMARY_YELLOW}
              strokeLinecap='butt'
              strokeWidth={STROKE_WIDTH}
              strokeDasharray={
                Math.round(correct + correctAfterFirstAttempt) >= 100
                  ? `${correct} 100`
                  : countStrokeDashArray(correctAfterFirstAttempt)
              }
              strokeDashoffset={-`${correct}`}
            />
          )}
          <circle
            className='donut-segment'
            cx='21'
            cy='21'
            r='15.91549430918954'
            fill='transparent'
            stroke={COLORS.PRIMARY_RED}
            strokeLinecap='butt'
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={
              Math.round(correct + (correctAfterFirstAttempt ?? 0) + wrong) >= 100
                ? `${wrong} 100`
                : countStrokeDashArray(wrong)
            }
            strokeDashoffset={-`${correct + (correctAfterFirstAttempt ?? 0)}`}
          />
        </svg>
      </div>
    </div>
  )
}

export default CircleProgressBar
