import React from 'react'
import { useStoreMap } from 'effector-react'
import { Button, SIZE, BUTTON_VARIANT, BUTTON_COLOR } from '@magmamath/ui'
import styles from './GradeSelectorModalFooter.module.scss'
import useText from '../../../i18n/hook'
import { $studentsGradesData } from '../models/studentsGradesModel'

type GradeSelectorModalFooterProps = {
  onSaveClick: () => void
  onCancelClick: () => void
  isErrorOnClose: boolean
}

const GradeSelectorModalFooter = ({
  onSaveClick,
  onCancelClick,
  isErrorOnClose,
}: GradeSelectorModalFooterProps) => {
  const t = useText()
  const isGradesChanged = useStoreMap(
    $studentsGradesData,
    (data) => data.studentsGrades.isDataChanged
  )
  const isGradesDirty = useStoreMap($studentsGradesData, (data) => data.studentsGrades.isDataDirty)

  return (
    <div className={styles.GradeSelectorModalFooterContainer}>
      <div className={styles.ButtonsContainer}>
        <Button variant={BUTTON_VARIANT.TERTIARY} size={SIZE.LARGE} onClick={onCancelClick}>
          {t.cancelChangesTxt}
        </Button>
        <Button
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZE.LARGE}
          color={BUTTON_COLOR.ORANGE}
          classes={{ button: styles.SaveButtonContainer }}
          disabled={!isGradesChanged || isGradesDirty}
          onClick={onSaveClick}
        >
          {t.saveTxt}
        </Button>
      </div>
      {isErrorOnClose && <span className={styles.ErrorMessage}>{t.saveOrCancelChangesTxt}</span>}
    </div>
  )
}

export default GradeSelectorModalFooter
