import React from 'react'
import { SvgIconProps } from '../types/types'

const PrintIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.0999 8.77178C1.85137 8.77178 1.6499 8.97325 1.6499 9.22178V14.7348C1.6499 14.9834 1.85137 15.1848 2.0999 15.1848H5.68904V13.5196H4.85643C4.44221 13.5196 4.10643 13.1838 4.10643 12.7696C4.10643 12.3554 4.44221 12.0196 4.85643 12.0196H15.9347C16.3489 12.0196 16.6847 12.3554 16.6847 12.7696C16.6847 13.1838 16.3489 13.5196 15.9347 13.5196H15.1021V15.1848H17.8999C18.1484 15.1848 18.3499 14.9834 18.3499 14.7348V9.22178C18.3499 8.97325 18.1484 8.77178 17.8999 8.77178H2.0999ZM15.1021 16.6848H17.8999C18.9769 16.6848 19.8499 15.8118 19.8499 14.7348V9.22178C19.8499 8.14482 18.9769 7.27178 17.8999 7.27178H2.0999C1.02295 7.27178 0.149902 8.14482 0.149902 9.22178V14.7348C0.149902 15.8118 1.02295 16.6848 2.0999 16.6848H5.68904V18.3C5.68904 19.1561 6.38299 19.85 7.23904 19.85H13.5521C14.4081 19.85 15.1021 19.1561 15.1021 18.3V16.6848ZM13.6021 13.5196H7.18904V18.3C7.18904 18.3277 7.21142 18.35 7.23904 18.35H13.5521C13.5797 18.35 13.6021 18.3277 13.6021 18.3V13.5196Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.05643 1.65002C5.8079 1.65002 5.60643 1.8515 5.60643 2.10002V5.64785C5.60643 6.06207 5.27064 6.39785 4.85643 6.39785C4.44221 6.39785 4.10643 6.06207 4.10643 5.64785V2.10002C4.10643 1.02307 4.97947 0.150024 6.05643 0.150024H12.285C12.749 0.150024 13.2026 0.287354 13.5886 0.544706L14.8469 1.38361C15.5007 1.81945 15.8934 2.5532 15.8934 3.33893V5.64785C15.8934 6.06207 15.5576 6.39785 15.1434 6.39785C14.7292 6.39785 14.3934 6.06207 14.3934 5.64785V3.33893C14.3934 3.05473 14.2514 2.78933 14.0149 2.63169L12.7565 1.79278C12.6169 1.6997 12.4528 1.65002 12.285 1.65002H6.05643Z'
        fill={color}
      />
      <path
        d='M17.5173 10.3957C17.5173 10.8327 17.163 11.187 16.726 11.187C16.289 11.187 15.9347 10.8327 15.9347 10.3957C15.9347 9.95868 16.289 9.6044 16.726 9.6044C17.163 9.6044 17.5173 9.95868 17.5173 10.3957Z'
        fill={color}
      />
    </svg>
  )
}

export default PrintIcon
