import { Listbox, SIZE, SelectValue } from '@magmamath/ui'
import useText from 'i18n/hook'
import React from 'react'
import { CALCULATOR_DESCRIPTORS } from '../../constants'
import { useUnit } from 'effector-react'
import styles from './CalculatorListbox.module.scss'
import { $exerciseSettings, setExerciseSetting } from '../../model/exerciseSettings'
import { EXERCISE_SETTINGS } from '../../types'

const CalculatorListbox = () => {
  const { calculatorType } = useUnit($exerciseSettings)
  const t = useText()
  return (
    <Listbox
      placeholder={t.calculator}
      size={SIZE.SMALL}
      classes={{
        ListboxOptions: styles.ListboxOptions,
        Button: styles.ListboxButton,
      }}
      value={{
        name: t[CALCULATOR_DESCRIPTORS[calculatorType]],
        value: `${calculatorType}`,
      }}
      onChange={(data: SelectValue) => {
        if (!data?.value) return
        const newValue = Number(data.value)
        setExerciseSetting({ key: EXERCISE_SETTINGS.CALCULATOR_TYPE, newValue })
      }}
      multiple={false}
      isActive={false}
    >
      {Object.entries(CALCULATOR_DESCRIPTORS).map(([key, calculatorType]) => (
        <Listbox.Option value={{ name: t[calculatorType], value: `${key}` }} key={key}>
          {t[calculatorType]}
        </Listbox.Option>
      ))}
    </Listbox>
  )
}

export default CalculatorListbox
