import { useState, useEffect } from 'react'

function useKeyPress(targetKey: string, eventOptions?: boolean | AddEventListenerOptions) {
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false)

  function downHandler({ key }: { key: string }) {
    if (key === targetKey) {
      setIsKeyPressed(true)
    }
  }

  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setIsKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler, eventOptions)
    window.addEventListener('keyup', upHandler, eventOptions)

    return () => {
      window.removeEventListener('keydown', downHandler, eventOptions)
      window.removeEventListener('keyup', upHandler), eventOptions
    }
  }, [])

  return isKeyPressed
}

export default useKeyPress
