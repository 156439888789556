import React from 'react'

import styles from './PracticeCategory.module.scss'

import PracticeSubcategory from '../PracticeSubcategory/PracticeSubcategory'
import { ISkillTreeNode } from 'api/types.skills'

type PracticeCategoryProps = {
  category: ISkillTreeNode
}

const PracticeCategory = ({ category }: PracticeCategoryProps) => {
  return (
    <div className={styles.PracticeContentContainer}>
      <span className={styles.CategoryTitle}>{category.nameTranslations}</span>
      <div className={styles.SubcategoriesBlockContainer}>
        {category.children.map((subcategory) => {
          return <PracticeSubcategory subcategory={subcategory} key={subcategory._id} />
        })}
      </div>
    </div>
  )
}

export default PracticeCategory
