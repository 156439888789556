import React from 'react'
import styles from './PlayableControllers.module.scss'
import { previewPersistModule } from '../DrawBoardPreview/model.persist'
import { serverPersistModule } from '../../../DrawBoard/hook.persist'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import ExpandIcon from '../../../../ui/icons/ExpandIcon/ExpandIcon'
import DrawBoardSolutionLink from '../../../DrawBoard/DrawBoardSolutionLink'
import { HistorySaveCredits } from '../../../DrawBoard/types'
import PlayIcon from '../../../../ui/icons/PlayIcon/PlayIcon'
import useText from '../../../../i18n/hook'

type PlayableFullScreenButtonProps = {
  credits: HistorySaveCredits
  drawBoardBackground?: string
  replayVariant?: boolean
}

const PlayableFullScreenButton = ({
  credits,
  replayVariant,
  drawBoardBackground,
}: PlayableFullScreenButtonProps) => {
  const t = useText()
  const pushDrawingHistoryToModule = () => {
    const instance = previewPersistModule.getCurrentInstance()
    serverPersistModule.setInstance(instance)
  }

  return (
    <DrawBoardSolutionLink
      credits={credits}
      className={styles.DrawBoardLink}
      backgroundImage={drawBoardBackground}
    >
      {replayVariant ? (
        <Button
          classes={{
            content: styles.ReplayButton,
          }}
          onClick={pushDrawingHistoryToModule}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZE.SMALL}
          icon={<PlayIcon color={'var(--icon-color)'} size={16} />}
        >
          {t.play}
        </Button>
      ) : (
        <Button
          onClick={pushDrawingHistoryToModule}
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZE.SMALL}
          icon={<ExpandIcon color={'var(--icon-color)'} size={16} />}
        />
      )}
    </DrawBoardSolutionLink>
  )
}

export default PlayableFullScreenButton
