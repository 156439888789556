import * as React from 'react'
import clsx from 'clsx'
import { groupInTwoDigits } from 'lib/numbers'
import { isClassCreatedManually } from 'helpers/classesView.helpers'
import styles from './StudentsTableUIHeader.module.scss'
import { ILocalization } from '../../../../../config/languages.config'
import { IClass } from '../../../../../api/types.classes'
import { IGroup } from '../../../../../api/api.groups'
import { IMunicipality } from '../../../../../api/types.countries'

interface IStudentsTableHeaderProps {
  municipality: IMunicipality
  localization: ILocalization
  classOrGroup: IClass | IGroup
}

const StudentsTableHeader: React.FC<IStudentsTableHeaderProps> = ({
  classOrGroup,
  municipality,
  localization,
}) => {
  const getStudentsColumnName = () => {
    return (
      classOrGroup.students.length === 1
        ? localization.data.studentTxt
        : localization.data.studentsTxt
    ).toLowerCase()
  }

  const copyCode = (event: React.ClipboardEvent) => {
    const selection = document.getSelection()
    if (selection) {
      event.clipboardData.setData('text/plain', selection.toString().replaceAll(' ', ''))
    }

    event.preventDefault()
  }

  const hasStudents = classOrGroup.students?.length > 0

  return (
    <div className={styles.Header}>
      {'invitationalCode' in classOrGroup &&
        (isClassCreatedManually(classOrGroup.source) || !municipality.source) && (
          <div
            className={clsx(styles.Item, {
              [styles.Code]: !hasStudents,
            })}
          >
            {localization.data.classcode}
            <span className={styles.CodeValue} onCopy={copyCode}>
              {groupInTwoDigits(classOrGroup.invitationalCode)}
            </span>
          </div>
        )}

      {hasStudents && (
        <div className={clsx(styles.Item, styles.StudentsLength)}>
          {`${classOrGroup.students.length} ${getStudentsColumnName()}`}
        </div>
      )}
      <div className={styles.Item} />
      <div className={styles.Item} />
    </div>
  )
}

export default StudentsTableHeader
