import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import { updatePinState } from 'features/pinnedSolutions/model/isPinsFilterActive'
import useText from 'i18n/hook'
import React, { useEffect } from 'react'
import { $commonErrorFilterTask, updateCommonErrorFilterTask } from '../model/filterTask'
import styles from './FilterButton.module.scss'

const getButtonStyleProps = (isCommonErrorFilterActive: boolean) =>
  isCommonErrorFilterActive
    ? { variant: BUTTON_VARIANT.PRIMARY, color: BUTTON_COLOR.BLUE }
    : { variant: BUTTON_VARIANT.SECONDARY }

type FilterButtonProps = {
  taskId: string
}

const FilterButton = ({ taskId }: FilterButtonProps) => {
  const t = useText()
  const commonErrorFilterTask = useUnit($commonErrorFilterTask)

  const handleButtonClick = () => {
    const newCommonErrorFilterTask = commonErrorFilterTask ? null : taskId
    updateCommonErrorFilterTask(newCommonErrorFilterTask)
    if (newCommonErrorFilterTask) updatePinState(false)
  }

  useEffect(() => {
    return () => {
      updateCommonErrorFilterTask(null)
    }
  }, [taskId])

  return (
    <Button
      classes={{ button: styles.FilterButton }}
      size={SIZE.SMALL}
      {...getButtonStyleProps(!!commonErrorFilterTask)}
      onClick={handleButtonClick}
    >
      {t.showTxt}
    </Button>
  )
}

export default FilterButton
