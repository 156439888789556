import React, { useEffect, useRef } from 'react'

const usePressOutside = (ref: React.MutableRefObject<HTMLElement | null>, fn: () => void) => {
  const fnRef = useRef(fn)
  fnRef.current = fn

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        fnRef.current()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  }, [ref])
}

export default usePressOutside
