import React from 'react'
import { SvgIconProps } from '../types/types'

const PlayBackIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.61103 0.930607C4.31814 0.637714 3.84327 0.637714 3.55037 0.930607L0.750373 3.73061C0.45748 4.0235 0.45748 4.49837 0.750373 4.79127L3.55037 7.59127C3.84327 7.88416 4.31814 7.88416 4.61103 7.59127C4.90393 7.29837 4.90393 6.8235 4.61103 6.53061L3.09136 5.01094H9.6007C12.0142 5.01094 13.9707 6.96745 13.9707 9.38094V10.4209C13.9707 12.8344 12.0142 14.7909 9.6007 14.7909H4.0807C3.66649 14.7909 3.3307 15.1267 3.3307 15.5409C3.3307 15.9552 3.66649 16.2909 4.0807 16.2909H9.6007C12.8426 16.2909 15.4707 13.6628 15.4707 10.4209V9.38094C15.4707 6.13903 12.8426 3.51094 9.6007 3.51094H3.09136L4.61103 1.99127C4.90393 1.69837 4.90393 1.2235 4.61103 0.930607Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayBackIcon
