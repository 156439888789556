import React from 'react'
import './ContenEditable.scss'

import ContentEditable from 'react-contenteditable'
import * as PropTypes from 'prop-types'

interface ContentEditableAreaProps {
  value: string
  disabled?: boolean
  className?: string
  textAreaRef?: PropTypes.Requireable<object>
}

const ContentEditableArea: React.FC<ContentEditableAreaProps> = ({
  value,
  textAreaRef,
  disabled,
  className,
}) => {
  return (
    <ContentEditable
      innerRef={textAreaRef}
      className={className}
      id='answer-input'
      html={value}
      onChange={() => null}
      disabled={disabled}
    />
  )
}

export default ContentEditableArea
