import { createServerPersistModule } from '../../../DrawBoard/hook.persist'
import { useEffect } from 'react'
import { HTMLDrawAreaPreset } from '@magmamath/react-native-draw-board'
import { useStore } from 'effector-react'
import { HistorySaveCredits } from '../../../DrawBoard/types'

export const previewPersistModule = createServerPersistModule()

export const usePreviewPersistModule = (
  preset: HTMLDrawAreaPreset,
  credits: HistorySaveCredits
) => {
  const isLoading = useStore(previewPersistModule.load.pending)

  useEffect(() => {
    previewPersistModule.setUp(preset.model)

    return () => {
      previewPersistModule.setUp(null)
    }
  }, [preset])

  useEffect(() => {
    previewPersistModule.setCredits(credits)
  }, [credits.userId, credits.exerciseId, credits.problemId, credits.subProblemId])

  return isLoading
}
