import { api } from '../../api'

export const updateTeacherCurrentSchool = async ({
  teacherId,
  schoolId,
}: {
  teacherId: string
  schoolId: string
}) => {
  try {
    await api.teachers.updateSingle({ teacherId, body: { schoolId } })
  } catch (error) {}
}
