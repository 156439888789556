import { HeatmapTabsParams } from '../../../../helpers/enums'
import { ILocalization } from '../../../../config/languages.config'

export const getTabs = (hasSkillsStatsPages: boolean, isSkillsVisible: boolean) => [
  {
    id: HeatmapTabsParams.HEATMAP,
    getTitle: (t: ILocalization['data']) => t.heatmapTxt,
    tabParam: HeatmapTabsParams.HEATMAP,
    show: true,
  },
  {
    id: HeatmapTabsParams.SKILLS,
    getTitle: (t: ILocalization['data']) => t.skillsMatrix,
    tabParam: HeatmapTabsParams.SKILLS,
    show: hasSkillsStatsPages,
  },
  {
    id: HeatmapTabsParams.CHART,
    getTitle: (t: ILocalization['data']) => t.pieChart,
    tabParam: HeatmapTabsParams.CHART,
    show: isSkillsVisible,
  },
]
