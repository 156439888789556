import { TextSizeSettings } from './enums'

export const findRoundedNumberFromArray = (inputNumber: number | null) => {
  if (!inputNumber) {
    return TextSizeSettings.Min
  }

  const numbers: Array<number> = Array(
    (TextSizeSettings.Max - TextSizeSettings.Min) / TextSizeSettings.Step + 1
  )
    .fill(0)
    .map((_, i) => {
      return TextSizeSettings.Min + i * TextSizeSettings.Step
    })

  const foundedNumber: number | undefined = numbers.find((number) => {
    return Math.abs(number - inputNumber) < 2
  })

  if (foundedNumber) {
    return foundedNumber
  }

  return inputNumber < TextSizeSettings.Min ? TextSizeSettings.Min : TextSizeSettings.Max
}
