import React from 'react'

import { SvgIconProps } from '../types/types'
const CheckboxIconDefault: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.642857'
        y='0.642857'
        width='16.7143'
        height='16.7143'
        rx='4.5'
        stroke={color}
        strokeWidth='1.28571'
      />
    </svg>
  )
}

export default CheckboxIconDefault
