import React from 'react'

import LengthIcon from 'ui/icons/LengthIcon/LengthIcon'
import AreaIcon from 'ui/icons/AreaIcon/AreaIcon'
import VolumeIcon from 'ui/icons/VolumeIcon/VolumeIcon'
import MassIcon from 'ui/icons/MassIcon/MassIcon'
import TimeUnitIcon from 'ui/icons/TimeUnitIcon/TimeUnitIcon'
import CurrencyIcon from 'ui/icons/CurrencyIcon/CurrencyIcon'

export enum UnitsTypes {
  AREA = 'AREA',
  CURRENCY = 'CURRENCY',
  LENGTH = 'LENGTH',
  MASS = 'MASS',
  TIME = 'TIME',
  VOLUME = 'VOLUME',
}

interface IUnitsButtons {
  title: UnitsTypes
  icon: JSX.Element
  fullWidth?: boolean
}

export const UNITS_BUTTONS: IUnitsButtons[] = [
  { title: UnitsTypes.LENGTH, icon: <LengthIcon size={25} color='black' /> },
  { title: UnitsTypes.AREA, icon: <AreaIcon size={25} color='black' /> },
  { title: UnitsTypes.VOLUME, icon: <VolumeIcon size={25} color='black' /> },
  { title: UnitsTypes.MASS, icon: <MassIcon size={25} color='black' />, fullWidth: true },
  { title: UnitsTypes.TIME, icon: <TimeUnitIcon size={25} color='black' /> },
  { title: UnitsTypes.CURRENCY, icon: <CurrencyIcon size={25} color='black' /> },
]

type UnitSymbol = {
  symbol: string
  mathPower?: number
}

type Units = {
  [key: string]: UnitSymbol[]
}

type LocalizedUnitsSymbols = {
  [key: string]: Units
}

export const LOCALIZED_UNITS_SYMBOLS: LocalizedUnitsSymbols = {
  'sv-SE': {
    [UnitsTypes.LENGTH]: [
      { symbol: 'mm' },
      { symbol: 'cm' },
      { symbol: 'dm' },
      { symbol: 'm' },
      { symbol: 'km' },
      { symbol: 'mil' },
    ],
    [UnitsTypes.MASS]: [
      { symbol: 'mg' },
      { symbol: 'g' },
      { symbol: 'hg' },
      { symbol: 'kg' },
      { symbol: 'ton' },
    ],
    [UnitsTypes.AREA]: [
      { symbol: 'mm', mathPower: 2 },
      { symbol: 'cm', mathPower: 2 },
      { symbol: 'dm', mathPower: 2 },
      { symbol: 'm', mathPower: 2 },
      { symbol: 'km', mathPower: 2 },
    ],
    [UnitsTypes.VOLUME]: [
      { symbol: 'mm', mathPower: 3 },
      { symbol: 'cm', mathPower: 3 },
      { symbol: 'dm', mathPower: 3 },
      { symbol: 'm', mathPower: 3 },
      { symbol: 'ml' },
      { symbol: 'cl' },
      { symbol: 'dl' },
      { symbol: 'l' },
    ],
    [UnitsTypes.TIME]: [{ symbol: 's' }, { symbol: 'min' }, { symbol: 'h' }],
    [UnitsTypes.CURRENCY]: [{ symbol: 'kr' }, { symbol: '$' }, { symbol: '€' }, { symbol: '¢' }],
  },
  'en-SE': {
    [UnitsTypes.LENGTH]: [
      { symbol: 'mm' },
      { symbol: 'cm' },
      { symbol: 'm' },
      { symbol: 'km' },
      { symbol: 'in' },
      { symbol: 'ft' },
      { symbol: 'yd' },
      { symbol: 'mi' },
    ],
    [UnitsTypes.MASS]: [
      { symbol: 'mg' },
      { symbol: 'g' },
      { symbol: 'kg' },
      { symbol: 'ton' },
      { symbol: 'T' },
      { symbol: 'oz' },
      { symbol: 'lb' },
    ],
    [UnitsTypes.AREA]: [
      { symbol: 'mm', mathPower: 2 },
      { symbol: 'cm', mathPower: 2 },
      { symbol: 'm', mathPower: 2 },
      { symbol: 'km', mathPower: 2 },
      { symbol: 'in', mathPower: 2 },
      { symbol: 'ft', mathPower: 2 },
      { symbol: 'yd', mathPower: 2 },
      { symbol: 'mi', mathPower: 2 },
    ],
    [UnitsTypes.VOLUME]: [
      { symbol: 'mm', mathPower: 3 },
      { symbol: 'cm', mathPower: 3 },
      { symbol: 'm', mathPower: 3 },
      { symbol: 'in', mathPower: 3 },
      { symbol: 'ft', mathPower: 3 },
      { symbol: 'ml' },
      { symbol: 'l' },
      { symbol: 'floz' },
      { symbol: 'cup' },
      { symbol: 'pt' },
      { symbol: 'qt' },
      { symbol: 'gal' },
    ],
    [UnitsTypes.TIME]: [{ symbol: 'sec' }, { symbol: 'min' }, { symbol: 'h' }],
    [UnitsTypes.CURRENCY]: [{ symbol: '$' }, { symbol: '€' }, { symbol: '¢' }],
  },
  'en-GB': {
    [UnitsTypes.LENGTH]: [
      { symbol: 'mm' },
      { symbol: 'cm' },
      { symbol: 'm' },
      { symbol: 'km' },
      { symbol: 'in' },
      { symbol: 'ft' },
      { symbol: 'yd' },
      { symbol: 'mi' },
    ],
    [UnitsTypes.MASS]: [
      { symbol: 'mg' },
      { symbol: 'g' },
      { symbol: 'kg' },
      { symbol: 'ton' },
      { symbol: 'T' },
      { symbol: 'oz' },
      { symbol: 'lb' },
    ],
    [UnitsTypes.AREA]: [
      { symbol: 'mm', mathPower: 2 },
      { symbol: 'cm', mathPower: 2 },
      { symbol: 'm', mathPower: 2 },
      { symbol: 'km', mathPower: 2 },
      { symbol: 'in', mathPower: 2 },
      { symbol: 'ft', mathPower: 2 },
      { symbol: 'yd', mathPower: 2 },
      { symbol: 'mi', mathPower: 2 },
    ],
    [UnitsTypes.VOLUME]: [
      { symbol: 'mm', mathPower: 3 },
      { symbol: 'cm', mathPower: 3 },
      { symbol: 'm', mathPower: 3 },
      { symbol: 'in', mathPower: 3 },
      { symbol: 'ft', mathPower: 3 },
      { symbol: 'ml' },
      { symbol: 'l' },
      { symbol: 'floz' },
      { symbol: 'cup' },
      { symbol: 'pt' },
      { symbol: 'qt' },
      { symbol: 'gal' },
    ],
    [UnitsTypes.TIME]: [{ symbol: 'sec' }, { symbol: 'min' }, { symbol: 'h' }],
    [UnitsTypes.CURRENCY]: [{ symbol: '$' }, { symbol: '€' }, { symbol: '¢' }],
  },
}
