import React from 'react'
import { GalleryCard, StarProgressBar } from '@magmamath/ui'

import styles from './StudentItem.module.scss'
import { getProgressStarCount } from '../../../../helpers'

type StudentItemProps = {
  studentName: string
  studentStats: number
  onClick: () => void
  isDisabled: boolean
}

const StudentItem = ({ studentName, studentStats, onClick, isDisabled }: StudentItemProps) => {
  return (
    <GalleryCard
      title={studentName}
      classes={{ card: styles.StudentItem }}
      onClick={onClick}
      disabled={isDisabled}
      rightContent={
        <StarProgressBar count={3} progress={getProgressStarCount(studentStats)} size={25} />
      }
    />
  )
}

export default StudentItem
