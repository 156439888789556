import { SortingTypes, TypesOfStudentCell } from 'helpers/enums'
import React from 'react'

import { ILocalization } from "../../../../config/languages.config";

export interface IStudentTableHeaderCellProps {
  localization: ILocalization
  order: TypesOfStudentCell
  name: string
  prevSortedCell: TypesOfStudentCell
  changePrevSortedCell: (value: TypesOfStudentCell) => void
  sortExercisesFunc: (typeOfCell: TypesOfStudentCell, value: SortingTypes) => void
}

export interface IStudentTableHeaderCellState {
  sortedCell: TypesOfStudentCell
  sortedType: SortingTypes
}

export default class StudentTableHeaderCell extends React.PureComponent<
  IStudentTableHeaderCellProps,
  any,
  any
> {
  constructor(props: IStudentTableHeaderCellProps) {
    super(props)
    this.state = {
      prevSortedCell: null,
      sortedCell: TypesOfStudentCell.DateCell,
      sortedType: SortingTypes.Desc,
    }
  }

  public componentDidMount() {
    const { sortedCell, sortedType } = this.state

    this.props.sortExercisesFunc(sortedCell, sortedType)
  }

  public UNSAFE_componentWillUpdate(
    prevProps: IStudentTableHeaderCellProps,
    prevState: IStudentTableHeaderCellState
  ) {
    if (prevState.sortedType !== this.state.sortedType) {
      this.props.changePrevSortedCell(prevProps.order)
    }
  }

  public getDefaultState = async (prevState: IStudentTableHeaderCellState) => {
    await this.setState({ prevSortedCell: prevState.sortedCell })
    if (prevState.sortedCell !== this.props.order) {
      this.setState({ sortedType: SortingTypes.Desc })
    }
  }

  public changeSortedType = async (typeOfCell: TypesOfStudentCell) => {
    const { sortedType } = this.state

    this.checkAnotherCell(sortedType).then(async (returnedSortedType: SortingTypes) => {
      const wasFirstClick: boolean = returnedSortedType === SortingTypes.Desc
      const wasSecondClick: boolean = returnedSortedType === SortingTypes.Asc
      await this.setState({ sortedCell: typeOfCell })

      if (wasFirstClick) {
        await this.setState({ sortedType: SortingTypes.Asc })
        this.props.sortExercisesFunc(typeOfCell, SortingTypes.Asc)
      } else if (wasSecondClick) {
        await this.setState({ sortedType: SortingTypes.Desc })
        this.props.sortExercisesFunc(typeOfCell, SortingTypes.Desc)
      }
    })
  }

  public checkAnotherCell = async (sortedType: any) => {
    const isDifferentCell: boolean = !(this.props.prevSortedCell === this.props.order)

    await this.setState({ sortedType: isDifferentCell ? SortingTypes.Desc : sortedType })
    return this.state.sortedType
  }

  public render() {
    const { order, name, prevSortedCell } = this.props
    const { sortedType } = this.state
    const caretClass: string = sortedType === SortingTypes.Desc ? 'fa-caret-down' : 'fa-caret-up'

    return (
      <div className='header-title-wrapper'>
        <div className='header-title-block' onClick={() => this.changeSortedType(order)}>
          <div className='header-title'>
            <span className='header-title'>{name}</span>
          </div>
          {prevSortedCell === order && <i className={`fa ${caretClass} title-icon`} />}
        </div>
      </div>
    )
  }
}
