import React, { PropsWithChildren } from 'react'
import { HistorySaveCredits } from './types'
import { dynamicPaths } from '../../config/pathnames.config'
import { Link } from 'react-router-dom'

type DrawBoardSolutionLinkProps = {
  className?: string
  onPress?: () => void
  credits: HistorySaveCredits
  backgroundImage?: string
}

const DrawBoardSolutionLink = ({
  children,
  className,
  backgroundImage,
  credits,
}: PropsWithChildren<DrawBoardSolutionLinkProps>) => {
  return (
    <Link
      to={() => {
        return {
          pathname: dynamicPaths.extendedView({
            problemId: credits.problemId || '',
            exerciseId: credits.exerciseId,
            studentId: credits.userId,
            subProblemId: credits.subProblemId || '',
          }),
          state: { imgSrc: backgroundImage },
        }
      }}
      className={className}
    >
      {children}
    </Link>
  )
}

export default DrawBoardSolutionLink
