import { MathInputModel, MathInputProps } from './MathInputModel'
import { InlineMathInputModel } from './inline/InlineMathInputModel'
import { PopUpMathInputModel } from './popUp/PopUpMathInputModel'

export enum MathInputVariant {
  INLINE = 'inline',
  POP_UP = 'pop-up',
}
export const MATH_INPUT_MODEL_VARIANT_MAP: Record<
  MathInputVariant,
  (props?: MathInputProps) => MathInputModel
> = {
  [MathInputVariant.INLINE]: (props) => new InlineMathInputModel(props),
  [MathInputVariant.POP_UP]: (props) => new PopUpMathInputModel(props),
}

export const getMathInputVariant = (variant: MathInputVariant) => {
  return MATH_INPUT_MODEL_VARIANT_MAP[variant]
}
