import React from 'react'
import styles from './PlayController.module.scss'
import { Button, BUTTON_VARIANT, COLORS } from '@magmamath/ui'
import UndoIcon from '../../../../../ui/icons/UndoIcon/UndoIcon'
import PauseIcon from '../../../../../ui/icons/PauseIcon/PauseIcon'
import PlayIcon from '../../../../../ui/icons/PlayIcon/PlayIcon'
import RedoIcon from '../../../../../ui/icons/RedoIcon/RedoIcon'

type PlayControllerProps = {
  isPlaying: boolean
  onUndoClick: () => void
  onRedoClick: () => void
  onPlayClick: () => void
}

const PlayController = ({
  isPlaying,
  onUndoClick,
  onRedoClick,
  onPlayClick,
}: PlayControllerProps) => {
  return (
    <div className={styles.ControlButtons}>
      <Button
        icon={<UndoIcon color={COLORS.NEUTRAL_9} />}
        variant={BUTTON_VARIANT.TERTIARY}
        onClick={onUndoClick}
      />
      <Button
        icon={
          isPlaying ? <PauseIcon color={COLORS.NEUTRAL_9} /> : <PlayIcon color={COLORS.NEUTRAL_9} />
        }
        variant={BUTTON_VARIANT.TERTIARY}
        onClick={onPlayClick}
      />
      <Button
        icon={<RedoIcon color={COLORS.NEUTRAL_9} />}
        variant={BUTTON_VARIANT.TERTIARY}
        onClick={onRedoClick}
      />
    </div>
  )
}

export default PlayController
