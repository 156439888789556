import React from 'react'
import { ILocalization } from 'config/languages.config'
import { FormTypes, RemovableTypes } from 'helpers/enums'

export const getWarningText = (type: RemovableTypes | FormTypes, t: ILocalization['data']): string => {
  const textsMap: Partial<Record<RemovableTypes, string>> = {
    [RemovableTypes.Class]: t.deleteClassNote,
    [RemovableTypes.StudentFromClass]: t.deleteStudentNote,
    [RemovableTypes.Section]: t.deleteSectionNote,
    [RemovableTypes.AssessedExercise]: t.assessExamQuestion,
  }
  return textsMap[type] || ''
}
