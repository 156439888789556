import React, { memo, useMemo } from 'react'
import ProblemBlock from 'features/myBook/ProblemBlock/ProblemBlock'
import useText from 'i18n/hook'
import { ILevel, ProblemClickProps } from 'features/myBook/types'
import { useUnit } from 'effector-react'
import styles from './ProblemsList.module.scss'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import { localStorageKeys } from 'config/localStorageKeys'
import { LocaleData } from "helpers/enums"
import { MyBookModel } from '../model'

type ProblemsListProps = {
  level: ILevel,
  onMenuClick: (props: ProblemClickProps) => void
  isDropdownOpen: boolean
}

const ProblemsList = ({
  level,
  onMenuClick,
  isDropdownOpen
}: ProblemsListProps) => {
  const selectedProblem = useUnit(MyBookModel.$selectedProblem)
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const t = useText()
  const me = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  const noProblemsText = `${t.noProblemsAddedForLevel?.(level.name?.toLocaleLowerCase())} ${me?.setting?.appLanguage === LocaleData.SE ? level.complexity : ''} ${t.yetTxt}`

  const { sortedProblems, problemsToShow } = useMemo(() => {
    if (!selectedSection?.problems) return { sortedProblems: [], problemsToShow: [] }
    const sortedProblems = [...selectedSection.problems].sort((a, b) => a.level - b.level)
    const problemsToShow = sortedProblems.filter((problem) => problem.level === level.complexity) ?? []
    return { sortedProblems, problemsToShow }
  }, [selectedSection, level])

  if (problemsToShow.length === 0) {
    return <p className={styles.NoProblemsStub}>{noProblemsText}</p>
  }

  return (
    <>
      {
        problemsToShow.map((problem) => {
          const index = sortedProblems.findIndex((item) => item._id === problem._id) ?? 0
          return (
            <ProblemBlock
              key={problem._id}
              problem={problem}
              problemOrder={index + 1}
              onMenuClick={onMenuClick}
              isMenuBtnActive={selectedProblem?._id === problem._id && isDropdownOpen}
            />
          )
        }
        )
      }
    </>
  )
}

export default memo(ProblemsList)