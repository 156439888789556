import { COLORS } from '@magmamath/ui'
import { ILocalization } from '../../../config/languages.config'

const getPercentageText = (percentage: string, t: ILocalization['data']) =>
  `${percentage}% ${t.correctTxt}`

type MenuItem = {
  getTitle: (t: ILocalization['data']) => string
  color: string
}

export const getMenuContent = (): MenuItem[] => [
  {
    getTitle: (t) => getPercentageText('90-100', t),
    color: COLORS.PRIMARY_GREEN,
  },
  {
    getTitle: (t) => getPercentageText('70-89', t),
    color: COLORS.GREEN_3,
  },
  {
    getTitle: (t) => getPercentageText('50-69', t),
    color: COLORS.PRIMARY_YELLOW,
  },
  {
    getTitle: (t) => getPercentageText('30-49', t),
    color: COLORS.RED_3,
  },
  {
    getTitle: (t) => getPercentageText('0-29', t),
    color: COLORS.PRIMARY_RED,
  },
  {
    getTitle: (t) => t.noAnswer,
    color: COLORS.NEUTRAL_4,
  },
]
