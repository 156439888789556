import React from 'react'
import { useDispatch } from 'react-redux'
import { updateStudentExamStatus } from 'redux/modules/studentsExamStats'
import heatmapStyles from '../../../../../features/tables/HeatmapTable/ui/HeatmapTable/Table.module.scss'
import { updatePendingMutation } from '../../model/pendingMutationStatus'
import UserSettingsButton from '../ExamStatusButtons/UserSettingsButton'
import { IStudentsExamStats } from '../../../../../api/types.statistics'
import { ExerciseState } from '../../../../../api/types.assignments'

export type ChangeStudentExamStatusParams = {
  exerciseId: string
  newExerciseState: ExerciseState
  studentId: string
}

type ExamStatusButtonsSetProps = {
  exerciseId: string
  exerciseState?: ExerciseState
  studentExamStats?: IStudentsExamStats
  userId: string
  isUserDeleted: boolean
  onEndButtonClick: (value: React.SetStateAction<ChangeStudentExamStatusParams | null>) => void
}

const ExamStatusButtonsSet = ({
  exerciseId,
  exerciseState,
  onEndButtonClick,
  studentExamStats,
  userId,
  isUserDeleted,
}: ExamStatusButtonsSetProps) => {
  const dispatch = useDispatch()

  const exerciseSettingsState =
    exerciseState === ExerciseState.DistrictLocked
      ? ExerciseState.DistrictLocked
      : studentExamStats?.examStats?.state || exerciseState

  const changeStudentExamStatus = ({
    exerciseId,
    newExerciseState,
    studentId,
  }: ChangeStudentExamStatusParams) => {
    updatePendingMutation({ mutationType: newExerciseState, userId: studentId })
    return updateStudentExamStatus({
      exerciseId,
      status: newExerciseState,
      studentIds: [studentId],
    })(dispatch)
  }

  return (
    <div key={userId} className={heatmapStyles.ButtonContainers}>
      <UserSettingsButton
        isUserDeleted={isUserDeleted}
        exerciseState={ExerciseState.Started}
        exerciseSettingsState={exerciseSettingsState}
        userId={userId}
        onClick={() =>
          changeStudentExamStatus({
            exerciseId,
            newExerciseState: ExerciseState.Started,
            studentId: userId,
          })
        }
      />
      <UserSettingsButton
        isUserDeleted={isUserDeleted}
        exerciseState={ExerciseState.Stopped}
        exerciseSettingsState={exerciseSettingsState}
        userId={userId}
        onClick={() =>
          changeStudentExamStatus({
            exerciseId,
            newExerciseState: ExerciseState.Stopped,
            studentId: userId,
          })
        }
      />
      <UserSettingsButton
        isUserDeleted={isUserDeleted}
        exerciseState={ExerciseState.Finished}
        exerciseSettingsState={exerciseSettingsState}
        userId={userId}
        onClick={() =>
          onEndButtonClick({
            exerciseId,
            newExerciseState: ExerciseState.Finished,
            studentId: userId,
          })
        }
      />
    </div>
  )
}

export default ExamStatusButtonsSet
