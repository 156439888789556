import { IExercise } from 'api/types.assignments'
import { IProblem } from 'api/types.problem'
import { SortOptions } from './model/sortOption/sortOptionModel'

export const sortProblemsByIds = (ids: string[], problemsResponse: IProblem[]): IProblem[] =>
  [...problemsResponse].sort((a, b) => ids.indexOf(a._id) - ids.indexOf(b._id))

const sortProblemsBySubchapter = (problems: Map<string, IProblem>): Map<string, IProblem> => {
  const groupedProblems = new Map<string, IProblem[]>()

  problems.forEach((problem) => {
    const { sectionId } = problem
    if (!groupedProblems.has(sectionId)) {
      groupedProblems.set(sectionId, [])
    }
    groupedProblems.get(sectionId)!.push(problem)
  })

  const sortedProblems = new Map<string, IProblem>()

  groupedProblems.forEach((problemArray) => {
    problemArray.sort((a, b) => {
      if (a.level !== b.level) {
        return a.level - b.level
      }
      return a.order - b.order
    })

    problemArray.forEach((problem) => {
      sortedProblems.set(problem._id, problem)
    })
  })

  return sortedProblems
}

const sortProblemsByDifficulty = (problems: Map<string, IProblem>): Map<string, IProblem> => {
  const problemArray = Array.from(problems.entries())

  problemArray.sort(([, a], [, b]) => {
    if (a.level !== b.level) return a.level - b.level

    const aIndex = problemArray.findIndex(([key]) => key === a._id)
    const bIndex = problemArray.findIndex(([key]) => key === b._id)

    if (aIndex !== bIndex) return aIndex - bIndex

    return a.order - b.order
  })
  const sortedProblems = new Map<string, IProblem>(problemArray)

  return sortedProblems
}

export const sortProblemsBySortOption = (
  problems: Map<string, IProblem>,
  sortOption: SortOptions.SUBCHAPTER | SortOptions.DIFFICULTY
): Map<string, IProblem> => {
  const problemsCopy = new Map(problems)
  if (sortOption === SortOptions.SUBCHAPTER) return sortProblemsBySubchapter(problemsCopy)
  if (sortOption === SortOptions.DIFFICULTY) return sortProblemsByDifficulty(problemsCopy)
  return problemsCopy
}

type GetSelectedProblemsIdsProps = {
  savedSortedProblemsId: string[] | null
  exercise?: IExercise
  selectedProblems: Set<string>[]
  savedSortOption: SortOptions | null
  selectedProblemsData: Map<string, IProblem> | null
}
export const getSelectedProblemsIds = ({
  savedSortedProblemsId,
  exercise,
  selectedProblems,
  savedSortOption,
  selectedProblemsData,
}: GetSelectedProblemsIdsProps) => {
  if (savedSortedProblemsId) return savedSortedProblemsId

  const selectedIds = selectedProblems.flatMap((set) => Array.from(set))

  if (exercise?._id) {
    if (savedSortOption === SortOptions.MANUAL) {
      const exerciseProblemIds = exercise.problems.map((problem) => problem._id)
      const exerciseSelectedProblemIds = exerciseProblemIds.filter((problem) =>
        selectedIds.includes(problem)
      )
      const additionalSelectedProblemIds = selectedIds.filter(
        (item) => !exerciseProblemIds.includes(item)
      )
      return [...exerciseSelectedProblemIds, ...additionalSelectedProblemIds]
    }
    if (selectedProblemsData && savedSortOption) {
      const sortedProblemsBySortOption = sortProblemsBySortOption(
        selectedProblemsData,
        savedSortOption
      )
      return Array.from(sortedProblemsBySortOption.values()).map((problem) => problem._id)
    }
  }
  return selectedIds
}
