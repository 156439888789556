import React from 'react'

import styles from './SolutionInfo.module.scss'
import Markdown from '../../../../../../../components/RichDescription/RichDescription.component'
import { getDisplayedAnswer } from '../../../../../helpers'
import useText from '../../../../../../../i18n/hook'

type SolutionInfoProps = {
  description: string
  solutionNumber: number
  correctAnswer: [string]
}

const SolutionInfo = ({ description, solutionNumber, correctAnswer }: SolutionInfoProps) => {
  const t = useText()

  return (
    <div className={styles.SolutionInfoContainer}>
      <span className={styles.SolutionNumber}>{solutionNumber}</span>
      <div className={styles.SolutionInfo}>
        <div className={styles.SolutionDescription}>
          <Markdown>{description}</Markdown>
        </div>
        <div className={styles.SolutionCorrectAnswer}>
          <span className={styles.CorrectAnswerTitle}>{t.correctAnswer}</span>
          <span className={styles.CorrectAnswer}>{getDisplayedAnswer(correctAnswer)}</span>
        </div>
      </div>
    </div>
  )
}

export default SolutionInfo
