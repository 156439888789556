import React from 'react'
import Spinner from '../../../Spinner/Spinner'

interface IEmptyExerciseContainerProps {
  heightClass: string
}

const EmptyExerciseContainer: React.FC<IEmptyExerciseContainerProps> = ({ heightClass }) => {
  return <Spinner className={`${heightClass} loader`} />
}

export default EmptyExerciseContainer
