import { TextField } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { NOTIFY } from 'redux/modules/notify.module'
import { ReactComponent as ExitIcon } from '../../../assets/img/exit.svg'
import { config } from '../../../config'
import { ModalType, NotifyType } from '../../../helpers/enums'
import { getStatusFromExercise } from '../../../helpers/exercises.helpers'
import { IState } from '../../../interfaces/state.interface'
import { clearExercise } from '../../../redux/modules/exercise.module'
import { closeShareLinkModal } from '../../../redux/modules/modal.module'
import './ShareLinkModal.scss'
import { ILocalization } from '../../../config/languages.config'
import { IExercise } from '../../../api/types.assignments'

interface IShareLinkModal {
  modal: any
  localization: ILocalization
  exercise: IState<IExercise>
}

const ShareLinkModal: React.FC<IShareLinkModal> = ({ modal, localization, exercise }) => {
  const googleClassroomRef = useRef(null)
  const dispatch = useDispatch()

  const isOpen = modal.data.modalType === ModalType.ShareLinkModal
  const exerciseId = exercise.data._id
  const exerciseStatus = getStatusFromExercise(exercise.data).toLocaleLowerCase()

  const { shareLinkModalBody, shareLinkModalTitle, copyLink, googleClassroom, copied, orTxt } =
    localization.data

  const inputValue = `${config.STUDENTS_HOST}/exercises/${exerciseStatus}/${exerciseId}`

  const onModalClose = () => {
    dispatch(clearExercise())
    dispatch(closeShareLinkModal())
  }

  const onCopyButtonClick = useCallback(() => {
    dispatch({
      type: NOTIFY.NOTIFY_BEGIN,
      payload: { message: copied, type: NotifyType.Success },
    })

    setTimeout(() => {
      dispatch({ type: NOTIFY.NOTIFY_END })
    }, 500)

    navigator.clipboard.writeText(inputValue)
  }, [copied, inputValue])

  useEffect(() => {
    setTimeout(() => {
      // fixed problem when render google-share-class-button from library
      // @ts-ignore
      window.gapi.sharetoclassroom.render('g-sharetoclassroom', {
        url: inputValue,
        size: 40,
        title: exercise.data.name,
      })
    }, 0)
  }, [exercise.data._id])

  return (
    <Modal
      isOpen={isOpen}
      toggle={onModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='share-link'
    >
      <>
        <div className='exit' onClick={onModalClose}>
          <ExitIcon />
        </div>
        <>
          <ModalBody>
            <div className='text-box'>
              <h2 className='text-box-title'>{shareLinkModalTitle}</h2>
              <p className='text-box-paragraph'>{shareLinkModalBody} </p>
            </div>
            <TextField
              variant='standard'
              value={inputValue}
              fullWidth
              className='link-input'
              aria-readonly
            />
            <div className='buttons-container'>
              <button className='copy-button-primary' onClick={onCopyButtonClick}>
                {copyLink}
              </button>
              <span className='or-txt'>{orTxt}</span>
              <div
                id='g-sharetoclassroom'
                ref={googleClassroomRef}
                className='g-sharetoclassroom'
                data-url={inputValue}
                data-size={35}
                data-theme='classic'
              />
              <span className='googleClassroom-txt'>{googleClassroom}</span>
            </div>
          </ModalBody>
        </>
      </>
    </Modal>
  )
}

export default ShareLinkModal
