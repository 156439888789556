import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './HeatmapMenu.module.scss'
import { Button, BUTTON_VARIANT } from '@magmamath/ui'
import { Link } from 'react-router-dom'
import { PATHS } from '../../../config/pathnames.config'
import CrossIcon from '../../../ui/icons/CrossIcon/CrossIcon'

type HeatmapMenuProps = {
  navigation: ReactNode | null
  title: string
  studentsSelect?: ReactNode
  actions: ReactNode
  className?: string
}

const HeatmapMenu = ({ actions, navigation, title, className }: HeatmapMenuProps) => {
  return (
    <header className={clsx(styles.Menu, className)}>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        As={Link}
        to={PATHS.EXERCISES.EXERCISES_MAIN}
        icon={<CrossIcon size={16} color='var(--icon-color)' />}
        classes={{ button: styles.Button }}
      />
      <div className={styles.MenuBar}>
        <p className={styles.Title}>{title}</p>
        <div className={styles.Actions}>{actions}</div>
      </div>
      {navigation}
    </header>
  )
}

export default HeatmapMenu
