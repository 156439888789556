import { BUTTON_COLOR, BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import useText from 'i18n/hook'
import React from 'react'
import styles from './ExerciseModeButtonsSet.module.scss'

type ExerciseModeButtonsSetProps = {
  isExamMode: boolean
  onExerciseModeChange: (isExamMode: boolean) => void
}

const ExerciseModeButtonsSet = ({
  isExamMode,
  onExerciseModeChange,
}: ExerciseModeButtonsSetProps) => {
  const t = useText()
  return (
    <div className={styles.Container}>
      <Button
        classes={{ button: styles.ExerciseModeButton, content: styles.ExerciseModeButtonContent }}
        size={SIZE.LARGE}
        color={!isExamMode ? BUTTON_COLOR.BLUE : undefined}
        variant={!isExamMode ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
        onClick={() => onExerciseModeChange(false)}
        isActive={!isExamMode}
      >
        {t.exerciseTxt}
      </Button>
      <Button
        isActive={isExamMode}
        classes={{ button: styles.ExerciseModeButton, content: styles.ExerciseModeButtonContent }}
        size={SIZE.LARGE}
        variant={isExamMode ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
        color={isExamMode ? BUTTON_COLOR.PURPLE : undefined}
        onClick={() => onExerciseModeChange(true)}
      >
        {t.exam}
      </Button>
    </div>
  )
}

export default ExerciseModeButtonsSet
