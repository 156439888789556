import React from 'react'
import ExerciseParticipantsSingleName from './ExerciseParicipantsName'
import { ExerciseParticipantsInfo } from './helpers'
import { useStoreMap } from 'effector-react'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import styles from './ExerciseParticipants.module.scss'
import clsx from 'clsx'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import useText from 'i18n/hook'

type ExerciseParticipantsContentProps = {
  participants: string[]
  textForSingle: string
  textForPlural: string
  participantsInfo: ExerciseParticipantsInfo
  isParentTooltipVisible: boolean
}

const ExerciseParticipantsContent = ({
  participants,
  textForSingle,
  textForPlural,
  participantsInfo,
  isParentTooltipVisible
}: ExerciseParticipantsContentProps) => {
  const { participantsData, isTooltipVisible, isShowAll } = participantsInfo
  const isAnonymousNameMode = useStoreMap($heatmapSettings, (settings) => settings.isAnonymousNameMode)
  const t = useText()
  const { overflowRef, isOverflowing } = useOverflowDetection()

  const hasParticipants = !!participants.length
  const areStudentsNamesHidden = hasParticipants && isAnonymousNameMode && textForSingle === t.studentTxt
  const isParticipantsNumberVisible = hasParticipants && isTooltipVisible
  const isSingleRow = participantsData.isSingleType && !isOverflowing

  if (areStudentsNamesHidden || isParticipantsNumberVisible) {
    return (
      <div
        ref={overflowRef}
        className={clsx(styles.SingleName, styles.Lowercase, {
          [styles.Ellipsis]: !participantsData.isSingleType,
          [styles.SingleRow]: isSingleRow,
        })}
        data-skip-redirect
      >
        {`${participants.length} ${participants.length === 1 ? textForSingle : textForPlural}`}
      </div>
    )
  }

  if (hasParticipants && isShowAll) {
    return (
      <>
        {participants.map((participant, index) => (
          <ExerciseParticipantsSingleName
            key={index}
            name={participant}
            participantsData={participantsData}
            isParentTooltipVisible={isParentTooltipVisible}
          />
        ))}
      </>
    )
  }

  return (
    <ExerciseParticipantsSingleName
      name={participants[0]}
      participantsData={participantsData}
      isParentTooltipVisible={isParentTooltipVisible}
    />
  )
}

export default ExerciseParticipantsContent
