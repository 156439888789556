import SectionsList from 'features/myBook/SectionsList/SectionsList'
import { ViewContainer } from 'components/ViewContainer/ViewContainer.component'
import { localStorageKeys } from 'config/localStorageKeys'
import { getItemFromLocalStorageWithParsing, setItemToLocalStorage } from 'helpers/storage.helper'
import { IState } from 'interfaces/state.interface'
import React, { Fragment, useState, useEffect, memo } from 'react'
import { IProblems } from 'redux/modules/problems.module'
import Spinner from '../../../features/Spinner/Spinner'
import useText from 'i18n/hook'
import { getLevelsData, getSelectedSectionId, findSectionById } from './helpers'
import { useUnit } from 'effector-react'
import ConfirmDeleteModal from 'features/myBook/ConfirmDeleteModal/ConfirmDeleteModal'
import { ConfirmModalState } from 'features/myBook/types'
import { ConfirmModalMode } from 'helpers/enums'
import styles from './MyBook.module.scss'
import MyBookHeader from 'features/myBook/MyBookHeader/MyBookHeader'
import { useHistory } from 'react-router'
import ProblemsColumn from 'features/myBook/ProblemsColumn/ProblemsColumn'
import { MyBookModel } from 'features/myBook/model'
import { ISection } from '../../../api/types.books'

type CreatedProblemsProps = {
  problems: IState<IProblems>
  section: IState<ISection>
}

const MyBook = ({ problems, section }: CreatedProblemsProps) => {
  const books = useUnit(MyBookModel.$books)
  const selectedSection = useUnit(MyBookModel.$selectedSection)
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({
    mode: ConfirmModalMode.SECTION,
    isOpen: false,
  })
  const t = useText()
  const levelsData = getLevelsData(t.easyLevel, t.mediumLevel, t.hardLevel)
  const history = useHistory()

  useEffect(() => {
    if (selectedSection) {
      setItemToLocalStorage(localStorageKeys.section, JSON.stringify(selectedSection))
    }
  }, [selectedSection])

  useEffect(() => {
    MyBookModel.fetchBooksFx()
    return () => MyBookModel.resetBooks()
  }, [])

  useEffect(() => {
    if (books) {
      const sectionFromStorage: ISection = getItemFromLocalStorageWithParsing(
        localStorageKeys.section
      )
      const sectionId = getSelectedSectionId(section, sectionFromStorage)
      const selectedSection = findSectionById(books, sectionId)
      MyBookModel.setSelectedSection(selectedSection)
    }
  }, [books])

  const handleModalToggle = () =>
    setConfirmModalState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }))

  const handleSectionDeleteClick = () =>
    setConfirmModalState({ mode: ConfirmModalMode.SECTION, isOpen: true })

  const handleProblemDeleteClick = () =>
    setConfirmModalState({ mode: ConfirmModalMode.PROBLEM, isOpen: true })

  return (
    <Fragment>
      <MyBookHeader />
      <ViewContainer className={styles.ViewWrapper} history={history} model={section}>
        {!selectedSection ? (
          <Spinner className={styles.Loader} />
        ) : (
          <div className={styles.ContentWrapper}>
            <div className={styles.SectionsListWrapper}>
              <SectionsList onSectionDeleteClick={handleSectionDeleteClick} />
            </div>
            {!problems.loading && (
              <div className={styles.ProblemsListWrapper}>
                <div className={styles.LevelsRow}>
                  {levelsData.map((level) => (
                    <div key={level.name} className={styles.LevelColumn}>
                      <ProblemsColumn
                        level={level}
                        onProblemDeleteClick={handleProblemDeleteClick}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </ViewContainer>
      {selectedSection && (
        <ConfirmDeleteModal onModalToggle={handleModalToggle} {...confirmModalState} />
      )}
    </Fragment>
  )
}

export default memo(MyBook)
