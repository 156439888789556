import { ValueMiddlewareSettings } from '../mathInput/static/StaticMathInputModel'
import { KatexValueParser } from '../parser/KatexValueParser'
import { refocus } from './helpers'
import { LocaleData } from '../../../helpers/enums'
import { currentLocale } from '../../../config/modules.config'

type LocalizedRegexp = Record<LocaleData, RegExp>
const createLocalizedRegexp = (fn: (localizedItem: string) => RegExp) => {
  return {
    [LocaleData.SE]: fn(','),
    [LocaleData.US]: fn('.'),
    [LocaleData.GB]: fn('.'),
  }
}

export const NUMBERS_SYMBOLS = createLocalizedRegexp(
  (localized) => new RegExp(`\\d\\-${localized}\\/%\\^`)
)

export const ALGEBRA_SYMBOLS = createLocalizedRegexp(
  (localized) => new RegExp(`\\d\\-+ ${localized}\\/%=xyz\\^`)
)

export const createLocalizedMiddleware = (localizedAcceptedSymbols: LocalizedRegexp) => {
  return (value: string, prevValue: string, settings?: ValueMiddlewareSettings) => {
    const acceptedSymbols = localizedAcceptedSymbols[currentLocale.currentLang]
    const changed = new KatexValueParser(value, { acceptedSymbols }).unwrap().value()
    if (changed === prevValue && settings) refocus(settings)
    return changed
  }
}

export const mathNumberFilter = createLocalizedMiddleware(NUMBERS_SYMBOLS)

export const algebraFilter = createLocalizedMiddleware(ALGEBRA_SYMBOLS)
