import { Problem } from '@magmamath/ui'
import { IProblem } from 'api/types.problem'
import clsx from 'clsx'
import { getProblemImageUrl } from 'features/heatmap/Problem/helpers/imageUrl'
import React, { forwardRef, RefObject } from 'react'
import UICard from 'ui/cards/Card/Card'
import PopoverMenu from '../PopoverMenu/PopoverMenu'
import styles from './ProblemItem.module.scss'
import { mapProblemLevelToClass } from './helpers'

type ProblemItemProps = {
  problem: IProblem
  order: number
  problemsCount: number
}

const ProblemItem = forwardRef(
  ({ problem, order, problemsCount }: ProblemItemProps, cardRef: RefObject<HTMLDivElement>) => (
    <UICard ref={cardRef} className={styles.Card}>
      <Problem
        problem={problem}
        order={order}
        imageLinkGetter={getProblemImageUrl}
        className={clsx(styles.ProblemItem, mapProblemLevelToClass(problem.level))}
        menu={
          <PopoverMenu
            problemsCount={problemsCount}
            order={order}
            problemId={problem._id}
            sectionId={problem.sectionId}
          />
        }
      />
    </UICard>
  )
)

export default ProblemItem
