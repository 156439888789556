import React from 'react'
import { SvgIconProps } from '../types/types'

const SortIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8116 2.75C16.2258 2.75 16.5616 3.08579 16.5616 3.5V14.6897L18.6938 12.5575C18.9867 12.2646 19.4616 12.2646 19.7545 12.5575C20.0473 12.8504 20.0473 13.3253 19.7545 13.6182L16.3419 17.0307C16.2013 17.1713 16.0105 17.2504 15.8116 17.2504C15.6127 17.2504 15.4219 17.1713 15.2813 17.0307L11.8688 13.6182C11.5759 13.3253 11.5759 12.8504 11.8688 12.5575C12.1617 12.2646 12.6366 12.2646 12.9295 12.5575L15.0616 14.6897V3.5C15.0616 3.08579 15.3974 2.75 15.8116 2.75ZM0.0258789 3.73364C0.0258789 3.31943 0.361665 2.98364 0.775879 2.98364H11.7759C12.1901 2.98364 12.5259 3.31943 12.5259 3.73364C12.5259 4.14786 12.1901 4.48364 11.7759 4.48364H0.775879C0.361665 4.48364 0.0258789 4.14786 0.0258789 3.73364ZM0.0258789 7.73364C0.0258789 7.31943 0.361665 6.98364 0.775879 6.98364H9.77588C10.1901 6.98364 10.5259 7.31943 10.5259 7.73364C10.5259 8.14786 10.1901 8.48364 9.77588 8.48364H0.775879C0.361665 8.48364 0.0258789 8.14786 0.0258789 7.73364ZM0.0258789 11.7336C0.0258789 11.3194 0.361665 10.9836 0.775879 10.9836H5.77588C6.19009 10.9836 6.52588 11.3194 6.52588 11.7336C6.52588 12.1479 6.19009 12.4836 5.77588 12.4836H0.775879C0.361665 12.4836 0.0258789 12.1479 0.0258789 11.7336ZM0.0258789 15.7336C0.0258789 15.3194 0.361665 14.9836 0.775879 14.9836H3.77588C4.19009 14.9836 4.52588 15.3194 4.52588 15.7336C4.52588 16.1479 4.19009 16.4836 3.77588 16.4836H0.775879C0.361665 16.4836 0.0258789 16.1479 0.0258789 15.7336Z'
        fill={color}
      />
    </svg>
  )
}

export default SortIcon
