import React from 'react'
import { BUTTON_COLOR, BUTTON_VARIANT, Button, ICON_ALIGNMENT, SIZE, COLORS } from '@magmamath/ui'
import styles from './MyBookHeader.module.scss'
import ArrowBackIcon from 'ui/icons/ArrowBackIcon/ArrowBackIcon'
import { Route, useHistory } from 'react-router'
import { PATHS } from 'config/pathnames.config'
import AddIcon from 'ui/icons/AddIcon/AddIcon'
import useText from 'i18n/hook'
import { Link } from 'react-router-dom'

const MyBookHeader = () => {
  const history = useHistory<{ prevPath?: string }>()
  const t = useText()

  return (
    <div className={styles.Header}>
      <div className={styles.HeaderLeftPart}>
        <div className={styles.BtnWrapper}>
          <Button
           classes={{ button: styles.BackButton }}
           As={Link}
           to={
             history.location.state?.prevPath
               ? history.location.state.prevPath
               : PATHS.CONTENT.BOOKSERIES
           }
           variant={BUTTON_VARIANT.TERTIARY}
           icon={<ArrowBackIcon color={COLORS.NEUTRAL_9} size={12} />}
          />
        </div>
        <p className={styles.Title}>{t.myBookTxt}</p>
      </div>
      <div className={styles.HeaderRightPart}>
        <Route
          exact={true}
          path={PATHS.CONTENT.PROBLEMS}
          render={() => (
            <Button
              size={SIZE.LARGE}
              variant={BUTTON_VARIANT.PRIMARY}
              color={BUTTON_COLOR.ORANGE}
              icon={<AddIcon size={16} color={COLORS.NEUTRAL_1} />}
              iconAlign={ICON_ALIGNMENT.RIGHT}
              onClick={() => {
                setTimeout(() => {
                  history.push(PATHS.CONTENT.ADD_NEW_PROBLEM)
                  //show the button click animation and only then redirect
                }, 500)
              }}
            >
              {t.addNewProblemTxt}
            </Button>
          )}
        />
      </div>
    </div>
  )
}


export default MyBookHeader
