import { NUMBER_OF_COLUMNS, PROBLEM_LEVELS } from './types'
import { IProblem } from '../../../../api/types.problem'

export const getProblemsByLevel = (problems: IProblem[]) => {
  const levels = new Map<number, IProblem[]>()

  return problems.reduce((acc, problem) => {
    const level = problem.level
    if (acc.get(level)) {
      acc.get(problem.level)?.push(problem)
      return acc
    }
    acc.set(level, [problem])
    return acc
  }, levels)
}

export const getProblemLevelColumns = (
  problemsByLevel: Map<PROBLEM_LEVELS, IProblem[]>,
  level: PROBLEM_LEVELS
) => {
  const levelsCount = problemsByLevel.size
  switch (levelsCount) {
    case 1:
      return NUMBER_OF_COLUMNS.THREE
    case 2:
      switch (level) {
        case PROBLEM_LEVELS.EASY:
          return NUMBER_OF_COLUMNS.TWO
        case PROBLEM_LEVELS.MEDIUM:
          return problemsByLevel.has(PROBLEM_LEVELS.EASY)
            ? NUMBER_OF_COLUMNS.ONE
            : NUMBER_OF_COLUMNS.TWO
        case PROBLEM_LEVELS.HARD:
          return NUMBER_OF_COLUMNS.ONE
      }
    case 3:
      return NUMBER_OF_COLUMNS.ONE
    default:
      return NUMBER_OF_COLUMNS.ONE
  }
}
