import React from 'react'
import styles from './ProblemCreatorBody.module.scss'
import ProblemCreatorSideBar from './ProblemCreatorSideBar/ProblemCreatorSideBar'
import ProblemCreatorMain from './ProblemCreatorMain'

const ProblemCreatorBody = () => {
  return (
    <div className={styles.Container}>
      <ProblemCreatorMain />
      <ProblemCreatorSideBar />
    </div>
  )
}

export default ProblemCreatorBody
