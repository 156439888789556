import clsx from 'clsx'
import CreateGroupModal from 'components/Modals/CreateGroupModal/CreateGroupModal'
import StudentsGroupOptionsMenu from 'features/tables/StudentsTable/StudentTableRow/StudentsGroupOptionsMenu/StudentsGroupOptionsMenu'
import { SearchParam } from 'helpers/enums'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { openGroupStudentAutosuggest } from 'redux/modules/modal.module'
import { isSingleGroupPage } from 'screens/Students/Students'
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import PlusIcon from 'ui/icons/PlusIcon/PlusIcon'
import StudentsTableUI from 'ui/tables/StudentsTableUI/StudentsTableUI'
import StudentsTableUIRow from 'ui/tables/StudentsTableUI/StudentsTableUIRow/StudentsTableUIRow'
import GroupsTab from '../GroupsTab/GroupsTab'
import styles from './GroupsTabContent.module.scss'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import { ClassroomGroup } from '../../../../api/types.classes'

type GroupsTabContentProps = {
  groups: ClassroomGroup[]
  classroomId: string
}

const getActiveGroup = (queryString?: string, groups?: ClassroomGroup[]) => {
  if (!groups || !queryString) return null

  const regex = /(?:\?|&)group=([^&]+)/
  const match = queryString.match(regex)
  if (match) return groups.find((group) => group._id === match[1]) ?? null

  return null
}

const GroupsTabContent = ({ groups, classroomId }: GroupsTabContentProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const t = useText()
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false)

  const goToGroupsTab = () => {
    history.replace({
      pathname: history.location.pathname,
      search: SearchParam.GROUPS_TAB,
    })
  }

  if (isSingleGroupPage(history.location.search)) {
    const activeGroup = getActiveGroup(history.location.search, groups)

    if (!activeGroup) return null

    const openGroupStudentsList = () => {
      const students: any = activeGroup.students
      dispatch(openGroupStudentAutosuggest(students, activeGroup._id))
    }

    return (
      <>
        <div className={clsx(styles.Header, styles.SpaceBottom)}>
          <div className={styles.GoBackContainer} onClick={goToGroupsTab}>
            <ArrowLeftIcon color={COLORS.NEUTRAL_9} size={17} strokeWidth='2.5' />
            <span className={styles.GoBackText}>{t.allGroupsTxt}</span>
          </div>
          <div className={styles.HeaderTitle}>{activeGroup.name}</div>
          <Button
            classes={{
              button: clsx({
                [styles.Hidden]: !activeGroup.students?.length,
              }),
            }}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.MEDIUM}
            color={BUTTON_COLOR.ORANGE}
            onClick={openGroupStudentsList}
            icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
            iconAlign={ICON_ALIGNMENT.RIGHT}
          >
            {t.addStudentTxt}
          </Button>
        </div>
        {!!activeGroup.students?.length ? (
          <StudentsTableUI>
            {activeGroup.students.map((student) => (
              <StudentsTableUIRow key={student._id} student={student}>
                <StudentsGroupOptionsMenu student={student} group={activeGroup} />
              </StudentsTableUIRow>
            ))}
          </StudentsTableUI>
        ) : (
          <div className={styles.Container}>
            <Button
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZE.LARGE}
              color={BUTTON_COLOR.ORANGE}
              onClick={openGroupStudentsList}
              icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
              iconAlign={ICON_ALIGNMENT.RIGHT}
            >
              {t.addStudentTxt}
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {groups.length > 0 && (
        <div className={styles.Header}>
          <Button
            classes={{
              button: styles.NewGroupBtn,
            }}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZE.MEDIUM}
            color={BUTTON_COLOR.ORANGE}
            onClick={() => setIsGroupModalOpen(true)}
            icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={14} />}
            iconAlign={ICON_ALIGNMENT.RIGHT}
          >
            {t.newGroupWithoutPlusTxt}
          </Button>
        </div>
      )}

      <GroupsTab groups={groups} onEmptyGroupsButtonClick={() => setIsGroupModalOpen(true)} />

      <CreateGroupModal
        open={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        classroomId={classroomId}
      />
    </>
  )
}

export default GroupsTabContent
