import { COLORS } from '@magmamath/ui'

export const INPUT_STYLES = {
  sx: {
    '& .MuiInputBase-input': {
      border: '1px solid var(--neutral-6)',
      borderRadius: '10px',
      backgroundColor: '#fff',
      color: COLORS.NEUTRAL_10,
      margin: '0px 16px',
      padding: '8.5px 14px',
      marginRight: '0px',
      width: '33%',
      minWidth: '330px',

      '&::placeholder': {
        color: COLORS.NEUTRAL_6,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
      margin: 0,
    },
  },
}
