import { combine, createEvent, createStore } from 'effector'
import { IStudent } from '../../../api/types.users'
import { compareGradesArrays, sortNumbersASC } from '../helpers'
import { fetchStudentsByClassFx, $isFetchingStudents } from './requests'
import { StudentsGrades, StudentGrades, StudentsGradesData } from './types'

export const AVAILABLE_GRADES = Array.from({ length: 13 }, (_, i) => i)

export const setAllStudentsGrades = createEvent<number[]>()
export const setSingleStudentGrades = createEvent<StudentGrades>()

export const applyToAllStudents = createEvent()

export const reset = createEvent()

const $studentsGradesStore = createStore<StudentsGrades>({
  students: [],
  gradesInfo: {},
  allStudentsGrades: [],
  isValuesMixed: true,
  isDataDirty: false,
  isDataChanged: false,
  shouldApplyForAll: false,
})
  .on(fetchStudentsByClassFx.doneData, (state, students) => {
    const studentsGrades = students.reduce((accumulator: StudentsGradesData, value: IStudent) => {
      const studentLockedGrades = value.setting?.practiceModeLockedGrades
      return {
        ...accumulator,
        [value._id]: !studentLockedGrades?.length
          ? AVAILABLE_GRADES
          : AVAILABLE_GRADES.filter((grade) => !studentLockedGrades.includes(grade)),
      }
    }, {})
    const gradesArray = Object.values(studentsGrades)
    const isEqualGrades = compareGradesArrays(gradesArray)

    return {
      ...state,
      students,
      gradesInfo: studentsGrades,
      isValuesMixed: !isEqualGrades,
      allStudentsGrades: isEqualGrades ? gradesArray[0] : [],
    }
  })
  .on(setAllStudentsGrades, (state, grades) => {
    return {
      ...state,
      allStudentsGrades: grades.sort(sortNumbersASC),
      isValuesMixed: false,
      isDataDirty: true,
      shouldApplyForAll: true,
    }
  })
  .on(setSingleStudentGrades, (state, studentGrades) => {
    const newGradesInfo = {
      ...state.gradesInfo,
      [studentGrades._id]: studentGrades.grades.sort(sortNumbersASC),
    }

    const gradesArray = Object.values(newGradesInfo)
    const isEqualGrades = compareGradesArrays(gradesArray)
    return {
      ...state,
      gradesInfo: newGradesInfo,
      isValuesMixed: !isEqualGrades,
      isDataChanged: true,
    }
  })
  .on(applyToAllStudents, (state) => {
    const newGradesInfo = Object.keys(state.gradesInfo).reduce((accumulator, key) => {
      return { ...accumulator, [key]: state.allStudentsGrades }
    }, {})

    return {
      ...state,
      gradesInfo: newGradesInfo,
      isValuesMixed: false,
      shouldApplyForAll: false,
      isDataDirty: false,
      isDataChanged: true,
    }
  })
  .reset(reset)

export const $studentsGradesData = combine({
  studentsGrades: $studentsGradesStore,
  isFetchingStudents: $isFetchingStudents,
})
