import { IDispatch } from '../../interfaces/dispatch.interface'
import { IAction } from '../../interfaces/actions.interface'
import _, { join } from 'lodash'
import { ISkill } from '../../api/types.oldSkills'
import { api } from '../../api'

export enum SKILLS {
  GET_ALL_SKILLS_REQUEST = 'GET_ALL_SKILLS_REQUEST',
  GET_ALL_SKILLS_SUCCESS = 'GET_ALL_SKILLS_SUCCESS',
  GET_ALL_SKILLS_ERROR = 'GET_ALL_SKILLS_ERROR',
  CLEAR_ALL_SKILLS = 'CLEAR_ALL_SKILLS',
}

const initialState = {
  data: {},
  error: null,
  loading: false,
}

export const skillsReducer = (state: any = initialState, action: IAction<SKILLS>) => {
  switch (action.type) {
    case SKILLS.GET_ALL_SKILLS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SKILLS.GET_ALL_SKILLS_SUCCESS:
      const oldSkills = state.data?.items || []
      const newSkills = action.payload?.items || []
      const resNewSkills = _.uniqBy([...oldSkills, ...newSkills], '_id')
      return {
        data: { ...action.payload, items: [...resNewSkills] },
        error: null,
        loading: false,
      }
    case SKILLS.GET_ALL_SKILLS_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case SKILLS.CLEAR_ALL_SKILLS:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const getSkills = async (
  offset = 0,
  dispatch: IDispatch<any>,
  ids: string[]
): Promise<any> => {
  const limit = 50
  const params = { limit, offset, ids: join(ids, ',') }
  dispatch({ type: SKILLS.GET_ALL_SKILLS_REQUEST })
  const res = await api.oldSkills.getAll(params)
  dispatch({ type: SKILLS.GET_ALL_SKILLS_SUCCESS, payload: res })
  if (res.items.length === limit) {
    return getSkills(offset + limit, dispatch, ids)
  }
}

export function reloadAllSkills(ids: string[]) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SKILLS.CLEAR_ALL_SKILLS })
      getSkills(0, dispatch, ids)
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SKILLS.GET_ALL_SKILLS_ERROR,
      })
    }
  }
}

export function clearAllSkills() {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: SKILLS.CLEAR_ALL_SKILLS })
  }
}

export interface ISkills {
  items: ISkill[]
}

export interface ISkillsActions {
  reloadAllSkills: () => void
  clearAllSkills: () => void
}
