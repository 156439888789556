import clsx from 'clsx'
import { useStore } from 'effector-react'
import ExamSettings from 'features/heatmap/ExamMode/ui/ExamSettings/ExamSettings'
import {
  COLUMNS_TO_PRINT_PER_PAGE,
  EXAM_MODE_COLUMN_WIDTH,
  SCROLL_WIDTH,
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEADER_WIDTH,
} from 'features/tables/HeatmapTable/constants'
import { mergeProblemsAndSubProblems } from 'features/tables/HeatmapTable/helpers/data'
import { getTableListHeight } from 'features/tables/HeatmapTable/helpers/ui'
import { $heatmapSettings } from 'features/tables/HeatmapTable/model/heatmapSettings'
import {
  HeatmapTableRef,
  HeatmapTestIds,
  OnHoverStateChangeParams,
} from 'features/tables/HeatmapTable/types'
import { ITeacher } from 'api/types.users'
import useScrollPosition from 'lib/hooks/useScrollPosition'
import chunk from 'lodash/chunk'
import React, { useCallback, useMemo, useRef } from 'react'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { $sortedStudents } from '../../model/exercise'
import TableScrollButtons from '../ScrollButtons/ScrollButtons'
import styles from './Table.module.scss'
import TableContent from './TableContent'
import { IStudentsExamStats } from '../../../../../api/types.statistics'

type HeatmapTableProps = {
  exerciseWithoutStatsData: IExerciseWithoutStats
  isPrint?: boolean
  teacher: ITeacher
  studentsExamStatsData?: IStudentsExamStats[] | {}
}

const HeatmapTable = ({
  exerciseWithoutStatsData,
  isPrint,
  teacher,
  studentsExamStatsData,
}: HeatmapTableProps) => {
  const tableRef = useRef<HeatmapTableRef>(null)

  const scrollPositionData = useScrollPosition(tableRef)

  const settings = useStore($heatmapSettings)

  const hasExamModeStatusColumn = exerciseWithoutStatsData.testModeEnabled && !settings.isCompressed

  const scrollThresholdX = tableRef.current?.clientWidth
    ? tableRef.current.clientWidth -
      TABLE_ROW_HEADER_WIDTH -
      SCROLL_WIDTH -
      (hasExamModeStatusColumn ? EXAM_MODE_COLUMN_WIDTH : 0)
    : 0

  const scrollThresholdY = tableRef.current?.clientHeight
    ? tableRef.current.clientHeight - TABLE_HEADER_HEIGHT - SCROLL_WIDTH
    : 0

  const sortedStudents = useStore($sortedStudents)

  const rowsUsers = [...sortedStudents, teacher]

  const tableListHeight = getTableListHeight({
    numberOfUsers: rowsUsers.length,
    isCompressed: settings.isCompressed && !isPrint,
  })

  const mergedProblemsAndSubProblems = useMemo(
    () => mergeProblemsAndSubProblems(exerciseWithoutStatsData.problems),
    [exerciseWithoutStatsData.problems]
  )

  const isExamMode =
    exerciseWithoutStatsData.testModeEnabled && !exerciseWithoutStatsData.archived && !isPrint

  const isCompressedMode = settings.isCompressed && !isPrint

  const studentsExamStatsMap = useMemo(() => {
    if (!Array.isArray(studentsExamStatsData)) return
    return new Map(studentsExamStatsData.map((studentStat) => [studentStat.studentId, studentStat]))
  }, [studentsExamStatsData])

  const chunkedProblemsAndSubProblems = useMemo(() => {
    return isPrint ? chunk(mergedProblemsAndSubProblems, COLUMNS_TO_PRINT_PER_PAGE) : []
  }, [mergedProblemsAndSubProblems, isPrint])

  const tableElementStyles = clsx(styles.Table, {
    [styles.ScrolledX]: !scrollPositionData.scrollStatus.isScrolledToLeft,
    [styles.ScrolledY]: !scrollPositionData.scrollStatus.isScrolledToTop,
    [styles.AnonymousCorrectnessMode]: settings.isAnonymousCorrectnessMode,
    [styles.ExamModeState]: isExamMode,
    [styles.Compressed]: isCompressedMode,
  })

  const onHeaderElementHoverChange = useCallback(
    ({ elementIndex, newIsHovered, orientation }: OnHoverStateChangeParams) => {
      const elements = tableRef.current?.querySelectorAll(`[data-${orientation}="${elementIndex}"]`)
      elements?.forEach((element) => {
        newIsHovered ? element.classList.add(styles.Hover) : element.classList.remove(styles.Hover)
      })
    },
    [tableRef.current]
  )

  if (isPrint)
    return (
      <>
        {chunkedProblemsAndSubProblems.map((chunk, index) => (
          <div key={index} className={clsx(styles.TableContainer, { [styles.Print]: isPrint })}>
            <div className={tableElementStyles}>
              <TableContent
                exerciseId={exerciseWithoutStatsData._id}
                isAnonymousNameMode={settings.isAnonymousNameMode}
                isCompressedMode={false}
                isExamMode={false}
                onHeaderElementHoverChange={onHeaderElementHoverChange}
                mergedProblemsAndSubProblems={chunk}
                rowsUsers={rowsUsers}
                tableListHeight={tableListHeight}
                exerciseState={exerciseWithoutStatsData.settings?.state}
              />
            </div>
          </div>
        ))}
      </>
    )

  return (
    <div
      className={clsx(styles.TableContainer, {
        [styles.Compressed]: isCompressedMode,
        [styles.ExamModeState]: isExamMode,
      })}
    >
      {isExamMode && studentsExamStatsMap && (
        <ExamSettings
          exerciseWithoutStatsData={exerciseWithoutStatsData}
          studentsExamStatsMap={studentsExamStatsMap}
        />
      )}

      <div className={tableElementStyles} ref={tableRef} data-testid={HeatmapTestIds.TABLE}>
        <TableContent
          exerciseId={exerciseWithoutStatsData._id}
          isAnonymousNameMode={settings.isAnonymousNameMode}
          isCompressedMode={isCompressedMode}
          isExamMode={isExamMode}
          onHeaderElementHoverChange={onHeaderElementHoverChange}
          mergedProblemsAndSubProblems={mergedProblemsAndSubProblems}
          rowsUsers={rowsUsers}
          tableListHeight={tableListHeight}
          studentsExamStatsMap={studentsExamStatsMap}
          exerciseState={exerciseWithoutStatsData.settings?.state}
        />
      </div>
      <TableScrollButtons
        scrollPositionData={scrollPositionData}
        scrollThresholdX={scrollThresholdX}
        scrollThresholdY={scrollThresholdY}
        isCompressedMode={isCompressedMode}
        isExamMode={isExamMode}
      />
    </div>
  )
}

export default HeatmapTable
