import { Button, BUTTON_VARIANT, ModalSideBar, SIZE } from '@magmamath/ui'
import { IExercise } from 'api/types.assignments'
import { IProblem } from 'api/types.problem'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { toast } from 'features/ToastModel/ToastModel'
import { $exerciseSettings } from 'features/caf/exerciseSettings/model/exerciseSettings'
import useText from 'i18n/hook'
import useHiddenIntercom from 'lib/hooks/useHiddenIntercom'
import React, { useEffect } from 'react'
import PenIcon from 'ui/icons/PenIcon/PenIcon'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import { $selectedProblems } from '../Book/model/selectedProblems'
import OrderSelection from './OrderSelection/OrderSelection'
import ProblemsList from './ProblemsList/ProblemsList'
import styles from './ReorderProblems.module.scss'
import { sortProblemsBySortOption } from './helpers'
import { dndSelectedProblemsModel } from './model/dndSelectedProblems'
import { reorderModalStateModel } from './model/isReorderModalOpen'
import { fetchProblemsByIdsFx } from './model/requests'
import { savedSortedProblemsIdModel } from './model/savedSortedSelectedProblemIds'
import { selectedProblemsDataModel } from './model/selectedProblemsData'
import { currentSortOptionModel } from './model/sortOption/currentSortOption'
import { savedSortOptionModel } from './model/sortOption/savedSortOption'
import { SortOptions } from './model/sortOption/sortOptionModel'

type ReorderProblemsProps = {
  exercise?: IExercise
}

const ReorderProblems = ({ exercise }: ReorderProblemsProps) => {
  const t = useText()
  const isExamMode = useStoreMap($exerciseSettings, (state) => state.isExamMode)
  const sortOption = useUnit(currentSortOptionModel.$state)
  const isReorderModalOpen = useUnit(reorderModalStateModel.$state)
  const selectedProblemsData = useUnit(selectedProblemsDataModel.$state)
  const dndSelectedProblems = useUnit(dndSelectedProblemsModel.$state)
  const savedSortOption = useUnit(savedSortOptionModel.$state)
  const savedSortedProblemsIds = useUnit(savedSortedProblemsIdModel.$state)
  const selectedProblems = useStoreMap($selectedProblems, (selectedProblems) =>
    [...selectedProblems.values()].filter((set) => set.size > 0)
  )

  useHiddenIntercom({ isHidden: isReorderModalOpen })

  const getSelectedProblemIds = () => {
    if (sortOption === SortOptions.MANUAL) {
      if (!dndSelectedProblems) return [] as string[]
      return dndSelectedProblems.map((problem) => problem._id)
    }

    if (!selectedProblemsData) {
      return selectedProblems.flatMap((set) => Array.from(set))
    }
    const sortedProblemsBySortOption = sortProblemsBySortOption(selectedProblemsData, sortOption)
    return Array.from(sortedProblemsBySortOption.values()).map((problem) => problem._id)
  }

  const onCancel = () => {
    currentSortOptionModel.reset()
    dndSelectedProblemsModel.reset()
    reorderModalStateModel.setState(false)

    if (savedSortOption) currentSortOptionModel.set(savedSortOption)

    if (savedSortedProblemsIds && selectedProblemsData) {
      const savedSortedProblems: IProblem[] = []

      savedSortedProblemsIds.forEach((id) => {
        const problemData = selectedProblemsData.get(id)
        if (problemData) savedSortedProblems.push(problemData)
      })
      dndSelectedProblemsModel.set(savedSortedProblems)
      return
    }

    if (exercise?._id) dndSelectedProblemsModel.set(exercise.problems)
  }

  const onSave = () => {
    const selectedProblemIds = getSelectedProblemIds()
    savedSortedProblemsIdModel.set(selectedProblemIds)
    savedSortOptionModel.set(sortOption)
    reorderModalStateModel.setState(false)
    toast.confirmation({ props: { title: t.orderSavedTxt } })
  }

  useEffect(() => {
    if (!savedSortedProblemsIds || !savedSortOption || exercise?._id) return

    if (savedSortOption === SortOptions.MANUAL) {
      fetchProblemsByIdsFx(savedSortedProblemsIds)
    }

    currentSortOptionModel.set(savedSortOption)
  }, [])

  useEffect(() => {
    if (!exercise?._id) return
    const exerciseProblemsIds = exercise.problems.map((problem) => problem._id)
    fetchProblemsByIdsFx(exerciseProblemsIds)
    const sortOption = exercise.problemsSortOption
    if (sortOption) {
      currentSortOptionModel.set(sortOption)
      savedSortOptionModel.set(sortOption)
    }
  }, [])

  return (
    <>
      <UITooltip title={t.reorderProblemsTxt}>
        <div>
          <Button
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZE.MEDIUM}
            classes={{
              button: clsx(styles.PenIconButton, { [styles.Hidden]: !selectedProblems.length }),
            }}
            icon={<PenIcon color='var(--icon-color)' size={20} />}
            onClick={() => reorderModalStateModel.setState(true)}
            isActive={isReorderModalOpen}
            data-color='transparent'
          />
        </div>
      </UITooltip>
      {isReorderModalOpen && (
        <ModalSideBar
          isOpen={isReorderModalOpen}
          onClose={onCancel}
          onActionButtonClick={onSave}
          title={t.problemsTxt}
          actionButtonText={t.saveTxt}
          cancelButtonText={t.cancelTxt}
          mainContent={<ProblemsList />}
          classes={{
            dialog: styles.Dialog,
            mainContent: styles.MainContent,
            sideBarContent: styles.SideBarContent,
            sideBar: clsx(styles.SideBar, {
              [styles.ExamMode]: isExamMode,
              [styles.NonExamMode]: !isExamMode,
            }),
          }}
          sideBarContent={<OrderSelection sortOption={sortOption} isExamMode={isExamMode} />}
        />
      )}
    </>
  )
}

export default ReorderProblems
