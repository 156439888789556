import { useStore } from 'effector-react'
import HeatmapMenuActionsSkeleton from 'features/heatmap/HeatmapMenu/HeatmapMenuActions/HeatmapMenuActionsSkeleton'
import { VerticalDivider } from 'features/heatmap/HeatmapMenu/VerticalDivider'
import SortMenu from 'features/heatmap/SortMenu/SortMenu'
import HelpQueue from 'features/helpQueue/HelpQueue'
import {
  $isPinsFilterActive,
  updatePinState,
} from 'features/pinnedSolutions/model/isPinsFilterActive'
import { clearCurrentPinsData } from 'features/pinnedSolutions/model/pins'
import { fetchPinsDataFx } from 'features/pinnedSolutions/model/requests'
import FilterPinnedButton from 'features/pinnedSolutions/ui/FilterPinnedButton/FilterPinnedButton'
import AnonymousCorrectnessButton from 'features/tables/HeatmapTable/ui/AnonymousCorrectnessButton/AnonymousCorrectnessButton'
import AnonymousNameButton from 'features/tables/HeatmapTable/ui/AnonymousNameButton/AnonymousNameButton'
import { checkIsUserRemovedFromAssignment, getFullName, getTeacherData } from 'helpers/user.helpers'
import { useSocketEvents } from 'lib/hooks/useSocketEvents'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import MultipleSolutions from '../../components/MultipleSolutionsScreen/MultipleSolutions/MultipleSolutions'
import Spinner from '../../features/Spinner/Spinner'
import HeatmapMenu from '../../features/heatmap/HeatmapMenu/HeatmapMenu'
import HeatmapMenuSkeleton from '../../features/heatmap/HeatmapMenu/HeatmapMenuSkeleton'
import HeatmapMenuTabsSkeleton from '../../features/heatmap/HeatmapMenu/HeatmapMenuTabs/HeatmapMenuTabsSkeleton'
import { getButtonColor } from '../../features/heatmap/HeatmapMenu/getButtonColor'
import { handleStudentsSort } from '../../features/tables/HeatmapTable/helpers/sorting'
import { $heatmapSettings } from '../../features/tables/HeatmapTable/model/heatmapSettings'
import { socketEvents } from '../../helpers/enums'
import socket from '../../helpers/socket.helper'
import useText from '../../i18n/hook'
import { IStudent } from '../../api/types.users'
import { getExercise } from '../../redux/modules/exercise.module'
import {
  getExerciseWithoutStats,
  getStatsForSingleStudent,
  updateStats,
} from '../../redux/modules/exerciseWithoutStats.module'
import {
  clearDataMultipleSolutions,
  getMultipleSolutions,
} from '../../redux/modules/multipleSolutions.module'
import { RootState } from '../../store/store'
import Breadcrumbs from '../../ui/Breadcrumbs/Breadcrumbs'
import { getExerciseStats } from './helpers'

type MultipleSolutionsRoute = {
  exerciseId: string
  studentId: string
}

const MultipleSolutionsLayout = () => {
  const { exerciseId, studentId } = useParams<MultipleSolutionsRoute>()
  const t = useText()

  const dispatch = useDispatch()
  const isPinsFilterActive = useStore($isPinsFilterActive)
  const solutionData = useSelector((state: RootState) => state.multipleSolution.data)
  const exerciseWithoutStats = useSelector((state: RootState) => state.exerciseWithoutStats.data)
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))

  const { taskIds, usersFromAllSets } = useMemo(
    () => getExerciseStats(exerciseWithoutStats, currentTeacher),
    [exerciseWithoutStats, currentTeacher]
  )

  const stats =
    taskIds.length > 0 && solutionData?.problems
      ? taskIds.map(
          (taskId: string) => solutionData?.problems[taskId] || solutionData?.subProblems[taskId]
        )
      : []

  useEffect(() => {
    if (exerciseWithoutStats?._id !== exerciseId) {
      dispatch(getExerciseWithoutStats(exerciseId))
    }

    dispatch(getMultipleSolutions(exerciseId, studentId))

    return () => {
      dispatch(clearDataMultipleSolutions())
    }
  }, [exerciseWithoutStats?._id, exerciseId, studentId])

  useEffect(() => {
    fetchPinsDataFx({
      exerciseId: exerciseId,
      userId: studentId,
      isCurrent: true,
    })

    return () => {
      clearCurrentPinsData()
      updatePinState(false)
    }
  }, [exerciseId, studentId])

  useSocketEvents(socket, [
    {
      event: socketEvents.exerciseUpdated,
      handler: async (data) => {
        if (data.exerciseId === exerciseId) {
          dispatch(getExercise(exerciseId))
          dispatch(getMultipleSolutions(exerciseId, studentId))
        }
      },
    },
    {
      event: socketEvents.statisticsUpdate,
      handler: async (data) => {
        if (data.exerciseId !== exerciseId) return
        if (!data.userId || data.userId !== studentId) {
          updateStats(data.exerciseId)
          return
        }
        dispatch(getStatsForSingleStudent(data.exerciseId, data.userId))
        dispatch(getMultipleSolutions(exerciseId, studentId))
      },
    },
  ])

  const getUsersData = (students: IStudent[]) =>
    students.find((student) => student?._id === studentId)

  const userData = getUsersData(usersFromAllSets)
  const isUserDeleted = checkIsUserRemovedFromAssignment({ currentTeacher, student: userData })
  const userFullName = getFullName(userData?.firstName, userData?.lastName)
  const buttonColor = getButtonColor(exerciseWithoutStats.testModeEnabled)
  const heatmapSettings = useStore($heatmapSettings)
  const sortedStudents = handleStudentsSort(
    exerciseWithoutStats?.students || [],
    heatmapSettings.sortOption,
    exerciseWithoutStats
  )

  const cardTitle = heatmapSettings.isAnonymousNameMode
    ? `${t.studentTxt} ${
        [...sortedStudents, exerciseWithoutStats.teacher].findIndex(
          (student) => student._id === studentId
        ) + 1
      }`
    : userFullName

  const breadcrumbsLinks = [
    { text: t.heatmapTxt, link: `/exercises/${exerciseId}/heatmap` },
    { text: cardTitle },
  ]

  if (!exerciseWithoutStats._id) {
    return (
      <HeatmapMenuSkeleton
        actionButtons={<HeatmapMenuActionsSkeleton numberOfButtons={4} />}
        navigation={<HeatmapMenuTabsSkeleton />}
      />
    )
  }

  return (
    <>
      <HeatmapMenu
        actions={
          <>
            <HelpQueue />
            <VerticalDivider />
            <FilterPinnedButton buttonColor={buttonColor} />
            <AnonymousNameButton buttonColor={buttonColor} />
            <AnonymousCorrectnessButton buttonColor={buttonColor} />
            <SortMenu
              buttonColor={buttonColor}
              withPinsOrderOption={!studentId && isPinsFilterActive}
            />
          </>
        }
        navigation={<Breadcrumbs links={breadcrumbsLinks} />}
        title={exerciseWithoutStats.name}
      />
      {!isEmpty(stats) ? (
        <MultipleSolutions
          exercise={exerciseWithoutStats}
          cardTitle={cardTitle}
          studentId={studentId}
          stats={stats}
          isUserDeleted={isUserDeleted}
        />
      ) : (
        <Spinner className='height loader' />
      )}
    </>
  )
}

export default MultipleSolutionsLayout
