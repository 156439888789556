import { localStorageKeys } from 'config/localStorageKeys'
import { NotifyType, SortingTypes } from 'helpers/enums'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { matchPath } from 'react-router-dom'
import { PATHS } from '../../config/pathnames.config'
import { getClass } from './class.module'
import { FILTER } from './filterExercises.module'
import { MODAL } from './modal.module'
import { NOTIFY } from './notify.module'
import { ILocalization } from '../../config/languages.config'
import { CLASSES_FILTER } from '../../features/filters/MultiplicityFilter/classesFilter.module'
import { RouteComponentProps } from 'react-router'
import { api } from '../../api'

import { MoveStudentsPayload } from '../../api/types.classes'

// Classes constants
export enum CLASSES {
  CLASSES_GET_REQUEST = 'CLASSES_GET_REQUEST',
  CLASSES_GET_SUCCESS = 'CLASSES_GET_SUCCESS',
  CLASSES_GET_ERROR = 'CLASSES_GET_ERROR',
  CLASSES_EDIT_REQUEST = 'CLASSES_EDIT_REQUEST',
  CLASSES_EDIT_SUCCESS = 'CLASSES_EDIT_SUCCESS',
  CLASSES_EDIT_ERROR = 'CLASSES_EDIT_ERROR',
  CLASSES_CHANGE_ARCHIVE_STATUS_REQUEST = 'CLASSES_CHANGE_ARCHIVE_STATUS_REQUEST',
  CLASSES_CHANGE_ARCHIVE_STATUS_SUCCESS = 'CLASSES_CHANGE_ARCHIVE_STATUS_SUCCESS',
  CLASSES_CHANGE_ARCHIVE_STATUS_ERROR = 'CLASSES_CHANGE_ARCHIVE_STATUS_ERROR',
  CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_REQUEST = 'CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_REQUEST',
  CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_SUCCESS = 'CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_SUCCESS',
  CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_ERROR = 'CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_ERROR',
  CLASSES_CLEAR = 'CLASSES_CLEAR',
}

// Classes reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}
export function classesReducer(state: any = initialState, action: IAction<CLASSES>) {
  switch (action.type) {
    case CLASSES.CLASSES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASSES.CLASSES_GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case CLASSES.CLASSES_GET_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case CLASSES.CLASSES_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case CLASSES.CLASSES_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case CLASSES.CLASSES_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CLASSES.CLASSES_CLEAR:
      return { ...initialState }
    default:
      return state
  }
}

// Classes actions
export function getClasses() {
  const me = getItemFromLocalStorageWithParsing(localStorageKeys.me)
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASSES.CLASSES_GET_REQUEST })
      const res = await api.classes.getAll({
        schoolId: me?.school?._id,
        fetchAll: 1,
        order: 'asc',
        sortBy: 'name',
      })
      dispatch({ type: CLASSES.CLASSES_GET_SUCCESS, payload: res })
      return res
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASSES.CLASSES_GET_ERROR,
      })
    }
    return null
  }
}

export const changeArchivedClassStatus = (
  classId: string,
  archived: boolean,
  history: RouteComponentProps['history'],
  localization: ILocalization['data']
) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASSES.CLASSES_CHANGE_ARCHIVE_STATUS_REQUEST })
      const res = await api.classes.updateSingle({
        id: classId,
        data: { archived },
      })
      dispatch({ type: CLASSES.CLASSES_CHANGE_ARCHIVE_STATUS_SUCCESS, payload: res })
      const isStudentsPathname = !!matchPath(
        history.location.pathname,
        PATHS.STUDENTS.STUDENTS_MAIN
      )
      if (isStudentsPathname) {
        history.push(PATHS.CLASSES)
        dispatch({
          type: FILTER.FILTER_ORDER,
          payload: { order: SortingTypes.Desc, sortBy: SortingTypes.StartDate },
        })
        dispatch({
          type: CLASSES_FILTER.SELECT_CLASS,
          payload: { name: localization.allClassesTxt, value: null },
        })
      }
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASSES.CLASSES_CHANGE_ARCHIVE_STATUS_ERROR,
      })
    }
  }
}

export const changeLoginWithQrCodeStatus = (
  classId: string,
  loginWithQrCodeStatus: boolean,
  history: any
) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASSES.CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_REQUEST })
      const res = await api.classes.updateSingle({
        id: classId,
        data: { qrCodeLogin: loginWithQrCodeStatus },
      })
      dispatch({ type: CLASSES.CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_SUCCESS, payload: res })
      return !!res
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: CLASSES.CLASSES_CHANGE_QR_CODE_LOGIN_STATUS_ERROR,
      })
      return false
    }
  }
}

export function moveStudents(
  data: MoveStudentsPayload,
  classId: string,
  history: any,
  localization: ILocalization
) {
  const notification: string = localization.data.studentMovedToAnotherClass
  const errorMessage: string = localization.data.forbiddenTxt
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: CLASSES.CLASSES_EDIT_REQUEST })
      const res = await api.classes.moveStudents(data)
      dispatch({ type: CLASSES.CLASSES_EDIT_SUCCESS, payload: res })
      dispatch({ type: MODAL.CLOSE_CLASSES_MODAL, payload: { opened: false } })
      getClass(classId)(dispatch)
      getClasses()(dispatch)
      dispatch({
        type: NOTIFY.NOTIFY_BEGIN,
        payload: { message: notification, type: NotifyType.Info },
      })
      dispatch({ type: NOTIFY.NOTIFY_END })
    } catch (error) {
      if (error.response.status === 403 && error.response.data.message.includes('Forbidden')) {
        dispatch({
          type: NOTIFY.NOTIFY_BEGIN,
          payload: { message: errorMessage, type: NotifyType.Danger },
        })
        dispatch({ type: NOTIFY.NOTIFY_END })
        dispatch({
          payload: !!error.response ? error.response.data.message : error.message,
          type: CLASSES.CLASSES_EDIT_ERROR,
        })
      }
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: CLASSES.CLASSES_EDIT_ERROR,
          })
        },
        () => handleNetworkError(CLASSES.CLASSES_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export interface IClassesActions {
  getClasses: () => void
  changeArchivedClassStatus: (
    classId: string,
    archived: boolean,
    history: RouteComponentProps['history'],
    localization: ILocalization['data']
  ) => void
  changeLoginWithQrCodeStatus: (
    classId: string,
    loginWithQrCodeStatus: boolean,
    history: any
  ) => void
  moveStudents: (
    data: MoveStudentsPayload,
    id: string,
    history: any,
    localization: ILocalization
  ) => void
}
