import React, { SyntheticEvent } from 'react'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon/ArrowLeftIcon'
import SmallArrowRightIcon from 'ui/icons/SmallArrowRightIcon/SmallArrowRightIcon'
import useText from 'i18n/hook'
import styles from './AttemptsNavigation.module.scss'

type AttemptsNavigationProps = {
  attemptOffset: number
  attemptsCount: number
  onNavigate: (attempt: number) => void
  buttonColor: BUTTON_COLOR
}

const AttemptsNavigation = ({
  attemptOffset,
  onNavigate,
  attemptsCount,
  buttonColor,
}: AttemptsNavigationProps) => {
  const t = useText()

  return (
    <div className={styles.AttemptsNavigation}>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.SMALL}
        color={buttonColor}
        icon={<ArrowLeftIcon color='var(--icon-color)' size={10} className={styles.Icon} />}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          onNavigate(attemptOffset + 1)
        }}
        disabled={attemptOffset >= attemptsCount - 1}
      />
      <p className={styles.Center}>{`${t.attemptTxt} ${attemptsCount - attemptOffset} ${
        t.ofTxt
      } ${attemptsCount}`}</p>
      <Button
        variant={BUTTON_VARIANT.TERTIARY}
        size={SIZE.SMALL}
        color={buttonColor}
        icon={<SmallArrowRightIcon color='var(--icon-color)' size={10} className={styles.Icon} />}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          onNavigate(attemptOffset - 1)
        }}
        disabled={attemptOffset < 1}
      />
    </div>
  )
}

export default AttemptsNavigation
