export const BOOKSHELF_ID = 'bookshelf'

export const MAX_RECOMMENDED_PROBLEMS = 50

export const SPECIAL_CHAPTERS_IDS = ['61d5b1939cdbf10018006959', '61d5b1149cdbf10018006958']

export const SPECIAL_BOOK_HREF =
  'https://intercom.help/matteappen/sv/articles/5007514-forsta-och-anvanda-tal-startguide'

export const BOOKS_LIMIT = 20
