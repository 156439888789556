import { IMe } from 'api/types.users'

import { IBook } from '../../../api/types.books'
import { BookSeriesData } from '../../../api/types.bookSeries'

export const getBookSeriesIds = (me?: IMe) => {
  return me
    ? [
      ...new Set([
        ...(me.country?.bookSeries || []),
        ...(me.region?.bookSeries || []),
        ...(me.municipality?.bookSeries || []),
        ...(me.school?.bookSeries || []),
        ...(me.bookSeries || []),
      ]),
    ]
    : []
}

export const getFormattedBookSeries = (bookSeries: BookSeriesData[], books: IBook[]) => {
  const sortedBookSeries = [...bookSeries].sort((a, b) => a.order - b.order)

  return (
    sortedBookSeries.reduce((acc, bookSeriesData) => {
      const formattedBooks = (books || [])
        .filter((book) => bookSeriesData.books.includes(book._id))
        .sort((a, b) => {
          const orderA = a.order ?? Infinity
          const orderB = b.order ?? Infinity
          return orderA - orderB
        })
        .map((book) => ({ label: book.name, id: book._id }))
      return [
        ...acc,
        {
          title: bookSeriesData.name,
          items: formattedBooks
        },
      ]
    }, [])
  )
}

export const getRemovedBooks = (
  initiallySelected: Map<string, IBook>,
  selectedItems: Map<string, IBook>
) => [...initiallySelected.keys()].filter((bookId) => !selectedItems.has(bookId))

export const getAddedBooks = (
  initiallySelected: Map<string, IBook>,
  selectedItems: Map<string, IBook>
) =>
  [...selectedItems.entries()].reduce<IBook[]>((acc, [bookId, book]) => {
    if (initiallySelected.has(bookId)) {
      return acc
    }
    return [...acc, book]
  }, [])
