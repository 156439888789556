import clsx from 'clsx'
import { PATHS } from 'config/pathnames.config'
import { SearchParam, SourceTypeName } from 'helpers/enums'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import { IMe } from 'api/types.users'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { followTeacherToClass, unfollowTeacherFromClass } from 'redux/modules/class.module'
import { RootState } from 'store/store'
import UICard from 'ui/cards/Card/Card'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import { ClassTypes } from 'features/ClassesTab/ClassesTab'
import { saveClass } from '../../../../redux/modules/saveClass.module'
import ClassCardDropdown from '../../../../views/Classes/components/ClassCardDropdown/ClassCardDropdown'
import RequestAccessContent from '../../../../views/Classes/components/RequestAccessContent/RequestAccessContent'
import styles from './ClassCard.module.scss'
import { selectClass } from '../../../filters/MultiplicityFilter/classesFilter.module'
import SyncIcon from '../../../../ui/icons/SyncIcon/SyncIcon'

import { getSyncLevel, isRosteredClass, SyncLevel } from '../../../../helpers/classesView.helpers'
import { getSchoolClasses } from '../../../../redux/modules/schoolClasses.module'
import { getClasses } from '../../../../redux/modules/classes.module'
import { Button, BUTTON_VARIANT, COLORS, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import PlusIcon from '../../../../ui/icons/PlusIcon/PlusIcon'
import { IClass, SourceType } from '../../../../api/types.classes'

type ClassCardProps = {
  classesType: ClassTypes
  classDetails: IClass
  gradeLabel: string
  me: IMe
}

const ClassCard = ({ classesType, classDetails, gradeLabel, me }: ClassCardProps) => {
  const nameOverflow = useOverflowDetection()
  const displayNameOverflow = useOverflowDetection()
  const localization = useSelector((state: RootState) => state.localization)

  const t = localization.data
  const studentsLength = classDetails.students?.length ?? 0
  const classOwnerFirstName = classDetails.owner?.firstName ?? ''

  const dispatch = useDispatch()
  const history = useHistory()
  const syncLevel = getSyncLevel(me, classDetails)

  const changeFollowState = async (state: boolean) => {
    const schoolId = me?.school._id ?? ''
    const action = state ? followTeacherToClass : unfollowTeacherFromClass

    const isSuccessful = await action(
      {
        loading: false,
        data: classDetails,
        error: undefined,
      },
      me as IMe,
      localization,
      history
    )(dispatch)

    if (isSuccessful) {
      dispatch(getClasses())
      dispatch(getSchoolClasses(schoolId))
    }
  }

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement
    if (!target || classesType === ClassTypes.OTHER_CLASSES) return

    // Some card elements should not make redirect:
    const skipRedirect =
      // items with specific data attribute
      target.getAttribute('data-skip-redirect') ||
      // options button icon
      target.nodeName === 'path' ||
      // options button icon
      target.nodeName === 'svg' ||
      // options dropdown backdrop
      target.classList.contains('MuiBackdrop-root') ||
      // options dropdown settings menu item
      target.classList.contains('MuiMenuItem-root')

    if (skipRedirect) return
    if (classDetails?._id) {
      const selectedOption = {
        value: classDetails._id,
        name: classDetails?.name,
      }
      dispatch(selectClass(selectedOption))
      dispatch(saveClass(classDetails._id))
    }
    history.push(
      classDetails?._id
        ? `${PATHS.PARTS.CLASS_STUDENTS}${classDetails?._id}${SearchParam.STUDENTS_TAB}`
        : '#'
    )
  }
  const isDisplayName = isRosteredClass(classDetails)
  return (
    <UICard
      onClick={onCardClick}
      className={clsx(styles.ClassCard, {
        [styles.DarkBackground]: classesType === ClassTypes.OTHER_CLASSES,
      })}
    >
      <div className={styles.Content}>
        <div className={styles.Header}>
          <div className={styles.Name}>
            <UITooltip
              title={
                nameOverflow.isOverflowing ? (
                  <span className={styles.HeaderTooltip}>{classDetails.name}</span>
                ) : null
              }
              placement='top-start'
            >
              <h5 ref={nameOverflow.overflowRef} className={styles.HeaderTitle}>
                {classDetails.name}
              </h5>
            </UITooltip>
            {isDisplayName && (
              <UITooltip
                title={
                  displayNameOverflow.isOverflowing ? (
                    <span className={styles.HeaderTooltip}>{classDetails.displayName}</span>
                  ) : null
                }
                placement='top-start'
              >
                <span ref={displayNameOverflow.overflowRef} className={styles.DisplayName}>
                  {classDetails.displayName}
                </span>
              </UITooltip>
            )}
          </div>
          {syncLevel === SyncLevel.PARTLY_SYNC && classesType === ClassTypes.OTHER_CLASSES && (
            <Button
              classes={{
                content: styles.ShareButtonContent,
              }}
              size={SIZE.SMALL}
              variant={BUTTON_VARIANT.TERTIARY}
              onClick={() => changeFollowState(true)}
              icon={<PlusIcon className={styles.PlusIcon} color='var(--icon-color)' size={18} />}
              iconAlign={ICON_ALIGNMENT.RIGHT}
            >
              {t.follow}
            </Button>
          )}

          {syncLevel === SyncLevel.NO_SYNC && classesType === ClassTypes.OTHER_CLASSES && (
            <RequestAccessContent classDetails={classDetails} me={me} />
          )}

          {syncLevel !== SyncLevel.FULL_SYNC && classesType === ClassTypes.USER_CLASSES && (
            <ClassCardDropdown changeFollowState={changeFollowState} classDetails={classDetails} />
          )}
        </div>
        <div className={styles.Body}>
          {classDetails.grade !== -1 && (
            <p className={styles.InfoText}>
              {t.grade} {gradeLabel}
            </p>
          )}
          {!!classDetails.year && (
            <p className={styles.InfoText}>
              {t.schoolYear} {classDetails.year}
            </p>
          )}
          <p className={styles.InfoText}>
            {studentsLength}
            {studentsLength === 1
              ? ` ${t.studentTxt}`.toLowerCase()
              : ` ${t.studentsTxt}`.toLowerCase()}
          </p>
        </div>
      </div>
      <div className={styles.Footer}>
        {!!classDetails.source && (
          <div className={styles.Owner}>
            <p className={clsx(styles.InfoText, styles.Source)}>{`${t.fromTxt} ${
              SourceTypeName[SourceType[classDetails.source]].name
            }`}</p>
            <SyncIcon color={COLORS.NEUTRAL_7} />
          </div>
        )}

        {!classDetails.source && !!classOwnerFirstName && (
          <div className={styles.Owner}>
            <p className={styles.InfoText}>{`${t.ownedByTxt} ${classOwnerFirstName}`}</p>
          </div>
        )}
      </div>
    </UICard>
  )
}

export default ClassCard
