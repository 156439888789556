import { ExerciseState } from './types.assignments'

export enum CheatsType {
  LEAVING_EXAM = 0,
  TEXT_REPLACEMENT = 1,
  LEAVING_APP = 2,
}

export enum StatisticsType {
  CATEGORY = 1,
  SKILLS = 4,
  SKILLS_CHAPTERS = 5,
}

export interface ICheat {
  type: CheatsType
  time: string
}

export interface IStudentsExamStats {
  afterFirstAttempt: number
  completedAmount: number
  createdAt: string
  examStats?: { state: number; cheats: ICheat[] }
  exerciseId: string
  firstAttempt: number
  notAnswered: number
  studentId: string
  updatedAt: string
  wrong: number
  __v: number
  _id: string
}

export type MostCommonErrorProblemDetails = {
  answer: string[]
  studentIds: string[]
  errorType?: string
}

export type GetCommonErrorPayload = {
  exerciseId: string
  minStudentCount?: number
}
export type GetCommonErrorResponse = Record<string, MostCommonErrorProblemDetails>

export interface IPracticeResult {
  correctCount: number
  totalCount: number
  passed: boolean
}

export type GetPracticeResultsPayload = {
  abilityId: string
  urlType: string
  filterOptionId: string
}

export interface GetPracticeResultsResponse {
  [key: string]: IPracticeResult
}

export interface IStudentProgressStat {
  _id: string
  exerciseId: string
  studentId: string
  firstAttempt: number
  afterFirstAttempt: number
  notAnswered: number
  wrong: number
  exerciseName: string
  createdAt: string
  updatedAt: string
  testModeEnabled: boolean
}

export interface IStudentData {
  firstName: string
  lastName: string
  _id: string
  type: number
}

export interface IStudentProgressData {
  studentData: IStudentData
  studentStats: IStudentProgressStat[]
}

export type GetStudentsProgressPayload = {
  studentId: string
  page: number
}

export interface GetStudentProgressResponse {
  page: number
  progress: IStudentProgressData
  totalExercises?: number
  totalPages: number
}

export interface ISkillsUsers {
  userName?: string
  userScore?: number
  isTeacher?: boolean
  solvedPercentage: number
  wrong: number
  solved: number
  userId: string
  _id: string
}

export interface ISkills {
  numberOfProblems: number
  skillId: string
  _id: string
  users: ISkillsUsers[]
}

export interface GetSkillsStatisticsResponse {
  id: string
  exerciseId: string
  __v: number
  createdAt: string
  skills: ISkills[]
}

export interface ISKillsChaptersStatsItem {
  problemsLength: number
  skillsLength: number
  subProblemsLength: number
  solvedPercentage: number
  users: {
    [key: string]: {
      numberOfSolved: number
      solvedPercentage: number
    }
  }
}

export type GetSkillsStatisticsPayload = {
  exerciseId: string
  classIds?: string
  groupIds?: string
}

export type GetSkillsChaptersStatisticsResponse = {
  [key: string]: ISKillsChaptersStatsItem
}

export type UpdateExamStatusPayload = {
  exerciseId: string
  status: ExerciseState
  studentIds: string[]
}
export type GetSingleStudentExamStatsPayload = {
  exerciseId: string
  studentId: string
}
