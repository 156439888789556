import React from 'react'
import { SvgIconProps } from '../types/types'

const LengthIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_224)'>
        <path
          d='M6.32586 23.4317L1.23776 18.3437C1.12908 18.2349 1.12908 18.0587 1.23778 17.95L17.6422 1.54558C17.9842 1.20356 18.5387 1.20356 18.8807 1.54558L23.5464 6.21119C23.8884 6.55322 23.8884 7.10774 23.5464 7.44976L7.56443 23.4317C7.22241 23.7737 6.66789 23.7737 6.32586 23.4317Z'
          stroke='black'
          strokeWidth='1.66'
          strokeLinecap='round'
        />
        <path
          d='M3.40625 16.1777L4.78389 17.5554'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M7.14453 12.9316L8.52218 14.3093'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M10.8867 9.68359L12.2644 11.0612'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M14.625 5.8457L16.0026 7.22334'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M5.17969 14.5059L7.54136 16.8674'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M8.91797 11.2578L11.2796 13.6194'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M12.6523 8.01172L15.014 10.3734'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
        <path
          d='M16.3906 4.17383L18.7523 6.5355'
          stroke={color}
          strokeWidth='0.875802'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_224'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LengthIcon
