import React, { ReactNode, useContext, useState } from 'react'
import { EXERCISES_STATUS, IExercisesStatuses } from 'redux/modules/exercisesStatuses.module'
import {
  AnimationDirections,
  AnimationVariants,
  animationVariants,
  getAnimationDirection,
  getAnimationStyles,
  mainTransition,
} from './animationConfig'
import { MotionProps } from 'framer-motion'

type SafeToRemove = (() => void) | null | undefined

type AnimationsContextValue = {
  isAnimating: boolean
  handleAssignmentsAnimation: (
    exercisesStatuses: IExercisesStatuses,
    chosenExerciseStatus: EXERCISES_STATUS
  ) => void
  getAnimationConfig: (isPresent: boolean, safeToRemove: SafeToRemove) => MotionProps
}

type AnimationsProviderProps = {
  children?: ReactNode
}

const initialState: AnimationsContextValue = {
  isAnimating: false,
  handleAssignmentsAnimation: () => null,
  getAnimationConfig: () => null as unknown as MotionProps,
}

const AnimationsContext = React.createContext<AnimationsContextValue>(initialState)

export const AnimationsProvider = ({ children }: AnimationsProviderProps) => {
  const [isAnimating, setIsAnimating] = useState(initialState.isAnimating)
  const [animationDirection, setAnimationDirection] = useState(AnimationDirections.NONE)

  const handleAssignmentsAnimation = (
    exercisesStatuses: IExercisesStatuses,
    chosenExerciseStatus: EXERCISES_STATUS
  ) => {
    const animationDirection = getAnimationDirection(exercisesStatuses, chosenExerciseStatus)
    setIsAnimating(true)
    setAnimationDirection(animationDirection)
  }

  const getAnimationConfig = (isPresent: boolean, safeToRemove: SafeToRemove) => {
    const outAnimation = animationDirection
      ? AnimationVariants.EXIT_IN_DIRECTION
      : AnimationVariants.OUT
    const currentAnimation = isPresent ? AnimationVariants.IN : outAnimation

    const animationConfigProps: MotionProps = {
      layout: true,
      initial: AnimationVariants.OUT,
      animate: currentAnimation,
      custom: { direction: animationDirection },
      style: getAnimationStyles(isPresent),
      variants: animationVariants,
      onAnimationComplete: (animationVariant: AnimationVariants) => {
        setIsAnimating(false)
        setAnimationDirection(AnimationDirections.NONE)
        return !isPresent && safeToRemove && safeToRemove()
      },
      transition: mainTransition,
    }

    return animationConfigProps
  }

  return (
    <AnimationsContext.Provider
      value={{
        isAnimating,
        handleAssignmentsAnimation,
        getAnimationConfig,
      }}
    >
      {children}
    </AnimationsContext.Provider>
  )
}

export const useAnimationsContext = () => useContext(AnimationsContext)
