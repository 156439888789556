import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { detectPageWithHiddenSidebar } from 'helpers/pathnameChecking.helper'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, RouteComponentProps } from 'react-router-dom'
import { localStorageKeys } from '../../config/localStorageKeys'
import { PATHS } from '../../config/pathnames.config'
import MultiplicityFilter from '../../features/filters/MultiplicityFilter/MultiplicityFilter'
import { Events, SelectedValueTypes } from '../../helpers/enums'
import { adminType } from '../../helpers/route.helpers'
import {
  getItemFromLocalStorage,
  getItemFromLocalStorageWithParsing,
} from '../../helpers/storage.helper'
import { IState } from '../../interfaces/state.interface'
import { IAuth, IAuthActions } from '../../redux/modules/auth.module'
import { IBooksActions } from '../../redux/modules/books.module'
import {
  IExercisesStatusActions,
  IExercisesStatuses,
} from '../../redux/modules/exercisesStatuses.module'
import { ILocalizationActions } from '../../redux/modules/localization.module'
import { setSidebarStatus } from '../../redux/modules/sidebar.module'
import { RootState } from '../../store/store'
import Logo from '../Logo/Logo'
import SideBarLink from './SideBarLink/SideBarLink'
import './Sidebar.scss'
import SideBarBottomLinks from '../../features/SideBar/SideBarBottomLinks/SideBarBottomLinks'
import LinkHighlighter from '../../ui/higlihters/LinkHighlighter/LinkHighlighter'
import { config } from '../../config'
import isEmpty from 'lodash/isEmpty'
import { IFilterExercisesActions } from 'redux/modules/filterExercises.module'

import { GetAllAssignmentsResponse } from '../../api/types.assignments'

interface ISidebarProps {
  history: RouteComponentProps['history']
  localization: any
  booksActions: IBooksActions
  authActions: IAuthActions
  localizationActions: ILocalizationActions
  exercisesStatusActions: IExercisesStatusActions
  exercisesStatuses: IState<IExercisesStatuses>
  filterExercisesActions: IFilterExercisesActions
  routes: any[]
  auth: IState<IAuth>
  exercises: IState<GetAllAssignmentsResponse>
}

const Sidebar: React.FC<ISidebarProps> = ({
  history,
  localization,
  booksActions,
  exercisesStatusActions,
  exercisesStatuses,
  routes,
  auth,
  exercises,
  filterExercisesActions
}) => {
  let sidebarRef: HTMLElement
  const refs: {} = []
  const [exerciseSubLinks, setExerciseSubLinks] = useState<boolean>(false)
  const isSidebarOpen = useSelector((state: RootState) => state.sideBarStatus.data.isOpen)
  const dispatch = useDispatch()

  useEffect(() => {
    document.addEventListener(Events.CLICK, handleClickOutside, true)
    const isExercisesPathname = history.location.pathname === PATHS.EXERCISES.EXERCISES_MAIN
    const isMainPath = history.location.pathname === PATHS.MAIN
    const isEditClassPathname = !!matchPath(history.location.pathname, '/students/class/:id/edit')
    const isStudentsPathname = !!matchPath(history.location.pathname, PATHS.STUDENTS.STUDENTS_MAIN)

    ;(refs as any).map((ref: any) => {
      if (
        ref.classList.contains('exercise-link') &&
        (isExercisesPathname || isMainPath) &&
        !isEditClassPathname &&
        !isStudentsPathname
      ) {
        setExerciseSubLinks(true)
      }
    })

    if (isEditClassPathname || isStudentsPathname) {
      setExerciseSubLinks(false)
    }
    return () => {
      document.removeEventListener(Events.CLICK, handleClickOutside, true)
    }
  }, [history.location.pathname])

  useEffect(() => {
    updateData()
  }, [localization.data.languageTxt])

  const getBooksAndOpenLinks = async (): Promise<any> => {
    await booksActions.getBooksForCreatingProblems(SelectedValueTypes.INITIALBOOKS)
  }

  const updateData = () => {
    if (history.location.pathname.includes(PATHS.CONTENT.PROBLEMS)) {
      getBooksAndOpenLinks()
    }
  }

  const handleClickOutside = (event: any) => {
    if (
      document.documentElement!.classList.contains('nav-open') &&
      sidebarRef &&
      !sidebarRef.contains(event.target)
    ) {
      closeSidebar(event)
    }
  }

  const closeSidebar = (event: any) => {
    event.preventDefault()
    document.documentElement!.classList.toggle('nav-open')
    dispatch(setSidebarStatus(false))
  }

  const activeRoute = (routeName: string) =>
    history.location.pathname.startsWith(routeName) ? 'active' : ''

  const checkActiveFilterLink = (activeFilter: boolean) => (activeFilter ? 'active' : 'unactive')

  const { pathname } = history.location

  const changeExerciseStatuses = (callback: () => void) => {
    if (exercises.loading) {
      return
    }
    callback()
  }

  const isExercisesPage = history.location.pathname.includes('exercises')
  const isStudentsPage = history.location.pathname.includes('students')
  const isPracticePage = history.location.pathname.includes(PATHS.PRACTICE.PRACTICE_MAIN)
  const isPracticePageHidden =
    config.APP_HOST === config.APP_HOST_INTERNATIONAL && !auth.data?.me?.setting?.practiceMode
  const onCLickHandler = (e: any) => {
    if (e.target.classList.contains('sidebar-wrapper-absolute')) {
      closeSidebar(e)
    }
  }
  const isLoadedMe = useSelector((state: RootState) => state.auth.data.me)
  const classes = useSelector((state: RootState) => state.classes.data?._embedded?.classes)
  const groups = useSelector((state: RootState) => state.groups.data?._embedded?.groups)
  const isShowFilter = isLoadedMe && classes && groups && !isEmpty(localization.data)
  const isSidebarHidden = detectPageWithHiddenSidebar(history)
  return (
    <>
      {auth.data.me && (
        <div
          className={`${isSidebarOpen && 'sidebar-wrapper-absolute'}`}
          onClick={(e) => {
            onCLickHandler(e)
          }}
        >
          <div
            className={clsx('sidebar', { hidden: isSidebarHidden })}
            data-color='light'
            ref={(node: HTMLDivElement) => (sidebarRef = node)}
          >
            <Logo />
            <div className='sidebar-wrapper'>
              <div className='sidebar-container-menu'>
                <div className='sidebar-menu-wrapper'>
                  <div className='filter-wrapper'>
                    {isShowFilter && <MultiplicityFilter filterExercisesActions={filterExercisesActions}/>}</div>
                  {routes
                    .filter((route: any) => {
                      route.type = _.isEmpty(route.type) ? adminType() : route.type
                      return (
                        route.hideInSidebar !== true &&
                        ((auth.data &&
                          auth.data.me &&
                          route.type.indexOf(+auth.data.me.type) > -1) ||
                          (getItemFromLocalStorage(localStorageKeys.me) &&
                            route.type.indexOf(
                              +getItemFromLocalStorageWithParsing(localStorageKeys.me).type
                            ) > -1))
                      )
                    })
                    .map((prop: any, index: number) => {
                      const isExistOtherPartsOfPath: boolean =
                        pathname.includes(PATHS.PARTS.HEATMAP) ||
                        pathname.includes(PATHS.PARTS.SOLUTIONS)
                      const isExercisesPath: boolean =
                        !isExistOtherPartsOfPath && prop.path === PATHS.EXERCISES.EXERCISES_MAIN
                      if (prop.redirect) {
                        return null
                      }
                      const isContentPage = prop.path === PATHS.CONTENT.BOOKSERIES
                      return !isContentPage ? (
                        <SideBarLink
                          key={prop.path}
                          index={index}
                          className={prop.className}
                          activeRoute={activeRoute}
                          path={prop.path}
                          setExerciseSubLinks={setExerciseSubLinks}
                          isExercisesPath={isExercisesPath}
                          icon={prop.icon}
                          name={prop.name}
                          exerciseSubLinks={exerciseSubLinks}
                          checkActiveFilterLink={checkActiveFilterLink}
                          localization={localization}
                          exercisesStatuses={exercisesStatuses}
                          changeExerciseStatuses={changeExerciseStatuses}
                          refs={refs}
                          exercisesStatusActions={exercisesStatusActions}
                          isExercisesPage={isExercisesPage}
                        />
                      ) : null
                    })}

                  <LinkHighlighter
                    className={clsx({
                      'sidebar-link-exercises-wrapper': isExercisesPage,
                      'sidebar-link-students-or-practice-wrapper':
                        (isPracticePage && !isPracticePageHidden) ||
                        (isStudentsPage && isPracticePageHidden),
                      'sidebar-link-students-wrapper': isStudentsPage && !isPracticePageHidden,
                    })}
                  />
                </div>
                <div className='profile-links-wrapper'>
                  <div className='profile-link-divider' />
                  <SideBarBottomLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Sidebar
