import { IExercise } from "api/types.assignments"

export const mergeAssignments = (oldAssignments: IExercise[], newAssignments: IExercise[]) => {
  const mergedAssignments = new Map()
  oldAssignments.forEach((assignment) => {
    mergedAssignments.set(assignment._id, assignment)
  })
  newAssignments.forEach((assignment) => {
    if (mergedAssignments.has(assignment._id)) {
      const oldAssignment = mergedAssignments.get(assignment._id)
      mergedAssignments.set(assignment._id, { ...oldAssignment, ...assignment })
    } else {
      mergedAssignments.set(assignment._id, assignment)
    }
  })

  return Array.from(mergedAssignments.values())
}