import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import styles from './Sidebar.module.scss'

type SidebarProps = PropsWithChildren<JSX.IntrinsicElements['div']>

const UISidebar = (props: SidebarProps) => {
  return (
    <div className={clsx(styles.Sidebar, props.className)} {...props}>
      {props.children}
    </div>
  )
}

export default UISidebar
