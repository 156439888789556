import React from 'react'
import clsx from 'clsx'
import styles from './StudentCard.module.scss'

type StudentCardProps = {
  textClassName?: string
  isActive?: boolean
  studentName: string
}

const StudentCard = ({ textClassName, isActive, studentName }: StudentCardProps) => {
  return (
    <div className={clsx(styles.StudentCardContainer, { [styles.Active]: isActive })}>
      <span className={clsx(styles.StudentCardContent, textClassName)} title={studentName}>
        {studentName}
      </span>
    </div>
  )
}

export default StudentCard
