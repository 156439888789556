import { ILocalization } from 'config/languages.config'
import { OptionsType } from './StudentsFilter'
import { GetExercisesPageDefaultFilterStateProps, InitialOptionType } from './types'

export const sortByName = (array: OptionsType[]) => {
  return [...array].sort((student1, student2) => {
    return student1.name.toLowerCase().localeCompare(student2.name.toLowerCase())
  })
}

export const getExercisesPageInitialFilterOption = ({
  defaultState,
  currentFilter,
  hasGoneToHeatmap,
}: GetExercisesPageDefaultFilterStateProps) => {
  return hasGoneToHeatmap && currentFilter ? currentFilter : defaultState
}

export const getDefaultFilterStateByPage = (t: ILocalization['data']) => {
  return {
    practice: { value: '0', name: t.allStudentsTxt, type: null },
    exercises: { value: '0', name: t.filter, type: null },
  }
}
