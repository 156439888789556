import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from 'store/store'
import { PATHS } from 'config/pathnames.config'
import { Button, BUTTON_COLOR, BUTTON_VARIANT, SIZE } from '@magmamath/ui'

const NoExercisesView = () => {
  const history = useHistory()
  const t = useSelector((state: RootState) => state.localization.data)
  const { available, unpublished, archived } = useSelector(
    (state: RootState) => state.exercisesStatuses.data
  )

  const emptyListText = (() => {
    if (available) {
      return t.noAvailable
    }

    if (unpublished) {
      return t.noUnpublished
    }

    if (archived) {
      return t.noArchived
    }
    return ''
  })()

  return (
    <div className='no-exercises-wrapper'>
      <p className='no-exercises-text'>{emptyListText}</p>
      {available && (
        <Button
          size={SIZE.LARGE}
          variant={BUTTON_VARIANT.PRIMARY}
          color={BUTTON_COLOR.ORANGE}
          onClick={() => {
            setTimeout(() => {
              history.push(PATHS.EXERCISES.CREATE)
              //show the button click animation and only then redirect
            }, 300)
          }}
        >
          {t.newExerciseTxt}
        </Button>
      )}
    </div>
  )
}

export default NoExercisesView
