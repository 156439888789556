import React, { Component } from 'react'
import './CancelButton.scss'

export interface ICancelButtonProps {
  title: string
  cancelFunc: () => void
  colorButton?: string
  className?: string
}

class CancelButton extends Component<ICancelButtonProps, any> {
  public render() {
    // @ts-ignore
    const { children, title, className } = this.props

    return (
      <>
        <button
          onClick={() => this.props.cancelFunc()}
          className={`cancel-button ${className || ''}`}
        >
          <span>{title}</span>
        </button>
        {children}
      </>
    )
  }
}
export default CancelButton
