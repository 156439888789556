import React from 'react'
import { SvgIconProps } from '../types/types'

const StopIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 10 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M8.93206 0.498047H1.07185C0.479882 0.498047 0 0.977929 0 1.56989V9.43011C0 10.0221 0.479882 10.502 1.07185 10.502H8.93206C9.52402 10.502 10.0039 10.0221 10.0039 9.43011V1.56989C10.0039 0.977929 9.52402 0.498047 8.93206 0.498047Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default StopIcon
