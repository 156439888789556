import { sessionStorageKeys } from 'config/localStorageKeys'
import { createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/session'

import { ExerciseStatuses } from '../../../../api/types.assignments'

const setExerciseStatus = createEvent<ExerciseStatuses>()
const resetExerciseStatus = createEvent()

const $exerciseStatus = createStore<ExerciseStatuses>(ExerciseStatuses.PublishNow)
  .on(setExerciseStatus, (_, payload) => payload)
  .reset(resetExerciseStatus)

persist({ store: $exerciseStatus, key: sessionStorageKeys.cafExerciseStatus })

export { $exerciseStatus, setExerciseStatus, resetExerciseStatus }
