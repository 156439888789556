import styles from '../ProblemCreatorSideBar.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { ImageListType } from 'react-images-uploading'
import useText from '../../../../i18n/hook'

const SideBarImage = () => {
  const images = useSelector((state: RootState) => state.problemCreatorData.images as ImageListType)
  const t = useText()
  return (
    <div>
      {images.map((image, index) => (
        <div key={index} className={styles.ImageItem}>
          <img src={image.dataURL} className={styles.ImageItem} alt={t.problemsTxt} />
        </div>
      ))}
    </div>
  )
}

export default SideBarImage
