import { BUTTON_VARIANT, Button, Modal, SIZE } from '@magmamath/ui'
import useText from 'i18n/hook'
import React, { useState } from 'react'
import styles from './ConfirmationModal.module.scss'

type ConfirmationModalProps = {
  contentText?: string
  confirmText?: string
  isModalOpen: boolean
  onModalClose: () => void
  onConfirm: () => Promise<void>
}

const ConfirmationModal = ({
  contentText,
  confirmText,
  isModalOpen,
  onConfirm,
  onModalClose,
}: ConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const t = useText()

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
    } catch (error) {
    } finally {
      setIsLoading(false)
      onModalClose()
    }
  }

  return (
    <Modal classes={{ Modal: styles.Modal }} isOpen={isModalOpen} onClose={onModalClose}>
      <Modal.Header title={t.areYouSure} />
      <Modal.Content className={styles.Content}>
        {contentText && <span className={styles.Text}>{contentText}</span>}
        <div className={styles.Actions}>
          <Button
            classes={{ content: styles.CancelButton }}
            size={SIZE.MEDIUM}
            variant={BUTTON_VARIANT.TERTIARY}
            onClick={onModalClose}
            disabled={isLoading}
          >
            {t.cancelTxt}
          </Button>
          <Button
            variant={BUTTON_VARIANT.SECONDARY}
            classes={{ content: styles.ConfirmChangeState }}
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            {confirmText ?? t.confirm}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmationModal
