import { Levels } from 'helpers/enums'
import { IBook } from '../../../api/types.books'
import { IState } from 'interfaces/state.interface'
import { ISection } from '../../../api/types.books'
import _ from 'lodash'
import { ILevel } from 'features/myBook/types'

export const getLevelsData = (
  easyLevelName: string,
  mediumLevelName: string,
  hardLevelName: string
) => {
  const levelsData: ILevel[] = [
    {
      name: easyLevelName,
      color: 'EasyColor',
      className: 'easy-level',
      complexity: Levels.EasyLevel,
    },
    {
      name: mediumLevelName,
      color: 'MediumColor',
      className: 'medium-level',
      complexity: Levels.MediumLevel,
    },
    {
      name: hardLevelName,
      color: 'HardColor',
      className: 'hard-level',
      complexity: Levels.HardLevel,
    },
  ]

  return levelsData
}

export const getSelectedSectionId = (
  defaultSection: IState<ISection>,
  cashedSection?: ISection
): string | undefined => {
  return !cashedSection && defaultSection && defaultSection.data._id
    ? defaultSection.data._id
    : cashedSection?._id
}

export const findSectionById = (
  books: IBook[],
  sectionId?: string | undefined
): ISection | null => {
  if (books.length > 0) {
    const foundSection: ISection | null | undefined = sectionId
      ? _.find(books[0].chapters[0].sections, {
        _id: sectionId,
      })
      : null
    const section: ISection = foundSection || books[0].chapters[0].sections[0]
    return section
  } else {
    return null
  }
}
