import clsx from 'clsx'
import { getExerciseDate } from 'helpers/general'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import TimeIcon from 'ui/icons/TimeIcon/TimeIcon'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import sharedStyles from '../../styles/Styles.module.scss'
import styles from './ExerciseDate.module.scss'

import { useBulkSelectContext } from 'features/ExercisesList/contexts/BulkSelectContext/BulkSelectContext'
import { COLORS } from '@magmamath/ui'
import { IExercise } from '../../../../api/types.assignments'

type ExerciseDateProps = {
  exercise: IExercise
}

const getExerciseDateType = (exercise: IExercise) => {
  if (exercise.archived && exercise.createdAt) return exercise.createdAt
  if (exercise.startDate) return exercise.startDate
  return null
}

const getDateString = (exercise: IExercise, languageTxt: string) => {
  const date = getExerciseDateType(exercise)
  if (!date) return ''
  return getExerciseDate(date, languageTxt, false)
}

const ExerciseDate = ({ exercise }: ExerciseDateProps) => {
  const { isBulkModeActive } = useBulkSelectContext()
  const t = useSelector((state: RootState) => state.localization.data)

  const isExerciseScheduled = !!(!exercise?.published && !exercise?.archived && exercise.startDate)
  const isExercisePublished = exercise?.published
  const exerciseDate = isExerciseScheduled
    ? exercise.startDate
    : isExercisePublished
    ? exercise.endDate
    : null
  const isTimeIconHidden =
    !exerciseDate ||
    (!isExerciseScheduled && !exercise.startDate && !isExercisePublished && !exercise.endDate)
  const timeActionText = isExercisePublished ? t.archiveExerciseTxt : t.publishExerciseTxt

  return (
    <UITooltip
      title={
        exerciseDate ? (
          <div className={styles.TooltipContent}>
            <span>{`${timeActionText} ${getExerciseDate(exerciseDate, t.languageTxt, true)}`}</span>
          </div>
        ) : null
      }
      placement='top'
    >
      <span
        className={clsx(sharedStyles.CardText, styles.Date, {
          [sharedStyles.DisableSelect]: isBulkModeActive,
        })}
      >
        {getDateString(exercise, t.languageTxt)}
        {!isTimeIconHidden && <TimeIcon color={COLORS.NEUTRAL_10} size={14} data-skip-redirect />}
      </span>
    </UITooltip>
  )
}

export default ExerciseDate
