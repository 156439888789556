import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { COLORS } from '@magmamath/ui'

export const TranslationLanguageSelectorModalBox = styled(Box)({
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  maxHeight: '650px',
  maxWidth: '910px',
  border: '1px solid #00000',
  background: COLORS.NEUTRAL_1,
  borderRadius: '20px',
})
