import { BUTTON_COLOR, Button, ICON_ALIGNMENT, SIZE } from '@magmamath/ui'
import useText from 'i18n/hook'
import React from 'react'
import AddIcon from 'ui/icons/AddIcon/AddIcon'
import styles from './NewClassButton.module.scss'

type NewClassButtonProps = {
  onClick: () => void
}

const NewClassButton = ({ onClick }: NewClassButtonProps) => {
  const t = useText()
  return (
    <Button
      classes={{ content: styles.NewClassButtonContent }}
      color={BUTTON_COLOR.ORANGE}
      size={SIZE.MEDIUM}
      icon={<AddIcon size={13} color={'var(--icon-color)'} />}
      iconAlign={ICON_ALIGNMENT.RIGHT}
      onClick={onClick}
    >
      {t.newClassTxt}
    </Button>
  )
}

export default NewClassButton
