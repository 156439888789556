import { getHelpCenterLink } from '../../../helpers/helpcenter'
import * as React from 'react'
import { useState } from 'react'
import HelpIcon from '../../icons/HelpIcon/HelpIcon'
import styles from './HelpLink.module.scss'
import { ILocalization } from '../../../config/languages.config'
import { COLORS } from '@magmamath/ui'

interface HelpLinkProps {
  localization: ILocalization
}

const HelpLink: React.FC<HelpLinkProps> = ({ localization }) => {
  const { helpcenterTxt, languageTxt } = localization.data
  const [isHovered, setIsHovered] = useState(false)

  const getIconColor = isHovered ? COLORS.NEUTRAL_10 : COLORS.NEUTRAL_9

  return (
    <li
      className={styles.HelpLink}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <a className={styles.HelpLinkWrapper} href={getHelpCenterLink(languageTxt)} target='_blank'>
        <div className={styles.IconWrapper}>
          <HelpIcon color={getIconColor} />
        </div>
        <span className={styles.Title}>{helpcenterTxt}</span>
      </a>
    </li>
  )
}

export default HelpLink
