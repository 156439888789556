import React from 'react'
import { SvgIconProps } from '../types/types'

const CompressHeatmapIcon = ({ size = 25, color }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5027 9.65719C10.0486 9.6702 9.66988 10.0489 9.65687 10.503L9.50008 15.9753C9.48707 16.4294 9.84466 16.787 10.2988 16.774C10.7529 16.761 11.1316 16.3823 11.1446 15.9282L11.2445 12.441L16.3645 17.561C16.6764 17.8729 17.1971 17.858 17.5274 17.5277C17.8577 17.1974 17.8726 16.6768 17.5607 16.3648L12.4407 11.2449L15.9279 11.1449C16.382 11.1319 16.7607 10.7532 16.7737 10.2991C16.7867 9.84498 16.4291 9.48739 15.975 9.5004L10.5027 9.65719Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.49738 8.34321C7.9515 8.3302 8.3302 7.9515 8.34321 7.49738L8.5 2.02507C8.51301 1.57095 8.15542 1.21335 7.70129 1.22637C7.24716 1.23938 6.86847 1.61807 6.85546 2.07219L6.75554 5.55936L1.63552 0.439341C1.32361 0.127425 0.802976 0.142343 0.47266 0.47266C0.142343 0.802976 0.127425 1.32361 0.439341 1.63552L5.55936 6.75554L2.07219 6.85546C1.61807 6.86847 1.23938 7.24716 1.22637 7.70129C1.21335 8.15541 1.57095 8.51301 2.02508 8.5L7.49738 8.34321Z'
        fill={color}
      />
    </svg>
  )
}

export default CompressHeatmapIcon
