import { Input, ValidationStatus } from '@magmamath/ui'
import { useUnit } from 'effector-react'
import { $errorMessages } from 'features/caf/errorMessages/model/errorMessages'
import useText from 'i18n/hook'
import React from 'react'
import { $exerciseName, setExerciseName } from '../../model/exerciseName'
import styles from './NameInput.module.scss'

const NameInput = () => {
  const t = useText()
  const { exerciseName } = useUnit($exerciseName)
  const { isSubmitted, messages } = useUnit($errorMessages)

  const isErrorVisible = messages.NAME && isSubmitted

  const validationState = (() => {
    if (!isErrorVisible && !exerciseName) return undefined
    return messages.NAME ? ValidationStatus.ERROR : ValidationStatus.SUCCESS
  })()

  return (
    <Input
      classes={{ InputControl: styles.InputControl }}
      value={exerciseName}
      label={t.exerciseNameTxt}
      placeholder={t.assignmentNamePlaceholder}
      onChange={(e) =>
        setExerciseName({
          exerciseName: e.target.value.trim() === '' ? '' : e.target.value,
          isAutofillEnabled: false,
        })
      }
      errorText={isErrorVisible ? t.requiredFieldText : undefined}
      validationStatus={validationState}
    />
  )
}

export default NameInput
