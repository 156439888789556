import React from 'react'
import styles from './ErrorMessage.module.scss'
import clsx from 'clsx'

interface ErrorMessageProps {
  title: string
  classes?: {
    border?: string
    title?: string
  }
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, classes }) => {
  return (
    <>
      <div className={clsx(styles.BorderOverlay, classes?.border)} />
      <div className={clsx(styles.ErrorText, classes?.border)}>{title}</div>
    </>
  )
}

export default ErrorMessage
