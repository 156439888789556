import React from 'react'
import styles from './StudentGradesItem.module.scss'
import { IStudent } from '../../../../../api/types.users'
import ButtonsOptionSelect from '../../../../../ui/selects/ButtonsOptionSelect/ButtonsOptionSelect'
import OptionsMenu from '../../../../../ui/selects/ButtonsOptionSelect/OptionsMenu/OptionsMenu'
import useText from '../../../../../i18n/hook'
import { StudentGrades } from '../../../models/types'
import { setOpenedStudentId } from '../../../models/openedStudentId'
import { AVAILABLE_GRADES } from '../../../models/studentsGradesModel'
import toggleItemReducer from '../../../../../lib/reducers/toggleItemReducer'

type StudentGradesItemProps = {
  student: IStudent
  studentGrades: number[]
  onSelectionChange: (studentGrades: StudentGrades) => void
  containerDimensions?: DOMRect
}

const StudentGradesItem = ({
  student,
  studentGrades,
  onSelectionChange,
  containerDimensions,
}: StudentGradesItemProps) => {
  const t = useText()
  const handleGradeItemSelect = (grade: number) => {
    onSelectionChange({ _id: student._id, grades: toggleItemReducer(studentGrades, grade) })
  }

  const handleGradeListSelect = () => {
    if (AVAILABLE_GRADES.length === studentGrades.length) {
      onSelectionChange({ _id: student._id, grades: [] })
      return
    }

    onSelectionChange({ _id: student._id, grades: AVAILABLE_GRADES })
  }

  const isAllOptionsSelected = AVAILABLE_GRADES.length === studentGrades.length
  return (
    <div className={styles.GradeItem}>
      <ButtonsOptionSelect
        allOptionsName={t.allGradesTxt}
        selectedOptions={studentGrades}
        isAllOptionsSelected={isAllOptionsSelected}
        specialOptionName={!studentGrades.length ? t.noneTxt : ''}
        containerDimensions={containerDimensions}
        onOpenMenuCallback={() => setOpenedStudentId(student._id)}
        onCloseMenuCallback={() => setOpenedStudentId('')}
      >
        <OptionsMenu
          enableAllOptionsSelect
          onListSelect={handleGradeListSelect}
          allOptionsName={t.allGradesTxt}
          options={AVAILABLE_GRADES}
          selectedOptions={studentGrades}
          onItemSelect={handleGradeItemSelect}
          isAllOptionsSelected={isAllOptionsSelected}
        />
      </ButtonsOptionSelect>
    </div>
  )
}

export default StudentGradesItem
