import React, { useMemo } from 'react'

import styles from './PracticeCategory.module.scss'

import PracticeSubcategory from '../PracticeSubcategory/PracticeSubcategory'
import { IStudent } from '../../../../api/types.users'
import { filterSubcategoriesByGrade } from '../../helpers'
import { IPracticeCategory, IStatistics } from 'api/types.oldPractice'

type PracticeCategoryProps = {
  category: IPracticeCategory
  grade: number
  statistics: IStatistics
  selectedStudents: IStudent[] | []
}

const PracticeCategory = ({
  category,
  grade,
  statistics,
  selectedStudents,
}: PracticeCategoryProps) => {
  const filteredSubcategories = useMemo(
    () =>
      !!category?.subcategories?.length
        ? filterSubcategoriesByGrade(category.subcategories, grade)
        : [],
    [category?.subcategories, grade]
  )

  return (
    <div className={styles.PracticeContentContainer}>
      <span className={styles.CategoryTitle}>{category?.primaryName}</span>
      <div className={styles.SubcategoriesBlockContainer}>
        {filteredSubcategories.map((subcategory) => {
          return (
            <PracticeSubcategory
              blockName={subcategory.primaryName}
              blockId={subcategory._id}
              abilities={subcategory.abilities}
              statistics={statistics}
              grade={grade}
              selectedStudents={selectedStudents}
              key={subcategory._id}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PracticeCategory
