import { apiManager } from './apiManager'
import { IMultipleSolutions } from './types.solutions'

type GetSolutionStatusesResponse = {}

type GetStudentSolutionsPayload = {
  exerciseId: string
  studentId: string
}

export type ChangeSolutionCorrectnessPayload = {
  solutionId: string
  data: {
    problemId: string
    correct: boolean
    exerciseId: string
  }
}

type ChangeSolutionCorrectnessResponse = {}

export type CreateDefaultAnswerPayload = {
  exercise: string
  user: string
  answer: string[]
} & ({ problem?: string; subProblem?: never } | { subProblem?: string; problem?: never })

export type CreateDefaultAnswerResponse = {}

export class solutionsApi {
  public static readonly getAllStatuses = apiManager.get<GetSolutionStatusesResponse, string>(
    (exerciseId) => `solution-statuses/exercises/${exerciseId}/attempts`
  )

  public static readonly getStudentAllSolutions = apiManager.get<
    IMultipleSolutions,
    GetStudentSolutionsPayload
  >(
    ({ exerciseId, studentId }) =>
      `solution-statuses/exercises/${exerciseId}/students/${studentId}/answers`
  )

  public static readonly changeCorrectness = apiManager.put<
    ChangeSolutionCorrectnessResponse,
    ChangeSolutionCorrectnessPayload
  >(({ solutionId, data }) => ({
    url: `solution-statuses/solution/${solutionId}/review`,
    body: data,
  }))

  public static readonly createDefaultAnswer = apiManager.post<
    CreateDefaultAnswerResponse,
    CreateDefaultAnswerPayload
  >('solutions/default')
}
