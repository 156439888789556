import React from 'react'
import { closeCreateOrEditExerciseModal, IModalActions } from '../../../redux/modules/modal.module'
import { INotify } from '../../../redux/modules/notify.module'
import { Modal } from '@mui/material'
import { ModalBox } from '../../basic/Boxes/ModalBox/ModalBox'
import { ModalContainedBtn } from '../../basic/Buttons/ModalContainedBtn/ModalContainedBtn'
import { ModalType } from 'helpers/enums'
import { useDispatch } from 'react-redux'
import { isEmpty, omit } from 'lodash'
import { addExercise } from '../../../redux/modules/exercise.module'
import '../modals.scss'
import { ILocalization } from '../../../config/languages.config'
import { AddExerciseData } from '../../../api/types.assignments'

interface ICreateOrEditExerciseModal {
  modalActions: IModalActions
  modal: any
  localization: ILocalization
  history: any
  notification: INotify
  location: Location
}

const CreateOrEditExerciseModal: React.FC<ICreateOrEditExerciseModal> = ({
  modal,
  localization,
  history,
}) => {
  const dispatch = useDispatch()
  const opened: boolean = modal.data.modalType === ModalType.CreateOrEditExercise
  const { creatingExercise, skillsModalText, create } = localization.data
  const dataForModal = modal.data.dataForCreateOrEditExerciseModal
  const { students } = dataForModal
  const dataForExercise = omit(dataForModal, ['numberOfProblems', 'exerciseId', 'updateMarker'])
  const handleClose = () => {
    dispatch(closeCreateOrEditExerciseModal())
  }

  const createExercise = () => {
    dispatch(addExercise(dataForExercise as AddExerciseData, history, localization))
  }

  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalBox>
        <div className='default-modal-header'>{creatingExercise}</div>
        <div className='default-modal-body'>
          <p>{`${skillsModalText}`}</p>
        </div>
        <div className='default-modal-footer create-edit-modal-footer'>
          <ModalContainedBtn
            variant='contained'
            onClick={createExercise}
            disabled={isEmpty(students)}
          >
            {create}
          </ModalContainedBtn>
        </div>
      </ModalBox>
    </Modal>
  )
}

export default CreateOrEditExerciseModal
