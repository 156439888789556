import React, { memo } from 'react'
import { ExerciseParticipantsInfo } from './helpers'
import styles from './ExerciseParticipants.module.scss'
import ExerciseParticipantsTooltipContent from './ExerciseParticipantsTooltipContent'
import useText from 'i18n/hook'

type ExerciseParticipantsTooltipProps = {
  participantsData: ExerciseParticipantsInfo['participantsData']
}

const ExerciseParticipantsTooltip = ({ participantsData }: ExerciseParticipantsTooltipProps) => {
  const t = useText()

  return (
    <div className={styles.TooltipContent}>
      <ExerciseParticipantsTooltipContent
        list={participantsData.classNames}
        textForSingle={t.classTxt}
        textForPlural={t.classesTxt}
        isSingleType={participantsData.isSingleType}
      />
      <ExerciseParticipantsTooltipContent
        list={participantsData.groupNames}
        textForSingle={t.groupTxt}
        textForPlural={t.groupsTxt}
        isSingleType={participantsData.isSingleType}
      />
      <ExerciseParticipantsTooltipContent
        list={participantsData.singleStudentNames}
        textForSingle={t.studentTxt}
        textForPlural={t.studentsTxt}
        isSingleType={participantsData.isSingleType}
      />
    </div>
  )
}

export default memo(ExerciseParticipantsTooltip)
