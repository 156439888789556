import { POWER_CUBE, POWER_SQUARE } from '../../ui/selects/SelectUnit/Units/Unit'
import { AnswerType, ImageType, MyScriptCharacterTypes } from '../../helpers/enums'
import { ProblemCreationTab } from '../../screens/ProblemCreator/ProblemCreator'
import { MultipleChoice } from '../../redux/modules/problemCreator.module'
import { IMAGE_DATA } from '../../redux/modules/images.module'

const MIN_REQUIRED_ANSWERS = 2
const MIN_REQUIRED_OPTIONS = 4

export const katexToBracketSystem = (input: string) => {
  // Handle roots inside fractions
  input = input.replace(/\\frac{\\sqrt{([^{}]+)}}{\\sqrt{([^{}]+)}}/g, '[√[$1]/√[$2]]')
  // Handle roots
  input = input.replace(/\\sqrt{([^{}]+)}/g, '√[$1]')
  // Handle powers
  input = input.replace(/\^{([^{}]+)}/g, '^[$1]')
  // Handle fractions (with all nested structures already transformed)
  input = input.replace(/\\frac{([^{}]+)}{([^{}]+)}/g, '[[$1]/[$2]]')
  // Remove extra square brackets for fractions that have single elements (like the roots)
  input = input.replace(/\[\[(√\[[^\]]+\]|\w+)\]\]/g, '[$1]')
  return removeExtraSlash(input)
}

export const bracketSystemToKatex = (input: string) => {
  // Handle fractions inside roots
  input = input.replace(/\[√\[(.*?)\]\/√\[(.*?)\]\]/g, '\\frac{\\sqrt{$1}}{\\sqrt{$2}}')
  // Handle roots
  input = input.replace(/√\[(.*?)\]/g, '\\sqrt{$1}')
  // Handle powers
  input = input.replace(/\^\[(.*?)\]/g, '^{$1}')
  // Handle fractions (with all nested structures already transformed)
  input = input.replace(/\[\[(.*?)\]\/\[(.*?)\]\]/g, '\\frac{$1}{$2}')
  // Remove extra brackets for fractions that have single elements (like the roots)
  input = input.replace(/\[\[(√\[\w+\]|\w+)\]\]/g, '{$1}')

  return input
}

export const replaceSpecialSymbolsWithBrackets = (value: string) => {
  return value.replace(/POWER_SQUARE/g, '^[2]').replace(/POWER_CUBE/g, '^[3]')
}

export const replaceBracketsWithSpecialSymbols = (value: string) => {
  return value.replace('^{2}', POWER_SQUARE).replace('^{3}', POWER_CUBE)
}

export const removeSelectedValueFromAnswer = (value: string) =>
  value.replace(
    /(\$|€|¢|km|cm|m|mm|dm|mil|mg|g|hg|kg|ton|ml|cl|dl|l|s|min|h|kr|sec|gal|qt|pt|cup|floz|ft|in|mi|yd|lb|oz|T)(\d+)?/g,
    '$2'
  )

export const getSelectedValueFromAnswer = (
  value: string,
  characterType: MyScriptCharacterTypes
) => {
  const match = value.match(
    /(km|cm|m|mm|dm|mil|mg|g|hg|kg|ton|ml|cl|dl|l|s|min|h|kr|$|€|¢|sec|gal|qt|pt|cup|floz|ft|in|mi|yd|lb|oz|T|\$)(\^{\d+})?$/
  )
  if (!match) return null
  const [firstElement] = match
  if (characterType === MyScriptCharacterTypes.CURRENCY && !firstElement) return '$'
  return replaceBracketsWithSpecialSymbols(firstElement)
}

export const removeDollarSigns = (value: string) => {
  return value.replace(/\$/g, '')
}

export const removeExtraSlash = (value: string) => {
  return value.replace(/\\/g, '')
}

export const isValidAnswerValue = (value: string) => {
  return !!value.replace(/\\/g, '').trim().length
}

export const getPlaceholder = (index: number, addAnswerTxt: string, optionalTxt: string) => {
  return `${addAnswerTxt} ${index + 1} ${
    index + 1 > MIN_REQUIRED_ANSWERS ? `(${optionalTxt?.toLowerCase()})` : ''
  }`
}

export const getCorrectAnswers = (answers: string[], correctAnswers: string[]) => {
  return answers.filter((item) => correctAnswers.includes(item))
}

export const getAnswerType = (activeTab: ProblemCreationTab, isMultipleAnswers: boolean) => {
  if (activeTab !== ProblemCreationTab.MULTIPLE_CHOICE) return AnswerType.handwriting
  return isMultipleAnswers ? AnswerType.multipleChoice : AnswerType.singleChoice
}

export const getChoices = (
  problemAnswer: string[],
  answerVariants: string[],
  firstProblemAnswer: string,
  isMultiple: boolean
) => {
  let foundTrueCase = false
  const correctAnswers = getCorrectAnswers(problemAnswer, answerVariants)
  return completeArray(answerVariants).map((el) => {
    const value = removeDollarSigns(bracketSystemToKatex(el || ''))
    const isCorrectAnswer = correctAnswers.includes(el)

    const isChecked = isMultiple
      ? isCorrectAnswer
      : value === removeDollarSigns(firstProblemAnswer) && !foundTrueCase
    if (isChecked && !isMultiple) {
      foundTrueCase = false
    }

    return { value, checked: isChecked }
  })
}

export const getMultipleOrSingleChoiceAnswer = (
  problemAnswer: string[],
  answerType: AnswerType,
  answerVariants: string[],
  firstProblemAnswer: string,
  multipleChoice: MultipleChoice[]
) => {
  if (answerType === AnswerType.singleChoice || answerType === AnswerType.multipleChoice)
    return getChoices(
      problemAnswer,
      answerVariants,
      firstProblemAnswer,
      answerType === AnswerType.multipleChoice
    )
  return multipleChoice
}

export const completeArray = (arr: string[]) => {
  const filler = new Array(MIN_REQUIRED_OPTIONS - arr.length).fill('')
  return [...arr, ...filler]
}

export const getImageURL = (
  type: ImageType,
  imageURLFromServer: string,
  imageData: IMAGE_DATA,
  cdnImage: string,
  images: string[]
): string => {
  if (imageURLFromServer) {
    return type === ImageType.IMAGE ? imageURLFromServer : cdnImage
  }

  if (images.length !== 0) {
    return type === ImageType.IMAGE
      ? `images/${imageData.image?.name}`
      : imageData?.image?.cdnId || ''
  }

  return ''
}
