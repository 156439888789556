import { PATHS } from 'config/pathnames.config'
import { SearchParam } from 'helpers/enums'
import { useOverflowDetection } from 'lib/hooks/useOverflowDetection'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from 'store/store'
import UICard from 'ui/cards/Card/Card'
import UITooltip from 'ui/tooltips/UITooltip/Tooltip'
import GroupCardDropdown from '../GroupCardDropdown/GroupCardDropdown'
import styles from './GroupCard.module.scss'
import { ClassroomGroup } from '../../../../api/types.classes'

type GroupCardProps = {
  groupDetails: ClassroomGroup
}

const GroupCard = ({ groupDetails }: GroupCardProps) => {
  const { overflowRef, isOverflowing } = useOverflowDetection()

  const history = useHistory()
  const localization = useSelector((state: RootState) => state.localization)
  const t = localization.data
  const studentsLength = groupDetails.students?.length ?? 0

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement

    // Some card elements should not make redirect:
    const skipRedirect =
      // items with specific data attribute
      target.getAttribute('data-skip-redirect') ||
      // options button icon
      target.nodeName === 'path' ||
      // options button icon
      target.nodeName === 'svg' ||
      // options dropdown backdrop
      target.classList.contains('MuiBackdrop-root') ||
      // options dropdown settings menu item
      target.classList.contains('MuiMenuItem-root')

    if (skipRedirect) return

    history.push(
      groupDetails?.classroomId
        ? `${PATHS.PARTS.CLASS_STUDENTS}${groupDetails?.classroomId}${SearchParam.GROUPS_TAB}&group=${groupDetails?._id}`
        : `${PATHS.PARTS.GROUP_STUDENTS}${groupDetails?._id}${SearchParam.STUDENTS_TAB}`
    )
  }

  return (
    <UICard className={styles.GroupCard} onClick={onCardClick}>
      <div className={styles.Header}>
        <UITooltip
          title={
            isOverflowing ? <span className={styles.HeaderTooltip}>{groupDetails.name}</span> : null
          }
          placement='top-start'
        >
          <h5 ref={overflowRef} className={styles.HeaderTitle}>
            {groupDetails.name}
          </h5>
        </UITooltip>
        <GroupCardDropdown groupDetails={groupDetails} />
      </div>
      <div className={styles.Body}>
        <p className={styles.InfoText}>
          {studentsLength}
          {studentsLength === 1
            ? ` ${t.studentTxt}`.toLowerCase()
            : ` ${t.studentsTxt}`.toLowerCase()}
        </p>
      </div>
    </UICard>
  )
}

export default GroupCard
