import axios from 'axios'
import { IState } from '../../interfaces/state.interface'
import { IMe } from '../../api/types.users'
import { IClass } from '../../api/types.classes'

type APIData = 'chapters' | 'exercises'

export const getData = async (url: string, params: any, typeOfData: APIData) => {
  const arr = []
  let currentPage = 1
  let pages = 0
  let res
  do {
    const result = await axios.get(url, {
      params: { ...params, page: currentPage },
    })
    pages = result.data.pages

    currentPage++
    res = result
    arr.push(...result.data._embedded[typeOfData])
    if (!Number.isInteger(pages)) {
      break
    }
  } while (currentPage <= pages)
  return {
    ...res,
    data: {
      ...res.data,
      _embedded: {
        [typeOfData]: arr,
      },
    },
  }
}

export const getDataForUnfollowClass = (classItem: IState<IClass>, me: IMe) => {
  const ownerId =
    classItem.data.owner?._id || (typeof classItem.data.owner === 'string' && classItem.data.owner) // owner sometimes is a string (ID) and sometimes an object IClass['owner']
  const isUserOwner = ownerId === me._id
  const isEmptyOwner = !ownerId
  const newTeachersIds = classItem.data.teachers.filter((teacher: string) => teacher !== me._id)

  if (me.municipality.isPrivate) {
    return {
      ownerId: isUserOwner || isEmptyOwner ? null : ownerId,
      teacherIds: newTeachersIds,
    }
  }

  if (isUserOwner || isEmptyOwner) {
    const newOwnerId = newTeachersIds[0]?.id || newTeachersIds[0]
    const teacherIds = newOwnerId
      ? newTeachersIds.filter((teacherId) => teacherId !== newOwnerId)
      : newTeachersIds
    return {
      ownerId: newOwnerId || ownerId,
      teacherIds,
      archived: newTeachersIds.length === 0,
    }
  }

  if (!isUserOwner && !isEmptyOwner) {
    return { teacherIds: newTeachersIds }
  }

  return { archived: true }
}
