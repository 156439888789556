import { createEvent, createStore } from 'effector'
import { fetchExerciseFx } from './requests'
import { IExercise } from '../../../api/types.assignments'

export const addExercise = createEvent<IExercise>()

export const $exercises = createStore<Map<string, IExercise>>(new Map())
  .on(addExercise, (state, exercise) => {
    const newState = new Map(state)
    if (newState.has(exercise._id)) {
      return newState
    }
    newState.set(exercise._id, exercise)
    return newState
  })
  .on(fetchExerciseFx.doneData, (state, exercise) => {
    const newState = new Map(state)
    newState.set(exercise._id, exercise)
    return newState
  })

export const $isExerciseDataPending = fetchExerciseFx.pending
