import { createEvent, createStore } from 'effector'
import { fetchAllBookSeriesFx } from './requests'
import { BookSeriesData } from '../../../api/types.bookSeries'

export const setBookSeries = createEvent<BookSeriesData[]>()

export const $allBookSeries = createStore<BookSeriesData[] | null>(null)
  .on(setBookSeries, (_, series) => series)
  .on(fetchAllBookSeriesFx.doneData, (_, series) => series)

export const $allBookSeriesLoading = fetchAllBookSeriesFx.pending
