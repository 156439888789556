import { useEffect } from 'react'
import { SortingTypes, TypesOfParams } from '../../helpers/enums'
import {
  defaultSortAndOrder,
  filterByClasses,
  filterByExam,
  filterByGroups,
  filterByStudents,
  filterByTopics,
  filterByWholeClass,
  ISelectedOption,
} from '../../redux/modules/filterExercises.module'
import { ClassesFilter } from '../filters/MultiplicityFilter/classesFilter.module'
import { FilterSelector } from '../filters/StudentsFilter/model'

import { ITopic } from '../../api/api.topics'

interface UseFilterDataParams {
  classesFilter: ClassesFilter | null
  filterSelector: FilterSelector | null
  sortBy: SortingTypes
  dispatch: (action: any) => void
  topics: ITopic[]
}

const useFilterData = ({
  classesFilter,
  filterSelector,
  sortBy,
  dispatch,
  topics,
}: UseFilterDataParams) => {
  useEffect(() => {
    if (!classesFilter || !filterSelector) {
      return
    }
    if (classesFilter.value === null && filterSelector.type === null) {
      dispatch(defaultSortAndOrder(sortBy))
      return
    }
    const classesFilterData: ISelectedOption = {
      _id: classesFilter.value as string,
      name: classesFilter.name,
      type: TypesOfParams.CLASS,
    }
    if (filterSelector.type === TypesOfParams.EXAM) {
      dispatch(filterByExam(classesFilterData, sortBy))
      return
    }
    if (filterSelector.type === TypesOfParams.TOPICS) {
      const topic = topics.find((t) => t._id === filterSelector.value)
      dispatch(filterByTopics([topic!._id], classesFilterData, sortBy))
      return
    }
    if (filterSelector.type === TypesOfParams.STUDENT) {
      dispatch(
        filterByStudents([filterSelector.value], classesFilterData, sortBy, {
          _id: [classesFilterData._id],
          type: classesFilterData.type as TypesOfParams,
        })
      )
      return
    }
    if (filterSelector.type === TypesOfParams.GROUPS) {
      dispatch(
        filterByGroups(
          [filterSelector.value],
          { ...filterSelector, _id: filterSelector.value },
          sortBy
        )
      )
      return
    }
    if (filterSelector.type === TypesOfParams.ALL_CLASSROOM_STUDENTS) {
      dispatch(
        filterByWholeClass(
          [filterSelector.value],
          { ...filterSelector, _id: filterSelector.value },
          sortBy
        )
      )
      return
    }
    if (classesFilter) {
      dispatch(filterByClasses([classesFilterData._id], classesFilterData, sortBy))
      return
    }
  }, [classesFilter, filterSelector, sortBy, dispatch, topics])
}

export default useFilterData
