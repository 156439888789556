import { useCallback, useState } from 'react'

export type OnSelectChangeEvent = {
  nativeEvent: { shiftKey: boolean }
  target: { checked: boolean }
}

export const useShiftSelected = <P>(
  initialState: P[],
  change: (addOrRemove: boolean, items: P[]) => void
) => {
  const [previousSelected, setPreviousSelected] = useState<P | null>(null)
  const [previousChecked, setPreviousChecked] = useState<boolean>(false)
  const [currentSelected, setCurrentSelected] = useState<P | null>(null)

  const onSelectChange = useCallback(
    (event: OnSelectChangeEvent, item: P) => {
      if (!event.nativeEvent.shiftKey) {
        setPreviousSelected(item)
        setCurrentSelected(null)
        setPreviousChecked(event.target.checked)
        return change(event.target.checked, [item])
      }

      if (event.nativeEvent.shiftKey) {
        const current = initialState.findIndex((x) => x === item)
        const previous = initialState.findIndex((x) => x === previousSelected)
        const previousCurrent = initialState.findIndex((x) => x === currentSelected)
        const start = Math.min(current, previous)
        const end = Math.max(current, previous)

        if (start > -1 && end > -1) {
          change(previousChecked, initialState.slice(start, end + 1))
          if (previousCurrent > end) {
            change(!previousChecked, initialState.slice(end + 1, previousCurrent + 1))
          }

          if (previousCurrent < start) {
            change(!previousChecked, initialState.slice(previousCurrent, start))
          }

          setCurrentSelected(item)

          return
        }
      }
    },
    [change, initialState, previousSelected, previousChecked, currentSelected]
  )

  return onSelectChange
}
