import React from 'react'
import { SvgIconProps } from '../types/types'

const TimeIcon = ({ size = 25, color, className }: SvgIconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0001 1.75005C5.4437 1.75005 1.75005 5.4437 1.75005 10.0001C1.75005 14.5564 5.4437 18.2501 10.0001 18.2501C14.5564 18.2501 18.2501 14.5564 18.2501 10.0001C18.2501 5.4437 14.5564 1.75005 10.0001 1.75005ZM0.0500488 10.0001C0.0500488 4.50482 4.50482 0.0500488 10.0001 0.0500488C15.4953 0.0500488 19.9501 4.50482 19.9501 10.0001C19.9501 15.4953 15.4953 19.9501 10.0001 19.9501C4.50482 19.9501 0.0500488 15.4953 0.0500488 10.0001Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0001 4.00657C10.4695 4.00657 10.8501 4.38713 10.8501 4.85657V10.0048L13.3228 12.1243C13.6792 12.4298 13.7205 12.9664 13.415 13.3228C13.1095 13.6792 12.5729 13.7205 12.2165 13.415L9.72626 11.2805C9.36055 10.9671 9.15007 10.5094 9.15007 10.0278V4.85657C9.15007 4.38713 9.53063 4.00657 10.0001 4.00657Z'
        fill={color}
      />
    </svg>
  )
}

export default TimeIcon
