import { createEvent, restore } from 'effector'

export enum SortOptions {
  SUBCHAPTER = 'subchapter',
  DIFFICULTY = 'difficulty',
  MANUAL = 'manual',
}

export class SortOptionModel {
  readonly set = createEvent<SortOptions>()
  readonly reset = createEvent()

  readonly $state = restore(this.set, SortOptions.SUBCHAPTER).reset(this.reset)
}
