import React, { useEffect, useMemo, useState } from 'react'

import './SkillsTable.scss'

import { useStoreMap } from 'effector-react'
import {
  $heatmapSettings,
  HeatmapSortOptions,
} from 'features/tables/HeatmapTable/model/heatmapSettings'
import { getFullName, getTeacherData } from 'helpers/user.helpers'
import { groupBy, isEmpty, uniqBy } from 'lodash'
import { useSelector } from 'react-redux'
import { ILocalization } from '../../config/languages.config'
import { FilterMultiplicityType, TypesOfParams } from '../../helpers/enums'
import {
  sortByAttempts,
  sortByFirstName,
  sortByLastName,
  sortByRandom,
  sortByRightAnswer,
} from '../../helpers/skills.helpers'
import { IState } from '../../interfaces/state.interface'
import { IStudent } from '../../api/types.users'
import { RootState } from '../../store/store'
import SkillsProgressTable from './SkillsProgressTable/SkillsProgressTable'
import { IExercise } from '../../api/types.assignments'
import { IProblem, ISubProblem } from '../../api/types.problem'
import { IClass } from '../../api/types.classes'
import { ISkills, GetSkillsStatisticsResponse, ISkillsUsers } from '../../api/types.statistics'
import { IGroup } from '../../api/api.groups'
import { ISkill } from '../../api/types.oldSkills'

interface ISkillsTableProps {
  history: any
  exercise: IState<IExercise>
  localization: ILocalization
  skillsStats: GetSkillsStatisticsResponse
  skillsData: ISkill[]
  isPrinting: boolean
}

export interface IUserFromExercise {
  _id: string
  firstName: string
  lastName: string
  isTeacher?: boolean
}

export interface IUserResult {
  userId: string
  correctAnswers: number
  attempts: number
}

export interface IUserSortedResult {
  [key: string]: IUserResult
}

export interface IUser {
  attempts: number
  correctAnswers: number
  fullName: string
  isTeacher: boolean
  initials: string
  userId: string
}

export interface IMergedSkillsStatsAndDataItem {
  book: string
  bookName: string
  chapter: string
  chapterName: string
  mainSection: string
  name: string
  skillId: string
  users: ISkillsUsers[]
}

const SkillsTable: React.FC<ISkillsTableProps> = ({
  history,
  exercise,
  localization,
  skillsStats,
  skillsData,
  isPrinting,
}) => {
  const sortOption = useStoreMap($heatmapSettings, ({ sortOption }) => sortOption)
  const [groupByBook, setGroupByBook] = useState<IMergedSkillsStatsAndDataItem[][]>([])
  const selectedValue = useSelector((state: RootState) => state.filterMultiplicity.data.filterValue)
  const currentTeacher = useSelector((state: RootState) => getTeacherData(state.auth.data.me))

  const problemsWithoutSubProblems: IProblem[] = exercise.data?.problems.filter(
    (problem: IProblem) => isEmpty(problem.subProblems)
  )
  const problemsWithSubProblems = exercise.data?.problems.filter(
    (problem: IProblem) => !isEmpty(problem.subProblems)
  )
  const subProblems: ISubProblem[] = problemsWithSubProblems.flatMap((problem: IProblem) => [
    ...problem.subProblems,
  ])
  const totalAmountProblemsAndSubProblems: number = [...problemsWithoutSubProblems, ...subProblems]
    .length

  useEffect(() => {
    if (skillsData) {
      const mergedSkillsStatsAndData: IMergedSkillsStatsAndDataItem[] = skillsStats.skills.map(
        (skills: ISkills) => {
          const res = skillsData.find((skill: ISkill) => skills?.skillId === skill._id)
          return res
            ? {
                ...skills,
                book: res.book,
                bookName: res.bookName,
                chapter: res.chapter,
                chapterName: res.chapterName,
                mainSection: res.mainSection,
                name: res.name,
              }
            : {
                ...skills,
                book: localization.data.other,
                bookName: localization.data.other,
                chapter: localization.data.other,
                chapterName: localization.data.other,
                name: localization.data.other,
                mainSection: localization.data.other,
                skillId: localization.data.other,
              }
        }
      )
      const chapter = Object.values(groupBy(mergedSkillsStatsAndData, 'chapter'))
      const skillsSortedByChapter = chapter.flatMap((item: IMergedSkillsStatsAndDataItem[]) =>
        item.flat()
      )
      const book = Object.values(groupBy(skillsSortedByChapter, 'book'))

      setGroupByBook(book)
    }
  }, [skillsData])

  const getStudentsFromMultiplicity = (multiplicityType: string) => {
    if (isEmpty(exercise.data[multiplicityType])) {
      return []
    }
    return exercise.data[multiplicityType].flatMap(
      (classOrGroup: IClass | IGroup) => classOrGroup.students
    )
  }

  const students: IStudent[] = [
    ...exercise.data?.students,
    ...getStudentsFromMultiplicity(TypesOfParams.CLASSES),
    ...getStudentsFromMultiplicity(TypesOfParams.GROUPS),
  ]

  const usersFromExercise: IUserFromExercise[] = [...uniqBy(students, '_id')]

  const allUsersData = skillsStats.skills.flatMap((skills: ISkills) => skills.users)
  const groupUsersById = groupBy(allUsersData, 'userId')
  const usersSortedById = Object.values(groupUsersById)
  const usersWithTotalResults = usersSortedById.flatMap((usersArray: ISkillsUsers[]) =>
    Object.values(
      usersArray.reduce((res: IUserSortedResult[], obj) => {
        res[obj.userId] = res[obj.userId] || {
          userId: obj.userId,
          correctAnswers: 0,
          attempts: 0,
        }
        res[obj.userId].correctAnswers += obj.solved
        res[obj.userId].attempts += obj.solved + obj.wrong
        return res
      }, [])
    )
  )

  const usersWithExtraFields = usersWithTotalResults.reduce<IUser[]>(
    (acc, userWithTotalRes: any) => {
      const exerciseUser = usersFromExercise.find(
        (userFromExercise) => userFromExercise._id === userWithTotalRes.userId
      )
      if (!exerciseUser) return acc

      const userWithExtraFields = {
        ...userWithTotalRes,
        isTeacher: exerciseUser.isTeacher || false,
        initials: `${exerciseUser.firstName[0] || ''} ${exerciseUser.lastName[0] || ''}`,
        firstName: exerciseUser.firstName ?? '',
        lastName: exerciseUser.lastName ?? '',
        fullName: getFullName(exerciseUser.firstName, exerciseUser.lastName),
      }

      acc.push(userWithExtraFields)
      return acc
    },
    []
  )

  const studentsWithExtraFields: IUser[] = usersWithExtraFields.filter(
    (user: IUser) => !user.isTeacher
  )
  const teacherWithExtraFields: IUser = usersWithExtraFields.find((user: IUser) => user.isTeacher)!

  const getSortedByGroupOrClass = (userStats: IUser[]) => {
    const { classes, groups } = exercise.data
    const arrayOfClassesAndGroups = [...classes, ...groups].find(
      (classOrGroup: IGroup | IClass) => classOrGroup._id === selectedValue
    )
    const studentsIds: string[] = arrayOfClassesAndGroups!.students.map(
      (student: IStudent) => student._id
    )
    return userStats.filter((userStat: IUser) =>
      studentsIds.find((id: string) => id === userStat.userId)
    )
  }

  const getSortedByMultiplicity = (userStats: IUser[]) => {
    const sortHandlerMap: Record<FilterMultiplicityType, IUser[]> = {
      [FilterMultiplicityType.ALL]: [...userStats, teacherWithExtraFields],
      [FilterMultiplicityType.SINGLE_STUDENTS]: userStats,
    }
    return sortHandlerMap[selectedValue] || getSortedByGroupOrClass(userStats)
  }

  const getSortedDataOfUsers = () => {
    const sortHandlerMap: Record<string, (firstItem: IUser, secondItem: IUser) => number> = {
      [HeatmapSortOptions.MOST_ANSWERS]: sortByAttempts,
      [HeatmapSortOptions.MOST_CORRECT_ANSWERS]: sortByRightAnswer,
      [HeatmapSortOptions.RANDOM]: sortByRandom,
      [HeatmapSortOptions.FIRST_NAME]: sortByFirstName,
      [HeatmapSortOptions.LAST_NAME]: sortByLastName,
    }

    const handler = sortHandlerMap[sortOption] || sortByFirstName
    const sortedByParam: IUser[] = studentsWithExtraFields.sort(handler)
    return getSortedByMultiplicity(sortedByParam)
  }

  const sortedUsers = useMemo(
    () => getSortedDataOfUsers().filter((user: IUser) => user),
    [sortOption]
  )

  const sortedSkills = groupByBook.map((item: any) =>
    item.map((skills: ISkills) => {
      const users = sortedUsers.map((user: ISkillsUsers) => {
        return skills.users.find((sortedUser: any) => user.userId === sortedUser.userId)
      })
      return { ...skills, users: [...users] }
    })
  )
  return (
    <div className='skills-table-wrapper'>
      <SkillsProgressTable
        history={history}
        localization={localization}
        skillsData={skillsData}
        exercise={exercise.data}
        teacherId={currentTeacher._id}
        totalAmountProblemsAndSubProblems={totalAmountProblemsAndSubProblems}
        groupByBook={sortedSkills}
        usersWithNameForTable={sortedUsers}
        isPrinting={isPrinting}
      />
    </div>
  )
}
export default SkillsTable
