import { apiManager } from './apiManager'
import {
  GetCommonErrorPayload,
  GetCommonErrorResponse,
  GetPracticeResultsPayload,
  GetPracticeResultsResponse,
  GetSingleStudentExamStatsPayload,
  GetSkillsChaptersStatisticsResponse,
  GetSkillsStatisticsPayload,
  GetStudentsProgressPayload,
  GetStudentProgressResponse,
  IStudentsExamStats,
  StatisticsType,
  UpdateExamStatusPayload,
  GetSkillsStatisticsResponse,
} from './types.statistics'

type GetSubcategoriesStatisticsPayload = {
  subcategoryId: string
  filterType: string
  id: string
}

export class statisticsApi {
  public static readonly getAllStudentsExam = apiManager.get<IStudentsExamStats[], string>(
    (exerciseId) => `statistics/exercises/${exerciseId}/students`
  )
  public static readonly updateExamStatus = apiManager.patch<void, UpdateExamStatusPayload>(
    ({ exerciseId, ...body }) => ({
      url: `statistics/exercises/${exerciseId}/students`,
      body,
    })
  )
  public static readonly getSingleStudentExam = apiManager.get<
    IStudentsExamStats,
    GetSingleStudentExamStatsPayload
  >(({ exerciseId, studentId }) => `statistics/exercises/${exerciseId}/student/${studentId}`)

  public static readonly getSingleAssignmentCommonError = apiManager.get<
    GetCommonErrorResponse,
    GetCommonErrorPayload
  >(({ exerciseId, ...params }) => ({
    url: `statistics/exercises/${exerciseId}/problems/common-error`,
    params,
  }))
  public static readonly getPracticeResults = apiManager.get<
    GetPracticeResultsResponse,
    GetPracticeResultsPayload
  >(
    ({ abilityId, urlType, filterOptionId }) =>
      `statistics/abilities/${abilityId}/${urlType}/${filterOptionId}`
  )
  public static getStudentsProgress = apiManager.get<
    GetStudentProgressResponse,
    GetStudentsProgressPayload
  >('statistics/student-progress')
  public static getSkillsChapters = apiManager.get<
    GetSkillsChaptersStatisticsResponse,
    GetSkillsStatisticsPayload
  >((params) => ({
    url: 'statistics',
    params: { ...params, type: StatisticsType.SKILLS_CHAPTERS },
  }))

  public static getSkills = apiManager.get<GetSkillsStatisticsResponse, GetSkillsStatisticsPayload>(
    (params) => ({
      url: 'statistics',
      params: { ...params, type: StatisticsType.SKILLS },
    })
  )

  public static getSubcategories = apiManager.get<
    GetSkillsStatisticsResponse,
    GetSubcategoriesStatisticsPayload
  >(
    ({ subcategoryId, filterType, id }) =>
      `statistics/categories/${subcategoryId}/${filterType}/${id}`
  )
}
