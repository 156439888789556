import React, { PropsWithChildren } from 'react'
import styles from './CreatingProblemCard.module.scss'

type ProblemCreatorCardHeaderProps = {
  title: string
}

const ProblemCreatorCardHeader = ({
  title,
  children,
}: PropsWithChildren<ProblemCreatorCardHeaderProps>) => {
  return (
    <div className={styles.Header}>
      <div className={styles.Title}>{title}</div>
      {children}
    </div>
  )
}

export default ProblemCreatorCardHeader
