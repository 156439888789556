import React from 'react'
import styles from './ProblemCreatorMain.module.scss'
import CreatingProblemCard from './common/CreatingProblemCard'
import SettingsCreatorBoard from './settingsBlock/SettingsCreatorBoard'
import useText from '../../i18n/hook'
import ProblemCreatorCardHeader from './common/ProblemCreatorCardHeader'
import ProblemDescriptionBlock from './problemDescriptionBlock/ProblemDescriptionBlock'
import AnswerCreationBlock from './answerBlock/AnswerCreationBlock'

const ProblemCreatorMain = () => {
  const t = useText()

  return (
    <div className={styles.Container}>
      <ProblemDescriptionBlock className={styles.DescriptionBlock} />
      <AnswerCreationBlock className={styles.AnswerBlock} />
      <CreatingProblemCard className={styles.SettingsBlock}>
        <ProblemCreatorCardHeader title={t.levelTxt} />
        <SettingsCreatorBoard />
      </CreatingProblemCard>
    </div>
  )
}

export default ProblemCreatorMain
