import React, { useRef } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import staticInputStyles from '../static/stylesStaticInput.module.scss'
import { useConst } from '../../../../lib/hooks/const'
import { PopUpMathInputModel } from './PopUpMathInputModel'
import MathShortcutButtons from '../../shortcuts/MathShortcutButtons'
import UIButton from '../../../../ui/Buttons/UIButton/Button'
import useText from '../../../../i18n/hook'
import { useStore } from 'effector-react'
import usePressOutside from '../../../../lib/hooks/usePressOutside'

type PopUpMathInputProps = {
  classes?: {
    container?: string
    input?: string
  }
  model: PopUpMathInputModel
}

const PopUpMathInput = ({ classes, model: extModel }: PopUpMathInputProps) => {
  const popUpRef = useRef<HTMLDivElement | null>(null)
  const model = useConst(() => extModel || new PopUpMathInputModel())
  const t = useText()
  const isVisible = useStore(model.$isVisible)

  usePressOutside(popUpRef, () => model.setIsVisible(false))

  return (
    <div
      ref={popUpRef}
      className={clsx(styles.Container, !isVisible && styles.HiddenInput, classes?.container)}
    >
      <span
        className={clsx(
          staticInputStyles.MathInput,
          staticInputStyles.WithText,
          styles.MathInput,
          classes?.input
        )}
        ref={model.setUp}
      />
      <MathShortcutButtons
        classes={{ container: styles.ShortcutButtonsContainer, button: styles.ShortcutButton }}
        onPress={model.insertOperator}
      />
      <UIButton
        className={styles.SubmitButton}
        onClick={() => {
          model.submitted()
          model.setIsVisible(false)
        }}
      >
        {t.saveTxt}
      </UIButton>
    </div>
  )
}

export default PopUpMathInput
