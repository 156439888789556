import { BUTTON_VARIANT, Button, SIZE } from '@magmamath/ui'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { checkLoadingState } from '../../model/helpers'
import { $pendingMutationStatus, updatePendingMutation } from '../../model/pendingMutationStatus'

import styles from './ExamStatusButtons.module.scss'
import { ICON_COLOR } from './constants'
import { getExamStatusButtonData, getIconColor } from './helpers'

import { ExerciseState } from '../../../../../api/types.assignments'

export type ChangeStudentExamStatusParams = {
  exerciseId: string
  newExerciseState: ExerciseState
  studentId: string
}

type UserSettingsButtonProps = {
  exerciseState: ExerciseState
  exerciseSettingsState?: ExerciseState
  isUserDeleted: boolean
  userId: string
  onClick: (() => void) | (() => Promise<void>)
}

const UserSettingsButton = ({
  exerciseState,
  exerciseSettingsState,
  isUserDeleted,
  onClick,
  userId,
}: UserSettingsButtonProps) => {
  const pendingMutationStatus = useUnit($pendingMutationStatus)
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const { color, Icon, isActive, isEndButton, isDisabled } = getExamStatusButtonData({
    exerciseState,
    exerciseSettingsState,
  })

  const iconColor = getIconColor({ color, isActive, isHovered })
  const noAction = pendingMutationStatus?.userId === userId

  useEffect(() => {
    updatePendingMutation(null)
  }, [isActive])

  return (
    <Button
      classes={{
        button: clsx({ [styles.NoAction]: isActive || noAction }),
        content: clsx(styles.ButtonContent, {
          [styles.EndButton]: isEndButton && iconColor === ICON_COLOR,
        }),
      }}
      disabled={isDisabled || isUserDeleted}
      isActive={isActive}
      size={SIZE.MEDIUM}
      icon={<Icon color={iconColor} size={12} />}
      color={color}
      variant={BUTTON_VARIANT.SECONDARY}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isLoading={checkLoadingState(exerciseState, userId, pendingMutationStatus)}
    />
  )
}

export default UserSettingsButton
