import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import { getItemFromLocalStorageWithParsing } from 'helpers/storage.helper'
import React from 'react'
import Exercises, { IExercisesProps } from 'views/Exercises/Exercises.view'
import HeatmapView from 'views/Heatmap/Heatmap.view'
import NewPieChart from 'views/PieChart/NewPieChart/NewPieChart.view'
import OldPieChart, { IPieChartViewProps } from 'views/PieChart/OldPieChart/OldPieChart.view'
import Skills, { ISkillsProps } from 'views/Skills/Skills.view'
import ExtendedSolution, {
  ExtendedSolutionRouteProps,
} from '../../features/DrawBoard/ExtendedSolution'
import CopyExerciseView from '../../views/CopyExercise/CopyExercise.view'
import CreateExerciseView from '../../views/CreateExercise/CreateExercise.view'
import EditExerciseView from '../../views/EditExercise/EditExercise.view'
import MultipleSolutionsLayout from '../../views/MultipleSolutionsLayout/MultipleSolutionsLayout'
import Solutions from '../../views/Solutions/Solutions.view'

export const ExercisesRoutes = [
  {
    exact: true,
    path: PATHS.EXERCISES.EXTENDED_VIEW,
    render(props: ExtendedSolutionRouteProps) {
      return <ExtendedSolution {...props} />
    },
  },
  {
    exact: true,
    path: PATHS.EXERCISES.EXERCISES_MAIN,
    render: (props: any) => {
      const exercisesProps: IExercisesProps = {
        autologinActions: props.autologinActions,
        classes: props.classes,
        exercise: props.exercise,
        exerciseActions: props.exerciseActions,
        exercises: props.exercises,
        exercisesActions: props.exercisesActions,
        exercisesStatusActions: props.exercisesStatusActions,
        filterExercisesActions: props.filterExercisesActions,
        groups: props.groups,
        history: props.history,
        localization: props.localization,
        location: props.location,
        modalActions: props.modalActions,
        notification: props.notify,
        topics: props.topics,
        topicsActions: props.topicsActions,
      }
      return <Exercises {...exercisesProps} />
    },
  },
  {
    exact: true,
    path: PATHS.EXERCISES.HEATMAP,
    render: () => <HeatmapView />,
  },
  {
    exact: true,
    path: PATHS.EXERCISES.CREATE,
    render: () => <CreateExerciseView />,
  },
  {
    exact: true,
    path: PATHS.EXERCISES.EDIT,
    render: () => <EditExerciseView />,
  },
  {
    exact: true,
    path: PATHS.EXERCISES.COPY,
    render: () => <CopyExerciseView />,
  },

  {
    exact: true,
    path: [
      PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTIONS,
      ...PATHS.EXERCISES.STUDENT_MULTIPLE_SOLUTION,
    ],
    render: () => <MultipleSolutionsLayout />,
  },
  {
    exact: true,
    path: PATHS.EXERCISES.SKILLS,
    render: (props: ISkillsProps) => {
      const skillsProps: any = {
        autologinActions: props.autologinActions,
        classes: props.classes,
        exercise: props.exercise,
        exerciseActions: props.exerciseActions,
        exercises: props.exercises,
        history: props.history,
        localization: props.localization,
        me: getItemFromLocalStorageWithParsing(localStorageKeys.me),
      }
      return <Skills {...skillsProps} />
    },
  },
  {
    exact: true,
    path: [
      ...PATHS.EXERCISES.STUDENTS_SOLUTIONS,
      ...PATHS.EXERCISES.STUDENT_SOLUTIONS,
      ...PATHS.EXERCISES.PINNED_SOLUTIONS,
      ...PATHS.EXERCISES.PINNED_SOLUTION,
    ],
    render: () => <Solutions />,
  },
  {
    exact: true,
    path: PATHS.EXERCISES.PIE_CHART,
    render: (props: any) => {
      if (!props.auth.data.me) {
        return null
      }
      if (props.auth.data.me?.setting.treeId) {
        return <NewPieChart />
      }
      const allPieChartProps: IPieChartViewProps = {
        history: props.history,
        localization: props.localization,
        match: props.match,
      }
      return <OldPieChart {...allPieChartProps} />
    },
  },
]
