import { createEvent, restore } from 'effector'
import { Group, SelectionUpdateEvent, Student } from '../types'
import { selectAll, unselectAll } from './events'

export type SetStudentsOfGroupSelectedProps = {
  group: Group
  selected: boolean
}

export const setStudentSelected = createEvent<SelectionUpdateEvent>()
export const setStudents = createEvent<Student[]>()

export const $students = restore(setStudents, [])
  .on(selectAll, (state) => state.map((group) => ({ ...group, selected: true })))
  .on(unselectAll, (state) => state.map((group) => ({ ...group, selected: false })))

  .on(setStudentSelected, (students, { id, selected }) =>
    students.map((student) => (student.id === id ? { ...student, selected } : student))
  )
