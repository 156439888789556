import fontStyle from '../assets/scss/fonts.scss'
import { COLORS } from '@magmamath/ui'

export const menuProps = {
  sx: {
    '& .MuiMenu-paper': {
      borderRadius: '10px',
      boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: '13px 6px 13px 6px',
    },

    '& .MuiMenu-paper::-webkit-scrollbar': {
      width: '10px',
      height: '301px',
    },

    '& .MuiMenu-paper::-webkit-scrollbar-track': {
      border: 'none',
    },

    '& .MuiMenu-paper::-webkit-scrollbar-thumb': {
      background: COLORS.NEUTRAL_6,
      borderRadius: '5px',
    },

    '& .MuiList-padding': {
      padding: 0,
    },

    '& .MuiMenuItem-root': {
      color: COLORS.NEUTRAL_10,
      padding: '4px 16px 4px 29px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
      borderRadius: '4px',

      '&:hover': {
        color: COLORS.PRIMARY_ORANGE,
        backgroundColor: '#FFF2E8!important',
      },
    },

    '& .MuiMenuItem-root.Mui-selected': {
      color: COLORS.PRIMARY_ORANGE,
      backgroundColor: '#FFF2E8!important',
    },
    '& .MuiListSubheader-root': {
      color: COLORS.NEUTRAL_10,
      lineHeight: '34px',
      fontSize: '16px',
    },
  },
}

export const selectStyles = {
  '& .MuiSelect-select': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
    hyphens: 'auto',
    maxWidth: 150,
    whiteSpace: 'normal!important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
  },
  '.MuiPaper-root': {
    borderRadius: '10px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
}

export const classesSelectStyles = {
  '& .MuiSelect-select': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
    hyphens: 'auto',
    maxWidth: 225,
    whiteSpace: 'normal!important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
  },
  '.MuiPaper-root': {
    borderRadius: '10px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
}

export const iconStyles = {
  width: '1em',
  height: '1em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  right: '14px',
}

export const inputStyles = {
  height: 46,
  font: fontStyle.defaultFont,
  color: COLORS.NEUTRAL_10,
  fontWeight: '400',
  backgroundColor: COLORS.NEUTRAL_1,
}
export const defaultInputStyles = {
  height: 46,
  font: fontStyle.defaultFont,
  color: COLORS.NEUTRAL_1,
  fontWeight: '400',
  backgroundColor: COLORS.PRIMARY_ORANGE,
  borderRadius: '12px',
}
export const notActiveInputStyles = {
  height: 46,
  font: fontStyle.defaultFont,
  color: COLORS.NEUTRAL_9,
  fontWeight: '400',
  backgroundColor: COLORS.NEUTRAL_1,
  borderRadius: '12px',
}

export const formControlStyles = { m: 1, width: 209, margin: 0 }
export const classesStyles = { m: 1, width: 250, margin: 0 }

export const studentsFilterFormControlStyles = { m: 1, width: 183, margin: 0 }
