import { TaskType } from '../../helpers/enums'
import {
  addStudentsStats,
  removeDuplicateStats,
  updateSingleStudentStat,
} from '../../helpers/exercise.helpers'
import { getAllStudentsFromExercise } from '../../helpers/getAllStudentFromExercise.helpers'
import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { IStudent, ITeacher } from '../../api/types.users'
import { IStudentStat } from 'api/types.solutions'
import { getTeacherData } from 'helpers/user.helpers'
import { api } from '../../api'
import { IProblem, ISubProblem } from '../../api/types.problem'
import { ExerciseState } from '../../api/types.assignments'
import { IClass } from '../../api/types.classes'
import { IGroup } from '../../api/api.groups'
import { EXERCISES } from './exercises.module'

export enum EXERCISE_WITHOUT_STATS {
  GET_EXERCISE_WITHOUT_STATS_REQUEST = 'GET_EXERCISE_WITHOUT_STATS_REQUEST',
  GET_EXERCISE_WITHOUT_STATS_SUCCESS = 'GET_EXERCISE_WITHOUT_STATS_SUCCESS',
  GET_EXERCISE_WITHOUT_STATS_ERROR = 'GET_EXERCISE_WITHOUT_STATS_ERROR',
  UPDATE_EXERCISE_STATS_REQUEST = 'UPDATE_EXERCISE_STATS_REQUEST',
  UPDATE_EXERCISE_STATS_SUCCESS = 'UPDATE_EXERCISE_STATS_SUCCESS',
  UPDATE_EXERCISE_STATS_ERROR = 'UPDATE_EXERCISE_STATS_ERROR',
  UPDATE_EXERCISE_STATS_FOR_STUDENT_REQUEST = 'UPDATE_EXERCISE_STATS_FOR_STUDENT_REQUEST',
  UPDATE_EXERCISE_STATS_FOR_STUDENT_SUCCESS = 'UPDATE_EXERCISE_STATS_FOR_STUDENT_SUCCESS',
  UPDATE_EXERCISE_STATS_FOR_STUDENT_ERROR = 'UPDATE_EXERCISE_STATS_FOR_STUDENT_ERROR',
  CLEAR_EXERCISE_WITHOUT_STATS = 'CLEAR_EXERCISE_WITHOUT_STATS',
  GET_EXERCISE_WITHOUT_STATS_MANUAL = 'GET_EXERCISE_WITHOUT_STATS_MANUAL',
  UPDATE_PROBLEM_WATCHED_STATISTICS_STATUS = 'UPDATE_PROBLEM_WATCHED_STATISTICS_STATUS',
}

export const exerciseInitialState = {
  data: {
    _id: null,
    isRequiredDrawing: false,
    isRequiredUnit: false,
    drawBoardAvailableFeatures: {
      calculator: false,
    },
    classes: [],
    problems: [],
    groups: [],
    students: [],
    published: true,
    archived: false,
    allowedAnswerLooking: true,
    testModeEnabled: false,
    enabledForEditing: true,
    name: '',
    isSectional: false,
    settings: {},
    startDate: '',
    endDate: null,
    createdAt: '',
    updatedAt: '',
    __v: 0,
    stats: {},
  },
  error: null,
  loading: false,
}

export const exerciseWithoutStatsReducer = (
  state: any = exerciseInitialState,
  action: IAction<EXERCISE_WITHOUT_STATS>
) => {
  switch (action.type) {
    case EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_SUCCESS:
      const students = getAllStudentsFromExercise(state.data)
      const tasksWithStats = state.data.problems.map((problem: IProblem) => ({
        ...problem,
        studentStats:
          action.payload &&
          addStudentsStats(state.data, problem, TaskType.Problem, students, action.payload),
        subProblems: problem.subProblems.map((subProblem: ISubProblem) => ({
          ...subProblem,
          studentStats:
            action.payload &&
            addStudentsStats(state.data, subProblem, TaskType.SubProblem, students, action.payload),
        })),
      }))
      return {
        ...state,
        data: {
          ...state.data,
          problems: tasksWithStats,
          stats: action.payload.stats.data.exercise,
        },
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_SUCCESS:
      const newProblems = updateSingleStudentStat(
        action.payload.studentId,
        state.data.problems,
        action.payload.res
      )
      return {
        ...state,
        data: {
          ...state.data,
          problems: newProblems,
        },
        loading: false,
        error: null,
      }
    case EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_MANUAL:
      return {
        ...state,
        data: { ...action.payload },
        error: null,
        loading: false,
      }
    case EXERCISE_WITHOUT_STATS.CLEAR_EXERCISE_WITHOUT_STATS:
      return exerciseInitialState
    case EXERCISE_WITHOUT_STATS.UPDATE_PROBLEM_WATCHED_STATISTICS_STATUS:
      const updatedProblems = state.data.problems.map((problem: IProblem) => {
        if (problem._id !== action.payload.problemId) return problem

        const updatedStudentStats = problem.studentStats.map((studentStat: IStudentStat) => {
          if (studentStat.studentId !== action.payload.studentId) return studentStat
          return { ...studentStat, stats: { ...studentStat.stats, watched: true } }
        })

        return {
          ...problem,
          studentStats: updatedStudentStats,
        }
      })
      return {
        ...state,
        data: {
          ...state.data,
          problems: updatedProblems,
        },
        loading: false,
        error: null,
      }
    default:
      return state
  }
}

export const getExerciseWithoutStats = (id: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_REQUEST })
      const [res, stats] = await Promise.all([
        api.assignments.getSingle({ id, fetchAll: 0, useCache: false }),
        api.assignments.getSingleStats(id),
      ])

      const students = getAllStudentsFromExercise(res, stats.students)
      const updatedStats = { ...stats, students: removeDuplicateStats(stats.students) }
      const teacher = getTeacherData()

      const tasksWithStats = res.problems.map((problem: IProblem) => ({
        ...problem,
        studentStats: addStudentsStats(
          res,
          problem,
          TaskType.Problem,
          [...students, teacher],
          updatedStats
        ),
        subProblems: problem.subProblems.map((subProblem: ISubProblem) => ({
          ...subProblem,
          studentStats:
            updatedStats &&
            addStudentsStats(
              res,
              subProblem,
              TaskType.SubProblem,
              [...students, teacher],
              updatedStats
            ),
        })),
      }))

      dispatch({
        type: EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_SUCCESS,
        payload: {
          ...res,
          problems: tasksWithStats,
          stats: updatedStats.exercise,
          students,
        },
      })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISE_WITHOUT_STATS.GET_EXERCISE_WITHOUT_STATS_ERROR,
      })
    }
  }
}

export const updateStats = (id: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_REQUEST })
      const res = await api.assignments.getSingleStats(id)
      dispatch({ type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_SUCCESS, payload: res })
      dispatch({
        type: EXERCISES.EXERCISES_UPDATE_EXERCISE_STATS_IN_LIST,
        payload: { exerciseId: id, stats: res },
      })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_ERROR,
      })
    }
  }
}

export const getStatsForSingleStudent = (exerciseId: string, studentId: string) => {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_REQUEST })
      const res = await api.solutions.getStudentAllSolutions({ exerciseId, studentId })
      dispatch({
        type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_SUCCESS,
        payload: { res: res, studentId },
      })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: EXERCISE_WITHOUT_STATS.UPDATE_EXERCISE_STATS_FOR_STUDENT_ERROR,
      })
    }
  }
}

export const clearExerciseWithoutStats = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: EXERCISE_WITHOUT_STATS.CLEAR_EXERCISE_WITHOUT_STATS })
  }
}

export const setAnswerWatched = (payload: {
  problemId: string
  studentId: string
  exerciseId: string
}) => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: EXERCISE_WITHOUT_STATS.UPDATE_PROBLEM_WATCHED_STATISTICS_STATUS, payload })
  }
}

export interface IStats {
  _id: string
  exerciseId: string
  teacherId: string
  __v: number
  activeUsers: number
  afterFirstAttempt: number
  completedAmount: number
  createdAt: string
  firstAttempt: number
  notAnswered: number
  totalStudents: number
  updatedAt: string
  wrong: number
}

export interface IDrawBoardAvailableFeatures {
  calculator?: number
}

export interface IExerciseWithoutStats {
  allClassroomStudents: boolean
  _id: string
  isRequiredDrawing: boolean
  isRequiredUnit: boolean
  drawBoardAvailableFeatures: IDrawBoardAvailableFeatures
  classes: IClass[]
  problems: IProblem[]
  groups: IGroup[]
  students: IStudent[]
  published: boolean
  archived: boolean
  allowedAnswerLooking: boolean
  testModeEnabled: boolean
  cheatDetection: boolean
  enabledForEditing: boolean
  name: string
  isSectional: boolean
  settings: {
    assessed: boolean
    state: ExerciseState
  }
  teachers: ITeacher[]
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  __v: number
  stats: IStats
  hasSkills?: boolean
}
