import { mergeProblemsAndSubProblems } from 'features/tables/HeatmapTable/helpers/data'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { ProblemOrSubProblemObject } from './model/types'
import { ISubProblem } from '../../api/types.problem'

const _createProblemOrSubProblemObject = ({
  problemOrSubProblemData,
  isCurrent,
}: {
  problemOrSubProblemData: { _id: string; subProblems?: ISubProblem[] }
  isCurrent: boolean
}): ProblemOrSubProblemObject => ({
  problemOrSubProblemId: problemOrSubProblemData._id,
  isProblem: 'subProblems' in problemOrSubProblemData,
  isCurrent,
})

export const getPinsObjectMemoryKey = (exerciseId: string, problemOrSubProblemId: string) =>
  `${exerciseId}-${problemOrSubProblemId}`

export const getPinsProblemOrSubProblemParams = ({
  problems,
  taskId,
}: {
  problems: IExerciseWithoutStats['problems']
  taskId: string
}) => {
  const mergedData = mergeProblemsAndSubProblems(problems)
  const currentIndex = mergedData.findIndex((item) => item._id === taskId)
  if (currentIndex === -1) return null

  const currentData = _createProblemOrSubProblemObject({
    problemOrSubProblemData: mergedData[currentIndex],
    isCurrent: true,
  })

  const nextData =
    currentIndex < mergedData.length - 1
      ? _createProblemOrSubProblemObject({
          problemOrSubProblemData: mergedData[currentIndex + 1],
          isCurrent: false,
        })
      : null

  const prevData =
    currentIndex > 0
      ? _createProblemOrSubProblemObject({
          problemOrSubProblemData: mergedData[currentIndex - 1],
          isCurrent: false,
        })
      : null

  return [currentData, nextData, prevData]
}
