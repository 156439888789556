import { localStorageKeys } from 'config/localStorageKeys'
import { PATHS } from 'config/pathnames.config'
import { SelectedValueTypes } from 'helpers/enums'
import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { setItemToLocalStorage } from 'helpers/storage.helper'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { getBooksForCreatingProblems } from './books.module'
import { closeModal } from './modal.module'
import { openSubchapter } from './problemsSidebarLinks.module'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'
import { UpdateSingleSectionPayload } from '../../api/api.sections'

export enum SECTION {
  SECTION_REQUEST = 'SECTION_REQUEST',
  SECTION_SUCCESS = 'SECTION_SUCCESS',
  SECTION_ERROR = 'SECTION_ERROR',
  SECTION_EDIT_REQUEST = 'SECTION_EDIT_REQUEST',
  SECTION_EDIT_SUCCESS = 'SECTION_EDIT_SUCCESS',
  SECTION_EDIT_ERROR = 'SECTION_EDIT_ERROR',
  SECTION_ADD_REQUEST = 'SECTION_ADD_REQUEST',
  SECTION_ADD_SUCCESS = 'SECTION_ADD_SUCCESS',
  SECTION_ADD_ERROR = 'SECTION_ADD_ERROR',
  SECTION_FROM_CHAPTER_REQUEST = 'SECTION_FROM_CHAPTER_REQUEST',
  SECTION_FROM_CHAPTER_SUCCESS = 'SECTION_FROM_CHAPTER_SUCCESS',
  SECTION_FROM_CHAPTER_ERROR = 'SECTION_FROM_CHAPTER_ERROR',
  SECTION_DELETE_ERROR = 'SECTION_DELETE_ERROR',
  SECTION_DELETE_SUCCESS = 'SECTION_DELETE_SUCCESS',
  SECTION_DELETE_REQUEST = 'SECTION_DELETE_REQUEST',
  SECTION_CLEAR = 'SECTION_CLEAR',
}

// Section reducer
const initialState = {
  data: {
    isInitial: true,
    name: null,
    order: 13,
  },
  error: null,
  loading: false,
}

export function sectionReducer(state: any = initialState, action: IAction<SECTION>) {
  switch (action.type) {
    case SECTION.SECTION_ADD_REQUEST:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
        loading: true,
      }
    case SECTION.SECTION_ADD_SUCCESS:
      return {
        data: { ...state.data },
        error: action.payload,
        loading: true,
      }
    case SECTION.SECTION_ADD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case SECTION.SECTION_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SECTION.SECTION_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SECTION.SECTION_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SECTION.SECTION_FROM_CHAPTER_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SECTION.SECTION_FROM_CHAPTER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SECTION.SECTION_FROM_CHAPTER_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case SECTION.SECTION_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SECTION.SECTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case SECTION.SECTION_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case SECTION.SECTION_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SECTION.SECTION_DELETE_SUCCESS:
      return {
        data: { ...state.data },
        error: null,
        loading: false,
      }
    case SECTION.SECTION_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SECTION.SECTION_CLEAR:
      return {
        data: {},
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

// Section actions

export function getSection(sectionId: string, history: any) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SECTION.SECTION_REQUEST })
      const res = await api.sections.getSingle(sectionId)
      dispatch({ type: SECTION.SECTION_SUCCESS, payload: res })
      if (
        history.location &&
        (history.location.pathname === PATHS.CONTENT.PROBLEMS ||
          history.location.pathname === PATHS.CONTENT.ADD_NEW_PROBLEM)
      ) {
        setItemToLocalStorage(localStorageKeys.section, JSON.stringify(res))
      }
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SECTION.SECTION_ERROR,
      })
    }
  }
}

export function editSection(
  data: UpdateSingleSectionPayload['data'],
  sectionId: string,
  history: any
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SECTION.SECTION_EDIT_REQUEST })
      const res = await api.sections.updateSingle({ id: sectionId, data })
      dispatch({ type: SECTION.SECTION_EDIT_SUCCESS, payload: res })
      getBooksForCreatingProblems(SelectedValueTypes.INITIALBOOKS)(dispatch)
      await openSubchapter(res._id, res.name, history)(dispatch)
      closeModal()(dispatch)
      history.push(PATHS.CONTENT.PROBLEMS)
    } catch (error) {
      closeModal()(dispatch)
      checkNetworkError(
        error,
        () => {
          if (!!error.response) {
            dispatch({
              payload: !!error.response ? error.response.data.message : error.message,
              type: SECTION.SECTION_EDIT_ERROR,
            })
          }
        },
        () => handleNetworkError(SECTION.SECTION_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export const clearSection = () => {
  return async (dispatch: IDispatch<any>) => {
    dispatch({ type: SECTION.SECTION_CLEAR })
  }
}

export interface ISectionActions {
  editSection: (
    data: { name?: string; problems?: string },
    sectionId: string,
    history: any,
    localization: ILocalization
  ) => void
  getSection: (sectionId: string, history: any) => void
  clearSection: () => void
}
