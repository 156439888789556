import React from 'react'
import { SvgIconProps } from '../types/types'

const MassIcon: React.FC<SvgIconProps> = ({ size = 25, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_123_218)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.78118 6.55664H6.4028H5.96477C4.90816 6.55664 3.98603 7.27706 3.72668 8.30485L0.268694 22.1369C0.095794 22.8236 0.249482 23.5537 0.686535 24.1155C1.12359 24.6775 1.79597 24.9993 2.50678 24.9993H22.4863C23.1971 24.9993 23.8647 24.6727 24.3018 24.1107C24.7388 23.5488 24.8925 22.8236 24.7196 22.1321L21.2616 8.30004C21.007 7.27706 20.0849 6.55664 19.0283 6.55664H18.5882H17.2074V8.22958H19.0283C19.3181 8.22958 19.5695 8.42807 19.6382 8.704L19.6386 8.7058L23.0965 22.5377C23.1446 22.73 23.1031 22.927 22.9812 23.0837C22.8607 23.2385 22.6802 23.3263 22.4863 23.3263H2.50678C2.30121 23.3263 2.12047 23.2343 2.00707 23.0885C1.8851 22.9316 1.84428 22.7309 1.89101 22.5452L1.89134 22.5439L1.89168 22.5425L5.34877 8.71417L5.34909 8.71293C5.4223 8.42515 5.67863 8.22958 5.96477 8.22958H7.78118V6.55664Z'
          fill='black'
        />
        <path
          d='M18.4387 7.37695H6.24609'
          stroke={color}
          strokeWidth='1.67294'
          strokeLinecap='round'
        />
        <path
          d='M12.4945 6.94989C14.1828 6.94989 15.5514 5.58123 15.5514 3.89291C15.5514 2.20459 14.1828 0.835938 12.4945 0.835938C10.8062 0.835938 9.4375 2.20459 9.4375 3.89291C9.4375 5.58123 10.8062 6.94989 12.4945 6.94989Z'
          stroke={color}
          strokeWidth='1.66'
        />
      </g>
      <defs>
        <clipPath id='clip0_123_218'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MassIcon
