import React from 'react'
import ProblemCreatorCardHeader from '../common/ProblemCreatorCardHeader'
import { isEmpty } from 'lodash'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import useText from '../../../i18n/hook'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { setImages, setImageURL } from '../../../redux/modules/problemCreator.module'
import UploadImageButton from './UploadImageButton'
import InsertFormulaButton from './InsertFormulaButton'
import { acceptType } from '../constatnts'
import { clearImage } from '../../../redux/modules/images.module'

type ProblemDescriptionBlockHeaderProps = {
  onInsertFormulaPress?: () => void
}
const ProblemDescriptionBlockHeader = ({
  onInsertFormulaPress,
}: ProblemDescriptionBlockHeaderProps) => {
  const t = useText()
  const dispatch = useDispatch()
  const images = useSelector((state: RootState) => state.problemCreatorData.images)
  const imageURL = useSelector((state: RootState) => state.problemCreatorData.imageURL)

  const setCurrentImages = (param: ImageListType) => {
    if (Array.isArray(param) && param.length === 0) {
      dispatch(clearImage())
    }
    dispatch(setImages(param))
    dispatch(setImageURL(''))
  }

  return (
    <ProblemCreatorCardHeader title={t.questionTxt}>
      <div>
        <InsertFormulaButton onClick={onInsertFormulaPress} label={t.insertEquation} />
        <ImageUploading
          multiple={false}
          value={images}
          onChange={setCurrentImages}
          acceptType={acceptType}
        >
          {({ onImageUpload }) => (
            <UploadImageButton
              variant={isEmpty(images) && !imageURL ? 'upload' : 'reset'}
              onUploadPress={onImageUpload}
              onResetPress={() => setCurrentImages([])}
            />
          )}
        </ImageUploading>
      </div>
    </ProblemCreatorCardHeader>
  )
}

export default ProblemDescriptionBlockHeader
