import Quill, { RangeStatic } from 'quill'
import { DeltaItem, EmitterSources, ExtendedQuill } from './types.quill'
import { getInitialFormulaForKatex, isMathOperator } from '../common/helpers.katex'
import katex from 'katex'
import { QUILL_ERRORS, renderFormulaToElement } from './helpers'
import { ConvertFormat } from '../common/types'
import { EditingFormula, FormulaDraft } from './types'

type InsertValueSettings = {
  toRange?: RangeStatic | null
  toIndex?: number
  finishSymbol?: string
  preventSelection?: boolean
}
export class QuillAreaManager {
  public isRealtimeUpdatesEnabled = false
  private quill: ExtendedQuill | null = null
  private _placeholder = ''

  public get placeholder() {
    return this._placeholder
  }

  private editingFormula: EditingFormula | null = null
  public cleanEditingFormula() {
    if (!this.editingFormula) return
    if (this.editingFormula.isDraft) {
      this.editingFormula.element.remove()
    }
    this.editingFormula = null
  }

  public setEditingFormula(formula: EditingFormula): FormulaDraft {
    this.cleanEditingFormula()
    this.editingFormula = formula
    return {
      element: formula.element,
      edit: this.isRealtimeUpdatesEnabled
        ? (e) => renderFormulaToElement(e.latex, formula.element)
        : null,
      finish: () => {
        if (this.editingFormula?.element === formula.element) {
          this.cleanEditingFormula()
        }
      },
    }
  }

  public isEditingFormula(element: HTMLElement) {
    return this.editingFormula?.element === element
  }

  public setUp(quill: ExtendedQuill) {
    this.quill = quill
  }

  public detach() {
    this.quill = null
  }

  public getInsertIndex(knownRange?: RangeStatic | null) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    const range = knownRange ?? this.quill.getSelection()
    return range ? range.index + range.length : this.quill.getLength() - 1
  }

  public prepareInsertValue(value: string, index: number) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    const contentBefore = this.quill.getContents(index - 1, 1).ops[0]?.insert
    const contentAfter = this.quill.getContents(index, 1).ops[0]?.insert
    if (!isMathOperator(value)) return value
    if (!contentAfter || (typeof contentAfter === 'string' && !contentAfter.startsWith(' '))) {
      value = value + ' '
    }
    if (typeof contentBefore === 'string' && !contentBefore.endsWith(' ')) {
      value = ' ' + value
    }
    return value
  }

  public insertValue(
    value: string,
    { toRange, toIndex, preventSelection }: InsertValueSettings = {}
  ) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    const index = toIndex ?? this.getInsertIndex(toRange)
    const contentToInsert = this.prepareInsertValue(value, index)
    this.quill.insertText(index, contentToInsert, EmitterSources.USER)
    if (!preventSelection) {
      this.quill.setSelection(index + contentToInsert.length, 0, EmitterSources.USER)
    }
  }

  public insertFormula(
    value: string,
    { toRange, finishSymbol = ' ', preventSelection }: InsertValueSettings = {}
  ) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    const index = this.getInsertIndex(toRange)
    this.quill.insertEmbed(index, 'formula', value, EmitterSources.USER)
    this.insertValue(finishSymbol, { toIndex: index + 1, preventSelection })
  }

  public createFormulaDraft(forFormula: string = ''): FormulaDraft {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    this.cleanEditingFormula()
    if (!this.isRealtimeUpdatesEnabled) return {}
    const selectionPosition = this.quill.getSelection(true)
    this.quill.insertEmbed(selectionPosition.index, 'formula', `++PTN++`, EmitterSources.USER)
    const element = this.quill.container.querySelector(`[data-value='++PTN++']`) as HTMLElement
    if (!element) return {}
    const initialFormula = getInitialFormulaForKatex(forFormula)
    if (initialFormula) {
      katex.render(initialFormula, element, {
        throwOnError: false,
        output: 'html',
      })
    }

    const elementRect = element.getBoundingClientRect()
    const coords = { x: elementRect.x, y: elementRect.y }
    element.innerHTML = ''
    element.setAttribute('data-value', '')
    this.editingFormula = {
      element,
      isDraft: true,
      initialValue: initialFormula || '',
    }
    return {
      coords,
      element,
      finish: () => {
        element.remove()
        this.editingFormula = null
      },
      edit: (e) => renderFormulaToElement(e.latex, element),
    }
  }

  public readonly findRootFormulaOfElement = (target: HTMLElement) => {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    let current = target
    while (current.parentElement && current.parentElement !== this.quill.container) {
      if (current instanceof HTMLElement && current.className.includes('ql-formula')) {
        return current
      }
      current = current.parentElement
    }
    return null
  }

  public convert(format: ConvertFormat.HTML | ConvertFormat.KATEX): string
  public convert(format?: ConvertFormat.RAW): ReturnType<Quill['getContents']>
  public convert(format: ConvertFormat | void = ConvertFormat.RAW) {
    if (!this.quill) throw QUILL_ERRORS.NOT_INITIALIZED
    if (format === ConvertFormat.HTML) {
      return this.quill.root.innerHTML
    }
    if (format === ConvertFormat.KATEX) {
      return this.quill.getContents().reduce((acc, op: DeltaItem) => {
        if (typeof op.insert === 'string') return acc + op.insert
        if (op.insert.formula) return acc + `$${op.insert.formula}$`
        return acc
      }, '')
    }
    return this.quill.getContents()
  }

  public focus(range: RangeStatic | null = null) {
    setTimeout(() => {
      if (range) {
        this.quill?.setSelection(range.index, 0, EmitterSources.USER)
        return
      }
      this.quill?.focus()
    })
  }

  public setPlaceholder(placeholder: string) {
    if (placeholder === this._placeholder) return this
    this._placeholder = placeholder
    if (this.quill) this.quill.root.setAttribute('data-placeholder', placeholder)
    return this
  }

  public setRealTimeUpdates(enabled: boolean) {
    this.isRealtimeUpdatesEnabled = enabled
    return this
  }
}
