import { checkNetworkError, handleNetworkError } from 'helpers/modules.helpers'
import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { getMe, login } from './auth.module'
import { MODAL } from './modal.module'
import { ILocalization } from '../../config/languages.config'
import { api } from '../../api'
import { ResetPasswordPayload, ResetPasswordRequestPayload } from '../../api/api.auth'
import { UpdateSingleTeacherPayload } from '../../api/types.teachers'

// Account constants
export enum ACCOUNT {
  INPUT_EMAIL_REQUEST = 'INPUT_EMAIL__REQUEST',
  INPUT_EMAIL_SUCCESS = 'INPUT_EMAIL_SUCCESS',
  INPUT_EMAIL_ERROR = 'INPUT_EMAIL_ERROR',
  CHANGE_PASSWORD_REQUEST = 'ACCOUNT_CHANGE_PASSWORD__REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'ACCOUNT_CHANGE_PASSWORD_ERROR',
  ACCOUNT_EDIT_REQUEST = 'ACCOUNT_EDIT_REQUEST',
  ACCOUNT_EDIT_SUCCESS = 'ACCOUNT_EDIT_SUCCESS',
  ACCOUNT_EDIT_ERROR = 'ACCOUNT_EDIT_ERROR',
}

// Account reducer
const initialState = {
  data: {},
  error: null,
  loading: false,
}
export function accountReducer(state: any = initialState, action: IAction<ACCOUNT>) {
  switch (action.type) {
    case ACCOUNT.INPUT_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ACCOUNT.INPUT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ACCOUNT.INPUT_EMAIL_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case ACCOUNT.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ACCOUNT.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ACCOUNT.CHANGE_PASSWORD_ERROR:
      return {
        data: {},
        error: action.payload,
        loading: false,
      }
    case ACCOUNT.ACCOUNT_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ACCOUNT.ACCOUNT_EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case ACCOUNT.ACCOUNT_EDIT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function resetPasswordRequest(data: ResetPasswordRequestPayload, history: any) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: ACCOUNT.INPUT_EMAIL_REQUEST })
      await api.auth.resetPasswordRequest(data)
      dispatch({ type: ACCOUNT.INPUT_EMAIL_SUCCESS, payload: data })
      history.push('/login/reset-password/code')
      return
    } catch (error) {
      dispatch({
        payload: !!error.response
          ? { message: error.response.data.message }
          : { message: error.message },
        type: ACCOUNT.INPUT_EMAIL_ERROR,
      })
    }
  }
}

export function editAccount(
  data: UpdateSingleTeacherPayload['body'],
  id: string,
  history: any,
  localization: ILocalization
) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: ACCOUNT.ACCOUNT_EDIT_REQUEST })
      const res = await api.teachers.updateSingle({ teacherId: id, body: data })
      dispatch({ type: ACCOUNT.ACCOUNT_EDIT_SUCCESS, payload: res })
      getMe(history)(dispatch)
      dispatch({ type: MODAL.CLOSE_SIMPLE_ASYNC_MODAL })
      dispatch({ type: MODAL.CLOSE_SELECT_SCHOOLS_MODAL })
    } catch (error) {
      checkNetworkError(
        error,
        () => {
          dispatch({
            payload: !!error.response ? error.response.data.message : error.message,
            type: ACCOUNT.ACCOUNT_EDIT_ERROR,
          })
        },
        () => handleNetworkError(ACCOUNT.ACCOUNT_EDIT_ERROR, error, dispatch)
      )
    }
  }
}

export function changePassword(data: ResetPasswordPayload, history: any, localization: any) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: ACCOUNT.CHANGE_PASSWORD_REQUEST })
      await api.auth.resetPassword(data)
      dispatch({ type: ACCOUNT.CHANGE_PASSWORD_SUCCESS, payload: data })
      login(data, history, localization)(dispatch)
      return
    } catch (error) {
      if (!!error.response) {
        const details = error.response.data
        if (details?.error_details?.includes('code')) {
          dispatch({
            payload: { message: localization.data?.invalidCode },
            type: ACCOUNT.CHANGE_PASSWORD_ERROR,
          })
        }
        if (details.includes('username')) {
          dispatch({
            payload: { message: localization.data?.invalidUsername },
            type: ACCOUNT.CHANGE_PASSWORD_ERROR,
          })
        } else {
          dispatch({
            payload: !!error.response ? { ...error.response.data } : { ...error },
            type: ACCOUNT.CHANGE_PASSWORD_ERROR,
          })
        }
      }
    }
  }
}

export interface IAccountActions {
  changePassword: (
    data: { password: string; passwordConfirmation: string },
    history: any,
    localization: any
  ) => void
  resetPasswordRequest: (data: { email: string }, history: any) => void
  editAccount: (
    data: UpdateSingleTeacherPayload['body'],
    id: string,
    history: any,
    localization: ILocalization
  ) => void
}
