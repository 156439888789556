export enum ErrorMessagesFields {
  NAME = 'NAME',
  SELECT_PROBLEMS = 'SELECT_PROBLEMS',
  PARTICIPANTS = 'PARTICIPANTS',
}

export type ErrorMessagesValues = {
  isSubmitted: boolean
  messages: Record<ErrorMessagesFields, null | string>
}
