import React from 'react'
import { SvgIconProps } from '../types/types'

const CrossIcon = ({ size = 12, color, strokeWidth = 0.7968 }: SvgIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
    >
      <g opacity='0.8' clipPath='url(#clip0_859_12326)'>
        <path
          d='M0.382568 0.382324L11.6083 11.6081'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
        />
        <path
          d='M11.6083 0.382324L0.382568 11.6081'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_859_12326'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CrossIcon
