import { createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/session'
import { filterDeletedProblems } from 'helpers/problems.helpers'
import { sessionStorageKeys } from '../../../../config/localStorageKeys'
import { getFormattedSelectedProblems } from '../../../exerciseDetails/helpers'
import { fetchExerciseFx } from '../../../exerciseDetails/model/requests'
import {
  deserializeSelectedProblems,
  filterProblemsByLevel,
  sortProblemsByLevelAndOrder,
} from '../helpers'
import { $problems } from './problems'
import { SelectAllByLevelProps, SelectProblemProps } from './types'

export const selectAllBySection = createEvent<string>()
export const removeSelectedProblems = createEvent<string>()

export const selectAllByLevel = createEvent<SelectAllByLevelProps>()
export const selectProblem = createEvent<SelectProblemProps>()

export const resetSelectedProblems = createEvent()

export const $selectedProblems = createStore<Map<string, Set<string>>>(new Map())
  .on(selectAllBySection, (state, sectionId) => {
    const newState = new Map(state)
    const problems = $problems.getState().get(sectionId)
    const filteredProblems = filterProblemsByLevel(problems || [])
    const areAllProblemsSelected = filteredProblems?.every((problem) =>
      newState.get(sectionId)?.has(problem._id)
    )

    if (newState.get(sectionId) && areAllProblemsSelected) {
      newState.delete(sectionId)
      return newState
    }

    newState.set(sectionId, new Set(filteredProblems?.map((problem) => problem._id)))
    return newState
  })
  .on(removeSelectedProblems, (state, sectionId) => {
    const newState = new Map(state)
    newState.delete(sectionId)
    return newState
  })
  .on(selectProblem, (state, { sectionId, problemId }) => {
    const newState = new Map(state)
    const sectionProblems = new Set(newState.get(sectionId) || [])
    const isProblemSelected = sectionProblems.has(problemId)

    if (isProblemSelected) {
      sectionProblems.delete(problemId)
      newState.set(sectionId, sectionProblems)
      return newState
    }

    sectionProblems.add(problemId)
    const allProblems = $problems.getState().get(sectionId) ?? []
    const sortedSectionProblems = sortProblemsByLevelAndOrder(sectionProblems, allProblems)
    newState.set(sectionId, sortedSectionProblems)
    return newState
  })
  .on(selectAllByLevel, (state, { sectionId, problemIds }) => {
    const newState = new Map(state)
    const sectionProblems = new Set(newState.get(sectionId) || [])
    const isAllProblemsSelected = problemIds.every((problemId) => sectionProblems.has(problemId))

    if (isAllProblemsSelected) {
      problemIds.forEach((problemId) => sectionProblems.delete(problemId))
      newState.set(sectionId, sectionProblems)
      return newState
    }

    problemIds.forEach(sectionProblems.add, sectionProblems)
    const allProblems = $problems.getState().get(sectionId) ?? []
    const sortedSectionProblems = sortProblemsByLevelAndOrder(sectionProblems, allProblems)
    newState.set(sectionId, sortedSectionProblems)
    return newState
  })
  .on(fetchExerciseFx.doneData, (state, { problems }) => {
    const newState = new Map(state)

    filterDeletedProblems(problems).forEach((problem) => {
      const sectionId = problem.sectionId
      const sectionProblems = new Set(newState.get(sectionId) || [])
      sectionProblems.add(problem._id)
      newState.set(sectionId, sectionProblems)
    })
    return newState
  })
  .reset(resetSelectedProblems)

persist({
  store: $selectedProblems,
  key: sessionStorageKeys.cafSelectedProblems,
  serialize: (state) => JSON.stringify(getFormattedSelectedProblems(state)),
  deserialize: (state) => deserializeSelectedProblems(state),
})
