import { localStorageKeys } from 'config/localStorageKeys'
import { LOCALIZE } from 'redux/modules/localization.module'
import { getItemFromLocalStorage, setItemToLocalStorage } from './storage.helper'

export function generateLanguageFromStorage() {
  const lang: string = getItemFromLocalStorage(localStorageKeys.language) || 'Swe'

  const mapOfLanguages: { [key in LOCALIZE]: string } = {
    [LOCALIZE.SE]: 'sv',
    [LOCALIZE.US]: 'us',
    [LOCALIZE.GB]: 'gb',
  }
  return mapOfLanguages[lang]
}

export function saveLanguageInStorage(lang: LOCALIZE) {
  setItemToLocalStorage(localStorageKeys.language, lang)
}
