import { IProblem } from 'api/types.problem'
import { dndSelectedProblemsModel } from './dndSelectedProblems'
import { reorderModalStateModel } from './isReorderModalOpen'
import { savedSortedProblemsIdModel } from './savedSortedSelectedProblemIds'
import { currentSortOptionModel } from './sortOption/currentSortOption'
import { savedSortOptionModel } from './sortOption/savedSortOption'

export function serializeSelectedProblemsData(store: Map<string, IProblem> | null): string {
  if (store === null) {
    return JSON.stringify(null)
  }

  const obj: { [key: string]: IProblem } = {}
  store.forEach((value, key) => {
    obj[key] = value
  })
  return JSON.stringify(obj)
}

export function deserializeSelectedProblemsData(
  serializedStore: string
): Map<string, IProblem> | null {
  if (serializedStore === 'null') {
    return null
  }

  const obj: { [key: string]: IProblem } = JSON.parse(serializedStore)
  const map = new Map<string, IProblem>()

  Object.entries(obj).forEach(([key, value]) => {
    map.set(key, value)
  })

  return map
}

export const resetReorderProblemStores = () => {
  currentSortOptionModel.reset()
  reorderModalStateModel.reset()
  savedSortedProblemsIdModel.reset()
  dndSelectedProblemsModel.reset()
  savedSortOptionModel.reset()
}
