import { checkClassEditingPage } from 'helpers/pathnameChecking.helper'
import { IState } from 'interfaces/state.interface'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import './ViewContainer.component.scss'
import { ILocalization } from "../../config/languages.config";

export interface IContainerProps {
  children?: any
  className?: string
  model?: IState<any>
  history: RouteComponentProps['history']
  localization?: ILocalization
}

export class ViewContainer extends React.PureComponent<IContainerProps, any> {
  public renderTitleForClassEditing(
    title: string,
    classcode: number,
    localization: ILocalization
  ): JSX.Element {
    return (
      <div className='heading-of-class-editing'>
        <div className='class-name-container'>
          {this.renderTitleAndSubtitleForClass(localization.data.classTxt, title)}
        </div>
        <div className='class-code-container'>
          {this.renderTitleAndSubtitleForClass(localization.data.classcode, classcode)}
        </div>
      </div>
    )
  }

  public renderTitleAndSubtitleForClass(subTitle: string, title: number | string): JSX.Element {
    return (
      <>
        <span className='sub-title'>{subTitle}:</span>
        <span className='title'>{title}</span>
      </>
    )
  }

  public render() {
    const { children, className, model, localization } = this.props
    const isItClassEditingPage: boolean = checkClassEditingPage(
      this.props.history.location.pathname
    )
    return (
      <div className={`${className} view-container`}>
        {!!model &&
          isItClassEditingPage &&
          this.renderTitleForClassEditing(
            model!.data.name,
            model!.data.invitationalCode,
            localization!
          )}
        {children}
      </div>
    )
  }
}
