import React, { forwardRef } from 'react'
import PlusIcon from '../../icons/PlusIcon/PlusIcon'

import UIButton, { ButtonOptions, ButtonProps } from '../UIButton/Button'
import './PlusIconButton.scss'

const getPlusIconSize = (size: ButtonOptions['size']) => {
  const map = {
    large: 13,
    medium: 10,
    small: 9,
    answer: 10,
    pill: 10,
  }

  if (!size || !map[size]) return 10

  return map[size]
}

interface PlusIconButtonOptions {
  /**
   * Custom size of plus icon.
   * When not filled - size is calculated based on button size.
   */
  plusIconSize?: number

  /**
   * Custom stroke-width.
   * @default '1.66'
   */
  plusIconStrokeWidth?: string
}

type Ref = HTMLButtonElement

type PlusIconButtonProps = ButtonProps & PlusIconButtonOptions

const PlusIconButton = forwardRef<Ref, PlusIconButtonProps>(
  ({ size, children, plusIconSize, plusIconStrokeWidth, ...rest }, ref) => {
    const iconSize = plusIconSize ?? getPlusIconSize(size)
    return (
      // @ts-ignore
      <UIButton ref={ref} size={size} {...rest}>
        <div className='ui-btn-plus-icon-content'>
          {children}
          <span className='ui-btn-plus-icon'>
            <PlusIcon size={iconSize} color='currentColor' strokeWidth={plusIconStrokeWidth} />
          </span>
        </div>
      </UIButton>
    )
  }
)

export default PlusIconButton
