import { IAction } from '../../interfaces/actions.interface'
import { IDispatch } from '../../interfaces/dispatch.interface'
import { isEmpty } from 'lodash'
import { timeout } from '../../helpers/modules.helpers'
import { api } from '../../api'
import { GetSkillsStatisticsPayload, StatisticsType } from '../../api/types.statistics'

export enum SKILLS_CHAPTERS_STATS {
  GET_SKILLS_CHAPTERS_STATS_REQUEST = 'GET_SKILLS_CHAPTERS_STATS_REQUEST',
  GET_SKILLS_CHAPTERS_STUDENTS_STATS_SUCCESS = 'GET_SKILLS_CHAPTERS_STUDENTS_STATS_SUCCESS',
  GET_SKILLS_CHAPTERS_CLASSES_STATS_SUCCESS = 'GET_SKILLS_CHAPTERS_CLASSES_STATS_SUCCESS',
  GET_SKILLS_CHAPTERS_GROUPS_STATS_SUCCESS = 'GET_SKILLS_CHAPTERS_GROUPS_STATS_SUCCESS',
  GET_SKILLS_CHAPTERS_STATS_ERROR = 'GET_SKILLS_CHAPTERS_STATS_ERROR',
  CLEAR_SKILLS_CHAPTERS_STATS = 'CLEAR_SKILLS_CHAPTERS_STATS',
}

const initialState = {
  data: {
    studentsStats: {},
    classesStats: {},
    groupsStats: {},
  },
  error: null,
  loading: false,
}

export const skillsChaptersStatsReducer = (
  state: any = initialState,
  action: IAction<SKILLS_CHAPTERS_STATS>
) => {
  switch (action.type) {
    case SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STATS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STUDENTS_STATS_SUCCESS:
      return {
        data: { ...state.data, studentsStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_CLASSES_STATS_SUCCESS:
      return {
        data: { ...state.data, classesStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_GROUPS_STATS_SUCCESS:
      return {
        data: { ...state.data, groupsStats: action.payload },
        error: false,
        loading: false,
      }
    case SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STATS_ERROR:
      return {
        data: {
          studentsStats: {},
          classesStats: {},
          groupsStats: {},
        },
        error: action.payload,
        loading: false,
      }
    case SKILLS_CHAPTERS_STATS.CLEAR_SKILLS_CHAPTERS_STATS:
      return {
        data: {
          studentsStats: {},
          classesStats: {},
          groupsStats: {},
        },
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

const getActionType = (classIds?: string[], groupsIds?: string[]) => {
  if (classIds && !isEmpty(classIds)) {
    return SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_CLASSES_STATS_SUCCESS
  }
  if (groupsIds && !isEmpty(groupsIds)) {
    return SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_GROUPS_STATS_SUCCESS
  }
  return SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STUDENTS_STATS_SUCCESS
}

export const getSkillsChaptersStats = (
  exerciseId: string,
  classIds?: string[],
  groupsIds?: string[]
) => {
  const params: GetSkillsStatisticsPayload = { exerciseId }
  if (!isEmpty(classIds)) {
    params.classIds = classIds?.toString()
  }
  if (!isEmpty(groupsIds)) {
    params.groupIds = groupsIds?.toString()
  }
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: SKILLS_CHAPTERS_STATS.CLEAR_SKILLS_CHAPTERS_STATS })
      dispatch({ type: SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STATS_REQUEST })
      let res = await api.statistics.getSkillsChapters(params)
      if (isEmpty(res) || Object.values(res).every(isEmpty)) {
        await timeout(1000)
        res = await api.statistics.getSkillsChapters(params)
      }
      dispatch({ type: getActionType(classIds, groupsIds), payload: res })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: SKILLS_CHAPTERS_STATS.GET_SKILLS_CHAPTERS_STATS_ERROR,
      })
    }
  }
}

export const clearSkillsChaptersStats = () => {
  return (dispatch: IDispatch<any>) => {
    dispatch({ type: SKILLS_CHAPTERS_STATS.CLEAR_SKILLS_CHAPTERS_STATS })
  }
}
