import { INamedDocument } from 'interfaces/basic.interfaces'
import { IBasicUser, IStudent } from 'api/types.users'
import _ from 'lodash'
import { SortingTypes } from './enums'
import { IMyBook } from '../api/types.books'
import { BookSeriesData } from '../api/types.bookSeries'
import { ClassroomGroup } from '../api/types.classes'
import { IStudentProgressStat } from '../api/types.statistics'

export function getComparedNames(a: any, b: any) {
  if (a.firstName === b.firstName) {
    return getComparedStringOfName(a.lastName, b.lastName)
  }
  return getComparedStringOfName(a.firstName, b.firstName)
}

export function getComparedStringOfName(nameA: string, nameB: string) {
  return nameA.localeCompare(nameB)
}

export function sortByExerciseName(arr: any[], value: SortingTypes) {
  if (value === SortingTypes.Asc) {
    return _.sortBy(arr, 'exerciseName')
  } else {
    return _.sortBy(arr, 'exerciseName').reverse()
  }
}

type SortableType = INamedDocument | IBasicUser | ClassroomGroup

export function sortABC(items?: SortableType[]) {
  const hasPropName = !!items?.every((item) => 'name' in item)
  const hasPropFirstName = !!items?.every((item) => 'firstName' in item)

  if (hasPropFirstName || hasPropName) {
    return (items ?? []).sort((a, b) =>
      hasPropFirstName
        ? getComparedNames(a, b)
        : getComparedStringOfName((a as INamedDocument).name, (b as INamedDocument).name)
    )
  }
  return []
}

export const sortByLastName = (items: Array<IStudent>) => {
  return items.sort((a: IStudent, b: IStudent) =>
    getComparedStringOfName(a.lastName.toLowerCase(), b.lastName.toLowerCase())
  )
}

export function compareDate(a: any, b: any, sortingDirection: SortingTypes) {
  const firstDate: any = new Date(a.createdAt)
  const secondDate: any = new Date(b.createdAt)
  return sortingDirection === SortingTypes.Asc ? firstDate - secondDate : secondDate - firstDate
}

export function sortByDate(array: IStudentProgressStat[], value: SortingTypes) {
  const sortedArray = array.sort((a: IStudentProgressStat, b: IStudentProgressStat) => {
    return compareDate(a, b, value)
  })

  return sortedArray
}

export function sortByExamMode(array: IStudentProgressStat[], value: SortingTypes) {
  return array.sort((a: IStudentProgressStat, b: IStudentProgressStat) => {
    const firstExamMode: any = a.testModeEnabled
    const secondExamMode: any = b.testModeEnabled

    if (firstExamMode === secondExamMode) {
      return compareDate(a, b, SortingTypes.Desc)
    } else {
      const isAsc = value === SortingTypes.Asc ? 1 : -1
      const isFirstValueGreat = firstExamMode > secondExamMode
      return isFirstValueGreat ? isAsc : -isAsc
    }
  })
}

export function compareWithPercent(a: number, b: number, order: SortingTypes) {
  const isFirstElementGreat: number = a > b ? 1 : -1
  const isFirstElementLess: number = a < b ? 1 : -1
  const comparableValues: number =
    order === SortingTypes.Asc ? isFirstElementGreat : isFirstElementLess
  return comparableValues
}

/**
 * Sorts an array of books by order and name, with a special preference for 'My book'.
 *
 * This function compares two books based on the following rules:
 * - If one of the books is 'My book' and the other is not, 'My book' comes first.
 * - If 'order' is the same or not available, the books are sorted by their 'name' in ascending order.
 *
 * @param {BookSeriesData | IMyBook} book1 - The first book to compare.
 * @param {BookSeriesData | IMyBook} book2 - The second book to compare.
 * @returns {number} Returns a positive number if book1 should come after book2, a negative number
 *                   if book1 should come before book2, or 0 if they are equal in sorting order.
 */
export const sortMyBooksByOrder = (
  book1: BookSeriesData | IMyBook,
  book2: BookSeriesData | IMyBook
): number => {
  const isMyBook = (book: BookSeriesData | IMyBook) => book.name === 'My book'
  if (isMyBook(book1) && !isMyBook(book2)) {
    return 1
  }
  if (!isMyBook(book1) && isMyBook(book2)) {
    return -1
  }
  if (book1.order && book2.order && book1.order !== book2.order) {
    return book1.order - book2.order
  }
  return book1.name.localeCompare(book2.name)
}
