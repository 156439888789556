import { IStudentStat } from 'api/types.solutions'
import { IExerciseWithoutStats } from 'redux/modules/exerciseWithoutStats.module'
import { MergedProblemsAndSubProblems } from '../types'
import isEmpty from 'lodash/isEmpty'
import { GetCommonErrorResponse } from '../../../../api/types.statistics'

const COMMON_ERROR_DATA_LENGTH = 5

export const mergeProblemsAndSubProblems = (problems: IExerciseWithoutStats['problems']) =>
  (problems ?? []).flatMap((problem, problemIndex) =>
    problem.subProblems.length > 0
      ? problem.subProblems.map((subProblem) => ({
          ...subProblem,
          cellName: `${problemIndex + 1}${subProblem.name}`,
        }))
      : { ...problem, cellName: `${problemIndex + 1}` }
  )

export const getUserStudentsStats = ({
  mergedProblemsAndSubProblems,
  userId,
}: {
  mergedProblemsAndSubProblems: MergedProblemsAndSubProblems
  userId: string
}) =>
  mergedProblemsAndSubProblems.reduce((acc: IStudentStat[], problemOrSubproblem) => {
    const stat = problemOrSubproblem.studentStats?.find((item) => item.studentId === userId)
    if (stat) acc.push(stat)
    return acc
  }, [])

export const pickTheMostPopularErrors = (
  data: GetCommonErrorResponse,
  problems: MergedProblemsAndSubProblems
) => {
  return Object.entries(data)
    .reduce((acc: { id: string; studentIds: string[] }[], [key, value]) => {
      if (!isEmpty(value)) {
        acc.push({ id: key, studentIds: value.studentIds })
      }
      return acc
    }, [])
    .sort((a, b) => {
      const studentIdsDiff = b.studentIds.length - a.studentIds.length
      if (studentIdsDiff !== 0) return studentIdsDiff
      return (
        problems.findIndex((problem) => problem._id === a.id) -
        problems.findIndex((problem) => problem._id === b.id)
      )
    })
    .slice(0, COMMON_ERROR_DATA_LENGTH)
    .reduce((acc, obj) => {
      acc[obj.id] = { studentIds: obj.studentIds }
      return acc
    }, {})
}
