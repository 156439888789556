import katex from 'katex'
import { DeltaItem } from './types.quill'

export const QUILL_ERRORS = {
  NOT_INITIALIZED: new Error('Quill is not initialized'),
}

export function renderFormulaToElement(latex: string, element: HTMLElement) {
  element.setAttribute('data-value', latex)
  katex.render(latex, element, {
    throwOnError: false,
    output: 'html',
  })
}

export const FRACTION_DETECT_REGEXP = /(\b\w*)\/(\w*\b)?/
export const POWER_DETECT_REGEXP = /(\b\w*)?\^(\b\w*)?/
export const MINUS_DETECT_REGEXP = /(\s)?-(\s)?/
export const PLUS_DETECT_REGEXP = /(\s)?\+(\s)?/

const DOLLAR_WRAPPED_FORMULA_REGEXP = /\$(.*?)\$/g
export const textToDelta = (input: string) => {
  const deltaItems: DeltaItem[] = []
  let lastIndex = 0
  let match = DOLLAR_WRAPPED_FORMULA_REGEXP.exec(input)
  while (match !== null) {
    const textBeforeFormula = input.slice(lastIndex, match.index)
    if (textBeforeFormula) {
      deltaItems.push({ insert: textBeforeFormula })
    }
    deltaItems.push({ insert: { formula: match[1] } })
    lastIndex = match.index + match[0].length
    match = DOLLAR_WRAPPED_FORMULA_REGEXP.exec(input)
  }

  if (lastIndex < input.length) {
    deltaItems.push({ insert: input.slice(lastIndex) })
  }

  return deltaItems
}
