import React from 'react'
import { useUnit } from 'effector-react'

import styles from './AllStudentsResults.module.scss'

import StudentsList from '../StudentsList/StudentsList'
import { sortStudentsByCorrectAnswers } from '../../helpers'
import { IStudent } from '../../../../api/types.users'
import EmptyExerciseContainer from '../../../ExercisesList/components/EmptyExerciseContainer/EmptyExerciseContainer'
import ExampleProblem from './ExampleProblem/ExampleProblem'
import { practiceResultsModel } from '../../models/practiceResults'

type AllStudentsResultsProps = {
  selectedStudents: IStudent[]
  onOpenSingleStudentView: (studentId: string, studentFullName: string) => void
}

const AllStudentsResults = ({
  selectedStudents,
  onOpenSingleStudentView,
}: AllStudentsResultsProps) => {
  const isLoading = useUnit(practiceResultsModel.isFetchingResults)
  const studentsResults = useUnit(practiceResultsModel.$studentsResults)

  const orderedSelectedStudents = sortStudentsByCorrectAnswers(selectedStudents, studentsResults)

  if (isLoading) {
    return (
      <div className={styles.AllStudentsResultsContainer}>
        <EmptyExerciseContainer heightClass={styles.Loader} />
      </div>
    )
  }

  return (
    <div className={styles.AllStudentsResultsContainer}>
      <StudentsList
        students={orderedSelectedStudents}
        results={studentsResults}
        onClickItem={onOpenSingleStudentView}
      />
      <div className={styles.BodyItemsSeparator} />
      <ExampleProblem />
    </div>
  )
}

export default AllStudentsResults
