import { IAction } from 'interfaces/actions.interface'
import { IDispatch } from 'interfaces/dispatch.interface'
import { api } from '../../api'

import { ITopic } from '../../api/api.topics'

// topics constants
export enum TOPICS {
  GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST',
  GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS',
  GET_TOPICS_ERROR = 'GET_TOPICS_ERROR',
  DELETE_TOPIC = 'DELETE_TOPIC',
  DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR',
}

// topics reducer
const initialState = {
  data: {
    topics: [],
  },
  error: null,
  loading: false,
}

export function topicsReducer(state: ITopicsState = initialState, action: IAction<TOPICS>) {
  switch (action.type) {
    case TOPICS.GET_TOPICS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case TOPICS.GET_TOPICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case TOPICS.GET_TOPICS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case TOPICS.DELETE_TOPIC:
      return {
        ...state,
        data: {
          ...state.data,
          res: {
            _embedded: {
              topics: state.data?.topics.filter((topic: ITopic) => topic._id !== action.payload.id),
            },
          },
        },
      }
    case TOPICS.DELETE_TOPIC_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

const getAllTopics = async (page = 1) => {
  const res = await api.topics.getAll({ page })
  const pages = res.res.pages
  const NEXT_PAGE = 2
  const promises = []
  const topics = res.res._embedded.topics

  for (let i = NEXT_PAGE; i <= pages; i++) {
    promises.push(api.topics.getAll({ page: i }))
  }

  return await Promise.all(promises).then((responses) =>
    responses.reduce(
      (accumulator, currentTopics) => [...accumulator, ...currentTopics.res._embedded.topics],
      topics
    )
  )
}

export function getTopics(locale?: string) {
  return async (dispatch: IDispatch<any>) => {
    try {
      dispatch({ type: TOPICS.GET_TOPICS_REQUEST })
      const topics = await getAllTopics()
      topics.sort((prevTopic, currentTopic) =>
        prevTopic.name.localeCompare(currentTopic.name, locale, {
          numeric: true,
          sensitivity: 'base',
        })
      )
      dispatch({ type: TOPICS.GET_TOPICS_SUCCESS, payload: { topics } })
    } catch (error) {
      dispatch({
        payload: !!error.response ? error.response.data.message : error.message,
        type: TOPICS.GET_TOPICS_ERROR,
      })
    }
  }
}

export interface ITopicsActions {
  getTopics: (locale?: string) => void
}

export interface ITopics {
  topics: ITopic[]
}

export interface ITopicsState {
  data: {
    topics: ITopic[]
  }
  error: null | boolean
  loading: boolean
}
