import React from 'react'
import { COLORS } from '@magmamath/ui'

import { SvgIconProps } from '../types/types'

const UndoIcon: React.FC<SvgIconProps> = ({ size = 25, color = COLORS.NEUTRAL_9 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 26'
      fill='none'
    >
      <path
        d='M4.52001 24.5C4.52001 24.5 4.61849 24.5 15.1511 24.5C25.6838 24.5 25.2365 6.70001 15.1511 6.70001C5.06574 6.70001 1.1193 6.70001 1.1193 6.70001'
        stroke={color}
        strokeWidth='1.66'
        strokeLinecap='round'
      />
      <path
        d='M0.52937 7.28691C0.205235 6.96278 0.205235 6.43725 0.52937 6.11311L5.81146 0.831026C6.13559 0.506891 6.66112 0.506891 6.98525 0.831026C7.30939 1.15516 7.30939 1.68069 6.98525 2.00482L2.29007 6.70001L6.98525 11.3952C7.30939 11.7193 7.30939 12.2449 6.98525 12.569C6.66112 12.8931 6.13559 12.8931 5.81146 12.569L0.52937 7.28691ZM8.79785 7.53001H1.11627V5.87001H8.79785V7.53001Z'
        fill={color}
      />
    </svg>
  )
}

export default UndoIcon
