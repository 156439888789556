type CreatePathProps = {
  exerciseId: string
  studentId?: string
  problemId?: string
  subProblemId?: string
  hiddenParams?: string
}
export type ExerciseProps = {
  exerciseId: string
  hiddenParams?: string
}
export type DefaultProps = {
  studentId: string
} & ExerciseProps
export type ProblemProps =
  | {
      problemId?: string
      subProblemId: string
    }
  | {
      problemId: string
      subProblemId?: string
    }
export type FullProps = DefaultProps & ProblemProps
export const defaultProps: ExerciseProps = {
  exerciseId: ':exerciseId',
  hiddenParams: ':studentsHiddenParam?',
}
export const commonProps: DefaultProps = {
  ...defaultProps,
  studentId: ':studentId',
} as const

export function createDynamicPath<Props extends CreatePathProps>(endpoint: string) {
  return ({ exerciseId, problemId, studentId, subProblemId, hiddenParams = '' }: Props) => {
    const params: string[] = ['exercises', exerciseId]
    if (subProblemId) params.push('sub-problems', subProblemId)
    else if (problemId) params.push('problems', problemId)
    if (studentId) params.push('students', studentId)
    params.push(endpoint)
    return `/${params.join('/')}${hiddenParams}`
  }
}
