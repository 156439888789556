import { getItemFromLocalStorage } from 'helpers/storage.helper'
import { applyMiddleware, compose, createStore } from 'redux'
import reduxThunk from 'redux-thunk'
import { AUTH } from 'redux/modules/auth.module'
import { reducers } from '../redux/index.reducer'
// tslint:disable-next-line:ordered-imports
import { localStorageKeys } from 'config/localStorageKeys'
const initialState = {}
const enhancers = []
const middleware = [reduxThunk]
const user = getItemFromLocalStorage(localStorageKeys.user)

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = (window as any).devToolsExtension!

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers)
)

if (user) {
  store.dispatch({ type: AUTH.AUTHENTICATED })
}
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
