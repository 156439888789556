import { Divider, Menu, MenuItem } from '@mui/material'
import { MuiMenuProps } from 'features/ExercisesList/components/ExerciseDropdown/MuiMenuProps'
import useText from 'i18n/hook'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'

import OptionIcon from 'ui/icons/OptionIcon/OptionIcon'
import { isClassCreatedManually } from '../../../../../helpers/classesView.helpers'
import { DropdownPopupIds, FormTypes, RemovableTypes } from '../../../../../helpers/enums'
import { IMe, IStudent } from '../../../../../api/types.users'
import { IModalActions } from '../../../../../redux/modules/modal.module'
import { ILocalization } from '../../../../../config/languages.config'
import { Button, BUTTON_VARIANT, COLORS, SIZE } from '@magmamath/ui'
import { IClass, SourceType } from '../../../../../api/types.classes'
import { IGroup } from '../../../../../api/api.groups'

interface IStudentsOptionsMenuProps {
  type?: FormTypes
  history: any
  localization: ILocalization
  me: IMe
  modalActions: IModalActions
  student?: IStudent
  username?: string
  classId?: string
  groupId?: string
  classOfLastRow?: string
  classOrGroup: IClass | IGroup
}

const StudentsOptionsMenuUpdated: React.FC<IStudentsOptionsMenuProps> = ({
  history,
  localization,
  me,
  modalActions,
  student,
  username,
  classId,
  groupId,
  classOrGroup,
}) => {
  const t = useText()
  const moveToStudentStat = (studentId: string) => {
    history.push(`/student-progress/${studentId}`)
  }

  const isClass = 'invitationalCode' in classOrGroup

  const isOptionForManualClass =
    !isClass || isClassCreatedManually(me.municipality.source) || !me.municipality.source
  const isManualRestrictedClass =
    me.municipality.source === SourceType.matteappen && me.municipality.isPrivate
  const isRemoveOptionAvailable = (classId || groupId) && isClass && !classOrGroup.source

  return (
    <PopupState variant='popover' popupId={DropdownPopupIds.STUDENT_LIST_ITEM}>
      {(popupState) => (
        <>
          <Button
            size={SIZE.SMALL}
            variant={BUTTON_VARIANT.TERTIARY}
            icon={<OptionIcon size={18} color={COLORS.NEUTRAL_10} />}
            {...bindTrigger(popupState)}
          />

          <Menu sx={MuiMenuProps.sx} {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close()
                moveToStudentStat(student!._id)
              }}
            >
              {t.showStats}
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                modalActions!.openModalPassword(me, username!, localization!)
              }}
            >
              {t.resetPassTxt}
            </MenuItem>
            {isOptionForManualClass && !isManualRestrictedClass && (
              <MenuItem
                onClick={() => {
                  const studentSettings: { student?: IStudent } = { student }
                  popupState.close()
                  modalActions!.openModal(
                    t.changeStudentUsername,
                    t.userNameTxt,
                    me,
                    student!._id,
                    studentSettings
                  )
                }}
              >
                {t.changeUsernameTxt}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                const studentSettings: { student?: IStudent } = { student }
                popupState.close()
                modalActions!.openLanguageSettingsModal(studentSettings, classId)
              }}
            >
              {t.languageSupportTxt}
            </MenuItem>
            {classId && !groupId && (isOptionForManualClass || isManualRestrictedClass) && (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  modalActions!.openClassesModal(student!._id)
                }}
              >
                {t.moveToAnotherClass}
              </MenuItem>
            )}
            {isRemoveOptionAvailable && <Divider sx={{ margin: '0!important' }} />}
            {isRemoveOptionAvailable && (
              <MenuItem
                onClick={() => {
                  popupState.close()
                  const currentType: RemovableTypes = classId
                    ? RemovableTypes.StudentFromClass
                    : RemovableTypes.StudentFromGroup
                  modalActions!.openSimpleConfirmModal(student!, currentType)
                }}
              >
                <span style={{ color: COLORS.PRIMARY_RED }}>
                  {isClass ? t.removeStudentFromClass : t.removeStudentFromGroup}
                </span>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default StudentsOptionsMenuUpdated
