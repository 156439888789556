import NoSolutionBody from 'features/heatmap/SolutionCard/SolutionCardBody/NoSolutionBody'
import { AnswerType, MyScriptCharacterTypes } from 'helpers/enums'
import { getSolutionsUrl } from 'helpers/exercise.helpers'
import useText from 'i18n/hook'
import React, { memo } from 'react'
import styles from '../SmallSolutionCard.module.scss'

type SmallSolutionCardBodyProps = {
  drawingUrl?: string
  isAnswerSubmitted: boolean
  characterType: MyScriptCharacterTypes
  answerType: AnswerType
  noSolutionAnswer?: string[]
}

const SmallSolutionCardBody = ({
  drawingUrl,
  isAnswerSubmitted,
  characterType,
  answerType,
  noSolutionAnswer,
}: SmallSolutionCardBodyProps) => {
  const t = useText()

  return (
    <div className={styles.Body}>
      {drawingUrl && (
        <img className={styles.Drawing} src={getSolutionsUrl(drawingUrl)} alt={t.solutionsTxt} />
      )}
      {!drawingUrl && isAnswerSubmitted && (
        <NoSolutionBody
          characterType={characterType}
          answerType={answerType}
          noSolutionAnswer={noSolutionAnswer}
        />
      )}
    </div>
  )
}

export default memo(SmallSolutionCardBody)
