import dayjs, { Dayjs } from 'dayjs'
import DatePicker from 'features/DateTimePicker/DatePicker/DatePicker'
import DateTimePicker from 'features/DateTimePicker/DateTimePicker'
import TimePicker from 'features/DateTimePicker/TimePicker/TimePicker'
import useText from 'i18n/hook'
import { Time } from 'interfaces/scheduleTime.interface'
import { unionDateAndTime } from 'lib/date/helpers'
import React, { useEffect, useState } from 'react'
import UIButton from 'ui/Buttons/UIButton/Button'
import styles from './ScheduleModal.module.scss'
import { BUTTON_COLOR, Button, SIZE } from '@magmamath/ui'
import { ExerciseStatuses } from '../../../../../api/types.assignments'

type ScheduleModalProps = {
  handleScheduleClose: () => void
  handleExerciseStatus: (type: ExerciseStatuses) => void
  publishDate: Dayjs
  onPublishDateChange: (date: Dayjs) => void
  publishTime: Time
  onPublishTimeChange: (time: Time) => void
  archiveDate: Dayjs
  onArchiveDateChange: (date: Dayjs) => void
  archiveTime: Time
  onArchiveTimeChange: (time: Time) => void
}

const ScheduleModal = ({
  handleScheduleClose,
  handleExerciseStatus,
  publishDate,
  onPublishDateChange,
  publishTime,
  onPublishTimeChange,
  archiveDate,
  onArchiveDateChange,
  archiveTime,
  onArchiveTimeChange,
}: ScheduleModalProps) => {
  const t = useText()
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(publishDate))
  const [startTime, setStartTime] = useState<Time>(publishTime)
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs(archiveDate))
  const [dueTime, setDueTime] = useState<Time>(archiveTime)
  const [isDateValid, setIsDateValid] = useState(true)

  useEffect(() => {
    const isValid = unionDateAndTime(startDate, startTime).isBefore(
      unionDateAndTime(dueDate, dueTime)
    )
    if (isValid === isDateValid) return
    setIsDateValid(isValid)
  }, [startDate, startTime, dueDate, dueTime])

  const onSave = () => {
    onPublishDateChange(startDate)
    onPublishTimeChange(startTime)
    onArchiveDateChange(dueDate)
    onArchiveTimeChange(dueTime)
    handleExerciseStatus(ExerciseStatuses.Schedule)
    handleScheduleClose()
  }

  return (
    <div className={styles.Content}>
      <p className={styles.Title}>{t.scheduleTxt}</p>
      <div className={styles.ScheduleContainer}>
        <DateTimePicker
          label={t.startDateTxt}
          Date={<DatePicker date={startDate} onChangeDate={setStartDate} />}
          Time={<TimePicker time={startTime} onTimeChange={setStartTime} />}
        />
        <DateTimePicker
          label={t.dueDateTxt}
          Date={<DatePicker date={dueDate} onChangeDate={setDueDate} minDate={startDate} />}
          Time={<TimePicker time={dueTime} onTimeChange={setDueTime} />}
        />
        <p className={styles.Error}>{!isDateValid && t.scheduleDateError}</p>
      </div>
      <UIButton className={styles.SaveButton} size='large' onClick={onSave} disabled={!isDateValid}>
        {t.saveTxt}
      </UIButton>
    </div>
  )
}

export default ScheduleModal
